import React, { useEffect } from "react";
import { Row, Col, Form, Modal, Descriptions, InputNumber, notification } from "antd";
import { SaveOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";

export default function ModalItemInventario({ form, dadosItem, exibirModal, onCloseModal, TipoCodigoProduto }) {

    const [formulario] = Form.useForm();

    const alterarItem = record => {
        let dados = {};
        dados.TipoCodigoProduto = TipoCodigoProduto;
        dados.inv_id = form.getFieldValue().inv_id;
        dados.ivi_id = dadosItem.ivi_id;
        dados.qtde = record.ivi_estoqueatual - dadosItem.ivi_estoqueatual;
        api.put(`Inventario/ModificarItem`, dados).then(
            res => {
                notification.success({ description: 'Item do inventário modificado com sucesso!', message: 'Aviso' });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                formulario.resetFields();
                onCloseModal();
            }
        )
    };

    return (
        <Modal
            centered
            title="Manutenção de Item do Inventário"
            visible={exibirModal}
            onCancel={() => {
                formulario.resetFields();
                onCloseModal();
            }}
            onOk={() => formulario.submit()}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }   
            maskClosable={false}
        >
            <Row>
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="Produto"> 
                            <b>{dadosItem.pro_codigo} - {dadosItem.pro_descricao}</b>
                        </Descriptions.Item>                
                    </Descriptions>
                </Col>
            </Row>
            {!!dadosItem.grade && 
                <Row>
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item label="Grade">
                                <b>{dadosItem.grade}</b> 
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            }
            <Row>
                <Col span={24}>
                    <Descriptions size="small">
                        <Descriptions.Item label="Quantidade do estoque anterior">
                            <b>{dadosItem.ivi_estoqueanterior}</b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row className="m-t-8">
                <Col span={24}>
                    <Form layout="vertical" form={formulario} name="formItemInventario" onFinish={alterarItem}>
                        <Form.Item name="ivi_estoqueatual" label="Estoque atual (Contagem do Inventário)">
                            <InputNumber step={1} min={0} />
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Modal>
    );
};
