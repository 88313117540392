import React, { useState } from 'react';
import { Row, Col, Button, Drawer } from "antd";
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import ManutencaoVendedor from '../../pages/vendedor/manutencao';

export default function DrawerVendedores({ form, exibirDrawerVendedores, fecharDrawerVendedores }) {
    const [carregando, setCarregando] = useState(false);
    const [editando, setEditando] = useState(false);
    const [pagina, setPagina] = useState(1);

    return (
        <Drawer width="60%"
            destroyOnClose
            title="Cadastro de Vendedores"
            visible={exibirDrawerVendedores}
            onClose={() => {
                form.resetFields();
                setPagina(1);
                fecharDrawerVendedores();
            }}
            onOk={() => form.submit()}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={() => fecharDrawerVendedores()} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => form.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoVendedor formulario={form} nameForm={'manutencaoVendedor'} salvandoDados={setCarregando} aoSalvar={fecharDrawerVendedores} pagina={{ pagina, setPagina }} editando={{ editando, setEditando }} />
        </Drawer>);
};