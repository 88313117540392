import React, { useState, useEffect } from 'react';
import { Row, Col, Descriptions, Tabs } from "antd";

import moment from 'moment';
import { FormatNumber } from '../../ValueObjects';
import DetalheNotaFiscal from '.';

export default function TabDetalheTotalizadores({ nota }) {
    const [detalheNotaFiscal, setDetalheNotaFiscal] = useState({});

    useEffect(() => {
        if (!!nota) {
            setDetalheNotaFiscal(nota);
            nota.itensNf.forEach(i => {

            });
        }
    }, [nota]);

    return (
        <Row align="middle">
            <Col>
                <Descriptions column={{ xxl: 4, xl: 4, lg: 4, md: 3, sm: 3, xs: 2 }} size="small">
                    <Descriptions.Item label="Valor Produtos ">
                        <b>{FormatNumber(detalheNotaFiscal.valortotalprodutos,true)}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Bc. Icms">
                        <b>{FormatNumber(detalheNotaFiscal.valortotalbcicms,true)}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Bc. Icms ST">
                        <b>{FormatNumber(detalheNotaFiscal.valortotalbcicmsst,true)}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Bc. IPI">
                        <b>{FormatNumber(detalheNotaFiscal.valortotalbcipi,true)}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Valor Frete ">
                        <b>{FormatNumber(detalheNotaFiscal.valortotalfrete,true)}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Valor Icms">
                        <b>{FormatNumber(detalheNotaFiscal.valortotalicms,true)}</b>
                        {/* pode ser utilizado tambem o campo valototalicmsst */}
                    </Descriptions.Item>
                    <Descriptions.Item label="Valor Icms ST">
                        <b>{FormatNumber(detalheNotaFiscal.valortotalicmsst,true)}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Valor IPI">
                        <b>{FormatNumber(detalheNotaFiscal.valortotalipi,true)}</b>
                        {/* pode ser utilizado tambem o campo totalvaloripi */}
                    </Descriptions.Item>
                    <Descriptions.Item label="Valor Descontos">
                        <b>{FormatNumber(detalheNotaFiscal.valortotaldesconto, true)}</b>
                    </Descriptions.Item>
                    <Descriptions.Item >
                    </Descriptions.Item>
                    <Descriptions.Item >
                    </Descriptions.Item>
                    <Descriptions.Item >
                    </Descriptions.Item>
                    <Descriptions.Item label="Valor Acréscimos">
                        <b>{FormatNumber(detalheNotaFiscal.valortotaloutros,true)}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Bc. Pis">
                        <b>{FormatNumber(detalheNotaFiscal.valorbcpis,true)}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Bc. Cofins">
                        <b>{FormatNumber(detalheNotaFiscal.valorbccofins,true)}</b>
                    </Descriptions.Item>
                    <Descriptions.Item >
                    </Descriptions.Item>
                    <Descriptions.Item label="Valor Total ">
                        <b>{FormatNumber(detalheNotaFiscal.valortotalnota,true)}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Valor Pis">
                        <b>{FormatNumber(detalheNotaFiscal.valortotalpis,true)}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Valor Cofins">
                        <b>{FormatNumber(detalheNotaFiscal.valortotalcofins,true)}</b>
                    </Descriptions.Item>
                </Descriptions>
            </Col>
        </Row>
    );
}