import React, { useState, useEffect } from "react";
import { Row, Col, Button, notification, Modal } from "antd";
import { EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import DrawerRestaurante from './drawer';
import { useStateValue } from '../../state';
import { FormatFone, FormatNumber } from "../../ValueObjects";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, Editar, PesquisaOrdenacao, TabelaDados } from "../../components";

export default function Restaurante() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [listaTiposRestaurante, setListaTiposRestaurantes] = useState([]);

    function excluirRegistro(record) {
        api.post(`Restaurante/Excluir?codigoRestaurante=${record.res_id}`).then(
            (res) => {
                notification.success({ message: 'Operação concluída', description: "Registro excluído com sucesso!" });
            }).catch((erro) => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
            }).finally(
                () => {
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+res_id' } });
                });
    }

    function carregarDados() {
        api.get('Enum/Listar?nome=TipoRestaurante').then(
            res => {
                setListaTiposRestaurantes(res.data);
            }
        ).catch(error => {
            console.log(error);
        });
    }

    function editarRestaurante(record) {
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
        record.cpfCnpj = !!record.pef_cpf ? record.pef_cpf : record.pej_cnpj;
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
    }

    function modalExcluir(record) {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(record);
            }
        });
    };

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+res_id' } })
        carregarDados();
    }, [])

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+pes_nome" },
                { label: "Nome Z - A", value: "-pes_nome" },
                { label: "Código Crescente", value: "+res_id" },
                { label: "Código Decrescente", value: "-res_id" }
            ]} />
            <div className="tabela">
                <TabelaDados url="Restaurante/Listar" paginacao={false} colunas={[
                    {
                        title: 'Nome',
                        render: ({ pes_nome }) => (
                            <div>
                                <b>{pes_nome}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Telefone',
                        width: 105,
                        render: ({ psc_numero }) => (
                            <div>
                                <b>{psc_numero}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Endereço',
                        render: ({ log_logradouro }) => (
                            <div>
                                <b>{log_logradouro || 'Não Informado'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Preferência',
                        align: 'center',
                        render: ({ res_notaavaliacao }) => (
                            <div>
                                <b>{res_notaavaliacao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Valor Buffet Livre (R$)',
                        align: 'right',
                        render: ({ res_preco_buffetlivre }) => (
                            <div>
                                <b>{FormatNumber(!!res_preco_buffetlivre ? res_preco_buffetlivre : 0, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Valor por KG (R$)',
                        align: 'right',
                        render: ({ res_preco_kg }) => (
                            <div>
                                <b>{FormatNumber(!!res_preco_kg ? res_preco_kg : 0, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Tipo',
                        render: ({ res_tipo }) => (
                            <div>
                                <b>{res_tipo ? listaTiposRestaurante.filter(res => (res.res_tipo = res_tipo))[0].value : 'Não Informado'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Café da Manhã',
                        align: 'center',
                        width: 105,
                        render: ({ res_cafemanha }) => (
                            <div>
                                {!!res_cafemanha ? <CheckOutlined /> : <CloseOutlined />}
                            </div>
                        ),
                    },
                    {
                        title: 'Almoço',
                        align: 'center',
                        width: 58,
                        render: ({ res_almoco }) => (
                            <div>
                                {!!res_almoco ? <CheckOutlined /> : <CloseOutlined />}
                            </div>
                        ),
                    },
                    {
                        title: 'Jantar',
                        align: 'center',
                        width: 55,
                        render: ({ res_janta }) => (
                            <div>
                                {!!res_janta ? <CheckOutlined /> : <CloseOutlined />}
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        width: 65,
                        align: 'center',
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<EditOutlined />} onClick={() => editarRestaurante(record)} />
                                    </Col>
                                    <Col>
                                        <Button icon={<DeleteOutlined />} onClick={() => modalExcluir(record)} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerRestaurante />
        </div>
    );

}