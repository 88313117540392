import React, { useEffect } from "react";
import { Row, Col, Form, Input, Modal, notification } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { InputPhone } from "../..";
import api from "../../../services/api";
import { InputCep } from "../../../components";
import { HasValue, retornaSoNumeros, validarEmail } from "../../../services/funcoes";
import InputEmail from "../../inputEmail";

export default function ModalOrgaoSubjacente({ form, listaOrgaoSubjacente, exibirModalOrgaoSubjacente, fecharModalOrgaoSubjacente }) {
    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalOrgaoSubjacente();
            }
        });
    };

    useEffect(() => {
        if (exibirModalOrgaoSubjacente && !!form.getFieldValue().pop_cep) {
            verificaCep(form.getFieldValue().pop_cep);
        }
    }, [exibirModalOrgaoSubjacente]);

    const onFinish = values => {
        let dados = [...listaOrgaoSubjacente.listaOrgaoSubjacente];
        let indice = values.indice;
        delete values.indice;

        if(HasValue(values.pop_email) && !validarEmail(values.pop_email)){
            notification.warning({ message: `Aviso!`, description: `E-mail invalido` });
            return;
        }

        values.pop_ativo = true;
        values.pop_email = !!values.pop_email ? values.pop_email : "";
        values.pop_cep = !!values.pop_cep ? values.pop_cep : "";
        values.pop_observacao = !!values.pop_observacao ? values.pop_observacao : "";
        values.pop_latitude = "";
        values.pop_longitude = "";
        values.pop_numero = !!values.pop_numero ? values.pop_numero : "";
        values.pop_localreferencia = !!values.pop_localreferencia ? values.pop_localreferencia : "";
        values.pop_complemento = !!values.pop_complemento ? values.pop_complemento : "";
        values.pop_bairro = !!values.pop_bairro ? values.pop_bairro : "";
        values.pop_endereco = !!values.pop_endereco ? values.pop_endereco : "";
        values.pop_telefone = !!values.pop_telefone ? values.pop_telefone : "";
        values.pop_cidade = !!values.pop_cidade ? values.pop_cidade : "";
        if (indice >= 0) {
            dados[indice] = values;
        } else {
            dados.push(values);
        }
        listaOrgaoSubjacente.setListaOrgaoSubjacente(dados);
        fecharModalOrgaoSubjacente();
    };

    const verificaCep = (cep) => {
        if (!!cep && retornaSoNumeros(cep)) {
            api.get(`Cidade/BuscaCidadeCep?cep=${cep}`).then(
                res => {
                    if (!!res.data) {
                        form.setFieldsValue({ pop_cidade: `${res.data[0].cid_descricao}` });
                    } else {
                        fetch(`https://viacep.com.br/ws/${cep.replace(`-`, ``)}/json/`)
                            .then(
                                res => {
                                    res.json();
                                    form.setFieldsValue({ pop_cidade: `${res.data[0].cid_descricao}` });
                                }
                            ).catch(
                                error => {
                                    notification.warning({ message: 'Aviso', description: 'Cep não encontrado!' })
                                }
                            );
                    }
                }
            ).catch(
                error => {
                    notification.warning({ message: 'Aviso', description: 'Cep não encontrado!' })
                }
            )
        }
    }

    return (
        <Modal centered
            title="Cadastro de Órgãos Subjacentes"
            visible={exibirModalOrgaoSubjacente}
            onCancel={onCloseModal}
            onOk={() => { form.submit() }}
            okText={
                <>
                    <PlusOutlined /> Adicionar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <Form layout="vertical" name="manutencaoOrgaoSubjacente" form={form} initialValues={{ remember: true }} onFinish={onFinish}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item name="indice" hidden />
                        <Form.Item name="pop_id" hidden></Form.Item>
                        <Form.Item label="Nome" name="pop_nome" rules={[{ required: true, message: 'Informe o nome do Órgão Público.' }]}>
                            <Input placeholder="Informe o nome do Órgão Público" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item label="E-mail" name="pop_email">
                            <InputEmail placeholder="Informe o E-mail" name="pop_email"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <InputPhone label="Telefone" name="pop_telefone" placeholder="Informe o Número do telefone" />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <InputCep label="CEP" name="pop_cep" rules={[{ required: true, message: 'Informe o CEP' }]} onBlur={(event) => { verificaCep(event.currentTarget.value); }} />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Cidade" name="pop_cidade" >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Endereço" name="pop_endereco">
                            <Input placeholder="Informe o Endereço" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item label="Bairro" name="pop_bairro">
                            <Input placeholder="Informe o Bairro" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item label="Número" name="pop_numero">
                            <Input placeholder="Informe o Número" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item label="Complemento" name="pop_complemento">
                            <Input placeholder="Informe o Complemento" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Local de Referência" name="pop_localreferencia">
                            <Input.TextArea placeholder="Informe o Local de Referência" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Observações" name="pop_observacao">
                            <Input.TextArea placeholder="Observações" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}