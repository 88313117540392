import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, notification, InputNumber, Table, Button, Modal } from "antd";
import { EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import { useStateValue } from '../../state';
import { listagemActions } from "../../actions";
import { FormatNumber } from "../../ValueObjects";
import { Data, FormGW, InputPreco } from "../../components";
import { imprimirRelatorioJasper } from "../../services/funcoes";
import { ModalEditarVencimentoParcela } from '../../components/modals/';
import SelectPaginado from '../../components/selectPaginado';

export default function ManutencaoContaReceber({ formulario, carregando, aoSalvar }) {

    const [{ manutencao }, dispatch] = useStateValue();

    const { Option } = Select;
    const [openIntevaloDias, setOpenIntevaloDias] = useState(false);
    const [listaContaCorrente, setListaContaCorrente] = useState([]);
    const [dadosContasReceber, setDadosContasReceber] = useState([]);
    const [openModalEditarParcela, setOpenModalEditarParcela] = useState(false);
    const [dadosLinhaEditar, setDadosLinhaEditar] = useState([]);
    const [listaContaGerencial, setListaContaGerencial] = useState([]);
    const [listaContaGerencialAgrupador, setListaContaGerencialAgrupador] = useState([]);

    const salvarManutencao = (values) => {
        values.parcelas = dadosContasReceber.parcelas;
        values.cpr_intervalodias = dadosContasReceber.cpr_intervalodias;
        values.cpr_numerodocumento = !!values.cpr_numerodocumento ? values.cpr_numerodocumento.toString() : "";
        
        if (!(!!values.valortotal) || values.valortotal === 0) {
            notification.warn({ message: 'Aviso!', description: 'Informe um valor total do documento!' });
            return false;
        }
        carregando(true);
        api.post('/contasPagarReceber/IncluirContasReceber', values).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Conta a receber incluída com sucesso!" });
                modalImprimir(res.data);
            }
        ).catch(
            err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }
        ).finally(() => {
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pes_nome' } })
            aoSalvar();
            carregando(false);
        });
    };

    const modalImprimir = (record) => {
        Modal.confirm({
            title: 'Imprimir?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja imprimir a duplicata?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                imprimirRelatorioJasper(37, { IdContaPagarReceber: record.cpr_id });
            }
        });
    };

    function gerarParcelas() {
        let dados = [];
        let parcelas = {};
        dados.parcelas = [];
        let qtdDias = 0;
        let somaDasParcelas = 0;
        let dataVencimento = moment(formulario.getFieldValue().cpr_datavencimento);
        let valorTotal = parseFloat(formulario.getFieldValue().valortotal);
        let quantidadeParcelas = formulario.getFieldValue().cpr_numeroparcelas;
        let periodo = formulario.getFieldValue().cpr_periodicidade;
        let primeiroPagamento = moment(formulario.getFieldValue().cpr_dataprimeiropagamento);
        let divisao = 0;
        let intervaloDias = formulario.getFieldValue().cpr_intervalodias;
        if (!!valorTotal && !!quantidadeParcelas && ((quantidadeParcelas === 1 && !!dataVencimento) || (quantidadeParcelas > 1 && !!primeiroPagamento && !!periodo && ((periodo === 4 && !!intervaloDias) || periodo !== 4)))) {
            divisao = parseFloat((valorTotal / quantidadeParcelas).toFixed(2));
            if (quantidadeParcelas > 1) {
                if (!!periodo && !!primeiroPagamento) {
                    if (periodo === 1) {
                        qtdDias = 7;
                        dados.cpr_intervalodias = qtdDias;
                    } else if (periodo === 2) {
                        qtdDias = 15;
                        dados.cpr_intervalodias = qtdDias;
                    } else if (periodo === 3) {
                        qtdDias = 30;
                        dados.cpr_intervalodias = qtdDias;
                    } else {
                        qtdDias = intervaloDias;
                        dados.cpr_intervalodias = qtdDias;
                    }
                }
                for (var i = 0; i < quantidadeParcelas; i++) {
                    parcelas = {};
                    parcelas.prc_numeroparcela = i + 1;
                    parcelas.prc_valor = divisao;

                    if (!!primeiroPagamento) {
                        var outraData = new Date(primeiroPagamento);
                        outraData.setDate((outraData.getDate()) + (i * qtdDias));
                        parcelas.prc_datavencimento = moment(outraData);
                    } else {
                        parcelas.prc_datavencimento = dataVencimento;
                    }
                    dados.parcelas.push(parcelas);
                    if (i !== 0)
                        somaDasParcelas += parseFloat(divisao.toFixed(2));
                }
                if (somaDasParcelas > 0)
                    dados.parcelas[0].prc_numeroparcela = 1;
                dados.parcelas[0].prc_valor = parseFloat((valorTotal - somaDasParcelas).toFixed(2));
            } else {
                parcelas = {};
                dados.cpr_periodicidade = null;
                dados.prc_periodicidade = null;
                dados.cpr_dataprimeiropagamento = dataVencimento;
                parcelas.prc_datavencimento = dataVencimento;
                parcelas.prc_numeroparcela = 1;
                parcelas.prc_valor = parseFloat(valorTotal).toFixed(2);
                dados.parcelas.push(parcelas);
            }
            setDadosContasReceber(dados);
        } else {
            setDadosContasReceber([]);
        }
    };

    function mostrarIntervaloDias(value, option) {
        gerarParcelas();
        if (option.value === 4) setOpenIntevaloDias(true);
        else setOpenIntevaloDias(false);
    };

    function editarDataVencimentoParcela(linha) {
        setDadosLinhaEditar(linha);
        setOpenModalEditarParcela(true);
    };

    function carregarListas() {
        api.get(`contaCorrente/listar?filtro=&ordem=+ctc_descricao`).then(res => {
            let key = 0;
            res.data.items.forEach((item) => { item.key = key++ }
            )
            setListaContaCorrente(res.data.items);
        })
        api.get(`ContaGerencial/ListarAtivosEstruturadoPelaCategoria?Ativo=true&CodReceitaDespesa=2`).then(res => {
            setListaContaGerencialAgrupador(res.data.filter((item) => { return item.podeSelecionar === false }));
            setListaContaGerencial(res.data.filter((item) => { return item.podeSelecionar === true }));
        })
    }

    useEffect(() => {
        formulario.setFieldsValue({ cpr_datainclusao: moment(new Date()), cpr_periodicidade: 3, cpr_numeroparcelas: 1 });
        carregarListas();
        gerarParcelas();
    }, []);

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoContaReceber" initialValues={{ remember: true }} onFinish={salvarManutencao} >
                <div>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={8} md={8} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Nome do Cliente" name="pes_id" rules={[{ required: true, message: 'Selecione o Cliente' }]}>
                                <SelectPaginado url="Cliente/Listar" placeholder="Selecione um Cliente" form={formulario} name="pes_id" conteudo={
                                    pc => (<Select.Option value={pc.pes_id} key={pc.key}>{pc.pes_nome}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Descrição Conta a Receber" name="cpr_descricao" >
                                <Input placeholder="Informe a Descrição da Conta" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Conta Corrente" name="ctc_id">
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Selectione uma Conta Corrente"    >
                                    {listaContaCorrente.map((item) => (
                                        <Select.Option value={item.ctc_id} key={item.key}>{item.ctc_descricao}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={7} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Conta Gerencial" name="ctg_id" rules={[{ required: true, message: 'Selecione uma Conta Gerencial' }]}>
                                <Select showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial...">
                                    {listaContaGerencialAgrupador.map(item => (
                                        <Select.OptGroup label={item.ctg_descricao}>
                                            {listaContaGerencial.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                                <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                            ))}
                                        </Select.OptGroup>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={5} lg={4} xl={4} xxl={3}>
                            <Data label="Data Lançamento" name="cpr_datainclusao" rules={[{ required: true, message: 'Informe a data de lançamento' }]} />
                        </Col>
                        <Col xs={24} sm={8} md={5} lg={5} xl={5} xxl={4}>
                            <Form.Item label="Número do Documento" name="cpr_numerodocumento">
                                <InputNumber
                                    step={1}
                                    min={1}
                                    placeholder="Informe o Número do documento"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={7} lg={5} xl={6} xxl={5}>
                            <InputPreco onBlur={() => gerarParcelas()} label="Valor Total do Documento (R$)" name="valortotal" rules={[{ required: true }]} />
                        </Col>
                        <Col xs={24} sm={8} md={4} lg={4} xl={4} xxl={4}>
                            <Form.Item label="Número de Parcelas" name="cpr_numeroparcelas" rules={[{ required: true, message: 'Informe o número de parcelas' }]}>
                                <InputNumber onChange={() => gerarParcelas()} step={1}
                                    min={1}
                                    placeholder="Informe o Número de Parcelas"
                                />
                            </Form.Item>
                        </Col>
                        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.cpr_numeroparcelas !== currentValues.cpr_numeroparcelas}>
                            {({ getFieldValue }) => {
                                if (getFieldValue('cpr_numeroparcelas') === 1) return (
                                    <>
                                        <Col xs={24} sm={8} md={6} lg={4} xl={5} xxl={4}>
                                            <Data onBlur={() => gerarParcelas()} label="Data Vencimento" name="cpr_datavencimento" rules={[{ required: true, message: 'Informe a data de vencimento' }]} />
                                        </Col>
                                    </>
                                );
                                if (getFieldValue('cpr_numeroparcelas') > 1) return (
                                    <>
                                        <Col xs={24} sm={8} md={6} lg={6} xl={5} xxl={4}>
                                            <Form.Item label="Periodicidade das Parcelas" name="cpr_periodicidade">
                                                <Select onChange={mostrarIntervaloDias} placeholder="Informe a periodicidade das parcelas">
                                                    <Option value={1}>Semanal</Option>
                                                    <Option value={2}>Quinzenal</Option>
                                                    <Option value={3}>Mensal</Option>
                                                    <Option value={4}>Outros</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8} md={6} lg={5} xl={5} xxl={4}>
                                            <Data onBlur={() => gerarParcelas()} label="Data Primeiro Vencimento" name="cpr_dataprimeiropagamento" rules={[{ required: true, message: 'Informe a data do primeiro vencimento' }]} />
                                        </Col>
                                        {openIntevaloDias === true &&
                                            <Col xs={24} sm={8} md={5} lg={5} xl={5} xxl={4}>
                                                <Form.Item label="Intervalo de Dias" name="cpr_intervalodias" align="right" rules={[{ required: true, message: 'Informe o intervalo de dias' }]}>
                                                    <Input onChange={() => gerarParcelas()} placeholder="Informe o intervalo de dias" />
                                                </Form.Item>
                                            </Col>
                                        }
                                    </>
                                );
                                else return null;
                            }}
                        </Form.Item>
                    </Row>
                    <Col span={24}>
                        <Form.Item label="Observação" name="cpr_observacao" >
                            <Input.TextArea placeholder="Informe observações complementares" />
                        </Form.Item>
                    </Col>
                </div>
                {!!dadosContasReceber.parcelas && dadosContasReceber.parcelas.length > 1 &&
                    <div className="tabela">
                        <Table dataSource={!!dadosContasReceber.parcelas ? dadosContasReceber.parcelas : null} columns={[
                            {
                                title: 'Parcelas',
                                render: ({ prc_numeroparcela }) => (
                                    <div>
                                        <b>{prc_numeroparcela}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Valor (R$)',
                                align: 'right',
                                render: ({ prc_valor }) => (
                                    <div>
                                        <b>{FormatNumber(prc_valor, true)}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Vencimento',
                                align: 'center',
                                width: 98,
                                render: ({ prc_datavencimento }) => (
                                    <div>
                                        <b>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                                    </div>
                                ),
                            },
                            {
                                dataIndex: '',
                                key: 'x',
                                title: 'Ações',
                                align: 'center',
                                width: 65,
                                fixed: 'right',
                                render: (record) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button onClick={() => editarDataVencimentoParcela(record)} icon={<EditOutlined />} />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]} scroll={{ x: 900 }} />
                    </div>
                }
            </FormGW>
            <ModalEditarVencimentoParcela exibirModal={openModalEditarParcela} fecharModal={() => setOpenModalEditarParcela(false)} dadosLinha={dadosLinhaEditar} dadosContasReceberPagar={dadosContasReceber} formulario={formulario} />
        </div>
    );
}