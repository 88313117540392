import React from "react";
import { Col, Form, Input, notification, Row } from "antd";
import InputNumber from "rc-input-number";

import api from "../../services/api";
import { useStateValue } from "../../state";

export default function ManutencaoEtiquetaServidor({ formulario, carregando, aoSalvar }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();

    const salvarManutencao = record => {
        let operacao = 'Incluir';
        if (!!record.met_id) {
            operacao = 'AlterarAsync';
        }
        carregando(true);
        api.post(`ModeloEtiqueta/${operacao}`, record).then(
            res => {
                notification.success({ description: `Modelo ${operacao === 'Incluir' ? 'incluído' : 'editado'} com sucesso!`, message: `Aviso` });
                aoSalvar();
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                carregando(false);
            }
        )
    };

    return (
        <div>
            <Form form={formulario} layout="vertical" name="formEtiquetaServidor" onFinish={salvarManutencao}>
                <Form.Item name="met_id" hidden></Form.Item>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={12} lg={14} xl={14}>
                        <Form.Item label="Descrição" name="met_descricao" rules={[{ required: true, message: "Informe a descrição do modelo!" }]}>
                            <Input placeholder="Informe a descrição do modelo" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <Form.Item label="Colunas" name="met_colunas" rules={[{ required: true, message: "Informe a quantidade de colunas do modelo!" }]}>
                            <InputNumber step={1} min={1} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item label="Caracteres na descrição do produto" name="met_quantidadecaracteresdescricaoproduto" initialValue={15} rules={[{ required: true, message: "Informe a quantidade de caracteres do produto!" }]} >
                            <InputNumber step={1} min={1} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Comando" name="met_comando" rules={[{ required: true, message: "Informe o comando da etiqueta!" }]}>
                            <Input.TextArea rows={15} placeholder="Informe o comando" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};