import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Table, Tag, InputNumber, Input, Modal } from "antd";
import { PlusOutlined, MinusOutlined, ExclamationCircleOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";

import InputPreco from "../../inputPreco";
import { useStateValue } from '../../../state';
import { info, validarNumeros } from "../../../services/funcoes";
import { manutencaoActions } from "../../../actions";
import { FormatNumber } from "../../../ValueObjects";

export default function ModalGradeProduto({ exibirModal, fecharModal, dados, origem, acrescimoDesconto = true, dadosValidacao }) {
    const [form] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();
    const [dadosRetorno, setDadosRetorno] = useState([]);
    const [ehPercentual, setEhPercentual] = useState(false);
    const [dadosFiltrados, setDadosFiltrados] = useState([]);

    useEffect(() => {
        if (!!dados) {
            dados.forEach((dado) => {
                if (!(!!dado.quantidade)) {
                    dado.quantidade = 0;
                }
            });
            setDadosRetorno(dados);
            setDadosFiltrados(dados);
        } else {
            form.resetFields();
        }
    }, [dados]);

    useEffect(() => {
        pesquisaGrade(form.getFieldValue().pesquisa);
    }, [dadosRetorno]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }
        });
    };

    const salvarDados = values => {
        dados?.forEach(item => {
            item.desconto = validarNumeros(form.getFieldValue()[`valorDesc${item.prg_id}`]);
            item.percDesc = validarNumeros(form.getFieldValue()[`percDesc${item.prg_id}`]);
            item.acrescimo = validarNumeros(form.getFieldValue()[`valorAcresc${item.prg_id}`]);
            item.percAcresc = validarNumeros(form.getFieldValue()[`percAcres${item.prg_id}`]);
        });
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, dadosGrade: [...dados] } } })
        fecharModal();
        form.resetFields();
    };

    function alterarQuantidade(item, operacao, qtde = null) {
        let dadosTemp = [...dadosRetorno];
        let itemTemp = dadosTemp.find((dado) => (dado === item));

        if (!!dadosValidacao && dadosValidacao.dadosNfeTransferencia && parseFloat(itemTemp.valorVenda) === 0) {
            info("Não foi possivel localizar o " + dadosValidacao.dadosNfeTransferencia.custo.value + " do item, selecione OUTRO CUSTO e informe o produto novamente!");
            return;
        }

        if (qtde !== null) {
            itemTemp.quantidade = qtde;
        } else {
            let qtd = !!itemTemp.quantidade ? itemTemp.quantidade : 0;
            if (operacao === "mais") {
                qtd++;
            } else if (operacao === "menos" && qtd > 0) {
                qtd--;
            }
            itemTemp.quantidade = qtd;
        }
        itemTemp.valorTotal = (parseFloat(itemTemp.quantidade) * parseFloat(itemTemp.valorVenda));
        if (acrescimoDesconto) {
            calcularAcres(ehPercentual ? 'P' : 'V', item, itemTemp);
            calcularDesc(ehPercentual ? 'P' : 'V', item, itemTemp);
        }
        setDadosRetorno(dadosTemp);
    };

    function pesquisaGrade(campo) {
        let filtrados = [];
        if (!!campo) {
            for (let item of dadosRetorno) {
                if (item.ean.indexOf(campo) >= 0) {
                    filtrados.push(item);
                } else {
                    for (let grade of item.atributos) {
                        if (grade.mgp_descricao.toLowerCase().indexOf(campo.toLowerCase()) >= 0) {
                            filtrados.push(item);
                        }
                    }
                }
            }
            setDadosFiltrados(filtrados);
        } else {
            setDadosFiltrados([...dadosRetorno]);
        }
    };

    function calcularDesc(tipo, item, itemTemp = null) {
        let salvar = false;
        let dadosTemp = [...dadosRetorno];
        if (itemTemp === null) {
            itemTemp = dadosTemp.find((dado) => (dado === item));
            itemTemp.valorTotal = (parseFloat(!!itemTemp.valorVenda ? itemTemp.valorVenda : itemTemp.pre_custoultimacompra) * itemTemp.quantidade);
            salvar = true;
        }

        let percDesc = parseFloat(form.getFieldValue()[`percDesc${item.prg_id}`]);
        let valorDesc = parseFloat(form.getFieldValue()[`valorDesc${item.prg_id}`]);
        let valorAcresc = parseFloat(form.getFieldValue()[`valorAcresc${item.prg_id}`]);

        itemTemp.valorTotal = (parseFloat(!!itemTemp.valorVenda ? itemTemp.valorVenda : itemTemp.pre_custoultimacompra) * itemTemp.quantidade);
        let valorRet = 0;
        if (!!valorDesc || !!percDesc) {
            if (tipo === 'P' && !!percDesc) {
                valorRet = (percDesc * itemTemp.valorTotal) / 100;
                form.setFieldsValue({ [`valorDesc${item.prg_id}`]: valorRet });
                valorDesc = valorRet;
            } else {
                valorRet = (valorDesc * 100) / itemTemp.valorTotal;
                form.setFieldsValue({ [`percDesc${item.prg_id}`]: valorRet });
            }
        }
        itemTemp.valorTotal = parseFloat(itemTemp.valorTotal - valorDesc + valorAcresc);

        if (salvar) {
            setDadosRetorno(dadosTemp);
        }
    };

    function calcularAcres(tipo, item, itemTemp = null) {
        let salvar = false;
        let dadosTemp = [...dadosRetorno];
        if (itemTemp === null) {
            itemTemp = dadosTemp.find((dado) => (dado === item));
            salvar = true;
        }

        let percAcresc = parseFloat(form.getFieldValue()[`percAcres${item.prg_id}`]);
        let valorDesc = parseFloat(form.getFieldValue()[`valorDesc${item.prg_id}`]);
        let valorAcresc = parseFloat(form.getFieldValue()[`valorAcresc${item.prg_id}`]);

        itemTemp.valorTotal = (parseFloat(!!itemTemp.valorVenda ? itemTemp.valorVenda : itemTemp.pre_custoultimacompra) * itemTemp.quantidade);
        let valorRet = 0;
        if (!!valorAcresc || !!percAcresc) {
            if (tipo === 'P' && !!percAcresc) {
                valorRet = (percAcresc * itemTemp.valorTotal) / 100;
                form.setFieldsValue({ [`valorAcresc${item.prg_id}`]: valorRet });
                valorAcresc = valorRet;
            } else {
                valorRet = (valorAcresc * 100) / itemTemp.valorTotal;
                form.setFieldsValue({ [`percAcres${item.prg_id}`]: valorRet });
            }
        }
        itemTemp.valorTotal = parseFloat(itemTemp.valorTotal - valorDesc + valorAcresc);

        if (salvar) {
            setDadosRetorno(dadosTemp);
        }
    };

    return (
        <Modal centered
            title="Grade Produtos"
            visible={exibirModal}
            onCancel={onCloseModal}
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            onOk={() => { form.submit() }}
            okText={
                <>
                    <CheckOutlined /> Ok
                </>
            }
            width={780}
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="formGradeCondicional" form={form} onFinish={salvarDados} >
                    <Row gutter={[0, 16]}>
                        <Col span={24}>
                            <Form.Item name="pesquisa">
                                <Input.Search placeholder="Localizar um item da grade"
                                    onSearch={(campo) => { pesquisaGrade(campo) }} allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="tabela">
                        <Table columns={[
                            {
                                title: 'Código',
                                width: 112,
                                render: ({ ean }) => (
                                    <div>
                                        <Tag color="processing">
                                            <b>{ean}</b>
                                        </Tag>
                                    </div>
                                ),
                            }, {
                                title: 'Grade',
                                render: ({ atributos }) => (
                                    <div>
                                        <Row align="middle" gutter={[5, 5]}>
                                            {atributos.map(atrib => (
                                                <Col>
                                                    <b>{atrib.mgp_descricao}</b>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                ),
                            }, acrescimoDesconto ? {
                                title: () => {
                                    return (
                                        <div>
                                            <Row justify="center" gutter={[5, 0]}>
                                                <Col>
                                                    Desconto
                                                </Col>
                                                <Col>
                                                    <Button size="small" onClick={() => { setEhPercentual(!ehPercentual) }}>{(ehPercentual ? 'R$' : '%')}</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                },
                                width: 175,
                                align: 'center',
                                render: (record, { prg_id }) => (
                                    <Row gutter={[5, 0]}>
                                        <Col span={12}>
                                            <Form.Item name={`percDesc${prg_id}`} label="Desc." hidden={!ehPercentual} initialValue={0}>
                                                <InputNumber
                                                    defaultValue={0}
                                                    precision={2}
                                                    min={0}
                                                    max={100}
                                                    formatter={value => `${value}%`}
                                                    parser={value => value.replace('%', '')}
                                                    onBlur={() => { calcularDesc('P', record) }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>  {/*
                                            <Form.Item name={`percAcres${prg_id}`} label="Acresc." hidden={!ehPercentual} initialValue={0}>
                                                <InputNumber
                                                    defaultValue={0}
                                                    precision={2}
                                                    min={0}
                                                    max={100}
                                                    formatter={value => `${value}%`}
                                                    parser={value => value.replace('%', '')}
                                                    onBlur={() => { calcularAcres('P', record) }}
                                                />
                                            </Form.Item>*/}
                                        </Col>
                                        <Col span={12}>
                                            <InputPreco name={`valorDesc${prg_id}`} hidden={ehPercentual} label="Desc. (R$)" onBlur={() => { calcularDesc('V', record) }} initialValue={0} />
                                        </Col>
                                        {/* <Col span={12}>
                                           <InputPreco name={`valorAcresc${prg_id}`} hidden={ehPercentual} label="Acresc. (R$)" onBlur={() => { calcularAcres('V', record) }} initialValue={0} />
                                </Col>*/}
                                    </Row>
                                ),
                            } : {}, {
                                title: 'Quantidade',
                                width: 112,
                                align: 'center',
                                render: (record, { quantidade }) => (
                                    <Row className="m-t-15">
                                        <Col span={6}>
                                            <Button type="primary" onClick={() => { alterarQuantidade(record, "menos") }} icon={<MinusOutlined />} block className="h32" />
                                        </Col>
                                        <Col span={12}>
                                            <InputNumber
                                                name="quantidade" value={quantidade} min={0} precision={0} onChange={(e) => { alterarQuantidade(record, "", e) }} />
                                        </Col>
                                        <Col span={6}>
                                            <Button type="primary" onClick={() => { alterarQuantidade(record, "mais") }} icon={<PlusOutlined />} block className="h32" />
                                        </Col>
                                    </Row>
                                ),
                            },
                            {
                                title: 'Vlr Unit. (R$)',
                                align: 'right',
                                width: 90,
                                render: ({ valorTotal, valorVenda, pre_custoultimacompra }) => (
                                    <div>
                                        <b>{FormatNumber(valorVenda, true, false)}</b>
                                    </div>
                                ),
                            },
                             {
                                title: 'Vlr Total (R$)',
                                align: 'right',
                                width: 90,
                                render: ({ valorTotal, valorVenda, pre_custoultimacompra }) => (
                                    <div>
                                        <b>{FormatNumber(valorTotal ? valorTotal : valorVenda, true, false)}</b>
                                    </div>
                                ),
                            }
                        ]} dataSource={dadosFiltrados} pagination={false} scroll={{ y: 285, x: 580 }} />
                    </div>
                </Form>
            </div>
        </Modal>

    );

};