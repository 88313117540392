import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Tag, Modal, notification } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

import api from '../../services/api';
import { useStateValue } from '../../state';
import { getEmpresa } from "../../services/auth";
import ManutencaoLimiteCreditoCliente from './drawer';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, FiltroSearch, TabelaDados, Editar, Excluir } from "../../components";

export default function LimiteCreditoCliente() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    const modalExcluir = (item) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                removerRegistro(item);
            }
        });
    };

    useEffect(() => {
        carregarDados();
    }, []);

    function carregarDados() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '%2Bqlc_ordem', outrosFiltros: `&codigoEmpresa=${getEmpresa()}` } });
    };

    function removerRegistro(item) {
        setCarregando(true);
        api.delete(`QuestaoLimiteCredito/Excluir/${item.qlc_id}`).then((res) => {
            if (res.status === 200) {
                notification.success({ message: 'Operação concluída', description: "Os dados do Limite de Crédito foram excluídos com sucesso!" });
            }
        }, err => {
            notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
        }).finally(() => {
            carregarDados();
        });
    };

    function alterarOrdem(qlc_id, qlc_ordem) {
        if (!!manutencao.listaTabelaDados) {
            let listasDados = manutencao.listaTabelaDados;
            let dados = listasDados.filter((questao) => questao.qlc_id === qlc_id)[0];
            if (qlc_ordem > listasDados.length && qlc_ordem > dados.qlc_ordem) {
                notification.warn({ description: 'Ordem maior que quantidade de questões!', message: 'Aviso' });
                return;
            }
        }
        api.post(`QuestaoLimiteCredito/AlterarOrdemRegistros?idQuestao=${qlc_id}&novaOrdem=${qlc_ordem}`).then(
            (res) => {
                if (res.status === 200) {
                    carregarDados();
                }
            }, erro => {
                console.log(erro);
            });
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <div>
                <Form layout="vertical">
                    <Row align="middle" gutter={[0, 8]}>
                        <Col span={24}>
                            <FiltroSearch />
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="tabela">
                <TabelaDados url="QuestaoLimiteCredito/Listar" colunas={[
                    {
                        width: 75,
                        align: 'center',
                        render: ({ qlc_ordem, qlc_id }) => (
                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                {qlc_ordem !== 1 &&
                                    <Col>
                                        <Button icon={<ArrowUpOutlined />} onClick={() => { alterarOrdem(qlc_id, qlc_ordem - 1) }}></Button>
                                    </Col>
                                }
                                <Col>
                                    <Button icon={<ArrowDownOutlined />} onClick={() => { alterarOrdem(qlc_id, qlc_ordem + 1) }}></Button>
                                </Col>
                            </Row>
                        ),
                    },
                    {
                        title: 'Ordem',
                        width: 75,
                        align: 'center',
                        render: ({ qlc_ordem }) => (
                            <Tag color="processing" className="w-100">
                                <b>{qlc_ordem}</b>
                            </Tag>
                        ),
                    },
                    {
                        title: 'Questões de Limite de Crédito',
                        render: ({ qlc_questao }) => (
                            <div>
                                <b>{qlc_questao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 65,
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Editar onClick={() => {
                                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                            dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                        }} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>
                                        <Excluir icon={<DeleteOutlined />} onClick={() => modalExcluir(record)} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <ManutencaoLimiteCreditoCliente />
        </div>
    );

}