import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Select, Modal } from "antd";
import { CheckOutlined, ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons";
import api from "../../../services/api";

import SelectPaginado from "../../../components/selectPaginado";

export default function ModalInutilizacaoNfe({ exibirModal, fecharModal }) {

    useEffect(() => {
        formulario.setFieldsValue({ justificativa: 'Solicitada a inutilização dos números de NF-e da respectiva série fiscal pois a numeração não foi utilizada por motivo de rejeição de NF-e ou falha no processo de emissão do sistema.' });
    }, []);

    const [formulario] = Form.useForm();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
            }
        });
    };

    function validarNumeracao(values) {
        let qtdNumero = parseInt(values.nroFinal) - parseInt(values.nroInicial);
        if (parseInt(values.nroInicial) == 0 || parseInt(values.nroFinal) == 0) {
            Modal.error({
                title: 'Aviso!',
                content: (
                    <>
                        <div>Número Inicial e Final devem ser diferente de 0!</div>
                        <br />
                    </>
                ),
            });
        } else if (qtdNumero < 0) {
            Modal.error({
                title: 'Aviso!',
                content: (
                    <>
                        <div>Número Inicial é menor que número final!</div>
                        <br />
                    </>
                ),
            });
        } else if (qtdNumero > 50) {
            Modal.confirm({
                title: 'Cancelar?',
                icon: <ExclamationCircleOutlined />,
                content: 'Tem certeza que deseja inutilizar uma faixa de ' + qtdNumero + ' Notas ?',
                okText: 'Sim',
                cancelText: 'Não',
                centered: true,
                onOk() {
                    solicitarInutilizacaoNumeracao(values);
                    fecharModal();
                }
            });
        } else {
            solicitarInutilizacaoNumeracao(values);
        }
    };

    function solicitarInutilizacaoNumeracao(values) {
        let inutilizacao = {
            ser_id: values.ser_id,
            numeroInicial: parseInt(values.nroInicial),
            numeroFinal: parseInt(values.nroFinal),
            justificativa: values.justificativa
        };

        api.post(`NotaFiscal/InutilizarPorFaixa`, inutilizacao).then((dados) => {
            console.log("dados inutilizados", dados);
            if (dados.status === 200) {
                if (dados.data.retorno.cStat == 241) {
                    Modal.error({
                        title: 'Aviso!',
                        content: (
                            <>
                                <div>{`Números solicitados para Inutilização:  ${dados.data.retorno.infInut.nNFIni} até ${dados.data.retorno.infInut.nNFFin}`}</div>
                                <br />
                                <div>{`Série: ${dados.data.retorno.infInut.serie}`}</div>
                                <br />
                                <div>{`Motivo : ${dados.data.retorno.xMotivo}`}</div>
                            </>
                        ),
                    });
                } else {
                    Modal.success({
                        title: 'Inutilização de Número Homologada!',
                        content: (
                            <>
                                <div>{`Números Inutilizados:  ${dados.data.retorno.infInut.nNFIni} até ${dados.data.retorno.infInut.nNFFin}`}</div>
                                <br />
                                <div>{`Série: ${dados.data.retorno.infInut.serie}`}</div>
                                <br />
                                <div>{`Protocolo: ${dados.data.retorno.infInut.nProt}`}</div>
                            </>
                        ),
                    });
                }
            } else {
                Modal.error({
                    title: 'Aviso!',
                    content: (
                        <>
                            <div>Não foi possível realizar a inutilização</div>
                            <br />
                            <div>{JSON.stringify(dados.data)}</div>
                        </>
                    ),
                });
            }
        }, err => {
            Modal.error({
                title: 'Aviso!',
                content: (
                    <>
                        <div>Não foi possível realizar a inutilização</div>
                        <br />
                        <div>{JSON.stringify(err.Message)}</div>
                    </>
                ),
            });
        }).finally(() => {
            fecharModal();
        });
    };

    return (
        <Modal centered
            title="Inutilização de Número de NF-e"
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={() => formulario.submit()}
            okText={
                <>
                    <CheckOutlined /> Solicitar Inutilização
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="formTelefone" form={formulario} onFinish={validarNumeracao}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                            <Form.Item label="Serie" name="ser_id" rules={[{ required: true, message: 'Selecione a Série' }]}>
                                <SelectPaginado url="SerieFiscal/Listar?CodigoModeloDocFiscal=55,65&EmitenteDocumento=0&SomenteAtivos=true" placeholder="Selecione a Série" form={formulario} name="ser_id" conteudo={
                                    serie => (<Select.Option value={serie.ser_id} key={serie.ser_id}>{serie.ser_serie}</Select.Option>)
                                }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={7} lg={7} xl={7}>
                            <Form.Item label="Número Inicial" name="nroInicial" rules={[{ required: true, message: 'Informe o Número Inicial' }]}>
                                <Input placeholder="Informe o Número Inicial" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={7} lg={7} xl={7}>
                            <Form.Item label="Número Final" name="nroFinal" rules={[{ required: true, message: 'Informe o Número Final' }]}>
                                <Input placeholder="Informe o Número Final" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Justificativa para a Inutilização" name="justificativa" rules={[{ required: true, min: 15 }]}>
                                <Input.TextArea placeholder="Informe aqui o motivo da inutilização da NF-e" autoSize={{ minRows: 3, maxRows: 5 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}