import React from "react";
import { Table, Tag } from "antd";

export default function TabDetalheMotorista({ motorista }) {
    return (
        <div className="tabela">
            <Table columns={
                [
                    {
                        title: 'ID',
                        align: 'center',
                        width: 100,
                        render: ({ fun_id }) => (
                            <Tag color="processing">
                                <b>{fun_id}</b>
                            </Tag>
                        )
                    },
                    {
                        title: 'Nome',
                        align: 'center',
                        render: ({ pes_nome }) => (
                            <b>{pes_nome}</b>
                        )
                    }
                ]
            } dataSource={motorista} />
        </div>
    )
}