import React from "react";
import { Table, Tag } from "antd";

export default function TabDetalheVeiculo({ veiculo }) {
    return (
        <div className="tabela">
            <Table columns={
                [
                    {
                        title: 'ID',
                        width: 30,
                        align: 'center',
                        render: ({ vei_id }) => (
                            <Tag color="processing">
                                <b>{vei_id}</b>
                            </Tag>
                        )
                    },
                    {
                        title: 'Descrição',
                        align: 'center',
                        width: 200,
                        render: ({ vei_descricao }) => (
                            <b>{vei_descricao}</b>
                        )
                    },
                    {
                        title: 'Placa',
                        align: 'center',
                        width: 200,
                        render: ({ vei_placa }) => (
                            <b>{vei_placa}</b>
                        )
                    },
                    {
                        title: 'Tipo Rodado',
                        align: 'center',
                        width: 200,
                        render: ({ vei_tiporodado }) => (
                            <b>{vei_tiporodado}</b>
                        )
                    }
                ]
            } dataSource={veiculo} />
        </div>
    )
}