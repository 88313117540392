import React, { useState } from 'react';
import {Row, Button, Result,Form, Card, notification, Drawer, List} from 'antd';
import { useHistory } from "react-router-dom";
import api from '../../services/api';
import { login } from '../../services/auth';

import iconEntidade from '../../assets/i-contato.png';
export default function AtivarNovoEmail() {
  const history = useHistory();
  const [form] = Form.useForm();
  const [ carregando, setCarregando ] = useState(false);
  const [ selecionarEntidade, setSelecionarEntidade ] = useState(false);
  const [ entidadeUsuario, setEntidadesUsuario ] = useState([]);
  
  const onFinish = values => {
    setCarregando(true);
    api.post('/auth', values).then(res => {
      if (res.status === 200) {
        const token = res.data.token;
        login(token);
        console.log('entidade logado');
        //history.push('/home');
      }
    }, err => {
      notification.error({ message: err.response.data.mensagem, description: 'Verifique os dados informados e tente novamente.' });
    }).finally(() => setCarregando(false));
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  function acessarEntidade(entidade) {
    setCarregando(true);
    api.post('/auth/entidade', { empresa_id: entidade.id }).then(res => {
      login(res.data.token);
      
      //history.push('/home');
    }, err => {
      notification.error({ message: 'Erro ao carregar os dados', description: err.response.data.mensagem });
    }).finally(() => setCarregando(false));
  }

  return (
    
    <div>  
      <Row><br/><br/><br/><br/><br/><br/><br/><br/></Row>
      <Result
          status="success"
          title="Email ativado com sucesso!"
          subTitle="Voce pode prosseguir ao sistema ou sair"
          extra={[
             <div> 
            <Button type="primary" key="console" onClick={() =>  history.push('/')}>
              Prosseguir para página de Login
            </Button>      
            </div>
         ]}
      />,
        <Drawer height={400} title="Selecione a entidade que você deseja acessar" placement="bottom" closable onClose={() => setSelecionarEntidade(false)} visible={selecionarEntidade}>
          <List loading={entidadeUsuario.length === 0 || carregando} grid={{ gutter: 16, xs: 1, sm: 2, md: 4, lg:4, xl: 6, xxl: 8 }}  dataSource={entidadeUsuario} renderItem={item => (
            <List.Item>
              <Card hoverable style={{width: 210, height: 270, textAlign: 'center'}} cover={<img height="150px" width="150px" alt="logo" src={!!item.logo ? item.logo : iconEntidade} />} onClick={() => acessarEntidade(item)}>
                <Card.Meta description={[ 
                  <div> 
                    <h3>{item.nomefantasia}</h3>
                  </div>
                ]}/>
              </Card>
            </List.Item>
          )} />
        </Drawer>
    </div>
  );
  
}