import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Select, Button, Table, Collapse, Divider } from "antd";
import { SearchOutlined, ArrowUpOutlined, ArrowDownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import { useStateValue } from '../../state';
import { ModalGerarComissao } from "../../components/modals/";
import { FormatDate, FormatNumber } from "../../ValueObjects";
import { BreadcrumbPage, BotaoAbrirChamado, BotaoIncluirDados, Data } from "../../components";
import { isNotNullOrEmpty } from '../../services/funcoes';
import SelectPaginado from "../../components/selectPaginado";

export default function ConsultaComissao() {
    const [openModal, setOpenModal] = useState(false);
    const [{ manutencao }, dispatch] = useStateValue();
    const [formConsultaComissao] = Form.useForm();
    const [dados, setDados] = useState([]);
    const [detalhesDados, setDetalhesDados] = useState([]);
    const [painelAtivo, setPainelAtivo] = useState([]);
    const [dadosModal, setDadosModal] = useState([]);


    function efetuarPesquisa() {
        let filtro = '';
        filtro += (!!formConsultaComissao.getFieldValue().dataFinal ? '&dataFinal=' + FormatDate(formConsultaComissao.getFieldValue().dataFinal.toDate().toLocaleDateString()) : '&dataFinal=')
        filtro += (!!formConsultaComissao.getFieldValue().dataInicial ? '&dataInicial=' + FormatDate(formConsultaComissao.getFieldValue().dataInicial.toDate().toLocaleDateString()) : '&dataInicial=');
        filtro += (!!formConsultaComissao.getFieldValue().ven_id ? '&vendedores=' + formConsultaComissao.getFieldValue().ven_id : '&vendedores=');
        api.get(`Comissao/ConsultarComissao?` + filtro).then(
            (res) => {
                if (res.status === 200) {
                    setDados(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    useEffect(() => {
        if (dados.length > 0) {
            let indice = 0;
            dados.forEach((vendedor) => {
                vendedor.comissaoVendedores.forEach((comissao) => {
                    comissao.indice = indice;
                    indice++;
                })
            })
        }
    }, [dados])

    function retornaDescTipo(tipo) {
        let retorno = '';
        if (!!tipo || tipo === 0) {
            switch (tipo) {
                case 0:
                    retorno = 'Vendas à Vista';
                    break;
                case 1:
                    retorno = 'Recebimentos Parcelas';

                    break;
                case 3:
                    retorno = 'Devoluções';
                    break;
            }
        }
        return retorno;
    };

    function buscarDadosDetalhados(indice, ven_id) {
        if (isNotNullOrEmpty(indice)) {
            let comissaoVendedores = dados.find(vendedor => { return vendedor.ven_id === ven_id })?.comissaoVendedores;
            let tipo = comissaoVendedores.find(com => { return com.indice === parseInt(indice) })?.fpg_tipoavistaaprazo;
            setPainelAtivo(indice);
            let filtro = '';
            filtro += '&IdVendedor=' + ven_id;
            filtro += (!!formConsultaComissao.getFieldValue().dataFinal ? '&dataFinal=' + FormatDate(formConsultaComissao.getFieldValue().dataFinal.toDate().toLocaleDateString()) : '&dataFinal=')
            filtro += (!!formConsultaComissao.getFieldValue().dataInicial ? '&dataInicial=' + FormatDate(formConsultaComissao.getFieldValue().dataInicial.toDate().toLocaleDateString()) : '&dataInicial=');
            switch (tipo) {
                case 1:
                    api.get(`Comissao/ListaComissaoAprazo?` + filtro).then(
                        (res) => {
                            if (res.status === 200) {
                                setDetalhesDados(res.data);
                            }
                        }
                    ).catch(
                        (erro) => {
                            console.log(erro);
                        }
                    );
                    break;
                case 0:
                    api.get(`Comissao/ListaComissaoAvista?` + filtro).then(
                        (res) => {
                            if (res.status === 200) {
                                setDetalhesDados(res.data);
                            }
                        }
                    ).catch(
                        (erro) => {
                            console.log(erro);
                        }
                    )
                    break;
                case 3:
                    api.get(`Comissao/ListaComissaoDevolucao?` + filtro).then(
                        (res) => {
                            if (res.status === 200) {
                                setDetalhesDados(res.data.dados);
                            }
                        }
                    ).catch(
                        (erro) => {
                            console.log(erro);
                        }
                    );
            };
        } else {
            setPainelAtivo("");
        }
    };

    function abrirModalComissao() {
        setOpenModal(true);
        let dadosTemp = [];
        dadosTemp.dataInicial = formConsultaComissao.getFieldValue().dataInicial;
        dadosTemp.dataFinal = formConsultaComissao.getFieldValue().dataFinal;
        setDadosModal(dadosTemp);
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoAbrirChamado />
                        </Col>
                        <Col>
                            <BotaoIncluirDados onClick={() => abrirModalComissao({})}>
                                GERAR COMISSÃO
                            </BotaoIncluirDados>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div>
                <Form layout="vertical" form={formConsultaComissao} name={formConsultaComissao}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={12} lg={13} xl={15} xxl={15}>
                            <Form.Item label="Vendedores" name="ven_id">
                                <SelectPaginado url="Vendedor/ListarVendedor" placeholder="Selecione os vendedores" multiple={true} form={formConsultaComissao} name="ven_id" conteudo={
                                    ven => (<Select.Option value={ven.ven_id} key={ven.ven_id}>{ven.ven_id} - {ven.pes_nome}</Select.Option>)
                                }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={4} lg={4} xl={3} xxl={3}>
                            <Data label="Data Inicial" name="dataInicial" />
                        </Col>
                        <Col xs={24} sm={8} md={4} lg={4} xl={3} xxl={3}>
                            <Data label="Data Final" name="dataFinal" />
                        </Col>
                        <Col xs={24} sm={8} md={4} lg={3} xl={3} xxl={3} className="t-mob-573">
                            <Button type="primary" block icon={<SearchOutlined />} onClick={() => (efetuarPesquisa())}>
                                Pesquisar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="colCollapseCom tabela">
                {dados.length > 0 && <Row gutter={[8, 8]} className="tb-head">
                    <Col span={9}>
                        Vendedor
                    </Col>
                    <Col span={5} className="text-right">
                        Total de Vendas (R$)
                    </Col>
                    <Col span={5} className="text-right">
                        Percentual de Comissão
                    </Col>
                    <Col span={5} className="text-right">
                        Total de Comissão (R$)
                    </Col>
                </Row>}
                {dados.map((vendedor) => (
                    <div className="m-b-16">
                        <Row gutter={[8, 8]} className="pad-list">
                            <Col span={9}>
                                <b>{vendedor.pes_nome}</b>
                            </Col>
                            <Col span={5} className="text-right">
                                <b>{FormatNumber(vendedor.valorTotalVenda, true)}</b>
                            </Col>
                            <Col span={5}></Col>
                            <Col span={5} className="text-right">
                                <b>{FormatNumber(vendedor.valorTotalComissao, true)}</b>
                            </Col>
                        </Row>
                        <Collapse activeKey={painelAtivo} accordion={true} onChange={(indice) => (buscarDadosDetalhados(indice, vendedor.ven_id))}
                            expandIcon={({ isActive }) => isActive ? <ArrowUpOutlined /> : <ArrowDownOutlined />}>
                            {vendedor.comissaoVendedores.map((comissao) => (
                                <Collapse.Panel header={
                                    <Row gutter={[24, 24]} className="w-100">
                                        <Col span={9}>
                                            {retornaDescTipo(comissao.fpg_tipoavistaaprazo)}
                                        </Col>
                                        <Col span={5} className="text-right">
                                            {FormatNumber(comissao.com_basecalculo, true)}
                                        </Col>
                                        <Col span={5} className="text-right">
                                            {comissao.com_percentual} %
                                        </Col>
                                        <Col span={5} className="text-right p-r-5">
                                            {FormatNumber(comissao.com_valor, true)}
                                        </Col>
                                    </Row>
                                } key={comissao.indice}>
                                    <Row>
                                        <Col span={24}>
                                            <Table columns={[
                                                {
                                                    title: 'Série / Nº da Nota / Parcela',
                                                    align: 'left',
                                                    render: ({ ser_serie, ntf_numero, prc_numeroparcela }) => (
                                                        <div>
                                                            <b>
                                                                {ser_serie || 'Não Informada'} / {ntf_numero || 'Não Informado'} / {prc_numeroparcela || 'Não Informada'}
                                                            </b>
                                                        </div>
                                                    ),
                                                }, {
                                                    title: 'Data',
                                                    render: ({ ntf_dataemissao }) => (
                                                        <div>
                                                            <b>
                                                                {(moment(ntf_dataemissao).format('DD/MM/YYYY'))}
                                                            </b>
                                                        </div>
                                                    )
                                                }, {
                                                    title: 'Base de Cálculo (R$)',
                                                    align: 'right',
                                                    render: ({ mcc_valor, valorTotalNota }) => (
                                                        <div>
                                                            <b>
                                                                {FormatNumber((!!mcc_valor ? mcc_valor : valorTotalNota), true)}
                                                            </b>
                                                        </div>
                                                    )
                                                }, {
                                                    title: 'Valor Comissão (R$)',
                                                    align: 'right',
                                                    render: ({ valorComissao }) => (
                                                        <div>
                                                            <b>
                                                                {FormatNumber(valorComissao, true)}
                                                            </b>
                                                        </div>
                                                    )
                                                }
                                            ]} dataSource={detalhesDados} locale={{
                                                emptyText: (
                                                    <Row>
                                                        <Col span={24}>
                                                            <Divider orientation="center">
                                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                            </Divider>
                                                        </Col>
                                                    </Row>
                                                )
                                            }} />
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                            ))}
                        </Collapse>
                    </div>
                ))}
            </div>
            <ModalGerarComissao exibirModal={openModal} fecharModal={() => setOpenModal(false)} dadosModal={dadosModal} />
        </div>
    );
}