import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, Modal, notification } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { listagemActions, manutencaoActions, selectPaginadoActions } from "../../../actions";
import api from "../../../services/api";
import { FormGW, InputCep, SelectPaginacao } from "../../../components";
import SelectPaginado from "../../selectPaginado";
import { useStateValue } from "../../../state";

export default function ModalEndereco({ form, listaEndereco, exibirModalEndereco, fecharModalEndereco, exibirPais = true, produtorRural = false }) {
    const [{ ui, manutencao, selectPaginado }, dispatch] = useStateValue();
    const [cidade, setCidade] = useState({});
    const [isEstrangeiro, setIsEstrangeiro] = useState(false);

    useEffect(() => {
        if (exibirModalEndereco) {
            let lista = [];
            if (form.getFieldValue().indice >= 0) {
                if (!!form.getFieldValue().pse_id) {
                    lista.push({ name: "pse_id", campo: "pse_id", value: form.getFieldValue().pse_id });
                }
            } else {
                lista.push({ name: "pse_id", campo: "Sigla", value: "BR" });
            }

            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }

    }, [exibirModalEndereco]);

    async function onFinish(values) {
        let lista = [...listaEndereco.dadosEndereco];
        let indice = values.indice;
        if (values.log_logradouro.length <= 3) {
            notification.warning({ message: 'Aviso!', description: 'Logradouro deve conter mais de 3 caracteres!' });
            return false;
        }
        delete values.indice;
        values.pse_nome = !!values.pse_id ? form.getFieldValue().pse_nome : '';

        values.cid_descricao = !!values.cid_nome ? values.cid_nome : form.getFieldValue().cid_descricao;
        values.pee_ativo = true;
        if (listaEndereco.dadosEndereco.length === 0) {
            values.pee_enderecoprincipal = true;
        }
        values.uf = values.est_sigla;
        values.logradouro = values.log_logradouro;
        values.enderecoCompleto = values.log_logradouro + ',' + values.pee_numero + '-' + values.bai_nome + '-' + values.pee_complemento + '-' + values.cep_cep + '-' + values.cid_descricao + '-' + values.est_sigla;
        let tipoEnd = await listarTpEndereco(values.tpe_id);
        values.tpe_descricao = tipoEnd.tpe_descricao
        if (!isEstrangeiro) {
            values.cep_id = cidade.cep_id;
        } else {
            values.bai_id = null;
            values.cep_id = null;
            values.cid_id = null;
            values.uf = "";
        }

        values.pais = await listarPais(values.pse_id);
        if (indice >= 0) {
            lista[indice] = values;
        } else {
            lista.push(values);
        }
        listaEndereco.setDadosEndereco(lista);
        form.resetFields();
        fecharModalEndereco();
    };

    async function listarPais(pseId) {
        let req = await api.get(`Pais/BuscarPorId/${pseId}`);
        if (req.status === 200 && !!req.data) {
            return req.data;
        }
        return {};
    }

    async function listarTpEndereco(tpeId) {
        let req = await api.get(`TipoEndereco/Listar?Filtro=${tpeId}`);
        if (req.status === 200 && !!req.data) {
            return req.data.items[0];
        }
        return {};
    }


    const verificaCep = (cep) => {
        cep = cep?.replaceAll(/\D/g, '');
        if (!!cep) {
            if (cep.length == 8) {
                api.get(`Cidade/BuscaCidadeCep?cep=${cep}`).then(res => {
                    if (res.status === 200) {
                        form.setFieldsValue({ cid_descricao: res.data.length > 0 ? res.data[0].cid_descricao : '' })
                        setCidade(res.data[0]);
                        form.setFieldsValue({ est_sigla: res.data.length > 0 ? res.data[0].est_sigla : '' });
                        form.setFieldsValue({ pse_id: res.data.length > 0 ? res.data[0].pse_id : '' });
                        form.setFieldsValue({ bai_nome: res.data.length > 0 ? res.data[0].bai_nome : '' });
                        form.setFieldsValue({ log_logradouro: res.data.length > 0 ? res.data[0].log_logradouro : '' });
                        let lista = [];
                        if (!!res.data[0].pse_id) {
                            lista.push({ name: "pse_id", campo: "pse_id", value: res.data[0].pse_id });
                        }
                        if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                    }
                }).catch(
                    (erro) => {
                        console.log(erro);
                        form.setFieldsValue({ cep_cep: '' });
                        setFocusCep();
                        notification.warning({ message: 'Aviso!', description: 'Não foi possível realizar consulta no Postmon do Cep informado!' });
                    }
                );
            } else {
                form.setFieldsValue({ cep_cep: '' });
                setFocusCep();;
                notification.warning({ message: 'Aviso!', description: 'Cep informado é inválido!' });
            }
        }
    };

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalEndereco();
            }
        });
    };


    // useEffect(() => {
    //     verificaCep(form.getFieldValue().cep_cep);
    // }, [form.getFieldValue().cep_cep])

    const inputRef = React.createRef();
    useEffect(() => {
        setTimeout(() => {
            setFocusCep();
        }, 500);
    }, [exibirModalEndereco, inputRef.current]);

    function setFocusCep() {
        try {
            if (!!!form.getFieldValue().cep_cep) {
                let element = document.getElementById("cep_cep");
                if (element) {
                    element.focus();

                }
            }
        } catch (error) {
            console.log('error :>> ', error);
        }
    }

    async function alterarStatusEstrangeiro(pse_id) {
        if (!!pse_id) {
            let pais = (await api.get(`Pais/BuscarPorId/${pse_id}`)).data;
            if (!!pais && pais.pse_codigobacen != "1058") {
                setIsEstrangeiro(true);
            } else {
                setIsEstrangeiro(false);
            }
        }
    };

    return (
        <Modal centered
            width={700}
            title="Cadastro de Endereços"
            visible={exibirModalEndereco}
            onCancel={() => {
                form.resetFields();
                onCloseModal();
            }}
            onOk={() => form.submit()}
            okText={
                <>
                    <PlusOutlined /> Adicionar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            destroyOnClose
        >
            <div>
                <FormGW layout="vertical" name="manutencaoEndereco" form={form} onFinish={onFinish} >
                    <Form.Item name="indice" hidden />
                    <Form.Item name="pee_id" hidden />
                    <Form.Item name="pie_id" hidden />
                    <Form.Item name="pee_enderecoprincipal" hidden />
                    <Form.Item name="pee_ativo" hidden />
                    <Row gutter={[8, 0]}>
                        {exibirPais && <Col xs={24} sm={16} md={6} lg={6} xl={6}>
                            <Form.Item label="País" name="pse_id">
                                <SelectPaginacao url="Pais/Listar" placeholder="Informe o País" form={form} selecionarRegUnico="pse_id" nameValue="pse_id" nameLabel="pse_nome" onChangeFunction={(value) => alterarStatusEstrangeiro(value)} conteudo={
                                    pse => (<Select.Option value={pse.pse_id} key={pse.key}>{pse.pse_nome}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>}
                        {!isEstrangeiro && <Col xs={24} sm={8} md={5} lg={5} xl={5}>
                            <InputCep ref={inputRef} label="CEP" name="cep_cep" rules={[{ required: true, message: 'Informe o CEP' }]} onBlur={(cep) => verificaCep(cep.target.value)} onPressEnter={(cep) => verificaCep(cep.target.value)} />
                        </Col>}
                        <Col xs={exibirPais ? 18 : 10} sm={exibirPais ? 18 : 10} md={exibirPais ? 11 : 17} lg={exibirPais ? 11 : 17} xl={exibirPais ? 11 : 17}>
                            <Form.Item label="Cidade" name="cid_descricao">
                                <Input disabled={!isEstrangeiro} placeholder="Informe a Cidade" />
                            </Form.Item>
                        </Col>
                        {!isEstrangeiro && <Col xs={6} sm={6} md={2} lg={2} xl={2}>
                            <Form.Item label="Estado" name="est_sigla">
                                <Input disabled placeholder="UF" />
                            </Form.Item>
                        </Col>}
                    </Row>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
                            <Form.Item label="Endereço" name="log_logradouro" rules={[{ required: true, message: 'Informe o Endereço' }]}>
                                <Input placeholder="Informe o Endereço" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                            <Form.Item label="Bairro" name="bai_nome" rules={[{ required: !isEstrangeiro, message: 'Informe o Bairro.' }]}>
                                <Input placeholder="Informe o Bairro" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                            <Form.Item label="Número" name="pee_numero">
                                <Input placeholder="Informe o Número" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <Form.Item label="Complemento" name="pee_complemento">
                                <Input placeholder="Informe o Complemento" maxLength={60} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                            <Form.Item label="Tipo de Endereço" name="tpe_id" rules={[{ required: true, message: 'Tipo de Endereço' }]}>
                                <SelectPaginado url="TipoEndereco/Listar" placeholder="Informe o Tipo de Endereço" form={form} name="tpe_id" conteudo={
                                    te => (<Select.Option value={te.tpe_id} key={te.tpe_id}>{te.tpe_descricao}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                        {!!produtorRural &&
                            <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={5}>
                                <Form.Item label="Inscrição Estadual" name="pie_inscricaoestadual" rules={[{ required: true, message: 'Informe a Inscrição Estadual do Produtor Rural' }]}>
                                    <Input placeholder="IE Produtor Rural" />
                                </Form.Item>
                            </Col>
                        }
                        <Col span={24}>
                            <Form.Item label="Local de Referência" name="pee_localreferencia">
                                <Input.TextArea placeholder="Informe o Local de Referência" />
                            </Form.Item>
                        </Col>
                    </Row>
                </FormGW>
            </div>
        </Modal>
    );
}