import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Button, Divider, Tag, Form, Table, Modal } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { ModalCfop } from "../../components/modals/";
import api from "../../services/api";

export default function ManutencaoOperacaoFiscal({ listaCfop, setListaCfop }) {

    const [openModal, setOpenModal] = useState(false);
    const [formulario] = Form.useForm();
    const [listaDestinoOperacao, setListaDestinoOperacao] = useState([]);
    const [listaCategoriaOperacao, setListaCategoriaOperacao] = useState([]);

    const selecionarOpcao = (checked, key) => {
        let listaTemp = [...listaCfop];
        listaTemp.filter(item => { return item.key === key })[0].opc_ativo = checked;

        setListaCfop(listaTemp);
    };

    useEffect(() => {
        api.get(`Enum/Listar?nome=DestinoOperacao`).then(res => {
            setListaDestinoOperacao(res.data);
        });
        api.get(`Enum/Listar?nome=Categoria`).then(res => {
            setListaCategoriaOperacao(res.data);
        });

    }, []);

    function adicionarCfop(values) {
        let listaTemp = [...listaCfop];
        if (!!values.key) {
            let index = listaTemp.findIndex(i => i.key === values.key);
            listaTemp.splice(index, 1);
        } else {
            values.key = listaTemp.length + 1;
        }
        values.opc_ativo = true;
        listaTemp.push(values);
        listaTemp.sort((a, b) => a.opc_destinooperacao - b.opc_destinooperacao)
        setListaCfop(listaTemp);
        setOpenModal(false);
    };

    function excluirRegistro(id) {
        Modal.confirm({
            title: 'Deseja deletar este registro?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                let lista = [...listaCfop];
                let index = lista.findIndex(i => i.key === id);
                lista.splice(index, 1);
                setListaCfop(lista);
            }
        });
    }

    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-code.png").default} alt="Código Fiscal de Operação - CFOP" /> Código Fiscal de Operação - CFOP
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Adicione os CFOPs relacionados aqui.
                    </Divider>
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => {
                            formulario.resetFields();
                            setOpenModal(true);
                        }} />
                    </div>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Table dataSource={listaCfop} columns={
                    [
                        {
                            title: 'CFOP',
                            width: 500,
                            render: ({ cfo_cfop, cfo_descricao }) => (
                                <div style={{ width: '500px' }}>
                                    <b>{cfo_cfop} - {cfo_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Destino',
                            align: 'center',
                            width: 75,
                            render: ({ opc_destinooperacao }) => (
                                <div>
                                    {listaDestinoOperacao.filter(x => x.key === opc_destinooperacao)[0]?.value}
                                </div>
                            )
                        },
                        {
                            title: 'Categoria',
                            align: 'center',
                            width: 75,
                            render: ({ opc_categoria }) => (
                                <div>
                                    {listaCategoriaOperacao.filter(x => x.key === opc_categoria)[0]?.value}
                                </div>
                            )
                        },
                        {
                            title: '',
                            align: 'center',
                            width: 65,
                            render: ({ opc_ativo, key }) => (
                                <div>
                                    <Tag.CheckableTag
                                        checked={opc_ativo}
                                        onChange={checked => selecionarOpcao(checked, key)}
                                    >
                                        {opc_ativo ? 'Ativo' : 'Inativo'}
                                    </Tag.CheckableTag>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            align: 'right',
                            key: 'x',
                            width: 65,
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button onClick={() => {
                                                console.log(record);
                                                formulario.setFieldsValue(record);
                                                setOpenModal(true);
                                            }} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Button icon={<DeleteOutlined />} onClick={() => excluirRegistro(record.key)} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } pagination={false} />
            </div>
            <ModalCfop exibirModal={openModal} fecharModal={() => setOpenModal(false)} formulario={formulario} adicionarCfop={adicionarCfop} listaDestinoOperacao={listaDestinoOperacao} listaCategoriaOperacao={listaCategoriaOperacao} />
        </div>
    );

}