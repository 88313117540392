import React from "react";
import { Row, Col, Tag, Divider, Button, Table } from "antd";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";

export default function TabPermissoesGrupo({ listPermissoes, load, handleChangeBloqueioAll }) {

    const handleChangeBloqueioPagina = (key, metodo = null) => {
        let array = [...listPermissoes.listPermissoes];
        let index = array.findIndex(x => x.key == key);
        if (index > -1) {
            if (metodo != null) {
                let idx = array[index].permissoes.findIndex(x => x.metodo == metodo);
                if (idx > -1)
                    array[index].permissoes[idx].acesso = !array[index].permissoes[idx].acesso;
            }
            else {
                array[index].acesso = !array[index].acesso;
                for (let y in array[index].permissoes)
                    array[index].permissoes[y].acesso = array[index].acesso;
            }
            listPermissoes.setListPermissoes(array);
        }
    }

    return (
        <div>
            <Row align="middle" justify="end" gutter={[8, 0]}>
                <Col>
                    <Button type="primary" icon={<CheckOutlined />} onClick={() => handleChangeBloqueioAll(true)}>
                        Ativar Todas as Permissões
                    </Button>
                </Col>
                <Col>
                    <Button icon={<DeleteOutlined />} onClick={() => handleChangeBloqueioAll(false)}>
                        Remover Todas as Permissão
                    </Button>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Table
                    loading={load}
                    columns={[
                        {
                            render: (record, { pagina, acesso, permissoes }) => (
                                <div>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col>
                                            <Tag
                                                className="cursor-pointer"
                                                color={acesso ? 'processing' : 'default'}
                                                onClick={() => handleChangeBloqueioPagina(record.key)}
                                            >
                                                <b>{(!!acesso) ? 'Liberado' : 'Bloqueado'}</b>
                                            </Tag>
                                        </Col>
                                        <Col>
                                            <b className="text-uppercase">{pagina}</b>
                                        </Col>
                                    </Row>
                                    {acesso &&
                                        <div className="m-b-5">
                                            <Divider />
                                            <Row align="middle" gutter={[8, 8]}>
                                                {permissoes.map((item, idx) =>
                                                    <Col key={idx}>
                                                        <Row align="middle" gutter={[8, 0]}>
                                                            <Col>
                                                                {item.metodo}
                                                            </Col>
                                                            <Col>
                                                                <Tag className="cursor-pointer" color={item.acesso ? 'processing' : 'default'} onClick={() => handleChangeBloqueioPagina(record.key, item.metodo)}>
                                                                    <b>{(item.acesso) ? 'Sim' : 'Não'}</b>
                                                                </Tag>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )}
                                            </Row>
                                        </div>

                                    }
                                </div>
                            ),
                        },
                    ]}
                    dataSource={listPermissoes.listPermissoes}
                />
            </div>
        </div>
    );

}