import React, { useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import ManutencaoUsuario from './manutencao';
import { DrawerNav } from '../../components';
import { drawerActions, manutencaoActions, listagemActions } from '../../actions';

export default function DrawerUsuario() {

    const [formUsuario] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    if (manutencao.dados !== null) {
        formUsuario.setFieldsValue(manutencao.dados, {usu_sac: false, usu_suporte: false});
    }
    else {
        formUsuario.setFieldsValue({ usu_ativo: false, usu_usuariopdv: false, usu_admin: false, usu_sac: false, usu_suporte: false, listaFiliais: [] });
    }


    function fecharDrawer() {
        formUsuario.resetFields();
        
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+usu_nome' } });
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
            }
        });
    };

    return (
        <DrawerNav title="Cadastro Usuários"
            width="70%"
            visible={ui.showDrawer}
            limparAoEditar={true}
            fecharDrawer={() => { fecharDrawer() }}
            closeIcon={<MinusOutlined />}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formUsuario.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoUsuario formulario={formUsuario} carregando={setCarregando} aoSalvar={fecharDrawer} />
        </DrawerNav>

    )
}