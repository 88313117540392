import React, { useEffect, useState } from "react";
import { Col, Descriptions, Divider, Row, Steps, Table, Typography } from "antd";
import formatNumber from "../../ValueObjects/formatNumber";
import { Description } from "@material-ui/icons";
import api from "../../services/api";
import { useStateValue } from "../../state";

export default function InfosCotacao({ formulario, dadosPedidos }) {
    const [listaItens, setListaItens] = useState([]);
    const [tipoCobranca, setTipoCobranca] = useState({});
    const [condicaoPagamento, setCondicaoPagamento] = useState({});
    useEffect(() => {
        if (!!dadosPedidos) {
            buscarItens(dadosPedidos.numero);
            buscarTipo(dadosPedidos.codTipoCobranca);
            buscarCondicao(dadosPedidos.codCondicaoPag);
        }
    }, [dadosPedidos]);

    function buscarItens(numeroPedido) {
        api.get(`PedidoItem/Buscar?NumeroPedido=${numeroPedido}`).then(
            res => {
                setListaItens(res.data);
            }
        ).catch(
            err => {
                console.log(err);
            }
        );
    }

    function buscarTipo(codigo) {
        api.get(`TipoCobranca/BuscarUm?Codigo=${codigo}`).then(
            (res) => {
                setTipoCobranca(res.data);
            }
        ).catch(
            err => {
                console.log(err);
            }
        );
    }

    function buscarCondicao(codigo) {
        api.get(`CondicaoPagamento/Buscar?Codigo=${codigo}`).then(
            (res) => {
                setCondicaoPagamento(res.data[0]);
            }
        ).catch(
            err => {
                console.log(err);
            }
        );
    }

    return (<>
        <Row gutter={[8, 0]}>
            <Descriptions>
                <Descriptions.Item label="Cliente">
                    <b>{dadosPedidos?.nomeCliente}</b>
                </Descriptions.Item>
                <Descriptions.Item label="CNPJ">
                    <b>{dadosPedidos?.cnpjCliente}</b>
                </Descriptions.Item>
                <Descriptions.Item label="Endereco">
                    <b>{dadosPedidos?.enderecoCliente}</b>
                </Descriptions.Item>
                <Descriptions.Item label="Cidade">
                    <b>{dadosPedidos?.cidadeCliente}</b>
                </Descriptions.Item>
                <Descriptions.Item label="Cep">
                    <b>{dadosPedidos?.cepCliente}</b>
                </Descriptions.Item>
                <Descriptions.Item label="Estado">
                    <b>{dadosPedidos?.ufCliente}</b>
                </Descriptions.Item>
            </Descriptions>
            <Descriptions>
                <Descriptions.Item label="Tipo Cobrança">
                    <b>{tipoCobranca?.descricao}</b>
                </Descriptions.Item>
                <Description.Item label="Condição Pagamento">
                    <b>{condicaoPagamento?.descricao}</b>
                </Description.Item>
            </Descriptions>
        </Row>
        <Divider>Itens da Cotação</Divider>
        <Row gutter={[8, 24]}>
            <Col span={24}>
                <Table dataSource={listaItens} columns={[
                    {
                        title: 'Código',
                        width: 90,
                        render: ({ codProduto }) => (
                            <div>
                                <b>{codProduto}</b>
                            </div>
                        ),
                    }, {
                        title: 'Descrição',
                        render: ({ produto }) => (
                            <div>
                                <b>{produto?.descricao}</b>
                            </div>
                        ),
                    }, {
                        title: 'Quantidade',
                        render: ({ quantidade }) => (
                            <div>
                                {quantidade}
                            </div>
                        ),
                    }, {
                        title: 'Valor Unitário',
                        render: ({ valorUnitario }) => (
                            <div>
                                {formatNumber(valorUnitario, true, true, 3)}
                            </div>
                        ),
                    }, {
                        title: 'Valor Total',
                        render: ({ quantidade, valorUnitario }) => (
                            <div>
                                {formatNumber(valorUnitario * quantidade, true, true, 3)}
                            </div>
                        ),
                    }
                ]} />
            </Col>
        </Row>
    </>
    );
}
