import React, { useState } from "react";
import moment from 'moment';
import { Row, Col, Button, Switch, Tag, Dropdown, Menu, Modal, notification } from "antd";
import { DollarCircleOutlined, DeleteOutlined, MoreOutlined, PrinterOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from '../../services/api';
import DrawerCondicional from './drawer';
import { useStateValue } from '../../state';
import DrawerDevolucao from './drawerDevolucao';
import { FormatFone, FormatNumber } from "../../ValueObjects";
import { EditarTabela, TabelaDados, Excluir } from '../../components';
import { listagemActions, manutencaoActions, drawerActions } from '../../actions';
import { ModalProdutoCondicional, ModalContatoCliente } from "../../components/modals/";
import DrawerNFExtra from "./drawerNFExtra";


export default function TabCondicionalEntrega({ situacaoVenda, dataPesquisa, imprimirCondicional }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [exibirDrawerDevolucao, setExibirDrawerDevolucao] = useState(false);
    const [dados, setDados] = useState({});
    const [openModalContatoCliente, setOpenModalContatoCliente] = useState(false);
    const [openModalProdutoCondicional, setOpenModalProdutoCondicional] = useState(false);
    const [pessoaId, setPessoaId] = useState();
    const [exibirDrawerNota, setExibirDrawerNota] = useState(false);

    function menuTable(registro) {
        return (<Menu className="text-right">
            <Menu.Item key="1">
                <Excluir hidden={registro.vnc_situacao === 2} type="text" icon={<DeleteOutlined />} onClick={() => { modalExcluirCondicional(registro) }}> Estornar </Excluir>
            </Menu.Item>
            <Menu.Item key="2">
                <Button type="text" icon={<PrinterOutlined />} onClick={() => imprimirCondicional(registro)}> Imprimir </Button>
            </Menu.Item>
        </Menu>)
    };

    function descricaoSituacao(codigo) {
        if (codigo >= 0 && !!situacaoVenda[codigo]) {
            return situacaoVenda[codigo].value;
        }
        return '';
    };

    function deletarCondicional(registro) {
        api.put(`VendaCondicional/Excluir/${registro.vnc_id}`).then(
            (res) => {
                notification.success({ message: `Sucesso!`, description: `Venda Condicional estornada com sucesso!` })
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+vnc_datalancamento' } });
            });
    };

    async function editarCondicional(registro) {
        registro.vnc_datalancamento = moment(registro.vnc_datalancamento);
        registro.vnc_prazoretorno = moment(registro.vnc_prazoretorno);
        registro.vnc_entregaagendada = moment(registro.vnc_entregaagendada);
        let dadosTemp = await api.get(`VendaCondicionalItem/Listar?IdVendaCondicional=${registro.vnc_id}`);
        if (dadosTemp.status === 200) {
            registro.itens = dadosTemp.data;
        }
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: registro } });
    }

    const modalExcluirCondicional = (condicional) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir a condicional do cliente ${condicional.cli_nome} contendo ${condicional.vnc_totalitens} itens com valor total de R$ ${!!condicional.vnc_valortotal ? condicional.vnc_valortotal : '0,00'} ?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                deletarCondicional(condicional);
            }
        });
    };

    function abrirModalItens(record) {
        setDados(record);
        setOpenModalProdutoCondicional(true);
    }

    function abrirModalContatos(record) {
        setPessoaId(record);
        setOpenModalContatoCliente(true);
    }
    function retornaCorSituacao(codigo) {
        let cor = "";
        switch (codigo) {
            case 0:
                cor = "processing";
                break;
            case 1:
                cor = "green";
                break;
            case 2:
                cor = "#87d068";
                break;
            case 3:
                cor = "orange";
                break;
            case 4:
                cor = "red";
                break;
        }
        return cor;
    };

    function alterarCondicionalEntregue(dados, valor) {
        let situacao = dados.vnc_situacao;
        if (valor === true) {
            situacao = 1;
        } else {
            situacao = 0;
        }
        api.post('VendaCondicional/EditarSituacaoCondicional',
            JSON.stringify({ vnc_id: dados.vnc_id, vnc_situacao: situacao }),
            { headers: { 'Content-Type': 'application/json;charset=utf-8' } }
        ).then(
            (res) => {
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+vnc_datalancamento' } });
            });
    };

    return (
        <div className="tabela">
            <TabelaDados url="VendaCondicional/Listar" condicao={(campo) => { return campo.vnc_levarparacliente === true }} colunas={
                [
                    {
                        title: 'Situação',
                        width: 140,
                        align: 'center',
                        render: ({ vnc_situacao }) => (
                            <div>
                                <Tag color={(retornaCorSituacao(vnc_situacao))} className="w-100">
                                    <b>{(descricaoSituacao(vnc_situacao))}</b>
                                </Tag>
                            </div>
                        ),
                    },
                    {
                        title: 'Nº',
                        width: 85,
                        align: 'center',
                        render: ({ vnc_numero }) => (
                            <div>
                                <b>{vnc_numero}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Descrição',
                        render: ({ cli_nome }) => (
                            <div>
                                <b>{cli_nome}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Telefone',
                        width: 110,
                        render: ({ vnc_telefonecliente, pes_id }) => (
                            <div>
                                <Button type="link" onClick={() => abrirModalContatos(pes_id)} className="p-0">
                                    {FormatFone(vnc_telefonecliente)}
                                </Button>
                            </div>
                        ),
                    },
                    {
                        title: 'Data Lançamento',
                        width: 135,
                        render: ({ vnc_datalancamento }) => (
                            <div>
                                <b>{moment(vnc_datalancamento).format("DD/MM/YYYY HH:mm:ss")}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Data Retorno',
                        width: 135,
                        render: ({ vnc_prazoretorno }) => (
                            <div>
                                <b>{moment(vnc_prazoretorno).format("DD/MM/YYYY HH:mm:ss")}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Itens',
                        align: 'right',
                        width: 100,
                        render: ({ vnc_totalitens }, record) => (
                            <div>
                                <Button type="link" onClick={() => abrirModalItens(record)}>
                                    {vnc_totalitens}
                                </Button>
                            </div>
                        ),
                    },
                    {
                        title: 'Total (R$)',
                        align: 'right',
                        width: 150,
                        render: ({ vnc_valortotal }) => (
                            <div>
                                <b className="f-18">{FormatNumber(vnc_valortotal, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 95,
                        fixed: 'right',
                        render: (record, registro) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button hidden={record.vnc_situacao === 2} onClick={() => {
                                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                            setExibirDrawerDevolucao(true);
                                        }} icon={<DollarCircleOutlined />} />
                                    </Col>
                                    <Col>
                                        <EditarTabela hidden={registro.vnc_situacao === 2} registro={registro} onClick={() => editarCondicional(registro)} />
                                    </Col>
                                    <Col>
                                        <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} expandir={
                    (record) => {
                        return (
                            <div>
                                <Row align="middle" gutter={[8, 0]}>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        Entregar em: <b>{record.vnc_enderecoentrega}</b>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                        Melhor Horário para Entrega: <b>{moment(record.vnc_entregaagendada).format("DD/MM/YYYY HH:mm:ss")}</b>
                                    </Col>
                                    <Col xs={24} sm={12} md={4} lg={4} xl={4} className="rg-txt">
                                        <Switch onChange={(valor) => { alterarCondicionalEntregue(record, valor) }} /> Condicional Entregue?
                                    </Col>
                                </Row>
                            </div>)
                    }}
            />

            <DrawerDevolucao exibirDrawerDevolucao={{ exibirDrawerDevolucao, setExibirDrawerDevolucao }} dataPesquisa={dataPesquisa} setExibirDrawerNota={setExibirDrawerNota}/>
            <DrawerNFExtra drawerExtra={{ exibirDrawerNota, setExibirDrawerNota }} />
            <DrawerCondicional />
            <ModalProdutoCondicional exibirModalProdutoCondicional={openModalProdutoCondicional} fecharModalProdutoCondicional={() => setOpenModalProdutoCondicional(false)} dadosItens={dados} situacaoVenda={situacaoVenda} />
            <ModalContatoCliente exibirModalContatoCliente={openModalContatoCliente} fecharModalContatoCliente={() => setOpenModalContatoCliente(false)} />
        </div>
    );
}