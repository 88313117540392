import { Button, Col, Form, Row, Select, notification } from "antd";
import { BotaoAbrirChamado, BreadcrumbPage, Data } from "../../components";
import { useEffect, useState } from "react";
import api from "../../services/api";
import { DownloadOutlined } from "@ant-design/icons";
import { getEmpresa, getLocal, getNomeEmpresa, getToken, linkApi } from "../../services/auth";
import moment from "moment";

export default function DownloadXml() {
    const [formulario] = Form.useForm();
    const [serie, setSerie] = useState([]);


    useEffect(() => {
        carregarDados();
    }, [])

    function carregarDados() {
        api.get('SerieFiscal/Listar?EmitenteDocumento=0&CodigoModeloDocFiscal=55&SomenteAtivos=true').then(
            res => {
                setSerie(res.data?.items);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    function download() {
        let form = formulario.getFieldsValue();
        if (!!!form.dataInicial || !!!form.dataFinal) {
            return notification.warning({ message: "Aviso!", description: "Filtros de datas não preenchidos!" });
        }
        let req = {
            emp_id: getEmpresa(),
            dataInicial: moment(form.dataInicial).format('YYYY-MM-DD'),
            dataFinal: moment(form.dataFinal).format('YYYY-MM-DD'),
            locais: [getLocal()],
        }


        if (!!form.modeloNota) req.modeloNota = [form.modeloNota];
        if (!!form.serieFiscal) req.serieFiscal = [form.serieFiscal];

        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getToken()
        }

        fetch(`${linkApi}Contador/DownloadLoteXmlNfe`, { headers, ...{ method: 'POST', body: JSON.stringify(req) } })
            .then(response => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                return response.blob();
            })
            .then(
                res => {
                    let nomeEmpresa = getNomeEmpresa();
                    let filename = "ArquivosXML-" + nomeEmpresa.replaceAll(" ", "_") + ".zip";
                    let objectUrl = URL.createObjectURL(res);
                    let link = document.createElement('a');
                    link.href = objectUrl;
                    link.download = filename;
                    link.click();
                    window.URL.revokeObjectURL(link.href);
                    notification.success({ description: "Download realizado com sucesso!", message: 'Aviso!' });
                }
            ).catch(
                erro => {
                    let msg = !!erro && !!erro.message ? erro.message : "Não foi possível realizar o download!";
                    notification.warning({ description: msg, message: 'Aviso!' });
                }
            );
    }

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-bom-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoAbrirChamado />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form layout="vertical" form={formulario} >
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={12} md={5} lg={4} xl={3}>
                        <Data label="Data Inicial" name="dataInicial" />
                    </Col>
                    <Col xs={24} sm={12} md={5} lg={4} xl={3}>
                        <Data label="Data Final" name="dataFinal" />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={10} xxl={12}>
                        <Form.Item label="Modelo Nota Fiscal" name="modeloNota">
                            <Select
                                mode="multiple"
                                allowClear
                                placeholder="Selecione os Modelos de Nota Fiscal"
                            >
                                <Select.Option key={1} value={55}>NF-e</Select.Option>
                                <Select.Option key={2} value={65}>NFC-e</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={8} xxl={6}>
                        <Form.Item label="Serie Fiscal" name="serieFiscal">
                            <Select
                                allowClear
                                mode="multiple"
                                placeholder="Selecione uma Série"
                            >
                                {serie.map((ser) => (
                                    <Select.Option key={ser.ser_id} value={ser.ser_id}>{ser.ser_serie}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" justify="center">
                    <Col>
                        <Button type="primary" size="large" icon={<DownloadOutlined />} onClick={download}>
                            Download de XMLs das NF-e/ NFC-e
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}