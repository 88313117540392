import React, { useEffect, useState } from "react";
import moment from 'moment';
import { Row, Col, Descriptions, Typography, Modal, Divider } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { useStateValue } from '../../../state';
import { FormatNumber } from "../../../ValueObjects";

export default function ModalDetalhePedido({ exibirModalDetalhesPedido, fecharModalDetalhesPedido, ped_id }) {
    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [dadosPedido, setDadosPedido] = useState({ pedidoItem: [], pedidoFormaPgto: [] });

    const onCloseModal = () => {
        fecharModalDetalhesPedido();
    };

    useEffect(() => {
        if (exibirModalDetalhesPedido && !!ped_id) {
            carregarItensPedido(ped_id);
        }
    }, [exibirModalDetalhesPedido]);

    function carregarItensPedido(ped_id) {
        api.get(`Pedido/BuscaPedido?IdPedido=${ped_id}`).then(
            (res) => {
                if (!!res.data) {
                    setDadosPedido(res.data)
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function formataCpf(value) {
        if (!!value) {
            return value.replace(/\D/g, '')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1')
        }
    };

    function formataCnpj(value) {
        if (!!value) {
            return value.replace(/^(\d{2})(\d)/, "$1.$2")
                .replace(/^(\d{2})\.(\d{3})(\d)/, "$1 $2 $3")
                .replace(/\.(\d{3})(\d)/, ".$1/$2")
                .replace(/(\d{4})(\d)/, "$1-$2")
        }
    };

    function retornaFrete() {
        let totFrete = 0;
        dadosPedido.pedidoItem.forEach((item) => {
            totFrete += parseFloat(item.pdi_valorfrete);
        });
        return totFrete;
    };

    function retornaValorTotal() {
        let valorTotal = 0;
        dadosPedido.pedidoItem.forEach((item) => {
            valorTotal += parseFloat(item.pdi_valortotal) + parseFloat(item.pdi_valorfrete);
        });
        return valorTotal;
    }

    return (
        <Modal centered
            title="Detalhes Pedido"
            visible={exibirModalDetalhesPedido}
            onCancel={onCloseModal}
            footer={null}
            width={920}
            maskClosable={false}
        >
            <div>
                <Row>
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item label="Nº">
                                <b>{dadosPedido.ped_id}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Nº do Pedido">
                                <b>{dadosPedido.ped_numero}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="CPF/CNPJ Cliente">
                                <b> {!!dadosPedido.pef_cpf ? formataCpf(dadosPedido.pef_cpf) : formataCnpj(dadosPedido.pej_cnpj)}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item label="Cliente">
                                <b>{!!dadosPedido.loc_id_transferencia ? dadosPedido.loc_descricao : dadosPedido.pes_nomecliente}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item label="Endereço">
                                <b>{dadosPedido.pes_endereco}, {dadosPedido.pes_nroestabelecimento}, {dadosPedido.pes_bairro}, {dadosPedido.pes_cidade}, {dadosPedido.pes_uf}, {dadosPedido.pes_cep}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item label="Transportador">
                                <b>{dadosPedido.pes_nometransportador}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Vendedor">
                                <b>{dadosPedido.pes_nomevendedor}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item label="Observação">
                                <b>{!!dadosPedido.ped_observacao ? dadosPedido.ped_observacao : 'Não informado'}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Frete (R$)">
                                <b>{FormatNumber(retornaFrete(), true)}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item label="Data de Inclusão">
                                <b>{!!dadosPedido.ped_datainclusao ? moment(dadosPedido.ped_datainclusao).format('DD/MM/YYYY') : 'Não informado'}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Data de Conclusão">
                                <b>{!!dadosPedido.ped_dataprevisaoentrega ? moment(dadosPedido.ped_dataprevisaoentrega).format('DD/MM/YYYY') : 'Não informado'}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Validade">
                                <b>{!!dadosPedido.ped_datavalidade ? moment(dadosPedido.ped_datavalidade).format('DD/MM/YYYY') : 'Não informado'}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item label="Total (R$)">
                                <b>{FormatNumber(retornaValorTotal(), true)}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row className="m-t-8">
                    <Col span={24}>
                        <Typography.Title level={4} className="m-b-0">
                            <img src={require("../../../assets/i-produto.png").default} alt="Itens" /> Itens
                        </Typography.Title>
                    </Col>
                </Row>
                {dadosPedido.pedidoItem.map((itens) => (
                    <div>
                        <Divider orientation="left" plain className="m-t-5 m-b-5" />
                        <Row align="middle">
                            <Col>
                                <Descriptions size="small">
                                    <Descriptions.Item>
                                        <b>{itens.pro_descricao}</b>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                        <Row align="middle">
                            <Col>
                                <Descriptions size="small">
                                    <Descriptions.Item label="Qtde.">
                                        <b>{parseInt(itens.pdi_quantidade)}</b>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Valor Unit. (R$)">
                                        <b>{FormatNumber(itens.pdi_valorunitario, true)}</b>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Total (R$)">
                                        <b>{FormatNumber(itens.pdi_valortotal, true)}</b>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Col>
                        </Row>
                    </div>
                ))}
                <Row className="m-t-8">
                    <Col span={24}>
                        <Typography.Title level={4}>
                            <img src={require("../../../assets/i-financeiro.png").default} alt="Forma de Pagamento" /> Forma de Pagamento
                        </Typography.Title>
                    </Col>
                </Row>
                {dadosPedido.pedidoFormaPgto.map(formaPagamento => (
                    <div>
                        <Divider orientation="left" plain className="m-t-0 m-b-5" />
                        <Row align="middle" gutter={[5, 0]}>
                            <Col>
                                {formaPagamento.fpg_descricao} / {formaPagamento.cpg_descricao} (R$):
                            </Col>
                            <Col>
                                <b>{FormatNumber(formaPagamento.pfp_valor, true)}</b>
                            </Col>
                        </Row>
                    </div>
                ))}
            </div>
        </Modal>
    );
}