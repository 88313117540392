import React, { useEffect } from "react";
import { Row, Col, Button, Modal, Table, Divider } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from '../../services/api';
import { useStateValue } from '../../state';
import { listagemActions, manutencaoActions } from "../../actions";

export default function Produto({ dados }) {

    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '+pro_descricao' } });
    }, [])

    function excluirRegistro(produto) {
        api.post(`produto/excluir?idProduto=${produto.pro_id}`).then((res) => {
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        }, (erro) => {
            console.error(erro);
            modalInativarProduto(produto);
        })
    };

    function inativarProduto(produto) {
        api.post(`produto/inativarProduto?idProduto=${produto.pro_id}`).then((res) => {
            if (res.status === 200)
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '+pro_descricao' } });
        });
    };

    const modalExcluirProduto = (produto) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o produto ${produto.pro_descricao} ?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(produto);
            }
        });
    };

    const modalInativarProduto = (produto) => {
        Modal.confirm({
            title: 'Não foi possível excluir o produto!',
            icon: <ExclamationCircleOutlined />,
            content: `Deve conter referências com outros registros,
            Deseja inativar o produto ${produto.pro_descricao} ?`,
            okText: 'Inativar',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                inativarProduto(produto);
            }
        });
    };


    return (
        <div className="tabela">
            <Table dataSource={dados} columns={[
                {
                    title: 'Produtos',
                    render: ({ pro_descricao, pro_observacao, gru_nome, sgp_nome, orm_id, orm_descricao, ncm_codigo, pro_fci, ump_descricao, tpi_descricao, pis_descricao, nat_descricao, mar_nome }) => (
                        <div>
                            <Row align="middle" gutter={[16, 8]}>
                                <Col>
                                    <b>{pro_descricao}</b>
                                </Col>
                            </Row>
                            <Row align="middle" gutter={[16, 8]}>
                                {gru_nome !== null &&
                                    <Col>
                                        Grupo: <b>{gru_nome || 'Não Informado'}</b>
                                    </Col>
                                }
                                {sgp_nome !== null &&
                                    <Col>
                                        Subgrupo: <b>{sgp_nome || 'Não Informado'}</b>
                                    </Col>
                                }
                                <Col>
                                    CST Origem: <b>{orm_id} - {orm_descricao}</b>
                                </Col>
                                <Col>
                                    NCM: <b>{ncm_codigo}</b>
                                </Col>
                                <Col>
                                    FCI: <b>{pro_fci || 'Não Informado'}</b>
                                </Col>
                            </Row>
                            <Row align="middle" gutter={[16, 8]}>
                                <Col>
                                    Unidade de Medida: <b>{ump_descricao}</b>
                                </Col>
                                <Col>
                                    Tipo de Produto para SPED: <b>{tpi_descricao}</b>
                                </Col>
                                {pis_descricao !== null &&
                                    <Col>
                                        Tributação Pis/Cofins: <b>{pis_descricao}</b>
                                    </Col>
                                }
                                {nat_descricao !== null &&
                                    <Col>
                                        Natureza da Receita Pis/Cofins: <b>{nat_descricao}</b>
                                    </Col>
                                }
                                {mar_nome !== null &&
                                    <Col>
                                        Marca: <b>{mar_nome}</b>
                                    </Col>
                                }
                            </Row>
                            {pro_observacao !== null &&
                                <Row align="middle" gutter={[16, 8]}>
                                    <Col>
                                        Observações: <b>{{ pro_observacao }}</b>
                                    </Col>
                                </Row>
                            }
                        </div>
                    ),
                }/*,
                {
                    title: 'Ações',
                    dataIndex: '',
                    key: 'x',
                    align: 'center',
                    width: 65,
                    fixed: 'right',
                    render: (produto) => (
                        <div>
                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                <Col>
                                    <Button icon={<EditOutlined />} />
                                </Col>
                                <Col>
                                    <Button onClick={() => modalExcluirProduto(produto)} icon={<DeleteOutlined />} />
                                </Col>
                            </Row>
                        </div>
                    ),
                },*/
            ]} locale={{
                emptyText: (
                    <Row>
                        <Col span={24}>
                            <Divider orientation="center">
                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                            </Divider>
                        </Col>
                    </Row>
                )
            }} scroll={{ x: 900 }}/>
        </div>
    );

}