import React, { useEffect, useState } from "react";
import { Row, Col, Tabs, Tag, Modal, Divider, Table } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import api from '../../../services/api';
import { FormatFone } from "../../../ValueObjects";

export default function ModalContatoCliente({ exibirModalContatoCliente, fecharModalContatoCliente, PessoaId }) {

    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [dados, setDados] = useState({});

    const onCloseModal = () => {
        fecharModalContatoCliente();
    };
    useEffect(() => {
        BuscarDadosCliente(PessoaId);
    }, [exibirModalContatoCliente]);

    async function BuscarDadosCliente(IdPessoa) {
        if (!!IdPessoa) {
            var dadosCliente;
            await api.get(`Cliente/BuscarByIdPessoa/${IdPessoa}`).then(
                (res) => {
                    if (res.status === 200) {
                        if (!!res.data) {
                            dadosCliente = res.data;
                            setDados(res.data)
                        }
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            );
        }
    };
    return (
        <Modal centered
            title="Contatos Cliente"
            visible={exibirModalContatoCliente}
            onCancel={onCloseModal}
            footer={null}
            maskClosable={false}
            width={680}
        >
            <div>
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Telefones" key="1">
                        <div className="tabela mt-zero">
                            <Table dataSource={dados.telefones} columns={
                                [
                                    {
                                        title: 'Telefone',
                                        render: ({ psc_numero }) => (
                                            <div>
                                                <b>{FormatFone(psc_numero) || 'Não Informado'}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Descrição',
                                        render: ({ tpt_descricao }) => (
                                            <div>
                                                <b>{tpt_descricao}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Ramal',
                                        render: ({ psc_ramal }) => (
                                            <div>
                                                <b>{psc_ramal}</b>
                                            </div>
                                        ),
                                    },
                                ]
                            } locale={{
                                emptyText: (
                                    <Row>
                                        <Col span={24}>
                                            <Divider orientation="center">
                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                            </Divider>
                                        </Col>
                                    </Row>)
                            }} />
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="E-mails" key="2">
                        <div className="tabela mt-zero">
                            <Table dataSource={dados.emails} columns={
                                [
                                    {
                                        title: 'E-mail',
                                        render: ({ pem_email }) => (
                                            <div>
                                                <Row align="middle" gutter={[0, 8]}>
                                                    <Col span={8}>
                                                        <b>{pem_email}</b>
                                                    </Col>
                                                    <Col>
                                                        <Tag color="processing">
                                                            <b>E-mail principal</b>
                                                        </Tag>
                                                    </Col>
                                                    <Col>
                                                        <Tag>
                                                            <b>NF-e</b>
                                                        </Tag>
                                                    </Col>
                                                    <Col>
                                                        <Tag>
                                                            <b>Cobrança</b>
                                                        </Tag>
                                                    </Col>
                                                    <Col>
                                                        <Tag>
                                                            <b>Promoção</b>
                                                        </Tag>
                                                    </Col>
                                                    <Col>
                                                        <Tag>
                                                            <b>Vencimento de Parcela</b>
                                                        </Tag>
                                                    </Col>
                                                    <Col>
                                                        <Tag>
                                                            <b>Boleto</b>
                                                        </Tag>
                                                    </Col>
                                                </Row>

                                            </div>
                                        ),
                                    },
                                ]
                            } locale={{
                                emptyText: (
                                    <Row>
                                        <Col span={24}>
                                            <Divider orientation="center">
                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                            </Divider>
                                        </Col>
                                    </Row>)
                            }} />
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Outros Contatos" key="3">
                        <div className="tabela mt-zero">
                            <Table dataSource={dados.pessoaoutrocontatos} columns={
                                [
                                    {
                                        title: 'Outros Contatos',
                                        render: ({ toc_descricao, out_descricao }) => (
                                            <div>
                                                <Row align="middle" justify="center" gutter={[8, 0]}>
                                                    <Col>
                                                        <b>{toc_descricao}</b>
                                                    </Col>
                                                    <Col>
                                                        <b>{out_descricao}</b>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ),
                                    },
                                ]
                            } locale={{
                                emptyText: (
                                    <Row>
                                        <Col span={24}>
                                            <Divider orientation="center">
                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                            </Divider>
                                        </Col>
                                    </Row>)
                            }} />
                        </div>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </Modal>
    );
}