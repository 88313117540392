import React, { useEffect, useState } from "react";
import { Col, Descriptions, Form, Input, Modal, notification, Row } from "antd";
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { FormatNumber } from "../../../ValueObjects";
import moment from "moment";
import { getLocal } from "../../../services/auth";
import api from "../../../services/api";

export default function ModalCancelamentoMdfe({ dadosMdfe, exibirModal, fecharModal }) {
    const [form] = Form.useForm();
    const [carregando, setCarregando] = useState(false);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
            }
        });
    };

    const realizarCancelamento = () => {
        let motivo = '';
        let dados = { ...dadosMdfe };
        dados.justificativa = form.getFieldValue().justificativa;
        if (!dados.justificativa || dados.justificativa.length < 15) {
            notification.warning({ message: 'Aviso!', description: 'A justificativa deve ter no mínimo 15 caracteres' });
            return false;
        }

        setCarregando(true);
        api.post(`ManifestoEletronico/CancelarMdfe`, dados).then(
            res => {
                let _cStat = res.data.cStat;
                let motivo = _cStat + " - " + res.data.xMotivo;
                if (_cStat == 135 || _cStat == 155 || _cStat == 101 || _cStat == 573 || _cStat == 218) {
                    notification.success({ message: `MDF-e Nº: ${dados.mdf_numero} cancelada com sucesso !`, description: motivo });
                } else {
                    notification.warning({ message: `Não foi possível efetuar o cancelamento da MDF-e Nº: ${dados.mdf_numero} !`, description: motivo });
                }
            }
        ).catch(
            erro => {
                notification.warning({ description: `Não foi possível efetuar o cancelamento da MDF-e Nº: ${dados.mdf_numero} !`, message: 'Aviso' });
                setCarregando(false);
            }
        ).finally(
            () => {
                form.resetFields();
                setCarregando(false);
                fecharModal();
            }
        );
    }

    return (
        <Modal centered
            title="Cancelamento de MDF-e"
            visible={exibirModal}
            confirmLoading={carregando}
            onCancel={onCloseModal}
            width={650}
            onOk={realizarCancelamento}
            okText={
                <>
                    <CheckOutlined /> Realizar Cancelamento
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            destroyOnClose
        >
            <Form layout="vertical" form={form}>
                <Descriptions size="small">
                    <Descriptions.Item label="Série">
                        <b> {dadosMdfe.ser_serie} </b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Chave do MDF-e">
                        <b> {dadosMdfe.mdf_chavemdfe} </b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                    <Descriptions.Item label="Nº do MDFe">
                        <b> {dadosMdfe.mdf_numero} </b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Data de Emissão">
                        <b> {moment(dadosMdfe.mdf_dataemissao).format('DD-MM-YYYY')} </b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                    <Descriptions.Item label="Veículo">
                        <b> {dadosMdfe.vei_descricao} </b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Placa do Veículo">
                        <b> {dadosMdfe.vei_placa} </b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions>
                    <Descriptions.Item label="Quantidade de Documentos">
                        <b> {dadosMdfe.quantidadedocs} </b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Peso bruto">
                        <b> {dadosMdfe.pesobruto} </b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Valor total (R$)">
                        <b> {FormatNumber(dadosMdfe.valortotalmdfe, true)} </b>
                    </Descriptions.Item>
                </Descriptions>
                <Row className="m-t-8">
                    <Col span={24}>
                        <Form.Item label="Motivo do Cancelamento" name="justificativa" rules={[{ required: true, message: 'Informe a Justificativa do Cancelamento' }]} >
                            <Input.TextArea placeholder="Informe aqui o motivo do cancelamento do MDF-e" minLength={15} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}