import React, { useState } from "react";
import { Row, Col, Input, Modal, Table, Tag, Divider, Button } from "antd";
import { ExclamationCircleOutlined, ArrowRightOutlined } from "@ant-design/icons";

export default function ModalEditarCFOP({ exibirModal, fecharModal, aoSelecionar, listaCFOP }) {

    const [filtro, setFiltro] = useState("");

    return (
        <Modal centered
            title="Editar CFOP do Item"
            visible={exibirModal}
            width={700}
            onCancel={() => fecharModal()}
            footer={null}
        >
            <div className="tabela">
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Input.Search placeholder="Pesquisar por CFOP ..." onSearch={(value) => setFiltro(value.trim())} allowClear/>
                    </Col>
                </Row>
                <Table dataSource={!!filtro ? listaCFOP.listaCFOP.filter((cfop) => { return cfop.cfo_cfop.toString().indexOf((filtro).toUpperCase()) >= 0 || cfop.cfo_descricao.toString().toUpperCase().indexOf((filtro).toUpperCase()) >= 0 }) : listaCFOP.listaCFOP} columns={[
                    {
                        title: 'CFOP',
                        width: 75,
                        render: ({ cfo_cfop }) => (
                            <div>
                                <Tag color="processing" className="w-75">
                                    <b>{cfo_cfop}</b>
                                </Tag>
                            </div>
                        ),
                    }, {
                        title: 'Descrição',
                        render: ({ cfo_descricao }) => (
                            <div className="white-space-normal"> 
                                {cfo_descricao}
                            </div>
                        ),
                    },
                    {
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        fixed: 'right',
                        width: 85,
                        render: ({ cfo_cfop }) => (
                            <div>
                                <Button onClick={() => { aoSelecionar(cfo_cfop) }}>
                                    Selecione <ArrowRightOutlined />
                                </Button>
                            </div>
                        ),
                    },
                ]} locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                </Divider>
                            </Col>
                        </Row>
                    )
                }} />
            </div>
        </Modal>
    );
}