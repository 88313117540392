import React, { useEffect, useState } from 'react';
import { notification } from 'antd';

import api from '../../services/api';
import { useStateValue } from '../../state';
import { listagemActions } from '../../actions';
import { EditorCamposPersonalizados, FormGW } from '../../components';

export default function CamposPersonalizados({formulario, carregando, aoSalvar, tipoEntidade}) {
    const [{ listagem, manutencao }, dispatch] = useStateValue();
    const [ elementosFormulario, setElementosFormulario ] = useState([]);

    const salvarManutencao = values => {
        carregando(true);
        console.log("valores Salvar: "+{values});
        let elementosOrdem=[]
        elementosFormulario.map((elemento, indice) =>{
            if (elemento.cap_ordem != (indice+1)){
                elemento.cap_ordem = (indice+1)
                !!elemento.status==false?elemento.status = 1 : elemento.status = elemento.status
            }
            elemento.cap_tipoentidade = tipoEntidade;
            elementosOrdem.push(elemento)
        })
        console.log({elementosOrdem});
        api.post(`CamposPersonalizados/Salvar`, elementosOrdem).then(
            res => {
                notification.success({ message: 'Aviso', description: `Campos Personalizados Salvos com sucesso!` });
            }
        ).catch(
            erro => {
                console.log(erro.response.status);
                notification.error({ message: 'Erro ao Gravar Campos Personalizados.', description: JSON.stringify(erro.response.status == 500?`Campo personalizado possui vínculo com outra informação! `:erro.response) 
            });
                carregando(false);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: listagem.ordem } });
                carregando(false);
                aoSalvar();
        });
    };

    useEffect(() =>  setElementosFormulario(manutencao.dados),[!!manutencao.dados]);

    return (
        <div className="pages-col personalize perso-cadastro">
            <FormGW layout="vertical" form={formulario} name="manutencaoFormulario" onFinish={salvarManutencao}>
                <EditorCamposPersonalizados elementosFormulario={elementosFormulario} setElementosFormulario={setElementosFormulario} tipoEntidade={tipoEntidade} />
            </FormGW>
        </div>
    );
}