import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";

import { DrawerNav } from '../../components';
import ImportarOperacao from './importarOperacao';

export default function DrawerOperacaoFiscalImportar({  showDrawer, onClose }) {

    const [formOperacaoFiscal] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const {showImportar, setShowImportar } = showDrawer;

    function fecharDrawer() {
        formOperacaoFiscal.resetFields();
        onClose(); 
    };

    const handleOnClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <DrawerNav title="Importar Operações Fiscais"
            width="70%"
            visible={showDrawer}
            closeIcon={<MinusOutlined />}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={handleOnClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formOperacaoFiscal.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ImportarOperacao formulario={formOperacaoFiscal} carregando={setCarregando} aoSalvar={fecharDrawer} />
        </DrawerNav>
    )
}