import React, { useState } from 'react';
import { Form } from "antd";
import { MinusOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import { DrawerNav } from '../../components';
import { drawerActions } from '../../actions';
import ServidorImpressao from './servidorImpressao';

export default function DrawerServidorImpressao({ servidor }) {

    const [formImprimirEtiqueta] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();

    if (!!manutencao.dados) formImprimirEtiqueta.setFieldsValue(manutencao.dados);
    else {
        //formImprimirEtiqueta.resetFields();
    }

    const [carregando, setCarregando] = useState(false);

    function fecharDrawer() {
        formImprimirEtiqueta.resetFields();
        dispatch({ type: drawerActions.CHANGE, data: { showDrawerExtra: false } });
    }

    return (

        <DrawerNav title="Configurações do Servidor de Impressão"
            width="70%"
            visible={ui.showDrawerExtra}
            closeIcon={<MinusOutlined />}
            fecharDrawerExtra={() => {
                formImprimirEtiqueta.resetFields();
                dispatch({ type: drawerActions.CHANGE, data: { showDrawerExtra: false } });
            }}>
            <ServidorImpressao formulario={formImprimirEtiqueta} carregando={setCarregando} aoSalvar={fecharDrawer} servidor={servidor} />
        </DrawerNav>

    )
}