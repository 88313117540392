import React, { useEffect, useState } from "react";
import { Form, Row, Col, Select, Input, Steps, notification, Button, Tooltip } from "antd";
import { UserAddOutlined } from "@ant-design/icons";

import api from "../../services/api";
import moment from "moment";
import { useStateValue } from "../../state";
import SelectPaginado from "../../components/selectPaginado";
import { MaskFormat } from "../../ValueObjects";
import { Data, InputCpfCnpj, InputPhone } from "../../components";
import { TabItens, TabFaturamento } from "./tabPage";
import { validaForm } from "../../services/funcoes";

export default function ManutencaoOrcamento({ formulario, aoSalvar, vendedor, pages, proximaPag }) {
    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [pessoaFisica, setPessoaFisica] = useState(true);
    const [listaProduto, setListaProduto] = useState([]);
    const [orcamentoFormaPagamento, setOrcamentoFormaPagamento] = useState([])
    const [permiteValidar, setPermiteValidar] = useState(true);
    const [clienteSemCadastro, setClienteSemCadastro] = useState(false);
    const [dadosCliente, setDadosCliente] = useState({});
    const listaValidacoesDest = []

    useEffect(() => {
        if (!!manutencao.dados) {
            if (!!manutencao.dados.orcamentoFormaPagamento) {
                setOrcamentoFormaPagamento(manutencao.dados.orcamentoFormaPagamento);
                carregarDados();
            }
            manutencao.dados.orc_datainclusao = moment(manutencao.dados.orc_datainclusao);
            manutencao.dados.orc_datavalidade = moment(manutencao.dados.orc_datavalidade);
            let record = manutencao.dados;
            if (!!manutencao.dados.orc_id && listaProduto.length === 0) {
                carregarDados(record);
                buscarDadosOrcamento(record);
            }
            if (!!manutencao.dados.pef_cpf) {
                setPessoaFisica(true);
            }
            else if (!!manutencao.dados.pej_cnpj) {
                setPessoaFisica(false);
            }
            if (manutencao.dados.cli_id === null) {
                setClienteSemCadastro(true)
            }
            setDadosCliente(manutencao.dados);
            formulario.setFieldsValue(manutencao.dados);
            formulario.setFieldsValue({ pes_id: manutencao.dados.pes_nome })
            formulario.setFieldsValue({ cpf: manutencao.dados.pef_cpf })
            formulario.setFieldsValue({ cnpj: manutencao.dados.pej_cnpj })
            formulario.setFieldsValue({ orc_numerotelefone: manutencao.dados.pes_telefone})
        }
        if (!!manutencao.dados && !!manutencao.dados.orc_datainclusao) {
            formulario.setFieldsValue({ orc_datainclusao: (manutencao.dados.orc_datainclusao) });
        } else if (!!!formulario.getFieldsValue().orc_datainclusao) {
            formulario.setFieldsValue({ orc_datainclusao: moment(new Date()) });
            formulario.setFieldsValue({ orc_datavalidade: moment(new Date()).add(1, 'days') });
        }
    }, [manutencao.dados])

    useEffect(() => {
        if ((!!proximaPag.proximaPag || proximaPag.proximaPag === 0) && permiteValidar) {
            validarTrocaPg(proximaPag.proximaPag);
        } else {
            setPermiteValidar(true);
        }
    }, [proximaPag.proximaPag])

    const informarCliente = () => {
        setClienteSemCadastro(!clienteSemCadastro);
        if (!clienteSemCadastro) {
            formulario.setFieldsValue({ cpf: null, cnpj: null, pes_nome: null, pes_id: null })
        } 
    };

    function carregarDados(record) {
        api.get(`OrcamentoItem/DadosItem?orcamento=${record.orc_id}`).then(
            (res) => {
                let itens = res.data;
                setListaProduto(itens);
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };

    function buscarDadosOrcamento(record) {
        api.get(`OrcamentoFormaPagamento/Listar?orcamento=${record.orc_id}`).then(
            res => {
                let key = 1;
                res.data.forEach((itens) => {
                    itens.key = key++;
                })
                setOrcamentoFormaPagamento(res.data);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    async function retornaDadosCliente(dados) {
        if (!!dados) {
            let clienteSelecionado = (await api.get(`Cliente/BuscarByIdPessoa/${dados}`)).data;
            if (!!clienteSelecionado.pef_cpf) {
                setPessoaFisica(true);
                formulario.setFieldsValue({ cpf: clienteSelecionado.pef_cpf, cli_id: clienteSelecionado.cli_id });
            } else if (!!clienteSelecionado.pej_cnpj) {
                setPessoaFisica(false);
                formulario.setFieldsValue({ cnpj: clienteSelecionado.pej_cnpj, cli_id: clienteSelecionado.cli_id });
            }
            await api.get(`PessoaTelefone/ListaTelefone?IdPessoa=` + dados).then(
                (res) => {
                    if (res.status === 200) {
                        const telefonePrincipal = res.data.find(telefone => telefone.psc_principal === true);
                        if (telefonePrincipal) {
                            formulario.setFieldsValue({ orc_numerotelefone: telefonePrincipal.psc_numero });
                        }
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            )
        }
    }

    function validarTrocaPg(proxPagina) {
        let paginaAtual = pages.tagPages;
        let listaValidacoes = [];

        if (paginaAtual < proxPagina) {
            switch (paginaAtual) {
                case 0:
                    listaValidacoes = listaValidacoesDest;
                    if (!!listaProduto && listaProduto.length === 0) {
                        notification.warning({ message: 'Aviso', description: 'Orçamento deve ter ao menos 1 item!' });
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
            }
            formulario.validateFields(['']).then(() => {
                if (validaForm(formulario, listaValidacoes)) {
                    pages.setTagPages(proxPagina);
                } else {
                    proximaPag.setProximaPag(paginaAtual);
                }
            }).catch(() => {
                notification.warning({ message: 'Aviso', description: 'Preencha os campos obrigatórios!'});
                proximaPag.setProximaPag(paginaAtual);
            });
        } else {
            pages.setTagPages(proxPagina);
        }
    }

    function preencherDadosOrcamento() {
        let dadosForma = [];
        orcamentoFormaPagamento.forEach(forma => {
            let aux = { ...forma };
            aux.valorTotal = formulario.getFieldValue().valorTotal;
            aux.orc_id = formulario.getFieldValue().orc_id;
            aux.orc_numero = formulario.getFieldValue().orc_numero;
            aux.fpg_id = forma.fpg_id;
            aux.fpg_descricao = forma.fpg_descricao;
            aux.cpg_id = forma.cpg_id;
            aux.cpg_descricao = forma.cpg_descricao;
            aux.data_sincro = new Date();
            aux.orf_id = forma.orf_id;
            aux.cpg_descricao = forma.cpg_descricao;
            aux.orf_percentualdesconto = !!forma.orf_percentualdesconto ? forma.orf_percentualdesconto : 0;
            aux.orf_percentualacrescimo = !!forma.orf_percentualacrescimo ? forma.orf_percentualacrescimo : 0;
            dadosForma.push(aux);
        });
        return dadosForma;
    };

    function popularDadosItens() {
        let produtos = [];
        listaProduto.forEach(itens => {
            let aux = { ...itens };
            aux.orc_id = itens.orc_id;
            aux.pro_id = itens.pro_id;
            aux.oit_qtde = itens.oit_qtde;
            aux.data_sincro = new Date();
            aux.oit_valorunitario = itens.oit_valorunitario;
            aux.prg_id = itens.prg_id;
            aux.oit_desconto = itens.oit_desconto;
            aux.oit_id = itens.oit_id;
            produtos.push(aux);
        })
        return produtos;
    };

    async function salvarManutencao(values) {
        if (listaProduto.length > 0) {
            let dados = {};
            if (dadosCliente.cli_id !== undefined && dadosCliente.cli_id !== null) {
                var cliente = (await api.get(`Cliente/BuscarCliente/${dadosCliente.cli_id}`)).data;
            }
            else if (values.pes_id){
                var cliente = (await api.get(`Cliente/BuscarByIdPessoa/${values.pes_id}`)).data;
            }
            dados = { ...values };
            dados.orc_id = formulario.getFieldValue().orc_id;
            dados.ven_id = formulario.getFieldValue().ven_id;
            dados.cli_id = formulario.getFieldValue().cli_id;
            dados.orc_observacao = formulario.getFieldValue().orc_observacao;
            dados.orc_valorfrete = parseFloat(values.orc_valorfrete);
            dados.orc_numero = formulario.getFieldValue().orc_numero;
            dados.orc_valordesconto = values.valorDesconto;
            dados.orc_valoroutros = parseFloat(values.orc_valoroutros);
            dados.orc_datainclusao = formulario.getFieldValue().orc_datainclusao;
            dados.orc_datavalidade = formulario.getFieldValue().orc_datavalidade;
            dados.orc_responsavelorcamento = formulario.getFieldValue().orc_responsavelorcamento;
            dados.orc_numerotelefone = formulario.getFieldValue().orc_numerotelefone;
            dados.orcamentoformapagamento = preencherDadosOrcamento(orcamentoFormaPagamento);
            dados.orcamentoitem = popularDadosItens(listaProduto);
            dados.orc_nomecliente = values.pes_nome || cliente.pes_nome
            if (orcamentoFormaPagamento.length === 0) {
                notification.warning({ success: 'Aviso', description: 'Selecione uma Forma de Pagamento' })
            } else {
                if (moment(dados.orc_datavalidade).isBefore(moment(dados.orc_datainclusao))) {
                    notification.warning({ message: 'Aviso', description: 'Data de validade não pode ser menor que a data de emissão!' });
                } else {
                    try {
                        var res = dados.orc_id ? await api.put('Orcamento/Editar', dados) : await api.post('Orcamento/Incluir', dados);
                        if (res.status == 200) {
                            notification.success({ message: 'Operação concluída', description: "Os dados do Orçamento foram salvos com sucesso!" });
                        }
                    } catch (error) {
                        notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(error.response) });
                    } finally {
                        carregarDados(true);
                        aoSalvar();
                    }
                }
            }
        } else {
            notification.warning({ message: 'Aviso!', description: 'É necessário informar pelo menos um item!' });
        }
    }

    return (
        <div>
            <Form layout="vertical" form={formulario} name="manutencaoOrcamento" onFinish={salvarManutencao}>
                <Row gutter={[8, 0]} className="m-t-16" >
                    <Col style={{ marginTop: 19}}>
                        <Tooltip title={clienteSemCadastro ? 'Selecionar Cliente cadastrado' : 'Informar Cliente sem cadastro'}>
                            <Button icon={<UserAddOutlined />} onClick={informarCliente}/>
                        </Tooltip>
                    </Col>
                    {!clienteSemCadastro && <Col xs={24} sm={24} md={10} lg={10} xl={8}>
                        <Form.Item label="Cliente" name="pes_id" rules={[{ required: true, message: 'Selecione um Cliente' }]}>
                            <SelectPaginado url="Cliente/Listar" placeholder="Selecione um Cliente" form={formulario} name="pes_id" onChangeFunction={(dados) => retornaDadosCliente(dados)}
                                conteudo={
                                    pc => (<Select.Option value={pc.pes_id} key={pc.key}> {pc.cli_codigo} - {pc.pes_nome} <br />
                                        {(!!pc.pej_cnpj ? `CNPJ.: ${MaskFormat(pc.pej_cnpj, '', true)}` : !!pc.pef_cpf ? `CPF.: ${MaskFormat(pc.pef_cpf, '', true)}` : '')}
                                    </Select.Option>)
                                } />
                        </Form.Item>
                    </Col>}
                    {clienteSemCadastro && <Col xs={24} sm={24} md={10} lg={10} xl={8}>
                        <Form.Item label="Cliente" name="pes_nome" rules={[{ required: true, message: 'Informe um Cliente' }]}>
                            <Input placeholder="Informe o nome do Cliente"/>
                        </Form.Item>
                    </Col>}
                    {pessoaFisica && <Col xs={24} sm={8} md={4} lg={4} xl={3}>
                        <InputCpfCnpj cpf label="CPF" name="cpf" disabled={clienteSemCadastro}/>
                    </Col>}
                    {!pessoaFisica && <Col xs={24} sm={8} md={4} lg={4} xl={3}>
                        <InputCpfCnpj label="CNPJ" name="cnpj" />
                    </Col>}
                    <Col xs={24} sm={7} md={8} lg={8} xl={4}>
                        <InputPhone label="Número" name="orc_numerotelefone"/>
                    </Col>
                    <Col xs={24} sm={16} md={16} lg={10} xl={8}>
                        <Form.Item label="Responsável pelo Orçamento" name="orc_responsavelorcamento">
                            <Input placeholder="Informe um Responsável" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={16} md={16} lg={10} xl={12}>
                        <Form.Item name="ven_id" label="Vendedor" rules={[{ required: true, message: 'Selecione um Vendedor'}]}>
                            <SelectPaginado url="Vendedor/Listar" placeholder="Selecione um Vendedor" form={formulario} name="ven_id"
                                conteudo={
                                    v => (<Select.Option value={v.ven_id} key={v.key}>{v.pes_nome}</Select.Option>)
                                } />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={4} xl={5}>
                        <Data label="Data de Emissão" name="orc_datainclusao" rules={[{ required: true, message: 'Informe a Data de Emissão' }]}/>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={4} xl={5}>
                        <Data label="Data de Validade" name="orc_datavalidade" rules={[{ required: true, message: 'Informe a Data de Validade' }]}/>
                    </Col>
                </Row>
                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Steps size="small" type="navigation" current={pages.tagPages}>
                            <Steps.Step title="Itens" />
                            <Steps.Step title="Faturamento" />
                        </Steps>
                    </Col>
                    <Col span={24}>
                        {pages.tagPages === 0 &&
                            <TabItens
                                form={formulario}
                                dadosItens={{ listaProduto, setListaProduto }}
                            />
                        }
                        {pages.tagPages === 1 &&
                            <TabFaturamento
                                form={formulario}
                                dadosItens={listaProduto}
                                dadosVendedor={vendedor}
                                orcamento={{ orcamentoFormaPagamento, setOrcamentoFormaPagamento }}
                            />
                        }
                    </Col>
                </Row>
            </Form>
        </div>
    )
}