import React, { useEffect } from "react";
import { Form, Row, Col, Radio, Input, Checkbox, notification } from "antd";

import api from '../../services/api';
import { FormGW } from "../../components";
import { useStateValue } from '../../state';
import { listagemActions } from "../../actions";

export default function ManutencaoHistorico({ formulario, carregando, aoSalvar }) {

    const [{ manutencao }, dispatch] = useStateValue();

    const salvarManutencao = (values) => {
        let retorno;
        carregando(true);


        let enderecoUrl = 'Historico/';
        if (values.his_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ description: `Condição de Pagamento editada com sucesso!`, message: 'Sucesso' });
                    retorno = res.data;
                }
            }, err => {
                carregando(false);
                console.log(err);
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+his_descricao' } });
                    aoSalvar();
                }
            );
        } else {
            enderecoUrl += `Incluir`;
            api.post(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ description: `Condição de Pagamento adicionada com sucesso!`, message: 'Sucesso' });
                    retorno = res.data;
                }
            }, err => {
                carregando(false);
                console.log(err);
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+his_descricao' } });
                    aoSalvar();
                }
            );
        }
    };

    useEffect(() => {
        if (!!manutencao.dados && manutencao.dados.duplicar) {
            formulario.setFieldsValue({ his_id: 0 });
        }
    }, [manutencao.dados]);

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+his_descricao' } });
    };

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoHistorico" onFinish={salvarManutencao}>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={13} lg={14} xl={13} xxl={14}>
                    <Form.Item name="his_id" hidden />
                    <Form.Item label="Nome do Histórico" name="his_descricao" rules={[{ required: true, message: 'Informe o Nome do Histórico' }]}>
                        <Input placeholder="Informe o Nome do Histórico" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={11} md={8} lg={7} xl={8} xxl={6}>
                    <Form.Item label="Tipo de Histórico" name="his_tipolancamento" rules={[{ required: true, message: 'Informe o Tipo de Histórico' }]}>
                        <Radio.Group>
                            <Radio value={0}>Saída</Radio>
                            <Radio value={1}>Entrada</Radio>
                            <Radio value={2}>Extra-Caixa</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={3} lg={3} xl={3} xxl={3}>
                    <Form.Item name="his_ativo" valuePropName="checked"
                        hidden={(!(formulario.getFieldValue(['his_id']) > 0))} className="t-mob-573">
                        <Checkbox>Ativo</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </FormGW>
    );
}