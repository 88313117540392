import React, { useEffect, useState } from "react";
import { Row, Col, Form, Checkbox, Input, Select, Slider, notification, Tabs, Typography, Divider } from "antd";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

import api from "../../services/api";
import TabEndereco from './tabEndereco';
import { useStateValue } from '../../state';
import { InputPhone, InputPreco, SelectPaginado } from "../../components";

export default function ManutencaoHotel({ formulario, aoSalvar, carregando }) {

    const valueMaximo = 10;
    const inputRef = React.createRef();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [valorPreferencia, setValorPreferencia] = useState(0);
    const [valuePreferenciaSlider, setValuePreferenciaSlider] = useState(0);
    const [listaTipoHotel, setListaTipoHotel] = useState([]);
    const [listaTipoEndereco, setListaTipoEndereco] = useState([]);
    const [listaPais, setListaPais] = useState([]);

    const handleChange = (value) => {
        setValorPreferencia(value);
        setValuePreferenciaSlider(value);
    };

    function salvarManutencao(values) {
        let operacao = !!values.hot_id ? 'Editar' : 'Incluir';
        let coordenadas = !!values.coordenadas ? values.coordenadas.split(',') : ['', ''];
        let enderecoTemp = {
            cep_cep: values.cep_cep,
            cid_descricao: values.cid_descricao,
            est_sigla: values.est_sigla,
            log_logradouro: values.log_logradouro,
            pee_complemento: values.pee_complemento,
            pee_localreferencia: values.pee_localreferencia,
            pee_numero: values.pee_numero,
            tpe_id: values.tpe_id,
            bai_nome: values.bai_nome,
            pee_enderecoprincipal: true,
            pee_latitude: coordenadas[0],
            pee_longitude: coordenadas[1]
        }
        let telefoneTemp = {
            psc_numero: values.psc_numero,
            tpt_id: values.tpt_id,
            psc_ramal: values.psc_ramal

        }
        let emailTemp = {
            pem_email: values.pem_email,
            pem_emailprincipal: true,
            pem_ativo: true
        }
        let outroContato = {
            toc_id: 3,
            out_ativo: true,
            out_descricao: values.out_descricao
        }
        values.OutrosContatos = !!values.out_descricao ? [outroContato] : [];
        values.Emails = !!values.pem_email ? [emailTemp] : [];
        values.Enderecos = [enderecoTemp];
        values.Telefones = [telefoneTemp];
        values.hot_nota_avaliacao = valuePreferenciaSlider;


        api.post(`Hotel/${operacao}`, values).then(res => {
            notification.success({ message: 'Sucesso!', description: `Hotel ${operacao === 'Editar' ? 'editado' : 'incluido'} com sucesso!` });
        }).catch(
            error => {
                notification.warn({ message: 'Aviso!', description: `Erro ao ${operacao === 'Editar' ? 'editar' : 'incluir'} o hotel!` });
                console.log(error);
            }
        ).finally(() => {
            aoSalvar();
        })
    }

    function carregarDados() {
        api.get('Enum/Listar?nome=TipoHotel').then(
            res => {
                setListaTipoHotel(res.data);
            }
        ).catch(error => {
            console.log(error);
        });
        api.get(`TipoEndereco/Listar`).then(
            res => {
                setListaTipoEndereco(res.data);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
        api.get(`Pais/Listar`).then(
            res => {
                setListaPais(res.data);
            }
        ).catch(
            (erro) => console.error(erro)
        );
    }

    useEffect(() => {
        if (!!manutencao.dados) {
            manutencao.dados.coordenadas = !!manutencao.dados.pee_latitude && !!manutencao.dados.pee_longitude ? manutencao.dados.pee_latitude + ',' + manutencao.dados.pee_longitude : '';
            formulario.setFieldsValue(manutencao.dados);
            setValorPreferencia(!!manutencao.dados.hot_nota_avaliacao ? manutencao.dados.hot_nota_avaliacao : 0);
            setValuePreferenciaSlider(!!manutencao.dados.hot_nota_avaliacao ? manutencao.dados.hot_nota_avaliacao : 0);
        }
    }, [manutencao.dados])

    useEffect(() => {
        carregarDados();
    }, [])

    return (
        <div className="pages-col">
            <Form layout="vertical" name="formManutencaoHotel" form={formulario} onFinish={salvarManutencao}>
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Hotel" key="1">
                        <Row gutter={[8, 0]}>
                            <Form.Item name='hot_id' hidden />
                            <Form.Item name='pes_id' hidden />
                            <Col xs={24} sm={6} md={4} lg={4} xl={4} xxl={3}>
                                <Form.Item label="CNPJ" name="pej_cnpj" rules={[{ required: true, message: 'Informe o CNPJ.' }]}>
                                    <CpfCnpj className='ant-input ant-input-sm' placeholder="Informe o CNPJ" disabled={!!manutencao.dados && !!manutencao.dados.pej_cnpj} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={9} md={7} lg={6} xl={6} xxl={6}>
                                <Form.Item label="Nome" name="pes_nome" rules={[{ required: true, message: 'Informe o nome' }]}>
                                    <Input placeholder="Informe o nome" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={9} md={7} lg={5} xl={6} xxl={6}>
                                <Form.Item label="E-mail" name="pem_email">
                                    <Input placeholder="Informe o E-mail" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={4}>
                                <Form.Item label="Tipo" name="hot_tipo_categoria">
                                    <Select
                                        placeholder="Selecione o Tipo"
                                        allowClear
                                        optionFilterProp="children"
                                        showSearch>
                                        {listaTipoHotel.map((th) => (
                                            <Select.Option value={th.key} key={th.key}>{th.value}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={5} xl={4} xxl={5}>
                                <Form.Item label="Site" name="out_descricao">
                                    <Input placeholder="Informe o Site" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={4}>
                                <Form.Item name="hot_nota_avaliacao">
                                    <label>
                                        Preferência: <span>{valorPreferencia}</span>
                                    </label>
                                    <Slider tipFormatter={null} min={0} max={valueMaximo} onChange={handleChange} value={valuePreferenciaSlider} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={5} xl={5} xxl={5}>
                                <InputPreco label="Valor Standard (R$)" name='hot_preco_standard' />
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={5} xl={5} xxl={5}>
                                <InputPreco label="Valor Duplo (R$)" name='hot_preco_duplo' />
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={5} xl={5} xxl={5}>
                                <InputPreco label="Valor Triplo (R$)" name='hot_preco_triplo' />
                            </Col>
                            <Col className="t-mob">
                                <Form.Item valuePropName="checked" name="hot_ativo">
                                    <Checkbox> Ativo </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align="middle" className="m-t-16">
                            <Col span={24}>
                                <Typography.Title level={3}>
                                    <img src={require("../../assets/i-telefone.png").default} alt="Telefone(s)" /> Telefone(s)
                                </Typography.Title>
                                <Divider orientation="left" plain>
                                    Adicione o(s) telefone(s) do cliente aqui.
                                </Divider>
                            </Col>
                        </Row>
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={7} md={5} lg={4} xl={4} xxl={4}>
                                <InputPhone label="Número" name="psc_numero" rules={[{ required: true, message: 'Informe o Número do Telefone' }]} />
                            </Col>
                            <Col xs={24} sm={10} md={6} lg={6} xl={6} xxl={5}>
                                <Form.Item label="Tipo de Telefone" name="tpt_id" rules={[{ required: true, message: 'Selecione um Tipo' }]}>
                                    <SelectPaginado ref={inputRef} url="TipoTelefone/Listar" placeholder="Selecione um tipo de telefone" form={formulario} name="tpt_id"
                                        conteudo={
                                            tp => (<Select.Option value={tp.tpt_id} key={tp.key}>{tp.tpt_descricao}</Select.Option>)
                                        } />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={7} md={5} lg={4} xl={4} xxl={4}>
                                <Form.Item label="Ramal" name="psc_ramal">
                                    <Input placeholder="Informe o ramal" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Endereços" key="2">
                        <TabEndereco listaTipoEndereco={listaTipoEndereco} listaPaises={listaPais} />
                    </Tabs.TabPane>
                </Tabs>
            </Form>
        </div>
    );
}