import React, { useEffect, useState } from "react";
import { Row, Col, Descriptions, Form, Input, Modal, notification } from "antd";
import { ExclamationCircleOutlined, CloudUploadOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../../services/api";
import { HasValue, novaAbaNavegador } from "../../../services/funcoes";
import { MaskFormat, FormatNumber } from "../../../ValueObjects";

export default function ModalReenviarMdfeSefaz({ dados, exibirModalReenviarMdfeSefaz, fecharModalReenviarMdfeSefaz, titleModal, textoBotao }) {
    const [form] = Form.useForm();
    const [carregando, setCarregando] = useState(false);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                form.resetFields();
                fecharModalReenviarMdfeSefaz();
            }
        });
    };

    const realizarReenvioMdfeSefaz = () => {
        setCarregando(true);
        let _link = `ManifestoEletronico/ReenviarMdfeSefaz/${dados.mdf_id}`;

        api.post(_link).then(
            (res) => {
                if (res.status === 200) {
                    if (res.data.mdf_status === 1 && !!res.data.mdf_chavemdfe) {
                        notification.success({ message: 'Sucesso', description: `MDF-e Autorizada com sucesso!` });
                        novaAbaNavegador(`Danfe/GerarDamdfe?chaveMdfe=${res.data.mdf_chavemdfe}`);
                    } else {
                        notification.error({
                            description: (<>
                                Não foi possível Autorizar Manifesto Eletrônico! <br />
                                {res.data.mdf_cstat} - {res.data.mdf_xmotivo}
                            </>), message: `Aviso`
                        });
                    }
                } else {
                    notification.error({ description: 'Não foi possível Autorizar Manifesto Eletrônico!', message: `Aviso` });
                }
            }
        ).catch(
            (erro) => {
                if (!!erro.response && !!erro.response.data) {
                    notification.error({ description: erro.response.data, message: `Aviso` });
                } else {
                    notification.error({ description: 'Não foi possível Autorizar Manifesto Eletrônico!', message: `Aviso` });
                }
                setCarregando(false);
            }
        ).finally(() => {
            setCarregando(false);
            form.resetFields();
            fecharModalReenviarMdfeSefaz();
        });
        console.log(dados)
    }
    return (
        <Modal centered
            title={titleModal}
            visible={exibirModalReenviarMdfeSefaz}
            onCancel={onCloseModal}
            onOk={realizarReenvioMdfeSefaz}
            confirmLoading={carregando}
            okText={
                <>
                    <CloudUploadOutlined /> Reenviar MDF-e Sefaz
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            destroyOnClose
        >
            <Form layout="vertical" form={form}>
                <Descriptions size="small">
                    <Descriptions.Item label="Nº MDF-e">
                        <b>{dados.mdf_numero}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small">
                    <Descriptions.Item label="Chave da MDf-e">
                        <b> {dados.mdf_chavemdfe} </b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small">
                    <Descriptions.Item label="Veiculo/Placa">
                        <b>{dados.vei_descricao + ' - ' + dados.vei_placa}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="Série">
                        <b>{dados.mdf_serie}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Modelo">
                        <b>{dados.mdf_modelo}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="Emitida em">
                        <b>{moment(dados.mdf_dataemissao).format('DD/MM/YYYY')}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small">
                    <Descriptions.Item label="Valor Total da Carga (R$)">
                        <b> {FormatNumber(dados.valortotalmdfe, true)} </b>
                    </Descriptions.Item>
                </Descriptions>
            </Form>
        </Modal>
    );
}