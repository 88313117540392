import React, { useEffect, useState } from "react";
import { Row, Col, Button, Select, Collapse, Form } from "antd";
import { SearchOutlined, ArrowDownOutlined, ArrowUpOutlined, PrinterOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";

import DrawerBoleto from './drawer';
import api from '../../services/api';
import { useStateValue } from '../../state';
import { linkApi } from "../../services/auth";
import { listagemActions } from "../../actions";
import { novaAbaNavegador } from "../../services/funcoes";
import { FormatDate, FormatNumber } from "../../ValueObjects";
import { BreadcrumbPage, BotaoAbrirChamado, FiltroSearch, ComboData, TabelaDados, Imprimir, Data } from "../../components";
import SelectPaginado from "../../components/selectPaginado";
export default function Boleto() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [formulario] = Form.useForm();
    const [listaBancos, setListaBancos] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [openPesquisaAvancada, setOpenPesquisaAvancada] = useState(false);

    const togglePesquisa = () => {
        setOpenPesquisaAvancada(!openPesquisaAvancada);
    };
    useEffect(() => {
        let sql = "";
        sql += !!formulario.getFieldValue().ban_id ? `&CodigoBanco=${formulario.getFieldValue().ban_id}` : "";
        sql += !!formulario.getFieldValue().ban_descricao ? `&NomeBanco=${formulario.getFieldValue().ban_descricao}` : "";
        sql += !!formulario.getFieldValue().pes_id ? `&ClienteID=${formulario.getFieldValue().pes_id}` : "";
        sql += !!formulario.getFieldValue().dataInicial ? `&DataInicial=${moment(formulario.getFieldValue().dataInicial).format('YYYY-MM-DD')}` : `&DataInicial=${moment(new Date()).format('YYYY-MM-DD')}`;
        sql += !!formulario.getFieldValue().dataFinal ? `&DataFinal=${moment(formulario.getFieldValue().dataFinal).format('YYYY-MM-DD')}` : `&DataFinal=${moment(new Date).format('YYYY-MM-DD')}`;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `${sql}`, pagina: 1, ordem: '-bol_dataemissao' } });
        formulario.setFieldsValue({ dataEmissao: moment(new Date()) });
    }, []);

    function buscarBancos(ban_id) {
        api.get(`Banco/Listar?filtro=${ban_id}`).then((res) => {
            if (res.status === 200) {
                setListaBancos(res.data);
            }
        }).catch(err => {
            console.log(err);
        });
    };

    function buscarClientes(pes_cpf) {
        api.get(`Cliente/ListarClientesAtivos?filtro=${pes_cpf}`).then(
            (res) => {
                if (res.status === 200) {
                    setClientes(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };

    function aplicarFiltrosAvancados() {
        let sql = "";
        sql += !!formulario.getFieldValue().ban_id ? `&CodigoBanco=${formulario.getFieldValue().ban_id}` : "";
        sql += !!formulario.getFieldValue().ban_descricao ? `&NomeBanco=${formulario.getFieldValue().ban_descricao}` : "";
        sql += !!formulario.getFieldValue().pes_id ? `&ClienteID=${formulario.getFieldValue().pes_id}` : "";
        sql += !!formulario.getFieldValue().dataInicial ? `&DataInicial=${moment(formulario.getFieldValue().dataInicial).format('YYYY-MM-DD')}` : `&DataInicial=${moment(new Date()).format('YYYY-MM-DD')}`;
        sql += !!formulario.getFieldValue().dataFinal ? `&DataFinal=${moment(formulario.getFieldValue().dataFinal).format('YYYY-MM-DD')}` : `&DataFinal=${moment(new Date).format('YYYY-MM-DD')}`;
        //dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `${sql}`, pagina: 1, ordem: '%2Bbol_id' } });
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `${sql}`, pagina: 1, ordem: '-bol_dataemissao' } });
    };

    function onBlurDataEmissao() {
        let sql = "";
        sql += !!formulario.getFieldValue().ban_id ? `&CodigoBanco=${formulario.getFieldValue().ban_id}` : "";
        sql += !!formulario.getFieldValue().ban_descricao ? `&NomeBanco=${formulario.getFieldValue().ban_descricao}` : "";
        sql += !!formulario.getFieldValue().pes_id ? `&ClienteID=${formulario.getFieldValue().pes_id}` : "";
        sql += !!formulario.getFieldValue().dataInicial ? `&DataInicial=${moment(formulario.getFieldValue().dataInicial).format('YYYY-MM-DD')}` : `&DataInicial=${moment(new Date()).format('YYYY-MM-DD')}`;
        sql += !!formulario.getFieldValue().dataFinal ? `&DataFinal=${moment(formulario.getFieldValue().dataFinal).format('YYYY-MM-DD')}` : `&DataFinal=${moment(new Date).format('YYYY-MM-DD')}`;
        //dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `${sql}`, pagina: 1, ordem: '%2Bbol_id' } });
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `${sql}`, pagina: 1, ordem: '-bol_dataemissao' } });
    };

    function imprimirBoleto(record) {
        if (!!record.bol_id) {
            //primeiro metodo imprime todos os boletos pelo codigo da nota
            //novaAbaNavegador(`Boleto/ImprimirBoletos/${record.ntf_id}`);
            //segundo metodo imprime o boleto secionado para imprimir passando codigo do boleto
            novaAbaNavegador(`Boleto/Gerar/${record.bol_id}`);
        }
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" gutter={[8, 8]}>
                <Col>
                    <BreadcrumbPage />
                </Col>
                <Col>
                    <BotaoAbrirChamado />
                </Col>
            </Row>
            <Form layout="vertical" form={formulario}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={18} xl={19} xxl={20}>
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={17} md={17} lg={18} xl={20} xxl={20}>
                                <FiltroSearch />
                            </Col>
                            <Col xs={24} sm={7} md={7} lg={4} xl={4} xxl={3}>
                            <ComboData form={formulario} eventoAoAlterar={() => {
                                formulario.setFieldsValue({ DataFinal: moment(new Date()) });
                                formulario.setFieldsValue({ DataInicial: moment(formulario.getFieldValue().dataPesquisa) });
                                formulario.setFieldsValue({ dataFinal: moment(new Date()) });
                                formulario.setFieldsValue({ dataInicial: moment(formulario.getFieldValue().dataPesquisa) });
                                aplicarFiltrosAvancados();
                                }} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row align="middle" gutter={[8, 8]}>
                    <Col span={24}>
                        <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                            <div className="colDet"></div>
                            <Collapse.Panel key="1" showArrow={false} extra={
                                <Button type="primary" onClick={togglePesquisa} block>
                                    {openPesquisaAvancada ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                </Button>
                            }>
                                <Row align="middle" gutter={[8, 0]}>
                                    <Col xs={24} sm={12} md={5} lg={4} xl={4} xxl={3}>
                                        <Data label="Data Emissão Incial" name="dataInicial"/>
                                    </Col>
                                    <Col xs={24} sm={12} md={5} lg={4} xl={4} xxl={3}>
                                        <Data label="Data Emissão Final" name="dataFinal"/>
                                    </Col>
                                    <Col xs={24} sm={12} md={7} lg={8} xl={5} xxl={7}>
                                        <Form.Item label="Cliente" name="pes_id">
                                            <SelectPaginado url="Cliente/ListarClientesAtivos?filtro=&ordem=%2Bpes_nome" placeholder="Selecione o Cliente" form={formulario} 
                                            name="ClienteId" 
                                            onChangeFunction={(dados) => { formulario.setFieldsValue({ ClienteId: null }); buscarClientes(dados); aplicarFiltrosAvancados() }}
                                                conteudo={
                                            cliente => (<Select.Option key={cliente.pes_id} value={cliente.pes_id}>{cliente.pes_nome}</Select.Option>)} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={7} lg={8} xl={5} xxl={6}>
                                        <Form.Item label="Banco" name="ban_id">
                                            <SelectPaginado url="Banco/Listar?filtro=&ordem=%2Bban_descricao" placeholder="Selecione o Banco" form={formulario} name="ban_id" 
                                            onChangeFunction={(dados) => { formulario.setFieldsValue({ ClienteId: null }); buscarBancos(dados); aplicarFiltrosAvancados() }}
                                            conteudo={
                                                banco => (<Select.Option Key={banco.ban_id} value={banco.ban_id}> {banco.banc_id - banco.ban_descricao}</Select.Option>)} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={24} md={24} lg={24} xl={6} xxl={5} className="t-mob tm5">
                                        <Row gutter={[8, 8]} justify="end" className="tt-5">
                                            <Col xs={24} sm={12} md={6} lg={5} xl={12}>
                                                <Button icon={<CloseOutlined />} block>
                                                    Remover Filtros
                                                </Button>
                                            </Col>
                                            <Col xs={24} sm={12} md={6} lg={5} xl={12}>
                                                <Button type="primary" icon={<SearchOutlined />} onClick={() => aplicarFiltrosAvancados()} block>
                                                    Aplicar Filtros
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="Boleto/Listar" colunas={
                    [
                        {
                            title: '',
                            align: 'center',
                            width: 80,
                            render: ({ ban_id, ban_descricao }) => (
                                <div>
                                    <img className="imgLogoListaBanco" src={`${linkApi}Banco/RetornaLogo/${ban_id}`} alt={ban_descricao} />
                                </div>
                            ),
                        },
                        {
                            title: 'Informações',
                            render: ({ pes_nome, ser_serie, ntf_numero, prc_numeroparcela, bol_numerodocumento }) => (
                                <div>
                                    <Row align="middle">
                                        <Col>
                                            <b>{pes_nome}</b>
                                        </Col>
                                    </Row>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col>
                                            Nº Doc.: <b>{bol_numerodocumento}</b>
                                        </Col>
                                        <Col>
                                            Parcela: <b>{prc_numeroparcela}</b>
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                        {
                            title: 'Data Emissão',
                            align: 'center',
                            render: ({ bol_dataemissao }) => (
                                <div>
                                    <b>{moment(bol_dataemissao).format('DD/MM/YYYY')}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Data Vencimento',
                            align: 'center',
                            render: ({ bol_datavencimento }) => (
                                <div>
                                    <b>{moment(bol_datavencimento).format('DD/MM/YYYY')}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Valor (R$)',
                            align: 'right',
                            render: ({ bol_valor }) => (
                                <div>
                                    <b className="f-18">{FormatNumber(bol_valor, true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            width: 65,
                            align: 'center',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Imprimir icon={<PrinterOutlined />} onClick={() => imprimirBoleto(record)}>Imprimir</Imprimir>
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } />
            </div>
        </div>
    );

}