import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import moment from "moment";

import { gravarTela, limparTela } from "../../services/navigation";
import { useStateValue } from "../../state";
import { MinusOutlined } from "@ant-design/icons";
import { drawerActions, manutencaoActions } from "../../actions";
import { getNavegacao } from "../../services/auth";
import { isDate } from "../../services/funcoes";

export default function DrawerNav({ title, width = '70%', visible, className, form, limparAoEditar = false, fecharDrawer, fecharDrawerExtra, footer, afterVisibleChange, closeIcon, closable = true, destroyOnClose = false, children, extra }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();

    function minimizarDrawer() {
        let url = window.document.location.href;
        let nomeForm = url.substring(url.lastIndexOf('/'), url.length);
        /*setTimeout(() => {
            gravarTela(nomeForm, manutencao);
        }, 500);*/
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false, showDrawerExtra: false } });
    };

    useEffect(() => {
        let jsonGeral = JSON.parse(getNavegacao()).geral;
        let dadosCaminho = {};
        let url = window.document.location.href;
        let nomeForm = url.substring(url.lastIndexOf('/'), url.length);
        if (!!jsonGeral) {
            dadosCaminho = jsonGeral[nomeForm];
        }
        if (!visible) {
            if (!!dadosCaminho && !!dadosCaminho.dados && (JSON.parse(dadosCaminho.dados).editar || !!!manutencao.dados)) {
                limparTela(nomeForm);
            }
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            if (ui.showDrawerExtra) {
                if (!!fecharDrawerExtra) {
                    fecharDrawerExtra();
                }
            } else {
                if (!!fecharDrawer) {
                    fecharDrawer();

                }
            }
        } else if (visible && !!dadosCaminho && !!dadosCaminho.dados && !!!manutencao.dados) {
            let dados = JSON.parse(dadosCaminho.dados);
            for (let key in dados) {
                if (isDate(dados[key])) {
                    dados[key] = moment(dados[key]);
                }
            }
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
        }
    }, [visible]);

    return (
        <Drawer
            destroyOnClose={limparTela}
            title={title}
            width={width}
            visible={visible}
            onClose={minimizarDrawer}
            footer={footer}
            afterVisibleChange={afterVisibleChange}
            closeIcon={<MinusOutlined />}
            closable={closable}
            className={className}
            extra={extra}
        >
            {children}
        </Drawer>
    );
};