import React, { useState } from "react";
import { Modal, Form, Descriptions, notification } from "antd";
import { SaveOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import api from "../../../services/api";

export default function ModalEncerrarMdfe({ exibirModal, fecharModal, dadosMdfe, setLimparCampo }) {
    const [carregando, setCarregando] = useState(false);

    const cancelarModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
                setLimparCampo(true);
            }
        });
    }

    const encerrarMdfe = () => {
        Modal.confirm({
            title: 'Encerrar MDF-e?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente encerrar o MDF-e Nº:${dadosMdfe.mdf_numero}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                setCarregando(true);
                api.post(`ManifestoEletronico/EncerrarMdfe/${dadosMdfe.mdf_id}`).then(
                    (retornoNF) => {
                        if (retornoNF.status === 200) {
                            if (retornoNF.data.cStat === 132 || retornoNF.data.cStat === 135) {
                                notification.success({ message: 'Sucesso', description: retornoNF.data.cStat + " - " + retornoNF.data.xMotivo });
                            } else {
                                notification.error({ description: 'Não foi possível Encerrar Manifesto Eletrônico! ' + retornoNF.data.cStat + " - " + retornoNF.data.xMotivo, message: `Aviso` });
                            }
                        } else {
                            notification.error({ description: 'Não foi possível Encerrar Manifesto Eletrônico!  ' + retornoNF.data.cStat + " - " + retornoNF.data.xMotivo, message: `Aviso` });
                        }
                    }
                ).catch(
                    (erro) => {
                        if (!!erro.response && !!erro.response.data) {
                            notification.error({ description: erro.response.data, message: `Aviso` });
                        } else {
                            notification.error({ description: 'Não foi possível Encerrar Manifesto Eletrônico!', message: `Aviso` });
                        }
                        setLimparCampo(true);
                    }
                ).finally(() => {
                    fecharModal();
                    setCarregando(false);
                });
            }
        })
    }

    return (
        <div>
            <Modal centered
                title="Encerrar Mdf-e?"
                visible={exibirModal}
                onCancel={cancelarModal}
                width={400}
                onOk={encerrarMdfe}
                confirmLoading={carregando}
                okText={
                    <>
                        <SaveOutlined /> Encerrar MDF-e
                    </>
                }
                cancelText={
                    <>
                        <CloseOutlined /> Cancelar
                    </>
                }
                maskClosable={false}
            >
                <div>
                    <Form>
                        <Descriptions size="small">
                            <Descriptions.Item label="Chave">
                                <b> {dadosMdfe.mdf_chavemdfe} </b>
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions size="small">
                            <Descriptions.Item label="Nº MDF-e">
                                <b> {dadosMdfe.mdf_numero} </b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Data de Emissão">
                                <b> {moment(dadosMdfe.mdf_dataemissao).format('DD-MM-YYYY')} </b>
                            </Descriptions.Item>
                        </Descriptions>
                        <Descriptions size="small">
                            <Descriptions.Item label="Modelo">
                                <b> {dadosMdfe.mdf_modelo} </b>
                            </Descriptions.Item>
                            <Descriptions.Item label="UF Inicial">
                                <b> {dadosMdfe.mdf_ufinicio} </b>
                            </Descriptions.Item>
                            <Descriptions.Item label="UF Final">
                                <b> {dadosMdfe.mdf_uffim} </b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Form>
                </div>
            </Modal>
        </div>
    )
}