import { Button, Col, Form, Row, notification } from "antd";
import SelectProduto from "../selects/selectProduto";
import { PlusOutlined } from "@ant-design/icons";
import { useStateValue } from "../../state";
import { useState } from "react";
import api from "../../services/api";
import { isObjetoDiffVazio } from "../../services/funcoes";
import InputNumber from "rc-input-number";
import InputPreco from "../inputPreco";

export default function FiltroProdutoKit({ formulario, setListaItems, listaItems }) {

    const [{ listaSelectPaginacao }, dispatch] = useStateValue();
    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [dadosProduto, setDadosProduto] = useState({});

    function adicionarItem() {
        let valores = formulario.getFieldsValue();
        let itemJaAdd = false;
        if (valores.produto_id !== undefined && valores.produto_id !== null) {

            if (valores?.valor_unitario == 0) {
                notification.warning({ description: 'Aviso!', description: 'Não é possivel adicionar item com valor 0' });
                return;
            }
            let produtoTemp = { ...dadosProduto };
            if (listaItems.length > 0) {
                if (produtoTemp.prg_id) {
                    itemJaAdd = listaItems.find(i => i.prg_id == produtoTemp.prg_id)
                } else {
                    itemJaAdd = listaItems.find(i => i.pro_id == produtoTemp.pro_id)
                }
                if (itemJaAdd) {
                    notification.warning({ description: 'Aviso!', description: 'Item já Adicionado' });
                    return;
                }
            }

            let listaTemp = [...listaItems];
            produtoTemp.qtde_item = valores.qtde_item;
            produtoTemp.pkc_valorunitario = valores.valor_unitario;
            produtoTemp.pkc_ativo = true;
            listaTemp.push(produtoTemp);
            setListaItems(listaTemp);
            setDadosProduto({});
            formulario.setFieldsValue({
                produto_id: null,
                qtde_item: null,
                valor_unitario: null,
            })
        } else {
            notification.warning({ description: 'Aviso!', description: 'Selecione um produto!' });
            return;
        }
    }

    async function buscarProduto(key) {
        let listaItensTemp = [...listaSelectPaginacao.itens];
        let produtoTemp = listaItensTemp.find(pro => pro.key == key);
        if (isObjetoDiffVazio(produtoTemp)) {
            produtoTemp.pkc_valorunitario = produtoTemp.lpi_valorvenda;
            setDadosProduto(produtoTemp);
            setExibirDetalhes(true);
            formulario.setFieldsValue({ qtde_item: 1, valor_unitario: produtoTemp.lpi_valorvenda ?? 0 });
        }
    }
    return (
        <div>
            <Row gutter={[8, 16]}>
                <Col xs={13} sm={13} md={13} lg={13} xl={13} xxl={13}>
                    <Form.Item label="Selecione o Produto" name="produto_id">
                        <SelectProduto form={formulario} placeholder="Selecione o Produto" name="produto_id" filtroExtra={'&ProdutoKit=false'} detalhes={true} valueKey={true} onChange={(dados) => { buscarProduto(dados) }} ordem={"pro_descricao"} />
                    </Form.Item>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <Form.Item name="qtde_item" label="Quantidade">
                        <InputNumber name="qtde_item" />
                    </Form.Item>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <InputPreco name="valor_unitario" label="Valor Unitário" />
                </Col>
                <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} className="add-button">
                    <Button icon={<PlusOutlined />} type="primary" onClick={() => adicionarItem()}>
                        Adicionar
                    </Button>
                </Col>
            </Row>
        </div>
    );
}