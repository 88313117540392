import React, { useState } from "react";
import moment from 'moment';
import { Row, Col, Descriptions, Modal, Table, Divider } from "antd";
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

import { FormatNumber } from "../../../ValueObjects";
import { ModalSenhaLiberacao } from "..";

export default function ModalParcelasPendentes({ dadosParcelas, exibirModal, fecharModal, fecharModalOk }) {
    const [abreModalLiberacao, setAbreModalLiberacao] = useState(false);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
            }
        });
    };

    const solicitarPermissao = () => {
        setAbreModalLiberacao(true);
    };

    const onCloseModalLiberacao = (retorno) => {
        setAbreModalLiberacao(false);
        if (retorno) {
            fecharModalOk();
        }
    };

    return (
        <Modal centered
            title="Parcelas em Atraso"
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={solicitarPermissao}
            okText={
                <>
                    <CheckOutlined /> Solicitar permissão para venda a prazo
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }   
            maskClosable={false}
        >
            <div>
                <Row justify="center">
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item>
                                O Cliente possui parcelas em atraso.
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item label="Limite de Crédito Disponível (R$)">
                                {FormatNumber(!!dadosParcelas && dadosParcelas.length > 0 ? dadosParcelas[0].limiteCredito : 0, true)}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table columns={
                            [
                                {
                                    title: 'N° Documento',
                                    render: ({ cpr_numerodocumento }) => (
                                        <div>
                                            <b>{cpr_numerodocumento}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Parcela',
                                    render: ({ prc_numeroparcela }) => (
                                        <div>
                                            <b>{prc_numeroparcela}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Data Vencimento',
                                    render: ({ prc_datavencimento }) => (
                                        <div>
                                            <b>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Valor Pendente (R$)',
                                    render: ({ prc_valor }) => (
                                        <div>
                                            <b>{FormatNumber(prc_valor, true)}</b>
                                        </div>
                                    ),
                                },
                            ]} dataSource={dadosParcelas} locale={{
                                emptyText: (
                                    <Row>
                                        <Col span={24}>
                                            <Divider orientation="center">
                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                            </Divider>
                                        </Col>
                                    </Row>
                                )
                            }} />
                    </Col>
                    <ModalSenhaLiberacao exibirModal={abreModalLiberacao} fecharModal={onCloseModalLiberacao} />
                </Row>
            </div>
        </Modal>
    );
}