import React, { useState, useEffect } from "react";
import { Button, Checkbox, Col, Divider, Form, Input, Modal, notification, Row, Select, Table, Tag, Tooltip } from "antd";
import { BotaoAbrirChamado, BreadcrumbPage } from "../../components";
import { useStateValue } from "../../state";
import api from "../../services/api";
import { QuestionCircleOutlined, ExclamationCircleOutlined, SaveOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import SelectPaginado from "../../components/selectPaginado";

export default function ManutencaoProduto() {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);
    const [carregandoGerar, setCarregandoGerar] = useState(false);
    const [considerarVigencia, setConsiderarVigencia] = useState(true);
    const [marcado, setMarcado] = useState(true);
    const [listaCest, setListaCest] = useState([]);
    const [listaCestAlterar, setListaCestAlterar] = useState([]);
    const [dadosProdutos, setDadosProdutos] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [form] = Form.useForm();
    const [formAlterar] = Form.useForm();

    function buscarCest(ncm, cestAlterar = false) {
        if (!!ncm) {
            api.get(`produto/BuscarCest?IdNcm=${ncm}`).then(
                res => {
                    if (res.status === 200) {
                        if (cestAlterar) {
                            setListaCestAlterar(res.data);
                            formAlterar.setFieldsValue({ cen_id: null });
                        } else {
                            setListaCest(res.data);
                            form.setFieldsValue({ cen_id: null });
                        }
                    }
                }
            ).catch(
                error => {
                    console.log(error);
                }
            );
        } else {
            if (cestAlterar) {
                setListaCestAlterar([]);
                formAlterar.setFieldsValue({ cen_id: null });
            } else {
                setListaCest([]);
                form.setFieldsValue({ cen_id: null });
            }
        }
    };

    function buscarProdutos() {
        if (!form.getFieldsValue().filtro && !form.getFieldsValue().filtroNcm && !form.getFieldsValue().filtroCest && !form.getFieldsValue().ncm_id
            && !form.getFieldsValue().cen_id && !form.getFieldsValue().icm_id && !form.getFieldsValue().SomenteNcmInativos && !form.getFieldsValue().EANInvalidos) {
            notification.warning({ message: 'Aviso!', description: 'É necessário informar pelo menos um filtro!' });
            return;
        }

        let outrosFiltros = `&filtro=${!!form.getFieldsValue().filtro ? form.getFieldsValue().filtro : ''}`;
        outrosFiltros += `&Ncm=${!!form.getFieldsValue().filtroNcm ? form.getFieldsValue().filtroNcm : ''}`;
        outrosFiltros += `&Cest=${!!form.getFieldsValue().filtroCest ? form.getFieldsValue().filtroCest : ''}`;
        outrosFiltros += `&SomenteNcmInativos=${form.getFieldsValue().SomenteNcmInativos}`;
        outrosFiltros += `&EANInvalidos=${form.getFieldsValue().EANInvalidos}`;
        if (form.getFieldsValue().ncm_id) outrosFiltros += `&IdNcm=${form.getFieldsValue().ncm_id}`;
        if (form.getFieldsValue().cen_id) outrosFiltros += `&IdCest=${form.getFieldsValue().cen_id}`;
        if (form.getFieldsValue().icm_id) outrosFiltros += `&IdIcms=${form.getFieldsValue().icm_id}`;

        setCarregando(true);
        api.get(`Produto/ListaProdutoManutencao?ordem=+pro_descricao${outrosFiltros}`).then(res => {
            if (res.status === 200) {
                let keys = [];
                res.data.map((item, idx) => {
                    item.key = idx;
                    keys.push(item.key);
                })
                setSelectedRowKeys(keys);
                setMarcado(true);
                setDadosProdutos(res.data.map((item, idx) => ({ key: idx, ...item })));
            }
        }, err => {
            console.log(err.response);
        }).finally(() => setCarregando(false));
    }

    function marcarDesmarcarTodos(e) {
        let keys = [];
        if (e.target.checked) {
            for (const item of dadosProdutos) {
                keys.push(item.key);
            }
        }
        setMarcado(e.target.checked);
        setSelectedRowKeys(keys);
    }

    const rowSelection = {
        selectedRowKeys,
        hideSelectAll: true,
        onChange: (keys, selectedRows) => {
            setSelectedRowKeys(keys);
        },
    };

    function confirmarIcms(values) {
        Modal.confirm({
            title: 'Deseja continuar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Essa operação irá alterar o ICMS dos itens selecionados! Após confirmar, não poderá ser desfeito!',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                gerarManutencao(values);
            }
        });
    };

    function confirmar(values) {
        Modal.confirm({
            title: 'Deseja continuar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Essa operação irá alterar os itens selecionados! Após confirmar, não poderá ser desfeito!',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                gerarManutencao(values);
            }
        });
    };

    function validarManutencao(values) {
        if (selectedRowKeys.length === 0) {
            notification.warning({ message: 'Aviso!', description: 'É necessário selecionar pelo menos um item!' });
            return;
        }
        if (!!values.pro_eaninvalido) {
            gerarManutencao(values);
        } else {
            if (!values.icm_id && !values.ncm_id) {
                notification.warning({ message: 'Aviso!', description: 'Favor informar o NCM ou ICMS que deseja alterar!' });
                return;
            }
            if (considerarVigencia && !!values.icm_id && !values.vig_id) {
                notification.warning({ message: 'Aviso!', description: 'Favor informar a vigência!' });
                return;
            }
            if (considerarVigencia && !!values.icm_id) {
                gerarManutencao(values);
            } else {
                confirmar(values);
            }
        }
    };

    function gerarManutencao(values) {
        let dados = {};
        dados.produtos = [];
        for (const item of dadosProdutos) {
            if (selectedRowKeys.indexOf(item.key) >= 0) {
                dados.produtos.push({ pro_id: item.pro_id, icm_id: item.icm_id });
            }
        }
        dados.ncm_id = !!values.ncm_id ? values.ncm_id : null;
        dados.cen_id = !!values.cen_id ? values.cen_id : null;
        dados.vig_id = !!values.vig_id ? values.vig_id : null;
        dados.icm_id = !!values.icm_id ? values.icm_id : null;
        dados.pro_eaninvalido = !!values.pro_eaninvalido ? values.pro_eaninvalido : null;
        setCarregandoGerar(true);
        api.post(`Produto/ManutencaoProduto`, dados).then(res => {
            if (res.data) {
                notification.success({ message: 'Aviso!', description: 'Manutenção realizada com sucesso!' });
                form.setFieldsValue({ produtos: [] });
                setSelectedRowKeys([]);
            } else {
                notification.error({ message: 'Aviso!', description: 'Não foi possível realizar a manutenção dos produtos selecionados!' });
            }
        }, err => {
            console.log(err.response);
        }).finally(() => {
            buscarProdutos();
            setCarregandoGerar(false);
        });
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <BotaoAbrirChamado />
                </Col>
            </Row>
            <div>
                <Form layout="vertical" form={form}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                            <Form.Item label="Localize o produto" name="filtro">
                                <Input placeholder="Buscar por código, descrição ..." allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4} lg={4} xl={2} xxl={2}>
                            <Form.Item label="Localize o NCM" name="filtroNcm">
                                <Input placeholder="Buscar NCM" allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={4}>
                            <Form.Item label="Localize o CEST" name="filtroCest">
                                <Input placeholder="Buscar CEST" allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                            <Form.Item label="ICMS" name="icm_id">
                                <SelectPaginado url="tributacaoIcms/Listar" placeholder="Selecione o ICMS" form={form} name="icm_id" conteudo={
                                    icm => (<Select.Option value={icm.icm_id} key={icm.icm_id}>{icm.icm_id} - {icm.icm_descricao}</Select.Option>)
                                }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                            <Form.Item label="NCM" name="ncm_id">
                                <SelectPaginado url="ncm/Listar" placeholder="Selecione o NCM" form={form} name="ncm_id" onChangeFunction={(ncm) => { buscarCest(ncm) }} conteudo={
                                    ncm => (<Select.Option value={ncm.ncm_id} key={ncm.ncm_id}>{ncm.ncm_codigo.codigoNcm} - {ncm.ncm_descricao}</Select.Option>)
                                }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={10} xl={6} xxl={6}>
                            <Form.Item label="CEST" name="cen_id">
                                <Select disabled={listaCest.length === 0} allowClear showSearch optionFilterProp="children" placeholder="Selecione a Classificação do CEST" >
                                    {listaCest.map((cest) => (
                                        <Select.Option value={cest.cen_id} key={cest.cen_id}>{cest.cet_id} - {cest.cet_descricao}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="check-top" xs={24} sm={8} md={6} lg={6} xl={6} xxl={4}>
                            <Form.Item name="SomenteNcmInativos" valuePropName="checked" initialValue={false}>
                                <Checkbox>Somente NCM inativos?</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col className="check-top" xs={24} sm={8} md={6} lg={6} xl={6} xxl={4}>
                            <Form.Item name="EANInvalidos" valuePropName="checked" initialValue={false}>
                                <Checkbox>
                                    <Tooltip title="Produtos com EAN inválidos que estão com o controle 'Não Gerar EAN em Documento Fiscal?' desmarcados no cadastro do produto">
                                        <span>Somente EAN Inválidos</span>
                                    </Tooltip>
                                </Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[8, 0]} justify="end"> 
                        <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={5}>
                            <Row gutter={[8, 8]} justify="end" className="tt-2">
                                <Col xs={24} sm={12} md={5} lg={8} xl={12} xxl={12}>
                                    <Button type="primary" icon={<SearchOutlined />} block onClick={() => buscarProdutos()}>
                                        Aplicar Filtros
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="tabela mt-dif m-t-0">
                <Col className="p-l-5" xs={24} sm={24} md={8} lg={10} xl={12}>
                    <Checkbox onChange={marcarDesmarcarTodos} checked={marcado} defaultChecked={true}>
                        Marcar/Desmarcar Todos
                    </Checkbox>
                </Col>
                <Table
                    rowSelection={{
                        ...rowSelection,
                    }}
                    preserveSelectedRowKeys={true}
                    loading={carregando}
                    dataSource={dadosProdutos}
                    columns={[
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ pro_codigo }) => (
                                <Tag color="processing" className="w-100">
                                    <b>{pro_codigo}</b>
                                </Tag>
                            ),
                        },
                        {
                            title: 'EAN',
                            align: 'center',
                            width: 90,
                            render: ({ pro_ean }) => (
                                <b>{pro_ean}</b>
                            ),
                        },
                        {
                            title: 'Descrição',
                            render: ({ pro_descricao }) => (
                                <b>{pro_descricao}</b>
                            ),
                        },
                        {
                            title: 'ICMS',
                            render: ({ icm_id, icm_descricao }) => (
                                <b>{icm_id} - {icm_descricao}</b>
                            ),
                        },
                        {
                            title: 'NCM',
                            render: ({ ncm_codigo }) => (
                                <b>{ncm_codigo}</b>
                            ),
                        },
                        {
                            title: 'CEST',
                            render: ({ cet_id }) => (
                                <b>{!!cet_id ? cet_id : '-'}</b>
                            ),
                        }
                    ]} scroll={{ x: 900, size:20 }} />
            </div>
            <Form layout="vertical" className="m-t-5" form={formAlterar} onFinish={validarManutencao}>
                <Divider />
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={14} lg={10} xl={10} xxl={8}>
                        <Form.Item label="ICMS" name="icm_id">
                            <SelectPaginado url="tributacaoIcms/Listar" placeholder="Selecione o ICMS" form={formAlterar} name="icm_id" conteudo={
                                icm => (<Select.Option value={icm.icm_id} key={icm.icm_id}>{icm.icm_id} - {icm.icm_descricao}</Select.Option>)
                            }
                            />
                        </Form.Item>
                    </Col>
                    <Col className="check-vigencia" xs={24} sm={8} md={6} lg={6} xl={6} xxl={4}>
                        <Form.Item name="considerarVigencia" valuePropName="checked" initialValue={true}>
                            <Checkbox onChange={(e) => { setConsiderarVigencia(e.target.checked) }}>Considerar Vigência</Checkbox>
                        </Form.Item>
                    </Col>
                    {considerarVigencia && <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={5}>
                        <Form.Item label="Vigência" name="vig_id">
                            <SelectPaginado url="VigenciaFiscal/Listar" placeholder="Selecione a vigência" form={formAlterar} name="vig_id" conteudo={
                                vig => (<Select.Option value={vig.vig_id} key={vig.vig_id}>{vig.vig_id} - {vig.vig_descricao}</Select.Option>)
                            }
                            />
                        </Form.Item>
                    </Col>}
                </Row>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={14} lg={14} xl={10} xxl={10}>
                        <Form.Item label="NCM" name="ncm_id">
                            <SelectPaginado url="ncm/Listar" placeholder="Selecione o NCM" form={formAlterar} name="ncm_id" onChangeFunction={(ncm) => { buscarCest(ncm) }} conteudo={
                                ncm => (<Select.Option value={ncm.ncm_id} key={ncm.ncm_id}>{ncm.ncm_codigo.codigoNcm} - {ncm.ncm_descricao}</Select.Option>)
                            }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={10} xl={6} xxl={6}>
                        <Form.Item label="CEST" name="cen_id">
                            <Select disabled={listaCestAlterar.length === 0} allowClear showSearch optionFilterProp="children" placeholder="Selecione a Classificação do CEST" >
                                {listaCestAlterar.map((cest) => (
                                    <Select.Option value={cest.cen_id} key={cest.cen_id}>{cest.cet_id} - {cest.cet_descricao}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col className="check-vigencia" xs={24} sm={8} md={6} lg={6} xl={6} xxl={4}>
                        <Form.Item name="pro_eaninvalido" valuePropName="checked" initialValue={false}>
                            <Checkbox>Marcar EAN Inválidos</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]} justify="end">
                    <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={5} className="t-mob tm5">
                        <Row gutter={[8, 8]} justify="end" className="tt-5">
                            <Col xs={24} sm={12} md={6} lg={7} xl={8} xxl={8}>
                                <Button onClick={() => formAlterar.submit()} loading={carregandoGerar} size="large" type="primary" icon={<SaveOutlined />} block>
                                    Gerar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div >
    );
}