import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Divider, Table } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";

export default function TabCondicaoPagamento({ listaCondPag }) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [condicaoPagamento, setCondicaoPagamento] = useState([]);

    useEffect(() => {
        popularCondicao();
    }, []);

    function popularCondicao() {
        api.get('CondicaoPagamento/Ativos?PageSize=500').then(
            res => {
                let sequencia = 0;
                res.data.items.forEach((cond) => {
                    cond.key = sequencia;
                    sequencia++;
                })
                setCondicaoPagamento(res.data.items);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    const configSelecionarItem =
    {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            popularCondicaoPgto(selectedRowKeys, selectedRows);
        }
    };

    function popularCondicaoPgto(key, rows) {
        listaCondPag.setListaCondPag(rows);
    };

    useEffect(() => {
        if (!!listaCondPag.listaCondPag.length > 0) {
            let listIndex = [];
            condicaoPagamento.forEach((condPgto, index, array) => {
                listaCondPag.listaCondPag.forEach((condPag) => {
                    if (condPgto.cpg_id === condPag.cpg_id) {
                        listIndex.push(index);
                    }
                });
            });
            setSelectedRowKeys(listIndex);
        }
    }, [condicaoPagamento]);

    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-financeiro.png").default} alt="Condições de Pagamento" /> Condições de Pagamento
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Defina as condições de pagamento. Pode ser selecionadas uma ou mais na tabela abaixo.
                    </Divider>
                </Col>
            </Row>
            <div className="tabela">
                <Table dataSource={condicaoPagamento} rowSelection={configSelecionarItem}
                    columns={
                        [
                            {
                                title: 'Condições de Pagamento',
                                render: ({ cpg_descricao }) => (
                                    <div>
                                        <b>{cpg_descricao}</b>
                                    </div>
                                ),
                            },
                        ]
                    } locale={{
                        emptyText: (
                            <Row>
                                <Col span={24}>
                                    <Divider orientation="center">
                                        <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                    </Divider>
                                </Col>
                            </Row>
                        )
                    }} />
            </div>
        </div>
    );

}