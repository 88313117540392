import React, { useState } from "react";
import { Row, Col, Button, Tag, Dropdown, Menu, Modal, notification } from "antd";
import { DollarCircleOutlined, DeleteOutlined, MoreOutlined, PrinterOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import DrawerCondicional from './drawer';
import { useStateValue } from '../../state';
import DrawerDevolucao from './drawerDevolucao';
import { FormatFone, FormatNumber } from "../../ValueObjects";
import { TabelaDados, EditarTabela, Excluir, Imprimir } from '../../components';
import { listagemActions, manutencaoActions, drawerActions } from '../../actions';
import { ModalProdutoCondicional, ModalContatoCliente } from "../../components/modals/";
import DrawerNFExtra from "./drawerNFExtra";

export default function TabCondicionalRetirada({ situacaoVenda, dataPesquisa, imprimirCondicional }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [exibirDrawerDevolucao, setExibirDrawerDevolucao] = useState(false);
    const [openModalContatoCliente, setOpenModalContatoCliente] = useState(false);
    const [openModalProdutoCondicional, setOpenModalProdutoCondicional] = useState(false);
    const [exibirDrawerNota, setExibirDrawerNota] = useState(false);
    const [dados, setDados] = useState({});
    const [pessoaId, setPessoaId] = useState();

    function menuTable(registro) {
        return (<Menu className="text-right">
            <Menu.Item key="1">
                <Excluir hidden={registro.vnc_situacao === 2} type="text" icon={<DeleteOutlined />} onClick={() => { modalExcluirCondicional(registro) }}> Estornar</Excluir>
            </Menu.Item>
            <Menu.Item key="2">
                <Imprimir type="text" icon={<PrinterOutlined />} onClick={() => imprimirCondicional(registro)}> Imprimir </Imprimir>
            </Menu.Item>
        </Menu>);
    };

    function descricaoSituacao(codigo) {
        if (codigo >= 0 && !!situacaoVenda[codigo]) {
            return situacaoVenda[codigo].value;
        }
        return "";
    };

    function retornaCorSituacao(codigo) {
        let cor = "";
        switch (codigo) {
            case 0:
                cor = "processing";
                break;
            case 1:
                cor = "green";
                break;
            case 2:
                cor = "#87d068";
                break;
            case 3:
                cor = "orange";
                break;
            case 4:
                cor = "red";
                break;
        }
        return cor;
    };

    function deletarCondicional(registro) {
        api.put(`VendaCondicional/Excluir/${registro.vnc_id}`).then(
            (res) => {
                notification.success({ message: `Sucesso!`, description: `Venda Condicional estornada com sucesso!` })
            }
        ).catch((erro) => {
            console.log(erro);
        }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+vnc_datalancamento' } });
            });
    };

    const modalExcluirCondicional = (condicional) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente estornar a condicional do cliente ${condicional.cli_nome} contendo ${condicional.vnc_totalitens} itens com valor total de R$ ${!!condicional.vnc_valortotal ? condicional.vnc_valortotal : '0,00'} ?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                deletarCondicional(condicional);
            }
        });
    };

    function abrirModalItens(record) {
        setDados(record);
        setOpenModalProdutoCondicional(true);
    }
    function abrirModalContatos(record) {
        setPessoaId(record);
        setOpenModalContatoCliente(true);
    }

    async function editarCondicional(registro) {
        registro.vnc_datalancamento = moment(registro.vnc_datalancamento);
        registro.vnc_prazoretorno = moment(registro.vnc_prazoretorno);
        let dadosTemp = await api.get(`VendaCondicionalItem/Listar?IdVendaCondicional=${registro.vnc_id}`);
        registro.itens = dadosTemp.data;
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: registro } });
    };


    return (
        <div className="tabela">
            <TabelaDados url="VendaCondicional/Listar" condicao={(campo) => { return campo.vnc_levarparacliente === false }} colunas={
                [
                    {
                        title: 'Situação',
                        width: 140,
                        align: 'center',
                        render: ({ vnc_situacao }) => (
                            <div>
                                <Tag color={retornaCorSituacao(vnc_situacao)} className="w-100">
                                    <b>{descricaoSituacao(vnc_situacao)}</b>
                                </Tag>
                            </div>
                        ),
                    },
                    {
                        title: 'Nº',
                        width: 100,
                        align: 'center',
                        render: ({ vnc_numero }) => (
                            <div>
                                <b>{vnc_numero}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Descrição',
                        render: ({ cli_nome }) => (
                            <div>
                                <b>{cli_nome}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Telefone',
                        width: 110,
                        render: ({ vnc_telefonecliente, pes_id }) => (
                            <div>
                                <Button type="link" onClick={() => abrirModalContatos(pes_id)} className="p-0">
                                    {FormatFone(vnc_telefonecliente)}
                                </Button>
                            </div>
                        ),
                    },
                    {
                        title: 'Data Lançamento',
                        width: 135,
                        render: ({ vnc_datalancamento }) => (
                            <div>
                                <b>{moment(vnc_datalancamento).format("DD/MM/YYYY HH:mm:ss")}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Data Retorno',
                        width: 135,
                        render: ({ vnc_prazoretorno }) => (
                            <div>
                                <b>{moment(vnc_prazoretorno).format("DD/MM/YYYY HH:mm:ss")}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Itens',
                        align: 'center',
                        width: 100,
                        render: ({ vnc_totalitens }, record) => (
                            <div>
                                <Button type="link" onClick={() => abrirModalItens(record)} >
                                    {vnc_totalitens}
                                </Button>
                            </div>
                        ),
                    },
                    {
                        title: 'Total (R$)',
                        align: 'right',
                        width: 150,
                        render: ({ vnc_valortotal }) => (
                            <div>
                                <b className="f-18">{FormatNumber(vnc_valortotal, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 95,
                        fixed: 'right',
                        render: (record, registro) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button hidden={record.vnc_situacao === 2} onClick={() => {
                                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                            setExibirDrawerDevolucao(true);
                                        }} icon={<DollarCircleOutlined />} />
                                    </Col>
                                    <Col>
                                        <EditarTabela hidden={registro.vnc_situacao === 2} registro={registro} onClick={() => editarCondicional(registro)} />
                                    </Col>
                                    <Col>
                                        <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />

            <DrawerDevolucao dataPesquisa={dataPesquisa} exibirDrawerDevolucao={{ exibirDrawerDevolucao, setExibirDrawerDevolucao }} setExibirDrawerNota={setExibirDrawerNota}/>
            <DrawerNFExtra drawerExtra={{ exibirDrawerNota, setExibirDrawerNota }} />
            <DrawerCondicional />
            <ModalProdutoCondicional exibirModalProdutoCondicional={openModalProdutoCondicional} fecharModalProdutoCondicional={() => setOpenModalProdutoCondicional(false)} dadosItens={dados} situacaoVenda={situacaoVenda} />
            <ModalContatoCliente exibirModalContatoCliente={openModalContatoCliente} fecharModalContatoCliente={() => setOpenModalContatoCliente(false)} PessoaId={pessoaId} />
        </div>
    );
}