import React, { useEffect } from "react";
import { Row, Col, Form, InputNumber, notification, Modal } from "antd";
import { ExclamationCircleOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";

import { useStateValue } from '../../../state';
import { manutencaoActions } from "../../../actions";

export default function ModalDevolucaoCondicional({ exibirModal, fecharModal, dados, dadosItens, setDadosItens }) {

    const [formDados] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => {
        if (!!dados) {
            formDados.setFieldsValue(dados);
            formDados.setFieldsValue({ vci_quantidadeDevolvida: dados.vci_devolvido });
        } else {
            formDados.resetFields();
        }
    }, [dados]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
            }
        });
    };

    const salvarDados = values => {
        if (values.vci_quantidadeDevolvida > dados.vci_quantidade) {
            return (
                notification.warning({
                    message: "Aviso",
                    description: "Valor informado maior a que quantidade pendente",
                })
            )
        } else if (values.vci_quantidadeDevolvida < 0) {
            return (
                notification.warning({
                    message: "Aviso",
                    description: "Valor informado menor que 0, favor informar um valor valido",
                })
            )
        }
        dadosItens.filter((item) => { return item.vci_id === dados.vci_id })[0].vci_devolvido = values.vci_quantidadeDevolvida;
        if (dadosItens.filter((item) => { return item.vci_id === dados.vci_id })[0].vci_devolvido === dadosItens.filter((item) => { return item.vci_id === dados.vci_id })[0].vci_quantidade) {
            dadosItens.filter((item) => { return item.vci_id === dados.vci_id })[0].devolvidoTotal = true;
        }else{
            dadosItens.filter((item) => { return item.vci_id === dados.vci_id })[0].devolvidoTotal = false;
        }
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dadosItens } });
        fecharModal();
    }

    return (
        <Modal centered
            title="Devolução de Itens Condicionais"
            visible={exibirModal}
            onCancel={onCloseModal}
            width={420}
            onOk={() => { formDados.submit() }}
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            okText={
                <>
                    <CheckOutlined /> Ok
                </>
            }
            maskClosable={false}
        >
            <Form layout="vertical" name="manutencaoOrigemDestino" form={formDados} onFinish={salvarDados}>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Quantidade devolução" name="vci_quantidadeDevolvida" rules={[{ required: true, message: 'Informe a quantidade' }]}>
                            <InputNumber placeholder="Informe a quantidade" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}