import React, { useState } from "react";
import { Row, Col, Form, Input, Typography, Modal, Button } from "antd";
import { CloseOutlined, ExclamationCircleOutlined, SaveOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { getEmpresa } from "../../../services/auth";

export default function ModalAdicionarUsuario({ exibirModal, fecharModal, incluirRemoverUsuario }) {

    const [listaLocais, setListaLocais] = useState([]);
    const [form] = Form.useForm();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Realmente deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
                form.resetFields();
                setListaLocais([]);
            }
        });
    };

    function verificarEmail() {
        if (!!form.getFieldValue('usu_email')) {
            api.get(`Usuario/Usuario?email=${form.getFieldValue('usu_email')}`).then((retornoEmail) => {
                if (!!retornoEmail.data) {
                    incluirRemoverUsuario({ usu_email: retornoEmail.data.usu_email.endereco }, true, 'Usuário vinculado ao grupo com sucesso!');
                    fecharModal();
                    form.resetFields();
                    setListaLocais([]);
                }
            }).catch((err) => {
                console.log(err.response);
            });
        }
    };

    function listarLocais() {
        if (!!form.getFieldValue('usu_email') && !!form.getFieldValue('usu_nome')) {
            api.get(`Usuario/ListarLocais?codigoEmpresa=${getEmpresa()}`).then((lista) => {
                if (!!lista.data) {
                    let filiais = [];
                    lista.data.forEach((local, indice) => {
                        local.selected = false;
                        filiais.push(local);
                        if (indice + 1 === lista.data.length) {
                            setListaLocais(filiais);
                        }
                    });
                }
            }).catch((err) => {
                console.log(err.response);
            });
        } else {
            setListaLocais([]);
        }
    };

    function selecionarLocal(id) {
        let lista = [...listaLocais];
        let indice = listaLocais.findIndex((local) => {
            return local.loc_id === id;
        });
        if (indice >= 0) {
            lista[indice].selected = !lista[indice].selected;
        }
        setListaLocais(lista);
    };

    function selecionarTodos(checked) {
        let lista = [...listaLocais];
        lista.forEach((local) => {
            local.selected = checked;
        });
        setListaLocais(lista);
    };
    
    function incluirUsuario(values){
        //values.usu_ativo = true;
        /*
        NÃO IMPLEMENTADO
        api.post(`Usuario/Incluir?email=${values.usu_email}&nome=${values.usu_nome}`).then((retornoUsuario) => {
            if (!!retornoUsuario.data) {
                let informacoesUsuario = {
                    id: retornoUsuario.data,
                    nome: values.usu_nome,
                    email: values.usu_email,
                    chave: (values.usu_email + "|" + values.usu_nome)
                }

            }
        }, err => {
            console.log(err);
        });*/
    };

    return (

        <Modal centered
            title="Novo Usuário"
            visible={exibirModal}
            onCancel={onCloseModal}
            destroyOnClose
            footer={listaLocais.length > 0 ?
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onCloseModal} icon={<CloseOutlined />} size="middle" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => form.submit()} icon={<SaveOutlined />} size="middle" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
                : null
            }
            width={720}
            maskClosable={false}
        >
            <Form layout="vertical" initialValues={{ remember: true }} form={form} onFinish={incluirUsuario}>
                <Row gutter={[0, 8]}>
                    <Col span={24}>
                        <Typography.Text>
                            Adicione um novo usuário.
                        </Typography.Text>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="E-mail" name="usu_email" rules={[{ required: true, message: 'Informe o E-mail do Usuário' }]}>
                            <Input placeholder="Informe o E-mail do Usuário" onBlur={() => { verificarEmail() }} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Nome" name="usu_nome" rules={[{ required: true, message: 'Informe o Nome do Usuário' }]}>
                            <Input placeholder="Informe o Nome do Usuário" onChange={() => { listarLocais() }} />
                        </Form.Item>
                    </Col>
                </Row>
                {/*listaLocais.length > 0 &&
                    <Table dataSource={listaLocais} columns={
                        [
                            {
                                title: <Checkbox onChange={(e) => { selecionarTodos(e.target.checked) }} />,
                                align: 'center',
                                render: ({ selected, loc_id }) => (
                                    <Checkbox checked={selected} onChange={() => { selecionarLocal(loc_id) }} />
                                ),
                            },
                            {
                                title: 'Filial',
                                align: 'left',
                                render: ({ loc_id, loc_descricao }) => (
                                    <div>
                                        <b>{loc_id} - {loc_descricao}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'CNPJ',
                                render: ({ loc_cnpj }) => (
                                    <div>
                                        <b>{loc_cnpj.numero}</b>
                                    </div>
                                ),
                            },
                        ]
                    } />
                */}
            </Form>
        </Modal>

    );
}