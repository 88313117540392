import React, { useEffect, useState } from "react";
import { Row, Col, Tag, Popover, notification, Modal, Button, Menu, Dropdown, Form, Collapse, Radio } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, MoreOutlined, CloudUploadOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from 'moment';

import api from "../../services/api";
import DrawerFornecedor from './drawer';
import { useStateValue } from '../../state';
import { FormatFone, MaskFormat } from "../../ValueObjects";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { PesquisaOrdenacao, TabelaDados, Editar, Excluir, BreadcrumbPage, BotaoAbrirChamado, BotaoIncluirDados, PesquisaAvancada } from "../../components";

export default function Fornecedor() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [editando, setEditando] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        let _ativo = !!!form.getFieldsValue().fornecedorAtivo ? 1 : form.getFieldsValue().fornecedorAtivo;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&ativo=${_ativo}`, ordem: '+pes_nome' } })
        form.setFieldsValue({ fornecedorAtivo: 1 });
    }, []);

    function editarFornecedor(record) {
        setEditando(true);
        record.pef_datanascimento = moment(record.pef_datanascimento);
        record.pej_datafundacao = !!record.pej_datafundacao ? moment(record.pej_datafundacao) : null;
        record.cpfCnpj = !!record.pef_cpf ? record.pef_cpf : (!!record.pej_cnpj ? record.pej_cnpj : '');
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    const togglePesquisa = () => {
        setOpen(!open);
    };

    function modalExcluir(record) {
        Modal.confirm({
            title: 'Aviso',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o Fornecedor?`,
            okText: 'Excluir',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluir(record);
            }
        });
    }

    function excluir(record) {
        api.delete(`Fornecedor/Excluir/${record.for_id}`).then(
            (res) => {
                if (res.status === 200) {
                    notification.success({ message: 'Aviso', description: 'Fornecedor excluído com sucesso!' });
                }
            }
        ).catch(
            (erro) => {
                modalInativarRegistro(record);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&ativo=${form.getFieldValue().fornecedorAtivo}`, ordem: '+pes_nome' } });
            }
        )
    };

    const modalInativarRegistro = (record) => {
        Modal.confirm({
            title: 'Aviso',
            icon: <ExclamationCircleOutlined />,
            content: `Não foi possível excluir o fornecedor ${record.pes_nome}. Deseja inativar este registro?`,
            okText: 'Inativar',
            cancelText: 'Não',
            centered: true,
            onOk() {
                inativarRegistro(record);
            }
        });
    };

    function inativarRegistro(record) {
        api.put(`Fornecedor/InativarFornecedor/${record.for_id}`).then(
            res => {
                notification.success({ description: 'Registros inativado com sucesso!', message: 'Aviso' });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&ativo=${form.getFieldValue().fornecedorAtivo}`, ordem: '+pes_nome' } });
            }
        )
    };

    const menuOutros = () => {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="text" icon={<CloudUploadOutlined />} onClick={() => {
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { entidade: 1 } } });
                    }}>
                        <Link to="/importarPessoa"> Importar Fornecedor </Link>
                    </Button>
                </Menu.Item>
            </Menu >
        )
    };

    function retornoPesquisaAvancada(expressao) {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&ativo=${form.getFieldValue().fornecedorAtivo}&filtroAdicional=${btoa(expressao)}`, ordem: '+pes_nome' } })
    };

    function limparFiltroPesquisa() {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&ativo=${form.getFieldValue().fornecedorAtivo}`, ordem: '+pes_nome' } })
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoAbrirChamado />
                        </Col>
                        <Col>
                            <BotaoIncluirDados />
                        </Col>
                        <Col>
                            <Dropdown overlay={menuOutros()} trigger={['click']}>
                                <Button size="middle" onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form layout="vertical" name="formularioPesqAvancadaFornecedores" form={form} onFinish={retornoPesquisaAvancada}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={16}>
                        <PesquisaOrdenacao opcoesOrdenacao={[
                            { label: "Nome A - Z", value: "+pes_nome" },
                            { label: "Nome Z - A", value: "-pes_nome" },
                            { label: "Código Crescente", value: "+for_codigo" },
                            { label: "Código Decrescente", value: "-for_codigo" }
                        ]} />
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9} xl={6}>
                        <Form.Item label="Selecione Status do Fornecedor" name="fornecedorAtivo">
                            <Radio.Group onChange={() => retornoPesquisaAvancada()}>
                                <Radio value={2}>Todos</Radio>
                                <Radio value={1}>Ativo</Radio>
                                <Radio value={0}>Inativo</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Collapse className="p-relative mt-coll" accordion ghost destroyInactivePanel={true}>
                            <div className="colDet"></div>
                            <Collapse.Panel key="1" showArrow={false} extra={
                                <Button type="primary" onClick={togglePesquisa} block>
                                    {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                </Button>
                            }>
                                <PesquisaAvancada funcaoRetorno={(dados) => retornoPesquisaAvancada(dados)} funcaoLimpar={() => { limparFiltroPesquisa() }} form={form} telaFiltro={4} />
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="Fornecedor/Listar" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ for_codigo, for_ativo }) => (
                            <Popover content={for_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                <Tag color={for_ativo ? 'processing' : 'default'} className="w-100">
                                    <b>{for_codigo}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Fornecedor',
                        render: ({ pes_nome }) => (
                            <div>
                                <b>{pes_nome}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Nome Fantasia',
                        render: ({ pej_nomefantasia }) => (
                            <div>
                                <b>{pej_nomefantasia || 'Não Informado'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'CPF/CNPJ',
                        width: 138,
                        render: ({ pef_cpf, pej_cnpj }) => (
                            <div>
                                <b>{!!pef_cpf ? MaskFormat(pef_cpf, 'Não Informado', true) : MaskFormat(pej_cnpj, 'Não Informado', true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Telefone',
                        width: 120,
                        render: ({ psc_numero }) => (
                            <div>
                                <b>{FormatFone(psc_numero) || 'Não Informado'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Endereço',
                        render: ({ log_logradouro, pee_numero, bai_nome, cep_cep, cid_descricao, est_sigla }) => (
                            <div>
                                <b>{log_logradouro || 'Não Informado'}{(!!pee_numero) ? (', ' + pee_numero) : ''}{!!(bai_nome) ? (', ' + bai_nome) : ''} {!!(cep_cep) ? ('- ' + cep_cep) : ''} {!!(cid_descricao) ? ('- ' + cid_descricao) : ''} {!!(est_sigla) ? ('- ' + est_sigla) : ''}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        align: 'center',
                        fixed: 'right',
                        width: 65,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Editar onClick={() => {
                                            editarFornecedor(record);
                                        }} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>
                                        <Excluir onClick={() => {
                                            modalExcluir(record)
                                        }} icon={<DeleteOutlined />} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} expandir={({ pej_nomefantasia, pef_rg, pej_inscricaoestadual, pem_email }) => {
                    return (
                        <div>
                            <Row align="middle" gutter={[16, 0]}>
                                {!!pej_nomefantasia ? <Col>
                                    Nome Fantasia: <b>{pej_nomefantasia}</b>
                                </Col> : null}
                                {!!pef_rg && <Col>
                                    RG: <b>{pef_rg || 'Não Informado'}</b>
                                </Col>}
                                {!!pej_inscricaoestadual && <Col>
                                    IE: <b>{pej_inscricaoestadual || 'Não Informado'}</b>
                                </Col>}
                                <Col>
                                    E-mail: <b>{pem_email || 'Não Informado'}</b>
                                </Col>
                            </Row>
                        </div>
                    )
                }} />
            </div>
            <DrawerFornecedor editando={{ editando, setEditando }} />
        </div>
    );

}