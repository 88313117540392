import React, { useEffect } from "react";
import { Typography, Row, Col, Form, Divider, Input } from "antd";

export default function TabVeiculo({ form, dadosVeiculos }) {

    useEffect(() => {
        if (!!dadosVeiculos) {
            form.setFieldsValue({ trv_placa: dadosVeiculos.trv_placa });
            form.setFieldsValue({ trv_uf: dadosVeiculos.trv_uf });
            form.setFieldsValue({ trv_rntc: dadosVeiculos.trv_rntc });
            form.setFieldsValue({ trv_modelo: dadosVeiculos.trv_modelo });
            form.setFieldsValue({ trv_marca: dadosVeiculos.trv_marca });
            form.setFieldsValue({ trv_observacao: dadosVeiculos.trv_observacao });
        }
    }, [dadosVeiculos]);

    return (
        <div>
            <Row align="middle" gutter={[0, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-car.png").default} alt="Veículo" /> Veículo
                    </Typography.Title>
                    <Divider orientation="left" plain> Informe abaixo os dados do Veículo utilizado para o transporte. </Divider>
                </Col>
            </Row>
            <Row gutter={[8, 0]} className="m-t-16">
                <Col xs={24} sm={6} md={5} lg={5} xl={5}>
                    <Form.Item label="Placa do Veículo" name="trv_placa">
                        <Input placeholder="Informe a Placa" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={3} lg={3} xl={3}>
                    <Form.Item label="UF" name="trv_uf">
                        <Input placeholder="Informe o UF" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={5} lg={5} xl={5}>
                    <Form.Item label="RNTC" name="trv_rntc">
                        <Input placeholder="Informe o RNTC" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                    <Form.Item label="Marca" name="trv_marca">
                        <Input placeholder="Informe a Marca" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={5} lg={5} xl={5}>
                    <Form.Item label="Modelo" name="trv_modelo">
                        <Input placeholder="Informe o Modelo" />
                    </Form.Item>
                </Col>
            </Row>
            <Row align="middle" gutter={[0, 16]}>
                <Col span={24}>
                    <Form.Item label="Observações ref. ao Veículo" name="trv_observacao">
                        <Input.TextArea placeholder="Observações ref. ao Veículo" />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}