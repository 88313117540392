import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Table, Tag, InputNumber, Input, Modal, notification } from "antd";
import { PlusOutlined, MinusOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import InputPreco from "../../inputPreco";
import { FormatNumber } from "../../../ValueObjects";
import { isObjetoDiffVazio } from "../../../services/funcoes";

export default function ModalProdutoGrade({ exibirModal, fecharModal, dados, acrescimoDesconto = true }) {
    const [form] = Form.useForm();
    const [ehPercentual, setEhPercentual] = useState(false);
    const [listaGrade, setListaGrade] = useState([]);
    const [listaGradeBkp, setListaGradeBkp] = useState([]);

    const onCloseModal = () => {
        fecharModal();
        setListaGradeBkp([]);
    };

    const salvarDados = () => {
        let grades = "";
        let listaItens = listaGradeBkp.filter(grade => grade.quantidade > 0);
        grades = listaItens.map(grade => grade.prg_id).join(',');
        //fecharModal(listaItens);
        api.get(`Produto/ListaProdutoPelaGrade?listaGrades=${grades}`).then(
            res => {
                let dados = res.data;
                dados.forEach((grade) => {
                    let dadosDaGrade = listaItens.filter((item) => (item.prg_id === grade.prg_id))[0];
                    grade.lpi_valorvenda = dadosDaGrade.lpi_valorvenda;
                    grade.quantidade = dadosDaGrade.quantidade;
                });
                fecharModal(dados);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                setListaGradeBkp([]);
            }
        );
    };

    useEffect(() => {
        if (!!exibirModal && !!dados && isObjetoDiffVazio(dados)) {
            buscarDadosGrade();
        }
    }, [exibirModal]);

    useEffect(() => {
        if (!!listaGradeBkp) {
            setListaGrade(listaGradeBkp);
        }
    }, [listaGradeBkp])

    function buscarDadosGrade() {
        api.get(`Produto/ListarGrades?idProduto=${dados.pro_id}`).then(
            res => {
                let dados = res.data;
                dados.forEach((item) => {
                    item.quantidade = 0;
                })
                setListaGradeBkp(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function calcularDesc() {

    };

    function calcularAcres() {

    };

    function alterarQuantidade(operacao, prg_id, qtd = 0) {
        let lista = [...listaGrade]
        let dados = lista.find((grade) => (grade.prg_id === prg_id));
        if (operacao === '+') {
            dados.quantidade++;
        } else if (operacao === '-') {
            if (dados.quantidade > 0) {
                dados.quantidade--;
            } else {
                notification.warn({ description: 'A quantidade informada não pode ser menor do que 0!', message: 'Aviso' });
                return;
            }
        } else {
            if (qtd > 0) {
                dados.quantidade = qtd;
            } else {
                notification.warn({ description: 'A quantidade informada não pode ser menor do que 0!', message: 'Aviso' });
                return;
            }
        }
        setListaGradeBkp(lista);
    };

    function pesquisaGrade(campo) {
        if (!!campo) {
            let dados = [...listaGradeBkp];
            dados.forEach(grade => {
                grade.modalidadesgrade.forEach((modalidade, idx) => {
                    if (idx === 0) {
                        grade.descricaoGrade = "";
                    }
                    grade.descricaoGrade += modalidade.mgp_descricao + " ";
                });
            });
            dados = dados.filter((grade) => (grade.ean.indexOf(campo) > -1 || grade.descricaoGrade.toLowerCase().indexOf(campo.toLowerCase()) > -1));
            setListaGrade(dados);
        } else {
            setListaGrade(listaGradeBkp);
        }
    };

    return (
        <Modal centered
            title="Grades Produto"
            visible={exibirModal}
            onCancel={onCloseModal}
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            onOk={() => { form.submit() }}
            okText={
                <>
                    <CheckOutlined /> Ok
                </>
            }
            width={780}
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="formGradeCondicional" form={form} onFinish={salvarDados} >
                    <Row gutter={[0, 16]}>
                        <Col span={24}>
                            <Form.Item name="pesquisa">
                                <Input.Search placeholder="Localizar um item da grade" onSearch={(campo) => { pesquisaGrade(campo) }} allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="tabela">
                        <Table columns={[
                            {
                                title: 'Código',
                                width: 112,
                                align: 'center',
                                render: ({ prg_ean }) => (
                                    <div>
                                        <Tag color="processing">
                                            <b>{prg_ean}</b>
                                        </Tag>
                                    </div>
                                ),
                            }, {
                                title: 'Grade',
                                render: ({ modalidadesgrade }) => (
                                    <div>
                                        {modalidadesgrade.map(atrib => (
                                           <b> {atrib.mgp_descricao} </b>
                                        ))}
                                    </div>
                                ),
                            }, acrescimoDesconto ? {
                                title: () => {
                                    return (
                                        <div>
                                            <Row justify="center" gutter={[5, 0]}>
                                                <Col>
                                                    Desconto/Acréscimo
                                                </Col>
                                                <Col>
                                                    <Button size="small" onClick={() => { setEhPercentual(!ehPercentual) }}>{(ehPercentual ? 'R$' : '%')}</Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                },
                                width: 175,
                                align: 'center',
                                render: (record, { prg_id }) => (
                                    <Row gutter={[5, 0]}>
                                        <Col span={12}>
                                            <Form.Item name={`percDesc${prg_id}`} label="Desc." hidden={!ehPercentual} initialValue={0}>
                                                <InputNumber
                                                    defaultValue={0}
                                                    precision={2}
                                                    min={0}
                                                    max={100}
                                                    formatter={value => `${value}%`}
                                                    parser={value => value.replace('%', '')}
                                                    onBlur={() => { calcularDesc('P', record) }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item name={`percAcres${prg_id}`} label="Acresc." hidden={!ehPercentual} initialValue={0}>
                                                <InputNumber
                                                    defaultValue={0}
                                                    precision={2}
                                                    min={0}
                                                    max={100}
                                                    formatter={value => `${value}%`}
                                                    parser={value => value.replace('%', '')}
                                                    onBlur={() => { calcularAcres('P', record) }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <InputPreco name={`valorDesc${prg_id}`} hidden={ehPercentual} label="Desc. (R$)" onBlur={() => { calcularDesc('V', record) }} initialValue={0} />
                                        </Col>
                                        <Col span={12}>
                                            <InputPreco name={`valorAcresc${prg_id}`} hidden={ehPercentual} label="Acresc. (R$)" onBlur={() => { calcularAcres('V', record) }} initialValue={0} />
                                        </Col>
                                    </Row>
                                ),
                            } : {}, {
                                title: 'Quantidade',
                                width: 112,
                                align: 'center',
                                render: (record, { quantidade }) => (
                                    <Row align="middle"> 
                                        <Col span={6}>
                                            <Button type="primary" onClick={() => { alterarQuantidade("-", record.prg_id) }} icon={<MinusOutlined />} block className="h32" />
                                        </Col>
                                        <Col span={12}>
                                            <InputNumber name="quantidade" value={quantidade} min={0} precision={0} onChange={(valor) => { alterarQuantidade("", record.prg_id, valor) }} />
                                        </Col>
                                        <Col span={6}>
                                            <Button type="primary" onClick={() => { alterarQuantidade("+", record.prg_id) }} icon={<PlusOutlined />} block className="h32" />
                                        </Col>
                                    </Row>
                                ),
                            }, {
                                title: 'Valor (R$)',
                                align: 'right',
                                fixed: 'right',
                                width: 110,
                                render: ({ valorTotal, lpi_valorvenda, pre_custoultimacompra }) => (
                                    <div>
                                        <b>{FormatNumber(valorTotal ? valorTotal : lpi_valorvenda, true, false)}</b>
                                    </div>
                                ),
                            }
                        ]} dataSource={listaGrade} pagination={false} scroll={{ y: 285, x: 620  }} />
                    </div>
                </Form>
            </div>
        </Modal>

    );

};