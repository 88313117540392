import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, Checkbox, Modal } from "antd";
import { ExclamationCircleOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import SelectPaginacao from "../../selects/selectPaginacao";
import { manutencaoActions, selectPaginadoActions } from "../../../actions";
import { useStateValue } from "../../../state";

export default function ModalCfop({ exibirModal, fecharModal, formulario, adicionarCfop, listaDestinoOperacao, listaCategoriaOperacao }) {
    const [{ listaSelectPaginacao }, dispatch] = useStateValue();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
            }
        });
    };

    useEffect(() => {
        if (exibirModal && !!formulario) {
            var lista = [];
            if (!!formulario.getFieldValue().cfo_id) {
                lista.push({ name: "cfo_id", campo: "Id", value: formulario.getFieldValue().cfo_id });
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }
    }, [exibirModal]);

    function retornaDadosCFOP(values) {
        let listaDados = [...listaSelectPaginacao.itens];
        let dados = listaDados.find(o => o.cfo_id == values.cfo_id);
        values.cfo_cfop = dados.cfo_cfop;
        values.cfo_descricao = dados.cfo_descricao;
        adicionarCfop(values);
    };


    return (

        <Modal
            centered
            title="Código Fiscal de Operação - CFOP"
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={() => { formulario.submit() }}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            destroyOnClose
            maskClosable={false}
            width={700}
        >
            <div>
                <Form layout="vertical" name="formCfop" form={formulario} onFinish={(values) => retornaDadosCFOP(values)} initialValues={{ remember: true }}>
                    <Row align="middle" gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                            <Form.Item name="key" hidden />
                            <Form.Item name="opc_id" hidden initialValue={null} />
                            <Form.Item name="ope_id" hidden initialValue={null} />
                            <Form.Item label="Destino da Operação" name="opc_destinooperacao" rules={[{ required: true, message: 'Informe o Destino da Operação' }]}>
                                <Select showSearch optionFilterProp="children" placeholder="Selecione o Destino da Operação">
                                    {listaDestinoOperacao?.map(item => (
                                        <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Form.Item label="Categoria da Operação" name="opc_categoria" rules={[{ required: true, message: 'Informe a Categoria da Operação' }]}>
                                <Select showSearch optionFilterProp="children" placeholder="Selecione a Categoria da Operação">
                                    {listaCategoriaOperacao?.map(item => (
                                        <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="CFOP" name="cfo_id" rules={[{ required: true, message: 'Informe um CFOP' }]}>
                                <SelectPaginacao
                                    url="Cfop/Buscar"
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Selecione um CFOP"
                                    nameValue="cfo_id"
                                    nameLabel="cfo_cfop"
                                    form={formulario}
                                    conteudo={item => (
                                        <Select.Option key={item.cfo_id} value={item.cfo_id}>{item.cfo_cfop} - {item.cfo_descricao}</Select.Option>
                                    )} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="opc_ativo" valuePropName="checked" initialValue={true} hidden={(!(formulario.getFieldValue(['key']) > 0))}>
                                <Checkbox>CFOP Ativo?</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>

    );
}