import React from "react";
import { Row, Col, Modal, Table, Descriptions } from "antd";

import { linkApi } from "../../../services/auth";
import { FormatNumber } from "../../../ValueObjects";

export default function ModalTaxasCartaoCredito({exibirModalTaxasCartaoCredito, fecharModalTaxasCartaoCredito, dadosTaxaCartao}) {
    
    return (
        <Modal centered
            title={`Taxas Cartão: ${dadosTaxaCartao.bnd_nome}`}
            visible={exibirModalTaxasCartaoCredito}
            onCancel={fecharModalTaxasCartaoCredito}       
            maskClosable={false}
            footer={null}
        >
            <div>
                <Row align="middle" gutter={[8, 16]}>
                    <Col span={19}>
                        <Descriptions size="small">
                            <Descriptions.Item label="Credenciadora"> 
                                <b>{dadosTaxaCartao.bce_nomecredenciadora}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col span={5} className="img">
                        <img src={dadosTaxaCartao.imagemCartao} alt={dadosTaxaCartao.bnd_nome} />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table dataSource={dadosTaxaCartao.taxasCartaoCreditoParcelados} columns={
                            [
                                {
                                    title: 'De Parcela(s)',
                                    align: 'center',
                                    render: ({ tcp_parcelaminima }) => (
                                        <div>
                                            <b>{tcp_parcelaminima}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Até Parcela(s)',
                                    align: 'center',
                                    render: ({ tcp_parcelamaxima }) => (
                                        <div>
                                            <b>{tcp_parcelamaxima}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Taxa % Aplicada',
                                    align: 'right',
                                    render: ({ tcp_taxa }) => (
                                        <div>
                                            <b>{FormatNumber(parseFloat(tcp_taxa) * 100, true, false)}%</b>
                                        </div>
                                    ),
                                },
                            ]
                        } />
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}