import React, { useEffect, useState } from 'react';
import moment from 'moment';

import api from '../../../services/api';
import { Col, Row } from 'antd';
import { FormatNumber } from '../../../ValueObjects';

export default function FluxoCaixaMensalLocalSintetico({ valoresProvisionados, tabelaFluxoCaixa, saldos, form, filtros }) {

    return (
        <div>
            <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', align: 'center' }}>
                <Col align="center"><b>Fluxo de Caixa de {moment(filtros.dataInicial).format('DD/MM/YYYY')} a {moment(filtros.dataFinal).format('DD/MM/YYYY')}</b></Col>
            </div>
            <div>
                <div>
                    {!valoresProvisionados &&
                        <div className="tabela-fluxo m-t-19">
                            <Row className="b-color-secondary col-layout c-black t-head">
                                {/* Cabeçalho da Tabela */}
                                <Col align="start" style={{ width: '20%' }}><b>DATA</b></Col>
                                <Col align="right" style={{ width: '40%' }}><b>ENTRADA</b></Col>
                                <Col align="right" style={{ width: '40%' }}><b>SAÍDA</b></Col>
                            </Row>

                            <div className="t-body p-0">
                                {/* Mapeando os dados do fluxo de caixa */}
                                {tabelaFluxoCaixa.map((fluxoLocal, index) => (
                                    <Row key={index} className="row">
                                        <Col align="start" style={{ width: '20%' }}>
                                            {fluxoLocal.mesExtenso + "/" + fluxoLocal.ano}
                                        </Col>
                                        <Col align="right" style={{ width: '40%' }}>
                                            {FormatNumber(fluxoLocal.realizadoEntradaMesAno, true, false)}
                                        </Col>
                                        <Col align="right" style={{ width: '40%' }}>
                                            {FormatNumber(fluxoLocal.realizadoSaidaMesAno, true, false)}
                                        </Col>
                                    </Row>
                                ))}

                                {/* Linha de Totais */}
                                <Row className="c-primary row">
                                    <Col style={{ width: '20%' }}><b>TOTAL DO PERÍODO</b></Col>
                                    <Col align="right" style={{ width: '40%' }}>
                                        <b>{FormatNumber(saldos.realizadoEntrada, true)}</b>
                                    </Col>
                                    <Col align="right" className="c-red" style={{ width: '40%' }}>
                                        <b>{FormatNumber(saldos.realizadoSaida, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    }
                    {valoresProvisionados &&
                        <div className="tabela-fluxo m-t-19">
                            <Row className="b-color-secondary col-layout c-black t-head">
                                {/* <Col span={6}><b>FLUXO DE CAIXA DE <span className="f-14">{moment(form.getFildValue().flx_mesInicial).format('DD/MM/YYYY')}</span> A <span className="f-14">{moment(form.getFildValue().flx_mesFinal).format('DD/MM/YYYY')}</span></b></Col> */}
                                <Col align="start" style={{ width: '20%' }}><b>DATA</b></Col>
                                <Col style={{ width: '40%' }}>
                                    <Row>
                                        <Col style={{ width: '80%' }} align="right"><b>ENTRADA</b></Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ width: '20%' }} align="right" ><b></b></Col>
                                        <Col style={{ width: '30%' }} align="right" ><b>PREVISTO</b></Col>
                                        <Col style={{ width: '10%' }} align="right" ><b></b></Col>
                                        <Col style={{ width: '40%' }} align="right" ><b>REALIZADO</b></Col>
                                    </Row>
                                </Col>
                                <Col style={{ width: '40%' }}>
                                    <Row>
                                        <Col align="right" style={{ width: '80%' }} ><b>SAÍDA</b></Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ width: '20%' }} align="right" ><b></b></Col>
                                        <Col style={{ width: '30%' }} align="right" ><b>PREVISTO</b></Col>
                                        <Col style={{ width: '10%' }} align="right" ><b></b></Col>
                                        <Col style={{ width: '40%' }} align="right" ><b>REALIZADO</b></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className="t-body p-0">
                                {/* Mapeando os dados do fluxo de caixa */}
                                {tabelaFluxoCaixa.map((fluxoLocal, index) => (
                                    <Row key={index} className="row">
                                        <Col align="start" style={{ width: '20%' }}>
                                            {fluxoLocal.mesExtenso + "/" + fluxoLocal.ano}
                                        </Col>
                                        <Col align="right" style={{ width: '20%' }}>
                                            {FormatNumber(fluxoLocal.prevReceberMesAno, true, false)}
                                        </Col>
                                        <Col align="right" style={{ width: '20%' }}>
                                            {FormatNumber(fluxoLocal.realizadoEntradaMesAno, true, false)}
                                        </Col>
                                        <Col align="right" style={{ width: '20%' }}>
                                            {FormatNumber(fluxoLocal.prevPagarMesAno, true, false)}
                                        </Col>
                                        <Col align="right" style={{ width: '20%' }}>
                                            {FormatNumber(fluxoLocal.realizadoSaidaMesAno, true, false)}
                                        </Col>
                                    </Row>
                                ))}
                                <Row className="c-primary row">
                                    <Col style={{ width: '20%' }}><b>TOTAL DO PERÍODO</b></Col>
                                    <Col align="right" style={{ width: '20%' }}>
                                        <b>{FormatNumber(saldos.prevReceber, true)}</b>
                                    </Col>
                                    <Col align="right" style={{ width: '20%' }}>
                                        <b>{FormatNumber(saldos.realizadoEntrada, true)}</b>
                                    </Col>
                                    <Col align="right" className="c-red" style={{ width: '20%' }}>
                                        <b>{FormatNumber(saldos.prevPagar, true)}</b>
                                    </Col>
                                    <Col align="right" className="c-red" style={{ width: '20%' }}>
                                        <b>{FormatNumber(saldos.realizadoSaida, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}