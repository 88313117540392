import { DownloadOutlined } from "@ant-design/icons";
import { Upload, Row, Col, Button, message, Modal } from "antd";
import React, { useState } from "react";

export default function UploadFoto({ listaImagens, setListaImagens }) {
    const [previewImage, setPreviewImage] = useState('');
    const [previewVisible, setPreviewVisible] = useState(false);

    const onChange = ({ fileList: newFileList }) => {
        setListaImagens(newFileList);
    };

    const onPreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    
    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Você só pode fazer upload de arquivos JPG/PNG!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('A imagem deve ter menos de 2 MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    
    return (
        <Col span={24}>
                    <Row align="middle" justify="center" gutter={[8, 8]}>
                        <Col>
                            <Upload
                                listType="picture-card"
                                onChange={onChange}
                                fileList={listaImagens}
                                onPreview={onPreview}
                                beforeUpload={beforeUpload}
                                maxCount={1}
                            >
                                <Row align="middle" justify="center">
                                    <Col span={22}>
                                        <div>
                                            Arraste a Imagem ou
                                        </div>
                                        <Button type="link" block icon={<DownloadOutlined />}>
                                            Clique Aqui
                                        </Button>
                                    </Col>
                                </Row>
                            </Upload>
                            <Modal
                                visible={previewVisible}
                                footer={null}
                                onCancel={() => setPreviewVisible(false)}
                            >
                                <img src={previewImage} alt="Imagem Produto" className="w-100" />
                            </Modal>
                        </Col>
                    </Row>
                </Col>
    );
};
