import React, { useEffect } from "react";
import { Row, Col, Form, Select, notification, InputNumber } from "antd";

import { useStateValue } from '../../state';
import api from "../../services/api";

export default function ManutencaoAliquotaSimples({ form, carregando, aoSalvar }) {
    const [{ manutencao, ui }, dispatch] = useStateValue();

    const salvarManutencao = values => {
        let enderecoUrl = 'AliquotaSimplesNacional/';
        carregando(true);

        if (values.asn_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ description: 'Registro alterado com sucesso!', message: 'Aviso' });
                    aoSalvar();
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                }
            );
        } else {
            enderecoUrl += "Incluir"
            api.post(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ description: 'Registro alterado com sucesso!', message: 'Aviso' });
                    aoSalvar();
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                }
            );
        };
    };

    useEffect(() => {
        if (!!ui.showDrawer) {
            buscarDados();
        }
    }, [ui.showDrawer]);

    function buscarDados() {
        let data = new Date();
        api.get(`AliquotaSimplesNacional/Listar?mes=${data.getMonth() + 1}&ano=${data.getFullYear()}`).then(
            res => {
                let dados = res.data.items[0];
                if (!!!dados) {

                    let dataAtual = new Date();
                    dados = { asn_mes: dataAtual.getMonth() + 1, asn_ano: dataAtual.getFullYear() };
                }
                form.setFieldsValue(dados);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    return (
        <div>
            <Form name="formulario" layout="vertical" form={form} onFinish={salvarManutencao}>
                <Form.Item hidden={true} name="asn_id"></Form.Item>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item name="asn_mes" label="Mês">
                            <Select placeholder="Selecione um Mês" disabled={true}>
                                <Select.Option value={1} key={1}>Janeiro</Select.Option>
                                <Select.Option value={2} key={2}>Fevereiro</Select.Option>
                                <Select.Option value={3} key={3}>Março</Select.Option>
                                <Select.Option value={4} key={4}>Abril</Select.Option>
                                <Select.Option value={5} key={5}>Maio</Select.Option>
                                <Select.Option value={6} key={6}>Junho</Select.Option>
                                <Select.Option value={7} key={7}>Julho</Select.Option>
                                <Select.Option value={8} key={8}>Agosto</Select.Option>
                                <Select.Option value={9} key={9}>Setembro</Select.Option>
                                <Select.Option value={10} key={10}>Outubro</Select.Option>
                                <Select.Option value={11} key={11}>Novembro</Select.Option>
                                <Select.Option value={12} key={12}>Dezembro</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item name="asn_ano" label="Ano">
                            <InputNumber
                                disabled={true}
                                step={1}
                                min={1}
                                max={9999}
                                placeholder="Informe o ano de referência"
                                enterButton />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item name="asn_percaliquota" label="Alíquota">
                            <InputNumber
                                defaultValue={0}
                                min={0}
                                max={100}
                                formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </div>
    )
};