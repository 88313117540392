import React, { useEffect } from "react";
import { Row, Col, Button, Dropdown, Menu, Tag, Popover, notification, Modal} from "antd";
import { EditOutlined, MoreOutlined, DeleteOutlined, PrinterOutlined, CopyOutlined, ToTopOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from '../../services/api';
import DrawerGrupoUsuario from './drawer';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, Editar, Excluir, Duplicar, Exportar, Imprimir } from "../../components";

export default function GrupoUsuario() {

    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+gru_nome' } }), []);

    function menuTable(dados) {
        return (
            <Menu className="text-right">                
                <Menu.Item key="1">
                    <Duplicar type="text" icon={<CopyOutlined />} onClick={() => abrirManutencaoDuplicar(dados)}> Duplicar </Duplicar>
                </Menu.Item>
                <Menu.Item key="2">
                    <Exportar type="text" icon={<ToTopOutlined />}> Exportar </Exportar>
                </Menu.Item>
                <Menu.Item key="3">
                    <Imprimir type="text" icon={<PrinterOutlined />}> Imprimir </Imprimir>
                </Menu.Item>
            </Menu>
        )
    }

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Excluir o grupo de usuários ${dados.gru_nome}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    function abrirManutencaoDuplicar(dados){
        let dadosDuplicar = {...dados};
        dadosDuplicar.duplicar = true;
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dadosDuplicar } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function excluirRegistro(dados) {
        api.post(`GrupoUsuario/Excluir?grupoUsuarioId=${dados.gru_id}`).then((res) => {
            if (res.status === 200) {
                notification.success({ message: 'Operação concluída', description: "Grupo de usuários excluído com sucesso!" });
            }
        }, (err) => {
            modalInativarGrupo(dados);
        }).finally(() => {
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+gru_nome' } });
        });
    }

    const modalInativarGrupo = (dados) => {
        Modal.confirm({
            title: `Não foi possível excluir o grupo de usuários ${dados.gru_nome}!`,
            icon: <CloseCircleOutlined />,
            content: `Deve conter referências com outros registros!`,
            okText: 'Inativar Registro',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                dados.gru_ativo = false;
                dados.usuarios = [];
                dados.usuarios = [];
                api.post(`GrupoUsuario/Editar`, dados).then((res) => {
                    if (res.status === 200) {
                        notification.success({ message: 'Operação concluída', description: "Grupo de Usuários inativado com sucesso!" });
                    }
                }, err => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
                }).finally(() => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+gru_nome' } });
                });
            }
        });
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+gru_nome" },
                { label: "Nome Z - A", value: "-gru_nome" },
                { label: "Código Crescente", value: "+gru_id" },
                { label: "Código Decrescente", value: "-gru_id" }
            ]}/>
            <div className="tabela">
                <TabelaDados url="GrupoUsuario/Listar" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ gru_ativo, gru_id }) => (
                            <Popover content={gru_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                <Tag color={gru_ativo ? 'processing' : 'default'} className="w-100">
                                    <b>{gru_id}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Grupos de Usuários',
                        render: ({ gru_nome }) => (
                            <div>
                                <b>{gru_nome}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 95,
                        fixed: 'right',
                        render: (record, dados) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Editar onClick={() => {
                                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                            dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                        }} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>   
                                        <Excluir icon={<DeleteOutlined />} onClick={() => modalExcluir(dados)} />
                                    </Col>
                                    {/* <Col>
                                        <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                        </Dropdown>
                                    </Col> */}
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerGrupoUsuario />
        </div>
    );

}