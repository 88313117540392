import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Typography, Form, Input, Modal, Switch, Table, Tag, Select, notification, Button } from "antd";
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { FormatNumber } from "../../../ValueObjects";
import { novaAbaNavegador } from "../../../services/funcoes";
import { SelectPaginacao } from "../../../components";

export default function ModalConfirmacaoTransferencia({ dadosNota = {}, exibirModal, fecharModal }) {

    const [form] = Form.useForm();
    const [conferiTodos, setConferiTodos] = useState(false);
    const [usarLeitor, setUsarLeitor] = useState(false);
    const [serieFiscalTerceiros, setSerieFiscalTerceiros] = useState([]);
    const [listaItens, setListaItens] = useState([]);
    const [parametrosEmpresa, setParametrosEmpresa] = useState([]);
    const codBarrasInput = useRef();

    const [carregando, setCarregando] = useState(false);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
            }
        });
    };

    useEffect(() => {
        if (exibirModal) {
            setConferiTodos(false);
            setUsarLeitor(false);

            api.get('ParametroEmpresa/BuscarParametros').then(res => {
                if (res.status === 200) {
                    setParametrosEmpresa(res.data);
                }
            }).catch(
                erro => {
                    console.log(erro);
                }
            );

            if (!!dadosNota.ntf_id) {
                let listaTemp = [];
                api.get(`NotaFiscalTransferencia/ListarItensNotaFiscal/${dadosNota.ntf_id}`).then(itensNotaFiscal => {
                    if (itensNotaFiscal.status === 200) {
                        itensNotaFiscal.data.forEach((item, indice) => {
                            item.produtoConferido = false;
                            item.key = indice + 1;
                            listaTemp.push(item);

                            if (item.key === itensNotaFiscal.data.length) {
                                setListaItens(listaTemp);
                            }
                        });
                    }
                }).catch(erro => {
                    console.log(erro);
                });
            }
        }
    }, [exibirModal]);

    function marcarItem(key, nfi_ean) {
        let itens = [...listaItens];
        let itemEditar = itens.filter((item) => { return (item.nfi_ean === nfi_ean && (!!key ? item.key === key : true)) });
        itemEditar.map((item) => {
            if (!key) {
                item.produtoConferido = true;
                form.setFieldsValue({ codBarras: '' });
            } else {
                item.produtoConferido = !item.produtoConferido;
            }
        });
        setListaItens(itens);
    };

    function marcarTodos(checked) {
        setConferiTodos(checked);

        let itens = [...listaItens];
        itens.map((item) => {
            item.produtoConferido = (checked ? true : false);
        });
        setListaItens(itens);
    };

    function salvarDados() {
        let idSerie = form.getFieldValue().ser_id;
        if (!idSerie) {
            notification.warning({ message: 'Aviso', description: ('Favor selecionar uma Série Fiscal!') });
        } else {
            setCarregando(true);
            api.post(`NotaFiscalTransferencia/incluirEntrada?IdNotaFiscal=${dadosNota.ntf_id}&IdSerie=${idSerie}`).then(
                retornoNF => {
                    if (retornoNF.status === 200) {
                        if (retornoNF.data.ntf_status === 1 && !!retornoNF.data.ntf_chavenfe && !!retornoNF.data.ntf_docfiscal) {
                            novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${retornoNF.data.ntf_chavenfe}`);
                        }
                        notification.success({ message: 'Operação Concluída', description: 'Nota Fiscal de Transferência adicionada com sucesso!' })
                        fecharModal();
                    }
                }
            ).catch(
                erro => {
                    notification.warning({ message: 'Aviso', description: (!!erro.data ? erro.data.Message : 'Não foi possível realizar a Confirmação de Transferencia!') });
                }
            ).finally(
                () => {
                    setCarregando(false);
                }
            );
        }
    };

    return (
        <Modal centered
            title="Itens da Nota Fiscal de Transferência"
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={() => form.submit()}
            maskClosable={false}
            width={850}
            destroyOnClose
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={onCloseModal} icon={<CloseOutlined />}>
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button loading={carregando} icon={<CheckOutlined />} type="primary" htmlType="submit" onClick={() => form.submit()}>
                            Confirmar a Transferência
                        </Button>
                    </Col>
                </Row>
            }
        >
            <div>
                <Form layout="vertical" name="formularioConfirmacaoTransf" form={form} onFinish={salvarDados}>
                    <Row align="middle" gutter={[8, 0]}>
                        <Col>
                            <Switch onChange={(checked) => {
                                setUsarLeitor(checked);
                                if (checked) {
                                    setTimeout(() => codBarrasInput.current.focus());
                                }
                            }} /> Utilizar leitor de código de barras?
                        </Col>
                        <Col>
                            <Switch onChange={(checked) => { marcarTodos(checked) }} /> Conferi Todos
                        </Col>
                    </Row>
                    {conferiTodos &&
                        <Row align="middle" justify='center' gutter={[8, 8]} className="m-t-16">
                            <Col span={24} align='center'>
                                <Typography.Text className="c-red">
                                    Atenção, é Recomendado a conferência dos Itens ao Habilitar a opção 'Conferi Todos'
                                </Typography.Text>
                            </Col>
                        </Row>
                    }
                    {usarLeitor &&
                        <Row align="middle" gutter={[8, 8]} className="m-t-16">
                            <Col span={24}>
                                <Form.Item name="codBarras">
                                    <Input ref={codBarrasInput} onChange={(e) => {
                                        marcarItem(null, e.target.value);
                                    }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                    <div className="tabela mt-dif">
                        <Table dataSource={listaItens} columns={[
                            {
                                title: 'Itens',
                                width: 55,
                                align: 'center',
                                render: ({ nfi_numeroitem }) => (
                                    <b>{nfi_numeroitem}</b>
                                ),
                            },
                            {
                                width: 110,
                                elipse: true,
                                title: 'Descrição',
                                render: ({ pro_descricao, nfi_ean }) => (
                                    <div>
                                        <b>{pro_descricao}</b>
                                        <div>
                                            {nfi_ean}
                                        </div>
                                    </div>
                                ),
                            },
                            {
                                title: 'Quantidade',
                                width: 60,
                                align: 'center',
                                render: ({ nfi_qtde, nfi_unidademedida }) => (
                                    <div>
                                        <b>{nfi_qtde} {nfi_unidademedida}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Valor Unitário (R$)',
                                width: 65,
                                align: 'right',
                                render: ({ nfi_valorunitario }) => (
                                    <div>
                                        <b>{FormatNumber(nfi_valorunitario, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Valor Total (R$)',
                                align: 'right',
                                width: 65,
                                render: ({ nfi_valorunitario, nfi_qtde }) => (
                                    <div>
                                        <b>{FormatNumber(nfi_valorunitario * nfi_qtde, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Confere',
                                align: 'center',
                                width: 70,
                                render: ({ produtoConferido, nfi_ean, key }) => (
                                    <Switch checked={produtoConferido} onChange={() => { marcarItem(key, nfi_ean) }} />
                                ),
                            }
                        ]} scroll={{ x: 800 }} />
                    </div>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <Form.Item label="Série Fiscal" name="ser_id">
                                <SelectPaginacao url="SerieFiscal/Listar?CodigoModeloDocFiscal=55&EmitenteDocumento=1" placeholder="Informe a Série" form={form} nameLabel="ser_serie" nameValue="ser_id" selecionarRegUnico="ser_id" allowClear={true} conteudo={
                                    pc => (<Select.Option value={pc.ser_id} key={pc.key}>{pc.ser_serie} <br />
                                    </Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}