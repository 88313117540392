import React, { useEffect, useState } from "react";
import { Form, Slider } from "antd";

export default function FiltroData({setDataPesquisa, dataPesquisa}) {
    const maximo = 10;
    const [valueSlider, setValueSlider] = useState(maximo);
    
    const handleChange = (value) => {
        let data = new Date();
        data.setDate(data.getDate() - (maximo - value));
        setDataPesquisa(data.toLocaleDateString());
        setValueSlider(value);
    };

    return (
        <Form.Item>
            <label className="m-t-3"> Data Inicial para Pesquisa <span>{dataPesquisa}</span> </label>
            <Slider tipFormatter={null} min={1} max={maximo} onChange={handleChange} value={valueSlider} />
        </Form.Item>
    );

}