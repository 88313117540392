import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Upload, Checkbox, Tabs, notification, Modal } from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

import api from '../../services/api';
import { FormGW } from "../../components";
import { TabFiliaisUsuario, TabPermissoesUsuario, TabHorariosAcessoUsuario } from "./tabPage";

import { getEmpresa, getIdUsuario } from '../../services/auth'

export default function ManutencaoUsuario({ formulario, carregando, aoSalvar }) {

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [userId, setUserId] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [array, setArray] = useState([]);
    const [emailAtual, setEmailAtual] = useState(formulario.getFieldValue('usu_email'));

    useEffect(() => {
        carregarAvatar();
        getPermissoesDefault();
        formulario.setFieldsValue({ usu_email: emailAtual });
    }, []);

    const getBase64 = async (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const beforeUpload = async (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            notification.error('Você só pode fazer upload de arquivos JPG/PNG!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            notification.error('A imagem deve ter menos de 2 MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    const setImageComponent = (img_avatar) => {
        if (formulario.getFieldValue('usu_id')) {
            setUserId(formulario.getFieldValue('usu_id'));
            let img = [
                {
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: img_avatar,
                },
            ];

            setFileList(img);
        }
    };

    const carregarAvatar = async () => {
        if (!!formulario.getFieldValue('usu_email')) {
            await api.get(`Usuario/Avatar?email=${formulario.getFieldValue('usu_email')}`).then(
                res => {
                    if (res.data.length > 100) {
                        var caminho_avatar = `data:image/png;base64,${res.data}`;
                        setImageComponent(caminho_avatar);
                        setImageUrl(caminho_avatar);
                    } else {
                        let img = [
                            {
                                uid: res.data,
                                name: 'image.png',
                                status: 'done'
                            },
                        ];

                        setFileList(img);
                    }
                }
            ).catch(
                erro => {
                    console.log(erro)
                }
            )
        }
    };

    const getPermissoesDefault = async () => {
        let permissaoDefault;
        await api.get(`PermissaoUsuario/ListarPermissoesPaginas`).then(
            res => {
                permissaoDefault = res.data.map(
                    (x, idx) =>
                        ({ key: idx, ...x })
                );
                permissaoDefault.forEach(e => {
                    e.acesso = true;
                    e.permissoes.forEach(p => {
                        p.acesso = true;
                    });
                });
                setArray(permissaoDefault);
            }).catch(
                (err) => {
                    console.log(err);
                });
    }

    function salvarManutencao(values) {
        values.usu_sac = false;
        values.usu_suporte = false;
        var usuarios_locais = [];
        if (typeof values.locaisselecionados == 'undefined') {
            Modal.warning({
                title: "Verifique os Locais de acesso!",
                content: "Não foi selecionado local de acesso para o usuário!",
            });
            return;
        }
        values.locaisselecionados.forEach(l => {
            usuarios_locais.push({ usu_id: getIdUsuario(), loc_id: l.loc_id })
        })
        delete values.locaisselecionados;
        values.usuarioslocais = usuarios_locais;


        if (!values.listaPermissoesPaginas && !values.usu_id) {
            values.listaPermissoesPaginas = array;
        }

        if (typeof values.usuarioacessos != 'undefined') {
            values.usuarioacessos.map(item => {
                item.emp_id = getEmpresa();
            })
        }

        //tratamento senha
        if (values.usu_senha == "" || values.usu_confirmaSenha == "" || values.usu_senha != values.usu_confirmaSenha) {
            delete values.usu_senha;
            delete values.usu_confirmaSenha;
        }

        //tratmento do avatar
        if ((imageUrl != null) && (fileList != '')) {
            values.avatar = { data: imageUrl, type: fileList[0].type };
        } else {
            delete values.usu_avatar;
        }
        if (!!values.usu_id) {
            editar(values);
        } else {
            incluir(values);
        }
    }
    
    function incluir(values) {
        //tratamento dos campos
        carregando(true);
        api.post(`Usuario/Incluir`, values).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Os dados do Usuário foram salvos com sucesso, um e-mail foi enviado para a definição de senha!" });
            }
        ).catch(error => {
            console.log(error);
            notification.error({ message: 'Aviso!', description: 'Problema ao incluir Usuario!' });
        }).finally(() => {
            carregando(false);
            formulario.resetFields();
            aoSalvar();
        })
    }
    
    function editar(values) {
        carregando(true);
        api.put(`Usuario/Editar`, values).then(
            res => {
                notification.success({ message: 'Sucesso!', description: 'Usuário alterado com Sucesso' });
            }
        ).catch(error => {
            console.log(error);
            notification.error({ message: 'Aviso!', description: 'Problema ao editar Usuário!' });
        }).finally(() => {
            carregando(false);
            formulario.resetFields();
            aoSalvar();
        })
    }
    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
        }
        if (info.file.status !== 'removed') {
            getBase64(info.file.originFileObj, (base64) => {
                setLoading(false);
                setImageUrl(base64);
            });
        }
        if (info.file.status === 'removed')
            setImageUrl(null);

        setFileList(info.fileList);
    };

    const onBlurVerificaEmail = async () => {
        var usuario;
        setEmailAtual(formulario.getFieldValue('usuEmail'));
        let emailNovo = formulario.getFieldValue('usu_email');
        let idUsuario = formulario.getFieldValue('usu_id');
        try {
            if (typeof idUsuario != 'undefined' & idUsuario != 0) usuario = `&idUsuario=${idUsuario}`;
            var result = await api.get(`Usuario/ValidarEmailEmpresa?email=${emailNovo}`);
            if (result.status == 200) {
                if (result.data) {
                    formulario.setFieldsValue(result.data)
                    carregarAvatar();
                }
            }
        } catch (err) {
            console.log('err :>> ', err);
        };
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">
                {loading ? 'Carregando...' : 'Selecionar Imagem'}
            </div>
        </div>
    );

    return (
        <div className="perfil">
            <FormGW layout="vertical" form={formulario} name="manutencaoUsuario" onFinish={salvarManutencao}>
                <Row justify="center" gutter={[16, 0]}>
                    <Col xs={6} sm={5} md={5} lg={3} xl={3}>
                        <ImgCrop
                            rotate
                            modalTitle="Ajuste sua imagem"
                            modalOk="Salvar"
                            shape="round">
                            <Upload
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={true}
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                fileList={fileList}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                                onRemove={() => setImageUrl(null)}
                                maxCount={1}
                            >
                                {fileList.length == 0 && uploadButton}
                            </Upload>
                        </ImgCrop>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={21} xl={21} className="t-5">
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Form.Item name="usu_id" hidden />
                                <Form.Item name="usuarioslocais" hidden />
                                <Form.Item name="locaisselecionados" hidden />
                                <Form.Item name="mudarAcesso" hidden />
                                <Form.Item name="listaPermissoesPaginas" hidden />
                                <Form.Item name="usuarioacessos" hidden />
                                <Form.Item name="usu_datainclusao" hidden />

                                <Form.Item
                                    rules={[{ required: true, message: "Informe o nome" }]}
                                    label="Nome"
                                    name="usu_nome">
                                    <Input placeholder="Informe o Nome do Usuário" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Form.Item
                                    rules={[
                                        { type: 'email', message: "E-mail invalido!" },
                                        { required: true, message: "Informe o e-mail" },
                                    ]}
                                    label="E-mail"
                                    name="usu_email">
                                    <Input placeholder="Informe o E-mail do Usuário" onBlur={onBlurVerificaEmail} />
                                </Form.Item>
                            </Col>
                        </Row>
                        {userId != null &&
                            <Row gutter={[8, 0]}>
                                <Col xs={24} sm={12} md={10} lg={8} xl={8}>
                                    <Form.Item
                                        rules={[
                                            () => ({
                                                validator(_, value) {
                                                    if (!value)
                                                        return Promise.resolve();

                                                    const regexNumber = /[0-9]/;
                                                    const regexLetrasMaiuscula = /[A-Z]/;
                                                    const regexLetrasMinuscula = /[a-z]/;

                                                    if (regexNumber.test(value) === false) {
                                                        return Promise.reject(new Error('A senha deve conter números!'));
                                                    } else if (regexLetrasMaiuscula.test(value) === false) {
                                                        return Promise.reject(new Error('A senha deve conter letras maiúsculas!'));
                                                    } else if (regexLetrasMinuscula.test(value) === false) {
                                                        return Promise.reject(new Error('A senha deve conter letras minúscula!'));
                                                    } else if (value.length < 6) {
                                                        return Promise.reject(new Error('A senha deve conter no mínimo  6 caracteres!'));
                                                    } else if ((value == " ") || (value == "")) {
                                                        return Promise.reject(new Error('A senha não pode ser vazia'));
                                                    }
                                                    else
                                                        return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                        label="Nova Senha"
                                        name="usu_senha">
                                        <Input.Password placeholder="Informe a Nova Senha" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={10} lg={8} xl={8}>
                                    <Form.Item
                                        label="Confirmação Nova Senha"
                                        name="usu_confirmaSenha"
                                        dependencies={['usu_senha']}
                                        hasFeedback
                                        rules={[
                                            ({ getFieldValue }) => ({
                                                required: getFieldValue('usu_confirmaSenha'),
                                                message: 'Informe a confirmação de sua senha!',
                                            }),
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('usu_senha') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('As senhas informadas não conferem!'));
                                                },

                                            })
                                        ]}>
                                        <Input.Password placeholder="Confirme a Nova Senha do Usuário" />
                                    </Form.Item>
                                </Col>
                                <Col className="t-mob">
                                    <Form.Item
                                        valuePropName="checked"
                                        name="usu_ativo"
                                        hidden={!formulario.getFieldValue('usu_id')}
                                    >
                                        <Checkbox>Usuário Ativo?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                        <Row gutter={[8, 0]}>
                            <Col>
                                <Form.Item
                                    valuePropName="checked"
                                    name="usu_admin">
                                    <Checkbox> Este usuário irá possuir privilégios de administrador no sistema?</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    valuePropName="checked"
                                    name="usu_usuariopdv">
                                    <Checkbox> Este usuário irá possuir acesso apenas no PDV?</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </FormGW>
            <Row align="middle" gutter={[8, 16]} className="m-t-16">
                <Col span={24}>
                    <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                        <Tabs.TabPane tab="Filiais do Usuário" key="1">
                            <TabFiliaisUsuario formulario={formulario} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Definição das Permissões" key="2">
                            <TabPermissoesUsuario formulario={formulario} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Restrição de Horários para Acesso" key="3">
                            <TabHorariosAcessoUsuario formulario={formulario} />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
        </div>
    );

}