import React, { useEffect, useState } from 'react';
import moment from 'moment';

import api from '../../../services/api';
import { Col, Row, Divider } from 'antd';
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { FormatNumber } from '../../../ValueObjects';

export default function FluxoCaixaDiarioGerencialAnalitico({ filtros, valoresProvisionados, form }) {

    const [receitaDespesa, setReceitaDespesa] = useState([]);
    const [categoria, setCategoria] = useState([]);
    const [contaGerencial, setContaGerencial] = useState([]);
    const [dataInicial, setDataInicial] = useState();
    const [dataFinal, setDataFinal] = useState();
    const [saldoInicial, setSaldoInicial] = useState();
    const [saldoFinal, setSaldoFinal] = useState();
    const [listaInicial, setListaInicial] = useState([]);
    const [listaFinal, setListaFinal] = useState([]);
    const [saldoPrevisto, setSaldoPrevisto] = useState();
    const [saldoRealizado, setSaldoRealizado] = useState();
    const [local, setLocal] = useState();

    //constantes utilizadas para criar uma nova lista de acordo com o filtro item.
    const ReceitaDespesa = (itens) => {
        // Criar um novo mapa para armazenar combinações únicas de categorias e contas gerenciais
        const mapaUnico = new Map();
        itens.forEach(item => {
            const chave = `${item.receitaDespesaCategoria}`;
            if (!mapaUnico.has(chave)) {
                mapaUnico.set(chave, item);
            }
        });
        // Converter o mapa de volta para uma lista de objetos únicos
        return Array.from(mapaUnico.values());
    };

    const FiltraCategoria = (itens) => {
        const mapaUnico = new Map();
        itens.forEach(item => {
            const chave = `${item.codigoCategoria}`;
            if (!mapaUnico.has(chave)) {
                mapaUnico.set(chave, item);
            }
        });
        return Array.from(mapaUnico.values());
    };

    const FiltraConta = (itens) => {
        const mapaUnico = new Map();
        itens.forEach(item => {
            const chave = `${item.codigoContaGerencial}`;
            if (!mapaUnico.has(chave)) {
                mapaUnico.set(chave, item);
            }
        });
        return Array.from(mapaUnico.values());
    };

    useEffect(() => {
        if (!!filtros) {
            tratarDados(filtros);
        }
    }, []);

    async function tratarDados(filtros) {
        setDataFinal(filtros.dataFinal);
        setDataInicial(filtros.dataInicial);
        api.get(`FluxoCaixa/ListarDiarioGerencialAnalitico?caixaPeriodo=${filtros.fluxoPeriodo}&caixaApresentacao=${filtros.fluxoApresentacao}
                &caixaOrganizacao=${filtros.fluxoOrganizacao}&dataInicial=${filtros.dataInicial}&dataFinal=${filtros.dataFinal}`).then(
            res => {
                if (!!res.data) {
                    setLocal(res.data[0].nomeLocal);
                    let dadosReceita = ReceitaDespesa(res.data);
                    setSaldoPrevisto((dadosReceita[0].totalProvisaoReceber - dadosReceita[0].totalProvisaoPagar));
                    setSaldoRealizado((dadosReceita[0].totalEntradaRealizado - dadosReceita[0].totalSaidaRealizado))

                    let dadosCategoria = FiltraCategoria(res.data);
                    let dadosConta = FiltraConta(res.data);
                    let ListaReceitaDespesa = dadosReceita.map(item =>
                        createData(
                            item.receitaDespesaCategoria,
                            item.totalEntradaRealizado,
                            item.totalProvisaoPagar,
                            item.totalProvisaoReceber,
                            item.totalSaidaRealizado
                        )
                    );
                    setReceitaDespesa(ListaReceitaDespesa);
                    let ListaCategoria = dadosCategoria.map(item =>
                        createCategoria(
                            item.receitaDespesaCategoria,
                            item.descricaoCategoria,
                            item.codigoCategoria,
                            item.realizadoentradaCategoria,
                            item.realizadosaidaCategoria,
                            item.provisaopagarCategoria,
                            item.provisaoreceberCategoria
                        )
                    );
                    setCategoria(ListaCategoria);
                    let ListaConta = dadosConta.map(item =>
                        createConta(
                            item.receitaDespesaCategoria,
                            item.codigoCategoria,
                            item.codigoContaGerencial,
                            item.descricaoContaGerencial,
                            item.valor_RealizadoEntrada,
                            item.valor_RealizadoSaida,
                            item.valor_ProvisaoPagar,
                            item.valor_ProvisaoReceber
                        )
                    );
                    setContaGerencial(ListaConta);
                }
            }).catch(err => {
                console.log(err);
            }
            );

        api.get(`FluxoCaixa/SaldoInicial?caixaPeriodo=${filtros.fluxoPeriodo}&caixaApresentacao=${filtros.fluxoApresentacao}
            &caixaOrganizacao=${filtros.fluxoOrganizacao}&dataInicial=${filtros.dataInicial}&dataFinal=${filtros.dataFinal}`).then(
            res => {
                if (!!res.data) {
                    let dadosInicial = res.data;
                    let InicialSaldo = (!res.data[0].totalSaldoConta && res.data[0].totalSaldoInicial !== 0) ? res.data[0].totalSaldoInicial : res.data[0].totalSaldo;
                    setSaldoInicial(InicialSaldo);

                    let saldoInicial = dadosInicial.map(item =>
                        createInicial(
                            item.codigoLocal,
                            item.CodigoLocal,
                            item.contaCorrente,
                            item.nomeContaCorrente,
                            item.saldo,
                            item.saldoConta,
                            item.saldoInicial,
                            item.totalSaldo,
                            item.totalSaldoInicial,
                            item.totalSaldosConta
                        )
                    );
                    setListaInicial(dadosInicial);
                }
            }
        ).catch(err => {
            console.log(err);
        }
        );
        api.get(`FluxoCaixa/SaldoFinal?caixaPeriodo=${filtros.fluxoPeriodo}&caixaApresentacao=${filtros.fluxoApresentacao}&caixaOrganizacao=${filtros.fluxoOrganizacao}
                &dataInicial=${filtros.dataInicial}&dataFinal=${filtros.dataFinal}`).then(
            res => {
                if (!!res.data) {
                    let dadosFinal = res.data;
                    let FinalSaldo = (!res.data[0].totalSaldoConta && res.data[0].totalSaldoConta !== 0 && res.data[0].totalSaldoConta !== undefined) ? res.data[0].totalSaldosConta : res.data[0].totalSaldo;
                    setSaldoFinal(FinalSaldo);
                    let saldoFinal = dadosFinal.map(item =>
                        createFinal(
                            item.codigoLocal,
                            item.CodigoLocal,
                            item.contaCorrente,
                            item.nomeContaCorrente,
                            item.saldo,
                            item.saldosConta,
                            item.saldoInicial,
                            item.totalSaldo,
                            item.totalSaldoInicial,
                            item.totalSaldosConta
                        )
                    );
                    setListaFinal(saldoFinal);
                }
            }
        ).catch(err => {
        }
        );
    }

    function createData(receitaDespesa, totalEntradaRealizado, totalProvisaoPagar, totalProvisaoReceber, totalSaidaRealizado) {
        return { receitaDespesa, totalEntradaRealizado, totalProvisaoPagar, totalProvisaoReceber, totalSaidaRealizado }
    };
    function createCategoria(receitaDespesaCategoria, descricaoCategoria, codigoCategoria, realizadoentradaCategoria, realizadosaidaCategoria, provisaopagarCategoria, provisaoreceberCategoria) {
        return { receitaDespesaCategoria, descricaoCategoria, codigoCategoria, realizadoentradaCategoria, realizadosaidaCategoria, provisaopagarCategoria, provisaoreceberCategoria }
    };
    function createConta(receitaDespesaCategoria, codigoCategoria, codigoContaGerencial, descricaoContaGerencial, valor_RealizadoEntrada, valor_RealizadoSaida, valor_ProvisaoPagar, valor_ProvisaoReceber) {
        return { receitaDespesaCategoria, codigoCategoria, codigoContaGerencial, descricaoContaGerencial, valor_RealizadoEntrada, valor_RealizadoSaida, valor_ProvisaoPagar, valor_ProvisaoReceber }
    };
    function createFinal(codigoLocal, CodigoLocal, contaCorrente, nomeContaCorrente, saldo, saldosConta, saldoInicial, totalSaldo, totalSaldoInicial, totalSaldosConta) {
        return { codigoLocal, CodigoLocal, contaCorrente, nomeContaCorrente, saldo, saldosConta, saldoInicial, totalSaldo, totalSaldoInicial, totalSaldosConta }
    };
    function createInicial(codigoLocal, CodigoLocal, contaCorrente, nomeContaCorrente, saldo, saldosConta, saldoInicial, totalSaldo, totalSaldoInicial, totalSaldosConta) {
        return { codigoLocal, CodigoLocal, contaCorrente, nomeContaCorrente, saldo, saldosConta, saldoInicial, totalSaldo, totalSaldoInicial, totalSaldosConta }
    };

    return (
        <div>
            <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', fontSize: '14px', align: 'center' }}>
                <div>
                    <Col align="center">
                        <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                            Contas: <span style={{ fontWeight: 'normal', align: 'center' }}>{local}</span>
                        </div>
                        {listaFinal.map((row, index) => (
                            <div key={index} style={{ marginBottom: '5px', align: 'center' }}>
                                <span>{row.nomeContaCorrente}: </span>
                                <span style={{ fontWeight: 'bold', color: '#046ab4' }}>
                                    {/* {row.nomeContaCorrente}  */}
                                    {FormatNumber(row.saldoInicial, true)}
                                </span>
                                <span style={{ marginLeft: '20px' }}>{row.nomeContaCorrente}: </span>
                                <span style={{ fontWeight: 'bold', color: '#046ab4' }}>
                                    {FormatNumber(((!row.saldosConta && row.saldosConta !== 0) ? row.saldosConta : row.saldo), true)}
                                </span>
                            </div>
                        ))}
                        <div style={{ marginTop: '10px', fontWeight: 'bold' }}>
                            SALDO INICIAL: <span style={{ color: '#046ab4' }}>{FormatNumber(saldoInicial, true)}</span>
                            <span style={{ marginLeft: '30px' }}>
                                SALDO FINAL: <span style={{ color: '#046ab4' }}>{FormatNumber(saldoFinal, true)}</span>
                            </span>
                        </div>
                    </Col>
                </div>
            </div>
            <div className="tabela-fluxo">
                <div className="b-color-secondary col-layout">
                    <Col align="center"><b>Fluxo de Caixa de {moment(dataInicial).format('DD/MM/YYYY')} a {moment(dataFinal).format('DD/MM/YYYY')}</b></Col>
                </div>
                <div className="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right' }}>
                    <div style={{ width: '60%', marginLeft: '10px', paddingRight: '16px' }}>
                        <b></b>
                    </div>
                    {valoresProvisionados && (
                        <div style={{ width: '20%', textAlign: 'right', paddingRight: '22px' }}>
                            <b>PREVISTO</b>
                        </div>
                    )}
                    <div style={{ width: '20%', textAlign: 'right', paddingRight: '16px' }}>
                        <b>REALIZADO</b>
                    </div>
                </div>
                <div className="row">
                    <Table size="small">
                        <TableBody>
                            {receitaDespesa.map((row) => {
                                // Filtrando categorias de acordo com a receita/despesa
                                const categoriasFiltradas = categoria.filter(
                                    (categoria) => categoria.receitaDespesaCategoria === row.receitaDespesa
                                );
                                return (
                                    <React.Fragment key={row.name}>
                                        {categoriasFiltradas.map((categoria) => {
                                            // Filtra a lista de contas gerenciais por categoria e tipo de receita/despesa
                                            const contasGerenciaisFiltradas = contaGerencial.filter(
                                                (conta) =>
                                                    conta.codigoCategoria === categoria.codigoCategoria &&
                                                    conta.receitaDespesaCategoria === row.receitaDespesa
                                            );
                                            return (
                                                <React.Fragment key={categoria.codigoCategoria}>
                                                    {/* Linha para a categoria */}
                                                    <TableRow key={categoria.codigoCategoria} >
                                                        <TableCell align="left" style={{ width: '60%', textAlign: 'left', fontSize: '12px' }}>
                                                            {categoria.descricaoCategoria}
                                                        </TableCell>
                                                        {/* Coluna de valores provisionados ou realizados de entrada ou saída */}
                                                        <TableCell align="right" style={{ width: '20%', textAlign: 'right', fontSize: '12px' }}>
                                                            {valoresProvisionados ? (
                                                                categoria.receitaDespesaCategoria === 2 ? (
                                                                    FormatNumber(categoria.provisaoreceberCategoria, true)
                                                                ) : (
                                                                    FormatNumber(categoria.provisaopagarCategoria, true)
                                                                )
                                                            ) : null}
                                                        </TableCell>
                                                        {/* Coluna de valores realizados de entrada ou saída */}
                                                        <TableCell align="right" style={{ width: '20%', textAlign: 'right', fontSize: '12px' }}>
                                                            {categoria.receitaDespesaCategoria === 2 ? (
                                                                FormatNumber(categoria.realizadoentradaCategoria, true)
                                                            ) : (
                                                                FormatNumber(categoria.realizadosaidaCategoria, true)
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                    {/* Linhas para cada conta gerencial dentro da categoria */}
                                                    {contasGerenciaisFiltradas.map((conta) => (
                                                        <TableRow key={conta.codigoContaGerencial} style={{ marginLeft: '20px' }}>
                                                            <TableCell align="left" style={{ width: '60%', textAlign: 'left', fontSize: '11px', paddingLeft: '30px' }}>
                                                                {conta.descricaoContaGerencial}
                                                            </TableCell>
                                                            {/* Coluna de valores provisionados ou realizados de entrada ou saída */}
                                                            <TableCell align="right" style={{ width: '20%', textAlign: 'right', fontSize: '11px' }}>
                                                                {valoresProvisionados ? (
                                                                    row.receitaDespesa === 2 ? (
                                                                        FormatNumber(conta.valor_ProvisaoReceber, true)
                                                                    ) : (
                                                                        FormatNumber(conta.valor_ProvisaoPagar, true)
                                                                    )
                                                                ) : null}
                                                            </TableCell>
                                                            {/* Coluna de valores realizados de entrada ou saída */}
                                                            <TableCell align="right" style={{ width: '20%', textAlign: 'right', fontSize: '11px' }}>
                                                                {row.receitaDespesa === 2 ? (
                                                                    FormatNumber(conta.valor_RealizadoEntrada, true)
                                                                ) : (
                                                                    FormatNumber(conta.valor_RealizadoSaida, true)
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </React.Fragment>
                                            );
                                        })}
                                        {/* Linha para totais de entradas */}
                                        {row.receitaDespesa === 2 && (
                                            <TableRow key={`total-entrada-${row.name}`} style={{ margin: 0 }}>
                                                <TableCell align="left" style={{ color: '#046ab4', width: '60%', textAlign: 'left', fontSize: '12px' }}>
                                                    <b>TOTAL DE RECEITAS</b>
                                                </TableCell>
                                                <TableCell align="right" style={{ color: '#046ab4', width: '20%', textAlign: 'right', fontSize: '12px' }}>
                                                    {valoresProvisionados && FormatNumber(row.totalProvisaoReceber, true)}
                                                </TableCell>
                                                <TableCell align="right" style={{ color: '#046ab4', width: '20%', textAlign: 'right', fontSize: '12px' }}>
                                                    {FormatNumber(row.totalEntradaRealizado, true)}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                        {/* Linha para totais de saídas */}
                                        {row.receitaDespesa === 1 && (
                                            <TableRow key={`total-saida-${row.name}`} style={{ margin: 0 }}>
                                                <TableCell align="left" style={{ color: '#e41414', width: '60%', textAlign: 'left', fontSize: '12px' }}>
                                                    <b>TOTAL DE DESPESAS</b>
                                                </TableCell>
                                                <TableCell align="right" style={{ color: '#e41414', width: '20%', textAlign: 'right', fontSize: '12px' }}>
                                                    {valoresProvisionados && FormatNumber(row.totalProvisaoPagar, true)}
                                                </TableCell>
                                                <TableCell align="right" style={{ color: '#e41414', width: '20%', textAlign: 'right', fontSize: '12px' }}>
                                                    {FormatNumber(row.totalSaidaRealizado, true)}
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
                <div>
                    <div className="row" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'right' }}>
                        <div style={{ width: '60%', marginLeft: '10px', paddingRight: '16px' }}>
                            <b>SALDO DO PERÍODO</b>
                        </div>
                        {valoresProvisionados && (
                            <div style={{ width: '20%', textAlign: 'right', paddingRight: '24px' }}>
                                <b>{FormatNumber(saldoPrevisto)}</b>
                            </div>
                        )}
                        <div style={{ width: '20%', textAlign: 'right', paddingRight: '16px' }}>
                            <b>{FormatNumber(saldoRealizado, true)}</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}