import React, { useEffect, useState } from "react";
import { Form, Row, Col, Select, Button, Table, Checkbox, notification } from "antd";
import { InfoCircleOutlined, RedoOutlined, InfoOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { Data } from "../../components";
import { linkApi } from "../../services/auth";
import { FormatNumber } from "../../ValueObjects";
import { ModalGerarRemessa, ModalInstrucaoRemessa } from "../../components/modals/";
import SelectPaginado from "../../components/selectPaginado";

export default function ManutencaoRemessa({ formulario, aoSalvar }) {

    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [openModalInstrucaoRemessa, setOpenModalInstrucaoRemessa] = useState(false);
    const [openModalGerarRemessa, setOpenModalGerarRemessa] = useState(false);
    const [listaBancos, setListaBancos] = useState([]);
    const [listaParcelas, setListaParcelas] = useState([]);
    const [parcelaEditar, setParcelaEditar] = useState(null);
    const [dadosSelecionados, setDadosSelecionados] = useState({});
    const [banco, setBanco] = useState(null);
    const [idBanco, setIdBanco] = useState(null);

    useEffect(() => {
        formulario.setFieldsValue({ dataEmissao: moment(new Date()) });
        listarParcelas();
    }, []);

    useEffect(() => {
        if (ui.showDrawer) {
            if (!!manutencao.dados && !!manutencao.dados.bol_dataEmissao) {
                formulario.setFieldsValue({ bol_dataEmissao: moment(manutencao.dados.bol_dataEmissao) });
            } else if (!!!formulario.getFieldsValue().bol_dataEmissao) {
                formulario.setFieldsValue({ bol_dataEmissao: moment(new Date()) });
            }
        }
    }, [ui.showDrawer]);

    function selecionarItem(parcela) {
        let parcelas = [...listaParcelas];
        let itemEditar = parcelas.find((item) => { return item.bol_id === parcela });
        if (idBanco === itemEditar.ban_id || idBanco === null || idBanco === undefined) {
            itemEditar.selecionado = !itemEditar.selecionado;
            if(itemEditar.selecionado === false){
                setIdBanco(null);
            }
            else{
            setIdBanco(itemEditar.ban_id);
            }
            setListaParcelas(parcelas);
        } else {
            notification.warning({ message: 'Aviso', description: 'Selecione somente boletos do mesmo banco!' });
            return;
        }
    };

    function selecionarTodos(checked) {
        let parcelas = [...listaParcelas];
        // Verifica se todas as parcelas possuem o mesmo ban_id
        let verificaBanco = VerificaBanco(parcelas);
        if (verificaBanco === true) {
            parcelas.map((item) => {
                item.selecionado = (checked ? true : false);
            });
            setListaParcelas(parcelas);
        }
        else {
            notification.warning({ message: 'Aviso', description: 'Não é possível Selecionar todos, pois ha parcelas de bancos diferentes!' });
            return;
        }
    };

    // Função para verificar se todos os IDs dos bancos são iguais
    const VerificaBanco = (parcelas) => {
        let firstId = parcelas[0].ban_id;
        return parcelas.every(item => item.ban_id === firstId);
    };

    function salvarInstrucao(isp_codigo) {
        let parcelas = [...listaParcelas];
        if (!!parcelaEditar) {
            parcelas.find((parcela) => parcela.prc_id === parcelaEditar).isp_codigo = isp_codigo;
            setListaParcelas(parcelas);
        } else {
            parcelas.forEach((parcela, indice) => {
                parcela.isp_codigo = isp_codigo;
                if (indice === parcelas.length - 1) {
                    setListaParcelas(parcelas);
                }
            });
        }
        setOpenModalInstrucaoRemessa(false);
    };

    async function gerarRemessa() {
        if (listaParcelas.filter((parcela) => { return parcela.selecionado }).length === 0) {
            notification.warning({ message: 'Aviso', description: 'É preciso selecionar pelo menos uma parcela!' });
            return;
        }
        let bancoId = listaParcelas.filter((parcela) => { return parcela.selecionado })[0].ban_id;
        if (!!formulario.getFieldValue().ban_id || !!bancoId) {
            let dados = {};
            //pega somente as parcelas selecionadas
            let parcelas = listaParcelas.filter((parcela) => { return parcela.selecionado });
            dados.ban_id = !!formulario.getFieldValue().ban_id ? formulario.getFieldValue().ban_id : bancoId;
            dados.ban_descricao = parcelas[0].ban_descricao;
            dados.rem_qtdeboletos = parcelas.length;
            dados.rem_valortotal = parcelas.reduce((total, bol) => total + bol.bol_valor, 0);
            let padraoArquivoRemessa = parcelas[0].dbl_padraoarquivoremessa;
            dados.padraoarquivoremessa = padraoArquivoRemessa;
            dados.dbl_extensaoarquivoremessa = parcelas[0].dbl_extensaoarquivoremessa;
            dados.dbl_nomearquivormessa = parcelas[0].dbl_nomearquivoremessa;
            dados.parcelaboleto = tratarParcelasBoletos(parcelas);
            setDadosSelecionados(dados);
            setOpenModalGerarRemessa(true);
        }
    };

    function tratarParcelasBoletos(parcelas){
        let parcelaboletos = [];
        parcelas.forEach((item) => {
            let dados = {
                prc_id: item.prc_id,
                bol_id: item.bol_id
            };
            parcelaboletos.push(dados);
        });
        return parcelaboletos;
    };

    function downloadArquivo() {
        let dados = dadosSelecionados;
        api.post(`RemessaBoleto/GerarRemessa`, dados).then((retornoRemessa) => {
            if (!!retornoRemessa) {
                var parts = retornoRemessa.headers['content-type'].split('/');
                var filename = parts[0];
                var contentType = parts[1];

                try {
                    var blob = new Blob([retornoRemessa.data], { type: contentType });
                    //verifica se o usuário usa IE
                    var ua = window.navigator.userAgent;
                    var msie = ua.indexOf("MSIE ");
                    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
                        window.navigator.msSaveBlob(blob, filename);
                    } else  //se for outro navegador, return 0
                    {
                        //Cria a url para o blob
                        var url = window.URL.createObjectURL(blob);
                        var linkElement = document.createElement('a');
                        linkElement.setAttribute('href', url);
                        linkElement.setAttribute("download", filename);
                        //Força o download
                        var clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        linkElement.dispatchEvent(clickEvent);
                        setOpenModalGerarRemessa(false);
                        aoSalvar();
                    }
                } catch (ex) {
                    console.log(ex);
                }
            }
        }).catch(err => {
            console.log(err);
        });
    };

    function buscarBancos(ban_id) {
        let filtro = [];
        setBanco(ban_id);
        filtro = !!formulario.getFieldValue().ban_id ? `CodigoBanco=${formulario.getFieldValue().ban_id}` : `CodigoBanco=${(!!ban_id ? ban_id : '')}`;
        filtro += !!formulario.getFieldValue().ban_descricao ? `&Nome=${formulario.getFieldValue().ban_descricao}` : "";
        api.get(`Banco/Listar?${filtro}&ordem=%2Bban_descricao`).then((res) => {
            if (res.status === 200) {
                setListaBancos(res.data);
                formulario.setFieldsValue({ ban_id: (!!formulario.getFieldValue().ban_id ? formulario.getFieldValue().ban_id : ban_id) });
                listarParcelas(res.data.items[0].ban_id);
            }
        }).catch(err => {
            console.log(err);
        });
    };

    async function listarParcelas(ban_id) {
        let emissao = !!formulario.getFieldValue().bol_dataEmissao ? formulario.getFieldValue().bol_dataEmissao.format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
        let filtro = !!banco ? `CodigoBanco=${banco}` : (!!ban_id ? `CodigoBanco=${ban_id}` : '');
        filtro += `&DataInicial=${emissao}&DataFinal=${emissao}`
        api.get(`Boleto/ListarDados?${filtro}&ordem=%2Bbol_id`).then((res) => {
            if (res.status === 200) {
                setListaParcelas(res.data);
                if (!!ban_id || !!banco) {
                    setOpenModalInstrucaoRemessa(true);
                }
            }
        }).catch(err => {
            console.log(err);
        });
    };

    return (
        <div>
            <Form layout="vertical" form={formulario} name="manutencaoRemessa">
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item label="Banco" name="ban_id">
                            <SelectPaginado url="Banco/Listar?ordem=%2Bban_descricao" placeholder="Seleciona o Banco" form={formulario} name="ban_id"
                                onChangeFunction={(dados) => {
                                    formulario.setFieldsValue({ ban_id: null });
                                    buscarBancos(dados);
                                    listarParcelas(dados);
                                }} conteudo={
                                    ban => (<Select.Option value={ban.ban_id} key={ban.ban_id}>{ban.ban_descricao}</Select.Option>)
                                } />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={5} lg={4} xl={4}>
                        <Data label="Data de Emissão" name="bol_dataEmissao" onChange={listarParcelas} />
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={5} xl={5} className="t-mob-573">
                        <Button icon={<InfoCircleOutlined />} onChange={() => {
                            setParcelaEditar(null);
                            setOpenModalInstrucaoRemessa(true);
                        }} block>
                            Instrução
                        </Button>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={5} xl={5} className="t-mob-573">
                        <Button type="primary" icon={<RedoOutlined />} onClick={() => gerarRemessa()} block>
                            Gerar Remessa
                        </Button>
                    </Col>
                </Row>
                <div className="tabela">
                    <Table pagination={true} dataSource={listaParcelas} columns={
                        [
                            {
                                title: () => { return <div><Checkbox onChange={(event) => { selecionarTodos(event.target.checked) }} /></div> },
                                align: 'center',
                                width: 45,
                                render: ({ bol_id, selecionado }) => (
                                    <div>
                                        <Checkbox checked={selecionado} onChange={() => { selecionarItem(bol_id) }} />
                                    </div>
                                ),
                            },
                            {
                                title: 'Informações',
                                render: ({ pes_nome, ser_serie, ntf_numero, prc_numeroparcela }) => (
                                    <div>
                                        <Row align="middle">
                                            <Col>
                                                <b>{pes_nome}</b>
                                            </Col>
                                        </Row>
                                        <Row align="middle" gutter={[8, 0]}>
                                            <Col>
                                                Série: <b>{ser_serie}</b>
                                            </Col>
                                            <Col>
                                                Nº Doc.: <b>{ntf_numero}</b>
                                            </Col>
                                            <Col>
                                                Parcela: <b>{prc_numeroparcela}</b>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                            {
                                title: '',
                                render: ({ prc_datavencimento, ban_id, ban_descricao }) => (
                                    <div>
                                        <Row align="middle" gutter={[8, 0]}>
                                            <Col>
                                                <img className="imgLogoListaBanco" src={`${linkApi}Banco/RetornaLogo/${ban_id}`} alt={ban_descricao} />
                                            </Col>
                                            <Col>
                                                <b>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                            {
                                title: 'Valor (R$)',
                                align: 'right',
                                render: ({ bol_valor }) => (
                                    <div>
                                        <b className="f-18">{FormatNumber(bol_valor, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                key: 'x',
                                align: 'center',
                                width: 65,
                                fixed: 'right',
                                render: (record) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button icon={<InfoOutlined />} onClick={() => {
                                                    setParcelaEditar(record);
                                                    setOpenModalInstrucaoRemessa(true);
                                                }} />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]
                    } scroll={{ x: 900 }} />
                </div>
            </Form>
            <ModalInstrucaoRemessa exibirModalInstrucaoRemessa={openModalInstrucaoRemessa} fecharModalInstrucaoRemessa={() => setOpenModalInstrucaoRemessa(false)} form={formulario} parcela={parcelaEditar} onOk={salvarInstrucao} />
            <ModalGerarRemessa exibirModalGerarRemessa={openModalGerarRemessa} fecharModalGerarRemessa={() => setOpenModalGerarRemessa(false)} dadosSelecionados={dadosSelecionados} download={downloadArquivo} />
        </div>
    );

}