import React from "react";
import { Modal } from "antd";

import api from "../../services/api";
import { LancamentoCheque } from "../../components";

export default function ManutencaoCheque({ form, carregando, aoSalvar }) {

    const salvarManutencao = (values) => {
        carregando(true);
        api.post(`Cheque/Incluir?IdContaCorrente=${values.ctc_id}`, values.cheques).then(
            (retorno) => {
                if (retorno) {
                    Modal.success({
                        content: 'Cheque incluído com sucesso!',
                    });
                }
            }
        ).catch(
            (erro) => {
                error(erro.response.data.Message, "Não foi possível incluir o cheque!");
            }
        ).finally(() => {
            carregando(false);
            aoSalvar();
        });
    };

    function error(mensagem, title = 'Atenção!') {
        Modal.error({
            title: title,
            content: (
                <div>
                    <p>{mensagem}</p>
                </div>
            )
        });
    };

    return (
        <LancamentoCheque form={form} carregando={carregando} funcaoSalvar={salvarManutencao} />
    );

}