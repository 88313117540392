import React, { useEffect } from "react";
import { Row, Col, Tag, Popover, notification, Modal } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import DrawerFormaPagamento from './drawer';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, Editar, Excluir } from "../../components";
import roundValue from '../../ValueObjects/roundValue';

export default function FormaPagamento() {

    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+fpg_descricao' } }), []);

    const modalInativarRegistro = (dados) => {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: `Não foi possível excluir a forma de pagamento ${dados.fpg_descricao}. Deseja inativar este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                inativarRegistro(dados);
            }
        });
    };
    function excluirRegistro(dados) {
        api.delete(`FormaPagamento/Excluir/${dados.fpg_id}`).then(
            (res) => {
                notification.success({ message: `Sucesso!`, description: `Forma de Pagamento excluida com sucesso!` });
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }).catch(
                (erro) => {
                    modalInativarRegistro(dados);
                }
            ).finally(
                () => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+fpg_descricao' } });
                });
    }

    const modalExcluirCondicao = forma => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Excluir a forma de Pagamento ${forma.fpg_descricao} ?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(forma);
            }
        });
    };
    function inativarRegistro(dados) {
        dados.fpg_ativo = false;
        api.put(`FormaPagamento/Editar`, dados).then(
            res => {
                notification.success({ message: `Sucesso!`, description: `Forma de Pagamento inativada com sucesso!` })
            }
        ).catch(
            error => {
                console.log(error);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+fpg_descricao' } });
            }
        )
    }

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+fpg_descricao" },
                { label: "Nome Z - A", value: "-fpg_descricao" },
                { label: "Código Crescente", value: "+fpg_id" },
                { label: "Código Decrescente", value: "-fpg_id" }
            ]} />
            <div className="tabela">
                <TabelaDados url="FormaPagamento/listar" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ fpg_id, fpg_ativo }) => (
                            <Popover content={fpg_ativo ? 'Ativa' : 'Inativa'} placement="right">
                                <Tag color={fpg_ativo ? 'processing' : 'default'} className="w-100">
                                    <b>{fpg_id}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Forma de Pagamento',
                        render: ({ fpg_descricao, fpg_principal }) => (
                            <div>
                                <Row align="middle" gutter={[8, 0]}>
                                    <Col>
                                        <Tag color={fpg_principal ? 'processing' : 'default'}>
                                            <b>Principal</b>
                                        </Tag>
                                    </Col>
                                    <Col>
                                        <b>{fpg_descricao}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Conta Corrente',
                        render: ({ ctc_descricao }) => (
                            <div>
                                <b>{(!!ctc_descricao) ? (ctc_descricao) : 'Não Informada'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Desconto Padrão',
                        align: 'right',
                        render: ({ fpg_descontopadrao }) => (
                            <div>
                                <b>{roundValue(fpg_descontopadrao * 100)}%</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 65,
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Editar onClick={() => {
                                            record.dadosBoleto = {};
                                            record.fpg_descontopadrao = (record.fpg_descontopadrao && record.fpg_descontopadrao > 0) ? (record.fpg_descontopadrao * 100) : 0;
                                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                            dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                        }} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>
                                        <Excluir icon={<DeleteOutlined />} onClick={() => { modalExcluirCondicao(record) }}/>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerFormaPagamento />
        </div>
    );

}