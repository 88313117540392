import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, Checkbox, notification, Modal } from "antd";
import { ExclamationCircleOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import SelectPaginado from "../../selectPaginado";
import { selectPaginadoActions } from "../../../actions";
import { useStateValue } from "../../../state";

export default function ModalOutroContato({ form, listaOutrosContatos, exibirModalOutroContato, fecharModalOutroContato }) {

    const [dispatch] = useStateValue();

    useEffect(() => {
        if (exibirModalOutroContato && !!listaOutrosContatos) {
            let lista = [];
            if (!!listaOutrosContatos.toc_id) {
                lista.push({ name: "toc_id", campo: "filtro", value: listaOutrosContatos.toc_id });
            }

            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }

    });

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                form.resetFields();
                fecharModalOutroContato();
            }
        });
    };

    const onFinish = async values => {
        let indice = form.getFieldValue().indice;
        delete values.indice;
        let lista = [...listaOutrosContatos.dadosOutrosContatos];
        let listaTpOutroContato = await listarTpOutroContato(form.getFieldValue().toc_id);
        values.toc_descricao = listaTpOutroContato[0].toc_descricao;
        if (indice >= 0) {
            lista[indice] = values;
        }
        else {
            let verificaOcupacaoAdd = listaTpOutroContato[0];
            if (verificaOcupacaoAdd.length > 0) {
                fecharModalOutroContato();
                notification.info({ message: 'Contato já adicionada!' });
            } else {
                values.out_ativo = true;
                lista.push(values);
            }
        }
        listaOutrosContatos.setDadosOutrosContatos(lista);
        fecharModalOutroContato();
    };

    async function listarTpOutroContato(toc_id) {
        let req = await api.get(`TipoOutroContato?Filtro=${toc_id}`);
        if (req.status === 200 && !!req.data) {
            return req.data.items;
        }
        return {};
    }
    return (
        <Modal centered
            title="Cadastro de Outros Contatos"
            visible={exibirModalOutroContato}
            onCancel={() => {
                onCloseModal();
            }}
            onOk={() => form.submit()}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <Form layout="vertical" name="manutencaoOutroContato" form={form} onFinish={onFinish}>
                <Row gutter={[8, 0]}>
                    <Form.Item name="out_id" hidden />
                    <Form.Item name="pes_id" hidden>
                    </Form.Item>
                    <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                        <Form.Item label="Tipo de Contato" name="toc_id" rules={[{ required: true, message: 'Selecione o Tipo de Contato' }]}>
                            <SelectPaginado url="TipoOutroContato" placeholder="Selecione um Tipo de Contato" form={form} name="toc_id"
                                conteudo={
                                    toc => (<Select.Option value={toc.toc_id} key={toc.key}>{toc.toc_descricao}</Select.Option>)
                                } />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={11} xl={11}>
                        <Form.Item label="Descrição" name="out_descricao" rules={[{ required: true, message: 'Informe um Contato' }]}>
                            <Input placeholder="Informe um Contato" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name="out_ativo" valuePropName="checked" className="t-mob-573">
                            <Checkbox>Ativo</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}