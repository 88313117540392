import React from "react";
import { Form, Row, Col, Typography, Divider, Input } from "antd";

import api from '../../services/api';
import { InputCep } from "../../components";

export default function TabEndereco({ formulario }) {

    const verificaCep = () => {
        if (!!formulario.getFieldValue("ctc_cepagencia")) {
            api.get(`Cidade/BuscaCidadeCep?cep=${formulario.getFieldValue("ctc_cepagencia").match(/\d+/g).join('')}`).then(res => {
                if (res.status === 200) {
                    formulario.setFieldsValue({ ctc_cidadeagencia: res.data.length > 0 ? res.data[0].cid_descricao : '' });
                    formulario.setFieldsValue({ ctc_ufagencia: res.data.length > 0 ? res.data[0].est_sigla : '' });
                    formulario.setFieldsValue({ ctc_bairroagencia: res.data.length > 0 ? res.data[0].bai_nome : '' });
                    formulario.setFieldsValue({ ctc_enderecoagencia: res.data.length > 0 ? res.data[0].log_logradouro : '' });
                }
            });
        }
    }

    return (
        <div>
            <Row align="middle" gutter={[8, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-endereco.png").default} alt="Endereço" /> Endereço
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Defina o Endereço da Agência
                    </Divider>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={6} md={4} lg={4} xl={4} xxl={3}>
                            <InputCep label="CEP" name="ctc_cepagencia" onBlur={() => verificaCep()} />
                        </Col>
                        <Col xs={24} sm={14} md={6} lg={5} xl={5} xxl={4}>
                            <Form.Item label="Cidade" name="ctc_cidadeagencia">
                                <Input disabled placeholder="Informe a Cidade da Agência" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={4} md={3} lg={2} xl={2} xxl={2}>
                            <Form.Item label="UF" name="ctc_ufagencia">
                                <Input disabled placeholder="Informe UF" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={8} lg={7} xl={7} xxl={6}>
                            <Form.Item label="Endereço" name="ctc_enderecoagencia">
                                <Input placeholder="Informe o Endereço da Agência" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={3} lg={3} xl={3} xxl={3}>
                            <Form.Item label="Número" name="ctc_nroagencia">
                                <Input type="number"
                                    placeholder="Informe o Número" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={4} lg={3} xl={3} xxl={3}>
                            <Form.Item label="Complemento" name="ctc_complementoagencia" >
                                <Input placeholder="Informe o Complemento do Endereço" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={6} lg={4} xl={4} xxl={3}>
                            <Form.Item label="Bairro" name="ctc_bairroagencia">
                                <Input placeholder="Informe o Bairro do Endereço" />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Observações" name="ctc_observacoes">
                                <Input.TextArea placeholder="Informe Observações Complementares" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );

}