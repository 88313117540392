import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Divider, Form, Input, Select, InputNumber } from "antd";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { InputPreco } from "../../components";
import { getEmpresa } from "../../services/auth";

export default function TabBoleto({ formulario, dadosBoleto }) {
    const [{ manutencao }, dispatch] = useStateValue();
    const [listaJuroMora, setListaJuroMora] = useState([]);
    const [listaRegistro, setListaRegistro] = useState([]);
    const [listaFormaEmissao, setListaFormaEmissao] = useState([]);
    const [listaEspecieDocumento, setListaEspecieDocumento] = useState([]);
    const [listaTipoAceite, setListaTipoAceite] = useState([]);
    const [listaCodigoProtesto, setListaCodigoProtesto] = useState([]);
    const [listaDistribuicao, setListaDistribuicao] = useState([]);
    const [listaCodigoMulta, setListaCodigoMulta] = useState([]);
    const [listaCodigoBaixa, setListaCodigoBaixa] = useState([]);
    const [listaConvenio, setListaConvenio] = useState([]);
    const [listaCarteira, setListaCarteira] = useState([]);
    const [listaPadraoArq, setListaPadraoArq] = useState([]);
    const [contemProtesto, setContemProtesto] = useState(true);
    const [ehBaixa, setEhBaixa] = useState(true);
    const [modeloImpressao, setModeloImpressao] = useState([]);

    useEffect(() => {
        carregarDados();
    }, []);

    async function carregarDados() {
        let _banco = "";
        if (!!manutencao.dados && !!manutencao.dados.ban_id) {
            _banco = manutencao.dados.ban_id;
        }
        let jurosMora = await api.get('Enum/Listar?nome=BoletoTipoJuroMora');
        setListaJuroMora(jurosMora.data);
        let BoletoRegistro = await api.get('Enum/Listar?nome=BoletoRegistro');
        setListaRegistro(BoletoRegistro.data);
        let boletoFormaEmissao = await api.get('Enum/Listar?nome=BoletoFormaEmissao');
        setListaFormaEmissao(boletoFormaEmissao.data);
        let boletoEspecieDocumento = await api.get('Enum/Listar?nome=TipoEspecieDocumento');
        setListaEspecieDocumento(boletoEspecieDocumento.data);
        let boletoTipoAceite = await api.get('Enum/Listar?nome=BoletoTipoAceite');
        setListaTipoAceite(boletoTipoAceite.data);
        let boletoDistribuicao = await api.get('Enum/Listar?nome=BoletoDistribuicao');
        setListaDistribuicao(boletoDistribuicao.data)
        let boletoCodigoMulta = await api.get('Enum/Listar?nome=BoletoCodigoMulta');
        setListaCodigoMulta(boletoCodigoMulta.data);
        let boletoCodigoProtesto = await api.get('Enum/Listar?nome=BoletoCodigoProtesto');
        setListaCodigoProtesto(boletoCodigoProtesto.data);
        let boletoCodigoBaixa = await api.get('Enum/Listar?nome=BoletoCodigoBaixa');
        setListaCodigoBaixa(boletoCodigoBaixa.data);
        let boletoConvenio = await api.get(`BoletoConvenio/Listar?CodigoBanco=${_banco}&PageSize=500&filtro=`);
        setListaConvenio(boletoConvenio.data.items);
        let boletoCarteira = await api.get(`BoletoCarteira/Listar?filtro=&CodigoBanco=${_banco}&PageSize=500&Order=%2Bcrt_descricao`);
        setListaCarteira(boletoCarteira.data.items);
        let padraoArquivoRemessa = await api.get('Enum/Listar?nome=PadraoArquivoRemessa');
        setListaPadraoArq(padraoArquivoRemessa.data);
        let modeloImpresaoBoleto = await api.get('Enum/Listar?nome=BoletoModeloImpressao');
        setModeloImpressao(modeloImpresaoBoleto.data);
    };

    useEffect(() => {
        if (!!listaPadraoArq && listaPadraoArq.length > 0) {
            popularDadosBoleto();
        }
    }, [listaPadraoArq]);

    function popularDadosBoleto() {
        if (!!formulario.getFieldValue()) {
            let dados = { ...dadosBoleto.dadosBoleto };
            if (!!formulario.getFieldValue().crt_id) {
                let crt_id = parseInt(formulario.getFieldValue().crt_id);
                dados.crt_id = listaCarteira.filter((carteira) => (carteira.crt_id === crt_id))[0];
            }
            if (!!formulario.getFieldValue().dbl_tipojuromora) {
                let dbl_tipojuromora = parseInt(formulario.getFieldValue().dbl_tipojuromora);
                dados.dbl_tipojuromora = listaJuroMora.filter((juroMora) => (juroMora.key === dbl_tipojuromora))[0].key;
            }
            if (!!formulario.getFieldValue().dbl_formatonossonumero) {
                let dbl_formatonossonumero = parseInt(formulario.getFieldValue().dbl_formatonossonumero);
                dados.dbl_formatonossonumero = listaConvenio.filter((listaConvenio) => (listaConvenio.cvn_id === dbl_formatonossonumero))[0];
            }
            if (!!formulario.getFieldValue().dbl_formaemissao) {
                let dbl_formaemissao = parseInt(formulario.getFieldValue().dbl_formaemissao);
                dados.dbl_formaemissao = listaFormaEmissao.filter((formaEmissao) => (formaEmissao.key === dbl_formaemissao))[0].key;
            }
            if (!!formulario.getFieldValue().dbl_especiedocumento) {
                let dbl_especiedocumento = parseInt(formulario.getFieldValue().dbl_especiedocumento);
                dados.dbl_especiedocumento = listaEspecieDocumento.filter((EspecieDocumento) => (EspecieDocumento.key === dbl_especiedocumento))[0].key;
            }
            if (!!formulario.getFieldValue().dbl_aceite) {
                let dbl_aceite = formulario.getFieldValue().dbl_aceite;
                dados.dbl_aceite = listaTipoAceite.filter((tipoAceite) => (tipoAceite.key === dbl_aceite))[0].key;
            }
            if (!!formulario.getFieldValue().dbl_distribuicao) {
                let dbl_distribuicao = parseInt(formulario.getFieldValue().dbl_distribuicao);
                dados.dbl_distribuicao = listaDistribuicao.filter((distribuicao) => (distribuicao.key === dbl_distribuicao))[0].key;
            }
            if (!!formulario.getFieldValue().dbl_codigoprotesto) {
                let dbl_codigoprotesto = parseInt(formulario.getFieldValue().dbl_codigoprotesto);
                if (!!dbl_codigoprotesto && dbl_codigoprotesto !== 3) {
                    setContemProtesto(true);
                } else {
                    setContemProtesto(false);
                }
                dados.dbl_codigoprotesto = listaCodigoProtesto.filter((codigoProtesto) => (codigoProtesto.key === dbl_codigoprotesto))[0].key;
            }
            if (!!formulario.getFieldValue().dbl_codigomulta) {
                let dbl_codigomulta = parseInt(formulario.getFieldValue().dbl_codigomulta);
                dados.dbl_codigomulta = listaCodigoMulta.filter((codigoMulta) => (codigoMulta.key === dbl_codigomulta))[0].key;
            }
            if (!!formulario.getFieldValue().dbl_codigobaixa) {
                let dbl_codigobaixa = parseInt(formulario.getFieldValue().dbl_codigobaixa);
                if (!!dbl_codigobaixa && dbl_codigobaixa !== 2) {
                    setEhBaixa(true);
                } else {
                    setEhBaixa(false);
                }
                dados.dbl_codigobaixa = listaCodigoBaixa.filter((codigoBaixa) => (codigoBaixa.key === dbl_codigobaixa))[0].key;
            }
            if (!!formulario.getFieldValue().dbl_padraoarquivoremessa) {
                let dbl_padraoarquivoremessa = parseInt(formulario.getFieldValue().dbl_padraoarquivoremessa);
                dados.dbl_padraoarquivoremessa = listaPadraoArq.filter((padraoArq) => (padraoArq.key === dbl_padraoarquivoremessa))[0].key;
            }
            if (JSON.stringify(dados) !== "{}") {
                dadosBoleto.setDadosBoleto(dados);
            }
        }

    };

    function popularDadosCarteira() {
        let dados = { ...dadosBoleto.dadosBoleto };
        let crt_id = parseInt(formulario.getFieldValue().crt_id);
        if (crt_id) {
            dados.crt_id = listaCarteira.filter((carteira) => (carteira.crt_id === crt_id))[0].key;
            dadosBoleto.setDadosBoleto(dados);
        }
    };

    function popularJurosMora() {
        let dados = { ...dadosBoleto.dadosBoleto };
        let dbl_tipojuromora = parseInt(formulario.getFieldValue().dbl_tipojuromora);
        if (dbl_tipojuromora) {
            dados.dbl_tipojuromora = listaJuroMora.filter((juroMora) => (juroMora.key === dbl_tipojuromora))[0].key;
            dadosBoleto.setDadosBoleto(dados);
        }
    };

    function popularFormatoNossoNum() {
        let dados = { ...dadosBoleto.dadosBoleto };
        let dbl_formatonossonumero = parseInt(formulario.getFieldValue().dbl_formatonossonumero);
        if (dbl_formatonossonumero) {
            dados.dbl_formatonossonumero = listaConvenio.filter((listaConvenio) => (listaConvenio.cvn_id === dbl_formatonossonumero))[0].key;
            dadosBoleto.setDadosBoleto(dados);
        }
    };

    function popularFormaEmissao() {
        let dados = { ...dadosBoleto.dadosBoleto };
        let dbl_formaemissao = parseInt(formulario.getFieldValue().dbl_formaemissao);
        if (dbl_formaemissao) {
            dados.dbl_formaemissao = listaFormaEmissao.filter((formaEmissao) => (formaEmissao.key === dbl_formaemissao))[0].key;
            dadosBoleto.setDadosBoleto(dados);
        }
    };

    function popularEspecieDocumento() {
        let dados = { ...dadosBoleto.dadosBoleto };
        let dbl_especiedocumento = parseInt(formulario.getFieldValue().dbl_especiedocumento);
        if (dbl_especiedocumento) {
            dados.dbl_especiedocumento = listaEspecieDocumento.filter((EspecieDocumento) => (EspecieDocumento.key === dbl_especiedocumento))[0].key;
            dadosBoleto.setDadosBoleto(dados);
        }
    }

    function popularIdentificacaoTitulo() {
        let dados = { ...dadosBoleto.dadosBoleto };
        let dbl_aceite = formulario.getFieldValue().dbl_aceite;
        if (dbl_aceite) {
            dados.dbl_aceite = listaTipoAceite.filter((tipoAceite) => (tipoAceite.key === dbl_aceite))[0].key;
            dadosBoleto.setDadosBoleto(dados);
        }
    };

    function popularFormaDistribuicao() {
        let dados = { ...dadosBoleto.dadosBoleto };
        let dbl_distribuicao = parseInt(formulario.getFieldValue().dbl_distribuicao);
        if (dbl_distribuicao) {
            dados.dbl_distribuicao = listaDistribuicao.filter((distribuicao) => (distribuicao.key === dbl_distribuicao))[0].key;
            dadosBoleto.setDadosBoleto(dados);
        }
    };

    function popularProtestoTitulo() {
        let dados = { ...dadosBoleto.dadosBoleto };
        let dbl_codigoprotesto = parseInt(formulario.getFieldValue().dbl_codigoprotesto);
        if (dbl_codigoprotesto) {
            if (!!dbl_codigoprotesto && dbl_codigoprotesto !== 3) {
                setContemProtesto(true);
            } else {
                setContemProtesto(false);
            }
            dados.dbl_codigoprotesto = listaCodigoProtesto.filter((codigoProtesto) => (codigoProtesto.key === dbl_codigoprotesto))[0].key;
            dadosBoleto.setDadosBoleto(dados);
        }
    };

    function popularMultaTitulo() {
        let dados = { ...dadosBoleto.dadosBoleto };
        let dbl_codigomulta = parseInt(formulario.getFieldValue().dbl_codigomulta);
        if (dbl_codigomulta) {
            dados.dbl_codigomulta = listaCodigoMulta.filter((codigoMulta) => (codigoMulta.key === dbl_codigomulta))[0].key;
            dadosBoleto.setDadosBoleto(dados);
        }
    };

    function popularBaixaDevTitulo() {
        let dados = { ...dadosBoleto.dadosBoleto };
        let dbl_codigobaixa = parseInt(formulario.getFieldValue().dbl_codigobaixa);
        if (dbl_codigobaixa) {
            if (!!dbl_codigobaixa && dbl_codigobaixa !== 2) {
                setEhBaixa(true);
            } else {
                setEhBaixa(false);
            }
            dados.dbl_codigobaixa = listaCodigoBaixa.filter((codigoBaixa) => (codigoBaixa.key === dbl_codigobaixa))[0].key;
            dadosBoleto.setDadosBoleto(dados);
        }
    };

    function popularPadraoArquivoRemessa() {
        let dados = { ...dadosBoleto.dadosBoleto };
        let dbl_padraoarquivoremessa = parseInt(formulario.getFieldValue().dbl_padraoarquivoremessa);
        if (dbl_padraoarquivoremessa) {
            dados.dbl_padraoarquivoremessa = listaPadraoArq.filter((padraoArq) => (padraoArq.key === dbl_padraoarquivoremessa))[0].key;
            dadosBoleto.setDadosBoleto(dados);
        }
    };

    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-boleto.png").default} alt="Boleto" /> Boleto
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Informe abaixo os dados para que seja possível emitir boletos.
                    </Divider>
                </Col>
            </Row>
            <div className="m-t-16">
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                        <Form.Item hidden name="dbl_id"></Form.Item>
                        <Form.Item label="Número do Convênio" name="dbl_numeroconvenio">
                            <Input placeholder="Informe Número do Convênio" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                        <Form.Item label="Número do Contrato" name="dbl_numerocontrato">
                            <Input placeholder="Informe Número do Contrato" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                        <Form.Item label="Carteira" name="crt_id" rules={[{ required: true, message: 'Informe a Carteira do Título' }]}>
                            <Select allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione a Carteira do Título" onChange={() => { popularDadosCarteira() }}>
                                {listaCarteira.map((carteira) => (
                                    <Select.Option value={carteira.crt_id} key={carteira.crt_id}>{carteira.ban_id} - {carteira.crt_descricao}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                    <Form.Item label="Carteira Padrão" name="crt_id" rules={[{ required: true, message: 'Informe a Carteira Padrão do seu banco' }]}>
                        <Select allowClear={true} showSearch optionFilterProp="children" placeholder="Informe a Carteira Padrão do seu banco" onChange={() => { popularDadosCarteira() }}>
                            {listaCarteira.map((carteira) => (
                                <Select.Option value={carteira.crt_id} key={carteira.crt_id}>{carteira.crt_carteira}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                        <Form.Item label="Espécie Documento" name="dbl_especiedocumento" rules={[{ required: true, message: 'Informe a Espécie de Documento' }]}>
                            <Select allowClear={true} placeholder="Selecione a Espécie de Documento" onChange={() => { popularEspecieDocumento() }}>
                                {listaEspecieDocumento.map((EspecieDocumento) => (
                                    <Select.Option key={EspecieDocumento.key} value={EspecieDocumento.key}>{EspecieDocumento.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24}>
                        <Form.Item label="Mensagem 1ª Instrução" name="dbl_mensagem1">
                            <Input.TextArea placeholder="Informe sua Mensagem" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Mensagem 2ª Instrução" name="dbl_mensagem2">
                            <Input.TextArea placeholder="Informe sua Mensagem" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Mensagem 3ª Instrução" name="dbl_mensagem3">
                            <Input.TextArea placeholder="Informe sua Mensagem" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item label="Código do Juros de Mora" name="dbl_tipojuromora" rules={[{ required: true, message: 'Informe o Código do Juros de Mora' }]}>
                            <Select allowClear={true} placeholder="Selecione o Código do Juros de Mora" onChange={() => { popularJurosMora() }}>
                                {listaJuroMora.map(
                                    juroMora => (
                                        <Select.Option value={juroMora.key} key={juroMora.key}>{juroMora.value}</Select.Option>
                                    )
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.dbl_tipojuromora !== currentValues.dbl_tipojuromora}>
                            {({ getFieldValue }) => {
                                if (getFieldValue('dbl_tipojuromora') === 1) return (
                                    <Row>
                                        <Col span={24}>
                                            <InputPreco label="Valor de Juros de Mora (R$)" name="dbl_percjuromora" rules={[{ required: true, message: 'Informe Valor de Juros de Mora' }]} />
                                        </Col>
                                    </Row>
                                );
                                if (getFieldValue('dbl_tipojuromora') === 2) return (
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item label="Percentual de Juros de Mora" name="dbl_percjuromora" rules={[{ required: true, message: 'Informe Percentual de Juros de Mora' }]}>
                                                <InputNumber
                                                    min={0}
                                                    max={100}
                                                    precision={2}
                                                    formatter={value => `${value}%`}
                                                    parser={value => value.replace('%', '')}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                );
                                else return null;
                            }}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Mensagem de Juros de Mora" name="dbl_mensagemmora">
                            <Input.TextArea placeholder="Informe a Mensagem de Juros de Mora" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={12} xl={12}>
                        <Form.Item label="Formato do Nosso Número" name="dbl_formatonossonumero" rules={[{ required: true, message: 'Informe qual o Formato do Nosso Número' }]}>
                            <Select allowClear={true} placeholder="Selecione qual o Formato do Nosso Número" onChange={() => { popularFormatoNossoNum() }}>
                                {listaConvenio.map((convenio) => (
                                    <Select.Option key={convenio.cvn_id} value={convenio.cvn_id}>{convenio.cvn_descricao}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={12} xl={12}>
                        <Form.Item label="Forma de Emissão" name="dbl_formaemissao" rules={[{ required: true, message: 'Informe a Forma de Emissão' }]}>
                            <Select allowClear={true} placeholder="Selecione a Forma de Emissão" onChange={() => { popularFormaEmissao() }}>
                                {listaFormaEmissao.map((formaEmissao) => (
                                    <Select.Option key={formaEmissao.key} value={formaEmissao.key}>{formaEmissao.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Identificação do Título" name="dbl_aceite" rules={[{ required: true, message: 'Informe Identificação do Título' }]}>
                            <Select allowClear={true} placeholder="Selecione Identificação do Título" onChange={() => { popularIdentificacaoTitulo() }}>
                                {listaTipoAceite.map((tipoAceite) => (
                                    <Select.Option value={tipoAceite.key} key={tipoAceite.key}>{tipoAceite.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Distribuição" name="dbl_distribuicao" rules={[{ required: true, message: 'Informe a Forma de Distribuição do Título' }]}>
                            <Select allowClear={true} placeholder="Selecione a Forma de Distribuição do Título" onChange={() => { popularFormaDistribuicao() }}>
                                {listaDistribuicao.map((distribuicao) => (
                                    <Select.Option value={distribuicao.key} key={distribuicao.key}>{distribuicao.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Código de Protesto" name="dbl_codigoprotesto" rules={[{ required: true, message: 'Informe Código de Protesto do Título' }]}>
                            <Select allowClear={true} placeholder="Selecione o Código de Protesto do Título" onChange={() => { popularProtestoTitulo() }}>
                                {listaCodigoProtesto.map(codigoProtesto => (
                                    <Select.Option value={codigoProtesto.key} key={codigoProtesto.key}>{codigoProtesto.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {contemProtesto &&
                        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                            <Form.Item label="Número de Dias para Protesto" name="dbl_diasparaprotestar" rules={contemProtesto ? [{ required: true, message: 'Informe Número de Dias para Protestar' }] : null}>
                                <Input placeholder="Informe Número de Dias para Protestar" />
                            </Form.Item>
                        </Col>
                    }
                    {contemProtesto &&
                        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                            <InputPreco label="Valor Mínimo  para Protesto (R$)" name="dbl_valorminimoprotesto" />
                        </Col>
                    }
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Código da Multa" name="dbl_codigomulta" rules={[{ required: true, message: 'Informe o Código da Multa do Título' }]}>
                            <Select allowClear={true} placeholder="Selecione o Código da Multa do Título" onChange={() => { popularMultaTitulo() }}>
                                {listaCodigoMulta.map((codigoMulta) => (
                                    <Select.Option key={codigoMulta.key} value={codigoMulta.key}>{codigoMulta.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Número de Dias para Multa" name="dbl_diasaplicarmulta" rules={[{ required: true, message: 'Informe Número de Dias para Multa' }]}>
                            <Input placeholder="Informe Número de Dias para Aplicar Multa" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.dbl_codigomulta !== currentValues.dbl_codigomulta}>
                            {({ getFieldValue }) => {
                                if (getFieldValue('dbl_codigomulta') === 1) return (
                                    <Row>
                                        <Col span={24}>
                                            <InputPreco label="Valor da Multa (R$)" name="dbl_valormulta" rules={[{ required: true, message: 'Informe o Valor da Multa' }]} />
                                        </Col>
                                    </Row>
                                );
                                if (getFieldValue('dbl_codigomulta') === 2) return (
                                    <Row>
                                        <Col span={24}>
                                            <Form.Item label="Percentual de Multa" name="dbl_valormulta" rules={[{ required: true, message: 'Informe Percentual de Multa' }]}>
                                                <InputNumber
                                                    min={0}
                                                    max={100}
                                                    precision={2}
                                                    formatter={value => `${value}%`}
                                                    parser={value => value.replace('%', '')}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                );
                                else return null;
                            }}
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Código da Baixa/Devolução" name="dbl_codigobaixa" rules={[{ required: true, message: 'Informe o Código da Baixa/Devolução do Título' }]}>
                            <Select allowClear={true} placeholder="Selecione O Código da Baixa/Devolução do Título" onChange={() => { popularBaixaDevTitulo() }}>
                                {listaCodigoBaixa.map(codigoBaixa => (
                                    <Select.Option value={codigoBaixa.key} key={codigoBaixa.key}>{codigoBaixa.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {ehBaixa &&
                        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                            <Form.Item label="Número de Dias para Baixa/Devolução" name="dbl_diasbaixa" rules={ehBaixa ? [{ required: true, message: 'Informe o Número de Dias para Baixa/Devolução do Título' }] : null}>
                                <Input placeholder="Informe o Número de Dias para Baixa/Devolução do Título" />
                            </Form.Item>
                        </Col>
                    }
                    <Col span={24}>
                        <Form.Item label="Mensagem Referente ao Local de Pagamento" name="dbl_msglocalpagamento">
                            <Input.TextArea placeholder="Informe a Mensagem Referente ao Local de Pagamento" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Nosso Número Inicial" name="dbl_nossonumeroinicial" rules={[{ required: true, message: 'Informe o Nosso Número Inicial' }]}>
                            <Input placeholder="Informe o Nosso Número Inicial" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Nosso Número Atual" name="dbl_nossonumerofinal">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Modelo Impressão" name="dbl_modeloimpressao" rules={[{ required: true }]}>
                            <Select allowClear={true} placeholder="Selecione o Modelo de Impressão">
                                {modeloImpressao.map((modelo) => (
                                    <Select.Option value={modelo.key} key={modelo.key}>{modelo.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Nome do Arquivo de Remessa" name="dbl_nomearquivoremessa" rules={[{ message: 'Informe Nome do Arquivo de Remessa (BBBAAAACCCC)' }]}>
                            <Input placeholder="Informe Nome do Arquivo de Remessa" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Extensão Arquivo de Remessa" name="dbl_extensaoarquivoremessa" rules={[{ required: true, message: 'Informe a Extensão Arquivo de Remessa' }]}>
                            <Input placeholder="Informe a Extensão Arquivo de Remessa" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Padrão Arquivo Remessa" name="dbl_padraoarquivoremessa">
                            <Select allowClear={true} placeholder="Selecione o Padrão Arquivo Remessa" onChange={() => { popularPadraoArquivoRemessa() }}>
                                {listaPadraoArq.map(padraoArq => (
                                    <Select.Option value={padraoArq.key} key={padraoArq.key}>{padraoArq.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </div>
    );

}