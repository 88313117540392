import React, { useEffect, useState } from 'react';
import { Select } from 'antd';

import api from '../../services/api';
import { useStateValue } from '../../state';
import { selectPaginadoActions } from '../../actions';

export default function SelectPaginado({
    url, name, hidden = false, form, allowClear = true, order = "", placeholder = "Selecione um registro", onChangeFunction, onBlurFunction, onClearFunction, conteudo, campoFiltro = "filtro", multiple = false, setarPrimeiroReg = false, disabled = false
}) {
    const [{ manutencao, selectPaginado }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);
    const [lista, setLista] = useState([]);
    const [filtro, setFiltro] = useState('');
    const [pagina, setPagina] = useState(1);
    const [registrosPorPagina, setRegistrosPorPagina] = useState(50);
    const [alterouPag, setAlterouPag] = useState(false);
    const [campoDescricao, setCampoDescricao] = useState(null);
    const [valor, setValor] = useState(null);
    const [primeiroReg, setPrimeiroReg] = useState(setarPrimeiroReg);

    useEffect(() => {
        if (pagina === 1) {
            carregarDados();
        } else {
            setPagina(1);
            setAlterouPag(true);
        }
    }, [filtro]);

    useEffect(() => {
        carregarDados();
    }, [pagina]);

    useEffect(() => {
        if (!!lista && selectPaginado?.length > 0) {
            let lista = [...selectPaginado];
            let item = selectPaginado.find(s => { return s.name === name });
            if (!!item && !!item.value) {
                if (!!item.campo) {
                    setCampoDescricao(item.campo);
                }
                setPagina(1);
                setFiltro(item.value);
                let idx = lista.indexOf(item);
                lista.splice(idx, 1);
                dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } })
            }
        }
    }, [selectPaginado]);

    useEffect(() => {
        setValor(form.getFieldValue([name]));
    }, [form.getFieldValue([name])]);

    async function carregarDados() {
        let parametrosUrl = "";
        !!filtro ? parametrosUrl = `&${!!campoDescricao ? campoDescricao : campoFiltro}=${filtro}` : parametrosUrl = "";
        if (!!campoDescricao) {
            setCampoDescricao(null);
        }

        if (!!order) {
            parametrosUrl += `&order=${order}`;
        }
        parametrosUrl += `&PageNumber=${pagina}&PageSize=${registrosPorPagina}`;
        if (url.search(/[?]/g) === -1) {
            parametrosUrl = parametrosUrl.replace('&', '?');
        }
        setCarregando(true);
        let res = await api.get(`${url}${parametrosUrl}`);
        setCarregando(false);
        if (res.status == 200) {
            let indice = 0;
            let dados = [];

            if (filtro !== '' || alterouPag) {
                if (pagina > 1) {
                    indice = lista.length;
                    let l = [...lista];
                    l.forEach(i => {
                        dados.push(i);
                    });
                    res.data?.items?.map((item, idx) => {
                        item.key = indice;
                        dados.push(item);
                        indice++;
                    });
                    setLista(dados);
                } else {
                    res.data?.items?.map((item, idx) => {
                        item.key = indice;
                        dados.push(item);
                        indice++;
                    });
                    setLista(dados);
                }
                setAlterouPag(false);
            } else {
                setPagina(1);
                res.data?.items?.map((item, idx) => {
                    item.key = indice;
                    dados.push(item);
                    indice++;
                });
                setLista(dados);
            }
            if (primeiroReg && !!filtro && !!selectPaginado && !!selectPaginado.find(s => { return s.name === name })) {
                onChange(!!dados[0]?.id ? dados[0].id : null);
            }
        }
    };

    function onPopUpScroll(e) {
        const { target } = e;
        if (parseFloat(Math.round(target.scrollTop + target.offsetHeight).toFixed(2)) === target.scrollHeight || parseFloat(Math.round(target.scrollTop + target.offsetHeight).toFixed(2)) === target.scrollHeight - 1) {
            setPagina(pagina + 1);
            setAlterouPag(true);
        }
    };

    function onChange(valor) {
        if (!form) {
            console.log("Form não definido para o componente de select!");
        } else {
            if (!valor) {
                setFiltro('');
            }
            setValor(valor);
            form.setFieldsValue({ [name]: valor });
        }
        if (!!onChangeFunction && (!!valor || valor == 0)) {
            onChangeFunction(valor);
        }
    };

    function onBlur(valor) {
        if (!!onBlurFunction) {
            onBlurFunction(valor);
        }
    };

    function onClear(valor) {
        if (!!onClearFunction) {
            onClearFunction(valor);
        }
    };

    return (
        <Select
            mode={multiple ? "multiple" : ""}
            value={valor}
            loading={carregando}
            showSearch
            hidden={hidden}
            allowClear={allowClear}
            placeholder={placeholder}
            optionFilterProp="children"
            onSearch={(valor) => { setPrimeiroReg(false); setFiltro(valor); }}
            onPopupScroll={(e) => { onPopUpScroll(e); }}
            onChange={(valor) => { onChange(valor); }}
            onBlur={(valor) => { onBlur(valor); }}
            onClear={(valor) => { onClear(valor); }}
            disabled={disabled}
        >
            {lista.map(item =>
                conteudo(item)
            )}
        </Select>
    )
}