import { CheckOutlined } from "@ant-design/icons";
import { Button, Col, notification, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import { FormatNumber } from "../../../ValueObjects";
import { validarNumeros } from "../../../services/funcoes";

export default function TabFormaPagamentoSelecionar ({ formaPagamento, confirmarFpgs, modalFechada }) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [listaFpg, setListaFpg] = useState([]);
    const [botaoConfirmar, setBotaoConfirmar] = useState(true);

    useEffect(() => {
        valortotal();
    }, [formaPagamento]);

    useEffect(() => {
        setBotaoConfirmar(selectedRowKeys.length === 0);
    }, [selectedRowKeys]);

    useEffect(() => {
        if (modalFechada) {
            setSelectedRowKeys([]);
        }
    }, [modalFechada])

    function valortotal () {
        let pagamento = [...formaPagamento];
        let dados = pagamento;
        dados.forEach((itens) => {
            let desconto = validarNumeros((itens.orf_valortotal * itens.orf_percentualdesconto)/100)
            let acrescimo = validarNumeros((itens.orf_valortotal * itens.orf_percentualacrescimo)/100);
            let total = (validarNumeros(itens.orf_valortotal) + acrescimo) - desconto;

            itens.desconto = desconto;
            itens.acrescimo = acrescimo;
            itens.total = total;
        })
        setListaFpg(dados);
    }

    const fpgSelecionadas = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        if (newSelectedRowKeys.length === 0) {
            setListaFpg(formaPagamento.map(item => ({ ...item, disabled: false})));
            confirmarFpgs([]);
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: fpgSelecionadas,
    }

    const confirmarFpgSelecionadas = () => {
        if (selectedRowKeys.length > 1) {
            notification.warning({ message: 'Aviso!', description: 'Selecione apenas uma Forma de Pagamento' })
        } else {
            const novasFpgs = listaFpg.map(item => ({
                ...item,
                disabled: !selectedRowKeys.includes(item.orf_id)
            }));
            setListaFpg(novasFpgs);
            setBotaoConfirmar(true);
            notification.success({ message: 'Aviso!', description: 'Forma(s) de Pagamento confirmada(s) com sucesso' })
            confirmarFpgs(novasFpgs.filter(item => !item.disabled))
        }
    }

    return (
        <div>
            <div className="tabela m-t-8 p-b-75">
                <Row align="middle" justify="end" gutter={[8, 8]} className="m-t-5 m-b-5">
                    <Col xs={24} sm={12} md={12} lg={12} xl={10} xxl={6}>
                        <Button disabled={botaoConfirmar} onClick={confirmarFpgSelecionadas} icon={<CheckOutlined />} type="primary" block htmlType="submit">
                            Confirmar Formas de Pagamento Selecionadas
                        </Button>
                    </Col>
                </Row>
                <Table dataSource={listaFpg} rowKey={(record) => record.orf_id} rowClassName={(record) => record.disabled ? 'disabled-row' : ''} columns={
                    [
                        {
                            title: 'Forma de Pagamento',
                            render: ({ fpg_descricao, cpg_descricao }) => (
                                <div>
                                    <b>{fpg_descricao} {!!cpg_descricao ? `/ ${cpg_descricao}` : ''}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Desconto',
                            align: 'center',
                            render: ({ orf_percentualdesconto }) => (
                                <div>
                                    <b>{FormatNumber(orf_percentualdesconto, true)} %</b>
                                </div>
                            )
                        },
                        {
                            title: 'Acréscimo',
                            align: 'center',
                            render: ({ orf_percentualacrescimo }) => (
                                <div>
                                    <b>{FormatNumber(orf_percentualacrescimo, true)} %</b>
                                </div>
                            )
                        },
                        {
                            title: 'Valor',
                            align: 'right',
                            render: ({ total }) => (
                                <div>
                                    <b>R$ {FormatNumber(!!total ? total : 0, true)}</b>
                                </div>
                            )
                        }
                    ]
                } expandable={{
                    expandedRowRender: ({ orf_observacao }) => (
                        <div>
                            <Row gutter={[8, 0]}>
                                <Col span={[8, 0]}>
                                    <b>Obs.:</b> { !!orf_observacao ? orf_observacao : "Não Informado" }
                                </Col>
                            </Row>
                        </div>
                    )
                }} rowSelection={rowSelection} />
            </div>
        </div>
    )
}