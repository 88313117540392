import React, { useState } from 'react';
import ManutencaoUsuarioCat from './manutencao';
import { useStateValue } from '../../state';
import { Button, Col, Drawer, Form, Modal, Row } from 'antd';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';
import { CloseOutlined, ExclamationCircleOutlined, MinusOutlined, SaveOutlined } from '@ant-design/icons';

export default function DrawerUsuarioCatalogo({ atualizarTabela }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [formUsuario] = Form.useForm();

    if (manutencao.dados !== null) {
        formUsuario.setFieldsValue(manutencao.dados, { usu_sac: false, usu_suporte: false });
    }
    else {
        formUsuario.setFieldsValue({ usu_ativo: false, usu_usuariopdv: false, usu_admin: false, usu_sac: false, usu_suporte: false, listaFiliais: [] });
    }

    const [carregando, setCarregando] = useState(false);

    function fecharDrawer() {
        formUsuario.resetFields();

        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        atualizarTabela();
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
            }
        });
    };

    return (
        <Drawer title="Cadastro Usuários"
            width="70%"
            visible={ui.showDrawer}
            limparAoEditar={true}
            fecharDrawer={() => { fecharDrawer() }}
            closeIcon={<MinusOutlined />}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formUsuario.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoUsuarioCat formulario={formUsuario} carregando={setCarregando} aoSalvar={fecharDrawer} />
        </Drawer>

    )
}
