import React, { useEffect } from "react";
import { Row, Col } from "antd";
import moment from 'moment';

import { FormatNumber } from "../../ValueObjects";

export default function InfoCheque({ dadosCheque }) {

    return (
        <div>
            <Row align="middle" justify="space-between" gutter={[16, 0]}>
                <Col>
                    Recebido de: <b>{dadosCheque?.pessoa.pes_nome}</b>
                </Col>
                <Col>
                    Banco: <b>{dadosCheque.ban_id} - {dadosCheque?.banco.ban_descricao}</b>
                </Col>
                <Col>
                    Cheque Emitido em: <b>{moment(dadosCheque.chq_dataemissao).format('DD/MM/YYYY')}</b>
                </Col>
                <Col>
                    Agência: <b>{dadosCheque.chq_agencia}</b>
                </Col>
                <Col>
                    Nº Conta: <b>{dadosCheque.chq_contacorrente}</b>
                </Col>
                <Col hidden={!!!dadosCheque.chq_bompara}>
                    Bom para: <b>{moment(dadosCheque.chq_bompara).format('DD/MM/YYYY')}</b>
                </Col>
                <Col>
                    Série: <b>{dadosCheque.chq_serie}</b>
                </Col>
                <Col>
                    Número do Cheque: <b>{dadosCheque.chq_numero}</b>
                </Col>
                <Col>
                    Valor do Cheque (R$): <b className="f-18"> {FormatNumber(dadosCheque.chq_valor, true)}</b>
                </Col>
            </Row>
        </div>
    );
}