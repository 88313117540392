import React, { useEffect, useState } from "react";
import { Row, Col, Button, Typography, Divider, notification, Spin, Form, Select, Card } from "antd";
import { DownloadOutlined, LoadingOutlined, MehOutlined, SyncOutlined } from "@ant-design/icons";

import api from "../../services/api";
import ImprimirImpEtiqueta from "./manutencaoEtiqueta";
import { isObjetoDiffVazio } from "../../services/funcoes";
import { carregarConfiguracoes, impressorasInstaladas, ServidorImpressoraOnline } from "../../services/servidorImpressora";

export default function ServidorImpressao({ formulario, carregando, aoSalvar, servidor, menuConfig = false }) {
    const [carregandoServer, setCarregandoServer] = useState(false);
    const [listaImpressoras, setListaImpressoras] = useState([]);
    const [listaModeloEtiqueta, setListaModeloEtiqueta] = useState([]);
    const [abrirManutencao, setAbrirManutencao] = useState(false);
    const [config, setConfig] = useState({});
    const [dadosConfiguracoes, setDadosConfiguracoes] = useState([]);
    const [form] = Form.useForm();
    useEffect(() => {
        verificarConfiguracao();
        listarImpressoras();
        listarModeloEtiqueta();
    }, []);

    useEffect(() => {
        if (!!config && isObjetoDiffVazio(config)) {
            let modelo = listaModeloEtiqueta.length > 0 ? listaModeloEtiqueta.filter((modelo) => modelo.met_id === config.modeloEtiqueta.met_id)[0] : { key: null };
            if (!!modelo) {
                form.setFieldsValue({ modeloEtiquetaImp: modelo.met_id });
            } else {
                form.setFieldsValue({ modeloEtiquetaImp: null });
            }
        }
    }, [listaModeloEtiqueta]);

    useEffect(() => {
        if (!!config && isObjetoDiffVazio(config)) {
            let impressoraEtiqueta = listaImpressoras.length > 0 ? listaImpressoras.filter((impressora) => impressora.name === config.impressoraEtiqueta.name)[0] : { key: null };
            let impressoraNFiscal = listaImpressoras.length > 0 ? listaImpressoras.filter((impressora) => impressora.name === config.impressoraNaoFiscal.name)[0] : { key: null };
            impressoraEtiqueta = !!impressoraEtiqueta ? impressoraEtiqueta :{key: null};
            impressoraNFiscal = !!impressoraNFiscal ? impressoraNFiscal :{key: null};
            form.setFieldsValue({ impEtiquetaUtilizar: impressoraEtiqueta.key, impressoraNaoFiscal: impressoraNFiscal.key })
        }
    }, [listaImpressoras]);

    async function verificarConfiguracao() {
        let ret = await carregarConfiguracoes();
        if (!!ret && !!ret.impressoraEtiqueta && !!ret.impressoraNaoFiscal && !!ret.modeloEtiqueta) {
            setConfig(ret);
            listarImpressoras();
            listarModeloEtiqueta();
            if (!menuConfig) {
                setAbrirManutencao(true);
            }

        }
    };

    function downloadServidor() {
        let link = document.createElement("a");
        link.download = "new-erp.print-server_0.0.1-x86_64.exe";
        link.href = "http://update.newerp.com.br/ServidorImpressao/new-erp.print-server_0.0.1-x86_64.exe";
        link.click();
    };

    async function verificarServidorNovamente() {
        setCarregandoServer(true);
        let ret = await ServidorImpressoraOnline();
        servidor.setServidorOn(ret.online);
        setCarregandoServer(false);
    };

    async function listarImpressoras() {
        let retImp = await impressorasInstaladas();
        let key = 0;
        if (!!retImp) {
            retImp.forEach((impressora) => {
                impressora.key = key;
                key++;
            });
            setListaImpressoras(retImp);
        }
    };

    function listarModeloEtiqueta() {
        api.get(`ModeloEtiqueta/Listar?PageSize=200&PageNumber=1&ordem=met_descricao`).then(
            res => {
                setListaModeloEtiqueta(res.data.items);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function salvarConfiguracoes() {
        let dados = {
            impressoraEtiqueta: listaImpressoras.filter((imp) => (imp.key === form.getFieldValue().impEtiquetaUtilizar))[0],
            modeloEtiqueta: listaModeloEtiqueta.filter((imp) => (imp.met_id === form.getFieldValue().modeloEtiquetaImp))[0],
            impressoraNaoFiscal: listaImpressoras.filter((imp) => (imp.key === form.getFieldValue().impressoraNaoFiscal))[0],
            padraoDYMO: null
        };
        api.post(`http://localhost:3001/configuracao`, JSON.stringify(dados), { headers: { 'Content-type': 'application/json;charset=utf-8' } }).then(
            res => {
                notification.success({ description: 'Suas definições para o servidor de impressão do GestorWeb neste computador foram salvas com sucesso!', message: 'Servidor de Impressão Configurado' });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                if (!!aoSalvar) {
                    aoSalvar();
                }
                verificarConfiguracao();
            }
        );

    };

    return (
        <div className="pages-col">
            {!abrirManutencao && <div>
                <div>
                    <Row align="middle">
                        <Col span={24}>
                            <Typography.Title level={3}>
                                <img src={require("../../assets/i-print.png").default} alt="Servidor de Impressão" /> Servidor de Impressão Gestor Web
                            </Typography.Title>
                            <Typography.Text>
                                O servidor de impressão do Gestor Web, é um software que deve ser instalado no computador para possibilitar a comunicação do Gestor Web em seu navegador de internet com as impressoras instaladas em seu computador.
                                Desta forma, através do servidor de impressão, é possível realizar impressões como etiquetas de produtos e comprovantes não fiscais diretamente do Gestor Web.
                            </Typography.Text>
                            <Divider plain />
                        </Col>
                    </Row>
                    <Row align="middle" justify="end">
                        <Col>
                            <div className="i-Position p-relative">
                                <Button type="primary" shape="circle" size="large" icon={<DownloadOutlined />} onClick={() => { downloadServidor() }} />
                            </div>
                        </Col>
                    </Row>
                </div>
                {carregandoServer && <div className="servImpressao">
                    <Row justify="center">
                        <Col span={24} >
                            <Spin size="small" indicator={<LoadingOutlined spin />} />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={24}>
                            <span>
                                Verificando Servidor de Impressão do Gestor Web. Aguarde...
                            </span>
                        </Col>
                    </Row>
                </div>}
                {servidor.servidorOn && <div>
                    <Card style={{ padding: 30 }}>
                        <Typography.Title level={2} className="text-center">
                            Configurações do Servidor de Impressão do Gestor Web para este computador
                        </Typography.Title>
                        <Form layout="vertical" form={form}>
                            <Row gutter={[8, 0]} >
                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                    <Form.Item name="impEtiquetaUtilizar" label="Impressora de Etiquetas a Utilizar">
                                        <Select allowClear>
                                            {listaImpressoras.map((imp) => (
                                                <Select.Option key={imp.key} value={imp.key}>{imp.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                    <Form.Item name="modeloEtiquetaImp" label="Modelo de Etiqueta a Imprimir">
                                        <Select allowClear>
                                            {listaModeloEtiqueta.map((modelo) => (
                                                <Select.Option key={modelo.met_id} value={modelo.met_id}>{modelo.met_descricao}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                    <Form.Item name="impressoraNaoFiscal" label="Impressora Não Fiscal a Utilizar">
                                        <Select allowClear>
                                            {listaImpressoras.map((imp) => (
                                                <Select.Option key={imp.key} value={imp.key}>{imp.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="end">
                                <Button type="primary" size="middle" onClick={() => { salvarConfiguracoes() }}>
                                    Salvar configurações
                                </Button>
                            </Row>
                        </Form>
                    </Card>
                </div>}
                {!servidor.servidorOn && !carregandoServer && <div>
                    <Row align="middle" justify="center">
                        <Col span={18}>
                            <Typography.Title level={3} className="text-center">
                                <MehOutlined /> OPS!<br /> Parece que o servidor de impressão não está em execução no seu computador.
                            </Typography.Title>
                        </Col>
                    </Row>
                    <Row align="middle"  justify="center" className="m-t-16">
                        <Col span={24}>
                            <Row align="middle" gutter={[0, 16]} justify="center">
                                <Col xs={12} sm={10} md={8} lg={6} xl={6} className="img">
                                    <img src={require("../../assets/i-servidor.png").default} alt="Servidor de Impressão" />
                                </Col>
                                <Col xs={24} sm={24} md={16} lg={18} xl={18}>
                                    <Typography.Title level={3}>
                                        O que posso fazer nesse caso?
                                    </Typography.Title>
                                    <Typography.Text>
                                        Verifique se o servidor de impressão esta instalado em seu computador e o inicie: <br /><br />
                                        1. Abra o menu iniciar em seu computador;<br />
                                        2. Procure por: "new-erp.print-server";<br />
                                        3. Abra o servidor de impressão (new-erp.print-server);<br />
                                        4. Após abrir o programa, minimize a tela do servidor de impressão (new-erp.print-server), para que ele permaneça em execução em seu computador.
                                    </Typography.Text>
                                    <Typography.Title level={3}>
                                        O servidor de impressão do Gestor Web não está instalado em seu computador?<br />
                                    </Typography.Title>
                                    <div className="m-t-16">
                                        <Typography.Text>
                                            1. Realize o download do instalador do servidor de impressão <a download="new-erp.print-server_0.0.1-x86_64.exe" href="http://update.newerp.com.br/ServidorImpressao/new-erp.print-server_0.0.1-x86_64.exe">CLICANDO AQUI</a>;<br />
                                            2. Após concluir o download, abra o instalador;<br />
                                            3. Siga as instruções do assistente de instalação até concluir o processo;<br />
                                            4. Ao concluir o processo de instalação, o servidor de impressão é iniciado automaticamente. Ao abrir, minimize a tela do servidor de impressão, para que ele permaneça em execução em seu computador; <br />
                                            5. Não se preocupe, após instalado o servidor de impressão se configura para iniciar automaticamente quando você ligar seu computador.
                                        </Typography.Text>
                                    </div>
                                </Col>
                            </Row>
                            <Row align="middle" justify="center" className="m-t-8">
                                <Col>
                                    <Button type="primary" onClick={() => { verificarServidorNovamente() }} icon={<SyncOutlined />} size="large">
                                        Verificar Novamente
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>}
            </div>}
            {abrirManutencao && <ImprimirImpEtiqueta config={config} />}
        </div >
    );

}