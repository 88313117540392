import React, { useState, useEffect } from "react";
import { Row, Col, Tabs, Form, Select, Button, Collapse, Input } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, CloseOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";

import api from '../../services/api';
import DrawerNotaFiscalTransferencia from './drawer';
import { BreadcrumbIncluirDados, Data, ComboData } from "../../components";
import { TabNotaFiscalEmitida, TabNotaFiscalRecebida } from './tabPage';
import { listagemActions, selectPaginadoActions } from "../../actions";
import { useStateValue } from "../../state";

export default function NotaFiscalTransferencia() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [formPesquisa] = Form.useForm();
    const [formulario] = Form.useForm();
    const [statusNfe, setStatusNfe] = useState([]);
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [open, setOpen] = useState(false);

    const togglePesquisa = () => {
        setOpen(!open);
    };
    useEffect(() => {
        carregarDados();
    }, []);

    useEffect(() => {
        api.get(`Enum/Listar?nome=StatusNFe`).then(res => {
            if (res.status === 200) {
                setStatusNfe(res.data);
            }
        }).catch((erro) => console.error(erro));
        formPesquisa.setFieldsValue({ dataFinal: moment(new Date()) });
        formPesquisa.setFieldsValue({ dataInicial: moment(formPesquisa.getFieldValue().dataPesquisa) });
    }, []);

    function alternarTab(aba) {
        setAbaSelecionada(aba);
        carregarDados(null, null, aba);
    };
    function aplicarFiltrosAvancados() {
        let dataInicial = !!formPesquisa.getFieldsValue().dataInicial ? moment(formPesquisa.getFieldValue().dataInicial).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
        let dataFinal = !!formPesquisa.getFieldValue().dataFinal ? moment(formPesquisa.getFieldValue().dataFinal).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
        carregarDados(dataInicial, dataFinal);
    };

    function retornaDescricaoStatus(ntf_status) {
        if (!!statusNfe) {
            return statusNfe.filter((statusNota) => (
                statusNota.key === ntf_status
            ))[0].value;
        }
    };

    function carregarDados(dataInicial, dataFinal, aba) {
        dataFinal = !!dataFinal ? dataFinal : moment(new Date()).format('YYYY-MM-DD');
        dataInicial = !!dataInicial ? dataInicial : !!formPesquisa.getFieldValue().dataPesquisa ? moment(formPesquisa.getFieldValue().dataPesquisa).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
        let filtros = `&situacao=0,1`;
        filtros += !!formulario.getFieldValue().pesquisa ? `&filtro=${formulario.getFieldValue().pesquisa}` : '';
        filtros += `&status=${(!!formulario.getFieldValue().situacaoNotaTransferencia ? formulario.getFieldValue().situacaoNotaTransferencia.join(',') : '0,1,2,3,4,5')}`;
        if (!!aba) {
            if (aba === "1") {
                filtros += '&tipoNFTransferencia=1';
            } else {
                filtros += '&tipoNFTransferencia=0';
            }
        } else {
            if (abaSelecionada === "1") {
                filtros += '&tipoNFTransferencia=1';
            } else {
                filtros += '&tipoNFTransferencia=0';
            }
        }
        filtros += `&dataFinal=${dataFinal}&dataInicial=${dataInicial}`;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `${filtros}`, ordem: '-NotaFiscal.ntf_dataemissao' } })
    }

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <div>
                <Form layout="vertical" form={formulario}>
                    <Row align="middle" gutter={[8, 0]}>
                        <Col xs={24} sm={17} md={17} lg={17} xl={7} xxl={8}>
                            <Form.Item label='Pesquisar por' name="pesquisa">
                                <Input.Search placeholder='Destinatário, Nº da Nota Fiscal, Chave de Acesso da NF-e, Nome, CNPJ, Nome Fantasia' onSearch={valor => { carregarDados() }} allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={7} md={7} lg={7} xl={3} xxl={3}>
                            <ComboData form={formPesquisa} eventoAoAlterar={() => {
                                formPesquisa.setFieldsValue({ dataFinal: moment(new Date()) });
                                formPesquisa.setFieldsValue({ dataInicial: moment(formPesquisa.getFieldValue().dataPesquisa) });
                                carregarDados(moment(formPesquisa.getFieldValue().dataInicial).format('YYYY-MM-DD'), moment(formPesquisa.getFieldValue().dataFinal).format('YYYY-MM-DD'));
                            }} />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={13}>
                            <Form.Item label="Situação da Nota Fiscal de Transferência" name="situacaoNotaTransferencia" initialValue={[0, 1, 2, 3, 4, 5]}>
                                <Select allowClear mode="tags" name="situacaoNotaTransferencia" placeholder="Status da Nota Fiscal" onChange={() => carregarDados()} showSearch optionFilterProp="children">
                                    {statusNfe.map((item) => (
                                        <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Form layout="vertical" form={formPesquisa}>
                    <div className="page m-t-8">
                        <Row align="bottom" gutter={[0, 8]}>
                            <Col span={24}>
                                <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                                    <div className="colDet"></div>
                                    <Collapse.Panel key="1" showArrow={false} extra={
                                        <Button type="primary" onClick={togglePesquisa} block>
                                            {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                        </Button>
                                    }>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} sm={12} md={5} lg={4} xl={3} xxl={3}>
                                                <Data label="Data Inicial" name="dataInicial" />
                                            </Col>
                                            <Col xs={24} sm={12} md={5} lg={4} xl={3} xxl={3}>
                                                <Data label="Data Final" name="dataFinal" />
                                            </Col>
                                            <Col xs={24} sm={24} md={14} lg={16} xl={17} xxl={18} className="t-mob">
                                                <Row gutter={[8, 8]} className="tt-5">
                                                    <Col xs={24} sm={12} md={8} lg={8} xl={5} xxl={3}>
                                                        <Button icon={<CloseOutlined />} block>
                                                            Remover Filtros
                                                        </Button>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={8} lg={8} xl={5} xxl={3}>
                                                        <Button type="primary" icon={<SearchOutlined />} block onClick={() => { aplicarFiltrosAvancados() }}>
                                                            Aplicar Filtros
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="m-t-19">
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => {
                    alternarTab(tab);
                }}>
                    <Tabs.TabPane tab="Emitidas" key="1">
                        <TabNotaFiscalEmitida retornaDescricaoStatus={retornaDescricaoStatus} carregarDados={carregarDados} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Recebidas" key="2">
                        <TabNotaFiscalRecebida carregarDados={carregarDados} />
                    </Tabs.TabPane>
                </Tabs>
            </div>
            <DrawerNotaFiscalTransferencia carregarDados={carregarDados} />
        </div>
    );
}