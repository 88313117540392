import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, notification, Row, Tabs } from "antd";
import { TabNotaFiscal, TabItens, TabTransporte, TabFaturamento } from "./tabPage";
import moment from "moment";
import { getNomeUsuario, getIdUsuario, getEmpresa, getLocal } from '../../services/auth';

import api from "../../services/api";
import { useStateValue } from "../../state";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { isNotNullOrEmpty, isObjetoDiffVazio } from "../../services/funcoes";

export default function ImportacaoNotaFiscalEntrada({ form, exibir, fechar }) {
    const [{ manutencao }, dispatch] = useStateValue();
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [dadosNota, setDadosNota] = useState([]);
    const [dadosItens, setDadosItens] = useState([]);
    const [dadosFaturamento, setDadosFaturamento] = useState([]);
    const [faturamentoDados, setFaturamentoDados] = useState([]);
    const [dadosRemetente, setDadosRemetente] = useState([])
    const [dadosTransporte, setDadosTransporte] = useState([]);
    const [correlacaoCFOP, setCorrelacaoCFOP] = useState([]);
    const [indicadorPagamento, setIndicadorPagamento] = useState([]);
    const [desabilitaBotao, setDesabilitaBotao] = useState(true);
    const [keys, setKeys] = useState([0]);
    const [carregando, setCarregando] = useState(false);
    const [listapreco, setListaPreco] = useState([]);
    const [parametrosEmpresa, setParametrosEmpresa] = useState({});

    const salvarNota = (valores) => {
        validarDados(() => {
            setCarregando(true);
            let dados = {};
            let find = dadosNota.find(objeto => objeto.nome === 'xmlBase64');
            let findXml = dadosNota.find(objeto => objeto.nome === 'xml');
            dados.xml = !!find ? atob(find.valor) : findXml.valor;
            dados.ntf_numero = dadosNota.find(objeto => objeto.nome === 'ntf_numero')?.valor;
            dados.ntf_dataemissao = moment(dadosNota.find(objeto => objeto.nome === 'ntf_dataemissao')?.valor);
            dados.ntf_chavenfe = dadosNota.find(objeto => objeto.nome === 'ntf_chavenfe')?.valor;
            dados.ntf_modalidadefrete = dadosNota.find(objeto => objeto.nome === 'ntf_modalidadefrete')?.valor;
            dados.ntf_finalidade = dadosNota.find(objeto => objeto.nome === 'ntf_finalidade')?.valor;
            dados.ser_numero = dadosNota.find(objeto => objeto.nome === 'ser_numero')?.valor;
            dados.mdf_id = dadosNota.find(objeto => objeto.nome === 'mdf_id')?.valor;
            dados.vDesconto = parseFloat(dadosNota.find(objeto => objeto.nome === 'vDesconto')?.valor);
            dados.vOutrasDespesas = parseFloat(dadosNota.find(objeto => objeto.nome === 'vOutrasDespesas')?.valor);
            dados.vValorFrete = parseFloat(dadosNota.find(objeto => objeto.nome === 'vValorFrete')?.valor);
            dados.vValorSeguro = parseFloat(dadosNota.find(objeto => objeto.nome === 'vValorSeguro')?.valor);
            dados.vValorTotalNfe = parseFloat(dadosNota.find(objeto => objeto.nome === 'vValorTotalNfe')?.valor);
            dados.vValorTotalProdutos = parseFloat(dadosNota.find(objeto => objeto.nome === 'vValorTotalProdutos')?.valor);
            dados.vBaseCalculoIcms = parseFloat(dadosNota.find(objeto => objeto.nome === 'vBaseCalculoIcms')?.valor);
            dados.vValorIcms = parseFloat(dadosNota.find(objeto => objeto.nome === 'vValorIcms')?.valor);
            dados.vValorIpi = parseFloat(dadosNota.find(objeto => objeto.nome === 'vValorIpi')?.valor);
            dados.vBaseCalculoIcmsSt = parseFloat(dadosNota.find(objeto => objeto.nome === 'vBaseCalculoIcmsSt')?.valor);
            dados.vValorIcmsSt = parseFloat(dadosNota.find(objeto => objeto.nome === 'vValorIcmsSt')?.valor);
            dados.vValorCofins = parseFloat(dadosNota.find(objeto => objeto.nome === 'vValorCofins')?.valor);
            dados.vValorPis = parseFloat(dadosNota.find(objeto => objeto.nome === 'vValorPis')?.valor);
            dados.ope_id = valores.ope_id;
            dados.ntf_datainclusao = moment(new Date());
            dados.valorFreteForaNota = parseFloat(valores.freteForaNota);
            dados.valorICMSSTForaNota = parseFloat(valores.icmsstForaNota);
            dados.valorOutrasDespesasForaNota = parseFloat(valores.outrasDespesasForaNota);
            dados.dadosRemetente = popularDadosRemetente();
            dados.dadosFaturamento = popularDadosFaturamento();
            dados.dadosTransportador = popularDadosTransportador();
            dados.dadosItens = dadosItens;
            dados.mar_id = form.getFieldsValue().mar_id ?? null;
            if (parametrosEmpresa.par_calculaprecovenda == true) {
                listaPreco(dadosItens);
            }
            incluirDadosNota(dados);
        });
    };

    useEffect(() => {
        api.get('/ParametroEmpresa/BuscarParametros').then(res => {
            setParametrosEmpresa(res.data);
        }).catch((erro) => console.error(erro));
        buscarCorrelacaoCFOP();
        buscarIndicadorPagamento();
    }, []);

    useEffect(() => {
        if (exibir && !!manutencao.dados) {
            if (!!manutencao.dados.notaEntrada) {
                setDadosNota(manutencao.dados.notaEntrada);
            }
            if (!!manutencao.dados.dadosItens) {
                let i = 0;
                let itens = [];
                while (i < manutencao.dados.dadosItens.length) {
                    // manutencao.dados.dadosItens.i = i;
                    itens.push(manutencao.dados.dadosItens[i]);
                    i++;
                }
                const itensOrd = itens.map(subvetor => { const objeto = {}; subvetor.forEach(item => { objeto[item.nome] = item.valor; }); return objeto; });
                setDadosItens(itensOrd);
            }
            if (!!manutencao.dados.dadosFaturamento) {
                let i = 0;
                let itens = [];
                while (i < manutencao.dados.dadosFaturamento.length) {
                    itens.push(manutencao.dados.dadosFaturamento);
                    i++;
                }
                itens.forEach((item) => {
                    item.formaPagamento = [];
                    item.condicaoPagamento = [];
                    if (item.nfp_valor == null) item.nfp_valor = 0;
                });
                let keys = [];
                for (const [i, fat] of itens.entries()) {
                    fat.key = i;
                    fat.id = i;
                    keys.push(i);
                }
                setKeys(keys);
                setDadosFaturamento(itens);
            }
            if (!!manutencao.dados.dadosRemetente) {
                setDadosRemetente(manutencao.dados.dadosRemetente);
            }
            if (!!manutencao.dados.dadosTransportador) {
                setDadosTransporte(manutencao.dados.dadosTransportador);
            }
        }
    }, [exibir]);

    function incluirDadosNota(dados) {
        api.post(`NotaFiscalEntrada/IncluirNotaEntrada`, dados).then(
            res => {
                notification.success({ description: 'Nota importada com sucesso!', message: 'Aviso' });
                setTimeout(() => { window.location = "/Home#/monitoramentoNfe"; }, 1000);
            }
        ).catch(
            erro => {
                setCarregando(false);
                if (!!erro.response && !!erro.response.data && !!erro.response.data.Message) {
                    Modal.error({ content: erro.response.data.Message, title: 'Aviso' });
                }
                console.log(erro);
            }
        ).finally(
            () => {
                setCarregando(false);
            }
        );
    };

    async function listaPreco(dadosItens) {
        let ListaPrecoItem = [...listapreco];
        for (var item of dadosItens) {
            let idProduto = item.dadosItensSistema[0].pro_id;
            let idProdutoGrade = item.dadosItensSistema[0].prg_id;
            let precoLista = {};
            precoLista.pro_id = idProduto;
            precoLista.prg_id = idProdutoGrade;
            precoLista.lpi_valorvenda = parseFloat(item.dadosItensSistema[0].valorVenda);
            ListaPrecoItem.push(precoLista);
        }
        editarValorVenda(ListaPrecoItem);
    }

    function editarValorVenda(ListaPrecoItem) {
        api.put(`ListaPrecoItem/EditarAsync`, ListaPrecoItem).then(
            res => {
                notification.success({ description: 'Valor Venda Ajustado com sucesso!', message: 'Aviso' });
                setTimeout(() => { window.location = "/Home#/monitoramentoNfe"; }, 1000);
            }
        ).catch(
            erro => {
                if (!!erro.response && !!erro.response.data && !!erro.response.data.Message) {
                    Modal.error({ content: erro.response.data.Message, title: 'Aviso' });
                }
                console.log(erro);
            }
        ).finally(
            () => {
            }
        );
    };

    function validarDados(callback) {
        if (faturamentoDados.length === 0) {
            notification.warning({ message: 'Aviso', description: 'Favor preencher a forma de pagamento!' });
        } else {
            let valorTotal = 0;
            faturamentoDados.forEach(item => {
                valorTotal += parseFloat(item.nfp_valor);
            })
            if (valorTotal != dadosFaturamento[0][0]?.find(obj => obj.nome === 'nfp_valor')?.valor) {
                notification.warning({ message: 'Aviso', description: 'Valor informado diferente do total da nota!' });
                return false;
            }
            validarItens(null,
                () => {
                    if (callback) {
                        callback();
                    }
                }
            );
        }
    };

    function popularDadosRemetente() {
        let dadosRem = {};
        dadosRem.for_id = dadosRemetente[0].find(obj => obj.nome === 'for_id')?.valor;
        dadosRem.cnpjCpf = dadosRemetente[0].find(obj => obj.nome === 'cnpjcpf')?.valor;
        dadosRem.for_regimetributariofiscal = dadosRemetente[0].find(obj => obj.nome === 'for_regimetributariofiscal')?.valor;
        dadosRem.pej_inscricaoestadual = dadosRemetente[0].find(obj => obj.nome === 'pej_inscricaoestadual')?.valor;
        dadosRem.pes_nome = dadosRemetente[0].find(obj => obj.nome === 'pse_nome')?.valor;
        dadosRem.psc_numero = !!dadosRemetente[0].find(obj => obj.nome === 'psc_numero') ? dadosRemetente.find(obj => obj.nome === 'psc_numero')?.valor : null;
        dadosRem.pej_nomefantasia = dadosRemetente[0].find(obj => obj.nome === 'pej_nomefantasia')?.valor;
        dadosRem.cep_cep = dadosRemetente[0].find(obj => obj.nome === 'cep_cep')?.valor;
        dadosRem.log_logradouro = dadosRemetente[0].find(obj => obj.nome === 'log_logradouro')?.valor;
        dadosRem.bai_bairro = dadosRemetente[0].find(obj => obj.nome === 'bai_bairro')?.valor;
        dadosRem.pee_numero = dadosRemetente[0].find(obj => obj.nome === 'pee_numero')?.valor;
        dadosRem.pee_complemento = dadosRemetente[0].find(obj => obj.nome === 'pee_complemento')?.valor;
        dadosRem.cid_descricao = dadosRemetente[0].find(obj => obj.nome === 'cid_descricao')?.valor;
        dadosRem.est_sigla = dadosRemetente[0].find(obj => obj.nome === 'est_sigla')?.valor;
        dadosRem.pes_nome = dadosRemetente[0].find(obj => obj.nome === 'pes_nome')?.valor;
        return dadosRem;
    };

    function popularDadosFaturamento() {
        let dadosFat = [];
        faturamentoDados.forEach((faturamento) => {
            let dados = {};
            dados.numeroFatura = faturamento.numeroFatura;
            dados.fpg_id = faturamento.fpg_id;
            dados.cpg_id = faturamento.cpg_id;
            dados.ctc_id = faturamento.ctc_id;
            dados.nfp_valor = parseFloat(faturamento.nfp_valor);
            if (dados.nfp_valor == null) {
                dados.nfp_valor = 0;
            }
            dados.parcelas = popularParcelas(faturamento);
            dados.antecipacoes = popularAntecipacoes(faturamento);
            dadosFat.push(dados);
        });
        return dadosFat;
    };

    function popularParcelas(faturamento) {
        let dadosParcelas = [];
        if (!!faturamento.parcelas && faturamento.parcelas.length > 0) {
            faturamento.parcelas.forEach((parcela) => {
                let dados = {};
                dados.prc_numeroparcela = parcela.prc_numeroparcela;
                dados.prc_datavencimento = moment(parcela.prc_datavencimento);
                dados.prc_numeroduplicata = parcela.prc_numeroDuplicata;
                dados.prc_valor = parseFloat(parcela.prc_valor);
                dadosParcelas.push(dados);
            })
        }
        return dadosParcelas;
    };

    function popularAntecipacoes(faturamento) {
        let dadosAntecipacoes = [];
        /*let dados = {};
        dados.acf_id = 0;
        dados.valorUtilizado = parseFloat(0);*/
        return dadosAntecipacoes;
    };

    function popularDadosTransportador() {
        let dados = {};
        if (isObjetoDiffVazio(dadosTransporte)) {
            dados.cnpjCpf = dadosTransporte?.[0]?.find(obj => obj.nome === 'cnpjcpf')?.valor;
            dados.pes_nome = dadosTransporte?.[0]?.find(obj => obj.nome === 'pes_nome')?.valor;
            dados.est_sigla = dadosTransporte?.[0]?.find(obj => obj.nome === 'est_sigla')?.valor;
            dados.pej_inscricaoestadual = dadosTransporte?.[0]?.find(obj => obj.nome === 'pej_inscricaoestadual')?.valor;
            dados.log_logradouro = dadosTransporte?.[0]?.find(obj => obj.nome === 'log_logradouro')?.valor;
            dados.cid_descricao = dadosTransporte?.[0]?.find(obj => obj.nome === 'cid_descricao')?.valor;
        }
        return dados;
    };

    function buscarCorrelacaoCFOP() {
        api.get(`CorrelacaoCFOP/CorrelacaoCFOP?cfop=5101&tipoCorrelacao=1&tipoNota=1`).then(
            res => {
                setCorrelacaoCFOP(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function buscarIndicadorPagamento() {
        api.get(`Enum/Listar?nome=IndicadorPagamento`).then(
            res => {
                setIndicadorPagamento(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function validarItens(tab, callback) {

        let valido = true;
        dadosItens.forEach((item, indice, lista) => {
            if (!!item.dadosItensSistema && item.dadosItensSistema.length > 0 && valido) {
                let valor = 0;
                if (typeof item.dadosItensSistema !== 'object') {
                    item.dadosItensSistema = JSON.parse(item.dadosItensSistema);
                }
                if (typeof item.lote !== 'object') {
                    item.lote = JSON.parse(item.lote);
                }
                item.dadosItensSistema.forEach((itemSis, idx, array) => {
                    if (parseFloat(itemSis.nfi_qtde) === 0) {
                        valido = false;
                        notification.warning({ message: 'Aviso', description: "Item nº: " + item.nItem + " - " + item.cProd + " - " + item.xProd + " não informado Quantidade!" });
                    }
                    valor += parseFloat(itemSis.nfi_custoinformado) * parseFloat(itemSis.nfi_qtde);
                    if (idx === array.length - 1) {
                        // faz o teste do null pq tem algumas notas que vem com o valor 0.
                        if (item.xValorUnitarioProduto === null) {
                            item.xValorUnitarioProduto = parseFloat(0);
                            item.xValorTotalProduto = parseFloat(0);
                        }
                    }
                });
            } else if (item.dadosItensSistema.length === 0 || !isNotNullOrEmpty(item.dadosItensSistema.length)) {
                valido = false;
                notification.warning({ message: 'Aviso', description: "Item nº: " + item.nItem + " - " + item.cProd + " - " + item.xProd + " não informado!" });
            }
            if (indice === lista.length - 1) {
                if (valido && !!callback) {
                    callback(tab);
                }
            }
        });
    };

    function validarAba(tab) {
        if (parseInt(abaSelecionada) === 1) {
            if (!!form.getFieldValue().ope_id) {
                setAbaSelecionada(tab);
            } else {
                notification.warning({ description: 'Favor informar uma operação fiscal', message: 'Aviso' });
            }
        } else if (parseInt(abaSelecionada) === 2) {
            validarItens(tab, () => {
                setAbaSelecionada(tab);
            });
        } else {
            setAbaSelecionada(tab);
        }
        if (parseInt(tab) !== 4) {
            setDesabilitaBotao(true);
        } else {
            setDesabilitaBotao(false);
        }

    };

    function cancelarOperacao() {
        setAbaSelecionada("1");
        setDadosNota([]);
        setDadosItens([]);
        setDadosFaturamento([]);
        setDadosRemetente([]);
        setDadosTransporte([]);
        setDesabilitaBotao(true);
        fechar();
    };

    return (
        <div className="pages-col">
            {exibir && <Form layout="vertical" form={form} onFinish={salvarNota}>
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => validarAba(tab)} className="p-b-75">
                    <Tabs.TabPane tab="Nota Fiscal" key="1">
                        <TabNotaFiscal form={form} dadosNota={dadosNota} dadosRemetente={dadosRemetente} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Itens" key="2">
                        <TabItens dadosRemetente={dadosRemetente} dadosItens={{ dadosItens, setDadosItens, parametrosEmpresa, setParametrosEmpresa }} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Transporte" key="3">
                        <TabTransporte form={form} dadosTransporte={{ dadosTransporte, setDadosTransporte }} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Faturamento" key="4">
                        <TabFaturamento dadosFaturamento={{ dadosFaturamento, setDadosFaturamento }} faturamentoDados={{ faturamentoDados, setFaturamentoDados }} />
                    </Tabs.TabPane>
                </Tabs>
                <div className="ant-drawer-footer footer-recebimento">
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button icon={<CloseOutlined />} size="large" htmlType="button" onClick={cancelarOperacao}>
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => { form.submit() }} loading={carregando} disabled={desabilitaBotao} icon={<SaveOutlined />} size="large" type="primary">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>

            </Form>}
        </div>
    );
}