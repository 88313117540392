import React, { useEffect } from 'react';
import { Form, Row, Col, Input, notification, Tag, Alert } from "antd";

import api from '../../services/api';
import { useStateValue } from '../../state';
import { compararDatas } from '../../services/funcoes';
import { manutencaoActions } from "../../actions";
import { Data, FormGW, InputPreco, TabelaDados } from "../../components";

export default function ManutencaoMetas({ formulario, carregando, aoSalvar, editando }) {

    const [{ manutencao, ui }, dispatch] = useStateValue();

    function salvarMeta(values) {
        if (!values.mtv_id) {
            if (manutencao.dados.length == 0) {
                notification.error({ message: 'Atenção', description: "Não foi selecionado nenhum vendedor!" });
                return;
            } values.listaVendedoresMetas = manutencao.dados;
        }
        carregando(true);
        if (!!values.mtv_id) {
            api.put(`MetaVenda/EditarAsync`, values).then(
                res => {
                    notification.success({ message: 'Operação concluída', description: "Os dados da meta foram editados com sucesso!" });
                }
            ).catch(
                erro => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
                }
            ).finally(
                () => {
                    carregando(false);
                    aoSalvar();
                }
            );
        } else {
            api.post(`MetaVenda/IncluirAsync`, values).then(
                res => {
                    notification.success({ message: 'Operação concluída', description: "Os dados da meta foram incluídos com sucesso!" });
                }
            ).catch(
                erro => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
                }
            ).finally(
                () => {
                    carregando(false);
                    aoSalvar();
                }
            );
        }
    };

    function listarMsgVendedorComMeta(valor) {
        if (valor > 0) {
            return <Alert message="Vendedor já possui meta!" type="warning" showIcon />
        } else {
            return <b></b>;
        }
    };

    function populaVendedoresSel(keys, linhas) {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: linhas } });
    };


    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoMetas" onFinish={salvarMeta}>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={8} md={6} lg={5} xl={5}>
                    <Form.Item hidden name="mtv_id"></Form.Item>
                    <Form.Item hidden name="ven_id"></Form.Item>
                    <InputPreco label="Valor da Meta (R$)" name="mtv_valor" />
                </Col>
                <Col xs={24} sm={8} md={6} lg={4} xl={4}>
                    <Data label="Data Inicial" name="mtv_datainicial" rules={[{ required: true, message: 'Informe a Data Inicial' }]} />
                </Col>
                <Col xs={24} sm={8} md={6} lg={4} xl={4}>
                    <Data label="Data Final" name="mtv_datafinal" rules={[{ required: true, message: 'Informe a Data Final' }]} onBlur={() => {
                        if (compararDatas(formulario.getFieldValue().mtv_datainicial, formulario.getFieldValue().mtv_datafinal, '<=')) {
                            return Promise.resolve();
                        } else {
                            formulario.setFieldsValue({ mtv_datafinal: null });
                            return Promise.reject(notification.warning({ description: 'Data final deve ser maior que a data inicial!' }));
                        }
                    }} />
                </Col>
            </Row>
            <Row align="middle" gutter={[8, 8]}>
                <Col span={24}>
                    <Form.Item label="Observação" name="mtv_observacao">
                        <Input.TextArea placeholder="Informe uma observação para a meta" />
                    </Form.Item>
                </Col>
            </Row>
            <div hidden={editando} className="tabela mt-dif">
                <TabelaDados url="MetaVenda/ListarVendedores" selecionarItem={true} colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ ven_id }) => (
                                <Tag color="processing" className="w-100">
                                    <b>{ven_id}</b>
                                </Tag>
                            ),
                        },
                        {
                            title: 'Selecione o(s) Vendedor(es) para esta Meta',
                            render: ({ pes_nome }) => (
                                <div>
                                    <b>{pes_nome}</b>
                                </div>
                            ),
                        },
                        {
                            title: "",
                            width: 205,
                            align: 'center',
                            render: ({ valor }) => (
                                listarMsgVendedorComMeta(valor)
                            ),
                        },
                    ]
                } parametrosSelecionarItens={
                    {
                        tipo: "change",
                        funcao: populaVendedoresSel
                    }
                } />
            </div>
        </FormGW>
    );

}