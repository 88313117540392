import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Upload, message, Modal } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from "antd-img-crop";
import api from "../../services/api";
import { getEmailUsuario, linkApi } from "../../services/auth";
import { getBase64 } from "../../services/funcoes";

export default function Perfil({ formulario, aoSalvar, imagemAlterada }) {

    const [loading, setLoading] = useState(false);
    const [dadosUsuario, setDadosUsuario] = useState({});
    const [fileList, setFileList] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
        }
        if (info.file.status !== 'removed') {
            let originFileObj = info.file.originFileObj;
            if (typeof originFileObj === 'undefined') {
                originFileObj = info.fileList[0].originFileObj;
            }
            getBase64(originFileObj).then(
                base64 => {
                    setLoading(false);
                    setImageUrl(base64);
                }
            );
        }
        if (info.file.status === 'removed') {
            setImageUrl(null);
        }

        setFileList(info.fileList);
    };

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Você só pode fazer upload de arquivos JPG/PNG!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('A imagem deve ter menos de 2 MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">Enviar Imagem</div>
        </div>
    );

    useEffect(() => {
        let url = `${linkApi}Usuario/CarregarAvatar?email=${getEmailUsuario()}`;
        setImageUrl(url);
        imagemAlterada(url);
        let img = [{
            uid: '-1',
            name: 'avatar',
            status: 'done',
            url: url,
        }];
        setFileList(img);
        api.get(`Usuario/RetornaUsuarioPerfilEmail?email=${getEmailUsuario()}`).then(
            (res) => {
                setDadosUsuario(res.data);
                formulario.setFieldsValue({ usu_nome: res.data.usu_nome, usu_email: res.data.usu_email });
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    }, []);

    function salvarDadosPerfil(values) {
        let dadosEnviar = { ...dadosUsuario };
        dadosEnviar.usu_nome = values.usu_nome;
        if (fileList.length > 0) {
            dadosEnviar.usu_avatar = { data: imageUrl, type: fileList[0].type };
        } else {
            dadosEnviar.usu_avatar = { data: null, type: null };
        }

        api.post(`Usuario/EditarProfile`, JSON.stringify(dadosEnviar), { headers: { 'Content-Type': 'application/json;charset=utf-8' } }).then(
            (retorno) => {
                if (retorno) {
                    Modal.success({
                        content: 'Dados atualizados com sucesso!',
                    });
                }
            }
        ).catch(
            (erro) => {
                Modal.error({
                    title: 'Não foi possível atualizar os dados!',
                    content: (
                        <div>
                            <p>{erro.response.data.Message}</p>
                        </div>
                    )
                });
            }
        ).finally(() => {
            aoSalvar();
        });
    };

    return (
        <div className="perfil">
            <Form layout="vertical" form={formulario} onFinish={salvarDadosPerfil}>
                <Row justify="center" gutter={[16, 0]}>
                    <Col xs={8} sm={5} md={5} lg={5} xl={5}>
                        <ImgCrop
                            rotate
                            modalTitle="Ajuste sua imagem"
                            modalOk="Salvar"
                            shape="round">
                            <Upload
                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                listType="picture-card"
                                name="avatar"
                                fileList={fileList}
                                onChange={handleChange}
                                className="avatar-uploader"
                                showUploadList={true}
                                onRemove={() => setImageUrl(null)}
                                beforeUpload={beforeUpload}
                                maxCount={1}
                            >
                                {fileList.length === 0 && uploadButton}
                            </Upload>
                        </ImgCrop>

                    </Col>
                    <Col xs={24} sm={19} md={19} lg={19} xl={19}>
                        <Row align="middle">
                            <Form.Item hidden name="usu_id">
                                <Input />
                            </Form.Item>
                            <Col span={24}>
                                <Form.Item label="Nome" name="usu_nome">
                                    <Input placeholder="Informe seu Nome" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="E-mail" name="usu_email">
                                    <Input placeholder="Informe seu E-mail" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}