import React, { useEffect } from "react";
import { Row, Col, Modal, Button } from "antd";
import { CheckOutlined, ExclamationCircleOutlined, EyeOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import { listagemActions } from "../../actions";
import { BreadcrumbAbrirChamado, TabelaDados } from "../../components";
import { useStateValue } from "../../state";

export default function Notificacao() {

    const [{ manutencao }, dispatch] = useStateValue();

    function excluirNotificacao(record) {
        api.delete(`Notificacao/Excluir/${record.id}`).then(
            res => {
                console.log(`Notificações excluidas com sucesso!`);
            }
        ).catch(
            error => {
                console.log(error);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+not_datahora' } });
            }
        )
    };
    function visualizar(record) {
        Modal.info({
            title: ('Notificação!'),
            content: (
                <div>
                    <p style={{ whiteSpace: 'pre-line' }}>
                        {record.not_descricao}
                    </p>
                </div>
            ),
        });
    };

    const modalExcluirNotificacao = (record) => {
        Modal.confirm({
            Title: 'Aviso',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja excluir essa notificação?',
            okText: 'Excluir',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirNotificacao(record);
            }
        });
    };

    function excluirTodasNotificacao() {
        Modal.confirm({
            Title: 'Aviso',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja excluir todas as notificações?',
            okText: 'Excluir',
            cancelText: 'Não',
            centered: true,
            onOk() {
                api.delete(`Notificacao/ExcluirTodos`).then(
                    res => {
                        console.log(`Notificacões excluidas com sucesso!`);
                    }
                ).catch(
                    error => {
                        console.log(error);
                    }
                ).finally(
                    () => {
                        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+not_datahora' } });
                    }
                )
            }
        });
    }

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-not_datahora' } });
    }, [])

    return (
        <div className="p-10">
            <BreadcrumbAbrirChamado />
            <div className="tabela">
                <TabelaDados paginacao={true} url="Notificacao/ListarTodos" ordenar={true} colunas={
                    [
                        {
                            title: 'Data',
                            width: 120,
                            render: ({ not_datahora }) => (
                                <div>
                                    {moment(not_datahora).format('DD/MM/YYYY HH:mm:ss')}
                                </div>
                            ),
                        },
                        {
                            title: 'Notificações',
                            render: ({ not_descricao }) => (
                                <div>
                                    {not_descricao}
                                </div>
                            ),
                        },
                        {
                            title: <CheckOutlined onClick={() => excluirTodasNotificacao()} />,
                            width: 95,
                            align: 'center',
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button type="text" icon={<CheckOutlined />} onClick={() => { modalExcluirNotificacao(record) }}></Button>
                                        </Col>
                                        <Col>
                                            <Button type="text" icon={<EyeOutlined />} onClick={() => { visualizar(record) }}></Button>
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]

                } />

            </div>
        </div>
    );

}