import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Button, Input, InputNumber, Card, notification, Tooltip, Modal } from "antd";
import { SearchOutlined, BarcodeOutlined, PlusOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { ModalManutencaoNota } from "../modals";
import { isObjetoDiffVazio } from "../../services/funcoes";
import { InputPreco, SelectProduto } from "../../components";
import { selectPaginadoActions } from "../../actions";

export default function FiltroProdutoOrcamento({ form, dadosTabela, dadosEndereco = {}, dadosOperacao = {}, btnEditando, itemEditando }) {

    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [dadosDetalhes, setDadosDetalhes] = useState({});
    const [desabilitarProd_id, setDesabilitarProd_id] = useState(false);
    const [buscarProdutosCodBarra, setBuscarProdutosCodBarra] = useState(null);
    const [abrirModalManutencaoNota, setAbrirModalManutencaoNota] = useState(false);
    const [{ manutencao }, dispatch] = useStateValue();
    const [listaSelectProd, setListaSelectProd] = useState([]);
    const codBarras = useRef();

    useEffect(() => {
        if (btnEditando.editando && isObjetoDiffVazio(itemEditando)) {
            editandoItem(itemEditando);
            setExibirDetalhes(true);
        }
    }, [btnEditando]);

    useEffect(() => {
        if (buscarProdutosCodBarra && !!codBarras && !!codBarras.current) {
            codBarras.current.focus();
        }
    }, [buscarProdutosCodBarra]);

    function carregarSelectPaginados() {
        let lista = [];
        if (!!form.getFieldValue().ltp_id)
            lista.push({ name: "ltp_id", campo: "idLista", value: form.getFieldValue().ltp_id });

        if (!!form.getFieldsValue().icm_id)
            lista.push({ name: "icm_id", campo: "Codigo", value: form.getFieldsValue().icm_id })

        if (!!form.getFieldsValue().pis_id)
            lista.push({ name: "pis_id", campo: "Id", value: form.getFieldsValue().pis_id })

        if (!!form.getFieldValue().ncm_id)
            lista.push({ name: "ncm_id", campo: "IdNcm", value: form.getFieldValue().ncm_id });

        if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: lista });
    }

    async function onChangeProID(pro_id) {
        let listaItens = [...listaSelectProd]
        let produto = listaItens.filter(pro => pro.key === pro_id)[0];
        let filtros = !!produto.pro_id ? `idProduto=${produto.pro_id}` : '';
        filtros += !!produto.prg_id ? `&idProdutoGrade=${produto.prg_id}` : '';
        let listaPrecoTemp = (await api.get(`ListaPreco/ListaPrecoPadraoProduto?${filtros}`)).data;
        form.setFieldsValue({ ltp_id: listaPrecoTemp[0].ltp_id });
        produto.lpi_valorvenda = listaPrecoTemp[0].lpi_valorvenda;
        await popularFormProduto(produto);
        carregarSelectPaginados();
    };

    async function onPressEnterProCodigo(campo) {
        let codigoEan = campo.target.value.trim();
        let dadosRequisicao = await api.get(`Produto/ListaProdutoFiltro?filtro=${codigoEan}`);
        if (!!dadosRequisicao.data && dadosRequisicao.data.items.length > 0) {
            let dadosProduto = dadosRequisicao.data.items[0];
            await popularFormProduto(dadosProduto);
            adicionarItem(dadosProduto);
            if (!!codBarras && !!codBarras.current) {
                codBarras.current.focus();
            }
        }
    };

    const togglePesquisa = () => {
        setBuscarProdutosCodBarra(!buscarProdutosCodBarra);
    };

    function onChangeQuantidade() {
        recalcularValorTotal();
    };

    function onChangeValorUnitario() {
        recalcularValorTotal();
    };

    function onClickBtnCancelar() {
        cancelar();
    };

    function onClickBtnAdicionar() {
        adicionarItem();
    }

    function recalcularValorTotal() {
        let total = 0;
        total = parseFloat(parseFloat(form.getFieldValue().oit_qtde) * parseFloat(form.getFieldValue().oit_valorunitario));
        form.setFieldsValue({ valorTotalItem: total.toFixed(2) });
    };

    function cancelar() {
        setExibirDetalhes(false);
        setDadosDetalhes({});
        setDesabilitarProd_id(false);
        limparCampos();
        if (btnEditando.editando) {
            btnEditando.setEditando(false);
        }
    };

    async function popularFormProduto(produto) {
        let valorVenda = (!!produto.lpi_valorvenda ? produto.lpi_valorvenda : 0);
        setExibirDetalhes(true);
        form.setFieldsValue({
            oit_qtde: 1,
            oit_valorunitario: valorVenda,
            valorTotalItem: valorVenda
        });
        setDadosDetalhes(produto);
        return produto;
    };

    function adicionarItem(item = {}) {
        let listaItens = [...dadosTabela.listaProduto];
        let dadosFormulario = form.getFieldValue();
        if (!buscarProdutosCodBarra) {
            if (btnEditando.editando) {
                item = listaItens.filter((prod) => (prod.numeroitem === dadosFormulario.numeroitem))[0];
            } else {
                item = { ...item, ...dadosFormulario, ...dadosDetalhes };
            }
        }
        let dados = {};
        if (!!validarItem(dadosFormulario)) {
            if (dadosFormulario.numeroitem !== undefined) {
                var dadosEditando = listaItens.filter((prod) => (prod.numeroitem === dadosFormulario.numeroitem))[0];
            } else {
                var dadosEditando = listaItens.filter((prod) => (prod.pro_id === item.pro_id && (!!!item.prg_id || item.prg_id === prod.prg_id)))[0];
            }
            if (!!dadosEditando) {
                dados = popularItemNota(false, item, dadosEditando, dados);
                if (!!btnEditando && btnEditando.editando) {
                    dados = popularItemNota(true, item, dadosEditando, dados);
                    btnEditando.setEditando(false);
                    alterarItem(dados, listaItens, dadosEditando)
                } else {
                    if (buscarProdutosCodBarra) {
                        dados.numeroitem = listaItens.length + 1;
                        incluirItem(dados, listaItens);
                    } else {
                        Modal.confirm({
                            title: 'Atualizar?',
                            icon: <ExclamationCircleOutlined />,
                            content: 'Item já cadastrado na nota, deseja atualizar suas informações?',
                            okText: 'Atualizar',
                            cancelText: 'Incluir',
                            centered: true,
                            onOk() {
                                dados = popularItemNota(true, item, dadosEditando, dados);
                                alterarItem(dados, listaItens, dadosEditando)
                            },
                            onCancel() {
                                dados.numeroitem = listaItens.length + 1;
                                incluirItem(dados, listaItens)
                            }
                        });
                    }
                }
            } else {
                dados = popularItemNota(false, item, dados);
                dados.numeroitem = listaItens.length + 1;
                incluirItem(dados, listaItens)
            }
            setExibirDetalhes(false);
            setDesabilitarProd_id(false);
            limparCampos();
            btnEditando.setEditando(false);
        }
    };

    function validarItem(dados) {
        if (dados.quantidade === 0 || !!!dados.oit_qtde) {
            notification.warning({ message: 'Aviso!', description: 'Quantidade do Item Inválida!' });
            return false;
        }
        if (parseFloat(dados.oit_valorunitario) === 0 || dados.oit_valorunitario === null) {
            notification.warning({ message: 'Aviso!', description: 'Valor do Item Inválido!' });
            return false
        }
        return true;
    }

    function incluirItem(dados, listaItens) {
        listaItens.push(dados);
        dadosTabela.setListaProduto(listaItens);
        notification.success({ message: 'Aviso', description: 'Item incluído com sucesso!' });
    }

    function alterarItem(dados, listaItens, dadosEditando) {
        let indice = listaItens.findIndex((item) => (item === dadosEditando));
        listaItens.splice(indice, 1, { ...dados });
        dadosTabela.setListaProduto(listaItens);
        notification.success({ message: 'Aviso', description: 'Item editado com sucesso!' });
    }

    function popularItemNota(editando = false, item, dadosEditando) {
        let itemNota = {};
        let formulario = form.getFieldValue();
        if (editando) {
            itemNota = {
                ...dadosEditando,
                oit_qtde: parseFloat(!!formulario.oit_qtde ? formulario.oit_qtde : item.oit_qtde),
                oit_valorunitario: parseFloat(formulario.oit_valorunitario),
            };
        } else {
            itemNota = {
                pro_id: item.pro_id,
                prg_id: item.prg_id,
                oit_qtde: formulario.oit_qtde,
                numeroitem: !!dadosTabela.dadosItens && dadosTabela.dadosItens.length > 0 ? dadosTabela.dadosItens.length + 1 : 1,
                oit_valorunitario: parseFloat(formulario.oit_valorunitario ?? 0),
                prg_ean: item.prg_ean,
                pro_ean: item.pro_ean,
                ump_id: item.ump_id,
                pro_codigo: item.pro_codigo,
                pro_descricao: item.pro_descricao,
                pro_ean: item.pro_ean,
                ump_id: item.ump_id,
                grade: item.descricaograde,
            };
        }
        return itemNota;
    };

    function limparCampos() {
        form.setFieldsValue({ pro_id: null, pro_codigo: null, percdesc: 0, desconto: 0, percacresc: 0, acrescimo: 0, oit_qtde: 0 });
    };

    function editandoItem(dadosEditando) {
        let dadosTemp = {};
        let dados = dadosEditando;
        form.setFieldsValue({
            pro_id: dados.pro_id,
            prg_id: dados.prg_id,
            oit_qtde: dados.oit_qtde,
            oit_valorunitario: dados.oit_valorunitario,
            numeroitem: dados.numeroitem,
            valorTotalItem: dados.oit_qtde * dados.oit_valorunitario
        });
        dadosTemp = {
            prg_id: dadosEditando.prg_id,
            pro_descricao: dadosEditando.pro_descricao,
            pro_codigo: dadosEditando.pro_codigo,
            pro_ean: dadosEditando.pro_ean,
            prg_ean: dadosEditando.prg_ean,
            ump_id: dados.ump_id
        };
        setDadosDetalhes(dadosTemp);
    }

    return (
        <div>
            <Row gutter={[8, 0]}>
                {!!!buscarProdutosCodBarra &&
                    <Col xs={21} sm={21} md={21} lg={22} xl={22}>
                        <Form.Item label="Selecione o Produto" name="pro_id">
                            <SelectProduto disabled={desabilitarProd_id} valueKey={true} detalhes={true} placeholder="Selecione o Produto"
                                name="pro_id" form={form} onChange={(dados) => { onChangeProID(dados) }} ordem={"pro_descricao"} setListaDados={setListaSelectProd} />
                        </Form.Item>
                    </Col>
                }
                {!!buscarProdutosCodBarra &&
                    <Col xs={21} sm={21} md={21} lg={22} xl={22}>
                        <Form.Item label="Localize o Produto" name="pro_codigo">
                            <Input ref={codBarras} onPressEnter={(value) => {
                                onPressEnterProCodigo(value)
                            }} placeholder="Informe o Produto/Mercadoria" />
                        </Form.Item>
                    </Col>
                }
                <Col xs={3} sm={3} md={3} lg={2} xl={2} className="i-button">
                    <Tooltip title={!!buscarProdutosCodBarra ? 'Localize produto por EAN' : 'Selecione o produto'} placement="left">
                        <Button onClick={togglePesquisa} block>
                            {buscarProdutosCodBarra ? <SearchOutlined /> : <BarcodeOutlined />}
                        </Button>
                    </Tooltip>
                </Col>
            </Row>
            {exibirDetalhes &&
                <div className="m-t-16">
                    <Card title={
                        <Row gutter={[8, 0]}>
                            <Col>
                                Produto: <b>{dadosDetalhes.pro_descricao} {!!dadosDetalhes.descricaograde ? dadosDetalhes.descricaograde : ''}</b>
                            </Col>
                            <Col>
                                Código Interno: <b>{dadosDetalhes.pro_codigo}</b>
                            </Col>
                            <Col>
                                Código de Barras: <b>{!!dadosDetalhes.prg_ean ? dadosDetalhes.prg_ean : dadosDetalhes.pro_ean}</b>
                            </Col>
                            {/* <Col>
                                NCM: <b>{dadosDetalhes.ncm_codigo}</b>
                            </Col> */}
                        </Row>
                    }>
                        <Row gutter={[8, 0]}>
                            <Form.Item hidden label="Id da Grade do Produto" name="prg_id">
                                <Input></Input>
                            </Form.Item>
                            <Col xs={24} sm={8} md={5} lg={6} xl={6}>
                                <Form.Item label="Quantidade" name="oit_qtde">
                                    <InputNumber decimalSeparator="," defaultValue={0} min={0} step={1} placeholder="Informe a Quantidade do Produto" onChange={() => { onChangeQuantidade() }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={5} lg={6} xl={6}>
                                <InputPreco label="Valor Unitário (R$)" name="oit_valorunitario" onChange={() => onChangeValorUnitario()} />
                            </Col>
                            <Col xs={24} sm={8} md={5} lg={6} xl={6}>
                                <InputPreco label="Valor Total (R$)" name="valorTotalItem" disabled={true} />
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={6} xl={6} className="t-mob">
                                <Row justify="end" gutter={[8, 0]}>
                                    <Col>
                                        <Button icon={<CloseOutlined />} onClick={() => (onClickBtnCancelar())}>
                                            Cancelar
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button icon={<PlusOutlined />} type="primary" onClick={() => onClickBtnAdicionar()}>
                                            Adicionar
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </div>}
            <ModalManutencaoNota formulario={form} exibirModal={abrirModalManutencaoNota} fecharModal={() => { setAbrirModalManutencaoNota(false); }}dadosOperacao={dadosOperacao} dadosEndereco={dadosEndereco}
            />
        </div >
    );
}