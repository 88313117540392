import React from 'react';
import { Col, Divider, Row } from 'antd';
import { FormatNumber } from '../../../ValueObjects';

export default function FluxoCaixaDiarioLocalAnalitico({ valoresProvisionados, tabelaFluxoCaixa, saldos }) {
 
    return (
        <div>
            {tabelaFluxoCaixa.map((fluxoLocal) => {
                return (
                    <div>
                        <div className="col-layout b-color-secondary text-center f-16">
                            Local: <b>{tabelaFluxoCaixa[0]?.nomeLocal}</b>
                        </div>
                        {fluxoLocal.listaFluxoSaldos.map((item) => (
                            <Row justify="center" gutter={[8,0]} className="f-14">
                                <Col span={12} align="right">
                                    <div>{item.nomeContaCorrente}: <b className={item.saldoInicial < 0 ? "c-red" : "c-primary"}>{FormatNumber(item.saldoInicial < 0 ? item.saldoInicial * -1 : item.saldoInicial, true, false)}</b></div>
                                </Col>
                                <Col span={12} align="left">
                                    <div>{item.nomeContaCorrente}: <b className={ ( (!item.saldoConta && item.saldoConta !== 0) ? (item.saldosConta < 0 ) : (item.saldo < 0)) ? "c-red" : "c-primary"}>
                                        {FormatNumber(
                                            ((!item.saldosConta && item.saldosConta !== 0 ) ? (item.saldosConta < 0 ? item.saldosConta * -1 : item.saldosConta) :
                                            (item.saldo < 0 ? item.saldo * -1 : item.saldo))
                                            , true, false)}</b></div>
                                </Col>
                            </Row>
                        ))}
                        <Divider className="m-0" />
                        <Row gutter={[8,0]} className="m-t-5 f-14">
                            <Col span={12} align="right">
                                <div>SALDO INICIAL: <b className={saldos.saldoInicial < 0 ? "c-red" : "c-primary"}>{FormatNumber(saldos.saldoInicial < 0 ? saldos.saldoInicial * -1 : saldos.saldoInicial, true, false)}</b></div>
                            </Col>
                            <Col span={12} align="left">
                                <div>SALDO FINAL: <b className={saldos.saldoFinal < 0 ? "c-red" : "c-primary"}>{FormatNumber(saldos.saldoFinal < 0 ? saldos.saldoFinal * -1 : saldos.saldoFinal, true, false)}</b></div>
                            </Col>
                        </Row>

                        {!valoresProvisionados &&
                            <div className="tabela-fluxo m-t-19">
                                <Row className="b-color-secondary col-layout c-black">
                                    <Col span={4}><b>DATA</b></Col>
                                    <Col align="right" span={10}><b>ENTRADA</b></Col>
                                    <Col align="right" span={10}><b>SAÍDA</b></Col>
                                </Row>
                                <div className="t-body p-0">
                                    {fluxoLocal.listaFluxoItens.map((item) => (
                                        <Row className="row">
                                            <Col span={4}>{item.dataPgtoVencimento}</Col>
                                            <Col align="right" span={10}>{FormatNumber(item.valor_RealizadoEntrada, true, false)}</Col>
                                            <Col align="right" span={10}>{FormatNumber(item.valor_RealizadoSaida, true, false)}</Col>
                                        </Row>
                                    ))}
                                    <Row className="c-primary row">
                                        <Col span={4}><b>TOTAL DO PERÍODO</b></Col>
                                        <Col align="right" className="c-primary" span={10}><b>{FormatNumber(saldos.totalCreditoRealizado, true, false)}</b></Col>
                                        <Col align="right" className="c-red" span={10}><b>{FormatNumber(saldos.totalDebitoRealizado, true, false)}</b></Col>
                                    </Row>
                                </div>
                            </div>
                        }
                        {valoresProvisionados &&
                            <div className="tabela-fluxo m-t-19">
                                <Row className="b-color-secondary col-layout c-black t-head">
                                    <Col span={4}><b>DATA</b></Col>
                                    <Col align="center" span={10}>
                                        <Row>
                                            <Col span={24} align="center"><b>ENTRADA</b></Col>
                                        </Row>
                                        <Row>
                                            <Col span={12} align="right"><b>PREVISTO</b></Col>
                                            <Col span={12} align="right"><b>REALIZADO</b></Col>
                                        </Row>
                                    </Col>
                                    <Col align="center" span={10}>
                                        <Row>
                                            <Col span={24} align="center"><b>SAÍDA</b></Col>
                                        </Row>
                                        <Row>
                                            <Col span={12} align="right"><b>PREVISTO</b></Col>
                                            <Col span={12} align="right"><b>REALIZADO</b></Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div className="t-body p-0">
                                    {fluxoLocal.listaFluxoItens.map((item) => (
                                        <Row className="row">
                                            <Col span={4}>{item.dataPgtoVencimento}</Col>
                                            <Col className="col" span={10}>
                                                <Row>
                                                    <Col align="right" span={12}>{FormatNumber(item.valor_ProvisaoReceber, true, false)}</Col>
                                                    <Col align="right" span={12}>{FormatNumber(item.valor_RealizadoEntrada, true, false)}</Col>
                                                </Row>
                                            </Col>
                                            <Col className="col" span={10}>
                                                <Row>
                                                    <Col align="right" span={12}>{FormatNumber(item.valor_ProvisaoPagar, true, false)}</Col>
                                                    <Col align="right" span={12}>{FormatNumber(item.valor_RealizadoSaida, true, false)}</Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))}
                                    <Row className="c-primary row">
                                        <Col span={4}><b>TOTAL DO PERÍODO</b></Col>
                                        <Col className="col c-primary" span={10}>
                                            <Row>
                                                <Col align="right" className="c-primary" span={12}><b>{FormatNumber(saldos.totalCreditoPrevisto, true, false)}</b></Col>
                                                <Col align="right" className="c-primary" span={12}><b>{FormatNumber(saldos.totalCreditoRealizado, true, false)}</b></Col>
                                            </Row>
                                        </Col>
                                        <Col className="col c-red" span={10}>
                                            <Row>
                                                <Col align="right" className="c-red" span={12}><b>{FormatNumber(saldos.totalDebitoPrevisto, true, false)}</b></Col>
                                                <Col align="right" className="c-red" span={12}><b>{FormatNumber(saldos.totalDebitoRealizado, true, false)}</b></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        }
                    </div>
                )
            })}
        </div >
    );
}