import React from "react";
import { Modal } from "antd";

import { useStateValue } from "../../state";
import { manutencaoActions } from "../../actions";
import DetalhesRecibo from "../../components/detalheRecibo";

export default function ModalDetalheRecibo({ exibirModal, fecharModal, dadosModal }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();

    const onClose = () => {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        fecharModal();
    };

    return (
        <Modal destroyOnClose title="Parcelas Quitadas"
            width="50%"
            onCancel={onClose}
            footer={null}
            maskClosable={false}
            visible={exibirModal}>
            <DetalhesRecibo dadosModal={dadosModal} />
        </Modal>
    )
}