import React, { useState, useEffect } from 'react';
import { Radio, Form, Col, Row, Select, Input, InputNumber, Button, Switch, Collapse, Modal, Table, notification, RadioChangeEvent } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, SearchOutlined, CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import api from '../../services/api';
import { useStateValue } from '../../state';
import { Data, FormGW, InputPreco, SelectPaginacao } from "../../components";
import { FormatNumber, FormatDate } from '../../ValueObjects/';
import SelectPaginado from '../../components/selectPaginado';
import { MaskFormat } from '../../ValueObjects/index';
import { HasValue } from '../../services/funcoes';

export default function LancamentoCaixaManutencao({ formulario, carregando, aoSalvar, initialValues }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [acompanharLancamentos, setAcompanharLancamentos] = useState(false);
    const [openPesquisaAvancada, setOpenPesquisaAvancada] = useState(false);
    const [listaContaCorrente, setListaContaCorrente] = useState([]);
    const [listaContaGerencial, setListaContaGerencial] = useState([]);
    const [listaContaGerencialAgrupador, setListaContaGerencialAgrupador] = useState([]);
    const [listaContaGerencialPesquisa, setListaContaGerencialPesquisa] = useState([]);
    const [listaContaGerencialAgrupadorPesquisa, setListaContaGerencialAgrupadorPesquisa] = useState([]);
    const [listaCaixa, setListaCaixa] = useState([]);
    const [saldoAtualConta, setSaldoAtualConta] = useState(null);
    const [saldoAnteriorConta, setSaldoAnteriorConta] = useState(null);
    const togglePesquisa = () => {
        setOpenPesquisaAvancada(!openPesquisaAvancada);
    };

    useEffect(() => {
        api.get(`contaCorrente/ListarContasParaTransferencia?filtro=&codigoContaCorrente=&ordem=+ctc_descricao`).then(res => {
            setListaContaCorrente(res.data);
        });

        buscarDadosContaGerencial(manutencao.dados.operacao);
        api.get(`ContaGerencial/ListarAtivosEstruturadoPelaCategoria?filtro=`).then(res => {
            setListaContaGerencialAgrupadorPesquisa(res.data.filter((item) => { return item.podeSelecionar === false }));
            setListaContaGerencialPesquisa(res.data.filter((item) => { return item.podeSelecionar === true }));
        });

    }, []);

    useEffect(() => {
        if (!!manutencao.dados) {
            manutencao.dados.mcc_datahora = moment(new Date());
            manutencao.dados.mcc_entradasaida = manutencao.dados.operacao;
            manutencao.dados.dataInicial = moment(new Date());
            manutencao.dados.dataFinal = moment(new Date());
            formulario.setFieldsValue(manutencao.dados);
            buscarSaldoConta();
        }
    }, [manutencao.dados]);

    function reverse(lista) {
        let retorno = [];

        for (let i = lista.length - 1; i >= 0; i--) {
            retorno.push(lista[i])
        }
        return retorno;
    };

    function buscarDadosContaGerencial(value) {
        api.get(`ContaGerencial/ListarAtivosEstruturadoPelaCategoria?filtro=&CodReceitaDespesa=${value}`).then(res => {
            setListaContaGerencialAgrupador(res.data.filter((item) => { return item.podeSelecionar === false }));
            setListaContaGerencial(res.data.filter((item) => { return item.podeSelecionar === true }));
        });
    };



    function listarCaixa() {
        let saldoAnterior = 0;
        let lista = [];
        if (HasValue(formulario.getFieldValue().ctc_id)) {
            if (!!formulario.getFieldValue().dataInicial && !!formulario.getFieldValue().dataFinal && !!formulario.getFieldValue().ctc_id) {
                api.get(`ContaCorrente/BuscarSaldoAnteriorContaCorrente?idContaCorrente=${formulario.getFieldValue().ctc_id}&dataBase=${FormatDate(formulario.getFieldValue().dataInicial.toDate().toLocaleDateString())}`).then(res => {
                    setSaldoAnteriorConta(res.data);
                    saldoAnterior = res.data;
                });
                let url = `MovimentoContaCorrente/ListarCaixa?IdContaCorrente=${formulario.getFieldValue().ctc_id}
            &dataInicial=${FormatDate(formulario.getFieldValue().dataInicial.toDate().toLocaleDateString())}
            &dataFinal=${FormatDate(formulario.getFieldValue().dataFinal.toDate().toLocaleDateString())}&ordem=-mcc_dataHora`;

                if (!!formulario.getFieldValue().ctg_id_consulta) {
                    url += `&IdContaGerencial=${formulario.getFieldValue().ctg_id_consulta}`;
                }
                if (!!formulario.getFieldValue().valor_consulta && formulario.getFieldValue().valor_consulta > 0) {
                    url += `&FiltroValor=${parseFloat(formulario.getFieldValue().valor_consulta)}`;
                }

                api.get(url).then(res => {
                    lista = res.data;
                    lista = reverse(lista);
                    lista.forEach((caixa) => {
                        if (caixa.mcc_entradasaida === 1) {
                            saldoAnterior -= parseFloat(caixa.mcc_valor);
                        } else if (caixa.mcc_entradasaida === 2) {
                            saldoAnterior += parseFloat(caixa.mcc_valor);
                        }
                        caixa.saldoAnterior = saldoAnterior;
                    });
                    lista = reverse(lista);
                    setListaCaixa(lista);
                });
            }
        }else{
            notification.warning({ message: `Aviso!`, description: `Favor informar a Conta Corrente para a pesquisa!` });
        }
    };

    function salvarManutencao(values) {
        values.mcc_id = null;
        values.mcc_valor = parseFloat(values.mcc_valor);
        values.mcc_entradasaida = parseInt(values.mcc_entradasaida);
        values.mcc_numerodocumento = !!values.mcc_numerodocumento ? values.mcc_numerodocumento.toString() : '';
        carregando(true);
        api.post('/MovimentoContaCorrente/IncluirLancamentoCaixa', values).then(
            res => {
                Modal.confirm({
                    title: 'Lançamento adicionado com sucesso!',
                    icon: <CheckCircleOutlined />,
                    content: '',
                    okText: 'Incluir mais um',
                    cancelText: 'OK',
                    centered: true,
                    onOk() {
                        carregando(false);
                        formulario.resetFields();
                        formulario.setFieldsValue({ mcc_datahora: moment(new Date()), mcc_entradasaida: manutencao.dados });
                    },
                    onCancel() {
                        aoSalvar();
                        carregando(false);
                    }
                });
            }).catch(
                err => {
                    notification.warning({ message: `Aviso!`, description: `Não foi possivel realizar o lançamento!` });
                }
            ).finally(() => {
                carregando(false)
                aoSalvar();;
            });;
    };

    async function buscarSaldoConta() {
        let saldoAnterior = 0;
        if (!!formulario.getFieldValue().ctc_id) {
            await api.get(`ContaCorrente/ListarSaldoContaCorrente?tipoLancamento=1`).then(
                res => {
                    saldoAnterior = res.data.filter(item => (item.ctc_id === formulario.getFieldValue().ctc_id))[0].saldo;
                }
            ).catch(
                error => {
                    console.log(error);
                }
            )
        }
        setSaldoAtualConta(saldoAnterior);

    };

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="formManutencaoCaixa" initialValues={{ remember: true }} onFinish={salvarManutencao} >
                <Row gutter={[8, 0]} justify="space-between">
                    <Col>
                        <Form.Item label="Você está lançando uma" name="mcc_entradasaida">
                            <Radio.Group defaultValue={2} onChange={(e) => buscarDadosContaGerencial(e.target.value)} buttonStyle="solid" optionType="button" size="middle">
                                <Radio.Button value="2">Entrada</Radio.Button>
                                <Radio.Button value="1" className="btn-red">Saída</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col className="f-18" align="right">
                        <div>
                            Saldo Atual da Conta (R$)
                        </div>
                        <b className="c-primary">
                            {FormatNumber(saldoAtualConta, true)}
                        </b>
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={6} md={5} lg={4} xl={4}>
                        <Data label="Data do Lançamento" name="mcc_datahora" rules={[{ required: true, message: 'Informe a data de lançamento' }]} initialValue={moment(new Date())} />
                    </Col>
                    <Col xs={24} sm={9} md={10} lg={5} xl={5}>
                        <Form.Item label="Conta Corrente" name="ctc_id" rules={[{ required: true, message: 'Selecione uma Conta Corrente' }]}>
                            <Select showSearch onChange={() => buscarSaldoConta()} options={listaContaCorrente.map((item) => {
                                return { label: item.ctc_descricao, value: item.ctc_id, key: item.ctc_id }
                            })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione uma Conta Corrente" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={9} md={9} lg={5} xl={5}>
                        <Form.Item label="Conta Gerencial" name="ctg_id" rules={[{ required: true, message: 'Selecione uma Conta Gerencial' }]}>
                            <Select showSearch allowClear={true} optionFilterProp="children" placeholder="Selecione uma Conta Gerencial">
                                {listaContaGerencialAgrupador.map(item => (
                                    <Select.OptGroup label={item.ctg_descricao}>
                                        {listaContaGerencial.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                            <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={15} md={7} lg={5} xl={5}>
                        <Form.Item label="Nome da Pessoa" name="pes_id">
                            <SelectPaginacao url="Pessoa/ListarPessoasAtivas" placeholder="Selecione um Pessoa" form={formulario} nameLabel="pes_nome" nameValue="pes_id" selecionarRegUnico="pes_id" allowClear={true} conteudo={
                                pc => (<Select.Option value={pc.pes_id} key={pc.key}>{pc.pes_nome} <br />
                                    {(!!pc.pej_cnpj ? `CNPJ.: ${MaskFormat(pc.pej_cnpj, '', true)}` : !!pc.pef_cpf ? `CPF.: ${MaskFormat(pc.pef_cpf, '', true)}` : '')}
                                </Select.Option>)
                            } />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={9} md={7} lg={5} xl={5}>
                        <Form.Item label="Histórico" name="his_id">
                            <SelectPaginado url="Historico/Listar?Ativo=true" form={formulario} placeholder="Selecione um Histórico" name="his_id" conteudo={
                                h => (<Select.Option value={h.his_id} key={h.key}>{h.his_descricao}</Select.Option>)
                            } />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={4}>
                        <Form.Item label="Nº do Documento" name="mcc_numerodocumento">
                            <InputNumber step={1} min={1} placeholder="Informe o Número do Documento" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <InputPreco name={'mcc_valor'} label={'Valor (R$)'} rules={[{ required: true, message: 'Informe o valor (R$)' }]} />
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Complemento" name="mcc_complemento">
                            <Input.TextArea type="text" placeholder="Informe o Complemento do Lançamento" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name="acompanharLancamentos">
                            <Switch onChange={(checked) => setAcompanharLancamentos(checked)} /> Desejo acompanhar os lançamentos realizados na conta corrente selecionada
                        </Form.Item>
                    </Col>
                </Row>
                {acompanharLancamentos &&
                    <div className="page">
                        <Row gutter={[8, 16]}>
                            <Col span={24}>
                                <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                                    <Collapse.Panel showArrow={false} extra={
                                        <Button type="primary" onClick={togglePesquisa} block>
                                            {openPesquisaAvancada ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Filtrar os Lançamentos Registrados
                                        </Button>
                                    }>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} sm={6} md={4} lg={4} xl={4} xxl={4}>
                                                <Data label="Data Incial" name="dataInicial" />
                                            </Col>
                                            <Col xs={24} sm={6} md={4} lg={4} xl={4} xxl={4}>
                                                <Data label="Data Final" name="dataFinal" />
                                            </Col>
                                            <Col xs={24} sm={12} md={7} lg={6} xl={7} xxl={8}>
                                                <Form.Item label="Conta Gerencial" name="ctg_id_consulta">
                                                    <Select allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial">
                                                        {listaContaGerencialAgrupadorPesquisa.map(item => (
                                                            <Select.OptGroup label={item.ctg_descricao}>
                                                                {listaContaGerencialPesquisa.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                                                    <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                                                ))}
                                                            </Select.OptGroup>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={6} md={5} lg={5} xl={4} xxl={4}>
                                                <InputPreco name={'valor_consulta'} label={'Valor (R$)'} />
                                            </Col>
                                            <Col xs={24} sm={18} md={4} lg={5} xl={5} xxl={4}>
                                                <Row justify="end" gutter={[8, 0]} className="t-mob-573">
                                                    <Col xs={24} sm={8} md={24} lg={24} xl={24} xxl={24}>
                                                        <Button type="primary" onClick={() => listarCaixa()} icon={<SearchOutlined />} block>
                                                            Aplicar Filtros
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>
                        {listaCaixa.length > 0 &&
                            <Table dataSource={listaCaixa} className="m-0" columns={
                                [
                                    {
                                        title: 'Data',
                                        width: 100,
                                        render: ({ mcc_dataHora }) => (
                                            <div>
                                                <b>{moment(mcc_dataHora).format('DD/MM/YYYY')}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Conta Gerencial',
                                        render: ({ ctg_descricao }) => (
                                            <div>
                                                <b>{ctg_descricao}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Entrada (R$)',
                                        align: 'right',
                                        render: ({ mcc_valor, mcc_entradasaida }) => (
                                            <div>
                                                <b>
                                                    {(mcc_entradasaida === 2) ? FormatNumber(!!mcc_valor ? mcc_valor : 0, true, false) : ''}
                                                </b>
                                            </div>
                                        ),
                                    }, {
                                        title: 'Saída (R$)',
                                        align: 'right',
                                        render: ({ mcc_valor, mcc_entradasaida }) => (
                                            <div>
                                                <b>
                                                    {(mcc_entradasaida === 1) ? FormatNumber(!!mcc_valor ? mcc_valor : 0, true, false) : ''}
                                                </b>
                                            </div>
                                        ),
                                    }, {
                                        title: 'Saldo (R$)',
                                        align: 'right',
                                        fixed: 'right',
                                        render: ({ saldoAnterior }) => (
                                            <div>
                                                {(saldoAnterior < 0) ? <b className="c-red f-16">{FormatNumber(saldoAnterior, true, false)}</b> : <b className="c-primary f-16">{FormatNumber(saldoAnterior, true, false)}</b>}
                                            </div>
                                        ),
                                    }
                                ]
                            } footer={() =>
                                <Row justify="end">
                                    <Col>
                                        <div className="f-16">
                                            Saldo Anterior a Data Inicial(R$): <b>{FormatNumber(!!saldoAnteriorConta ? saldoAnteriorConta : 0, true, false)}</b>
                                        </div>
                                    </Col>
                                </Row>
                            } scroll={{ x: 900 }} />
                        }
                    </div>
                }
            </FormGW>
        </div>
    )
}