import React, { useEffect } from "react";
import { Row, Col, Tag, Popover, notification, Modal, Radio, Form } from "antd";
import { EditOutlined, ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";

import api from "../../services/api";
import DrawerSerieFiscal from './drawer';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, Editar, Excluir } from "../../components";

export default function SerieFiscal() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [formPesquisa] = Form.useForm();

    useEffect(() => {
        let _ativo = !!!formPesquisa.getFieldsValue().statusAtivo ? 1 : formPesquisa.getFieldsValue().statusAtivo;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&Ativo=${_ativo}`, filtro: '', ordem: '+ser_serie' } });
        formPesquisa.setFieldsValue({ statusAtivo: 1 });
    }, [])

    function editarSerie(record) {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    }
    function aplicarFiltros() {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&Ativo=${formPesquisa.getFieldsValue().statusAtivo}`, ordem: '+ser_serie' } });
    }
    const modalInativarRegistro = (dados) => {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: `Não foi possível excluir a Série ${dados.ser_serie}. Deseja inativar este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                inativarRegistro(dados);
            }
        });
    };
    function inativarRegistro(dados) {
        api.put(`SerieFiscal/InativarSerie/${dados.ser_id}`).then(
            res => {
                notification.success({ message: `Sucesso!`, description: `Série inativada com sucesso!` })
            }
        ).catch(
            error => {
                console.log(error);

            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ser_serie' } });
            }
        )
    }
    function excluirRegistro(dados) {
        api.delete(`SerieFiscal/Excluir/${dados.ser_id}`).then(
            (res) => {
                notification.success({ message: 'Operação concluída', description: "Registro excluído com sucesso!" });
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }).catch(
                erro => {
                    modalInativarRegistro(dados);
                }).finally(
                    () => {
                        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ser_serie' } });
                    });
    }

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Form layout="vertical" name="formPesquisa" form={formPesquisa} onFinish={aplicarFiltros}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18}>
                        <PesquisaOrdenacao opcoesOrdenacao={[
                            { label: "Nome A - Z", value: "+ser_serie" },
                            { label: "Nome Z - A", value: "-ser_serie" },
                            { label: "Código Crescente", value: "+ser_id" },
                            { label: "Código Decrescente", value: "-ser_id" }
                        ]} />
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9} xl={6}>
                        <Form.Item label="Selecione Status" name="statusAtivo">
                            <Radio.Group onChange={() => aplicarFiltros()}>
                                <Radio value={2}>Todos</Radio>
                                <Radio value={1}>Ativo</Radio>
                                <Radio value={0}>Inativo</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="SerieFiscal/listar" colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ ser_id, ser_ativo }) => (
                                <Popover content={ser_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                    <Tag color={ser_ativo ? 'processing' : 'default'} className="w-100">
                                        <b>{ser_id}</b>
                                    </Tag>
                                </Popover>
                            ),
                        },
                        {
                            title: 'Modelo da Série',
                            render: ({ mdf_id, modelodocfiscal }) => (
                                <div>
                                    <b>{mdf_id} - {modelodocfiscal.mdf_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Descrição',
                            width: 150,
                            render: ({ ser_serie }) => (
                                <div>
                                    <b>{ser_serie}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Série Fiscal do Documento',
                            align: 'center',
                            width: 178,
                            render: ({ ser_numero }) => (
                                <div>
                                    <b>{ser_numero}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 65,
                            fixed: 'right',
                            render: (dados, record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Editar onClick={() => editarSerie(record)} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Excluir icon={<DeleteOutlined />} onClick={() => { modalExcluir(dados) }} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } />
            </div>
            <DrawerSerieFiscal />
        </div>
    );

}