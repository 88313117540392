import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Divider, Table } from "antd";

import api from "../../services/api";
import { useStateValue } from "../../state";

export default function TabFormaPagamento({ formasPagamento }) {
    const [{ manutencao }, dispatch] = useStateValue();
    const [listaFormaPagamento, setListaFormaPagamento] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        carregarFormasPgto();
    }, []);

    function carregarFormasPgto() {
        api.get(`FormaPagamento/ListarAtivos?PageSize=200`).then(
            res => {
                if (!!res.data.items) {
                    let index = 0;
                    let dados = [...res.data.items];
                    dados.sort((a, b) => (
                        (a.fpg_descricao.charAt(0) + a.fpg_descricao.charAt(1) > b.fpg_descricao.charAt(0) + b.fpg_descricao.charAt(1)) ? 1 :
                            ((b.fpg_descricao.charAt(0) + b.fpg_descricao.charAt(1)) > (a.fpg_descricao.charAt(0) + a.fpg_descricao.charAt(1))) ? -1 : 0
                    ))
                    dados.forEach(dados => {
                        dados.key = index;
                        index++;
                    });
                    setListaFormaPagamento(dados);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    useEffect(() => {
        if (formasPagamento.listaFormasPagamento.length > 0 && listaFormaPagamento.length > 0) {
            let listIndex = [];
            listaFormaPagamento.forEach((listaForma, index, array) => {
                formasPagamento.listaFormasPagamento.forEach((forma) => {
                    if (forma.fpg_id === listaForma.fpg_id) {
                        listIndex.push(index);
                    }
                });
            });
            setSelectedRowKeys(listIndex);
        }
    }, [formasPagamento.listaFormasPagamento, listaFormaPagamento]);

    function popularFormasPgtoSelect(keys, linhas) {
        formasPagamento.setListaFormasPagamento(linhas);
    };

    const configSelecionarItem =
    {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            popularFormasPgtoSelect(selectedRowKeys, selectedRows);
        }
    };

    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-formas.png").default} alt="Forma de Pagamento" /> Forma de Pagamento
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Defina as formas de pagamento. Pode ser selecionadas uma ou mais formas de pagamento na tabela abaixo.
                    </Divider>
                </Col>
            </Row>
            <div className="tabela">
                <Table
                    dataSource={listaFormaPagamento}
                    columns={
                        [
                            {
                                title: 'Formas de Pagamento',
                                render: ({ fpg_descricao }) => (
                                    <div>
                                        <b>{fpg_descricao}</b>
                                    </div>
                                ),
                            },
                        ]
                    }
                    rowSelection={configSelecionarItem}
                />
            </div>
        </div>
    );
}