import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button, Table, Select, notification, Tag, Input } from "antd";
import { CheckOutlined, DeleteOutlined, EditOutlined, PlusOutlined, ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons";
import moment from 'moment';

import Data from "../data";
import api from "../../services/api";
import { useStateValue } from "../../state";
import { InputPreco } from "../../components";
import { getTela } from "../../services/auth";
import { FormatNumber } from "../../ValueObjects";
import { manutencaoActions } from "../../actions";
import DrawerVendedores from "../drawerVendedores";
import { isObjetoDiffVazio, validarNumeros } from "../../services/funcoes";
import { ModalContaCorrente, ModalSenhaLiberacao } from "../modals";
import SelectPaginado from "../selectPaginado";

export default function FormasPagamentoRecebimento({ form, dadosOperacao }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [listaFormaPagamento, setListaFormaPagamento] = useState(!!manutencao.dados && !!manutencao.dados.faturamento && manutencao.dados.faturamento.length > 0 ? manutencao.dados.faturamento : [])
    const [formaPagamento, setFormaPagamento] = useState([]);
    const [condicaoPagamento, setCondicaoPagamento] = useState([]);
    const [valorJaPago, setValorJaPago] = useState(false);
    const [pagamentoTotal, setPagamentoTotal] = useState(false);
    const [multiplasFormasPagamento, setMultiplasFormasPagamento] = useState(false);
    const [editando, setEditando] = useState(false);
    const [abrirDrawerVendedores, setAbrirDrawerVendedores] = useState(false);
    const [formVendedores] = Form.useForm();
    const [valorTotal, setValorTotal] = useState(0);
    const [semCondicao, setSemCondicao] = useState(false);
    const [ehValePresente, setEhValePresente] = useState(false);
    const [dadosTela] = useState(getTela());
    const [isNota, setIsNota] = useState(false);
    const [dadosParcelas, setDadosParcelas] = useState([]);
    const [abreModalLiberacao, setAbreModalLiberacao] = useState(false);
    const [executarParcelas, setExecutarParcelas] = useState(false);
    const [listaContaCorrente, setListaContaCorrente] = useState([]);
    const [abreModalContaCorrente, setAbreModalContaCorrente] = useState(false);
    const [contaCorrente, setContaCorrente] = useState({});
    const [valorRestante, setValorRestante] = useState(0);
    const [desabilitaBtn, setDesabilitaBtn] = useState(false);
    const [editandoParcelas, setEditandoParcelas] = useState(false);
    const [semPagamento, setSemPagamento] = useState(false);
    const [tipoContaCorrente, setTipoContaCorrente] = useState("1");
    const pagamentosCodigos = {
        dinheiro: 1,
        cheque: 2,
        cartaoCredito: 3,
        cartaoDebito: 4,
        crediarioLoja: 5,
        valeAlimentacao: 10,
        valeRefeicao: 11,
        valePresente: 12,
        valeCombustivel: 13,
        duplicataMercantil: 14,
        boleto: 15,
        depositoBancario: 16,
        pix: 17,
        transfBancaria: 18,
        programaFidelidade: 19,
        semPagamento: 90,
        outro: 99
    };
    const rowExpandable = record => { return (record.fpg_tipopagamento === pagamentosCodigos.crediarioLoja || record.fpg_tipopagamento === pagamentosCodigos.boleto) };

    useEffect(() => {
        carregarDados();
        if (validarNumeros(valorTotal) === 0) {
            setValorTotal(validarNumeros(form.getFieldValue().valorTotal));
        }
    }, []);

    useEffect(() => {
        if (validarNumeros(valorTotal) !== 0) {
            valorRestantePgto([...listaFormaPagamento]);
        }
    }, [valorTotal]);

    useEffect(() => {
        if (!!dadosTela) {
            let tela = JSON.parse(dadosTela).formulario.replaceAll('/', '');
            if (tela === `ordemCompra`) {
                setIsNota(true);
            }
        }
    }, [dadosTela]);

    useEffect(() => {
        if (isNota) {
            setMultiplasFormasPagamento(true);
        }
    }, [isNota]);

    useEffect(() => {
        let valor = 0;
        if (listaFormaPagamento.length > 0) {
            listaFormaPagamento.forEach((lista) => {
                valor += parseFloat(lista.pfp_valor);
            });
            if (!!form.getFieldValue().fpg_id) {
                listarCondicao(form.getFieldValue().fpg_id, true);
            }
            if (valor >= valorTotal && valor >= parseFloat(form.getFieldValue().valorTotal)) {
                setPagamentoTotal(true);
            } else {
                setPagamentoTotal(false);
                valorRestantePgto([...listaFormaPagamento]);
            }
            setExecutarParcelas(true);
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ faturamento: listaFormaPagamento } } } });
        } else {
            setPagamentoTotal(false);
        }
        // if (!!listaFormaPagamento[listaFormaPagamento.length - 1] && !!listaFormaPagamento[listaFormaPagamento.length - 1].parcelas &&
        //      !!listaFormaPagamento[listaFormaPagamento.length - 1].parcelas[0] && listaFormaPagamento[listaFormaPagamento.length - 1].parcelas[0].pcl_numerodias === 0) {
        //     setAbreModalContaCorrente(true);
        // }
        if (!!listaFormaPagamento[listaFormaPagamento.length - 1] &&
            (listaFormaPagamento[listaFormaPagamento.length - 1].fpg_tipopagamento === pagamentosCodigos.dinheiro
                || listaFormaPagamento[listaFormaPagamento.length - 1].fpg_tipopagamento === pagamentosCodigos.cheque
                || listaFormaPagamento[listaFormaPagamento.length - 1].fpg_tipopagamento === pagamentosCodigos.pix)) {
            setAbreModalContaCorrente(true);
        }
        setEhValePresente(false);
        setValorJaPago(valor);
    }, [listaFormaPagamento]);

    useEffect(() => {
        if (parseFloat(form.getFieldValue().valorTotal) > 0 && !editando) {
            let valor = 0;
            if (listaFormaPagamento.length > 0) {
                listaFormaPagamento.forEach((lista) => {
                    valor += parseFloat(lista.pfp_valor);
                });
                if (valor >= valorTotal && valor >= parseFloat(form.getFieldValue().valorTotal)) {
                    setPagamentoTotal(true);
                } else {
                    setPagamentoTotal(false);
                    valorRestantePgto([...listaFormaPagamento]);
                }
            }
        }
    }, [form.getFieldValue()]);

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.faturamento && executarParcelas) {
            let dados = manutencao.dados.faturamento;
            let index = dados.length - 1;
            setExecutarParcelas(false);
            if (dados[index].fpg_tipopagamento === pagamentosCodigos.crediarioLoja) {
                validarJaPossuiCrediario(() => {
                    popularParcelas();
                });
            } else if (dados[index].fpg_tipopagamento === pagamentosCodigos.boleto) {
                validarJaPossuiBoleto(() => {
                    popularParcelas();
                });
            }
        }
    }, [manutencao.dados]);

    useEffect(() => {
        setValorTotal(validarNumeros(form.getFieldValue().valorTotal));
        let dadosFormaPagamento = formaPagamento.filter((forma) => { return forma.fpg_id === form.getFieldValue().fpg_id; })[0];
        if (multiplasFormasPagamento && (!!dadosFormaPagamento && dadosFormaPagamento.fpg_tipopagamento !== pagamentosCodigos.valePresente)) {
            valorRestantePgto([...listaFormaPagamento]);
        }
    }, [multiplasFormasPagamento]);

    useEffect(() => {
        if (!!form.getFieldValue().fpg_id) {
            let dadosFormaPagamento = formaPagamento.filter((forma) => { return forma.fpg_id === form.getFieldValue().fpg_id; })[0];
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ formaPagamento: dadosFormaPagamento } } } });
        }
    }, [form.getFieldValue().fpg_id]);

    useEffect(() => {
        if (semCondicao && !!form.getFieldValue().fpg_id) {
            adicionarFormaPagamento();
        }
    }, [semCondicao]);

    useEffect(() => {
        if (semPagamento) {
        }
    }, [semPagamento]);

    function onCloseModalLiberacao() {
        setAbreModalLiberacao(false);
    };

    function carregarDados() {
        api.get('FormaPagamento/Listar?Ativo=true').then(
            (res) => {
                if (res.status === 200) {
                    let dados = res.data.items;
                    if (!!dadosOperacao && isObjetoDiffVazio(dadosOperacao)) {
                        if (dadosOperacao.tip_id === 5) {
                            setFormaPagamento(dados);
                        } else {
                            setFormaPagamento(dados.filter((fpg) => (fpg.fpg_tipopagamento !== 90)));
                        }
                    }
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function valorRestantePgto(dadosTemp) {
        let restante = 0;
        let valorPago = 0;
        if (!editando) {
            dadosTemp.forEach((dados) => {
                valorPago += parseFloat(dados.pfp_valor);
            });
            restante = parseFloat(valorTotal) - valorPago;
            form.setFieldsValue({ pfp_valor: restante.toFixed(2) });
            setValorRestante(parseFloat(restante.toFixed(2)));
        } else {
            setEditando(false);
        }
    };

    function listarCondicao(fpg_id, dadosIniciais = false) {
        if (!dadosIniciais) {
            form.setFieldsValue({ cpg_id: null });
        }
        api.get(`CondicaoPagamento/Listar?Ativo=true&CodigoFormaPagamento=${fpg_id}&PageSize=500`).then(
            (res) => {
                if (res.status === 200) {
                    setCondicaoPagamento(res.data.items);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function validarJaPossuiCrediario(callback) {
        let dados = [...listaFormaPagamento];
        if (!dados.find((formaPgto => (formaPgto.fpg_tipopagamento === pagamentosCodigos.crediarioLoja && !!formaPgto.parcelas && formaPgto.parcelas.length > 0)))) {
            if (callback) {
                callback();
            }
        }
    };

    function validarJaPossuiBoleto(callback) {
        let dados = [...listaFormaPagamento];
        if (!dados.find((formaPgto => (formaPgto.fpg_tipopagamento === pagamentosCodigos.boleto && !!formaPgto.parcelas && formaPgto.parcelas.length > 0)))) {
            if (callback) {
                callback();
            }
        }
    }

    async function adicionarFormaPagamento(liberadoCredito = false) {
        let dadosTemp = [...listaFormaPagamento];
        let pagamentoCartao = false;
        if (parseFloat(form.getFieldValue().pfp_valor) === 0) {
            notification.warning({ message: 'Aviso!', description: 'Valor de Pagamento Inválido!' });
            return false;
        }
        if (ehValePresente && !!!form.getFieldValue().codigoValePresente) {
            notification.warning({ description: 'Favor informar um vale presente válido!', message: 'Aviso' });
            return;
        }

        //Utilizado para atualizar a constante de valor total da nota de acordo com o formulario que é o valor exibido em tela.
        setValorTotal(validarNumeros(form.getFieldValue().valorTotal));
        if (!!form.getFieldValue().fpg_id && (semCondicao || (condicaoPagamento.length === 0 || !!form.getFieldValue().cpg_id))) {
            let dadosFormaPagamento = (await api.get(`FormaPagamento/Get?id=${form.getFieldValue().fpg_id}`)).data;
            if (listaFormaPagamento.find((formas) => (
                formas.fpg_id === form.getFieldValue().fpg_id && (!semCondicao ? formas.cpg_id === form.getFieldValue().cpg_id : true)
            ))) {
                let dados = listaFormaPagamento.find((formas) => (
                    formas.fpg_id === form.getFieldValue().fpg_id && (!semCondicao ? formas.cpg_id === form.getFieldValue().cpg_id : true)
                ));
                //dados.pfp_valor = parseFloat(form.getFieldValue().pfp_valor);
                dados.pfp_valor = parseFloat(form.getFieldValue().valorTotal);
                validarContaCorrente(dadosTemp, () => {
                    if (!pagamentoCartao) {
                        valorRestantePgto(dadosTemp);
                    }
                });
                form.setFieldsValue({ fpg_id: null });
                form.setFieldsValue({ cpg_id: null });
                form.setFieldsValue({ fpg_tipopagamento: null });
                form.setFieldsValue({ ctc_id: null });
                form.setFieldsValue({ codigoValePresente: null });
            } else {
                incluirNovaFormaPagamento(dadosTemp);
            }
        } else {
            notification.warning({ message: 'Aviso', description: 'Informe a forma e a condicao de pagamento!' });
        }
    };

    async function incluirNovaFormaPagamento(listaForma) {
        let dadosFormaPagamento = (await api.get(`FormaPagamento/Get?id=${form.getFieldValue().fpg_id}`)).data;

        if (semCondicao) {
            let valor = 0;
            if (!semPagamento) {
                valor = form.getFieldValue().pfp_valor;
            }
            listaForma.push({
                fpg_id: form.getFieldValue().fpg_id,
                fpg_descricao: dadosFormaPagamento.fpg_descricao,
                cpg_descricao: '',
                cpg_id: null,
                condicaoPagamento: {},
                formaPagamento: !!dadosFormaPagamento && JSON.stringify(dadosFormaPagamento) !== '{}' ? dadosFormaPagamento : {},
                pfp_valor: valor,
                nfp_codigovalepresente: form.getFieldValue().codigoValePresente,
                parcelas: [],
                descontoPadrao: {},
                ctc_id: dadosFormaPagamento.ctc_id,
                fpg_tipopagamento: dadosFormaPagamento.fpg_tipopagamento
            });
            validarContaCorrente(listaForma, () => {
                if (!semPagamento) {
                    valorRestantePgto(listaForma);
                } else {
                    setValorRestante(0);
                }
            });


        } else {
            let dadosCondicoesPagamento = condicaoPagamento.filter((condicao) => { return condicao.cpg_id === form.getFieldValue().cpg_id; })[0];
            listaForma.push({
                fpg_id: form.getFieldValue().fpg_id,
                fpg_descricao: dadosFormaPagamento.fpg_descricao,
                cpg_descricao: !!dadosCondicoesPagamento && dadosCondicoesPagamento.cpg_descricao ? dadosCondicoesPagamento.cpg_descricao : '',
                cpg_id: !!form.getFieldValue().cpg_id ? form.getFieldValue().cpg_id : null,
                condicaoPagamento: !!dadosCondicoesPagamento && JSON.stringify(dadosCondicoesPagamento) !== '{}' ? dadosCondicoesPagamento : {},
                formaPagamento: !!dadosFormaPagamento && JSON.stringify(dadosFormaPagamento) !== '{}' ? dadosFormaPagamento : {},
                pfp_valor: form.getFieldValue().pfp_valor,
                nfp_codigovalepresente: form.getFieldValue().codigoValePresente,
                parcelas: [],
                descontoPadrao: {},
                ctc_id: dadosFormaPagamento.ctc_id,
                fpg_tipopagamento: dadosFormaPagamento.fpg_tipopagamento
            });
            validarContaCorrente(listaForma, () => {
                valorRestantePgto(listaForma);
            });
        }
        form.setFieldsValue({ fpg_id: null });
        form.setFieldsValue({ cpg_id: null });
        form.setFieldsValue({ fpg_tipopagamento: null });
        form.setFieldsValue({ ctc_id: null });
        form.setFieldsValue({ codigoValePresente: null });
    };

    function validarContaCorrente(dados, callback) {
        let ultimaForma = dados[dados.length - 1];
        if (!!!ultimaForma.ctc_id)
            if (ultimaForma.fpg_tipopagamento === pagamentosCodigos.dinheiro || ultimaForma.fpg_tipopagamento === pagamentosCodigos.cheque) {
                setTipoContaCorrente("1")
                setAbreModalContaCorrente(true);
            }
        if (ultimaForma.fpg_tipopagamento === pagamentosCodigos.depositoBancario || ultimaForma.fpg_tipopagamento === pagamentosCodigos.pix
            || ultimaForma.fpg_tipopagamento === pagamentosCodigos.transfBancaria) {
            setTipoContaCorrente("2")
            setAbreModalContaCorrente(true);
        }
        setListaFormaPagamento(dados);
        if (!!callback) {
            callback();
        }
    };

    function onCloseModalContaCorrente() {
        let ultimaForma = listaFormaPagamento[listaFormaPagamento.length - 1];
        ultimaForma.ctc_id = contaCorrente.ctc_id;
        ultimaForma.ctc_descricao = contaCorrente.ctc_descricao;
        setListaFormaPagamento(listaFormaPagamento);
        setAbreModalContaCorrente(false);
    };

    function popularParcelas(callback) {
        let dados = [...listaFormaPagamento];
        let index = manutencao.dados.faturamento.length - 1;
        api.get(`CondicaoPagamento/ListarParcelamento?codigoCondicaoPagamento=${manutencao.dados.faturamento[index].cpg_id}`).then(
            res => {
                if (!!res.data && res.data.length > 0) {
                    let resto = 0;
                    let somaParcelas = 0;
                    let listaParcelas = [];
                    let indexPosicao = manutencao.dados.faturamento.length - 1;
                    res.data.forEach((parcela) => {
                        let dataAtual = new Date();
                        let parc = {};
                        let dataVencimento = new Date(dataAtual.setDate(dataAtual.getDate() + parcela.pcl_numerodias));
                        parc.fpg_id = manutencao.dados.faturamento[indexPosicao].fpg_id;
                        parc.fpg_descricao = manutencao.dados.faturamento[indexPosicao].fpg_descricao;
                        parc.fpg_tipopagamento = manutencao.dados.faturamento[indexPosicao].fpg_tipopagamento;
                        parc.cpg_id = manutencao.dados.faturamento[indexPosicao].cpg_id;
                        parc.pcl_numerodias = parcela.pcl_numerodias;
                        parc.pcl_percparcela = parcela.pcl_percparcela;
                        parc.pcl_numeroparcela = parcela.pcl_numeroparcela;
                        parc.prc_numeroparcela = parcela.pcl_numeroparcela;
                        parc.dataPagamento = dataVencimento;
                        parc.prc_datavencimento = moment(dataVencimento);
                        parc.dataVencimento = moment(dataVencimento);
                        let valorParcela = ((parcela.pcl_percparcela / 100) * parseFloat(manutencao.dados.faturamento[index].pfp_valor)).toFixed(2);
                        parc.valorParcela = parseFloat(valorParcela);
                        parc.prc_valor = parseFloat(valorParcela);
                        somaParcelas += parc.valorParcela;
                        listaParcelas.push(parc);
                    });
                    somaParcelas = somaParcelas.toFixed(2);
                    somaParcelas = parseFloat(somaParcelas);
                    resto = parseFloat(manutencao.dados.faturamento[index].pfp_valor) - somaParcelas;
                    if (resto != 0) {
                        let numeroUltimaParcela = res.data.length - 1;
                        let valParcela = listaParcelas[numeroUltimaParcela].valorParcela;
                        let totalParcela = (valParcela + resto).toFixed(2);
                        totalParcela = parseFloat(totalParcela);
                        listaParcelas[numeroUltimaParcela].prc_valor = totalParcela;
                        listaParcelas[numeroUltimaParcela].valorParcela = totalParcela;
                    }
                    dados[index].parcelas = listaParcelas;
                    manutencao.dados.faturamento[indexPosicao].editando = false;
                    if (manutencao.dados.faturamento[indexPosicao].fpg_tipopagamento !== pagamentosCodigos.crediarioLoja && manutencao.dados.faturamento[indexPosicao].fpg_tipopagamento !== pagamentosCodigos.boleto) {
                        let creditoDebito = manutencao.dados.faturamento[indexPosicao].fpg_tipopagamento === pagamentosCodigos.cartaoCredito;
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ... { credito: creditoDebito } } } });
                    }
                    setListaFormaPagamento(dados);
                }
                if (callback) {
                    callback();
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function cancelarAlteracoesFormaPagamento(record) {
        let formasPgto = [...listaFormaPagamento];
        let formaPgto = formasPgto.find((forma) => (forma.fpg_id === record.fpg_id));
        formaPgto.editando = false;
        setEditandoParcelas(false);
    };

    function salvarFormaPagamento(record) {
        let formasPgto = [...listaFormaPagamento];
        let formaPgto = formasPgto.find((forma) => (forma.fpg_id === record.fpg_id));
        formaPgto.parcelas.forEach((item, i) => {
            i++;
            item.prc_datavencimento = form.getFieldValue(`dataVencimento${i}`);
            item.dataVencimento = form.getFieldValue(`dataVencimento${i}`);
            item.valorParcela = parseFloat(form.getFieldValue(`valorParcela${i}`));
            item.prc_valor = parseFloat(form.getFieldValue(`valorParcela${i}`));
        });
        formaPgto.editando = false;
        setListaFormaPagamento(formasPgto);
        setEditandoParcelas(false);
    };

    function editarFormasPagamento(forma) {
        let dados = forma.formaPagamento;
        setPagamentoTotal(false);
        setMultiplasFormasPagamento(true);
        setEditando(true);
        form.setFieldsValue({ fpg_id: forma.fpg_id });
        if (dados.fpg_tipopagamento === pagamentosCodigos.dinheiro || dados.fpg_tipopagamento === pagamentosCodigos.valePresente || dados.fpg_tipopagamento === pagamentosCodigos.crediarioLoja) {
            setSemCondicao(true);
            // if (dados.fpg_tipopagamento === pagamentosCodigos.cheque) {
            //     setEhCheque(true);
            // }
            if (dados.fpg_tipopagamento === pagamentosCodigos.valePresente) {
                setEhValePresente(true);
            } else if (dados.fpg_tipopagamento === pagamentosCodigos.crediarioLoja || dados.fpg_tipopagamento === pagamentosCodigos.boleto) {
                forma.editando = true;
                setEditandoParcelas(true);
            }
        } else {
            form.setFieldsValue({ cpg_id: forma.cpg_id });
        }
        form.setFieldsValue({ pfp_valor: forma.pfp_valor });
    };

    function removerFormaPagamento(forma) {
        let dadosTemp = [...listaFormaPagamento];
        if (dadosParcelas.length > 0 && forma.fpg_tipopagamento === dadosParcelas[0].fpg_tipopagamento) {
            setDadosParcelas([]);
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        }
        dadosTemp = dadosTemp.filter((dados) => dados !== forma);
        valorRestantePgto(dadosTemp);
        setListaFormaPagamento(dadosTemp);
    };

    function fecharDrawerVendedores() {
        setAbrirDrawerVendedores(false);
    };

    async function validacaoFormasPagamento(registro) {
        let dados = (await api.get(`FormaPagamento/Get?id=${registro}`)).data;
        setSemCondicao(false);
        setEhValePresente(false);
        setSemPagamento(false);
        switch (dados.fpg_tipopagamento) {
            case pagamentosCodigos.dinheiro:
                setSemCondicao(true);
                break;
            case pagamentosCodigos.pix:
                setSemCondicao(true);
                break;
            case pagamentosCodigos.valePresente:
                setEhValePresente(true);
                setSemCondicao(true);
                break;
            case pagamentosCodigos.boleto:
            case pagamentosCodigos.crediarioLoja:
                let cliente = !!manutencao.dados && manutencao.dados.destinatario ? manutencao.dados.destinatario : null;
                if (!!cliente && cliente.cli_vendasomenteavista) {
                    form.setFieldsValue({ fpg_id: null });
                    notification.warn({ description: 'Venda a prazo não autorizada!', message: 'Aviso' });
                }
                break;
            case pagamentosCodigos.semPagamento:
                setSemCondicao(true);
                setSemPagamento(true);
                break;
        }
    };

    function buscarValePresente(codVale) {
        setDesabilitaBtn(true);
        api.get(`AntecipacaoClienteFornecedor/BuscarValePresente?codigoVale=${codVale.currentTarget.value}`).then(
            (res) => {
                if (res.status === 200) {
                    let saldoVale = res.data;
                    let valorTotal = parseFloat(form.getFieldValue().valorTotal);
                    setMultiplasFormasPagamento(true);
                    if (saldoVale === 0) {
                        form.setFieldsValue({ fpg_id: null });
                        form.setFieldsValue({ cpg_id: null });
                        form.setFieldsValue({ codigoValePresente: null });
                        setMultiplasFormasPagamento(false);
                        notification.warning("Não existe saldo para o vale presente informado!");
                    } else {
                        if (valorTotal > saldoVale) {
                            form.setFieldsValue({ pfp_valor: saldoVale });
                        } else {
                            form.setFieldsValue({ pfp_valor: valorTotal });
                        }
                    }
                    setDesabilitaBtn(false);
                }
            }
        ).catch(
            (erro) => {
                notification.warning({ message: "Aviso", description: "Vale presente não encontrado!" });
                form.setFieldsValue({ codigoValePresente: null });
                setDesabilitaBtn(false);
            }
        );
    };

    function cancelaContaCorrente() {
        setAbreModalContaCorrente(false);
        let listaTemp = [...listaFormaPagamento];
        listaTemp.splice((listaFormaPagamento.length - 1), 1);
        valorRestantePgto(listaTemp);
        setListaFormaPagamento(listaTemp);
    }

    function onChangeValorParc(numeroParc, qtdTotParcelas) {
        let valorJaUtilizado = 0;
        let valorRestante = 0;
        let valorRestanteTot = 0;
        let valorParcelas = 0;
        let valorTotal = parseFloat(form.getFieldValue().valorTotal);
        let dados = form.getFieldValue();
        if (numeroParc === qtdTotParcelas) {
            return;
        }
        for (let i = 1; i <= numeroParc; i++) {
            valorJaUtilizado += parseFloat(dados[`valorParcela${i}`]);
        }
        valorRestante = valorTotal - valorJaUtilizado;
        valorParcelas = parseFloat((valorRestante / (qtdTotParcelas - numeroParc)).toFixed(2));
        for (let i = qtdTotParcelas; i > numeroParc; i--) {
            valorRestanteTot += valorParcelas;
            form.setFieldsValue({ [`valorParcela${i}`]: valorParcelas });
        }
        let valorSobra = valorTotal - (valorJaUtilizado + valorRestanteTot);
        form.setFieldsValue({ [`valorParcela${qtdTotParcelas}`]: parseFloat((valorParcelas + valorSobra).toFixed(2)) });
    };

    function ajustarDataParcelas(numeroParc, qtdTotParcelas) {
        let dataAtual = new Date(form.getFieldValue([`dataVencimento${numeroParc}`]).toDate());
        for (let i = numeroParc + 1; i <= qtdTotParcelas; i++) {
            let dataVenc = new Date(dataAtual.setDate(new Date(dataAtual).getDate() + 30));
            form.setFieldsValue({ [`dataVencimento${i}`]: moment(dataVenc) });
        }
    }

    const expandedRowRender = record => {
        return <Table columns={[
            {
                title: 'Nº',
                render: ({ pcl_numeroparcela }) => (
                    <Tag color="processing">{pcl_numeroparcela}</Tag>
                ),
            }, {
                title: 'Data Pagamento',
                render: ({ dataVencimento, pcl_numeroparcela }) => (
                    <div>
                        {editandoParcelas ? <Data
                            name={`dataVencimento${pcl_numeroparcela}`}
                            initialValue={dataVencimento}
                            onBlur={() => { ajustarDataParcelas(pcl_numeroparcela, record.parcelas.length) }} /> : <b>{moment(dataVencimento).format('DD/MM/YYYY')}</b>
                        }
                    </div>

                ),
            }, {
                title: 'Valor (R$)',
                align: 'right',
                render: ({ valorParcela, pcl_numeroparcela }) => (
                    <div>
                        {editandoParcelas ? <InputPreco
                            name={`valorParcela${pcl_numeroparcela}`}
                            initialValue={valorParcela}
                            disabled={pcl_numeroparcela === record.parcelas.length}
                            onBlur={() => { onChangeValorParc(pcl_numeroparcela, record.parcelas.length) }} /> : <b>{FormatNumber(valorParcela, true)}</b>
                        }
                    </div>
                ),
            }
        ]} dataSource={record.parcelas} pagination={false} />
    };

    return (
        <div className="m-t-16">
            {!pagamentoTotal && <Row gutter={[8, 0]}>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Form.Item label="Forma de Pagamento" name="fpg_id" rules={[{ required: true, message: 'Informe a forma de pagamento' }]}>
                        <SelectPaginado url="FormaPagamento/Listar?Recebimento=2,3,4&Order=+fpg_descricao" placeholder="Selecione Forma de Pagamento" form={form} name="fpg_id" onChangeFunction={(registro) => { listarCondicao(registro); validacaoFormasPagamento(registro) }} conteudo={
                            fp => (<Select.Option value={fp.fpg_id} key={fp.key}>{fp.fpg_descricao}</Select.Option>)
                        } />
                    </Form.Item>
                </Col>
                {!semCondicao && <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Form.Item label="Condição de Pagamento" name="cpg_id" rules={[{ required: true, message: 'Informe a condicao de pagamento' }]}>
                        <Select placeholder="Selecione Condição de Pagamento" disabled={(condicaoPagamento.length > 0 ? false : true)} >
                            {condicaoPagamento.map((condicao) => (
                                <Select.Option value={condicao.cpg_id} key={condicao.cpg_id}>{condicao.cpg_descricao}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>}
                {ehValePresente && <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Form.Item name="codigoValePresente" label="Código do Vale Presente">
                        <Input
                            placeholder="Informe o Código do Vale Presente"
                            onBlur={(codVale) => { buscarValePresente(codVale) }}
                        />
                    </Form.Item>
                </Col>}
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    {!pagamentoTotal && multiplasFormasPagamento &&
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={14} md={14} lg={14} xl={14} hidden={semPagamento}>
                                <InputPreco name={'pfp_valor'} label={'Valor do Pagamento  (R$)'} />
                            </Col>
                            <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                                <Button icon={<PlusOutlined />} disabled={desabilitaBtn} type="primary" onClick={() => (adicionarFormaPagamento())} block className="t-mob-573">
                                    Adicionar
                                </Button>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>}
            <Row gutter={[0, 24]}>
                <Col span={24}>
                    <div className="tabela mt-dif">
                        {listaFormaPagamento.length > 0 && <Table className="components-table-demo-nested" columns={[
                            {
                                title: 'Formas de Pagamento',
                                align: 'left',
                                render: ({ fpg_descricao, cpg_descricao }) => (
                                    <div>
                                        <b>
                                            {!!cpg_descricao ? fpg_descricao + ' / ' + cpg_descricao : fpg_descricao}
                                        </b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Valor (R$)',
                                align: 'right',
                                render: ({ pfp_valor }) => (
                                    <div>
                                        <b>{FormatNumber(pfp_valor, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                key: 'x',
                                align: 'center',
                                width: 65,
                                render: (record) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                {!editandoParcelas &&
                                                    <Button icon={<EditOutlined />}
                                                        onClick={() => {
                                                            editarFormasPagamento(record);
                                                        }} />
                                                }
                                                {editandoParcelas &&
                                                    <Button icon={<CheckOutlined />}
                                                        onClick={() => {
                                                            salvarFormaPagamento(record);
                                                        }} />
                                                }
                                            </Col>
                                            <Col>
                                                {!editandoParcelas &&
                                                    <Button icon={<DeleteOutlined />} onClick={() => {
                                                        setEditandoParcelas(false);
                                                        removerFormaPagamento(record);
                                                    }} />
                                                }
                                                {editandoParcelas &&
                                                    <Button icon={<CloseOutlined />}
                                                        onClick={() => {
                                                            cancelarAlteracoesFormaPagamento(record);
                                                        }} />
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]}
                            expandable={{ expandedRowRender, rowExpandable }}
                            footer={() => {
                                let totalPagamento = 0;
                                listaFormaPagamento.map((dados) => {
                                    totalPagamento += parseFloat(dados.pfp_valor);
                                });
                                return (
                                    <Row gutter={[0, 24]}>
                                        <Col span={24}>
                                            <Row justify="end" gutter={[8, 0]}>
                                                <Col className="m-t-3">
                                                    <strong className="f-16"> Total dos Pagamentos (R$): </strong>
                                                </Col>
                                                <Col>
                                                    <Form.Item name="pfp_valor">
                                                        <strong className="f-21">{FormatNumber(totalPagamento, true)}</strong>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>)
                            }} dataSource={listaFormaPagamento}
                        />}
                    </div>
                    <DrawerVendedores form={formVendedores} exibirDrawerVendedores={abrirDrawerVendedores} fecharDrawerVendedores={fecharDrawerVendedores} />
                    <ModalSenhaLiberacao exibirModal={abreModalLiberacao} fecharModal={onCloseModalLiberacao} />
                    <ModalContaCorrente exibirModalContaCorrente={abreModalContaCorrente} fecharModalContaCorrenteOk={() => { onCloseModalContaCorrente() }} fecharModalContaCorrente={() => { cancelaContaCorrente(); }} contaCorrente={{ contaCorrente, setContaCorrente }} listaContaCorrente={{ listaContaCorrente, setListaContaCorrente }} tipoConta={tipoContaCorrente} />
                </Col>
            </Row>
        </div>
    );
};