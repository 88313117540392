import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Table, Collapse, notification, Modal, Popover, Tag, Input, Divider } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined, ArrowUpOutlined, ArrowDownOutlined, MinusOutlined, ExclamationCircleOutlined, PrinterOutlined, CloseCircleOutlined } from "@ant-design/icons";

import api from '../../services/api';
import { useStateValue } from '../../state';
import DrawerContaGerencial from './drawer';
import { drawerActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, Adicionar, Excluir, Imprimir } from "../../components";

export default function ContaGerencial() {

    const [formPesquisa] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);
    const [listaContaGerencial, setListaContaGerencial] = useState([]);

   // useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '' } }), []);

    useEffect(() => {
        filtrarConta('');
    }, [])

    function excluirRegistro(dados) {
        Modal.confirm({
            title: 'Deseja deletar esta conta gerencial?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setCarregando(true);
                api.post(`ContaGerencial/Excluir?idContaGerencial=${dados.ctg_id}`).then((res) => {
                    if (res.status === 200) notification.success({ message: 'Operação concluída', description: "Os dados da Conta Gerencial foram excluídos com sucesso!" });
                }, err => {
                    modalInativarConta(dados);
                }).finally(() =>{
                    filtrarConta(formPesquisa.getFieldValue().pesquisa);
                });
            }
        });
    }

    const modalInativarConta = (dados) => {
        Modal.confirm({
            title: `Não foi possível excluir a Conta Gerencial ${dados.ctg_descricao}!`,
            icon: <CloseCircleOutlined />,
            content: `Deve conter referências com outros registros!`,
            okText: 'Inativar Registro',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                api.post(`ContaGerencial/AtivarInativar?idConta=${dados.ctg_id}`).then(
                    res => {
                        if (res.status === 200){
                            filtrarConta(formPesquisa.getFieldValue().pesquisa);
                            notification.success({ message: 'Operação concluída', description: "Conta inativada com sucesso!" });
                        } 
                });
            }
        });
    };

    function filtrarConta(filtro){
        if (!(!!filtro)){
            filtro = '';
        }else{

        }
        api.get(`ContaGerencial/ListarTodos?filtro=${filtro}`).then(res => {
            let index = 0;
            res.data.map((item) => {
                item.contasGerenciais.map(conta => {
                    conta.key = index;
                    index++;                    
                });
            });
            setListaContaGerencial(res.data);
        });
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Form layout="vertical" form={formPesquisa} name="formPesquisa">
                <Row align="middle" gutter={[8, 8]}>
                    <Col span={24}>
                        <Form.Item label="Pesquisar por" name="pesquisa">
                            <Input.Search defaultValue="" placeholder={"Código, descrição"} onSearch={valor => filtrarConta(valor) } allowClear/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className="colCollapseTabela">
                <Collapse expandIcon={({ isActive }) => isActive ? <MinusOutlined /> : <PlusOutlined />}>
                    {listaContaGerencial.map(conta => (
                        <Collapse.Panel header={conta.ctc_descricao} key={conta.ctc_id}>
                            <Table pagination={false} columns={[
                                {

                                    title: 'Código',
                                    align: 'left',
                                    render: ({ ctg_ativo,ctg_descricao }) => (
                                        <Popover content={ctg_ativo ? 'Ativa' : 'Inativa'} placement="right">
                                            <Tag color={ctg_ativo ? 'processing' : 'default'}>
                                                {ctg_descricao}
                                            </Tag>
                                        </Popover>
                                    ),
                                },
                                {
                                    title: '',
                                    dataIndex: '',
                                    key: 'x',
                                    align: 'right',
                                    width: 98,
                                    render: (record) => (
                                        <div>
                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                <Col>
                                                    <Imprimir icon={<PrinterOutlined />}/>
                                                </Col>
                                                <Col>
                                                    <Button onClick={() => {
                                                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                                    }} icon={<EditOutlined />} />
                                                </Col>
                                                <Col>
                                                    <Excluir icon={<DeleteOutlined />} onClick={() => excluirRegistro(record.dados)} /> 
                                                </Col>
                                            </Row>
                                        </div>
                                    ),
                                },
                            ]} expandable={{
                                expandedRowRender: ({dre_descricao}) => 
                                <div>
                                    <Row>
                                        <Col>
                                            DRE: <b> {dre_descricao || 'Não Informado'}</b>
                                        </Col>
                                    </Row>
                                </div>,
                                expandIcon: ({ expanded, onExpand, record }) =>
                                    expanded ? (
                                    <ArrowUpOutlined onClick={e => onExpand(record, e)} />
                                    ) : (
                                    <ArrowDownOutlined onClick={e => onExpand(record, e)} />
                                )
                            }} dataSource={conta.contasGerenciais} columnWidth={30}
                            />
                            <Divider className="m-0" />
                            <Row align="middle" justify="center" className="p-10">
                                <Col>
                                    <Adicionar type="primary" icon={<PlusOutlined />} size="middle" onClick={() => {
                                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null}});
                                        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true}});
                                    }}>
                                        Adicionar
                                    </Adicionar>
                                </Col>
                            </Row>

                        </Collapse.Panel>
                    ))}
                </Collapse>
            </div >
            <DrawerContaGerencial filtrarConta={filtrarConta} formPesquisa={formPesquisa}/>
        </div >
    );
}