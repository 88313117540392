import React from "react";
import { Form } from "antd";
import { Radio } from "antd";

import { useStateValue } from '../../state';
import { listagemActions } from '../../actions';
import { useEffect } from "react";

export default function FiltroAtivo({ labelInput, nameInput = ""}) {

    const [{ listagem }, dispatch] = useStateValue();

    return (
        <Form.Item label={labelInput || "Pesquisar Status"} name={nameInput}>
            <Radio.Group
                defaultValue={1}
                onChange={(valor) => {
                    dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: "&" + [nameInput] + "=" + valor.target.value } })
                }}>
                <Radio value={2}>Todos</Radio>
                <Radio value={1}>Ativo</Radio>
                <Radio value={0}>Inativo</Radio>
            </Radio.Group>
        </Form.Item>
    );
}