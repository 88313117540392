import React from "react";
import moment from 'moment';
import { Row, Col, Form, TimePicker, Modal } from "antd";
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

export default function ModalDefinirHorario({ id, onSave, exibirModal, fecharModal }) {

    const [form] = Form.useForm();
    const format = 'HH:mm';

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                form.resetFields();
                fecharModal();
            }
        });
    };

    const onSaveModal = (values) => {
        onSave(id, false, false, values.horaInicial, values.horaFinal);
        fecharModal();
    }

    return (
        <Modal centered
            title="Definir Horário de Acesso"
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={() => form.submit()}
            okText={
                <>
                    <CheckOutlined /> Definir
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }   
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" form={form} name="formModal" onFinish={onSaveModal}>
                    <Row align="middle" gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                rules={[{ required: true, message: "Informe a hora inicial" }]}
                                name="horaInicial"
                                label="Iniciar em">
                                <TimePicker
                                    defaultValue={moment('00:00', format)}
                                    format={format}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="horaFinal"
                                dependencies={['horaInicial']}
                                rules={[
                                    { required: true, message: "Informe a hora final" },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('horaInicial') < value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Horário final não pode ser menor que o inicial.'));
                                        },

                                    })
                                ]}
                                label="Até">
                                <TimePicker
                                    defaultValue={moment('00:00', format)}
                                    format={format}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}