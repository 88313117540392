import React from "react";
import FormBuilder from 'antd-form-builder';
import { Row, Col, Form, Empty, Button, Rate, Slider, Upload, TimePicker, Modal } from 'antd';
import { ExclamationCircleOutlined } from "@ant-design/icons";

FormBuilder.defineWidget('rate', Rate);
FormBuilder.defineWidget('slider', Slider);
FormBuilder.defineWidget('time-picker', TimePicker);
FormBuilder.defineWidget('upload', Upload);

export default function ModalPersonalizacaoFormulario({ exibirModalPersonalizacao, fecharModalPersonalizacao, elementosFormulario }) {

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalPersonalizacao();
            }
        });
    };

    const [ form ] = Form.useForm();

    const meta = {
        columns: 1, 
        fields: elementosFormulario
    }

    return (
        <Modal centered
            title="Pré-Visualização do Formulário"
            visible={exibirModalPersonalizacao}
            maskClosable={false}
            width={720}
            onCancel={() => {
                onCloseModal();
            }} footer={null}>
            <div className="col-pre">
                {elementosFormulario.length > 0 && 
                    <Form form={form} layout="vertical" onFinish={(v) => console.log(v)}>
                        <Row gutter={[0,8]}>
                            <Col span={24}>
                                <FormBuilder meta={meta} form={form}/>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col>
                                <Form.Item>
                                    <Button htmlType="submit">META MARCHA</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                }
                {elementosFormulario.length === 0 && <Empty/>}
            </div>
        </Modal>
    );
}