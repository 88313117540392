import React, { useEffect, useState } from "react";
import { Row, Col, Form, Descriptions, Select, Input, InputNumber, Button, Table, Tag, Tooltip } from "antd";
import { ArrowLeftOutlined, BarcodeOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../services/api";
import { InputPreco } from "../../components";
import { SelectProduto } from "../../components";

export default function CustosInventario({ formulario, exibirCustos, setExibirCustos, itensGrade, grade}) {

    const [itemProduto, setItemProduto] = useState(true);
    const [carregando, setCarregando] = useState(false);
    const [ehFracionado, setEhFracionado] = useState(false);
    const [ listaProduto, setListaProduto] = useState();

    const togglePesquisa = () => {
        resetarCampos();
        setItemProduto(!itemProduto);
    };

    const custoMedio = {
        title: 'Custo Médio (R$)',
        align: 'right',
        width: 140,
        render: ({ prg_id, pre_valorcustomedio }) => (
            <div>
                <InputPreco name={prg_id + "gradeCustoMedio"} initialValue={pre_valorcustomedio} />
            </div>
        )
    };

    const custoComIcms = {
        title: 'Custo com ICMS (R$)',
        align: 'right',
        width: 140,
        render: ({ prg_id, pre_valorcustocomicms }) => (
            <div>
                <InputPreco name={prg_id + "gradeCustoComIcms"} initialValue={pre_valorcustocomicms} />
            </div>
        )
    };

    const custoSemIcms = {
        title: 'Custo sem ICMS (R$)',
        align: 'right',
        width: 140,
        render: ({ prg_id, pre_valorcustosemicms }) => (
            <div>
                <InputPreco name={prg_id + "gradeCustoSemIcms"} initialValue={pre_valorcustosemicms} />
            </div>
        )
    };

    const custoUltimaCompra = {
        title: 'Custo Ultima Compra (R$)',
        align: 'right',
        width: 140,
        render: ({ prg_id, pre_valorcustoultimacompra }) => (
            <div>
                <InputPreco name={prg_id + "gradeCustoUltimaCompra"} initialValue={pre_valorcustoultimacompra} />
            </div>
        )
    };

    useEffect(() => {
        setListaProduto();
    }, []);

    function resetarCampos() {
        let custoMedio = !!formulario.getFieldValue().customedio;
        let custoComIcms = !!formulario.getFieldValue().custocomicms;
        let custoSemIcms = !!formulario.getFieldValue().custosemicms;
        let custoUltimaCompra = !!formulario.getFieldValue().custoultimacompra;
        let dataPosicao = formulario.getFieldValue().pre_dataposicao;
        formulario.resetFields();
        itensGrade.setItensGrade([]);
        grade.setPossuiGrade(false);
        formulario.setFieldsValue({ customedio: custoMedio, custocomicms: custoComIcms, custosemicms: custoSemIcms, custoultimacompra: custoUltimaCompra, pre_dataposicao: moment(dataPosicao) });
    }

    async function onChangeProCodigo() {
        if (!!formulario.getFieldValue().pro_codigo) {
            let produto = await (await api.get(`Produto/ListaProdutoFiltro?filtro=${formulario.getFieldValue().pro_codigo}`)).data.items[0];
            //substituido lista por uma busca direta, ja que a listaProduto sempre vinha vazia.
            // let produto = listaProduto.filter(
            //     (produto) => (produto.pro_ean === formulario.getFieldValue().pro_codigo))[0];
            if (!!produto && !!produto.pro_id) {
                validarProduto(produto.pro_id);
            }
        }
    };

    function validarProduto(idProduto) {
        grade.setPossuiGrade(false);
        validarEstoqueProduto(idProduto);
        setCarregando(true);
    };

    function validarEstoqueProduto(idProduto) {
        api.get(`Inventario/ListarProdutoCodigoDTO?CodigoProduto=${idProduto}`).then(
            res => {
                if (!!res.data && JSON.stringify(res.data) !== `{}`) {
                    let dados = res.data[0];
                    dados.pro_codigo = formulario.getFieldValue().pro_codigo;
                    dados.pre_justificativa = "Registro incluído pelo usuário para manutenção do saldo";
                    dados.pre_dataposicao = formulario.getFieldValue().pre_dataposicao;
                    setEhFracionado(dados.ump_casasdecimais > 0);
                    formulario.setFieldsValue(dados);
                    formulario.setFieldsValue({pre_qtde: dados.pre_qtde});
                    formulario.setFieldsValue({pro_id: dados.pro_id});
                    validarGradeProduto(idProduto);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregando(false);
        });
    };

    function validarGradeProduto(produtoId) {
        api.get(`ProdutoGrade/ListarGradesProdutos?codigoProduto=${produtoId}&order=-mgp_id`).then(
            res => {
                if (res.status === 200) {
                    if (res.data.length > 0) {
                        grade.setPossuiGrade(true);
                        res.data.forEach((dados) => {
                            formulario.setFieldsValue({ [dados.prg_id + 'gradeCustoMedio']: dados.pre_valorcustomedio })
                            formulario.setFieldsValue({ [dados.prg_id + 'gradeCustoComIcms']: dados.pre_custocomicms })
                            formulario.setFieldsValue({ [dados.prg_id + 'gradeCustoSemIcms']: dados.pre_custosemicms })
                            formulario.setFieldsValue({ [dados.prg_id + 'gradeCustoUltimaCompra']: dados.pre_custoultimacompra })
                            formulario.setFieldsValue({ [dados.prg_id + 'pre_qtde']: dados.pre_qtde })
                        });
                        itensGrade.setItensGrade(res.data);
                    }
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function popularCustoMedioGrade() {
        if (grade.possuiGrade) {
            if (!!formulario.getFieldValue().pre_valorcustomedio && parseFloat(formulario.getFieldValue().pre_valorcustomedio) > 0) {
                itensGrade.itensGrade.forEach((item) => {
                    let campo = item.prg_id + 'gradeCustoMedio';
                    formulario.setFieldsValue({
                        [campo]: parseFloat(formulario.getFieldValue().pre_valorcustomedio)
                    });
                });
            }
        }
    };

    function popularCustoComIcmsGrade() {
        if (grade.possuiGrade) {
            if (!!formulario.getFieldValue().pre_custocomicms && parseFloat(formulario.getFieldValue().pre_custocomicms) > 0) {
                itensGrade.itensGrade.forEach((item) => {
                    let campo = item.prg_id + 'gradeCustoComIcms';
                    formulario.setFieldsValue({
                        [campo]: parseFloat(formulario.getFieldValue().pre_custocomicms)
                    });
                });
            }
        }
    };

    function popularCustoSemIcmsGrade() {
        if (grade.possuiGrade) {
            if (!!formulario.getFieldValue().pre_custosemicms && parseFloat(formulario.getFieldValue().pre_custosemicms) > 0) {
                itensGrade.itensGrade.forEach((item) => {
                    let campo = item.prg_id + 'gradeCustoSemIcms';
                    formulario.setFieldsValue({
                        [campo]: parseFloat(formulario.getFieldValue().pre_custosemicms)
                    });
                });
            }
        }
    };

    function popularCustoUltimaCompraGrade() {
        if (grade.possuiGrade) {
            if (!!formulario.getFieldValue().pre_custoultimacompra && parseFloat(formulario.getFieldValue().pre_custoultimacompra) > 0) {
                itensGrade.itensGrade.forEach((item) => {
                    let campo = item.prg_id + 'gradeCustoUltimaCompra';
                    formulario.setFieldsValue({
                        [campo]: parseFloat(formulario.getFieldValue().pre_custoultimacompra)
                    });
                });
            }
        }
    };

    return (
        <div>
            {exibirCustos && <div>
                <Row hidden={true}>
                    <Form.Item name="pre_id" />
                    <Form.Item name="prg_id" />
                </Row>
                <Row align="middle" gutter={[8, 8]} justify="space-between">
                    <Col xs={24} sm={18} md={20} lg={20} xl={21}>
                        <Descriptions column={1} size="small">
                            <Descriptions.Item label="Data da Posição">
                                <b> {moment(formulario.getFieldValue().pre_dataposicao).format("DD/MM/YYYY HH:mm:ss")}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                    <Col xs={24} sm={6} md={4} lg={4} xl={3} align="right">
                        <Button type="primary" onClick={() => setExibirCustos(false)} block>
                            <ArrowLeftOutlined />Voltar
                        </Button>
                    </Col>
                </Row>
                <Row gutter={[8, 0]} className="m-t-8">
                    {!!itemProduto &&
                        <Col xs={20} sm={!grade.possuiGrade ? 16 : 13} md={!grade.possuiGrade ? 8 : 12} lg={14} xl={!grade.possuiGrade ? 11 : 10} xxl={!grade.possuiGrade ? 9 : 8}>
                            <Form.Item label="Localizar o Produto" name="pro_id" rules={[{ required: true, message: 'selecione o Produto' }]}>
                                <SelectProduto valueKey={false} onChange={(valor) => validarProduto(valor)} detalhes={true} placeholder="Selecione o Produto" name="pro_id" form={formulario} ordem={"pro_descricao"} /> 
                            </Form.Item>
                        </Col>
                    }
                    {!!!itemProduto &&
                        <Col xs={20} sm={!grade.possuiGrade ? 16 : 13} md={!grade.possuiGrade ? 8 : 12} lg={14} xl={!grade.possuiGrade ? 11 : 10} xxl={!grade.possuiGrade ? 9 : 8}>
                            <Form.Item label="Localize o Produto" name="pro_codigo" rules={[{ required: true, message: 'Informe um Produto.' }]}>
                                <Input placeholder="Passe o Leitor ou digite o Código de Barras" onBlur={() => onChangeProCodigo()} />
                            </Form.Item>
                        </Col>
                    }
                    <Col xs={4} sm={3} md={2} lg={2} xl={2} xxl={1} className="i-button">
                        <Tooltip title={!itemProduto ? 'Localize produto por EAN' : 'Selecione o produto'} placement="top">
                            <Button onClick={togglePesquisa} loading={carregando} icon={itemProduto ? <SearchOutlined /> : <BarcodeOutlined />} className="w-100" />
                        </Tooltip>
                    </Col>
                    {!grade.possuiGrade &&
                        <Col xs={24} sm={5} md={4} lg={4} xl={3} xxl={3} className="tt-5">
                            <Form.Item label="Quantidade" name="pre_qtde" rules={[{ required: true, message: 'Informe a Quantidade.' }]}>
                                {ehFracionado ? <InputNumber decimalSeparator={','} min={0} placeholder="Informe a Quantidade" /> : <InputNumber step={1} min={0} max={999999999} 
                                placeholder="Informe a Quantidade" />}
                            </Form.Item>
                        </Col>
                    }
                    {!!formulario.getFieldValue().customedio &&
                        <Col xs={24} sm={8} md={5} lg={4} xl={4} xxl={3}>
                            <InputPreco label="Custo Médio (R$)" name="pre_valorcustomedio" onBlur={() => { popularCustoMedioGrade() }} />
                        </Col>
                    }
                    {!!formulario.getFieldValue().custocomicms &&
                        <Col xs={24} sm={8} md={5} lg={4} xl={4} xxl={4}>
                            <InputPreco label="Custo com ICMS (R$)" name="pre_custocomicms" onBlur={() => { popularCustoComIcmsGrade() }} />
                        </Col>
                    }
                    {!!formulario.getFieldValue().custosemicms &&
                        <Col xs={24} sm={8} md={5} lg={4} xl={4} xxl={4}>
                            <InputPreco label="Custo sem ICMS (R$)" name="pre_custosemicms" onBlur={() => { popularCustoSemIcmsGrade() }} />
                        </Col>
                    }
                    {!!formulario.getFieldValue().custoultimacompra &&
                        <Col xs={24} sm={8} md={5} lg={4} xl={5} xxl={4}>
                            <InputPreco label="Custo Última Compra (R$)" name="pre_custoultimacompra" onBlur={() => { popularCustoUltimaCompraGrade() }} />
                        </Col>
                    }
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Justificativa" name="pre_justificativa">
                            <Input.TextArea placeholder="Informe uma Justificativa" />
                        </Form.Item>
                    </Col>
                </Row>
                <div className="tabela">
                    {grade.possuiGrade && <Table columns={[
                        {
                            title: 'Informações da Grade',
                            render: ({ ean, atributos }) => (
                                <div>
                                    <Row align="middle" gutter={[5, 5]}>
                                        <Col>
                                            <Tag color="processing">
                                                <b>{ean}</b>
                                            </Tag>
                                        </Col>
                                        {atributos.map(atr => (
                                            <Col>
                                                <b>{atr.mgp_descricao}</b>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>

                            )
                        },
                        !!formulario.getFieldValue().customedio ? custoMedio : <div></div>,
                        !!formulario.getFieldValue().custocomicms ? custoComIcms : <div></div>,
                        !!formulario.getFieldValue().custosemicms ? custoSemIcms : <div></div>,
                        !!formulario.getFieldValue().custoultimacompra ? custoUltimaCompra : <div></div>,
                        {
                            title: 'Quantidade',
                            width: 100,
                            render: ({ prg_id, qtdEstoque }) => (
                                <Form.Item name={prg_id + "pre_qtde"} initialValue={qtdEstoque}>
                                    <InputNumber step={1} min={0} max={999999999} placeholder="Informe a Quantidade" />
                                </Form.Item>
                            )
                        }]} dataSource={itensGrade.itensGrade} scroll={{ x: 900 }} />}
                </div>
            </div>}
        </div>
    );

}