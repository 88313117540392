import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Form, Input, Button, notification, Typography } from 'antd';
import { UnlockFilled, SendOutlined, DesktopOutlined, PhoneOutlined, EnvironmentOutlined } from '@ant-design/icons';

import { SlideLogin } from "../../components";
import api from '../../services/api';

export default function RecuperarSenha() {
    const [usuarioEmail, setUsuarioEmail] = useState('');
    const [validaHash, setValidaHash] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        let email = atob(window.location.href.split('usu=')[1]);
        setUsuarioEmail(email);
        verificarHashParaAlterarSenha();
    }, []);

    function verificarHashParaAlterarSenha() {
        api.get(`Usuario/VeriricarHashParaAlterarSenha/${atob(window.location.href.split('usu=')[1])}`).then(
            res => {
                if (res.data) {
                    console.log(res.data)
                    setValidaHash(true);
                } else {
                    notification.warn({ description: 'Sua Senha Foi Alterada Recentemente, tente novamente mais tarde!', message: 'Aviso' });
                    voltarLogin();
                }
            }
        ).catch(
            erro => {
                console.log(erro.response);
                // if (erro.response.data.Message === "Tempo para alterar a senha exgotado!") {
                notification.warn({ description: "Tempo limite de 30 minutos para alterar a senha exgotado! Tente novamente mais tarde!", message: "Aviso" });
                voltarLogin();
                //}
            }
        );
    };

    function alterarSenha() {
        if (validaHash) {
            if (form.getFieldValue().usu_senha === form.getFieldValue().usu_confirmaSenha) {
                let dados = {
                    novaSenha: btoa(form.getFieldValue().usu_senha),
                    usu_email: usuarioEmail
                };
                api.put(`Usuario/AlterarSenha`, dados).then(
                    res => {
                        notification.success({ description: 'Sua Senha Foi Alterada Com Sucesso!', message: 'Aviso' });
                        voltarLogin();
                    }
                ).catch(
                    erro => {
                        console.log(erro);
                    }
                );
            } else {
                notification.warn({ description: 'As senhas informadas não conferem, favor verificar!', message: 'Aviso' })
            }
        } else {
            notification.warn({ description: 'Não foi possivel validar hash, Tempo esgotado, favor enviar novo pedido de recuperação de senha!', message: 'Aviso' })
        }
    };

    function voltarLogin() {
        setTimeout(() => { window.location = '/#/' }, 2000);
    };

    return (
        <Layout className="vh100">
            <Layout.Content>
                <Row justify="center" className="vh100">
                    <Col className="vh100 fixed-left">
                        <SlideLogin />
                    </Col>
                    <Col className="vh100 fixed-right">
                        <Row align="middle" justify="center" className="vh100" gutter={[16, 16]}>
                            <Col span={18}>
                                <Form layout="vertical" name="recuperar-senha" form={form} initialValues={{ remember: true }} size="middle" onFinish={alterarSenha}>
                                    <Row align="middle" justify="center">
                                        <Col>
                                            <img src={require("../../assets/logoGestor.png").default} width="250" alt="Gestor Web" />
                                        </Col>
                                    </Row>
                                    <Row align="middle" className="m-t-16">
                                        <Col span={24}>
                                            <Form.Item label="Nova Senha" name="usu_senha" rules={[{ required: true, message: 'Informe a Nova Senha.', }]}>
                                                <Input.Password placeholder="Senha de Acesso" prefix={<UnlockFilled />} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label="Confirmar Nova Senha" name="usu_confirmaSenha" dependencies={['password']}
                                                rules={[{ required: true, message: 'Confirme a Nova Senha.' }, ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('usu_senha') === value) {
                                                            return Promise.resolve();
                                                        }

                                                        return Promise.reject(new Error('As senhas informadas não conferem!'));
                                                    },
                                                }),]}>
                                                <Input.Password placeholder="Confirme a Nova Senha do Usuário" prefix={<UnlockFilled />} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row align="middle" justify="end" className="m-t-16">
                                        <Col>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    Alterar Senha <SendOutlined />
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <Row align="middle" justify="center" className="rodape-login-copyright">
                            <Col>
                                <Row align="middle" justify="center" gutter={[8, 0]}>
                                    <Col>
                                        <DesktopOutlined />
                                        <Typography.Link href="https://www.gestorweb.com.br/" target="_blank" underline> www.gestorweb.com.br</Typography.Link> | <PhoneOutlined /> (55) 3535-4922
                                    </Col>
                                    <Col>
                                        | <EnvironmentOutlined /> Três de Maio - RS - 98910-000
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24} align="center">
                                Copyright © Gestor Web 2022 - Todos os direitos reservados
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
}