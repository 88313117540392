import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Form, Button, Collapse, Checkbox, Modal, notification } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, SearchOutlined, PrinterOutlined, DeleteOutlined, ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';

import api from '../../services/api';
import DrawerValePresente from './drawer';
import { useStateValue } from '../../state';
import { linkApi } from '../../services/auth';
import { FormatNumber, FormatDate } from "../../ValueObjects";
import { listagemActions, manutencaoActions } from '../../actions';
import { BreadcrumbIncluirDados, ComboData, TabelaDados, Excluir, Imprimir, Data, FiltroSearch } from '../../components';

export default function ValePresente() {

    const [open, setOpen] = useState(false);
    const [{ manutencao }, dispatch] = useStateValue();
    const [parametrosValePresente] = Form.useForm();
    const [dataPesquisa, setDataPesquisa] = useState(new Date().toLocaleDateString());
    const [dataAtual] = useState(new Date());
    const [valesQuitados, setValesQuitados] = useState(false);

    useEffect(() => {
        aplicarFiltrosAvancados();
    }, [valesQuitados]);

    function aplicarFiltrosAvancados() {
        let filtros = '';
        filtros += `&SomenteAtivos=true`;
        filtros += `&dataInicial=${(!!!parametrosValePresente.getFieldValue().dataPesquisa ? moment(dataAtual).format("YYYY-MM-DD") : !!parametrosValePresente.getFieldValue().DataInicial ? moment(parametrosValePresente.getFieldValue().DataInicial).format('YYYY-MM-DD') : moment(parametrosValePresente.getFieldValue().DataPesquisa).format('YYYY-MM-DD'))}`;
        filtros += `&dataFinal=${(!!!parametrosValePresente.getFieldValue().DataFinal ? moment(dataAtual).format("YYYY-MM-DD") : moment(parametrosValePresente.getFieldValue().DataFinal).format('YYYY-MM-DD'))}`;
        filtros += `&filtro=&mostrarValeQuitado=${(!!parametrosValePresente.getFieldValue().valesQuitados ? parametrosValePresente.getFieldValue().valesQuitados : false)}`;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: filtros, ordem: 'acf_datalancamento' } });
    };

    const modalImprimir = (record) => {
        Modal.confirm({
            title: 'Imprimir',
            icon: <ExclamationCircleOutlined />,
            content: `Confirmar impressão do vale ${record.acf_nomepresenteado} no valor de ${FormatNumber(record.acf_valor, true)}?`,
            okText: 'Imprimir',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                imprimir(record);
            }
        });
    };

    function excluirVale(valePresente) {
        api.post(`AntecipacaoClienteFornecedor/ExcluirValePresente/${valePresente.acf_id}`).then(
            (res) => {
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                notification.success({ message: 'Vale presente excluida com Sucesso!' });
            }
        ).catch(
            (erro) => {
                notification.warning({ message: `Aviso!`, description: erro.response.data });
                //notification.warning({ message: `Aviso!`, description: `Não foi possivel excluir, deve conter referências com outros registros!` });
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: 'acf_datalancamento' } });
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&SomenteAtivos=true&dataFinal=${FormatDate(dataAtual.toLocaleDateString())}&dataInicial=${FormatDate(dataPesquisa)}&filtro=&mostrarValeQuitado=${parametrosValePresente.getFieldValue().valesQuitados}` } })
            }
        );
    };

    const modalExcluirVale = (ValePresente) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o Vale de R$ ${ValePresente.acf_valor} presenteado à ${ValePresente.acf_nomepresenteado} ?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirVale(ValePresente);
            }
        });
    };

    const togglePesquisa = () => {
        setOpen(!open);
    };

    const imprimir = ValePresente => {
        window.open(linkApi + 'AntecipacaoClienteFornecedor/GerarComprovante/' + ValePresente.acf_id, "_blank");
    };

    useEffect(() => {
        parametrosValePresente.setFieldsValue({ DataFinal: moment(dataAtual) });
        parametrosValePresente.setFieldsValue({ DataInicial: moment(parametrosValePresente.getFieldValue().dataPesquisa) });
    }, [])

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Form layout="vertical">
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={17} md={17} lg={8} xl={11} xxl={13}>
                        <FiltroSearch />
                    </Col>
                    <Col xs={24} sm={7} md={7} lg={5} xl={4} xxl={5}>
                        <ComboData form={parametrosValePresente} eventoAoAlterar={() => {
                            parametrosValePresente.setFieldsValue({ DataFinal: moment(dataAtual) });
                            parametrosValePresente.setFieldsValue({ DataInicial: moment(parametrosValePresente.getFieldValue().dataPesquisa) });
                            aplicarFiltrosAvancados();
                        }} />
                    </Col>
                </Row>
            </Form>
            <div>
                <Form layout="vertical" name="formParametrosValePresente" form={parametrosValePresente}>
                    <Row align="middle" gutter={[0, 24]}>
                        <Col span={24}>
                            <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                                <div className="colDet"></div>
                                <Collapse.Panel key="1" showArrow={false} extra={
                                    <Row gutter={[8, 0]} justify="end">
                                        <Col>
                                            <Form.Item name="valesQuitados" valuePropName="checked" initialValue={false}>
                                                <Checkbox onChange={(value) => { setValesQuitados(value) }}> Mostrar Vales Quitados? </Checkbox>
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Button type="primary" onClick={togglePesquisa} block>
                                                {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                            </Button>
                                        </Col>
                                    </Row>
                                }>
                                    <Row gutter={[8, 0]}>
                                        <Col xs={24} sm={12} md={5} lg={4} xl={4} xxl={3}>
                                            <Data label="Data Inicial" name="DataInicial" />
                                        </Col>
                                        <Col xs={24} sm={12} md={5} lg={4} xl={4} xxl={3}>
                                            <Data label="Data Final" name="DataFinal" />
                                        </Col>
                                        <Col xs={24} sm={24} md={14} lg={16} xl={16} xxl={18} className="t-mob">
                                            <Row gutter={[8, 8]} className="tt-5">
                                                <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={3}>
                                                    <Button icon={<CloseOutlined />} block>
                                                        Remover Filtros
                                                    </Button>
                                                </Col>
                                                <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={3}>
                                                    <Button type="primary" icon={<SearchOutlined />} onClick={aplicarFiltrosAvancados} block>
                                                        Aplicar Filtros
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="tabela">        
                <TabelaDados url="AntecipacaoClienteFornecedor/ListarValePresente" colunas={[
                    {
                        title: 'Informações dos Vales Presentes',
                        width: 300,
                        render: ({ acf_codigovalepresente, acf_nomepresenteado }) => (
                            <div>
                                <Row align="middle" gutter={[5, 0]}>
                                    <Col>
                                        <b>{acf_codigovalepresente}</b>
                                    </Col>
                                    <Col>
                                        -
                                    </Col>
                                    <Col>
                                        <b>{acf_nomepresenteado}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Data',
                        width: 40,
                        render: ({ acf_datalancamento }) => (
                            <div>
                                <b>{moment(acf_datalancamento).format('DD/MM/YYYY')}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Total (R$)',
                        width: 50,
                        align: 'right',
                        render: ({ acf_valor }) => (
                            <div>
                                <b>{FormatNumber(acf_valor, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Utilizado (R$)',
                        width: 50,
                        align: 'right',
                        render: ({ valorUtilizado }) => (
                            <div>
                                <b>{FormatNumber(valorUtilizado, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Saldo (R$)',
                        width: 50,
                        align: 'right',
                        render: ({ saldoAntecipacao }) => (
                            <div>
                                <b>{FormatNumber(saldoAntecipacao, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'right',
                        fixed: 'right',
                        width: 30,
                        render: (dados) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Imprimir icon={<PrinterOutlined />} onClick={() => { modalImprimir(dados) }} />
                                    </Col>
                                    <Col>
                                        <Excluir onClick={() => modalExcluirVale(dados)} icon={<DeleteOutlined />} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]}
                />
            </div>
            <DrawerValePresente formParametrosValePresente={parametrosValePresente} dataPesquisa={dataPesquisa} />
        </div>
    );

}