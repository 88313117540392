import React, { useState, useEffect } from 'react';
import { Row, Col, Descriptions, Tabs, Form, Table, Divider, Popover } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';
import TabelaDados from '../tabelaDados';
import { FormatNumber } from '../../ValueObjects';
import { novaAbaNavegador } from '../../services/funcoes';

export default function DetalhesRecibo({ dadosModal }) {

    const [detalheRecibo, setDetalheRecibo] = useState({});
    const [parcelasProcessadas, setParcelasProcessadas] = useState([]);

    useEffect(() => {
        if (dadosModal) {
            setDetalheRecibo(dadosModal);
            const processedParcelas = processParcelas(dadosModal.parcelas);
            setParcelasProcessadas(processedParcelas);
        }
    }, [dadosModal]);

    const processParcelas = (parcelas) => {
        const sortedParcelas = parcelas.sort((a, b) => a.cpr_numerodocumento - b.cpr_numerodocumento);

        const groupedParcelas = sortedParcelas.reduce((acc, current) => {
            const { prc_id, valorQuitado } = current;

            if (!acc[prc_id]) {
                acc[prc_id] = { ...current, valorQuitado: 0 };
            }

            acc[prc_id].valorQuitado += valorQuitado;
            return acc;
        }, {});

        return Object.values(groupedParcelas);
    };
    function visualizarDanfe(cde_chaveacesso) {
        novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${cde_chaveacesso}`);
    };
    return (
        <div>
            <Form layout="vertical">
                <Row align="middle">
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item label="Cliente">
                                <b>{dadosModal.pes_nome}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row align="middle">
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item label="Forma Pagamento">
                                <b>{dadosModal.fpg_descricao}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row align="middle">
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item label="Condição Pagamento">
                                <b>{dadosModal.cpg_descricao}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row align="middle">
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item label="Descrição">
                                <b>{dadosModal.rec_decricaofinanceiro}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row align="middle">
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item label="Chave Acesso NFC-e">
                                <Popover
                                    content={<a onClick={() => visualizarDanfe(dadosModal.cde_chaveacesso)}>Clique para visualizar o Danfe</a>}
                                    title="Visualizar Danfe"
                                >
                                    <b>{dadosModal.cde_chaveacesso}</b>
                                </Popover>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            </Form>
            <div className="tabela m-t-8 p-b-75">
                <Table columns={
                    [
                        {
                            title: 'N° Documento',
                            width: '20%',
                            align: 'center',
                            render: ({ cpr_numerodocumento }) => (
                                <div>
                                    <b>{cpr_numerodocumento}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'N° Parcela / Qde Parcelas',
                            width: '20%',
                            align: 'center',
                            render: ({ prc_numeroparcela, cpr_numeroparcelas }) => (
                                <div>
                                    <b>{prc_numeroparcela}/{cpr_numeroparcelas}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Data Vencimento',
                            width: '20%',
                            align: 'center',
                            render: ({ prc_datavencimento }) => (
                                <div>
                                    <b>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Valor Juros (R$)',
                            width: '20%',
                            align: 'right',
                            render: ({ valorJuros }) => (
                                <div>
                                    <b>{FormatNumber(valorJuros, true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Valor Desconto (R$)',
                            width: '20%',
                            align: 'right',
                            render: ({ valorDesconto }) => (
                                <div>
                                    <b>{FormatNumber(valorDesconto, true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Valor Quitado (R$)',
                            fixed: 'right',
                            width: '25%',
                            align: 'right',
                            render: ({ valorQuitado }) => (
                                <div>
                                    <b>{FormatNumber(valorQuitado, true)}</b>
                                </div>
                            ),
                        },
                    ]} dataSource={parcelasProcessadas} pagination={false} scroll={{
                        x: 600,
                        y: 300,
                    }}
                    locale={{
                        emptyText: (
                            <Row>
                                <Col span={24}>
                                    <Divider orientation="center">
                                        <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                    </Divider>
                                </Col>
                            </Row>
                        )
                    }} />
            </div>
        </div>
    );
}