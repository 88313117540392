import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Button, Table, notification, Modal, Checkbox, Divider } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, CheckOutlined, ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons";

import { getEmpresa } from "../../services/auth";
import api from "../../services/api";

export default function TabItensGrade({ formulario, itensGrade, setItensGrade, atualizarDadosGrade }) {

    const [editandoRegistro, setEditandoRegistro] = useState(false);

    useEffect(() => {
        formulario.setFieldsValue({ descricaoItem: '' });
        setEditandoRegistro(false);
    }, [itensGrade]);

    function incluirItemGrade() {
        if (!!formulario.getFieldValue().descricaoItem) {
            if (itensGrade.filter((item) => { return item.mgp_descricao === formulario.getFieldValue().descricaoItem }).length > 0) {
                Modal.confirm({
                    title: 'Atenção!',
                    icon: <ExclamationCircleOutlined />,
                    content: `Você já possui um item nessa modalidade de grade denominado ${formulario.getFieldValue().descricaoItem}. Deseja adicionar este novo item mesmo assim?`,
                    okText: 'Sim, adicionar',
                    cancelText: 'Não',
                    centered: true,
                    onOk() {
                        adicionarItem();
                    }
                });
            } else {
                adicionarItem();
            }
        }
    };

    function adicionarItem() {
        let itemAdd = {};
        itemAdd.emp_id = getEmpresa();
        itemAdd.mgp_descricao = formulario.getFieldValue().descricaoItem;
        itemAdd.mgp_imprimenaetiqueta = false;
        if (!!formulario.getFieldValue().mgp_id) {
            itemAdd.mgp_modalidade = formulario.getFieldValue().mgp_id;
            api.post(`ModalidadeGrade/Incluir`, JSON.stringify(itemAdd), { headers: { 'Content-Type': 'application/json;charset=utf-8' } }).then(res => {
                if (res.status === 200) {
                    atualizarDadosGrade(formulario.getFieldValue().mgp_id);
                }
            }, erro => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
            });
        } else {
            let itens = [...itensGrade];
            itemAdd.key = itens.length + 1;
            itens.push(itemAdd);
            setItensGrade(itens);
        }
        formulario.setFieldsValue({ descricaoItem: '' });
    };

    function editarRegistro(dados) {
        if (!!dados.key) {
            setEditandoRegistro(true);
            formulario.setFieldsValue({ descricaoItem: dados.mgp_descricao, keyItem: dados.key });
        }
    };

    function salvarItemGrade() {
        let itens = [...itensGrade];
        let itemEditar = itens.filter((item) => { return item.key === formulario.getFieldValue().keyItem })[0];
        itemEditar.mgp_descricao = formulario.getFieldValue().descricaoItem;

        /*if (!!formulario.getFieldValue().mgp_id) {
            api.post(`ModalidadeGrade/editar`, JSON.stringify(itemEditar), { headers: { 'Content-Type': 'application/json;charset=utf-8' } }).then(res => {
                if (res.status === 200) {
                    atualizarDadosGrade(formulario.getFieldValue().mgp_id);
                }
            }, erro => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
            });
        } else {*/
        setItensGrade(itens);
        //}
    };

    function excluirRegistro(dadosItem) {
        Modal.confirm({
            title: 'Atenção!',
            icon: <ExclamationCircleOutlined />,
            content: `Remover o item ${dadosItem.mgp_descricao} desta modalidade de grade?`,
            okText: 'Sim, quero remover',
            cancelText: 'Não',
            centered: true,
            onOk() {
                let mgp_id = formulario.getFieldValue().mgp_id;
                if (!!mgp_id) {
                    api.delete(`ModalidadeGrade/Excluir?IdModalidade=${dadosItem.mgp_id}`).then(res => {
                        if (res.data) {
                            atualizarDadosGrade(mgp_id);
                        } else {
                            error('É provável que o item que você esta tentando remover esteja relacionado a algum produto cadastrado no sistema.', 'Não foi possível excluir o item da modalidade de grade!');
                        }
                    }, erro => {
                        error('É provável que o item que você esta tentando remover esteja relacionado a algum produto cadastrado no sistema.', 'Não foi possível excluir o item da modalidade de grade!');
                    });
                } else {
                    let itens = [...itensGrade];
                    let index = itens.findIndex(i => i.key === dadosItem.key);
                    itens.splice(index, 1);
                    itens.map((item, indice) => {
                        item.key = indice + 1;
                    });
                    setItensGrade(itens);
                }
            }
        });
    };

    function error(mensagem, title = 'Atenção!') {
        Modal.error({
            title: title,
            content: (
                <div>
                    <p>{mensagem}</p>
                </div>
            )
        });
    };

    function selecionarItemGrade(key) {
        let itens = [...itensGrade];
        let itemEditar = itens.filter((item) => { return item.key === key })[0];
        itemEditar.mgp_imprimenaetiqueta = !itemEditar.mgp_imprimenaetiqueta;
        setItensGrade(itens);
    };

    function selecionarTodos(checked){
        let itens = [...itensGrade];
        itens.map((item) => { 
            item.mgp_imprimenaetiqueta = (checked ? true : false);
        });
        setItensGrade(itens);
    };

    return (
        <div>
            {editandoRegistro &&
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={16} md={17} lg={18} xl={18}>
                        <Form.Item name="keyItem" hidden><Input /></Form.Item>
                        <Form.Item label="Descrição da Modalidade da Grade" name="descricaoItem">
                            <Input placeholder="Informe aqui a descrição do item de grade" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={7} lg={6} xl={6}>
                        <Row gutter={[8, 8]} className="t-mob-573">
                            <Col span={12}>
                                <Button type="primary" onClick={() => { salvarItemGrade() }} icon={<CheckOutlined />} block>
                                    Salvar
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Button onClick={() => { formulario.setFieldsValue({ descricaoItem: '' }); setEditandoRegistro(false) }} icon={<CloseOutlined />} block>
                                    Cancelar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }
            {!editandoRegistro &&
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={17} md={18} lg={19} xl={19} xxl={20}>
                        <Form.Item label="Descrição da Modalidade da Grade" name="descricaoItem">
                            <Input placeholder="Informe aqui a descrição do item de grade" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={7} md={6} lg={5} xl={5} xxl={4}>
                        <Form.Item>
                            <Button type="primary" onClick={() => { incluirItemGrade() }} icon={<PlusOutlined />} block className="t-mob-573">
                                Adicionar Item
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            }
            <div className="tabela mt-dif">
                <Table dataSource={itensGrade} columns={[
                    {
                        title: () => {return <div><Checkbox onChange={(event) => { selecionarTodos(event.target.checked) }} /> Imprime na Etiqueta?</div>},
                        align: 'left',
                        width: 150,
                        render: ({ mgp_imprimenaetiqueta, key }) => (
                            <div>
                                <Checkbox checked={mgp_imprimenaetiqueta} onChange={() => { selecionarItemGrade(key) }} />
                            </div>
                        ),
                    },
                    {
                        title: 'Descrição',
                        align: 'left',
                        render: ({ mgp_descricao }) => (
                            <div>
                                <b>{mgp_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        align: 'center',
                        key: 'x',
                        width: 65,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<EditOutlined />} onClick={() => { editarRegistro(record) }} />
                                    </Col>
                                    <Col>
                                        <Button icon={<DeleteOutlined />} onClick={() => { excluirRegistro(record) }} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                </Divider>
                            </Col>
                        </Row>
                    )
                }} />
            </div>
        </div>
    );

}