import React from "react";
import { Row, Col, Descriptions } from "antd";

export default function InfoProdutoGrade() {
    return (
        <Row>
            <Col>
                <Descriptions size="small">
                    <Descriptions.Item label="Produto/Mercadoria"> 
                        <b>pro_codigo - pro_descricao</b>
                    </Descriptions.Item>
                </Descriptions>
            </Col>    
        </Row>   
    );
}