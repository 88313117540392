import React from 'react';
import { Col, Row } from 'antd';
import moment from 'moment';
import { FormatNumber } from '../../../ValueObjects';

export default function FluxoCaixaDiarioSintetico({ valoresProvisionados, tabelaFluxoCaixa, saldos }) {

    return (
        <div>
            <div>
                {!valoresProvisionados &&
                    <div className="tabela-fluxo m-t-19">
                        <Row className="b-color-secondary col-layout c-black t-head">
                            <Col span={6}><b>FLUXO DE CAIXA DE <span className="f-14">{moment(saldos.dataInicial).format('DD/MM/YYYY')}</span> A <span className="f-14">{moment(saldos.dataFinal).format('DD/MM/YYYY')}</span></b></Col>
                            <Col align="right" span={3}><b>SALDO INICIAL</b></Col>
                            <Col align="right" span={6}><b>ENTRADA</b></Col>
                            <Col align="right" span={6}><b>SAÍDA</b></Col>
                            <Col align="right" span={3}><b>SALDO FINAL</b></Col>
                        </Row>
                        <div className="t-body p-0">
                            {tabelaFluxoCaixa.map((fluxoLocal) => {
                                return (
                                    <Row className="row">
                                        <Col span={6}>{fluxoLocal.nomeLocal}</Col>
                                        <Col align="right" span={3}>{FormatNumber(fluxoLocal.valor_SaldoInicial, true, false)}</Col>
                                        <Col align="right" span={6}>{FormatNumber(fluxoLocal.valor_RealizadoEntrada, true, false)}</Col>
                                        <Col align="right" span={6}>{FormatNumber(fluxoLocal.valor_RealizadoSaida, true, false)}</Col>
                                        <Col align="right" span={3}>{FormatNumber(fluxoLocal.valor_SaldoFinal, true, false)}</Col>
                                    </Row>
                                )
                            })}
                            <Row className="c-primary row">
                                <Col span={6}><b>TOTAL DO PERÍODO</b></Col>
                                <Col align="right" className={saldos.saldoInicial < 0 ? "c-red" : "c-primary"} span={3}><b>{FormatNumber(saldos.saldoInicial < 0 ? saldos.saldoInicial * -1 : saldos.saldoInicial, true, false)}</b></Col>
                                <Col align="right" className="c-primary" span={6}><b>{FormatNumber(saldos.totalCreditoRealizado, true, false)}</b></Col>
                                <Col align="right" className="c-red" span={6}><b>{FormatNumber(saldos.totalDebitoRealizado, true, false)}</b></Col>
                                <Col align="right" className={saldos.saldoFinal < 0 ? "c-red" : "c-primary"} span={3}><b>{FormatNumber(saldos.saldoFinal < 0 ? saldos.saldoFinal * -1 : saldos.saldoFinal, true, false)}</b></Col>
                            </Row>
                        </div>
                    </div>
                }
                {valoresProvisionados &&
                    <div className="tabela-fluxo m-t-19">
                        <Row className="b-color-secondary col-layout c-black t-head">
                            <Col span={6}><b>FLUXO DE CAIXA DE <span className="f-14">{moment(saldos.dataInicial).format('DD/MM/YYYY')}</span> A <span className="f-14">{moment(saldos.dataFinal).format('DD/MM/YYYY')}</span></b></Col>
                            <Col align="right" span={3}><b>SALDO INICIAL</b></Col>
                            <Col span={6}>
                                <Row>
                                    <Col span={24} align="center"><b>ENTRADA</b></Col>
                                </Row>
                                <Row>
                                    <Col span={12} align="right"><b>PREVISTO</b></Col>
                                    <Col span={12} align="right"><b>REALIZADO</b></Col>
                                </Row>
                            </Col>
                            <Col span={6}>
                                <Row>
                                    <Col span={24} align="center"><b>SAÍDA</b></Col>
                                </Row>
                                <Row>
                                    <Col span={12} align="right"><b>PREVISTO</b></Col>
                                    <Col span={12} align="right"><b>REALIZADO</b></Col>
                                </Row>
                            </Col>
                            <Col align="right" span={3}><b>SALDO FINAL</b></Col>
                        </Row>
                        <div className="t-body p-0">
                            {tabelaFluxoCaixa.map((fluxoLocal) => {
                                return (
                                    <Row className="row">
                                        <Col span={6}>{fluxoLocal.nomeLocal}</Col>
                                        <Col align="right" span={3}>{FormatNumber(fluxoLocal.valor_SaldoInicial, true, false)}</Col>
                                        <Col className="col" span={6}>
                                            <Row>
                                                <Col align="right" span={12}>{FormatNumber(fluxoLocal.valor_ProvisaoReceber, true, false)}</Col>
                                                <Col align="right" span={12}>{FormatNumber(fluxoLocal.valor_RealizadoEntrada, true, false)}</Col>
                                            </Row>
                                        </Col>
                                        <Col className="col" span={6}>
                                            <Row>
                                                <Col align="right" span={12}>{FormatNumber(fluxoLocal.valor_ProvisaoPagar, true, false)}</Col>
                                                <Col align="right" span={12}>{FormatNumber(fluxoLocal.valor_RealizadoSaida, true, false)}</Col>
                                            </Row>
                                        </Col>
                                        <Col align="right" span={3}>{FormatNumber(fluxoLocal.valor_SaldoFinal, true, false)}</Col>
                                    </Row>
                                )
                            })}
                            <Row className="c-primary row">
                                <Col span={6}><b>TOTAL DO PERÍODO</b></Col>
                                <Col align="right" span={3} className={saldos.saldoInicial < 0 ? "c-red" : "c-primary"} span={3}><b>{FormatNumber(saldos.saldoInicial < 0 ? saldos.saldoInicial * -1 : saldos.saldoInicial, true, false)}</b></Col>
                                <Col className="col c-primary" span={6}>
                                    <Row>
                                        <Col align="right" className="c-primary" span={12}><b>{FormatNumber(saldos.totalCreditoPrevisto, true, false)}</b></Col>
                                        <Col align="right" className="c-primary" span={12}><b>{FormatNumber(saldos.totalCreditoRealizado, true, false)}</b></Col>
                                    </Row>
                                </Col>
                                <Col className="col c-red" span={6}>
                                    <Row>
                                        <Col align="right" className="c-red" span={12}><b>{FormatNumber(saldos.totalDebitoPrevisto, true, false)}</b></Col>
                                        <Col align="right" className="c-red" span={12}><b>{FormatNumber(saldos.totalDebitoRealizado, true, false)}</b></Col>
                                    </Row>
                                </Col>
                                <Col align="right" span={3} className={saldos.saldoFinal < 0 ? "c-red" : "c-primary"} span={3}><b>{FormatNumber(saldos.saldoFinal < 0 ? saldos.saldoFinal * -1 : saldos.saldoFinal, true, false)}</b></Col>
                            </Row>
                        </div>
                    </div>
                }
            </div>
        </div>

    );
}