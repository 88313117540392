import React, { useState } from "react";
import { Row, Col, Button, Typography, Divider, Card, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { linkApi } from "../../services/auth";
import { ModalAdicionarUsuario } from "../../components/modals/";

export default function TabUsuarioGrupo({ formulario, usuarios }) {

    const [openModal, setOpenModal] = useState(false);

    function incluirRemoverUsuario(user, incluir, mensagem = '') {
        let lista = [...usuarios.usuarios];
        let indice = usuarios.usuarios.findIndex((element) => {
            return element.usu_email === user.usu_email;
        });
        lista[indice].incluido = incluir;

        formulario.setFieldsValue({ usuarios: lista.filter((user) => { return user.incluido }) });
        usuarios.setUsuarios(lista);
        if (!!mensagem){
            Modal.success({
                content: mensagem,
            });
        }
    };

    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-boleto.png").default}  alt="Usuário do Grupo" /> Informações Usuário do Grupo
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Adicione um novo usuário.
                    </Divider>
                </Col>
            </Row>
            {/* <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => setOpenModal(true)} />
                    </div>
                </Col>
            </Row> */}
            <div className="colUsers">
                <Row justify="center" gutter={[16,16]}>
                    {usuarios.usuarios.filter((user) => { return !user.usu_email.includes('@newerp.com.br') }).map((item) => (
                        <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={4}>
                            <Card hoverable
                                cover={
                                    <img alt="Usuário" src={`${linkApi}Usuario/CarregarAvatar?email=${item.usu_email}`} />
                                }
                            >
                                <Row align="middle" justify="center" gutter={[0, 8]}>
                                    <Col span={24}>
                                        <Card.Meta title={item.usu_nome} description={item.usu_email} />
                                    </Col>
                                    {!item.incluido &&
                                        <Col span={24}>
                                            <Button type="primary" block onClick={() => incluirRemoverUsuario(item, true)}>
                                                Incluir no Grupo
                                            </Button>
                                        </Col>
                                    }
                                    {item.incluido &&
                                        <Col span={24}>
                                            <Button type="primary" block danger onClick={() => incluirRemoverUsuario(item, false)}>
                                                Remover do Grupo
                                            </Button>
                                        </Col>
                                    }
                                </Row>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
            <ModalAdicionarUsuario exibirModal={openModal} fecharModal={() => setOpenModal(false)} incluirRemoverUsuario={incluirRemoverUsuario}/>
        </div>
    );

}