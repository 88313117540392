import { SaveOutlined } from "@ant-design/icons";
import { Col, Form, Input, notification, Row, Select, Checkbox, Button } from "antd";
import React, { useEffect } from "react";
import { selectPaginadoActions } from "../../actions";
import SelectPaginado from "../../components/selectPaginado";
import api from "../../services/api";
import { useStateValue } from "../../state";

export default function Testes() {

const [formTeste] = Form.useForm();
const [{ manutencao }, dispatch] = useStateValue();

useEffect(() => {
    carregarDados();
}, []);

//#region carregar dados
function carregarDados() {
    let lista = [];
    lista.push({ name: "marcaId", campo: "Ativa", value: true });
    if (lista.length > 0)
        dispatch({ type: selectPaginadoActions.CHANGE, data: {itens: lista} });
}
//#endregion

//#region tratamento dos dados
function setarFormulario(id) {
    api.get(`Marca/Buscar/${id}`).then(
        res => {
            if (res.status === 200) {
                formTeste.setFieldsValue(res.data);
            }
        }
    ).catch(
        error => {
            console.log(error);
            notification.warning({ message: 'Aviso!', description: 'Não foi possivel encontrar a marca selecionada!' });
        }
    )
}

//#region salvar dados

function salvarManutencao(values) {
    if (!!values.mar_id) {
        editar(values);
    } else {
        incluir(values);
    }
}

function incluir(values) {
    //tratamento dos campos
    api.post(`Marca/Incluir`, values).then(
        res => {
            notification.success({ message: 'Sucesso!', description: 'Marca Incluida com Sucesso' });
        }
    ).catch(error => {
        console.log(error);
        notification.error({ message: 'Aviso!', description: 'Problema ao incluir marca!' });
    }).finally(() => {
        formTeste.resetFields();
    })
}

function editar(values) {
    api.put(`Marca/Editar`, values).then(
        res => {
            notification.success({ message: 'Sucesso!', description: 'Marca Incluida com Sucesso' });
        }
    ).catch(error => {
        console.log(error);
        notification.error({ message: 'Aviso!', description: 'Problema ao editar marca!' });
    }).finally(() => {
        formTeste.resetFields();
    })
}

//#endregion

return (
    <div>
        <Form onFinish={salvarManutencao} name="manutencaoTeste" form={formTeste} layout="Vertical">
            <Row gutter={[8, 10]}>
                <Col />
                <Col>
                    <Form.Item name="marcaId" label="Selecione uma marca: ">
                        <SelectPaginado placeholder="Selecione uma marca" url="Marca/Listar" name="marcaId" form={formTeste} onChangeFunction={(id) => setarFormulario(id)}
                            conteudo={
                                mar => (<Select.Option value={mar.mar_id} key={mar.key}>{mar.mar_nome}</Select.Option>)
                            } />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[8, 0]}>
                <Col />
                <Col >
                    <Form.Item name="mar_id" hidden />
                    <Form.Item label="Nome da Marca" name="mar_nome" rules={[{ required: true, message: 'Informe a Marca.' }]}>
                        <Input placeholder="Informe o nome da Marca" />
                    </Form.Item>
                </Col>
                <Col >
                    <Form.Item name="mar_ativa" valuePropName="checked">
                        <Checkbox>Marca Ativa</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[8, 0]}>
                <Col>
                    <Button onClick={formTeste.submit} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                        Salvar
                    </Button>
                </Col>
            </Row>
        </Form>
    </div>
)
}