import React, { useState, useEffect } from "react";
import { useStateValue } from "../../state";
import { Button, Col, Divider, Form, Input, Menu, Row, Select, Table, Typography, notification, Modal } from "antd";
import { DrawerGrupoCat, SelectPaginacao } from "../../components";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import api from "../../services/api";
import GrupoCatalogo from "../grupoCatalogo";


export default function ManutencaoCatalogo({ formulario, listaGrupos, carregando, aoSalvar }) {
    const [{ manutencao }, dispatch] = useStateValue();
    const [openDrawerGrp, setOpenDrawerGrp] = useState(false);
    const [listaSubGrupo, setListaSubGrupo] = useState([]);
    const [editandoGrp, setEditandoGrp] = useState(false);
    const [sequencialGrp, setSequencialGrp] = useState(0);

    useEffect(() => {
        BuscarLinhas();
    }, []);

    const salvarManutencao = () => {
        aoSalvar();
    }

    const Editar = (sequenciaGrp) => {
        setEditandoGrp(true);
        setSequencialGrp(sequenciaGrp)
        setOpenDrawerGrp(true);
    }

    const modalExcluir = (record) => {
        const index = listaGrupos.listaGrupos.findIndex(item => item.Sequencia === record.Sequencia);
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja realmente excluir o grupo?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                Excluir(index);
            }
        })
    }

    const Excluir = (index) => {
        const novaListaGrupos = [...listaGrupos.listaGrupos];
        novaListaGrupos.splice(index, 1);

        novaListaGrupos.forEach((item, index) => {
            item.Sequencia = index + 1;
        });

        listaGrupos.setListaGrupos(novaListaGrupos);

    }

    const Incluir = () => {
        if (!!!formulario.getFieldValue().Codigo) {
            notification.warn({ description: "Informe o código do catálogo!", message: "Aviso" });
            return;
        }
        setOpenDrawerGrp(true);
    }

    function BuscarLinhas() {
        api.get("Cat005/GetAll?empresa=1").then(
            res => {
                if (!!res.data) {
                    setListaSubGrupo(res.data);
                }
            }
        ).catch(
            err => {
                console.log(err);
            }
        );
    }

    const aoSalvarGrupos = () => {
        setOpenDrawerGrp(false);
        setEditandoGrp(false);
    }
    const validarCodCat = (rules, value, callback) => {
        if (value && value.length > 12) {
            notification.warning({ description: 'Código do catálogo deve ser menor que 13 digitos!', message: 'Aviso!' });
            formulario.setFieldsValue({ "Codigo": value.substring(0, 12) });
        } else {
            callback();
        }
    }

    return (
        <Form layout="vertical" form={formulario} name="manutencaoCatalogo" onFinish={salvarManutencao}>
            <Row gutter={[8, 0]}>
                <Col span={12}>
                    <Form.Item label={"Código"} name={"Codigo"} rules={[{ required: true, message: 'Informe o Código do Catálogo' }, { validator: validarCodCat }]}>
                        <Input placeholder="Informe o código" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Descrição" name={"Descricao"} rules={[{ required: true, message: 'Informe a Descrição do Catálogo' }]}>
                        <Input placeholder="Informe a descrição" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[8, 0]}>
                <Col span={12}>
                    <Form.Item name="CodLinha" label="Linha do Catálogo" rules={[{ required: true, message: 'Informe a Linha do Catálogo' }]}>
                        <Select placeholder="Selecione a linha">
                            {listaSubGrupo.map((sub, idx) => (
                                <Select.Option value={sub.codLinha} key={idx}>{sub.descLinha}</Select.Option>)
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Caminho da Imagem" name={"CaminhoImg"}>
                        <Input placeholder="Informe o caminho da imagem" />
                    </Form.Item>
                </Col>
                <Form.Item name={"Controle"} hidden>

                </Form.Item>
            </Row>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img hidden src={require("../../assets/i-endereco.png").default} alt="Grupos" />
                        Grupos
                    </Typography.Title>
                    <Divider orientation="left" plain> Adicione o(s) grupo(s) do Catálogo aqui. </Divider>
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => Incluir()} />
                    </div>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Table dataSource={listaGrupos.listaGrupos} columns={[
                    {
                        title: "Código",
                        align: "center",
                        render: ({ Codigo }) => (
                            <b>{Codigo}</b>
                        ),
                    }, {
                        title: "Descrição",
                        align: "center",
                        render: ({ Descricao }) => (
                            <b>{Descricao}</b>
                        ),
                    }, {
                        title: "Sequencia",
                        align: "center",
                        render: ({ Sequencia }) => (
                            <b>{Sequencia}</b>
                        ),
                    }, {
                        title: "Sub-Grupo",
                        align: "center",
                        render: ({ SubGrupo }) => (
                            <b>{SubGrupo.Codigo}</b>
                        ),
                    }, {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        fixed: 'right',
                        width: 65,
                        render: (record) => (
                            <Menu>
                                <Menu.Item key="1">
                                    <Button type="text" icon={<EditOutlined />} onClick={() => Editar(record.Sequencia)} >Editar</Button>
                                </Menu.Item>
                                <Menu.Item key="2">
                                    <Button type="text" icon={<DeleteOutlined />} onClick={() => modalExcluir(record)}> Excluir </Button>
                                </Menu.Item>
                            </Menu>
                        )
                    }
                ]} />
            </div>
            <DrawerGrupoCat formCatalogo={formulario} openDrawer={openDrawerGrp} setOpenDrawer={setOpenDrawerGrp} listaGrupos={listaGrupos}
                editandoGrp={editandoGrp} sequenciaGrp={sequencialGrp} aoSalvarGrupos={aoSalvarGrupos} />
        </Form>
    )
}