import React, { useEffect, useState } from "react";
import { Row, Col, Form, Typography, Divider, InputNumber, Table, Select, Tag, Dropdown, Menu, Button, Input, notification, Modal } from "antd";
import { MoreOutlined, DeleteOutlined, PlusOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from "../../state";
import DrawerProduto from "./drawerProduto";
import { InputPreco, SelectProduto } from "../../components";
import { FormatNumber } from "../../ValueObjects";
import { drawerActions, manutencaoActions } from "../../actions";
import { isNotNullOrEmpty, isObjetoDiffVazio, HasValue } from "../../services/funcoes";
import { ModalGradeProdutoImportacao } from "../../components/modals";
import ModalSelecionarGrade from "../../components/modals/modalSelecionarGrade";

export default function TabItens({ dadosRemetente, dadosItens }) {

    const [{ manutencao, listaSelectPaginacao }, dispatch] = useStateValue();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [listaProduto, setListaProduto] = useState([]);
    const [listaProdutosSistema, setListaProdutosSistema] = useState([]);
    const [produtos, setProdutos] = useState([]);
    const [form] = Form.useForm();
    const [keyProdutoCadastrado, setKeyProdutoCadastrado] = useState(null);
    const [atualizarProduto, setAtualizarProduto] = useState(false);
    const [aguardandoPopular, setAguardandoPopular] = useState(false);
    const [filtroProd, setFiltroProd] = useState("");
    const [exibirModalSelecGrade, setExibirModalSelecGrade] = useState(false);
    const [dadosModalGrade, setDadosModalGrade] = useState({});
    const [editando, setEditando] = useState(true);
    const [ativarMarkup, setAtivarMarkup] = useState();
    const [parametrosEmpresa, setParametrosEmpresa] = useState({});
    const [dadosGrade, setDadosGrade] = useState([]);
    const [abrirModalGrade, setAbrirModalGrade] = useState(false);
    const [possuiGrade, setPossuiGrade] = useState(false);

    const configSelecionarItem =
    {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            ///criar quantidade setando
            setSelectedRowKeys(selectedRowKeys);
        }
    };

    useEffect(() => {
        api.get('/ParametroEmpresa/BuscarParametros').then(res => {
            if (res.data.par_calculaprecovenda == true) {
                setAtivarMarkup(true);
                setParametrosEmpresa(true);
            } else {
                setAtivarMarkup(false);
                setParametrosEmpresa(false);
            }
        }).catch((erro) => console.error(erro));
        setProdutos(dadosItens.dadosItens);
        buscarProdutosNotaEntrada();
    }, []);

    useEffect(() => {
        if (!!produtos && produtos.length > 0 && !isNotNullOrEmpty(produtos[0].key)) {
            let id = 0;
            let dados = [...produtos];
            let listaItemSis = [];
            dados.forEach(async (produto) => {
                produto.key = id;
                let dadosItensSistema = produto.dadosItensSistema.length ? JSON.parse(produto.dadosItensSistema) : [];
                if (dadosItensSistema.length > 0) {
                    let itemSis = dadosItensSistema;
                    let seq = 0;
                    itemSis.forEach(async item => {
                        item.seqPai = id;
                        item.key = seq;
                        item.ativarDisabled = true;
                        item.nfi_custoinformado = produto.xValorUnitarioProduto / itemSis.length;

                        let ParMarkup = 1;
                        let filtro = (item.pro_id ? `&IdProduto=${item.pro_id}` : '');
                        filtro += (!!item.prg_ean ? `&ean=${item.prg_ean}` : (!!produto.pro_ean ? `&ean=${produto.pro_ean}` : ''));
                        filtro += (!!item.prg_id ? `&grade=${item.prg_id}` : '');
                        api.get(`Produto/MarkupProduto?${filtro}`).then(
                            res => {
                                let parametro = res.data;
                                item.pro_markup = parametro.pro_markup;
                            })
                        item.valorVenda = ((produto.xValorUnitarioProduto / itemSis.length) * ParMarkup).toFixed(2);
                        seq++;
                        listaItemSis.push(item);
                    });
                    form.setFieldsValue({ [`qtdItem${id}`]: produto.xQuantidade });
                    form.setFieldsValue({ [`valorUnitario${id}`]: produto.xValorUnitarioProduto / itemSis.length });
                } else {
                }
                id++;
            });
            setListaProdutosSistema(listaItemSis)
            setProdutos(dados);
        }
    }, [produtos]);

    useEffect(() => {
        if (!!listaProdutosSistema && listaProdutosSistema.length > 0) {
            setAtualizarProduto(true);
            let produtosSistema = [...listaProdutosSistema];
            let dados = [...produtos];
            let linhasSeleq = [];
            limparItensSistemas(dados, (dados) => {
                produtosSistema.forEach((item) => {
                    let prod = dados.find(prod => prod.key === item.seqPai);
                    if (!!prod) {
                        if (linhasSeleq.indexOf(item.seqPai) === -1) {
                            linhasSeleq.push(item.seqPai);
                        }
                        prod.dadosItensSistema.push(item);
                    }
                });
                setProdutos(dados);
                setSelectedRowKeys(linhasSeleq);
                buscarSobra(produtosSistema[produtosSistema.length - 1].seqPai);
            });
        } else {
        }
    }, [listaProdutosSistema]);

    useEffect(() => {
        if (!!aguardandoPopular && !!listaSelectPaginacao?.itens) {
            let dados = listaSelectPaginacao?.itens;
            let itens = [...listaProdutosSistema];
            let ultimorProd = itens[itens.length - 1].pro_id;
            let prod = dados.filter((item) => (item.pro_id === ultimorProd))[0];
            if (!!prod) {
                form.setFieldsValue({ [`produtoCodigoUN${keyProdutoCadastrado}`]: prod.key });
                setKeyProdutoCadastrado(null);
                setAguardandoPopular(false);
            }
            setFiltroProd("");
            setAtualizarProduto(true);
        }
    }, [listaSelectPaginacao]);

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.dadosGrade) {
            buscarDadosGradeModal();
        }
    }, [manutencao.dados]);

    function limparItensSistemas(dados, callback) {
        dados.forEach((itemXml) => {
            itemXml.dadosItensSistema = [];
        });
        if (!!callback) {
            callback(dados)
        }
    };

    function adicionarControleQtd(qtd, key) {
        let dados = [...produtos];
        let registroItem = dados.filter((item) => item.key === key)[0];
        let valorUni = 0;
        if (qtd > 0) {
            valorUni = registroItem.xValorTotalProduto / qtd;
            form.setFieldsValue({ [`valorUnitario${key}`]: parseFloat(valorUni.toFixed(6)) });
            form.setFieldsValue({ [`nfi_custoinformado${key}`]: parseFloat(valorUni.toFixed(6)) });
            form.setFieldsValue({ [`nfi_qtde${key}`]: parseFloat(qtd) });
        }
    };

    function abrirCadastroProduto(record) {
        let dadosProd = {};
        let dadosProdutoXml = {};
        if (!!!record.xEan || isNaN(record.xEan)) {
            dadosProdutoXml = produtos.filter((prod) => prod.cProd === record.cProd)[0];
        } else {
            dadosProdutoXml = produtos.filter((prod) => prod.xEan === record.xEan)[0];
        }
        dadosProd.pro_descricao = dadosProdutoXml.xProd;
        if (dadosProdutoXml.xEan !== 'SEM GTIN') {
            dadosProd.pro_ean = dadosProdutoXml.xEan;
        } else {
            dadosProd.pro_ean = null;
        }
        dadosProd.orm_id = !!dadosProdutoXml.xOrigem ? dadosProdutoXml.xOrigem + "" : "0";
        dadosProd.orm = dadosProdutoXml.xOrigem;
        dadosProd.ncm_id = dadosProdutoXml.ncm_id;
        dadosProd.ump_id = dadosProdutoXml.xUnidadeMedida;
        dadosProd.ump = dadosProdutoXml.xUnidadeMedida;
        dadosProd.pre_custoultimacompra = dadosProdutoXml.xValorUnitarioProduto;
        dadosProd.pro_markup = dadosProdutoXml.pro_markup;
        dadosProd.pro_servico = false;
        dadosProd.pro_kitcomposicao = false;
        dadosProd.pro_materiaprima = false;
        dadosProd.pro_ativo = true;
        dadosProd.pro_referencia = "";
        dadosProd.pro_modelo = "";
        dadosProd.pro_observacao = "";
        dadosProd.icm_id = null;
        dadosProd.pro_usabalanca = false;
        dadosProd.gru_id = null;
        dadosProd.sgp_id = null;
        dadosProd.mar_id = null;
        dadosProd.tpi_id = "00";
        dadosProd.listaPreco = [];
        dadosProd.tpi = { tpi_id: "00", tpi_descricao: "Mercadorias para Revenda" };
        dadosProd.csv_id = null;
        dadosProd.pis_id = null;
        if (!!dadosProdutoXml.nfs_valorissqn) {
            dadosProd.pro_servico = true;
            dadosProd.pro_produto = false;
        } else {
            dadosProd.pro_produto = true;
        }
        setEditando(true);
        setKeyProdutoCadastrado(record.key);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dadosProd } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function menuTable(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="2">
                    <Button type="text" icon={<PlusOutlined />} onClick={() => { abrirCadastroProduto(record) }}> Cadastrar Produto </Button>
                </Menu.Item>
            </Menu>
        )
    };

    function buscarProdutosNotaEntrada() {
        api.get(`Produto/ListaProdutoFiltroNfEntrada?filtro=&ordem=%2Bpro_descricao`).then(
            res => {
                let dados = [...res.data.items];
                let seq = 0;
                dados.forEach((item) => {
                    item.seqAuxiliar = seq;
                    seq++;
                });
                setListaProduto(dados);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function popularQuantidade(valor, key) {
        let itens = [...produtos];
        itens.find((produto) => (produto.key === key));
        itens.nfi_qtde = valor;
    };

    function popularCustoInformado(valor, key) {
        let itens = [...produtos];
        itens.find((produto) => (produto.key === key));
        itens.nfi_custoinformado = (parseFloat(valor)).toFixed(2);
    };

    async function buscarDadosGradeModal() {
        // Dados necessarios para adicionar em banco podem ser adicionados ha função 'buscarGradeProduto'
        let itensSistema = [...listaProdutosSistema];
        let produtoGrade = manutencao.dados.dadosGrade.filter((produto) => (produto.nfi_qtde > 0));
        let salvarGrades = [];
        produtoGrade.forEach(prodGrade => {
            let itens = listaSelectPaginacao?.itens;
            let prod = (itens.filter((item) => item.prg_id == prodGrade.prg_id))[0];
            form.setFieldsValue({
                [`qtdItem${prod.key}`]: prodGrade.nfi_qtde, [`nfi_custoinformado${prod.key}`]: prodGrade.nfi_custoinformado,
                [`produtoCodigo${prod.key}`]: prodGrade.pro_id, [`codigograde${prod.key}`]: prodGrade.prg_id,
            });
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, dadosGrade: null } } })
            //ajustar este campo para que leve os dados necessarios e salve a lista de grades no fornecedor produto
            let prodVincular = {};
            prodVincular.pro_id = prodGrade.pro_id;
            prodVincular.for_id = dadosRemetente[0].find(obj => obj.nome === 'for_id')?.valor;
            prodVincular.fpr_codigo = prodGrade.cProd;
            prodVincular.fpr_descricao = prodGrade.xProd;
            prodVincular.prg_id = prodGrade.prg_id;
            salvarGrades.push(prodVincular);
            //adiciona a lista de produtosgrade(listaProdutosSistema)
            let dados = {};
            dados.key = prodGrade.key;
            dados.nfi_custoinformado = prodGrade.nfi_custoinformado;
            dados.prg_id = prodGrade.prg_id;
            dados.nfi_qtde = prodGrade.nfi_qtde;
            dados.par_calculaprecovenda = parametrosEmpresa;
            dados.seqPai = prodGrade.seqPai;
            dados.pro_id = prodGrade.pro_id;
            dados.pro_codigo = prodGrade.pro_codigo;
            dados.descricaoGrade = prodGrade.descricaoGrade;
            dados.pro_descricao = prodGrade.pro_descricao;
            dados.ativarDisabled = true;
            if (parametrosEmpresa == true) {
                dados.pro_markup = prodGrade.pro_markup;
                dados.valorVenda = (prodGrade.nfi_custoinformado * prodGrade.pro_markup).toFixed(2)
            }
            else (
                dados.valorVenda = prodGrade.nfi_custoinformado
            );
            itensSistema.push(dados);
        }
        );
        setListaProdutosSistema(itensSistema);
        await api.post(`FornecedorProduto/IncluirLista`, salvarGrades);
    }

    async function buscarGradeProduto(keyGrade, cProd, keyLista) {
        if (!!listaSelectPaginacao?.itens) {
            let dados = [...listaSelectPaginacao?.itens];
            let item = dados.filter((item) => item.key === keyGrade)[0];
            let produtoXml = produtos.filter((pro) => pro.key === keyLista)[0];
            if (!!item?.pro_id && !!item?.prg_id) {
                let grade = await api.get(`ProdutoGrade/ListarGradesProdutos?codigoProduto=${item.pro_id}`);
                if (grade.data.length >= 2) {
                    setPossuiGrade(true);
                    grade.data.forEach(async (pro) => {
                        let buscaGrade = dados.filter((item) => item.prg_id == pro.prg_id)[0];
                        pro.nfi_custoinformado = form.getFieldValue()[`nfi_custoinformado${keyLista}`];
                        pro.seqPai = produtoXml.key;
                        pro.pro_descricao = item.pro_descricao;
                        pro.pro_codigo = item.pro_codigo;
                        pro.descricaoGrade = buscaGrade.descricaograde;
                        pro.xQuantidade = buscaGrade.quantidade;
                        pro.prg_id = pro.prg_id;
                        pro.pro_id = item.pro_id;
                        pro.key = keyLista;
                        pro.cProd = cProd;
                        pro.xProd = produtoXml.xProd;
                        pro.key = buscaGrade.key;
                        if (parametrosEmpresa == true) {
                            let ParMarkup = await buscaMarkup(buscaGrade);
                            pro.pro_markup = ParMarkup;
                        }
                    });
                    setAbrirModalGrade(true);
                    setDadosGrade(grade.data);
                } else {
                    incluirProdutoItem(keyLista, item.prg_id);
                }
            } else {
                incluirProdutoItem(keyLista, item.prg_id);
            }
        }
    };

    function incluirProdutoItem(key, IdGrade) {
        let itens = [...listaProdutosSistema];
        let dados = {};
        let qtde = form.getFieldValue()[`nfi_qtde${key}`];
        let custo = form.getFieldValue()[`nfi_custoinformado${key}`];
        let cprod = form.getFieldValue()[`produtoCodigo${key}`];
        let grade = !!form.getFieldValue()[`codigograde${key}`] ? form.getFieldValue()[`codigograde${key}`] : IdGrade;
        let valorTotal = parseFloat(produtos.filter((prod) => prod.key === key)[0].xValorTotalProduto);
        let dadosProduto = [];
        if (!(qtde > 0 && custo > 0)) {
            notification.warning({ description: "Favor informar o valor e a quantidade do item", message: "Aviso" });
            return;
        }
        if (!!!listaSelectPaginacao.itens) {
            setAtualizarProduto(true);
        }
        if (parseFloat((qtde * custo).toFixed(2)) > valorTotal) {
            notification.warning({ message: 'Aviso', description: 'Valor informado maior que o valor total do item!' });
            form.setFieldsValue({ [`valorUnitario${key}`]: parseFloat(valorTotal / qtde) });
            form.resetFields([`produtoCodigoUN${key}`, `codigogradeUN${key}`]);
            return;
        }
        if (!!grade) {
            dadosProduto = listaSelectPaginacao?.itens.filter((prod) => (prod.prg_id === IdGrade))[0];
        }
        else {
            dadosProduto = listaSelectPaginacao?.itens.filter((prod) => (prod.key === cprod))[0];
        }
        validarValorTot(custo, qtde, key, async () => {
            if (!!!dadosProduto || !isObjetoDiffVazio(dadosProduto)) {
                return;
            }
            let dadosItem = itens.find(produtoSis => (produtoSis.pro_id === dadosProduto.pro_id && produtoSis.prg_id === grade && produtoSis.seqPai === key));
            if (!!dadosItem) {
                notification.warning({ description: 'Lista já possuí o produto informado!', message: 'Aviso' });
            } else {
                let ParMarkup = 1;
                if (ativarMarkup == true) {
                    ParMarkup = await buscaMarkup(dadosProduto);
                    dados.pro_markup = ParMarkup;
                    if (!!parametrosEmpresa.par_calculaprecovenda != null && !!parametrosEmpresa.par_calculaprecovenda != false) {
                        if (ParMarkup <= 1) {
                            notification.warn({
                                description: (
                                    <div>
                                        <strong>
                                            Valor de Venda é o mesmo do valor de Compra.
                                            <br />
                                            Ajustar valor Markup ou Valor de Venda!</strong>
                                    </div>
                                ), message: (<div> <strong>Aviso</strong> </div>)
                            });
                        }
                    }
                }
                dados.valorVenda = (!!dadosProduto.valorVenda ? dadosProduto : (ParMarkup * custo)).toFixed(2);
                dados.key = !!itens && itens.length > 0 ? itens[itens.length - 1].key + 1 : 1;
                dados.nfi_custoinformado = custo;
                dados.prg_id = !!grade ? grade : null;
                dados.nfi_qtde = qtde;
                dados.par_calculaprecovenda = parametrosEmpresa.par_calculaprecovenda;
                dados.seqPai = key;
                dados.pro_id = dadosProduto.pro_id;
                dados.pro_codigo = dadosProduto.pro_codigo;
                dados.descricaoGrade = dadosProduto.descricaograde;
                dados.pro_descricao = dadosProduto.pro_descricao;
                dados.ump_descricao = dadosProduto.ump_id;
                dados.ump_casasdecimais = 2;
                dados.ativarDisabled = true;
                dados.qtdeAnterior = qtde;
                itens.push(dados);
                //vincula poroduto a nota fical na tabela fornecedorproduto
                vincularProdutoNota(dados);
                setListaProdutosSistema(itens);
                form.setFieldsValue({ [`produtoCodigo${key}`]: null, [`codigograde${key}`]: null });
            }
        });
    };

    async function vincularProdutoNota(produto) {
        let proKey = HasValue(keyProdutoCadastrado) ? keyProdutoCadastrado : produto.seqPai;
        let produtoXml = produtos.filter((pro) => pro.key === proKey)[0];
        let dados = { produto };
        dados.for_id = dadosRemetente[0].find(obj => obj.nome === 'for_id')?.valor;
        dados.pro_id = produto.pro_id;
        dados.fpr_codigo = produtoXml.cProd;
        dados.fpr_descricao = produtoXml.xProd;
        if (produto.prg_id != null || produto.prg_id != 0) {
            dados.prg_id = produto.prg_id;
        }
        await api.post(`FornecedorProduto/Incluir`, dados);
    };

    async function modalExcluir(record) {
        record.fpr_id = dadosRemetente[0].find(obj => obj.nome === 'for_id')?.valor;
        Modal.confirm({
            title: "Excluir?",
            content: `Deseja excluir o item ${record.pro_descricao} da nota?`,
            okText: `Excluir`,
            cancelText: `Não`,
            okType: "danger",
            centered: true,
            onOk() {
                excluirItem(record)
            }
        })
    };

    function excluirItem(record) {
        let itens = [...listaProdutosSistema];
        let dados = itens.filter(produto => produto.key !== record.key || produto.prg_id !== record.prg_id);
        setListaProdutosSistema(dados);
        setExibirModalSelecGrade(false);
        if(record.pro_descricao != null){
            excluirItemSistemaXml(record);
        }
        calculaSobraAoRemoverItem(record);
    };

    function excluirItemSistemaXml(record) {
        api.delete(`FornecedorProduto/Excluir/${record.fpr_id}`).then(
            res => {
                notification.success({ description: 'Item removido com sucesso!', message: "Aviso" });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function salvarUnidadeMedida(record, key) {
        let itens = [...produtos];
        let dados = itens.find(produto => (produto.key === key));
        dados.unidadeMedida = record.target.value;
        setProdutos(itens);
    };

    function calculaSobraAoRemoverItem(record) {
        let key = record.seqPai;
        let itens = [...listaProdutosSistema];
        let dados = itens.filter(produto => produto.seqPai == record.seqPai)

        let custoInformadoAtual = parseFloat(form.getFieldsValue()[`nfi_custoinformado${key}`]);
        let qtdeInformadoAtual = parseFloat(form.getFieldsValue()[`nfi_qtde${key}`]);
        if (isNaN(custoInformadoAtual)) custoInformadoAtual = 0;
        if (isNaN(qtdeInformadoAtual)) qtdeInformadoAtual = 0;

        let custo = custoInformadoAtual + parseFloat(record.nfi_custoinformado);
        let qtde = qtdeInformadoAtual + record.nfi_qtde;

        form.setFieldsValue({ [`nfi_custoinformado${key}`]: custo });
        form.setFieldsValue({ [`nfi_qtde${key}`]: qtde });
        let valorRetorno = 0;
        let itensXml = [...produtos];
        let dadosProduto = itensXml.find((item) => (item.key === key));
        valorRetorno = parseFloat((dadosProduto.xValorTotalProduto - parseFloat(custo * qtde)).toFixed(3));
    };

    function buscarSobra(key, listaProd = []) {
        let itensXml = [...produtos];
        let itensSistema = [];
        if (listaProd.length > 0) {
            itensSistema = [...listaProd];
        } else {
            itensSistema = [...listaProdutosSistema];
        }
        let valor = 0;
        let qtde = 0;
        let valorRetorno = 0;
        let qtdTotal = parseInt(form.getFieldValue()[`qtdItem${key}`]);
        let dadosItem = itensSistema.filter((item) => (item.seqPai === key));
        let dadosProduto = itensXml.find((item) => (item.key === key));
        if (isNaN(qtdTotal)) qtdTotal = 0;
        if (!!dadosProduto) {
            if (!!dadosItem) {
                dadosItem.forEach((item) => {
                    valor += parseFloat((parseFloat(item.nfi_custoinformado) * parseFloat(item.nfi_qtde)).toFixed(3));
                    qtde += parseFloat(item.nfi_qtde);
                });
            } else {
                valor = parseFloat(form.getFieldValue()[`nfi_custoinformado${key}`]);
            }
            valorRetorno = parseFloat((dadosProduto.xValorTotalProduto - parseFloat(valor.toFixed(2))).toFixed(3));
            let valorRestante = valorRetorno;
            let qdteRestante = parseFloat((qtdTotal - parseFloat(qtde.toFixed(2))).toFixed(3));
            if (qdteRestante > 0) {
                valorRestante = parseFloat(valorRetorno / qdteRestante).toFixed(2);
                setSelectedRowKeys(selectedRowKeys);
            }
            form.setFieldsValue({ [`nfi_custoinformado${key}`]: valorRestante });
            form.setFieldsValue({ [`nfi_qtde${key}`]: qdteRestante });
        }
        return valorRetorno;
    };

    function validarValorTot(custo, qtd, key, callback) {
        let itens = [...listaProdutosSistema];
        let itemXml = produtos.filter((prod) => prod.key === key)[0];
        let valorItens = 0;
        let qtdItens = 0;
        let qtdTotal = parseInt(form.getFieldValue()[`qtdItem${key}`]);
        itens.forEach(item => {
            if (item.seqPai === key) {
                valorItens += parseFloat(item.nfi_custoinformado) * parseInt(item.nfi_qtde);
                qtdItens += parseInt(item.nfi_qtde);
            }
        });
        valorItens += (parseFloat(qtd) * parseFloat(custo));
        if (!!itemXml) {
            if (parseFloat(parseFloat(qtd) * parseFloat(custo)).toFixed(2) > parseFloat(itemXml.xValorTotalProduto)) {
                notification.warning({ message: 'Aviso', description: 'Valor informado maior que o valor total do item!' });
                form.setFieldsValue({ [`nfi_custoinformado${key}`]: parseFloat(itemXml.xValorTotalProduto) / parseFloat(qtd) })
            } else if (parseFloat(valorItens).toFixed(2) > parseFloat(itemXml.xValorTotalProduto)) {
                notification.warning({ message: 'Aviso', description: 'A soma dos valores ultrapassa o valor total do item!' });
            } else if (parseInt(qtd) + qtdItens > qtdTotal) {
                notification.warning({ message: 'Aviso', description: 'Quantidade informada maior que quantidade total do item!' });
            } else {
                if (!!callback) {
                    callback();
                }
            }
        }
    };

    async function buscaMarkup(produto) {
        let filtro = (produto.pro_id ? `&IdProduto=${produto.pro_id}` : '');
        filtro += (!!produto.prg_id ? `&grade=${produto.prg_id}` : '');
        let Valor = (await api.get(`Produto/MarkupProduto?${filtro}`)).data;
        return Valor.pro_markup;
    }

    async function aoFecharDrawerProduto(produto) {
        if (!!produto && !!produto.pro_id) {
            let itens = [...listaProdutosSistema];
            setAtualizarProduto(true);
            let dados = {};
            dados.key = 1;
            dados.nfi_custoinformado = form.getFieldValue()[`valorUnitario${keyProdutoCadastrado}`];
            dados.prg_id = null;
            dados.nfi_qtde = form.getFieldValue()[`qtdItem${keyProdutoCadastrado}`];
            dados.valorVenda = produto.valorVenda;
            dados.par_calculaprecovenda = parametrosEmpresa.par_calculaprecovenda;
            dados.seqPai = keyProdutoCadastrado;
            dados.pro_codigo = produto.pro_codigo;
            dados.pro_id = produto.pro_id;
            dados.pro_markup = produto.pro_markup;
            dados.pro_descricao = produto.pro_descricao;
            dados.ump_descricao = produto.ump_id;
            dados.ump_casasdecimais = 2;
            dados.ativarDisabled = true;
            let grades = await api.get(`ProdutoGrade/RetornaGradesProduto?produtoId=${produto.pro_id}`); 
            if (!!produto.produtogrades && produto.produtogrades.length > 0 && grades.data.length >= 1) {
                dados.grades = [...grades.data];
                let seq = 0;
                dados.grades.forEach((grade) => { grade.key = seq++; grade.qtdeItem = 1 });
                setDadosModalGrade(dados);
                setExibirModalSelecGrade(true);
            } else {
                let itemExiste = itens.filter((obj) => (obj.pro_id === produto.pro_id));
                if (itemExiste.length == 0)
                    itens.push(dados);
                vincularProdutoNota(produto);
                setAguardandoPopular(true);
                setFiltroProd(produto.pro_codigo);
                setListaProdutosSistema(itens);
            }
        }
    };

    function validarValorItem(key) {
        let dados = [...produtos];
        let produto = dados.find((obj) => (obj.key === key));
        let valor = parseFloat(form.getFieldValue()[`valorUnitario${key}`]);
        let qtd = parseFloat(form.getFieldValue()[`qtdItem${key}`]);
        let valorTotal = parseFloat((valor * qtd).toFixed(2));
        if (produto.xValorTotalProduto < valorTotal) {
            notification.warn({ description: 'Valor informado é maior que o total!', message: 'Aviso' });
            form.setFieldsValue({ [`valorUnitario${key}`]: parseFloat((produto.xValorTotalProduto / qtd).toFixed(3)) });
        }
    };

    function modalEditar(record) {
        let prod = [...produtos];
        let itens = [...listaProdutosSistema];
        let produtoSelecionado = itens.find((p) => { return p.seqPai === record.seqPai && p.pro_id === record.pro_id && p.prg_id === record.prg_id });
        let itensProduto = itens.filter((prod) => { return prod.seqPai === record.seqPai })
        let valorItemSelecionado = form.getFieldValue()[`valorUnitario${record.seqPai}`];
        let qtdTotal = parseInt(form.getFieldValue()[`qtdItem${record.seqPai}`]);
        let qtdItens = 0;
        itensProduto.forEach((it) => {
            qtdItens += parseInt(it.nfi_qtde);
        });
        if (!produtoSelecionado.ativarDisabled) {
            if (!(record.nfi_qtde > 0)) {
                notification.warning({ description: "Favor informar a quantidade do item!", message: "Aviso" });
                return;
            } else
                if (qtdItens > qtdTotal) {
                    notification.warning({ message: 'Aviso', description: 'Quantidade informada maior que quantidade total do item!' });
                    return;
                } else {
                    produtoSelecionado.ativarDisabled = true;
                    produtoSelecionado.nfi_custoinformado = valorItemSelecionado; //parseFloat(valorItemSelecionado) / parseFloat(produtoSelecionado.nfi_qtde);
                    produtoSelecionado.qtdeAnterior = produtoSelecionado.nfi_qtde;
                    setProdutos(prod);
                }
        } else {
            produtoSelecionado.ativarDisabled = false;
            setProdutos(prod);
        }
        buscarSobra(record.seqPai);
    };

    function alterarQtde(e, record) {
        let itens = [...listaProdutosSistema];
        let dados = itens.find(p => (p.seqPai === record.seqPai && p.pro_id === record.pro_id && p.prg_id === record.prg_id));
        dados.nfi_qtde = e.target.value;
        setListaProdutosSistema(itens);
    }

    function alterarMarkup(e, record) {
        let itens = [...listaProdutosSistema];
        let dados = itens.find(p => (p.seqPai === record.seqPai && p.pro_id === record.pro_id && p.prg_id === record.prg_id));
        dados.pro_markup = (e.target.value).replace(",", ".")
        dados.valorVenda = parseFloat((dados.nfi_custoinformado * (dados.pro_markup).replace(",", "."))).toFixed(2);
        setListaProdutosSistema(itens);
    }

    function valorVendas(e, record) {
        let itens = [...listaProdutosSistema];
        let dados = itens.find(p => (p.seqPai === record.seqPai && p.pro_id === record.pro_id && p.prg_id === record.prg_id));
        dados.valorVenda = parseFloat((e.target.value)).toFixed(2);
        dados.pro_markup = parseFloat((parseFloat(e.target.value) / dados.nfi_custoinformado)).toFixed(2);
        setListaProdutosSistema(itens);
    }

    async function onCloseModalGrade(record) {
        let itens = [...listaProdutosSistema];
        let produto = dadosModalGrade;
        let item = {};
        for (let i = 0; i < record.length; i++) {
            item = record[i];
            let dados = {};
            dados.key = i;
            dados.nfi_custoinformado = item.pre_custoultimacompra;
            dados.prg_id = item.prg_id;
            dados.nfi_qtde = record.qtdeItem;
            dados.valorVenda = record.valorVenda;
            dados.par_calculaprecovenda = parametrosEmpresa.par_calculaprecovenda;
            dados.seqPai = keyProdutoCadastrado;
            dados.pro_codigo = produto.pro_codigo;
            dados.pro_id = produto.pro_id;
            dados.pro_markup = produto.pro_markup;
            dados.pro_descricao = produto.pro_descricao;
            dados.ump_descricao = produto.ump_descricao;
            dados.ump_casasdecimais = 2;
            dados.descricaoGrade = item.descricaoGrade;
            itens.push(dados);
            await vincularProdutoNota({ pro_id: produto.pro_id, pro_descricao: produto.pro_descricao, prg_id: item.prg_id });
        };
        setListaProdutosSistema(itens);
        setExibirModalSelecGrade(false);
    };

    return (
        <div>
            <Row gutter={[8, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-produto.png").default} alt="Itens da Nota" /> Itens da Nota
                    </Typography.Title>
                    <Divider orientation="left" />
                </Col>
                <Col span={24} className="tabela ver-aling">
                    <Form name="FormularioItem" form={form} >
                        <Table onRow={(record, rowIndex) => {
                            return {
                                onMouseLeave: event => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                },
                                onMouseEnter: event => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                },
                            };
                        }} columns={[
                            {
                                title: 'Itens NF-e',
                                render: ({ cProd, xProd, xEan, key, xQuantidade, xUnidadeMedida, xValorUnitarioProduto, listaGrade, ativarDisabled }) => (
                                    <div className="m-t-3">
                                        <Row gutter={[8, 0]}>
                                            <Col>
                                                <Tag color="processing">
                                                    <b>{cProd}</b>
                                                </Tag>
                                            </Col>
                                            <Col>
                                                <b>{xProd}</b>
                                            </Col>
                                            <Col>
                                                <b>{xEan}</b>
                                            </Col>
                                            <Col span={24}>
                                                <Row gutter={[8, 0]} align="middle">
                                                    <Col xs={12} sm={12} md={6} lg={6} xl={8} xxl={4}>
                                                        Quant. para controle de estoque:
                                                    </Col>
                                                    <Col xs={12} sm={8} md={6} lg={5} xl={5} xxl={5}>
                                                        <Form.Item name={`qtdItem${key}`} initialValue={xQuantidade} label="Quantidade">
                                                            <InputNumber placeholder="" step={1} min={0} onChange={(record) => { adicionarControleQtd(record, key) }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={12} sm={8} md={6} lg={4} xl={4} xxl={4}>
                                                        <Form.Item name={`unidadeMedida${key}`} initialValue={xUnidadeMedida} label="Un. Medida">
                                                            <Input placeholder="" onBlur={(record) => { salvarUnidadeMedida(record, key); }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={24} sm={8} md={6} lg={5} xl={5} xxl={5}>
                                                        <InputPreco name={`valorUnitario${key}`} initialValue={xValorUnitarioProduto} label="Vlr Unit. (R$)" onBlur={() => { validarValorItem(key) }} />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                <Row gutter={[8, 0]}>
                                                    <Col xs={12} sm={6} md={4} lg={4} xl={3} xxl={2}>
                                                        <Form.Item label="Quantidade" name={`nfi_qtde${key}`} initialValue={xQuantidade}>
                                                            <InputNumber placeholder="" step={1} min={0} onChange={(dado) => { popularQuantidade(dado, key) }} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={12} sm={6} md={6} lg={6} xl={5} xxl={3}>
                                                        <InputPreco name={`nfi_custoinformado${key}`} initialValue={xValorUnitarioProduto} label="Custo Informado (R$)" onChange={(dado) => { popularCustoInformado(dado, key) }} />
                                                    </Col>
                                                    <Col xs={24} sm={13} md={13} lg={15} xl={14} xxl={13}>
                                                        <Form.Item label="Produto" name={`produtoCodigo${key}`}>
                                                            <SelectProduto form={form} allowClear={true} name={`produtoCodigo${key}`} valueKey={true} onChange={(record) => { buscarGradeProduto(record, cProd, key); }} ordem={'pro_id'} detalhes={true} registrosPorPagina={10} />
                                                        </Form.Item>
                                                        <Form.Item hidden label="Código grade" name="prg_id"></Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24} align="right">
                                                <div className="tabelaNfe">
                                                    {
                                                        !!listaProdutosSistema && listaProdutosSistema.filter((record) => { return record.seqPai === key }).length > 0 &&
                                                        <Table columns={
                                                            [{
                                                                title: 'Codigo',
                                                                align: 'center',
                                                                width: 80,
                                                                render: ({ pro_codigo }) => (
                                                                    <Tag color="processing" className="w-100">
                                                                        <b>{pro_codigo}</b>
                                                                    </Tag>
                                                                )
                                                            },
                                                            {
                                                                title: 'Produto',
                                                                render: ({ pro_descricao }) => (
                                                                    <div>
                                                                        <b>{pro_descricao}</b>
                                                                    </div>
                                                                )
                                                            },
                                                            {
                                                                title: 'Grade',
                                                                render: ({ descricaoGrade }) => (
                                                                    <div>
                                                                        <b>{descricaoGrade}</b>
                                                                    </div>
                                                                )
                                                            },
                                                            {
                                                                title: 'Custo Inf.(R$)',
                                                                align: 'right',
                                                                width: 50,
                                                                render: ({ nfi_custoinformado }) => (
                                                                    <div>
                                                                        <b>{FormatNumber(nfi_custoinformado, true, false, 2)}</b>
                                                                    </div>
                                                                )
                                                            },
                                                            {
                                                                title: 'Quant.',
                                                                align: 'center',
                                                                width: 50,
                                                                render: ({ nfi_qtde, ativarDisabled }, record) => (
                                                                    <InputNumber value={nfi_qtde} onBlur={(e) => alterarQtde(e, record)} disabled={ativarDisabled} placeholder="" />
                                                                )
                                                            },
                                                            ativarMarkup ? {
                                                                title: 'Markup',
                                                                align: 'right',
                                                                width: 60,
                                                                render: ({ pro_markup, ativarDisabled }, record) => (
                                                                    <InputNumber value={FormatNumber(pro_markup,2)}
                                                                        onBlur={(e) => alterarMarkup(e, record)}
                                                                        disabled={ativarDisabled}
                                                                        placeholder="" />
                                                                )
                                                            } : {},
                                                            ativarMarkup ? {
                                                                title: 'Vlr. Venda(R$)',
                                                                align: 'center',
                                                                width: 80,
                                                                render: ({ valorVenda, ativarDisabled }, record) => (
                                                                    <InputNumber value={FormatNumber(valorVenda,2)}
                                                                        onBlur={(v) => valorVendas(v, record)}
                                                                        disabled={ativarDisabled}
                                                                        placeholder="" />
                                                                )
                                                            } : {},
                                                            {
                                                                title: 'Ações',
                                                                dataIndex: '',
                                                                align: 'center',
                                                                key: 'x',
                                                                width: 80,
                                                                render: (record) => (
                                                                    <div>
                                                                        <Row align="right" justify="center" gutter={[5, 0]}>
                                                                            <Col>
                                                                                <Button onClick={() => { modalEditar(record) }} icon={record.ativarDisabled ? <EditOutlined /> : <SaveOutlined />} />
                                                                            </Col>
                                                                            <Col>
                                                                                <Button onClick={() => { modalExcluir(record) }} icon={<DeleteOutlined />} />
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                ),
                                                            }]
                                                        } dataSource={!!listaProdutosSistema && listaProdutosSistema.filter((record) => { return record.seqPai === key }).length > 0 ? listaProdutosSistema.filter((record) => { return record.seqPai === key }) : []} />}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                            {
                                title: 'Quant.',
                                align: 'center',
                                width: 75,
                                render: ({ xQuantidade, xUnidadeMedida }) => (
                                    <div>
                                        <b>{xQuantidade} {xUnidadeMedida}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr. Unit. (R$)',
                                align: 'right',
                                width: 90,
                                render: ({ xValorUnitarioProduto }) => (
                                    <div>
                                        <b>{FormatNumber(xValorUnitarioProduto, true, false, 2)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Valor Total (R$)',
                                align: 'right',
                                width: 110,
                                render: ({ xValorTotalProduto }) => (
                                    <div>
                                        <b>{FormatNumber(xValorTotalProduto, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                align: 'center',
                                fixed: 'right',
                                key: 'x',
                                width: 60,
                                render: (record) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                                    <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]} scroll={{ x: 1000 }} rowSelection={configSelecionarItem}
                            dataSource={produtos} />
                    </Form>
                </Col>
            </Row>
            <DrawerProduto callback={aoFecharDrawerProduto} editando={{ editando, setEditando }} />
            <ModalSelecionarGrade dadosProduto={dadosModalGrade} exibirModal={exibirModalSelecGrade} onCloseModal={(record) => { onCloseModalGrade(record) }} />
            <ModalGradeProdutoImportacao exibirModal={abrirModalGrade} fecharModal={() => { setAbrirModalGrade(false); }} dados={dadosGrade} acrescimoDesconto={false} />
        </div >
    );
}