import React, { useState, useEffect } from 'react';
import { Row, Col, Form, message, Empty, Select, Input, Button, InputNumber, Checkbox, Switch } from 'antd';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { PlusOutlined, CloseOutlined, EyeTwoTone } from '@ant-design/icons';

import api from '../../services/api';
import { ItemFormulario, TiposElementosFormulario } from './pages';
import { ModalPersonalizacaoFormulario } from "../../components/modals/";

export default function EditorFormulario({elementosFormulario, setElementosFormulario}) {

    const objElementoFormularioEdicao = { indice: null, elemento: null };

    const [ formEditor ] = Form.useForm();
    const [ openModalPersonalizacao, setOpenModalPersonalizacao ] = useState(false);
    const [ elementoEdicao, setElementoEdicao ] = useState(objElementoFormularioEdicao);

    useEffect(() => formEditor.resetFields(), [elementoEdicao]);

    const adicionarElemento = values => {
        if (values.widget === "upload") {
            values.valuePropName = "file";
            values.children = "Clique aqui para anexar um arquivo..."
            values.widgetProps = {
                action: `${api.defaults.baseURL}/arquivos/upload`,
                headers: {
                    Authorization: 'Bearer ' + eval('localStorage.getItem("@Aflow-Token")')
                }
            };
        }
        console.log('elementos', [...elementosFormulario, values]);
        if (elementoEdicao.indice === null) setElementosFormulario([...elementosFormulario, values]);
        else {
            let listaElementos = [...elementosFormulario];
            listaElementos[elementoEdicao.indice] = values;
            setElementosFormulario(listaElementos);
        }
        setElementoEdicao(objElementoFormularioEdicao);
        formEditor.resetFields();
    }

    const tratarErro = errorInfo => {
        errorInfo.errorFields.map((field) => field.errors.map((erro) => message.warning(erro, 2 * errorInfo.errorFields.length)));
    };

    const editarElemento = indiceElemento => {
        setElementoEdicao({ indice: indiceElemento, elemento: elementosFormulario[indiceElemento] });
    }

    const excluirElemento = indiceElemento => {
        let listaElementos = [...elementosFormulario];
        listaElementos.splice(indiceElemento, 1);
        setElementosFormulario(listaElementos);
        setElementoEdicao(objElementoFormularioEdicao);
        formEditor.resetFields();
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        let listaElementos = [...elementosFormulario];
        listaElementos = arrayMove(listaElementos, oldIndex, newIndex);
        setElementosFormulario(listaElementos);
        setElementoEdicao(objElementoFormularioEdicao);
        formEditor.resetFields();
    };

    const SortableItem = SortableElement(({value, sortIndex}) => <ItemFormulario elemento={value} fnEditar={() => editarElemento(sortIndex)} fnExcluir={() => excluirElemento(sortIndex)} />);

    const SortContainer = SortableContainer(({children}) => {
        return <div>{children}</div>;
    });

    return (
        <div className="hg-100">
            <div className="col-formulario-60">
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Row justify="space-between" gutter={[8, 8]}>
                            <Col>
                                <b>Estrutura do Formulário</b>
                            </Col>
                            <Col>
                                <Button icon={<EyeTwoTone />} onClick={() => setOpenModalPersonalizacao(true)}>
                                    Pré-Visualizar
                                </Button>
                            </Col>
                        </Row>
                        <div className="col-elementor">
                            <SortContainer onSortEnd={onSortEnd} shouldCancelStart={(e) => (e.target.tagName.toLowerCase() === "path" || e.target.tagName.toLowerCase() === "svg" || e.target.tagName.toLowerCase() === "button")}>
                                {elementosFormulario.map((elemento, indice) => 
                                    <Row className="m-b-5">
                                        <Col span={24}>
                                            <SortableItem index={indice} sortIndex={indice} value={elemento} key={`${elemento.key}-${indice}`} />
                                        </Col>
                                    </Row>
                                )}
                            </SortContainer>
                            </div>
                        {elementosFormulario.length === 0 && <Empty/>}
                    </Col>
                </Row>
            </div>
            <div className="col-formulario-40">
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Row gutter={[0, 8]}>
                            <Col>
                                <b>Adicionar Elementos ao Formulário</b>
                            </Col>
                        </Row>
                        <Form layout="vertical" form={formEditor} initialValues={elementoEdicao.elemento} onFinish={adicionarElemento} onFinishFailed={tratarErro}>
                            <Row gutter={[0, 8]}>
                                <Col span={24}>
                                    <Form.Item name="widget" label="Tipo de Elemento" rules={[{ required: true, message: 'Informe o tipo de elemento para o formulário!' }]}>
                                        <Select showSearch placeholder="Selecione o tipo de elemento" optionFilterProp="children" filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>
                                            {TiposElementosFormulario.map((tipoEl) => <Select.Option value={tipoEl.tipo}>{tipoEl.descricao}</Select.Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[0, 8]}>
                                <Col span={24}>
                                    <Form.Item name="required" valuePropName="checked">
                                        <Switch /> Obrigatório
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[0, 8]}>
                                <Col span={24}>
                                    <Form.Item name="label" label="Label" rules={[{ required: true, message: 'Informe o label/descrição do elemento no formulário!' }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[0, 8]}>
                                <Col span={24}>
                                    <Form.Item name="tooltip" label="Tooltip">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[0, 8]}>
                                <Col span={24}>
                                    <Form.Item name="key" label="Nome do Atributo" rules={[{ required: true, message: 'Informe o nome do atributo para o elemento!' }]} normalize={(value) => {
                                        return !!value.match(/[a-zA-Z0-9_.]/gm) ? value.match(/[a-zA-Z0-9_.]/gm).join('') : '';
                                    }}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[0, 8]}>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.widget !== currentValues.widget}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('widget') === 'radio-group' || getFieldValue('widget') === 'checkbox-group' || getFieldValue('widget') === 'select') ? (
                                            <Form.Item name="options" label="Opções" rules={[{ required: true }]} extra="Informe o texto da opção e pressione <ENTER>">
                                                <Select mode="tags" placeholder="Informe as opções do elemento" />
                                            </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[0, 8]}>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.widget !== currentValues.widget}>
                                        {({ getFieldValue }) => {
                                            let elemento = getFieldValue('widget');
                                            if (elemento === 'input') return <Form.Item name="initialValue" label="Valor Inicial"><Input /></Form.Item>;
                                            if (elemento === 'textarea') return <Form.Item name="initialValue" label="Valor Inicial"><Input.TextArea /></Form.Item>;
                                            if (elemento === 'number') return <Form.Item name="initialValue" label="Valor Inicial"><InputNumber /></Form.Item>;
                                            if (elemento === 'checkbox') return <Form.Item name="initialValue" label="Valor Inicial" valuePropName="checked"><Checkbox /></Form.Item>;
                                            if (elemento === 'switch') return <Form.Item name="initialValue" label="Valor Inicial" valuePropName="checked"><Switch /></Form.Item>;
                                            else return null;
                                        }}
                                    </Form.Item>
                                </Col>
                            </Row>                        
                            <Row justify="center" gutter={[5, 0]}>
                                <Col>
                                    <Button htmlType="submit" size="middle" type="primary" icon={<PlusOutlined />}>
                                        {elementoEdicao.indice === null ? "Adicionar Elemento" : "Alterar Elemento"}
                                    </Button>
                                </Col>
                                <Col>
                                    {elementoEdicao.indice !== null && 
                                        <Button size="middle" icon={<CloseOutlined />} onClick={() => setElementoEdicao(objElementoFormularioEdicao)} htmlType="button">
                                            Cancelar
                                        </Button>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
            <ModalPersonalizacaoFormulario exibirModalPersonalizacao={openModalPersonalizacao} fecharModalPersonalizacao={() => setOpenModalPersonalizacao(false)} elementosFormulario={elementosFormulario} setElementosFormulario={setElementosFormulario}/>
        </div>
    );

}