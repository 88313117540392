import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import { DrawerNav } from '../../components';
import ManutencaoProdutos from '../produto/manutencao';
import { drawerActions, manutencaoActions } from '../../actions';

export default function DrawerProduto({ callback, editando }) {

    const [formProduto] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    useEffect(() => {
        if (manutencao.dados !== null && !!editando.editando) {
            formProduto.setFieldsValue(manutencao.dados)
        } else {
            formProduto.setFieldsValue({ pro_id: null, pro_ativo: true, pro_produto: true });
        }
    }, [])

    function fecharDrawer(retornoDrawer) {
        if (!!callback) {
            callback(!!retornoDrawer ? retornoDrawer : {});
        }
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        formProduto.resetFields();
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <DrawerNav title="Cadastro Produto"
            width="70%"
            visible={ui.showDrawer}
            limparAoEditar={true}
            closeIcon={<MinusOutlined />}
            fecharDrawer={fecharDrawer}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formProduto.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoProdutos formulario={formProduto} nameForm="manutencaoProduto" salvandoDados={setCarregando} aoSalvar={fecharDrawer} validarAoIniciar={true} editando={editando} />
        </DrawerNav>
    )
}