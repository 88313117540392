import React from "react";
import { Row, Col, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function ModalMapa({exibirModalMapa, fecharModalMapa}){

    const onCloseModalLocal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            okType: 'danger',
            centered: true,
            onOk() {
                fecharModalMapa();
            }
        });
    };

    return(
        <Modal centered
            title="Mapa"
            visible={exibirModalMapa}
            onCancel={onCloseModalLocal}
            footer={null}
            maskClosable={false}
            destroyOnClose
        >
            <Row align="middle" gutter={[8,0]}>
                <Col span={24}>
                    MAPA
                </Col>   
            </Row>
        </Modal>
    );
}