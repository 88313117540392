
import React, { useState, useEffect } from "react";
import { Row, Col, Table } from "antd";

import { FormatNumber } from '../../../ValueObjects';
import { validarNumeros } from "../../../services/funcoes";

export default function TabFormaPagamento({ formaPagamento }) {

    const  [ valorFinal, setValorFinal] = useState();

    useEffect(() => {
        valortotal();
    }, [formaPagamento]);

    function valortotal (){
        let pagamento = [...formaPagamento];
        let dados = pagamento;
        dados.forEach((itens) =>{
        let desconto = validarNumeros((itens.orf_valortotal * itens.orf_percentualdesconto)/100);
        let acrescimo = validarNumeros((itens.orf_valortotal * itens.orf_percentualacrescimo)/100);
        let total = (validarNumeros(itens.orf_valortotal) + acrescimo) - desconto;

        itens.desconto = desconto;
        itens.acrescimo = acrescimo;
        itens.total = total;
        })
        setValorFinal(dados);
    }

    return (
        <div className="tabela modalTable" >
            <Table dataSource={valorFinal} 
            showHeader={true} 
            columns={[
                {
                    title: 'Forma de Pagamento',
                    render: ({ fpg_descricao, cpg_descricao }) => (
                        <div>
                            <b>{fpg_descricao} {!!cpg_descricao ? `/ ${cpg_descricao}` : ''}</b>
                        </div>
                    ),
                },
                {
                    title: 'Desconto',
                    align: 'center',
                    render: ({ orf_percentualdesconto }) => (
                        <div>
                            <b>{FormatNumber(orf_percentualdesconto, true)} %</b>
                        </div>
                    )
                },
                {
                    title: 'Acréscimo',
                    align: 'center',
                    render: ({ orf_percentualacrescimo }) => (
                        <div>
                            <b>{FormatNumber(orf_percentualacrescimo, true)} %</b>
                        </div>
                    )
                },
                {
                    title: 'Valor',
                    align: 'right',
                    render: ({ total }) => (
                        <div>
                            <b>R$ {FormatNumber(!!total ? total : 0, true)}</b>
                        </div>
                    )
                }
            ]} expandable={{
                expandedRowRender: ({ orf_observacao }) => (
                    <div>
                        <Row gutter={[8, 0]}>
                            <Col span={[8, 0]}>
                            <b>Obs.: </b> { !!orf_observacao ? orf_observacao : "Não Informado" }
                            </Col>
                        </Row>
                    </div>
                )
            }}
            />
        </div >
    );
}