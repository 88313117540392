import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Radio, Modal } from "antd";
import { SaveOutlined, CloseOutlined } from "@ant-design/icons";
import SelectPaginacao from "../../selects/selectPaginacao";
import { info } from "../../../services/funcoes";

export default function ModalAntecipacaoDevolucao({ form, exibirModal, cancelar, aoSalvar }) {

    const [selecionarContaCorrente, setSelecionarContaCorrente] = useState(false);

    useEffect(() => {
        if (exibirModal) {
            form.setFieldsValue({ antecipacaoEstorno: 1 });
        }
    }, [exibirModal]);

    function selecionaContaCorrente(value) {
        if (value === 1)
            setSelecionarContaCorrente(false)
        else
            setSelecionarContaCorrente(true)
    };
    function salvar() {
        if (selecionarContaCorrente && !form.getFieldValue().ctc_id) {
            info('Selecione uma conta corrente!');
        } else {
            aoSalvar();
        }
    }

    return (
        <Modal centered
            title="Movimentação Financeira"
            visible={exibirModal}
            onCancel={() => cancelar()}
            onOk={() => salvar()}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="form" form={form}>
                    <Row>
                        Deseja Gerar?
                    </Row>
                    <Row gutter={[8, 8]}>
                        <Col>
                            <Form.Item name="antecipacaoEstorno" initialValue={1} form={form}>
                                <Radio.Group onChange={(event) => { selecionaContaCorrente(event.target.value) }} >
                                    <Radio value={1}>Antecipação/Crédito ?</Radio>
                                    <Radio value={2}>Estorno da Movimentação financeira ?</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        {selecionarContaCorrente && <Col span={24}>
                            <Form.Item label="Conta Corrente" name="ctc_id" rules={[{ required: true, message: 'Selecione a conta corrente' }]}>
                                <SelectPaginacao url="ContaCorrente/ListarAtivos" showSearch optionFilterProp="children" placeholder="Selecione uma conta corrente" nameValue="ctc_id" nameLabel="ctc_id" form={form}
                                    conteudo={item => (
                                        <Select.Option key={item.ctc_id} value={item.ctc_id}>{item.ctc_id} - {item.ctc_descricao}</Select.Option>
                                    )} />
                            </Form.Item>
                        </Col>}
                    </Row>
                </Form>
            </div >
        </Modal>
    );
}