import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Input, Select, Button, Switch, Tag, notification, Modal, Table, InputNumber } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, UndoOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { selectPaginadoActions } from "../../actions";
import moment from 'moment';


import api from "../../services/api";
import SelectPaginado from "../../components/selectPaginado";
import SelectPaginacao from "../../components/selects/selectPaginacao";

import { useStateValue } from '../../state';
import { FormatNumber } from "../../ValueObjects";
import { ModalSenhaLiberacao } from "../../components/modals";
import { Data, FiltroProdutoVnc, FormGW, InputPhone, Tabela } from "../../components";
import { listagemActions, manutencaoActions } from "../../actions";
import { HasValue, compararDatas, isObjetoDiffVazio } from "../../services/funcoes";

export default function ManutencaoCondicional({ formulario, carregando, aoSalvar, dadosItens, setDadosItens, imprimirCondicional }) {

    const format = 'HH:mm';
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [dadosEntrega, setDadosEntrega] = useState(false);
    const [cliente, setCliente] = useState({});
    const [openModalSenha, setOpenModalSenha] = useState(false);
    const [quantidadeTot, setQuantidadeTot] = useState(0);
    const [valorTot, setValorTot] = useState(0.00);
    const [dadosSenha, setDadosSenha] = useState([]);
    const [editando, setEditando] = useState(false);
    const [dadosEditando, setDadosEditando] = useState({});
    const [dataRetorno, setDataRetorno] = useState(moment(new Date()).add(1, 'day'));
    const devolver = useRef();

    function switchEntregar() {
        setDadosEntrega(!dadosEntrega);
        if (!!formulario.getFieldValue().cli_id && !dadosEntrega) {
            if (isObjetoDiffVazio(cliente)) {
                if (cliente.enderecos.length > 0) {
                    let endPrincipal = cliente.enderecos.length == 1 ? cliente.enderecos[0] : cliente.enderecos.filter((e) => (e.pee_enderecoprincipal == true))[0]
                    if (!!endPrincipal) {
                        let endEntrega = endPrincipal.log_logradouro + ', ' + endPrincipal.pee_numero + '. ' + endPrincipal.bai_nome + ', ' + endPrincipal.cep_cep + ', ' + endPrincipal.cid_descricao + '/' + endPrincipal.est_sigla;
                        formulario.setFieldsValue({ vnc_enderecoentrega: endEntrega.replaceAll("null", '') });
                        formulario.setFieldsValue({ vnc_entregaagendada: moment(new Date()) });
                        formulario.setFieldsValue({ horaagendada: '00:00' });
                    }
                }
            } else {
                api.get(`PessoaEndereco/ListaEndereco?IdPessoa=${formulario.getFieldValue().pes_id}`).then(
                    (res) => {
                        if (res.status === 200) {
                            if (!!res.data) {
                                if (res.data.length > 0) {
                                    let endPrincipal = res.data.length == 1 ? res.data[0] : res.data.filter((e) => (e.pee_enderecoprincipal == true))[0]
                                    if (!!endPrincipal) {
                                        let endEntrega = endPrincipal.log_logradouro + ', ' + endPrincipal.pee_numero + '. ' + endPrincipal.bai_nome + ', ' + endPrincipal.cep_cep + ', ' + endPrincipal.cid_descricao + '/' + endPrincipal.est_sigla;
                                        formulario.setFieldsValue({ vnc_enderecoentrega: endEntrega.replaceAll("null", '') });
                                        formulario.setFieldsValue({ vnc_entregaagendada: moment(new Date()) });
                                        formulario.setFieldsValue({ horaagendada: '00:00' });
                                    }
                                }
                            }
                        }
                    }
                ).catch(
                    (erro) => {
                        console.log(erro);
                    }
                );
            }
        }
    };

    function setarFocoDevolver() {
        setTimeout(() => {
            if (devolver && !!devolver.current) {
                devolver.current.focus();
            }
        }, 300);
    };

    useEffect(() => {
        formulario.setFieldsValue({ vnc_levarparacliente: dadosEntrega });
    }, [dadosEntrega]);

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.itens) {
            if (!!manutencao.dados.vnc_levarparacliente) {
                setDadosEntrega(manutencao.dados.vnc_levarparacliente);
            }
            setDadosItens(manutencao.dados.itens);
        } else {
            formulario.setFieldsValue({ vnc_prazoretorno: dataRetorno });
        }
    }, [manutencao.dados]);

    useEffect(() => {
        if (!!manutencao.dados) {
            let lista = [];
            if (!!manutencao.dados.cli_id) {
                lista.push({ name: "cli_id", campo: "IdCliente", value: manutencao.dados.cli_id });
            }
            if (!!manutencao.dados.ven_id) {
                lista.push({ name: "ven_id", campo: "IdVendedor", value: manutencao.dados.ven_id });
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            formulario.setFieldsValue(manutencao.dados);
        }
    }, [manutencao.dados]);

    function desfazerDevolucao(record) {
        let dadosTemp = dadosItens;
        dadosTemp.filter((item) => { return item.vci_id === record.vci_id })[0].vci_devolvido = 0;
        dadosTemp.filter((item) => { return item.vci_id === record.vci_id })[0].totBaixado = false;
        setDadosItens(dadosTemp);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dadosTemp } });
    };

    useEffect(() => {
        recalcularTotais(dadosItens);
    }, [dadosItens]);


    function salvarDados(values) {
        if (!(!!values.vnc_levarparacliente)) {
            values.vnc_levarparacliente = false;
        } else {
            let dataEntrega = moment(values.vnc_entregaagendada);
            let horaEntrega = '00';
            let minutoEntrega = '00';
            if (!!values.horaagendada) {
                horaEntrega = values.horaagendada.substring(0, 2);
                minutoEntrega = values.horaagendada.substring(3, 5);
            }
            dataEntrega.set("hour", parseInt(horaEntrega)); // Setando a hora
            dataEntrega.set("minute", parseInt(minutoEntrega)); // Setando os minutos
            values.vnc_entregaagendada = dataEntrega;
        }
        values.vnc_imagemassinaturacliente = "";
        values.pes_id = cliente.pes_id;
        values.pes_nome = HasValue(cliente.pes_nome) ? cliente.pes_nome : formulario.getFieldValue().cli_nome;
        values.vnc_telefonecliente = values.vnc_telefonecliente.replaceAll(`(`, ``).replaceAll(`)`, ``).replaceAll(`-`, ``).replaceAll(` `, ``).replaceAll(`_`, ``);
        values.vendacondicionalitens = dadosItens;
        if (values.vendacondicionalitens.length == 0) {
            notification.warning({ message: 'Atenção', description: "O condicional não possui itens, verifique!" });
            return;
        }
        let enderecoUrl = 'VendaCondicional/';
        carregando(true);
        if (values.vnc_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    if (!!res.data) {
                        imprimirCondicional(res.data);
                    }
                    notification.success({ message: 'Operação concluída', description: "Os dados da condicional foram salvos com sucesso!" });
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: res.data } });
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response.data.Message) });
            }).finally(
                () => {
                    atualizarPagina();
                    carregando(false);
                    aoSalvar();
                    setDadosEntrega(false);
                    recalcularTotais(dadosItens);
                }
            );
        } else {
            enderecoUrl += "Incluir"
            api.post(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    if (!!res.data) {
                        imprimirCondicional(res.data);
                    }
                    notification.success({ message: 'Operação concluída', description: "Os dados da condicional foram salvos com sucesso!" });
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: res.data } });
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response.data.Message) });
            }).finally(
                () => {
                    atualizarPagina();
                    carregando(false);
                    aoSalvar();
                    setDadosEntrega(false);
                    recalcularTotais(dadosItens);
                }
            );
        };
    };

    // so deixa excluir o item se não tiver sido gravado em banco, caso contrario é apenas feito a devolução total do item, para manter historico.
    function excluirItem(item) {
        let dadosTemp = [...dadosItens];
        if (typeof item.vci_id === "undefined") {
            dadosTemp = dadosTemp.filter((dados) => dados !== item);
            dadosTemp = dadosTemp.map((dados, index) => {
                if (dados.vci_id > 0) {
                    // dados.vci_sequencia = dados.vci_sequencia
                    return dados;
                } else {
                    return {
                        ...dados,
                        vci_sequencia: dadosTemp.length - index
                    };
                };
            });
        } else {
            dadosTemp.filter((i) => { return i.vci_id === item.vci_id })[0].vci_devolvido = item.vci_quantidade;
        }
        setDadosItens(dadosTemp);
        recalcularTotais(dadosTemp);
    };

    function editarItem(registro, indice) {
        if (registro.vci_id) {
            let listaTemp = [...dadosItens];
            let possuiEdicaoAberta = listaTemp.filter((item) => item.editando == true);
            if (possuiEdicaoAberta.length > 0) {
                notification.warning({ message: 'Aviso!', description: 'Antes de editar outro item, por favor, salve as alterações feitas no item atual!' });
                return
            }

            let item = listaTemp[indice];
            listaTemp[indice].editando = true;
            formulario.setFieldsValue({ [`vci_devolvido`]: item.vci_devolvido });
            setDadosItens(listaTemp);
            setarFocoDevolver();
        } else {
            registro.indice = indice;
            setDadosEditando(registro);
            setEditando(true);
        }
    };

    function manutencaoItem(index) {
        let listaTemp = [...dadosItens];
        let item = listaTemp[index];
        let qtdeItem = formulario.getFieldValue().vci_devolvido;

        if (qtdeItem > item.vci_quantidade) {
            formulario.setFieldsValue({ [`vci_devolvido`]: item.vci_quantidade });
        }
    }

    function salvarItem(registro, indice) {
        let listaTemp = [...dadosItens];
        let item = listaTemp[indice];
        listaTemp[indice].editando = false;
        item.vci_devolvido = formulario.getFieldsValue().vci_devolvido;

        setDadosItens(listaTemp);

    };

    function cancelarEdicaoItem(indice) {
        let listaTemp = [...dadosItens];
        let item = listaTemp[indice];
        listaTemp[indice].editando = false;
        setDadosItens(listaTemp);
    }

    function recalcularTotais(dados) {
        let qtd = 0;
        let valor = 0;
        dados.forEach((dado) => {
            qtd += (dado.vci_quantidade - dado.vci_devolvido);
            valor += dado.vci_valorun * (dado.vci_quantidade - dado.vci_devolvido);
        });
        setValorTot(valor);
        setQuantidadeTot(qtd);
    };

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+vnc_datalancamento' } });
    };

    const modalParcelasAtraso = (cliente) => {
        Modal.confirm({
            title: 'Atenção',
            icon: <ExclamationCircleOutlined />,
            content: `Este cliente possui parcelas em atraso!! Deseja Continuar? Será solicitado uma senha Gerencial!`,
            okText: 'Sim, quero continuar!',
            cancelText: 'Não',
            centered: true,
            onOk() {
                modalSenhaGerencial(cliente);
            },
            onCancel() {
                formulario.setFieldsValue({ cli_id: null });
            }
        });
    };

    const modalSenhaGerencial = (dados) => {
        setOpenModalSenha(true);
        setDadosSenha(dados);
    };

    function retornoModalSenha(dados) {
        if (!dados) {
            formulario.setFieldsValue({ cli_id: null });
        }
    };

    async function BuscarDadosCliente(IdPessoa) {
        if (!!IdPessoa) {
            var dadosCliente;
            await api.get(`Cliente/BuscarCliente/${IdPessoa}`).then(
                (res) => {
                    if (res.status === 200) {
                        if (!!res.data) {
                            dadosCliente = res.data;
                            setCliente(res.data)
                        }
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            );
            let telefone = dadosCliente.telefones.filter((tel) => (tel.psc_ativo == true && tel.psc_principal == true))[0]?.psc_numero;
            if (!!telefone) telefone = dadosCliente.telefones.filter((tel) => (tel.psc_ativo == true))[0]?.psc_numero;
            if (!!telefone) formulario.setFieldsValue({ vnc_telefonecliente: telefone });
            else formulario.setFieldsValue({ vnc_telefonecliente: "" });
            setDadosEntrega(false);
            await api.get(`Parcela/ListarParcelasClientesEmAtrazo?IdPessoa=${dadosCliente.pes_id}`).then(
                (res) => {
                    if (res.status === 200) {
                        if (!!res.data && res.data.items.length > 0) {
                            modalParcelasAtraso(dadosCliente);
                        }
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            );
        }
    };

    useEffect(() => {
        if (dadosItens.length > 0) {
            dadosItens.map(itens => {
                itens.totBaixado = (itens.vci_quantidade - itens.vci_devolvido) === 0;
            });
        }
    }, [dadosItens]);

    return (
        <div>
            <FormGW layout="vertical" form={formulario} name="manutencaoCondicional" initialValues={{ remember: true }} onFinish={salvarDados}>
                <Row gutter={[8, 0]}>
                    <Form.Item hidden name="vnc_id">
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name="pes_id">
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name="vnc_numero">
                        <Input />
                    </Form.Item>
                    <Col xs={24} sm={16} md={6} lg={6} xl={6} xxl={7}>
                        <Form.Item label="Cliente" name="cli_id" rules={[{ required: true, message: 'Selecione o Cliente.' }]}>
                            <SelectPaginacao url="Cliente/Listar" placeholder="Selecione o Cliente" onChangeFunction={(valor) => (BuscarDadosCliente(valor))}
                                nameLabel="pes_nome" nameValue="cli_id" form={formulario} name="cli_id" conteudo={
                                    cli => (<Select.Option value={cli.cli_id} key={cli.cli_id}>{cli.cli_codigo} - {cli.pes_nome}</Select.Option>)
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={4} lg={4} xl={3} xxl={3}>
                        <InputPhone label="Telefone" name="vnc_telefonecliente" rules={[{ required: true, message: 'Informe o telefone.' }]} />
                    </Col>
                    <Col xs={24} sm={16} md={5} lg={5} xl={6} xxl={7}>
                        <Form.Item label="Vendedor" name="ven_id" rules={[{ required: true, message: 'Selecione o Vendedor.' }]}>
                            <SelectPaginado url="Vendedor/Listar" placeholder="Selecione o Vendedor" form={formulario} name="ven_id" conteudo={
                                ven => (<Select.Option value={ven.ven_id} key={ven.ven_id}>{ven.ven_id} - {ven.pes_nome}</Select.Option>)
                            }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={4} lg={4} xl={4} xxl={3}>
                        <Data label="Data Retorno" name="vnc_prazoretorno" rules={[{ required: true }, ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || compararDatas(value)) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('A data precisa ser maior que a atual!'));
                            },
                        }),]} />
                    </Col>
                    <Col xs={24} sm={12} md={dadosEntrega ? 5 : 5} lg={dadosEntrega ? 5 : 5} xl={5} xxl={4}>
                        <Form.Item name="vnc_levarparacliente" className={dadosEntrega ? 't-mob-573' : 't-mob-573'}>
                            <Switch checked={dadosEntrega} onChange={(campo) => (switchEntregar(campo))} /> Entregar para o Cliente
                        </Form.Item>
                    </Col>
                    {!!dadosEntrega &&
                        <>
                            <Col xs={24} sm={12} md={10} lg={11} xl={11} xxl={14}>
                                <Form.Item label="Endereço de Entrega" name="vnc_enderecoentrega">
                                    <Input placeholder="Informe o Endereço de Entrega" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={5} lg={4} xl={4} xxl={3}>
                                <Data label="Data Agendada" name="vnc_entregaagendada" />
                            </Col>
                            <Col xs={24} sm={12} md={dadosEntrega ? 4 : 5} lg={4} xl={4} xxl={3}>
                                <Form.Item label="Hora Agendada" name="horaagendada">
                                    <Input type="time" onChange={() => { let a = formulario.getFieldValue().horaagendada; }} />
                                </Form.Item>
                            </Col>
                        </>
                    }
                </Row>
                <Row align="middle" gutter={[8, 8]}>
                    <Col span={24}>
                        <Form.Item label="Observação" name="vnc_observacao">
                            <Input.TextArea placeholder="Informe uma Observação" />
                        </Form.Item>
                    </Col>
                </Row>
                <FiltroProdutoVnc form={formulario} dadosTabela={{ dadosItens, setDadosItens }} btnEditando={{ editando, setEditando }} itemEditando={dadosEditando} />
                <Row align="middle" gutter={[0, 16]}>
                    <Col span={24}>
                        <div className="tabela mt-dif">
                            <Table columns={[
                                {
                                    title: 'Código',
                                    width: '8%',
                                    align: 'center',
                                    render: ({ pro_codigo, vci_devolvido, vci_quantidade }) => (
                                        <Tag color={vci_devolvido == vci_quantidade ? "default" : "processing"} className="w-100">
                                            <b>{pro_codigo}</b>
                                        </Tag>
                                    ),
                                },
                                {
                                    title: 'Itens',
                                    width: '35%',
                                    render: ({ vci_devolvido, vci_quantidade, pro_descricao, pro_ean, prg_ean, descricaograde }) => (
                                        <div>
                                            <Row>
                                                <Col>
                                                    {vci_devolvido == vci_quantidade ?
                                                        <b>
                                                            <s>{pro_descricao}</s>
                                                        </b> :
                                                        <b>{pro_descricao}</b>
                                                    }
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 0]}>
                                                {!!pro_ean || !!prg_ean ? (<Col>
                                                    {vci_devolvido == vci_quantidade ?
                                                        <s>
                                                            EAN: <b> {pro_ean ? pro_ean : prg_ean}</b>
                                                        </s> :
                                                        <span>
                                                            EAN:  <b>{pro_ean ? pro_ean : prg_ean}</b>
                                                        </span>
                                                    }
                                                </Col>) : ""}
                                                {!!descricaograde ? (<Col>
                                                    {vci_devolvido == vci_quantidade ?
                                                        <s>
                                                            Grade: <b> {descricaograde}</b>
                                                        </s> :
                                                        <span>
                                                            Grade: <b>{descricaograde}</b>
                                                        </span>
                                                    }
                                                </Col>) : ''}
                                            </Row>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Nº',
                                    width: '2%',
                                    render: ({ vci_sequencia }) => (
                                        <div>
                                            {vci_sequencia}
                                        </div>
                                    ),
                                },
                                {
                                    title: '',
                                    width: '8%',
                                    render: ({ vci_quantidade, vci_devolvido }) => (
                                        <div>
                                            {(vci_devolvido == vci_quantidade ? (<s>Ficou com: <b> {vci_quantidade - vci_devolvido}</b></s>) : (<span>Ficou com: <b>{vci_quantidade - vci_devolvido} </b></span>))}
                                        </div>
                                    ),
                                },
                                {
                                    title: '',
                                    width: '12%',
                                    editable: true,
                                    render: ({ vci_quantidade, vci_devolvido }, record) => (
                                        <div>
                                            <Row gutter={[8, 0]}>
                                                <Col>
                                                    {(vci_devolvido == vci_quantidade ? (<s> Devolveu: <b> {vci_devolvido}</b> </s>) : (vci_devolvido > 0) ? <span>Devolveu: <b> {vci_devolvido}</b></span> : <span></span>)}
                                                </Col>
                                                <Col>
                                                    {(vci_devolvido > 0 ? <Button type="link" onClick={() => desfazerDevolucao(record)} icon={<UndoOutlined />} /> : <span> </span>)}
                                                </Col>
                                                {/* <Col>
                                                    {(vci_devolvido == vci_quantidade ? (<s>(Todos)</s>) : <span></span>)}
                                                </Col> */}
                                            </Row>
                                        </div>
                                    ),
                                },
                                {
                                    width: '8%',
                                    align: 'center',
                                    render: ({ vci_devolvido, vci_quantidade, editando }, record, indice) => (
                                        <div>
                                            <span>{editando ? "Devolver:" : ""}</span>
                                            {editando ? (
                                                <Form.Item name={`vci_devolvido`}>
                                                    <InputNumber ref={devolver}
                                                        style={{ display: editando ? 'block' : 'none' }}
                                                        name={"vci_devolvido"}
                                                        initialValue={vci_devolvido}
                                                        onChange={() => manutencaoItem(indice)}
                                                    />
                                                </Form.Item>
                                            ) : (
                                                ''
                                            )}

                                        </div>
                                    )
                                },
                                {
                                    editable: true,
                                    title: 'Vlr. Unit. (R$)',
                                    align: 'right',
                                    render: ({ vci_devolvido, vci_quantidade, vci_valorun }) => (
                                        <div>
                                            {vci_devolvido == vci_quantidade ?
                                                <s>
                                                    <b> {FormatNumber(vci_valorun, true)}</b>
                                                </s> :
                                                <span>
                                                    <b> {FormatNumber(vci_valorun, true)}</b>
                                                </span>
                                            }
                                        </div>
                                    ),
                                },
                                {
                                    editable: true,
                                    title: 'Vlr. Total (R$)',
                                    align: 'right',
                                    render: ({ vci_devolvido, vci_quantidade, vci_valorun }) => (
                                        <div>
                                            {vci_devolvido == vci_quantidade ?
                                                <s>
                                                    <b> {FormatNumber((vci_quantidade - vci_devolvido) * vci_valorun, true)}</b>
                                                </s> :
                                                <span>
                                                    <b> {FormatNumber((vci_quantidade - vci_devolvido) * vci_valorun, true)}</b>
                                                </span>
                                            }
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Ações',
                                    dataIndex: '',
                                    align: 'center',
                                    key: 'x',
                                    width: '8%',
                                    fixed: 'right',
                                    render: (record, e, indice) => (
                                        <div>
                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                <Col>
                                                    {record.editando ?
                                                        <Button
                                                            onClick={() => salvarItem(record, indice)}
                                                            icon={<CheckOutlined />}
                                                        /> :
                                                        <Button
                                                            onClick={() => editarItem(record, indice)}
                                                            icon={<EditOutlined />}
                                                        />
                                                    }
                                                </Col>
                                                <Col>
                                                    {record.editando ?
                                                        <Button
                                                            onClick={() => cancelarEdicaoItem(indice)}
                                                            icon={<CloseOutlined />}
                                                        /> :
                                                        <Button
                                                            onClick={() => excluirItem(record)}
                                                            icon={<DeleteOutlined />}
                                                        />
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    ),
                                },
                            ]} dataSource={dadosItens} />
                        </div>
                    </Col>
                </Row>
                <ModalSenhaLiberacao exibirModal={openModalSenha} fecharModal={(retorno) => { setOpenModalSenha(false); retornoModalSenha(retorno) }} dados={dadosSenha} />
            </FormGW>
            <Row justify="space-between" gutter={[8, 0]} className="m-t-16">
                <Col>
                    Quantidade Total de Itens: <b>{quantidadeTot}</b>
                </Col>
                <Col>
                    Valor Total dos Itens (R$): <b className="f-18">{FormatNumber(valorTot, true)}</b>
                </Col>
            </Row>
        </div >
    );

}