import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography, Divider, Form, Select, Button } from 'antd';
import { ExclamationCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { BarChart, Bar, LineChart, Line, LabelList } from 'recharts';
import moment from 'moment';

import api from '../../services/api';
import { getEmpresa, getLocal } from '../../services/auth';
import { Data, BreadcrumbPage, BotaoAbrirChamado } from "../../components";
import { CartesianChart, CartesianChartBar, ComponentSpin, RenderChartPie, TableRenderer } from "./funcoes";

const numberFormatter = (item, casasDecimais = 2) => item.toLocaleString({ style: 'currency', currency: 'BRL' }, { minimumFractionDigits: casasDecimais, maximumFractionDigits: casasDecimais });

export default function VendaLiquida({ graficoSelecionado }) {
    const [formFiltros] = Form.useForm();
    const [vendaLiquidaDiaria, setVendaLiquidaDiaria] = useState([]);
    const [totalizadores, setTotalizadores] = useState({});
    const [pessoaFisicaJuridica, setPessoaFisicaJuridica] = useState([]);
    const [topVendedores, setTopVendedores] = useState([]);
    const [vendedores, setVendedores] = useState([]);
    const [vendaLiquidaCidades, setVendaLiquidaCidades] = useState([]);
    const [vendaLiquidaClientes, setVendaLiquidaClientes] = useState([]);
    const [listaLocaisUsuario, setListaLocaisUsuario] = useState([]);
    const [carregandoTotalizadores, setCarregandoTotalizadores] = useState(false);
    const [carregandoVendaDiaria, setCarregandoVendaDiaria] = useState(false);
    const [carregandoFisicaJuridica, setCarregandoFisicaJuridica] = useState(false);
    const [carregandoVendedor, setCarregandoVendedor] = useState(false);
    const [carregandoCliente, setCarregandoCliente] = useState(false);
    const [carregandoCidade, setCarregandoCidade] = useState(false);

    useEffect(() => {
        api.get(`/Local/ListarLocalUsuario?codigoEmpresa=${getEmpresa()}`).then(res => {
            if (res.status === 200) {
                setListaLocaisUsuario(res.data.items);
            }
        }).catch((erro) => console.error(erro));

        carregarDados();
    }, []);

    function carregarDados() {
        let dataInicial = !!formFiltros.getFieldValue().dataInicial ? moment(formFiltros.getFieldValue().dataInicial).format('YYYY-MM-DD') : null;
        let dataFinal = !!formFiltros.getFieldValue().dataFinal ? moment(formFiltros.getFieldValue().dataFinal).format('YYYY-MM-DD') : null;
        let locais = !!formFiltros.getFieldValue().local ? formFiltros.getFieldValue().local.join(',') : '';

        let mesAnterior = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 30)).format("YYYY-MM-DD");

        setCarregandoTotalizadores(true);
        setCarregandoVendaDiaria(true);
        setCarregandoFisicaJuridica(true);
        setCarregandoVendedor(true);
        setCarregandoCliente(true);
        setCarregandoCidade(true);
        api.get(`Dashboard/ListarVendaLiquidaDiaria?locais=${locais}&dataInicial=${mesAnterior}&dataFinal=${moment(new Date()).format("YYYY-MM-DD")}`).then(
            (res) => {
                setVendaLiquidaDiaria(res.data);
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoVendaDiaria(false);
        });

        api.get(`Dashboard/ListarTotalizadoresVenda?locais=${locais}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`).then(
            (res) => {
                if (!!res.data && res.data.length > 0) {
                    setTotalizadores(res.data[0]);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoTotalizadores(false);
        });

        api.get(`Dashboard/ListarPessoaFisicaJuridica?locais=${locais}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`).then(
            (res) => {
                if (!!res.data) {
                    setPessoaFisicaJuridica(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoFisicaJuridica(false);
        });

        api.get(`Dashboard/ListarVendedoresVendaLiquida?locais=${locais}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`).then(
            (res) => {
                if (!!res.data) {
                    setVendedores(res.data);
                    setTopVendedores(res.data.slice(0, 10));
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoVendedor(false);
        });

        api.get(`Dashboard/ListarVendaLiquidaCidades?locais=${locais}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`).then(
            (res) => {
                if (!!res.data) {
                    setVendaLiquidaCidades(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoCidade(false);
        });

        api.get(`Dashboard/ListarVendaLiquidaClientes?locais=${locais}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`).then(
            (res) => {
                if (!!res.data) {
                    setVendaLiquidaClientes(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoCliente(false);
        });
    };

    const renderCustomizedLabelBar = (props) => {
        const { x, y, width, value } = props;

        return (
            <g>
                <text x={x + width / 2} y={parseFloat(value) > 0 ? y - 10 : y + 10} fill={parseFloat(value) > 0 ? "#0d49c3" : "red"} textAnchor="middle" dominantBaseline="middle">
                    {numberFormatter(parseFloat(value))}
                </text>
            </g>
        );
    };

    return (
        <div>
            <Row align="middle" justify="space-between" gutter={[8, 0]} className="col-pagina-breadcrumb"> 
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoAbrirChamado />
                        </Col>
                        <Col>
                            <Button icon={<ArrowLeftOutlined />} size="middle" type="primary" onClick={() => { graficoSelecionado.setGraficoSelecionado(null) }}>
                                Voltar
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Row justify="center">
                        <Col>
                            <Typography.Title level={3} className="m-b-0">
                                <img src={require("../../assets/i-financeiro.png").default} alt="Venda Liquída " /> Venda Liquída 
                            </Typography.Title>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Form form={formFiltros} layout="vertical">
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={2}>
                                <Data label="Data Inicial" name="dataInicial" initialValue={new Date(new Date().getFullYear(), new Date().getMonth(), 1)} onBlur={carregarDados} />
                            </Col>
                            <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={2}>
                                <Data label="Data Final" name="dataFinal" initialValue={new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)} onBlur={carregarDados} />
                            </Col>
                            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={20}>
                                <Form.Item name="local" label="Local" initialValue={[parseInt(getLocal())]}>
                                    <Select onChange={carregarDados} mode="multiple" allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione o local...">
                                        {listaLocaisUsuario.filter(ativa => (ativa.loc_ativo)).map(Local => (
                                            <Select.Option key={Local.loc_id} value={Local.loc_id}> {Local.loc_descricao} </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="m-t-8">
                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
                    <Card>
                        <ComponentSpin carregando={carregandoTotalizadores}>
                            <Typography.Title level={4} className="m-b-0">
                                Valor Venda Líquida (R$)
                            </Typography.Title>
                            <Typography.Title level={2} className="m-t-0 m-b-0 text-right">
                                {numberFormatter(!!totalizadores.vlr_vendaliquida ? totalizadores.vlr_vendaliquida : 0)}
                            </Typography.Title>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={8} xxl={8}>
                    <Card>
                        <ComponentSpin carregando={carregandoTotalizadores}>
                            <Typography.Title level={4} className="m-b-0">
                                Valor Venda (R$)
                            </Typography.Title>
                            <Typography.Title level={2} className="m-t-0 m-b-0 text-right">
                                {numberFormatter(!!totalizadores.vlr_total_venda ? totalizadores.vlr_total_venda : 0)}
                            </Typography.Title>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                    <Card>
                        <ComponentSpin carregando={carregandoTotalizadores}>
                            <Typography.Title level={4} className="m-b-0">
                                Valor Devolução de Venda (R$)
                            </Typography.Title>
                            <Typography.Title level={2} className="m-t-0 m-b-0 text-right">
                                {numberFormatter(!!totalizadores.vlr_total_devvenda ? totalizadores.vlr_total_devvenda : 0)}
                            </Typography.Title>
                        </ComponentSpin>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="m-t-16">
                <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={14}>
                    <Card>
                        <ComponentSpin carregando={carregandoVendaDiaria}>
                            <Typography.Title level={4}>
                                Venda Líquida por Dia - Últimos 30 dias
                            </Typography.Title>
                            <>
                                {vendaLiquidaDiaria.length > 0 ? 
                                    <CartesianChart dataKey="data" resultSet={vendaLiquidaDiaria} ChartComponent={LineChart} >
                                        <Line dataKey={"total"} type="linear" stroke="rgb(106, 110, 229)" name={"Total Venda Líquida"} fill="rgb(106, 110, 229)" /*legendType="circle"*/ 
                                    />
                                    </CartesianChart> : <Row className="col-charts">
                                        <Col span={24}>
                                            <Divider orientation="center">
                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                            </Divider>
                                        </Col>
                                    </Row>
                                }
                            </>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={10}>
                    <Card>
                        <ComponentSpin carregando={carregandoFisicaJuridica}>
                            <Typography.Title level={4}>
                                Valor Venda Líquida - Pessoa Física Jurídica
                            </Typography.Title>
                            <div>
                                {pessoaFisicaJuridica.length > 0 ? <RenderChartPie resultSet={pessoaFisicaJuridica} dataKey="total" nameKey="fisica_juridica" /> : <Row className="col-charts">
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>}
                            </div>
                        </ComponentSpin>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="m-t-16">
                <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={10}>
                    <Card>
                        <Typography.Title level={4}>
                            Venda Líquida por Vendedor
                        </Typography.Title>
                        <div className="">
                            <TableRenderer dataSource={vendedores} carregando={carregandoVendedor} columns={[{
                                title: 'Vendedor',
                                render: ({ vendedor }) => (
                                    <div>
                                        {vendedor}
                                    </div>
                                ),
                            }, {
                                title: 'Quant. Venda Líquida',
                                align: 'right',
                                render: ({ qtde_total }) => (
                                    <div>
                                        {numberFormatter(parseFloat(qtde_total), 3)}
                                    </div>
                                ),
                            }, {
                                title: 'Valor Venda Líquida',
                                align: 'right',
                                render: ({ valor_total }) => (
                                    <div>
                                        {numberFormatter(parseFloat(valor_total))}
                                    </div>
                                ),
                            }]} />
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={14}>
                    <Card>
                        <ComponentSpin carregando={carregandoVendedor}>
                            <Typography.Title level={4}>
                                Top 10 Vendedores por Venda Líquida
                            </Typography.Title>
                            <div>
                                {topVendedores.length > 0 ? <CartesianChartBar resultSet={topVendedores} ChartComponent={BarChart}>
                                    <Bar dataKey="valor_total" name={"Total Vendedor"} fill="#4b97efd4">
                                        <LabelList dataKey="vendedor" position="bottom" />
                                        <LabelList dataKey="valor_total" content={renderCustomizedLabelBar} position="top" />
                                    </Bar>
                                </CartesianChartBar> : <Row className="col-charts">
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>}
                            </div>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <Card>
                        <Typography.Title level={4}>
                            Venda Líquida por Cliente
                        </Typography.Title>
                        <div className="">
                            <TableRenderer dataSource={vendaLiquidaClientes} carregando={carregandoCliente} columns={[{
                                title: 'Cliente',
                                render: ({ cliente }) => (
                                    <div>
                                        {cliente}
                                    </div>
                                ),
                            }, {
                                title: 'Quant. Venda Líquida',
                                align: 'right',
                                render: ({ qtde_total }) => (
                                    <div>
                                        {numberFormatter(parseFloat(qtde_total), 3)}
                                    </div>
                                ),
                            }, {
                                title: 'Valor Venda Líquida',
                                align: 'right',
                                render: ({ valor_total }) => (
                                    <div>
                                        {numberFormatter(parseFloat(valor_total))}
                                    </div>
                                ),
                            }]} />
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <Card>
                        <Typography.Title level={4}>
                            Venda Líquida por Cidade UF
                        </Typography.Title>
                        <div className="">
                            <TableRenderer dataSource={vendaLiquidaCidades} carregando={carregandoCidade} columns={[{
                                title: 'Cidade',
                                render: ({ cidade }) => (
                                    <div>
                                        {cidade}
                                    </div>
                                ),
                            }, {
                                title: 'UF',
                                render: ({ uf }) => (
                                    <div>
                                        {uf}
                                    </div>
                                ),
                            }, {
                                title: 'Quant. Venda Líquida',
                                align: 'right',
                                render: ({ qtde_total }) => (
                                    <div>
                                        {numberFormatter(parseFloat(qtde_total), 3)}
                                    </div>
                                ),
                            }, {
                                title: 'Valor Venda Líquida',
                                align: 'right',
                                render: ({ valor_total }) => (
                                    <div>
                                        {numberFormatter(parseFloat(valor_total))}
                                    </div>
                                ),
                            }]} />
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};