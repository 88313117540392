import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Form, Divider, InputNumber, Card, notification, Button, Select, Table, Input } from "antd";

import { useStateValue } from "../../state";
import { validarNumeros } from "../../services/funcoes";
import { InputPreco, } from "../../components";
import { PlusOutlined, EditOutlined, DeleteOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { FormatNumber } from "../../ValueObjects";
import SelectPaginado from "../../components/selectPaginado";

export default function TabFaturamentoNovo({ form, dadosItens, dadosVendedor, orcamento }) {

    const [valorOrcamento, setValorOrcamento] = useState(0);
    const [{ manutencao }, dispatch] = useStateValue();
    const [condicaoPagamento, setCondicaoPagamento] = useState([]);
    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [valorTotal, setValorTotal] = useState(0);
    const [pagamentoTotal, setPagamentoTotal] = useState(false);
    const [editando, setEditando] = useState(false);
    const [dadosItemEditando, setDadosItemEditando] = useState({})

    useEffect(() => {
        calcularFaturamento();
        if (orcamento != null) {
            setExibirDetalhes(true);
        } else {
            setExibirDetalhes(false);
        }
    }, [manutencao.dados])

    useEffect(() => {
        recalcularTotal();
        formasPagamento();
    }, [valorOrcamento]);

    function formasPagamento() {
        let pagamento = [...orcamento.orcamentoFormaPagamento];
        let dados = pagamento;
        let formulario = form.getFieldValue();
        dados.forEach((itens) => {
            let total = validarNumeros(formulario.valorTotal);
            let acrescimo = (itens.orf_percentualacrescimo * total) / 100;
            let desconto = (itens.orf_percentualdesconto * total) / 100;

            itens.total = total;
            itens.valoracrescimo = acrescimo;
            itens.valordesconto = desconto;
            itens.valorPagamento = (total + acrescimo) - desconto;
        })
        orcamento.setOrcamentoFormaPagamento(dados);
    };

    function desconto() {
        let formulario = form.getFieldValue();
        let valorTotal = parseFloat(formulario.valorTotal);
        let totalItens = parseFloat(formulario.totalItens);
        let desconto = 0;
        let acrescimo = 0;
        if (valorTotal < totalItens) {
            desconto = (formulario.totalItens - formulario.valorTotal);
            form.setFieldsValue({ valorDesconto: desconto.toFixed(2) });
            form.setFieldsValue({ valorAcrescimo: 0 });
            calcularAcrescimo("VALOR");
            calcularDesconto("VALOR");
        }
        if (valorTotal > totalItens) {
            acrescimo = (formulario.valorTotal - formulario.totalItens);
            form.setFieldsValue({ valorAcrescimo: acrescimo.toFixed(2) });
            form.setFieldsValue({ valorDesconto: 0 });
            calcularAcrescimo("VALOR");
            calcularDesconto("VALOR");
        }
        if (valorTotal === totalItens) {
            form.setFieldsValue({
                valorDesconto: 0, valorAcrescimo: 0, orf_percentualacrescimo: 0, orf_percentualdesconto: 0 
            })
        }
    }

    function calcularFaturamento() {
        let formulario = form.getFieldValue();
        let totalItens = 0;
        dadosItens.forEach((item) => {
            totalItens += item.oit_qtde * item.oit_valorunitario;
        });
        form.setFieldsValue({ totalItens: totalItens.toFixed(2) });
        form.setFieldsValue({ orc_valorfrete: validarNumeros(formulario.orc_valorfrete) });
        form.setFieldsValue({ valorSeguro: validarNumeros(formulario.valorSeguro) });
        form.setFieldsValue({ orc_valoroutros: validarNumeros(formulario.orc_valoroutros) })
        setValorOrcamento(parseFloat(totalItens));
    };

    function calcularDesconto(campo) {
        let formulario = form.getFieldValue();
        let valor = validarNumeros(formulario.valorDesconto);
        let perc = validarNumeros(formulario.orf_percentualdesconto);
        let tot = parseFloat(valorOrcamento);
        let result = 0;
        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ valorDesconto: result.toFixed(2) });
            } else {
                result = (valor * 100) / tot;
                form.setFieldsValue({ orf_percentualdesconto: result.toFixed(2) });
            }
        }
        recalcularTotal();
    };

    function calcularAcrescimo(campo) {
        let formulario = form.getFieldValue();
        let valor = validarNumeros(formulario.valorAcrescimo);
        let perc = validarNumeros(formulario.orf_percentualacrescimo);
        let tot = (!!valorOrcamento ? parseFloat(valorOrcamento) : 0);
        let result = 0;
        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ valorAcrescimo: result.toFixed(2) });
            } else {
                result = (valor * 100) / tot;
                form.setFieldsValue({ orf_percentualacrescimo: result.toFixed(2) });
            }
        }
        recalcularTotal();
    };

    function recalcularTotal() {
        let formulario = form.getFieldValue();
        
        const percDesconto = formulario.orf_percentualdesconto ? parseFloat(formulario.orf_percentualdesconto) /100 : 0;
        const percAcresc = formulario.orf_percentualacrescimo ? parseFloat(formulario.orf_percentualacrescimo) /100 : 0;

        const valorFrete = validarNumeros(formulario.orc_valorfrete);
        const valorSeguro = validarNumeros(formulario.valorSeguro);
        const valorOutros = validarNumeros(formulario.orc_valoroutros);
        //Calcular valor com frete e outros
        const valorCFreteEOutros = valorOrcamento + valorFrete + valorSeguro + valorOutros;
        //Calcular descontos e acrescimos
        const calcuPercDesc = valorCFreteEOutros * percDesconto;
        const calcuPercAcresc = valorCFreteEOutros * percAcresc;
        //Calcular valor total e subtotal
        const valorTotal = valorCFreteEOutros - calcuPercDesc + calcuPercAcresc;
        const subTotal = valorOrcamento + valorFrete + valorSeguro + valorOutros;

        form.setFieldsValue({
            valorTotal: valorTotal.toFixed(2),
            subTotal: subTotal.toFixed(2)
        })

        setValorTotal(subTotal);
        form.setFieldsValue({ pfp_valor: valorTotal.toFixed(2) });
    };

    function vendedorDescMaxFinal() {
        let totalDesconto = 0;
        let totalValorDescontoItens = 0;
        dadosItens.forEach((itens) => {
            totalValorDescontoItens += parseFloat(itens.nfi_valordesconto);
        });
        let percentualDescontoConcedido = parseFloat(((totalValorDescontoItens * 100) / parseFloat(form.getFieldsValue().totalItens)).toFixed(2));
        totalDesconto = percentualDescontoConcedido + parseFloat(form.getFieldValue().orf_percentualdesconto);
        if (!!dadosVendedor && JSON.stringify(dadosVendedor) !== '{}' && !!dadosVendedor.ven_maximodesconto) {
            if (totalDesconto > (dadosVendedor.ven_maximodesconto * 100)) {
                notification.warning({ description: `O desconto máximo para este vendedor não pode exceder ${dadosVendedor.ven_maximodesconto * 100}% !`, message: 'Aviso' });
                form.setFieldsValue({ orf_percentualdesconto: (dadosVendedor.ven_maximodesconto * 100) - parseFloat(percentualDescontoConcedido) });
                calcularDesconto('PERC');
            }
        }
    };

    async function listarCondicao(fpg_id, dadosIniciais = false) {
        if (!dadosIniciais) {
            form.setFieldsValue({ cpg_id: null })
        }
        api.get(`CondicaoPagamento/Listar?codigoFormaPagamento=${fpg_id}`).then(
            (res) => {
                if (res.status === 200) {
                    setCondicaoPagamento(res.data.items);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };
    //não mecher daqui pra cima realiza os calculos que ja estão certos

    async function adicionarLista() {
        let valorPgmto = parseFloat(form.getFieldValue().valorTotal);
        let pagamento = [...orcamento.orcamentoFormaPagamento]
        const fpgIncluida = pagamento.some(item => 
            item.fpg_id === form.getFieldValue().fpg_id &&
            item.cpg_id === form.getFieldValue().cpg_id &&
            item.valorPagamento === valorPgmto
        );

        if (fpgIncluida) {
            notification.warning({ message: 'Aviso', description: 'Forma de Pagamento ja incluída!' });
        } else {
            if (editando) {
                if (!form.getFieldValue().fpg_id) {
                    notification.warning({ message: 'Aviso', description: 'Informe a forma de pagamento!' });
                } else if (condicaoPagamento.length > 0 && !form.getFieldValue().cpg_id) {
                    notification.warning({ message: 'Aviso', description: 'Informe uma condição de pagamento!' });
                } else {
                    let novaFormaPagamento = (await api.get(`FormaPagamento/Get?id=${form.getFieldValue().fpg_id}`)).data;
                    let novaCondicoesPagamento = condicaoPagamento.filter((condicao) => { return condicao.cpg_id === form.getFieldValue().cpg_id; })[0];
                    let dadosTemp = [...orcamento.orcamentoFormaPagamento]
                    let dadosItem = dadosItemEditando;

                    let pagamentoTemp = {
                        key: dadosItem.key,
                        fpg_id: form.getFieldValue().fpg_id,
                        fpg_descricao: novaFormaPagamento.fpg_descricao,
                        cpg_descricao: !!novaCondicoesPagamento && novaCondicoesPagamento.cpg_descricao ? novaCondicoesPagamento.cpg_descricao: '',
                        cpg_id: !!form.getFieldValue().cpg_id ? form.getFieldValue().cpg_id : null,
                        valorPagamento: parseFloat(form.getFieldValue().valorTotal),
                        valoracrescimo: form.getFieldValue().valorAcrescimo,
                        valordesconto: form.getFieldValue().valorDesconto,
                        orf_observacao: form.getFieldValue().orf_observacao,
                        orf_percentualacrescimo: form.getFieldValue().orf_percentualacrescimo,
                        orf_percentualdesconto: form.getFieldValue().orf_percentualdesconto,
                        valoritens: form.getFieldValue().totalItens
                    }

                    dadosTemp = dadosTemp.map(item => item.key === dadosItem.key ? pagamentoTemp : item);
                    orcamento.setOrcamentoFormaPagamento(dadosTemp)
                    notification.success({ message: 'Aviso', description: 'Forma de Pagamento editada com sucesso!' });
                }
                setEditando(false);
            } else {
                let dadosTemp = [...orcamento.orcamentoFormaPagamento];
                let dados = {};
                let campo = form.getFieldsValue();
                if (!campo.fpg_id) {
                    notification.warning({ message: 'Aviso', description: 'Informe uma forma de pagamento!' });
                } if (condicaoPagamento.length > 0 && !campo.cpg_id) {
                    notification.warning({ message: 'Aviso', description: 'Informe uma condição de pagamento!' });
                } else {
                    var condicao = condicaoPagamento.filter((condicao) => { return condicao.cpg_id === form.getFieldValue().cpg_id; })[0];
                    //var condicao = await api.get(`CondicaoPagamneto/Listar?codigoCondicaoPagamento=${form.getFieldValue().cpg_id}`);
                    var forma = await api.get(`FormaPagamento/Get?id=${form.getFieldValue().fpg_id}`);
                    dados.key = dadosTemp.length + 1;
                    dados.orc_id = form.getFieldValue().orc_id;
                    dados.fpg_id = form.getFieldValue().fpg_id;
                    dados.fpg_descricao = forma.data.fpg_descricao;
                    dados.cpg_id = !!form.getFieldValue().cpg_id ? form.getFieldValue().cpg_id: null;
                    dados.cpg_descricao = !!condicao && condicao.cpg_descricao ? condicao.cpg_descricao: '';
                    dados.orf_percentualacrescimo = form.getFieldValue().orf_percentualacrescimo;
                    dados.orf_percentualdesconto = form.getFieldValue().orf_percentualdesconto;
                    dados.orf_observacao = form.getFieldValue().orf_observacao;
                    dados.valoritens = form.getFieldValue().totalItens;
                    dados.valordesconto = parseFloat(form.getFieldValue().valorDesconto);
                    dados.valoracrescimo = parseFloat(form.getFieldValue().valorAcrescimo);
                    dados.valorPagamento = parseFloat(form.getFieldValue().valorTotal);

                    dadosTemp.push(dados); //adiciona os dados do novo orcamento a um array
                    //dados.key = retornaSequencial(dadosTemp); //seta o key no orcamento cadastrado
                    orcamento.setOrcamentoFormaPagamento(dadosTemp); //adiciona os dados temporarios a lista de orcamentos
                    notification.success({ message: 'Aviso', description: 'Forma de Pagametno incluída com sucesso!' });
                }
            }
        }
        setExibirDetalhes(true);
        limparCampos();
        recalcularTotal();
        setEditando(false)
    };

    function cancelar() {
        limparCampos();
        recalcularTotal();
        setEditando(false);
    };

    function limparCampos() {
        form.setFieldsValue({
            fpg_id: null, cpg_id: null, orf_percentualdesconto: 0, orf_percentualacrescimo: 0,// orc_valorfrete: 0, orc_valoroutros: 0, 
            valorAcrescimo: 0, valorDesconto: 0, orf_observacao: ''
        });
    };

    async function editarItem(registro) {
        setPagamentoTotal(false);
        setEditando(true);
        setDadosItemEditando(registro);

        await listarCondicao(registro.fpg_id, true)
        form.setFieldsValue({ fpg_id: registro.fpg_id });
        form.setFieldsValue({ cpg_id: registro.cpg_id });
        form.setFieldsValue({ valorTotal: registro.valorPagamento });
        form.setFieldsValue({ orf_observacao: registro.orf_observacao });
        form.setFieldsValue({ valorDesconto: registro.valordesconto });
        form.setFieldsValue({ valorAcrescimo: registro.valoracrescimo });
        form.setFieldsValue({ orf_percentualacrescimo: registro.orf_percentualacrescimo });
        form.setFieldsValue({ orf_percentualdesconto: registro.orf_percentualdesconto });
    };

    function removerItem(item) {
        let dadosTemp = [...orcamento.orcamentoFormaPagamento];
        dadosTemp = dadosTemp.filter((dados) => dados !== item);
        orcamento.setOrcamentoFormaPagamento(dadosTemp);
        setEditando(false)
    };

    return (
        <div className="m-t-16">
            <Row align="middle" justify="center" gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={16} xl={14} xxl={12}>
                    <Card extra={
                        <Row justify="center">
                            <Col>
                                <Typography.Title level={3}>
                                    Resumo do Orçamento
                                </Typography.Title>
                            </Col>
                        </Row>
                    }>
                        <Row justify="space-between" gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <InputPreco disabled label="Subtotal dos Itens (R$)" name="totalItens" className="text-right" />
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <InputPreco label="Valor do Frete (R$)" name="orc_valorfrete"
                                    onBlur={() => { recalcularTotal(); }}
                                />
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8} xl={68}>
                                <InputPreco label="Outras Despesas (R$)" name="orc_valoroutros"
                                    onBlur={() => { recalcularTotal(); }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Divider className="m-t-b-5" />
                            </Col>
                        </Row>
                        <Row justify="end">
                            <Col>
                                <Typography.Title level={4} className="m-0">
                                    Total do Orçamento (R$) {FormatNumber(valorTotal, true)}
                                </Typography.Title>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {!pagamentoTotal && <Col>
                <Row align="bottom" justify="space-between" gutter={[8, 0]}>
                    <Col span={12}>
                        <Form.Item label="Forma de Pagamento" name="fpg_id" >
                            <SelectPaginado url="FormaPagamento/Listar?Ativo=true&Order=+fpg_descricao" placeholder="Selecione Forma de Pagamento" form={form} name="fpg_id" onChangeFunction={(registro) => { listarCondicao(registro) }} conteudo={
                                fp => (<Select.Option value={fp.fpg_id} key={fp.key}>{fp.fpg_descricao}</Select.Option>)
                            } />
                        </Form.Item>
                    </Col>
                    {condicaoPagamento.length > 0 && <Col span={12}>
                        <Form.Item label="Condição de Pagamento" name="cpg_id">
                            <Select placeholder="Selecione Condição de Pagamento">
                                {condicaoPagamento.map((condicao) => (
                                    <Select.Option value={condicao.cpg_id} key={condicao.cpg_id}>{condicao.cpg_descricao}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>}
                </Row>
                <Row gutter={[8, 0]} justify="end">
                    <Col span={5}>
                        <Form.Item label="Percentual de Desconto" name="orf_percentualdesconto">
                            <InputNumber
                                defaultValue={0}
                                min={0}
                                max={100}
                                formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')}
                                onBlur={() => { vendedorDescMaxFinal(); }}
                                onChange={() => { calcularDesconto('PERC'); }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <InputPreco label="Valor do Desconto (R$)" name="valorDesconto" onBlur={() => { vendedorDescMaxFinal(); }} onChange={() => { calcularDesconto('VALOR'); }} />
                    </Col>
                    <Col span={5}>
                        <Form.Item label="Percentual de Acréscimo" name="orf_percentualacrescimo">
                            <InputNumber
                                defaultValue={0}
                                min={0}
                                max={100}
                                formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')}
                                onBlur={() => { calcularAcrescimo('PERC') }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={5}>
                        <InputPreco label="Valor do Acréscimo (R$)" name="valorAcrescimo" onBlur={() => { calcularAcrescimo('VALOR') }}
                        />
                    </Col>
                    <Col span={4}>
                        <InputPreco
                            label="Valor da Forma Pagamento (R$)"
                            name="valorTotal"
                            onBlur={() => { desconto() }}
                            defaultValue={"valorTotal"} />
                    </Col>
                    <Col span={24}> 
                        <Form.Item label="Observações da Forma de Pagamento" name="orf_observacao">
                            <Input.TextArea 
                                autoSize={{
                                    minRows: 2,
                                    maxRows: 6,
                                }}
                                maxLength={800}
                                placeholder="Informações Adicionais da Forma de Pagamento"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={10} lg={10} xl={4}>
                        <Button icon={<CloseOutlined />} onClick={() => (cancelar())} className="t-mob-573" block>
                            Cancelar
                        </Button>
                    </Col>
                    <Col xs={24} sm={12} md={10} lg={10} xl={4}>
                        <Button icon={<PlusOutlined />} type="primary" onClick={() => (adicionarLista())} className="t-mob-573" block>
                            Adicionar
                        </Button>
                    </Col>
                </Row>
            </Col>}

            <Row gutter={[0, 8]}>
                <Col span={24}>
                    <div className="tabela mt-dif">
                        {!!orcamento.orcamentoFormaPagamento && orcamento.orcamentoFormaPagamento.length > 0 &&
                            <Table columns={[
                                {
                                    title: 'Formas de Pagamento',
                                    width: 200,
                                    render: ({ fpg_descricao, cpg_descricao }) => (
                                        <div>
                                            <b>{fpg_descricao} {!!cpg_descricao ? `/ ${cpg_descricao}` : ''}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Desconto (R$)',
                                    width: 90,
                                    align: 'right',
                                    render: ({ valordesconto }) => (
                                        <div>
                                            <b>{FormatNumber(valordesconto, true)}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Acréscimo (R$)',
                                    width: 90,
                                    align: 'right',
                                    render: ({ valoracrescimo }) => (
                                        <div>
                                            <b>{FormatNumber(valoracrescimo, true)}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Valor (R$)',
                                    width: 90,
                                    align: 'right',
                                    render: ({ valorPagamento }) => (
                                        <div>
                                            <b>{FormatNumber(valorPagamento, true)}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Ações',
                                    width: 60,
                                    dataIndex: '',
                                    key: 'x',
                                    align: 'center',
                                    fixed: 'right',
                                    render: (record) => (
                                        <div>
                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                <Col>
                                                    <Button icon={<EditOutlined />} onClick={() => editarItem(record)} />
                                                </Col>
                                                <Col>
                                                    <Button icon={<DeleteOutlined />} onClick={() => removerItem(record)} />
                                                </Col>
                                            </Row>
                                        </div>
                                    ),
                                }
                            ]} expandable={{
                                expandedRowRender: ({ orf_observacao }) => (
                                    <div>
                                        <Row gutter={[8, 0]}>
                                            <Col span={24}>
                                                <b>Obs.: </b> { !!orf_observacao ? orf_observacao : "Não Informado" } 
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            }} dataSource={orcamento.orcamentoFormaPagamento} scroll={{ x: 900 }} />}
                    </div>
                </Col>
            </Row>
        </div>
    );
}