import React, { useEffect, useState } from "react";
import { Row, Col, Tag, Popover, notification, Modal, Table, Divider } from "antd";
import { CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { useStateValue } from '../../../state';
import { listagemActions } from "../../../actions";
import DrawerTributacaoIcms from '../../../pages/tributacaoIcms/drawer';

export default function PesquisaTributacaoIcms({ dados }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+icm_descricao' } }), []);

    function excluirRegistro(item) {
        setCarregando(true);
        api.delete(`tributacaoIcms/Excluir?icm_id=${item.icm_id}`).then((res) => {
            if (res.status === 200) notification.success({ message: 'Operação concluída', description: "Os dados do ICMS foram excluídos com sucesso!" });
            dispatch({ type: listagemActions.CHANGE, data: { label: "Nome Z - A", ordem: '+icm_descricao' } })
        }, err => {
            modalInativarConta(item);
        }).finally(() => {
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+icm_descricao' } });
        });
    }

    const modalExcluir = (record) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(record);
            }
        });
    };

    const modalInativarConta = (record) => {
        Modal.confirm({
            title: `Não foi possível excluir a Tributação ICMS ${record.icm_descricao}!`,
            icon: <CloseCircleOutlined />,
            content: `Deve conter referências com outros registros!`,
            okText: 'Inativar Registro',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                record.inativar = 1;
                record.icm_ativo = false;
                api.post(`tributacaoIcms/editar`, record).then((res) => {
                    if (res.status === 200) {
                        notification.success({ message: 'Operação concluída', description: "Tributação inativada com sucesso!" });
                    }
                    dispatch({ type: listagemActions.CHANGE, data: { label: "Nome Z - A", ordem: '+icm_descricao' } })
                }, err => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
                }).finally(() => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+icm_descricao' } });
                });
            }
        });
    };

    return (
        <div className="rowPanel text-center">
            <Row align="middle" justify="center" className="hg-100">
                <Col span={24} className="hg-100">
                    <div className="tabela">
                        <Table dataSource={dados} columns={
                            [
                                {
                                    title: 'Código',
                                    width: 75,
                                    align: 'center',
                                    render: ({ icm_id, icm_ativo }) => (
                                        <Popover content={icm_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                            <Tag color={icm_ativo ? 'processing' : 'default'} className="w-100">
                                                <b>{icm_id}</b>
                                            </Tag>
                                        </Popover>
                                    ),
                                },
                                {
                                    title: 'Tributações do ICMS',
                                    render: ({ icm_descricao }) => (
                                        <div>
                                            <b>{icm_descricao}</b>
                                        </div>
                                    ),
                                },/*
                                {
                                    title: 'Ações',
                                    dataIndex: '',
                                    key: 'x',
                                    align: 'center',
                                    width: 65,
                                    fixed: 'right',
                                    render: (record) => (
                                        <div>
                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                <Col>
                                                    <Button onClick={() => {
                                                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                                    }} icon={<EditOutlined />} />
                                                </Col>
                                                <Col>
                                                    <Button icon={<DeleteOutlined />} onClick={() => modalExcluir(record)} />
                                                </Col>
                                            </Row>
                                        </div>
                                    ),
                                },*/
                            ]
                        } locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }} scroll={{ x: 900 }} />
                    </div>
                    <DrawerTributacaoIcms />
                </Col>
            </Row>
        </div>
    );

}