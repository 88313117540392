import React, { useEffect, useMemo, useState } from "react";

import { useStateValue } from '../../state';

import { Link } from "react-router-dom";
import { Row, Col, Layout, Avatar, Form, Dropdown, Menu, Tooltip, Button, Drawer, Input, Typography, Badge, notification } from "antd";
import {
    SearchOutlined, UserOutlined, SettingFilled, DeleteOutlined, LogoutOutlined, AppstoreOutlined, BellFilled, SaveFilled, InfoCircleTwoTone,
    CheckOutlined, ExpandOutlined, WechatFilled, PrinterFilled, CloseOutlined, UnorderedListOutlined, RedoOutlined, ShoppingOutlined
} from '@ant-design/icons';
import { useFullScreen } from 'react-browser-hooks';
import Circle from '@uiw/react-color-circle';

import "./index.less";
import { ModalEmpresaFilial, ModalSelecaoClientesCat } from "../../components/modals/";
import { logout, getNomeEmpresa, getNomeLocal, getEmpresa, getIdUsuario, getEmailUsuario, getNomeUsuario, getStatusFilial, getNomeClienteCat, getClienteCat } from '../../services/auth';
import { ModulosPage, PesquisaGlobal, Perfil, DrawerPaginasAbertas, SincronizarCatalogo } from "../../components";

import { tributacaoActions, parametrosEmpresaActions } from "../../actions";
import api from "../../services/api";
import ServidorImpressao from "../../pages/etiqueta/servidorImpressao";
import { ServidorImpressoraOnline } from "../../services/servidorImpressora";
import apiWeb from "../../services/apiWeb";
import { isNotNullOrEmpty } from "../../services/funcoes";
import { loadShoppingCartItems } from "../../services/CarrinhoDB";
import CarrinhoCatalogo from "../../pages/CarrinhoCatalogo";

export default function HeaderPage() {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [openPerfil, setOpenPerfil] = useState(false);
    const [openBuscaGlobal, setOpenBuscaGlobal] = useState(false);
    const [openPaginaAbertas, setOpenPaginaAbertas] = useState(false);
    const [openModalModulos, setOpenModalModulos] = useState(false);
    const [openModalPesquisaGlobal, setOpenModalPesquisaGlobal] = useState(false);
    const [openModalEmpresaFilial, setOpenModalEmpresaFilial] = useState(false);
    const [paginasAbertas, setPaginasAbertas] = useState([]);
    const [formularioPerfil] = Form.useForm();
    const [listaNotificacoes, setListaNotificacoes] = useState([]);
    const [contador, setContador] = useState(0);
    const [image, setImage] = useState(false);
    const [openServidor, setOpenServidor] = useState(false);
    const [dadosBusca, setDadosBusca] = useState({});
    const [formularioServImp] = Form.useForm();
    const [openModalCliente, setOpenModalCliente] = useState(false);
    const [catalogo, setCatalogo] = useState(false);
    const [openDrawerSinc, setOpenDrawerSinc] = useState(false);
    const [clienteCatalogo, setClienteCatalogo] = useState("");
    const [openCart, setOpenCart] = useState(false);
    const [countItemCart, setCountItemCart] = useState(0);
    const [carrinhoItens, setCarrinhoItens] = useState([]);
    const { toggle } = useFullScreen()

    const showDrawerBusca = () => {
        setOpenBuscaGlobal(true);
    };

    const onCloseBuscaGlobal = () => {
        setOpenBuscaGlobal(false)
    };


    const showDrawerModulos = () => {
        setOpenModalModulos(true);
    };

    const onCloseModulos = () => {
        setOpenModalModulos(false)
    };

    const onClosePesquisaGlobal = () => {
        setOpenModalPesquisaGlobal(false);
        setOpenBuscaGlobal(false);
        localStorage.removeItem('@GestorWeb-PesquisaGlobal');
    };

    const showDrawerPerfil = async () => {
        await carregarAvatar();
        setOpenPerfil(true);
    };

    const openModalCart = () => {
        loadShoppingCartItems(setCarrinhoItens);
        setOpenCart(true);
    }

    const onCloseCart = () => {
        setOpenCart(false);
    }
    const onClosePerfil = async () => {
        setOpenPerfil(false);
    };

    const showDrawerServidor = () => {
        verificarServidor();
    };

    const showDrawerSincronizarCat = () => {
        setOpenDrawerSinc(true);
    };

    async function verificarServidor() {
        let servidorOn = await ServidorImpressoraOnline();
        if (servidorOn.online) {
            setOpenServidor(true)
        } else {
            notification.warn({ description: 'O Servidor de impressão não esta em execução nesse computador, para utilizar impressoras de etiquetas verifique as configurações!', message: 'Aviso' });
            setOpenServidor(false);
        }
    };

    const onCloseServidor = () => {
        setOpenServidor(false);
    };

    const avatar = useMemo(() => {
        return (<Avatar size="large" src={image} />);
    }, [image]);

    const menuUsuario = (

        <Menu className="col-avatar text-right">
            <Menu.Item key="1">
                <Button type="text" onClick={showDrawerPerfil}>
                    <UserOutlined /> Meu Perfil
                </Button>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="2">
                <Button type="text">
                    <Link to="/parametrosEmpresa">
                        <SettingFilled /> Configurações
                    </Link>
                </Button>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3">
                <Button type="text" onClick={showDrawerSincronizarCat}>
                    <RedoOutlined />Sincronizar Catalogo
                </Button>
            </Menu.Item>
            <Menu.Item key="4">
                <Button type="text" onClick={showDrawerServidor}>
                    <PrinterFilled /> Servidor de Impressão
                </Button>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="5">
                <Button type="text">
                    <a href="http://suporte.abase.com.br/login.php" target="_blank">
                        <WechatFilled /> Atendimento
                    </a>
                </Button>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="6">
                <Button type="text">
                    <DeleteOutlined /> Limpar Cache
                </Button>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="7">
                <Button type="text" onClick={() => logout()} block className="text-right">
                    <LogoutOutlined /> Sair
                </Button>
            </Menu.Item>
        </Menu>

    );

    const notificacoes = (
        <Menu className="col-notificaoes">
            <Menu.Item key="1">
                <Typography.Title level={4} className="text-center">
                    <Row gutter={[8, 8]}>
                        <Col span={24} align="middle">
                            Notificações

                            <Button type="link" size="size" icon={<CheckOutlined onClick={() => excluirNotificacoesDia()} />} />
                        </Col>
                    </Row>
                </Typography.Title>
            </Menu.Item>
            <Menu.Divider />
            <Menu>
                {
                    listaNotificacoes.map((notificacoes) => (
                        <Menu.Item key={notificacoes.key}>
                            <Tooltip title={notificacoes.not_descricao}>
                                <Link to="/notificacao">
                                    <InfoCircleTwoTone />{notificacoes.not_descricao}
                                </Link>
                            </Tooltip>
                        </Menu.Item>
                    ))
                }
                <Menu.Item className="menuNotificacoes">
                    <Link to="/notificacao">
                        Ver todas as notificações
                    </Link>
                </Menu.Item>
            </Menu>
        </Menu>
    );

    async function excluirNotificacoesDia() {
        await apiWeb.delete(`Notificacao/ExcluirTodosDia`).then(
            res => {
                notification.success({ message: "Sucesso!", description: "Mensagens lidas!" })
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    };

    async function listarNotificacoes() {
        await apiWeb.get(`Notificacao/ListarDoDia`).then(
            res => {
                let seq = 0;
                res.data.forEach((notif, idx) => {
                    notif.key = seq;
                    seq++;
                    if (idx === res.data.length - 1) {
                        setListaNotificacoes(res.data);
                        setContador(seq);
                    }
                })
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    async function carregarAvatar() {
        await apiWeb.get(`Usuario/AvatarBase64/${getEmailUsuario()}`).then(
            res => {
                setImage(`data:image/png;base64,${res.data}`);
            }
        ).catch(
            erro => {
                console.log(erro)
            }
        )
    };

    useEffect(() => {
        carregarAvatar();
        listarNotificacoes();
        if (window.location.href.indexOf('catalogo') > -1) {
            setCatalogo(true);
            let nome = getNomeClienteCat();
            if (!!nome) {
                setClienteCatalogo(nome);
            }
        } else {
            setCatalogo(false);
        }
    }, []);

    useEffect(() => {
        if (catalogo && !!manutencao.dados?.nomeCliente) {
            let nomeCliente = manutencao.dados.nomeCliente;
            setClienteCatalogo(nomeCliente);
        }
    }, [manutencao.dados]);

    async function efetuarBuscaGlobal(value) {
        await apiWeb.get(`BuscaGlobal/buscar?pesquisa=${value}&rota=`).then(
            res => {
                localStorage.setItem('@GestorWeb-PesquisaGlobal', value);
                setDadosBusca(res.data);
                setOpenModalPesquisaGlobal(true);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    const outrosConteudos = (
        <Menu>
            <Menu.Item>
                <a href="https://pdv.gestorweb.com.br/" target="_blank">
                    <Row align="middle" gutter={[8, 0]}>
                        <Col>
                            <img src={require("../../assets/i-new-pdv.png").default} alt="PDV Web" />
                        </Col>
                        <Col>
                            PDV Web
                        </Col>
                    </Row>
                </a>
            </Menu.Item>
        </Menu>
    );

    const infoCliCatalogo = (
        <Col xxl={4} xl={5} lg={8} md={9} sm={24} xs={24} className="hd-col">
            <Row align="middle" gutter={[8, 0]}>
                <div className="w-100">
                    <Tooltip title="Deseja Alterar o Cliente? Clique aqui!" placement="bottom" className="over">
                        <span>
                            Cliente: &nbsp;
                        </span>
                        <Button type="text" onClick={() => setOpenModalCliente(true)} className="btn-overflow emp">
                            <u> {clienteCatalogo} </u>
                        </Button>
                    </Tooltip>
                </div>
            </Row>
        </Col>
    );


    const [color, setColor] = useState({
        primaryColor: '#0053a6',
    });

    function onColorChange(nextColor) {
        const mergedNextColor = {
            ...color,
            ...nextColor,
        };
        setColor(mergedNextColor);
    }

    const teste = (teste) => {
        setClienteCatalogo(teste)
    }
    
    return (
        <Layout.Header>
            <Row align="middle" gutter={[8, 0]}>
                <Col xl={16} lg={13} md={11} sm={12} xs={7}>
                    <Row align="middle" gutter={[8, 0]}>
                        <Col xxl={4} xl={5} lg={8} md={9} sm={24} xs={24} className="logo-page text-center">
                            <Link to="/">
                                <img src={require("../../assets/logoGestorWhite.png").default} width="145" alt="Gestor Web" />
                            </Link>
                        </Col>
                        <Col xxl={16} xl={14} lg={16} md={15} className="hd-col">
                            <div className="w-100 f-14">
                                <span>
                                    Usuário:<b> {getNomeUsuario()} </b>
                                </span>
                            </div>
                            <div className="w-100">
                                <Tooltip title="Deseja Alterar a Empresa? Clique aqui!" placement="bottom" className="over">
                                    <span>
                                        Você está acessado a empresa: &nbsp;
                                    </span>
                                    <Button type="text" onClick={() => setOpenModalEmpresaFilial(true)} className="btn-overflow emp">
                                        <u>{`${getNomeEmpresa()} / ${getNomeLocal()}`}</u>
                                    </Button>
                                    {!getStatusFilial() && <span className="info-consult">LOCAL INATIVO (SOMENTE CONSULTA)</span>}
                                </Tooltip>
                            </div>
                        </Col>
                        {catalogo && infoCliCatalogo}
                    </Row>
                </Col>
                {!catalogo && <Col xl={8} lg={11} md={13} sm={12} xs={17} className="text-right">
                    <Tooltip title="Busca Global" placement="bottom">
                        <Button type="text" size="large" onClick={showDrawerBusca} icon={<SearchOutlined />} />
                    </Tooltip>
                    <Tooltip title="Alterar para Tela Cheia" placement="bottom">
                        <Button type="text" onClick={toggle}>
                            <ExpandOutlined />
                        </Button>
                    </Tooltip>
                    <Circle
                        colors={['#11a3b3', '#0053a6']}
                        color={color.primaryColor}
                        onChange={({ hex }) => {
                            onColorChange({
                                primaryColor: hex,
                            });
                        }}
                    />
                    <Dropdown overlay={notificacoes} trigger={['click']} placement="bottomRight" arrow>
                        <Badge count={contador}>
                            <Button type="text" size="large" icon={<BellFilled />} />
                        </Badge>
                    </Dropdown>
                    <Tooltip title="Páginas Abertas" placement="bottom">
                        <Badge count={paginasAbertas.length}>
                            <Button type="text" size="large" onClick={() => setOpenPaginaAbertas(true)} icon={<UnorderedListOutlined />} />
                        </Badge>
                    </Tooltip>
                    <Dropdown overlay={outrosConteudos} trigger={['click']} placement="bottomRight" arrow>
                        <Button type="text" size="large" icon={<AppstoreOutlined />} />
                    </Dropdown>
                    
                    <Dropdown overlay={menuUsuario} trigger={['click']} placement="bottomRight" arrow>
                        <span className="p-l-5">
                            {avatar}
                        </span>
                    </Dropdown>
                </Col>}
                {catalogo && <Col xl={8} lg={11} md={13} sm={12} xs={17} className="text-right" 
                style={{'display': 'flex', 'justify-content': 'flex-end', 'align-items': 'center'}}>
                    <div style={{'display': 'flex'}}>
                        <div style={{'width': '100%', 'margin-right': '30px', 'position': 'relative'}}>
                            <a style={{'font-size': '35px', 'color': 'white'}} href="#/carrinhoCatalogo">
                                <ShoppingOutlined />
                                <div style={{'background-color': 'red', 'display': 'none', 'position': 'absolute', 'border': '3px solid #1d6db3)',
                                'font-size': '12px', 'right': '0px','font-weight': 'bold', 'bottom': '0px', 'border-radius': '7px', 'width': '23px',
                                'justifyContent': 'center', 'margin-right': '-14px'}}>
                                    {countItemCart}
                                </div>
                            </a>
                        </div>
                    </div>
                    <Dropdown overlay={menuUsuario} trigger={['click']} placement="bottomRight" arrow>
                        <span className="p-l-5">
                            {avatar}
                        </span>
                    </Dropdown>
                </Col>}
            </Row>
            <DrawerPaginasAbertas exibirPagina={openPaginaAbertas} paginas={{ paginasAbertas, setPaginasAbertas }} fecharPagina={() => setOpenPaginaAbertas(false)} />
            <ModalEmpresaFilial exibirModalEmpresaFilial={openModalEmpresaFilial} fecharModalEmpresaFilial={() => setOpenModalEmpresaFilial(false)} />
            <ModalSelecaoClientesCat exibirModal={openModalCliente} onCloseModal={() => setOpenModalCliente(false)} teste={true} />
            <Drawer title="Busca Global"
                placement="top"
                className="hg-110"
                visible={openBuscaGlobal}
                destroyOnClose={true}
                closable={true}
                zIndex={openBuscaGlobal ? 1000 : -20}
                onClose={onCloseBuscaGlobal}>
                <Input.Search placeholder="Pesquisar..." allowClear onSearch={(value) => { efetuarBuscaGlobal(value) }} size="middle" />
            </Drawer>
            <Drawer title="Pesquisa Global"
                height='100%'
                placement="top"
                className="drawer-modulos"
                closable={true}
                visible={openModalPesquisaGlobal}
                onClose={onClosePesquisaGlobal}>
                <PesquisaGlobal dadosBusca={dadosBusca} openPesquisaGlobal={openModalPesquisaGlobal} />
            </Drawer>
            <Drawer title="Módulos"
                height='100%'
                placement="top"
                className="drawer-modulos"
                visible={openModalModulos}
                onClose={onCloseModulos}>
                <ModulosPage />
            </Drawer>
            {/* <Drawer title="Carrinho" width="30%" placement="right" visible={openCart} onClose={onCloseCart} >
                <div>
                    <div>
                        {carrinhoItens.map(item => (
                            <div key={item.codigo} className="FastShoppingCart">
                                <div className="codPeca" >{item.codigo}</div>
                                <span>{item.descricao}</span>
                                <span>Valor: R$ {item.valor}</span>
                                <div className="qtdItem">
                                    <span>Quantidade:</span>
                                    <p>{item.Qtd}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </Drawer> */}
            <Drawer title="Meu Perfil"
                width="50%"
                visible={openPerfil}
                onClose={onClosePerfil}
                footer={
                    <div>
                        <Row align="middle" justify="end" gutter={[8, 0]}>
                            <Col>
                                <Button onClick={onClosePerfil} icon={<CloseOutlined />} size="large" htmlType="button">
                                    Cancelar
                                </Button>
                            </Col>
                            <Col>
                                <Button icon={<SaveFilled />} size="large" type="primary" htmlType="submit" onClick={() => { formularioPerfil.submit() }}>
                                    Salvar
                                </Button>
                            </Col>
                        </Row>
                    </div>
                }>
                <Perfil formulario={formularioPerfil} aoSalvar={onClosePerfil} imagemAlterada={setImage} />
            </Drawer>
            <Drawer
                title="Configuração servidor de impressão"
                width="70%"
                visible={openServidor}
                onClose={onCloseServidor}
            >
                <ServidorImpressao formulario={formularioServImp} aoSalvar={onCloseServidor} menuConfig={true} servidor={{ servidorOn: true }} />
            </Drawer>
            <Drawer title="Sincronizar Catalogo"
                width="50%"
                visible={openDrawerSinc}
                onClose={() => setOpenDrawerSinc(false)}
            >
                <SincronizarCatalogo />
            </Drawer>
        </Layout.Header>

    );

}