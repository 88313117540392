import { Col, Form, Modal, Popover, Row, Table, Tag, notification } from "antd";
import { BotaoAbrirChamado, BotaoIncluirDados, BreadcrumbIncluirDados, BreadcrumbPage, Duplicar, Editar, Excluir, PesquisaOrdenacao, TabelaDados, FiltroProdutoKit } from "../../components";
import { CopyOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useStateValue } from "../../state";
import { drawerActions, manutencaoActions } from "../../actions";
import DrawerKitComposicao from "./drawer";
import { FormatNumber } from "../../ValueObjects";
import api from "../../services/api";
import { useEffect, useState } from "react";
import Item from "antd/lib/list/Item";
import { func } from "prop-types";

export default function KitComposicao({ formulario }) {
    const [{ manutencao }, dispatch] = useStateValue();
    const [listaKits, setListaKits] = useState([]);
    const [attList, setAttList] = useState(false);
    const [fechouCadastro, setFechouCadastro] = useState(false)

    useEffect (() => {
        carregarListaKit();
    }, [ , attList, fechouCadastro]);

    async function carregarListaKit () {
        try {
            const response = await api.get(`ProdutoKitComposicao/ListarTodos?pageNumber=1&PageSize=20`);
            setListaKits(response.data?.items);
        } catch (error) {
            console.error("Erro ao carregar lista de kits:", error);
        }
    };
    
    const modalExcluir = (dados, index) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o kit ${dados.pro_descricao}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                inativarKit(dados, index);
            }
        });
    };

    const inativarKit = async (dados, index) => {
        if (!!dados.pro_codigo) {
            let retorno = await api.put(`produto/AtivarInativar?IdProduto=${dados.pro_id}&Ativo=false`);
            if (!retorno) {
                notification.warning({ description: 'Aviso!', message: 'Não foi possível inativar o kit' });
                return;
            }
            notification.success({ description: 'Sucesso!', message: 'Kit inativado com sucesso!' });
            setAttList(true);
        }
        let listaTemp = [...listaKits];
        listaTemp.splice(index, 1);
        setListaKits(listaTemp)
    };
    console.log(listaKits)
    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: 'Nome A - Z', value: '+pro_descricao' },
                { label: 'Nome Z - A', value: '-pro_descricao' },
                { label: 'Código Crescente', value: '+pro_codigo' },
                { label: 'Código Decrescente', value: '-pro_codigo' },
            ]} />
            <div className="tabela">
                <Table dataSource={listaKits} columns={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ pro_codigo, pro_ativo }) => (
                            <Popover content={pro_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                <Tag color={pro_ativo ? 'processing' : 'default'} className="w-100">
                                    <b>{pro_codigo}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'EAN-13',
                        align: 'center',
                        width: 110,
                        render: ({ pro_ean }) => (
                            <div>
                                <b>{pro_ean}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Descrição',
                        render: ({ pro_descricao }) => (
                            <div>
                                <b>{pro_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Preço (R$)',
                        align: 'right',
                        render: ({ precoVendaMinimo, precoVendaMaximo }) => (
                            <div>
                                {precoVendaMinimo === precoVendaMaximo &&
                                    <b className="f-18" >{FormatNumber(precoVendaMinimo, true)}</b>
                                }
                                {precoVendaMinimo !== precoVendaMaximo &&
                                    <b className="f-18" >{FormatNumber(precoVendaMinimo, true)} até {FormatNumber(precoVendaMaximo, true)}</b>
                                }
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 95,
                        fixed: 'right',
                        render: (record, dados) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Editar
                                            onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} icon={<EditOutlined />}
                                        />
                                    </Col>
                                    <Col>
                                        <Excluir icon={<DeleteOutlined />} onClick={() => { modalExcluir(record) }} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerKitComposicao setFechouCadastro={setFechouCadastro} fechouCadastro={fechouCadastro}/>
        </div>
    );
}