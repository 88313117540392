import React from "react";
import { Row, Col, Form, Empty, Button, Modal } from 'antd';
import {FormularioDinamico} from "../../../components"
import moment from 'moment'

export default function ModalPersonalizacaoFormularioFiltros({ exibirModalPersonalizacao, fecharModalPersonalizacao, elementosFormulario }) {

    const onCloseModal = () => {
        fecharModalPersonalizacao();
    };

    function finish(values){
        //altera o Retorno dos campos data
        for (let valor in values){
            if (!!Date.parse(values[valor]) && typeof values[valor] === "object" && values[valor].toString().length > 30){
                values[valor] = moment(values[valor]).format("DD/MM/YYYY")
            }
        }
        console.log({values}); 
        alert(JSON.stringify(values));
    }

    const [ form ] = Form.useForm();

    return (
        <Modal centered
            title="Pré-Visualização do Formulário"
            visible={exibirModalPersonalizacao}
            maskClosable={false}
            destroyOnClose
            width={720}
            onCancel={() => {
                onCloseModal();
            }} footer={null}>
            <div className="col-pre">
                {!!elementosFormulario && elementosFormulario.length > 0 && 
                    <div>
                    <Form form={form} layout="vertical" onFinish={(v) => finish(v)}>
                        <FormularioDinamico formulario={form}  parametrosFiltros={elementosFormulario}/> 
                    </Form>                     
                    <Row justify="center">
                        <Col>
                            <Form.Item>
                                <Button type="primary" onClick={() => form.submit()} htmlType="submit">TESTAR RETORNOS </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    </div>
                }
                {!!elementosFormulario && elementosFormulario.length === 0 && <Empty/>}
            </div>
        </Modal>
    );
}