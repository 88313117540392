import React from 'react';
import { Row, Col, Typography, Carousel } from 'antd';
import { EnvironmentOutlined, PhoneOutlined, DesktopOutlined } from '@ant-design/icons';

import "./index.less";

export default function SlideLogin() {

    return (
        <div>
            {/* <div className="colLogo">
                <Row align="middle" justify="end">
                    <Col span={24}>
                        <Row align="middle" justify="end" gutter={[8, 0]}>
                            <Col>
                                <DesktopOutlined /> 
                                <Typography.Link href="https://www.gestorweb.com.br/" target="_blank" underline> www.gestorweb.com.br</Typography.Link> | <PhoneOutlined /> (55) 3535-4900
                            </Col>
                            <Col>
                                | <EnvironmentOutlined /> Três de Maio - RS - 98910-000
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row align="middle" justify="end">
                            <Col>
                                Copyright © Gestor Web 2022 - Todos os direitos reservados.
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div> */}
            <Carousel effect="fade" dots={false} autoplay>
                <div>
                    <div className="col-banners" style={{backgroundImage: `url(${require("../../assets/img-home.jpg").default})`}}></div>
                </div>
                <div>
                    <div className="col-banners" style={{backgroundImage: `url(${require("../../assets/img-home-01.jpg").default})`}}></div>
                </div>
                <div>
                    <div className="col-banners" style={{backgroundImage: `url(${require("../../assets/img-home-02.jpg").default})`}}></div>
                </div>
                <div>
                    <div className="col-banners" style={{backgroundImage: `url(${require("../../assets/img-home-03.jpg").default})`}}></div>
                </div>
            </Carousel>
        </div>
    );

}