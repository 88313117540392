import React, { useEffect, useState } from 'react';
import { Select} from 'antd';
import api from '../../../services/api';
import { isNotNullOrEmpty } from '../../../services/funcoes';

export default function SelectAll({ url, idValue, nameValue, nameLabel, onChangeFunction, onBlurFunction, onClearFunction, campoFiltro = "filtro", placeholder,
    form, atualizaComponente = false, mostrarCodigoLabel = false, multiple = false, allowClear = false, conteudo = null, filtroPesq = null, filtroExtra,
    disabled = false, returnObject = false, onSelectFunction }) {
    const [carregando, setCarregando] = useState(false);
    const [lista, setLista] = useState([]);
    const [filtro, setFiltro] = useState('');
    const [usouFiltro, setUsouFiltro] = useState(false);
    const [valor, setValor] = useState(null);
    const [campoDescricao, setCampoDescricao] = useState(null);
    const [filtroAnt, setFiltroAnt] = useState("");
    const [multiplosFiltros, setMultiplosFiltros] = useState(false);
    const [listaFiltros, setListaFiltros] = useState([]);
    const _nameValue = idValue ? idValue : nameValue;

    useEffect(() => carregarDados(), [filtro, atualizaComponente]);

    useEffect(() => {
        setFiltro(filtroPesq);
    }, [filtroPesq, atualizaComponente]);

    useEffect(() => {
        if (!isNotNullOrEmpty(form.getFieldValue([_nameValue]))) {
            setFiltro('');
        }
        setValor(form.getFieldValue([_nameValue]));
    }, [form.getFieldValue([_nameValue])]);

    function carregarDados() {
        setCarregando(true);
        let parametrosUrl = '';
        if (atualizaComponente !== null) {
            if (!multiplosFiltros) {
                !!filtro ? parametrosUrl = `&${!!campoDescricao ? campoDescricao : campoFiltro}=${filtro}` : parametrosUrl = "";
            } else {
                !!listaFiltros && listaFiltros.length > 0 ? listaFiltros.forEach(lf => parametrosUrl += `&${!!lf.campoDescricao ? lf.campoDescricao : lf.campoFiltro}=${lf.filtro}`) : parametrosUrl = "";
            }
            if (!!campoDescricao) {
                setCampoDescricao(null);
            }
            if (mostrarCodigoLabel) {
                parametrosUrl += `&Order=%2B${nameValue}`;
            }
            else {
                parametrosUrl += (nameLabel.search('[/+/g]') > 0 ? `&Order=%2B${nameLabel.split('+')[0]}` : `&Order=%2B${nameLabel}`);
            }
            if (url.search(/[?]/g) === -1) {
                parametrosUrl = parametrosUrl.replace('&', '?');
            }
            !!filtroExtra ? parametrosUrl += filtroExtra : naoFazNada();
            api.get(url + parametrosUrl).then(
                (res) => {
                    if (res.status === 200) {
                        let dados = [];

                        let indice = !!atualizaComponente ? 0 : lista.length > 0 ? lista[lista.length - 1].key + 1 : 0;
                        res.data.map((item, idx) => {
                            item.key = indice;
                            dados.push(item);
                            indice++;
                        });

                        if (!isNotNullOrEmpty(filtro)) {//filtro em branco
                            if (usouFiltro === true || atualizaComponente !== false) {
                                atualizaComponente = false
                                setUsouFiltro(false);
                                setLista(dados);
                            } else {
                                setLista([...lista, ...dados]);
                            }
                        } else {
                            if (filtro === filtroAnt) {
                                setLista(dados);
                            } else {
                                setUsouFiltro(true);
                                setFiltroAnt(filtro);
                                setLista(dados);
                            }
                        }
                    }
                }
            ).finally(
                () => {
                    setCarregando(false)
                }
            );
        }
    }

    function naoFazNada() { }

    return (
        <Select
            loading={carregando}
            mode={multiple ? "multiple" : ""}
            showSearch
            allowClear={allowClear}
            placeholder={placeholder}
            value={valor}
            disabled={disabled}
            optionFilterProp="children"
            filterOption={(input, option) => {
                if (option.props.value) {
                    return true;
                } else {
                    return false;
                }
            }}
            onSearch={(valor) => { setFiltro(valor); }}
            onChange={(valor) => {
                setValor(valor);
                form.setFieldsValue({ [_nameValue]: valor });
                !!onChangeFunction ? onChangeFunction(valor) : naoFazNada();
            }}
            onBlur={(valor) => {

                !!onBlurFunction ? onBlurFunction(valor) : naoFazNada();
            }}
            onClear={(valor) => {
                !!onClearFunction ? onClearFunction(valor) : naoFazNada();
            }}
            // onSelect={(valor) => {
            //     setValor(valor);
            //     form.setFieldsValue({ [_nameValue]: valor });
            //     !!onSelectFunction ? onSelectFunction(valor) : naoFazNada();
            //     console.log(valor);
            // }}
        >
            {lista.map(item =>
                !!conteudo ? conteudo(item) :
                    <Select.Option key={item.key} value={returnObject ? JSON.stringify(item) : item[nameValue]}>{mostrarCodigoLabel ? item[nameValue] + " - " : ""} {(nameLabel.search(/[+]/g) > 0 ? (item[nameLabel.split('+')[0]] + " - " + item[nameLabel.split('+')[1]]) : item[nameLabel])} </Select.Option>
            )}
        </Select>
    )
}
