import React, { useState } from 'react';
import { Row, Col, Tag, Table, Button } from "antd";

import { FormatNumber } from '../../ValueObjects';
import moment from 'moment';

export default function TabNotaAntecipacaoDevolucao({ antecipacaoDevolucao }) { 
    return (
        <div className="tabela">
            <Table dataSource={antecipacaoDevolucao} columns={
                [
                    {
                        title: 'Tipo Movimento',
                        width: 30,
                        align: 'center',
                        render: ({ tipoMovimento }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{!!tipoMovimento ? tipoMovimento : ''}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Data',
                        width: 30,
                        align: 'center',
                        render: ({ datadoc }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{!!datadoc ? moment(datadoc).format('DD/MM/YYYY') : ''}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Informações',
                        width: 140,
                        align: 'center',
                        render: ({ informacoes }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{!!informacoes ? informacoes : ''}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Valor Total',
                        width: 30,
                        align: 'center',
                        render: ({ valor }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{!!FormatNumber(valor, true) ? FormatNumber(valor, true) : '0,00'}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    }
                ]
            } />
        </div>
    );
}