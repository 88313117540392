import React from "react";
import { Modal, Table } from "antd";
import moment from "moment";

export default function ModalDetalheLoteNfi({ exibirModal, fecharModal, item }) {

    return (
        <Modal centered 
            title={`Lotes do Produto: ${item.pro_descricao}`}
            visible={exibirModal}
            onCancel={fecharModal}
            width='60%'
            maskClosable={false}
            footer={null}
        >
            <div>
                <Table 
                    columns={[
                        {
                            title: 'Nº',
                            align: 'center',
                            width: 170,
                            render: ({ prl_numerolote }) => (
                                <div>
                                    <b>{prl_numerolote}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Qtde',
                            align: 'center',
                            width: 75,
                            render: ({ prl_qtde }) => (
                                <div>
                                    <b>{prl_qtde}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Data Entrada',
                            align: 'left',
                            width: 160,
                            render: ({ prl_dataentrada }) => (
                                <div>
                                    <b>{moment(prl_dataentrada).format('DD/MM/YYYY')}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Data Fabricação',
                            align: 'left',
                            width: 160,
                            render: ({ prl_datafabricacao }) => (
                                <div>
                                    <b>{moment(prl_datafabricacao).format('DD/MM/YYYY')}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Data Vencimento',
                            align: 'left',
                            width: 160,
                            render: ({ prl_datavencimento }) => (
                                <div>
                                    <b>{moment(prl_datavencimento).format('DD/MM/YYYY')}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Informação Adicional',
                            align: 'left',
                            render: ({ prl_infoadicional}) => (
                                <div>
                                    <b>{prl_infoadicional}</b>
                                </div>
                            )
                        }
                    ]}
                    dataSource={item.itemLote}
                    pagination={false}
                />
            </div>
        </Modal>
    )
}