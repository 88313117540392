import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Select } from "antd";
import api from "../../services/api";
import { InputCep } from "../../components";

export default function TabEndereco({ formulario, listaTipoEndereco, cidade, setCidade, listaPais }) {

    const [openDropdown, setOpenDropdown] = useState(false);

    function verificaCep(cep) {
        if (!!cep) {
            api.get(`Cidade/ListarCidadeCep?Cep=${cep.match(/\d+/g).join('')}`).then(
                res => {
                    formulario.setFieldsValue({ cid_descricao: res.data.length > 0 ? res.data[0].cid_descricao : '' });
                    setCidade(res.data[0]);
                    formulario.setFieldsValue({ est_sigla: res.data.length > 0 ? res.data[0].est_sigla : '' });
                }
            )
        }
    }

    return (
        <div className="pages-col">
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={8} md={4} lg={4} xl={4} xxl={4}>
                    <Form.Item label="País" name="pse_id">
                        <Select
                            showSearch
                            optionFilterProp="children"
                            placeholder="Informe o País"
                            allowClear
                        >
                            {listaPais.map(pais => (
                                <Select.Option value={pais.pse_id} key={pais.pse_id}>{pais.pse_nome}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={4} lg={4} xl={4} xxl={4}>
                    <InputCep label="CEP" name="cep_cep" rules={[{ required: true, message: 'Informe o CEP' }]} onBlur={(cep) => verificaCep(cep.target.value)} onPressEnter={(cep) => verificaCep(cep.target.value)} />
                </Col>
                <Col xs={18} sm={8} md={5} lg={5} xl={5} xxl={5}>
                    <Form.Item label="Cidade" name="cid_descricao">
                        <Input placeholder="Informe a Cidade" />
                    </Form.Item>
                </Col>
                <Col xs={6} sm={3} md={2} lg={2} xl={3} xxl={3}>
                    <Form.Item label="Estado" name="est_sigla">
                        <Input placeholder="UF" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={21} md={9} lg={9} xl={8} xxl={8}>
                    <Form.Item label="Endereço" name="log_logradouro" rules={[{ required: true, message: 'Informe o Endereço' }]}>
                        <Input placeholder="Informe o Endereço" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={4} lg={4} xl={4} xxl={4}>
                    <Form.Item label="Bairro" name="bai_nome" rules={[{ required: true, message: 'Informe o Bairro.' }]}>
                        <Input placeholder="Informe o Bairro" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={4} lg={4} xl={4} xxl={4}>
                    <Form.Item label="Número" name="pee_numero">
                        <Input placeholder="Informe o Número" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={5} lg={4} xl={4}>
                    <Form.Item label="Complemento" name="pee_complemento">
                        <Input placeholder="Informe o Complemento" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={6} lg={6} xl={4} xxl={4}>
                    <Form.Item label="Tipo de Endereço" name="tpe_id" rules={[{ required: true, message: 'Tipo de Endereço' }]}>
                        <Select
                            onFocus={() => { setOpenDropdown(true) }}
                            onBlur={() => setOpenDropdown(false)}
                            onSelect={() => setOpenDropdown(false)}
                            allowClear
                            showSearch
                            open={openDropdown}
                            optionFilterProp="children"
                            placeholder="Informe o Tipo de Endereço">
                            {listaTipoEndereco.map((te) => (
                                <Select.Option value={te.tpe_id} key={te.tpe_id}>{te.tpe_descricao}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={5} lg={5} xl={5} xxl={5}>
                    <Form.Item label="Coordenadas" name="coordenadas">
                        <Input placeholder="Informe as Coordenadas" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="Local de Referência" name="pee_localreferencia">
                        <Input.TextArea placeholder="Informe o Local de Referência" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24} className="m-t-8">
                    MAPA
                </Col>
            </Row>
        </div>
    );
}