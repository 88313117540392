import React, { useState } from 'react';
import { Row, Col, Tag, Table, Button } from "antd";

import { FormatNumber } from '../../ValueObjects';
import moment from 'moment';

export default function TabNotaReferenciada({ docsreferenciados }) {
    return (
        <div className="tabela">
            <Table dataSource={docsreferenciados} columns={
                [
                    {
                        title: 'N°',
                        width: 30,
                        align: 'center',
                        render: ({ ntf_numero }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{!!ntf_numero ? ntf_numero : ''}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Data',
                        width: 30,
                        align: 'center',
                        render: ({ datadoc }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{!!datadoc ? moment(datadoc).format('DD/MM/YYYY') : ''}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Chave',
                        width: 140,
                        align: 'center',
                        render: ({ dfr_chave }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{!!dfr_chave ? dfr_chave : ''}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Valor Total',
                        width: 30,
                        align: 'center',
                        render: ({ totaldoc }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>{!!FormatNumber(totaldoc, true) ? FormatNumber(totaldoc, true) : '0,00'}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    }
                ]
            } />
        </div>
    );
}