import React, { useState } from "react";
import { Col, Descriptions, Form, Modal, notification, Row, Select } from "antd";
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import SelectPaginacao from "../../selects/selectPaginacao";
import api from "../../../services/api";
import moment from "moment";

export default function ModalAdicionarMotorista({ exibirModal, fecharModal, dados }) {
    const [form] = Form.useForm()
    const [carregando, setCarregando] = useState(false);

    const cancelarModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                form.resetFields();
                fecharModal();
            }
        });
    }

    const incluirMotorista = () => {
        setCarregando(true);
        let dadosForm = form.getFieldsValue();

        if (dadosForm.fun_id !== undefined) {
            let dadosMotorista = {
                fun_id: dadosForm.fun_id,
                mdf_id: dados.mdf_id
            }
            api.post(`ManifestoEletronico/IncluirCondutor`, dadosMotorista).then(
                res => {
                    let _cStat = res.data.cStat;
                    let motivo = _cStat + " - " + res.data.xMotivo;
                    if (_cStat === 135) {
                        notification.success({ message: 'Novo condutor incluído com sucesso!', description: motivo });
                    } else {
                        notification.warning({ message: 'Não foi possível incluir um novo condutor!', description: motivo })
                    }
                }
            ).catch(
                erro => {
                    console.error(erro);
                    notification.warning({ message: 'Atenção!', description: 'Não foi possível incluir um novo motorista' });
                    setCarregando(false);
                }
            ).finally(
                () => {
                    form.resetFields();
                    setCarregando(false);
                    fecharModal();
                }
            );
        } else {
            notification.warning({ message: 'Atenção!', description: 'É necessário selecionar um motorista para realizar a inclusão' });
        }
    }

    return (
        <div>
            <Modal centered
                visible={exibirModal}
                title="Adicionar novo Condutor"
                onCancel={cancelarModal}
                width={500}
                onOk={incluirMotorista}
                confirmLoading={carregando}
                okText={
                    <>
                        <CheckOutlined /> Incluir
                    </>
                }
                cancelText={
                    <>
                        <CloseOutlined /> Cancelar
                    </>
                }
                maskClosable={false}
                destroyOnClose
            >
                <Form layout="vertical" name="selecionarMotorista" form={form}>
                    <Descriptions size="small">
                        <Descriptions.Item label="Nº MDF-e">
                            <b> {dados.mdf_numero} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Data de Emissão">
                            <b> {moment(dados.mdf_dataemissao).format('DD-MM-YYYY')} </b>
                        </Descriptions.Item>
                    </Descriptions>
                    <Descriptions size="small">
                        <Descriptions.Item label="Chave">
                            <b> {dados.mdf_chavemdfe} </b>
                        </Descriptions.Item>
                    </Descriptions>
                    <Row align="middle" gutter={[8, 0]} style={{ marginTop: '10px'}}>
                        <Col xs={24} sm={12} md={12} lg={7} xl={24}>
                            <Form.Item label="Motorista" name="fun_id" rules={[{ required: true }]}>
                                <SelectPaginacao url="Funcionario/Listar" placeholder="Selecione o Transportador" form={form} nameValue="fun_id" nameLabel="pes_nome" allowClear={true} conteudo={
                                    fun => (<Select.Option value={fun.fun_id} key={fun.fun_id}>{fun.pes_nome}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}