import React, { useEffect, useState } from "react";
import { Row, Col, Descriptions, Radio, Form, Input, Modal, notification } from "antd";
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../../services/api";
import { getLocal } from "../../../services/auth";
import { FormatNumber, MaskFormat } from "../../../ValueObjects";

export default function ModalManifestoDestinatario({ exibirModal, fecharModal, dadosNota }) {

    const [formulario] = Form.useForm();
    const [carregando, setCarregando] = useState(false);

    useEffect(() => {
        formulario.setFieldsValue({ tipoManifesto: dadosNota.tipoManifesto });
    }, [dadosNota]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
            }
        });
    };

    function retornaDescricaoManifestoDestinatario(nfd_manifestodestinatario) {
        switch (nfd_manifestodestinatario) {
            case 0:
                return 'Ciência da Emissão';
            case 1:
                return 'Confirmação da Operação';
            case 2:
                return 'Operação não Realizada';
            case 3:
                return 'Desconhecimento da Operação';
            default:
                return null;
        }
    };

    function manifestarNota() {
        let justificativa = !!formulario.getFieldValue().justificativa ? formulario.getFieldValue().justificativa : '';
        api.post(`NotaFiscal/ManifestarNfe?TipoManifesto=${formulario.getFieldValue().tipoManifesto}&ChaveNfe=${dadosNota.nfd_chavenfe}&Justificativa=${justificativa}`).then(
            (res) => {
                if (res.data.retorno.cStat === 128) {
                    if (res.data.retorno.retEvento[0].infEvento.cStat === 135) {
                        notification.success({ description: res.data.retorno.retEvento[0].infEvento.xMotivo, message: `Manifestação do Destinatário` });
                    } else {
                        notification.error({ description: res.data.retorno.retEvento[0].infEvento.xMotivo, message: `Manifestação do Destinatário` });
                    }
                } else {
                    setCarregando(false);
                    notification.error({ description: res.data.retorno.xMotivo, message: `Manifestação do Destinatário` });
                }
            }
        ).finally(() => {
            fecharModal();
        });
    };

    return (
        <Modal centered
            title="Manifestação do Destinatário"
            visible={exibirModal}
            confirmLoading={carregando}
            onCancel={onCloseModal}
            onOk={manifestarNota}
            okText={
                <>
                    <CheckOutlined /> Realizar Manifestação
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            width={720}
            maskClosable={false}
        >
            <div>
                <div>
                    <Row align="middle" justify="space-between">
                        <Col>
                            Chave da NF-e: <b>{dadosNota.nfd_chavenfe}</b>
                        </Col>
                    </Row>
                    <Row align="middle" justify="space-between" gutter={[16, 0]}>
                        <Col>
                            Nota Emitida por: <b>{dadosNota.nfd_nomeemissor}</b>
                        </Col>
                        <Col>
                            CPF/CNPJ do Emissor: <b>{MaskFormat(dadosNota.nfd_cpfcnpjemissor, '', true)}</b>
                        </Col>
                    </Row>
                    <Row align="middle" justify="space-between" gutter={[16, 0]}>
                        <Col>
                            Nº da Nota: <b>{dadosNota.nfd_numeronf}</b>
                        </Col>
                        <Col>
                            Série: <b>{dadosNota.nfd_serienf}</b>
                        </Col>
                        <Col>
                            Modelo: <b>{dadosNota.nfd_modelodocumento}</b>
                        </Col>
                        <Col>
                            Emitida em: <b>{moment(dadosNota.nfd_datahoraemissao).format('DD/MM/YYYY HH:mm:ss')}</b>
                        </Col>
                    </Row>
                    <Row align="middle" justify="space-between" gutter={[16, 0]}>
                        <Col>
                            Tipo de Nota: <b>{dadosNota.tipoNf}</b>
                        </Col>
                        <Col>
                            Valor Total da Nota (R$): <b>{FormatNumber(dadosNota.nfd_valortotal, true)}</b>
                        </Col>
                        <Col>
                            Última Manifestação Realizada: <b>{retornaDescricaoManifestoDestinatario(!!dadosNota.nfd_manifestodestinatario) ? (dadosNota.nfd_manifestodestinatario) : 'Não Informada'}</b>
                        </Col>
                    </Row>
                </div>
                <div className="m-t-8">
                    <Form layout="vertical" name="manutencaoOrigemDestino" form={formulario}>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={24}>
                                Selecione abaixo o tipo de manifestação que deseja para esta Nota Fiscal
                            </Col>
                            <Col span={24}>
                                <Form.Item name="tipoManifesto">
                                    <Radio.Group>
                                        {!(!!dadosNota.tipoManifesto) &&
                                            <Radio value={0}>
                                                <strong>Ciência da Emissão: </strong>
                                                Registra que o destinatário da operação, constante nesta NF-e, tem conhecimento que o documento foi emitido, mas ainda não expressou uma manifestação conclusiva para a operação.
                                            </Radio>
                                        }
                                        <Radio value={1}>
                                            <strong>Confirmação da Operação: </strong>
                                            Registra que o destinatário da operação, constante nesta NF-e, confirma que a operação descrita na NF-e ocorreu.
                                        </Radio>
                                        <Radio value={2}>
                                            <strong>Operação não Realizada: </strong>
                                            Registra que o destinatário da operação, constante nesta NF-e, declara que a operação descrita na NF-e foi solicitada, porem não foi efetivada. Ex.: Devolução sem entrada física de mercadorias no estabeleciomento do destinatário, sinistro da carga durante o transporte, etc.
                                        </Radio>
                                        <Radio value={3}>
                                            <strong>Desconhecimento da Operação: </strong>
                                            Registra que o destinatário da operação, constante nesta NF-e, declara que a operação descrita na NF-e NÃO foi solicitada, informando ao fisco a utilização indevida de sua Inscrição Estadual/CNPJ por parte do emitente da NF-e em questão.
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={24}>
                                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.tipoManifesto !== currentValues.tipoManifesto}>
                                    {({ getFieldValue }) => {
                                        if (getFieldValue('tipoManifesto') === 2) return (
                                            <Form.Item label="Justificativa" name="justificativa">
                                                <Input.TextArea placeholder="Informe Aqui o Motivo da não Realização da Operação" />
                                            </Form.Item>
                                        );
                                    }}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </Modal>
    );
}