import React from "react";
import { Row, Col, Form, Input, notification, Modal } from "antd";
import { EyeTwoTone, EyeInvisibleOutlined, ExclamationCircleOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";

import api from "../../../services/api";

export default function ModalSenhaLiberacao({ exibirModal, fecharModal }) {

    const [formDados] = Form.useForm();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal(false);
            }
        });
    };

    const salvarDados = values => {
        api.get(`Local/ValidarSenhaGerencial?senhaGerencial=${values.senha}`).then(
            (res) => {
                if (res.status === 200) {
                    if (res.data) {
                        fecharModal(true)
                    } else {
                        notification.error({ message: 'Senha incorreta!', description: "Favor informar uma senha válida!" });
                    }
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(
            () => {
                formDados.setFieldsValue({ senha: null });
            }
        )
    };

    return (
        <Modal centered
            title="Senha Gerencial!"
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={() => { formDados.submit() }}
            okText={
                <>
                    <CheckOutlined /> Liberar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }   
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="formSenhaLiberacao" form={formDados} onFinish={salvarDados}>
                    <Row>
                        <Col>
                            Informe a Senha Gerencial para poder realizar este procedimento.
                        </Col>
                    </Row>
                    <Form.Item name="senha">
                        <Input.Password
                            placeholder="Informe a senha gerencial"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />

                    </Form.Item>
                </Form>

            </div>
        </Modal>
    );
};
