import React, { useEffect, useRef, useState } from "react";
import moment from 'moment';
import { Row, Col, Form, Select, Button, Switch, Table, Tag, notification, Modal, Input } from "antd";
import { UndoOutlined, EditOutlined, DeleteOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { FormGW, Editar, Excluir, Data } from "../../components";
import api from "../../services/api";

import { useStateValue } from '../../state';
import { listagemActions, manutencaoActions } from "../../actions";
import { ModalDevolucaoCondicional } from "../../components/modals";
import DrawerNFExtra from "./drawerNFExtra";
import { FormatNumber } from "../../ValueObjects";

export default function DevolucaoCondicional({ formulario, aoSalvar, dadosItens, exibirDrawerDevolucao, setCarregando, setExibirDrawerNota }) {

    const [formItemCondicional, setFormItemCondicional] = useState([]);
    const [{ manutencao }, dispatch] = useStateValue();
    const [openModal, setOpenModal] = useState(false);
    const [riscarCampos, setRiscarCampos] = useState(false);
    const [checkedSwitch, setCheckedSwitch] = useState(false);
    const [dadosDevolucao, setDadosDevolucao] = useState({});
    const [quantidadeTot, setQuantidadeTot] = useState(0);
    const [valorTot, setValorTot] = useState(0.00);
    const codBarras = useRef();

    useEffect(() => {
        let dadosTemp = dadosItens.dadosItens;
        if (checkedSwitch) {
            setRiscarCampos(true);
            dadosTemp.forEach((item) => {
                item.vci_devolvido = item.vci_quantidade;
                item.devolvidoTotal = true;
            })
        }
        else {
            setRiscarCampos(false);
            dadosTemp.forEach(
                (item) => {
                    item.vci_devolvido = 0;
                    item.devolvidoTotal = false;
                }
            )
        }
        dadosItens.setDadosItens(dadosTemp);
    }, [checkedSwitch])

    useEffect(() => {
        if (!!!dadosItens.dadosItens || dadosItens.dadosItens.length === 0) {
            carregarDados();
        }
    }, [dadosDevolucao]);

    useEffect(() => {
        let dadosTemp = dadosItens.dadosItens;
        dadosTemp.forEach((item) => {
            if (item.vci_devolvido === item.vci_quantidade) {
                item.devolvidoTotal = true;
            }
        })
    }, [dadosItens.dadosItens])

    useEffect(() => {
        if (!!manutencao.dados) {
            if (!!manutencao.dados.length && manutencao.dados.length > 0) {
                let key = 0;
                manutencao.dados.forEach((item) => {
                    item.key = key++;
                })
                dadosItens.setDadosItens(manutencao.dados);
            } else {
                setDadosDevolucao(manutencao.dados);
            }
        }
    }, [manutencao.dados]);

    function recalcularTotais(dados) {
        let qtd = 0;
        let valor = 0;
        dados.forEach((dado) => {
            qtd += (dado.vci_quantidade - dado.vci_devolvido);
            valor += dado.vci_valorun * (dado.vci_quantidade - dado.vci_devolvido);
        });
        setValorTot(valor);
        setQuantidadeTot(qtd);
    };

    useEffect(() => {
        recalcularTotais(dadosItens.dadosItens);
    }, [dadosItens.dadosItens]);

    function desfazerDevolucao(record) {
        let dadosTemp = dadosItens.dadosItens;
        dadosTemp.filter((item) => { return item.vci_id === record.vci_id })[0].vci_devolvido = 0;
        dadosTemp.filter((item) => { return item.vci_id === record.vci_id })[0].devolvidoTotal = false;
        dadosItens.setDadosItens(dadosTemp);
        recalcularTotais(dadosTemp);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dadosTemp } });
    };

    const salvar = (record) => {
        let dadosCondicional = {
            vnc_id: record.vnc_id,
            vendacondicionalitens: dadosItens.dadosItens
        }

        let dadosNota = {
            vnc_id: record.vnc_id,
            pes_id: record.pes_id,
            itensNf: dadosItens.dadosItens.filter(item => (item.vci_devolvido !== item.vci_quantidade))
        };
        setCarregando(true)
        api.put(`VendaCondicional/Devolver`, dadosCondicional).then(
            (res) => {
                if (dadosNota.itensNf.lenght > 0) {
                    notification.success({ message: 'Operação concluída', description: "Condicional modificado com sucesso!" });
                    dadosNota.vnc_id = res.data.vnc_id;
                } else {
                    notification.success({ message: 'Operação concluída', description: "Itens da condicional devolvidos com sucesso!" });
                }
            }
        ).catch(
            (erro) => {
                notification.warning({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
            }
        ).finally(
            () => {
                if (!!dadosNota && dadosNota.itensNf.length > 0) {
                    dadosNota.pes_id = dadosNota.pes_id;
                    dadosNota.itensNf.forEach((itens) => {
                        itens.nfi_qtde = itens.vci_quantidade - itens.vci_devolvido;
                        itens.nfi_valorunitario = itens.vci_valorun;
                        itens.percdes = 0;
                        itens.nfi_valordesconto = 0;
                        itens.percacresc = 0;
                        itens.nfi_valoroutros = 0;
                        itens.nfi_ean = !!itens.prg_ean ? itens.prg_ean : itens.pro_ean;
                        itens.total = itens.vci_valorun * itens.nfi_qtde;
                    });
                    Modal.confirm({
                        title: 'Sucesso',
                        icon: <CheckCircleOutlined />,
                        content: 'Condicional modificado com sucesso!',
                        okText: 'Emitir NFE',
                        cancelText: 'Ok',
                        okType: 'success',
                        centered: true,
                        onOk() {
                            setExibirDrawerNota(true);
                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: dadosNota } });
                            exibirDrawerDevolucao.setExibirDrawerDevolucao(false);
                        },
                        onCancel() {
                            aoSalvar();
                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                            exibirDrawerDevolucao.setExibirDrawerDevolucao(false);
                        }
                    });
                } else {
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                    exibirDrawerDevolucao.setExibirDrawerDevolucao(false);
                }
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+vnc_datalancamento' } });
                setCarregando(false);

            }
        );
    }

    function excluirItens(record) {
        let listaTemp = [...dadosItens.dadosItens];
        let itemTemp = listaTemp.filter((item) => (item.key === record.key))[0];
        itemTemp.devolvidoTotal = true;
        itemTemp.vci_devolvido = itemTemp.vci_quantidade;
        dadosItens.setDadosItens(listaTemp);
    }

    function carregarDados() {
        if (!!dadosDevolucao.vnc_id) {
            api.get(`VendaCondicionalItem/Listar?IdVendaCondicional=${dadosDevolucao.vnc_id}`).then(
                (res) => {
                    let key = 0;
                    res.data.forEach((item) => {
                        item.key = key++;
                        if (item.vci_devolvido == item.vci_quantidade)
                            item.devolvidoTotal = true;
                    })
                    dadosItens.setDadosItens(res.data);
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            );
        }
    };

    function editarQuantidadeDev(record) {
        setFormItemCondicional(record);
    };

    async function onPressEnterProCodigo(campo) {
        let codigoEan = campo.target.value.trim();
        let listaTemp = [...dadosItens.dadosItens];
        let itemEncontrado = false;
        let todosItensDevolvidos = true;
        for (let i = 0; i < listaTemp.length; i++) {
            if (listaTemp[i].pro_ean === codigoEan || listaTemp[i].prg_ean === codigoEan) {
                itemEncontrado = true;
                if (listaTemp[i].vci_quantidade - listaTemp[i].vci_devolvido > 0) {
                    todosItensDevolvidos = false;
                    listaTemp[i].vci_devolvido++;
                    if (listaTemp[i].vci_quantidade - listaTemp[i].vci_devolvido === 0) {
                        listaTemp[i].devolvidoTotal = true;
                    }
                    dadosItens.setDadosItens(listaTemp);
                    break;
                }
            }
        };
        if (itemEncontrado == false) {
            return (
                notification.warning({
                    message: "Aviso",
                    description: "Item não encontrado!",
                })
            )
        }
        if (todosItensDevolvidos == true) {
            return (
                notification.warning({
                    message: "Aviso",
                    description: "Quantidade totalmente devolvida para este produto!",
                })
            )
        }
        formulario.setFieldsValue({ pro_codigo: null });

        setarFocoProduto();
    };

    function setarFocoProduto() {
        setTimeout(() => {
            if (codBarras && !!codBarras.current) {
                codBarras.current.focus();
            }
        }, 300);
    };

    return (
        <div>
            <FormGW layout="vertical" form={formulario} name="devolucaoCondicional" onFinish={salvar}>
                <div>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={16} md={6} lg={7} xl={7} xxl={7}>
                            <Form.Item label="Cliente" name="cli_nome">
                                <Select disabled={true} showSearch placeholder="Selecione o Cliente"></Select>
                            </Form.Item>
                            <Form.Item name="vnc_id" hidden></Form.Item>
                            <Form.Item name="pes_id" hidden></Form.Item>
                            <Form.Item name="vnc_numero" hidden></Form.Item>
                            <Form.Item name="ven_id" hidden></Form.Item>
                            <Form.Item name="cli_id" hidden></Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={3} lg={3} xl={3} xxl={3}>
                            <Data disabled={true} label="Data Retirada" name="vnc_datalancamento" />
                        </Col>
                        <Col xs={24} sm={8} md={3} lg={3} xl={3} xxl={3}>
                            <Data disabled={true} label="Data Devolução" name="vnc_prazoretorno" />
                        </Col>
                        <Col xs={24} sm={16} md={6} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Vendedor" name="ven_nome">
                                <Select disabled={true} showSearch placeholder="Selecione o Cliente"></Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={5} xl={5} xxl={5}>
                            <Form.Item className="t-mob">
                                <Switch checked={checkedSwitch} onClick={() => setCheckedSwitch(!checkedSwitch)} /> Devolver Todos os Itens?
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={9} lg={7} xl={7} xxl={7}>
                            <Form.Item label="Localize o Produto" name="pro_codigo">
                                <Input ref={codBarras} onPressEnter={(value) => {
                                    onPressEnterProCodigo(value)
                                }} placeholder="Informe o Produto/Mercadoria" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="tabela">
                        <Table scroll={{ x: 900 }} columns={[
                            {
                                title: 'Código',
                                width: '8%',
                                align: 'center',
                                render: ({ pro_codigo, devolvidoTotal }) => (
                                    <Tag color="processing" className="w-100">
                                        <b>{(!!riscarCampos || devolvidoTotal ? (<s> #{pro_codigo} </s>) : (<span>#{pro_codigo}</span>))}</b>
                                    </Tag>
                                ),
                            },
                            {
                                title: 'Itens',
                                width: '35%',
                                render: ({ pro_descricao, pro_ean, prg_ean, descricaograde, devolvidoTotal }) => (
                                    <div>
                                        <Row>
                                            <Col>
                                                <b>{(!!riscarCampos || devolvidoTotal ? (<s> {pro_descricao} </s>) : (pro_descricao))}</b>
                                            </Col>
                                        </Row>
                                        <Row gutter={[16, 0]}>
                                            <Col>
                                                {(!!riscarCampos || devolvidoTotal ? (<s> EAN: <b> {pro_ean || prg_ean}</b> </s>) : (<span>EAN:  <b> {pro_ean || prg_ean}</b></span>))}
                                            </Col>
                                            <Col>
                                                {(!!descricaograde ? (!!riscarCampos || devolvidoTotal ? (<s> Grade: <b> {descricaograde}</b> </s>) : (<span>Grade: <b>{descricaograde}</b></span>)) : '')}
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                            {
                                title: 'Nº',
                                width: '2%',
                                render: ({ vci_sequencia }) => (
                                    <div>
                                        {vci_sequencia}
                                    </div>
                                ),
                            },
                            {
                                title: '',
                                width: '8%',
                                render: ({ vci_quantidade, devolvidoTotal, vci_devolvido }) => (
                                    <div>
                                        {(!!riscarCampos || devolvidoTotal ? (<s>Ficou com: <b> {vci_quantidade - vci_devolvido}</b></s>) : (<span>Ficou com: <b>{vci_quantidade - vci_devolvido} </b></span>))}
                                    </div>
                                ),
                            },
                            {
                                title: '',
                                width: '12%',
                                editable: true,
                                render: ({ devolvidoTotal, vci_devolvido }, record) => (
                                    <div>
                                        <Row gutter={[8, 0]}>
                                            <Col>
                                                {(!!riscarCampos || devolvidoTotal ? (<s> Devolveu: <b> {vci_devolvido}</b> </s>) : (vci_devolvido > 0) ? <span>Devolveu: <b> {vci_devolvido}</b></span> : <span></span>)}
                                            </Col>
                                            <Col>
                                                {(!!riscarCampos || devolvidoTotal ? (<s>(Todos)</s>) : <span></span>)}
                                            </Col>
                                            <Col>
                                                {(!!riscarCampos || vci_devolvido > 0 ? <Button type="link" onClick={() => desfazerDevolucao(record)} icon={<UndoOutlined />} /> : <span> </span>)}
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                            {
                                editable: true,
                                title: 'Vlr. Total (R$)',
                                align: 'right',
                                render: ({ vci_devolvido, vci_quantidade, vci_valorun }) => (
                                    <div>
                                        {vci_devolvido == vci_quantidade ?
                                            <s>
                                                <b> {FormatNumber((vci_quantidade - vci_devolvido) * vci_valorun, true)}</b>
                                            </s> :
                                            <span>
                                                <b> {FormatNumber((vci_quantidade - vci_devolvido) * vci_valorun, true)}</b>
                                            </span>
                                        }
                                    </div>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                align: 'center',
                                key: 'x',
                                width: '8%',
                                fixed: 'right',
                                render: (record) => {
                                    return <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            {/* <Col>
                                            <Editar onClick={() => {
                                                editarQuantidadeDev(record);
                                            }} icon={<EditOutlined />} />
                                        </Col> */}
                                            <Col>
                                                <Excluir onClick={() => excluirItens(record)} icon={<DeleteOutlined />} />
                                            </Col>
                                        </Row>
                                    </div>
                                },
                            },
                        ]} dataSource={dadosItens.dadosItens} />
                    </div>
                </div>
                <ModalDevolucaoCondicional exibirModal={openModal} fecharModal={() => setOpenModal(false)} dados={formItemCondicional} dadosItens={dadosItens.dadosItens} formularioDados={formulario} />
            </FormGW>
            <Row justify="space-between" gutter={[8, 0]} className="m-t-16">
                <Col>
                    Quantidade Total de Itens: <b>{quantidadeTot}</b>
                </Col>
                <Col>
                    Valor Total dos Itens (R$): <b className="f-18">{FormatNumber(valorTot, true)}</b>
                </Col>
            </Row>
        </div >
    );
}