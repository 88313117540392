import React, { useEffect, useState } from "react";
import { Form, Row, Col, Tabs, Input, Select, InputNumber, notification } from "antd";

import SelectPaginado from "../../components/selectPaginado";
import api from '../../services/api';
import { InputPercentual } from '../../components';
import { FormGW } from "../../components";
import { useStateValue } from "../../state";
import { listagemActions } from "../../actions";
import TabDadosOrigemDestino from "./tabDadosOrigemDestino";

export default function ManutencaoNcmSubtituicaoTributaria({ formulario, carregando, aoSalvar }) {

    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [abaSelecionada, setAbaSelecionada] = useState("1");

    useEffect(() => {
        setAbaSelecionada("1");
        if (!!manutencao.dados) {
            manutencao.dados.ncm_codigo = manutencao.dados.ncm_codigo.codigoNcm;
            formulario.setFieldsValue(manutencao.dados);
        }
    }, [manutencao.dados])

    function verificaNCM() {
        let codigo = formulario.getFieldValue().ncm_codigo;
        if (!!codigo && codigo.toString().length < 5) {
            notification.warning({ message: `Aviso!`, description: `Código de NCM deve conter no mínimo 5 digitos!` });
            return false;
        }
    }

    function salvarManutencao(values) {
        values.ncm_codigo = values.ncm_codigo.toString();
        values.ncm_ativo = true;
        values.mvaorigemdestinos = !!formulario.getFieldValue().listaMvaOrigemDestino ? formulario.getFieldValue().listaMvaOrigemDestino :
            !!manutencao.dados ? manutencao.dados.mvaorigemdestinos : [];
        carregando(true);
        if (values.ncm_id) {
            editarNcm(values);
        }
        else {
            if (!!values.mvaorigemdestinos) {
                values.mvaorigemdestinos.forEach(
                    (item) => {
                        item.ncm_id = 0;
                    });
            }
            incluirNcm(values);
        }
    };

    function incluirNcm(values) {
        api.post("Ncm/Incluir", values).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Os dados do NCM foram salvos com sucesso!" });
            }).catch(
                error => {
                    console.log(error);
                }).finally(
                    () => {
                        carregando(false);
                        aoSalvar();
                        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ncm_codigo' } });
                    });
    }

    function editarNcm(values) {
        api.put("Ncm/Editar", values).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Os dados do NCM foram salvos com sucesso!" });
            }).catch(
                error => {
                    console.log(error);
                }).finally(
                    () => {
                        carregando(false);
                        aoSalvar();
                        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ncm_codigo' } });
                    });
    }

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoNCM" onFinish={salvarManutencao}>
            <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                <Tabs.TabPane tab="Itens" key="1">
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={8} md={8} lg={5} xl={5}>
                            <Form.Item hidden name="ncm_id" />
                            <Form.Item hidden name="cip_entrada" />
                            <Form.Item hidden name="cip_saida" />
                            <Form.Item label="Código da NCM" name="ncm_codigo" rules={[{ required: true, message: 'Informe o código da NCM' }]} onBlur={() => { verificaNCM() }}>
                                <InputNumber
                                    size="small"
                                    placeholder="Informe o Código da NCM"
                                    maxLength={9}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={6} xl={6}>
                            <Form.Item label="EX - Exceção do IPI" name="ncm_extipi">
                                <Input maxLength={2} placeholder="Informe a Exceção do IPI (OPCIONAL)" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={5} xl={5}>
                            <InputPercentual
                                defaultValue={0}
                                label="% Alíquota do IPI"
                                name="ncm_percentual"
                                min={0}
                                max={100}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                            <Form.Item label="Descrição" name="ncm_descricao" rules={[{ required: true, message: "Informe a Descrição" }]}>
                                <Input placeholder="Informe Descrição da NCM" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item label="CST IPI para Entradas" name="cip_entrada" rules={[{ required: true, message: "Informe o CST IPI para Entradas" }]}>
                                <SelectPaginado url="CstIpiNcm/Listar?Descricao=Entrada" placeholder={ui.editItem === true ? manutencao.dados?.cip_entrada : "Selecione o CST IPI para Entradas"} form={formulario} name="cip_entrada" conteudo={
                                    cip => (<Select.Option value={cip.cip_id} key={cip.cip_id}>{cip.cip_id} - {cip.cip_descricao}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item label="CST IPI para Saídas" name="cip_saida" rules={[{ required: true, message: "Informe o CST IPI para Saídas" }]}>
                                <SelectPaginado url="CstIpiNcm/Listar?Descricao=saída" placeholder={ui.editItem === true ? manutencao.dados?.cip_saida : "Selecione o CST IPI de Saida"} form={formulario} name="cip_saida" conteudo={
                                    cip => (<Select.Option value={cip.cip_id} key={cip.cip_id}>{cip.cip_id} - {cip.cip_descricao}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Dados Origem Destino" key="2">
                    <TabDadosOrigemDestino formulario={formulario} aoSalvar={aoSalvar} />
                </Tabs.TabPane>
            </Tabs>
        </FormGW>
    );
}