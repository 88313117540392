import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Select, notification } from "antd";

import api from '../../services/api';
import { FormGW } from "../../components";
import { useStateValue } from '../../state';
import { listagemActions } from "../../actions";
import SelectPaginado from "../../components/selectPaginado";
import { selectPaginadoActions } from "../../actions";
import { isObjetoDiffVazio } from "../../services/funcoes";

export default function ManutencaoOperacaoDebitoCredito({ formulario, carregando, aoSalvar }) {

    const [{ manutencao ,ui}, dispatch] = useStateValue();
    const [listaTipoImposto, setlistaTipoImposto] = useState([]);

    useEffect(() => {
        api.get(`Enum/Listar?nome=TipoNotaDebitoCredito`).then(res => {
            setlistaTipoImposto(res.data);
        })
    }, []);
    
    useEffect(() => {
        if (ui.showDrawer && !!manutencao.dados && !!manutencao.dados.odc_id) {
            let lista = [];
            if (!!manutencao.dados.cfo_cfop) {
                lista.push({ name: "cfo_cfop", campo: "Cfop", value: manutencao.dados.cfo_cfop });
            } 
            if (!!manutencao.dados.taj_id) {
                lista.push({ name: "taj_id", campo: "Id", value: manutencao.dados.taj_id });
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: {itens: lista} });
            formulario.setFieldsValue(manutencao.dados);
        }
    }, [ui.showDrawer, manutencao.dados]);

    const salvarOperacaoDebitoCredito = (values) => {
        values.odc_cfop = values.cfo_cfop;
        carregando(true);
        if (values.odc_id) {
            api.put(`operacaoDebitoCredito/Alterar` , values).then(res => {
                notification.success({ message: 'Operação concluída', description: "Os dados da Operação Fiscal foram salvos com sucesso!" });
            }).catch(
                erro => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
                }).finally(() => {
                    atualizarPagina();
                    carregando(false);
                    aoSalvar();
                });
        } else {
            api.post(`operacaoDebitoCredito/Incluir`, values).then(res => {
                notification.success({ message: 'Operação concluída', description: "Os dados da Operação Fiscal foram salvos com sucesso!" });
            }).catch(
                erro => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
                }).finally(() => {
                    atualizarPagina();
                    carregando(false);
                    aoSalvar();
                });
        };
    };

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+odc_id' } });
    };

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoOperacaoDebitoCredito" onFinish={salvarOperacaoDebitoCredito}>
            <Row gutter={[8, 0]}>
                <Form.Item name="odc_id" hidden><Input /></Form.Item>
                <Col span={24}>
                    <Form.Item label="Descrição" name="odc_descricao" rules={[{ required: true, message: 'Informe a Descrição da Operação Fiscal' }]}>
                        <Input placeholder="Informe a Descrição da Operação Fiscal" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item label="CFOP - Código Fiscal de Operação" name="cfo_cfop">
                        <SelectPaginado url="Cfop/Buscar" placeholder="CFOP - Código Fiscal de Operação" form={formulario} name="cfo_cfop" conteudo={
                            c => (<Select.Option value={c.cfo_cfop} key={c.cfo_cfop}>{c.cfo_cfop} - {c.cfo_descricao}</Select.Option>)
                        }
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={9} md={5} lg={5} xl={5}>
                    <Form.Item label="Imposto" name="odc_tipoImposto" rules={[{ required: true, message: 'Selecione o Tipo de Imposto' }]}>
                        <Select showSearch optionFilterProp="children" placeholder="Selecione o Tipo de Imposto">
                            {listaTipoImposto?.map(item => (
                                <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={15} md={19} lg={19} xl={19}>
                    <Form.Item label="Código do Ajuste para EFD-ICMS/IPI" name="taj_id">
                        <SelectPaginado url="TabelaAjuste53/Listar" placeholder="Código do Ajuste para EFD-ICMS/IPI" form={formulario} name="taj_id" conteudo={
                            t => (<Select.Option value={t.taj_id} key={t.taj_id}>{t.taj_codigoajuste} - {t.taj_descricao}</Select.Option>)
                        }
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FormGW>
    );
}