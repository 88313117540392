import React, { useState } from "react";
import { Modal, Button, } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";
import { PrecoGrade } from '../../';

export default function ModalGrade({ visible, onCloseModal, list, setList, onSave }) {

    const onCancel = () => {
        Modal.confirm({
            title: 'Atenção',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja sair sem salvar?',
            okText: 'Sim',
            cancelText: 'Cancelar',
            onOk: () => {
                onCloseModal();
            }
        });
    }


    return (
        <Modal centered
            title="Informar preços nos itens de grade"
            visible={visible}
            destroyOnClose={true}
            onCancel={onCancel}
            width={1000}
            footer={[
                <Button icon={<CloseOutlined />} size="middle" onClick={onCancel}>
                    Cancelar
                </Button>,
                <Button type="primary" size="middle" icon={<PlusOutlined />} onClick={onSave}>
                    Adicionar
                </Button>,
            ]}>
            <PrecoGrade grades={list} setGrades={setList} />
        </Modal>
    );
}