import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, notification, Row, Select, Table } from "antd";
import { CloseOutlined, DeleteOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../services/api";
import { Data } from "../../components";
import { isNotNullOrEmpty } from "../../services/funcoes";
import apiWeb from "../../services/apiWeb";

/**
 * Função de pesquisa avançada
 *
 * @param   {Form} form  Formulario onde serao setados 
 * @param   {function} funcaoRetorno Função onde será devolvida a expressao para enviar
 * @param   {function} funcaoLimpar Função que sera executada ao limpar os filtros
 * @param   {integer} telaFiltro Informe a tela a qual pertence a pesquisa (1-Cliente, 2-Produto, 3-Fornecedores, 4-Funcionarios, 5-Transportadores, 6-Vendedores, 7-Notas, 8-Condicionais, 9-Pedidos, 10-Peças Catalogo)
 * @param   {boolean} retornarSempre Retorna sempre pelo funcaoRetorno mesmo não tendo expressão
*/

export default function PesquisaAvancada({ form, funcaoRetorno, funcaoLimpar, telaFiltro, retornarSempre = false }) {
    const [listaCondicao, setListaCondicao] = useState([]);
    const [listaCondicaoReq, setListaCondicaoReq] = useState([]);
    const [listaCampos, setListaCampos] = useState([]);
    const [listaFiltros, setListaFiltros] = useState([]);
    const [ehBooleano, setEhBooleano] = useState(false);
    const [ehData, setEhData] = useState(false);

    //#region definições das contantes das listas de campos 

    const listaCamposBooleano = [
        { key: 0 },
        { key: 5 },
    ];
    const listaCamposString = [
        { key: 0 },
        { key: 5 },
        { key: 6 },
        { key: 7 },
        { key: 8 }
    ];
    const listaCamposData = [
        { key: 0 },
        { key: 1 },
        { key: 2 },
        { key: 3 },
        { key: 4 },
        { key: 5 },
        { key: 8 }
    ]


    //#endregion

    useEffect(() => {
        popularListaCondicao();
        popularListaCampos();
    }, []);

    //#region funções para popular as constantes 

    function popularListaCondicao() {
        apiWeb.get(`Enum/Listar?filtro=&nome=CondicaoFiltroCampoAdicional`).then(
            res => {
                setListaCondicaoReq(res.data);
            }
        );
    };

    function popularListaCampos() {
        apiWeb.get(`Enum/ListarFiltros?type=${telaFiltro}`).then(
            (res) => {
                let key = 0;
                res.data.forEach((campo) => {
                    campo.key = key;
                    key++;
                });
                setListaCampos(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    //#endregion

    function adicionarFiltro() {
        let lista = [...listaFiltros];
        let dados = form.getFieldValue();
        if (validarFiltrosPreenchidos()) {
            let tipo = listaCampos.filter((campo) => campo.name === dados.campo)[0].type;
            let filtro = dados.filtro;
            if (tipo === 'DateTime') {
                filtro = moment(dados.filtro).format('YYYY-MM-DD');
            }
            
            let expressao = {
                key: lista.length,
                labelCampo: listaCampos.filter((campo) => campo.name === dados.campo)[0].label,
                campo: dados.campo,
                labelCondicao: listaCondicao.filter((condicao) => condicao.key === dados.condicao)[0].value,
                tipo: tipo,
                condicao: dados.condicao,
                filtro: filtro
            };
            lista.push(expressao);
            setListaFiltros(lista);
            limparCampos();
        }
    };

    function validarFiltrosPreenchidos() {
        let dados = form.getFieldValue();
        let retorno = false;
        if (!!dados.campo && isNotNullOrEmpty(dados.condicao) && !!dados.filtro) {
            retorno = true;
        } else {
            notification.warn({ description: "Favor informe os dados para o filtro", message: "Aviso" });
        }
        return retorno;
    };

    function aplicarFiltros() {
        let expressao = "";
        if (validarFiltrar()) {
            if (listaFiltros.length > 0) {
                expressao = JSON.stringify({ filtros: listaFiltros });
            } else {
                let dados = form.getFieldValue();
                expressao = JSON.stringify({ filtros: [{ campo: dados.campo, condicao: dados.condicao, filtro: dados.filtro, tipo: listaCampos.filter((campo) => campo.name === dados.campo)[0].type }] })
            }
            funcaoRetorno(expressao);
        } else {
            if (retornarSempre) {
                funcaoRetorno(null);
            } else {
                notification.warn({ description: 'Favor informar o filtro da pesquisa!', message: 'Aviso' });
                funcaoLimpar();
            }
        }
    };

    function validarFiltrar() {
        let dados = form.getFieldValue();
        let retorno = false;
        if (listaFiltros.length > 0 || !!dados.campo && !!dados.condicao && !!dados.filtro) {
            retorno = true;
        }
        return retorno;
    }

    function limparCampos() {
        form.setFieldsValue({ campo: null, condicao: null, filtro: null });
    };

    function removerFiltros() {
        setListaFiltros([]);
        funcaoLimpar();
        //dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '+pes_nome' } });
    };

    function removerFiltroLista(record) {
        let lista = [...listaFiltros];
        let retorno = lista.filter((dados) => dados.key !== record.key);
        setListaFiltros(retorno);
    };

    function buscarCondicao(dados) {
        let lista = [...listaCondicaoReq];
        let campo = listaCampos.filter((campo) => campo.name == dados)[0];
        let booleano = false;
        let data = false;
        if (campo.type === "Boolean") {
            lista = lista.filter((condicao) => listaCamposBooleano.find(camposKey => camposKey.key === condicao.key));
            booleano = true;
            setListaCondicao(lista);
        } else if (campo.type === "String") {
            lista = lista.filter((condicao) => listaCamposString.find(camposKey => camposKey.key === condicao.key));
            setListaCondicao(lista);
        } else if (campo.type === "DateTime") {
            lista = lista.filter((condicao) => listaCamposData.find(camposKey => camposKey.key === condicao.key));
            data = true;
            setListaCondicao(lista);
        } else {
            setListaCondicao(listaCondicaoReq);
        }
        setEhData(data);
        setEhBooleano(booleano);
    };

    return (
        <Row justify="end" gutter={[8, 0]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={19}>
                <Row justify="start" gutter={[8, 0]}>
                    <Col xs={24} sm={12} md={7} lg={7} xl={7}>
                        <Form.Item label="Campo" name="campo">
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                placeholder="Selecione um campo personalizado"
                                onChange={(dados) => { buscarCondicao(dados) }}
                            >
                                {listaCampos.map(
                                    (campo) => (
                                        <Select.Option key={campo.key} value={campo.name}>{campo.label}</Select.Option>
                                    )
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={7}>
                        <Form.Item label="Condição" name="condicao">
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                placeholder="Selecione uma condição..."
                            >
                                {listaCondicao.map(
                                    (condicao) => (
                                        <Select.Option key={condicao.key} value={condicao.key}>{condicao.value}</Select.Option>
                                    )
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                        {ehData ?
                            <Data label="Filtro" name="filtro" /> :
                            <Form.Item label="Filtro" name="filtro">
                                {ehBooleano ?
                                    <Select placeholder="Informe o filtro para essa condição">
                                        <Select.Option value={"true"} key={0}>
                                            Verdadeiro
                                        </Select.Option>
                                        <Select.Option value={"false"} key={1}>
                                            Falso
                                        </Select.Option>
                                    </Select> :
                                    <Input placeholder="Informe o filtro para essa condição" />
                                }
                            </Form.Item>}
                    </Col>           
                    <Col xs={24} sm={12} md={5} lg={5} xl={4} className="tt-5">
                        <Button block icon={<PlusOutlined />} onClick={() => { adicionarFiltro() }} className="t-mob-573"> Adicionar </Button>
                    </Col>
                </Row>
            </Col> 
            <Col xs={24} sm={24} md={24} lg={12} xl={6} xxl={5} className="t-mob tm5">
                <Row gutter={[8, 8]} justify="end" className="tt-5">
                    <Col xs={24} sm={12} md={5} lg={8} xl={12} xxl={12}>
                        <Button icon={<CloseOutlined />} block onClick={() => removerFiltros()}>
                            Remover Filtros
                        </Button>
                    </Col>
                    <Col xs={24} sm={12} md={5} lg={8} xl={12} xxl={12}>
                        <Button type="primary" icon={<SearchOutlined />} block onClick={() => aplicarFiltros()}>
                            Aplicar Filtros
                        </Button>
                    </Col>
                </Row>
            </Col>
            {listaFiltros.length > 0 &&
                <Col span={24}>
                    <div className="tabela mt-8 m-b-10">
                        <Table dataSource={listaFiltros} columns={[
                            {
                                title: "Campo",
                                render: ({ labelCampo }) => (
                                    <div>{labelCampo}</div>
                                ),
                            }, {
                                title: "Condição",
                                render: ({ labelCondicao }) => (
                                    <div>{labelCondicao}</div>
                                ),
                            }, {
                                title: "Filtro",
                                render: ({ filtro, tipo }) => (
                                    <div> {tipo === "DateTime" ? moment(filtro).format("DD/MM/YYYY") : filtro}</div>
                                ),
                            }, {
                                title: 'Ações',
                                dataIndex: '',
                                align: 'center',
                                key: 'x',
                                width: 65,
                                render: (record) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button icon={<DeleteOutlined />} onClick={() => { removerFiltroLista(record) }} />
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            }
                        ]} scroll={{ y: 99 }} pagination={false} />
                    </div>
                </Col>
            }
        </Row>



    )
}