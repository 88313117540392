import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Divider, Button, Table, Form, Modal, Tag } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { ModalOrgaoSubjacente } from "../../components/modals";
import { useStateValue } from "../../state";
import { manutencaoActions } from "../../actions";

export default function OrgaoSubjacente() {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [openModalOrgaoSubjacente, setOpenModalOrgaoSubjacente] = useState(false);
    const [listaOrgaoSubjacente, setListaOrgaoSubjacente] = useState(!!manutencao.dados && !!manutencao.dados.listaOrgaoPublico ? manutencao.dados.listaOrgaoPublico : []);
    const [form] = Form.useForm();

    useEffect(() => {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ listaOrgaoPublico: listaOrgaoSubjacente } } } });
    }, [listaOrgaoSubjacente]);

    function editar(record, indice) {
        record.indice = indice;
        form.setFieldsValue(record);
        setOpenModalOrgaoSubjacente(true);
    };

    function excluir(id) {
        const lista = [...listaOrgaoSubjacente];
        const index = lista.findIndex((item) => id === item.pse_id);
        lista.splice(index, 1)
        setListaOrgaoSubjacente(lista);
    };

    const modalConfirmaExclusao = (record) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja excluir o registro?`,
            okText: 'Excluir',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                excluir(record);
            }
        });
    };

    const alterarPropriedade = (key, coluna, checked) => {
        let newData = [...listaOrgaoSubjacente];
        newData[key][coluna] = checked;
        setListaOrgaoSubjacente(newData);
    };

    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-subjacentes.png").default} alt="Órgãos Subjacentes" /> Órgãos Subjacentes
                    </Typography.Title>
                    <Divider orientation="left" plain> Adicione o(s) órgão(s) público(s) Aqui. </Divider>
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => setOpenModalOrgaoSubjacente(true)} />
                    </div>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Table columns={[
                    {
                        title: '',
                        align: 'center',
                        width: 75,
                        render: (record, obj, indice) => (
                            <Tag.CheckableTag
                                key="pop_ativo"
                                checked={record.pop_ativo}
                                onChange={(checked) => alterarPropriedade(indice, "pop_ativo", checked)}
                            >
                                Ativo
                            </Tag.CheckableTag>
                        ),
                    },
                    {
                        title: 'Nome',
                        width: 150,
                        render: ({ pop_nome }) => (
                            <div>
                                <b>{pop_nome}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Endereço',
                        render: ({ pop_endereco, pop_numero, pop_bairro, pop_complemento, pop_cep, cep }) => (
                            <div>
                                <b>
                                    {!!pop_endereco ? pop_endereco : ""}{!!pop_numero ? (', ' + pop_numero) : ''}
                                    {!!pop_bairro ? (', ' + pop_bairro) : ''}
                                    {!!pop_complemento ? (', ' + pop_complemento) : ''}
                                    {!!pop_cep ? (' - ' + pop_cep) : !!cep ? cep : ''}
                                </b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        align: 'center',
                        key: 'x',
                        width: 65,
                        fixed: 'right',
                        render: (record, obj, indice) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button onClick={() => editar(record, indice)} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>
                                        <Button onClick={() => modalConfirmaExclusao(record, indice)} icon={<DeleteOutlined />} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    }
                ]} scroll={{ x: 960 }} dataSource={listaOrgaoSubjacente} />
            </div>
            <ModalOrgaoSubjacente form={form} listaOrgaoSubjacente={{ listaOrgaoSubjacente, setListaOrgaoSubjacente }} exibirModalOrgaoSubjacente={openModalOrgaoSubjacente} fecharModalOrgaoSubjacente={() => { form.resetFields(); setOpenModalOrgaoSubjacente(false) }} />
        </div>
    );
}