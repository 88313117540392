import React, { useEffect, useState } from "react";
import { Row, Col, Button, Dropdown, Menu, Form, Select } from "antd";

import { listagemActions } from "../../actions";
import { useStateValue } from '../../state';
import { ModalExibirAcaoUsuario } from "../../components/modals/";
import { FormatDate } from '../../ValueObjects/';
import { TabelaDados, FiltroOrdenacao, BreadcrumbPage, BotaoAbrirChamado, FiltroSearch, Data } from "../../components";
import { EyeOutlined } from "@ant-design/icons";
import SelectPaginado from '../../components/selectPaginado';
import moment from "moment";
import api from "../../services/api";

export default function AcaoUsuario() {

    const [openModal, setOpenModal] = useState(false);
    const [dadosModal, setDadosModal] = useState([]);
    const [listaTipoEntidade, setListaTipoEntidade] = useState([]);
    const [{ }, dispatch] = useStateValue();
    const [formulario] = Form.useForm();

    useEffect(() => {
        formulario.setFieldsValue({ DataInicial: moment(new Date()) });
        formulario.setFieldsValue({ DataFinal: moment(new Date()) });

        retornaTipoEntidade();
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '-aco_datahora' } })
    }, []);

    function carregarDados() {
        let data = `&DataInicial=${FormatDate(formulario.getFieldValue().DataInicial.toDate().toLocaleDateString())}
        &DataFinal=${FormatDate(formulario.getFieldValue().DataFinal.toDate().toLocaleDateString())}`
        if (formulario.getFieldValue().loc_id)
            data += `&CodigoLocal=${formulario.getFieldValue().loc_id}`
        if (formulario.getFieldValue().usu_id)
            data += `&CodigoUsuario=${formulario.getFieldValue().usu_id}`
        if (formulario.getFieldValue().TipoEntidade)
            data += `&TipoEntidade=${formulario.getFieldValue().TipoEntidade}`

        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: data } });
    };

    function menuTableOutros(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="text" icon={<EyeOutlined />} onClick={() => { visualizar(record); }}> Visualizar </Button>
                </Menu.Item>
            </Menu>
        )
    };
    async function visualizar(Id) {
        api.get(`acaousuario/Buscar/${Id}`).then(
            (res) => {
                if (res.status === 200) {
                    setOpenModal(true);
                    setDadosModal(res);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
        
        
        
    };




    function retornaTipoEntidade() {
        api.get('Enum/Listar?nome=TipoTabelaAcaoUsuario').then(
            (res) => {
                if (res.status === 200) {
                    setListaTipoEntidade(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };
    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoAbrirChamado />
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form form={formulario} layout="vertical">
                <Row align="middle" gutter={[8, 0]}>
                    <Col xs={8} sm={8} md={6} lg={6} xl={6} xxl={6}>
                        <FiltroSearch />
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                        <Form.Item label="Local" name="CodigoLocal">
                            <SelectPaginado url="Local/Listar?Ativo=true" placeholder="Selecione um Local" form={formulario} name="loc_id"
                                allowClear={true}
                                onChangeFunction={() => carregarDados()}
                                conteudo={
                                    l => (<Select.Option value={l.loc_id} key={l.key}>{l.loc_descricao}</Select.Option>)
                                } />
                        </Form.Item>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                        <Form.Item label="Usuário" name="CodigoUsuario">
                            <SelectPaginado url="Usuario/RetornaUsuariosLocal?SuporteNao=true" placeholder="Selecione um Usuário" form={formulario} name="usu_id"
                                allowClear={true}
                                onChangeFunction={() => carregarDados()}
                                conteudo={
                                    u => (<Select.Option value={u.usu_id} key={u.key}>{u.usu_nome}</Select.Option>)
                                } />
                        </Form.Item>
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3}>
                        <Form.Item label="Entidade" name="TipoEntidade">
                            <Select
                                allowClear={true}
                                onChange={() => carregarDados()}
                                placeholder="Tipo Entidade"
                            >
                                {listaTipoEntidade.map((x) => (
                                    <Select.Option key={x.key} value={x.key}>{x.value}</Select.Option>
                                ))}

                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={6} sm={2} md={2} lg={2} xl={2} xxl={2}>
                        <Data onChange={(value) => { carregarDados(value) }} name="DataInicial" label="Data Inicial" />
                    </Col>
                    <Col xs={6} sm={2} md={2} lg={2} xl={2} xxl={2}>
                        <Data onChange={(value) => { carregarDados(value) }} name="DataFinal" label="Data Inicial" />
                    </Col>
                    <Col xs={12} sm={3} md={3} lg={3} xl={3} xxl={3}>
                        <FiltroOrdenacao opcoesOrdenacao={[
                            { label: "Data Crescente", value: "aco_datahora" },
                            { label: "Data Decrescente", value: "-aco_datahora" },
                        ]} />
                    </Col>
                </Row>
            </Form>


            <div className="tabela">
                <TabelaDados url="acaousuario/Listar" colunas={[
                    {
                        title: 'Data Hora',
                        width: 10,
                        align: 'left',
                        render: ({ aco_datahora }) => (
                            <div>
                                <b>{moment(aco_datahora).format('DD-MM-YYYY HH:mm:ss')}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Tipo Ação',
                        width: 12,
                        render: ({ aco_tipoacao }) => (
                            <div>
                                <div>
                                    <b>{aco_tipoacao}</b>
                                </div>
                            </div>
                        ),
                    },
                    {
                        title: 'Usuário',
                        width: 20,
                        render: ({ usu_nome }) => (
                            <div>
                                <div>
                                    <b>{usu_nome}</b>
                                </div>
                            </div>
                        ),
                    },
                    {
                        title: 'Titulo',
                        width: 50,
                        render: ({ aco_titulo }) => (
                            <div>
                                <b>{aco_titulo}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 5,
                        fixed: 'right',
                        render: (record, dados) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button onClick={() => visualizar(record.id)} icon={<EyeOutlined />} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <ModalExibirAcaoUsuario exibirModal={openModal} fecharModal={() => setOpenModal(false)} dadosModal={dadosModal} />
        </div>
    );
}