import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Tag, Table, Tabs, Divider } from 'antd';
import { EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import api from '../../services/api';
import DrawerPersonalizacao from './drawer';
import { useStateValue } from '../../state';
import { drawerActions, manutencaoActions } from "../../actions";
import { BreadcrumbAbrirChamado } from "../../components";

export default function CamposPersonalizados() {
    const [ abaSelecionada, setAbaSelecionada ] = useState("1");
    const [{ listagem, manutencao }, dispatch] = useStateValue();
    const [ camposPersonalizados, setCamposPersonalizados ] = useState([]);

    function editarCamposPersonalizados(valores) {
        var camposPersonalizadosTemp = [];
        valores.map((data, idx) => {
            delete(data.key);
            data.key = idx;
            data.status = 2;
            camposPersonalizadosTemp.push(data)
        });
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: camposPersonalizadosTemp } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function carregaCamposPersonalizados() {
        api.get(`CamposPersonalizados/ListarTodos?PageNumber=1&PageSize=1000&Order=+cap_ordem`).then(res => {
            console.log(res);
            if (res.status === 200) {
                var camposPersonalizadosTemp = [];
                 res.data.items.map((data, idx) => {
                     data.key = idx;
                     data.status = 2;
                     camposPersonalizadosTemp.push(data)
                 });
                setCamposPersonalizados(camposPersonalizadosTemp);
            }
        }).catch(
            (erro) => {
                console.log(erro);
                alert('Erro ao carregar Campos personalizados!');
            }
        );
    };

    const ListaCamposPersonalizados = (dataSource) => {
        return (
        <div className="tabela">
            <Table dataSource={dataSource} columns={[
                {
                    title: 'Ordem',
                    width: 75,
                    align: 'center',
                    render: ({cap_ordem}) => (
                        <Tag color="processing" className="w-100">
                        <b>{cap_ordem}</b>
                        </Tag>
                    )
                },
                {
                    title: 'Nome Elemento',
                    render: ({cap_nomeelemento}) => (
                        <div>
                        <b>{cap_nomeelemento}</b>
                        </div>
                    )
                },
                {
                    title: 'Tipo Elemento',
                    render: ({cap_tipoelemento}) => (
                        <div>
                        <b>{cap_tipoelemento}</b>
                        </div>
                    )
                },
                {
                    title: 'Obrigatorio',
                    align: 'right',
                    width: 80,
                    render: ({cap_mensagemobrigatorio}) => (
                        <div>                               
                           <b>{cap_mensagemobrigatorio ? "SIM":"NÃO"}</b>
                        </div>
                    )
                },
                
            ]} locale={{
                emptyText: (
                    <Row>
                        <Col span={24}>
                            <Divider orientation="center">
                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                            </Divider>
                        </Col>
                    </Row>)
            }} />
            <Row justify="center" className="m-t-16">
                <Col>
                    <Button onClick={() => editarCamposPersonalizados(dataSource)} icon={<EditOutlined />} size="middle" type="primary">
                        Incluir/Editar Campos Personalizados 
                    </Button>
                </Col>
            </Row>
        </div>
        )
    }

    useEffect(() => carregaCamposPersonalizados() , []);

    return (
        <div className="p-10">
            <BreadcrumbAbrirChamado />
            <Row className="m-t-8">
                <Col span={24}>
                    <div className="pages-col">
                        <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                            <Tabs.TabPane tab="Clientes" key="1">
                                {ListaCamposPersonalizados(camposPersonalizados.filter(f => f.cap_tipoentidade == 1 ))}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Fornecedor" key="2">
                                {ListaCamposPersonalizados(camposPersonalizados.filter(f => f.cap_tipoentidade == 2 ))}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Funcionário" key="3">
                                {ListaCamposPersonalizados(camposPersonalizados.filter(f => f.cap_tipoentidade == 3 ))}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Produto" key="4">
                                {ListaCamposPersonalizados(camposPersonalizados.filter(f => f.cap_tipoentidade == 4 ))}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Transportador" key="5">
                                {ListaCamposPersonalizados(camposPersonalizados.filter(f => f.cap_tipoentidade == 5 ))}
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Vendedor" key="6">
                                {ListaCamposPersonalizados(camposPersonalizados.filter(f => f.cap_tipoentidade == 6 ))}
                            </Tabs.TabPane>
                        </Tabs>                        
                    </div>
                </Col>
            </Row>    
            <DrawerPersonalizacao tipoEntidade={abaSelecionada} carregaCamposPersonalizados={carregaCamposPersonalizados}/>
        </div>
    );

}