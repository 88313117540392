import React, { useEffect, useState } from "react";
import { Row, Col, Tag, Modal, notification } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, PrinterOutlined } from "@ant-design/icons";

import api from '../../services/api';
import { useStateValue } from '../../state';
import DrawerOperacaoDebitoCredito from './drawer';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, Excluir, Imprimir, Editar } from "../../components";

export default function OperacaoDebitoCredito() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+odc_descricao'}}), []);

    function excluirRegistro(dados){
        setCarregando(true);
        api.delete(`OperacaoDebitoCredito/Excluir?id=${dados.odc_id}`).then(
            (res) => {
                notification.success({ message: 'Operação concluída', description: "Registro excluído com sucesso!" }); 
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null}});
            },(erro) => {
                notification.error({ message: 'Atenção', description: "Não foi possível excluír o registro!" }); 
                //modalInativarRegistro(dados)
            }
        ).finally(() => {dispatch({ type: listagemActions.CHANGE, data: { ordem: '+odc_descricao'}})
            }
        );
    };

    function inativarRegistro(dados) {
        dados.mar_ativa = false;
        api.put(`operacaoDebitoCredito/Alterar`, dados).then(
            res => {
                notification.success({ message: `Sucesso!`, description: `Operação inativada com sucesso!` })
            }
        ).catch(
            error => {
                console.log(error);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+odc_descricao' } });
            }
        )
    }

    const modalInativarRegistro = (dados) => {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: `Não foi possível excluir a marca ${dados.odc_descricao}. Deseja inativar este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                inativarRegistro(dados);
            }
        });
    };

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir a operação: ${dados.odc_descricao}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+odc_descricao" },
                { label: "Nome Z - A", value: "-odc_descricao" }, 
                { label: "Código Crescente", value: "+odc_id" },
                { label: "Código Decrescente", value: "-odc_id" }
            ]} /> 
            <div className="tabela">
                <TabelaDados url="OperacaoDebitoCredito/Listar" colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align:'center',  
                            render: ({odc_id}) => (
                                <Tag color="processing" className="w-100">
                                    <b>{odc_id}</b>
                                </Tag>
                            ),
                        },
                        { 
                            title: 'Operações para Nota Fiscal de Ajuste', 
                            render: ({odc_descricao}) => (
                                <div>
                                    <b>{odc_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 95,                            
                            fixed: 'right',
                            render: (record, dados) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Editar onClick={() => {
                                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: record }});
                                            dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true }});
                                        }} icon={<EditOutlined />}/>
                                    </Col>
                                    <Col>
                                        <Excluir icon={<DeleteOutlined />} onClick={() => modalExcluir(dados)}/>
                                    </Col>
                                </Row>
                            </div>
                            ),
                        },
                    ]
                } />
            </div>   
            <DrawerOperacaoDebitoCredito />
        </div>
    );
    
}