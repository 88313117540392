import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Dropdown, Menu, Modal, notification } from "antd";
import { EditOutlined, DeleteOutlined, SendOutlined , PrinterOutlined,  MoreOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import api from '../../services/api';
import { useStateValue } from '../../state';
import { TabelaDados, Editar } from "../../components";
import DrawerRelatorioEtiqueta from './drawer';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { ModalEmpresaFilialBusca } from "../../components/modals/";

export default function TabModeloPersonalizado({ exibeModelo }) {

    const [form] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();
    const [openModalEmpresaFilial, setOpenModalEmpresaFilial] = useState(false);
    const [relatorio, setRelatorio] = useState();
    const [rels, setRels]  = useState();
    

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: ' ', filtro: ' ', ordem: 'Nome' } })
    }, []);

    function imprimirEtiqueta(modelo) {
        if (!!!modelo.jsonRelatorio) {
            api.get(`RelatorioModeloEtiqueta/Listar?id=${modelo.id}`).then(
                res => {
                    exibeModelo(res.data);
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            )
        } else {
            exibeModelo(modelo);
        }
    };

    function modalRemoverModelo(record) {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este modelo personalizado?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                removerModelo(record);
            }
        });
    };

    function removerModelo(record) {
        api.delete(`RelatorioModeloEtiqueta/Excluir/${record.id}`).then(
            res => {
                notification.warning({ description: 'Modelo de etiqueta removido com sucesso!', message: 'Aviso' });
                
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function menuEmpresa(record){
        setRels(' ' + record.nome);
        setRelatorio(record);
        setOpenModalEmpresaFilial(true, record, rels);
    };

    function menuTable(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1"
                >
                    <Editar type="text" icon={<EditOutlined />} onClick={() => {
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                    }}> Editar
                    </Editar>
                </Menu.Item>
                <Menu.Item key="2">
                    <Button type="text" icon={<DeleteOutlined />} onClick={() => { modalRemoverModelo(record) }}>Excluir</Button>
                </Menu.Item>
                <Menu.Item key="3">
                    <Button type="text" icon={<SendOutlined  />} onClick={() => { menuEmpresa(record) }}>Cópia</Button>
                </Menu.Item>
            </Menu>
        )
    };

    return (
        <div>
            <div className="tabela mt-dif">
                <TabelaDados url="RelatorioModeloEtiqueta/ListarGeral" condicao={(campo) => { return campo.emp_id !== null }} colunas={
                    [
                        {
                            title: 'Modelo',
                            render: ({ nome }) => (
                                <div>
                                    <b>{nome}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 95,
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                                <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                            </Dropdown>
                                        </Col>
                                        <Col>
                                            <Button icon={<PrinterOutlined />} onClick={() => { imprimirEtiqueta(record) }} />
                                        </Col>
                                    </Row>

                                    {/* <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<PrinterOutlined />} onClick={() => { imprimirEtiqueta(record) }} />
                                        </Col>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }}/>
                                        </Col>
                                        <Col>
                                            <Button icon={<DeleteOutlined />} onClick={() => { modalRemoverModelo(record) }}/>
                                        </Col>
                                    </Row> */}
                                </div>
                            ),
                        },
                    ]
                } />
            </div>
            <DrawerRelatorioEtiqueta />
            <ModalEmpresaFilialBusca exibirModalEmpresaFilial={openModalEmpresaFilial} form={form} relatorio={relatorio} rels={rels}  fecharModalEmpresaFilial={() => setOpenModalEmpresaFilial(false)} />
        </div>

    )
}