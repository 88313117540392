import React from "react";
import { Row, Col, Form, Input, Modal, InputNumber } from "antd";
import { SaveOutlined, CloseOutlined } from "@ant-design/icons";

export default function ModalEditarItemDevolucao({ form, exibirModalEditarItemDevolucao, cancelar, aoSalvar }) {

    return (
        <Modal centered
            title="Editar Item"
            visible={exibirModalEditarItemDevolucao}
            onCancel={() => cancelar()}
            onOk={() => form.submit()}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <Form layout="vertical" name="manutencaoEditarItemDevolucao" form={form} onFinish={aoSalvar}>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Quantidade" name="nfi_qtde">
                            <InputNumber decimalSeparator="," min={0} step={1} placeholder="Informe a Quantidade do Produto" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}