import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Button, Divider, Typography, Card, Tooltip, Modal } from "antd";
import { QuestionCircleOutlined, EditOutlined, SettingOutlined, CheckOutlined } from "@ant-design/icons";

import api from '../../services/api';
import { useStateValue } from '../../state';
import { linkApi } from "../../services/auth";
import DrawerBandeiraCartaoEmpresa from './drawer';
import { FormatNumber, MaskFormat } from "../../ValueObjects";
import { drawerActions, manutencaoActions } from "../../actions";
import { ModalTaxasCartaoCredito } from "../../components/modals/";
import { BreadcrumbAbrirChamado, Editar } from "../../components";

export default function BandeiraCartaoEmpresa() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [bandeirasCartao, setBandeirasCartao] = useState([]);
    const [openModalTaxas, setOpenModalTaxas] = useState(false);
    const [dadosTaxaCartao, setDadosTaxaCartao] = useState({});
    const [formPesquisa] = Form.useForm();

    useEffect(() => {
        consultar();
    }, []);

    function consultar() {
        let filtro = '';
        if (!!formPesquisa.getFieldValue().pesquisa) {
            filtro = formPesquisa.getFieldValue().pesquisa;
        }

        api.get(`BandeiraCartao/Listar?filtro=${filtro}`).then(
            (res) => {
                if (res.status === 200) {
                    setBandeirasCartao(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function ativarBandeiraCartaoEmpresa(cartao) {
        cartao.bce_ativo = true;
        api.put(`BandeiraCartaoEmpresa/Editar`, cartao).then(res => {
            if (res.status === 200) {
                Modal.success({
                    content: 'Bandeira de Cartão de Crédito ativada com sucesso!',
                });
            }
        }, erro => {
            Modal.error({
                title: "Não foi possivel ativar a Bandeira de Cartão de Crédito!",
                content: (
                    <div>
                        <p>{erro.response.data.Message}</p>
                    </div>
                )
            });
        }).finally(() => {
            consultar();
        });
    };

    function abrirModalTaxas(cartao) {
        setDadosTaxaCartao(cartao);
        setOpenModalTaxas(true);
    };

    return (
        <div className="p-10">
            <BreadcrumbAbrirChamado />
            <Row align="middle">
                <Col span={24}>
                    <Typography.Text>
                        Selecione abaixo uma das bandeiras de cartão de crédito.
                    </Typography.Text>
                </Col>
            </Row>
            <Form layout="vertical" form={formPesquisa} className="m-t-16">
                <Row align="top" gutter={[0, 40]}>
                    <Col span={24}>
                        <Form.Item name="pesquisa" label={
                            <div>
                                Pesquisar por
                                <Tooltip title="Cartão de crédito, Credenciadora">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                        }>
                            <Input.Search onSearch={() => { consultar() }} placeholder="Cartão de crédito, Credenciadora..." allowClear />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Row justify="center" gutter={[16, 40]}>
                            {bandeirasCartao.map((cartao) => (
                                <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                                    <div className="colBandeiras">
                                        <Card hoverable>
                                            <div className="logoBandeira">
                                                <Row align="middle" justify="center" gutter={[0, 8]}>
                                                    <Col>
                                                        <img src={cartao.imagemCartao} alt={cartao.bnd_nome} />
                                                    </Col>
                                                    {cartao.bce_id !== null && cartao.bce_ativo &&
                                                        <div className="btEdit">
                                                            <Editar icon={<EditOutlined />} onClick={() => {
                                                                let dados = { ...cartao };
                                                                dados.bce_taxapadraocredito = (parseFloat(dados.bce_taxapadraocredito) * 100).toFixed(2);
                                                                dados.bce_taxapadraodebito = (parseFloat(dados.bce_taxapadraodebito) * 100).toFixed(2);

                                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                                            }}>
                                                                Editar
                                                            </Editar>
                                                        </div>
                                                    }
                                                </Row>
                                            </div>
                                            {cartao.bce_id !== null && cartao.bce_ativo &&
                                                <div>
                                                    <div className="m-t-8">
                                                        <Row align="middle">
                                                            <Col className="txt-overflow">
                                                                <Typography.Text>
                                                                    Credenciadora: <b>{!!cartao.bce_nomecredenciadora ? cartao.bce_nomecredenciadora : 'Não Informada'}</b>
                                                                </Typography.Text>
                                                            </Col>
                                                        </Row>
                                                        <Row align="middle">
                                                            <Col className="txt-overflow">
                                                                <Typography.Text>
                                                                    CNPJ da Credenciadora: <b>{MaskFormat(cartao.bce_cnpjcredenciadora, '', true)}</b>
                                                                </Typography.Text>
                                                            </Col>
                                                        </Row>
                                                        <Row align="middle">
                                                            <Col className="txt-overflow">
                                                                <Typography.Text>
                                                                    Banco: <img className="imgLogoListaBanco" src={cartao.imagemBanco} alt={cartao.ban_descricao} />
                                                                    <b> {cartao.ban_descricao}</b>
                                                                </Typography.Text>
                                                            </Col>
                                                        </Row>
                                                        <Row align="middle" gutter={[8, 8]}>
                                                            <Col className="txt-overflow">
                                                                <Typography.Text>
                                                                    Ag.: <b>{cartao.ctc_numeroagencia}</b>
                                                                </Typography.Text>
                                                            </Col>
                                                            <Col className="txt-overflow">
                                                                <Typography.Text>
                                                                    Conta: <b>{cartao.ctc_numeroconta}</b>
                                                                </Typography.Text>
                                                            </Col>
                                                        </Row>
                                                        <Row align="middle">
                                                            <Col span={24}>
                                                                <div className="m-t-8">
                                                                    <Row align="middle" gutter={[8, 8]}>
                                                                        <Col span={8}>
                                                                            Débito (R$):
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <div className="text-center">
                                                                                <b>
                                                                                    {FormatNumber((parseFloat(cartao.bce_taxapadraodebito) * 100), true, false)}%
                                                                                </b>
                                                                            </div>
                                                                        </Col>
                                                                        <Col span={8}>
                                                                            <div className="text-right">
                                                                                <b>
                                                                                    {cartao.bce_nrodiasrecebimentodebito} dias
                                                                                </b>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                    <Divider />
                                                                    <Row align="middle" gutter={[8, 8]}>
                                                                        <Col span={8}>
                                                                            Crédito (R$):
                                                                        </Col>
                                                                        {cartao.taxasCartaoCreditoParcelados.length > 0 &&
                                                                            <Col span={8} onClick={() => abrirModalTaxas(cartao)}>
                                                                                <div className="text-center">
                                                                                    <b><a>Ver Taxas</a></b>
                                                                                </div>
                                                                            </Col>
                                                                        }{cartao.taxasCartaoCreditoParcelados.length === 0 &&
                                                                            <Col span={8}>
                                                                                <div className="text-center">
                                                                                    <b>{FormatNumber((parseFloat(cartao.bce_taxapadraocredito) * 100), true, false)}% </b>
                                                                                </div>
                                                                            </Col>
                                                                        }
                                                                        <Col span={8}>
                                                                            <div className="text-right">
                                                                                <b>
                                                                                    {cartao.bce_nrodiasrecebimentocredito} dias
                                                                                </b>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            }
                                            {cartao.bce_id === null &&
                                                <div className="colConfig">
                                                    <Row align="middle">
                                                        <Col span={24}>
                                                            <div className="text-center">
                                                                <Typography.Title level={4}>
                                                                    Não configurado!
                                                                </Typography.Title>
                                                            </div>
                                                        </Col>
                                                        <Col span={24}>
                                                            <div className="text-center">
                                                                <Button icon={<SettingOutlined />} type="primary" onClick={() => {
                                                                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: cartao } });
                                                                    dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                                                }}>
                                                                    Configurar
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                            {cartao.bce_id !== null && !cartao.bce_ativo &&
                                                <div className="colConfig">
                                                    <Row align="middle">
                                                        <Col span={24}>
                                                            <div className="text-center">
                                                                <Typography.Title level={4}>
                                                                    Bandeira de Cartão INATIVA.
                                                                </Typography.Title>
                                                            </div>
                                                        </Col>
                                                        <Col span={24}>
                                                            <div className="text-center">
                                                                <Button icon={<CheckOutlined />} type="" onClick={() => {
                                                                    ativarBandeiraCartaoEmpresa(cartao);
                                                                }}>
                                                                    Ativar
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        </Card>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Form>
            <DrawerBandeiraCartaoEmpresa consultar={consultar}/>
            <ModalTaxasCartaoCredito exibirModalTaxasCartaoCredito={openModalTaxas} fecharModalTaxasCartaoCredito={() => {
                setOpenModalTaxas(false);
                setDadosTaxaCartao({});
            }} dadosTaxaCartao={dadosTaxaCartao}/>
        </div>
    );
}
