import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tag, Table } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { useStateValue } from "../../state";
import { FormatNumber } from '../../ValueObjects';
import { FiltroProdutoPdi, Tabela } from "../../components";

export default function TabItens({ formulario, dadosEndereco, dadosTabela, totalPedido, custo, dadosOperacao }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [editando, setEditando] = useState(false);
    const [dadosEditando, setDadosEditando] = useState({});
    const [quantidade, setQuantidade] = useState(0);

    useEffect(() => {
        if (!!totalPedido && !!totalPedido.setTotalPedido) {
            let valorTotal = 0;
            let qtd = 0;
            if (!!dadosTabela && !!dadosTabela.dadosItens) {
                let seqKey = 0;
                dadosTabela.dadosItens.map(dado => {
                    valorTotal += dado.valorItemDesconto;
                    qtd += dado.pdi_quantidade;
                    dado.key = seqKey;
                    seqKey++;
                });
            }
            totalPedido.setTotalPedido(valorTotal);
            setQuantidade(qtd);
        } else {
            totalPedido.setTotalPedido(0);
            setQuantidade(0);
        }
    }, [dadosTabela.dadosItens]);

    function editarProdutos(item) {
        setDadosEditando(item);
        setEditando(true);
    };

    function removerItem(item) {
        let dadosTemp = [...dadosTabela.dadosItens];
        dadosTemp = dadosTemp.filter((dados) => dados !== item);
        dadosTemp.sort((a, b) => b.pdi_numeroitem - a.pdi_numeroitem);
        dadosTemp = dadosTemp.map((dados, index) => {
            return {
                ...dados,
                pdi_numeroitem: dadosTemp.length - index
            };
        });
        dadosTabela.setDadosItens(dadosTemp);
    };

    return (
        <div>
            <FiltroProdutoPdi form={formulario} dadosTabela={dadosTabela} dadosEndereco={dadosEndereco} custo={custo} dadosOperacao={dadosOperacao} btnEditando={{ editando, setEditando }} itemEditando={dadosEditando} />
            <div className="tabela mt-dif">
                {!!dadosTabela.dadosItens && dadosTabela.dadosItens.length > 0 &&
                    <Table dataSource={dadosTabela.dadosItens}
                        columns={[
                            {
                                title: 'ITEM',
                                width: '25%',
                                fixed: 'left',
                                render: ({ pro_codigo, pro_descricao, pro_ean, descricaograde, prg_ean }) => (
                                    <div>
                                        <Row align="middle" gutter={[5, 0]}>
                                            <Col>
                                                <Tag color="processing" className="w-75">
                                                    <b>{pro_codigo}</b>
                                                </Tag>
                                                <b> {pro_descricao}</b>
                                            </Col>
                                        </Row>
                                        <Row align="middle" gutter={[5, 0]}>
                                            <Col>
                                                EAN: <b>{!!pro_ean ? pro_ean : prg_ean}</b>
                                            </Col>
                                            <Col hidden={!!!descricaograde}>
                                                Grade: <b>{descricaograde}</b>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            },
                            {
                                title: 'N°',
                                align: 'center',
                                width: '2%',
                                fixed: 'left',
                                render: ({ pdi_numeroitem }) => (
                                    <div>
                                        <b>{pdi_numeroitem}</b>
                                    </div>
                                )
                            },
                            // {
                            //     title: 'CFOP',
                            //     align: 'center',
                            //     width: '4%',
                            //     fixed: 'left',
                            //     render: ({ pdi_cfop }) => (
                            //         <div>
                            //             <b>{nfi_cfop}</b>
                            //         </div>
                            //     ),
                            // },
                            // {
                            //     title: `${dadosContribuinte.emp_regimetributario === 1 ? 'CSOSN' : 'CST'}`,
                            //     align: 'center',
                            //     width: '5%',
                            //     fixed: 'left',
                            //     render: ({ cst_id, cso_id }) => (
                            //         <div>
                            //             <Col >
                            //                 <b>{dadosContribuinte.emp_regimetributario === 1 ? cso_id : cst_id}</b>
                            //             </Col>
                            //         </div>
                            //     ),
                            // },
                            {
                                title: 'Qtde.',
                                width: '4%',
                                align: 'right',
                                fixed: 'left',
                                render: ({ pdi_quantidade }) => (
                                    <div>
                                        <b>{FormatNumber(pdi_quantidade, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr Unt.(R$)',
                                align: 'right',
                                width: '5%',
                                fixed: 'left',
                                render: ({ pdi_valorunitario }) => (
                                    <div>
                                        <b>{FormatNumber(pdi_valorunitario, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Desc.',
                                align: 'right',
                                width: '4%',
                                render: ({ percdesc }) => (
                                    <div>
                                        <b>{percdesc}%</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Acres.',
                                align: 'right',
                                width: '4%',
                                render: ({ pdi_percacresc }) => (
                                    <div>
                                        <b>{FormatNumber(pdi_percacresc, true)}%</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr. Frete',
                                align: 'right',
                                width: '4%',
                                render: ({ pdi_valorfrete }) => (
                                    <div>
                                        <b>{FormatNumber(pdi_valorfrete, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Bc. Icms',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos }) => (
                                    <div>
                                        <b>{FormatNumber(impostos?.retornoICMS?.ret_baseIcms, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: '% Icms',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos }) => (
                                    <div>
                                        <b>{FormatNumber(impostos?.retornoICMS?.ret_aliquotaIcms, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr Icms',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos }) => (
                                    <div>
                                        <b>{FormatNumber(impostos?.retornoICMS?.ret_valorIcms, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Bc. ST',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos }) => (
                                    <div>
                                        <b>{FormatNumber(impostos?.retornoICMSST?.ret_baseIcmsSt, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: '% ST',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos }) => (
                                    <div>
                                        <b>{FormatNumber(impostos?.retornoICMSST?.ret_aliquotaIcmsSt, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr ST',
                                align: 'right',
                                width: '4%',
                                render: ({ impostos }) => (
                                    <div>
                                        <b>{FormatNumber(impostos?.retornoICMSST?.ret_valorIcmsSt, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr Total (R$)',
                                align: 'right',
                                width: '6%',
                                fixed: 'right',
                                render: ({ valorItemDesconto }) => (
                                    <div>
                                        <b>{FormatNumber(valorItemDesconto, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                key: 'x',
                                align: 'center',
                                width: '5%',
                                fixed: 'right',
                                render: (record, e, indice) => (
                                    <div>
                                        <Row align="center" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button icon={<EditOutlined />} onClick={() => editarProdutos(record)} />
                                            </Col>
                                            <Col>
                                                <Button icon={<DeleteOutlined />} onClick={() => removerItem(record)} />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]}
                        scroll={{
                            x: 1500,
                            y: 300,
                        }}
                    />
                }
            </div>
            <div className="m-t-16">
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col className="f-16">
                        Quantidade:
                    </Col>
                    <Col>
                        <b className="f-21">{quantidade}</b>
                    </Col>
                </Row>
                <Row justify="end" align="middle" gutter={[8, 0]}>
                    <Col>
                        <span className="f-14"> Valor Total dos Itens (R$):</span>
                    </Col>
                    <Col>
                        <b className="f-16">
                            {FormatNumber(totalPedido.totalPedido, true)}
                        </b>
                    </Col>
                </Row>
            </div>
        </div>
    );
}