import React, { useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined, MinusOutlined, SaveOutlined } from '@ant-design/icons';

import { useStateValue } from '../../state';
import { DrawerNav } from '../../components';
import ManutencaoEtiquetaServidor from './manutencao';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';

export default function DrawerEtiquetaServidor() {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [formulario] = Form.useForm();
    const [carregando, setCarregando] = useState(false);

    if (!!manutencao.dados) {
        formulario.setFieldsValue(manutencao.dados);
    }

    function fecharDrawer() {
        formulario.resetFields();
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-met_descricao' } });
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <div>
            <DrawerNav title="Manutenção de modelos de etiqueta"
                width="70%"
                visible={ui.showDrawer}
                closeIcon={<MinusOutlined />}
                footer={
                    <div>
                        <Row align="middle" justify="end" gutter={[8, 0]}>
                            <Col>
                                <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                    Cancelar
                                </Button>
                            </Col>
                            <Col>
                                <Button onClick={() => formulario.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                    Salvar
                                </Button>
                            </Col>
                        </Row>
                    </div>
                }>
                <ManutencaoEtiquetaServidor formulario={formulario} carregando={setCarregando} aoSalvar={() => { fecharDrawer() }} />
            </DrawerNav>
        </div>
    );
};