import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, Modal, Descriptions } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { info } from "../../../services/funcoes";
import { useStateValue } from "../../../state";
import { manutencaoActions } from "../../../actions";
import { getEmpresa } from "../../../services/auth";

export default function ModalRetornaContaGerencial({ exibirModalContaGerencial, fecharModalContaGerencial, descricao = "", avistaAPrazo = false }) {
    const [{ manutencao }, dispatch] = useStateValue();
    const [listaContaGerencial, setListaContaGerencial] = useState([]);
    const [listaContaGerencialTodas, setListaContaGerencialTodas] = useState([]);
    const [form] = Form.useForm();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalContaGerencial();
            }
        });
    };

    useEffect(() => {
        carregarListaConta();
    }, []);

    useEffect(() => {
        if (!!manutencao.dados && manutencao.dados.dadosEmpresa) {
            if (avistaAPrazo) {
                form.setFieldsValue({ cgc_contagerencialdestino: manutencao.dados.dadosEmpresa.cgc_contagerencialdestino });
                form.setFieldsValue({ cgc_contagerencialdestino2: manutencao.dados.dadosEmpresa.cgc_contagerencialdestino2 });
            }
        }
    }, [manutencao.dados])

    function carregarListaConta() {
        api.get(`ContaGerencial/ListarAtivosEstruturadoPelaCategoria?PageSize=1000&PageNumber=1`).then(
            res => {
                let lista = [];
                let dados = res.data.items
                setListaContaGerencialTodas(dados);
                for (const conta of dados) {
                    if (conta.nivel === 0) {
                        conta.listaContas = [];
                        lista.push(conta);
                    }
                }
                for (const conta of dados) {
                    if (conta.nivel !== 0) {
                        let obj = lista.find(contaLista => contaLista.ctc_id === conta.ctc_id);
                        if (!!obj && !!obj.listaContas) {
                            obj.listaContas.push(conta);
                        }

                    }
                }
                setListaContaGerencial(lista);

            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function adicionarConfig(values) {
        values.emp_id = getEmpresa();
        values.listaContas = []
        if (!!form.getFieldValue().cgc_contagerencialdestino2) {
            values.listaContas = listaContaGerencialTodas.filter((conta) => conta.ctg_id === form.getFieldValue().cgc_contagerencialdestino || conta.ctg_id === form.getFieldValue().cgc_contagerencialdestino2)
        } else {
            values.listaContas = listaContaGerencialTodas.filter((conta) => conta.ctg_id === form.getFieldValue().cgc_contagerencialdestino);
        }
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ dadosContaGerencial: values } } } });
        fecharModalContaGerencial(values);
    };

    return (
        <Modal centered
            title="Cadastro das Configurações das Contas Gerenciais"
            visible={exibirModalContaGerencial}
            onCancel={onCloseModal}
            onOk={() => { form.submit() }}
            okText={
                <>
                    <PlusOutlined /> Adicionar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            destroyOnClose
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="configuracaoContaGerencial" form={form} onFinish={adicionarConfig} initialValues={{ remember: true }}>
                    <Row>
                        <Col>
                            <Descriptions>
                                <Descriptions.Item>
                                    {descricao}
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Form.Item name="key" hidden />
                    <Row align="middle" gutter={[8, 8]}>
                        <Col span={24}>
                            <Form.Item label={`Selecione a Conta Gerencial ${avistaAPrazo ? '(À Vista)' : ''}`} name="cgc_contagerencialdestino" rules={[{ required: true, message: 'Selecione a Conta Gerencial' }]}>
                                <Select showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial...">
                                    {listaContaGerencial.map(item => (
                                        <Select.OptGroup label={item.ctg_descricao}>
                                            {item.listaContas.map(conta => (
                                                <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                            ))}
                                        </Select.OptGroup>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {avistaAPrazo && <Row align="middle" gutter={[8, 8]}>
                        <Col span={24}>
                            <Form.Item label="Selecione a Conta Gerencial (À Prazo)" name="cgc_contagerencialdestino2" rules={[{ required: true, message: 'Selecione a Conta Gerencial' }]}>
                                <Select showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial...">
                                    {listaContaGerencial.map(item => (
                                        <Select.OptGroup label={item.ctg_descricao}>
                                            {item.listaContas.map(conta => (
                                                <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                            ))}
                                        </Select.OptGroup>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>}
                </Form>
            </div>
        </Modal>
    );
}