import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography, Form, Divider, Select, Button } from 'antd';
import { ExclamationCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { LineChart, Line, BarChart, Bar, LabelList } from 'recharts';
import moment from 'moment';

import api from '../../services/api';
import { getEmpresa, getLocal } from '../../services/auth';
import { Data, BreadcrumbPage, BotaoAbrirChamado } from '../../components';
import { CartesianChart, CartesianChartBar, RenderChartPie, TableRenderer, ComponentSpin } from './funcoes';

const numberFormatter = (item, casasDecimais = 2) => item.toLocaleString({ style: 'currency', currency: 'BRL' }, { minimumFractionDigits: casasDecimais, maximumFractionDigits: casasDecimais });

export default function ReceitasDespesas({ graficoSelecionado }) {
    const [formFiltros] = Form.useForm();
    const [listaLocaisUsuario, setListaLocaisUsuario] = useState([]);
    const [totalizadores, setTotalizadores] = useState({});
    const [receitaDespesaDiaria, setReceitaDespesaDiaria] = useState([]);
    const [resultadoPorTipoConta, setResultadoPorTipoConta] = useState([]);
    const [resultadoCategoria, setResultadoCategoria] = useState([]);
    const [resultadoContaGerencial, setResultadoContaGerencial] = useState([]);
    const [comparativo12Meses, setComparativo12Meses] = useState([]);
    const [carregandoTotalizadores, setCarregandoTotalizadores] = useState(false);
    const [carregandoRecDespDiaria, setCarregandoRecDespDiaria] = useState(false);
    const [carregandoTipoConta, setCarregandoTipoConta] = useState(false);
    const [carregandoCategoria, setCarregandoCategoria] = useState(false);
    const [carregandoContaGerencial, setCarregandoContaGerencial] = useState(false);
    const [carregandoRecDespAnual, setCarregandoRecDespAnual] = useState(false);

    useEffect(() => {
        api.get(`/Local/ListarLocalUsuario?codigoEmpresa=${getEmpresa()}`).then(res => {
            if (res.status === 200) {
                setListaLocaisUsuario(res.data.items);
            }
        }).catch((erro) => console.error(erro));

        carregarDados();
    }, []);

    function carregarDados() {
        let dataInicial = !!formFiltros.getFieldValue().dataInicial ? moment(formFiltros.getFieldValue().dataInicial).format('YYYY-MM-DD') : null;
        let dataFinal = !!formFiltros.getFieldValue().dataFinal ? moment(formFiltros.getFieldValue().dataFinal).format('YYYY-MM-DD') : null;
        let tipoConta = !!formFiltros.getFieldValue().tipoConta ? formFiltros.getFieldValue().tipoConta.join(',') : '';

        let mesAtual = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
        let ultimosDozeMeses = new Date(mesAtual.getFullYear(), mesAtual.getMonth() - 11, 1);
        let locais = !!formFiltros.getFieldValue().local ? formFiltros.getFieldValue().local.join(',') : '';
        let mesAnterior = moment(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 30)).format("YYYY-MM-DD");

        setCarregandoTotalizadores(true);
        setCarregandoRecDespDiaria(true);
        setCarregandoTipoConta(true);
        setCarregandoCategoria(true);
        setCarregandoContaGerencial(true);
        setCarregandoRecDespAnual(true);
        api.get(`Dashboard/ListarTotalizadoresRecDesp?locais=${locais}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&tipoConta=${tipoConta}`).then(
            (res) => {
                if (!!res.data && res.data.length > 0) {
                    setTotalizadores(res.data[0]);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoTotalizadores(false);
        });

        api.get(`Dashboard/ListarRecDespDiaria?locais=${locais}&dataInicial=${mesAnterior}&dataFinal=${dataFinal}&tipoConta=${tipoConta}`).then(
            (res) => {
                if (!!res.data) {
                    setReceitaDespesaDiaria(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoRecDespDiaria(false);
        });

        api.get(`Dashboard/ListarResultadoTipoConta?locais=${locais}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&tipoConta=${tipoConta}`).then(
            (res) => {
                if (!!res.data) {
                    setResultadoPorTipoConta(res.data.filter((x) => { return x.vlr_resultado >= 0 }));
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoTipoConta(false);
        });

        api.get(`Dashboard/ListarResultadoCategoria?locais=${locais}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&tipoConta=${tipoConta}`).then(
            (res) => {
                if (!!res.data) {
                    setResultadoCategoria(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoCategoria(false);
        });

        api.get(`Dashboard/ListarResultadoContaGerencial?locais=${locais}&dataInicial=${dataInicial}&dataFinal=${dataFinal}&tipoConta=${tipoConta}`).then(
            (res) => {
                if (!!res.data) {
                    setResultadoContaGerencial(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoContaGerencial(false);
        })

        api.get(`Dashboard/ListarRecDespAnual?locais=${locais}&dataInicial=${moment(ultimosDozeMeses).format("YYYY-MM-DD")}&dataFinal=${moment(mesAtual).format("YYYY-MM-DD")}&tipoConta=${tipoConta}`).then(
            (res) => {
                if (!!res.data) {
                    setComparativo12Meses(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoRecDespAnual(false);
        })
    };

    return (
        <div>
            <Row align="middle" justify="space-between" gutter={[8, 0]} className="col-pagina-breadcrumb"> 
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoAbrirChamado />
                        </Col>
                        <Col>
                            <Button icon={<ArrowLeftOutlined />} size="middle" type="primary" onClick={() => { graficoSelecionado.setGraficoSelecionado(null) }}>
                                Voltar
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Row justify="center" gutter={[8, 0]}>
                        <Col>
                            <Typography.Title level={3} className="m-b-0">
                                <img src={require("../../assets/i-tranferencia.png").default} alt="Receitas x Despesas" /> Receitas x Despesas
                            </Typography.Title>
                        </Col>                        
                    </Row>
                </Col>
                <Col span={24}>
                    <Form form={formFiltros} layout="vertical">
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={2}>
                                <Data label="Data Inicial" name="dataInicial" initialValue={new Date(new Date().getFullYear(), new Date().getMonth(), 1)} onBlur={carregarDados} />
                            </Col>
                            <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={2}>
                                <Data label="Data Final" name="dataFinal" initialValue={new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)} onBlur={carregarDados} />
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={10}>
                                <Form.Item name="local" label="Local" initialValue={[parseInt(getLocal())]}>
                                    <Select onChange={carregarDados} mode="multiple" allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione o local...">
                                        {listaLocaisUsuario.filter(ativa => (ativa.loc_ativo)).map(local => (
                                            <Select.Option key={local.loc_id} value={local.loc_id}> {local.loc_descricao} </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={10}>
                                <Form.Item name="tipoConta" label="Tipo Conta">
                                    <Select onChange={carregarDados} mode="multiple" allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione o tipo de conta...">
                                        <Select.Option key={1} value={1}>Caixa</Select.Option>
                                        <Select.Option key={2} value={2}>Banco</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="m-t-8 card-dash">
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                    <Card>
                        <ComponentSpin carregando={carregandoTotalizadores}>
                            <Typography.Title level={4} className="m-b-0">
                                Valor Total Receita (R$)
                            </Typography.Title>
                            <Typography.Title level={2} className="m-t-0 m-b-0 text-right">
                                {numberFormatter(!!totalizadores.vlr_total_receita ? totalizadores.vlr_total_receita : 0)}
                            </Typography.Title>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                    <Card>
                        <ComponentSpin carregando={carregandoTotalizadores}>
                            <Typography.Title level={4} className="m-b-0">
                                Valor Total Despesa (R$)
                            </Typography.Title>
                            <Typography.Title level={2} className="m-t-0 m-b-0 text-right">
                                {numberFormatter(!!totalizadores.vlr_total_despesa ? totalizadores.vlr_total_despesa : 0)}
                            </Typography.Title>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <Card>
                        <ComponentSpin carregando={carregandoTotalizadores}>
                            <Typography.Title level={4} className="m-b-0">
                                Valor Resultado (Receita-Despesa) (R$)
                            </Typography.Title>
                            <Typography.Title level={2} className="m-t-0 m-b-0 text-right">
                                {numberFormatter(!!totalizadores.vlr_resultado ? totalizadores.vlr_resultado : 0)}
                            </Typography.Title>
                        </ComponentSpin>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="m-t-16">
                <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={14}>
                    <Card>
                        <ComponentSpin carregando={carregandoRecDespDiaria}>
                            <Typography.Title level={4}>
                                Valor Total Receita x Despesa por - Últimos 30 dias
                            </Typography.Title>
                            <div>
                                {receitaDespesaDiaria.length > 0 ? <CartesianChart dataKey="data" resultSet={receitaDespesaDiaria} ChartComponent={LineChart} >
                                    <Line dataKey={"vlr_total_receita"} type="linear" stroke="rgb(227 35 173)" name={"Valor Total Receita"} fill="rgb(227 35 173)" /*legendType="circle"*/ />
                                    <Line dataKey={"vlr_total_despesa"} type="linear" stroke="rgb(31, 119, 180)" name={"Valor Total Despesa"} fill="rgb(31, 119, 180)" /*legendType="circle"*/ />
                                </CartesianChart> : <Row className="col-charts">
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>}
                            </div>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={10}>
                    <Card>
                        <ComponentSpin carregando={carregandoTipoConta}>
                            <Typography.Title level={4}>
                                Resultado (Receita - Despesa) por Tipo de Conta
                            </Typography.Title>
                            <div>
                                {resultadoPorTipoConta.length > 0 ?
                                    <RenderChartPie resultSet={resultadoPorTipoConta} dataKey="vlr_resultado" nameKey="tipo_conta" /> : <Row className="col-charts">
                                        <Col span={24}>
                                            <Divider orientation="center">
                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                            </Divider>
                                        </Col>
                                    </Row>
                                }
                            </div>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card>
                        <ComponentSpin carregando={carregandoRecDespAnual}>
                            <Typography.Title level={4}>
                                Comparativo entre Receita e Despesa - Últimos 12 Meses
                            </Typography.Title>
                            <div>
                                {comparativo12Meses.length > 0 ? <CartesianChartBar resultSet={comparativo12Meses} legend tickFormatter={(item) => moment(item).format("MM/YYYY")} dataKey="periodo" ChartComponent={BarChart}>
                                    <Bar dataKey="vlr_total_receita" name={"Total Receita"} fill="#4b97efd4" barSize={45}>
                                        <LabelList dataKey="vlr_total_receita" position="top" /*content={renderCustomizedLabelBar}*/ formatter={numberFormatter} />
                                    </Bar>
                                    <Bar dataKey="vlr_total_despesa" name={"Total Despesa"} fill="#ffb117" barSize={45}>
                                        <LabelList dataKey="vlr_total_despesa" /*content={renderCustomizedLabelBar}*/ position="top" formatter={numberFormatter} />
                                    </Bar>
                                </CartesianChartBar> : <Row className="col-charts">
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>}
                            </div>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <Card>
                        <Typography.Title level={4}>
                            Receita x Despesa por Categoria Conta Gerencial
                        </Typography.Title>
                        <div>
                            <TableRenderer dataSource={resultadoCategoria} carregando={carregandoCategoria} columns={[{
                                title: 'Categoria Conta Gerencial',
                                render: ({ categoria_conta_gerencial }) => (
                                    <div>
                                        <b>{categoria_conta_gerencial}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Valor Total Receita (R$)',
                                align: 'right',
                                render: ({ vlr_total_receita }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(vlr_total_receita))}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Valor Total Despesa (R$)',
                                align: 'right',
                                render: ({ vlr_total_despesa }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(vlr_total_despesa))}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Valor Resultado (R$)',
                                align: 'right',
                                render: ({ vlr_resultado }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(vlr_resultado))}</b>
                                    </div>
                                ),
                            }]} />
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
                    <Card>
                        <Typography.Title level={4}>
                            Receita x Despesa por Conta Gerencial
                        </Typography.Title>
                        <div>
                            <TableRenderer dataSource={resultadoContaGerencial} carregando={carregandoContaGerencial} columns={[{
                                title: 'Conta Gerencial',
                                render: ({ conta_gerencial }) => (
                                    <div>
                                        <b>{conta_gerencial}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Valor Total Receita (R$)',
                                align: 'right',
                                render: ({ vlr_total_receita }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(vlr_total_receita))}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Valor Total Despesa (R$)',
                                align: 'right',
                                render: ({ vlr_total_despesa }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(vlr_total_despesa))}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Valor Resultado (R$)',
                                align: 'right',
                                render: ({ vlr_resultado }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(vlr_resultado))}</b>
                                    </div>
                                ),
                            }]} />
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};