import React, { useEffect } from "react";
import { Row, Col, Tag, notification, Modal } from "antd";
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from '../../state';
import DrawerFornecedorProduto from './drawer';
import { FormatNumber } from "../../ValueObjects";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { PesquisaOrdenacao, TabelaDados, BreadcrumbAbrirChamado, Editar, Excluir } from "../../components";

export default function RelacionamentoFornecedorProduto() {

    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', ordem: '+pes_nome' } })
    }, []);

    function editarRegistro(record) {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function modalExcluirRegistro(record) {
        Modal.confirm({
            title: 'Aviso',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja realmente excluir este registro?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(record);
            }
        });
    };

    function excluirRegistro(record) {
        api.delete(`fornecedorProduto/Excluir/${record.fpr_id}`).then(
            res => {
                notification.success({ description: 'Registro excluído com sucesso!', message: 'Aviso' });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pes_nome' } });
            }
        )
    };

    return (
        <div className="p-10">
            <BreadcrumbAbrirChamado />
            <Row>
                <Col span={24}>
                    <PesquisaOrdenacao opcoesOrdenacao={[
                        { label: "Nome Fornecedor A - Z", value: "+pes_nome" },
                        { label: "Nome Fornecedor Z - A", value: "-pes_nome" },
                        { label: "Código Crescente", value: "+fpr_codigo" },
                        { label: "Código Decrescente", value: "-fpr_codigo" }
                    ]} />
                </Col>
            </Row>
            <div className="tabela">
                <TabelaDados url="fornecedorproduto/listar" colunas={[
                    {
                        title: 'Código',
                        width: 90,
                        align: 'center',
                        render: ({ pro_codigo }) => (
                            <Tag color="processing" className="w-100">
                                <b>{pro_codigo}</b>
                            </Tag>
                        ),
                    },
                    {
                        title: 'Produto',
                        render: ({ pro_descricao }) => (
                            <div>
                                <b>{pro_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Cód. Prod./Fornec.',
                        width: 150,
                        align: 'center',
                        render: ({fpr_codigo }) => (
                            <div>
                                <b>{fpr_codigo}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Fornecedor',
                        render: ({for_codigo, pes_nome }) => (
                            <div>
                                <b>{for_codigo}-{pes_nome}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Último Custo (R$)',
                        align: 'right',
                        width: 150,
                        render: ({ ultimocusto }) => (
                            <div>
                                <b>{FormatNumber(ultimocusto, true)}</b>
                            </div>
                        ),
                    }, {

                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        fixed: 'right',
                        width: 65,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Editar icon={<EditOutlined />} onClick={() => {
                                            editarRegistro(record);
                                        }}/>
                                    </Col>
                                    <Col>
                                        <Excluir icon={<DeleteOutlined />} onClick={() => {
                                            modalExcluirRegistro(record)
                                        }}/>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerFornecedorProduto />
        </div>
    );

}