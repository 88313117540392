import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, notification, Modal } from "antd";
import { ExclamationCircleOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";

import { useStateValue } from "../../../state";
import { selectPaginadoActions } from "../../../actions";
import SelectPaginacao from "../../selects/selectPaginacao";

export default function ModalOcupacao({ form, listaOcupacoes, exibirModalOcupacao, fecharModalOcupacao }) {
    const [{ ui, listaSelectPaginacao }, dispatch] = useStateValue();

    useEffect(() => {
        if (exibirModalOcupacao && !!listaOcupacoes) {
            let lista = [];
            if (!!listaOcupacoes.cbo_id) {
                lista.push({ name: "cbo_id", campo: "cbo_id", value: listaOcupacoes.cbo_id });
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }
    }, [exibirModalOcupacao]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                form.resetFields();
                fecharModalOcupacao();
            }
        });
    };

    const onFinish = values => {
        let listaCbo = [...listaSelectPaginacao.itens];
        let cbo = listaCbo.filter((c) => c.cbo_id === form.getFieldValue().cbo_id)[0];
        let indice = form.getFieldValue().indice;
        let lista = [...listaOcupacoes.dadosOcupacoes];

        values.cbo_descricao = cbo.cbo_descricao;
        if (indice >= 0) {
            lista[indice] = values;
        } else {
            let verificaOcupacaoAdd = lista.filter((c) => c.cbo_id === form.getFieldValue().cbo_id).length;
            if (verificaOcupacaoAdd.length > 0) {
                onCloseModal();
                notification.info({ message: 'Ocupação já adicionada!' });
            } else {
                if (lista.length > 0) {
                    values.poc_principal = false;
                } else {
                    values.poc_principal = true;
                }
                lista.push(values);
            }
        }
        listaOcupacoes.setDadosOcupacoes(lista);
        form.resetFields();
        fecharModalOcupacao();
    };

    return (
        <Modal centered
            title="Cadastro de Ocupações"
            visible={exibirModalOcupacao}
            onCancel={() => {
                onCloseModal();
            }}
            onOk={() => form.submit()}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="manutencaoOcupacao" form={form} onFinish={onFinish}>
                    <Row align="middle" gutter={[8, 8]}>
                        <Col span={24}>
                            <Form.Item label="Ocupação" name="cbo_id">
                                <SelectPaginacao url="Cbo\Listar" placeholder="Selecione uma Ocupação" form={form} nameValue="cbo_id" nameLabel={"cbo_descricao"}
                                    conteudo={
                                        ocup => (<Select.Option value={ocup.cbo_id}>{ocup.cbo_id} - {ocup.cbo_descricao} </Select.Option>)
                                    } />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}