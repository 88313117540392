import React from "react";
import { Row, Col, Form, Tag, Button, Table, InputNumber, Switch } from "antd";
import { EditOutlined, } from "@ant-design/icons";
import CurrencyInput from 'react-currency-input';

export default function PageGrade({ grades, setGrades }) {

    const [form] = Form.useForm();

    const onAlterAllLList = (values) => {
        if (values) {
            const { lpi_perccomissao, lpi_valorvenda, lpi_naocalculacomissao } = values;
            let array = [...grades];
            array = array.map((x) => ({ ...x, lpi_perccomissao: parseFloat(lpi_perccomissao ? lpi_perccomissao : x.lpi_perccomissao), lpi_valorvenda: parseFloat(lpi_valorvenda ? lpi_valorvenda : x.lpi_valorvenda), lpi_naocalculacomissao: lpi_naocalculacomissao }));
            setGrades(array);
            form.resetFields();
        }
    }

    const updateValue = (ean, property, value) => {
        const array = [...grades];
        const index = array.findIndex(x => x.prg_ean == ean);
        if (index !== -1) {
            array[index][property] = value;
            setGrades(array);
        }
    }

    return (
        <div>
            <Form layout="vertical" form={form} onFinish={onAlterAllLList}>
                <Row gutter={[8, 0]} justify="end">
                    <Col xs={24} sm={6} md={8} lg={6} xl={6}>
                        <Form.Item label="Percentual de Comissão" name="lpi_perccomissao">
                            <InputNumber
                                defaultValue={0}
                                min={0}
                                max={100}
                                formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={7} lg={6} xl={6}>
                        <Form.Item label="Valor do Item" name="lpi_valorvenda">
                            <CurrencyInput inputType="text" className="CurrencyInput" decimalSeparator="," thousandSeparator="." />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={9} lg={7} xl={7} className="switch-modal">
                        <Form.Item label="Considerar Percentual de Desconto" name="lpi_naocalculacomissao" valuePropName="checked">
                            <Switch />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={7} lg={5} xl={5}>
                        <Button type="primary" htmlType="submit" block icon={<EditOutlined />} className="m-t-19 t0">
                            Alterar todos os itens
                        </Button>
                    </Col>
                </Row>
            </Form>
            <div className="tabela mt-dif">
                <Table
                    dataSource={grades}
                    columns={[
                        {
                            title: 'EAN',
                            width: 75,
                            align: 'center',
                            render: ({ prg_ean }) => (
                                <Tag color="processing" className="w-100">
                                    <b>{prg_ean}</b>
                                </Tag>
                            ),
                        },
                        {
                            title: 'Descrição',
                            render: ({ descricaoGrade }) => (
                                <div>
                                    <b>{descricaoGrade}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Percentual Desconto',
                            align: 'right',
                            width: '25%',
                            render: ({ lpi_naocalculacomissao, prg_ean }) => (
                                <Switch checked={lpi_naocalculacomissao} onChange={(checked) => updateValue(prg_ean, 'lpi_naocalculacomissao', checked)} />
                            )
                        },
                        {
                            title: 'Comissão',
                            align: 'right',
                            width: '25%',
                            render: ({ prg_ean, lpi_perccomissao }) => (
                                <InputNumber
                                    value={lpi_perccomissao}
                                    min={0}
                                    max={100}
                                    onChange={(value) => updateValue(prg_ean, 'lpi_perccomissao', value)}
                                    formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')} />
                            )
                        },
                        {
                            title: 'Valor Item',
                            width: '25%',
                            align: 'right',
                            render: ({ prg_ean, lpi_valorvenda }) => (                       
                                <CurrencyInput
                                    value={lpi_valorvenda}
                                    onChange={(value) => updateValue(prg_ean, 'lpi_valorvenda', value)}
                                    inputType="text"
                                    className="CurrencyInput"
                                    decimalSeparator="," thousandSeparator="."
                                />
                            )
                        },
                    ]} scroll={{ x: 900 }}/>
            </div>
        </div >
    );
}