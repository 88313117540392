import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Checkbox, Select, Tooltip, Tabs, Typography, notification, Divider, Button } from "antd";
import { QuestionCircleOutlined, PlusOutlined } from "@ant-design/icons";

import TabCfop from "./tabCfop";
import api from "../../services/api";
import { FormGW, SelectPaginacao } from "../../components";
import { useStateValue } from '../../state';
import { listagemActions, selectPaginadoActions } from "../../actions";
import DrawerContaGerencial from './drawerContaGerencial';

export default function ManutencaoOperacaoFiscal({ formulario, carregando, aoSalvar }) {
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [hiddenFlagsCompra, setHiddenFlagsCompra] = useState(true);
    const [{ manutencao }, dispatch] = useStateValue();
    const [listaCfop, setListaCfop] = useState([]);
    const [listaIndicadorPresenca, setListaIndicadorPresenca] = useState([]);
    const [listaNaturezaOperacao, setListaNaturezaOperacao] = useState([]);
    const [listaContaGerencial, setListaContaGerencial] = useState([]);
    const [listaContaGerencialAgrupador, setListaContaGerencialAgrupador] = useState([]);
    const [listaTipoPreco, setListaTipoPreco] = useState([]);
    const [listaTributacaoPisCofins, setListaTributacaoPisCofins] = useState([]);
    const [listaTributacaoIcms, setListaTributacaoIcms] = useState([]);
    const [openDrawerContaGerencial, setOpenDrawerContaGerencial] = useState(false);
    const [codigoContaGerencial, setCodigoContaGerencial] = useState(null);

    useEffect(() => {
        api.get(`Enum/Listar?nome=IndicadorPresenca`).then(res => {
            setListaIndicadorPresenca(res.data);
        });
        api.get(`TipoOperacao/Listar?filtro=`).then(res => {
            setListaNaturezaOperacao(res.data);
        });
        carregarContasGerenciais();
        api.get(`Enum/Listar?nome=TipoPrecoUtilizar`).then(res => {
            setListaTipoPreco(res.data);
        });
        api.get(`TributacaoPisCofins/ListarAtivos`).then(res => {
            setListaTributacaoPisCofins(res.data);
        });
        api.get(`TributacaoIcms/Listar`).then(res => {
            setListaTributacaoIcms(res.data.items);
        });
    }, []);

    useEffect(() => {
        if (!!manutencao.dados?.cfop) {
            let key = 0;
            manutencao.dados.cfop.forEach(
                (item) => {
                    item.key = key++;
                }
            )
            manutencao.dados.cfop.sort((a, b) => a.opc_destinooperacao - b.opc_destinooperacao)
            setListaCfop(manutencao.dados.cfop);
            if (manutencao.dados.tip_id == 2)
                setHiddenFlagsCompra(false);
        }
    }, [manutencao.dados])

    useEffect(() => {
        if (!!codigoContaGerencial) {
            carregarContasGerenciais();
        }
    }, [codigoContaGerencial]);

    function carregarContasGerenciais() {
        api.get(`ContaGerencial/ListarAtivosEstruturadoPelaCategoria?filtro=`).then(res => {
            setListaContaGerencialAgrupador(res.data.filter((item) => { return item.podeSelecionar === false }));
            setListaContaGerencial(res.data.filter((item) => { return item.podeSelecionar === true }));
        }).finally(
            formulario.setFieldsValue({ ctg_id: codigoContaGerencial })
        );
    }

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ope_descricao' } });
    };

    function salvarOperacaoFiscal(values) {
        if (!!listaCfop && listaCfop.length > 0) {
            values.operacaocfops = listaCfop;
        } else {
            notification.warning({ message: `Aviso!`, description: `É Obrigatório informar pelo menos um CFOP na operação` })
            return false;
        }
        values.ope_calculacomissao = false;
        values.ope_alteracontagerencial = false;
        carregando(true);

        if (!!values.ope_id) {
            editarManutencao(values);
        } else {
            incluirManutencao(values);
        };
    };

    function editarManutencao(values) {
        api.put(`OperacaoFiscal/Editar`, values).then(res => {
            notification.success({ message: 'Operação concluída', description: "Os dados da Operação Fiscal foram salvos com sucesso!" });
        }).catch(
            erro => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
            }).finally(() => {
                atualizarPagina();
                carregando(false);
                aoSalvar();
            });
    }

    function incluirManutencao(values) {
        api.post(`OperacaoFiscal/Incluir`, values).then(res => {
            notification.success({ message: 'Operação concluída', description: "Os dados da Operação Fiscal foram salvos com sucesso!" });
        }).catch(
            erro => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
            }).finally(() => {
                atualizarPagina();
                carregando(false);
                aoSalvar();
            });
    }
    function checkMarcadores() {
        if (formulario.getFieldValue(['tip_id']) == 2)
            setHiddenFlagsCompra(false);
        else
            setHiddenFlagsCompra(true);
    };

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="formOperacaoFiscal" onFinish={salvarOperacaoFiscal}>
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Itens" key="1">
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={24}>
                                <Typography.Text>
                                    Selecione o Tipo de Imposto a Calcular
                                </Typography.Text>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[8, 0]}>
                            <Col>
                                <Form.Item valuePropName='checked' name="ope_calculaicms" initialValue={false}>
                                    <Checkbox>ICMS?</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item valuePropName='checked' name="ope_calculaipi" initialValue={false}>
                                    <Checkbox>IPI?</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item valuePropName='checked' name="ope_calculaicmsst" initialValue={false}>
                                    <Checkbox>ICMS ST?</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item valuePropName='checked' name="ope_calculapiscofins" initialValue={false}>
                                    <Checkbox>PIS/COFINS?</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item valuePropName="checked" initialValue={false} name="ope_calculaissqn">
                                    <Checkbox>ISSQN?</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item valuePropName='checked' name="ope_calcularetencao" initialValue={false}>
                                    <Checkbox>Retenção?</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={12} lg={17} xl={17} xxl={7}>
                                <Form.Item name="ope_id" hidden />
                                <Form.Item label="Descrição" name="ope_descricao" rules={[{ required: true, message: 'Informe a Descrição da Operação Fiscal' }]}>
                                    <Input placeholder="Informe a descrição da operação fiscal" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={7} xl={7} xxl={6}>
                                <Form.Item label="Indicador de Presença do Comprador" name="ope_indicadorpresenca">
                                    <Select showSearch optionFilterProp="children" placeholder="Selecione Indicador de Presença do Comprador">
                                        {listaIndicadorPresenca?.map(item => (
                                            <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6} xl={5} xxl={5}>
                                <Form.Item label="Natureza da Operação" name="tip_id" rules={[{ required: true, message: 'Informe a Natureza da Operação' }]}>
                                    <Select showSearch optionFilterProp="children" onChange={checkMarcadores} placeholder="Selecione Tipo da Operação">
                                        {listaNaturezaOperacao?.map(item => (
                                            <Select.Option key={item.tip_id} value={item.tip_id}>{item.tip_descricao}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={5} xl={6} xxl={6}>
                                <Form.Item label="Conta Gerencial" name="ctg_id" rules={[{ required: true, message: 'Informe a Conta Gerencial' }]}>
                                    <Select showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial" dropdownRender={menu => (
                                        <div>
                                            <Row>
                                                <Col span={24}>
                                                    {menu}
                                                </Col>
                                            </Row>
                                            <Row className="m-b-8">
                                                <Col span={24}>
                                                    <Divider className="m-0" />
                                                </Col>
                                            </Row>
                                            <Row justify="center" className="m-t-5">
                                                <Col>
                                                    <Button type="primary" icon={<PlusOutlined />} size="small" onClick={() => { setOpenDrawerContaGerencial(true) }}>
                                                        Novo
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    )}>
                                        {listaContaGerencialAgrupador.map(item => (
                                            <Select.OptGroup label={item.ctg_descricao}>
                                                {listaContaGerencial.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                                    <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                                ))}
                                            </Select.OptGroup>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={5} xl={5} xxl={5}>
                                <Form.Item name="ope_tipoprecoutilizar" label={
                                    <div>
                                        Tipo Preço
                                        <Tooltip title="Tipo de preço específico considerado para esta operação.">
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </div>
                                }>
                                    <Select showSearch optionFilterProp="children" placeholder="Selecione Tipo de Preço a Utilizar para esta Operação">
                                        {listaTipoPreco?.map(item => (
                                            <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={7}>
                                <Form.Item name="pis_id" label={
                                    <div>
                                        Código Tributação para PIS/COFINS
                                        <Tooltip title="Informar a Tributação do PIS e COFINS para operações que exijam a apresentação dos tributos de forma genérica.">
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </div>
                                }>
                                    <Select showSearch optionFilterProp="children" allowClear={true} placeholder="Selecione a Tributação PIS/COFINS para esta Operação">
                                        {listaTributacaoPisCofins?.map(item => (
                                            <Select.Option key={item.pis_id} value={item.pis_id}>{item.pis_descricao}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                <Form.Item name="icm_id" label={
                                    <div>
                                        Código Tributação para ICMS
                                        <Tooltip title="Informar a Tributação do ICMS para operações que exijam a apresentação dos tributos de forma genérica.">
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </div>
                                }>
                                    <Form.Item name="icm_id">
                                        <SelectPaginacao
                                            url="TributacaoIcms/Listar"
                                            showSearch
                                            allowClear={true}
                                            optionFilterProp="children"
                                            placeholder="Selecione a Tributação do ICMS para esta Operação"
                                            nameValue="icm_id"
                                            nameLabel="icm_descricao"
                                            form={formulario}
                                            conteudo={item => (
                                                <Select.Option key={item.icm_id} value={item.icm_id}>{item.icm_descricao}</Select.Option>
                                            )} />
                                    </Form.Item>
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Form.Item label="Código Benefício Fiscal" name="cbf_id">
                                    <SelectPaginacao url="TabelaCbenef/Listar" placeholder="Informe o Código Benefício Fiscal" form={formulario} allowClear nameValue="cbf_id" nameLabel={"cbf_codigo"} conteudo={
                                        v => (<Select.Option value={v.cbf_id} key={v.key}>{v.cbf_codigo} - {v.cbf_descricao} </Select.Option>)
                                    } />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Form.Item label="Observação Fiscal" name="ope_observacao">
                                    <Input.TextArea
                                        placeholder="Informe uma observação fiscal"
                                        autoSize={{ minRows: 3, maxRows: 3 }}
                                        maxLength={300}
                                    />
                                </Form.Item>
                            </Col>
                            <Col className="t-mob-573">
                                <Form.Item name="ope_ativo" valuePropName="checked" initialValue={true}
                                    hidden={(!(formulario.getFieldValue(['ope_id']) > 0))}>
                                    <Checkbox>Operação Ativa?</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col className="t-mob-573">
                                <Form.Item name="ope_notaprodutorrural" valuePropName="checked" initialValue={false}
                                    hidden={hiddenFlagsCompra}>
                                    <Checkbox>Contra Nota de Produtor Rural?</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col className="t-mob-573">
                                <Form.Item name="ope_notaimportacao" valuePropName="checked" initialValue={false}
                                    hidden={hiddenFlagsCompra}>
                                    <Checkbox>Nota de Importação?</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col className="t-mob-573">
                                <Form.Item name="ope_gerarespelho" valuePropName="checked" initialValue={false}>
                                    <Checkbox>Danfe Rascunho antes envio Sefaz?</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Dados Origem Destino" key="2">
                        <TabCfop listaCfop={listaCfop} setListaCfop={setListaCfop} />
                    </Tabs.TabPane>
                </Tabs>
                <DrawerContaGerencial openDrawerContaGerencial={openDrawerContaGerencial} setOpenDrawerContaGerencial={setOpenDrawerContaGerencial} setCodigoContaGerencial={setCodigoContaGerencial} />
            </FormGW>
        </div>
    );

}