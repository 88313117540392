import React, { useState, useEffect } from "react";
import { Row, Col, Button, Tag, Popover, Switch, Modal } from "antd";
import { EditOutlined, DownloadOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../services/api";
import { useStateValue } from '../../state';
import { getLocal } from "../../services/auth";
import { listagemActions } from "../../actions";
import { ModalEditarPDV } from "../../components/modals";
import { BreadcrumbPage, BotaoAbrirChamado, PesquisaOrdenacao, TabelaDados } from "../../components";

export default function GerenciarPdv() {

    const [{ manutencao }, dispatch] = useStateValue();

    const [abreModalSerie, setAbreModalSerie] = useState(false);
    const [dadosPdv, setDadosPdv] = useState({});

    function onCloseModalSerie() {
        setAbreModalSerie(false);
    };

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pdv_nomecomputador' } }), []);

    function abrirModalSerie(record) {
        setDadosPdv(record);
        setAbreModalSerie(true);
    };

    function ativarDesativarPDV(record) {
        api.put(`Pdv/Ativar?IdPdv=${record.pdv_id}&ativo=${record.pdv_ativado}`).then(
            (retorno) => {
                Modal.success({
                    content: `PDV ${record.pdv_ativado === true ? 'desativado' : 'ativado' } com sucesso!`,
                });
            }).catch(
                (erro) => {
                    error(`Não foi possível ${record.pdv_ativado == true ? 'desativar' : 'ativar'} o PDV!`);
                }
            ).finally(() => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pdv_nomecomputador' } });
            });
    };

    function error(mensagem, title = 'Atenção!') {
        Modal.error({
            title: title,
            content: (
                <div>
                    <p>{mensagem}</p>
                </div>
            )
        });
    }

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoAbrirChamado />
                        </Col>
                        <Col>
                            <Button href="https://update.newerp.com.br/PDV/Setup.exe" type="primary" icon={<DownloadOutlined />} size="middle">
                                Download do PDV
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome do A - Z", value: "+pdv_nomecomputador" },
                { label: "Nome do Z - A", value: "-pdv_nomecomputador" },
                { label: "Número do PDV Crescente", value: "+pdv_id" },
                { label: "Número do PDV Decrescente", value: "-pdv_id" }
            ]} />
            <div className="tabela">
                <TabelaDados url="pdv/listar" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ pdv_id, pdv_ativado }) => (
                            <Popover content={pdv_ativado ? 'Ativado' : 'Desativado'} placement="right">
                                <Tag color={pdv_ativado ? 'processing' : 'default'} className="w-100">
                                    <b>{pdv_id}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Nº do Caixa',
                        width: 105,
                        align: 'center',
                        render: ({ pdv_numerocaixa }) => (
                            <div>
                                <b>{(!!pdv_numerocaixa) ? (pdv_numerocaixa) : 'Não Informado'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Computador',
                        render: ({ pdv_nomecomputador }) => (
                            <div>
                                <b>{(!!pdv_nomecomputador) ? (pdv_nomecomputador) : '--'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Nº Registro',
                        render: ({ pdv_registro }) => (
                            <div>
                                <b>{pdv_registro}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Data',
                        width: 145,
                        render: ({ pdv_dataregistro }) => (
                            <div>
                                <b>{moment(pdv_dataregistro).format('DD/MM/YYYY HH:mm:ss')}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'PDV Ativo?',
                        align: 'center',
                        width: 78,
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Switch checked={record.pdv_ativado} onChange={() => ativarDesativarPDV(record)} />
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        align: 'center',
                        fixed: 'right',
                        key: 'x',
                        width: 65,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<EditOutlined />} onClick={() => abrirModalSerie(record)} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} expandir={({ pdv_ipredelocal, ser_id, usu_nome, usu_email }) => {
                    return (
                        <div>
                            <Row align="middle" justify="space-between" gutter={[16, 0]}>
                                <Col>
                                    IP: <b>{pdv_ipredelocal}</b>
                                </Col>
                                <Col>
                                    Série Fiscal: <b>{(!!ser_id) ? (ser_id) : 'Não Informado'}</b>
                                </Col>
                                <Col>
                                    Registrado por: <b>{usu_nome}</b>
                                </Col>

                                <Col>
                                    E-mail: <b>{usu_email}</b>
                                </Col>
                            </Row>
                        </div>
                    )
                }} />
            </div>
            <ModalEditarPDV exibirModalPDV={abreModalSerie} fecharModalPDV={() => { onCloseModalSerie() }} dadosPdv={dadosPdv} setDadosPdv={setDadosPdv} />
        </div>
    );

}