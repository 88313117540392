import React,{ useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import { DrawerNav } from '../../components';
import ManutencaoRestaurante from './manutencao';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';

export default function DrawerRestaurante() {

    const [ formularioRestaurante ] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [ carregando, setCarregando ] = useState(false);
    
    if (manutencao.dados !== null) {
        formularioRestaurante.setFieldsValue(manutencao.dados);
    }

    function fecharDrawer() {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null}});
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false}});
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+res_id' } });
        setCarregando(false);
        formularioRestaurante.resetFields();
    };

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (

        <DrawerNav title="Manutenção de Teste"
            width="70%"
            visible={ui.showDrawer}
            closeIcon={<MinusOutlined />}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => {
                                formularioRestaurante.submit();
                            }} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>                
            <ManutencaoRestaurante formulario={formularioRestaurante} aoSalvar={fecharDrawer} carregando={setCarregando}/>
        </DrawerNav>   

    )
}