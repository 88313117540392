import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Checkbox, Tabs, notification } from "antd";
import moment from "moment";

import api from "../../services/api";
import { FormGW } from "../../components";
import { useStateValue } from "../../state";
import { listagemActions } from "../../actions";
import { TabUsuarioGrupo, TabHorariosAcessoGrupo, TabPermissoesGrupo } from "./tabPage";

export default function ManutencaoGrupoUsuario({ formulario, carregando, aoSalvar }) {

    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [usuarios, setUsuarios] = useState([]);
    const [listPermissoes, setListPermissoes] = useState([]);
    const [load, setLoad] = useState(false);
    const [telaDuplicar, setTelaDuplicar] = useState(false);
    const [listHorarios, setListHorarios] = useState(
        [{
            id: 0,
            name: 'dom',
            disabled: false,
            description: 'Domingo',
            day: []
        }, {
            id: 1,
            name: 'seg',
            disabled: false,
            description: 'Segunda-feira',
            day: []
        }, {
            id: 2,
            name: 'ter',
            disabled: false,
            description: 'Terça-feira',
            day: []
        }, {
            id: 3,
            name: 'qua',
            disabled: false,
            description: 'Quarta-feira',
            day: []
        }, {
            id: 4,
            name: 'qui',
            disabled: false,
            description: 'Quinta-feira',
            day: []
        }, {
            id: 5,
            name: 'sex',
            disabled: false,
            description: 'Sexta-feira',
            day: []
        }, {
            id: 6,
            name: 'sab',
            disabled: false,
            description: 'Sábado',
            day: []
        },]);

    useEffect(() => {
        if (ui.showDrawer) {
            carregarUsuarios();
            loadPermissoes();
            carregarHorariosAcesso();
            if (!!manutencao.dados && manutencao.dados.duplicar) {
                setTelaDuplicar(true);
            }
        }
    }, [ui.showDrawer]);

    useEffect(() => {
        if (telaDuplicar) {
            formulario.setFieldsValue({ gru_ativo: true, gru_nome: null, gru_descricao: null });
        }
    }, [telaDuplicar]);

    function loadPermissoes() {
        setLoad(true);
        api.get(`PermissaoUsuario/ListarPermissoesPaginas`).then(res => {
            if (res.status === 200) {
                const list = res.data;
                let gru_id = formulario.getFieldValue('gru_id');
                if (gru_id) {
                    api.get(`PermissaoGrupo/Listar?codigoGrupoUsuario=${gru_id}`).then((result) => {
                        if (result.status === 200) {
                            let dataAcess = result.data;
                            for (let i in list) {
                                let idx = dataAcess.findIndex(x => x.prg_nome === list[i].pagina);
                                if (idx > -1) {
                                    let obj = JSON.parse(dataAcess[idx].prg_permissoes);
                                    if (!!obj) {
                                        let { api, sistema } = obj[0];
                                        if (api.length > 0 && sistema.length > 0 && sistema[0].acesso) {
                                            list[i].acesso = sistema[0].acesso;
                                            if (api[0].controller)
                                                list[i].permissoes = sistema[0].elementos.map((x) => ({ controller: api[0].controller, ...x }));
                                        }
                                    }
                                }
                            }
                            setListPermissoes(list.map((x, idx) => ({ key: idx, ...x })));
                        }
                    }).catch((err) => {
                        console.log(err.response)
                    }).finally(() => setLoad(false));
                } else {
                    const array = list.map((x, idx) => ({ key: idx, ...x }));
                    handleChangeBloqueioAll(true, array);
                    setLoad(false)
                }

            }
        }, err => {
            console.log(err.response)
            setLoad(false)
        });
    }

    const handleChangeBloqueioAll = (option, list = null) => {
        let array = (list != null) ? list : [...listPermissoes];
        for (let i in array) {
            array[i].acesso = option;
            for (let y in array[i].permissoes) {
                array[i].permissoes[y].acesso = option;
            }
        }
        setListPermissoes(array);
    };

    useEffect(() => {
        formulario.setFieldsValue({ listaPermissoesPaginas: listPermissoes });
    }, [listPermissoes]);

    async function carregarUsuarios() {
        let usuariosEmpresa = await api.get(`Usuario/ListarUsuarioAcessos?Ativo=true&PageSize=500&filtro=`);
        if (usuariosEmpresa.status === 200 && usuariosEmpresa.data.items.length > 0) {
            const list = usuariosEmpresa.data.items;
            let usuariosGrupo = [];
            let gru_id = formulario.getFieldValue('gru_id');
            if (gru_id) {
                let listaUsuarios = await api.get(`Usuario/ListarUsuariosGrupo?IdGrupo=${gru_id}`);
                if (listaUsuarios.status === 200 && listaUsuarios.data.length > 0) {
                    listaUsuarios.data.forEach((value) => {
                        let indice = list.findIndex((element) => {
                            return element.usu_email === value.usu_email;
                        });
                        if (indice >= 0) {
                            list[indice].incluido = true;
                            usuariosGrupo.push(list[indice]);
                        }
                    });
                }
            }
            setUsuarios(list.map((x, idx) => ({ key: idx, ...x })));
            formulario.setFieldsValue({ usuarios: usuariosGrupo });
        }
    };

    function carregarHorariosAcesso() {
        if (formulario.getFieldValue('gru_id')) {
            api.get(`GrupoUsuario/ListarHorariosAcessoGrupo?IdGrupo=${formulario.getFieldValue('gru_id')}`).then((result) => {
                if (result.status === 200) {
                    let array = [...listHorarios];
                    result.data.forEach(element => {
                        array[element.uac_dia].disabled = element.uac_bloqueado;
                        if (!element.uac_bloqueado) {
                            array[element.uac_dia].day.push({
                                bloqueado: element.uac_bloqueado,
                                horaInicial: element.uac_horainicial != null ? moment(element.uac_horainicial) : null,
                                horaFinal: element.uac_horafinal != null ? moment(element.uac_horafinal) : null
                            });
                        }
                    });
                    setListHorarios(array)
                    transformList(array);
                }
            }).catch((err) => {
                console.log(err.response);
            });
        }
    };

    const transformList = (array) => {
        let obj = {};
        for (let i in array) {
            if (array[i].disabled)
                obj[array[i].name] = [{ horaInicial: null, horaFinal: null, bloqueado: true }];
            else
                obj[array[i].name] = array[i].day.length > 0 ? array[i].day.map((x) => (
                    {
                        horaInicial: new Date(x.horaInicial),
                        horaFinal: new Date(x.horaFinal),
                        bloqueado: x.bloqueado
                    })) : [];

        }
        formulario.setFieldsValue({ listaDefinicaoHorario: { ...obj } });
    }

    function salvarManutencao(values) {
        values.usuariogrupousuarios = values.usuarios;
        carregando(true);
        if (!!values.gru_id) {
            editar(values);
        } else {
            incluir(values);
        }
    }
    
    function incluir(values) {
        //tratamento dos campos
        api.post(`GrupoUsuario/Incluir`, values).then(
            res => {
                notification.success({ message: 'Operação concluída', description: `Grupo de Usuários ${values.duplicar ? 'duplicado' : 'salvo'} com sucesso!` });
            }
        ).catch(error => {
            console.log(error);
            notification.error({ message: 'Aviso!', description: 'Problema ao incluir Grupo de Usuários!' });
        }).finally(() => {
            formulario.resetFields();
            aoSalvar();
            carregando(false);
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+gru_nome' } });
        })
    }
    
    function editar(values) {
        api.put(`GrupoUsuario/Editar`, values).then(
            res => {
                notification.success({ message: 'Operação concluída', description: `Grupo de Usuários ${values.duplicar ? 'duplicado' : 'salvo'} com sucesso!` });
            }
        ).catch(error => {
            console.log(error);
            notification.error({ message: 'Aviso!', description: 'Problema ao editar Grupo de Usuários!' });
        }).finally(() => {
            formulario.resetFields();
            aoSalvar();
            carregando(false);
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+gru_nome' } });
        })
    }




    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoGrupoUsuario" onFinish={salvarManutencao}>
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Form.Item name="gru_id" hidden />
                        <Form.Item name="duplicar" hidden />
                        <Form.Item name="usuarios" hidden />
                        <Form.Item name="listaPermissoesPaginas" hidden />
                        <Form.Item name="listaDefinicaoHorario" hidden />
                        <Form.Item label="Nome do Grupo" name="gru_nome" rules={[{ required: true }]}>
                            <Input placeholder="Informe o Nome do Grupo de Usuário" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Form.Item label="Descrição do Grupo" name="gru_descricao">
                            <Input.TextArea placeholder="Informe a Descrição Detalhada do Grupo (Opcional)" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Form.Item name="gru_ativo" valuePropName="checked">
                            <Checkbox>Grupo Ativo?</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" gutter={[8, 16]}>
                    <Col span={24}>
                        <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                            <Tabs.TabPane tab="Definição das Permissões" key="1">
                                <TabPermissoesGrupo listPermissoes={{ listPermissoes, setListPermissoes }} handleChangeBloqueioAll={handleChangeBloqueioAll} load={load} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Usuários do Grupo" key="2">
                                <TabUsuarioGrupo formulario={formulario} usuarios={{ usuarios, setUsuarios }} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Restrição de Horários para Acesso" key="3">
                                <TabHorariosAcessoGrupo listHorarios={{ listHorarios, setListHorarios }} transformList={transformList} />
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </FormGW>
        </div>
    );

}