import React, { Children, useEffect, useState } from "react";
import { Row, Col, Typography, Divider, Card, Modal, Spin } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

export default function ModalProcessoLento(props) {

    return (
        <Spin spinning={props.exibirModal} tip="Carregando...">
            <Modal centered
                title="Aguarde"
                visible={props.exibirModal}
                onCancel={props.fecharModal}
                footer={null}
                closable={false}
                maskClosable={false}
            >
                <div>
                    <Row>
                        O Processo pode demorar um pouco!
                    </Row>
                </div>
            </Modal>
            {props.children}
        </Spin>
    );
}