import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tag, Menu, Modal, Table, Divider } from "antd";
import { DeleteOutlined, PhoneOutlined, PrinterOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import { useStateValue } from '../../state';
import { FormatFone, FormatNumber } from "../../ValueObjects";
import DrawerCondicional from '../../pages/condicional/drawer';
import DrawerDevolucao from '../../pages/condicional/drawerDevolucao';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { ModalProdutoCondicional, ModalContatoCliente } from "../../components/modals/";

export default function PesquisaCondicional({ dados, dataPesquisa }) {

    const [carregando, setCarregando] = useState(false);
    const [{ manutencao }, dispatch] = useStateValue();
    const [registroDevolucao, setRegistroDevolucao] = useState({});
    const [openRegistroDevolucao, setOpenRegistroDevolucao] = useState(false);
    const [situacaoVenda, setSituacaoVenda] = useState([]);
    const [openModalContatoCliente, setOpenModalContatoCliente] = useState(false);
    const [openModalProdutoCondicional, setOpenModalProdutoCondicional] = useState(false);

    function menuTable(registro) {
        return (<Menu className="text-right">
            <Menu.Item key="1">
                <Button type="link" icon={<EditOutlined />} onClick={() => editarCondicional(registro)}> Editar </Button>
            </Menu.Item>
            <Menu.Item key="2">
                <Button type="link" icon={<DeleteOutlined />} onClick={() => { modalExcluirCondicional(registro) }}> Remover </Button>
            </Menu.Item>
            <Menu.Item key="3">
                <Button type="link" icon={<PrinterOutlined />}> Imprimir </Button>
            </Menu.Item>
        </Menu>)
    };



    function descricaoSituacao(codigo) {
        if (codigo >= 0 && !!situacaoVenda && !!situacaoVenda[codigo]) {
            return situacaoVenda[codigo].value;
        }
        return '';
    };

    function deletarCondicional(registro) {
        api.post(`VendaCondicional/Excluir`, JSON.stringify(registro.vnc_id), { headers: { 'Content-Type': 'application/json;charset=utf-8' } }).then(
            (res) => {
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }
        ).catch(
            (erro) => {
                console.log(erro);
                alert('erro ao excluir condicional!');
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+vnc_datalancamento' } });
            });
    };

    async function editarCondicional(registro) {
        registro.vnc_datalancamento = moment(registro.vnc_datalancamento);
        registro.vnc_prazoretorno = moment(registro.vnc_prazoretorno);
        registro.vnc_entregaagendada = moment(registro.vnc_entregaagendada);
        let dadosTemp = await api.get(`VendaCondicional/ListarItens?listarTodos=true&vendaCondicionalId=${registro.vnc_id}`);
        if (dadosTemp.status === 200) {
            registro.itens = dadosTemp.data;
        }
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: registro } });
    }

    const modalExcluirCondicional = (condicional) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir a condicional do cliente ${condicional.cli_nome} contendo ${condicional.vnc_totalitens} itens com valor total de R$ ${!!condicional.vnc_valortotal ? condicional.vnc_valortotal : '0,00'} ?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                deletarCondicional(condicional);
            }
        });
    };

    function retornaCorSituacao(codigo) {
        let cor = "";
        switch (codigo) {
            case 0:
                cor = "processing";
                break;
            case 1:
                cor = "green";
                break;
            case 2:
                cor = "#87d068";
                break;
            case 3:
                cor = "orange";
                break;
            case 4:
                cor = "red";
                break;
            default:
                cor = "processing";
                break;
        }
        return cor;
    };

    useEffect(() => {
        carregarDados();
    }, []);

    function carregarDados() {
        api.get(`Enum/Listar?nome=SituacaoVendaCondicional`).then(
            (res) => {
                if (res.status === 200) {
                    setSituacaoVenda(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function alterarCondicionalEntregue(dados, valor) {
        let situacao = dados.vnc_situacao;
        if (valor === true) {
            situacao = 1;
        } else {
            situacao = 0;
        }
        api.post('VendaCondicional/EditarSituacaoCondicional',
            JSON.stringify({ vnc_id: dados.vnc_id, vnc_situacao: situacao }),
            { headers: { 'Content-Type': 'application/json;charset=utf-8' } }
        ).then(
            (res) => {
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }
        ).catch(
            (erro) => {
                console.log(erro);
                alert('erro ao alterar status da condicional!');
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+vnc_datalancamento' } });
            });
    };

    return (
        <div className="tabela">
            <Table dataSource={dados} columns={[
                {
                    title: '',
                    width: 135,
                    align: 'center',
                    render: ({ vnc_situacao, vnc_numero }) => (
                        <div>
                            <Row align="middle" justify="center">
                                <Col span={24}>
                                    <Tag color={(retornaCorSituacao(vnc_situacao))} className="w-100">
                                        <b>{(descricaoSituacao(vnc_situacao))}</b>
                                    </Tag>
                                </Col>
                                <Col span={24}>
                                    <b>{vnc_numero}</b>
                                </Col>
                            </Row>
                        </div>
                    ),
                },
                {
                    title: 'Descrição',
                    render: ({ cli_nome, vnc_telefonecliente }) => (
                        <div>
                            <Row>
                                <Col>
                                    <b>{cli_nome}</b>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button type="link" icon={<PhoneOutlined />} onClick={() => setOpenModalContatoCliente(true)}>
                                        {FormatFone(vnc_telefonecliente)}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    ),
                },
                {
                    title: 'Data',
                    align: 'center',
                    children: [
                        {
                            title: 'Lançamento',
                            width: 140,
                            render: ({ vnc_datalancamento }) => (
                                <div>
                                    <b>{moment(vnc_datalancamento).format("DD/MM/YYYY HH:mm:ss")}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Retorno',
                            align: 'right',
                            width: 140,
                            render: ({ vnc_prazoretorno }) => (
                                <div>
                                    <b>{moment(vnc_prazoretorno).format("DD/MM/YYYY HH:mm:ss")}</b>
                                </div>
                            ),
                        },
                    ],
                },
                {
                    title: 'Itens',
                    align: 'center',
                    width: 100,
                    render: ({ vnc_totalitens }) => (
                        <div>
                            <Button type="link" onClick={() => setOpenModalProdutoCondicional(true)}>
                                {vnc_totalitens}
                            </Button>
                        </div>
                    ),
                },
                {
                    title: 'Total (R$)',
                    align: 'right',
                    width: 150,
                    render: ({ vnc_valortotal }) => (
                        <div>
                            <b className="f-18">{FormatNumber(vnc_valortotal, true)}</b>
                        </div>
                    ),
                },/*
                {
                    title: 'Ações',
                    dataIndex: '',
                    key: 'x',
                    align: 'center',
                    width: 65,
                    fixed: 'right',
                    render: (record) => (
                        <div>
                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                <Col>
                                    <Button onClick={() => { setRegistroDevolucao(record); setOpenRegistroDevolucao(true); }} icon={<DollarCircleOutlined />} />
                                </Col>
                                <Col>
                                    <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                        <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>
                    ),
                },*/
            ]} locale={{
                emptyText: (
                    <Row>
                        <Col span={24}>
                            <Divider orientation="center">
                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                            </Divider>
                        </Col>
                    </Row>
                )
            }} scroll={{ x: 900 }}/>
            {/*<DrawerDevolucao dadosDevolucao={registroDevolucao} exibirDrawerDevolucao={openRegistroDevolucao} fecharDrawerDevolucao={() => setOpenRegistroDevolucao(false)} dataPesquisa={dataPesquisa} />
            <DrawerCondicional />
            <ModalProdutoCondicional exibirModalProdutoCondicional={openModalProdutoCondicional} fecharModalProdutoCondicional={() => setOpenModalProdutoCondicional(false)} />
            <ModalContatoCliente exibirModalContatoCliente={openModalContatoCliente} fecharModalContatoCliente={() => setOpenModalContatoCliente(false)} />
                */}
        </div>

    );

}