const TiposElementosFormulario = [
    {value: "input", label: "Input Texto"},
    {value: "inputNumber", label: "Input Numérico"},
    {value: "inputPreco", label: "Input Decimal"},
    {value: "inputPercentual", label: "Input Percentual Decimal"},
    {value: "dataPicker", label: "Input de Data"},
    {value: "select", label: "Lista de Seleção Cadastrado"},
    {value: "selectPaginacao", label: "Lista de Seleção Paginado"},
    {value: "selectAll", label: "Lista de Seleção"},
    {value: "checkbox", label: "Caixa de Seleção"},
    {value: "checkboxgroup", label: "Caixa de Multiseleção"}
];
    
export default TiposElementosFormulario;