import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Select, Steps, Alert, Input, notification, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import { Data, FormGW } from '../../components';
import { useStateValue } from '../../state';
import { getEmpresa, getLocal } from '../../services/auth';
import { MaskFormat } from '../../ValueObjects';
import { TabTransporte } from '../tabNotaFiscal/index';
import { TabItemTransferencia, TabResumoTransferencia } from './tabPage';
import { validaForm, isObjetoDiffVazio, novaAbaNavegador, calculaValorProporcional } from '../../services/funcoes';
import { CalcularImpostoItem } from "../../services/calculaImposto";

export default function ManutencaoNotaFiscalTransferencia({ formulario, carregando, aoSalvar, pages, proximaPag, limparDados, abrirDrawer }) {

    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [serieFiscal, setSerieFiscal] = useState([]);
    const [tipoPreco, setTipoPreco] = useState([]);
    const [ambiente, setAmbiente] = useState([]);
    const [filiaisEmpresa, setFiliaisEmpresa] = useState([]);
    const [parametrosEmpresa, setParametrosEmpresa] = useState([]);
    const [dadosContribuinte, setDadosContribuinte] = useState([]);
    const [dadosEndereco, setDadosEndereco] = useState({});
    const [dadosNfeTransferencia, setDadosNfeTransferencia] = useState({});
    const [listaVeiculos, setListaVeiculos] = useState([]);
    const [dadosTransp, setDadosTransp] = useState({});
    const [resumoNota, setResumoNota] = useState({});
    const [dadosItens, setDadosItens] = useState([]);
    const [editando, setEditando] = useState(false);
    const [listaIndicadorPresenca, setListaIndicadorPresenca] = useState([]);
    const listaValidacoesDest = [
        { nome: 'loc_id', obrigatorio: true, label: 'Destinatário' },
        { nome: 'ser_id', obrigatorio: true, label: 'Série Fiscal' },
        { nome: 'ntf_dataemissao', obrigatorio: true, label: 'Data de Emissão' },
        { nome: 'custo', obrigatorio: true, label: 'Preço' }
    ];

    const listaValidacoesTransp = [
        { nome: 'ntf_modalidadefrete', obrigatorio: true, label: 'Modalidade do Frete' }
    ];

    useEffect(() => {
        if (ui.showDrawer) {
            carregarDados();
            if (!(!!formulario.getFieldValue().ntf_dataemissao)) {
                formulario.setFieldsValue({ ntf_dataemissao: moment(new Date()) });
            }
            if (!!manutencao.dados && !!manutencao.dados.ntf_id) {
                formulario.setFieldsValue(manutencao.dados);
                setEditando(true);
            } else {
                setEditando(false);
            }
        }
    }, [ui.showDrawer]);

    useEffect(() => {
        if (abrirDrawer && !!manutencao.dados && !!manutencao.dados.itensNf) {
            let itens = manutencao.dados.itensNf;
            let pdi_valorfrete = 0;
            let percacresc = 0;
            itens.forEach((item) => {
                pdi_valorfrete += parseFloat(!!item.pdi_valorfrete ? item.pdi_valorfrete : 0);
                percacresc += parseFloat(!item.percacresc ? percacresc : 0)
            });
            setDadosItens(itens);
            carregarDados();
            formulario.setFieldsValue(manutencao.dados);
            formulario.setFieldsValue({ valorFrete: pdi_valorfrete });
            formulario.setFieldsValue({ valorAcrescimo: percacresc });
            if (!(!!formulario.getFieldValue().ntf_dataemissao)) {
                formulario.setFieldsValue({ ntf_dataemissao: moment(new Date()) });
            }
        }
    }, [manutencao.dados]);

    useEffect(() => {
        if (!!proximaPag.proximaPag || proximaPag.proximaPag === 0) {
            validarTrocaPg(proximaPag.proximaPag);
        }
    }, [proximaPag.proximaPag]);

    function salvarNota(values) {
        let operacao = 'Incluir';

        if (!!values.ntf_id) {
            operacao = 'Editar';
        }
        let dados = popularDados();
        carregando(true);
        api.post(`NotaFiscalTransferencia/${operacao}`, dados).then(
            (retornoNF) => {
                if (retornoNF.status === 200) {
                    if (operacao === 'Incluir') {
                        if (retornoNF.data.ntf_status === 1 && !!retornoNF.data.ntf_chavenfe && !!retornoNF.data.ser_id) {
                            novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${retornoNF.data.ntf_chavenfe}`);
                        } else if (retornoNF.data.ntf_status === 1 && !!retornoNF.data.ntf_chavenfe) {
                        }
                    }
                    notification.success({ message: 'Sucesso', description: `Nota ${operacao === 'Incluir' ? 'Incluída' : 'Editada'} com sucesso!` });
                }
            }
        ).catch(
            (erro) => {
                if (!!erro.response && !!erro.response.data) {
                    notification.error({ description: erro.response.data, message: `Aviso` });
                } else {
                    notification.error({ description: 'Não foi possível salvar os dados da nota de transferência!', message: `Aviso` });
                }
            }
        ).finally(() => {
            carregando(false);
            setDadosItens([]);
            aoSalvar();
        });
    };

    async function validarTrocaPg(proxPagina) {
        let paginaAtual = pages.tagPages;
        let listaValidacoes = [];
        if (paginaAtual < proxPagina) {
            switch (paginaAtual) {
                case 0:
                    listaValidacoes = listaValidacoesDest;
                    break;
                case 1:
                    if (dadosItens.length === 0) {
                        notification.warning({ message: 'Aviso', description: `É necessário informar um item na nota fiscal!` });
                        validarTransporte(dadosItens);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                    break;
                case 2:
                    listaValidacoes = listaValidacoesTransp;
                    await validarTransporte(dadosItens);
                    break;
            }
            if (validaForm(formulario, listaValidacoes)) {
                pages.setTagPages(proxPagina);
            } else {
                proximaPag.setProximaPag(paginaAtual);
            }
        } else {
            pages.setTagPages(proxPagina);
        }
    };

    useEffect(() => {
        if (limparDados.limparDados) {
            pages.setTagPages(0);
            setDadosItens([]);
            setResumoNota({});
            limparDados.setLimparDados(false);
        }
    }, [limparDados.limparDados]);

    function popularDados() {
        let valores = formulario.getFieldsValue();
        let dados = formulario.getFieldValue();
        valores.dadosDestinatario = preencherDadosDestinatario(dados);
        valores.serieFiscal = serieFiscal.filter((serie) => { return serie.ser_id === dados.ser_id })[0];
        valores.dadosContribuinte = dadosContribuinte;
        valores.listaItemNotaFiscal = dadosItens;
        valores.custo = tipoPreco.filter((custo) => { return custo.key === dados.custo })[0];
        valores.par_69 = parametrosEmpresa.par_69;
        valores.transporte = preencherDadosTransportadores(dados);
        valores.ntf_modalidadefrete = valores.transporte.ntf_modalidadefrete;
        valores.resumo = resumoNota;
        valores.emp_id = getEmpresa();
        valores.ser_id = valores.serieFiscal.ser_id;
        valores.ser_numero = valores.serieFiscal.ser_numero;
        return valores;
    };

    function preencherDadosDestinatario(dados) {
        let dadosDestinatario = {
            loc_id: dados.loc_id
        }
        return dadosDestinatario;
    };

    function preencherDadosTransportadores(dados) {
        let dadosTransporte = {};
        dadosTransporte.trn_id = !!dadosTransp.trn_id ? dadosTransp.trn_id : null;
        dadosTransporte.trn_id = dados.trn_id;
        dadosTransporte.trv_marca = dados.trv_marca;
        dadosTransporte.trv_modelo = dados.trv_modelo;
        dadosTransporte.trv_placa = dados.trv_placa;
        dadosTransporte.trv_rntc = dados.trv_rntc;
        dadosTransporte.trv_uf = dados.trv_uf;
        dadosTransporte.trv_observacao = dados.trv_observacao
        dadosTransporte.nvl_especievolume = dados.nvl_especievolume;
        dadosTransporte.nvl_marcavolume = dados.nvl_marcavolume;
        dadosTransporte.nvl_numeracaovolume = !!dados.nvl_numeracaovolume ? dados.nvl_numeracaovolume.toString() : "";
        dadosTransporte.nvl_pesobruto = dados.nvl_pesobruto;
        dadosTransporte.nvl_pesoliquido = dados.nvl_pesoliquido;
        dadosTransporte.nvl_qtdevolumes = dados.nvl_qtdevolumes
        dadosTransporte.ntf_modalidadefrete = dados.ntf_modalidadefrete;
        return dadosTransporte;
    };

    async function carregarDados() {
        let retornoSerie = await api.get(`SerieFiscal/Listar?CodigoModeloDocFiscal=55&EmitenteDocumento=0`);
        if (retornoSerie.status === 200) {
            setSerieFiscal(retornoSerie.data.items);
            if (retornoSerie.data.items.length === 1) {
                formulario.setFieldsValue({ ser_id: retornoSerie.data.items[0].ser_id });
            }
        }
        let listaIndicadorPresenca = await api.get('Enum/Listar?nome=IndicadorPresenca');
        if (listaIndicadorPresenca.status === 200) {
            setListaIndicadorPresenca(listaIndicadorPresenca.data);
        }
        let retornoTipoPreco = await api.get(`Enum/Listar?nome=TipoPrecoUtilizar`);
        if (retornoTipoPreco.status === 200) {
            retornoTipoPreco.data = retornoTipoPreco.data.filter((item) => { return item.key > 0 });
            setTipoPreco(retornoTipoPreco.data);
        }
        let retornoAmbiente = await api.get(`Nfe/VerificarAmbiente?codigoLocal=${getLocal()}`);
        if (retornoAmbiente.status === 200) {
            setAmbiente(retornoAmbiente.data);
        }
        let retornoFiliais = await api.get(`Local/ListarLocalUsuario?codigoEmpresa=${getEmpresa()}`);
        if (retornoFiliais.status === 200) {
            setFiliaisEmpresa(retornoFiliais.data.items.filter((item) => { return item.loc_id !== parseInt(getLocal()) }));
        }
        let retornoParametrosEmp = await api.get('ParametroEmpresa/BuscarParametros');
        if (retornoParametrosEmp.status === 200) {
            let dados = {};
            dados.par_69 = retornoParametrosEmp.data.par_69;
            if (!!retornoParametrosEmp.data.par_custopadraonftransferencia || !!formulario.getFieldValue().custo) {
                let custo = retornoTipoPreco.data.filter((custo) => { return custo.key === !!formulario.getFieldValue().custo ? formulario.getFieldValue().custo : retornoParametrosEmp.data.par_custopadraonftransferencia });
                if (custo.length > 0) {
                    dados.custo = custo[0];
                    if (!(!!formulario.getFieldValue().custo)) {
                        formulario.setFieldsValue({ custo: custo[0].key });
                    }
                }
            }
            setParametrosEmpresa(retornoParametrosEmp.data);
            setDadosNfeTransferencia(dados);
        }
        let retornoDadosContribuinte = await api.get(`dadosContribuinte/Buscar`);
        setDadosContribuinte(retornoDadosContribuinte.data);
        if (!!formulario.getFieldValue().ntf_id && dadosItens.length === 0 && (!!manutencao.dados && manutencao.dados.listaItemNotaFiscal.length > 0)) {
            let itens = manutencao.dados.listaItemNotaFiscal;
            itens.forEach((item) => {
                if (!!item.icm_id) {
                    item.icm_id = { icm_id: item.icm_id, icm_descricao: item.icm_descricao }
                }
            });
            setDadosItens(itens);
        };
    };

    useEffect(() => {
        if (filiaisEmpresa.length > 0 && !!formulario.getFieldValue().loc_id) {
            buscarEnderecoLocal(formulario.getFieldValue().loc_id);
        }
    }, [filiaisEmpresa]);

    function buscarEnderecoLocal(loc_id) {
        if (!!loc_id) {
            let lcd_id = filiaisEmpresa.filter((filial) => { return filial.loc_id === loc_id })[0].lcd_id;
            api.get(`LogradouroCidade/BuscarLogradouroCidade?codigoLogradouroCidade=${lcd_id}&estrangeiro=0`).then(res => {
                if (res.status === 200) {
                    setDadosEndereco(res.data);
                }
            }).catch((erro) => console.error(erro));
        }
    };

    function alterarCusto(e) {
        if (!!e) {
            let custo = tipoPreco.find((custo) => { return custo.key === e });
            if (!!custo) {
                let dados = { ...dadosNfeTransferencia };
                dados.custo = custo;
                setDadosNfeTransferencia(dados);
            }
        }
    };

    async function validarTransporte(dadosItens) {
        await rateioFrete(dadosItens);
        await recalcularImpostos(dadosItens);
    }

    async function recalcularImpostos(dadosItens) {
        if (dadosItens.length > 0) {
            let total = 0;
            let totalSt = 0;
            let possuiIe = !!formulario.getFieldValue().pej_inscricaoestadual ? true : false;
            let empresaSelecionada = filiaisEmpresa.filter(item => item.loc_id === formulario.getFieldValue().loc_id);
            let fisicaJuridica = empresaSelecionada[0].loc_tipocontribuinte;
            for (const p of dadosItens) {
                try {
                    let aliquotaIcms = p.nig_percicms !== null && p.nig_percicms !== '' && Number(p.nig_percicms) !== 0 ? p.nig_percicms : (p.mva_percaliquotaicms !== null && p.mva_percaliquotaicms !== '' ? p.mva_percaliquotaicms : 0);
                    let percIpi = p.ncm_percentual !== null && p.ncm_percentual !== '' && Number(p.ncm_percentual) !== 0 ? p.ncm_percentual : (!p.npi_percaliquotaipi ? p.npi_percaliquotaipi : 0);
                    let aliquotaICMSST = !p.mva_percaliquotaicms ? p.mva_percaliquotaicms : p.nig_percicmsst;
                    let valorOutros = parseFloat(formulario.getFieldValue().valorSeguro) + parseFloat(p.nfi_valoroutros);
                    let valorFrete = parseFloat(formulario.getFieldValue().valorFrete);

                    //calcula impostos da nota no beck
                    let impostos = await CalcularImpostoItem(p.orm_id, p.cst_id, p.cso_id,
                        p.iod_uforigem, p.iod_ufdestino, p.nfi_qtde, p.nfi_valorunitario, valorFrete, valorOutros,
                        p.nfi_valordesconto, aliquotaIcms, p.iod_percdiferimentoicms, p.iod_percicmsreducaobc, aliquotaICMSST, p.mva_percmva, p.mva_percaliquotaicmsreducao, p.mva_ajustado,
                        percIpi,
                        fisicaJuridica, formulario.getFieldValue().ntf_consumidorfinal, possuiIe, formulario.getFieldValue().ntf_indicadorpresenca);
                    if (isObjetoDiffVazio(impostos)) {
                        p.baseICMS = impostos.retornoICMS.ret_baseIcms;
                        p.valorICMS = impostos.retornoICMS.ret_valorIcms;
                        p.BaseST = impostos.retornoICMSST.ret_baseIcmsST;
                        p.ValorST = impostos.retornoICMSST.ret_valorIcmsSt;
                        p.BaseIPI = impostos.retornoIPI.ret_baseIPI;
                        p.ValorIPI = impostos.retorno.ret_valorIpi;
                        p.nfi_valoricmsst = impostos.retornoICMSST.ret_valorIcmsSt;
                        p.nfi_valoripi = impostos.retornoIPI.ret_valorIpi
                        p.nig_valorIcms = impostos.retornoICMS.ret_valorIcms;
                        p.total = (p.nfi_qtde * p.nfi_valorunitario) - p.nfi_valordesconto + p.nfi_valoroutros + p.nfi_valoripi + p.nfi_valoricmsst;
                        p.impostos = impostos;

                        setDadosItens(p);
                    }
                    totalSt += parseFloat(p.nfi_valoricmsst);
                    total += parseFloat(p.total);
                } catch (error) {
                    console.error("Error item:", error);
                }
            }
            formulario.setFieldsValue({ valorTotalSt: totalSt });
        }
    }

    async function rateioFrete(dadosItens) {
        let _numeroItem = 1;
        let _totalFrete = 0;
        if (dadosItens.length > 0) {
            let _valorFrete = parseFloat(resumoNota.valorFrete);
            if (_valorFrete > 0) {
                // Ordena os itens em ordem de maior valor crescente
                var itens = dadosItens.sort((a, b) => a.total - b.total);
                // Calcular a soma da multiplicação dos campos
                let somaTotal = dadosItens.reduce((total, item) => {
                    let resultadoMultiplicacao = item.nfi_valorunitario * item.nfi_qtde;
                    return total + resultadoMultiplicacao;
                }, 0);
                itens.forEach(item => {
                    let valorTotalItem = parseFloat(item.nfi_valorunitario * item.nfi_qtde);
                    item.nfi_valorfrete = calculaValorProporcional(valorTotalItem, _valorFrete, somaTotal);
                    if (_numeroItem == dadosItens.length) {
                        item.nfi_valorfrete = parseFloat((_valorFrete - _totalFrete).toFixed(2))
                    }
                    _totalFrete += item.nfi_valorfrete;
                    _numeroItem++;
                });
            }
        }
        rateioAcrescimo(dadosItens);
    }

    async function rateioAcrescimo(dadosItens) {
        let _numeroItem = 1;
        let _totalAcrescimo = 0;
        if (dadosItens.length > 0) {
            let _valorAcrescimo = (parseFloat(resumoNota.valorOutros) + parseFloat(resumoNota.valorSeguro));
            if (_valorAcrescimo > 0) {
                // Ordena os itens em ordem de maior valor crescente
                var itens = dadosItens.sort((a, b) => a.total - b.total);
                // Calcular a soma da multiplicação dos campos
                let somaTotal = dadosItens.reduce((total, item) => {
                    let resultadoMultiplicacao = item.nfi_valorunitario * item.nfi_qtde;
                    return total + resultadoMultiplicacao;
                }, 0);
                itens.forEach(item => {
                    item.nfi_valorseguro = 0;
                    let valorTotalItem = parseFloat(item.nfi_valorunitario * item.nfi_qtde);
                    let valoracresc = calculaValorProporcional(valorTotalItem, _valorAcrescimo, somaTotal);
                    //transforma o valor acrescimo em porcentagem
                    item.percacresc = (valoracresc * 100) / valorTotalItem;
                    item.nfi_valorseguro = valoracresc;
                    _totalAcrescimo += item.percacresc;
                    _numeroItem++;
                });
            }
        }
    }

    return (
        <div className="pages-col">
            {ambiente === 2 &&
                <Row align="middle" justify="center" gutter={[8, 8]} className="m-b-16">
                    <Col>
                        <Alert message="ATENÇÃO: Sistema de Emissão de Nota Fiscal em Ambiente de Homologação" type="error" showIcon />
                    </Col>
                    <Col>
                        <Tooltip title="Notas Fiscais emitidas em ambiente de homologação não tem validade fiscal ou jurídica">
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </Col>
                </Row>
            }
            <FormGW form={formulario} layout="vertical" name="formNotaFiscal" onFinish={salvarNota}>
                <Row gutter={[8, 0]}>
                    <Form.Item name="ped_id" hidden><Input /></Form.Item>
                    <Form.Item name="ntf_id" hidden><Input /></Form.Item>
                    <Form.Item name="ntf_numero" hidden><Input />W</Form.Item>
                    {/* <Col xs={24} sm={10} md={10} lg={10} xl={10}> */}
                    <Col xs={24} sm={8} md={3} lg={4} xl={4}>
                        <Form.Item label="Série" name="ser_id">
                            <Select allowClear placeholder="Selecione uma Série" showSearch optionFilterProp="children">
                                {serieFiscal.map((item) => (
                                    <Select.Option value={item.ser_id} key={item.ser_id}>{item.ser_serie}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={8} md={5} lg={4} xl={4}> */}
                    <Col xs={24} sm={editando ? 8 : 6} md={4} lg={4} xl={4}>
                        <Data label="Data de Emissão" name="ntf_dataemissao" />
                    </Col>
                    <Col xs={24} sm={8} md={7} lg={8} xl={8}>
                        {/* <Col xs={24} sm={editando ? 12 : 10} md={editando ? 6 : 8} lg={editando ? 6 : 8} xl={editando ? 6 : 8}> */}
                        <Form.Item label="Selecione o Preço" name="custo" rules={[{ required: true, message: 'Informe o Preço a Utilizar' }]}>
                            <Select allowClear placeholder="Selecione o Preço a Utilizar" showSearch optionFilterProp="children" onChange={(e) => { alterarCusto(e) }}>
                                {tipoPreco.map((item) => (
                                    <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={12} md={editando ? 8 : 9} lg={editando ? 7 : 8} xl={editando ? 7 : 8}> */}
                    <Col xs={24} sm={8} md={7} lg={8} xl={8}>
                        <Form.Item label="Indicador de Presença do Comprador" name="ntf_indicadorpresenca" rules={[{ required: true, message: 'Informe o Indicador de Presença' }]}>
                            <Select showSearch optionFilterProp="children" placeholder="Selecione Indicador de Presença do Comprador">
                                {listaIndicadorPresenca?.map(item => (
                                    <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[0, 24]}>
                    <Col span={24}>
                        <Steps size="small" type="navigation" onChange={(pagina) => {
                            if (pagina >= pages.tagPages) {
                                return;
                            }
                            pages.setTagPages(pagina);
                        }} current={pages.tagPages}>
                            <Steps.Step title="Destinatário" />
                            <Steps.Step title="Itens" />
                            <Steps.Step title="Transporte" />
                            <Steps.Step title="Resumo" />
                        </Steps>
                    </Col>
                </Row>
                <Row gutter={[8, 24]} className="m-t-16">
                    <Col span={24}>
                        {pages.tagPages === 0 &&
                            <div>
                                <Row align="middle" gutter={[0, 16]}>
                                    <Col span={24}>
                                        <Form.Item label="Destinatário" name="loc_id" rules={[{ required: true, message: 'Informe o Destinatário' }]}>
                                            <Select allowClear placeholder="Selecione o Destinatário" onChange={(loc_id) => { buscarEnderecoLocal(loc_id) }} showSearch optionFilterProp="children">
                                                {filiaisEmpresa.map((filial) => (
                                                    <Select.Option value={filial.loc_id} key={filial.loc_id}>
                                                        {filial.loc_descricao}
                                                        {(!!filial.loc_cnpj ? <div>{MaskFormat(filial.loc_cnpj.numero, '', true)}</div> : '')}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        }
                        {pages.tagPages === 1 &&
                            <TabItemTransferencia
                                form={formulario}
                                dadosItens={{ dadosItens, setDadosItens }}
                                dadosEndereco={dadosEndereco}
                                dadosNfeTransferencia={{ dadosNfeTransferencia, setDadosNfeTransferencia }}
                                dadosContribuinte={dadosContribuinte}
                                validarTransporte={validarTransporte}
                            />
                        }
                        {pages.tagPages === 2 &&
                            <TabTransporte form={formulario} veiculos={{ listaVeiculos, setListaVeiculos }} dadosTransp={{ dadosTransp, setDadosTransp }} />
                        }
                        {pages.tagPages === 3 &&
                            <TabResumoTransferencia form={formulario} dadosItens={{ dadosItens, setDadosItens }} resumoNota={{ resumoNota, setResumoNota }} />
                        }
                    </Col>
                </Row>
            </FormGW>
        </div>
    );

}