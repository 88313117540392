import React from 'react';
import { Col, Row, Spin, Table, Divider } from 'antd';
import { CartesianGrid, PieChart, Pie, Cell, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

const colors = [
    '#0088FE', '#00C49F', '#FF8042', '#eb2f96', '#003c80', '#faad14', '#fa1414', '#9c00fe', '#14d0d9', '#5c5c5c'
];
const numberFormatter = (item, casasDecimais = 2) => item.toLocaleString({ style: 'currency', currency: 'BRL' }, { minimumFractionDigits: casasDecimais, maximumFractionDigits: casasDecimais });
const dateFormatter = (item) => moment(item).format("DD/MM/YYYY");
const dateFormatterMes = (item) => moment(item).format("DD/MM");
const dateFormatterAnoMes = (item) => moment(item).format("MM/YYYY");

const renderCustomizedLabelPie = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    let RADIAN = Math.PI / 180;
    let radius = innerRadius + (outerRadius - innerRadius) * 0.4;
    let x = cx + radius * Math.cos(-midAngle * RADIAN);
    let y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text
            x={x}
            y={y}
            fill="white"
            textAnchor={x > cx ? "start" : "end"}
            dominantBaseline="central"
        >
            {(percent * 100).toFixed(0) >= 6 ? `${(percent * 100).toFixed(0)}%` : ''}
        </text>
    );
};

export const RenderChartPie = ({ resultSet, dataKey, nameKey }) => {

    if (!resultSet) {
        return <Spin />;
    }

    return (
        <ResponsiveContainer width="100%" height={350}>
            <PieChart margin={{ top: 20, bottom: 20 }}>
                <Pie
                    isAnimationActive={false}
                    data={resultSet}
                    dataKey={dataKey}
                    innerRadius={50}
                    outerRadius={100}
                    labelLine={false}
                    label={renderCustomizedLabelPie}
                    legendType="circle"
                    nameKey={nameKey}
                >
                    {resultSet.map((e, index) => (
                        <Cell key={index} fill={colors[index]} />
                    ))}
                </Pie>
                <Legend verticalAlign="top" />
                <Tooltip formatter={(t) => numberFormatter(parseFloat(t))} />
            </PieChart>
        </ResponsiveContainer>
    );
};

export const TableRenderer = ({ dataSource, columns, carregando }) => {
    return (
        <ResponsiveContainer width="100%" height={350} className="row-charts">
            <Table columns={columns} dataSource={dataSource} loading={carregando} locale={{
                emptyText: (
                    <Row>
                        <Col span={24}>
                            <Divider orientation="center">
                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                            </Divider>
                        </Col>
                    </Row>)
            }} />
        </ResponsiveContainer>
    );
};

export const CartesianChart = ({ resultSet, children, ChartComponent, dataKey }) => (
    <ResponsiveContainer width="100%" height={350}>
        <ChartComponent data={resultSet} margin={{ top: 20, bottom: 20, left: 20, right: 10 }}>
            <XAxis dataKey={dataKey} tickFormatter={dateFormatterMes} />
            <YAxis tickFormatter={(v) => numberFormatter(v)} />
            <CartesianGrid />
            {/*<Legend />*/}
            {children}
            <Tooltip formatter={(t) => numberFormatter(parseFloat(t))} labelFormatter={dateFormatter} />
        </ChartComponent>
    </ResponsiveContainer>
);

export const CartesianChartBar = ({ resultSet, children, ChartComponent, dataKey, legend, tickFormatter }) => (
    <ResponsiveContainer width="100%" height={350}>
        <ChartComponent data={resultSet} margin={{ top: 20, bottom: 40, left: 20, right: 10 }}>
            <CartesianGrid />
            {!!dataKey ? <XAxis dataKey={dataKey} tickFormatter={tickFormatter} /> : <XAxis dataKey="x" width={0} height={0} />}
            <YAxis tickFormatter={(v) => numberFormatter(v)} />
            {children}
            <Tooltip cursor={false} formatter={(t) => numberFormatter(parseFloat(t))} labelFormatter={dateFormatterAnoMes}/>
            {legend && <Legend />}
        </ChartComponent>
    </ResponsiveContainer>
);

export const ComponentSpin = ({ children, carregando }) => {
    if (carregando) {
        return <Spin>
            {children}
        </Spin>;
    } else {
        return <div>
            {children}
        </div>;
    }
};