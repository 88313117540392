import React, { useEffect, useState } from "react";
import { Tabs, Row, Col, Form, Input, Radio, Switch, Tooltip, Checkbox, Select, InputNumber, notification, Modal, Typography, Divider } from "antd";
import { ExclamationCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import moment from "moment";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { manutencaoActions, selectPaginadoActions } from "../../actions";
import { validarIE } from "../../services/funcoesIE";
import { Data, FormGW, FormularioDinamico, InputPreco, SelectPaginacao } from "../../components";
import { isObjetoDiffVazio, validarCnpj, validarCpf, consultaCNPJ, retornaSoNumeros, HasValue, validarTelefone, validarEmail } from "../../services/funcoes";
import { Endereco, Email, Telefone, OutrosContatos, Veiculos, Nacionalidade } from '../pessoas/pages';
import { trataRetornoFormularioDinamico, trataSetaDadosFormularioDinamico } from "../../services/funcoes";

export default function ManutencaoTransportador({ formulario, carregando, aoSalvar, editando }) {
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [dadosEstrangeiro, setDadosEstrangeiro] = useState(false);
    const [fretePorConta, setFretePorConta] = useState([]);
    const [tipoTransporte, setTipoTransporte] = useState([]);
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [pessoaJuridica, setPessoaJuridica] = useState(false);
    const [baixadoRec, setBaixadoRec] = useState(false);
    const [desabilitarCfpCnpj, setDesabilitarCpfCnpj] = useState(false);
    const [elementosFormulario, setElementosFormulario] = useState([]);
    const [filtroCdd, setFiltroCdd] = useState(null);
    const [listaCidades, setListaCidades] = useState(false);


    useEffect(() => {
        if (ui.showDrawer && !!manutencao.dados && !!manutencao.dados.trn_id && editando.editando) {
            let lista = [];
            if (!!manutencao.dados.pef_rguf) {
                lista.push({ name: "pef_rguf", campo: "Uf", value: manutencao.dados.pef_rguf });
                carregarCidades(manutencao.dados.pef_rguf);
            }
            if (!!manutencao.dados.pef_codigocidadenascimento) {
                lista.push({ name: "pef_codigocidadenascimento", campo: "IdCidade", value: manutencao.dados.pef_codigocidadenascimento });
            }
            if (lista.length > 0) {
                dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                editando.setEditando(false);
            }

            formulario.setFieldsValue(manutencao.dados);

        }
    }, [ui.showDrawer, manutencao.dados]);


    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.trn_id) {
            listaTransportador(manutencao.dados.trn_id);
        }
        //formulario.setFieldsValue(manutencao.dados);
    }, []);

    useEffect(() => { if (!!elementosFormulario && elementosFormulario.length > 0) carregaDadosCamposAdicionais(); }, [elementosFormulario]);
    useEffect(() => {
        if (manutencao?.dados?.transportadorcampopersonalizados?.length > 0)
            carregaDadosCamposAdicionais();
    }, [manutencao.dados]);

    useEffect(() => {
        listarFretePorConta();
        listarTipoTransporte();
        carregaCamposPersonalizados();
    }, []);

    const switchEstrangeiro = () => {
        setDadosEstrangeiro(!dadosEstrangeiro)
    };

    useEffect(() => {
        if (!!manutencao.dados) {
            let _cpfCnpj = formulario.getFieldValue().cpfCnpj ? formulario.getFieldValue().cpfCnpj : manutencao.dados.cpfCnpj;
            _cpfCnpj = _cpfCnpj.replace(/[^\d]+/g, '');
            if (HasValue(_cpfCnpj)) {
                if (_cpfCnpj.length === 11) {
                    if (validarCpf(_cpfCnpj)) {
                        setDesabilitarCpfCnpj(true);
                    }
                }
                else if (_cpfCnpj.length === 14) {
                    if (validarCnpj(_cpfCnpj)) {
                        setDesabilitarCpfCnpj(true);
                    }
                } else {
                    setDesabilitarCpfCnpj(false);
                }
            }
            if (!!manutencao.dados.cpfCnpj) {
                if (manutencao.dados.cpfCnpj.length > 11) {
                    setPessoaJuridica(true);
                } else {
                    setPessoaJuridica(false);
                }
            }
        } else {
            setFocusCpfCnpj();
        }
    }, [manutencao.dados]);

    function setFocusCpfCnpj() {
        setTimeout(
            () => {
                try {
                    let element = document.getElementById("cpf");
                    if (element) {
                        element.focus();
                    }
                } catch (error) {
                    console.log('error :>> ', error);
                }
            }, 300);
    }

    const salvarManutencao = valores => {
        valores.pes_fisicajuridica = pessoaJuridica ? 2 : (dadosEstrangeiro ? 3 : 1);
        valores.listaPessoasAutorizadas = manutencao.dados.pessoaAutorizada;
        valores.enderecos = !!manutencao.dados.enderecos && manutencao.dados.enderecos.length > 0 ? manutencao.dados.enderecos : [];
        valores.pessoaemails = !!manutencao.dados.emails && manutencao.dados.emails.length > 0 ? manutencao.dados.emails : [];
        valores.pessoatelefones = !!manutencao.dados.telefones && manutencao.dados.telefones.length > 0 ? manutencao.dados.telefones : [];
        valores.pessoaoutrocontatos = !!manutencao.dados.pessoaoutrocontatos && manutencao.dados.pessoaoutrocontatos.length > 0 ? manutencao.dados.pessoaoutrocontatos : [];
        valores.pessoanacionalidades = !!manutencao.dados.nacionalidades && manutencao.dados.nacionalidades.length > 0 ? manutencao.dados.nacionalidades : [];
        valores.transportadorveiculos = !!manutencao.dados.veiculos && manutencao.dados.veiculos.length > 0 ? manutencao.dados.veiculos : [];

        valores.pef_produtorrural = false;
        valores.pej_databaixasefaz = !!valores.pej_databaixasefaz ? valores.pej_databaixasefaz : null;
        valores.pej_baixadoreceita = !!valores.pej_baixadoreceita ? valores.pej_baixadoreceita : false;
        valores.trn_valorfretefixo = !!valores.trn_valorfretefixo ? parseFloat(valores.trn_valorfretefixo) : parseFloat(0);
        valores.trn_percentualfrete = !!valores.trn_percentualfrete ? parseFloat(valores.trn_percentualfrete) : parseFloat(0);
        valores.transportadorcampopersonalizados = salvarCamposAdicionais(valores);

        valores.cpfCnpj = valores.cpfCnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
        if (!!valores.idEstrangeiro && dadosEstrangeiro) {
            valores.isEstrangeiro = true;
            valores.pes_estrangeiro = true;
        } else {
            valores.isEstrangeiro = false;
            valores.pes_estrangeiro = false;

            if (valores.cpfCnpj.length === 11) {
                valores.pes_fisicajuridica = 1;
                valores.pef_cpf = valores.cpfCnpj;
                if (!validarCpf(valores.cpfCnpj)) {
                    notification.warning({ message: 'Aviso', description: 'CPF informado é inválido' });
                    return false;
                }
            } else if (valores.cpfCnpj.length === 14) {
                valores.pes_fisicajuridica = 2;
                valores.pej_cnpj = valores.cpfCnpj;
                if (!validarCnpj(valores.cpfCnpj)) {
                    notification.warning({ message: 'Aviso', description: 'CNPJ informado é inválido' });
                    return false;
                }
            } else {
                notification.warning({ message: 'Aviso', description: 'CPF/CNPJ informado é inválido' });
                return false;
            }
        }

        if (!!valores.pej_inscricaoestadual && !validarIE(valores.pej_inscricaoestadual, valores.pej_inscuf)) {
            notification.warning({ message: 'Aviso', description: 'Inscrição Estadual informada é inválida' });
            return false;
        } else if (!!!valores.pej_inscricaoestadual) {
            valores.pej_inscricaoestadual = "";
        }

        carregando(true);
        if (!!valores.trn_id) {
            api.put(`Transportador/Editar`, valores).then(
                res => {
                    notification.success({ message: 'Aviso', description: `Transportador editado com sucesso!` });
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            ).finally(
                () => {
                    carregando(false);
                    aoSalvar();
                }
            );
        } else {
            api.post(`Transportador/Incluir`, valores).then(
                res => {
                    notification.success({ message: 'Aviso', description: `Transportador incluído com sucesso!` });
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            ).finally(
                () => {
                    carregando(false);
                    aoSalvar();
                }
            );
        }

    };
    function carregarCidades(UfEstado) {
        if (!!UfEstado) {
            setListaCidades(true);
            setFiltroCdd(` &UfEstado=${UfEstado}`);
        }
        else {
            setListaCidades(false);
        }
    }

    const salvarCamposAdicionais = (values) => {
        var camposAdicionais = [];
        elementosFormulario?.map((elemento) => {
            values = trataRetornoFormularioDinamico(values, elemento);
            let transportadorcampopersonalizados = { ...manutencao.dados };
            let forDados = transportadorcampopersonalizados.transportadorcampopersonalizados?.filter(f => f.cap_id == elemento.cap_id)[0];
            if ((!!forDados ? forDados.fcp_valor : "") !== (!!values[elemento.cap_nomeelemento] ? values[elemento.cap_nomeelemento] : "")) {
                camposAdicionais.push({ tcp_id: !!forDados ? forDados.tcp_id : null, trn_id: values.trn_id, cap_id: elemento.cap_id, tcp_valor: values[elemento.cap_nomeelemento] });
            }
        });
        return camposAdicionais;
    }
    function carregaCamposPersonalizados() {
        api.get(`CamposPersonalizados/ListarTodos?entidade=5&PageNumber=1&PageSize=1000&Order=+cap_ordem`).then(res => {
            if (res.status === 200) {
                setElementosFormulario(res.data.items);
            }
        }).catch(
            (erro) => {
                console.log(erro);
                alert('Erro ao carregar Campos personalizados!');
            }
        );
    };

    function carregaDadosCamposAdicionais() { //Campos Adicionais
        let camposPersonalizados = { ...manutencao.dados };
        camposPersonalizados?.transportadorcampopersonalizados?.map(d => {
            let elemento = elementosFormulario.filter(f => f.cap_id === d.cap_id)[0];
            if (!!elemento)
                trataSetaDadosFormularioDinamico(formulario, elemento, d.tcp_valor);
        })
    }



    function listarFretePorConta() {
        api.get(`Enum/Listar?nome=FretePorConta`).then(
            res => {
                if (res.status === 200) {
                    setFretePorConta(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function listarTipoTransporte() {
        api.get(`Enum/Listar?nome=TipoTransporteInternacional`).then(
            res => {
                if (res.status === 200) {
                    setTipoTransporte(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function validarFisicoJuridico(event) {
        let valorCampo = event.currentTarget.value.replace(/\D/g, '');
        if (valorCampo.length === 11) {
            if (!validarCpf(valorCampo)) {
                notification.warning({ message: 'Aviso', description: 'CPF informado é inválido' });
                formulario.setFieldsValue({ cpfCnpj: "" })
                setFocusCpfCnpj();
                return false;
            }
        } else if (valorCampo.length === 14) {
            if (!validarCnpj(valorCampo)) {
                notification.warning({ message: 'Aviso', description: 'CNPJ informado é inválido' });
                formulario.setFieldsValue({ cpfCnpj: "" })
                setFocusCpfCnpj();
                return false;
            }
        }
        setPessoaJuridica(valorCampo.length === 14);
    };

    function validarCpfCnpjExistente(cpfCnpj) {
        if (cpfCnpj) {
            if (cpfCnpj.length === 14) {
                validarCnpjExiste(cpfCnpj);
            } else if (cpfCnpj.length === 11) {
                validarCpfExiste(cpfCnpj);
            } else {
                notification.warning({ message: 'Aviso', description: 'O CFP/CNPJ informado é inválido. Deve conter 11 ou 14 dígitos' });
            }
        } else {
            notification.warning({ message: 'Aviso', description: 'O CFP/CNPJ não foi informado' });
        }
    };

    function validarCpfExiste(cpfCnpj) {
        api.get(`PessoaFisica/VerificarCpfExistente?cpf=${cpfCnpj}`).then(
            res => {
                if (res.status === 200) {
                    if (res.data.pes_transportador) {
                        Modal.confirm({
                            title: 'Aviso',
                            icon: <ExclamationCircleOutlined />,
                            content: "O CPF informado já esta cadastrado no sistema!\n Deseja atualizar seus dados?",
                            okText: 'Sim',
                            cancelText: 'Não',
                            centered: true,
                            onOk() {
                                listarTransportadorCpfCnpj(cpfCnpj);
                            },
                            onCancel() {
                                formulario.resetFields();
                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                            }
                        });
                    } else {
                        listarTransportadorCpfCnpj(cpfCnpj);
                    }
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function validarCnpjExiste(cpfCnpj) {
        api.get(`PessoaJuridica/VerificarCnpjExistente?cnpj=${cpfCnpj}`).then(
            res => {
                if (res.status === 200) {
                    if (res.data.pes_transportador) {
                        Modal.confirm({
                            title: 'Aviso',
                            icon: <ExclamationCircleOutlined />,
                            content: "O CNPJ informado já esta cadastrado no sistema!\n Deseja atualizar seus dados?",
                            okText: 'Sim',
                            cancelText: 'Não',
                            centered: true,
                            onOk() {
                                listarTransportadorCpfCnpj(cpfCnpj);
                            },
                            onCancel() {
                                formulario.resetFields();
                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                            }
                        });
                    } else {
                        listarTransportadorCpfCnpj(cpfCnpj);
                    }
                } else {
                    receitaWS(cpfCnpj);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function listaTransportador(trn_id) {
        if (!!!trn_id) {
            return;
        }
        api.get(`Transportador/BuscarTransportador/${trn_id}`).then(
            res => {
                if (res.status === 200) {
                    if (res.data.pes_fisicajuridica === 2) {
                        setPessoaJuridica(true);
                    } else {
                        setPessoaJuridica(false);
                    }
                    if (!!res.data && isObjetoDiffVazio(res.data)) {
                        let valores = res.data;
                        valores.pej_datafundacao = !!valores.pej_datafundacao ? moment(valores.pej_datafundacao) : null;
                        valores.pes_datainclusao = !!valores.pes_datainclusao ? moment(valores.pes_datainclusao) : null;
                        valores.pef_dataalteracao = !!valores.pef_dataalteracao ? moment(valores.pef_dataalteracao) : null;
                        valores.pef_datanascimento = !!valores.pef_datanascimento ? moment(valores.pef_datanascimento) : null;
                        formulario.setFieldsValue(valores);
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: valores } });
                    }
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    const receitaWS = (cpfCnpj) => {
        if (!HasValue(formulario.getFieldsValue().pes_nome)) {
            consultaCNPJ(cpfCnpj).then((retorno) => {
                let dados = retorno;
                if (dados.status === "OK" && dados.situacao === "ATIVA") {
                    let dadosForm = {};

                    if (dados.efr !== "") {
                        dadosForm.pes_orgaopublico = true;
                    }
                    dadosForm.pes_nome = dados.nome;
                    if (!!dados.abertura) {
                        let dia = dados.abertura.substring(0, 2);
                        let mes = dados.abertura.substring(3, 5);
                        let ano = dados.abertura.substring(6, 10);
                        dadosForm.pej_datafundacao = moment(new Date(ano, mes, dia));
                    }
                    dadosForm.pej_nomefantasia = dados.fantasia;


                    let listaEnderecos = [];
                    let tipoEndereco = {};
                    tipoEndereco.pais = "BRASIL";
                    tipoEndereco.pse_id = 33;
                    tipoEndereco.tpe_id = 4;
                    tipoEndereco.tpe_descricao = "COMERCIAL";
                    tipoEndereco.pee_numero = dados.numero;
                    tipoEndereco.pee_ativo = true;
                    tipoEndereco.pee_enderecoprincipal = true;
                    tipoEndereco.cep_cep = retornaSoNumeros(dados.cep);
                    tipoEndereco.pee_complemento = dados.complemento;
                    tipoEndereco.bai_nome = dados.bairro;
                    tipoEndereco.log_logradouro = dados.logradouro;
                    tipoEndereco.cid_descricao = dados.municipio;
                    tipoEndereco.est_sigla = dados.uf;
                    tipoEndereco.enderecoCompleto = tipoEndereco.log_logradouro + ', ' + tipoEndereco.pee_numero + ' - ' + tipoEndereco.bai_nome + ' - ' + tipoEndereco.pee_complemento + ' - ' + tipoEndereco.cep_cep + ' - ' + tipoEndereco.cid_descricao + ' - ' + tipoEndereco.uf;
                    listaEnderecos.push(tipoEndereco);

                    let listaTelefones = [];
                    if (dados.telefone !== "" && validarTelefone(retornaSoNumeros(dados.telefone).substring(0, 10))) {
                        let tpt = {};
                        tpt.tpt_id = 1;
                        tpt.tpt_descricao = "COMERCIAL";
                        tpt.psc_ativo = true;
                        tpt.psc_numero = retornaSoNumeros(dados.telefone).substring(0, 10);
                        tpt.psc_principal = true;
                        listaTelefones.push(tpt);
                    }

                    let listaEmails = [];
                    if (dados.email !== "" && validarEmail(dados.email)) {
                        let email = {};
                        email.pem_email = dados.email;
                        email.pem_emailprincipal = true;
                        email.pem_enviarboleto = false;
                        email.pem_enviarcartacobranca = false;
                        email.pem_enviarnfe = false;
                        email.pem_enviarpromocao = false;
                        email.pem_enviarvencparcela = false;
                        email.pem_ativo = true;
                        listaEmails.push(email);
                    }
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ telefones: listaTelefones }, ...{ emails: listaEmails }, ...{ enderecos: listaEnderecos } } } });
                    formulario.setFieldsValue(dadosForm);

                }
                if (dados.status === "OK" && dados.situacao === "BAIXADA") {
                    notification.warning({ message: 'Aviso', description: 'Empresa/CNPJ ' + dados.situacao + ' na receita!' });
                }
            }, (erro) => {
                console.log('ERRO:', erro);
            });
        }
    }
    function listarTransportadorCpfCnpj(cpfCnpj) {
        cpfCnpj = cpfCnpj.replaceAll('-', '').replaceAll('/', '').replaceAll('.', '');
        if (cpfCnpj === null || cpfCnpj === "") {
            return false;
        }
        api.get(`Transportador/BuscarTransportardorCpfCnpj/${cpfCnpj}`).then(
            (res) => {
                let valores = res.data;
                if (res.data.pes_fisicajuridica === 2) {
                    setPessoaJuridica(true);
                } else {
                    setPessoaJuridica(false);
                }
                if (!!res.data && isObjetoDiffVazio(res.data)) {
                    valores.trn_ativo = true;
                    valores.pej_datafundacao = !!valores.pej_datafundacao ? moment(valores.pej_datafundacao) : null;
                    valores.pes_datainclusao = !!valores.pes_datainclusao ? moment(valores.pes_datainclusao) : null;
                    valores.pef_dataalteracao = !!valores.pef_dataalteracao ? moment(valores.pef_dataalteracao) : null;
                    valores.pef_datanascimento = !!valores.pef_datanascimento ? moment(valores.pef_datanascimento) : null;
                    formulario.setFieldsValue(valores);
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: valores } });
                } else {
                    if (cpfCnpj.length === 14) {
                        setPessoaJuridica(true);
                        receitaWS(cpfCnpj);
                    }
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
                if (cpfCnpj.length === 14) {
                    setPessoaJuridica(true);
                    receitaWS(cpfCnpj);
                }
            }
        );
    }
    function alteraBaixadoRec(record) {
        setBaixadoRec(record);
    };



    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoTransportador" onFinish={salvarManutencao}>
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Transportador" key="1">
                        <Row gutter={[8, 8]}>
                            <Col span={24}>
                                {!desabilitarCfpCnpj && <Form.Item>
                                    <Switch onClick={switchEstrangeiro} /> Transportador Estrangeiro
                                </Form.Item>}
                                <Form.Item hidden name="trn_id">
                                    <Input></Input>
                                </Form.Item>
                                <Form.Item hidden name="pej_id">
                                    <Input></Input>
                                </Form.Item>
                                <Form.Item hidden name="pef_id">
                                    <Input></Input>
                                </Form.Item>
                                <Form.Item hidden name="pes_id">
                                    <Input></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[8, 0]}>
                            {!!dadosEstrangeiro === false &&
                                <Col xs={24} sm={7} md={5} lg={4} xl={4} xxl={3}>
                                    <Form.Item label="CPF/CNPJ" name="cpfCnpj" rules={[{ required: true, message: 'Informe o CPF ou CNPJ.' }]}>
                                        <CpfCnpj className='ant-input ant-input-sm'
                                            placeholder="Informe o CPF ou CNPJ"
                                            id="cpf"
                                            value={cpfCnpj}
                                            disabled={desabilitarCfpCnpj}
                                            onBlur={valor => { validarFisicoJuridico(valor); validarCpfCnpjExistente(valor.currentTarget.value.replace(/\D/g, '')) }}
                                            onChange={(event, type) => {
                                                setCpfCnpj(event.target.value);
                                            }} />
                                    </Form.Item>
                                </Col>
                            }
                            {!!dadosEstrangeiro &&
                                <Col xs={24} sm={7} md={5} lg={4} xl={4} xxl={3}>
                                    <Form.Item label="ID Estrangeiro" id="pes_idestrangeiro">
                                        <Input placeholder="Informe o documento"
                                            suffix={
                                                <Tooltip title="Documento de Identificação, Passaporte">
                                                    <QuestionCircleOutlined />
                                                </Tooltip>
                                            } />
                                    </Form.Item>
                                </Col>
                            }
                            <Col xs={24} sm={17} md={10} lg={10} xl={10} xxl={pessoaJuridica ? 8 : 12}>
                                <Form.Item label="Nome" name="pes_nome" rules={[{ required: true, message: 'Informe o nome' }]}>
                                    <Input placeholder="Informe o nome" />
                                </Form.Item>
                            </Col>
                            {pessoaJuridica && <>
                                <Col xs={24} sm={16} md={9} lg={10} xl={10} xxl={9}>
                                    <Form.Item label="Nome Fantasia" name="pej_nomefantasia">
                                        <Input placeholder="Informe o Nome Fantasia" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={4} lg={4} xl={4} xxl={4}>
                                    <Data label="Data de Fundação" name="pej_datafundacao" />
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={5}>
                                    <Form.Item label="Inscrição Estadual" name="pej_inscricaoestadual">
                                        <Input placeholder="Informe a Inscrição Estadual" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={7} lg={7} xl={7} xxl={5}>
                                    <Form.Item label="Inscrição Municipal" name="pej_inscricaomunicipal">
                                        <Input placeholder="Informe a Inscrição Municipal" />
                                    </Form.Item>
                                </Col>
                            </>}
                            {pessoaJuridica && <>
                                <Col xs={24} sm={12} md={7} lg={7} xl={7}>
                                    <Form.Item label="Contato" name="trn_contato">
                                        <Input placeholder="Informe a Pessoa para Contato" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={7} xl={7}>
                                    <Form.Item label="E-mail do Contato" name="pej_emailpessoacontato">
                                        <Input placeholder="Informe a Pessoa para Contato" />
                                    </Form.Item>
                                </Col>
                            </>}
                            {!pessoaJuridica && <>
                                <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                                    <Form.Item label="Sexo" name="pef_sexo">
                                        <Radio.Group options={[{ label: 'Feminino', value: 'F' }, { label: 'Masculino', value: 'M' }]} />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={8} md={6} lg={6} xl={5} xxl={4}>
                                    <Form.Item label="Apelido" name="pef_apelido">
                                        <Input placeholder="Informe o Apelido (Opcional)" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={6} lg={4} xl={5} xxl={5}>
                                    <Form.Item label="RG" name="pef_rg">
                                        <Input placeholder="Informe o RG" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={7} lg={6} xl={5} xxl={6}>
                                    <Form.Item label="Órgão Expedidor" name="pef_rgorgaoexpedidor">
                                        <Input placeholder="Informe órgão expedidor" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={5} lg={4} xl={4} xxl={4}>
                                    <Data label="Data de Expedição" name="pef_rgdataexpedicao" />
                                </Col>
                                <Col xs={24} sm={8} md={5} lg={5} xl={5} xxl={5}>
                                    <Form.Item label="UF RG" name="pef_rguf">
                                        <SelectPaginacao url="Estado/Listar" placeholder="Selecione um Estado" selecionarRegUnico="est_sigla" allowClear={true} form={formulario} nameLabel="est_sigla" nameValue="pef_rguf" onClearFunction={(UfEstado) => carregarCidades(UfEstado)} onSelectFunction={(UfEstado) => carregarCidades(UfEstado)}
                                            conteudo={
                                                est => (
                                                    <Select.Option value={est.est_sigla} key={est.key}>{est.est_sigla} - {est.est_descricao}</Select.Option>)
                                            } />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={5} lg={5} xl={4} xxl={4}>
                                    <Data label="Data de Nascimento" name="pef_datanascimento" />
                                </Col>
                                <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={4}>
                                    <Form.Item label="Cidade Nascimento" name="pef_codigocidadenascimento" disabled={listaCidades == true}>
                                        <SelectPaginacao url="Cidade/Listar" placeholder="Selecione a Cidade de Nascimento" nameLabel="cid_descricao" nameValue="pef_codigocidadenascimento" form={formulario} filtroExtra={filtroCdd} selecionarRegUnico="cid_id"
                                            conteudo={
                                                cid => (<Select.Option value={cid.cid_id} key={cid.key}>{cid.cid_id} - {cid.cid_descricao}</Select.Option>)
                                            } />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={10} md={8} lg={6} xl={6} xxl={6}>
                                    <Form.Item label="Nome do Pai" name="pef_nomepai">
                                        <Input placeholder="Informe o nome do pai do cliente" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                                    <Form.Item label="Nome da Mãe" name="pef_nomemae">
                                        <Input placeholder="Informe o nome da mãe do cliente" />
                                    </Form.Item>
                                </Col>
                            </>}
                            <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={5}>
                                <Form.Item label="Frete por Conta" name="trn_freteporconta" rules={[{ required: true, message: 'Selecione o Frete' }]}>
                                    <Select placeholder="Selecione o Frete">
                                        {fretePorConta.map((frete) => (
                                            <Select.Option value={frete.key} key={frete.key}>{frete.value}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={pessoaJuridica ? 6 : 7} lg={pessoaJuridica ? 6 : 7} xl={pessoaJuridica ? 6 : 7} xxl={5}>
                                <Form.Item label="Tipo de Transporte" name="trn_tipotransporte" rules={[{ required: true, message: 'Selecione Tipo de Transporte' }]}>
                                    <Select placeholder="Selecione Tipo de Transporte">
                                        {tipoTransporte.map((transporte) => (
                                            <Select.Option value={transporte.key} key={transporte.key}>{transporte.value}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={5} xl={pessoaJuridica ? 5 : 6} xxl={5}>
                                <Form.Item label="Percentual do Frete" name="trn_percentualfrete">
                                    <InputNumber
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6} xxl={5}>
                                <InputPreco label="Valor do Frete (R$)" name="trn_valorfretefixo" />
                            </Col>
                            <Col className={pessoaJuridica ? 't-mob' : 't-mob'}>
                                <Form.Item name="trn_ativo" valuePropName="checked" initialValue={true}>
                                    <Checkbox> Transportador Ativo? </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Observação" name="trn_observacao">
                                    <Input.TextArea placeholder="Informe uma observação" />
                                </Form.Item>
                            </Col>
                        </Row>
                        {pessoaJuridica &&
                            <Row>
                                <Col>
                                    <Form.Item name="pej_baixadoreceita" valuePropName="checked">
                                        <Checkbox onChange={(value) => { alteraBaixadoRec(value) }}>Baixado da Receita?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Endereços" key="3">
                        <Endereco />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Telefones" key="4">
                        <Telefone />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="E-mails" key="5">
                        <Email />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Veículos" key="6">
                        <Veiculos />
                    </Tabs.TabPane>
                    {!pessoaJuridica && <Tabs.TabPane tab="Nacionalidade" key="7">
                        <Nacionalidade />
                    </Tabs.TabPane>}
                    <Tabs.TabPane tab="Outros Contatos" key="8">
                        <OutrosContatos />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Campos Adicionais" key="9">
                        <Row align="middle" gutter={[0, 16]}>
                            <Col span={24}>
                                <Typography.Title level={3}>
                                    <img src={require("../../assets/i-grade.png").default} alt="Campos Adicionais" /> Campos Adicionais
                                </Typography.Title>
                                <Divider orientation="left" plain>
                                    Adicione abaixo as informações adicionais.
                                </Divider>
                            </Col>
                            <Col span={24}>
                                <FormularioDinamico formulario={formulario} parametrosFiltros={elementosFormulario} />
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </FormGW>
        </div>
    );

}