import React, { useState } from "react";
import { Button, Tooltip } from "antd";

import { AberturaChamado } from "../../components";

export default function BotaoAbrirChamado() {

    const [activeOpen, setActiveOpen] = useState(false);

    const handleOnClickOpen = () => {
        setActiveOpen(true);
    }

    return (
        <>
            <Tooltip title="Abrir chamado para o Suporte" placement="left">
                <Button type="link" size="large" onClick={() => handleOnClickOpen()}>
                    <img src={require("../../assets/i-suporte.png").default} alt="Abrir chamado para o Suporte" />
                </Button>
            </Tooltip>
            <AberturaChamado activeOpen={activeOpen} setActiveOpen={setActiveOpen} />
        </>
    );
}