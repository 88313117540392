import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tag, Table, Modal } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { FiltroProdutoOrcamento } from "../../components";
import { FormatNumber } from '../../ValueObjects';

export default function TabItens({ form, dadosItens }) {

    const [quantidadeItens, setQuantidadeItens] = useState(0);
    const [valorTotal, setValorTotal] = useState(0);
    const [editando, setEditando] = useState(false);
    const [dadosEditando, setDadosEditando] = useState({});

    useEffect(() => {
        if (dadosItens.listaProduto.length > 0) {
            let total = 0;
            setQuantidadeItens(dadosItens.listaProduto.length);
            dadosItens.listaProduto.forEach((item) => {
                total += parseFloat(item.oit_qtde * item.oit_valorunitario);
            });
            setValorTotal(total);
        } else {
            setQuantidadeItens(0);
            setValorTotal(0);
        }
    }, [dadosItens.listaProduto]);

    function editarItem(registro) {
        setDadosEditando(registro);
        setEditando(true);
    };

    function removerItem(record) {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja remover o item da nota?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                let dadosTemp = [...dadosItens.listaProduto];
                let dadosRet = dadosTemp.filter((item => item !== record));
                dadosItens.setListaProduto(dadosRet);
            }
        });
    };

    return (
        <div className="m-t-16">
            <FiltroProdutoOrcamento form={form} dadosTabela={dadosItens} btnEditando={{ editando, setEditando }} itemEditando={dadosEditando} />
            <div className="tabela mt-dif">
                {!!dadosItens.listaProduto && dadosItens.listaProduto.length > 0 && <Table columns={[
                    {
                        title: 'ITEM',
                        width: 220,
                        render: ({ pro_codigo, pro_descricao, pro_ean, prg_ean, grade }) => (
                            <div>
                                <Row align="middle" gutter={[5, 0]}>
                                    <Col>
                                        <Tag color="processing" className="w-75">
                                            <b>{pro_codigo}</b>
                                        </Tag>
                                        <b> {pro_descricao}</b>
                                    </Col>
                                </Row>
                                <Row align="middle" gutter={[5, 0]}>
                                    <Col>
                                        EAN: <b>{!!pro_ean ? pro_ean : prg_ean}</b>
                                    </Col>
                                    <Col hidden={!!!grade}>
                                        Grade: <b>{grade}</b>
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: 'Qtde.',
                        width: 40,
                        align: 'center',
                        render: ({ oit_qtde }) => (
                            <div>
                                <b>{oit_qtde}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Vlr Unt.(R$)',
                        align: 'right',
                        width: 50,
                        render: ({ oit_valorunitario }) => (
                            <div>
                                <b>{FormatNumber(oit_valorunitario, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Vlr Total (R$)',
                        align: 'right',
                        width: 60,
                        render: ({ total, oit_qtde, oit_valorunitario }) => (
                            <div>
                                <b>{FormatNumber((oit_qtde * oit_valorunitario), true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 40,
                        fixed: 'right',
                        render: (record, e, indice) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<EditOutlined />} onClick={() => editarItem(record)} />
                                    </Col>
                                    <Col>
                                        <Button icon={<DeleteOutlined />} onClick={() => removerItem(record, indice)} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} dataSource={dadosItens.listaProduto} scroll={{ x: 900 }} />}
            </div>
            <div className="m-t-16">
                <Row justify="end" align="middle" gutter={[8, 0]}>
                    <Col className="f-14">
                        <span>Quantidade Itens:</span>
                    </Col>
                    <Col className="f-16">
                        <b>{quantidadeItens}</b>
                    </Col>
                </Row>
                <Row justify="end" gutter={[8, 0]}>
                    <Col className="f-14">
                        <span>Valor Total dos Itens (R$):</span>
                    </Col>
                    <Col className="f-16">
                        <b>{FormatNumber(valorTotal, true)}</b>
                    </Col>
                </Row>
            </div >
        </div>
    );
}