import React, { useState, useEffect } from "react";
import { Typography, Row, Col, Form, Divider, Select, Button, InputNumber, Card, Table, notification, Input, Tag } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, FormOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import { FormatNumber } from '../../ValueObjects';
import { Data, InputPreco, SelectIncluir } from "../../components";
import DrawerVendedores from "../../components/drawerVendedores";
import SelectPaginado from "../../components/selectPaginado";
import { useStateValue } from "../../state";
import { manutencaoActions } from "../../actions";
import { CheckOutlined, CloseOutlined } from "@material-ui/icons";

export default function TabFaturamento({ form, valorTotalPedido, listaFormaPagamento, setListaFormaPagamento, emiteTransferencia, dadosOrcamento }) {
    const [{ manutencao }, dispatch] = useStateValue();
    const [editarValores, setEditarValores] = useState(false);
    const [valorTotal, setValorTotal] = useState(0);
    const [formaPagamento, setFormaPagamento] = useState([]);
    const [condicaoPagamento, setCondicaoPagamento] = useState([]);
    const [valorJaPago, setValorJaPago] = useState(false);
    const [pagamentoTotal, setPagamentoTotal] = useState(false);
    const [editando, setEditando] = useState(false);
    const [editandoParcelas, setEditandoParcelas] = useState(false);
    const [executarParcelas, setExecutarParcelas] = useState(false);
    const [formaPagamentoAtual, setFormaPagamentoAtual] = useState(null);
    const editarDesconto = () => {
        setEditarValores(!editarValores)
    };
    const pagamentosCodigos = {
        dinheiro: 1,
        cheque: 2,
        cartaoCredito: 3,
        cartaoDebito: 4,
        crediarioLoja: 5,
        valeAlimentacao: 10,
        valeRefeicao: 11,
        valePresente: 12,
        valeCombustivel: 13,
        duplicataMercantil: 14,
        boleto: 15,
        depositoBancario: 16,
        pixDinamico: 17,
        transfBancaria: 18,
        programaFidelidade: 19,
        pixEstatico: 20,
        creditoEmLoja: 21,
        falhaDeHardware: 22,
        semPagamento: 90,
        outro: 99
    };
    useEffect(() => {
        carregarDados();
        calcularTotal();
        recalcularTotalPedido();
    }, []);

    useEffect(() => {
        let valor = 0;
        if (!!listaFormaPagamento && listaFormaPagamento.length > 0) {
            listaFormaPagamento.forEach((lista) => {
                valor += parseFloat(lista.pfp_valor);
            });
            if (valor >= valorTotal) {
                setPagamentoTotal(true);
            } else {
                valorRestantePgto([...listaFormaPagamento]);
            }
            if (listaFormaPagamento.some(dado => !dado.parcelas || dado.parcelas.length === 0)){
                setExecutarParcelas(true);
            } else {
                listaFormaPagamento.forEach(dado => {
                    if (dado.parcelas && dado.parcelas.length > 0) {
                        dado.pedidoparcela = dado.parcelas.map(parcela => {
                            return {
                                ...parcela,
                                pcl_numeroparcela: parcela.prc_numeroparcela,
                                dataVencimento: parcela.prc_datavencimento,
                                valorParcela: parcela.prc_valor,
                            };
                        });
                        delete dado.parcelas
                    }
                });
            }
            dispatch({ type: manutencaoActions.CHANGE, data: {dados: { ...manutencao.dados, ...{ faturamento: listaFormaPagamento } } } });
        } else {
            setPagamentoTotal(false);
        }
        setValorJaPago(valor);
    }, [listaFormaPagamento]);

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.faturamento && executarParcelas) {
            let dados = manutencao.dados.faturamento;
            let index = dados.length - 1;
            setExecutarParcelas(false);
            if (dados[index].fpg_tipopagamento === pagamentosCodigos.crediarioLoja) {
                validarJaPossuiCrediario(() => {
                    popularParcelas();
                });
            } else if (dados[index].fpg_tipopagamento === pagamentosCodigos.boleto) {
                validarJaPossuiBoleto(() => {
                    popularParcelas();
                });
            }
        }
    }, [manutencao.dados]);

    function carregarDados() {
        if (!!dadosOrcamento) {
            form.setFieldsValue({ pdi_valorfrete: dadosOrcamento.orc_valorfrete });
            if (listaFormaPagamento[0].orf_percentualdesconto) {
                form.setFieldsValue({ percentualDesconto: listaFormaPagamento[0].orf_percentualdesconto });
                calculaDesconto('perc')
                setEditarValores(true);
            }
        }
    };

    function validarJaPossuiCrediario(callback) {
        let dados = [...listaFormaPagamento];
        if (!dados.find((formaPgto => (formaPgto.fpg_tipopagamento === pagamentosCodigos.crediarioLoja && !!formaPgto.pedidoparcela && formaPgto.pedidoparcela.length > 0)))) {
            if (callback) {
                callback();
            }
        }
    }

    function validarJaPossuiBoleto(callback) {
        let dados = [...listaFormaPagamento];
        let possuiBoletoComParcelas = dados.some(formaPgto => 
            formaPgto.fpg_tipopagamento === pagamentosCodigos.boleto &&
            !!formaPgto.pedidoparcela &&
            formaPgto.pedidoparcela.length > 0
        );
        let possuiBoletoSemParcelas = dados.some(formaPgto => 
            formaPgto.fpg_tipopagamento === pagamentosCodigos.boleto &&
            (!formaPgto.pedidoparcela || formaPgto.pedidoparcela.length === 0)
        );

        if (!possuiBoletoComParcelas || possuiBoletoSemParcelas) {
            if (callback) {
                callback();
            }
        }
    }

    function popularParcelas(callback) {
        let dados = [...listaFormaPagamento];
        let index = manutencao.dados.faturamento.length - 1;

        api.get(`CondicaoPagamento/ListarParcelamento?codigoCondicaoPagamento=${manutencao.dados.faturamento[index].cpg_id}`).then(
            res => {
                if (!!res.data && res.data.length > 0) {
                    let resto = 0;
                    let somaParcelas = 0;
                    let listaParcelas = [];
                    let indexPosicao = manutencao.dados.faturamento.length - 1;
                    res.data.forEach((parcela) => {
                        let dataAtual = new Date();
                        let parc = {};
                        let dataVencimento = new Date(dataAtual.setDate(dataAtual.getDate() + parcela.pcl_numerodias));
                        parc.fpg_id = manutencao.dados.faturamento[indexPosicao].fpg_id;
                        parc.fpg_descricao = manutencao.dados.faturamento[indexPosicao].fpg_descricao;
                        parc.fpg_tipopagamento = manutencao.dados.faturamento[indexPosicao].fpg_tipopagamento;
                        parc.cpg_id = manutencao.dados.faturamento[indexPosicao].cpg_id;
                        parc.pcl_numerodias = parcela.pcl_numerodias;
                        parc.pcl_percparcela = parcela.pcl_percparcela;
                        parc.pcl_numeroparcela = parcela.pcl_numeroparcela;
                        parc.prc_numeroparcela = parcela.pcl_numeroparcela;
                        parc.dataPagamento = dataVencimento;
                        parc.prc_datavencimento = moment(dataVencimento);
                        parc.dataVencimento = moment(dataVencimento);
                        let valorParcela = ((parcela.pcl_percparcela / 100) * parseFloat(manutencao.dados.faturamento[index].pfp_valor)).toFixed(2);
                        parc.valorParcela = parseFloat(valorParcela);
                        parc.prc_valor = parseFloat(valorParcela);
                        somaParcelas += parc.valorParcela;
                        listaParcelas.push(parc);
                    });
                    somaParcelas = somaParcelas.toFixed(2);
                    somaParcelas = parseFloat(somaParcelas);
                    resto = parseFloat(manutencao.dados.faturamento[index].pfp_valor) - somaParcelas;
                    if (resto != 0) {
                        let numeroUltimaParcela = res.data.length - 1;
                        let valParcela = listaParcelas[numeroUltimaParcela].valorParcela;
                        let totalParcela = (valParcela + resto).toFixed(2);
                        totalParcela = parseFloat(totalParcela);
                        listaParcelas[numeroUltimaParcela].prc_valor = totalParcela;
                        listaParcelas[numeroUltimaParcela].valorParcela = totalParcela;
                    }
                    dados[index].pedidoparcela = listaParcelas;
                    manutencao.dados.faturamento[indexPosicao].editando = false;
                    setListaFormaPagamento(dados);
                }
                if (callback) {
                    callback();
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    }

    function valorRestantePgto(dadosTemp) {
        let restante = 0;
        let valorPago = 0;
        let valor = form.getFieldValue().pfp_valor;
        dadosTemp.forEach((dados) => {
            valorPago += parseFloat(dados.pfp_valor);
        });
        restante = parseFloat(form.getFieldValue().valorTotal) - valorPago;
        if (parseFloat(restante.toFixed(3)) === 0) {
            setPagamentoTotal(true);
        } else {
            setPagamentoTotal(false);
        }
        form.setFieldsValue({ pfp_valor: restante.toFixed(2) });
        setEditando(false);
    };

    function calcularTotal() {
        let valor = 0;
        valor = parseFloat(valorTotalPedido) + parseFloat(!!form.getFieldValue().pdi_valorfrete ? form.getFieldValue().pdi_valorfrete : 0) + parseFloat(!!form.getFieldValue().pdi_valoroutrasdespesas ? form.getFieldValue().pdi_valoroutrasdespesas : 0);
        setValorTotal(valor);
        form.setFieldsValue({ ped_valorTotal: valor.toFixed(2) });
        valorRestantePgto([...listaFormaPagamento]);
    };

    async function listarCondicao(fpg_id, dadosIniciais = false) {
        if (!dadosIniciais) {
            form.setFieldsValue({ cpg_id: null });
        }

        return api.get(`CondicaoPagamento/Listar?Ativo=true&codigoFormaPagamento=${fpg_id}&PageSize=500`).then(
            (res) => {
                if (res.status === 200) {
                    setCondicaoPagamento(res.data.items);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    async function adicionarFormaPagamento() {
        if (editando) {
            if (!form.getFieldValue().fpg_id || !form.getFieldValue().cpg_id) {
                notification.warning({ message: 'Aviso', description: 'Informe a forma e a condicao de pagamento!' });
            }
            let novaFormaPagamento = (await api.get(`FormaPagamento/Get?id=${form.getFieldValue().fpg_id}`)).data;
            let novaCondicoesPagamento = condicaoPagamento.filter((condicao) => { return condicao.cpg_id === form.getFieldValue().cpg_id; })[0];
            let pagamentoTemp = [];
            pagamentoTemp.push({
                fpg_id: form.getFieldValue().fpg_id,
                fpg_descricao: novaFormaPagamento.fpg_descricao,
                cpg_descricao: !!novaCondicoesPagamento && novaCondicoesPagamento.cpg_descricao ? novaCondicoesPagamento.cpg_descricao : '',
                cpg_id: !!form.getFieldValue().cpg_id ? form.getFieldValue().cpg_id : null,
                pfp_valor: parseFloat(form.getFieldValue().pfp_valor),
                fpg_tipopagamento: novaFormaPagamento.fpg_tipopagamento
            });
            setListaFormaPagamento(pagamentoTemp);
            form.setFieldsValue({ fpg_id: null });
            form.setFieldsValue({ cpg_id: null });
            valorRestantePgto(pagamentoTemp);
            setEditando(false);

        } else {
            let dadosTemp = [...listaFormaPagamento];
            if (!!form.getFieldValue().fpg_id && (condicaoPagamento.length === 0 || !!form.getFieldValue().cpg_id)) {
                if (listaFormaPagamento.find((formas) => (
                    formas.fpg_id === form.getFieldValue().fpg_id && formas.cpg_id === form.getFieldValue().cpg_id
                ))) {
                    let dados = listaFormaPagamento.find((formas) => (
                        formas.fpg_id === form.getFieldValue().fpg_id && formas.cpg_id === form.getFieldValue().cpg_id
                    ));
                    if (parseFloat(form.getFieldValue().ped_valorTotal) < parseFloat(form.getFieldValue().pfp_valor) + (parseFloat(valorJaPago) - dados.pfp_valor)) {
                        notification.warning({ message: 'Aviso', description: 'Valor de pagamento maior que o total do pedido!' });
                        return;
                    } else {
                        dados.pfp_valor = parseFloat(form.getFieldValue().pfp_valor);
                        setListaFormaPagamento(dadosTemp);
                        form.setFieldsValue({ fpg_id: null });
                        form.setFieldsValue({ cpg_id: null });
                        valorRestantePgto(dadosTemp);
                    }
                } else {
                    if (parseFloat(form.getFieldValue().ped_valorTotal) < parseFloat(form.getFieldValue().pfp_valor) + parseFloat(valorJaPago)) {
                        notification.warning({ message: 'Aviso', description: 'Valor de pagamento maior que o total do pedido!' });
                    } else {
                        let dadosFormaPagamento = (await api.get(`FormaPagamento/Get?id=${form.getFieldValue().fpg_id}`)).data;

                        let dadosCondicoesPagamento = condicaoPagamento.filter((condicao) => { return condicao.cpg_id === form.getFieldValue().cpg_id; })[0];
                        dadosTemp.push({
                            fpg_id: form.getFieldValue().fpg_id,
                            fpg_descricao: dadosFormaPagamento.fpg_descricao,
                            cpg_descricao: !!dadosCondicoesPagamento && dadosCondicoesPagamento.cpg_descricao ? dadosCondicoesPagamento.cpg_descricao : '',
                            cpg_id: !!form.getFieldValue().cpg_id ? form.getFieldValue().cpg_id : null,
                            pfp_valor: parseFloat(form.getFieldValue().pfp_valor),
                            fpg_tipopagamento: dadosFormaPagamento.fpg_tipopagamento
                        });
                        setListaFormaPagamento(dadosTemp);
                        form.setFieldsValue({ fpg_id: null });
                        form.setFieldsValue({ cpg_id: null });
                        valorRestantePgto(dadosTemp);
                    }
                }
            } else {
                notification.warning({ message: 'Aviso', description: 'Informe a forma e a condicao de pagamento!' });
            }
        }
    };

    function calculaDesconto(campo) {
        let percValor = (!!form.getFieldValue().percentualDesconto ? form.getFieldValue().percentualDesconto : 0);
        let valor = (!!form.getFieldValue().ped_valorTotalDesc ? form.getFieldValue().ped_valorTotalDesc : 0);
        if (campo === 'perc') {
            valor = (percValor * valorTotalPedido) / 100;
            form.setFieldsValue({ ped_valorTotalDesc: parseFloat(valor).toFixed(2) });
        } else if (campo === 'valor') {
            valor = (valor / valorTotalPedido) * 100;
            valor = parseFloat(valor).toFixed(2);
            form.setFieldsValue({ percentualDesconto: valor });
        }
        recalcularTotalPedido();
    };

    function calculaAcrescimo(campo) {
        let perc = (!!form.getFieldValue().percentualAcrescimo ? form.getFieldValue().percentualAcrescimo : 0);
        let valor = (!!form.getFieldValue().ped_valorTotalAcre ? form.getFieldValue().ped_valorTotalAcre : 0);
        if (campo === 'perc') {
            valor = (perc * valorTotalPedido) / 100;
            form.setFieldsValue({ ped_valorTotalAcre: parseFloat(valor).toFixed(2) });
        } else if (campo === 'valor') {
            valor = (100 * valor) / valorTotalPedido;
            form.setFieldsValue({ percentualAcrescimo: parseFloat(valor).toFixed(2) })
        }
        recalcularTotalPedido();
    };

    function recalcularTotalPedido() {
        let formulario = form.getFieldValue();
        const percDesconto = formulario.percentualDesconto ? parseFloat(formulario.percentualDesconto) / 100 : 0;
        const percAcresc = formulario.percentualAcrescimo ? parseFloat(formulario.percentualAcrescimo) / 100 : 0;
        const valorFrete = formulario.pdi_valorfrete;
        const valorOutros = formulario.pdi_valoroutrasdespesas;
        const valorCFreteEOutros = parseFloat(valorTotalPedido) + parseFloat(valorFrete) + parseFloat(valorOutros);
        const calcuPercDesc = parseFloat(valorCFreteEOutros) * parseFloat(percDesconto);
        const calcuPercAcresc = parseFloat(valorCFreteEOutros) * parseFloat(percAcresc);
        const valorTotal = parseFloat(valorCFreteEOutros) - parseFloat(calcuPercDesc) + parseFloat(calcuPercAcresc);
        form.setFieldsValue({ valorTotal: valorTotal.toFixed(2) });
        form.setFieldsValue({ ped_valorTotal: valorTotal.toFixed(2) });
        form.setFieldsValue({ pfp_valor: valorTotal.toFixed(2) });
        if (listaFormaPagamento.length > 0) {
            listaFormaPagamento[0].pfp_valor = valorTotal.toFixed(2);
        }
        setValorTotal(valorTotal.toFixed(2));
    };

    async function editarFormasPagamento(forma) {
        setPagamentoTotal(false);
        setEditando(true);
        await listarCondicao(forma.fpg_id, true)
        form.setFieldsValue({ fpg_id: forma.fpg_id });
        form.setFieldsValue({ cpg_id: forma.cpg_id });
        form.setFieldsValue({ pfp_valor: forma.pfp_valor });
    };

    function removerFormaPagamento(forma) {
        let dadosTemp = [...listaFormaPagamento];
        dadosTemp = dadosTemp.filter((dados) => dados !== forma);
        form.setFieldsValue({ pfp_valor: forma.pfp_valor });
        form.setFieldsValue({ fpg_id: null });
        form.setFieldsValue({ cpg_id: null });
        setListaFormaPagamento(dadosTemp);
        valorRestantePgto(dadosTemp);
        recalcularTotalPedido();
        setEditando(false);
    };

    function editarParcelas(forma) {
        let dados = forma;
        if (dados.fpg_tipopagamento === pagamentosCodigos.crediarioLoja || dados.fpg_tipopagamento === pagamentosCodigos.boleto) {
            setEditandoParcelas(true);
            setFormaPagamentoAtual(dados.fpg_id);
        }
    }

    function salvarParcelas(record) {
        let formasPgto = [...listaFormaPagamento];
        let formaPgto = formasPgto.find((forma) => (forma.fpg_id === record.fpg_id));
        formaPgto.pedidoparcela.forEach((item, i) => {
            i++;
            item.prc_datavencimento = form.getFieldValue(`dataVencimento${i}`);
            item.dataVencimento = form.getFieldValue(`dataVencimento${i}`);
            item.valorParcela = parseFloat(form.getFieldValue(`valorParcela${i}`));
            item.prc_valor = parseFloat(form.getFieldValue(`valorParcela${i}`));
        });
        setListaFormaPagamento(formasPgto);
        setEditandoParcelas(false);
    }

    function onChangeValorParc(numeroParc, qtdTotParcelas) {
        let dadosFormaPagamento = [...listaFormaPagamento];
        let valorJaUtilizado = 0;
        let valorRestante = 0;
        let valorRestanteTot = 0;
        let valorParcelas = 0;
        let valorTotal = parseFloat(dadosFormaPagamento[0].pfp_valor);
        let dados = form.getFieldValue();
        if (numeroParc === qtdTotParcelas) {
            return;
        }
        for (let i = 1; i <= numeroParc; i++) {
            valorJaUtilizado += parseFloat(dados[`valorParcela${i}`]);
        }
        valorRestante = valorTotal - valorJaUtilizado;
        valorParcelas = parseFloat((valorRestante / (qtdTotParcelas - numeroParc)).toFixed(2));
        for (let i = qtdTotParcelas; i > numeroParc; i--) {
            valorRestanteTot += valorParcelas;
            form.setFieldsValue({ [`valorParcela${i}`]: valorParcelas });
        }
        let valorSobra = valorTotal - (valorJaUtilizado + valorRestanteTot);
        form.setFieldsValue({ [`valorParcela${qtdTotParcelas}`]: parseFloat((valorParcelas + valorSobra).toFixed(2)) });
    }

    function ajustarDataParcelas(numeroParc, qtdTotParcelas) {
        let dataAtual = new Date(form.getFieldValue([`dataVencimento${numeroParc}`]).toDate());
        for (let i = numeroParc + 1; i <= qtdTotParcelas; i++) {
            let dataVenc = new Date(dataAtual.setDate(new Date(dataAtual).getDate() + 30));
            form.setFieldsValue({ [`dataVencimento${i}`]: moment(dataVenc) });
        }
    }

    const expandedRowRender = record => {
        return <Table columns={[
            {
                title: 'Nº',
                render: ({ pcl_numeroparcela }) => (
                    <Tag color="processing">{pcl_numeroparcela}</Tag>
                ),
            }, {
                title: 'Data Pagamento',
                render: ({ dataVencimento, pcl_numeroparcela, fpg_id }) => (
                    <div>
                        {editandoParcelas && fpg_id === formaPagamentoAtual ? <Data
                            initialValue={dataVencimento}
                            name={`dataVencimento${pcl_numeroparcela}`}
                            onBlur={() => { ajustarDataParcelas(pcl_numeroparcela, record.pedidoparcela.length) }} /> : <b>{moment(dataVencimento).format('DD/MM/YYYY')}</b>
                        }
                    </div>
                )
            }, {
                title: 'Valor (R$)',
                align: 'right',
                render: ({ valorParcela, pcl_numeroparcela, fpg_id }) => (
                    <div>
                        {editandoParcelas && fpg_id === formaPagamentoAtual ? <InputPreco
                            name={`valorParcela${pcl_numeroparcela}`}
                            initialValue={valorParcela}
                            disabled={pcl_numeroparcela === record.pedidoparcela.length}
                            onBlur={() => { onChangeValorParc(pcl_numeroparcela, record.pedidoparcela.length) }} /> : <b>{FormatNumber(valorParcela, true)}</b>
                        }
                    </div>
                ),
            }, {
                title: 'Ações',
                dataIndex: '',
                key: 'x',
                align: 'center',
                width: 65,
                render: (record) => (
                    <div>
                        <Row align="middle" justify="center" gutter={[5, 0]}>
                            {record.fpg_tipopagamento !== pagamentosCodigos.programaFidelidade && !record.fpg_quitacaoantecipacao && <Col>
                                {!editandoParcelas &&
                                    <Button icon={<FormOutlined />}
                                        onClick={() => {
                                            editarParcelas(record);
                                        }}
                                    />
                                }
                                {editandoParcelas && record.fpg_id === formaPagamentoAtual &&
                                    <Button icon={<CheckOutlined />}
                                        onClick={() => {
                                            salvarParcelas(record);
                                        }}
                                    />
                                }
                            </Col>}
                            <Col>
                                {editandoParcelas && record.fpg_id === formaPagamentoAtual &&
                                    <Button icon={<CloseOutlined />}
                                        onClick={() => {
                                            setEditandoParcelas(false);
                                    }} />
                                }
                            </Col>
                        </Row>
                    </div>
                )
            }
        ]} dataSource={record.pedidoparcela} pagination={false} />
    }

    const rowExpandable = record => { return (record.fpg_tipopagamento === pagamentosCodigos.crediarioLoja || record.fpg_tipopagamento === pagamentosCodigos.boleto)};

    return (
        <div className="m-t-16">
            <Row align="middle" justify="center">
                {/* <Col xs={24} sm={24} md={24} lg={15} xl={15}> */}

                <Col xs={24} sm={24} md={18} lg={15} xl={16} xxl={14}>
                    <Card extra={
                        <Typography.Title level={3}>
                            Resumo do Pedido
                        </Typography.Title>
                    }>
                        <Row gutter={[8, 0]} className="text-right">
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <Form.Item label="Subtotal do Pedido (R$)">
                                    <b>{FormatNumber(valorTotalPedido, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <Form.Item label="Valor do Frete (R$)">
                                    <b>{FormatNumber(form.getFieldValue().pdi_valorfrete, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <Form.Item label="Outras Despesas (R$)">
                                    <b>{FormatNumber(form.getFieldValue().pdi_valoroutrasdespesas, true)}</b>
                                </Form.Item>
                            </Col>
                        </Row>
                        {!emiteTransferencia && <Row justify="end" gutter={[0, 8]} className="m-t-b-5">
                            <Col>
                                <Button onClick={editarDesconto} icon={<EditOutlined />} type="primary">
                                    Editar Descontos/Acréscimos
                                </Button>
                            </Col>
                        </Row>}
                        {editarValores &&
                            <div>
                                <Row justify="center" gutter={[8, 0]}>
                                    <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                                        <Form.Item label="Percentual de Desconto" name="percentualDesconto">
                                            <InputNumber
                                                defaultValue={0}
                                                min={0}
                                                max={100}
                                                formatter={value => `${value}%`}
                                                parser={value => value.replace('%', '')}
                                                onBlur={() => { calculaDesconto('perc') }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4} className="text-center t-mob-573">
                                        <b> ou </b>
                                    </Col>
                                    <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                                        <InputPreco name={'ped_valorTotalDesc'} label={'Valor do Desconto (R$)'} onBlur={() => { calculaDesconto('valor') }} />
                                    </Col>
                                </Row>
                                <Row justify="center" gutter={[8, 0]}>
                                    <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                                        <Form.Item label="Percentual de Acréscimo" name="percentualAcrescimo">
                                            <InputNumber
                                                defaultValue={0}
                                                min={0}
                                                max={100}
                                                formatter={value => `${value}%`}
                                                parser={value => value.replace('%', '')}
                                                onBlur={() => { calculaAcrescimo('perc') }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4} className="text-center t-mob-573">
                                        <b> ou </b>
                                    </Col>
                                    <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                                        <InputPreco name={'ped_valorTotalAcre'} label={'Valor do Acréscimo (R$)'} onBlur={() => { calculaAcrescimo('valor') }} />
                                    </Col>
                                </Row>
                            </div>
                        }
                        <Row justify="end">
                            <Col>
                                IPI (R$): <b> {FormatNumber(form.getFieldValue().valorTotalIPI, true)}</b>
                            </Col>
                        </Row>
                        <Row justify="end">
                            <Col>
                                Substituição Tributária (R$): <b> {FormatNumber(form.getFieldValue().valorTotalSt, true)}</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Divider className="m-t-b-5" />
                            </Col>
                        </Row>
                        <Row justify="end">
                            <Col>
                                <Typography.Title level={3} >
                                    Total do Pedido (R$)  {FormatNumber(valorTotal, true)}
                                </Typography.Title>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className="m-t-16">
                <Col span={24}>
                    <Form.Item label="Informações Adicionais do Pedido" name="ped_observacao">
                        <Input.TextArea
                            autoSize={{
                                minRows: 2,
                                maxRows: 6,
                            }}
                            maxLength={800}
                            placeholder="Informações Adicionais do Pedido" />
                    </Form.Item>
                </Col>
            </Row>
            {!pagamentoTotal && <Row gutter={[8, 0]}>
                {!emiteTransferencia && <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Form.Item label="Forma de Pagamento" name="fpg_id" rules={[{ required: true, message: 'Informe a forma de pagamento' }]}>
                        <SelectPaginado url="FormaPagamento/Listar?Ativo=true&Order=+fpg_descricao" placeholder="Selecione Forma de Pagamento" form={form} name="fpg_id" onChangeFunction={(registro) => { listarCondicao(registro) }} conteudo={
                            fp => (<Select.Option value={fp.fpg_id} key={fp.key}>{fp.fpg_descricao}</Select.Option>)
                        } />
                    </Form.Item>
                </Col>}
                {condicaoPagamento.length > 0 && <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Form.Item label="Condição de Pagamento" name="cpg_id" rules={[{ required: true, message: 'Informe a condicao de pagamento' }]}>
                        <Select placeholder="Selecione Condição de Pagamento">
                            {condicaoPagamento.map((condicao) => (
                                <Select.Option value={condicao.cpg_id} key={condicao.cpg_id}>{condicao.cpg_descricao}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>}
                <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                    {!emiteTransferencia &&
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={14} lg={14} xl={14}>
                                <InputPreco name="pfp_valor" label="Valor do Pagamento (R$)" />
                            </Col>
                            <Col xs={24} sm={12} md={10} lg={10} xl={10}>
                                <Button icon={<PlusOutlined />} type="primary" onClick={() => (adicionarFormaPagamento())} className="t-mob-573" block>
                                    Adicionar
                                </Button>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>}
            <Row gutter={[0, 24]}>
                <Col span={24}>
                    <div className="tabela mt-dif">
                        {!!listaFormaPagamento && listaFormaPagamento.length > 0 && <Table columns={[
                            {
                                title: 'Formas de Pagamento',
                                align: 'left',
                                render: ({ fpg_descricao, cpg_descricao }) => (
                                    <div>
                                        <b>
                                            {fpg_descricao} {!!cpg_descricao ? `/ ${cpg_descricao}` : ''}
                                        </b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Valor (R$)',
                                align: 'right',
                                render: ({ pfp_valor }) => (
                                    <div>
                                        <b>{FormatNumber(pfp_valor, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                key: 'x',
                                align: 'center',
                                width: 65,
                                render: (record) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button icon={<EditOutlined />} onClick={() => {
                                                    editarFormasPagamento(record);
                                                }} />
                                            </Col>
                                            <Col>
                                                <Button icon={<DeleteOutlined />} onClick={() => {
                                                    removerFormaPagamento(record);
                                                }} />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]} 
                        expandable={{ expandedRowRender, rowExpandable }}
                        footer={() => {
                            let totalPagamento = 0;
                            listaFormaPagamento.map((dados) => {
                                totalPagamento += parseFloat(dados.pfp_valor);
                            })
                            return (
                                <Row align="middle" justify="end" gutter={[8, 0]}>
                                    <Col>
                                        <span className="f-14"> Total dos Pagamentos (R$):</span>
                                    </Col>
                                    <Col>
                                        <Form.Item name="pfp_valor">
                                            <strong className="f-21">{FormatNumber(totalPagamento, true)}</strong>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            )
                        }} dataSource={listaFormaPagamento}
                        />}
                    </div>
                </Col>
            </Row>
        </div>
    );
}