import React, { useState, useEffect } from 'react';
import { Row, Col, Descriptions, Tabs } from "antd";

import { TabDetalheItens, TabDetalheIFormaPagamento, TabDetalheTotalizadores, TabNotaReferenciada, TabNotaAntecipacaoDevolucao } from './tabPage';
import { useStateValue } from '../../state';
import moment from 'moment';
import { FormatFone, MaskFormat } from '../../ValueObjects/index';

export default function DetalheNotaFiscal({ dadosModal }) {
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [detalheNotaFiscal, setDetalheNotaFiscal] = useState({});
    const [arrFinalidade] = useState(["NF-E NORMAL", "NF-E COMPLEMENTAR", "NF-E DE AJUSTE", "DEVOLUÇÃO"]);
    const [arrStatus] = useState(["EM DIGITAÇÃO", "AUTORIZADA", "CANCELADA", "DENEGADA", "REJEITADA", "CONTINGÊNCIA"]);
    const [arrAmbiente] = useState(["PRODUÇÃO", "HOMOLOGAÇÃO"]);
    const [verificadevolucao, setverificadevolucao] = useState(false);

    useEffect(() => {
        if (!!dadosModal) {
            setDetalheNotaFiscal(dadosModal);
            setverificadevolucao(dadosModal.verificadevolucao);
        }
    }, [dadosModal]);

    return (
        <div>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="Destinatário">
                            <b>{(!!detalheNotaFiscal.ntf_nomedest ? detalheNotaFiscal.ntf_nomedest : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>

                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }} size="small">
                    <Descriptions.Item label="CPF/CNPJ">
                            <b>{(!!detalheNotaFiscal.ntf_cnpjcpfdest ? MaskFormat(detalheNotaFiscal.ntf_cnpjcpfdest, '', true) : (!!detalheNotaFiscal.ntf_cnpjcpfdest ? MaskFormat(detalheNotaFiscal.ntf_cnpjcpfdest, '', true) : 'NÃO INFORMADO'))}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Status">
                            <b>{arrStatus[detalheNotaFiscal.ntf_status]}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Nº">
                            <b>{detalheNotaFiscal.ntf_numero}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="IE">
                            <b>{(!!detalheNotaFiscal.ntf_iedest ? detalheNotaFiscal.ntf_iedest : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Vendedor">
                            <b>{(!!detalheNotaFiscal.nomeVendedor ? detalheNotaFiscal.nomeVendedor : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Data de Emissão">
                            <b>{!!detalheNotaFiscal.ntf_dataemissao ? moment(detalheNotaFiscal.ntf_dataemissao).format('DD/MM/YYYY') : null}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="E-mail">
                            <b>{(!!detalheNotaFiscal.ntf_emaildest ? detalheNotaFiscal.ntf_emaildest : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Finalidade">
                            <b>{arrFinalidade[detalheNotaFiscal.ntf_finalidade - 1]}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Chave">
                            <b>{(!!detalheNotaFiscal.ntf_chavenfe ? detalheNotaFiscal.ntf_chavenfe : 'NÃO DISPONÍVEL')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Telefone">
                            <b>{!!detalheNotaFiscal.ntf_telefonedest ? FormatFone(detalheNotaFiscal.ntf_telefonedest) : 'NÃO INFORMADO'}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Ambiente">
                            <b>{arrAmbiente[detalheNotaFiscal.ntf_ambiente - 1]}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Operação">
                            <b>{detalheNotaFiscal.ope_descricao}</b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="Endereço">
                            <b>
                                {(!!detalheNotaFiscal.ntf_logradourodest ? detalheNotaFiscal.ntf_logradourodest : ' NÃO INFORMADO ')},
                                {(!!detalheNotaFiscal.ntf_bairrodest ? detalheNotaFiscal.ntf_bairrodest : ' NÃO INFORMADO ')},
                                {(!!detalheNotaFiscal.ntf_municipiodest ? detalheNotaFiscal.ntf_municipiodest : ' NÃO INFORMADO ')},
                                {(!!detalheNotaFiscal.ntf_cepdest ? MaskFormat(detalheNotaFiscal.ntf_cepdest, '00000-000') : ' NÃO INFORMADO')}.
                            </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="Info. Complementar: ">
                            <b>
                                {(!!detalheNotaFiscal.ntf_infcomplementar ? detalheNotaFiscal.ntf_infcomplementar : '')}
                            </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <div className="m-t-8">
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Itens" key="1">
                        <TabDetalheItens itensNF={detalheNotaFiscal.itensNf} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Formas de Pagamento" key="2">
                        <TabDetalheIFormaPagamento formaPagamento={detalheNotaFiscal.formaPgto} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Totalizadores" key="3">
                        <TabDetalheTotalizadores nota={detalheNotaFiscal} />
                    </Tabs.TabPane>
                    {(detalheNotaFiscal.ntf_tiponota == 4 || detalheNotaFiscal.ntf_tiponota == 5) &&<Tabs.TabPane tab="Documentos Referenciados" key="4">
                        <TabNotaReferenciada docsreferenciados={detalheNotaFiscal.docsreferenciados} />
                    </Tabs.TabPane>}
                    {(detalheNotaFiscal.ntf_tiponota == 4 || detalheNotaFiscal.ntf_tiponota == 5) &&<Tabs.TabPane tab="Antecipação/Devolução" key="5">
                        <TabNotaAntecipacaoDevolucao antecipacaoDevolucao={detalheNotaFiscal.antecipacaoDevolucao} />
                    </Tabs.TabPane>}
                </Tabs>
            </div>
        </div>
    );
}