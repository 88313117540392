import React, { useEffect, useState } from "react";
import { Planos } from "../../components";
import { useStateValue } from "../../state";



export default function RenovarPlano() {
    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => {
        
    }, []);

    return (
        <div className="App">
            <Planos />
        </div>
    );
};