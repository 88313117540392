import React, { useEffect, useState } from "react";
import { Button, Table, Tag, Form, Row, Col, notification, Modal } from "antd";
import { CloseOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, SaveOutlined } from "@ant-design/icons";
import moment from 'moment';

import Data from "../../data";
import api from "../../../services/api";
import { FormatNumber } from '../../../ValueObjects';
import InputPreco from "../../inputPreco";

export default function ModalListarParcelas({ exibirModal, fecharModal, parcelas, setParcelas }) {

    useEffect(() => {
        console.log(parcelas)
    }, [parcelas]);

    const [form] = Form.useForm();

    const onCloseModal = () => {
        fecharModal();
        form.resetFields();

    };
    function alterarParcela(record) {
        alterarEstado(record);
    }

    function alterarEstado(record) {
        let parcelaAlt = [...parcelas];
        let a = {};
        a = parcelaAlt.find((prc) => prc.prc_id === record.prc_id);
        a.editando = !a.editando;
        setParcelas(parcelaAlt);
        console.log(parcelaAlt)
    }

    function salvarParcela(record) {
        let parcela = {
            prc_datavencimento: form.getFieldValue([`prc_datavencimento${record.prc_id}`]),
            prc_valor: form.getFieldValue([`prc_valor${record.prc_id}`]),
            prc_id: record.prc_id
        }
        api.put(`Parcela/EditarParcelaPagar`, parcela).then(
            res => {
                notification.success({ message: `Sucesso!`, description: `Parcela alterada com Sucesso!` });
                alterarEstado(record)
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    function estornaParcela(record) {
        if (!!record.ntf_id) {
            notification.warning({ message: 'Aviso', description: 'Parcela vinculada com uma nota fiscal, não é possivel estorna-la!' });
            return false;
        } else {
            Modal.confirm({
                title: 'Estornar?',
                icon: <ExclamationCircleOutlined />,
                content: 'Deseja realmente estornar a parcela?',
                okText: 'Sim',
                cancelText: 'Não',
                centered: true,
                onOk() {
                    api.put(`Parcela/EstornarParcela/${record.prc_id}`).then(
                        res => {
                            notification.success({ message: 'Sucesso', description: 'Parcela Estornada com Sucesso!' });
                        }
                    ).catch(
                        error => {
                            notification.warn({ message: 'Aviso', description: 'Não foi possivel estornar a parcela!' });
                        }
                    ).finally(() => {
                        fecharModal();
                    })
                }
            });
        }
    }

    return (
        <Modal centered
            title="Parcelas"
            visible={exibirModal}
            footer={null}
            width={620}
            onCancel={onCloseModal}
        >
            <div className="tabela">
                <Form form={form}>
                    <Table dataSource={parcelas} columns={[
                        {
                            title: 'Nº',
                            width: 70,
                            render: ({ prc_numeroparcela, saldo }) => (
                                <Tag color={saldo === 0 ? 'green' : 'red'} className="w-100">
                                    <b>{prc_numeroparcela}</b>
                                </Tag>
                            ),
                        },
                        {
                            title: 'Status Parcela',
                            width: 100,
                            render: ({ saldo }) => (
                                <div>
                                    <b>{saldo === 0 ? 'Liquidada' : 'Pendente'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Valor (R$)',
                            align: 'right',
                            render: ({ prc_valor, editando, prc_id, ntf_id }, idx) => (
                                <div>
                                    <InputPreco name={`prc_valor${prc_id}`} initialValue={prc_valor} disabled={ntf_id !== null || !editando} />
                                </div>
                            ),
                        }, {
                            title: 'Data Vencimento',
                            width: 135,
                            render: ({ prc_datavencimento, prc_id, editando }, idx) => (
                                <Row>
                                    <Col span={24}>
                                        <Data name={`prc_datavencimento${prc_id}`} initialValue={moment(prc_datavencimento)} disabled={!editando} />
                                    </Col>
                                </Row>
                            ),
                        }, {
                            title: 'Ações',
                            align: 'right',
                            width: 65,
                            render: (record, { nft_id, saldo }) => (
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    {!record.editando && saldo !== 0 &&
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => alterarParcela(record)} />
                                        </Col>
                                    }
                                    {record.editando &&
                                        <Col>
                                            <Button icon={<SaveOutlined />} onClick={() => salvarParcela(record)} />
                                        </Col>
                                    }
                                    {record.editando &&
                                        <Col>
                                            <Button icon={<CloseOutlined />} onClick={() => alterarEstado(record)} />
                                        </Col>}
                                    {!(!!nft_id) && saldo !== 0 && !record.editando &&
                                        <Col>
                                            <Button icon={<DeleteOutlined />} onClick={() => estornaParcela(record)} />
                                        </Col>

                                    }
                                </Row>

                            )
                        }

                    ]} scroll={{ y: 230 }} pagination={false} />
                </Form>
            </div>
        </Modal>
    );
}