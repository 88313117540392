
import React, { useState } from 'react';
import { ConfigProvider } from 'antd';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Circle from '@uiw/react-color-circle';
import ptBR from 'antd/lib/locale/pt_BR';
import 'moment/locale/pt-br';
import moment from 'moment';

import "./App.less";
import { StateProvider } from './state';
import { reducer } from './state/reducer';
import { isAuthenticated } from './services/auth';
import { Login, AtivarNovoEmail, Home, RecuperarSenha, RecuperarSenhaGerencial, AtivarConta, AnaliseInadimplenciaCliente, NovaEmpresa, AtivarUsuarioPlano, RenovarPlano, Testes, LoginCatalogo } from "./pages";
import HomeCatalogo from './pages/homeCatalogo';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            isAuthenticated() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: "/", state: { from: props.location } }} />
            )
        }
    />
);

function App() {

    moment.locale('pt-br');

    const initialState = {
        userConfig: { size: 'small' },
        ui: { showDrawer: false, showDrawerExtra: false },
        manutencao: { dados: null },
        listaSelectPaginacao: { itens: [] },
        listaTabelaDados: { itens: [] },
        listagem: { filtro: '', ordem: '', outrosFiltros: '' },
        tributacao: { empRegimeTributario: null, empEnquadramentoFiscal: null },
        parametrosEmpresa: { markupProduto: null, par_percjuromesparcelavencida: '', par_diastoleranciacobrancajuro: '' }
    };

    const FormSizeDemo = () => {

        const [componentSize, setComponentSize] = useState('small');

        const [color, setColor] = useState({
            primaryColor: '#0053a6',
        });

        function onColorChange(nextColor) {
            const mergedNextColor = {
                ...color,
                ...nextColor,
            };
            setColor(mergedNextColor);
            ConfigProvider.config({
                theme: mergedNextColor,
            });

        }

        return (
            <ConfigProvider locale={ptBR} componentSize={componentSize}>
                <Circle
                    colors={['#11a3b3', '#0053a6']}
                    color={color.primaryColor}
                    onChange={({ hex }) => {
                        onColorChange({
                            primaryColor: hex,
                        });
                    }}
                />
                <Router>
                    <Switch>
                        <Route exact path="/" component={Login} />
                        <PrivateRoute path="/home" component={Home} />
                        <Route exact path="/ativar-novo-usuario/" component={AtivarConta} />
                        <Route exact path="/recuperar-senha" component={RecuperarSenha} />
                        <Route exact path="/recuperar-senhaGerencial" component={RecuperarSenhaGerencial} />
                        <Route exact path="/ativar-novo-email" component={AtivarNovoEmail} />
                        <Route exact path="/renovarPlano" component={RenovarPlano} />
                        <PrivateRoute exact path="/AnaliseInadimplenciaCliente" component={AnaliseInadimplenciaCliente} />
                        <Route exact path="/nova-empresa" component={NovaEmpresa} />
                        <Route exact path="/ativar-nova-empresa/" component={AtivarUsuarioPlano} />
                        <Route exact path="/teste" component={Testes} />
                        <Route exact path="/login-cliente" component={LoginCatalogo} />
                        <PrivateRoute path="/catalogo" component={HomeCatalogo} />
                    </Switch>
                </Router>
            </ConfigProvider>
        )

    }

    return (
        <StateProvider initialState={initialState} reducer={reducer}>
            <FormSizeDemo />
        </StateProvider>
    );

}

export default App;