import React, { useEffect, useRef, useState } from "react";
import { Layout, Row, Col, Typography, Divider, Radio, Form, Card, Tag, Input, Select, Button, Modal } from "antd";
import { RocketOutlined, ArrowRightOutlined, ShoppingOutlined, EyeOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import ReCAPTCHA from "react-google-recaptcha";

import { ModalContrato, ModalPlano } from "../../components/modals/";
import api from "../../services/api";
import { FormatNumber } from "../../ValueObjects";
import { consultaCNPJ, removerCarcaterEspecial, textoAleatorio, validaForm } from "../../services/funcoes";
import { linkApi } from "../../services/auth";
import { InputCep, InputCpfCnpj, InputPhone } from "../../components";

export default function NovaEmpresa() {

    const [openModal, setOpenModal] = useState(false);
    const [listaPlanos, setListaPlanos] = useState([]);
    const [recursosPlano, setRecursosPlano] = useState([]);
    const [formulario] = Form.useForm();
    const [periodoAssinatura, setPeriodoAssinatura] = useState(1);
    const [planoSelecionado, setPlanoSelecionado] = useState({});
    const [testeGratuito, setTesteGratuito] = useState(false);
    const [urlContrato, setUrlContrato] = useState('');
    const [dadosNovaEmpresa, setDadosNovaEmpresa] = useState({});
    const [informacoes, setInformacoes] = useState({});
    const [openModalContrato, setOpenModalContrato] = useState(false);
    const [codigoSegurancaContrato, setCodigoSegurancaContrato] = useState('');
    const TEST_SITE_KEY = "6LeoNSQcAAAAANE1sxJTZvTYWXi9rzrNH_LuWf9e";
    const [captcha, setCaptcha] = useState('');
    const reCaptchaRef = React.createRef();
    const inputEmail = useRef();
    const [carregando, setCarregando] = useState(false);

    const dadosLocalAtivacao = {
        loc_id: 10086,
        emp_id: 1022,
        pla_id: 1
    };

    const formasPagamento = {
        cartaoCredito: 1,
        boleto: 2
    };

    const [dadosContrato, setDadosContrato] = useState({
        codigoSegurancaContrato: '',
        formaPagamento: formasPagamento.boleto,
        diaPagamento: 10,
        emailEnvioCobranca: '',
        periodoAssinatura: 1,
        cartaoCredito: {
            numero: '',
            nome: '',
            mesValidade: '',
            anoValidade: '',
            cvv: '',
            dadosTitular: {
                cpfCnpj: '',
                endereco: {
                    cep: '',
                    cidade: '',
                    uf: '',
                    logradouro: '',
                    bairro: '',
                    numero: '',
                    complemento: ''
                }
            }
        },
        planoId: null
    });

    const handleChange = (value) => {
        if (value !== null) {
            setCaptcha(value);
        } else {
            setCaptcha('');
        }
    };

    useEffect(() => {
        api.get(`Plano/ListarPlanosAtivos`).then(retornoPlanos => {
            let lista = retornoPlanos.data.items.reverse();
            lista.forEach((item) => {
                item.diferenciais = JSON.parse(item.pla_diferenciais);
            });
            setListaPlanos(lista);
        }).catch(erro => {
            console.log(erro);
        });
    }, []);

    function selecionarPlano(plano, teste) {
        setPlanoSelecionado(plano);
        setTesteGratuito(teste);
        setTimeout(() => inputEmail.current.focus(), [500]);
    };

    function pesquisaEmail() {
        if (!!formulario.getFieldsValue().usu_email) {
            api.get(`Usuario/RetornaUsuarioEmail?email=${formulario.getFieldsValue().usu_email}`).then(retornoPesquisaEmail => {
                if (!!retornoPesquisaEmail.data) {
                    formulario.setFieldsValue({ usu_nome: retornoPesquisaEmail.data.usu_nome, usu_email: retornoPesquisaEmail.data.usu_email, usu_id: retornoPesquisaEmail.data.usu_id });
                }
            }).catch(erro => {
                console.log(erro);
            });
        }
    };

    function pesquisaCNPJ() {
        if (!!formulario.getFieldsValue().loc_cnpj) {
            let cnpj = formulario.getFieldsValue().loc_cnpj.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '');
            api.get(`Local/BuscarLocalCnpj/${cnpj}`).then(
                retornoCnpjLocal => {
                    if (!!retornoCnpjLocal.data || retornoCnpjLocal !== "") {
                        consultaCNPJ(formulario.getFieldsValue().loc_cnpj).then((retorno) => {
                            if (retorno.status === "ERROR") {
                                api.get(`Empresa/BuscarDadosNovaEmpresa?cnpj=${formulario.getFieldsValue().loc_cnpj}`).then(retornoCnpj => {
                                    if (!!retornoCnpj.data) {
                                        formulario.setFieldsValue({
                                            loc_inscricaoestadual: retornoCnpj.data.pej_inscricaoestadual,
                                            loc_telefone: retornoCnpj.data.psc_numero,
                                            emp_razaosocial: retornoCnpj.data.pes_nome,
                                            emp_nomefantasia: retornoCnpj.data.pej_nomefantasia,
                                            cep_cep: retornoCnpj.data.cep_cep,
                                            log_logradouro: retornoCnpj.data.log_logradouro,
                                            cidade: retornoCnpj.data.cid_descricao + " / " + retornoCnpj.data.est_sigla,
                                            loc_numeroestabelecimento: retornoCnpj.data.pee_numero,
                                            bai_nome: retornoCnpj.data.bai_nome,
                                            loc_complemento: retornoCnpj.data.pee_complemento
                                        });
                                        pesquisaInformacoesCep();
                                    }
                                }).catch(erro => {
                                    console.log(erro);
                                });
                            } else {
                                formulario.setFieldsValue({
                                    loc_inscricaoestadual: "",
                                    loc_telefone: retorno.telefone.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', ''),
                                    emp_razaosocial: retorno.nome,
                                    emp_nomefantasia: retorno.fantasia,
                                    cep_cep: retorno.cep.replace('.', '').replace('-', ''),
                                    cidade: retorno.municipio + " / " + retorno.uf,
                                    loc_numeroestabelecimento: retorno.numero,
                                    bai_nome: retorno.bairro,
                                    loc_complemento: retorno.complemento,
                                    log_logradouro: retorno.logradouro
                                });
                                pesquisaInformacoesCep();
                            }
                        }, (erro) => {
                            console.log('ERRO:', erro);
                        });
                    } else {
                        Modal.warning({
                            title: 'Você já é nosso cliente!',
                            content: "Caso não lembre sua senha utilize a opção de 'Esqueci a Senha'",
                            onOk() {
                                window.location = "/";
                            }
                        });
                    }
                }).catch(erro => {
                    console.log(erro);
                });
        }
    };

    function pesquisaInformacoesCep() {
        if (!!formulario.getFieldValue().cep_cep) {
            let cep = formulario.getFieldValue().cep_cep.replace('-', '');
            api.get(`Cidade/BuscaCidadeCep?cep=${cep}`).then(retornoCep => {
                if (!!retornoCep.data[0]) {
                    formulario.setFieldsValue({
                        cid_id: retornoCep.data[0].cid_id,
                        cep: retornoCep.data[0],
                        cep_cep: retornoCep.data[0].cep_cep,
                        cidade: retornoCep.data[0].cid_descricao + " / " + retornoCep.data[0].est_sigla,
                        siglaEstado: retornoCep.data[0].est_sigla
                    });
                } else {
                    pesquisaCepPostmon(cep, false);
                }
            }).catch(erro => {
                console.log(erro);
            });
        }
    };

    function pesquisaCepPostmon(cep, possuiCep) {
        fetch(`https://api.postmon.com.br/v1/cep/${cep}`).then(
            res => res.json()
        ).then(
            retornoCep => {
                if (!possuiCep) {
                    formulario.setFieldsValue({
                        cep: {},
                        cep_cep: retornoCep.cep,
                        cidade: retornoCep.cidade,
                        siglaEstado: retornoCep.estado
                    });
                }
            }, erro => {
                Modal.warning({
                    content: `Cep (${cep}) não encontrado!`,
                });
                formulario.setFieldsValue({
                    cep_cep: '',
                    cidade: ''
                });
            }
        );
    };

    function salvar(values) {
        if (!(!!values.loc_inscricaoestadual)) {
            values.loc_inscricaoestadual = "ISENTO";
        }
        let novaEmpresa = {};
        novaEmpresa.usu_email = values.usu_email;
        novaEmpresa.usu_nome = values.usu_nome;
        novaEmpresa.usu_id = values.usu_id;
        novaEmpresa.loc_cnpj = values.loc_cnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
        novaEmpresa.loc_inscricaoestadual = values.loc_inscricaoestadual;
        novaEmpresa.loc_telefone = values.loc_telefone.replaceAll('(', '').replaceAll(')', '').replaceAll('-', '').replaceAll(' ', '');
        novaEmpresa.emp_razaosocial = values.emp_razaosocial;
        novaEmpresa.emp_nomefantasia = values.emp_nomefantasia;
        novaEmpresa.cep_cep = values.cep_cep;
        novaEmpresa.est_sigla = values.cep.est_sigla;
        novaEmpresa.cid_descricao = values.cep.cid_descricao;
        novaEmpresa.log_logradouro = values.log_logradouro;
        novaEmpresa.logradouro = values.endereco;
        novaEmpresa.bai_nome = values.bai_nome;
        novaEmpresa.loc_numeroestabelecimento = values.loc_numeroestabelecimento;
        novaEmpresa.loc_complemento = values.loc_complemento;
        novaEmpresa.emp_regimetributario = values.emp_regimetributario;
        novaEmpresa.enquadramentoFiscal = 3;
        novaEmpresa.loc_tipoContribuinte = values.loc_tipoContribuinte;
        novaEmpresa.loc_cpfresponsavel = values.loc_cpfresponsavel.replaceAll('.', '').replaceAll('-', '');
        novaEmpresa.planoSelecionado = {};
        novaEmpresa.planoSelecionado.plano = planoSelecionado;
        novaEmpresa.pla_id = planoSelecionado.pla_id;
        novaEmpresa.pla_numeropdvs = planoSelecionado.pla_numeropdvs;
        novaEmpresa.planoSelecionado.testeGratuito = testeGratuito;
        setDadosNovaEmpresa(novaEmpresa);
        setCarregando(true);
        if (testeGratuito) {
            let dados = { ...dadosContrato };
            dados.codigoSegurancaContrato = textoAleatorio(4);
            dados.periodoAssinatura = periodoAssinatura;
            setCodigoSegurancaContrato(dados.codigoSegurancaContrato);
            setUrlContrato(`${linkApi}Contrato/gerar?codigoaceite=${dados.codigoSegurancaContrato}&localid=${dadosLocalAtivacao.loc_id}&planoId=${planoSelecionado.pla_id}&periodoAssinatura=${periodoAssinatura}&cnpj=${values.loc_cnpj}`);
            setDadosContrato(dados);
            setOpenModalContrato(true);
        } else {
            cadastrarEmpresa(novaEmpresa);
        }
    };

    function cadastrarEmpresa(novaEmpresa = dadosNovaEmpresa) {
        novaEmpresa.captcha = captcha;
        api.post(`Empresa/Incluir`, novaEmpresa).then((retornoNovaEmpresa) => {
            if (retornoNovaEmpresa.status === 200) {
                let informacoes = {};
                informacoes.nome = novaEmpresa.usu_nome;
                informacoes.email = novaEmpresa.usu_email;
                informacoes.chave = retornoNovaEmpresa.data.chave;
                informacoes.loc_id = retornoNovaEmpresa.data.loc_id;
                informacoes.emp_id = retornoNovaEmpresa.data.emp_id;
                informacoes.lcp_id = retornoNovaEmpresa.data.lcp_id;
                informacoes.pla_id = planoSelecionado.pla_id;
                informacoes.periodoAssinatura = periodoAssinatura;
                setInformacoes(informacoes);

                if (testeGratuito) {
                    api.post(`EnviaEmail/EnviaEmailAtivacaoEmpresa`, informacoes).then((retornoEmail) => {
                        if (!!retornoEmail) {
                            Modal.success({
                                title: 'Quase tudo pronto!',
                                content: `Acesse o e-mail cadastrado (${novaEmpresa.usu_email}) para ativar sua conta e definir sua senha de acesso.`,
                                onOk() {
                                    window.location = "/";
                                }
                            });
                        }
                    });
                } else {
                    let dados = { ...dadosContrato };
                    dados.codigoSegurancaContrato = textoAleatorio(4);
                    dados.periodoAssinatura = periodoAssinatura;
                    setCodigoSegurancaContrato(dados.codigoSegurancaContrato);
                    setUrlContrato(`${linkApi}Contrato/Gerar?codigoAceitacao=${dados.codigoSegurancaContrato}&localId=${informacoes.loc_id}&planoId=${informacoes.pla_id}&periodoAssinatura=${periodoAssinatura}&cnpj=${novaEmpresa.loc_cnpj}`);
                    dados.emailEnvioCobranca = informacoes.email;
                    dados.planoId = informacoes.pla_id;
                    setDadosContrato(dados);
                    setOpenModalContrato(true);
                }
            }
        }).catch(err => {
            Modal.error({
                title: 'Não foi possível incluir a empresa!',
                content: (
                    <div>{err.response.data.Message}</div>
                ),
                onOk() {
                    setOpenModalContrato(false);
                }
            });
        }).finally(() => {
            setCarregando(false);
        });
    };

    useEffect(() => {
        if (!openModalContrato) {
            setCarregando(false);
        }
    }, [openModalContrato]);

    function assinarContrato(periodoTeste = false) {
        dadosContrato.periodoTeste = periodoTeste;
        dadosContrato.localId = informacoes.loc_id;
        api.post(`Assinatura/AssinarPlano`, dadosContrato).then(
            (retornoAssinatura) => {
                if (retornoAssinatura.status === 200) {
                    api.post(`EnviaEmail/EnviaEmailAtivacaoEmpresa`, informacoes).then(
                        (retornoEmail) => {
                            if (retornoEmail.status === 200) {
                                Modal.success({
                                    title: 'Quase tudo pronto!',
                                    content: `Acesse o e-mail cadastrado ('${informacoes.email}') para ativar sua conta e definir sua senha de acesso.`,
                                    onOk() {
                                        window.location = "/";
                                    }
                                });
                            } else {
                                console.log('Erro envio Email:', retornoEmail)
                            }
                        }).catch(
                            err => {
                                Modal.error({
                                    title: 'Aviso!',
                                    content: (
                                        <>
                                            <div>Não foi enviar e-mail de ativação!</div>
                                        </>
                                    ),
                                });
                            }
                        );
                } else {
                    console.log('Erro EnviaEmailAtivacaoEmpresa:', retornoAssinatura)
                    Modal.success({
                        title: 'Assinatura realizada com sucesso!',
                        onOk() {
                            window.location = "/";
                        }
                    });
                }
            }
        ).catch(
            err => {
                Modal.error({
                    title: 'Aviso!',
                    content: (
                        <>
                            <div>Não foi possível realizar a assinatura!</div>
                        </>
                    ),
                });
            }
        );
    };

    function validarRazaoSocial() {
        let razao = formulario.getFieldValue().emp_razaosocial;
        razao = removerCarcaterEspecial(razao);
        formulario.setFieldsValue({ emp_razaosocial: razao });
    };

    function fecharModalContrato() {
        assinarContrato(true);
        setOpenModalContrato(false);
    };

    return (
        <Layout className="vh100">
            <Layout.Content className="col-empresa">
                <Form layout="vertical" form={formulario} onFinish={salvar}>
                    <Row justify="center" className="header-fixed">
                        <Col>
                            <img src={require("../../assets/logoGestor.png").default} width="200" alt="Gestor Web" />
                        </Col>
                    </Row>
                    <Row justify="center" className="p-t-90">
                        <Col span={22}>
                            {JSON.stringify(planoSelecionado) === "{}" &&
                                <div>
                                    <Row align="middle">
                                        <Col span={24}>
                                            <Typography.Title level={3}>
                                                <img src={require("../../assets/i-grade.png").default} alt="Planos" /> Planos
                                            </Typography.Title>
                                            <Divider orientation="left" plain> Abaixo selecione o plano mais adequado às necessidades de sua empresa. </Divider>
                                        </Col>
                                    </Row>
                                    <Row justify="center" gutter={[0, 8]}>
                                        <Col span={24} align="center">
                                            Selecione o Período da Assinatura
                                        </Col>
                                        <Col>
                                            <Form.Item name="assinatura" initialValue={1}>
                                                <Radio.Group onChange={(e) => { setPeriodoAssinatura(e.target.value) }} size="large" optionType="button" buttonStyle="solid" defaultValue={1} options={[{ label: 'Mensal', value: 1 }, { label: 'Anual (-10%)', value: 2 }]} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <div className="m-t-16 planos">
                                        <Row align="middle" justify="center" gutter={[16, 16]}>
                                            {listaPlanos.map((plano) => (
                                                <Col xs={8} sm={8} md={8} lg={8} xl={4}>
                                                    <Card hoverable>
                                                        <Row align="middle" justify="center">
                                                            <Col>
                                                                <div className="titlePlano">
                                                                    <Typography.Title level={2} className="m-b-0">
                                                                        {plano.pla_nome}
                                                                    </Typography.Title>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row align="bottom" justify="center" gutter={[5, 0]}>
                                                            <Col>
                                                                <Typography.Text>
                                                                    R$
                                                                </Typography.Text>
                                                            </Col>
                                                            <Col>
                                                                <Typography.Title level={2} className="m-b-0">
                                                                    {periodoAssinatura === 1 ? FormatNumber(plano.pla_valor, true, false) : FormatNumber(((plano.pla_valor - (plano.pla_valor * 0.1)) * 12), true, false)}
                                                                </Typography.Title>
                                                            </Col>
                                                            <Col span={24} className="text-center">
                                                                {periodoAssinatura === 1 ? 'ao Mês' : 'ao Ano'} |  Por CNPJ
                                                            </Col>
                                                        </Row>
                                                        <Row align="middle" justify="center" gutter={[0, 8]} className="m-t-8">
                                                            <Col span={24}>
                                                                <Button type="link" icon={<ShoppingOutlined />} block onClick={() => { selecionarPlano(plano, true) }}>
                                                                    Teste Grátis
                                                                </Button>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Button type="primary" disabled={plano.planoUsuario} size="middle" icon={<ShoppingOutlined />} block onClick={() => { selecionarPlano(plano, false) }} >
                                                                    Adquirir Agora
                                                                </Button>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Button size="middle" icon={<EyeOutlined />} block onClick={() => {
                                                                    setRecursosPlano(plano);
                                                                    setOpenModal(true);
                                                                }}>
                                                                    Ver Comparativo
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                    <ModalPlano exibirModal={openModal} fecharModal={() => setOpenModal(false)} recursosPlano={recursosPlano} />
                                </div>
                            }
                            {JSON.stringify(planoSelecionado) !== "{}" &&
                                <div>
                                    <div className="m-t-16">
                                        <Row justify="begin">
                                            <Col>
                                                <Button type="primary" size="small" onClick={() => setPlanoSelecionado({})}>
                                                    <ArrowLeftOutlined />Selecionar outro plano
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row align="middle" justify="center" className="m-t-16">
                                            <Col span={24}>
                                                <Card>
                                                    <Row align="middle" justify="center" gutter={[8, 0]}>
                                                        <Col>
                                                            <Typography.Text>
                                                                Você escolheu o Plano:
                                                            </Typography.Text>
                                                        </Col>
                                                        <Col>
                                                            <div className="titlePlano">
                                                                <Typography.Title level={2}>
                                                                    {planoSelecionado.pla_nome}
                                                                </Typography.Title>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {testeGratuito && <Row align="middle" justify="center" gutter={[8, 0]} className="m-t-8">
                                                        <Col>
                                                            <Tag color="cyan">
                                                                <RocketOutlined /> Teste gratuito de 15 dias
                                                            </Tag>
                                                        </Col>
                                                    </Row>}
                                                    <Row align="middle" justify="center" className="m-t-8" gutter={[8, 0]}>
                                                        <Col>
                                                            <Typography.Text>
                                                                R$
                                                            </Typography.Text>
                                                        </Col>
                                                        <Col>
                                                            <Typography.Title level={2}>
                                                                {periodoAssinatura === 1 ? FormatNumber(planoSelecionado.pla_valor, true, false) : FormatNumber(((planoSelecionado.pla_valor - (planoSelecionado.pla_valor * 0.1)) * 12), true, false)}
                                                            </Typography.Title>
                                                        </Col>
                                                        <Col>
                                                            <small>{periodoAssinatura === 1 ? 'ao Mês' : 'ao Ano'}</small>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="m-t-16">
                                        <Form.Item name="siglaEstado" hidden></Form.Item>
                                        <Row align="middle">
                                            <Col span={24}>
                                                <Typography.Title level={3}>
                                                    <img src={require("../../assets/i-user.png").default} alt="Usuário" /> Usuário
                                                </Typography.Title>
                                                <Divider orientation="left" plain> Informe abaixo os dados do Usuário. </Divider>
                                            </Col>
                                        </Row>
                                        <Row justify="center" gutter={[8, 0]}>
                                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                <Form.Item label="E-mail" name="usu_email" rules={[{ required: true }]}>
                                                    <Input ref={inputEmail} placeholder="Informe seu E-mail" onBlur={() => pesquisaEmail()} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                                <Form.Item name="usu_id" hidden></Form.Item>
                                                <Form.Item name="cid_id" hidden></Form.Item>
                                                <Form.Item name="cep" hidden></Form.Item>
                                                <Form.Item label="Nome" name="usu_nome" rules={[{ required: true }]}>
                                                    <Input placeholder="Informe seu Nome" />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="m-t-16">
                                        <Row align="middle">
                                            <Col span={24}>
                                                <Typography.Title level={3}>
                                                    <img src={require("../../assets/i-banco.png").default} alt="Empresa" /> Empresa
                                                </Typography.Title>
                                                <Divider orientation="left" plain> Informe abaixo os dados do Empresa. </Divider>
                                            </Col>
                                        </Row>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={20} sm={6} md={4} lg={4} xl={4}>
                                                <InputCpfCnpj label="CNPJ" name="loc_cnpj" rules={[{ required: true }]} onBlur={() => pesquisaCNPJ()} />
                                            </Col>
                                            <Col xs={20} sm={6} md={4} lg={4} xl={4}>
                                                <Form.Item label="Inscrição Estadual" name="loc_inscricaoestadual">
                                                    <Input placeholder="Informe a Inscrição Estadual" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={20} sm={6} md={4} lg={4} xl={4}>
                                                <InputCpfCnpj cpf label="CPF do Responsável" name="loc_cpfresponsavel" rules={[{ required: true }]} placeholder="Informe o CPF do Responsável" />
                                            </Col>
                                            <Col xs={20} sm={6} md={4} lg={4} xl={4}>
                                                <InputPhone label="Telefone" name="loc_telefone" rules={[{ required: true }]} />
                                            </Col>
                                            <Col xs={20} sm={6} md={4} lg={4} xl={4}>
                                                <InputPhone label="Celular" name="loc_celular" />
                                            </Col>
                                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                                <Form.Item label="Razão Social" name="emp_razaosocial" rules={[{ required: true }]}>
                                                    <Input placeholder="Informe a Razão Social" onBlur={() => { validarRazaoSocial() }} />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                                <Form.Item label="Nome Fantasia" name="emp_nomefantasia" rules={[{ required: true }]}>
                                                    <   Input placeholder="Informe a Nome Fantasia" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                                <Form.Item label="Regime Tributário" name="emp_regimetributario" rules={[{ required: true }]}>
                                                    <Select placeholder="Regime Tributário de sua Empresa">
                                                        <Select.Option value={1} key={1}>Simples Nacional</Select.Option>
                                                        <Select.Option value={2} key={2}>Simples Nacional - Excesso de Sublimite</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <small>* No momento estamos atendento apenas empresas do Simples Nacional.</small>
                                            </Col>
                                            <Col span={24} className="m-t-8">
                                                <Form.Item label="Ramo de atividade principal" name="loc_tipoContribuinte" rules={[{ required: true }]}>
                                                    <Select placeholder="Selecione o Ramo de Atividade Principal">
                                                        <Select.Option value={1} key={1}>Comércio</Select.Option>
                                                        <Select.Option value={2} key={2}>Indústria</Select.Option>
                                                        <Select.Option value={3} key={3}>Equiparado a Indústria</Select.Option>
                                                        <Select.Option value={4} key={4}>Serviços</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={17} md={6} lg={6} xl={6}>
                                                <InputCep label="CEP" name="cep_cep" rules={[{ required: true, message: 'Informe o CEP' }]} onBlur={() => pesquisaInformacoesCep('', true)} />
                                            </Col>
                                            <Col xs={24} sm={7} md={3} lg={3} xl={3}>
                                                <Form.Item className="text-center m-t-19">
                                                    <Typography.Link href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="_black" underline>
                                                        Não sei o CEP
                                                    </Typography.Link>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                                                <Form.Item label="Cidade" name="cidade">
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={19} lg={19} xl={19}>
                                                <Form.Item label="Endereço" name="log_logradouro" rules={[{ required: true, message: 'Informe o Endereço' }]}>
                                                    <Input placeholder="Informe o Endereço" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={5} lg={5} xl={5}>
                                                <Form.Item label="Número" name="loc_numeroestabelecimento" rules={[{ required: true }]}>
                                                    <Input placeholder="Informe o Número" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={4} xl={4}>
                                                <Form.Item label="Complemento" name="loc_complemento">
                                                    <Input placeholder="Informe o Complemento" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={4} xl={4}>
                                                <Form.Item label="Bairro" name="bai_nome" rules={[{ required: true }]} >
                                                    <Input placeholder="Informe o Bairro" /*onChange={() => { pesquisaBairro() }} */ />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="m-t-16">
                                        <Row align="middle">
                                            <Col span={24}>
                                                <Typography.Title level={3}>
                                                    <img src={require("../../assets/i-financeiro.png").default} alt="Cobrança/Faturamento" /> Cobrança/Faturamento
                                                </Typography.Title>
                                                <Divider orientation="left" plain> Informações referente a cobrança e faturamento de sua assinatura. </Divider>
                                            </Col>
                                        </Row>
                                        <Typography.Title level={4}>
                                            <Row className="m-t-16 text-center">
                                                {testeGratuito ?
                                                    <Col span={24}>
                                                        <b>Não existe cobrança para o periodo de testes de 15 dias.</b>
                                                        <p style={{ margin: 0 }}>Se você assinar o plano após o período de teste, a cobrança será via <b>Boleto Bancário com vencimento dia 10</b> de cada mês.</p>
                                                    </Col> :
                                                    <Col span={24}>
                                                        Cobrança via <b>Boleto Bancário com vencimento dia 10</b> de cada mês.
                                                    </Col>
                                                }
                                            </Row>
                                        </Typography.Title>
                                        <Row justify="center" className="m-t-16">
                                            <Col>
                                                <ReCAPTCHA
                                                    ref={reCaptchaRef}
                                                    sitekey={TEST_SITE_KEY}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row justify="end">
                                            <Col>
                                                {testeGratuito ? <Button disabled={!(!!captcha)} type="primary" loading={carregando} size="large" onClick={() => formulario.submit()}>
                                                    Iniciar meu Teste Gratuito <ArrowRightOutlined />
                                                </Button> :
                                                    <Button disabled={!(!!captcha)} type="primary" loading={carregando} size="large" onClick={() => formulario.submit()}>
                                                        Avançar <ArrowRightOutlined />
                                                    </Button>}
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                    <ModalContrato exibirModalContrato={openModalContrato} fecharModalContrato={() => fecharModalContrato()} urlContrato={urlContrato} testeGratuito={testeGratuito} cadastrarEmpresa={cadastrarEmpresa} assinarContrato={assinarContrato} codigoSeguranca={codigoSegurancaContrato} />
                    <div className="footer">
                        <Divider className="m-0" />
                        <Row justify="center" gutter={[24, 8]} className="m-t-16">
                            <Col>
                                <a href="mailto:comercial.gestor@abase.com.br"> comercial.gestor@abase.com.br </a>
                            </Col>
                            <Col>
                                (55) 3535-4922
                            </Col>
                            <Col>
                                <a href="https://www.gestorweb.com.br/" target="_blank"> www.gestorweb.com.br </a>
                            </Col>
                            <Col span={24} align="center">
                                Copyright © Gestor Web
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Layout.Content>
        </Layout>
    );
}