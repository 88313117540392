import React, { useState } from 'react';
import { Row, Col, Button, Form, Modal, Drawer } from "antd";
import { CloseOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';

import ManutencaoContaGerencial from '../contaGerencial/manutencao';

export default function DrawerContaGerencial({ openDrawerContaGerencial, setOpenDrawerContaGerencial, setCodigoContaGerencial }) {
    
    const [formulario] = Form.useForm();
    const [ carregando, setCarregando ] = useState(false);

    function fecharDrawer(idRetorno) {
        if (!!idRetorno){
            setCodigoContaGerencial(idRetorno);
        }
        formulario.resetFields();
        setOpenDrawerContaGerencial(false);
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <Drawer width="60%"
            title="Cadastro de Conta Gerencial"
            visible={openDrawerContaGerencial}
            closable={false}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formulario.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoContaGerencial formulario={formulario} carregando={setCarregando} aoSalvar={fecharDrawer} />
        </Drawer>

    )
};