import React, { useState } from "react";
import { Row, Col, Typography, Form, Input, Checkbox, Modal, notification } from "antd";
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../../services/api";
import { novaAbaNavegador } from "../../../services/funcoes";
import { MaskFormat, FormatNumber } from "../../../ValueObjects";

export default function ModalCartaCorrecaoNotaFiscal({ dadosNota = {}, exibirModal, fecharModal }) {
    const [form] = Form.useForm();
    const [exibeCondicoes, setExibeCondicoes] = useState(true);
    const retornoProcessamento = {
        cancelamentoNfeHomologado: 101,
        eventoRegistradoEVinculadoANfe: 135
    }

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
            }
        });
    };

    const salvarDados = record => {
        let dados = { ...dadosNota };

        dados.textoCorrecao = form.getFieldValue().textoCorrecao;
        if (!!dados.textoCorrecao) {
            if (dados.textoCorrecao.length >= 15) {
                api.post('NotaFiscal/EmitirCartaCorrecao', dados).then(
                    res => {
                        if (!!res.data) {
                            let mensagemCancelamento = res.data.retorno.retEvento[0].infEvento.cStat + "-" + res.data.retorno.retEvento[0].infEvento.xMotivo;
                            if (res.data.retorno.retEvento[0].infEvento.cStat == retornoProcessamento.eventoRegistradoEVinculadoANfe) {
                                if (!!res.data.retorno.retEvento[0].infEvento.chNFe) {
                                    novaAbaNavegador(`Danfe/GerarCce?chaveNFe=${res.data.retorno.retEvento[0].infEvento.chNFe}&sequenciaEvento=${res.data.retorno.retEvento[0].infEvento.nSeqEvento}`);
                                }
                                notification.success({ description: mensagemCancelamento, message: `Carta de correção eletrônica referente a NF-e Nº: ${dadosNota.ntf_numero} vinculada com sucesso !` });
                            } else {
                                notification.warning({ description: mensagemCancelamento, message: `Não foi possível vincular a carta de correção eletrônica da NF-e Nº: ${dadosNota.ntf_numero}, verefique os erros ocorridos!` });
                            }
                        }
                    }
                ).catch(
                    erro => {
                        console.log(erro);
                    }
                ).finally(
                    () => {
                        fecharModal();
                    }
                );
            } else {
                notification.warning({ description: 'O tamanho mínimo da carta de correção é de 15 caracteres', message: 'Aviso' });
            }
        } else {
            notification.warning({ description: 'O tamanho mínimo da carta de correção é de 15 caracteres', message: 'Aviso' });
        }
    };

    return (
        <Modal centered
            title="Carta de Correção Eletrônica de NF-e"
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={salvarDados}
            width={720}
            okText={
                <>
                    <CheckOutlined /> Emitir Carta de Correção
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            destroyOnClose
        >
            <div>
                <div>
                    <Row align="middle" justify="space-between" gutter={[16, 0]}>
                        <Col>
                            Chave da NF-e: <b> {dadosNota.ntf_chavenfe} </b>
                        </Col>
                    </Row>
                    <Row align="middle" justify="space-between" gutter={[16, 0]}>
                        <Col>
                            Destinatário: <b> {dadosNota.ntf_nomedest} </b>
                        </Col>
                        <Col>
                            CPF/CNPJ: <b>{MaskFormat(dadosNota.ntf_cnpjcpfdest, '', true)}</b>
                        </Col>
                    </Row>
                    <Row align="middle" justify="space-between" gutter={[16, 0]}>
                        <Col>
                            Modelo: <b>{dadosNota.ntf_modelo}</b>
                        </Col>
                        <Col>
                            Emitida em: <b>{moment(dadosNota.ntf_dataemissao).format('DD/MM/YYYY HH:mm:ss')}</b>
                        </Col>
                    </Row>
                    <Row align="middle" justify="space-between" gutter={[16, 0]}>
                        <Col>
                            Tipo de Nota: <b>{dadosNota.tip_descricao}</b>
                        </Col>
                        <Col>
                            Valor total da Nota (R$): <b>{FormatNumber(dadosNota.valor_total, true)} </b>
                        </Col>
                    </Row>
                </div>
                <div className="m-t-8">
                    <Form layout="vertical" name="formularioCartaCorrecao" form={form}>
                        <Row align="middle" gutter={[8, 0]}>
                            <Col span={24}>
                                <Form.Item label="Texto explicativo da correção eletrônica" name="textoCorrecao">
                                    <Input.TextArea placeholder="Informe aqui o texto explicativo da carta de correção eletrônica da Nf-e" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={24}>
                                <Form.Item name="apresentaTermos" valuePropName="checked" initialValue={true}>
                                    <Checkbox onChange={() => { setExibeCondicoes(!exibeCondicoes) }}> Visualizar Condições de uso da Carta de Correção </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {exibeCondicoes && <div className="m-t-8">
                    <Typography.Text>
                        A Carta de Correção é disciplinada pelo § 1º-A do art. 7º do Convênio S/ N, de 15 de dezembro de 1970 e pode ser utilizada para regularização de erro ocorrido na emissão de documento fiscal, desde que o erro não esteja relacionado com:
                        <br /><br />
                        I - as variáveis que determinam o valor do imposto tais como: base de cálculo, alíquota, diferença de preço, quantidade,
                        valor da operação ou da prestação; <br />
                        II - a correção de dados cadastrais que implique mudança do remetente ou do destinatário; <br />
                        III - a data de emissão ou de saída.
                    </Typography.Text>
                </div>}
            </div>
        </Modal>
    );
}