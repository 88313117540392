import React, { useEffect, useState } from "react";
import { Row, Col, Form, Typography, Checkbox, Button, notification, Modal, Input } from "antd";
import { ArrowRightOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment, { min } from "moment";

import api from "../../services/api";
import { Data, FormGW } from "../../components";
import { getLocal } from "../../services/auth";
import CustosInventario from './custosInventario';

export default function ManutencaoEstoqueInventario({ formulario, carregando, aoSalvar, exibirCustos, setExibirCustos }) {

    const [itensGrade, setItensGrade] = useState([]);
    const [possuiGrade, setPossuiGrade] = useState(false);
    const [listaProduto, setListaProduto] = useState([]);
    const [dataHora, setDataHora] = useState([]);

    useEffect(() => {
        carregarData();
    }, []);

    function carregarData() {
        let dataAtual = moment(new Date());
        formulario.setFieldsValue({ pre_dataposicao: dataAtual });
        let hora = dataAtual.hours();
        let minuto = dataAtual.minutes();
        if (hora < 10) hora = '0' + hora;
        if (minuto < 10) minuto = '0' + minuto;
        formulario.setFieldsValue({ pre_hora: hora + ':' + minuto });
        let teste = formulario.getFieldValue().pre_hora;
        setDataHora(hora + ':' + minuto);
    };

    function modalConfirmacao(values) {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: `Caso já existam registros com data posterior a data ${moment(formulario.getFieldValue().pre_dataposicao).format('DD/MM/YYYY')} eles serão excluídos, deseja continuar?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                salvarManutencao(values);
            }
        });
    };

    function changeExibirCustos() {
        if (!!formulario.getFieldValue().customedio || !!formulario.getFieldValue().custocomicms
            || !!formulario.getFieldValue().custosemicms || !!formulario.getFieldValue().custoultimacompra) {
            if (!!formulario.getFieldValue().pre_dataposicao) {
                setExibirCustos(!exibirCustos);
            } else {
                notification.warning({ description: `Informe a data da posição do inventário`, message: 'Aviso' })
            }
        } else {
            notification.warning({ description: 'Selecione pelo menos um custo para ser informado no inventário!', message: 'Aviso' })
        }
    };

    function validarValoresInformados(valores) {
        let retorno = true;
        let campos = [];
        if (!!formulario.getFieldValue().customedio && !!!valores.pre_valorcustomedio) {
            retorno = false;
            campos.push("Custo Médio");
        }
        if (!!formulario.getFieldValue().custocomicms && !!!valores.pre_custocomicms) {
            retorno = false;
            campos.push("Custo com Icms");
        }
        if (!!formulario.getFieldValue().custosemicms && !!!valores.pre_custosemicms) {
            retorno = false;
            campos.push("Custo sem Icms");
        }
        if (!!formulario.getFieldValue().custoultimacompra && !!!valores.pre_custoultimacompra) {
            retorno = false;
            campos.push("Custo Ultima Compra");
        }
        if (!retorno) {
            notification.error({ description: `Os campos: ${campos.join(",")} devem possuir valores maiores do que R$ 0,00`, message: "Aviso" });
        }
        return retorno;
    };

    function salvarManutencao(valores) {
        valores.pro_id = formulario.getFieldValue().pro_id;
        let teste = formulario.getFieldValue().pre_hora;
        let dados = {};
        let dadosItens = {};
        let listaInventario = [];
        let dataHoraPosicao = moment(formulario.getFieldValue().pre_dataposicao);
        let horas = '00';
        let minutos = '00';
        if (!!moment(formulario.getFieldValue().pre_hora) || !!moment(dataHora) ) {
            horas = (!!formulario.getFieldValue().pre_hora ? formulario.getFieldValue().pre_hora : dataHora).substring(0, 2);
            minutos = (!!formulario.getFieldValue().pre_hora ? formulario.getFieldValue().pre_hora : dataHora).substring(3, 5);
        }
        dataHoraPosicao.set("hour", parseInt(horas)); // Setando a hora
        dataHoraPosicao.set("minute", parseInt(minutos)); // Setando os minutos
        
        valores.pre_dataposicao = dataHoraPosicao;

        if (!!valores.pro_id) {
            carregando(true);
            let form = formulario.getFieldValue();
            if (!validarValoresInformados(valores)) {
                carregando(false);
                return;
            }
            if (itensGrade.length > 0) {
                itensGrade.forEach((item) => {
                    dadosItens = {};
                    dadosItens.pre_valorcustomedio = !!form[item.prg_id + 'gradeCustoMedio'] ? parseFloat(form[item.prg_id + 'gradeCustoMedio']) : null;
                    dadosItens.pre_custocomicms = !!form[item.prg_id + 'gradeCustoComIcms'] ? parseFloat(form[item.prg_id + 'gradeCustoComIcms']) : null;
                    dadosItens.pre_custosemicms = !!form[item.prg_id + 'gradeCustoSemIcms'] ? parseFloat(form[item.prg_id + 'gradeCustoSemIcms']) : null;
                    dadosItens.pre_custoultimacompra = !!form[item.prg_id + 'gradeCustoUltimaCompra'] ? parseFloat(form[item.prg_id + 'gradeCustoUltimaCompra']) : null;
                    dadosItens.pre_qtde = form[item.prg_id + 'pre_qtde'];
                    dadosItens.pre_dataposicao = valores.pre_dataposicao;
                    dadosItens.pre_justificativa = valores.pre_justificativa;
                    dadosItens.pro_id = valores.pro_id;
                    dadosItens.prg_id = item.prg_id;
                    dadosItens.loc_id = parseInt(getLocal());
                    listaInventario.push(dadosItens);
                });
            } else {
                dadosItens.pre_custocomicms = !!form.pre_custocomicms ? parseFloat(form.pre_custocomicms) : null;
                dadosItens.pre_valorcustomedio = !!form.pre_valorcustomedio ? parseFloat(form.pre_valorcustomedio) : null;
                dadosItens.pre_custosemicms = !!form.pre_custosemicms ? parseFloat(form.pre_custosemicms) : null;
                dadosItens.pre_custoultimacompra = !!form.pre_custoultimacompra ? parseFloat(form.pre_custoultimacompra) : null;
                dadosItens.pre_dataposicao = valores.pre_dataposicao;
                dadosItens.pre_justificativa = valores.pre_justificativa;
                dadosItens.pro_id = valores.pro_id;
                dadosItens.prg_id = valores.prg_id;
                dadosItens.pre_qtde = valores.pre_qtde;
                listaInventario.push(dadosItens);
            }
            dados.manutencaoInventario = listaInventario;
            console.log("dados", dados);
            api.post(`Inventario/ManutencaoInventario`, dados).then(
                res => {
                    notification.success({ message: 'Aviso', description: 'Inventário adicionado com sucesso!' });
                }
            ).catch(
                erro => {
                    carregando(false);
                    console.log(erro);
                }
            ).finally(
                () => {
                    formulario.resetFields();
                    carregando(false);
                    setPossuiGrade(false);
                    setItensGrade([]);
                    setExibirCustos(false);
                    aoSalvar();
                }
            );
        } else {
            notification.warning({ description: 'Favor preencher o formulario!', message: 'Aviso' });
        }
    };

    return (
        <div>
            <FormGW layout="vertical" form={formulario} name="manutencaoEstoqueInventario" onFinish={modalConfirmacao}>
                {!!!exibirCustos && <div>
                    <Row align="middle" gutter={[8, 0]}>
                        <Col span={24} className="m-b-16">
                            <Typography.Text>
                                Utilize este processo para registrar alterações no estoque de algum produto, isto é, alterar a quantidade já existente e que não condiz com o estoque real.
                            </Typography.Text>
                        </Col>
                        <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                            <Row gutter={[8, 0]}>
                                <Col>
                                    <Form.Item name="customedio" valuePropName="checked" initialValue={false}>
                                        <Checkbox> Custo Médio </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="custocomicms" valuePropName="checked" initialValue={false}>
                                        <Checkbox> Custo Com ICMS </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="custosemicms" valuePropName="checked" initialValue={false}>
                                        <Checkbox> Custo Sem ICMS </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="custoultimacompra" valuePropName="checked" initialValue={false}>
                                        <Checkbox> Custo Última Compra </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={6} lg={4} xl={4} xxl={4}>
                            <Data label="Data da Posição" name="pre_dataposicao" />
                        </Col>
                        <Col xs={24} sm={12} md={5} lg={5} xl={4} xxl={3}>
                            <Form.Item label="Hora" name="pre_hora">
                                <Input type="time" />
                            </Form.Item>
                        </Col>
                        <Col span={24} className="m-t-8">
                            <Row justify="end" gutter={[8, 0]}>
                                <Col xs={24} sm={3} md={3} lg={3} xl={3} xxl={2}>
                                    <Button type="primary" onClick={changeExibirCustos} block>
                                        Avançar <ArrowRightOutlined />
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>}
                <CustosInventario formulario={formulario} exibirCustos={exibirCustos} setExibirCustos={setExibirCustos} itensGrade={{ itensGrade, setItensGrade }} grade={{ possuiGrade, setPossuiGrade }} listaProduto={listaProduto} setListaProduto={setListaProduto} />
            </FormGW>
        </div>
    );

}