import React, { useEffect, useState } from "react";
import { Row, Col, Card, Divider, Progress, Button, Table, Tag } from "antd";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import { FormatNumber } from "../../ValueObjects";
import { isObjetoDiffVazio } from "../../services/funcoes";
import { ModalDetalhePedido, ModalProdutoCondicional, ModalDetalheOrcamento } from "../../components/modals/";

export default function TabEstatistica({ dadosCliente, dataInicial, dataFinal }) {

    const [openModalDetalhePedido, setOpenModalDetalhePedido] = useState(false);
    const [pedidoId, setPedidoId] = useState(null);
    const [openModalDetalheOrcamento, setOpenModalDetalheOrcamento] = useState(false);
    const [openModalProdutoCondicional, setOpenModalProdutoCondicional] = useState(false);
    const [totalParcAtraso, setTotalParcAtraso] = useState({});
    const [resumoFinanceiro, setResumoFinanceiro] = useState({});
    const [dadosCondicionais, setDadosCondicionais] = useState({});
    const [dadosCrediario, setDadosCrediario] = useState({});
    const [dadosPedido, setDadosPedido] = useState({});
    const [dadosPedidosAbertos, setDadosPedidosAbertos] = useState([]);
    const [limiteCredito, setLimiteCredito] = useState({});
    const [dadosOrcamento, setDadosOrcamento] = useState({});
    const [dadosCheque, setDadosCheque] = useState([]);
    const [valorPercentual, setValorPercentual] = useState(0);
    const [openCondicional, setOpenCondicional] = useState(false);
    const [openPedidos, setOpenPedidos] = useState(false);
    const [openOrcamento, setOpenOrcamento] = useState(false);
    const [dadosListaOrcamento, setDadosListaOrcamento] = useState([]);
    const [dadosCondicionaisAbertas, setDadosCondicionaisAbertas] = useState([]);
    const [dadosItensCondicional, setDadosItensCondicional] = useState({});
    const [orcamentoId, setOrcamentoId] = useState(null);

    const viewCondicional = () => {
        setOpenCondicional(!openCondicional)
    };

    const viewPedidos = () => {
        setOpenPedidos(!openPedidos);
    };

    const viewOrcamento = () => {
        setOpenOrcamento(!openOrcamento);
    };

    useEffect(() => {
        if (!!dadosCliente && isObjetoDiffVazio(dadosCliente)) {
            carregarDados();
        }
    }, [dadosCliente]);

    useEffect(() => {
        if (!!dadosCliente && isObjetoDiffVazio(dadosCliente)) {
            carregarDados();
        }
    }, [dataInicial]);

    useEffect(() => {
        if (!!dadosCliente && isObjetoDiffVazio(dadosCliente)) {
            carregarDados();
        }
    }, [dataFinal]);

    function carregarDados() {
        listarLimiteCredito();
        listarTotalParcelasEmAtraso();
        listarResumoFinanceiro();
        listarDadosCondicionais();
        listarDadosCondicionaisAbertas();
        listarDadosPedido();
        listarDadosPedidoAberto();
        listarDadosOrcamento();
        listarDadosListaOrcamento();
        listarDadosCheque();
        listarParcelasAReceber();
    };

    function listarLimiteCredito() {
        api.get(`Cliente/LimiteCreditoCMR?IdPessoa=${dadosCliente.pes_id}`).then(
            res => {
                let dados = res.data;
                let perc = parseFloat(((dados.vlr_ParcelasAbertas * 100) / dados.vlr_LimiteCredito).toFixed(2));
                if (perc > 100) {
                    setValorPercentual(100);
                } else {
                    setValorPercentual(perc);
                }
                setLimiteCredito(dados);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function listarParcelasAReceber() {
        api.get(`ContasPagarReceber/CRMParcelasAReceber?IdPessoa=${dadosCliente.pes_id}&dataFinal=${moment(dataFinal).toISOString()}&dataInicial=${moment(dataInicial).toISOString()}`).then(
            res => {
                setDadosCrediario(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function listarTotalParcelasEmAtraso() {
        api.get(`Parcela/TotalParcelasEmAtraso?IdPessoa=${dadosCliente.pes_id}`).then(
            res => {
                setTotalParcAtraso(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function listarResumoFinanceiro() {
        api.get(`Pessoa/ResumoFinanceiroCRM?IdPessoa=${dadosCliente.pes_id}&dataFinal=${moment(dataFinal).toISOString()}&dataInicial=${moment(dataInicial).toISOString()}`).then(
            res => {
                setResumoFinanceiro(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function listarDadosCondicionais() {
        api.get(`VendaCondicional/RetornaDadosCondicionaisCRM?IdPessoa=${dadosCliente.pes_id}&dataFinal=${moment(dataFinal).toISOString()}&dataInicial=${moment(dataInicial).toISOString()}`).then(
            res => {
                setDadosCondicionais(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function listarDadosCondicionaisAbertas() {
        api.get(`VendaCondicional/RetornaDadosCondicionaisAberto?IdCliente=${dadosCliente.cli_id}&dataFinal=${moment(dataFinal).toISOString()}&dataInicial=${moment(dataInicial).toISOString()}`).then(
            res => {
                setDadosCondicionaisAbertas(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function listarDadosPedido() {
        api.get(`Pedido/RetornaDadosPedidoCRM?IdPessoa=${dadosCliente.pes_id}&dataFinal=${moment(dataFinal).toISOString()}&dataInicial=${moment(dataInicial).toISOString()}`).then(
            res => {
                setDadosPedido(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function listarDadosPedidoAberto() {
        api.get(`Pedido/RetornaDadosPedidosAberto?IdCliente=${dadosCliente.cli_id}&dataFinal=${moment(dataFinal).toISOString()}&dataInicial=${moment(dataInicial).toISOString()}`).then(
            res => {
                setDadosPedidosAbertos(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };
    // não implementado ainda no novo back end
    function listarDadosOrcamento() {
        // api.get(`Orcamento/RetornaDadosOrcamentoCRM?IdPessoa=${dadosCliente.pes_id}&dataFinal=${moment(dataFinal).toISOString()}&dataInicial=${moment(dataInicial).toISOString()}`).then(
        //     res => {
        //         setDadosOrcamento(res.data);
        //     }
        // ).catch(
        //     erro => {
        //         console.log(erro);
        //     }
        // );
    };

    function listarDadosListaOrcamento() {
        // api.get(`Orcamento/ListaOrcamentoCrmDTO?IdPessoa=${dadosCliente.cli_id}&dataFinal=${moment(dataFinal).toISOString()}&dataInicial=${moment(dataInicial).toISOString()}`).then(
        //     res => {
        //         setDadosListaOrcamento(res.data);
        //     }
        // ).catch(
        //     erro => {
        //         console.log(erro);
        //     }
        // );
    };

    function listarDadosCheque() {
        api.get(`Cheque/RetornaDadosChequeCRM?CodigoPessoa=${dadosCliente.pes_id}&dataFinal=${moment(dataFinal).toISOString()}&dataInicial=${moment(dataInicial).toISOString()}`).then(
            res => {
                setDadosCheque(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function abrirModalItens(record) {
        setDadosItensCondicional(record)
        setOpenModalProdutoCondicional(true)
    }

    return (
        <div>
            <Row align="middle" gutter={[16, 16]} className="min-hg">
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Card size="default" title="Limite de Crédito">
                        <Progress percent={valorPercentual} />
                        <Row>
                            <Col>
                                <small>
                                    * Limite de Crédito Excedido. Valor Limite (R$):
                                    <b>{isObjetoDiffVazio(limiteCredito) ? FormatNumber(limiteCredito.vlr_LimiteCredito, true) : FormatNumber(0, true)}</b>
                                </small>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Card size="default" title="Valores a receber em Atraso">
                        <Row justify="center">
                            <Col>
                                <b className="c-red f-30">{!!totalParcAtraso ? FormatNumber(totalParcAtraso.vlr_ParcelasEmAtraso, true) : FormatNumber(0, true)}</b>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="m-t-16">
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Card size="default" title="Crediário">
                        <Row justify="center">
                            <Col>
                                <span className="f-14">Média de Atraso</span>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col>
                                <b className="c-red f-30">{isObjetoDiffVazio(dadosCrediario) ? dadosCrediario.mediaDiasAtraso : 0} dias</b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={16}>
                                Total de parcelas:
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(dadosCrediario) ? dadosCrediario.total_Parcelas : 0}</b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Divider className="m-0" />
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={16}>
                                Total de parcelas em atraso:
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(dadosCrediario) ? dadosCrediario.total_Parcelas_Atraso : 0}</b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Divider className="m-0" />
                            </Col>
                        </Row>
                        <Row align="middle">
                            <Col span={16}>
                                Total de parcelas adiantadas:
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(dadosCrediario) ? dadosCrediario.total_Parcelas_Adiantadas : 0}</b>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Card size="default" title="Resumo Financeiro">
                        <Row justify="center">
                            <Col>
                                <span className="f-14">Valor Comprado</span>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col>
                                <b className="c-green f-30">{isObjetoDiffVazio(resumoFinanceiro) ? FormatNumber(resumoFinanceiro.vlr_TotalVendas, true) : FormatNumber(0, true)}</b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={16}>
                                Valor compras a vista (R$):
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(resumoFinanceiro) ? FormatNumber(resumoFinanceiro.vlr_TotalVendasAVista, true) : FormatNumber(0, true)}</b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Divider className="m-0" />
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={16}>
                                Valor compras a prazo (R$):
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(resumoFinanceiro) ? FormatNumber(resumoFinanceiro.vlr_TotalVendasAPrazo, true) : FormatNumber(0, true)}</b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Divider className="m-0" />
                            </Col>
                        </Row>
                        <Row align="middle">
                            <Col span={16}>
                                Valor a receber (R$):
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(resumoFinanceiro) ? FormatNumber(resumoFinanceiro.vlr_ParcelasAReceber, true) : FormatNumber(0, true)}</b>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="m-t-16">
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Card size="default" title="Condicionais">
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={16}>
                                Percentual de Vendas das Condicionais:
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(dadosCondicionais) ? dadosCondicionais.percentual_Vendas_Condicionais + '%' : '0%'}</b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Divider className="m-0" />
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={16}>
                                Total de Condicionais:
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(dadosCondicionais) ? dadosCondicionais.qtde_Condicionais : 0}</b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Divider className="m-0" />
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={16}>
                                Valor Total de Compras (R$):
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(dadosCondicionais) ? FormatNumber(dadosCondicionais.vlr_Total_Condicionais, true) : FormatNumber(0, true)}</b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Divider className="m-0" />
                            </Col>
                        </Row>
                        <Row align="middle">
                            <Col span={16}>
                                Condicionais em Aberto:
                            </Col>
                            <Col span={8}>
                                <Button type="link" onClick={() => viewCondicional(true)}>
                                    <u>
                                        <b>{isObjetoDiffVazio(dadosCondicionais) ? dadosCondicionais.qtde_Condicionais_Abertos : 0}</b>
                                    </u>
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Card size="default" title="Pedidos">
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={16}>
                                Percentual de Vendas dos Pedidos:
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(dadosPedido) ? dadosPedido.percentual_Vendas_Pedidos + '%' : '0%'}</b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Divider className="m-0" />
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={16}>
                                Total de Pedidos:
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(dadosPedido) ? dadosPedido.qtde_Pedidos : 0}</b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Divider className="m-0" />
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={16}>
                                Valor total de compras (R$):
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(dadosPedido) ? FormatNumber(dadosPedido.vlr_Total_Pedidos, true) : FormatNumber(0, true)} </b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Divider className="m-0" />
                            </Col>
                        </Row>
                        <Row align="middle">
                            <Col span={16}>
                                Pedidos em Aberto:
                            </Col>
                            <Col span={8}>
                                <Button type="link" onClick={() => viewPedidos()}>
                                    <u>
                                        <b>{isObjetoDiffVazio(dadosPedido) ? dadosPedido.qtde_Pedidos_Abertos : 0}</b>
                                    </u>
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {openCondicional &&
                <Row gutter={[0, 16]} className="col-infos m-t-19">
                    <Col span={24}>
                        <Card size="default" extra={
                            <Button icon={<CloseOutlined />} onClick={() => viewCondicional(false)}> Fechar </Button>
                        }>
                            <Table dataSource={dadosCondicionaisAbertas} columns={[
                                {
                                    title: 'Condicionais',
                                    width: 105,
                                    render: ({ vnc_numero }) => (
                                        <div>
                                            <Tag color="processing"><b>Nº {vnc_numero}</b></Tag>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Lançamento',
                                    render: ({ vnc_datalancamento }) => (
                                        <div>
                                            <b>{moment(vnc_datalancamento).format('DD/MM/YYYY')}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Retorno',
                                    render: ({ vnc_prazoretorno }) => (
                                        <div>
                                            <b>{moment(vnc_prazoretorno).format('DD/MM/YYYY')}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Itens',
                                    align: 'center',
                                    render: ({ vnc_totalitens }, record) => (
                                        <div>
                                            <Button type="link" onClick={() => abrirModalItens(record)}>
                                                {vnc_totalitens}
                                            </Button>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Total (R$)',
                                    align: 'right',
                                    fixed: 'right',
                                    render: ({ vnc_valortotal }) => (
                                        <div>
                                            <b>{FormatNumber(vnc_valortotal, true)}</b>
                                        </div>
                                    ),
                                },
                            ]} locale={{
                                emptyText: (
                                    <Row>
                                        <Col span={24} className="p-t-b-8">
                                            <Divider orientation="center">
                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                            </Divider>
                                        </Col>
                                    </Row>
                                )
                            }} />
                        </Card>
                    </Col>
                </Row>
            }
            {openPedidos &&
                <Row gutter={[0, 16]} className="col-infos m-t-19">
                    <Col span={24}>
                        <Card size="default" extra={
                            <Button icon={<CloseOutlined />} onClick={() => viewPedidos()}> Fechar </Button>
                        }>
                            <Table dataSource={dadosPedidosAbertos} columns={[
                                {
                                    title: 'Status',
                                    render: ({ stp_descricao, stp_corstatus }) => (
                                        <div>
                                            <Row align="middle" justify="left">
                                                <Col>
                                                    <Tag color={stp_corstatus} className="w-100">
                                                        <b>{stp_descricao}</b>
                                                    </Tag>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                },
                                {
                                    title: 'Nº',
                                    align: 'left',
                                    render: ({ ped_numero }) => (
                                        <div>
                                         <b>{ped_numero}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Lançamento',
                                    align: 'center',
                                    render: ({ ped_datainclusao }) => (
                                        <div>
                                            <b>{moment(ped_datainclusao).format('DD/MM/YYYY')}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Entrega',
                                    align: 'center',
                                    render: ({ ped_dataprevisaoentrega }) => (
                                        <div>
                                            <b>{moment(ped_dataprevisaoentrega).format('DD/MM/YYYY')}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Itens',
                                    align: 'center',
                                    render: ({ ped_totalitens, ped_id }) => (
                                        <div>
                                            <Button type="link" onClick={() => { setOpenModalDetalhePedido(true); setPedidoId(ped_id); }}>
                                                {ped_totalitens}
                                            </Button>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Total (R$)',
                                    align: 'right',
                                    fixed: 'right',
                                    render: ({ pdi_valortotal }) => (
                                        <div>
                                            <b>{FormatNumber(pdi_valortotal, true)}</b>
                                        </div>
                                    ),
                                },
                            ]} locale={{
                                emptyText: (
                                    <Row>
                                        <Col span={24} className="p-t-b-8">
                                            <Divider orientation="center">
                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                            </Divider>
                                        </Col>
                                    </Row>
                                )
                            }} />
                        </Card>
                    </Col>
                </Row>
            }
            <Row gutter={[16, 16]} className="m-t-16">
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Card size="default" title="Orçamentos">
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={16}>
                                Percentual de Vendas dos Orçamentos:
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(dadosOrcamento) ? dadosOrcamento.percentual_Vendas_Orcamentos + '%' : '0%'}</b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Divider className="m-0" />
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={16}>
                                Total de Orçamentos:
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(dadosOrcamento) ? dadosOrcamento.qtde_Orcamentos : 0}</b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Divider className="m-0" />
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={16}>
                                Valor Total de Compras (R$):
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(dadosOrcamento) ? FormatNumber(dadosOrcamento.vlr_Total_Orcamentos, true) : FormatNumber(0, true)}</b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Divider className="m-0" />
                            </Col>
                        </Row>
                        <Row align="middle">
                            <Col span={16}>
                                Orçamentos em Aberto:
                            </Col>
                            <Col span={8}>
                                <Button type="link" onClick={() => viewOrcamento(true)}>
                                    <u>
                                        <b>{isObjetoDiffVazio(dadosOrcamento) ? dadosOrcamento.qtde_Orcamentos_Abertos : '0'}</b>
                                    </u>
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} className="col-cheque">
                    <Card size="default" title="Cheques" className="min-table">
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={16}>
                                Quantidade:
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(dadosCheque) ? dadosCheque.qtde_Cheques : 0}</b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Divider className="m-0" />
                            </Col>
                        </Row>
                        <Row align="middle">
                            <Col span={16}>
                                Status:
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(dadosCheque) ? dadosCheque.statusCheques : 0}</b>
                            </Col>
                        </Row>
                        {false && <Row align="middle">
                            <Col span={16}>
                                Cheques de Terceiros:
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(dadosCheque) ? dadosCheque.qtde_ChequesDepositados : 0}</b>
                            </Col>
                        </Row>}
                        {false && <Row align="middle">
                            <Col span={16}>
                                Voltaram:
                            </Col>
                            <Col span={8}>
                                <b>{isObjetoDiffVazio(dadosCheque) ? dadosCheque.qtde_ChequesDevolvidos : 0}</b>
                            </Col>
                        </Row>}
                    </Card>
                </Col>
            </Row>
            {openOrcamento &&
                <Row gutter={[0, 16]} className="col-infos m-t-19">
                    <Col span={24}>
                        <Card size="default" extra={
                            <Button icon={<CloseOutlined />} onClick={() => viewOrcamento(false)}> Fechar </Button>
                        }>
                            <Table dataSource={dadosListaOrcamento} columns={[
                                {
                                    title: 'Informações dos Orçamentos',
                                    render: ({ orc_numero }) => (
                                        <div>
                                            <Tag><b>{orc_numero}</b></Tag>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Lançamento',
                                    align: 'center',
                                    render: ({ orc_datainclusao }) => (
                                        <div>
                                            <b>{moment(orc_datainclusao).format('DD/MM/YYYY')}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Validade',
                                    align: 'center',
                                    render: ({ orc_datavalidade }) => (
                                        <div>
                                            <b>{moment(orc_datavalidade).format('DD/MM/YYYY')}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Itens',
                                    align: 'center',
                                    render: ({ oit_qtdtotalitens, orc_id }) => (
                                        <div>
                                            <Button type="link" onClick={() => { setOpenModalDetalheOrcamento(true); setOrcamentoId(orc_id); }}>
                                                {oit_qtdtotalitens}
                                            </Button>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Total (R$)',
                                    align: 'right',
                                    fixed: 'right',
                                    render: ({ oit_vlrtotalitens }) => (
                                        <div>
                                            <b>{FormatNumber(oit_vlrtotalitens, true)}</b>
                                        </div>
                                    ),
                                },
                            ]} locale={{
                                emptyText: (
                                    <Row>
                                        <Col span={24} className="p-t-b-8">
                                            <Divider orientation="center">
                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                            </Divider>
                                        </Col>
                                    </Row>
                                )
                            }} />
                        </Card>
                    </Col>
                </Row>
            }
            <ModalProdutoCondicional exibirModalProdutoCondicional={openModalProdutoCondicional} fecharModalProdutoCondicional={() => setOpenModalProdutoCondicional(false)} dadosItens={dadosItensCondicional} />
            <ModalDetalhePedido exibirModalDetalhesPedido={openModalDetalhePedido} fecharModalDetalhesPedido={() => setOpenModalDetalhePedido(false)} ped_id={pedidoId} />
            <ModalDetalheOrcamento exibirModalDetalhesOrcamento={openModalDetalheOrcamento} fecharModalDetalhesOrcamento={() => setOpenModalDetalheOrcamento(false)} orc_id={orcamentoId} />
        </div>
    )
}