import { Button, Col, Divider, Form, Input, Row, Select, Table, Typography, Modal, notification } from 'antd';
import React, { useState, useEffect } from 'react';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { ModalManutencaoPecasCat } from '../../components/modals';
import { SelectPaginacao } from '../../components';


export default function GrupoCatalogo({ formulario, formCatalog, listaGrupos, editandoGrp, sequenciaGrp, openDrawer, aoSalvarGrupos }) {
    const [listaPecas, setListaPecas] = useState([]);
    const [exibirModalPecas, setExibirModalPecas] = useState(false);
    const [editando, setEditando] = useState(false);
    const [sequencial, setSequencial] = useState(0);
    const [subgrupoSelect, setSubgrupoSelect] = useState([]);

    useEffect(() => {
        if (!!editandoGrp) {
            let listaTempGrp = listaGrupos.listaGrupos.find(x => x.Sequencia == sequenciaGrp);

            formulario.setFieldsValue({
                codCatalogo: formCatalog.getFieldValue().Codigo,
                sequenciaGrp: listaTempGrp.Sequencia,
                codigoGrp: listaTempGrp.Codigo,
                descricaoGrp: listaTempGrp.Descricao,
                codSubGrupo: listaTempGrp.SubGrupo.Codigo,
            });

            if (listaTempGrp.Pecas) {
                setListaPecas(listaTempGrp.Pecas);
            }

        }

        if (!!openDrawer && !editandoGrp) {
            formulario.setFieldsValue({ codCatalogo: formCatalog.getFieldValue().Codigo });
            formulario.setFieldsValue({ sequenciaGrp: listaGrupos.listaGrupos?.length + 1 });
        }
    }, [editandoGrp, openDrawer]);

    const editarPecas = (sequencia) => {
        setSequencial(sequencia);
        setEditando(true);
        setExibirModalPecas(true);
    }

    const aoSalvar = () => {
        setExibirModalPecas(false);
        setEditando(false);
    }
    //VERIFICAR LOGICA EXCLUIR COM SEQUENCIAL
    const modalExcluir = (record) => {
        const index = listaPecas.findIndex(item => item.Sequencia === record.Sequencia);
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja realmente excluir a peça?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                removerPecas(index);
            }
        });
    }

    const removerPecas = (index) => {
        const novaListaPecas = [...listaPecas];
        novaListaPecas.splice(index, 1);

        novaListaPecas.forEach((item, index) => {
            item.Sequencia = index + 1;
        });

        setListaPecas(novaListaPecas);

    }

    function salvar() {
        let dados = {};
        let subGrupo = subgrupoSelect.find(x => x.key == formulario.getFieldValue().codSubGrupo);
        dados.Codigo = formulario.getFieldValue().codigoGrp;
        dados.Descricao = formulario.getFieldValue().descricaoGrp;
        dados.Sequencia = formulario.getFieldValue().sequenciaGrp;
        dados.CaminhoImg = 'null';
        dados.SubGrupo = {
            Codigo: subGrupo.codSubGrupo,
            Descricao: subGrupo.descricao
        }
        dados.Pecas = [];
        dados.Pecas = listaPecas.map(item => {
            return { ...item };
        });
        let lista = [...listaGrupos.listaGrupos];

        if (editandoGrp) {
            const index = lista.findIndex(item => item.Sequencia === sequenciaGrp);
            if (index !== -1) {
                lista[index] = dados;
                listaGrupos.setListaGrupos(lista);
            }
        } else {
            lista.push(dados);
            listaGrupos.setListaGrupos(lista);
        }

        aoSalvarGrupos();
        setListaPecas([]);
        formulario.resetFields();
    }

    const validarCodGrupo = (rules, value, callback) => {
        if (value && listaGrupos.listaGrupos.some(grupo => grupo.Codigo === value && grupo.Sequencia !== sequenciaGrp)) {
            notification.warning({ description: 'Este código de grupo já existe!', message: 'Aviso!' });
        } else if (value && value.length > 9) {
            notification.warning({ description: 'Código do grupo deve ser menor que 9 digitos!', message: 'Aviso!' });
            formulario.setFieldsValue({ "codigoGrp": value.substring(0, 9) });
        } else {
            callback();
        }
    }

    return (
        <Form form={formulario} layout="vertical" onFinish={salvar}>
            <Row gutter={[8, 0]}>
                <Col span={6}>
                    <Form.Item label="Código Grupo" name="codigoGrp" rules={[{ required: true, message: 'Informe o Código do Grupo' }, { validator: validarCodGrupo }]}>
                        <Input placeholder="Informar código grupo" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Descrição" name="descricaoGrp" rules={[{ required: true, message: 'Informe a Descrição do Grupo' }]}>
                        <Input placeholder="Informar a descrição" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label="Código Catálogo" name="codCatalogo">
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[8, 0]}>
                <Col span={6}>
                    <Form.Item label="Sequência" name="sequenciaGrp">
                        <Input placeholder="Informar a sequência" disabled />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item label="Pasta de Fotos" name="caminhoImg">
                        <Input placeholder="Informe a pasta de foto" />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Código Sub-Grupo"
                        name="codSubGrupo"
                        rules={[{ required: true, message: 'Selecione o Sub-Grupo' }]}
                    >
                        <SelectPaginacao
                            form={formulario}
                            url="Cat006/buscarSubGrupoPaginado?empresa=1"
                            nameLabel="descricao"
                            nameValue="codSubGrupo"
                            placeholder="Selecione o sub-grupo"
                            setListaDados={setSubgrupoSelect}
                            conteudo={
                                est => (
                                    <Select.Option value={est.key} key={est.key}>{est.codSubGrupo} - {est.descricao}</Select.Option>)
                            }
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        Peças
                    </Typography.Title>
                    <Divider orientation="left" plain> Adicione a(s) peça(s) do Catálogo aqui. </Divider>
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => setExibirModalPecas(true)} />
                    </div>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Table dataSource={listaPecas} columns={[
                    {
                        title: "Número Item",
                        align: "center",
                        render: ({ NumeroItem }) => (
                            <b>{NumeroItem}</b>
                        )
                    },
                    {
                        title: "Cód. Peça",
                        align: "center",
                        render: ({ CodPeca }) => (
                            <b>{CodPeca}</b>
                        )
                    }, {
                        title: "Descrição",
                        align: "center",
                        render: ({ descpeca }) => (
                            <b>{descpeca}</b>
                        ),
                    }, {
                        title: "Sequencia",
                        align: "center",
                        render: ({ Sequencia }) => (
                            <b>{Sequencia}</b>
                        ),
                    }, {
                        title: "Quantidade",
                        align: "center",
                        render: ({ Quantidade }) => (
                            <b>{Quantidade}</b>
                        ),
                    }, {
                        title: "Observação",
                        align: "center",
                        render: ({ Observacoes }) => (
                            <b>{Observacoes}</b>
                        ),
                    }, {
                        title: 'Ações',
                        align: 'center',
                        fixed: 'right',
                        key: 'x',
                        width: 95,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button onClick={() => editarPecas(record.Sequencia)} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>
                                        <Button onClick={() => modalExcluir(record)} icon={<DeleteOutlined />} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    }
                ]} />
            </div>
            <ModalManutencaoPecasCat formGrupo={formulario} aoSalvar={aoSalvar} listaPecas={{ listaPecas, setListaPecas }}
                exibirModalItens={exibirModalPecas} sequencia={{sequencial, setSequencial}} editando={{editando, setEditando}} />
        </Form>
    );
}