import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Row, Col, Divider } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import api from '../../services/api';
import Emitter from '../../services/emitter';
import { useStateValue } from '../../state';
import { listaTabelaDadosActions, listagemActions, manutencaoActions } from '../../actions';
import { isNotNullOrEmpty } from '../../services/funcoes';

function TabelaDados({ colunas, url, paginacao = true, expandir, selecionarItem = false, ordenar = true, parametrosSelecionarItens,
    condicao = undefined, footerTabela, headerTabela, tipo = "checkbox", keySelecionaRadio = null }) {

    const [carregando, setCarregando] = useState(false);
    const [lista, setLista] = useState([]);
    const [pagina, setPagina] = useState(1);
    const [registrosPorPagina, setRegistrosPorPagina] = useState(20);
    const [listaTbDados, setListaTbDados] = useState([]);
    const [{ listagem }, dispatch] = useStateValue();

    const configPaginacao = paginacao ? {
        current: pagina,
        pageSize: registrosPorPagina,
        total: lista.totalItems,
        showSizeChanger: true,
        onChange: (pg) => setPagina(pg),
        onShowSizeChange: (atual, porPagina) => setRegistrosPorPagina(porPagina)
    } : null;

    const configSelecionarItem = selecionarItem ?
        setCondicaoSelecionarItem()
        : null;

    const configSelecionarRadio = tipo == "radio" && selecionarItem ?
        setSelecionaRadio() : null;

    useEffect(() => {
        if (!!listaTbDados && listaTbDados.length > 0) {
            dispatch({ type: listaTabelaDadosActions.CHANGE, data: { itens: [...listaTbDados] } });
        }

    }, [listaTbDados])

    function setCondicaoSelecionarItem() {
        if (!!parametrosSelecionarItens && !!parametrosSelecionarItens.tipo) {
            if (parametrosSelecionarItens.tipo === "change") {
                return {
                    onChange: (selectedRowKeys, selectedRows) => {
                        parametrosSelecionarItens.funcao(selectedRowKeys, selectedRows);
                    }
                }
            } else if (parametrosSelecionarItens.tipo === "select") {
                return {
                    onSelect: (record, selected, selectedRows) => {
                        parametrosSelecionarItens.funcao(record, selected, selectedRows);
                    }
                }
            } else if (parametrosSelecionarItens.tipo === "selectall") {
                return {
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        parametrosSelecionarItens.funcao(selected, selectedRows, changeRows);
                    }
                }
            }
        }
        return "";
    };

    function setSelecionaRadio() {
        if (isNotNullOrEmpty(keySelecionaRadio)) {
            return {
                selectedRowKeys: [keySelecionaRadio]
            }
        }
    }

    function carregarDados() {
        if (listagem.ordem) {
            setCarregando(true);
            let parametrosUrl = '';
            if (paginacao === true) parametrosUrl = `&pageNumber=${pagina}&pageSize=${registrosPorPagina}`;
            if (ordenar === true && listagem?.ordem) parametrosUrl += `&order=${listagem.ordem}`;
            if (ordenar === true && listagem?.ordem2) parametrosUrl += `&order2=${listagem.ordem2}`;
            if (listagem?.filtro) parametrosUrl += listagem?.campoFiltro ? `&${listagem.campoFiltro}=${listagem.filtro}` : `&filtro=${listagem.filtro}`;
            if (listagem?.outrosFiltros) parametrosUrl += listagem.outrosFiltros;
            if (!!parametrosUrl) parametrosUrl = parametrosUrl.replace('&', '?');

            api.get(url + parametrosUrl).then((res) => {
                if (res.status === 200) {
                    let dados = [];
                    if (paginacao === true) {
                        if (condicao !== undefined) {
                            res.data.items = res.data.items.filter(condicao);
                        }
                        if (!!res.data && !!res.data.items) {
                            res.data.items.map((item, idx) => {
                                item.key = idx;
                                dados.push(item);
                            })
                        } else {
                            res.data.map((item, idx) => {
                                item.key = idx;
                                dados.push(item);
                            })
                        }
                    } else {
                        if (condicao !== undefined) {
                            res.data.filter(condicao);
                        }
                        res.data.items.map((item, idx) => {
                            item.key = idx;
                            dados.push(item);
                        })
                    }

                    setListaTbDados(dados);
                    setLista(res.data);
                }
            }).finally(() => {
                setCarregando(false);
            });
        }
    }

    useEffect(() => {
        if (!!listagem?.pagina) {
            setPagina(listagem.pagina);
            dispatch({ type: listagemActions.CHANGE, data: { filtro: listagem.filtro, pagina: null } })
        } else if (!paginacao || !!pagina) {
            carregarDados();
        }
    }, [listagem, pagina, registrosPorPagina])

    useEffect(() => {
        Emitter.on('AtualizaDados', (newValue) => carregarDados());
        console.log('Emitter On')
    }, []);
    useEffect(() => () => Emitter.off('AtualizaDados', (newValue) => console.log('Emitter Off')), []);

    return (
        <Table loading={carregando} columns={colunas} dataSource={paginacao ? lista.items : lista} pagination={configPaginacao} expandedRowRender={expandir}

            expandIcon={({ record, expanded, onExpand }) => {
                return expanded ? (
                    <ArrowUpOutlined onClick={(e) => onExpand(record, e)} />
                ) : (
                    <ArrowDownOutlined onClick={(e) => onExpand(record, e)} />
                );
            }}

            locale={{
                emptyText: (
                    <Row>
                        <Col span={24}>
                            <Divider orientation="center">
                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                            </Divider>
                        </Col>
                    </Row>)
            }}
            rowSelection={selecionarItem ? { type: tipo, ...configSelecionarItem, ...configSelecionarRadio } : null}
             scroll={{ x: 920 }} footer={footerTabela} showHeader={headerTabela} columnWidth={30} 
        />
    )
}

TabelaDados.propTypes = {
    colunas: PropTypes.array.isRequired,
    url: PropTypes.string.isRequired,
    paginacao: PropTypes.bool
}

export default TabelaDados;