import React, { useEffect } from "react";
import { Modal, Form, Row, Col, Input, notification } from "antd";
import { SaveOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons"
import { Data, InputPreco } from "../../../components";
import moment from "moment";

export default function ModalLoteProduto({ form, exibirModal, fecharModal, listaLote, setListaLote, editando, sequencia, casaDecimaisUnd }) {

    useEffect(() => {
        if (!!editando) {
            let listaTemp = listaLote.find(x => x.ordem == sequencia);

            form.setFieldsValue({
                ordem: listaTemp.ordem,
                prl_dataentrada: listaTemp.prl_dataentrada,
                prl_datafabricacao: listaTemp.prl_datafabricacao,
                prl_datavencimento: listaTemp.prl_datavencimento,
                prl_numerolote: listaTemp.prl_numerolote,
                prl_qtde: listaTemp.prl_qtde,
                prl_infoadicional: listaTemp.prl_infoadicional
            });
        } else {
            form.resetFields();
        }
        if (!!exibirModal && !editando) {
            let listaLoteLength = listaLote ? listaLote.length : 0;
            form.setFieldsValue({ ordem: listaLoteLength + 1 });
        }
    }, [exibirModal, editando]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                form.resetFields();
                fecharModal();
            }
        })
    }

    const salvarLote = () => {
        let dados = [];
        let lista = [...listaLote];
        dados = form.getFieldValue();

        if (dados.prl_numerolote === undefined || dados.prl_dataentrada === undefined || dados.prl_datafabricacao === undefined || dados.prl_datavencimento === undefined) {
            notification.warning({ message: 'Aviso!', description: 'Preencha os campos obrigatórios.' });
            return
        }
        if (dados.prl_qtde <= 0 && dados.prl_qtde != undefined) {
            notification.warning({ message: 'Aviso!', description: 'Quantidade não pode ser menor ou igual a 0' });
            return
        }
        if (moment(dados.prl_datafabricacao).isAfter(moment(new Date()))) {
            notification.warning({ message: 'Aviso!', description: 'Data de Fabricação não pode ser maior que data atual' });
            return
        }

        if (editando) {
            const index = lista.findIndex(item => item.ordem === sequencia);
            if (index !== -1) {
                lista[index] = dados;
                setListaLote(lista);
            }
        } else {
            lista.push(dados);
            setListaLote(lista);
        }

        form.resetFields();

        fecharModal();
    }

    return (
        <Modal centered
            title="Cadastro Lote do Produto"
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={salvarLote}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="formLote" form={form}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={16} md={16} lg={16} xl={7}>
                            <Form.Item label="Número" name="prl_numerolote" rules={[{ required: true, message: 'Informe o número do Lote' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={4}>
                            <InputPreco
                                precision={casaDecimaisUnd}
                                label={
                                    <div>
                                        Qtde
                                    </div>
                                } name="prl_qtde"
                            />
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={2}>
                            <Form.Item name="ordem" />
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={11} />
                        <Col xs={24} sm={16} md={16} lg={16} xl={8}>
                            <Data label="Data Entrada" name="prl_dataentrada" rules={[{ required: true, message: 'Informe a Data de Entrada' }]} />
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={8}>
                            <Data label="Data Fabricação" name="prl_datafabricacao" rules={[{ required: true, message: 'Informe a Data de Fabricação' }]} />
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={8}>
                            <Data label="Data Validade" name="prl_datavencimento" rules={[{ required: true, message: 'Informe a Data de Validade' }]} />
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Informações Adicionais" name="prl_infoadicional">
                                <Input maxLength={100} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    )
}