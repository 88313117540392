import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Drawer, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import { drawerActions } from '../../actions';
import ReparcelamentoParcelas from './reparcelamentoParcelas';

export default function DrawerReparcelamento({ listaParcelasReparcelar, totalizadores, dadosCliente, buscarParcelasPessoa }) {

    const [formReparcelamento] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [parcelasGeradas, setParcelasGeradas] = useState([]);
    const [carregando, setCarregando] = useState(false);

    function fecharDrawer() {
        formReparcelamento.resetFields();
        setParcelasGeradas([]);
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
    }

    useEffect(() => {
        setParcelasGeradas([]);
        formReparcelamento.resetFields();
    }, [listaParcelasReparcelar]);

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <Drawer title="Reparcelamento de Parcelas"
            width="70%"
            visible={ui.showDrawerReparcelamento}
            onClose={onClose}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]} className="m-t-8">
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={parcelasGeradas.length === 0} onClick={() => formReparcelamento.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ReparcelamentoParcelas formulario={formReparcelamento} carregando={setCarregando} aoSalvar={() => { fecharDrawer(); buscarParcelasPessoa(); }} listaParcelas={listaParcelasReparcelar} totalizadores={totalizadores} dadosCliente={dadosCliente} parcelasGeradas={{ parcelasGeradas, setParcelasGeradas }} />
        </Drawer>
    )
}