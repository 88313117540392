import React from "react";
import { Link } from "react-router-dom";
import { Icon } from '@ant-design/compatible';

import { useStateValue } from "../../state";
import { setTelaAnterior } from "../../services/auth";

export default function LinkNav(props) {
    
    const [{ manutencao }, dispatch] = useStateValue();

    const functionClick = () => {
        if (!!props.onClick) {
            props.onClick();
        }
        setTelaAnterior(props.patch);
    }

    return (
        <Link to={props.patch} onClick={functionClick}>
            <span>
                <Icon type={(!!props.icone ? props.icone : "DoubleRight")} />
                <span>{props.label}</span>
            </span>
        </Link>
    );
};