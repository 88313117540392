export function initDatabase() {

    var request = indexedDB.open("CarrinhoDB", 1);

    request.onupgradeneeded = function (event) {
        var db = event.target.result;

        // Cria uma object store
        var objectStore = db.createObjectStore("Carrinho", { keyPath: "codigo" });
        var objectStoreSincro = db.createObjectStore("Sincronizador", { keyPath: "id", autoIncrement: true });

        // Cria índices para pesquisa rápidaz
        objectStore.createIndex("descricao", "descricao", { unique: false });
        objectStore.createIndex("quantidade", "quantidade", { unique: false });
        objectStore.createIndex("valor", "valor", { unique: false });
        objectStoreSincro.createIndex("data", "data", { unique: false });

    };
    // Tratamento de erros
    request.onerror = function (event) {
        console.error("Erro ao abrir o banco de dados:", event.target.errorCode);
    };
}

export function dateSincro() {
    var request = indexedDB.open("CarrinhoDB");

    request.onsuccess = function (event) {
        var db = event.target.result;

        var transaction = db.transaction(["Sincronizador"], "readwrite");
        var objectStore = transaction.objectStore("Sincronizador");
        var date = new Date();
        date = date.getTime();
        var newItem = { data: (date - 86400000) };

        var addRequest = objectStore.add(newItem);
        addRequest.onsuccess = function (event) {
            console.log("Sincronizador Atualizado");
        };

        addRequest.onerror = function (event) {
            console.error("Erro ao atualizar Sincronizador");
        };
    }
    request.onerror = function (event) {
        console.error("Erro ao atualizar Sincronizador");
    }
}

export function getDateSincro(setDateSincro) {
    var request = indexedDB.open("CarrinhoDB");
    request.onsuccess = function (event) {
        var db = event.target.result;
        try {

            var transaction = db.transaction(["Sincronizador"], "readonly");
            var objectStore = transaction.objectStore("Sincronizador");

            var cursorRequest = objectStore.openCursor();

            var date = 0;

            cursorRequest.onsuccess = function (event) {
                var cursor = event.target.result;

                if (cursor) {
                    date = cursor.value.data;
                    cursor.continue();
                } else {
                    setDateSincro(date);
                }
            }
            cursorRequest.onerror = function (event) {
                console.error("Erro getDateSincro: ", event)
            }
        } catch (ex) {
            debugger;
            var a = ex;
            //cai aqui teu erro kadu
        }

    }
}

export function clearDateSincro(){
    var request = indexedDB.open("CarrinhoDB");

    request.onsuccess = function (event) {
        var db = event.target.result;
            var transaction = db.transaction(["Sincronizador"], "readwrite");
            var objectStore = transaction.objectStore("Sincronizador");

            var clearRequest = objectStore.clear();

            clearRequest.onsuccess = function (event) {
                console.log("Sincronizador limpo com sucesso!");
            }

            clearRequest.onerror = function(event) {
                console.log("Erro ao limpar sincronizador: ", event.target.errorCode);
            }
    }
    request.onerror = function(event) {
        console.error("Erro ao abrir o banco de dados: ", event.target.errorCode);
    }
}
export function loadShoppingCartItems(setCarrinhoItens, callback) {
    var request = indexedDB.open("CarrinhoDB");

    request.onsuccess = function (event) {
        var db = event.target.result;

        var transaction = db.transaction(["Carrinho"], "readonly");
        var objectStore = transaction.objectStore("Carrinho");

        var cursorRequest = objectStore.openCursor();

        var items = [];

        cursorRequest.onsuccess = function (event) {
            var cursor = event.target.result;

            if (cursor) {
                items.push(cursor.value);
                cursor.continue();
            } else {
                setCarrinhoItens(items);
                if (!!callback) {
                    callback(items);
                }
            }

        };
        cursorRequest.onerror = function (event) {
            console.error("Erro ao carregar itens do carrinho:", event.target.errorCode);
        };

    };

    request.onerror = function (event) {
        console.error("Erro ao abrir o banco de dados:", event.target.errorCode);
    };
}

export function saveShoppingCart(codigoPeca, descricaoPeca, quantidade, valorPeca) {
    return new Promise((resolve, reject) => {
        var request = indexedDB.open("CarrinhoDB");
        request.onsuccess = function (event) {
            var db = event.target.result;

            var transaction = db.transaction(["Carrinho"], "readwrite");

            var objectStore = transaction.objectStore("Carrinho");

            var newItem = { codigo: codigoPeca, descricao: descricaoPeca, Qtd: quantidade, valor: valorPeca };
            var addRequest = objectStore.add(newItem);

            addRequest.onsuccess = function (event) {
                console.log("Item adicionado ao carrinho com sucesso!");
                dateSincro();
                resolve();
            };

            addRequest.onerror = function (event) {
                console.error("Erro ao adicionar item ao carrinho:", event.target.errorCode);
                reject(event.target.errorCode);
            };
        };

        request.onerror = function (event) {
            console.error("Erro ao abrir o banco de dados:", event.target.errorCode);
            reject(event.target.errorCode);
        };
    })
}

export function editCartItemQuantity(codigoPeca, novaQuantidade) {
    var request = indexedDB.open("CarrinhoDB");

    request.onsuccess = function (event) {
        var db = event.target.result;

        var transaction = db.transaction(["Carrinho"], "readwrite");
        var objectStore = transaction.objectStore("Carrinho");

        var getRequest = objectStore.get(codigoPeca);

        getRequest.onsuccess = function (event) {

            var itemExistente = event.target.result;

            if (itemExistente) {
                if (novaQuantidade >= 1 && novaQuantidade <= 120) {
                    itemExistente.Qtd = novaQuantidade;

                    var putRequest = objectStore.put(itemExistente);

                    putRequest.onsuccess = function (event) {
                        console.log("Quantidade do item editada no carrinho com sucesso!");
                    };

                    putRequest.onerror = function (event) {
                        console.error("Erro ao editar quantidade do item no carrinho:", event.target.errorCode);
                    };
                } else {
                    console.error("Quantidade não autorizada");
                }
            } else {
                console.error("Item não encontrado no carrinho.");
            }
        };

        getRequest.onerror = function (event) {
            console.error("Erro ao obter item do carrinho:", event.target.errorCode);
        };
    };

    request.onerror = function (event) {
        console.error("Erro ao abrir o banco de dados:", event.target.errorCode);
    };
}

export function deleteCartItem(codigoPeca) {
    var request = indexedDB.open("CarrinhoDB");

    request.onsuccess = function (event) {
        var db = event.target.result;

        var transaction = db.transaction(["Carrinho"], "readwrite");
        var objectStore = transaction.objectStore("Carrinho");

        var deleteRequest = objectStore.delete(codigoPeca);

        deleteRequest.onsuccess = function (event) {
            console.log("Item excluído do carrinho com sucesso!");
        };

        deleteRequest.onerror = function (event) {
            console.error("Erro ao excluir item do carrinho:", event.target.errorCode);
        };
    };

    request.onerror = function (event) {
        console.error("Erro ao abrir o banco de dados:", event.target.errorCode);
    };
}

export function clearShoppingCart() {
    var request = indexedDB.open("CarrinhoDB");

    request.onsuccess = function (event) {
        var db = event.target.result;
        var transaction = db.transaction(["Carrinho"], "readwrite");
        var objectStore = transaction.objectStore("Carrinho");

        var clearRequest = objectStore.clear();

        clearRequest.onsuccess = function (event) {
            console.log("Carrinho limpo com sucesso!");
        };

        clearRequest.onerror = function (event) {
            console.error("Erro ao limpar o carrinho:", event.target.errorCode);
        };
    };

    request.onerror = function (event) {
        console.error("Erro ao abrir o banco de dados:", event.target.errorCode);
    };
}

export function countItemsInCart() {
    return new Promise((resolve, reject) => {
      var request = indexedDB.open("CarrinhoDB");

      request.onsuccess = function (event) {
        var db = event.target.result;

        var transaction = db.transaction(["Carrinho"], "readonly");
        var objectStore = transaction.objectStore("Carrinho");

        var countRequest = objectStore.count();

        countRequest.onsuccess = function (event) {
          console.log("Contador: ", countRequest.result)
          resolve(countRequest.result);
        };

        countRequest.onerror = function (event) {
          console.error("Erro ao contar registros no carrinho:", event.target.errorCode);
          reject(event.target.errorCode);
        };
      };

      request.onerror = function (event) {
        console.error("Erro ao abrir o banco de dados:", event.target.errorCode);
        reject(event.target.errorCode);
      };
    });
}

document.addEventListener("DOMContentLoaded", function () {
    initDatabase();
});


