import React, { useState, useEffect } from "react";
import { Form, Row, Col, Table, Input, Checkbox, Tag, Button, Modal, notification, Divider } from "antd";
import { EditOutlined, DeleteOutlined, PlusOutlined, ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons";

import api from '../../services/api';
import { useStateValue } from '../../state';
import { listagemActions } from "../../actions";
import { FormGW, Editar, Excluir } from "../../components";
import { getEmpresa, getLocal } from "../../services/auth";

export default function ManutencaoArmazemEstoque({ formulario, carregando, aoSalvar }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [listaSetor, setListaSetor] = useState([]);
    const [exibirAlterarSetor, setExibirAlterarSetor] = useState(false);

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    const selecionarOpcao = (record, checked) => {
        let setores = [...listaSetor];
        setores.filter((setor => (record === setor)))[0].ses_ativo = checked;
        setListaSetor(setores);
    };

    useEffect(() => {
        if (!!manutencao.dados) {
            buscarSetores();
        }
    }, [manutencao.dados]);

    //#region alterações setor

    function buscarSetores() {
        api.get(`SetorEstoque/ListarPorArmazem?aes_id=${manutencao.dados.aes_id}&codigoLocal=${getLocal()}`).then(
            res => {
                if (manutencao.dados?.duplicar) {
                    res.data.forEach(
                        item => {
                            item.aes_id = 0;
                            item.ses_id = 0;
                        }
                    );
                }
                setListaSetor(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                if (manutencao.dados.duplicar) {
                    formulario.setFieldsValue({ aes_id: 0 });
                }
            }
        )
    };

    function incluirSetor() {
        let setores = [...listaSetor];
        let setor = formulario.getFieldValue().ses_descricaoItem;
        if (!!setor) {
            let obj = {};
            obj.ses_id = 0;
            obj.aes_id = formulario.getFieldValue().aes_id;
            obj.ses_descricao = setor;
            obj.ses_ativo = true;
            setores.push(obj);
            setListaSetor(setores);
            formulario.setFieldsValue({ ses_descricaoItem: null });
        } else {
            notification.warning({ message: 'Aviso!', description: 'Informe a descrição do setor' });
            return false;
        }
    };

    function editarSetor(record) {
        formulario.setFieldsValue({ ses_id: record.ses_id });
        formulario.setFieldsValue({ ses_descricaoItem: record.ses_descricao });
        setExibirAlterarSetor(true);
    };

    function alterarSetor() {
        let setores = [...listaSetor];
        setores.filter((setor) => (setor.ses_id === formulario.getFieldValue().ses_id))[0].ses_descricao = formulario.getFieldValue().ses_descricaoItem;
        setListaSetor(setores);
        cancelarAlteracaoSetor();
    };

    function cancelarAlteracaoSetor() {
        formulario.setFieldsValue({ id: null });
        formulario.setFieldsValue({ ses_descricaoItem: null });
        setExibirAlterarSetor(false);
    };

    //#endregion

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+aes_descricao' } });
    };

    function excluirRegistro(dados) {
        let setores = [...listaSetor];
        setores = setores.filter((setor) => (setor !== dados));
        setListaSetor(setores);
    };

    function salvarManutencao(values) {
        values.setorestoques = listaSetor;
        values.emp_id = getEmpresa();
        values.loc_id = getLocal();

        carregando(true);
        if (values.aes_id) {
            editar(values);
        } else {
            incluir(values);
        };
    }

    function editar(values) {
        api.put(`ArmazemEstoque/Editar`, values).then(res => {
            notification.success({ message: 'Operação concluída', description: "Os dados do Armazém de Estoque foram salvos com sucesso!" });
        }).catch(
            erro => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
            }).finally(() => {
                atualizarPagina();
                carregando(false);
                aoSalvar();
            });
    }

    function incluir(values) {
        api.post(`ArmazemEstoque/Incluir`, values).then(res => {
            notification.success({ message: 'Operação concluída', description: "Os dados do Armazém de Estoque foram salvos com sucesso!" });
        }).catch(
            erro => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
            }).finally(() => {
                atualizarPagina();
                carregando(false);
                aoSalvar();
            });
    }

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoArmazemEstoque" onFinish={salvarManutencao}>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={12} md={9} lg={9} xl={9}>
                    <Form.Item hidden name="aes_id"></Form.Item>
                    <Form.Item label="Descrição" name="aes_descricao" rules={[{ required: true, message: 'Informe a Descrição' }]}>
                        <Input placeholder="Informe a Descrição" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Form.Item label="Endereço" name="aes_endereco" rules={[{ required: true, message: 'Informe o Endereço' }]}>
                        <Input placeholder="Informe o Endereço" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={3} lg={3} xl={3}>
                    <Form.Item label="Número" name="aes_numero">
                        <Input placeholder="Informe o Número" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                    <Form.Item label="Bairro" name="aes_bairro" rules={[{ required: true, message: 'Informe o Bairro' }]}>
                        <Input placeholder="Informe o Bairro" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={16} md={20} lg={19} xl={19}>
                    <Form.Item hidden label="ses_id" name="ses_id">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Descrição do Setor" name="ses_descricaoItem">
                        <Input placeholder="Informe a Descrição do Setor" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={4} lg={5} xl={5}>
                    {!exibirAlterarSetor && <Row align="middle" className="t-mob-573 tt-5">
                        <Col span={24}>
                            <Button icon={<PlusOutlined />} type="primary" onClick={() => { incluirSetor() }} block>
                                Adicionar
                            </Button>
                        </Col>
                    </Row>}
                    {exibirAlterarSetor &&
                        <Row align="middle" justify="end" gutter={[8, 0]} className="t-mob-573 tt-5">
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Button icon={<CloseOutlined />} block onClick={() => { cancelarAlteracaoSetor() }}>
                                    Cancelar
                                </Button>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Button icon={<PlusOutlined />} block type="primary" onClick={() => { alterarSetor() }}>
                                    Alterar
                                </Button>
                            </Col>
                        </Row>
                    }
                </Col>
                <Col>
                    <Form.Item name="aes_ativo" valuePropName="checked" initialValue={true}>
                        <Checkbox>Armazém Ativo?</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Table locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                </Divider>
                            </Col>
                        </Row>)
                }} dataSource={listaSetor} columns={
                    [
                        {
                            title: 'Descrição do setor',
                            render: ({ ses_descricao }) => (
                                <div>
                                    <b>{ses_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: '',
                            align: 'center',
                            render: (record) => (
                                <Tag.CheckableTag
                                    key={record.key}
                                    checked={record.ses_ativo}
                                    onChange={checked => selecionarOpcao(record, checked)}
                                >
                                    Ativo
                                </Tag.CheckableTag>

                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            align: 'center',
                            key: 'x',
                            width: 65,
                            render: (dados) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Editar icon={<EditOutlined />} onClick={() => { editarSetor(dados) }} />
                                        </Col>
                                        <Col>
                                            <Excluir icon={<DeleteOutlined />} onClick={() => { modalExcluir(dados) }} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } />
            </div>
        </FormGW>
    );

}