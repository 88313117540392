import { Table } from "antd";
import React, { useEffect, useState } from "react";
import formatNumber from "../../ValueObjects/formatNumber";

export default function ItensPedidoCat({ formulario, dados }) {


    return (
        <div>
            <Table dataSource={dados?.dadosPedidos?.itens}
                columns={[
                    {
                        title: 'Código',
                        width: 90,
                        render: ({ codProduto }) => (
                            <div>
                                <b>{codProduto}</b>
                            </div>
                        ),
                    }, {
                        title: 'Descrição',
                        render: ({ descricao }) => (
                            <div>
                                {descricao}
                            </div>
                        )
                    }, {
                        title: 'Quantidade',
                        render: ({ quantidade }) => (
                            <div>
                                {quantidade}
                            </div>
                        )
                    }, {
                        title: 'Valor Unitário',
                        render: ({ valorUnitario }) => (
                            <div>
                                {formatNumber(valorUnitario, true, true, 3)}
                            </div>
                        )
                    }, {
                        title: 'Valor Total',
                        render: ({ quantidade, valorUnitario }) => (
                            <div>
                                {formatNumber(valorUnitario * quantidade, true, true, 3)}
                            </div>
                        )
                    }
                ]} />
        </div>
    )
}