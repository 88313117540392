import React, { useEffect, useState } from 'react';
import { InfoCircleOutlined, PictureOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table, Tooltip, notification } from 'antd';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { linkApiGestor } from '../../services/auth';
import api from "../../services/api";
import { isObjetoDiffVazio } from '../../services/funcoes';
import { ModalExibeImagem } from '../../components/modals';
import ModaDadosPecas from '../../components/modals/modalDadosPeca';

export default function ConsultaPecas({ grupoAtual, catalogoExterno }) {
    const [listaPecas, setListaPecas] = useState([]);
    const [pecaSelecionada, setPecaSelecionada] = useState(null);
    const [openImg, setOpenImg] = useState(false);
    const [image, setImage] = useState({});
    const [imageGrupo, setImageGrupo] = useState({});
    
    const [opeModalInfo, setOpenModalInfo] = useState(false);

    useEffect(() => {
        if (!!grupoAtual && isObjetoDiffVazio(grupoAtual)) {
            if(!!catalogoExterno) {
                buscarPecasGrupoCatalogo(grupoAtual);
            } else {
                buscarPecas(grupoAtual);
            }
            buscarImagemGrupo(grupoAtual);
        }
    }, [grupoAtual]);

    function buscarPecas(grupo) {
        api.get(`Cat007/BuscarPecasGrupo?Empresa=1&CodCatalogo=${grupo.codcatalogo}&CodGrupo=${grupo.codigo}`).then(
            res => {
                if (res.data) {
                    setListaPecas(res.data);
                }
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }

    function buscarPecasGrupoCatalogo(grupo) {
        api.get(`Cat002/ListaPecasGrupoCatalogo?Empresa=1&CodCatalogo=${grupo.codcatalogo}&CodGrupo=${grupo.codigo}`).then(
            res => {
                if (res.data) {
                    setListaPecas(res.data);
                }
            }
        ).catch(
            err => {
                console.log(err);
            }
        )
    }

    function buscarImagemGrupo(grupoAtual)
    {
        api.get(`${linkApiGestor}Cat004/BuscarImg?Empresa=1&CodGrupo=${grupoAtual.codigo}&CodCatalogo=${grupoAtual.codcatalogo}`).then(res => {
            if(res.status === 200) {
                let dados = res.data.map((x) => ({ ...x}))
                if(dados.length > 0){
                    const objeto = {...dados[0]};
                    setImageGrupo(objeto);
                }
                else {
                    setImageGrupo('');
                }
            }
        }, err => {
            notification.warning({ message: 'Aviso', description: 'Ausência de Imagem para a Peça Selecionada!' });
            console.log(err.response);

        });
    }

    function buscarImagemPeca(codpeca, descpeca) {
        api.get(`${linkApiGestor}Cat007/BuscarImgPeca?Empresa=1&CodPeca=${codpeca}&Numero=0`).then(res => {
            if(res.status === 200) {
                let dados = res.data.map((x) => ({ ...x}))
                if(dados.length > 0){
                    const objeto = {...dados[0]};
                    objeto.codpeca = codpeca;
                    objeto.descpeca = descpeca;
                    setImage(objeto);
                    setOpenImg(true);
                }
                else {
                    setImage('');
                }
            }
        }, err => {
            notification.warning({ message: 'Aviso', description: 'Ausência de Imagem para a Peça Selecionada!' });
            console.log(err.response);

        });
    }

    function buscarImagemMongo(codPeca, descpeca)
        {
            
            api.get(`${linkApiGestor}UploadImagens/GetImgMongo?filename=${codPeca}`).then(res => {
                debugger
                if (res.status === 200){
                    let dados = res.data.map((x) => ({...x}))
                    if (dados.length > 0){
                        const objeto = {...dados[0]};
                        objeto.codPeca = codPeca;
                        objeto.descPeca = descpeca;
                        setImage(objeto);
                        setOpenImg(true);
                    }
                }
                else {
                    setImage({});
                }
            })
        }

    return (
        <Row align="top" gutter={[8, 8]}>
            <Col sm={14}>
                <Row>
                    <Col sm={24}>
                        <b>Grupo: {grupoAtual.codigo} - {grupoAtual.descricao}</b>
                        <div className="tabela hgt">
                            <Table sticky small
                                scroll={{ y: window.innerHeight }}
                                columns={
                                    [
                                        {
                                            title: 'Código Peça',
                                            width: 85,
                                            fixed: 'left',
                                            render: ({ codpeca }) => (
                                                <div>
                                                    <b>{codpeca}</b>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Descrição',
                                            width: 420,
                                            render: ({ descpeca }) => (
                                                <b>{descpeca}</b>
                                            ),
                                        },
                                        {
                                            title: 'Item',
                                            width: 60,
                                            render: ({ numeropeca }) => (
                                                <div>
                                                    {numeropeca}
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Qtde',
                                            width: 60,
                                            render: ({ quantidade }) => (
                                                <div>
                                                    {quantidade}
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Obs',
                                            ellipsis: {
                                                showTitle: false,
                                            },
                                            render: ({ observacoes }) => (
                                                <Tooltip placement="topLeft" title={observacoes}>
                                                    <b>{observacoes}</b>
                                                </Tooltip>
                                            ),
                                        },
                                        {
                                            title: '',
                                            fixed: 'right',
                                            width: 30,
                                            render: ({ codpeca, descpeca }) => (
                                                <div>
                                                    <a onClick={() => { buscarImagemMongo(codpeca, descpeca)}}>
                                                        <PictureOutlined />
                                                    </a>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: '',
                                            fixed: 'right',
                                            width: 30,
                                            render: ({ codpeca }) => (
                                                <div>
                                                    <a onClick={() => {
                                                        setPecaSelecionada(codpeca);
                                                        setOpenModalInfo(true);
                                                    }}><InfoCircleOutlined /></a>
                                                </div>
                                            ),
                                        },
                                    ]
                                } dataSource={listaPecas} pagination={false}
                                footer={() => <b>Total de Peças: {listaPecas.length}</b>}
                                rowClassName={(record, index) => record.codpeca === grupoAtual.codpeca ? 'blue' : record.produzido === 1 ? 'green' : record.controle === 1 ? 'yellow' : ''}
                            />
                        </div></Col>
                </Row>
                
            </Col>
            <Col sm={10}>
                <Row align="middle" gutter={[8, 8]}>
                    <div className="divimg">
                        <TransformWrapper>
                            <TransformComponent contentClass="imageZoom">
                                <img src={`data:image/jpg;base64, ${imageGrupo.base64Data}`} alt="grupo" />
                            </TransformComponent>
                        </TransformWrapper>
                    </div>
                </Row>
            </Col>
            <ModaDadosPecas exibirModal={opeModalInfo} codigoPeca={pecaSelecionada} fecharModal={() => { setOpenModalInfo(false); }} />
            <ModalExibeImagem dadosImagem={image} exibirModal={openImg} onCloseModal={() => { setOpenImg(false); }} />
        </Row>
    );
}
