import React,{ useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import { DrawerNav } from '../../components';
import ManutencaoOperacaoDebitoCredito from './manutencao';
import { drawerActions, manutencaoActions } from '../../actions';

export default function DrawerOperacaoDebitoCredito() {

    const [formOperacaoDebitoCredito] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [ carregando, setCarregando ] = useState(false);
    
    if (manutencao.dados !== null) formOperacaoDebitoCredito.setFieldsValue(manutencao.dados);
    else {
        //formOperacaoDebitoCredito.resetFields();
    }

    function fecharDrawer() {
        formOperacaoDebitoCredito.resetFields();

        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null}});
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false}});
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',            
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };


    return (

        <DrawerNav title="Cadastro Operação Débito e Crédito"
            width="70%"
            visible={ui.showDrawer}
            closeIcon={<MinusOutlined />}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formOperacaoDebitoCredito.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>                
            <ManutencaoOperacaoDebitoCredito formulario={formOperacaoDebitoCredito} carregando={setCarregando} aoSalvar={fecharDrawer} />
        </DrawerNav>      

    )
}