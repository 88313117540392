import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Checkbox, Select, notification } from "antd";

import api from '../../services/api';
import { FormGW, SelectPaginacao } from "../../components";
import { useStateValue } from '../../state';
import { selectPaginadoActions } from "../../actions";

export default function ManutencaoSubGrupo({ formulario, carregando, aoSalvar, editando, openDrawer, gruId }) {

    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [filtroAdd, setFiltroAdd] = useState(null);
    var lista = [];

    useEffect(() => {
        if (ui.showDrawer && !!manutencao.dados && !!manutencao.dados.sgp_id && editando.editando) {
            let lista = [];
            if (!!manutencao.dados.gru_id) {
                lista.push({ name: "gru_id", campo: "CodigoGrupo", value: manutencao.dados.gru_id });
            }
            if (lista.length > 0) {
                editando.setEditando(false);
                dispatch({ type: selectPaginadoActions.CHANGE, data: {itens: lista} });
            }
            formulario.setFieldsValue(manutencao.dados);
        } else {
            formulario.setFieldsValue({ sgp_id: null });
            setFiltroAdd(` &ApenasAtivos=true`);
        }
    }, [ui.showDrawer, manutencao.dados]);

    const salvarManutencao = (values) => {
        carregando(true);
        let retorno;
        let enderecoUrl = 'SubGrupo/';

        if (values.sgp_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "Os dados do Subgrupo foram salvos com sucesso!" });
                    retorno = res.data;
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                    aoSalvar(retorno);
                }
            );
        } else {
            enderecoUrl += `Incluir`;
            api.post(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "Os dados do Subgrupo foram salvos com sucesso!" });
                    retorno = res.data;
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                    aoSalvar(retorno);
                }
            );
        }
    };

    useEffect(() => {
        if(!!openDrawer){
        lista.push({ name: "gru_id", campo: "CodigoGrupo", value: gruId.gru_id });
        dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }
    }, [openDrawer]);

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoSubGrupo" onFinish={salvarManutencao}>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    <Form.Item name="sgp_id" hidden />
                    <Form.Item label="Nome do Subgrupo" name="sgp_nome" rules={[{ required: true, message: 'Informe a Descrição do Subgrupo' }]}>
                        <Input placeholder="Informe a Descrição do Subgrupo" />
                    </Form.Item>
                </Col>
                <Col xs={16} sm={16} md={8} lg={8} xl={8}>
                    <Form.Item label="Grupo" name="gru_id" rules={[{ required: true, message: 'Selecione o Grupo' }]}>
                        <SelectPaginacao url="GrupoProduto/Listar" placeholder="Selecione o Grupo" selecionarRegUnico={"gru_id"} form={formulario} nameLabel="gru_nome" nameValue="gru_id" filtroExtra={filtroAdd} conteudo={
                            gru => (<Select.Option value={gru.gru_id} key={gru.gru_id}>{gru.gru_id} - {gru.gru_nome}</Select.Option>)
                        }
                        />
                    </Form.Item>
                </Col>
                <Col xs={8} sm={8} md={5} lg={5} xl={5}>
                    <Form.Item name="sgp_ativo" valuePropName="checked" initialValue={true} hidden={(!(formulario.getFieldValue(['sgp_id']) > 0))} className="m-t-19">
                        <Checkbox> Subgrupo Ativo? </Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </FormGW>
    );

}