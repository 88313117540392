import React from 'react';
import { Link } from "react-router-dom";
import { Form, Input, Tabs, Row, Col, Typography, Card } from 'antd';

import "./modulos.less";

export default function ModulosPage() {

    return (
        <div>
            <Form size="middle" layout="vertical">
                <Row className="fixed-pesquisa">
                    <Col span={24}>
                        <Row justify="center">
                            <Col span={14}>
                                <Form.Item label="Pesquisar módulo">
                                    <Input.Search placeholder="Pesquisar..." enterButton  allowClear/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            <div className="col-modulo">
                <Tabs defaultActiveKey="1" size="large" type="card" centered>
                    <Tabs.TabPane tab="Varejo e Serviços" key="1">
                        <div className="rowPanel text-center">
                            <Row align="middle" gutter={[0, 16]} justify="center">
                                <Col span={24}>
                                    <Typography.Text>
                                        Selecione abaixo o módulo que deseja acessar
                                    </Typography.Text>
                                </Col>
                                <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                                    <Row gutter={[16, 16]} justify="center">
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/vendas.png').default} alt="Varejo e Serviços" />                                                            
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>Vendas</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>                                            
                                        </Col>
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/compra.png').default} alt="Compras" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>Compras</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>                                            
                                        </Col>
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/pdv.png').default} alt="PDV" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>PDV</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>                                            
                                        </Col>
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">                                                    
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/financeiro.png').default} alt="Cotação de Vendas" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>Cotação de Vendas</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>                                            
                                        </Col>
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/contabilidade.png').default} alt="Faturamento Contratos" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>Faturamento Contratos</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>                                            
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Industrial" key="2">
                        <div className="rowPanel text-center">
                            <Row align="middle" gutter={[0, 16]} justify="center">
                                <Col span={24}>
                                    <Typography.Text>
                                        Selecione abaixo o módulo que deseja acessar
                                    </Typography.Text>
                                </Col>
                                <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                                    <Row gutter={[8, 16]} justify="center">
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/industria.png').default} alt="Indústria" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>Indústria</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>     
                                        </Col>
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/catalogoEletronico.png').default} alt="Catálogo eletrônico" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>Catálogo eletrônico</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>     
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>       
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Pedidos/O.S" key="3">
                        <div className="rowPanel text-center">
                            <Row align="middle" gutter={[0, 16]} justify="center">
                                <Col span={24}>
                                    <Typography.Text>
                                        Selecione abaixo o módulo que deseja acessar
                                    </Typography.Text>
                                </Col>
                                <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                                    <Row gutter={[8, 16]} justify="center">
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/pedidosOs.png').default} alt="Pedidos/O.S." />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>Pedidos/O.S</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>     
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div> 
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Financeiro" key="4">
                        <div className="rowPanel text-center">
                            <Row align="middle" gutter={[0, 16]} justify="center">
                                <Col span={24}>
                                    <Typography.Text>
                                        Selecione abaixo o módulo que deseja acessar
                                    </Typography.Text>
                                </Col>
                                <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                                    <Row gutter={[8, 16]} justify="center">
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/aReceber.png').default} alt="A Receber" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>A Receber</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>  
                                        </Col>
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/aPagar.png').default} alt="A Pagar" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>A Pagar</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>  
                                        </Col>
                                        <Col span={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/contaCorrente.png').default} alt="Conta Corrente" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>Conta Corrente</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>  
                                        </Col>
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/caixaBanco.png').default} alt="Caixa e Bancos" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>Caixa e Bancos</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>  
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>  
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Contábil/Fiscal" key="5">
                        <div className="rowPanel text-center">
                            <Row align="middle" gutter={[0, 16]} justify="center">
                                <Col span={24}>
                                    <Typography.Text>
                                        Selecione abaixo o módulo que deseja acessar
                                    </Typography.Text>
                                </Col>
                                <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                                    <Row gutter={[8, 16]} justify="center">
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/contabil.png').default} alt="Contábil" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>Contábil</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>  
                                        </Col>
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/fiscal.png').default} alt="Fiscal" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>Fiscal</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>  
                                        </Col>
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/impostos.png').default} alt="Impostos" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>Impostos</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>  
                                        </Col>
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/sped.png').default} alt="SPED" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>SPED</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>  
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>  
                    </Tabs.TabPane>                    
                    <Tabs.TabPane tab="Indicadores" key="6">
                        <div className="rowPanel text-center">
                            <Row align="middle" gutter={[0, 16]} justify="center">
                                <Col span={24}>
                                    <Typography.Text>
                                        Selecione abaixo o módulo que deseja acessar
                                    </Typography.Text>
                                </Col>
                                <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                                    <Row gutter={[8, 16]} justify="center">
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/indicadores.png').default} alt="Indicadores" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>Indicadores</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>  
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>  
                    </Tabs.TabPane>                   
                    <Tabs.TabPane tab="Logística" key="7">
                        <div className="rowPanel text-center">
                            <Row align="middle" gutter={[0, 16]} justify="center">
                                <Col span={24}>
                                    <Typography.Text>
                                        Selecione abaixo o módulo que deseja acessar
                                    </Typography.Text>
                                </Col>
                                <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                                    <Row gutter={[8, 16]} justify="center">
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/mdfe.png').default} alt="Conta Corrente" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>MDF-e</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>  
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>  
                    </Tabs.TabPane>                   
                    <Tabs.TabPane tab="Utilitários" key="8">
                        <div className="rowPanel text-center">
                            <Row align="middle" gutter={[0, 16]} justify="center">
                                <Col span={24}>
                                    <Typography.Text>
                                        Selecione abaixo o módulo que deseja acessar
                                    </Typography.Text>
                                </Col>
                                <Col xs={24} sm={24} md={15} lg={15} xl={15}>
                                    <Row gutter={[8, 16]} justify="center">
                                        <Col xs={12} sm={12} md={5} lg={4} xl={4}>
                                            <Card hoverable>
                                                <Link to="/">
                                                    <Row align="middle" justify="center">
                                                        <Col span={24}>
                                                            <img src={require('../../assets/iconesModulos/utilitarios.png').default} alt="Conta Corrente" />
                                                        </Col>
                                                        <Col span={24}>
                                                            <b>Utilitários</b>
                                                        </Col>
                                                    </Row>
                                                </Link>
                                            </Card>  
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>  
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
}