import React, { useState, useEffect } from 'react';
import { BreadcrumbIncluirDados, TabelaDados } from '../../components';
import { Button, Col, Row, Modal, notification } from 'antd';
import { useStateValue } from '../../state';
import { listagemActions } from '../../actions';
import DrawerCadastroCatalogo from './drawer';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import api from '../../services/api';

export default function CadastroCatalogo() {
    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+desccatalogo', outrosFiltros: `&empresa=1` } });
    }, []);

    const modalExcluirCat = (Codigo) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja realmente excluir o grupo?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk () {
                ExcluirCat(Codigo);
            }
        })
    }

    // const editCatalogo = (Codigo) => {
    //     api.get(`Cat001/Buscar?empresa=${1}&cta001=${Codigo.codcatalogo}`).then(
    //         (res) => {
    //             console.log(res)
    //         }
    //     ).catch(
    //         (error) => {
    //             console.error("erro: ", error);
    //         }
    //     )
    // }

    const ExcluirCat = (Codigo) => {
        api.post(`Cat001/Excluir?codigo=${Codigo.codcatalogo}`).then(
            (res) => {
                notification.success({ message: 'Catálogo excluido com sucesso!' });
            }
        ).catch(
            (error) => {
                console.error('Erro ao excluir catálogo:', error);
            }
        )
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <div className="tabela">
                <TabelaDados url="Cat001/Listar" colunas={[
                    {
                        title: 'Código',
                        render: ({ codcatalogo }) => (
                            <div>
                                <Row align="middle" gutter={[5, 0]}>
                                    <Col>
                                        <b>{codcatalogo}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Descrição',
                        render: ({ desccatalogo }) => (
                            <div>
                                <b>{desccatalogo}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Cód. Linha',
                        render: ({ codlinha }) => (
                            <div>
                                <b>{codlinha}</b>
                            </div>
                        ),
                    }, {
                        title: "Linha",
                        render: ({ linha }) => (
                            <div>
                                <b>{linha?.descLinha}</b>
                            </div>
                        )
                    }, {
                        title: "Ações",
                        align: "center",
                        fixed: "right",
                        key: "x",
                        width: 95,
                        render: (Codigo) => (
                            <div>
                                <Row align='middle' justify='center' gutter={[5, 0]}>
                                    {/* <Col>
                                        <Button icon={<EditOutlined />} onClick={() => editCatalogo(Codigo)}/>
                                    </Col> */}
                                    <Col>
                                        <Button icon={<DeleteOutlined />} onClick={() => modalExcluirCat(Codigo)}/>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                ]} />
            </div>
            <DrawerCadastroCatalogo />
        </div>
    );
}