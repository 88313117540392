import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tag, Menu, Dropdown, Typography, Popover, Table, Divider, notification } from "antd";
import { MoreOutlined, PrinterOutlined, CloseOutlined, FileTextOutlined, EditOutlined, ExclamationCircleOutlined, DeleteOutlined, CloudUploadOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { FormatNumber } from "../../ValueObjects";
import { Editar, TabelaDados } from "../../components";
import { novaAbaNavegador } from "../../services/funcoes";
import { drawerActions, manutencaoActions, listagemActions, selectPaginadoActions } from "../../actions";
import { ModalCancelamentoNfe, ModalCartaCorrecaoNotaFiscal, ModalReenviarSefaz, ModalInutilizarNota } from "../../components/modals";

export default function TabNotaFiscalEmitida({ retornaDescricaoStatus, carregarDados }) {

    const [openModalCancelar, setOpenModalCancelar] = useState(false);
    const [openModalCartaCorrecao, setOpenModalCartaCorrecao] = useState(false);
    const [dadosNota, setDadosNota] = useState({});
    const [{ manutencao }, dispatch] = useStateValue();
    const [exibirModalInutilizar, setExibirModalInutilizar] = useState(false);
    const [openModalExcluir, setOpenModalExcluir] = useState(false);
    const [openModalReenviarSefaz, setModalReenviarSefaz] = useState(false);


    function menuTable(record) {
        return (
            <Menu className="text-right">
                {/*Não tem opção de editar no permissão de acesso.
                
                {(record.ntf_status === 0 || record.ntf_status === 4) && record.ntf_nroinutilizadosefaz == false && record.ntf_tiponota === 2 &&
                    <Menu.Item key="1">
                        <Editar type="text" icon={<EditOutlined />} onClick={() => {
                            editarNota(record)
                        }}> Editar </Editar>
                    </Menu.Item>
                } */}
                {(record.ntf_status === 4 || record.ntf_status === 0) && record.ntf_modelo == '55' &&
                    <Menu.Item key="9"><Button type="text" icon={<CloudUploadOutlined />} onClick={() => { autorizarNota(record) }} >Autorizar Sefaz</Button>
                    </Menu.Item>}
                {(record.ntf_status === 4 || record.ntf_status === 0) && record.ntf_modelo == '55' &&
                    <Menu.Item key="9"><Button type="text" icon={<DeleteOutlined />} onClick={() => { inutilizarNota(record) }} >Inutilizar</Button>
                    </Menu.Item>}
                {record.ntf_status === 1 && record.ntf_modelo == '55' && record.ntf_tiponota === 1 &&
                    <Menu.Item key="4">
                        <Button hidden type="text" icon={<DeleteOutlined />} onClick={() => abrirExcluirNota(record)}> Excluir </Button>
                    </Menu.Item>}
                {record.ntf_status === 1 &&
                    <Menu.Item key="2">
                        <Button type="text" icon={<CloseOutlined />} onClick={() => abrirCancelarNota(record)}> Cancelar </Button>
                    </Menu.Item>
                }
                {record.ntf_status === 1 &&
                    <Menu.Item key="3">
                        <Button type="text" icon={<FileTextOutlined />} onClick={() => abrirCartaCorrecao(record)}> Carta de Correção </Button>
                    </Menu.Item>
                }
                <Menu.Item key="3">
                    <Button type="text" icon={<PrinterOutlined />} onClick={() => {
                        if (!!record.ntf_chavenfe) {
                            novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${record.ntf_chavenfe}`)
                        }
                    }}> Imprimir </Button>
                </Menu.Item>
            </Menu>
        )
    }

    function abrirExcluirNota(record) {
        setDadosNota(record);
        setOpenModalExcluir(true);
    };

    function inutilizarNota(record) {
        setDadosNota(record);
        setExibirModalInutilizar(true)
    };
    function autorizarNota(record) {
        setDadosNota(record);
        setModalReenviarSefaz(true);
    };


    function fecharModalInutilizar() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ntf_numero' } });
        setExibirModalInutilizar(false);
    }

    function editarNota(record) {
        setDadosNota(record);
        if (record.ntf_id) {
            api.get(`NotaFiscalTransferencia/dadosNotaFiscal?codigoNotaFiscal=${record.ntf_id}`).then(retornaNota => {
                if (retornaNota.status === 200) {
                    retornaNota.data[0].ntf_dataemissao = moment(retornaNota.data[0].ntf_dataemissao);
                    retornaNota.data[0].loc_id = retornaNota.data[0].localDestino;
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: retornaNota.data[0] } });
                    dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                }
            }).catch((erro) => console.error(erro));
        }
    };

    function abrirCancelarNota(record) {
        setDadosNota(record);
        setOpenModalCancelar(true);
    };

    function abrirCartaCorrecao(record) {
        setDadosNota(record);
        setOpenModalCartaCorrecao(true);
    };

    return (
        <div className="tabela">
            <TabelaDados url="NotaFiscalTransferencia/Listar" colunas={[
                {
                    title: 'Nº',
                    width: 100,
                    align: 'center',
                    render: ({ ntf_numero }) => (
                        <div>
                            <b>
                                {ntf_numero}
                            </b>
                        </div>
                    ),
                },
                {
                    title: 'Status',
                    width: 100,
                    align: 'center',
                    render: ({ ntf_status }) => (
                        <div>
                            <Tag color={((ntf_status === 0) ? 'default' : (ntf_status === 1 ? 'green' : (ntf_status === 2 ? 'gold' : (ntf_status === 3 ? 'orange' : (ntf_status === 4 ? 'red' : (ntf_status === 5 ? 'lime' : ''))))))} className="w-100">
                                <b>
                                    {retornaDescricaoStatus(ntf_status)}
                                </b>
                            </Tag>
                        </div>
                    ),
                },
                {
                    width: 750,
                    title: 'Transferências Emitidas',
                    render: ({ ntf_status, ntf_nomedest, ser_serie, ntf_dataemissao, ntf_chavenfe, ntf_cstat, ntf_xmotivo }) => (
                        <div>
                            <Row gutter={[6, 3]}>
                                <Col>
                                    <b>
                                        {ntf_nomedest}
                                    </b>
                                </Col>
                                <Col>
                                    Série: <b>{ser_serie}</b>
                                </Col>
                                <Col>
                                    Emissão: <b>{moment(ntf_dataemissao).format('DD/MM/YYYY')}</b>
                                </Col>
                            </Row>
                            <Row gutter={[24, 12]}>
                                {!(!!ntf_chavenfe) ?
                                    <Col>
                                        NF-e não gerada
                                    </Col> :
                                    <Col>
                                        NF-e:
                                        <Popover content={'Clique para visualizar o Danfe'} placement="top">
                                            <Typography.Link onClick={() => {
                                                novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${ntf_chavenfe}`);
                                            }} target="_blank">
                                                <b> {ntf_chavenfe}</b>
                                            </Typography.Link>
                                        </Popover>
                                    </Col>
                                }
                                {(ntf_status == 3 || ntf_status == 4) &&
                                    <Col>
                                        Rejeição: <b>{ntf_cstat}{!!(ntf_xmotivo) ? (', ' + ntf_xmotivo) : ''}</b>
                                    </Col>
                                }
                            </Row>
                        </div>
                    ),
                },
                {
                    title: 'Total da Nota (R$)',
                    align: 'right',
                    render: ({ valor_total }) => (
                        <div>
                            <b className="f-18">{FormatNumber(valor_total, true)}</b>
                        </div>
                    ),
                },
                {
                    title: 'Ações',
                    dataIndex: '',
                    key: 'x',
                    align: 'center',
                    fixed: 'right',
                    width: 65,
                    render: (record) => (
                        <div>
                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                <Col>
                                    <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                        <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>
                    ),
                },
            ]} />
            <ModalReenviarSefaz dadosNota={dadosNota} titleModal="Reenviar NF-e Sefaz" exibirModalReenviarSefaz={openModalReenviarSefaz} fecharModalReenviarSefaz={() => { setModalReenviarSefaz(false); carregarDados() }} />
            <ModalInutilizarNota dadosNota={dadosNota} exibirModal={exibirModalInutilizar} fecharModal={() => { fecharModalInutilizar() }} />
            <ModalCartaCorrecaoNotaFiscal dadosNota={dadosNota} exibirModal={openModalCartaCorrecao} fecharModal={() => { setOpenModalCartaCorrecao(false); carregarDados() }} />
            <ModalCancelamentoNfe dadosNota={dadosNota} titleModal="Cancelamento de NF-e" textoBotao="Realizar Cancelamento" exibirModalCancelarEstornar={openModalCancelar} fecharModalCancelarEstornar={() => { setOpenModalCancelar(false); carregarDados() }} />
        </div>
    );
}