import React from 'react';
import { Row, Col, Tag, Table } from "antd";
import { FormatNumber } from '../../../ValueObjects';


export default function TabItens({ itens }) {

    return (
        <div className="tabela">
            <Table dataSource={itens} columns={
                [
                    {
                        title: '',
                        render: ({ pro_codigo, pro_descricao, atributos }) => (
                            <div>
                                <Row align="middle" gutter={[5, 5]}>
                                    <Col>
                                        <Tag color="processing" className="w-75">
                                            <b>{pro_codigo}</b>
                                        </Tag>
                                    </Col>
                                    <Col>
                                        <b>{pro_descricao}</b>
                                    </Col>
                                    {!!atributos &&
                                        <Col>
                                            <Tag color="default">
                                                <b>{atributos.grade}</b>
                                            </Tag>
                                        </Col>
                                    }
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'UM.',
                        width: 60,
                        align: 'right',
                        render: ({ ump_id }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{ump_id}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Qtde.',
                        width: 60,
                        align: 'right',
                        render: ({ oit_qtde }) => (
                            <div>
                                <Row align="middle" justify="end">
                                    <Col>
                                        <b>{oit_qtde}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Vlr Unt.(R$)',
                        align: 'right',
                        width: 100,
                        render: ({ oit_valorunitario }) => (
                            <div>
                                <b>{FormatNumber(oit_valorunitario, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Vlr Total (R$)',
                        align: 'right',
                        width: 100,
                        render: ({ oit_valorunitario, oit_qtde }) => (
                            <div>
                                <b>{FormatNumber(oit_qtde * oit_valorunitario, true)}</b>
                            </div>
                        ),
                    }
                ]
            } />
        </div>
    );
}