import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, notification } from "antd";

import api from "../../services/api";
import { useStateValue } from '../../state';
import { FormGW, InputPreco } from "../../components";
import SelectPaginado from "../../components/selectPaginado";

export default function ManutencaoAntecipacao({ formulario, carregando, aoSalvar }) {
    const [{ manutencao }, dispatch] = useStateValue();

    const salvarManutencao = record => {
        record.acf_entradasaida = 2;
        carregando(true);
        api.post(`AntecipacaoClienteFornecedor/IncluirAntecipacao`, record).then(
            res => {
                notification.success({ description: "Antecipação incluída com sucesso!", message: "Aviso" })
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                carregando(false);
                aoSalvar();
            }
        );
    };

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoValePresente" onFinish={salvarManutencao}>
            <Row gutter={[8, 0]}>
                <Form.Item name="acf_id" hidden></Form.Item>
                <Col xs={24} sm={16} md={18} lg={18} xl={19}>
                    <Form.Item name="pes_id" label="Cliente/Fornecedor">
                        <SelectPaginado url="Cliente/Listar" form={formulario} placeholder="Selecione o Cliente/Fornecedor" name="pes_id" conteudo={
                            cf => (<Select.Option value={cf.pes_id} key={cf.key}>{cf.pes_nome}</Select.Option>)
                        } />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={6} lg={6} xl={5}>
                    <InputPreco name="acf_valor" label="Valor do Vale (R$)" rules={[{ required: true, message: `O Valor do Vale é Obrigatório` }]} />
                </Col>
                <Col span={24}>
                    <Form.Item label="Informações Adicionais do Vale" name="acf_observacao">
                        <Input.TextArea placeholder="Informe as Observações a Imprimir no Comprovante" />
                    </Form.Item>
                </Col>
            </Row>
        </FormGW>
    );
}