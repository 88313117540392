import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, Modal, Descriptions, Table, InputNumber } from "antd";

import formatNumber from "../../../ValueObjects/formatNumber";

export default function ModalSelecionarGrade({ dadosProduto = {}, exibirModal, onCloseModal }) {
    
    const [form] = Form.useForm();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [gradesSelecionadas, setGradesSelecionadas] = useState([]);

    function selecionarGrades(selectedRowKeys, selectedRows) {
        setGradesSelecionadas(selectedRows);
    }

    const functionOnOK = record => {
        let gradesSelecionadasBkp = [...gradesSelecionadas];
        setGradesSelecionadas([]);
        onCloseModal(gradesSelecionadasBkp);
    };

    return (
        <div>
            <Modal centered
                title="Selecione a grade desejada"
                visible={exibirModal}
                onCancel={onCloseModal}
                onOk={functionOnOK}
                destroyOnClose
            >
                <Form layout="vertical" name="formGrade" form={form}>
                    <Row align="middle" gutter={[16, 0]}>
                        <Col>
                            Código: <b>{dadosProduto.pro_codigo}</b>
                        </Col>
                        <Col>
                            Produto: <b>{dadosProduto.pro_descricao}</b>
                        </Col>
                    </Row>
                    <Table rowSelection={{
                            onChange: (selectedRowKeys, selectedRows) => {
                                selecionarGrades(selectedRowKeys, selectedRows);
                            }
                        }}
                        columns={[
                            {
                                title: "Grade",
                                render: ({ descricaoGrade }) => (
                                    <div>
                                        {descricaoGrade}
                                    </div>
                                )
                            }, {
                                title: "EAN",
                                render: ({ prg_ean }) => (
                                    <div>
                                        {prg_ean}
                                    </div>
                                )
                            }, {
                                title: "Valor (R$)",
                                align: "right",
                                render: ({ pre_custoultimacompra }) => (
                                    <div>
                                        {formatNumber(pre_custoultimacompra, true, false)}
                                    </div>
                                )
                            }
                        ]}
                        dataSource={!!dadosProduto.grades ? dadosProduto.grades : []}
                    />
                </Form>
            </Modal>
        </div >
    )
}