import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, Checkbox, notification, Modal } from "antd";
import { ExclamationCircleOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { validarCpf } from "../../../services/funcoes";
import { InputCpfCnpj } from "../../../components";

export default function ModalPessoaAutorizada({ form, listaPessoasAutorizadas, exibirModalPessoaAutorizada, fecharModalPessoaAutorizada }) {
    const [listasGrauParentesco, setListasGrauParentesco] = useState([]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                form.resetFields();
                fecharModalPessoaAutorizada();
            }
        });
    };

    const onFinish = values => {
        let lista = [...listaPessoasAutorizadas.dadosPessoasAutorizadas];
        let nomeLista = listasGrauParentesco.filter(g => g.grp_id === form.getFieldValue().grp_id);
        values.grp_id = nomeLista[0].grp_id;
        values.grp_descricao = nomeLista[0].grp_descricao;

        let listaPessoas = lista.find((pesAuto) => pesAuto.pea_id === values.pea_id);
        if (!!listaPessoas) {
            listaPessoas.pes_nome = values.pes_nome;
            listaPessoas.grp_descricao = values.grp_descricao;
            listaPessoas.pea_ativo = values.pea_ativo;
        } else {
            let verificaOcupacaoAdd = lista.filter(p => p.pef_cpf === form.getFieldValue().pef_cpf);
            if (verificaOcupacaoAdd.length > 0) {
                fecharModalPessoaAutorizada();
                notification.info({ message: 'Pessoa Autorizada já adicionada!' });
            } else {
                values.pea_ativo = values.pea_ativo;
                lista.push(values);
            }
        }
        listaPessoasAutorizadas.setDadosPessoasAutorizadas(lista);
        fecharModalPessoaAutorizada();
    };

    useEffect(() => carregarDados(), []);

    function verificarCpfValido(event) {
        let valorCampo = event.currentTarget.value.replace(/\D/g, '');
        if (!validarCpf(valorCampo)) {
            notification.warning({ message: 'Aviso', description: 'CPF informado é inválido' });
            form.resetFields();
            return false;
        } else {
            api.get(`PessoaFisica/BuscarPessoaFisica/${valorCampo}`).then(
                res => {
                    if (res.status === 200) {
                        if (res.data) {
                            form.setFieldsValue({ pef_id: res.data.pessoafisicas[0].pef_id, pes_nome: res.data.pes_nome })
                            console.log(res.data);
                        }
                    }
                }
            ).catch((erro) => console.error(erro))
        }
    }

    function carregarDados() {
        api.get(`GrauParentesco/Listar`).then(
            res => {
                setListasGrauParentesco(res.data);
            }
        ).catch((erro) => console.error(erro))
    };

    return (

        <Modal centered
            title="Cadastro de Pessoas Autorizadas"
            visible={exibirModalPessoaAutorizada}
            onCancel={() => {
                onCloseModal();
            }}
            onOk={() => form.submit()}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="formPessoaAutorizada" form={form} onFinish={onFinish}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={8} md={5} lg={8} xl={8} xxl={8}>
                            <Form.Item hidden name="pea_id"></Form.Item>
                            <Form.Item hidden name="cli_id"></Form.Item>
                            <Form.Item hidden name="pef_id"></Form.Item>
                            <Form.Item hidden name="pea_codigodigital"></Form.Item>
                            <InputCpfCnpj cpf label="CPF" name="pef_cpf" rules={[{ required: true, message: 'Informe o CPF da Pessoa' }]} onBlur={(valor) => { verificarCpfValido(valor) }} />
                        </Col>
                        <Col xs={24} sm={16} md={8} lg={16} xl={16} xxl={16}>
                            <Form.Item label="Nome" name="pes_nome" rules={[{ required: true, message: 'Informe o Nome da Pessoa' }]}>
                                <Input placeholder="Informe o Nome da Pessoa" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={6} lg={8} xl={8} xxl={8}>
                            <Form.Item label="Grau de Parentesco" name="grp_id" rules={[{ required: true, message: 'Selecione o Grau de Parentesco' }]}>
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    placeholder="Selecione o Grau de Parentesco"
                                    options={listasGrauParentesco.map((g) => { return { label: `${g.grp_descricao}`, value: g.grp_id } })}>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="pea_ativo" valuePropName="checked" initialValue={true} className="t-mob-573">
                                <Checkbox>Pessoa Autorizada?</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}