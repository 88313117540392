const drawerActions = {
    CHANGE: 'changeDrawer'
}

const manutencaoActions = {
    CHANGE: 'changeManutencao'
}

const listagemActions = {
    CHANGE: 'changeListagem'
}

const tributacaoActions = {
    CHANGE: 'changeTributacao'
}

const listaTabelaDadosActions = {
    CHANGE: 'changeListaTabelaDados'
}

const parametrosEmpresaActions = {
    CHANGE: 'changeParametrosEmpresa'
}

const selectPaginadoActions = {
    CHANGE: 'changeSelectPaginadoActions'
}


export {
    drawerActions, manutencaoActions, listagemActions, tributacaoActions, listaTabelaDadosActions, parametrosEmpresaActions, selectPaginadoActions
}