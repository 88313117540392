import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, Button, Collapse, Tag, Popover } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, SearchOutlined, EditOutlined, SyncOutlined, PrinterOutlined, CloseOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import { useStateValue } from '../../state';
import { FormatDate } from '../../ValueObjects';
import { DrawerPreco, DrawerAtualizarPreco } from "./drawerPage";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacaoData, TabelaDados, Editar, Imprimir, Data, PesquisaAvancada } from "../../components";

export default function Preco() {

    const [open, setOpen] = useState(false);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [itemEdit, setItemEdit] = useState(null);
    const [dataAtual] = useState(new Date());
    const [{ manutencao }, dispatch] = useStateValue();
    const [formulario] = Form.useForm();
    const [clientes, setClientes] = useState([]);
    const [vendedores, setVendedores] = useState([]);
    const [formPesquisaAvancada] = Form.useForm();

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ltp_id' } });
        formulario.setFieldsValue({ dataInicial: moment(new Date()), dataFinal: moment(new Date()) });

        api.get('Cliente/Listar?filtro=&ordem=%2Bpes_nome').then(
            (res) => {
                if (res.status === 200) {
                    setClientes(res.data.items);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );

        api.get('Vendedor/ListarVendedor').then(
            (res) => {
                if (res.status === 200) {
                    setVendedores(res.data.items);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    }, []);

    const togglePesquisa = () => {
        setOpen(!open);
    };

    const loadDada = async (data) => {
        setItemEdit(data.ltp_id);
        if (!!data.ltp_datafinal) {
            data.ltp_datafinal = !!data.ltp_datafinal ? moment(data.ltp_datafinal) : null;
        }
        if (!!data.ltp_datainicial) {
            data.ltp_datainicial = !!data.ltp_datainicial ? moment(data.ltp_datainicial) : null;
        }
        if (data.loc_id == null) {
            data.ltp_todaempresa = true;
        }
        setLoadingEdit(true);
        data.itens = await getList(`ListaPrecoItem/ListarItens?codigoLista=${data.ltp_id}&Ativo=1`);
        data.clientes = await getList(`ListaPrecoCliente/ListarTodos?CodigoLista=${data.ltp_id}`);
        data.vendedores = await getList(`ListaPrecoVendedor/ListarTodos?codigoLista=${data.ltp_id}`);
        setLoadingEdit(false);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: data } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    }

    const openAtualizarPrecos = async (data) => {
        data.itens = await getList(`ListaPrecoItem/ListarItens?codigoLista=${data.ltp_id}`);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: data } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawerExtra: true } });
    }

    const getList = async (url) => {
        return await api.get(url).then((result) => {
            if (result.status === 200)
                return result.data;
            return [];
        }).catch((err) => {
            console.log('err request :>> ', err);
            return [];
        });
    };

    function aplicarFiltros() {
        let dataInicial = (!!formulario.getFieldValue().dataInicial ? FormatDate(formulario.getFieldValue().dataInicial._d.toLocaleDateString()) : moment(formulario.getFieldValue().dataPesquisa).format('YYYY-MM-DD'));
        let dataFinal = (!!formulario.getFieldValue().dataFinal ? FormatDate(formulario.getFieldValue().dataFinal._d.toLocaleDateString()) : FormatDate(dataAtual.toLocaleDateString()));
        let cliente = (!!formulario.getFieldValue().cli_id ? `&idCliente=${formulario.getFieldValue().cli_id}` : '');
        let vendedor = (!!formulario.getFieldValue().ven_id ? `&idVendedor=${formulario.getFieldValue().ven_id}` : '');
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ltp_id', outrosFiltros: `&dataFinal=${dataFinal}&dataInicial=${dataInicial}${cliente}${vendedor}` } });
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={18} xl={19} xxl={20}>
                    <PesquisaOrdenacaoData opcoesOrdenacao={[
                        { label: "Código Crescente", value: "%2Bltp_id" },
                        { label: "Código Decrescente", value: "-ltp_id" },
                        { label: "Nome A - Z", value: "%2Bltp_nome" },
                        { label: "Nome Z - A", value: "-ltp_nome" },
                    ]} form={formulario} eventoAoAlterar={() => {
                        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&dataFinal=${FormatDate(dataAtual.toLocaleDateString())}&dataInicial=${moment(formulario.getFieldValue().dataPesquisa).format('YYYY-MM-DD')}` } });
                    }} />
                </Col>
            </Row>
            <div>
                <Form layout="vertical" form={formulario}>
                    <div className="page">
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Collapse className="p-relative topGrade" accordion ghost destroyInactivePanel={true}>
                                    <div className="colDet"></div>
                                    <Collapse.Panel key="1" showArrow={false} extra={
                                        <Button type="primary" onClick={togglePesquisa} block>
                                            {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                        </Button>
                                    }>
                                        {<Row gutter={[8, 0]}>
                                            <Col xs={24} sm={12} md={4} lg={4} xl={3}>
                                                <Data label="Data Inicial" name="dataInicial" />
                                            </Col>
                                            <Col xs={24} sm={12} md={4} lg={4} xl={3}>
                                                <Data label="Data Final" name="dataFinal" />
                                            </Col>
                                            <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                                                <Form.Item label="Cliente" name="cli_id">
                                                    <Select allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione o Cliente">
                                                        {clientes.map((cliente) => (
                                                            <Select.Option key={cliente.cli_id} value={cliente.cli_id}>{cliente.pes_nome}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12} md={8} lg={8} xl={6}>
                                                <Form.Item label="Vendedor" name="ven_id">
                                                    <Select allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione o Vendedor">
                                                        {vendedores.map((vendedor) => (
                                                            <Select.Option key={vendedor.ven_id} value={vendedor.ven_id}>{vendedor.pes_nome}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                                                <Row gutter={[8, 8]} justify="end" className="t-mob tm5">
                                                    <Col xs={24} sm={6} md={5} lg={5} xl={12}>
                                                        <Button icon={<CloseOutlined />} block>
                                                            Remover Filtros
                                                        </Button>
                                                    </Col>
                                                    <Col xs={24} sm={6} md={5} lg={5} xl={12}>
                                                        <Button type="primary" onClick={() => aplicarFiltros()} icon={<SearchOutlined />} block>
                                                            Aplicar Filtros
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>}
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="tabela mt-dif">
                <TabelaDados url="ListaPreco/ListarListaPreco" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ ltp_id, ltp_ativa }) => (
                            <Popover content={ltp_ativa ? 'Ativa' : 'Inativa'} placement="right">
                                <Tag color={ltp_ativa ? 'processing' : 'default'} className="w-100">
                                    <b>{ltp_id}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Descrição',
                        render: ({ ltp_nome }) => (
                            <div>
                                <b>{ltp_nome}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Padrão?',
                        width: 100,
                        align: 'center',
                        render: ({ ltp_principal }) => (
                            <div>
                                <b>{ltp_principal ? 'SIM' : 'NÃO'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Data Inicial',
                        align: 'center',
                        width: 100,
                        render: ({ ltp_datainicial }) => (
                            <div>
                                <b>{(ltp_datainicial ? moment(ltp_datainicial).format("DD/MM/YYYY") : '')}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Data Final',
                        align: 'center',
                        width: 100,
                        render: ({ ltp_datafinal }) => (
                            <div>
                                <b>{(ltp_datafinal ? moment(ltp_datafinal).format("DD/MM/YYYY") : '')}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        fixed: 'right',
                        align: 'center',
                        width: 158,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<SyncOutlined />} onClick={() => { openAtualizarPrecos(record) }}>
                                            Atualizar Preços
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Editar icon={<EditOutlined />} loading={record.ltp_id === itemEdit ? loadingEdit : false} onClick={() => {
                                            loadDada(record)
                                        }} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerPreco />
            <DrawerAtualizarPreco />
        </div>
    );

}