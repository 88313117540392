import { Button, Col, Progress, Row, Spin, notification } from "antd";
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { CheckOutlined, CloseOutlined, LoadingOutlined } from "@ant-design/icons";


export default function SincronizarCatalogo() {
    const [progresso, setProgresso] = useState(0);
    const [exibeProg, setExibeProg] = useState(false);
    const [clienteOk, setClienteOk] = useState(false);
    const [produtoOk, setProdutoOk] = useState(false);
    const [deuErro, setDeuErro] = useState(false);
    const [listaErros, setListaErros] = useState([]);

    async function iniciarSincronização() {
        let lista = [];
        try {
            setExibeProg(true);
            setProgresso(0);
            let resCli = await api.post("Siger/SincClientes");
            if (resCli.status == 200) {
                if (resCli.data.length > 0) {
                    lista = resCli.data;
                }
                setClienteOk(true);
                setProgresso(50);
            }
            let resProd = await api.post("Siger/SincProdutos");
            if (resProd.status == 200) {
                if (resProd.data.length > 0) {
                    lista = resProd.data;
                }
                setProgresso(100);
                setProdutoOk(true);
            }
        } catch (ex) {
            setDeuErro(true);
            setListaErros(lista);
        }
    }

    return (<div>
        <Row align="middle">
            {!exibeProg && <Col span={24} style={{ textAlign: "center" }}>
                <Button size="large" type="primary" onClick={iniciarSincronização}>Sincronizar Agora</Button>
            </Col>}
            {exibeProg && <>
                {!deuErro && <Progress style={{ margin: "auto", marginTop: "10px" }} type="circle" percent={progresso} />}
                {deuErro && <Progress style={{ margin: "auto", marginTop: "10px" }} type="circle" status="exception" />}
                <Table style={{ marginTop: "10px", marginLeft: "30px", marginRight: "30px" }} component={Paper}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Modulo</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={1}>
                            <TableCell>Clientes</TableCell>
                            <TableCell>{(!clienteOk && deuErro) && <CloseOutlined />}{!clienteOk && !deuErro && <Spin indicator={<LoadingOutlined spin />} />}{clienteOk && <CheckOutlined />}</TableCell>
                        </TableRow>
                        <TableRow key={2}>
                            <TableCell>Produtos</TableCell>
                            <TableCell>{(!produtoOk && deuErro) && <CloseOutlined />}{(!produtoOk && !deuErro) && <Spin indicator={<LoadingOutlined spin />} />} {produtoOk && <CheckOutlined />}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                {deuErro && <h2 style={{ margin: "30px" }}>Não foi possível sincronizar os dados do catalogo! Favor tente novamente mais tarde, ou entre em contato com nossa central de suporte!</h2>}
                {(progresso == 100 && listaErros.length > 0) && <Col span={24}> <h2 style={{ marginTop: "20px" }}>Clientes/Produtos não importados!</h2>
                    <Table style={{ marginTop: "10px", marginLeft: "30px", marginRight: "30px" }} component={Paper}>
                        <TableBody>
                            {listaErros.map((err, idx) => (
                                <TableRow key={idx}>
                                    <TableCell>{err.mensagem}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table></Col>}
            </>}
        </Row>
    </div >);
}