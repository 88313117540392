import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Divider, Button, Form, Table, Tag, Modal } from "antd";
import { PlusOutlined, ExclamationCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { ModalLoteProduto } from "../../components/modals";
import moment from "moment";

export default function TabLote({ listaLote, setListaLote, casaDecimaisUnd }) {
    const [form] = Form.useForm();
    const [openModal, setOpenModal] = useState(false);
    const [editando, setEditando] = useState(false);
    const [sequencial, setSequencial] = useState(0);

    useEffect(() => {
        const novaListaLote = [...listaLote];
        novaListaLote.forEach((item, index) => {
            item.ordem = index + 1;
        });

        setListaLote(novaListaLote);
    }, [])

    const editarLote = (sequencia) => {
        setSequencial(sequencia);
        setEditando(true);
        setOpenModal(true);
    }

    const modalExcluir = (record) => {
        const index = listaLote.findIndex(item => item.ordem === record.ordem);
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja realmente excluir o lote?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                removerLote(index);
            }
        });
    }

    const removerLote = (index) => {
        const novaListaLote = [...listaLote];
        novaListaLote.splice(index, 1);

        novaListaLote.forEach((item, index) => {
            item.ordem = index + 1;
        });

        setListaLote(novaListaLote);
    }

    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-storage.png").default} alt="Cadastro de Lotes do Produto" /> Cadastro de Lotes do Produto
                    </Typography.Title>
                    <Divider orientation="left" plain> Cadastro de Lotes do Produto </Divider>
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => { form.resetFields(); setOpenModal(true); }} />
                    </div>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Form form={form} component={false}>
                    <Table dataSource={listaLote} columns={[
                        {
                            width: 35,
                            align: 'center',
                            render: ({ ordem }) => (
                                <Tag color="processing" className="w-100">
                                    <b>{ordem}</b>
                                </Tag>
                            )
                        },
                        {
                            title: 'Número',
                            width: 180,
                            align: 'center',
                            render: ({ prl_numerolote }) => (
                                <b>{prl_numerolote}</b>
                            )
                        },
                        {
                            title: 'Qtde',
                            width: 75,
                            align: 'center',
                            render: ({ prl_qtde }) => (
                                <b>{prl_qtde}</b>
                            )
                        },
                        {
                            title: 'Data Entrada',
                            render: ({ prl_dataentrada }) => (
                                <b>{moment(prl_dataentrada).format('DD/MM/YYYY')}</b>
                            )
                        },
                        {
                            title: 'Data Fabricação',
                            render: ({ prl_datafabricacao }) => (
                                <b>{moment(prl_datafabricacao).format('DD/MM/YYYY')}</b>
                            )
                        },
                        {
                            title: 'Data Validade',
                            render: ({ prl_datavencimento }) => (
                                <b>{moment(prl_datavencimento).format('DD/MM/YYYY')}</b>
                            )
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            align: 'center',
                            key: 'x',
                            width: 65,
                            fixed: 'right',
                            render: (record) => {
                                return (
                                    <div>
                                        <Row align="midle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button onClick={() => editarLote(record.ordem)} icon={<EditOutlined />} />
                                            </Col>
                                            <Col>
                                                <Button onClick={() => modalExcluir(record)} icon={<DeleteOutlined />} />
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            }
                        }
                    ]} locale={{
                        emptyText: (
                            <Row>
                                <Col span={24}>
                                    <Divider orientation="center">
                                        <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                    </Divider>
                                </Col>
                            </Row>
                        )
                    }} />
                </Form>
            </div>
            <ModalLoteProduto form={form} listaLote={listaLote} setListaLote={setListaLote} exibirModal={openModal} fecharModal={() => (setOpenModal(false), setEditando(false))} editando={editando} 
                setEditando={setEditando} sequencia={sequencial} casaDecimaisUnd={casaDecimaisUnd} />
        </div>
    );
}