import React, { useEffect, useState } from "react";
import { Form, Row, Col, Typography, Divider, Select, InputNumber, Modal } from "antd";
import { InputPercentual } from '../../components';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import api from "../../services/api";

export default function TabEntrada({ formulario }) {

    const [listaCstPisCofins, setListaCSTPisCofins] = useState([]);
    const [cstpispj, setcstpispj] = useState(null);
    const [cstcofinspj, setcstcofinspj] = useState(null);
    const [cstpispf, setcstpispf] = useState(null);
    const [cstcofinspf, setcstcofinspf] = useState(null);

    useEffect(() => {
        if (!!formulario.getFieldValue(['cpc_entradacstpispj', 'cpc_id'])) {
            setcstpispj(formulario.getFieldValue(['cpc_entradacstpispj', 'cpc_id']));
        }

        if (!!formulario.getFieldValue(['cpc_entradacstcofinspj', 'cpc_id'])) {
            setcstcofinspj(formulario.getFieldValue(['cpc_entradacstcofinspj', 'cpc_id']));
        }

        if (!!formulario.getFieldValue(['cpc_entradacstpispf', 'cpc_id'])) {
            setcstpispf(formulario.getFieldValue(['cpc_entradacstpispf', 'cpc_id']));
        }

        if (!!formulario.getFieldValue(['cpc_entradacstcofinspf', 'cpc_id'])) {
            setcstcofinspf(formulario.getFieldValue(['cpc_entradacstcofinspf', 'cpc_id']));
        }
        api.get(`CstPisCofins/ListarEntradaSaida?tipo=E`).then(
            res => {
                setListaCSTPisCofins(res.data);
            }).catch(
                error => {
                    console.log(error);
                }
            )
    }, [])

    useEffect(() => {
        if (cstpispj && cstcofinspj)
            if (cstpispj !== cstcofinspj)
                Modal.warning({
                    title: 'Atenção!',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Os CST estão diferentes, normalmente o CST PIS e COFINS devem ser iguais - Verifique a situação!',
                    okText: 'Ok',
                });
    }, [cstpispj, cstcofinspj]);

    useEffect(() => {
        if (cstpispf && cstcofinspf)
            if (cstpispf !== cstcofinspf)
                Modal.warning({
                    title: 'Atenção!',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Os CST estão diferentes, normalmente o CST PIS e COFINS devem ser iguais - Verifique a situação!',
                    okText: 'Ok',
                });
    }, [cstpispf, cstcofinspf]);

    return (
        <div>
            <Row align="middle" gutter={[0, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-saida.png").default} alt="Entradas" /> Entradas
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Defina a configuração do PIS e da COFINS para as entradas.
                    </Divider>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={12} lg={13} xl={13}>
                            <Form.Item label="Cód. da Situação Tributária do PIS - Pessoa Jurídica" name={['cpc_entradacstpispj', 'cpc_id']} rules={[{ required: false, message: 'Selecione um CST do PIS' }]}>
                                <Select
                                    placeholder="Selecione o CST"
                                    onChange={(valor) => {
                                        setcstpispj(valor);
                                        if (formulario.getFieldValue(['cpc_entradacstpispj', 'cpc_id']) > 69 && formulario.getFieldValue(['cpc_entradacstpispj', 'cpc_id']) < 76)
                                            formulario.setFieldsValue({ pis_entradapercredbcpispj: 0, pis_entradapercpispj: 0 })
                                    }}>
                                    {listaCstPisCofins.map((item) => (
                                        <Select.Option value={item.cpc_id} key={item.cpc_id}>{item.cpc_id} - {item.cpc_descricao}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <InputPercentual
                                disabled={cstpispj > 69 && cstpispj < 76}
                                label="% Redução Base de Cálculo"
                                name="pis_entradapercredbcpispj"
                                defaultValue={0}
                                min={0}
                                max={100}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                            <InputPercentual
                                disabled={cstpispj > 69 && cstpispj < 76}
                                label="% Alíquota do PIS"
                                name="pis_entradapercpispj"
                                rules={[{ required: (cstpispj < 70 && cstpispj != null) ? true : false, message: 'Informe o % alíquota do PIS' }]}
                                defaultValue={0}
                                min={0}
                                max={100}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={13} xl={13}>
                            <Form.Item label="Cód. da Situação Tributária da COFINS - Pessoa Jurídica"
                                name={['cpc_entradacstcofinspj', 'cpc_id']}
                                rules={[{ required: cstpispj > 0, message: 'Selecione um CST da COFINS' }]}>
                                <Select
                                    placeholder="Selecione o CST"
                                    onChange={(valor) => {
                                        setcstcofinspj(valor);
                                        if (formulario.getFieldValue(['cpc_entradacstcofinspj', 'cpc_id']) > 69 && formulario.getFieldValue(['cpc_entradacstcofinspj', 'cpc_id']) < 76)
                                            formulario.setFieldsValue({ pis_entradapercredbccofinspj: 0, pis_entradaperccofinspj: 0 })
                                    }}>
                                    {listaCstPisCofins.map((item) => (
                                        <Select.Option value={item.cpc_id} key={item.cpc_id}>{item.cpc_id} - {item.cpc_descricao}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <InputPercentual
                                disabled={cstcofinspj > 69 && cstcofinspj < 76}
                                label="% Redução Base de Cálculo"
                                name="pis_entradapercredbccofinspj"
                                defaultValue={0}
                                min={0}
                                max={100}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                            <InputPercentual
                                disabled={cstcofinspj > 69 && cstcofinspj < 76}
                                label="% Alíquota da COFINS"
                                rules={[{ required: (cstcofinspj < 70 && cstcofinspj != null) ? true : false, message: 'Informe o % alíquota do COFINS' }]}
                                name="pis_entradaperccofinspj"
                                defaultValue={0}
                                min={0}
                                max={100}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={13} xl={13}>
                            <Form.Item label="Cód. da Situação Tributária do PIS - Pessoa Física" name={['cpc_entradacstpispf', 'cpc_id']}>
                                <Select
                                    placeholder="Selecione o CST"
                                    onChange={(valor) => {
                                        setcstpispf(valor);
                                        if (formulario.getFieldValue(['cpc_entradacstpispf', 'cpc_id']) > 69 && formulario.getFieldValue(['cpc_entradacstpispf', 'cpc_id']) < 76) formulario.setFieldsValue({ pis_entradapercredbcpispf: 0, pis_entradapercpispf: 0 })
                                    }}>
                                    {listaCstPisCofins.map((item) => (
                                        <Select.Option value={item.cpc_id} key={item.cpc_id}>{item.cpc_id} - {item.cpc_descricao}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <InputPercentual
                                disabled={cstpispf > 69 && cstpispf < 76}
                                label="% Redução Base de Cálculo"
                                name="pis_entradapercredbcpispf"
                                defaultValue={0}
                                min={0}
                                max={100}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                            <InputPercentual
                                disabled={cstpispf > 69 && cstpispf < 76}
                                label="% Alíquota do PIS"
                                name="pis_entradapercpispf"
                                rules={[{ required: (cstpispf < 70 && cstpispf != null) ? true : false, message: 'Informe o % alíquota do PIS' }]}
                                defaultValue={0}
                                min={0}
                                max={100}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={13} xl={13}>
                            <Form.Item label="Cód. da Situação Tributária da COFINS - Pessoa Física" name={['cpc_entradacstcofinspf', 'cpc_id']}
                                rules={[{ required: cstpispf > 0, message: 'Selecione um CST da COFINS' }]}>
                                <Select
                                    placeholder="Selecione o CST"
                                    onChange={(valor) => {
                                        setcstcofinspf(valor);
                                        if (formulario.getFieldValue(['cpc_entradacstcofinspf', 'cpc_id']) > 69 && formulario.getFieldValue(['cpc_entradacstcofinspf', 'cpc_id']) < 76) formulario.setFieldsValue({ pis_entradapercredbccofinspf: 0, pis_entradaperccofinspf: 0 })
                                    }}>
                                    {listaCstPisCofins.map((item) => (
                                        <Select.Option value={item.cpc_id} key={item.cpc_id}>{item.cpc_id} - {item.cpc_descricao}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <InputPercentual
                                disabled={cstcofinspf > 69 && cstcofinspf < 76}
                                label="% Redução Base de Cálculo"
                                name="pis_entradapercredbccofinspf"
                                defaultValue={0}
                                min={0}
                                max={100}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                            <InputPercentual
                                disabled={cstcofinspj > 69 && cstcofinspj < 76}
                                label="% Alíquota do COFINS"
                                rules={[{ required: (cstcofinspf < 70 && cstcofinspf != null) ? true : false, message: 'Informe o % alíquota do COFINS' }]}
                                name="pis_entradaperccofinspf"
                                defaultValue={0}
                                min={0}
                                max={100}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}