import React, { useEffect, useState } from "react";
import { Row, Col, Upload, Button, notification, Form, Typography, Divider, Modal } from "antd";

import { manutencaoActions } from "../../actions";
import { useStateValue } from "../../state";
import { getBase64, validarImportacao } from "../../services/funcoes";
import { CloudUploadOutlined } from "@ant-design/icons";
import { BreadcrumbPage } from "../../components";
import api from "../../services/api";
import { getEntidadeImp, setEntidadeImp } from "../../services/auth";

export default function ImportarPessoa() {
    const [arquivoBase64, setArquivoBase64] = useState("");
    const [arquivo, setArquivo] = useState(null);
    const [{ manutencao }, dispatch] = useStateValue();
    const [entidade, setEntidade] = useState();
    const [carregando, setCarregando] = useState(false);
    const apiArquivo = window.location.href.indexOf('localhost') > -1 || window.location.href.indexOf('homologacao') > -1  ? 'http://homologacao.gestorweb.com.br/' : 'https://app.gestorweb.com.br/';

    const listaEntidades = {
        0: 'Cliente',
        1: 'Fornecedor',
        2: 'Transportador',
        3: 'Funcionario',
        4: 'Vendedor',
        5: 'Produto'
    };

    const listaDocumentosPadrao = {
        0: 'ImportarCliente.xlsx',
        1: 'ImportarFornecedores.xlsx',
        2: 'ImportarTransportador.xlsx',
        3: 'ImportarFuncionario.xlsx',
        4: 'ImportarVendedores.xlsx',
        5: 'ImportarProdutos.xlsx'
    };

    const props = {
        name: 'file',
        multiple: false,
        showUploadList: false,
        maxCount: 1,
        disabled: !!arquivo,
        beforeUpload(file) {
            if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                notification.warning({ description: 'Favor informar uma planilha no formato XLSX!', message: 'Aviso' });
                return false;
            }
            getBase64(file).then(base64 => {
                setArquivoBase64(base64);
                setArquivo(file);
            });
        }
    };

    useEffect(() => {
        if (!!!entidade) {
            setEntidade(getEntidadeImp());
        }
    }, []);

    useEffect(() => {
        if (!!manutencao.dados && (!!manutencao.dados.entidade || manutencao.dados.entidade === 0)) {
            setEntidade(manutencao.dados.entidade);
        }
    }, [manutencao.dados]);

    useEffect(() => {
        if (!!entidade || entidade === 0) {
            setEntidadeImp(entidade);
        }
    }, [entidade])

    function importar() {
        setCarregando(true);
        api.post(`ImportarDados/Importar`, { entidade: { Value: listaEntidades[entidade] }, dados: { Value: arquivoBase64 } }).then(
            (res) => {
                if (!!res.data) {
                    validarImportacao(res.data, listaEntidades[entidade]);
                } else {
                    Modal.error({ title: `Não foi possivel importar a planilha de ${listaEntidades[entidade]}` });
                }
            }
        ).catch(
            (erro) => {
                if (!!erro.response && !!erro.response.data && !!erro.response.data.Message) {
                    notification.warning({ description: erro.response.data.Message, message: 'Aviso' });
                }
                console.log(erro);
            }
        ).finally(
            () => {
                setCarregando(false);
                excluir();
            }
        );
    };

    function excluir() {
        setArquivoBase64("");
        setArquivo(null);
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" gutter={[8, 8]}>
                <Col>
                    <BreadcrumbPage />
                </Col>
            </Row>
            <div className="upload m-t-16">
                <Row>
                    <Col span={24}>
                        <Typography.Title level={3}>
                            <img src={require("../../assets/i-financeiro.png").default} alt={`Importar ${listaEntidades[entidade]}`} /> Importar dados do cadastro de {entidade === 3 ? 'Funcionário' : listaEntidades[entidade]}
                        </Typography.Title>
                        <Divider orientation="left" plain>
                            Importe aqui os dados a partir de uma planilha padrão para o GestorWeb
                        </Divider>
                    </Col>
                </Row>
                <Upload.Dragger {...props}>
                    {!!!arquivo && <div>
                        <Row align="middle" justify="center" gutter={[0, 8]}>
                            <Col span={24}>
                                <p className="ant-upload-drag-icon m-0">
                                    <CloudUploadOutlined />
                                </p>
                            </Col>
                            <Col span={24}>
                                <p>Arraste a planilha aqui! (o arquivo deve estar no formato .xlsx)</p>
                            </Col>
                            <Col span={24}>
                                <Button type="primary" size="large" icon={<CloudUploadOutlined />}>
                                    Importar XLSX
                                </Button>
                            </Col>
                        </Row>
                    </div>}
                    {!!arquivo && <div>
                        <Row align="middle" justify="center" gutter={[8, 8]}>
                            <Col >
                                <svg t="1622205362360" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2101" id="mx_n_1622205362361" data-spm-anchor-id="a313x.7781069.0.i7" width="60" height="60"><path d="M354.40128 0c-87.04 0-157.44 70.55872-157.44 157.59872v275.68128H78.72c-21.6576 0-39.36256 17.69984-39.36256 39.36256v236.31872c0 21.6576 17.69984 39.35744 39.36256 39.35744h118.24128v118.08256c0 87.04 70.4 157.59872 157.44 157.59872h472.63744c87.04 0 157.59872-70.55872 157.59872-157.59872V315.0336c0-41.74848-38.9888-81.93024-107.52-149.27872l-29.11744-29.12256L818.87744 107.52C751.5392 38.9888 711.39328 0 669.59872 0H354.4064z m0 78.72h287.20128c28.35456 7.0912 27.99616 42.1376 27.99616 76.8v120.16128c0 21.6576 17.69984 39.35744 39.36256 39.35744h118.07744c39.38816 0 78.87872-0.0256 78.87872 39.36256v512c0 43.32032-35.55328 78.87872-78.87872 78.87872H354.4064c-43.32544 0-78.72-35.5584-78.72-78.87872v-118.08256h393.91744c21.66272 0 39.36256-17.69472 39.36256-39.35744V472.64256c0-21.66272-17.69984-39.36256-39.36256-39.36256H275.68128V157.59872c0-43.32032 35.39456-78.87872 78.72-78.87872zM145.12128 507.36128h23.99744l39.36256 67.2 40.32-67.2h23.04l-50.88256 83.51744 54.72256 92.16h-24.96l-43.20256-75.83744-43.19744 75.83744h-23.04l54.71744-92.16-50.87744-83.51744z m154.55744 0h32.64l49.92 143.03744h0.96256l48.95744-143.03744h33.60256v175.67744h-22.08256v-106.55744c0-10.88 0.32256-26.56256 0.96256-47.04256h-0.96256l-52.79744 153.6h-19.2l-52.80256-153.6h-0.95744c1.28 22.4 1.92 38.72256 1.92 48.96256v104.63744H299.6736V507.36128z m214.08256 0h22.07744v155.52h69.12v20.15744h-91.19744V507.36128z" p-id="2102"></path></svg>
                            </Col>
                            <Col span={24}>
                                {arquivo.name}
                            </Col>
                            <Col >
                                <Button type="primary" size="large" icon={<CloudUploadOutlined />} onClick={() => { importar() }} loading={carregando}>
                                    Importar
                                </Button>
                            </Col>
                            <Col>
                                <Button type="primary" size="large" icon={<CloudUploadOutlined />} onClick={() => { excluir() }}>
                                    Remover
                                </Button>
                            </Col>
                        </Row>
                    </div>}
                </Upload.Dragger>
                <Row justify={"start"}>
                    <a href={`${apiArquivo}arquivos/${listaDocumentosPadrao[entidade]}`} download style={{ 'marginRight': '5px' }}>Clique aqui</a> para realizar o download da planilha modelo para importar os dados.
                </Row>
            </div>
        </div>
    );
};