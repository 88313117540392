import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Select, Table, Button, Modal, Card, Radio, InputNumber, Tag, Divider, notification } from "antd";
import { CloseOutlined, PlusOutlined, RedoOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { info } from "../../services/funcoes";
import { InputPreco, SelectPaginacao, SelectPaginado } from "../../components";
import { listagemActions, selectPaginadoActions } from "../../actions";
import { getEmpresa } from "../../services/auth";
import { FormatNumber } from '../../ValueObjects';

export default function AtualizarPreco({ formulario, carregando, aoSalvar }) {

    const [listaItens, setListaItens] = useState([]);
    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [quantidadeEncontrados, setQuantidadeImportar] = useState(0);

    useEffect(() => {
        if (ui.showDrawerExtra) {
            if (!!formulario.getFieldValue('itens')) {
                setListaItens(formulario.getFieldValue('itens'));
            } else {
                setListaItens([]);
            }
            buscarQuantidadeProdutos();
            if (!formulario.getFieldValue().tipoAtualizacaoPreco) {
                formulario.setFieldsValue({ tipoAtualizacaoPreco: 0 });
            }
        }
    }, [ui.showDrawerExtra]);

    function buscarQuantidadeProdutos() {
        let filtros = '';
        if (!!formulario.getFieldValue().mar_id) {
            filtros += `&idMarca=${formulario.getFieldValue().mar_id}`;
        }
        if (!!formulario.getFieldValue().gru_id) {
            filtros += `&idGrupo=${formulario.getFieldValue().gru_id}`;
        }
        if (!!formulario.getFieldValue().sgp_id) {
            filtros += `&idSubGrupo=${formulario.getFieldValue().sgp_id}`;
        }
        api.get(`ListaPreco/QuantidadeProdutosImportar?idListaOrigem=${formulario.getFieldValue().ltp_id}${filtros}`).then(res => {
            if (res.status === 200) {
                setQuantidadeImportar(res.data);
            }
        }).catch((erro) => console.error(erro));
    };

    function limparFiltros() {
        formulario.setFieldsValue({ mar_id: null, gru_id: null, sgp_id: null });
    };

    function popularDadosSubGrupo(codigoGrupo) {
        let lista = [];
        lista.push({ name: "sgp_id", campo: "CodigoGrupo", value: codigoGrupo });
        if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: {itens: lista} });
    };

    function atualizarPrecos(values) {
        if (parseFloat(values.reajustarValor) > 0) {
            let valuesEnviar = {};
            valuesEnviar.filtrosAdicional = null;//não implementado
            valuesEnviar.gru_id = !!values.gru_id ? values.gru_id : null;
            valuesEnviar.ltp_id = values.ltp_id;
            valuesEnviar.mar_id = !!values.mar_id ? values.mar_id : null;
            valuesEnviar.spg_id = !!values.sgp_id ? values.sgp_id : null;
            valuesEnviar.tipoAtualizacaoPreco = values.tipoAtualizacaoPreco;

            if (valuesEnviar.tipoAtualizacaoPreco === 2 || valuesEnviar.tipoAtualizacaoPreco === 4) {
                valuesEnviar.reajustarValor = parseFloat(values.reajustarValor) / 100;
            } else {
                valuesEnviar.reajustarValor = parseFloat(values.reajustarValor);
            }

            Modal.confirm({
                title: 'Confirmar atualização de preços?',
                icon: <ExclamationCircleOutlined />,
                content: '',
                okText: 'Sim, atualizar preços',
                cancelText: 'Não',
                centered: true,
                onOk() {
                    api.post('ListaPreco/AtualizarPrecosProdutos', valuesEnviar).then(res => {
                        if (res.status === 200) {
                            Modal.success({
                                title: 'Preços atualizados com sucesso!',
                                content: `Os preços dos ${listaItens.length} produtos localizados da lista ${formulario.getFieldValue().ltp_nome} foram atualizados com sucesso!`,
                            });
                            dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ltp_id' } });
                            aoSalvar();
                        }
                    }, err => {
                        Modal.error({
                            title: 'Atenção!',
                            content: (
                                <div>
                                    <p>Ocorreu um erro ao tentar realizar o reajuste de preços!</p>
                                </div>
                            ),
                        });
                    });
                }
            });
        } else {
            info('Você deve informar o valor ou percentual para realizar o reajuste de preços!');
        }
    };

    return (
        <div className="pages-col">
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-financeiro.png").default} alt="Atualização de Preços de Produtos" /> Atualização de Preços de Produtos
                    </Typography.Title>
                    <Divider orientation="left" />
                </Col>
            </Row>
            <Row align="middle" className="m-t-8">
                <Col span={24}>
                    <Typography.Title level={5}>
                        Lista de Preços: <b>{formulario.getFieldValue().ltp_nome}</b>
                    </Typography.Title>
                </Col>
            </Row>
            <Row gutter={[0, 16]}>
                <Col span={24}>
                    <Typography.Text>
                        Para realizar a atualização dos preços configure os filtros abaixo:
                    </Typography.Text>
                </Col>
            </Row>
            <Form layout="vertical" form={formulario} name="manutencaoAtualizar" onFinish={atualizarPrecos}>
                <Row gutter={[8, 0]} className="m-t-8">
                    <Form.Item name="ltp_id">
                        <Input hidden />
                    </Form.Item>
                    <Col xs={24} sm={8} md={80} lg={6} xl={6}>
                        <Form.Item label="Marca" name="mar_id">
                            <SelectPaginacao url="Marca/Listar" allowClear={true} nameValue="mar_id" nameLabel="mar_nome" placeholder='Selecione a Forma de Pagamento' form={formulario} conteudo={
                                (marca) => (
                                    <Select.Option value={marca.mar_id} key={marca.mar_id}>{marca.mar_nome}</Select.Option>
                                )} onClearFunction={() => { formulario.setFieldsValue({ "mar_id": null }) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={5} xl={5}>
                        <Form.Item label="Grupo" name="gru_id">
                            <SelectPaginacao
                                nameValue="gru_id"
                                nameLabel="gru_nome"
                                form={formulario}
                                url='GrupoProduto/Listar'
                                allowClear={true}
                                placeholder='Selecione o Grupo'
                                onChangeFunction={(value) => popularDadosSubGrupo(value)}
                                conteudo={
                                    (grupo) => (
                                        <Select.Option value={grupo.gru_id} key={grupo.gru_id}>{grupo.gru_nome}</Select.Option>
                                    )} onClearFunction={() => { formulario.setFieldsValue({ "gru_id": null }) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={6} xl={6}>
                        <Form.Item label="Subgrupo" name="sgp_id">
                            <SelectPaginacao url="Subgrupo/Listar" nameValue="sgp_id" allowClear={true} nameLabel="sgp_nome" placeholder="Selecione o Subgrupo" form={formulario} conteudo={
                                (subGrupo) => (
                                    <Select.Option value={subGrupo.sgp_id} key={subGrupo.sgp_id}>{subGrupo.sgp_nome}</Select.Option>
                                )} onClearFunction={() => { formulario.setFieldsValue({ "sgp_id": null }) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={7} xl={7}>
                        <Row gutter={[8, 8]} justify="end" className="t-mob t-5">
                            <Col xs={24} sm={6} md={5} lg={12} xl={12}>
                                <Button icon={<CloseOutlined />} onClick={() => { limparFiltros() }} htmlType="button" block>
                                    Limpar Filtros
                                </Button>
                            </Col>
                            <Col xs={24} sm={6} md={5} lg={12} xl={12}>
                                <Button type="primary" icon={<PlusOutlined />} onClick={() => { buscarQuantidadeProdutos() }} block>
                                    Aplicar Filtros
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Card className="m-t-16">
                    <Row justify="center" gutter={[16, 0]}>
                        <Col xs={24} sm={24} md={17} lg={17} xl={17}>
                            <Form.Item name="tipoAtualizacaoPreco" label="O que você deseja fazer com os preços destes produtos?" initialValue={0}>
                                <Radio.Group className="m-t-8">
                                    <Radio value={0}>Definir um novo valor especifico para os produtos nesta lista de preços</Radio>
                                    <br />
                                    <Radio value={1}>Aumentar o preço de venda atual, aplicando um aumento fixo em reais (R$)</Radio>
                                    <br />
                                    <Radio value={2}>Aumentar o preço de venda atual, aplicando um aumento percentual sobre o valor de venda atual (%)</Radio>
                                    <br />
                                    <Radio value={3}>Diminuir o preço de venda atual, aplicando uma redução fixa em reais (R$)</Radio>
                                    <br />
                                    <Radio value={4}>Diminuir o preço de venda atual, aplicando uma redução percentual sobre o valor de venda atual (%)</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={7} lg={7} xl={7}>
                            <Row justify="center" gutter={[8, 0]}>
                                <Col>
                                    <Typography.Text>
                                        Produtos Encontrados
                                    </Typography.Text>
                                </Col>
                            </Row>
                            <Row justify="center" gutter={[8, 0]}>
                                <Col>
                                    <Typography.Text>
                                        <b className="f-18">{quantidadeEncontrados}</b>
                                    </Typography.Text>
                                </Col>
                            </Row>
                            <Row justify="center" gutter={[8, 0]}>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.tipoAtualizacaoPreco !== currentValues.tipoAtualizacaoPreco}>
                                        {({ getFieldValue }) => {
                                            if (getFieldValue('tipoAtualizacaoPreco') === 0) return (
                                                <InputPreco label="Fixar este preço R$" name="reajustarValor" />
                                            );
                                            if (getFieldValue('tipoAtualizacaoPreco') === 1) return (
                                                <InputPreco label="Aumentar preço em R$" name="reajustarValor" />
                                            );
                                            if (getFieldValue('tipoAtualizacaoPreco') === 3) return (
                                                <InputPreco label="Diminuir preço em R$" name="reajustarValor" />
                                            );
                                            else return (
                                                <Form.Item label={getFieldValue('tipoAtualizacaoPreco') === 2 ? 'Aumentar preço em %' : 'Diminuir preço em %'} name="reajustarValor">
                                                    <InputNumber
                                                        min={0}
                                                        max={100}
                                                        formatter={value => `${value}%`}
                                                        parser={value => value.replace('%', '')} />
                                                </Form.Item>
                                            );
                                        }}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row justify="end" gutter={[8, 8]}>
                        <Col>
                            <Button type="primary" icon={<RedoOutlined />} onClick={() => formulario.submit()}>
                                Atualizar Preços
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Form>
            <div className="tabela mt-dif">
                <Table dataSource={listaItens} columns={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ pro_codigo }) => (
                                <Tag color="processing" className="w-100">
                                    <b>{pro_codigo}</b>
                                </Tag>
                            ),
                        },
                        {
                            title: 'Descrição',
                            render: ({ pro_descricao }) => (
                                <div>
                                    <b>{pro_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Valor Item (R$)',
                            align: 'right',
                            fixed: 'right',
                            render: ({ lpi_valorvenda }) => (
                                <div>
                                    <b>{FormatNumber(lpi_valorvenda, true)}</b>
                                </div>
                            ),
                        }
                    ]
                } expandedRowRender={({ pro_ean, descricaoGrade }) => {
                    return (
                        <div>
                            <Row align="middle" gutter={[8, 0]}>
                                <Col>
                                    Código de Barras (EAN): <b>{pro_ean}</b>
                                </Col>
                                <Col>
                                    Grade: <b>{descricaoGrade}</b>
                                </Col>
                            </Row>
                        </div>
                    )
                }} expandIconColumnIndex={-1} locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                </Divider>
                            </Col>
                        </Row>)
                }} />
            </div>
        </div>
    );

}