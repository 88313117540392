import React, { useState, useEffect } from "react";
import { Form, Row, Col, Typography, Divider, Input, Select, notification } from "antd";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { InputPercentual, InputPreco, SelectPaginacao, SelectPaginado } from "../../components";
import { selectPaginadoActions, manutencaoActions } from "../../actions";
import { getRegimeTributario } from "../../services/auth";
import { debounce } from "@material-ui/core";

export default function TabProdutoTributacao({ formulario }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [listaCest, setListaCest] = useState([]);
    const [regimeTrib, setRegimeTrib] = useState(parseInt(getRegimeTributario()));
    const [listaCstOrigem, setListaCstOrigem] = useState([]);
    const [hiddenFci, setHiddenFci] = useState(true);
    const [showGas, setShowGas] = useState(false);
    const [listaAnp, setlistaAnp] = useState([]);
    const [listaTipoProdutosSped, setListaTipoProdutoSped] = useState([]);
    const [listaNcm, setListaNcm] = useState([]);

    useEffect(() => carregarDados(), []);

    useEffect(() => {
        if (listaTipoProdutosSped.length > 0) {
            if (!!!formulario.getFieldValue().tpi_id) {
                formulario.setFieldsValue({ tpi_id: listaTipoProdutosSped[0].tpi_id });
            }
        }
    }, [listaTipoProdutosSped])

    function carregarDados() {
        if (!!manutencao.dados?.ncm_codigo) {
            api.get(`produto/BuscarCest?CodigoNcm=${manutencao.dados.ncm_codigo}`).then(res => {
                if (res.status === 200) setListaCest(res.data);
            }).catch((erro) => console.error(erro));
        }

        api.get(`CstOrigemMercadoria/ListarTodos`).then(res => {
            if (res.status === 200) {
                setListaCstOrigem(res.data);
                if (!!manutencao.dados?.orm_id) {
                    let number = parseInt(manutencao.dados.orm_id)
                    formulario.setFieldsValue({ orm_id: res.data[number].orm_id });
                } else {
                    formulario.setFieldsValue({ orm_id: res.data[0].orm_id });
                }
                checkMarcadores();
            }
        }).catch((erro) => console.error(erro));

        api.get(`tipoprodutosped/listar`).then(res => {
            if (!!formulario.getFieldValue().tpi_id && res.data.length > 0 && !res.data?.find((tp) => { return tp.tpi_id === formulario.getFieldValue().tpi_id })) {
                formulario.setFieldsValue({ tpi_id: null });
            }
            setListaTipoProdutoSped(res.data);

        }).catch((erro) => console.error(erro));

        let lista = [];
        if (!!manutencao.dados?.ncm_id)
            lista.push({ name: "ncm_id", campo: "IdNcm", value: manutencao.dados?.ncm_id });

        if (!!manutencao.dados?.icm_id)
            lista.push({ name: "icm_id", campo: "Codigo", value: manutencao.dados?.icm_id })

        if (!!manutencao.dados?.pis_id)
            lista.push({ name: "pis_id", campo: "Id", value: manutencao.dados?.pis_id });

        if (!!manutencao.dados?.anp_id) {
            lista.push({ name: "anp_id", campo: "IdAnp", value: manutencao.dados?.anp_id });
            if (manutencao.dados?.anp_codigo === 210203001)
                setShowGas(true);
        }
        if (!!manutencao.dados?.cbf_id && regimeTrib === 3)
            lista.push({ name: "cbf_id", campo: "Id", value: manutencao.dados?.cbf_id });

        if (lista.length > 0) {
            dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }
    };


    function checkMarcadores() {
        if (formulario.getFieldValue(['orm_id']) == 0 || formulario.getFieldValue(['orm_id']) == 4)
            setHiddenFci(true);
        else
            setHiddenFci(false);
    };
    function checkAnp(anp) {
        // conforme NT
        if (!!anp && anp.anp_codigo === 210203001) {
            formulario.setFieldsValue({ anp_codigo: anp.anp_codigo });
            setShowGas(true);
        }
        else {
            if (anp)
                formulario.setFieldsValue({ anp_codigo: anp.anp_codigo });
            else
                formulario.setFieldsValue({ anp_codigo: null });
            setShowGas(false);
        }
    };


    function buscarCest(ncm) {
        if (listaNcm.length > 0) {
            let _lista = [...listaNcm]
            if (!!formulario.getFieldValue().ncm_id) {
                let ncm = _lista.find(c => c.ncm_id == formulario.getFieldValue().ncm_id)
                formulario.setFieldsValue({ ncm_ativo: ncm.ncm_ativo });
                console.log(ncm);
                if (ncm.ncm_ativo === false)
                    notification.warn({ message: 'Atenção', description: "NCM está inativo na base de dados do SEFAZ! Certifique-se de que o NCM que você está usando está correto na aba Dados Tributários" });
            }
        }
        if (!!ncm) {
            api.get(`produto/BuscarCest?IdNcm=${ncm}`).then(res => {
                if (res.status === 200) setListaCest(res.data);
                formulario.setFieldsValue({ cen_id: null });
            }).catch((erro) => console.error(erro));
        }
    };


    const onFinish = values => {
        console.log('values trib', values);
    };

    return (
        <div>
            <Row align="middle" gutter={[0, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-bill.png").default} alt="Dados Tributários" /> Dados Tributários
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Defina as Tributações do Produto.
                    </Divider>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <Form.Item label="Tipo de Produto - SPED" name="tpi_id" rules={[{ required: true, message: 'Informe o Tipo de Produto para o Sped' }]}>
                                <Select
                                    placeholder="Selecione o tipo de produto"
                                    showSearch
                                    allowClear
                                    optionFilterProp="children">
                                    {listaTipoProdutosSped.map((tp) => (<Select.Option value={tp.tpi_id} key={tp.tpi_id}>{tp.tpi_id} - {tp.tpi_descricao} </Select.Option>))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Form.Item label="Código Situação Tributária Origem " name="orm_id" rules={[{ required: true, message: 'Selecione a origem do Produto' }]}>
                                <Select
                                    placeholder="Selecione a Origem do Produto"
                                    onChange={checkMarcadores}
                                    showSearch
                                    allowClear
                                    optionFilterProp="children">
                                    {listaCstOrigem.map((ori) => (<Select.Option key={ori.orm_id} value={ori.orm_id}>{ori.orm_id} - {ori.orm_descricao}</Select.Option>))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <Form.Item label="FCI - Ficha de Conteúdo de Importação" name="pro_fci"
                                hidden={hiddenFci}>
                                <Input placeholder="Informe Ficha de Conteúdo de Importação" maxLength={36} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Form.Item label="NCM - Nomenclatura Comum do Mercosul" name="ncm_id" rules={[{ required: true, message: 'Selecione a Classificação NCM' }]}>
                                <SelectPaginacao url="ncm/Listar"
                                    nameLabel="ncm_descricao"
                                    nameValue="ncm_id"
                                    form={formulario}
                                    filtroExtra={"&ativo=1"}
                                    usaDados={true}
                                    placeholder="NCM - Nomenclatura Comum do Mercosul"
                                    setListaDados={setListaNcm}
                                    onClearFunction={(ncm) => buscarCest(ncm)} onChangeFunction={(ncm) => buscarCest(ncm)}
                                    conteudo={
                                        ncm => (<Select.Option value={ncm.ncm_id} key={ncm.ncm_id}>{ncm.ncm_codigo.codigoNcm} - {ncm.ncm_descricao}</Select.Option>)}
                                />
                            </Form.Item>
                        </Col>
                        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.hiddenCest !== currentValues.hiddenCest}>
                            {listaCest.length > 0 &&
                                <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                                    <Form.Item label="CEST" name="cen_id" rules={listaCest.length > 0 ? [{ required: true, message: 'Selecione a Classificação do CEST' }] : null}>
                                        <Select
                                            placeholder="Selecione a Classificação do CEST"
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            options={listaCest.map((cest) => { return { label: `${cest.cet_id} - ${cest.cet_descricao}`, value: cest.cen_id } })}>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            }
                        </Form.Item>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Form.Item label="Classificação ICMS" name="icm_id" rules={[{ required: true, message: 'Selecione a Classificação ICMS' }]}>
                                <SelectPaginado url="tributacaoIcms/Listar"
                                    name="icm_id"
                                    form={formulario}
                                    placeholder="Selecione o ICMS"
                                    conteudo={
                                        icms => (<Select.Option value={icms.icm_id} key={icms.icm_id}>{icms.icm_id} - {icms.icm_descricao}</Select.Option>)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                            <Form.Item label="Classificação PIS / COFINS" name="pis_id" rules={[{ required: regimeTrib === 3 ? true : false, message: 'Selecione a Classificação PIS / COFINS' }]}>
                                <SelectPaginado url="tributacaoPisCofins/Listar"
                                    name="pis_id"
                                    form={formulario}
                                    placeholder="Selecione o Pis/Cofins"
                                    allowClear={true}
                                    conteudo={
                                        pis => (<Select.Option value={pis.pis_id} key={pis.pis_id}>{pis.pis_id} - {pis.pis_descricao}</Select.Option>)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={regimeTrib === 3 ? 8 : 24} xl={regimeTrib === 3 ? 8 : 24}>
                            <Form.Item label="Código ANP (Somente produto derivado do Petróleo)" name="anp_id">
                                <SelectPaginacao url="TabelaAnp/Listar"
                                    allowClear
                                    nameLabel="anp_codigo"
                                    nameValue="anp_id"
                                    form={formulario}
                                    setListaDados={setlistaAnp}
                                    onChangeFunction={(anp) => {
                                        checkAnp(listaAnp.find(l => l.anp_id == anp));
                                    }}
                                    onClearFunction={(anp) => checkAnp(anp)}
                                    placeholder="Selecione o Código ANP(Caso For combustível)"
                                    conteudo={
                                        anp => (<Select.Option value={anp.anp_id} key={anp.anp_id}>{anp.anp_codigo} - {anp.anp_descricao}</Select.Option>)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        {regimeTrib === 3 && <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                            <Form.Item label="Código Benefício Fiscal" name="cbf_id">
                                <SelectPaginado url="TabelaCbenef/Listar"
                                    placeholder="Informe o Código Benefício Fiscal"
                                    form={formulario}
                                    allowClear
                                    name="cbf_id"
                                    conteudo={
                                        v => (<Select.Option value={v.cbf_id} key={v.key}>{v.cbf_codigo} - {v.cbf_descricao} </Select.Option>)
                                    } />
                            </Form.Item>
                        </Col>}
                        {showGas && <>
                            <Col xs={6} sm={6} md={4} lg={4} xl={4}>
                                <InputPercentual name="prc_percgasnatural"
                                    label="Gás Natural (%) MixGN"
                                    max={100}
                                    min={0}
                                    precision={4}
                                    percent={true}
                                />
                            </Col>
                            <Col xs={6} sm={6} md={4} lg={4} xl={4}>
                                <InputPercentual name="prc_percglp" rules={[{ required: true, message: 'Informe o % de gás natural derivado do petroleo' }]}
                                    label="Gás Natural (%) GLP"
                                    max={100}
                                    min={0}
                                    precision={4}
                                />
                            </Col>
                            <Col xs={6} sm={6} md={4} lg={4} xl={4}>
                                <InputPercentual name="prc_percglpnacional" rules={[{ required: true, message: 'Informe o % de gás natural(Nacional) derivado do petroleo' }]}
                                    label="Gás Nacional (%) GNn"
                                    max={100}
                                    min={0}
                                    precision={4}
                                />
                            </Col>
                            <Col xs={6} sm={6} md={4} lg={4} xl={4}>
                                <InputPercentual name="prc_percglpimportado" rules={[{ required: true, message: 'Informe o % de gás natural(Importado) derivado do petroleo' }]}
                                    label="Gás Importado (%) GNi"
                                    max={100}
                                    min={0}
                                    precision={4}
                                />
                            </Col>
                            <Col xs={6} sm={6} md={4} lg={4} xl={4}>
                                <InputPreco name="prc_valorpartida"
                                    label="Valor Partida "
                                    precision={2}
                                />
                            </Col>
                            <Col xs={6} sm={6} md={4} lg={4} xl={4}>
                                <InputPreco name="prc_qtdetemperatura"
                                    label="Qtde Faturada Temp. Ambiente"
                                    precision={4}
                                />
                            </Col>
                        </>}
                    </Row>
                </Col>
            </Row>
            <Row gutter={[0, 8]}>
                <Col span={24}>
                    <Form.Item label="Observações Complementares" name="pro_observacao">
                        <Input.TextArea placeholder="Informe Observações Referente o Produto" />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}