import React, { useState, useEffect } from "react";
import { Tabs, Row, Col, Form, Input, Select, Radio, Checkbox, notification, Modal, Typography, Divider, InputNumber } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import api from "../../services/api";
import { useStateValue } from "../../state";
import { manutencaoActions, selectPaginadoActions } from "../../actions";
import { FormGW, Data, FormularioDinamico, InputCpfCnpj, SelectPaginacao } from "../../components";
import { Endereco, Email, Telefone, Ocupacao, OutrosContatos } from '../pessoas/pages';
import { trataRetornoFormularioDinamico, trataSetaDadosFormularioDinamico, HasValue, validaForm, validarCpf, isObjetoDiffVazio } from "../../services/funcoes";
import SelectPaginado from "../../components/selectPaginado";

export default function ManutencaoVendedor({ formulario, salvandoDados, aoSalvar, editando, pagina }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [opcoesCriarUsuario, setOpcoesCriarUsuario] = useState(0);
    const [listaUsuarios, setListaUsuarios] = useState([]);
    const [listaEstadoCivil, setListaEstadoCivil] = useState([]);
    const [listaUsuariosLocal, setListaUsuariosLocal] = useState([]);
    const [desabilitarCfpCnpj, setDesabilitarCpfCnpj] = useState(false);
    const [elementosFormulario, setElementosFormulario] = useState([]);
    const [exibirOpcoes, setExibirOpcoes] = useState(true);
    const [listaCidades, setListaCidades] = useState(false);
    const [filtroCdd, setFiltroCdd] = useState(null);

    useEffect(() => {
        formulario.setFieldsValue({ criarUsuario: opcoesCriarUsuario })
    }, [opcoesCriarUsuario]);

    useEffect(() => {
        if (!!pagina.pagina && pagina.pagina > parseInt(abaSelecionada)) {
            validarAbas(pagina.pagina.toString());
        }
    }, [pagina]);

    useEffect(() => {
        if (!!abaSelecionada && pagina.pagina !== parseInt(abaSelecionada)) {
            pagina.setPagina(parseInt(abaSelecionada));
        }
    }, [abaSelecionada]);

    useEffect(() => {
        if (ui.showDrawer && !!manutencao.dados && !!manutencao.dados.ven_id && editando.editando) {
            listaVendedor(manutencao.dados.ven_id);
            let lista = [];
            if (!!manutencao.dados.pef_rguf) {
                lista.push({ name: "pef_rguf", campo: "Uf", value: manutencao.dados.pef_rguf });
            }
            if (!!manutencao.dados.pef_codigocidadenascimento) {
                lista.push({ name: "pef_codigocidadenascimento", campo: "IdCidade", value: manutencao.dados.pef_codigocidadenascimento });
            }
            if (lista.length > 0) {
                dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                editando.setEditando(false);
            }
            if (manutencao.dados.key === undefined) {
                editando.setEditando(false);
            }
            formulario.setFieldsValue(manutencao.dados);

            if (manutencao.dados.vendedorcampopersonalizados?.length > 0) {
                carregaDadosCamposAdicionais();
            }

            if (manutencao.dados?.usu_id > 0) {
                setOpcoesCriarUsuario(2)
                setExibirOpcoes(false);
            }
        } else {
            setFocusCpfCnpj();
        }
    }, [ui.showDrawer, manutencao.dados]);

    useEffect(() => {
        carregarDados();
        carregaCamposPersonalizados();
    }, []);
    useEffect(() => {
        if (!!manutencao.dados) {
            let _cpfCnpj = formulario.getFieldValue().pef_cpf ? formulario.getFieldValue().pef_cpf : manutencao.dados.pef_cpf;
            _cpfCnpj = _cpfCnpj.replace(/[^\d]+/g, '');
            if (HasValue(_cpfCnpj)) {
                if (_cpfCnpj.length === 11) {
                    if (validarCpf(_cpfCnpj)) {
                        setDesabilitarCpfCnpj(true);
                    }
                }
            }
        } else {
            setFocusCpfCnpj();
        }
    }, [manutencao.dados]);

    useEffect(() => { if (!!elementosFormulario && elementosFormulario.length > 0) carregaDadosCamposAdicionais(); }, [elementosFormulario]);

    function setFocusCpfCnpj() {
        setTimeout(
            () => {
                try {
                    let element = document.getElementById("pef_cpf");
                    if (element) {
                        element.focus();
                    }
                } catch (error) {
                    console.log('error :>> ', error);
                }
            }, 300);
    }
    function carregarCidades(UfEstado) {
        if (!!UfEstado) {
            setListaCidades(true);
            setFiltroCdd(` &UfEstado=${UfEstado}`);
        }
        else {
            setListaCidades(false);
        }
    }
    const salvarManutencao = values => {
        let usu = {}
        if (values.criarUsuario === 1) {
            usu = { operacao: values.criarUsuario, email: values.emailUsuario };
        } else if (values.criarUsuario === 2) {
            usu = { operacao: values.criarUsuario, usu_id: values.usu_id, email: listaUsuarios.find(item => item.usu_id === values.usu_id).usu_email };
        } else {
            usu = { operacao: 0, email: '' };
        }
        values.usuarioVinculado = usu;
        values.pessoaemails = !!manutencao.dados.emails && manutencao.dados.emails.length > 0 ? manutencao.dados.emails : [];
        values.pessoasocupacoes = !!manutencao.dados.ocupacoes && manutencao.dados.ocupacoes.length > 0 ? manutencao.dados.ocupacoes : [];
        values.regioesVenda = !!manutencao.dados.regioes && manutencao.dados.regioes.length > 0 ? manutencao.dados.regioes : [];
        values.nacionalidades = !!manutencao.dados.nacionalidades && manutencao.dados.nacionalidades.length > 0 ? manutencao.dados.nacionalidades : [];
        values.pessoaoutrocontatos = !!manutencao.dados.pessoaoutrocontatos && manutencao.dados.pessoaoutrocontatos.length > 0 ? manutencao.dados.pessoaoutrocontatos : [];
        values.pessoatelefones = manutencao.dados.telefones;
        values.enderecos = !!manutencao.dados.enderecos && manutencao.dados.enderecos.length > 0 ? manutencao.dados.enderecos : [];
        values.pef_dataalteracao = moment(new Date());
        values.vendedorcampopersonalizados = salvarCamposAdicionais(values);

        salvandoDados(true);
        if (!!values.ven_id) {
            api.put(`Vendedor/Editar`, values).then(
                res => {
                    notification.success({ message: 'Sucesso!', description: `Vendedor editado com sucesso!` });
                }
            ).catch(
                erro => {
                    console.log(erro);
                    notification.error({ message: 'Erro ao Gravar dados do Vendedor.', description: JSON.stringify(erro.response) });
                }
            ).finally(
                () => {
                    salvandoDados(false);
                    aoSalvar();
                }
            );
        } else {
            api.post(`Vendedor/Incluir`, values).then(
                res => {
                    notification.success({ message: 'Sucesso!', description: `Vendedor incluido com sucesso!` });
                    let cpfFuncionario = values.pef_cpf.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
                    listarVendedorCpf(cpfFuncionario, (dadosVendedor) => {
                        modalAdicionarFuncionarioVendedor(dadosVendedor);
                    });
                }
            ).catch(
                erro => {
                    console.log(erro);
                    notification.error({ message: 'Erro ao Gravar dados do Vendedor.', description: JSON.stringify(erro.response) });
                }
            ).finally(
                () => {
                    salvandoDados(false);
                    aoSalvar();
                }
            );
        }

    };

    const salvarCamposAdicionais = (values) => {
        var camposAdicionais = [];
        elementosFormulario?.map((elemento) => {
            values = trataRetornoFormularioDinamico(values, elemento);
            let vendedorcampopersonalizados = { ...manutencao.dados };
            let forDados = vendedorcampopersonalizados.vendedorcampopersonalizados?.filter(f => f.cap_id == elemento.cap_id)[0];
            if ((!!forDados ? forDados.vcp_valor : "") !== (!!values[elemento.cap_nomeelemento] ? values[elemento.cap_nomeelemento] : "")) {
                camposAdicionais.push({ vcp_id: !!forDados ? forDados.vcp_id : null, ven_id: values.ven_id, cap_id: elemento.cap_id, vcp_valor: values[elemento.cap_nomeelemento] });
            }
        });
        return camposAdicionais;
    }

    const listaValidacoesVen = [
        { nome: 'pef_cpf', obrigatorio: true, label: 'CPF' },
        { nome: 'pes_nome', obrigatorio: true, label: 'Nome' },
    ];

    function validarAbas(tab) {
        let listaValidacoes = [];
        let valida = false;
        switch (abaSelecionada) {
            case '1':
                listaValidacoes = [...listaValidacoesVen];
                if (formulario.getFieldValue().criarUsuario === 1) {
                    listaValidacoes.push({ nome: 'emailUsuario', obrigatorio: true, label: 'E-mail Principal do Vendedor' });
                } else if (formulario.getFieldValue().criarUsuario === 2) {
                    listaValidacoes.push({ nome: 'usu_id', obrigatorio: true, label: 'Usuário para vincular ao Vendedor' });
                }
                valida = true;
                break;
            case '3':
                if (!(!!manutencao.dados.enderecos && manutencao.dados.enderecos.length > 0)) {
                    notification.warning({ description: 'Favor informar ao menos um endereço!', message: 'Aviso' });
                    pagina.setPagina(parseInt(abaSelecionada));
                    return;
                }
                break;
        }
        if (validaForm(formulario, listaValidacoes) && valida) {
            setAbaSelecionada(tab);
        } else if (!valida) {
            setAbaSelecionada(tab);
        } else {
            pagina.setPagina(parseInt(abaSelecionada));
        }
    };

    function carregaCamposPersonalizados() {
        api.get(`CamposPersonalizados/ListarTodos?TipoEntidade=6&PageNumber=1&PageSize=1000&Order=+cap_ordem`).then(res => {
            if (res.status === 200) {
                setElementosFormulario(res.data.items);
            }
        }).catch(
            (erro) => {
                console.log(erro);
                alert('Erro ao carregar Campos personalizados!');
            }
        );
    };

    function carregaDadosCamposAdicionais() { //Campos Adicionais
        let camposPersonalizados = { ...manutencao.dados };
        camposPersonalizados?.vendedorcampopersonalizados?.map(d => {
            let elemento = elementosFormulario.filter(f => f.cap_id === d.cap_id)[0];
            if (!!elemento)
                trataSetaDadosFormularioDinamico(formulario, elemento, d.vcp_valor);
        })
    }

    function carregarDados() {
        salvandoDados(false);
        let parametro = !!formulario.getFieldValue().ven_id ? `?codigoVendedor=${formulario.getFieldValue().ven_id}` : '';
        api.get(`Vendedor/ListarUsuariosNaoVinculadosVendedor${parametro}`).then(
            res => {
                setListaUsuarios(res.data);
            }).catch(
                erro =>
                    console.error(erro)
            );
        api.get(`Usuario/RetornaUsuariosLocal?filtro=`).then(
            res => {
                setListaUsuariosLocal(res.data);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )

        api.get(`EstadoCivil/Listar`).then(
            res => {
                setListaEstadoCivil(res.data.items);
            }).catch(
                erro =>
                    console.error(erro)
            );
    }

    function validarEmail(email) {
        email = email.target.value;
        if (!!email) {
            let emailUsuarioExistente = false;
            listaUsuarios.forEach((usuario) => {
                if (usuario.usu_email.endereco == email) {
                    emailUsuarioExistente = true;
                }
            });

            let emailUsuarioDisponivel = false;
            if (!!emailUsuarioExistente) {
                listaUsuariosLocal.forEach((usuario) => {
                    if (usuario.usu_email.endereco == email) {
                        emailUsuarioDisponivel = true;
                    }
                });
            } else {
                emailUsuarioDisponivel = true;
            }

            if (!!!emailUsuarioDisponivel) {
                notification.warning({ description: `Endereço de e-mail informado ${email} já está vinculado a outro vendedor! Informe outro e-mail e tende novamente.`, message: `Aviso!` })
            } else {
                api.get(`Usuario/RetornaUsuarioEmail?email=${email}`).then(
                    res => {
                        if (!!res.data) {
                            Modal.confirm({
                                title: 'Aviso',
                                icon: <ExclamationCircleOutlined />,
                                content: `O E-mail ${email} já pertence a um usuário no sistema, ao continuar este usuário terá acesso a esta empresa vinculado a este novo vendedor.`,
                                okText: `Continuar com o usuário existente`,
                                cancelText: `Informar outro e-mail`,
                                centered: true,
                                onOk() {
                                    adicionaEmail(email)
                                },
                                onCancel() {
                                    formulario.setFieldsValue({ emailUsuario: null });
                                }
                            });
                        } else {
                            adicionaEmail(email);
                        }
                    }
                ).catch(
                    error => {
                        console.log(error);
                    }
                )
            }
        }
    };

    function adicionaEmail(email) {
        let principal = true;
        let verifica = true;
        if (!!manutencao.dados.emails) {
            if (!!manutencao.dados.emails.length > 0) {
                principal = false;
            }
            manutencao.dados.emails.forEach((email) => {
                if (email.pem_email === email) {
                    verifica = false;
                }
            })
        }
        if (verifica) {
            let listaEmails = [...!!manutencao.dados.emails ? manutencao.dados.emails : []];
            let dadosEmail = {};
            dadosEmail.pem_email = email;
            dadosEmail.pem_ativo = true;
            dadosEmail.pem_emailprincipal = principal;
            dadosEmail.pem_enviarboleto = false;
            dadosEmail.pem_enviarcartacobranca = false;
            dadosEmail.pem_enviarnfe = false;
            dadosEmail.pem_enviarpromocao = false;
            dadosEmail.pem_enviarvencparcela = false;
            listaEmails.push(dadosEmail);
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ emails: listaEmails } } } });
        } else {
            notification.warning({ description: `Este E-mail já foi adicionado na lista de e-mails`, message: `Atenção!` });
        }
    };

    function verificarCpfValido(dado) {
        let valorCampo;
        if (!!dado.currentTarget) {
            valorCampo = dado.currentTarget.value.replace(/\D/g, '');
        } else {
            valorCampo = dado;
        }
        if (HasValue(valorCampo)) {
            if (!validarCpf(valorCampo)) {
                notification.warning({ message: 'Aviso', description: 'CPF informado é inválido' });
                formulario.setFieldsValue({ pef_cpf: null });
                setFocusCpfCnpj();
            } else {
                verificaCpf(valorCampo)
            }
        }
    }

    function verificaCpf(pef_cpf) {
        api.get(`PessoaFisica/VerificarCpfExistente?cpf=${pef_cpf}`).then(
            res => {
                if (res.status === 200) {
                    if (res.data.pes_vendedor) {
                        Modal.confirm({
                            title: 'Aviso',
                            icon: <ExclamationCircleOutlined />,
                            content: "O CPF informado já esta cadastrado no sistema!\n Deseja atualizar seus dados?",
                            okText: 'Sim',
                            cancelText: 'Não',
                            centered: true,
                            onOk() {
                                listarVendedorCpf(pef_cpf);
                            },
                            onCancel() {
                                formulario.resetFields();
                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                            }
                        });
                    } else {
                        listarVendedorCpf(pef_cpf);
                    }
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };
    function listaVendedor(ven_id) {
        if (!!!ven_id) {
            return;
        }
        api.get(`Vendedor/BuscarVendedor/${ven_id}`).then(
            (res) => {
                let valores = res.data;
                if (!!res.data && isObjetoDiffVazio(res.data)) {
                    valores.pef_rguf = res.data.pef_rguf == "" ? null : res.data.pef_rguf;
                    valores.pef_datanascimento = !!valores.pef_datanascimento ? moment(valores.pef_datanascimento) : null;
                    valores.pes_datainclusao = !!valores.pes_datainclusao ? moment(valores.pes_datainclusao) : null;
                    valores.pes_dataalteracao = !!valores.pes_dataalteracao ? moment(valores.pes_dataalteracao) : null;
                    valores.pef_dataalteracao = !!valores.pef_dataalteracao ? moment(valores.pef_dataalteracao) : null;
                    valores.pef_rgdataexpedicao = !!valores.pef_rgdataexpedicao ? moment(valores.pef_rgdataexpedicao) : null;
                    formulario.setFieldsValue(valores);
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: valores } });
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function listarVendedorCpf(pef_cpf, callback) {
        api.get(`Vendedor/BuscarVendedorCpf/${pef_cpf}`).then(
            res => {
                let valores = res.data;
                valores.pef_datanascimento = !!valores.pef_datanascimento ? moment(valores.pef_datanascimento) : null;
                valores.pes_datainclusao = !!valores.pes_datainclusao ? moment(valores.pes_datainclusao) : null;
                valores.pes_dataalteracao = !!valores.pes_dataalteracao ? moment(valores.pes_dataalteracao) : null;
                valores.pef_dataalteracao = !!valores.pef_dataalteracao ? moment(valores.pef_dataalteracao) : null;
                valores.pef_rgdataexpedicao = !!valores.pef_rgdataexpedicao ? moment(valores.pef_rgdataexpedicao) : null;
                valores.ven_ativo = true;
                let lista = [];
                if (!!valores.pef_rguf) {
                    lista.push({ name: "pef_rguf", campo: "Uf", value: valores.pef_rguf });
                }
                if (!!valores.pef_codigocidadenascimento) {
                    lista.push({ name: "pef_codigocidadenascimento", campo: "IdCidade", value: valores.pef_codigocidadenascimento });
                }
                if (lista.length > 0) {
                    editando.setEditando(false);
                    dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                }
                formulario.setFieldsValue(valores);
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: valores } });
                if (!!callback) {
                    callback(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function modalAdicionarFuncionarioVendedor(dadosVendedor) {

        Modal.confirm({
            title: 'Aviso',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja cadastrar esse vendedor como Funcionário da empresa?`,
            cancelText: 'Não',
            okText: 'Sim',
            okType: 'sucess',
            centered: true,
            onOk() {
                adicionarFuncionarioVendedor(dadosVendedor);
            },
            onCancel() {
                formulario.resetFields();
            }
        });
    }

    function adicionarFuncionarioVendedor(dadosVendedor) {
        dadosVendedor.fun_ativo = true;
        api.post(`Funcionario/Incluir`, dadosVendedor).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Vendedor cadastrado como Funcionário com sucesso!" });
            }).catch(
                erro => {
                    console.log(erro);
                }
            ).finally(
                () => {
                    salvandoDados(false);
                    aoSalvar();
                })
    }

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name='manutencaoVendedor' onFinish={salvarManutencao} campoId="ven_id">
                <Form.Item name="pef_id" hidden></Form.Item>
                <Form.Item name="pes_id" hidden></Form.Item>
                <Form.Item name="ven_id" hidden>
                    <Input />
                </Form.Item>
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => validarAbas(tab)}>
                    <Tabs.TabPane tab="Vendedor" key="1">
                        <div>
                            <Row gutter={[8, 0]}>
                                <Col xs={24} sm={7} md={4} lg={4} xl={4} xxl={3}>
                                    <InputCpfCnpj cpf label="CPF" name="pef_cpf" rules={[{ required: true, message: 'Informe o CPF.' }]}
                                        disabled={desabilitarCfpCnpj}
                                        onBlur={(pef_cpf) => { verificarCpfValido(pef_cpf) }} />
                                </Col>
                                <Col xs={24} sm={17} md={10} lg={10} xl={10} xxl={7}>
                                    <Form.Item label="Nome" name="pes_nome" rules={[{ required: true, message: 'Informe o nome' }]}>
                                        <Input placeholder="Nome do Vendedor" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={7} lg={5} xl={6} xxl={5}>
                                    <Form.Item label="Sexo" name="pef_sexo">
                                        <Radio.Group>
                                            <Radio value="F">Feminino</Radio>
                                            <Radio value="M">Masculino</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={5} lg={5} xl={4} xxl={4}>
                                    <Data label="Data de Nascimento" name="pef_datanascimento" />
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Usuário do Vendedor no Sistema" name="criarUsuario">
                                        <Radio.Group>
                                            {(!editando.editando || (editando.editando && exibirOpcoes)) && <Radio onChange={() => setOpcoesCriarUsuario(0)} value={0}>Não criar Usuário para este Vendedor</Radio>}
                                            <Radio onChange={() => setOpcoesCriarUsuario(1)} value={1}>Criar um Usuário Automaticamente</Radio>
                                            <Radio onChange={() => setOpcoesCriarUsuario(2)} value={2}>Vincular usuário existente</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.criarUsuario !== currentValues.criarUsuario}>
                                    {({ getFieldValue }) => {
                                        if (getFieldValue('criarUsuario') === 1) return (
                                            <Col xs={24} sm={16} md={10} lg={10} xl={10} xxl={9}>
                                                <Form.Item label="E-mail Principal do Vendedor" name="emailUsuario" rules={[{ required: true, message: 'Informe o E-mail Principal do Vendedor.' }]}>
                                                    <Input placeholder="Informe o e-mail principal aqui" onBlur={(valor) => { validarEmail(valor) }} />
                                                </Form.Item>
                                            </Col>
                                        );
                                        if (getFieldValue('criarUsuario') === 2) return (
                                            <Col xs={24} sm={16} md={10} lg={10} xl={10} xxl={9}>
                                                <Form.Item label="Selecione um Usuário para vincular ao Vendedor" name="usu_id" rules={[{ required: true, message: 'Informe um Usuário para vincular ao Vendedor.' }]}>
                                                    <Select showSearch optionFilterProp="children" placeholder="Selecione um Usuário" >
                                                        {listaUsuarios?.map(Usuario => (
                                                            <Select.Option key={Usuario.usu_id} value={Usuario.usu_id}>{Usuario.usu_nome}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        );
                                        else return null;
                                    }}
                                </Form.Item>

                                <Col xs={24} sm={12} md={6} lg={6} xl={7} xxl={6}>
                                    <Form.Item label="Apelido" name="pef_apelido">
                                        <Input placeholder="Informe o Apelido (Opcional)" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={6} xl={7} xxl={6}>
                                    <Form.Item label="Estado Civil" name="ecv_id">
                                        <Select placeholder="Selecione um Estado Civil">
                                            {listaEstadoCivil.map(EstCivil => (
                                                <Select.Option key={EstCivil.ecv_id} value={EstCivil.ecv_id}>{EstCivil.ecv_descricao}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={4}>
                                    <Form.Item label="RG" name="pef_rg">
                                        <Input placeholder="Informe o RG" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={3} xl={3} xxl={3}>
                                    <Form.Item label="Órgão Expedidor" name="pef_rgorgaoexpedidor">
                                        <Input placeholder="Informe órgão expedidor" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={3}>
                                    <Data label="Data de Expedição" name="pef_rgdataexpedicao" />
                                </Col>
                                <Col xs={24} sm={8} md={5} lg={5} xl={5} xxl={3}>
                                    <Form.Item label="UF RG" name="pef_rguf">
                                        <SelectPaginacao url="Estado/Listar" placeholder="Selecione um Estado" allowClear={true} form={formulario} nameLabel="est_sigla" nameValue="pef_rguf" selecionarRegUnico="est_sigla" onClearFunction={(UfEstado) => carregarCidades(UfEstado)} onSelectFunction={(UfEstado) => carregarCidades(UfEstado)}
                                            conteudo={
                                                est => (
                                                    <Select.Option value={est.est_sigla} key={est.est_id}>{est.est_sigla} - {est.est_descricao}</Select.Option>)
                                            } />
                                    </Form.Item>
                                </Col>

                                <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={4}>
                                    <Form.Item label="Cidade Nascimento" name="pef_codigocidadenascimento" disabled={listaCidades == true}>
                                        <SelectPaginacao url="Cidade/Listar" placeholder="Selecione a Cidade de Nascimento" nameLabel="cid_descricao" nameValue="pef_codigocidadenascimento" form={formulario} filtroExtra={filtroCdd} selecionarRegUnico="cid_id"
                                            conteudo={
                                                cid => (<Select.Option value={cid.cid_id} key={cid.cid_id}>{cid.cid_id} - {cid.cid_descricao}</Select.Option>)
                                            } />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={5}>
                                    <Form.Item label="Nome do Pai" name="pef_nomepai">
                                        <Input placeholder="Informe o Nome do Pai do Funcionário" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={6}>
                                    <Form.Item label="Nome da Mãe" name="pef_nomemae">
                                        <Input placeholder="Informe o Nome da Mãe do Funcionário" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={5} lg={5} xl={5} xxl={4}>
                                    <Form.Item name="ven_ativo" valuePropName='checked' initialValue={true} className="t-mob-573">
                                        <Checkbox> Vendedor Ativo? </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Comissão / Descontos / Outros" key="2">
                        <div>
                            <Row gutter={[8, 0]}>
                                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Item label="Percentual de Comissão à Vista" name="ven_perccomissaoavista" >
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            controls={false}
                                            defaultValue={0}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            placeholder="(%) Comissão à Vista" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Item label="Percentual de Comissão à Prazo" name="ven_perccomissaoaprazo" >
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            controls={false}
                                            defaultValue={0}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            placeholder="(%) Comissão à Prazo" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Item label="Percentual Máximo de Desconto" name="ven_maximodesconto" >
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            controls={false}
                                            defaultValue={0}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            placeholder="(%) Máximo de Desconto" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Item label="Selecione a Região das Vendas">
                                        <SelectPaginado url="Estado/Listar" placeholder="Selecione um Estado" form={formulario} name="est_id"
                                            conteudo={
                                                est => (<Select.Option value={est.est_id} key={est.key}>{est.est_sigla} - {est.est_descricao}</Select.Option>)
                                            } />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="ven_externo" valuePropName="checked">
                                        <Checkbox> Vendedor Externo? </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item name="ven_consideradesconto" valuePropName="checked">
                                        <Checkbox> Considerar Desconto? </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Endereços" key="3">
                        <Endereco />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Telefones" key="4">
                        <Telefone />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="E-mails" key="5">
                        <Email />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Ocupações" key="6">
                        <Ocupacao />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Outros Contatos" key="7">
                        <OutrosContatos />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Campos Adicionais" key="8">
                        <Row align="middle" gutter={[0, 16]}>
                            <Col span={24}>
                                <Typography.Title level={3}>
                                    <img src={require("../../assets/i-grade.png").default} alt="Campos Adicionais" /> Campos Adicionais
                                </Typography.Title>
                                <Divider orientation="left" plain>
                                    Adicione abaixo as informações adicionais.
                                </Divider>
                            </Col>
                            <Col span={24}>
                                <FormularioDinamico formulario={formulario} parametrosFiltros={elementosFormulario} />
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </FormGW>
        </div>
    );
}
