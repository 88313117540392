import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Checkbox, Tooltip, Tabs, notification } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import CurrencyInput from 'react-currency-input';

import api from '../../services/api';
import { FormGW } from "../../components";
import { useStateValue } from "../../state";
import { listagemActions } from "../../actions";
import { TabFormaPagamento, TabParcelas } from "./tabPage";


export default function ManutencaoCondicaoPagamento({ formulario, carregando, aoSalvar }) {

    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [exibeParcelas, setExibeParcelas] = useState(false);
    const [listaFormasPagamento, setListaFormasPagamento] = useState([]);
    const [parcelas, setParcelas] = useState([]);
    const [{ manutencao }, dispatch] = useStateValue();
    const [listaDadosForma, setListaDadoForma] = useState([]);

    useEffect(() => {
        if (!!formulario.getFieldValue().cpg_id) {
            let dados = manutencao.dados;
            dados.cpg_descontopadrao = (dados.cpg_descontopadrao && dados.cpg_descontopadrao > 0) ? (dados.cpg_descontopadrao * 100) : 0;
            dados.cpg_percentualjuros = (dados.cpg_percentualjuros && dados.cpg_percentualjuros > 0) ? (dados.cpg_percentualjuros * 100) : 0;
            formulario.setFieldsValue(dados);
            carregarDadosCondicao();
        }
    }, [manutencao.dados]);

    useEffect(() => {
        buscarDadosFormasPgto();
    }, []);

    const salvarManutencao = async valores => {
        let dados = {};
        let retorno;
        carregando(true);
        if (!(await validarCondicaoCrediario(valores))) {
            return;
        }

        dados.cpg_ativo = valores.cpg_ativo;
        dados.cpg_descontopadrao = !!valores.cpg_descontopadrao ? calcPercent(valores.cpg_descontopadrao) : null;
        dados.cpg_descricao = valores.cpg_descricao;
        dados.cpg_id = valores.cpg_id;
        dados.cpg_percentualjuros = !!valores.cpg_percentualjuros ? calcPercent(valores.cpg_percentualjuros) : null;
        dados.formacondicaopagamentos = listaFormasPagamento;
        dados.mostraParcela = valores.mostraParcela;
        dados.parcelamentos = parcelas;

        let enderecoUrl = 'CondicaoPagamento/';
        if (dados.cpg_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, dados).then(res => {
                if (res.status === 200) {
                    notification.success({ description: `Condição de Pagamento editada com sucesso!`, message: 'Sucesso' });
                    retorno = res.data;
                }
            }, err => {
                carregando(false);
                console.log(err);
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+cpg_descricao' } });
                    aoSalvar();
                }
            );
        } else {
            enderecoUrl += `Incluir`;
            api.post(enderecoUrl, dados).then(res => {
                if (res.status === 200) {
                    notification.success({ description: `Condição de Pagamento adicionada com sucesso!`, message: 'Sucesso' });
                    retorno = res.data;
                }
            }, err => {
                carregando(false);
                console.log(err);
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    carregando(false);
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+cpg_descricao' } });
                    aoSalvar();
                }
            );
        }
    };

    const calcPercent = value => {
        let result = 0;
        if (value) {
            value = value.toString();
            if (value.includes("%"))
                result = (parseFloat(value.toString().replace(".", "").replace(",", ".").replace("%", "")) / 100);
            else
                result = (value / 100);
        }
        return result;
    };

    async function validarCondicaoCrediario(valores) {
        let lista = [...listaFormasPagamento];
        for (let forma of lista) {
            let formaPgto = listaDadosForma.find(f => f.fpg_id === forma.fpg_id);
            if (!!formaPgto && formaPgto.fpg_tipopagamento == 5 && parcelas.length <= 0) {
                notification.warning({ description: "Condição com forma de pagamento do tipo crediario deve possuir parcelas!", message: "Aviso" });
                carregando(false);
                return false;
            }
        }
        return true;
    };

    function buscarDadosFormasPgto() {
        api.get('FormaPagamento/Listar?filtro=&apenasAtivos=true&PageSize=200').then(
            (res) => {
                if (!!res.data) {
                    setListaDadoForma(res.data.items);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    }

    function defineParcelas() {
        setExibeParcelas(!exibeParcelas);
    };

    function carregarDadosCondicao() {
        let cpg_id = formulario.getFieldValue().cpg_id;
        api.get(`CondicaoPagamento/ListarParcelamento?codigoCondicaoPagamento=${cpg_id}`).then(
            res => {
                if (res.data.length > 0) {
                    formulario.setFieldsValue({ mostraParcela: true });
                    defineParcelas();
                    res.data.sort((a, b) => (a.pcl_numeroparcela > b.pcl_numeroparcela) ? 1 : ((b.pcl_numeroparcela > a.pcl_numeroparcela) ? -1 : 0));
                    setParcelas(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
        api.get(`CondicaoPagamento/ListarFormaPagamentoRelacionadas?codigoCondicaoPagamento=${cpg_id}`).then(
            res => {
                setListaFormasPagamento(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoFormaPagamento" onFinish={salvarManutencao}>
                <Row align="middle" gutter={[8, 8]}>
                    <Col span={24}>
                        <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                            <Tabs.TabPane tab="Condição de Pagamento" key="1">
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                        <Form.Item name="cpg_id" hidden />
                                        <Form.Item name="cpg_ativo" hidden><Input /></Form.Item>
                                        <Form.Item label="Descrição da Condição de Pagamento" name="cpg_descricao" rules={[{ required: true, message: 'Informe a Descrição da Condição de Pagamento' }]}>
                                            <Input placeholder="Informe a Descrição da Condição de Pagamento" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.cpg_descontopadrao !== currentValues.cpg_descontopadrao}>
                                            {({ getFieldValue, setFieldsValue }) => {
                                                let value = getFieldValue("cpg_descontopadrao");
                                                if (value && value !== "") {
                                                    value = value.toString();
                                                    if (value.includes("%")) {
                                                        value = value.replace(".", "").replace(",", ".").replace("%", "");
                                                        if (parseFloat(value) > 100) {
                                                            setFieldsValue({ cpg_descontopadrao: 100 });
                                                        }
                                                    }
                                                }
                                                return <Form.Item name="cpg_descontopadrao" label={
                                                    <div>
                                                        Desconto Padrão Aplicado
                                                        <Tooltip title="Informe aqui um valor percentual de desconto padrão, que será aplicado nas vendas automaticamente ao utilizar esta condição de pagamento. Se definido um valor de desconto padrão na CONDIÇÃO DE PAGAMENTO, este percentual irá prevalecer caso a FORMA DE PAGAMENTO relacionada também possuir um desconto padrão configurado. Esta informação é OPCIONAL!">
                                                            <QuestionCircleOutlined />
                                                        </Tooltip>
                                                    </div>
                                                }>
                                                    <CurrencyInput
                                                        className="CurrencyInput"
                                                        suffix=" %"
                                                        inputType="text"
                                                        decimalSeparator=","
                                                        thousandSeparator="." />
                                                </Form.Item>
                                            }}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.cpg_percentualjuros !== currentValues.cpg_percentualjuros}>
                                            {({ getFieldValue, setFieldsValue }) => {
                                                let value = getFieldValue("cpg_percentualjuros");
                                                if (value && value !== "") {
                                                    value = value.toString();
                                                    if (value.includes("%")) {
                                                        value = value.replace(".", "").replace(",", ".").replace("%", "");
                                                        if (parseFloat(value) > 100) {
                                                            setFieldsValue({ cpg_percentualjuros: 100 });
                                                        }
                                                    }
                                                }
                                                return <Form.Item name="cpg_percentualjuros" label={
                                                    <div>
                                                        Percentual de Juros Aplicado
                                                        <Tooltip title="Informe aqui um valor percentual OPCIONAL!">
                                                            <QuestionCircleOutlined />
                                                        </Tooltip>
                                                    </div>
                                                }>
                                                    <CurrencyInput
                                                        className="CurrencyInput"
                                                        suffix=" %"
                                                        inputType="text"
                                                        decimalSeparator=","
                                                        thousandSeparator="." />
                                                </Form.Item>
                                            }}
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item valuePropName="checked" name="mostraParcela">
                                            <Checkbox onChange={() => { defineParcelas() }}>Definir Parcelas</Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item name="cpg_ativo" valuePropName="checked">
                                            <Checkbox>Condição de Pagamento Ativa?</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Formas de Pagamento" key="2">
                                <TabFormaPagamento formasPagamento={{ listaFormasPagamento, setListaFormasPagamento }} />
                            </Tabs.TabPane>
                            {exibeParcelas && <Tabs.TabPane tab="Parcelas" key="3">
                                <TabParcelas formulario={formulario} listaParcelas={{ parcelas, setParcelas }} />
                            </Tabs.TabPane>}
                        </Tabs>
                    </Col>
                </Row>
            </FormGW>
        </div >
    );

}