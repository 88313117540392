import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table, Select, Form, Divider, Tag } from "antd";
import { ExclamationCircleOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../services/api";
import { FormatNumber } from "../../ValueObjects";
import { isObjetoDiffVazio } from "../../services/funcoes";

export default function TabUltimasCompras({ form, dadosCliente, dataInicial, dataFinal }) {

    const [dadosNotasFiscais, setDadosNotasFiscais] = useState([]);
    const [dadosItensNotas, setItensNotas] = useState([]);
    const [dadosGrupo, setDadosGrupo] = useState([]);

    useEffect(() => {
        form.setFieldsValue({ ordemNota: "+ntf_dataemissao" });
        if (!!dadosCliente && isObjetoDiffVazio(dadosCliente)) {
            carregarDados();
        }
    }, [dadosCliente]);

    useEffect(() => {
        carregarDados();
    }, [dataInicial]);

    useEffect(() => {
        carregarDados();
    }, [dataFinal]);

    function carregarDados() {
        listarNotasFiscais();
        listarItensNotas();
        listarTotaisGrupo();
    };

    function listarNotasFiscais(ordem = "") {
        api.get(`NotaFiscal/ListaNotasFiscaisClienteCRM?IdPessoa=${dadosCliente.pes_id}&dataFinal=${moment(dataFinal).toISOString()}&dataInicial=${moment(dataInicial).toISOString()}${!!ordem ? `&ordem=${ordem}` : ''}`).then(
            res => {
                setDadosNotasFiscais(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function listarItensNotas() {
        api.get(`NotaFiscal/ListaItensNotasClienteCRM?IdPessoa=${dadosCliente.pes_id}&dataFinal=${moment(dataFinal).toISOString()}&dataInicial=${moment(dataInicial).toISOString()}`).then(
            res => {
                setItensNotas(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function listarTotaisGrupo() {
        api.get(`NotaFiscal/RetornaTotaisGruposCRM?IdPessoa=${dadosCliente.pes_id}&dataFinal=${moment(dataFinal).toISOString()}&dataInicial=${moment(dataInicial).toISOString()}`).then(
            res => {
                if (!!res.data) {
                    let sequencia = 1;
                    res.data.forEach((dados) => {
                        dados.key = sequencia++;
                    });
                    setDadosGrupo(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function alterarOrdemDadosNota() {
        listarNotasFiscais(form.getFieldValue().ordemNota);
    };

    return (
        <div>
            <Row gutter={[0, 24]}>
                <Col span={24}>
                    <Card size="default" title="Vendas" className="lista-resumo">
                        <Row align="middle" justify="end" gutter={[0, 8]}>
                            <Col xs={24} sm={12} md={6} lg={5} xl={4} xxl={3} className="selectOrdemNota">
                                <Form.Item name="ordemNota">
                                    <Select onChange={() => { alterarOrdemDadosNota() }}>
                                        <Select.Option key={0} value="+ntf_dataemissao" >Data Emissão Crescente</Select.Option>
                                        <Select.Option key={1} value="-ntf_dataemissao" >Data Emissão Decrescente</Select.Option>
                                        <Select.Option key={2} value="+ntf_totalnota" >Total Nota Crescente</Select.Option>
                                        <Select.Option key={3} value="-ntf_totalnota" >Total Nota Decrescente</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Table dataSource={dadosNotasFiscais} columns={[
                                    {
                                        title: 'Série',
                                        width: 50,
                                        align: 'center',
                                        render: ({ ser_serie }) => (
                                            <div>
                                                <Tag color="processing" className="w-100">
                                                    <b>{ser_serie}</b>
                                                </Tag>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Nº',
                                        width: 105,
                                        align: 'center',
                                        render: ({ ntf_numero }) => (
                                            <div>
                                                <b>{ntf_numero}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Data Emissão',
                                        width: 100,
                                        align: 'right',
                                        render: ({ ntf_dataemissao }) => (
                                            <div>
                                                <b>{!!ntf_dataemissao ? moment(ntf_dataemissao).format('DD/MM/YYYY') : ''}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Qtde. Itens',
                                        width: 120,
                                        align: 'right',
                                        render: ({ ntf_totalitens }) => (
                                            <div>
                                                <b>{ntf_totalitens}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Total (R$)',
                                        align: 'right',
                                        width: 120,
                                        render: ({ ntf_totalnota }) => (
                                            <div>
                                                <b>{FormatNumber(ntf_totalnota, true)}</b>
                                            </div>
                                        ),
                                    }
                                ]} locale={{
                                    emptyText: (
                                        <Row>
                                            <Col span={24} className="p-t-b-8">
                                                <Divider orientation="center">
                                                    <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                </Divider>
                                            </Col>
                                        </Row>
                                    )
                                }} scroll={{ x: 900 }} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[0, 24]} className="lista-resumo m-t-16">
                <Col span={24}>
                    <Card size="default" title="Itens">
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Table dataSource={dadosItensNotas} columns={[
                                    {
                                        title: 'Informações dos Itens Vendidos',
                                        width: 250,
                                        render: ({ pro_descricao }) => (
                                            <div>
                                                <b>{pro_descricao}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Qantidade',
                                        width: 75,
                                        align: 'center',
                                        render: ({ nfi_qtde }) => (
                                            <div>
                                                <b>{nfi_qtde}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Desconto (R$)',
                                        width: 75,
                                        align: 'right',
                                        render: ({ nfi_valordesconto }) => (
                                            <div>
                                                <b>{FormatNumber(nfi_valordesconto, true)}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Frete (R$)',
                                        width: 75,
                                        align: 'right',
                                        render: ({ nfi_valorfrete }) => (
                                            <div>
                                                <b>{FormatNumber(nfi_valorfrete, true)}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Total (R$)',
                                        align: 'right',
                                        width: 75,
                                        fixed: 'right',
                                        render: ({ nfi_valortotalitem }) => (
                                            <div>
                                                <b>{FormatNumber(nfi_valortotalitem, true)}</b>
                                            </div>
                                        ),
                                    }
                                ]} locale={{
                                    emptyText: (
                                        <Row>
                                            <Col span={24} className="p-t-b-8">
                                                <Divider orientation="center">
                                                    <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                </Divider>
                                            </Col>
                                        </Row>
                                    )
                                }} scroll={{ x: 900 }} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[0, 24]} className="lista-resumo m-t-16">
                <Col span={24}>
                    <Card size="default" title="Grupo/Subgrupo">
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Table dataSource={dadosGrupo} columns={[
                                    {
                                        title: 'Grupo',
                                        render: ({ gru_nome }) => (
                                            <div>
                                                <b>{gru_nome}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Qtde Total Grupo',
                                        width: 275,
                                        align: 'right',
                                        render: ({ gru_qtdetotalgrupo }) => (
                                            <div>
                                                <b>{gru_qtdetotalgrupo}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Valor Total Grupo (R$)',
                                        width: 275,

                                        align: 'right',
                                        render: ({ gru_valortotalgrupo }) => (
                                            <div>
                                                <b>{FormatNumber(gru_valortotalgrupo, true)}</b>
                                            </div>
                                        ),
                                    }
                                ]} expandable={{
                                    expandedRowRender: ({ listaTotSubGrupo }) => {
                                        return (
                                            <Table dataSource={listaTotSubGrupo} pagination={false} showHeader={false} columns={[
                                                {
                                                    title: 'Subgrupo',
                                                    render: ({ sgp_nome }) => (
                                                        <div>
                                                            <b>{sgp_nome}</b>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    title: 'Qtde Total Subgrupo',
                                                    width: 275,
                                                    align: 'right',
                                                    fixed: 'right',
                                                    render: ({ sgp_qtdetotalsubgrupo }) => (
                                                        <div>
                                                            <b>{sgp_qtdetotalsubgrupo}</b>
                                                        </div>
                                                    ),
                                                },
                                                {
                                                    title: 'Valor Total Subgrupo (R$)',
                                                    width: 275,
                                                    align: 'right',
                                                    fixed: 'right',
                                                    render: ({ sgp_valortotalsubgrupo }) => (
                                                        <div>
                                                            <b>{FormatNumber(sgp_valortotalsubgrupo, true)}</b>
                                                        </div>
                                                    ),
                                                }
                                            ]} />
                                        )
                                    }
                                }} expandIcon={({ record, expanded, onExpand }) => {
                                    return expanded ? (
                                        <ArrowUpOutlined onClick={(e) => onExpand(record, e)} />
                                    ) : (
                                        <ArrowDownOutlined onClick={(e) => onExpand(record, e)} />
                                    );
                                }} locale={{
                                    emptyText: (
                                        <Row>
                                            <Col span={24} className="p-t-b-8">
                                                <Divider orientation="center">
                                                    <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                </Divider>
                                            </Col>
                                        </Row>
                                    )
                                }} scroll={{ x: 900 }} />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}