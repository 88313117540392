import React, { useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined, LeftOutlined, RightOutlined  } from "@ant-design/icons";

import { useStateValue } from '../../state';
import { DrawerNav } from '../../components';
import ManutencaoRecebimentoOrdemCompra from './manutencaoRecebimento';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';
//import FormasPagamentoRecebimento from '../../components';

export default function DrawerRecebimento({ carregarDados, alteraData, fornecedor, transportador,  showDrawerExtra, setShowDrawerExtra }) {

    const [formRecebimentoOrdemCompra] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [proximaPag, setProximaPag] = useState(0);
    const [tagPages, setTagPages] = useState(null);
    const [carregando, setCarregando] = useState(false);

    if (!!manutencao.dados && manutencao.dados.recebimento !== null) {
        formRecebimentoOrdemCompra.setFieldsValue(manutencao.dados.recebimento);
    }

    function fecharDrawer() {      
        setTagPages(0);
        setProximaPag(null);
        formRecebimentoOrdemCompra.resetFields();
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } }); 
        alteraData();
        carregarDados();
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                formRecebimentoOrdemCompra.resetFields();
                fecharDrawer();
            }
        });
    };

    return (
        <DrawerNav title="Recebimento da Ordem de Compra"
            width="70%"
            visible={ui.showDrawerExtra}
            closeIcon={<MinusOutlined />}
            fecharDrawer={() => {fecharDrawer()}}
            limparAoEditar={true}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={tagPages === 0} icon={<LeftOutlined />} size="large" onClick={() => setProximaPag(tagPages - 1)} type="primary">
                                Voltar
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" hidden={tagPages === 2} size="large" onClick={() => setProximaPag(tagPages + 1)}>
                                Avançar <RightOutlined />
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formRecebimentoOrdemCompra.submit()} hidden={tagPages !== 2} loading={carregando} icon={<SaveOutlined />} size="large" type="primary">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoRecebimentoOrdemCompra form={formRecebimentoOrdemCompra} aoSalvar={fecharDrawer} carregando={setCarregando} fornecedor={fornecedor} transportador={transportador} 
            pages={{ tagPages, setTagPages }} proximaPag={{ proximaPag, setProximaPag }} showDrawerExtra={showDrawerExtra}  carregarDados={carregarDados} alteraData={alteraData}/>
        </DrawerNav>
    )
}