import React from "react";
import { Row, Col, Select, Divider, Form, Button } from "antd";

import { PlusOutlined } from "@ant-design/icons";

export default function SelectIncluir({
    dadosSelect = [],
    funcaoBotao = (() => { console.log('selectIncluir') }),
    campoDescricao = '',
    campoValue = '',
    placeHolder = '',
    campoKey = '',
    labelFormItem = '',
    nameFormItem = '',
    rulesFormItem = [],
    onChange = (() => { }),
    onBlur = (() => { }),
    onClear = (() => { }),
    onDeselect = (() => { }),
    onDropdownVisibleChange = (() => { }),
    onFocus = (() => { }),
    onInputKeyDown = (() => { }),
    onMouseEnter = (() => { }),
    onMouseLeave = (() => { }),
    onPopupScroll = (() => { }),
    onSearch = (() => { }),
    onSelect = (() => { }),
}) {
    function retornarDadosArray(dado) {
        let retorno = '';
        campoDescricao.map((campo) => { retorno += dado[campo] + ','; });
        retorno = retorno.substring(0, retorno.lastIndexOf(','));
        return retorno;
    };

    return (

        <Form.Item label={labelFormItem} name={nameFormItem} rules={rulesFormItem}>
            <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder={placeHolder}
                onChange={onChange}
                onBlur={onBlur}
                onClear={onClear}
                onDeselect={onDeselect}
                onDropdownVisibleChange={onDropdownVisibleChange}
                onFocus={onFocus}
                onInputKeyDown={onInputKeyDown}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onPopupScroll={onPopupScroll}
                onSearch={onSearch}
                onSelect={onSelect}
                dropdownRender={menu => (
                    <div>
                        <Row>
                            <Col span={24}>
                                {menu}
                            </Col>
                        </Row>
                        <Row align="middle" justify="center" gutter={[0, 8]}>
                            <Col span={24}>
                                <Divider className="m-0" />
                            </Col>
                            <Col>
                                <Button onClick={() => {funcaoBotao()}}>
                                    <PlusOutlined /> Adicionar
                                </Button>
                            </Col>
                        </Row>
                    </div>
                )}
            >
                {dadosSelect.map((dado) => (
                    <Select.Option value={dado[campoValue]} key={dado[campoKey]}>{(Array.isArray(campoDescricao) ? retornarDadosArray(dado) : dado[campoDescricao])}</Select.Option>
                ))}
            </Select>
        </Form.Item>

    );
};