import React, { useState } from 'react';
import { Row, Col, Button, Form, Modal } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from '@ant-design/icons';

import { useStateValue } from '../../state';
import { drawerActions, manutencaoActions } from '../../actions';

import LancamentoTransferencia from '../../pages/lancamentoCaixa/lancamentoTransferencia';
import DrawerNav from '../drawerNav';

export default function DrawerLancamentoTransferencia({aoFechar = null}) {

    const [carregando, setCarregando] = useState(false);
    const [formulario] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();

    function fecharDrawer() {
        if (!!aoFechar) {
            aoFechar();
        }
        formulario.resetFields();
        dispatch({ type: drawerActions.CHANGE, data: { showDrawerTransferencia: false } });
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
    };

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <DrawerNav title="Lançamento de Transferência"
            width="70%"
            visible={ui.showDrawerTransferencia}
            closeIcon={<MinusOutlined />}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formulario.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <LancamentoTransferencia formulario={formulario} carregando={setCarregando} aoSalvar={fecharDrawer} />
        </DrawerNav>
    );
};