import React, { useState } from 'react';
import { Drawer, Form } from "antd";
import InfosCotacao from './infosPedido';

export default function DrawerCotacaoCat({ openDrawer, setOpenDrawer, dados }) {
    const [form] = Form.useForm();
    const [carregando, setCarregando] = useState(false);

    function onClose() {
        setOpenDrawer(false);
    }

    return (
        <Drawer width="70%"
            title="Informações Cotação"
            visible={openDrawer}
            closable={false}
            onClose={onClose}>
            <InfosCotacao formulario={form} carregando={setCarregando} dadosPedidos={dados} />
        </Drawer>
    );
}