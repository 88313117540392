import api from "./api";

export async function ServidorImpressoraOnline() {
    let req;
    try {
        req = await api.get(`http://localhost:3001/online`);
    } catch {
        req = { data: { online: false } };
    }
    return req.data;
}

export async function impressorasInstaladas() {
    let req;
    try {
        req = await api.get(`http://localhost:3001/impressao/printers`);
    } catch {
        req = { data: null };
    }
    return req.data;
}

export async function carregarConfiguracoes() {
    let req;
    try {
        req = await api.get(`http://localhost:3001/configuracao`);
    } catch {
        req = { data: null };
    }
    return req.data;
}

export async function impressoraEtiqueta() {
    let req = await api.get(`http://localhost:3001/configuracao/ImpressoraEtiqueta`);
    return req.data;
}

export async function impressoraNaoFiscal() {
    let req = await api.get(`http://localhost:3001/configuracao/ImpressoraNaoFiscal`);
    return req.data;
}

export async function modeloEtiqueta() {
    let req = await api.get(`http://localhost:3001/configuracao/ModeloEtiqueta`);
    return req.data;
}

export async function configurarImpressoraEtiqueta(impressoraEtiqueta) {
    let impressora = {
        impressora: impressoraEtiqueta
    };
    let req = await api.post(`http://localhost:3001/configuracao/ImpressoraEtiqueta`, JSON.stringify(impressora), { headers: { 'Content-type': 'application/json;charset=utf-8' } });
    return req.data;
}

export async function configurarModeloEtiqueta(etiqueta) {
    let dados = {
        modeloEtiqueta: etiqueta
    };
    let req = await api.post(`http://localhost:3001/configuracao/ModeloEtiqueta`, JSON.stringify(dados), { headers: { 'Content-type': 'application/json;charset=utf-8' } });
    return req.data;
}

export async function salvarConfiguracoes(configuracoes) {
    let req = await api.post(`http://localhost:3001/configuracao/ModeloEtiqueta`, JSON.stringify(configuracoes), { headers: { 'Content-type': 'application/json;charset=utf-8' } });
    return req.data;
}

export async function imprimirRawBase64(nomeImpressora, conteudo) {
    let dados = {
        impressora: nomeImpressora,
        texto: btoa(conteudo)
    };

    let urlImpressao = 'http://localhost:3001/naofiscal/imprimirRAWB64';
    if (nomeImpressora.toLowerCase().includes('dymo')) urlImpressao = 'http://localhost:3001/naofiscal/imprimeEtiquetaDymo';

    let req = await api.post(urlImpressao, JSON.stringify(dados), { headers: { 'Content-type': 'application/json;charset=utf-8' } });
    return req.data;
}

export async function imprimirEtiquetaDymo(nomeImpressora, conteudo) {
    let dados = {
        impressora: nomeImpressora,
        texto: btoa(unescape(encodeURIComponent(conteudo)))
    };

    let req = await api.post(`http://localhost:3001/naofiscal/imprimeEtiquetaDymo`, JSON.stringify(dados), { headers: { 'Content-type': 'application/json;charset=utf-8' } });
    return req.data;
}

export async function enviaComandoImpressoraNaoFiscal(nomeImpressora, comando, dados) {
    let dadosEnviar = {
        impressora: nomeImpressora
    };

    if (!!dados) {
        dadosEnviar = { ...dadosEnviar, ...dados };
    }

    let req = await api.post(`http://localhost:3001/naofiscal/${comando}`, JSON.stringify(dadosEnviar), { headers: { 'Content-type': 'application/json;charset=utf-8' } });
    return req.data;
}

