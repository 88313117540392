import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, notification, Modal, Radio, Input } from "antd";
import { ExclamationCircleOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { useStateValue } from "../../../state";
import SelectPaginacao from "../../selects/selectPaginacao";
import InputNumber from "rc-input-number";

export default function ModalRetencao({exibirModalRetencao, fecharModalRetencao }) {
    const [dispatch] = useStateValue();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalRetencao();
            }
        });
    };

    return (
        <Modal centered
            title="Cadastro de Retenção"
            visible={exibirModalRetencao}
            onCancel={onCloseModal}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <div>
                <Form layout="vertical">
                    <Row align="middle" gutter={[8, 8]}>
                        <Col span={24}>
                            <Form.Item label="Retenção" name="">
                               <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Percentual(%)" name>
                                <InputNumber
                                    defaultValue={100}
                                    min={0}
                                    max={100}
                                    formatter={value => `%`}
                                    parser={value => value.replace('%', '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Definir Produto" name>
                                <Radio.Group>
                                    <Radio value={1}>Produto</Radio>
                                    <Radio value={2}>Serviço</Radio>
                                    <Radio value={3}>Ambos</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </div>

        </Modal>
    );
}