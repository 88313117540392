import React, { useEffect, useState } from "react";
import { Row, Col, Form, Checkbox, Input, Select, Slider, notification, Tabs, Typography, Divider } from "antd";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

import api from "../../services/api";
import TabEndereco from './tabEndereco';
import { useStateValue } from '../../state';
import { InputPreco, InputPhone, SelectPaginado } from "../../components";

export default function ManutencaoRestaurante({ formulario, aoSalvar, carregando }) {

    const valueMaximo = 10;
    const inputRef = React.createRef();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [listaTipoRestaurante, setListaTipoRestaurante] = useState([]);
    const [valorPreferencia, setValorPreferencia] = useState(0);
    const [valuePreferenciaSlider, setValuePreferenciaSlider] = useState(0);
    const [listaTiposEndereco, setListaTiposEndereco] = useState([]);
    const [tiposContatos, setTiposContatos] = useState([]);
    const [cidade, setCidade] = useState({});
    const [desabilitarCfpCnpj, setDesabilitarCpfCnpj] = useState(false);
    const [listaPais, setListaPais] = useState([]);
    const handleChange = (value) => {
        setValorPreferencia(value);
        setValuePreferenciaSlider(value);
    };

    function carregarDados() {
        api.get('Enum/Listar?nome=TipoRestaurante').then(
            res => {
                setListaTipoRestaurante(res.data);
            }
        ).catch(error => {
            console.log(error);
        });
        api.get(`TipoEndereco/Listar`).then(
            res => {
                setListaTiposEndereco(res.data);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
        api.get(`TipoOutroContato/Listar`).then(
            res => {
                setTiposContatos(res.data);
            }
        ).catch(
            (erro) => console.error(erro)
        );
        api.get(`Pais/Listar`).then(
            res => {
                setListaPais(res.data);
            }
        ).catch(
            (erro) => console.error(erro)
        );
    }

    function salvarManutencao(values) {
        let operacao = !!values.res_id ? 'Editar' : 'Incluir';
        let coordenadas = !!values.coordenadas ? values.coordenadas.split(',') : ['', ''];
        let enderecoTemp = {
            cep_cep: values.cep_cep,
            cid_descricao: values.cid_descricao,
            est_sigla: values.est_sigla,
            log_logradouro: values.log_logradouro,
            pee_complemento: values.pee_complemento,
            pee_localreferencia: values.pee_localreferencia,
            pee_numero: values.pee_numero,
            tpe_id: values.tpe_id,
            bai_nome: values.bai_nome,
            pee_enderecoprincipal: true,
            pee_latitude: coordenadas[0],
            pee_longitude: coordenadas[1]
        }
        let telefoneTemp = {
            psc_numero: values.psc_numero,
            tpt_id: values.tpt_id,
            psc_ramal: values.psc_ramal

        }
        let emailTemp = {
            pem_email: values.pem_email,
            pem_emailprincipal: true,
            pem_ativo: true
        }
        let outroContato = {
            toc_id: 3,
            out_ativo: true,
            out_descricao: values.res_site
        }
        values.OutrosContatos = !!values.res_site ? [outroContato] : [];
        values.Emails = !!values.pem_email ? [emailTemp] : [];
        values.Enderecos = [enderecoTemp];
        values.Telefones = [telefoneTemp];
        values.cpfCnpj = values.cpfCnpj.replaceAll('-', '').replaceAll('/', '').replaceAll('.', '');
        values.pes_fisicajuridica = values.cpfCnpj.length <= 11 ? 1 : 2;
        values.res_notaavaliacao = valuePreferenciaSlider;
        values.pej_databaixasefaz = !!values.pej_databaixasefaz ? values.pej_databaixasefaz : null;
        values.pej_baixadoreceita = !!values.pej_baixadoreceita ? values.pej_baixadoreceita : false;
        if (!!values.cpfCnpj) {
            if (values.pes_fisicajuridica === 1) {
                values.pef_cpf = values.cpfCnpj.replace(/\D/g, '');
                values.pef_rguf = values.Enderecos.filter((end) => (end.pee_enderecoprincipal === true))[0].est_sigla;
            } else {
                values.pej_cnpj = values.cpfCnpj.replace(/\D/g, '');
                values.pej_inscuf = values.Enderecos.filter((end) => (end.pee_enderecoprincipal === true))[0].est_sigla;
            }
        }
        carregando(true);
        api.post(`Restaurante/${operacao}`, values).then(
            res => {
                notification.success({ message: `Sucesso!`, description: `Restaurante ${operacao === 'Editar' ? 'editado' : 'incluido'} com sucesso!` });
            }
        ).catch(
            error => {
                notification.warning({ message: `Aviso!`, description: `Erro ao ${operacao === 'Editar' ? 'editar' : 'incluir'} o Restaurante` });
            }
        ).finally(() => {
            aoSalvar();
        })

    }

    useEffect(() => {
        if (!!manutencao.dados) {
            setDesabilitarCpfCnpj(!!manutencao.dados && !!manutencao.dados.cpfCnpj ? true : false);
            setValorPreferencia(!!manutencao.dados && !!manutencao.dados.res_notaavaliacao ? manutencao.dados.res_notaavaliacao : 0);
            setValuePreferenciaSlider(!!manutencao.dados && !!manutencao.dados.res_notaavaliacao ? manutencao.dados.res_notaavaliacao : 0);
            manutencao.dados.res_site = manutencao.dados.out_descricao;
            formulario.setFieldsValue(manutencao.dados);
        }
    }, [manutencao.dados])

    useEffect(() => {
        carregarDados();
    }, [])

    return (
        <Form layout="vertical" name="formManutencaoRestaurante" form={formulario} onFinish={salvarManutencao}>
            <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                <Tabs.TabPane tab="Restaurante" key="1">
                    <Row gutter={[8, 0]}>
                        <Form.Item name='res_id' hidden />
                        <Form.Item name='pes_id' hidden />
                        <Col xs={24} sm={6} md={5} lg={4} xl={4} xxl={3}>
                            <Form.Item label="CPF/CNPJ" name="cpfCnpj" rules={[{ required: true, message: 'Informe o CPF ou CNPJ.' }]}>
                                <CpfCnpj
                                    disabled={desabilitarCfpCnpj}
                                    className='ant-input ant-input-sm'
                                    placeholder="Informe o CPF ou CNPJ" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={9} md={7} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Nome" name="pes_nome" rules={[{ required: true, message: 'Informe o nome' }]}>
                                <Input placeholder="Informe o nome" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={9} md={6} lg={5} xl={5} xxl={6}>
                            <Form.Item label="E-mail" name="pem_email">
                                <Input placeholder="Informe o E-mail" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={14} md={6} lg={5} xl={5} xxl={5}>
                            <Form.Item label="Site" name="res_site">
                                <Input placeholder="Informe o Site" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={10} md={4} lg={4} xl={4} xxl={4}>
                            <Form.Item name="res_notaavaliacao">
                                <label>
                                    Preferência: <span>{valorPreferencia}</span>
                                </label>
                                <Slider tipFormatter={null} min={0} max={valueMaximo} onChange={handleChange} value={valuePreferenciaSlider} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={10} md={4} lg={4} xl={4} xxl={4}>
                            <Form.Item label="Tipo de Resturante" name="res_tipo" rules={[{ required: true, message: 'Selecione um Tipo' }]}>
                                <Select
                                    ref={inputRef}
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Selecione um tipo de restaurante">
                                    {listaTipoRestaurante.map((tr) => (
                                        <Select.Option value={tr.key} key={tr.key}>{tr.value}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={4}>
                            <InputPreco label="Valor Buffet Livre (R$)" name="res_preco_buffetlivre" />
                        </Col>
                        <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={4}>
                            <InputPreco label="Valor por Kilo (R$)" name="res_preco_kg" />
                        </Col>
                        <Col className="t-mob">
                            <Row align="middle" gutter={[8, 0]}>
                                <Col>
                                    <Form.Item valuePropName="checked" name="res_almoco">
                                        <Checkbox> Almoço </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item valuePropName="checked" name="res_janta">
                                        <Checkbox> Jantar </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item valuePropName="checked" name="res_cafemanha">
                                        <Checkbox> Café da Manhã </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item valuePropName="checked" name="res_ativo">
                                        <Checkbox> Ativo </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row align="middle" className="m-t-16">
                        <Col span={24}>
                            <Typography.Title level={3}>
                                <img src={require("../../assets/i-telefone.png").default} alt="Telefone(s)" /> Telefone(s)
                            </Typography.Title>
                            <Divider orientation="left" plain>
                                Adicione o(s) telefone(s) do cliente aqui.
                            </Divider>
                        </Col>
                    </Row>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={7} md={5} lg={4} xl={4} xxl={4}>
                            <InputPhone label="Número" name="psc_numero" rules={[{ required: true, message: 'Informe o Número do Telefone' }]} />
                        </Col>
                        <Col xs={24} sm={10} md={6} lg={6} xl={6} xxl={5}>
                            <Form.Item label="Tipo de Telefone" name="tpt_id" rules={[{ required: true, message: 'Selecione um Tipo' }]}>
                                <SelectPaginado ref={inputRef} url="TipoTelefone/Listar" placeholder="Selecione um tipo de telefone" form={formulario} name="tpt_id"
                                    conteudo={
                                        tp => (<Select.Option value={tp.tpt_id} key={tp.key}>{tp.tpt_descricao}</Select.Option>)
                                    } />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={7} md={5} lg={4} xl={4} xxl={4}>
                            <Form.Item label="Ramal" name="psc_ramal">
                                <Input placeholder="Informe o ramal" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Endereços" key="2">
                    <TabEndereco formulario={formulario} listaTipoEndereco={listaTiposEndereco} cidade={cidade} setCidade={setCidade} listaPais={listaPais} />
                </Tabs.TabPane>
            </Tabs>
        </Form>
    );
}