import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Checkbox, Modal, Table, Descriptions, Button } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";

import { useStateValue } from "../../../state";
import moment from "moment";

export default function ModalConsultaProtocoloMdfe({ dadosMdfe, exibirModalConsultaSit, fecharModalConsultaSit }) {

    const [listaProcEvento, setListaProcEvento] = useState([]);

    useEffect(() => {
        if (!!dadosMdfe.retorno) {
            setListaProcEvento(dadosMdfe.retorno.procEventoMDFe);
        }
    }, [dadosMdfe]);



    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalConsultaSit();
            }
        });
    };


    return (
        <Modal
            centered
            title={`Consulta Situação MDF-e`}
            visible={exibirModalConsultaSit}
            onCancel={fecharModalConsultaSit}
            width={'60%'}
            footer={[
                <Button key="cancel" onClick={fecharModalConsultaSit}>
                    <CloseOutlined /> Fechar
                </Button>,
            ]}
            maskClosable={false}
            destroyOnClose
        >
            <Form layout="vertical">
                <Descriptions size="small">
                    <Descriptions.Item label="Chave">
                        <b>{dadosMdfe.retorno?.protMDFe.infProt.chMDFe}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small">
                    <Descriptions.Item label="Situação MDF-e">
                        <b> {dadosMdfe.retorno?.protMDFe.infProt.cStat + " - " + dadosMdfe.retorno?.protMDFe.infProt.xMotivo}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small">
                    <Descriptions.Item label="Data">
                        <b> {moment(dadosMdfe.retorno?.protMDFe.infProt.dhRecbto).format('DD/MM/YYYY HH:mm:ss')}</b>
                    </Descriptions.Item>
                </Descriptions>
            </Form>
            <div>
                <Table
                    columns={[
                        {
                            title: 'Seq',
                            align: 'center',
                            width: "5%",
                            render: ({ eventoMDFe }) => (
                                <div>
                                    <b>{eventoMDFe.infEvento.nSeqEvento}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Data Hora',
                            align: 'center',
                            width: "15%",
                            render: ({ eventoMDFe }) => (
                                <div>
                                    <b>{moment(eventoMDFe.infEvento.dhEvento).format('DD/MM/YYYY HH:mm:ss')}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Evento',
                            align: 'left',
                            width: "30%",
                            render: ({ retEventoMDFe }) => (
                                <div>
                                    <b>{retEventoMDFe.infEvento.tpEvento + " - " + retEventoMDFe.infEvento.xEvento}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Protocolo',
                            align: 'left',
                            width: "15%",
                            render: ({ retEventoMDFe }) => (
                                <div>
                                    <b>{retEventoMDFe.infEvento.nProt}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Status',
                            align: 'left',
                            width: "30%",
                            render: ({ retEventoMDFe }) => (
                                <div>
                                    <b>{retEventoMDFe.infEvento.cStat + " - " + retEventoMDFe.infEvento.xMotivo}</b>
                                </div>
                            )
                        },
                    ]}
                    dataSource={listaProcEvento}
                    pagination={false}
                />
            </div>
        </Modal>
    )
}