import React from "react";
import { Form, Row, Col, Input, Checkbox, notification } from "antd";

import api from '../../services/api';
import { FormGW } from "../../components";
import { useStateValue } from '../../state';
import { listagemActions, manutencaoActions } from "../../actions";

export default function ManutencaoMarca({ formulario, carregando, aoSalvar }) {

    const [{ manutencao }, dispatch] = useStateValue();

    const salvarManutencao = (values) => {
        carregando(true);
        let retorno;
        let enderecoUrl = 'marca/';

        if (values.mar_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "Os dados da Marca foram salvos com sucesso!" });
                    retorno = res.data;
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    atualizarPagina();
                    carregando(false);
                    aoSalvar(retorno);
                }
            );
        } else {
            enderecoUrl += "Incluir"
            api.post(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "Os dados da Marca foram salvos com sucesso!" });
                    retorno = res.data;
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: res.data } });
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    atualizarPagina();
                    carregando(false);
                    aoSalvar(retorno);
                }
            );
        };
    }

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+mar_nome' } });
    };

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoMarca" onFinish={salvarManutencao}>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={18} md={20} lg={20} xl={20}>
                    <Form.Item name="mar_id" hidden><Input /></Form.Item>
                    <Form.Item label="Nome da Marca" name="mar_nome" rules={[
                        { required: true, message: 'Informe o nome da marca' },
                        { min: 2, message: 'No mínimo 2 caracteres' }
                    ]}>
                        <Input placeholder="Informe o nome da marca" />
                    </Form.Item>
                </Col>
                <Col xs={10} sm={6} md={4} lg={4} xl={4}>
                    <Form.Item name="mar_ativa" initialValue={true} valuePropName="checked" className="t-mob-573">
                        <Checkbox>Marca Ativa</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </FormGW>
    );

}