import React, { useEffect } from "react";
import { Row, Col, Tag, Popover, notification, Modal, Form } from "antd";
import { EditOutlined, DeleteOutlined, CopyOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from '../../services/api';
import DrawerVigencia from './drawer';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, TabelaDados, Editar, Excluir, FiltroSearch } from "../../components";
import moment from 'moment';

export default function VigenciaFiscal() {

    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+vig_id' } }), []);

    function excluirRegistro(id) {
        api.delete(`VigenciaFiscal/Excluir/${id}`).then((res) => {
            if (res.status === 200) notification.success({ message: 'Operação concluída', description: "Registro excluído com sucesso!" });
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        }).catch((erro) => {
            notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
        }).finally(() => {
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+vig_id' } });
        });
    }

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Form layout="vertical">
                <Row align="middle" gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <FiltroSearch />
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="vigenciaFiscal/listar" colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ vig_id }) => (
                                <Tag color="processing" className="w-100">
                                    <b>{vig_id}</b>
                                </Tag>
                            ),
                        },
                        {
                            title: 'Descrição',
                            render: ({ vig_descricao }) => (
                                <div>
                                    <b>{vig_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Data Inicial',
                            render: ({ vig_datainicial }) => (
                                <div>
                                    <b>{moment(vig_datainicial).format('DD/MM/YYYY')}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            fixed: 'right',
                            width: 95,
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Editar onClick={() => {
                                                let dados = { ...record };
                                                dados.vig_datainicial = moment(dados.vig_datainicial);
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Excluir icon={<DeleteOutlined />} onClick={() => { modalExcluir(record.vig_id) }} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } />
            </div>
            <DrawerVigencia />
        </div>
    );

}