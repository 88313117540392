import React, { useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import { DrawerNav } from '../../components';
import { drawerActions } from '../../actions';
import ManutencaoEstoqueInventario from './manutencao';

export default function DrawerEstoque() {

    const [formEstoqueInventario] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);
    const [exibirCustos, setExibirCustos] = useState(false);

    if (manutencao.dados !== null) {
        formEstoqueInventario.setFieldsValue(manutencao.dados);
    } else {
        //formEstoqueInventario.resetFields();
    }

    function fecharDrawer() {
        formEstoqueInventario.resetFields();
        setExibirCustos(false);
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
    };

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <DrawerNav title="Manutenção Inventário"
            width="70%"
            visible={ui.showDrawer}
            closeIcon={<MinusOutlined />}
            limparAoEditar={true}
            fecharDrawer={() => { fecharDrawer() }}
            footer={
                <div>
                    {!exibirCustos &&
                        <Row align="middle" justify="end" gutter={[8, 0]}>
                            <Col>
                                <Button onClick={fecharDrawer} icon={<CloseOutlined />} size="large" htmlType="button">
                                    Cancelar
                                </Button>
                            </Col>
                        </Row>}
                    {exibirCustos &&
                        <Row align="middle" justify="end" gutter={[8, 0]}>
                            <Col>
                                <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                    Cancelar
                                </Button>
                            </Col>
                            <Col>
                                <Button onClick={() => formEstoqueInventario.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                    Salvar
                                </Button>
                            </Col>
                        </Row>}
                </div>
            }>
            <ManutencaoEstoqueInventario formulario={formEstoqueInventario} carregando={setCarregando} aoSalvar={fecharDrawer} exibirCustos={exibirCustos} setExibirCustos={setExibirCustos} />
        </DrawerNav>
    )
}