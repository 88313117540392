import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Select, Input, Button, Switch, Tag, Alert, Typography, Divider, Table, Modal, Tooltip, InputNumber } from "antd";
import { PlusOutlined, DownloadOutlined, BarcodeOutlined, SearchOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import CurrencyInput from 'react-currency-input';

import api from '../../services/api';
import { FormatNumber } from '../../ValueObjects';
import { ModalGrade } from '../../components/modals';
import { InputPreco, SelectPaginacao, SelectPagination } from "../../components";
import SelectPaginado from "../../components/selectPaginado";
import { useStateValue } from "../../state";
import { selectPaginadoActions } from "../../actions";

export default function TabItens({ formulario, listaItensExcluir, setListaItensExcluir, listaEditarItens, setListaEditarItens, setEditandoItens }) {

    const [formItens] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();
    const [formImportItens] = Form.useForm();
    const [formTableItens] = Form.useForm();
    const [filtros, setFiltros] = useState([]);
    const [itemProduto, setItemProduto] = useState(false);
    const [importarProduto, setImportarProduto] = useState(false);
    const [editiItemId, setEditiItenId] = useState({});
    const [visibleModalGrade, setVisibleModalGrade] = useState(false);
    const [grades, setGrades] = useState([]);
    const [subgrupos, setSubgrupos] = useState(false);
    const [listaPrecoSelectd, setListaPrecoSelectd] = useState(null);
    const [itens, setItens] = useState(() => {
        if (formulario.getFieldValue('ltp_id')) {
            let list = formulario.getFieldValue('itens');
            return list ? list : [];
        }
        return []
    });
    const searchInput = useRef();
    const [productName, setProductName] = useState('');


    useEffect(() => {
        formulario.setFieldsValue({ itens: itens });
    }, [itens])

    const togglePesquisa = () => {
        setProductName('');
        setItemProduto(!itemProduto);
    };

    async function onPressEnterProCodigo(campo) {
        let codigoEan = campo.target.value.trim();
        let dadosRequisicao = await api.get(`Produto/ListaProdutoFiltro?filtro=${codigoEan}`);
        setProductName(dadosRequisicao.data.items[0].pro_descricao );
        
        let grade = dadosRequisicao.data.items[0].prg_id;
        if (dadosRequisicao.data.items[0].pro_usagrade) {
            let itenSelected = (await api.get(`Produto/RetornaDadosItem?ProdutoId=${dadosRequisicao.data.items[0].pro_id}`)).data;
            setGrades([]);
            api.get(`ProdutoGrade/RetornaGradesProduto?produtoId=${dadosRequisicao.data.items[0].pro_id}`).then((res) => {
                if (res.status === 200) {
                    let array = [...res.data].filter(item => item.prg_id == grade);
                    array = array.map((x) => ({ ...{ ...itenSelected, ...x }, lpi_valorvenda: x.prg_valorvenda, lpi_naocalculacomissao: false }));
                    setGrades(array);
                    setVisibleModalGrade(true);
                }
            }).catch((err) => {
                console.log('error ao buscar grades :>> ', err);
            });
        }
        else {
            onChangeProdutoFiltro(dadosRequisicao.data.items[0].pro_id, 2);
            formulario.setFieldsValue({ pro_id: dadosRequisicao.data.items[0].pro_id });
        }
    };

    async function onChangeProdutoFiltro(value, chamada) {
        let itenSelected = (await api.get(`Produto/RetornaDadosItem?ProdutoId=${value}`)).data;

        if (itenSelected.pro_usagrade) {
            setGrades([]);
            api.get(`ProdutoGrade/RetornaGradesProduto?produtoId=${value}`).then((result) => {
                if (result.status === 200) {
                    let array = [...result.data];
                    array = array.map((x) => ({ ...{ ...itenSelected, ...x }, lpi_valorvenda: x.prg_valorvenda, lpi_naocalculacomissao: false }));
                    setGrades(array);
                    setVisibleModalGrade(true);
                    formItens.resetFields();
                }
            }).catch((err) => {
                console.log('error ao buscar grades :>> ', err);
            });
        } else
            api.get(`ListaPrecoItem/BuscaPrecoItem?CodigoListaPreco=${formulario.getFieldValue('ltp_id')}&CodigoProduto=${value}`).then((res) => {
                    if (res.data) {
                        formItens.setFieldsValue(res.data);
                    } else {
                        api.get(`ListaPrecoItem/BuscarPrecoPadraoItem?codigoProduto=${value}`).then((res) => {
                            if (res.status === 200) {
                                let valorVenda = 1;
                                if (res.data)
                                    valorVenda = res.data;
                                formItens.setFieldsValue({
                                    lpi_valorvenda: valorVenda,
                                    lpi_perccomissao: 0,
                                    lpi_naocalculacomissao: false
                                });
                            }
                        });
                    }
            });
    }

    const onSaveGrades = () => {
        let array = [...itens];
        let verify = grades.filter(x => x.lpi_valorvenda !== null && parseFloat(x.lpi_valorvenda) !== 0);
        if (verify.length !== 0) {
            grades.forEach(element => {
                if (element.lpi_valorvenda !== null) {
                    let index = array.findIndex(x => x.pro_id == element.pro_id && x.prg_ean == element.prg_ean);
                    element.lpi_valorvenda = parseFloat(element.lpi_valorvenda.toString().replaceAll(`.`, ``).replaceAll(`,`, `.`));
                    if (index !== -1) {
                        array[index] = element;
                       // console.log("editou ==>")
                    } else {
                        array.push(element);
                    }
                }
            });
            setItens(array);
            onCloseModal();
        } else {
            Modal.warning({
                title: 'Atenção',
                icon: <ExclamationCircleOutlined />,
                content: `Existem itens da grade que não possuem valor informado, por favor verifique-os!`,
                okText: 'Ok',
                centered: true,
            });
        }
    }

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
            <div className="p-10">
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Input
                            ref={searchInput}
                            placeholder={`Buscar por...`}
                            value={selectedKeys[0]}
                            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => handleSearch(selectedKeys, dataIndex, clearFilters)}
                        />
                    </Col>
                    <Col span={24}>
                        <Button type="primary" onClick={() => handleSearch(selectedKeys, dataIndex, clearFilters)} icon={<SearchOutlined />} size="small" block>
                            Filtrar
                        </Button>
                    </Col>
                </Row>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.focus());
            }
        },
        render: text => text
    });

    const handleSearch = (selectedKeys, dataIndex, clearFilters) => {
        let listaFiltros = [...filtros];
        let indice = listaFiltros.findIndex(f => f.campo === dataIndex);
        if (indice < 0) listaFiltros.push({ campo: dataIndex, valor: selectedKeys[0], clearFilters });
        else listaFiltros[indice].valor = selectedKeys[0];
        setFiltros(listaFiltros);
    };

    async function onFinishItens(values) {
        let produto = (await api.get(`Produto/RetornaDadosItem?ProdutoId=${formulario.getFieldValue().pro_id}`)).data;
        delete produto.lpi_valorvenda;
        delete values.produto;
        let array = [...itens];
        let index = array.findIndex(x => x.pro_id == produto.pro_id);
        values.pro_id = formulario.getFieldValue().pro_id;
        values.lpi_perccomissao = !!values.lpi_perccomissao ? values.lpi_perccomissao : 0;
        values.lpi_naocalculacomissao = !!values.lpi_naocalculacomissao ? values.lpi_naocalculacomissao : false;
        let obj = { ...produto, ...values };

        if (index !== -1) {
            array.splice(index, 1, { ...obj });
            setItens(array);
        } else
            setItens((prev) => ([...prev, { ...obj }]));

        formItens.resetFields();
    }

    function onDeleteItem(value) {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja excluir o item selecionado?`,
            okText: 'Excluir',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                deleteItem(value);
            }
        });
    }

    function deleteItem(value) {
        let array = [...itens];
        let index = array.indexOf(value);
        if (index !== -1) {
            array.splice(index, 1);
            setItens(array);
        }
        let listaExcluir = [...listaItensExcluir];
        listaExcluir.push(value);
        setListaItensExcluir(listaExcluir);
    }

    const EditableCell = ({
        editing,
        dataIndex,
        title,
        inputType,
        record,
        index,
        children,
        required,
        ...restProps
    }) => {
        const inputNode = inputType === 'number' ? (
            <CurrencyInput className="CurrencyInput" inputType="text" decimalSeparator="," thousandSeparator="." />
        ) : (
            <InputNumber
                min={0}
                max={100}
                formatter={value => `${value}%`}
                parser={value => value.replace('%', '')}
            />
        );
        return (
            <td {...restProps}>
                {editing ? (
                    <Form.Item
                        name={dataIndex}
                        className="m-0"
                        rules={[
                            {
                                required: required === true,
                                message: `Por favor, insira  ${title}!`,
                            },
                        ]}
                    >
                        {inputNode}
                    </Form.Item>
                ) : (
                    children
                )}
            </td>
        );
    };

    const editRow = (record) => {
        formTableItens.setFieldsValue({ ...record });
        setEditiItenId({ pro_id: record.pro_id, prg_ean: record.prg_ean });
        setEditandoItens(true);
    }

    const saveRow = async (data) => {
        try {
            const obj = await formTableItens.validateFields();
            if (typeof obj.lpi_valorvenda !== `number`) {
                obj.lpi_valorvenda = parseFloat(obj.lpi_valorvenda.toString().replaceAll(`.`, ``).replaceAll(`,`, `.`));
            }
            const array = [...itens];
            const index = array.findIndex(x => x === data);
            let reg = { ...array[index] };
            if (!!reg.lpi_id) {
                let listaEditarItensTemp = [...listaEditarItens];
                listaEditarItensTemp.push({ ...array[index], ...obj });
                setListaEditarItens(listaEditarItensTemp);
            }
            if (index !== -1) {
                array.splice(index, 1, { ...array[index], ...obj });
                setItens(array);
            }
            setEditiItenId({});
            setEditandoItens(false);
        } catch (error) {
            console.log('Valores inválidos ', error);
        }
    }
    const onCloseModal = () => setVisibleModalGrade(false);

    const cancelEdition = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: `Tem certeza que deseja cancelar?`,
            okText: 'OK',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                setEditandoItens(false);
                setEditiItenId({});
            }
        });
    }

    const isEditing = (record) => record.pro_id === editiItemId.pro_id && record.prg_ean === editiItemId.prg_ean;

    const columns = [
        {
            title: 'Código',
            width: 75,
            align: 'center',
            render: ({ pro_codigo }) => (
                <Tag color="processing" className="w-100">
                    <b>{pro_codigo}</b>
                </Tag>
            ),
        },
        {
            title: 'Produto',
            ...getColumnSearchProps('produto'),
            render: ({ pro_descricao, pro_ean, prg_ean, descricaoGrade }) => (
                <div>
                    <Row align="middle">
                        <Col span={24}>
                            <b>{pro_descricao}</b>
                        </Col>
                        <Col>
                            <small>
                                EAN: <b>{pro_ean}</b>
                            </small>
                        </Col>
                    </Row>
                    {prg_ean && <Row align="middle" gutter={[8, 0]}>
                        <Col>
                            <small>
                                EAN Grade: <b>{prg_ean}</b>
                            </small>
                        </Col>
                        <Col>
                            <small>
                                Grade: <b>{descricaoGrade}</b>
                            </small>
                        </Col>
                    </Row>}
                </div>
            ),
        },
        {
            title: 'Comissão',
            align: 'right',
            dataIndex: 'lpi_perccomissao',
            width: '25%',
            editable: true,
            render: text => <b>{text}%</b>
        },
        {
            title: 'Valor Item (R$)',
            dataIndex: 'lpi_valorvenda',
            width: '25%',
            align: 'right',
            required: true,
            editable: true,
            render: text => <b>{FormatNumber(text, true)}</b>
        },
        {
            title: 'Ações',
            align: 'center',
            key: 'x',
            width: 65,
            fixed: 'right',
            render: (record) => {
                const editable = isEditing(record);
                return editable ? (
                    <div>
                        <Row align="middle" justify="center" gutter={[5, 0]}>
                            <Col>
                                <Button
                                    htmlType="submit"
                                    onClick={() => saveRow(record)}
                                    icon={<CheckOutlined />}
                                />
                            </Col>
                            <Col>
                                <Button onClick={cancelEdition} icon={<CloseOutlined />} />
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <div>
                        <Row align="middle" justify="center" gutter={[5, 0]}>
                            <Col>
                                <Button onClick={() => editRow(record)} icon={<EditOutlined />} />
                            </Col>
                            <Col>
                                <Button icon={<DeleteOutlined />} onClick={() => onDeleteItem(record)} />
                            </Col>
                        </Row>
                    </div>
                )
            },
        },
    ];
    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: col.dataIndex === 'lpi_perccomissao' ? 'percent' : 'number',
                dataIndex: col.dataIndex,
                title: col.title,
                required: col.required,
                editing: isEditing(record),
            }),
        };
    });

    ///import produtos por lista de preço 
    async function onChangeListaPreco(value) {
        api.get(`ListaPreco/ListaPreco?codigoLista=${value}`).then(
            res =>
                setListaPrecoSelectd(res.data)
        ).catch(
            error => console.log(error)
        )
    }

    const updateQtdImports = () => {
        const { ltp_id, marId, grupoId, subgrupId } = formImportItens.getFieldsValue();
        const listPrecoDestino = formulario.getFieldValue('ltp_id');
        document.getElementById('qtdImport').innerHTML = 0;
        api.get(`ListaPreco/QuantidadeProdutosImportar?listaPrecoOrigemId=${ltp_id}&listaPrecoDestinoId=${listPrecoDestino}&marcaId=${marId}&grupoId=${grupoId}&grupsubGrupoIdoId=${subgrupId}`).then((result) => {
            if (result.status === 200) {
                document.getElementById('qtdImport').innerHTML = result.data;
            }
        })
    }

    const importItensForPriceList = () => {
        let ltp_id = formImportItens.getFieldValue().ltp_id;
        let filtroMarca = !!formImportItens.getFieldValue().mar_id ? `&MarcaId=${formImportItens.getFieldValue().mar_id}` : '';
        let filtroGrupo = !!formImportItens.getFieldValue().gru_id ? `&GrupoId=${formImportItens.getFieldValue().gru_id}` : '';
        let filtroSubGp = !!formImportItens.getFieldValue().sgp_id ? `&grupsubGrupoIdoId=${formImportItens.getFieldValue().sgp_id}` : '';
        api.get(`ListaPrecoItem/ListarItens?codigoLista=${ltp_id}${filtroMarca}${filtroGrupo}${filtroSubGp}`).then(
            (result) => {
                if (result.status === 200) {
                    let codigoLista = formulario.getFieldsValue().ltp_id;
                    let array = [...itens];
                    const listPrice = result.data;
                    listPrice.forEach(element => {
                        element.ltp_id = codigoLista;
                        element.lpi_id = 0;
                        let index = array.findIndex(x => x.pro_id == element.pro_id && x.prg_ean == element.prg_ean);
                        if (index !== -1) {
                            array[index] = element;
                        } else {
                            array.push(element);
                        }
                    });
                    setItens(array);
                    setImportarProduto(false);
                    setListaPrecoSelectd(null);
                    formImportItens.resetFields();
                }
            })
    }

    function onChangeGrupo(value) {
        let lista = [];
        lista.push({ name: "sgp_id", campo: "CodigoGrupo", value: value });
        lista.push({ name: "sgp_id", campo: "Ativo", value: true });
        if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        setSubgrupos(true);
    }

    const filtroItens = (value) => {
        api.get(`ListaPrecoItem/ListarItens?CodigoLista=${formulario.getFieldValue().ltp_id}&Descricao=${value}`).then(
            res => {
                const data = res.data;
                setItens(data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    }

    return (
        <div>
            <div>
                <Row align="middle" justify="center" gutter={[8, 0]}>
                    <Col>
                        <Button className={!importarProduto ? 'ant-btn-primary' : ''} icon={<PlusOutlined />} onClick={() => setImportarProduto(false)}>
                            Adicionar Produtos à Lista
                        </Button>
                    </Col>
                    <Col>
                        <Button className={importarProduto ? 'ant-btn-primary' : ''} icon={<DownloadOutlined />} onClick={() => setImportarProduto(true)}>
                            Importar Produtos de uma Lista
                        </Button>
                    </Col>
                </Row>
                {!!importarProduto === false &&
                    <div className="m-t-16">
                        <Form layout="vertical" form={formItens} onFinish={onFinishItens}>
                            <Row gutter={[8, 0]}>
                                {itemProduto &&
                                    <Col xs={24} sm={21} md={16} lg={16} xl={17}>
                                        <Form.Item name="pro_id" label="Localize o Produto">
                                            <Input ref={searchInput} name="pro_id" placeholder="Informe o Produto/Mercadoria" form={formulario} onChange={(value) => { onPressEnterProCodigo(value) }}
                                             conteudo={
                                                pro => (<Select.Option value={pro.pro_id} key={pro.key}>{pro.pro_id} - {pro.pro_descricao}</Select.Option>)
                                            }
                                            />
                                            <div>
                                                {productName && (
                                                    <p>Nome do Produto: {productName}</p>
                                                )}
                                            </div>
                                        </Form.Item>
                                    </Col>
                                }
                                {!itemProduto &&
                                    <Col xs={24} sm={21} md={16} lg={16} xl={17}>
                                        <Form.Item name="pro_id" label="Selecione o Produto">
                                            <SelectPaginado url="Produto/ListaProdutoFiltroSemGrade" placeholder="Filtrar por Produto..." form={formulario} name="pro_id" onChangeFunction={(value) => onChangeProdutoFiltro(value,1)}
                                                conteudo={
                                                    pro => (<Select.Option value={pro.pro_id} key={pro.key}>{pro.pro_id} - {pro.pro_descricao}</Select.Option>)
                                                } />
                                        </Form.Item>
                                    </Col>
                                }
                                <Col xs={24} sm={3} md={2} lg={2} xl={2} className="i-button tt0">
                                    <Tooltip title={!itemProduto ? 'Localize produto por EAN' : 'Selecione o produto'} placement="top">
                                        <Button onClick={togglePesquisa} block>
                                            {itemProduto ? <SearchOutlined /> : <BarcodeOutlined />}
                                        </Button>
                                    </Tooltip>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={6} xl={5} className="tt-8">
                                    <InputPreco label="Valor do Item (R$)" name="lpi_valorvenda" rules={[{ required: true, message: 'O valor do item deve ser informado.' }]} />
                                </Col>
                                <Col xs={24} sm={12} md={9} lg={8} xl={5}>
                                    <Form.Item label="Percentual de Comissão" name="lpi_perccomissao">
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={9} lg={7} xl={7} xxl={6} className="switch">
                                    <Form.Item
                                        valuePropName="checked"
                                        name="lpi_naocalculacomissao"
                                        label="Considerar Percentual de Desconto">
                                        <Switch />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={5} xl={4}>
                                    <div className="t-mob">
                                        <Button type="primary" icon={<PlusOutlined />} onClick={() => formItens.submit()} block>
                                            Adicionar Item
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                        <Form form={formTableItens}>
                            <div className="m-t-8">
                                <Row align="middle" style={{ marginBottom: '10px' }}>
                                    <Col span={24}>
                                        <Typography.Text strong>
                                            A lista abaixo está sendo filtrada por:
                                        </Typography.Text>
                                        {filtros.map((_filtro, indice) => (
                                            <Tag closable onClose={() => {
                                                filtros[indice].clearFilters();
                                                let _filtros = [...filtros];
                                                _filtros.splice(indice, 1);
                                                setFiltros(_filtros);
                                            }} className="m-l-5 text-uppercase">
                                                {`${_filtro.valor}`}
                                            </Tag>
                                        ))}
                                    </Col>
                                </Row>
                                <div>
                                    <Row align="middle">
                                        <Col span={24}>
                                            <Form.Item name="pesquisa">
                                                <Input.Search placeholder={"Pesquisar por Produto"}  onSearch={ value => { filtroItens(value) }} allowClear/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="tabela mt-dif">
                                    <Table dataSource={itens}
                                        components={{
                                            body: {
                                                cell: EditableCell,
                                            },
                                        }}
                                        columns={mergedColumns} locale={{
                                            emptyText: (
                                                <Row>
                                                    <Col span={24}>
                                                        <Divider orientation="center">
                                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                        </Divider>
                                                    </Col>
                                                </Row>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </Form>
                    </div>
                }
            </div>

            {!!importarProduto === true &&
                <Form layout="vertical" form={formImportItens} onValuesChange={updateQtdImports}>
                    <Row gutter={[0, 16]} className="m-t-16">
                        <Col>
                            <Alert message="Utiliando esta opção você poderá importar produtos de outras listas de preço da empresa, podendo ainda filtrar os produtos por marca, grupo/subgrupo, entre outras opções de filtro." type="warning" showIcon />
                        </Col>
                        <Col span={24}>
                            <Form.Item name="ltp_id" label="Selecione uma lista de preço para importar os produtos">
                                <SelectPaginado
                                    url="ListaPreco/RetornaListaPrecoEmpresa"
                                    form={formImportItens}
                                    name="ltp_id"
                                    placeholder="Filtrar por lista de preço"
                                    onChangeFunction={(value) => onChangeListaPreco(value)}
                                    showSearch={true}
                                    allowClear={true}
                                    conteudo={
                                        lp => (<Select.Option value={lp.ltp_id} key={lp.key}>{lp.ltp_nome}</Select.Option>)
                                    } />
                            </Form.Item>
                        </Col>
                    </Row>
                    {listaPrecoSelectd != null && <div>
                        <Row gutter={[0, 16]}>
                            <Col span={24}>
                                <Typography.Title level={3}>
                                    <img src={require("../../assets/i-produto.png").default} alt="Filtrar produtos" /> Produtos
                                </Typography.Title>
                                <Divider orientation="left" plain>
                                    Realize o filtro dos produtos da lista de preço selecionada para importar
                                </Divider>
                            </Col>
                        </Row>
                        <Row gutter={[8, 16]}>
                            <Col span={8}>
                                <Form.Item name="mar_id" label="Marca">
                                    <SelectPaginado
                                        url="Marca/Listar"
                                        form={formImportItens}
                                        name="mar_id"
                                        placeholder="Selecione a Marca"
                                        showSearch={true}
                                        allowClear={true}
                                        conteudo={
                                            mar => (<Select.Option value={mar.mar_id} key={mar.key}>{mar.mar_nome}</Select.Option>)
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="gru_id" label="Grupo">
                                    <SelectPaginado
                                        url="GrupoProduto/Listar"
                                        form={formImportItens}
                                        name="gru_id"
                                        showSearch={true}
                                        allowClear={true}
                                        onChangeFunction={(value) => onChangeGrupo(value)}
                                        conteudo={
                                            gp => (<Select.Option value={gp.gru_id} key={gp.key}>{gp.gru_nome}</Select.Option>)
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            {subgrupos &&
                                <Col span={8}>
                                    <Form.Item name="sgp_id" label="Subgrupo">
                                        <SelectPaginado
                                            url="SubGrupo/Listar"
                                            form={formImportItens}
                                            name="sgp_id"
                                            showSearch={true}
                                            allowClear={true}
                                            conteudo={
                                                sgp => (<Select.Option value={sgp.sgp_id} key={sgp.key}>{sgp.sgp_nome}</Select.Option>)
                                            }
                                        />
                                    </Form.Item>
                                </Col>}
                        </Row>
                    </div>}
                    <Row>
                        <Col span={24}>
                            <Row align="middle" justify="center" gutter={[8, 0]}>
                                <Col>
                                    <Typography.Text>
                                        Total de produtos à importar: <b className="f-18" id="qtdImport">0</b>
                                    </Typography.Text>
                                </Col>
                            </Row>
                            <Row align="middle" justify="center" gutter={[8, 0]}>
                                <Col>
                                    <Button onClick={() => importItensForPriceList()}
                                        type="primary"
                                        size="middle"
                                        icon={<DownloadOutlined />}>
                                        Importar Produtos de uma Lista
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            }
            <ModalGrade visible={visibleModalGrade} onCloseModal={onCloseModal} list={grades} setList={setGrades} onSave={onSaveGrades} />
        </div>
    );
}