import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Divider, Form, Input, Button, notification } from "antd";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { isObjetoDiffVazio } from "../../services/funcoes";
import { getIdUsuario } from "../../services/auth";
import { UnlockFilled } from '@ant-design/icons';

export default function TabSenhaGerencial(form) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [possuiSenha, setPossuiSenha] = useState(true);
    const [Usuario, setUsuario] = useState([]);

    useEffect(() => {
        if (!!manutencao.dados && isObjetoDiffVazio(manutencao.dados)) {
            if (!!manutencao.dados.loc_senhagerencial) {
                setPossuiSenha(true);
            } else {
                setPossuiSenha(false);
            }
        } else {
            setPossuiSenha(false);
        }
        carregarUsuario();
    }, [manutencao.dados]);

    function carregarUsuario() {
        let userId = parseInt(getIdUsuario());
        api.get(`Usuario/RetornaUsuarioId?IdUser=${userId}`).then(
            res => {
                setUsuario(res.data);
                console.log("usuario", res.data);
            }
        ).catch(err => {
            console.log(err);
        })
    };

    function ValidarSenhaGerencial(value) {
        if (!!value && !!manutencao.dados.loc_id) {
            api.get(`Local/ValidarSenhaGerencial?LocalId=` + manutencao.dados.loc_id + `&SenhaGerencial=` + value).then(
                (res) => {
                    if (res.status === 200) {
                        if (res.data == false)
                            notification.error({ message: 'Atenção', description: "Senha atual e nova senha não conferem!" });
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                    notification.error({ message: 'Atenção', description: "Não foi possível validar a senha gerencial!" });
                }
            );
        }
    };

    //ta funcionando
    function RecuperaSenhaGerencial() {
        if (Usuario.usu_admin === true) {
            let emailUsuario = Usuario.usu_email;
            if (!!emailUsuario) {
                api.put(`Usuario/EnviarEmailRecuperarSenhaGerencial/${emailUsuario}`).then(
                    res => {
                        notification.success({ message: 'Operação concluída', description: "Enviamos um E-mail para você com as instruções para recuperar a senha gerencial!" })
                    }
                ).catch(
                    error => {
                        notification.warning({ message: `AVISO!`, description: `Usuário não encontrado!` });
                    }
                )
            }
        }
        else {
            notification.error({ message: 'Atenção', description: "Usuário não é Administrador" });
        }
    };

    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-password.png").default} alt="Senha Gerencial" /> Senha Gerencial
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        A Senha Gerencial é utilizada no Gestor Web para ser utilizada pelos gerentes e supervisores para liberar descontos acima do limite definido, vendas a prazo para clientes com limite de crétido abaixo do necessário, dentre outras operações.
                    </Divider>
                </Col>
            </Row>
            <Row gutter={[8, 0]} className="m-t-16">
                {possuiSenha && <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item label="Senha Gerencial Atual" name="senhaGerencialAtual">
                        <Input.Password placeholder="Informe a Senha Gerencial Atual" onBlur={(event) => { ValidarSenhaGerencial(event.currentTarget.value); }} />
                    </Form.Item>
                </Col>}
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                    <Form.Item label="Nova Senha Gerencial" name="novaSenhaGerencial">
                        <Input.Password placeholder="Informe uma Nova Senha Gerencial" />
                    </Form.Item>
                </Col>
                {possuiSenha &&<Col>
                    <Button type="primary" onClick={() => RecuperaSenhaGerencial()} size="small">
                        Recuperar Senha gerencial <UnlockFilled />
                    </Button>
                </Col>}

            </Row>
        </div>
    );
}