import React, {useEffect, useState} from "react";
import { Button, Col, Form, notification, Row, Table, Tag } from "antd";
import { CheckOutlined, CloseOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { FormatNumber } from "../../../ValueObjects";
import InputNumber from "rc-input-number";

export default function TabItensSelecionar({ itens, confirmarItens, modalFechada }) {
    const [form] = Form.useForm();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [listaItens, setListaItens] = useState([]);
    const [botaoConfirmar, setBotaoConfirmar] = useState(true);
    const [editando, setEditando] = useState(null);
    const [itemEditado, setItemEditado] = useState({});

    useEffect(() => {
        setListaItens(itens);
    }, [itens]);

    useEffect(() => {
        setBotaoConfirmar(selectedRowKeys.length === 0);
    }, [selectedRowKeys]);

    useEffect(() => {
        if (modalFechada) {
            setSelectedRowKeys([]);
        }
    }, [modalFechada])

    const itensSelecionados = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        if (newSelectedRowKeys.length === 0) {
            setListaItens(itens.map(item => ({ ...item, disabled: false })));
            confirmarItens([]);
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: itensSelecionados,
    }

    const editarItem = (record) => {
        setEditando(record.oit_id);
        setItemEditado({ ...itemEditado, [record.oit_id]: record.oit_qtde });
    }

    const cancelEdit = () => {
        setEditando(null);
        setItemEditado({});
    }

    const salvarEdicao = (record) => {
        const novosItens = listaItens.map((item) => 
            item.oit_id === record.oit_id ? { 
                ...item, 
                oit_qtde: itemEditado[record.oit_id] ,
                oit_valortotal: itemEditado[record.oit_id] * item.oit_valorunitario
            } : item
        )
        setListaItens(novosItens);
        setEditando(null);
        setItemEditado({});
    }

    const confirmarItensSelecionados = () => {
        const novosItens = listaItens.map(item => ({
            ...item,
            disabled: !selectedRowKeys.includes(item.oit_id)
        }));
        setListaItens(novosItens);
        setBotaoConfirmar(true);
        notification.success({ message: 'Aviso!', description: 'Item(ns) confirmado(s) com sucesso'})
        confirmarItens(novosItens.filter(item => !item.disabled));
    }

    return (
        <div>
            <div className="tabela m-t-8 p-b-75">
                <Row align="middle" justify="end" gutter={[8, 8]} className="m-t-5 m-b-5">
                    <Col xs={24} sm={12} md={12} lg={12} xl={10} xxl={4}>
                        <Button disabled={botaoConfirmar} onClick={confirmarItensSelecionados} icon={<CheckOutlined />} type="primary" block htmlType="submit">
                            Confirmar Itens Selecionados
                        </Button>
                    </Col>
                </Row>
                <Form name="formularioItensOrcamento" form={form}>
                <Table dataSource={listaItens} rowKey={(record) => record.oit_id} rowClassName={(record) => record.disabled ? 'disabled-row' : ''} columns={
                    [
                        {
                            title: 'Item',
                            render: ({ pro_codigo, pro_descricao, atributos }) => (
                                <div>
                                    <Row align="middle" gutter={[5, 5]}>
                                        <Col>
                                            <Tag color="processing" className="w-75">
                                                <b>{pro_codigo}</b>
                                            </Tag>
                                        </Col>
                                        <Col>
                                            <b>{pro_descricao}</b>
                                        </Col>
                                        {!!atributos &&
                                            <Col>
                                                <Tag color="default">
                                                    <b>{atributos.grade}</b>
                                                </Tag>
                                            </Col>
                                        }
                                    </Row>
                                </div>
                            ),
                        },
                        {
                            title: 'UM.',
                            width: 60,
                            align: 'right',
                            render: ({ ump_id }) => (
                                <div>
                                    <Row align="middle" justify="end">
                                        <Col>
                                            <b>{ump_id}</b>
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                        {
                            title: 'Qtde.',
                            width: 60,
                            align: 'right',
                            render: ({ oit_qtde, oit_id }) => (
                                <div>
                                    {editando === oit_id ? (
                                        <Row align="middle" justify="end">
                                            <Col>
                                                <InputNumber 
                                                    value={itemEditado[oit_id]}
                                                    onChange={(value) => setItemEditado({ ...itemEditado, [oit_id]: value })}
                                                    style={{ width: 80 }}
                                                />
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row align="middle" justify="end">
                                            <Col>
                                                <b>{oit_qtde}</b>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            ),
                        },
                        {
                            title: 'Vlr Unt.(R$)',
                            align: 'right',
                            width: 100,
                            render: ({ oit_valorunitario }) => (
                                <div>
                                    <b>{FormatNumber(oit_valorunitario, true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Vlr Total (R$)',
                            align: 'right',
                            width: 100,
                            render: ({ oit_valorunitario, oit_qtde }) => (
                                <div>
                                    <b>{FormatNumber(oit_qtde * oit_valorunitario, true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            align: 'center',
                            fixed: 'right',
                            key: 'x',
                            width: 65,
                            render: ({ oit_id, oit_qtde }) => (
                                <div>
                                    {editando === oit_id ? (
                                        <Row align="middle" justify="center">
                                            <Col>
                                                <Button 
                                                    icon={<SaveOutlined />}
                                                    onClick={() => salvarEdicao({ oit_id })}
                                                    style={{ marginLeft: 8 }}
                                                />
                                                <Button
                                                    icon={<CloseOutlined />}
                                                    onClick={cancelEdit}
                                                    style={{ marginLeft: 8 }}
                                                />
                                            </Col>
                                        </Row>
                                    ) : (
                                        <Row align="middle" justify="center">
                                            <Col>
                                                <Button 
                                                    icon={<EditOutlined />}
                                                    onClick={() => editarItem({ oit_id, oit_qtde })}
                                                    style={{ marginLeft: 8 }}
                                                />
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            ),
                        }
                    ]
                } rowSelection={rowSelection} />
                </Form>
            </div>
        </div>
    )
}