import React, { useEffect, useState, useRef } from 'react';
import { Form, Input, notification } from 'antd';
import { HasValue } from '../../services/funcoes';

export default function InputEmail(props) {

    const [value, setValue] = useState('');
    const [isValid, setIsValid] = useState(true);
    const inputRef = useRef(null);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleBlur = (e) => {
        if (HasValue(value)) {
            if (!validateEmail(value)) {
                setIsValid(false);
                notification.warning({ message: `Aviso!`, description: `E-mail invalido` });
                setTimeout(() => inputRef.current.focus(), 0); // Retorna o foco ao campo
            } else {
                setIsValid(true);
            }
        }
        if (!!props.onBlur) {
            props.onBlur(e);
        }
    };

    const handleChange = (e) => {
        setValue(e.target.value);
        if (!!props.onChange) {
            props.onChange(e.target.value);
        }
    };

    return (
        <Form.Item
            ref={props.ref}
            name={props.name}
            label={props.label}
            initialValue={props.initialValue}
            style={props.style || {}}
            className={props.className || ''}
            hidden={!!props.hidden}
            rules={props.rules || []}
        >
            <Input
                {...props}
                ref={inputRef}
                id={props.name}
                disabled={!!props.disabled ? props.disabled : false}
                placeholder={props.placeholder || 'Informe o Email'}
                value={value}
                className={`ant-input ant-input-sm ${!isValid ? 'input-error' : ''}`}
                onBlur={handleBlur}
                onChange={handleChange}
            />
        </Form.Item>
    );
}
