import React from "react";
import { Row, Col, Typography, Divider, Form, Input } from "antd";
import { InputCep } from "../../components";

export default function TabEndereco({ verificaCep }) {

    return (
        <div>
            <Row align="middle" gutter={[0, 8]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-endereco.png").default} alt="Endereço" /> Endereço
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Adicione abaixo as informações referente ao endereço.
                    </Divider>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={8} md={5} lg={4} xl={4} xxl={3}>
                            <Form.Item hidden name="cid_id" />
                            <Form.Item hidden name="cep_id" />
                            <Form.Item hidden name="est_sigla" />
                            <Form.Item hidden name="log_id" />
                            <Form.Item hidden name="bai_id" />
                            <Form.Item hidden name="est_id" />
                            <Form.Item hidden name="lcd_id" />
                            <Form.Item hidden name="pse_id" />
                            <InputCep label="CEP" name="cep_cep" rules={[{ required: true, message: 'Informe o CEP' }]} onBlur={(event) => { verificaCep(event.currentTarget.value); }} />
                        </Col>
                        <Col xs={24} sm={8} md={3} lg={3} xl={3} xxl={3}>
                            <Form.Item className="text-center t-mob-573">
                                <Typography.Link href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="_black" underline>
                                    Não sei o CEP
                                </Typography.Link>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={6} lg={5} xl={5} xxl={4}>
                            <Form.Item label="Cidade" name="cid_descricao">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={19} md={10} lg={9} xl={9} xxl={8}>
                            <Form.Item label="Endereco" name="log_logradouro" rules={[{ required: true }]}>
                                <Input placeholder="Informe o Endereço"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={5} md={4} lg={3} xl={3} xxl={3}>
                            <Form.Item label="Número" name="loc_numeroestabelecimento">
                                <Input placeholder="Informe o Número"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={5} lg={4} xl={4} xxl={3}>
                            <Form.Item label="Bairro" name="bai_nome" rules={[{ required: true }]}>
                                <Input placeholder="Informe o Bairro"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={5} lg={4} xl={4} xxl={4}>
                            <Form.Item label="Complemento" name="loc_complemento">
                                <Input placeholder="Informe o Complemento"/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={10} lg={8} xl={8} xxl={6}>
                            <Form.Item label="Ponto de Referência" name="loc_pontoreferencia">
                                <Input placeholder="Informe o Ponto de Referência"/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}