import React, { useEffect, useState } from "react";
import { Form, Row, Col, Select, Input, Radio, Card, Button, notification, InputNumber } from "antd";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import { AimOutlined } from "@ant-design/icons";
import moment from 'moment'

import Data from "../data";
import api from "../../services/api";
import { useStateValue } from "../../state";
import { linkApi } from "../../services/auth";
import { FormGW, InputPreco } from "../../components";
import { consultaCNPJ, retornaValorPorExtenso } from "../../services/funcoes";
import InputPhone from "../inputPhone";
import SelectPaginado from "../selectPaginado";
import { manutencaoActions } from "../../actions";

export default function LancamentoCheque({ form, carregando, funcaoSalvar }) {

    const [listaCliente, setListaCliente] = useState([]);
    const [contaCorrente, setContaCorrente] = useState([]);
    const [cliente, setCliente] = useState({ pes_id: null, pes_nome: null });
    const [infoBanco, setInfoBanco] = useState({});
    const [detalhesCheque, setDetalhesCheque] = useState({});
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [listaCheques, setListaCheques] = useState([]);
    const [emissor, setEmissor] = useState(0); //0 - O Mesmo, 1 - Terceiro
    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => {
        api.get('Pessoa/ListarPessoasAtivas?ordem=+pes_nome').then(
            (res) => {
                if (res.status === 200) {
                    setListaCliente(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );

        api.get(`ContaCorrente/AtivosTipoConta?tipoConta=2&ordem=+ctc_descricao`).then(
            (res) => {
                if (res.status === 200) {
                    setContaCorrente(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    }, []);


    useEffect(() => {
        if (!!listaCheques && listaCheques.length > 0) {
            listaCheques.forEach((cheque, indice) => {
                form.setFieldsValue({ [`chq_valor${indice + 1}`]: cheque['chq_valor' + (indice + 1)] });
                valorPorExtenso(`chq_valor${indice + 1}`, `valorExtenso${indice + 1}`);
            });
        }
    }, [listaCheques]);

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.dadosModalCheque) {
            form.setFieldsValue({ valorTotalCheque: manutencao.dados.dadosModalCheque.valorTotalCheque });
            form.setFieldsValue({ quantidadeCheque: manutencao.dados.dadosModalCheque.quantidadeCheque });
            form.setFieldsValue({ pes_id: manutencao.dados.destinatario.pes_id });
            form.setFieldsValue({ pessoa_nome: manutencao.dados.destinatario.pes_nome });
            selecionarCliente( !!manutencao.dados.dadosModalCheque.cliente ? manutencao.dados.dadosModalCheque.cliente : manutencao.dados.destinatario);
            popularCheque();
        }
    }, [manutencao.dados]);

    const salvarManutencao = (values) => {
        values.ban_descricao = infoBanco.ban_descricao;
        let cheques = [];
        let valorCheques = 0;
        if (listaCheques.length > 0) {
            listaCheques.forEach(
                (cheque) => {
                    let obj = {};
                    obj.chq_codigocompensacao = values[`chq_codigocompensacao${cheque.sequencia}`];
                    obj.ban_id = values[`ban_id${cheque.sequencia}`];
                    obj.chq_agencia = values[`chq_agencia${cheque.sequencia}`];
                    obj.chq_c1 = values[`chq_c1${cheque.sequencia}`];
                    obj.chq_contacorrente = values[`chq_contacorrente${cheque.sequencia}`];
                    obj.chq_c2 = values[`chq_c2${cheque.sequencia}`];
                    obj.chq_serie = values[`chq_serie${cheque.sequencia}`];
                    obj.chq_numero = values[`chq_numero${cheque.sequencia}`];
                    obj.chq_c3 = values[`chq_c3${cheque.sequencia}`];
                    obj.chq_valor = values[`chq_valor${cheque.sequencia}`];
                    valorCheques += parseFloat(values[`chq_valor${cheque.sequencia}`]);
                    obj.valorExtenso = values[`valorExtenso${cheque.sequencia}`];
                    obj.chq_bompara = values[`chq_bompara${cheque.sequencia}`];
                    obj.pes_id = cliente.cli_id ?? manutencao.dados.destinatario.pes_id;
                    obj.chq_dataemissao = moment(values[`chq_dataemissao${cheque.sequencia}`]);
                    obj.chq_nomeemissor = detalhesCheque.nomeemissor;
                    obj.chq_cpfcnpjemissor = detalhesCheque.cpfcnpjemissor;
                    obj.chq_rgieemissor = detalhesCheque.rgieemissor;
                    obj.chq_telefoneemissor = values.telefoneemissor;
                    obj.chq_emissorclientedesde = detalhesCheque.emissorclientedesde;
                    obj.chq_observacao = values.chq_observacao;
                    obj.chq_cmc7 = '';
                    cheques.push(obj);
                }
            );
            values.cheques = cheques;
            if (valorCheques === parseFloat(form.getFieldValue().valorTotalCheque)) {
                funcaoSalvar(values, carregando);
            } else {
                notification.warning({ message: 'Aviso', description: 'Valor dos cheques diferente do valor total!' });
            }
        } else {
            notification.warning({ message: 'Aviso', description: 'Informe a quantidade e o valor total do(s) cheque(s)!' });
        }
        selecionarCliente();
    };

    function valorPorExtenso(campoValor, campoExtenso) {
        let valor = parseFloat(form.getFieldValue()[campoValor]);
        if (valor > 0) {
            form.setFieldsValue({ [campoExtenso]: retornaValorPorExtenso(valor) });
        }
    };

    function atualizaDetalhes() {
        let detalhes = {};
        if (!!form.getFieldValue().cpfcnpjemissor) {
            detalhes.cpfcnpjemissor = form.getFieldValue().cpfcnpjemissor;
        }
        if (!!form.getFieldValue().rgieemissor) {
            detalhes.rgieemissor = form.getFieldValue().rgieemissor;
        }
        if (!!form.getFieldValue().nomeemissor) {
            detalhes.nomeemissor = form.getFieldValue().nomeemissor;
        }
        if (!!form.getFieldValue().emissorclientedesde) {
            detalhes.emissorclientedesde = form.getFieldValue().emissorclientedesde;
        }

        setDetalhesCheque(detalhes);
    };

    function buscarBanco(event) {
        let ban_id = event.currentTarget.value;
        let id = event.currentTarget.id;
        if (!!ban_id) {
            api.get(`Banco/Buscar/${ban_id}`).then(
                (res) => {
                    if (!!res.data) {
                        setInfoBanco(res.data);
                    } else {
                        setInfoBanco({});
                        notification.warning({ message: 'Aviso', description: 'Banco informado inválido!' });
                        form.setFieldsValue({ [id]: null });
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            );
        } else {
            setInfoBanco({});
        }
    };

    async function selecionarCliente(pes_id) {
        if (!!pes_id) {
            let dadosCliente = (await api.get(`Cliente/BuscarByIdPessoa/${pes_id}`)).data;
            var cpfcnpjemissor = !!dadosCliente.pef_cpf ? dadosCliente.pef_cpf : dadosCliente.pej_cnpj;
            var nomeemissor = dadosCliente.pes_nome;
            var rgieemissor = !dadosCliente.pef_rg ? dadosCliente.pef_rg : dadosCliente.pej_inscricaoestadual;

            form.setFieldsValue({ cpfcnpjemissor: cpfcnpjemissor, nomeemissor: nomeemissor, rgieemissor: rgieemissor });
            setCliente({ pes_id: dadosCliente.pes_id, pes_nome: dadosCliente.pes_nome, cli_id: dadosCliente.cli_id });
            atualizaDetalhes();
        }
    };

    function buscaDadosEmissorCheque(cpfCnpj) {
        cpfCnpj = cpfCnpj.replace(/\D/g, '');
        if (cpfCnpj.length === 11) {
            api.get(`Cliente/ListaCliente?cpfCnpj=${cpfCnpj}`).then(
                (res) => {
                    if (res.status === 200) {
                        form.setFieldsValue({ nomeemissor: res.data.pes_nome, rgieemissor: res.data.pef_rg });
                        if (res.data.telefones.length > 0) {
                            form.setFieldsValue({ telefoneemissor: res.data.telefones[0].psc_numero });
                        }
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            );
        } else if (cpfCnpj.length === 14) {
            consultaCNPJ(cpfCnpj).then((json) => {
                form.setFieldsValue({ nomeemissor: json.nome/*, telefoneemissor: json.telefone*/ });
            }, (erro) => {
                console.log('ERRO:', erro);
            });
        }
    };

    function popularCheque() {
        let dados = [];
        let qtd = parseFloat(form.getFieldValue().quantidadeCheque) ?? 1;
        let valorTot = parseFloat(form.getFieldValue().valorTotalCheque);
        let restante = 0;
        if (!!qtd && !!valorTot && valorTot > 0) {
            let valorUn = valorTot / qtd;
            if (parseFloat(valorUn.toFixed(2)) * qtd !== valorTot) {
                restante = valorTot - (parseFloat(valorUn.toFixed(2)) * qtd);
            }
            for (let i = 1; i <= qtd; i++) {
                let cheque = {};
                cheque.sequencia = i;
                if (i === qtd) {
                    cheque['chq_valor' + i] = parseFloat(valorUn.toFixed(2)) + restante;
                } else {
                    cheque['chq_valor' + i] = parseFloat(valorUn.toFixed(2));
                }

                dados.push(cheque);
            }
            setListaCheques(dados);
        }
    };

    function validarDigito(e) {
        let id = e.target.id;
        let valor = parseInt(e.target.value);
        if (!!valor && valor > 9) {
            form.setFieldsValue({ [id]: null });
            notification.warning({ description: 'Verificador deve conter somente um digito!', message: 'Aviso' });
        }
    };

    return (
        <div>
            <FormGW layout="vertical" form={form} name="manutencaoCheque" onFinish={salvarManutencao}>
                <Row align="middle" gutter={[8, 0]}>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item label="Emissor do Cheque" name="radioemissocheque" initialValue={0}>
                            <Radio.Group defaultValue={0} options={[{ label: ' O Mesmo', value: 0 }, { label: 'Terceiro', value: 1 }]} onChange={(event) => { setEmissor(event.target.value) }} />
                        </Form.Item>
                    </Col>
                    {emissor === 0 &&
                        <Col xs={24} sm={12} md={8} lg={9} xl={9}>
                            <Form.Item name={"pessoa_nome"} label="Estou Recebendo Cheque de: ">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    }
                    <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                        {cliente.pes_id == null &&
                            <Form.Item name="quantidadeCheque" label="Quantidade Cheque" initialValue={0}>
                                <InputNumber step={1} min={0} precision={0} onBlur={() => { popularCheque() }} />
                            </Form.Item>
                        }
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={5} xl={5}>
                        <InputPreco name="valorTotalCheque" label="Valor Total (R$)" onBlur={() => { popularCheque() }} />
                    </Col>
                    {emissor === 1 &&
                        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.radioemissocheque !== currentValues.radioemissocheque}>
                            <Col xs={24} sm={12} md={12} lg={9} xl={9}>
                                <Form.Item label="CPF/CNPJ do Emissor do Cheque" name="cpfcnpjemissor">
                                    <CpfCnpj className='ant-input ant-input-sm'
                                        placeholder="Informe o CPF/CNPJ do Emissor do Cheque"
                                        value={cpfCnpj}
                                        onBlur={(e) => { buscaDadosEmissorCheque(e.target.value) }}
                                        onChange={(event, type) => {
                                            atualizaDetalhes();
                                            setCpfCnpj(event.target.value);
                                        }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={9} xl={9}>
                                <Form.Item label="Nome do Emissor do Cheque" name="nomeemissor">
                                    <Input onChange={atualizaDetalhes} placeholder="Informe o Nome do Emissor do Cheque" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={5} xl={5}>
                                <Form.Item label="RG/IE do Emissor" name="rgieemissor">
                                    <Input onChange={atualizaDetalhes} placeholder="Informe o Nome do Emissor do Cheque" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                <InputPhone label="Telefone do Emissor" name="telefoneemissor" />
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={4} xl={4}>
                                <Data disabled={true} onBlur={() => { atualizaDetalhes() }} label="Conta Desde" name="emissorclientedesde" />
                            </Col>
                        </Form.Item>
                    }
                    {cliente.pes_id == null &&
                        <Col xs={24} sm={24} md={emissor ? 24 : 16} lg={24} xl={24}>
                            <Form.Item label="Registrar entrada na Conta Corrente" name="ctc_id" rules={[{ required: true, message: 'Informe a Conta Corrente' }]}>
                                <SelectPaginado url="ContaCorrente/ListarAtivos" placeholder="Selecione a conta corrente" form={form} name="ctc_id" conteudo={
                                    cta => (<Select.Option value={cta.ctc_id} key={cta.ctc_id}>{cta.ctc_id} - {cta.ctc_descricao}</Select.Option>)
                                }
                                />
                            </Form.Item>
                        </Col>
                    }
                </Row>
                {listaCheques.map(cheque => (
                    <div className="colCheque">
                        <Card hoverable>
                            <Row align="middle" justify='space-between' gutter={[0, 16]}>
                                <Col >
                                    <Button icon={<AimOutlined />}>
                                        Utilizar Leitor de Cheques
                                    </Button>
                                </Col>
                                <Col>
                                    <b>Cheque {cheque.sequencia} de {listaCheques.length}</b>
                                </Col>
                            </Row>
                            <Row align="middle">
                                <Col span={24}>
                                    <Row gutter={[8, 8]}>
                                        <Col span={2}>
                                            <Form.Item label="Comp." name={`chq_codigocompensacao${cheque.sequencia}`} rules={[{ required: true, message: 'Campo Obrigatório ' }]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item label="Banco" name={`ban_id${cheque.sequencia}`} rules={[{ required: true, message: 'Campo Obrigatório ' }]}>
                                                <Input onBlur={(valor) => buscarBanco(valor)} id={`ban_id${cheque.sequencia}`} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item label="Agência" name={`chq_agencia${cheque.sequencia}`} rules={[{ required: true, message: 'Campo Obrigatório ' }]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item label="C1" name={`chq_c1${cheque.sequencia}`} rules={[{ required: true, message: 'Campo Obrigatório ' }]}>
                                                <Input onBlur={(valor) => validarDigito(valor)} id={`chq_c1${cheque.sequencia}`} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item label="Conta" name={`chq_contacorrente${cheque.sequencia}`} rules={[{ required: true, message: 'Campo Obrigatório ' }]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item label="C2" name={`chq_c2${cheque.sequencia}`} rules={[{ required: true, message: 'Campo Obrigatório ' }]}>
                                                <Input onBlur={(valor) => validarDigito(valor)} id={`chq_c2${cheque.sequencia}`} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item label="Série" name={`chq_serie${cheque.sequencia}`}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item label="Cheque Nº" name={`chq_numero${cheque.sequencia}`} rules={[{ required: true, message: 'Campo Obrigatório ' }]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Form.Item label="C3" name={`chq_c3${cheque.sequencia}`} rules={[{ required: true, message: 'Campo Obrigatório ' }]}>
                                                <Input onBlur={(valor) => validarDigito(valor)} id={`chq_c3${cheque.sequencia}`} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <InputPreco label="R$" name={`chq_valor${cheque.sequencia}`} rules={[{ required: true, message: 'Campo Obrigatório' }]} onChange={() => { valorPorExtenso(`chq_valor${cheque.sequencia}`, `valorExtenso${cheque.sequencia}`) }} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row align="middle" gutter={[0, 8]}>
                                <Col span={24}>
                                    <Form.Item name={`valorExtenso${cheque.sequencia}`} label="Pague por este cheque a quantia de">
                                        <Input disabled />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row align="middle" justify="center" gutter={[8, 0]}>
                                <Col span={12}>
                                    <Row justify="center">
                                        <Col>
                                            {!form.getFieldValue().ban_id &&
                                                <div className="imgCheque">
                                                    <img src={require('../../assets/logoGestor.png').default} alt='Gestor Web' />
                                                </div>
                                            }
                                            {!!form.getFieldValue().ban_id &&
                                                <div className="imgCheque">
                                                    <img src={`${linkApi}Banco/RetornaLogo/${form.getFieldValue().ban_id}`} />
                                                    <span>{JSON.stringify(infoBanco) !== '{}' && !!infoBanco.ban_descricao ? infoBanco.ban_descricao : 'Banco Desconhecido'}</span>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={12}>
                                    <Row gutter={[8, 0]}>
                                        <Col span={12}>
                                            <Data label="Cheque Emitido em" name={`chq_dataemissao${cheque.sequencia}`} initialValue={moment(new Date(), 'DD/MM/YYYY')} />
                                        </Col>
                                        <Col span={12}>
                                            <Data label="Bom Para" name={`chq_bompara${cheque.sequencia}`} />
                                        </Col>
                                    </Row>
                                    <Row gutter={[8, 0]}>
                                        {!!detalhesCheque.nomeemissor &&
                                            <Col span={12}>
                                                <small>{detalhesCheque.nomeemissor}</small>
                                            </Col>
                                        }
                                        {!!detalhesCheque.emissorclientedesde &&
                                            <Col span={12} className="text-right">
                                                <small>Cliente desde: {moment(detalhesCheque.emissorclientedesde).format('MM/YYYY')}</small>
                                            </Col>
                                        }
                                    </Row>
                                    <Row gutter={[8, 0]} justify="end">
                                        {!!detalhesCheque.cpfcnpjemissor &&
                                            <Col>
                                                <small>CPF/CNPJ: {detalhesCheque.cpfcnpjemissor}</small>
                                            </Col>
                                        }
                                        {!!detalhesCheque.rgieemissor &&
                                            <Col>
                                                <small>RG/IE: {detalhesCheque.rgieemissor}</small>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </div>
                ))}
                <Row>
                    <Col span={24}>
                        <Form.Item label="Observação" name="chq_observacao">
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
            </FormGW>
        </div>
    );

}