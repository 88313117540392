import React, { useState } from 'react';
import { Row, Col, Button, Modal, Form, Drawer } from 'antd';
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from '@ant-design/icons';

import ManutencaoMarcaVeiculo from './manutencao';
import { useStateValue } from '../../state';
import { drawerActions, manutencaoActions } from '../../actions';

export default function DrawerMarcaVeiculo({ cadMarcaVeiculo, openDrawer, setOpenDrawer, retornoSalvar }) {

    const [formMarcaVeiculo] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    if (manutencao.dados !== null) {
        formMarcaVeiculo.setFieldsValue(manutencao.dados);
    }
    else {
        formMarcaVeiculo.setFieldsValue({ mav_ativa: true });
    }

    function salvandoDados(retorno) {
        if (!!retornoSalvar) {
            retornoSalvar(retorno);
        }
        fecharDrawer();
    }

    function fecharDrawer() {
        formMarcaVeiculo.resetFields();
        if (!!openDrawer) {
            setOpenDrawer(false);
        }
        else {
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        }
    }

    const onClose = () => {
        Modal.confirm({
            title: "Cancelar?",
            icon: <ExclamationCircleOutlined />,
            content: "Deseja cancelar a operação e ignorar o que foi realizado até o momento?",
            okText: "Sim",
            cancelText: "Não",
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };


    return (
        <Drawer
            title="Cadastro Marca de Veículo"
            width="60%"
            visible={!!cadMarcaVeiculo ? ui.showDrawer : !!openDrawer ? openDrawer : false}
            closeIcon={<MinusOutlined />}
            onClose={() => onClose()}

            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formMarcaVeiculo.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoMarcaVeiculo formulario={formMarcaVeiculo} carregando={setCarregando} aoSalvar={salvandoDados} />
        </Drawer>
    )
}