import React,{ useState } from 'react';
import { Row, Col, Button, Modal, Form, Drawer } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import ManutencaoSubGrupo from './manutencao';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';

export default function DrawerSubGrupo({editando, openDrawerSubGp, setOpenDrawerSubGp, cadSubGp, retornoSalvar, gruId}) {

    const [ formSubGrupo ] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [ carregando, setCarregando ] = useState(false);
    
    if (manutencao.dados !== null ) {
        formSubGrupo.setFieldsValue(manutencao.dados);
    }
    else {
        formSubGrupo.setFieldsValue({sgb_ativo:true})
    }

    function salvandoDados(retorno) {
        if(!!retornoSalvar){
            retornoSalvar(retorno);
        }
        
        fecharDrawer();
    }
    function fecharDrawer() {
        formSubGrupo.resetFields();

        if(!!openDrawerSubGp){
            setOpenDrawerSubGp(false);
        }
        else {
            editando.setEditando(false);
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null}});
            dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false}});
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+sgp_nome' } });
        }
    };

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (

        <Drawer title="Cadastro Subgrupo de Produtos"
            width="60%"
            visible={!!cadSubGp ? ui.showDrawer : !!openDrawerSubGp ? openDrawerSubGp : false}
            closeIcon={<MinusOutlined />}
            onClose={() => onClose()}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formSubGrupo.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>                
            <ManutencaoSubGrupo formulario={formSubGrupo} carregando={setCarregando} aoSalvar={salvandoDados} editando={editando} gruId={gruId} openDrawer={openDrawerSubGp}/>
        </Drawer>   

    )
}