import React from "react";
import { Table, Tag } from "antd";
import moment from "moment";

export default function Log({ dadosItens }) {

    return (
        <div className="tabela mt-zero">
            <Table dataSource={dadosItens.listaDadosLog} columns={[
                {
                    title: 'Código',
                    width: 75,
                    align: 'center',
                    render: ({ pro_codigo }) => (
                        <Tag color="processing" className="w-100">
                            <b>{pro_codigo}</b>
                        </Tag>
                    ),
                },
                {
                    title: 'Produto',
                    render: ({ pro_descricao }) => (
                        <div>
                            <b>{pro_descricao}</b>
                        </div>
                    ),
                },
                {
                    title: 'Qtde',
                    align: 'center',
                    render: ({ iic_quantidade }) => (
                        <div>
                            <b>{iic_quantidade}</b>
                        </div>
                    ),
                },
                {
                    title: 'Data/Hora',
                    align: 'center',
                    render: ({ iic_datahora }) => (
                        <div>
                            <b>{moment(iic_datahora).format("DD/MM/YYYY HH:mm:ss")}</b>
                        </div>
                    ),
                },
                {
                    title: 'Usuário',
                    render: ({ usu_nome }) => (
                        <div>
                            <b>{usu_nome}</b>
                        </div>
                    ),
                },
                {
                    title: 'Grade',
                    render: ({ grade }) => (
                        <div>
                            <b>{grade}</b>
                        </div>
                    ),
                },
            ]} />
        </div>
    );

}