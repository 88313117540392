import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, Button, Collapse, Tag, Dropdown, Menu, Divider, Modal, notification } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, EditOutlined, MoreOutlined, PrinterOutlined, EyeOutlined, UploadOutlined, CloseOutlined, SyncOutlined, SearchOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import DrawerOrdemCompra from './drawer';
import { useStateValue } from '../../state';
import DrawerRecebimento from './drawerRecebimento';
import { imprimirRelatorioJasper } from "../../services/funcoes";
import { FormatNumber, FormatDate } from "../../ValueObjects";
import { ModalDetalhesOrdemCompra } from "../../components/modals";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaData, TabelaDados, Editar, Imprimir, Data, PesquisaOrdenacaoData } from "../../components";
import ComboData from "../../components/comboData";

export default function OrdemCompra() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [dataPesquisa, setDataPesquisa] = useState(new Date().toLocaleDateString());
    const [listaStatus, setListaStatus] = useState([]);
    const [listaFornecedor, setListaFornecedor] = useState([]);
    const [listaTransportador, setListaTransportador] = useState([]);
    const [open, setOpen] = useState(false);
    const [openModalDetalhes, setOpenModalDetalhes] = useState(false);
    const [dadosOrdemCompra, setDadosOrdemCompra] = useState({});
    const [form] = Form.useForm();
    const [formParam] = Form.useForm();

    const togglePesquisa = () => {
        setOpen(!open);
    };

    useEffect(() => {
        carregarDados();
    }, [dataPesquisa]);

    function carregarDados() {
        let sql = "";
        sql += !!form.getFieldValue().dataInicial ? `&dataInicial=${form.getFieldValue().dataInicial.format('YYYY-MM-DD')}` : "";
        sql += !!form.getFieldValue().dataFinal ? `&dataFinal=${form.getFieldValue().dataFinal.format('YYYY-MM-DD')}` : "";
        sql += !!form.getFieldValue().for_id ? `&IdFornecedor=${form.getFieldValue().for_id}` : '';
        sql += !!form.getFieldValue().trn_id ? `&IdTransportador=${form.getFieldValue().trn_id}` : '';
        sql += !!form.getFieldValue().status ? `&status=${form.getFieldValue().status.join(',')}` : '&status=0,1,2,3';
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-orc_datainclusao', outrosFiltros: sql } });

        api.get('Enum/Listar?nome=StatusOrdemCompra').then(
            (res) => {
                setListaStatus(res.data);
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
        api.get('Fornecedor/Listar?Order=+pes_nome').then(
            (res) => {
                setListaFornecedor(res.data.items);
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );

        api.get('Transportador/ListarTransportador?Order=+pes_nome').then(
            (res) => {
                setListaTransportador(res.data.items);
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
        if (!!form.getFieldValue().dataInicial) {
            form.setFieldsValue({ dataFinal: moment(new Date()), dataInicial: moment(form.getFieldValue().dataInicial) });
        } else {
            form.setFieldsValue({ dataFinal: moment(new Date()), dataInicial: moment(form.getFieldValue().dataPesquisa) });
        }
    };

    function aplicarFiltrosAvancados() {
        let dados = form.getFieldValue();
        let dataIni = dados.dataInicial;
        let dataFim = dados.dataFinal;
        let dataInicial = (!!dataIni ? moment(dataIni) : moment(dados.dataPesquisa));
        let dataFinal = (!!dataFim ? moment(dataFim) : moment(new Date));
        form.setFieldsValue({ dataFinal: dataFinal });
        form.setFieldsValue({ dataInicial: dataInicial });
        carregarDados();
    };

    function cancelarOrdem(orc_id) {
        Modal.confirm({
            title: 'Deseja Cancelar essa Ordem de Compra?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                api.delete(`OrdemCompra/CancelarOrdemCompra?IdOrdemCompra=${orc_id}`).then((res) => {
                    if (res.status === 200) {
                        notification.success({ message: 'Operação concluída', description: "Ordem de Compra cancelada com sucesso!" });
                        carregarDados();
                        alteraData();
                    }
                }, err => {
                    notification.error({ message: 'Aviso!', description: "Não foi possível cancelar a ordem de compra!" });
                });
            }
        });
    };

    function cancelarOrdemRecebida(orc_id) {
        Modal.confirm({
            title: 'Deseja Cancelar essa Ordem de Compra? (Essa Ordem de Compra já foi recebida!)',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                api.delete(`OrdemCompra/CancelarOrdemCompra?IdOrdemCompra=${orc_id}`).then((res) => {
                    if (res.status === 200) {
                        notification.success({ message: 'Operação concluída', description: "Ordem de Compra cancelada com sucesso!" });
                        carregarDados();
                        alteraData();
                    }
                }, err => {
                    notification.error({ message: 'Aviso!', description: "Não foi possível cancelar a ordem de compra!" });
                });
            }
        });
    };

    function abrirDetalhesOrdemCompra(dados) {
        setDadosOrdemCompra(dados);
        setOpenModalDetalhes(true);
    };

    function imprimirOrdemCompra(record) {
        imprimirRelatorioJasper(47, { IdOrdemCompra: record.orc_id });
    };

    function menuTable(dados) {

        return (
            <Menu className="text-right">
                {dados.orc_status === 0 &&
                    <Menu.Item key="1">
                        <Button type="text" icon={<CloseOutlined />} onClick={() => { cancelarOrdem(dados.orc_id) }}> Cancelar </Button>
                    </Menu.Item>
                }
                {(dados.orc_status === 0 || dados.orc_status === 1 || dados.orc_status === 4) &&
                    <Menu.Item key="2">
                        <Button type="text" onClick={() => {
                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ recebimento: dados } } } });
                            dispatch({ type: drawerActions.CHANGE, data: { showDrawerExtra: true } });
                        }} icon={<UploadOutlined />}>
                            Receber
                        </Button>
                    </Menu.Item>
                }
                <Menu.Item key="3">
                    <Imprimir type="text" icon={<PrinterOutlined />} onClick={() => imprimirOrdemCompra(dados)}> Imprimir </Imprimir>
                </Menu.Item>
                {
                    <Menu.Item key="4">
                        <Button type="text" icon={<EyeOutlined />} onClick={() => { abrirDetalhesOrdemCompra(dados) }}> Visualizar </Button>
                    </Menu.Item>
                }
                {(dados.orc_status === 1 || dados.orc_status === 2) &&
                    <Menu.Item key="5">
                        <Button type="text" icon={<SyncOutlined />} onClick={() => reabrirOrdemCompra(dados.orc_id)}> Reabrir </Button>
                    </Menu.Item>
                }
                {(dados.orc_status === 1 || dados.orc_status === 2) &&
                    <Menu.Item key="7">
                        <Button type="text" icon={<CloseOutlined />} onClick={() => cancelarOrdemRecebida(dados.orc_id)}> Cancelar </Button>
                    </Menu.Item>
                }
            </Menu>
        )
    }

    function reabrirOrdemCompra(orc_id) {
        Modal.confirm({
            title: 'Deseja Reabrir essa Ordem de Compra? (Essa Ordem de Compra já foi recebida!)',
            icon: <ExclamationCircleOutlined />,
            async onOk() {
                await api.put(`OrdemCompra/ReabrirOrdemCompraRecebida?IdOrdemCompra=${orc_id}`).then((res) => {
                    if (res.status === 200) {
                        notification.success({ message: 'Operação concluída', description: "Ordem de compra reaberta com sucesso!" });
                        carregarDados();
                        alteraData();
                    }
                }, err => {
                    notification.error({ message: 'Aviso!', description: "Não foi possível reabrir a ordem de compra!" });
                });
            }
        });
    };

    function retornaDescricaoStatus(orc_status) {
        if (!!listaStatus) {
            return listaStatus.filter((status) => (
                status.key === orc_status
            ))[0].value;
        }
    };

    function editarOrdemCompra(record) {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    }

    function alteraData() {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&dataFinal=${FormatDate(new Date().toLocaleDateString())}&dataInicial=${moment(form.getFieldValue().dataPesquisa).format('YYYY-MM-DD')}` } });
        form.setFieldsValue({ dataFinal: moment(new Date()) });
        form.setFieldsValue({ dataInicial: moment(form.getFieldValue().dataPesquisa) });
    }

    function limparFiltroPesquisa() {
        form.setFieldsValue({ for_id: null, trn_id: null, dataInicial: moment(form.getFieldValue().dataPesquisa), dataFinal: moment(new Date()) })
        dispatch({
            type: listagemActions.CHANGE, data: {  ordem: '+orc_datainclusao' }
        })

    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={18} xl={19} xxl={20}>
                    <PesquisaOrdenacaoData opcoesOrdenacao={[
                        { label: "Data Inclusão Crescente", value: "+orc_datainclusao" },
                        { label: "Data Inclusão Decrescente", value: "-orc_datainclusao" }

                    ]} form={form} eventoAoAlterar={alteraData} />
                </Col>
            </Row>
            <div>
                <Form layout="vertical" form={form}>
                    <div className="page">
                        <Row align="bottom" gutter={[0, 8]}>
                            <Col span={24}>
                                <Collapse className="p-relative topGrade" accordion ghost destroyInactivePanel={true}>
                                    <div className="colDet"></div>
                                    <Collapse.Panel key="1" showArrow={false} extra={
                                        <Button type="primary" onClick={togglePesquisa} block>
                                            {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                        </Button>
                                    }>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} sm={24} md={24} lg={16} xl={17} xxl={18}>
                                                <Form.Item label="Status" name="status" initialValue={[0, 1, 2, 3]}>
                                                    <Select mode="multiple" placeholder="Status Nota">
                                                        {listaStatus.map((statusNota) => (
                                                            <Select.Option key={statusNota.key} value={statusNota.key}>{statusNota.value}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12} md={8} lg={8} xl={7} xxl={6}>
                                                <Form.Item label="Fornecedor" name="for_id">
                                                    <Select showSearch placeholder="Selecione um Fornecedor" optionFilterProp="children" allowClear={true}>
                                                        {listaFornecedor.map((fornecedor) => (
                                                            <Select.Option value={fornecedor.for_id} key={fornecedor.for_id}>{fornecedor.pes_nome}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                                                <Form.Item label="Transportador" name="trn_id">
                                                    <Select showSearch placeholder="Selecione um Transportador" optionFilterProp="children" allowClear={true}>
                                                        {listaTransportador.map((transportador) => (
                                                            <Select.Option value={transportador.trn_id} key={transportador.trn_id}>{transportador.pes_nome}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={3}>
                                                <Data label="Data Inicial" name="dataInicial" />
                                            </Col>
                                            <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={3}>
                                                <Data label="Data Final" name="dataFinal" />
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={8} xl={10} xxl={6} className="t-mob t-5">
                                                <Row align="middle" justify="end" gutter={[8, 8]}>
                                                    <Col xs={24} sm={7} md={5} lg={12} xl={8} xxl={8}>
                                                        <Button icon={<CloseOutlined />} onClick={limparFiltroPesquisa} block>
                                                            Remover Filtros
                                                        </Button>
                                                    </Col>
                                                    <Col xs={24} sm={7} md={5} lg={12} xl={8} xxl={8}>
                                                        <Button type="primary" icon={<SearchOutlined />} onClick={aplicarFiltrosAvancados} block>
                                                            Aplicar Filtros
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="tabela">
                <TabelaDados url="OrdemCompra/Listar" colunas={[
                    {
                        title: '',
                        width: 135,
                        render: ({ orc_status, orc_id }) => (
                            <div>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <Tag color={((orc_status === 0) ? 'default' : (orc_status === 1 ? 'orange' : (orc_status === 2 ? 'green' : (orc_status === 3 ? 'red' : ''))))} className="w-100">
                                            <b> {retornaDescricaoStatus(orc_status)} </b>
                                        </Tag>
                                    </Col>
                                </Row>
                                <Row align="middle" justify="center">
                                    <Col>
                                        <b>
                                            {orc_id}
                                        </b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Descrição',
                        render: ({ nomeFornecedor, orc_nropedido, orc_atendente, orc_valortotal, orc_datainclusao, orc_prazomaximo, orc_dataconclusao, nomeTransportador, orc_valorfrete, orc_valordesconto }) => (
                            <div>
                                <Row align="middle">
                                    <Col>
                                        <b>{nomeFornecedor}</b>
                                    </Col>
                                </Row>
                                <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                    <Col span={6}>
                                        Nº Pedido: <b>{orc_nropedido || 'Não Informado'}</b>
                                    </Col>
                                    <Col span={9}>
                                        Atendente: <b>{orc_atendente || 'Não Informado'}</b>
                                    </Col>
                                    <Col span={9} align="right">
                                        Transportador: <b>{nomeTransportador || 'Não Informado'}</b>
                                    </Col>
                                </Row>
                                <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                    <Col span={6}>
                                        Lançamento: <b>{moment(orc_datainclusao).format('DD/MM/YYYY')}</b>
                                    </Col>
                                    <Col span={9}>
                                        Prazo: <b>{moment(orc_prazomaximo).format('DD/MM/YYYY')}</b>
                                    </Col>
                                    <Col span={9} align="right">
                                        Conclusão:
                                        <b> {!!orc_dataconclusao ? moment(orc_dataconclusao).format('DD/MM/YYYY') : 'Não'}</b>
                                    </Col>
                                </Row>
                                <Row className="m-t-5">
                                    <Col span={24}>
                                        <Divider className="m-0" />
                                    </Col>
                                </Row>
                                <Row align="middle" justify="space-between" gutter={[16, 0]}>
                                    <Col span={6}>
                                        Total itens (R$): <b className="f-18">{FormatNumber(orc_valortotal, true, false)}</b>
                                    </Col>
                                    <Col span={6}>
                                        Frete (R$): <b className="f-18">{FormatNumber(!!orc_valorfrete ? orc_valorfrete : 0, true, false)}</b>
                                    </Col>
                                    <Col span={6}>
                                        Desconto (R$): <b className="f-18">{FormatNumber(!!orc_valordesconto ? orc_valordesconto : 0, true, false)}</b>
                                    </Col>
                                    <Col span={6} align="right">
                                        Total (R$): <b className="f-18">{FormatNumber(orc_valortotal + orc_valorfrete - orc_valordesconto, true, false)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        align: 'center',
                        fixed: 'right',
                        key: 'x',
                        width: 65,
                        render: (record) => (
                            <div>

                                <Row align="top" justify="center" gutter={[5, 0]}>
                                    {(record.orc_status === 0) && <Col>
                                        <Editar onClick={() => {
                                            editarOrdemCompra(record);
                                        }} icon={<EditOutlined />} />
                                    </Col>
                                    }
                                    <Col>
                                        <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <ModalDetalhesOrdemCompra exibirModal={openModalDetalhes} fecharModal={() => { setOpenModalDetalhes(false) }} dadosOrdemCompra={dadosOrdemCompra} />
            <DrawerOrdemCompra carregarDados={carregarDados} alteraData={alteraData} fornecedor={listaFornecedor} transportador={listaTransportador} />
            <DrawerRecebimento carregarDados={carregarDados} alteraData={alteraData} fornecedor={listaFornecedor} transportador={listaTransportador} />
        </div>
    );
}