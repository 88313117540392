import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

export default function ModalExibeImagem({ dadosImagem, exibirModal, onCloseModal }) {
    return (<Modal centered
        title={`${dadosImagem.codPeca} - ${dadosImagem.descPeca}`}
        visible={exibirModal}
        onCancel={onCloseModal}
        className='exibeImg'
        onOk={onCloseModal}
        cancelText={
            <>
                <CloseOutlined /> Cancelar
            </>
        }
        maskClosable={false}
    >
        <div >
            <TransformWrapper>
                <TransformComponent contentClass="imageZoom">
                    <img src={dadosImagem.thumbUrl}  alt="imagem" />
                </TransformComponent>
            </TransformWrapper>
        </div>
    </Modal>);
}