import React, { useState, useEffect } from "react";
import { Tabs, Row, Col, Form, Input, InputNumber, Select, Checkbox, Switch, Tooltip, Button, Modal, notification, Alert } from "antd";
import { QuestionCircleOutlined, BarcodeOutlined, ExclamationCircleOutlined, RestFilled } from "@ant-design/icons";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { manutencaoActions, selectPaginadoActions, drawerActions } from "../../actions";
import { calculaDigitoVerificadorEAN, isNotNullOrEmpty, validarCnpj, validarEan, ajustarTamanhoImagemBase64, resizeImageToMB } from "../../services/funcoes";
import { TabListaPreco, TabProdutoTributacao, TabGrade, TabImagem, TabLote } from './tabPage';
import { FormGW, FormularioDinamico, InputPreco, SelectIncluir, SelectPaginacao, Data } from "../../components";
import { trataRetornoFormularioDinamico, trataSetaDadosFormularioDinamico } from "../../services/funcoes";
import moment from "moment";
import DrawerMarca from "../../pages/marca/drawer";
import DrawerGrupoProduto from "../../pages/grupoProdutos/drawer";
import DrawerSubGrupo from "../../pages/subGrupo/drawer";

export default function ManutencaoProdutos({ formulario, nameForm, salvandoDados, aoSalvar, validarAoIniciar, editando }) {
    const [{ ui, manutencao, parametrosEmpresa }, dispatch] = useStateValue();
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [formGrade] = Form.useForm();
    const [listaDePrecos, setListaDePrecos] = useState([]);
    const [listaDeGrades, setListaDeGrades] = useState([]);
    const [listaImagens, setListaImagens] = useState([]);
    const [listaLote, setListaLote] = useState([]);
    const [filtroGrupo, setFiltroGrupo] = useState(null);
    const [listaPrecoExclusao, setListaPrecoExclusao] = useState([]);
    const [produtoCamposPersonalizados, setProdutoCamposPersonalizados] = useState([]);
    const [listaUnidadeMedida, setListaUnidadeMedida] = useState([]);
    const [elementosFormulario, setElementosFormulario] = useState([]);
    const [possuiCusto, setPossuiCusto] = useState(false);
    const [openDrawerMarca, setOpenDrawerMarca] = useState(false);
    const [openDrawerGrupo, setOpenDrawerGrupo] = useState(false);
    const [openDrawerSubGp, setOpenDrawerSubGp] = useState(false);
    const [grupoSelect, setGrupoSelect] = useState([]);
    const [foundGroup, setFoundGroup] = useState([]);
    const [casaDecimaisUnd, setCasaDecimaisUnd] = useState(0);
    const [exibeLote, setExibeLote] = useState(false);

    const trocaAbas = (pag) => {
        setAbaSelecionada(pag);
    };

    const listaValidacoes = [
        { nome: 'pro_descricao', obrigatorio: true, label: 'Descrição' },
        { nome: 'tpi_id', obrigatorio: true, label: 'Tipo de Produto - SPED' },
        { nome: 'ump_id', obrigatorio: true, label: 'Unidade de Medida' },
        { nome: 'orm_id', obrigatorio: true, label: 'Código Situação Tributária Origem' },
        { nome: 'ncm_id', obrigatorio: true, label: 'NCM - Nomenclatura Comum do Mercosul' },
        { nome: 'icm_id', obrigatorio: true, label: 'Classificação ICMS' }
    ];

    useEffect(() => { if (!!elementosFormulario && elementosFormulario.length) carregaDadosCamposAdicionais(); }, [elementosFormulario]);

    useEffect(() => {
        if (!!manutencao.dados && !!editando.editando) {
            let lista = [];
            if (!!manutencao.dados?.mar_id) {
                lista.push({ name: "mar_id", campo: "Codigo", value: manutencao.dados.mar_id });
            }
            if (!!manutencao.dados?.gru_id) {
                lista.push({ name: "gru_id", campo: "CodigoGrupo", value: manutencao.dados.gru_id });
            }
            if (!!manutencao.dados?.sgp_id) {
                lista.push({ name: "sgp_id", campo: "Codigo", value: manutencao.dados.sgp_id });
            }
            if (!!manutencao.dados?.ncm_id) {
                lista.push({ name: "ncm_id", campo: "IdNcm", value: manutencao.dados.ncm_id });
            }
            if (!!manutencao.dados?.icm_id) {
                lista.push({ name: "icm_id", campo: "Codigo", value: manutencao.dados.icm_id });
            }
            if (!!manutencao.dados?.pis_id) {
                lista.push({ name: "pis_id", campo: "Id", value: manutencao.dados.pis_id });
            }
            if (!!manutencao.dados?.anp_id) {
                lista.push({ name: "anp_id", campo: "IdAnp", value: manutencao.dados.anp_id });
            }
            if (lista.length > 0) {
                editando.setEditando(false);
                dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            }
            formulario.setFieldsValue(manutencao.dados);
        }
    }, [manutencao.dados]);

    useEffect(() => {
        if (!!ui.showDrawer && !!validarAoIniciar && !!formulario.getFieldValue().pro_ean) {
            buscarEanExistente(formulario.getFieldValue().pro_ean);
        }
    }, [ui.showDrawer])

    useEffect(() => {
        if (listaUnidadeMedida.length > 0 && !formulario.getFieldValue().ump_id) {
            formulario.setFieldsValue({ ump_id: listaUnidadeMedida.find(un => un.ump_id === 'UN').ump_id });
        }
        if (listaUnidadeMedida.length > 0) {
            checkCasaDecimalUnidadeMedida();
        }
    }, [listaUnidadeMedida]);

    useEffect(() => {
        carregarDados();
        carregarListas();
        carregarParametros();
    }, []);

    useEffect(() => {
        const found = grupoSelect.find(element => element.gru_id === formulario.getFieldValue(['gru_id']));

        if (!!found) {
            setFoundGroup(found);
            carregarSubGrupos(found.gru_id);
        }
    }, [formulario.getFieldValue(['gru_id'])]);

    async function carregarParametros() {
        let markupLoc = await api.get(`/ParametroLocal/BuscarParametros`);
        if (!!markupLoc.data.par_markupproduto) {
            formulario.setFieldsValue({ pro_markup: markupLoc.data.par_markupproduto });
        } else {
            let markupEmp = await api.get(`/ParametroEmpresa/BuscarParametros`);
            if (!!markupEmp.data.par_markupproduto) {
                formulario.setFieldsValue({ pro_markup: markupEmp.data.par_markupproduto });
            }
        };
    }

    async function carregarListas() {
        let unidade = await api.get(`unidademedida/listar?PageSize=100&PageNumber=1`);
        if (!!formulario.getFieldValue().ump_id && unidade.data?.items.length > 0 && !unidade.data?.items?.find((un) => { return un.ump_id === formulario.getFieldValue().ump_id })) {
            formulario.setFieldsValue({ ump_id: null });
        }
        setListaUnidadeMedida(unidade.data?.items);

        carregaCamposPersonalizados();
    }
    function checkCasaDecimalUnidadeMedida() {
        if (!!formulario.getFieldValue().emb_ump_id) {
            let und = listaUnidadeMedida.find(un => un.ump_id === formulario.getFieldValue().emb_ump_id);
            if (und) {
                setCasaDecimaisUnd(und.ump_casasdecimais);
            }
            else {
                setCasaDecimaisUnd(0);
            }
        } else {
            setCasaDecimaisUnd(0);
        }
    }

    function carregarSubGrupos(CodigoGrupo) {
        if (!!CodigoGrupo) {
            setFiltroGrupo(`&CodigoGrupo=${CodigoGrupo}`);
            let lista = [];
            if (!!formulario.getFieldValue().gru_id) {
                lista.push({ name: "sgp_id", campo: "CodigoGrupo", value: formulario.getFieldValue().gru_id });
            }
            dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }
    }
    function validarForm(values, listaValidacoes) {
        let retorno = false;
        if (!!listaValidacoes) {
            let msgRetorno = [];
            listaValidacoes.forEach(
                (validacao) => {
                    if (validacao.obrigatorio && (!!values[validacao.nome] === false && values[validacao.nome] !== 0)) {
                        msgRetorno.push('O campo ' + validacao.label + ' é obrigatório!');
                    }
                }
            );
            if (listaDeGrades.length > 0) {
                let gradeValida = true;
                let validaValorVenda = true;
                listaDeGrades.forEach((grade) => {
                    if (!!!formGrade.getFieldValue(['prg_ean' + grade.key])) {
                        gradeValida = false;
                    }
                    // if (grade.lpi_valorvenda == 0 || grade.lpi_valorvenda == null && grade.prg_valorvenda != null && grade.prg_valorvenda != 0) {
                    //     grade.lpi_valorvenda = grade.prg_valorvenda;
                    // }
                    if (grade.prg_valorvenda == null || grade.prg_valorvenda == 0) {
                        validaValorVenda = false;
                    }
                });
                if (!gradeValida) {
                    msgRetorno.push("Favor informar o EAN para a grade do produto!");
                }
                if (!validaValorVenda) {
                    msgRetorno.push("Valor de Venda deve ser diferente de Zero!");
                }
            }
            if (values.anp_codigo === 210203001) {
                let val = (parseFloat(values.prc_percglp) + parseFloat(values.prc_percglpnacional) + parseFloat(values.prc_percglpimportado));
                if ((parseFloat(values.prc_percglp) + parseFloat(values.prc_percglpnacional) + parseFloat(values.prc_percglpimportado)) !== 100)
                    msgRetorno.push("Somatorio percentuais de GLP derivado do petroleo, GLGNn e GLGNi diferente de 100!");
            }
            if (values.emb_ump_id && (values.pru_quantidade == null || values.pru_quantidade === 0)) {
                msgRetorno.push("Quantidade da embalagem deve ser informado quando existir unidade de medida(Embalagem)");
            }
            if (values.ncm_ativo == false) {
                msgRetorno.push("NCM está inativo na base de dados do SEFAZ! Certifique-se de que o NCM que você está usando está correto na aba Dados Tributários");
            }

            if (msgRetorno.length > 0) {
                notification.warning({ message: 'Aviso', description: msgRetorno.join('\n') })
            } else {
                retorno = true;
            }

        }
        return retorno;
    };

    function salvarDadosCombustivel(dadosProd) {
        let dadosComb = {};
        if (dadosProd.anp_id && dadosProd.anp_codigo === 210203001) {// Gas
            dadosComb.prc_percgasnatural = dadosProd.prc_percgasnatural;
            dadosComb.prc_percglp = dadosProd.prc_percglp;
            dadosComb.prc_percglpnacional = dadosProd.prc_percglpnacional;
            dadosComb.prc_percglpimportado = dadosProd.prc_percglpimportado;
            dadosComb.prc_valorpartida = dadosProd.prc_valorpartida;
            dadosComb.prc_qtdetemperatura = dadosProd.prc_qtdetemperatura;
            return dadosComb;
        }
        return null;
    };
    function salvarProdutosUnidadeMedida(dadosProd) {
        let dadosUnidadeMedida = [];
        if (dadosProd.emb_ump_id) {
            dadosUnidadeMedida.push({ pru_id: dadosProd.pru_id, ump_id: dadosProd.emb_ump_id, pru_quantidade: dadosProd.pru_quantidade });
            return dadosUnidadeMedida;
        }
        return null;
    };


    async function salvarDados(values) {
        let dadosProd = {};
        dadosProd = { ...values, ...formulario.getFieldValue() };
        dadosProd.orm_id = (dadosProd.orm_id).toString();
        dadosProd.pro_kitcomposicao = !!dadosProd.pro_kitcomposicao ? dadosProd.pro_kitcomposicao : false;
        dadosProd.lpi_valorvenda = parseFloat(dadosProd.lpi_valorvenda);
        dadosProd.listaPreco = listaDePrecos.filter(l => l.ltp_principal == false);
        dadosProd.produtocampopersonalizados = salvarCamposAdicionais(formulario.getFieldValue());
        dadosProd.pre_qtdeestoqueinicial = parseFloat(dadosProd.pre_qtde)
        dadosProd.pro_markup = parseFloat(dadosProd.pro_markup)
        dadosProd.produtoGrade = popularGrade();
        dadosProd.ProdutoCombustivel = salvarDadosCombustivel(dadosProd);
        dadosProd.ProdutoUnidades = salvarProdutosUnidadeMedida(dadosProd);
        dadosProd.imagens = await popularImagens();
        dadosProd.produtolotes = listaLote.map(item => {
            const { ordem, ...restante } = item;
            return restante;
        });
        if (validarForm(dadosProd, listaValidacoes)) {
            salvandoDados(true);
            if (dadosProd.pro_id) {
                editarManutencao(dadosProd);
            } else {
                incluirManutencao(dadosProd);
            }
        }
    };

    async function editarManutencao(dadosProd) {
        let retornoDrawer = [];
        api.put(`Produto/Editar`, dadosProd).then(
            res => {
                retornoDrawer = res.data;
                notification.success({ message: 'Operação concluída', description: "Os dados do Produto foram salvos com sucesso!" });
                aoSalvar(retornoDrawer);
            }).catch(
                error => {
                    console.log(error);
                }).finally(
                    () => {
                        salvandoDados(false);
                    }
                );
    }

    async function incluirManutencao(dadosProd) {
        let retornoDrawer = [];
        api.post(`Produto/Incluir`, dadosProd).then(
            res => {
                retornoDrawer = res.data;
                notification.success({ message: 'Operação concluída', description: "Os dados do Produto foram salvos com sucesso!" });
                aoSalvar(retornoDrawer);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                salvandoDados(false);
            }
        );
    }

    function salvandoDadosMarca(retorno) {
        let lista = [];

        if (retorno) {
            lista.push({ name: "mar_id", campo: "Codigo", value: retorno.mar_id });
            dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }
    }

    function salvandoDadosGrupo(retorno) {
        let lista = [];

        if (retorno) {
            lista.push({ name: "gru_id", campo: "CodigoGrupo", value: retorno });
            dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }
    }

    function salvandoDadosSubGrupo(retorno) {
        let lista = [];

        if (retorno) {
            lista.push({ name: "sgp_id", campo: "Codigo", value: retorno.sgp_id });
            dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }

    }

    function popularGrade() {
        let dadosGrade = [...listaDeGrades];
        dadosGrade.forEach(
            (grade) => {
                grade.prg_ean = formGrade.getFieldValue(['prg_ean' + grade.key]);
                grade.prg_codigoetiqueta = formGrade.getFieldValue(['prg_codigoetiqueta' + grade.key]);
                grade.pre_custoultimacompra = !!parseFloat(formGrade.getFieldValue(['pre_custoultimacompra' + grade.key])) ? parseFloat(formGrade.getFieldValue(['pre_custoultimacompra' + grade.key])) : 0;
                grade.prg_markup = !!parseFloat(formGrade.getFieldValue(['prg_markup' + grade.key])) ? parseFloat(formGrade.getFieldValue(['prg_markup' + grade.key])) : 0;
                grade.prg_valorvenda = !!parseFloat(formGrade.getFieldValue(['lpi_valorvenda' + grade.key])) ? parseFloat(formGrade.getFieldValue(['lpi_valorvenda' + grade.key])) : 0;
                grade.pre_qtde = !!parseFloat(formGrade.getFieldValue(['pre_qtde' + grade.key])) ? parseFloat(formGrade.getFieldValue(['pre_qtde' + grade.key])) : 0;
                grade.prg_ativa = grade.prg_ativa;
                grade.imagens = popularImgGrade(grade.imagens);
                grade.modalidadeGrade = popularModalidades(grade.modalidadesgrade);
            }
        );
        return dadosGrade;
    };

    function popularImgGrade(imagens) {
        let imagensGrade = [];
        imagens.forEach(
            imgs => {
                let imagem = {};
                imagem.base64img = !!imgs.url ? imgs.url : imgs.thumbUrl;
                imagem.principal = imgs.principal;
                imagem.imagemComprimida = false;
                imagensGrade.push(imagem);
            }
        );
        return imagensGrade;
    };

    function popularModalidades(modalidadesgrade) {
        let modalidades = [];
        modalidadesgrade.forEach(
            atrib => {
                let mod = {
                    mgp_id: atrib.mgp_id
                }
                modalidades.push(mod);
            }
        )
        return modalidades;
    };

    async function popularImagens() {
        let listaImg = [];
        let possuiPrincipal = false;
        let maxSize = 2 * 1024 * 1024;
        for (var img of listaImagens) {
            let imagem = {};
            if (img.size != null) {
                if ((img.size + 1) >= maxSize) {
                    // 1200 equivale a resolução da imagem
                    //imagem.base64img = await ajustarTamanhoImagemBase64((!!img.url ? img.url : img.thumbUrl), 1200, 1200, maxSize);
                    //parametros para diminuir tamanho da imagem( imagem, tamanho em MB, dimensões)
                    imagem.base64img = await resizeImageToMB((!!img.url ? img.url : img.thumbUrl), 2, 1200, 1200);
                    imagem.imagemComprimida = false;
                    //console.log('base64img:', imagem.base64img);
                    imagem.principal = false;
                } else {
                    imagem.base64img = !!img.url ? img.url : img.thumbUrl;
                    imagem.imagemComprimida = false;
                    imagem.principal = false;
                }
            } else {
                imagem.base64img = !!img.url ? img.url : img.thumbUrl;
                imagem.imagemComprimida = false;
                imagem.principal = false;
            }
            if (!!img.principal) {
                possuiPrincipal = true;
                imagem.principal = true;
            }
            listaImg.push(imagem);
        };
        if (!possuiPrincipal && listaImg.length > 0) {
            listaImg[0].principal = true;
        }
        return listaImg;
    };

    const salvarCamposAdicionais = (values) => {
        var camposAdicionais = [];
        elementosFormulario.map((elemento) => {
            values = trataRetornoFormularioDinamico(values, elemento);
            let proDados = produtoCamposPersonalizados.filter(f => f.cap_id == elemento.cap_id)[0];
            if (!!proDados && !!proDados.pcp_valor && !!proDados.pcp_id) {
                camposAdicionais.push({ pcp_id: proDados.pcp_id, pro_id: values.pro_id, cap_id: elemento.cap_id, pcp_valor: values[elemento.cap_nomeelemento] });
            } else if (!!values[elemento.cap_nomeelemento]) {
                if (!!Date.parse(values[elemento.cap_nomeelemento]) && typeof values[elemento.cap_nomeelemento] === "object") {
                    camposAdicionais.push({ pcp_id: null, pro_id: values.pro_id, cap_id: elemento.cap_id, pcp_valor: moment(values[elemento.cap_nomeelemento]).format("YYYY/MM/DD") });
                } else {
                    camposAdicionais.push({ pcp_id: null, pro_id: values.pro_id, cap_id: elemento.cap_id, pcp_valor: values[elemento.cap_nomeelemento] });
                }
            }
        });
        return camposAdicionais;
    }

    function carregaCamposPersonalizados() {
        api.get(`CamposPersonalizados/ListarTodos?TipoEntidade=4&PageSize=500&Order=+cap_ordem`).then(res => {
            if (res.status === 200) {
                setElementosFormulario(res.data.items);
            }
        }).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function carregaDadosCamposAdicionais() { //Campos Adicionais
        if (!!formulario.getFieldValue().pro_id) {
            api.get(`ProdutoCampoPersonalizado/Listar?IdProduto=${formulario.getFieldValue().pro_id}`).then(res => {
                if (res.status === 200 && !!res.data) {
                    setProdutoCamposPersonalizados(res.data);
                    res.data.map(d => {
                        let elemento = elementosFormulario.filter(f => f.cap_id === d.cap_id)[0];
                        trataSetaDadosFormularioDinamico(formulario, elemento, d.pcp_valor);
                    })
                }
            }).catch(
                (erro) => {
                    console.log(erro);
                }
            );
        }
    };

    async function carregarDados() {
        if (!!formulario.getFieldsValue().pro_id) {
            if (!!formulario.getFieldValue().pro_controlelote) {
                setExibeLote(true);
            }

            let listaPreco = await api.get(`ListaPreco/ListarListaPrecoProduto?idProduto=${formulario.getFieldsValue().pro_id}`);
            setListaDePrecos(listaPreco.data.map((x, idx) => ({ key: idx, ...x })));

            let listaGrades = await api.get(`produto/ListarGrades?IdProduto=${formulario.getFieldsValue().pro_id}`);
            if (listaGrades.status === 200) {
                let lista = listaGrades.data;
                if (lista.length > 0) {
                    lista.forEach((item, indice) => {
                        item.key = indice + 1;
                        formGrade.setFieldsValue({
                            ['prg_ean' + item.key]: item.prg_ean,
                            ['pre_custoultimacompra' + item.key]: (!!item.pre_custoultimacompra ? item.pre_custoultimacompra.toFixed(2) : null),
                            ['prg_markup' + item.key]: (!!item.prg_markup ? item.prg_markup.toFixed(2) : null),
                            ['lpi_valorvenda' + item.key]: (!!item.lpi_valorvenda ? item.lpi_valorvenda.toFixed(2) : null),
                            ['prg_codigoetiqueta' + item.key]: item.prg_codigoetiqueta
                        });
                    });
                }
                setListaDeGrades(lista);
            }

            let listaImagens = await api.get(`produto/ListarImagens?IdProduto=${formulario.getFieldsValue().pro_id}`);
            if (listaImagens.status === 200) {
                let lista = [];
                if (listaImagens.data.length > 0) {
                    listaImagens.data.forEach((imagem, index) => {
                        lista.push({ url: imagem.conteudoImagem, uid: index + 1, principal: imagem.principal });
                    });
                    setListaImagens(lista);
                }
            }

            let custoProduto = await api.get(`estoque/BuscarCustosProdutos?codigoProduto=${formulario.getFieldsValue().pro_id}`);
            formulario.setFieldsValue({ pre_custoultimacompra: custoProduto.data.pre_custoultimacompra });
            setPossuiCusto(!!custoProduto.data.pre_custoultimacompra);

            let listaDoLote = await api.get(`ProdutoLote/Listar?IdProduto=${formulario.getFieldValue().pro_id}`);
            setListaLote(listaDoLote.data.items);

        } else {
            setListaDePrecos([]);
            setListaImagens([]);
            setListaDeGrades([]);
        }

        recalcularMarkupDoProduto();
        carregaCamposPersonalizados();
    };

    function buscarEanExistente(event) {
        let codEan;
        if (!!event.currentTarget) {
            codEan = event.currentTarget.value;
        } else {
            codEan = event;
        }
        if (isNaN(codEan)) {
            return;
        }
        api.get(`Produto/VerificaEanExistente?CodigoEan=${codEan}`).then(
            res => {
                if (!!res.data && res.data.existe == true) {
                    Modal.confirm({
                        title: 'Aviso',
                        icon: <ExclamationCircleOutlined />,
                        content: 'Produto já cadastrado - deseja alterá-lo?',
                        okText: 'Sim',
                        cancelText: 'Não',
                        centered: true,
                        onOk() {
                            buscarProdutosEan(res.data.pro_id);

                        }, onCancel() {
                            //aoSalvar();
                            formulario.resetFields();
                        }
                    });
                }
            }
        ).catch(
            erro => {
                console.log(erro)
            }
        );
    };

    function buscarProdutosEan(IdProduto) {
        api.get(`produto/RetornaDadosProduto?ProdutoId=${IdProduto}`).then(
            res => {
                res.data.pro_datainclusao = !!res.data.pro_datainclusao ? moment(res.data.pro_datainclusao) : null;
                res.data.data_sincro = !!res.data.data_sincro ? moment(res.data.data_sincro) : null;
                formulario.setFieldsValue(res.data);
                editando.setEditando(true);
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: res.data } });
                carregarDados();
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };
    function gerarEANProdutoBalanca(e) {
        if (e.target.checked == true) {
            api.get(`produto/GerarCodigoProdutoBalanca`).then(
                res => {
                    if (res.status == 200) {
                        console.log(res.data)
                        formulario.setFieldsValue({ pro_ean: res.data.toString(), pro_eaninvalido: true });
                    }
                    else {
                        notification.warning({ message: `Aviso!`, description: res.data })
                    }
                }
            ).catch(
                erro => {
                    formulario.setFieldsValue({ pro_ean: "" });
                    console.log(erro);
                    if (!!erro.response && !!erro.response.data) {
                        notification.warning({ message: `Aviso!`, description: erro.response.data })
                    } else {
                        notification.warning({ message: `Aviso!`, description: "Não foi possível gerar código para balança" })
                    }
                }
            );
        } else {
            formulario.setFieldsValue({ pro_ean: "" });
        }
    }

    var eanAnterior = null;
    function gerarEANProduto(campo) {
        let primeiroNumero = "6";
        let EAN;
        let digitoVerificador;
        eanAnterior = primeiroNumero;
        for (let i = 0; i <= 10; i++) {
            eanAnterior += Math.floor((Math.random() * 9) + 1).toString();
        }
        EAN = eanAnterior;
        EAN = EAN.substr(0, 12);
        EAN = (parseInt(EAN) + 1).toString();
        eanAnterior = EAN;
        digitoVerificador = calculaDigitoVerificadorEAN(EAN);
        EAN = EAN + "" + digitoVerificador;

        if (!!campo) {
            formGrade.setFieldsValue({ [campo]: EAN });
        } else {
            formulario.setFieldsValue({ pro_ean: EAN });
        }
        formulario.setFieldsValue({ pro_eaninvalido: true });
    };

    function validaTipoMercadoria(campo) {
        if (campo === "pro_produto" && formulario.getFieldsValue().pro_produto) {
            formulario.setFieldsValue({ pro_servico: false });
            formulario.setFieldsValue({ pro_materiaprima: false });
        } else if (campo === 'pro_servico' && formulario.getFieldsValue().pro_servico) {
            formulario.setFieldsValue({ pro_produto: false });
            formulario.setFieldsValue({ pro_materiaprima: false });
        } else if (campo === 'pro_materiaprima' && formulario.getFieldsValue().pro_materiaprima) {
            formulario.setFieldsValue({ pro_produto: false });
            formulario.setFieldsValue({ pro_servico: false });
        } else {
            formulario.setFieldsValue({ pro_produto: true });
        }
    };

    function calcularValorVendaPorMarkup() {
        let valorVenda = retornaPrecoPorMarkup(formulario.getFieldsValue().pre_custoultimacompra, formulario.getFieldsValue().pro_markup);
        formulario.setFieldsValue({ lpi_valorvenda: valorVenda });
    };

    function retornaPrecoPorMarkup(custo, markup) {
        if (parseFloat(custo) > 0 && parseFloat(markup) > 0) {
            return (parseFloat(custo) * parseFloat(markup)).toFixed(2);
        } else {
            return null;
        }
    };

    function recalcularMarkupDoProduto() {
        if (parseFloat(formulario.getFieldsValue().pre_custoultimacompra) > 0 && parseFloat(formulario.getFieldsValue().lpi_valorvenda) > 0) {
            let markupCalculado = (parseFloat(formulario.getFieldsValue().lpi_valorvenda) / parseFloat(formulario.getFieldsValue().pre_custoultimacompra)).toFixed(2);
            formulario.setFieldsValue({ pro_markup: markupCalculado })
        } else {
            formulario.setFieldsValue({ pro_markup: parametrosEmpresa.markupProduto });
        }
    };

    function verificaCnpj(record) {
        record = record.currentTarget.value.replace(/\D/g, '');
        if (!validarCnpj(record)) {
            notification.warning({ message: `Aviso!`, description: `O CNPJ informado é inválido!` })
        }
    }

    function onBlurProEan(event) {
        let codEan = "";
        if (!!event.currentTarget) {
            codEan = event.currentTarget.value;
        } else {
            codEan = event;
        }
        if (codEan !== "") {
            if (validarEan(codEan)) {
                buscarEanExistente(codEan);
                if (codEan.startsWith("7") && codEan.length === 13)
                    formulario.setFieldsValue({ pro_eaninvalido: false });
                else
                    formulario.setFieldsValue({ pro_eaninvalido: true });

            } else {
                formulario.setFieldsValue({ pro_eaninvalido: true });
                if (formulario.getFieldsValue().pro_usabalanca == false) {
                    notification.warning({ description: 'EAN informado fora dos padrões GTIN, verifique o EAN antes de continuar!', message: 'Aviso' });
                }
            }
        }
    };

    function exibirLote() {
        setExibeLote(!exibeLote);
    };

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name={nameForm} onFinish={(values) => salvarDados(values)} >
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                        <Form.Item hidden label="pro_id" name="pro_id">
                            <Input placeholder="pro_id" />
                        </Form.Item>
                        <Form.Item label="Descrição" name="pro_descricao">
                            <Input placeholder="Informe a Descrição do Produto/Mercadoria" maxLength={120} />
                        </Form.Item>
                    </Col>
                    {!!manutencao.dados?.pro_id && <Col xs={24} sm={12} md={3} lg={3} xl={3}>
                        <Form.Item label="Código" name="pro_codigo">
                            <Input placeholder="Código Produto" maxLength={50} disabled />
                        </Form.Item>
                    </Col>}
                    <Col xs={24} sm={16} md={7} lg={5} xl={5}>
                        <Form.Item name="pro_ean" label={
                            <div>
                                Código de Barras - EAN
                                <Tooltip title="Campo destinado a informação do EAN. Verifique a necessidade dos produtos ou mercadorias da empresa, se houver dúvidas em relação a escolha/informação do código, consulte o responsável fiscal de sua empresa. Esta informação é OPCIONAL.">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                        }>
                            <Input placeholder="Informe o Código de Barras (OPCIONAL)" onBlur={(event) => onBlurProEan(event)} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={4} lg={3} xl={3} className="tt-5">
                        <Button block icon={<BarcodeOutlined />} onClick={() => gerarEANProduto()} className="t-mob-573">Gerar EAN</Button>
                    </Col>
                    <Col span={24} className="m-t-16">
                        <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => trocaAbas(tab)}>
                            <Tabs.TabPane tab="Dados Gerais" key="1">
                                <Row gutter={[8, 0]}>
                                    <Col>
                                        <Form.Item name="pro_produto" valuePropName="checked" initialValue={true}>
                                            <Checkbox onChange={() => validaTipoMercadoria('pro_produto')}> Produto/Mercadoria? </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    {/* <Col>
                                        <Form.Item name="pro_servico" valuePropName="checked">
                                            <Checkbox onChange={() => validaTipoMercadoria('pro_servico')}> Serviço? </Checkbox>
                                        </Form.Item>
                                    </Col> */}
                                    {/* <Col>
                                        <Form.Item name="pro_materiaprima" valuePropName="checked">
                                            <Checkbox onChange={() => validaTipoMercadoria('pro_materiaprima')}> Matéria-Prima? </Checkbox>
                                        </Form.Item>
                                    </Col> */}
                                    <Col>
                                        <Form.Item name="pro_kitcomposicao" valuePropName="checked">
                                            <Checkbox disabled> Kit Composição? </Checkbox >
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={12} md={8} lg={7} xl={7}>
                                        <Form.Item label="Modelo" name="pro_modelo" rules={[{ required: false, message: 'Informe a Descrição do Modelo' }]}>
                                            <Input placeholder="Informe a Descrição do Modelo" maxLength={50} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={5} xl={5}>
                                        <Form.Item name="pro_referencia" rules={[{ required: false, message: 'Informe o Código da Referência do Produto' }]} label={
                                            <div>
                                                Código de Referência
                                                <Tooltip title="Informe aqui o código do produto atribuído pelo fabricante. Esta informação é OPCIONAL.">
                                                    <QuestionCircleOutlined />
                                                </Tooltip>
                                            </div>
                                        }>
                                            <Input maxLength={50} placeholder="Informe o Código da Referência do Produto" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                        <Form.Item name="pro_codigoetiqueta" rules={[{ required: false, message: 'Informe o Código da Etiqueta do Produto' }]} label={
                                            <div>
                                                Código da Etiqueta
                                                <Tooltip title="Informe aqui o código na etiqueta do produto, caso utilize impressão de etiquetas. Esta informação é OPCIONAL.">
                                                    <QuestionCircleOutlined />
                                                </Tooltip>
                                            </div>
                                        }>
                                            <Input maxLength={20} placeholder="Informe o Código da Etiqueta do Produto" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={3} lg={3} xl={3} xxl={3}>
                                        <Data label="Data de Cadastro" name="pro_datainclusao" disabled />
                                    </Col>
                                    <Col xs={24} sm={8} md={3} lg={3} xl={3} xxl={3}>
                                        <Data label="Data de Alteração" name="data_sincro" disabled />
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                        <Form.Item label="Marca" name="mar_id">
                                            <SelectPaginacao url="marca/Listar?Ativa=true"
                                                allowClear={true}
                                                selecionarRegUnico={"mar_id"}
                                                nameLabel="mar_nome"
                                                nameValue="mar_id"
                                                placeholder="Selecione a Marca"
                                                form={formulario}
                                                funcaoIncluir={() => setOpenDrawerMarca(true)}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                                        <Form.Item label="Grupo" name="gru_id">
                                            <SelectPaginacao url="grupoproduto/Listar?ApenasAtivos=true"
                                                selecionarRegUnico={"gru_id"}
                                                nameLabel="gru_nome"
                                                nameValue="gru_id"
                                                form={formulario}
                                                placeholder="Selecione o grupo de produto"
                                                onClearFunction={(IdGrupo) => carregarSubGrupos(IdGrupo)}
                                                onChangeFunction={(IdGrupo) => carregarSubGrupos(IdGrupo)}
                                                setListaDados={setGrupoSelect}
                                                funcaoIncluir={() => setOpenDrawerGrupo(true)}
                                                allowClear={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                                        <Form.Item label="Subgrupo" name="sgp_id">
                                            <SelectPaginacao url="subgrupo/Listar"
                                                placeholder="Selecione o Subgrupo do Produto"
                                                selecionarRegUnico={"sgp_id"}
                                                nameLabel="sgp_nome"
                                                nameValue="sgp_id"
                                                form={formulario}
                                                filtroExtra={filtroGrupo}
                                                funcaoIncluir={formulario.getFieldValue(['gru_id']) > 0 && (() => setOpenDrawerSubGp(true))}
                                                allowClear={true}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>  </Col>

                                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                                        <Form.Item label="Unidade de Medida" name="ump_id" rules={[{ required: true, message: 'Informe a Unidade de Medida' }]}>
                                            <Select
                                                placeholder="Selecione a Unidade de Medida"
                                                showSearch
                                                allowClear
                                                optionFilterProp="children">
                                                {listaUnidadeMedida.map((um) => (<Select.Option key={um.ump_id} id={um.ump_id}>{um.ump_id} - {um.ump_descricao}</Select.Option>))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Form.Item hidden label="pru_id" name="pru_id"></Form.Item>
                                    <Col xs={24} sm={12} md={8} lg={5} xl={5}>
                                        <Form.Item name="emb_ump_id" label={
                                            <div>
                                                Embalagem
                                                <Tooltip title="Informe aqui a unidade de medida da embalagem do produto EX: Unidade de medida CX(Caixa), Unidade Medida Embalagem (GR)Garrafas. Esta informação é OPCIONAL.">
                                                    <QuestionCircleOutlined />
                                                </Tooltip>
                                            </div>
                                        }>
                                            <Select
                                                placeholder="Selecione a Unidade de Medida"
                                                showSearch
                                                allowClear
                                                onBlur={(um) => checkCasaDecimalUnidadeMedida()}
                                                optionFilterProp="children">
                                                {listaUnidadeMedida.map((um) => (<Select.Option key={um.ump_id} id={um.ump_id}>{um.ump_id} - {um.ump_descricao}</Select.Option>))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={8} lg={8} xl={4}>
                                        <InputPreco
                                            precision={casaDecimaisUnd}
                                            label={
                                                <div>
                                                    Qtde da Embalagem
                                                    <Tooltip title="Informe aqui a quantidade da embalagem do produto EX: 1 CX(Caixa), Quantidade da Embalagem 12 GR(Garrafas). Esta informação é OPCIONAL.">
                                                        <QuestionCircleOutlined />
                                                    </Tooltip>
                                                </div>
                                            } name="pru_quantidade" />
                                    </Col>
                                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <InputPreco
                                            label="Peso Bruto"
                                            placeholder="Peso Bruto"
                                            name="pro_pesobruto"
                                            precision={3} />
                                    </Col>
                                    <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                                        <InputPreco
                                            placeholder="Peso Líquido"
                                            label="Peso Líquido"
                                            name="pro_pesoliquido"
                                            precision={3} />
                                    </Col>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.pro_diasvalidade !== currentValues.pro_diasvalidade}>
                                        {({ getFieldValue }) => {
                                            if (getFieldValue().pro_usabalanca === true) return (
                                                <Col xs={12} sm={12} md={3} lg={3} xl={3}>
                                                    <Form.Item label="Dias Validade" name="pro_diasvalidade">
                                                        <InputNumber placeholder="Informe Dias Validade" />
                                                    </Form.Item>
                                                </Col>
                                            );
                                            else return null;
                                        }}
                                    </Form.Item>
                                    {listaDeGrades.length === 0 &&
                                        <Col xs={24} sm={8} md={8} lg={8} xl={4}>
                                            <InputPreco label="Custo do Produto (R$)" name="pre_custoultimacompra" onChange={() => { calcularValorVendaPorMarkup() }} />
                                        </Col>
                                    }
                                    {listaDeGrades.length === 0 &&
                                        <>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={3}>
                                                <InputPreco cifrao={true} placeholder="Informe a Margem de Lucro do Produto (R$)" label="Markup" name="pro_markup" onChange={() => { calcularValorVendaPorMarkup() }} />
                                            </Col>
                                            <Col xs={24} sm={8} md={8} lg={8} xl={5}>
                                                <InputPreco rules={[{
                                                    required: true, validator: (rule, value) => {
                                                        if (parseFloat(value) > 0) {
                                                            return Promise.resolve();
                                                        } else {
                                                            return Promise.reject(new Error('Informe um valor de preço de venda!'))
                                                        }
                                                    }
                                                }]}
                                                    placeholder="Informe a Margem de Lucro do Produto" label="Preço de Venda (R$)" name="lpi_valorvenda" onChange={() => { recalcularMarkupDoProduto() }} />
                                            </Col>
                                            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.pro_id !== currentValues.pro_id}>
                                                {({ getFieldValue }) => {
                                                    if (!getFieldValue().pro_id && !getFieldValue().pre_qtde && !possuiCusto) return (
                                                        <Col xs={24} sm={24} md={24} lg={24} xl={11}>
                                                            <Row gutter={[8, 0]}>
                                                                <Col xs={24} sm={8} md={8} lg={8} xl={10}>
                                                                    <Form.Item label="Quantidade em Estoque" name="pre_qtde">
                                                                        <Input placeholder="Informe a Quantidade para a Entrada no Estoque" />
                                                                    </Form.Item>
                                                                </Col>
                                                                <Col xs={24} sm={16} md={16} lg={16} xl={14}>
                                                                    <Form.Item className="t-mob-573">
                                                                        <Switch /> Adicionar na Impressão de Etiquetas?
                                                                    </Form.Item>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    );
                                                    else return null;
                                                }}
                                            </Form.Item>
                                        </>
                                    }
                                </Row>
                                {listaDeGrades.length > 0 &&
                                    <Row align="middle" className="m-t-b-10">
                                        <Col span={24}>
                                            <Alert message='Para alterar o custo, markup ou valor de venda deste produto acesse a aba "GRADE"' type="warning" showIcon />
                                        </Col>
                                    </Row>
                                }
                                <Row gutter={[8, 0]}>
                                    <Col>
                                        <Form.Item name="pro_ativo" valuePropName="checked" initialValue={true}>
                                            <Checkbox> Produto Ativo? </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item name="pro_usabalanca" valuePropName="checked">
                                            <Checkbox onClick={(e) => gerarEANProdutoBalanca(e)}> Usa Balança? </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item name="pro_eaninvalido" valuePropName="checked">
                                            <Checkbox> Não Gerar EAN em Documento Fiscal? </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item name="pro_escalanaorelevante" valuePropName="checked" >
                                            <Checkbox> Produto SEM escala relevante? </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item name="pro_fabricacaopropria" valuePropName="checked" initialValue={false} >
                                            <Checkbox> Produto Fabricado? </Checkbox>
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Form.Item name="pro_controlelote" valuePropName="checked" initialValue={false} >
                                            <Checkbox onChange={() => {exibirLote()}}> Controla Lotes? </Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.pro_escalanaorelevante !== currentValues.pro_escalanaorelevante}>
                                    {({ getFieldValue }) => {
                                        if (getFieldValue().pro_escalanaorelevante === true) return (
                                            <Row gutter={[8, 0]}>
                                                <Col xs={24} sm={8} md={8} lg={8} xl={6}>
                                                    <Form.Item label="CNPJ do Fabricante" name="pro_cnpjfabricante" rules={[{ required: true, message: 'Informe o CNPJ do Fabricante' }]}>
                                                        <CpfCnpj
                                                            className='ant-input ant-input-sm'
                                                            placeholder="Informe o CNPJ do Fabricante"
                                                            onBlur={(record) => verificaCnpj(record)}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        );
                                        else return null;
                                    }}
                                </Form.Item>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Listas de Preços" key="2">
                                <TabListaPreco listaDePrecos={listaDePrecos} setListaDePrecos={setListaDePrecos} listaPrecoExclusao={{ listaPrecoExclusao, setListaPrecoExclusao }} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Dados Tributários" key="3" >
                                <TabProdutoTributacao formulario={formulario} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Grade" key="4"  >
                                <TabGrade gerarEANProduto={gerarEANProduto} formulario={formulario} listaDeGrades={listaDeGrades} formGrade={formGrade} setListaDeGrades={setListaDeGrades} />
                            </Tabs.TabPane>
                            {!!exibeLote &&
                                <Tabs.TabPane tab="Lote Produto" key="5">
                                    <TabLote listaLote={listaLote} setListaLote={setListaLote} casaDecimaisUnd={casaDecimaisUnd} />
                                </Tabs.TabPane>
                            }
                            <Tabs.TabPane tab="Imagem" key="6">
                                <TabImagem listaImagens={listaImagens} setListaImagens={setListaImagens} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Campos Adicionais" key="7">
                                <FormularioDinamico formulario={formulario} parametrosFiltros={elementosFormulario} />
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                </Row>
                <DrawerMarca openDrawer={openDrawerMarca} setOpenDrawer={setOpenDrawerMarca} cadMarca={false} retornoSalvar={salvandoDadosMarca} />
                <DrawerGrupoProduto openDrawerGrupo={openDrawerGrupo} setOpenDrawerGrupo={setOpenDrawerGrupo} cadGrupo={false} retornoSalvar={salvandoDadosGrupo} />
                <DrawerSubGrupo openDrawerSubGp={openDrawerSubGp} setOpenDrawerSubGp={setOpenDrawerSubGp} cadSubGp={false} gruId={foundGroup} retornoSalvar={salvandoDadosSubGrupo} />
            </FormGW>
        </div>
    );
}