import React, { useState } from 'react';
import { Button, Col, Drawer, Form, Modal, Row } from "antd";

import { useStateValue } from '../../state';
import { drawerActions } from '../../actions';
import ManutencaoPagamento from './pagamento';
import { InputPreco } from "../../components";
import { FormatNumber } from '../../ValueObjects';
import { CloseOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';

export default function DrawerPagamento({ formulario, calcularPagamentoParcial, listaParcelasPagar, totalizadores, limparListas, dadosFornecedor }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    function fecharDrawer() {
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        limparListas();
    };

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <Drawer title="Efetuar Pagamento"
            width="70%"
            visible={ui.showDrawer}
            onClose={() => { dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } }) }}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]} className="m-t-5">
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={listaParcelasPagar.listaParcelasPagar.length === 0} onClick={() => formulario.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoPagamento formulario={formulario} calcularPagamentoParcial={calcularPagamentoParcial} listaParcelasPagar={listaParcelasPagar} totalizadores={totalizadores} carregando={setCarregando} aoSalvar={fecharDrawer} dadosFornecedor={dadosFornecedor} />
        </Drawer>
    )
}