import React, { useEffect } from "react";
import { Row, Col, Tag, Popover, notification, Modal } from "antd";
import { EditOutlined, DeleteOutlined, CopyOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import DrawerHistorico from './drawer';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, Editar, Excluir, Duplicar } from "../../components";

export default function Historico() {

    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+his_descricao' } }), []);

    function excluirRegistro(dados) {
        api.delete(`Historico/Excluir/${dados.his_id}`).then(
            (res) => {
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }).catch(
                (erro) => {
                    modalInativarRegistro(dados);
                }
            ).finally(
                () => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+his_descricao' } });
                });
    }

    function inativarRegistro(dados) {
        dados.his_ativo = false;
        api.put(`Historico/Editar`,dados).then(
            res => {
                notification.success({ message: `Sucesso!`, description: `Status inativado com sucesso!` })
            }
        ).catch(
            error => {
                console.log(error);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+his_descricao' } });
            }
        )
    }

    const modalInativarRegistro = (dados) => {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: `Não foi possível excluir a Status ${dados.his_descricao}. Deseja inativar este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                inativarRegistro(dados);
            }
        });
    };

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir a Status: ${dados.his_id} - ${dados.his_descricao}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    const modalDuplicarDados = (dados) => {
        Modal.confirm({
            title: 'Duplicar?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja duplicar este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                dados.duplicar = true;
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados }});
                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true }});
            }
        });
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+his_descricao" },
                { label: "Nome Z - A", value: "-his_descricao" },
                { label: "Código Crescente", value: "+his_id" },
                { label: "Código Decrescente", value: "-his_id" }
            ]} />
            <div className="tabela">
                <TabelaDados url="historico/listar" colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',    
                            render: ({ his_id, his_ativo }) => (
                                <Popover content={his_ativo ? 'Ativa' : 'Inativa'} placement="right">
                                    <Tag color={his_ativo ? 'processing' : 'default'} className="w-100">
                                        <b>{his_id}</b>
                                    </Tag>
                                </Popover>
                            ),
                        },
                        {
                            title: 'Histórico',
                            render: ({ his_descricao }) => (
                                <div>
                                    <b>{his_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 95,                            
                            fixed: 'right',
                            render: (record, dados) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Duplicar icon={<CopyOutlined />} onClick={() => {modalDuplicarDados(dados)}}/>
                                        </Col>
                                        <Col>
                                            <Editar onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Excluir icon={<DeleteOutlined />} onClick={() => {modalExcluir(dados)}}/>
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } />
            </div>
            <DrawerHistorico />
        </div>
    );

}