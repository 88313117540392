import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import ManutencaoNotaFiscalTransferencia from '../notaFiscalTransferencia/manutencao';
import { DrawerNav } from '../../components';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';

export default function DrawerTransferencia({ abrirDrawer, fecharDrawer, dadosPedido }) {

    const [formNotaFiscal] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);
    const [tagPages, setTagPages] = useState(null);
    const [proximaPag, setProximaPag] = useState(0);
    const [limparDados, setLimparDados] = useState(false);
    const [formulario] = Form.useForm();

    function fechar() {
        formNotaFiscal.resetFields();
        setTagPages(0);
        setProximaPag(null);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '%2Bped_datainclusao' } });
        fecharDrawer(false)
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        setLimparDados(true);
    };

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fechar();
            }
        });
    };

    return (
        <DrawerNav title="Emissão de Nota Fiscal Eletrônica de Transferência"
            width="70%"
            visible={abrirDrawer}
            closeIcon={<MinusOutlined />}
            limparAoEditar={true}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={tagPages === 0} icon={<LeftOutlined />} size="large" onClick={() => setProximaPag(tagPages - 1)} type="primary">
                                Voltar
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" hidden={tagPages === 3} size="large" onClick={() => setProximaPag(tagPages + 1)}>
                                Avançar <RightOutlined />
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formulario.submit()} hidden={tagPages !== 3} loading={carregando} icon={<SaveOutlined />} size="large" type="primary">
                                Emitir Nota
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoNotaFiscalTransferencia formulario={formulario} carregando={setCarregando} aoSalvar={fechar} pages={{ tagPages, setTagPages }} proximaPag={{ proximaPag, setProximaPag }} limparDados={{ limparDados, setLimparDados }} abrirDrawer={abrirDrawer} />
        </DrawerNav>
    )
}