import React, { useState } from "react";
import { Row, Col, Typography, Divider, Form, Input, Checkbox, Upload, message, Tooltip, Button } from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import ImgCrop from "antd-img-crop";
import { DeleteOutlined } from "@material-ui/icons";

export default function TabFilial({ imageUrl, setImageUrl }) {

    const [loading, setLoading] = useState(false);
    const [hover, setHover] = useState(false);

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Você só pode fazer upload de arquivos JPG/PNG!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('A imagem deve ter menos de 2 MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    const handleChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        getBase64(info.file.originFileObj, (imageUrl) => {
            setLoading(false);
            setImageUrl(imageUrl);
        });
    };

    const borderColor = hover ? "#004b97" : "#d9d9d9";
    const mouseEnter = () => { setHover(true); }
    const mouseLeave = () => { setHover(false); }
    const removerImagem = () => { setImageUrl(null); }

    const uploadButton = (
        <div className="buttonUpload" style={{ border: `1px dashed ${borderColor}`, cursor: hover ? "pointer" : "auto" }} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} >
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text"> {loading ? 'Carregando...' : 'Selecionar Imagem'}</div>
        </div>
    );

    return (
        <div className="perfil">
            <Row align="middle" gutter={[0, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-group.png").default} alt="Filial" /> Filial
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Adicione as informações referente a Filial.
                    </Divider>
                </Col>
                <Col span={24}>
                    <Row justify="center" gutter={[8, 8]}>
                        <Col xs={24} sm={8} md={7} lg={7} xl={7} align="center">
                            <ImgCrop
                                rotate                           
                                modalTitle="Ajuste sua imagem"
                                modalOk="Salvar">
                                <Upload
                                    name="avatar"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                    beforeUpload={beforeUpload}
                                    onChange={handleChange}
                                    onRemove={() => setImageUrl(null)}
                                    maxCount={1}
                                    disabled={imageUrl}
                                >
                                    {imageUrl ?
                                        <img src={imageUrl} alt="avatar" className="imgFilial"/>
                                        : uploadButton}
                                </Upload>
                            </ImgCrop>
                            { imageUrl &&
                            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                    <Form.Item style={{ marginTop: "10px"}}> 
                                        <Tooltip title="Remover Imagem" placement="bottom">
                                            <Button shape="circle" icon={<DeleteOutlined />} onClick={removerImagem}/>
                                        </Tooltip>
                                    </Form.Item>
                            </Col>
                            }
                        </Col>
                        <Col xs={24} sm={24} md={17} lg={17} xl={17}>
                            <Row gutter={[8, 0]}>
                                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                                    <Form.Item label="Código de Identificação" name="loc_codigo">
                                        <Input placeholder="Informe um Código para Identificação da Filial" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                                    <Form.Item label="Nome da Filial" name="loc_descricao" rules={[{ required: true }]}>
                                        <Input placeholder="Informe o Nome da Filial" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={19} lg={19} xl={19}>
                                    <Form.Item label="Nome Fantasia da Filial" name="loc_nomefantasia">
                                        <Input placeholder="Informe o Nome Fantasia Referente a Filial" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={5} lg={5} xl={5}>
                                    <Form.Item name="loc_ativo" valuePropName="checked" className="t-mob">
                                        <Checkbox>Filial Ativa?</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );

}