import React, { useEffect } from "react";
import { Row, Col, Form, Button, Typography, Divider, InputNumber } from "antd";
import { RedoOutlined, PlusOutlined } from "@ant-design/icons";

export default function TabParcelas({ formulario, listaParcelas }) {

    useEffect(() => {
        if (listaParcelas.parcelas.length > 0) {
            let parcelas = [...listaParcelas.parcelas];
            parcelas.forEach(
                (parcela) => {
                    formulario.setFieldsValue({ ['perc' + parcela.pcl_numeroparcela]: parcela.pcl_percparcela });
                    formulario.setFieldsValue({ ['dias' + parcela.pcl_numeroparcela]: parcela.pcl_numerodias });
                }
            );
        }
    }, [listaParcelas.parcelas]);


    function criarParcelas() {
        let qtdParcelas = parseInt(formulario.getFieldValue().pcl_numeroparcela);
        let dias = parseInt(formulario.getFieldValue().pcl_numerodias);
        let nDiasParcelas = 0;
        let somaPercentual = 0;
        let resto = 0;
        let nUltimaParcela = qtdParcelas - 1;
        let percParcela = (100 / qtdParcelas).toFixed(2);
        let dadosParcelamento = [];
        for (let i = 0; i < qtdParcelas; i++) {
            nDiasParcelas += dias;
            dadosParcelamento.push({
                pcl_numeroparcela: i + 1,
                pcl_numerodias: nDiasParcelas,
                pcl_percparcela: parseFloat(percParcela)
            });
            
            somaPercentual += parseFloat(percParcela);
        }
        resto = (100 - somaPercentual).toFixed(2);
        if (resto != 0) {
            resto = parseFloat(resto);
            dadosParcelamento[nUltimaParcela].pcl_percparcela = parseFloat((dadosParcelamento[nUltimaParcela].pcl_percparcela + resto).toFixed(2));
        }
        listaParcelas.setParcelas(dadosParcelamento);
    };

    function reorganizarParcelas() {
        listaParcelas.setParcelas([]);
    };

    function alterarParcela(campo, name) {
        let dados = [...listaParcelas.parcelas];
        let valor = parseFloat(formulario.getFieldValue()[name]);
        let posicao = parseInt(name.replace(campo, '').trim()) - 1;
        if (campo === 'dias') {
            dados[posicao].pcl_numerodias = valor;
            for (let i = posicao + 1; i < listaParcelas.parcelas.length; i++) {
                valor += parseInt(formulario.getFieldValue().pcl_numerodias);
                dados[i].pcl_numerodias = valor;
            }
        } else {
            if (valor > 100) {
                valor = 100;
            }
            let valorPerc = (100 - valor) / (dados.length - 1);
            let valorInteiro = valorPerc.toFixed(2).split('.');
            valorInteiro = parseInt(valorInteiro);
            let percValorInt = valorInteiro * (dados.length - 1) + valor;
            for (let i = 0; i < dados.length; i++) {
                if (posicao != i) {
                    dados[i].pcl_percparcela = valorInteiro;
                } else {
                    dados[i].pcl_percparcela = valor;
                }
            }
            let sobra = 100 - percValorInt;
            if (posicao === 0) {
                dados[1].pcl_percparcela += sobra;
            } else {
                dados[0].pcl_percparcela += sobra;
            }
        }
        listaParcelas.setParcelas(dados);
    };


    return (
        <div className="pages-col">
            <Row gutter={[0, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-saida.png").default} alt="Parcelas" /> Parcelas
                    </Typography.Title>
                    <Divider orientation="left" plain> Defina o número de parcelas e a quantidade de dias que as mesmas terão de intervalo.  </Divider>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={8} md={8} lg={8} xl={4}>
                            <Form.Item label="Número de Parcelas" name="pcl_numeroparcela">
                                <InputNumber
                                    step={1}
                                    min={1}
                                    placeholder="Informe o número total de parcelas"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={10} md={8} lg={8} xl={6}>
                            <Form.Item label="Número de Dias entre as parcelas" name="pcl_numerodias">
                                <InputNumber
                                    step={1}
                                    min={1}
                                    placeholder="Informe o numero de dias entre as parcelas"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={6} md={4} lg={4} xl={3} className="t-mob-573">
                            <Button icon={<PlusOutlined />} block type="primary" onClick={() => { criarParcelas() }}>
                                Adicionar
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>            
            <Row justify="center" className="m-t-16">
                <Col xs={24} sm={24} md={12} lg={12} xl={10}>
                    <Row gutter={[16,16]}>
                        {listaParcelas.parcelas.map(parcela => (
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Row align="middle" gutter={[8, 5]}>
                                    <Col span={24}>
                                        <Typography.Text strong className="f-14">
                                            Parcela - {parcela.pcl_numeroparcela}
                                        </Typography.Text>
                                        <Divider className="m-0"  />
                                    </Col>
                                </Row>
                                <Row align="middle" gutter={[8, 8]}>
                                    <Col span={12}>
                                        <Form.Item name={'dias' + parcela.pcl_numeroparcela} initialValue={parcela.pcl_numerodias} label="Dias">
                                            <InputNumber
                                                step={1}
                                                min={0}
                                                onChange={() => { alterarParcela('dias', 'dias' + parcela.pcl_numeroparcela) }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="%" name={'perc' + parcela.pcl_numeroparcela} initialValue={parcela.pcl_percparcela}>
                                            <InputNumber
                                                min={0}
                                                max={100}
                                                formatter={value => `${value}%`}
                                                onChange={() => { alterarParcela('perc', 'perc' + parcela.pcl_numeroparcela) }}
                                                parser={value => value.replace('%', '')} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        ))}
                        {listaParcelas.parcelas.length > 0 && <Col span={24}>
                            <Button block size="middle" icon={<RedoOutlined />} type="primary" onClick={() => { reorganizarParcelas() }}>
                                Reorganizar
                            </Button>
                        </Col>}
                    </Row>
                </Col>
            </Row>
        </div>
    );

}