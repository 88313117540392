import React, { useEffect, useState } from "react";
import { Form, Row, Col, Typography, Divider, Select, InputNumber, Modal } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { InputPercentual } from '../../components';

import api from "../../services/api";

export default function TabSaida({ formulario }) {

    const [listaCstPisCofins, setListaCSTPisCofins] = useState([]);
    const [listaNaturezaEfd, setListaNaturezaEfd] = useState([]);
    const [naturezaReceita, setNaturezaReceita] = useState(null);
    const [cstcofins, setcofins] = useState(null);

    useEffect(() => {
        if (!!formulario.getFieldValue(['cpc_saidacstpis', 'cpc_id']))
            setNaturezaReceita(formulario.getFieldValue(['cpc_saidacstpis', 'cpc_id']));

        if (!!formulario.getFieldValue(['cpc_saidacstcofins', 'cpc_id']))
            setcofins(formulario.getFieldValue(['cpc_saidacstcofins', 'cpc_id']));

        api.get(`CstPisCofins/ListarEntradaSaida?tipo=S`).then(
            res => {
                setListaCSTPisCofins(res.data);
            })
    }, [])

    useEffect(() => {
        api.get(`NaturezaReceitaEfd/Listar?cstPis=${naturezaReceita}&cstCofins=${naturezaReceita}`).then(
            res => {
                setListaNaturezaEfd(res.data.items);
            })
    }, [naturezaReceita]);

    useEffect(() => {
        if (cstcofins && naturezaReceita)
            if (cstcofins !== naturezaReceita)
                Modal.warning({
                    title: 'Atenção!',
                    icon: <ExclamationCircleOutlined />,
                    content: 'Os CST estão diferentes, normalmente o CST PIS e COFINS devem ser iguais - Verifique a situação!',
                    okText: 'Ok',
                });
    }, [cstcofins, naturezaReceita]);

    return (

        <div>
            <Row align="middle" gutter={[0, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-saida.png").default} alt="Saídas" /> Saídas
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Defina a configuração do PIS e da COFINS para as saídas.
                    </Divider>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={12} lg={13} xl={13}>
                            <Form.Item label="Cód. da Situação Tributária do PIS - Pessoa Jurídica"
                                name={['cpc_saidacstpis', 'cpc_id']}>
                                <Select rules={[{ required: true, message: 'Informe o CST do PIS' }]} placeholder="Selecione o CST" onChange={(valor) => {
                                    setNaturezaReceita(valor);
                                    if (formulario.getFieldValue(['cpc_saidacstpis', 'cpc_id']) > 3) formulario.setFieldsValue({ pis_saidapercredbcpis: 0, pis_saidapercpis: 0 })
                                }}>
                                    {listaCstPisCofins.map((item) => (
                                        <Select.Option value={item.cpc_id} key={item.cpc_id}>{item.cpc_id} - {item.cpc_descricao}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <InputPercentual
                                disabled={naturezaReceita > 3}
                                label="% Redução Base de Cálculo"
                                name="pis_saidapercredbcpis"
                                defaultValue={0}
                                min={0}
                                max={100}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                            <InputPercentual
                                disabled={naturezaReceita > 3}
                                label="% Alíquota do PIS"
                                rules={[{ required: (naturezaReceita <= 3) ? true : false, message: 'Informe o % alíquota do PIS' }]}
                                name="pis_saidapercpis"
                                defaultValue={0}
                                min={0}
                                max={100}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={13} xl={13}>
                            <Form.Item label="Cód. da Situação Tributária da COFINS" name={['cpc_saidacstcofins', 'cpc_id']}
                                rules={[{ required: (naturezaReceita > 0), message: 'Informe o CST da COFINS' }]}>
                                <Select
                                    placeholder="Selecione o CST"
                                    onChange={(valor) => {
                                        setcofins(valor);
                                        if (formulario.getFieldValue(['cpc_saidacstcofins', 'cpc_id']) > 3) formulario.setFieldsValue({ pis_saidapercredbccofins: 0, pis_saidaperccofins: 0 })
                                    }}>
                                    {listaCstPisCofins.map((item) => (
                                        <Select.Option value={item.cpc_id} key={item.cpc_id}>{item.cpc_id} - {item.cpc_descricao}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <InputPercentual
                                disabled={naturezaReceita > 3}
                                label="% Redução Base de Cálculo"
                                name="pis_saidapercredbccofins"
                                defaultValue={0}
                                min={0}
                                max={100}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                        <InputPercentual
                                disabled={naturezaReceita > 3}
                                label="% Alíquota do COFINS"
                                rules={[{ required: (naturezaReceita <= 3) ? true : false, message: 'Informe o % alíquota do COFINS' }]}
                                name="pis_saidaperccofins"
                                defaultValue={0}
                                min={0}
                                max={100}
                            />
                        </Col>
                        <Col span={24}>
                            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.cpc_saidacstpis !== currentValues.cpc_saidacstpis}>
                                {({ getFieldValue }) => {
                                    if (getFieldValue(['cpc_saidacstpis', 'cpc_id']) > 3 && getFieldValue(['cpc_saidacstpis', 'cpc_id']) < 49)
                                        return (
                                            <Row>
                                                <Col span={24}>
                                                    <Form.Item label="Natureza da Receita" name="nat_id" rules={[{ required: true }]}>
                                                        <Select allowClear mode={formulario.getFieldValue('pis_id') ? '' : 'multiple'}>
                                                            {listaNaturezaEfd.map((item) => (
                                                                <Select.Option value={item.nat_id} key={item.nat_id}>{item.nat_codigo} - {item.nat_descricao}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        );
                                    else return null;
                                }}
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}