import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Tag, Popover, Divider, Modal, notification } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { useStateValue } from '../../../state';
import { TabelaDados } from "../../../components";
import { listagemActions, manutencaoActions } from "../../../actions";
import DrawerTributacaoPisCofins from '../../../pages/tributacaoPisCofins/drawer';

export default function PesquisaTributacaoPisCofins() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pis_descricao' } }), []);

    function excluirRegistro(id) {
        setCarregando(true);
        api.post(`TributacaoPisCofins/Excluir?codigoPisCofins=${id}`).then((res) => {
            if (res.status === 200) notification.success({ message: 'Operação concluída', description: "Registro excluído com sucesso!" });
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        }, erro => {
            notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
        }).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pis_descricao' } });
            });
    }

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    return (
        <div className="rowPanel text-center">
            <Row align="middle" justify="center" className="hg-100">
                <Col span={24} className="hg-100">
                    <div className="tabela">
                        <TabelaDados url="TributacaoPisCofins/Listar" colunas={
                            [
                                {
                                    title: 'Código',
                                    width: 75,
                                    align: 'center',
                                    render: ({ pis_id, pis_ativo }) => (
                                        <Popover content={pis_ativo ? "Ativo" : "Inativo"} placement="right">
                                            <Tag color={pis_ativo ? 'processing' : 'default'} className="w-100">
                                                <b>{pis_id}</b>
                                            </Tag>
                                        </Popover>
                                    ),
                                },
                                {
                                    title: 'Tributação do PIS e COFINS',
                                    render: ({ pis_descricao, nat_descricao }) => (
                                        <div>
                                            <Row>
                                                <Col>
                                                    <b>{pis_descricao}</b>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    {nat_descricao}
                                                </Col>
                                            </Row>
                                        </div>
                                    ),
                                },/*
                                {
                                    title: 'Ações',
                                    dataIndex: '',
                                    key: 'x',
                                    fixed: 'right',
                                    align: 'center',
                                    width: 65,
                                    render: (record) => (
                                        <div>
                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                <Col>
                                                    <Button onClick={() => {
                                                        let item = { ...record };
                                                        item.cpc_saidacstpis = { cpc_id: record.cpc_saidacstpis };
                                                        item.cpc_saidacstcofins = { cpc_id: record.cpc_saidacstcofins };
                                                        item.cpc_entradacstpispj = { cpc_id: record.cpc_entradacstpispj };
                                                        item.cpc_entradacstpispf = { cpc_id: record.cpc_entradacstpispf };
                                                        item.cpc_entradacstcofinspj = { cpc_id: record.cpc_entradacstcofinspj };
                                                        item.cpc_entradacstcofinspf = { cpc_id: record.cpc_entradacstcofinspf };
                                                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: item } });
                                                        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                                    }} icon={<EditOutlined />} />
                                                </Col>
                                                <Col>
                                                    <Button icon={<DeleteOutlined />} onClick={() => modalExcluir(record.pis_id)} />
                                                </Col>
                                            </Row>
                                        </div>
                                    ),
                                },*/
                            ]
                        }
                        expandir={(record) => {
                            return (
                                <div>
                                    <Row gutter={[8,0]}>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col span={24}>
                                                    <Typography.Text strong className="f-14">
                                                        Tributação PIS
                                                </Typography.Text>
                                                    <Divider className="m-0" />
                                                </Col>
                                                <Col span={10}>
                                                    CST Saída: <b>{record.cpc_saidacstpis || 'Não Informado'} </b>
                                                </Col>
                                                <Col span={7}>
                                                    Perc.Redução BC: <b>{record.pis_saidapercredbcpis || 'Não Informado'}</b>
                                                </Col>
                                                <Col span={7}>
                                                    Alíquota: <b>{record.pis_saidapercpis || 'Não Informado'}</b>
                                                </Col>
                                                <Divider className="m-0" />
                                                <Col span={10}>
                                                    CST Entrada Pessoa Física: <b>{record.cpc_entradacstpispf || 'Não Informado'}</b>
                                                </Col>
                                                <Col span={7}>
                                                    Perc.Redução BC: <b>{record.pis_entradapercredbcpispf || 'Não Informado'} </b>
                                                </Col>
                                                <Col span={7}>
                                                    Alíquota: <b>{record.pis_entradapercpispf || 'Não Informado'}</b>
                                                </Col>
                                                <Divider className="m-0" />
                                                <Col span={10}>
                                                    CST Entrada Pessoa Jurídica: <b>{record.cpc_entradacstpispj || 'Não Informado'}</b>
                                                </Col>
                                                <Col span={7}>
                                                    Perc.Redução BC: <b>{record.pis_entradapercredbcpispj || 'Não Informado'}</b>
                                                </Col>
                                                <Col span={7}>
                                                    Alíquota: <b>{record.pis_entradapercpispj || 'Não Informado'}</b>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                                            <Row align="middle" gutter={[8, 8]}>
                                                <Col span={24}>
                                                    <Typography.Text strong className="f-14">
                                                        Tributação COFINS
                                                </Typography.Text>
                                                    <Divider className="m-0" />
                                                </Col>
                                                <Col span={10}>
                                                    CST Saída: <b>{record.cpc_saidacstcofins || 'Não Informado'}</b>
                                                </Col>
                                                <Col span={7}>
                                                    Perc.Redução BC: <b>{record.pis_saidapercredbccofins || 'Não Informado'}</b>
                                                </Col>
                                                <Col span={7}>
                                                    Alíquota: <b>{record.pis_saidaperccofins || 'Não Informado'}</b>
                                                </Col>
                                                <Divider className="m-0" />
                                                <Col span={10}>
                                                    CST Entrada Pessoa Física: <b>{record.cpc_entradacstcofinspf || 'Não Informado'}</b>
                                                </Col>
                                                <Col span={7}>
                                                    Perc.Redução BC: <b>{record.pis_entradapercredbccofinspf || 'Não Informado'} </b>
                                                </Col>
                                                <Col span={7}>
                                                    Alíquota: <b>{record.pis_entradaperccofinspf || 'Não Informado'} </b>
                                                </Col>
                                                <Divider className="m-0" />
                                                <Col span={10}>
                                                    CST Entrada Pessoa Jurídica: <b>{record.cpc_entradacstcofinspj || 'Não Informado'} </b>
                                                </Col>
                                                <Col span={7}>
                                                    Perc.Redução BC: <b>{record.pis_entradapercredbccofinspj || 'Não Informado'} </b>
                                                </Col>
                                                <Col span={7}>
                                                    Alíquota:<b> {record.pis_entradaperccofinspj || 'Não Informado'} </b>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }} locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }} scroll={{ x: 900 }} columnWidth={30} />
                    </div>  
                </Col>
            </Row>            
            <DrawerTributacaoPisCofins />
        </div>
    );
    
}