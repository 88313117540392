import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Button, Input, InputNumber, Select, Card, notification, Tooltip, Modal } from "antd";
import { SearchOutlined, BarcodeOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { InputPreco } from "../../components";
import { ModalGradeProduto, ModalManutencaoNota } from "../modals";
import { useStateValue } from "../../state";
import { manutencaoActions } from "../../actions";
import { info, isObjetoDiffVazio } from "../../services/funcoes";
import SelectProduto from "../selects/selectProduto";
import { CalcularImpostoItem } from "../../services/calculaImposto";

export default function FiltroProdutoNtf({ form, dadosTabela, dadosEndereco = {}, dadosOperacao = {}, dadosNfeTransferencia, validarTransporte }) {

    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [abrirModalGrade, setAbrirModalGrade] = useState(false);
    const [dadosDetalhes, setDadosDetalhes] = useState({});
    const [dadosGrade, setDadosGrade] = useState([]);
    const [desabilitarProd_id, setDesabilitarProd_id] = useState(false);
    const [consultaProduto, setConsultaProduto] = useState(null);
    const [dadosCfop, setDadosCfop] = useState([]);
    const [abrirModalManutencaoNota, setAbrirModalManutencaoNota] = useState(false);
    const [dadosProduto, setDadosProduto] = useState({});
    const [{ manutencao }, dispatch] = useStateValue();
    const [dadosContribuinte, setDadosContribuinte] = useState({});
    const [tributacaoIcms, setTributacaoIcms] = useState([]);
    const [listaItens, setListaItens] = useState([]);
    const codBarras = useRef();
    const togglePesquisa = () => {
        setConsultaProduto(!consultaProduto);
    };

    useEffect(() => {
        popularDadosContribuintes();
        buscarDadosCfop();
    }, []);

    useEffect(() => {
        if (!!dadosContribuinte && isObjetoDiffVazio(dadosContribuinte)) {
            if (!!manutencao.dados && !!manutencao.dados.ped_id) {
                incluirProdutoExterno();
            }
        }
    }, [dadosContribuinte]);

    useEffect(() => {
        if (consultaProduto && !!codBarras && !!codBarras.current) {
            codBarras.current.focus();
        }
    }, [consultaProduto]);

    useEffect(() => {
        if (!!manutencao.dados && !!dadosContribuinte && isObjetoDiffVazio(dadosContribuinte) && !!manutencao.dados.pro_id) {
            let prod = listaItens.find(p => p.pro_id == manutencao.dados.pro_id && (manutencao.dados.prg_id == null || manutencao.dados.prg_id == p.prg_id));
            form.setFieldsValue({ pro_id: prod.key });
            abrirEditarItem(prod);
        }
    }, [manutencao]);

    async function incluirProdutoExterno() {
        let listaItens = [];
        let custo = !!dadosNfeTransferencia.dadosNfeTransferencia && !!dadosNfeTransferencia.dadosNfeTransferencia.custo ? dadosNfeTransferencia.dadosNfeTransferencia.custo.key : 1;
        let destinoOperacao = (!!dadosEndereco.est_sigla && !!dadosContribuinte.est_sigla && (dadosEndereco.est_sigla === dadosContribuinte.est_sigla) ? '1' : '2');
        for (let [idx, itens] of dadosTabela.dadosItens.entries()) {
            let dadosItem = await api.get(`Produto/BuscarDadosProdutoTransferencia?codigoProduto=${itens.pro_id}&destinoOperacao=${destinoOperacao}&regimeTributarioEmpresa=${dadosContribuinte.emp_regimetributario}`
                + `&tipoContribuinte=${dadosContribuinte.loc_tipocontribuinte}&tipoCusto=${custo}&ufDestino=${dadosEndereco.est_sigla}&codigoProdutoGrade=${itens.prg_id}`
                + `&ufOrigem=${dadosContribuinte.est_sigla}`);
            let icm_id = null;
            let tributacaoIcms = await api.get(`TributacaoIcms/ListarTributacaoIcmsOrigemDestinoProduto?codigoProduto=${itens.pro_id}&ufDestino=${dadosEndereco.est_sigla}&ufOrigem=${dadosContribuinte.est_sigla}`);
            if (tributacaoIcms.status === 200) {
                tributacaoIcms.data.forEach((icm) => {
                    if (!!icm.classeProduto) {
                        icm_id = icm;
                    }
                });
            }
            if (dadosItem.status === 200) {
                dadosItem = dadosItem.data;
                let prodNota = {};
                if (!!icm_id && JSON.stringify(icm_id) !== '{}' && !!icm_id.icm_id) {
                    prodNota.icm_id = icm_id;
                    prodNota.cso_id = icm_id.cso_id;
                    prodNota.cst_id = icm_id.cst_id;
                    prodNota.icm_descricao = icm_id.icm_descricao;
                    prodNota.iod_id = icm_id.iod_id;
                    prodNota.iod_percdiferimentoicms = icm_id.iod_percdiferimentoicms;
                    prodNota.iod_percicms = icm_id.iod_percicms;
                    prodNota.iod_percicmsreducaobc = icm_id.iod_percicmsreducaobc;
                    prodNota.iod_ufdestino = icm_id.iod_ufdestino;
                    prodNota.iod_uforigem = icm_id.iod_uforigem;
                }
                prodNota.pro_id = itens.pro_id;
                prodNota.prg_id = !!itens.prg_id ? itens.prg_id : null;
                prodNota.nfi_cfop = dadosItem.cfo_cfop;
                prodNota.nfi_qtde = itens.nfi_qtde;
                prodNota.nfi_numeroitem = dadosTabela.dadosItens.length + 1;
                prodNota.nfi_valorunitario = parseFloat(itens.nfi_valorunitario);
                prodNota.total = parseFloat(itens.nfi_valorunitario) * parseFloat(itens.nfi_qtde);
                prodNota.percdesc = parseFloat(!!itens.percdesc ? itens.percdesc : 0);
                prodNota.nfi_valordesconto = parseFloat(!!itens.nfi_valordesconto ? itens.nfi_valordesconto : 0);
                prodNota.percacresc = parseFloat(!!itens.percacresc ? itens.percacresc : 0);
                prodNota.nfi_valoroutros = parseFloat(!!itens.nfi_valoroutros ? itens.nfi_valoroutros : 0);
                prodNota.nfi_informacaoadicional = '';
                prodNota.cfo_cfop = dadosItem.cfo_cfop;
                prodNota.cfo_descricao = dadosItem.cfo_descricao;
                prodNota.cfo_id = dadosItem.cfo_id;
                prodNota.nfi_ean = itens.nfi_ean;
                prodNota.nfi_unidademedida = dadosItem.ump_id;
                prodNota.pro_codigo = itens.pro_codigo;
                prodNota.pro_descricao = itens.pro_descricao;
                prodNota.pro_ean = dadosItem.pro_ean;
                prodNota.ump_id = dadosItem.ump_id;
                prodNota.nfi_numeropedidocompra = !!itens.nfi_numeropedidocompra ? itens.nfi_numeropedidocompra : null;
                prodNota.nfi_ncm = dadosItem.ncm_codigo;
                prodNota.ncm_id = dadosItem.ncm_id;
                listaItens.push(prodNota)
                if (idx === dadosTabela.dadosItens.length - 1) {
                    dadosTabela.setDadosItens(listaItens);
                }
            }
        }
    }

    function buscarDadosCfop() {
        api.get(`Cfop/ListarCfopTipo?tipoCfopFim=8000&tipoCfopIni=1000`).then(
            (res) => {
                if (res.status === 200) {
                    setDadosCfop(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };

    async function buscarDadosProdutoNotaTransf(incluir = false, possuiGrade = false) {
        let listaProdutos = [...listaItens];
        let produto = listaProdutos.filter(pro => pro.key === form.getFieldValue().pro_id)[0];
        let custo = !!dadosNfeTransferencia.dadosNfeTransferencia && !!dadosNfeTransferencia.dadosNfeTransferencia.custo ? dadosNfeTransferencia.dadosNfeTransferencia.custo.key : 1;
        let destinoOperacao = (!!dadosEndereco.est_sigla && !!dadosContribuinte.est_sigla && (dadosEndereco.est_sigla === dadosContribuinte.est_sigla) ? '1' : '2');
        let parametros = `IdProduto=${produto.pro_id}&destinoOperacao=${destinoOperacao}&empresaEnquadramento=${dadosContribuinte.emp_regimetributario}`;
        parametros += `&tipoContribuinte=${dadosContribuinte.loc_tipocontribuinte}&tipoCusto=${custo}&ufDestino=${dadosEndereco.est_sigla}&ufOrigem=${dadosContribuinte.est_sigla}`;
        parametros += !!produto.prg_id ? `&codigoProdutoGrade=${produto.prg_id}` : '';
        api.get(`Produto/BuscarDadosProdutoTransferencia?${parametros}`).then(
            res => {
                if (res.status === 200) {
                    let dadosProduto = res.data;
                    let icm_id = null;
                    api.get(`TributacaoIcms/ListarTributacaoIcmsOrigemDestinoProduto?codigoProduto=${dadosProduto.pro_id}&ufDestino=${dadosEndereco.est_sigla}&ufOrigem=${dadosContribuinte.est_sigla}`).then(
                        tributacaoIcms => {
                            if (tributacaoIcms.status === 200) {
                                tributacaoIcms.data.forEach((icm) => {
                                    if (!!icm.icm_id) {
                                        icm_id = icm;
                                    }
                                });
                                setTributacaoIcms(tributacaoIcms.data);
                            }
                            form.setFieldsValue({ nfi_cfop: dadosProduto.cfo_cfop });
                            if (!!icm_id && JSON.stringify(icm_id) !== '{}' && !!icm_id.icm_id) {
                                dadosProduto.icm_id = icm_id.icm_id;
                                dadosProduto.cso_id = icm_id.cso_id;
                                dadosProduto.cst_id = icm_id.cst_id;
                                dadosProduto.icm_descricao = icm_id.icm_descricao;
                                dadosProduto.iod_id = icm_id.iod_id;
                                dadosProduto.iod_percdiferimentoicms = icm_id.iod_percdiferimentoicms;
                                dadosProduto.iod_percicms = icm_id.iod_percicms;
                                dadosProduto.iod_percicmsreducaobc = icm_id.iod_percicmsreducaobc;
                                dadosProduto.iod_ufdestino = icm_id.iod_ufdestino;
                                dadosProduto.iod_uforigem = icm_id.iod_uforigem;

                                form.setFieldsValue({ icm_id: icm_id.icm_id });
                            }

                            setDadosProduto(dadosProduto);

                            if ((!!dadosProduto.icm_id === false) || (!!dadosProduto.ncm_id === false) || (!!dadosProduto.ump_id === false) || (!!dadosProduto.pro_descricao === false)) {
                                setAbrirModalManutencaoNota(true);
                            } else if ((!!dadosProduto.iod_ufdestino) === false) {
                                setAbrirModalManutencaoNota(true);
                            } else {
                                setarCfopCorrespondenteTributacaoICMS(dadosProduto);
                            }

                            if (dadosProduto.pre_custoultimacompra === 0 && !possuiGrade) { //validação é feita na grade quando possuir
                                info("Não foi possivel localizar o " + dadosNfeTransferencia.dadosNfeTransferencia.custo.value + " do item " + dadosProduto.pro_descricao + ", selecione OUTRO CUSTO e informe o produto novamente!");
                                return;
                            }

                            form.setFieldsValue({ quantidade: 1, valorUnitario: dadosProduto.pre_custoultimacompra, valorTotalItem: dadosProduto.pre_custoultimacompra });

                            if (incluir) {
                                let dadosTemp = [...dadosTabela.dadosItens];
                                adicionarItemNotaTransferencia(false, dadosProduto, null, dadosProduto.prg_id, () => {
                                    dadosTemp.push(dadosProduto);
                                    dadosTabela.setDadosItens(dadosTemp);
                                    limparCampos();
                                    if (consultaProduto && !!codBarras && !!codBarras.current) {
                                        codBarras.current.focus();
                                    }
                                });
                            } else {
                                setExibirDetalhes(true);
                                let dadosTemp = {};
                                dadosTemp.prg_id = dadosProduto.prg_id;
                                dadosTemp.pro_descricao = dadosProduto.pro_descricao;
                                dadosTemp.pro_codigo = dadosProduto.pro_codigo;
                                dadosTemp.pro_ean = dadosProduto.pro_ean;
                                dadosTemp.prg_ean = dadosProduto.prg_ean;
                                dadosTemp.ncm_codigo = dadosProduto.ncm_codigo;
                                dadosTemp.ump_id = dadosProduto.ump_id;
                                dadosTemp.descricaograde = dadosProduto.descricaograde;
                                setDadosDetalhes(dadosTemp);
                            }
                        }
                    )
                }
            }
        ).catch(error => console.log(error));
    };

    function setarCfopCorrespondenteTributacaoICMS(dadosProduto) {
        var cfoCfop = "";
        if (dadosContribuinte.emp_regimetributario === 3) {
            if (dadosProduto.cst_id === "00" || dadosProduto.cst_id === "20" || dadosProduto.cst_id === "40" || dadosProduto.cst_id === "41" || dadosProduto.cst_id === "50" || dadosProduto.cst_id === "51" || dadosProduto.cst_id === "90") {
                if (dadosContribuinte.loc_tipocontribuinte === 1 || dadosContribuinte.loc_tipocontribuinte === 4) {
                    if (dadosEndereco.est_sigla === dadosContribuinte.est_sigla) {
                        cfoCfop = "5152";
                    } else {
                        cfoCfop = "6152";
                    }
                } else {
                    if (dadosEndereco.est_sigla === dadosContribuinte.est_sigla) {
                        cfoCfop = "5151";
                    }
                    else {
                        cfoCfop = "6151";
                    }
                }
            } else if (dadosProduto.cst_id === "10" || dadosProduto.cst_id === "30" || dadosProduto.cst_id === "60" || dadosProduto.cst_id === "70") {
                if (dadosContribuinte.loc_tipocontribuinte === 1 || dadosContribuinte.loc_tipocontribuinte === 4) {
                    if (dadosEndereco.est_sigla === dadosContribuinte.est_sigla) {
                        cfoCfop = "5409";
                    } else {
                        cfoCfop = "6409";
                    }
                } else {
                    if (dadosEndereco.est_sigla === dadosContribuinte.est_sigla) {
                        cfoCfop = "5408";
                    }
                    else {
                        cfoCfop = "6408";
                    }
                }
            }
        } else {
            if (dadosProduto.cso_id === "101" || dadosProduto.cso_id === "102" || dadosProduto.cso_id === "103" || dadosProduto.cso_id === "300" || dadosProduto.cso_id === "400" || dadosProduto.cso_id === "900") {
                if (dadosContribuinte.loc_tipocontribuinte === 1 || dadosContribuinte.loc_tipocontribuinte === 4) {
                    if (dadosEndereco.est_sigla === dadosContribuinte.est_sigla) {
                        cfoCfop = "5152";
                    }
                    else {
                        cfoCfop = "6152";
                    }
                }
                else {
                    if (dadosEndereco.est_sigla === dadosContribuinte.est_sigla) {
                        cfoCfop = "5151";
                    }
                    else {
                        cfoCfop = "6151";
                    }
                }
            } else if (dadosProduto.cso_id === "201" || dadosProduto.cso_id === "202" || dadosProduto.cso_id === "203" || dadosProduto.cso_id === "500") {
                if (dadosContribuinte.loc_tipocontribuinte === 1 || dadosContribuinte.loc_tipocontribuinte === 4) {
                    if (dadosEndereco.est_sigla === dadosContribuinte.est_sigla) {
                        cfoCfop = "5409";
                    }
                    else {
                        cfoCfop = "6409";
                    }
                }
                else {
                    if (dadosEndereco.est_sigla === dadosContribuinte.est_sigla) {
                        cfoCfop = "5408";
                    }
                    else {
                        cfoCfop = "6408";
                    }
                }
            }
        }

        dadosCfop.map((cfop) => {
            if (cfoCfop === cfop.cfo_cfop) {
                form.setFieldsValue({ nfi_cfop: cfop.cfo_cfop });
            }
        });
    };

    function popularDadosContribuintes(callback) {
        api.get(`dadosContribuinte/Buscar`).then(
            (res) => {
                if (res.status === 200) {
                    setDadosContribuinte(res.data);
                    if (callback) {
                        callback();
                    }
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function alterarValorTotal() {
        let total = 0;
        total = form.getFieldValue().quantidade * form.getFieldValue().valorUnitario;
        form.setFieldsValue({ valorTotalItem: total.toFixed(2) });
    };

    async function buscarProduto(key) {
        let produtos = [...listaItens];
        let produto = produtos.filter((produto) => (produto.key === key))[0];
        let custo = !!dadosNfeTransferencia.dadosNfeTransferencia && !!dadosNfeTransferencia.dadosNfeTransferencia.custo ? dadosNfeTransferencia.dadosNfeTransferencia.custo.key : 1;
        let params = `&apenasAtivas=true&tipoCusto=${custo}`;
        let grade = await api.get(`ProdutoGrade/ListarGradesProdutos?codigoProduto=${produto.pro_id}${params}`);
        buscarDadosProdutoNotaTransf(false);

    };

    function salvarDadosGradeModal() {
        let produtosSelec = [...dadosTabela.dadosItens];
        let produtoGrade = dadosGrade.filter((produto) => { return produto.quantidade > 0; });
        produtoGrade.map((item) => {
            let dados = { ...dadosProduto };
            adicionarItemNotaTransferencia(false, dados, item, null, () => { produtosSelec.push(dados) });
        });
        dadosTabela.setDadosItens(produtosSelec);
        cancelar();
    };

    function abrirEditarItem(prodSelecionado) {
        setExibirDetalhes(true);
        let itemDaLista = [...dadosTabela.dadosItens].find(p => p.pro_id == prodSelecionado.pro_id && (prodSelecionado.prg_id == null || prodSelecionado.prg_id == p.prg_id))
        form.setFieldsValue({ quantidade: itemDaLista.nfi_qtde });
        form.setFieldsValue({ valorUnitario: itemDaLista.nfi_valorunitario });
        form.setFieldsValue({ valorTotalItem: itemDaLista.total });
        form.setFieldsValue({ nfi_cfop: itemDaLista.nfi_cfop });
        form.setFieldsValue({ icm_id: itemDaLista.icm_id });
        form.setFieldsValue({ prg_id: itemDaLista.prg_id });
        form.setFieldsValue({ informacaoAdicional: itemDaLista.nfi_informacaoadicional });
        api.get(`TributacaoIcms/ListarTributacaoIcmsOrigemDestinoProduto?codigoProduto=${prodSelecionado.pro_id}&ufDestino=${dadosEndereco.est_sigla}&ufOrigem=${dadosContribuinte.est_sigla}`).then(
            (tributacaoIcms) => {
                if (tributacaoIcms.status === 200) {
                    setTributacaoIcms(tributacaoIcms.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
        let dadosTemp = {};
        dadosTemp.prg_id = prodSelecionado.prg_id;
        dadosTemp.pro_descricao = prodSelecionado.pro_descricao;
        dadosTemp.pro_codigo = prodSelecionado.pro_codigo;
        dadosTemp.pro_ean = prodSelecionado.pro_ean;
        dadosTemp.prg_ean = prodSelecionado.prg_ean;
        dadosTemp.ncm_codigo = prodSelecionado.ncm_codigo;
        dadosTemp.prg_ean = prodSelecionado.prg_ean;
        dadosTemp.descricaograde = prodSelecionado.descricaograde;
        dadosTemp.ump_id = prodSelecionado.ump_id;
        setDadosDetalhes(dadosTemp);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        return;
    };

    function adicionarItem() {
        let dadosTemp = [...dadosTabela.dadosItens];
        let dados = {};
        if (dadosTemp.find(campo => (campo.pro_id === form.getFieldValue().pro_id || campo.pro_codigo === form.getFieldValue().pro_codigo)
            && ((!!form.getFieldValue().prg_id && form.getFieldValue().prg_id === campo.prg_id) || !form.getFieldValue().prg_id))) {
            if (!!form.getFieldValue().nfi_cfop && !!form.getFieldValue().icm_id) {
                if (!!form.getFieldValue().prg_id) {
                    dados = dadosTemp.find(campo => campo.prg_id === form.getFieldValue().prg_id);
                } else {
                    dados = dadosTemp.find(campo => campo.pro_id === form.getFieldValue().pro_id || campo.pro_codigo === form.getFieldValue().pro_codigo);
                }
                adicionarItemNotaTransferencia(true, dados);
                if (!dadosTemp[0].nfi_valorfrete || !dadosTemp[0].nfi_valoroutros) {
                    validarTransporte(dadosTemp);
                    dadosTabela.setDadosItens(dadosTemp);
                }
            } else {
                notification.warning({ message: 'Aviso', description: 'Campo CFOP e Tributação ICMS são obrigatórios!' });
                return;
            }
        } else {
            if (!!form.getFieldValue().nfi_cfop && !!form.getFieldValue().icm_id) {
                dados = { ...dadosProduto };
                adicionarItemNotaTransferencia(false, dados, null, dados.prg_id, () => {
                    dadosTemp.push(dados);
                    if (!dadosTemp[0].nfi_valorfrete || !dadosTemp[0].nfi_valoroutros) {
                        validarTransporte(dadosTemp);
                        dadosTabela.setDadosItens(dadosTemp);
                    }
                });
            } else {
                notification.warning({ message: 'Aviso', description: 'Campo CFOP e Tributação ICMS são obrigatórios!' });
                return;
            }
        }
        setExibirDetalhes(false);
        setDesabilitarProd_id(false);
        limparCampos();
    };

    function cancelar() {
        setExibirDetalhes(false);
        setDadosDetalhes({});
        setDesabilitarProd_id(false);
        limparCampos();
    };

    function limparCampos() {
        form.setFieldsValue({ pro_codigo: null, pro_id: null, percdesc: 0, desconto: 0, percacresc: 0, acrescimo: 0, nfi_cfop: null, icm_id: null });
    };

    async function adicionarItemNotaTransferencia(editar = false, prodNota, dadosGrade, prg_id, callback) {
        let formulario = form.getFieldValue();
        if (!editar) {
            let produtos = [...listaItens];
            let produto = produtos.filter((produto) => (produto.key === formulario.pro_id))[0];
            prodNota.nfi_ean = !!produto.prg_ean ? produto.prg_ean : produto.pro_ean;
            prodNota.nfi_unidademedida = produto.ump_id;
            prodNota.pro_codigo = produto.pro_codigo;
            prodNota.pro_descricao = produto.pro_descricao;
            prodNota.pro_ean = produto.pro_ean;
            prodNota.grade = produto.descricaograde;
            prodNota.ump_id = produto.ump_id;
            prodNota.ump_casasdecimais = produto.ump_casasdecimais;
            prodNota.prg_id = produto.prg_id;
        }
        if (!(!!prodNota.icm_id) || prodNota.icm_id !== formulario.icm_id) {
            let icms = tributacaoIcms.filter((icms) => (icms.icm_id === formulario.icm_id))[0];
            prodNota.icm_id = icms.icm_id;
            prodNota.icm_descricao = icms.icm_descricao;
            prodNota.cst_id = icms.cst_id;
            prodNota.cso_id = icms.cso_id;
        }
        if (!(!!prodNota.cfo_cfop) || prodNota.nfi_cfop !== formulario.nfi_cfop) {
            let cfop = dadosCfop.filter((cfop) => (cfop.cfo_cfop === formulario.nfi_cfop))[0];
            prodNota.nfi_cfop = cfop.cfo_cfop;
            prodNota.cfo_cfop = cfop.cfo_cfop;
            prodNota.cfo_descricao = cfop.cfo_descricao;
            prodNota.cfo_id = cfop.cfo_id;
        }
        prodNota.nfi_qtde = formulario.quantidade;
        prodNota.nfi_valorunitario = parseFloat(!!formulario.valorUnitario ? formulario.valorUnitario : 0);
        prodNota.quantidade = formulario.quantidade;
        prodNota.vlr_valorunitario = parseFloat(!!formulario.valorUnitario ? formulario.valorUnitario : 0);
        prodNota.total = parseFloat(prodNota.nfi_qtde * prodNota.nfi_valorunitario).toFixed(2);
        prodNota.percdesc = parseFloat(!!formulario.percdesc ? formulario.percdesc : 0);
        prodNota.nfi_valordesconto = parseFloat(!!formulario.desconto ? formulario.desconto : 0);
        prodNota.percacresc = parseFloat(!!formulario.percacresc ? formulario.percacresc : 0);
        prodNota.nfi_valoroutros = parseFloat(!!formulario.acrescimo ? formulario.acrescimo : 0);
        prodNota.nfi_numeroitem = !!prodNota.nfi_numeroitem ? prodNota.nfi_numeroitem : dadosTabela.dadosItens.length + 1;
        prodNota.nfi_valorfrete = 0;
        prodNota.nfi_valorseguro = 0;
        let valorCalculadoIPI = 0;
        let valorCalculadoIMS = 0;
        let ipi = null;
        let icms = null;
        let diferimento = null;
        let icmst = null;
        let possuiIe = !!formulario.pej_inscricaoestadual ? true : false;
        let impostos = await CalcularImpostoItem(
            prodNota.orm_id, prodNota.cst_id, prodNota.cso_id, prodNota.iod_uforigem, prodNota.iod_ufdestino,
            prodNota.quantidade, prodNota.vlr_valorunitario, prodNota.nfi_valorfrete, prodNota.nfi_valoroutros, prodNota.nfi_valordesconto, prodNota.iod_percicms,
            prodNota.iod_percdiferimentoicms, prodNota.iod_percicmsreducaobc, prodNota.mva_percaliquotaicms, prodNota.mva_percmva, prodNota.mva_percaliquotaicmsreducao,
            prodNota.mva_ajustado, prodNota.ncm_percentual,
            2, //pessoa juridica transferencia
            0, // não é consumidor final
            possuiIe,
            prodNota.ope_indicadorpresenca
        );

        ipi = impostos.retornoIPI;
        icms = impostos.retornoICMS;
        diferimento = impostos.retornoDiferimento;
        icmst = impostos.retornoICMSST;
        prodNota.npi_valorbcipi = !!ipi ? ipi.baseIPI : 0;
        //prodNota.npi_percaliquotaipi = prodNota.ncm_percentual * 100;
        prodNota.npi_percaliquotaipi = prodNota.ncm_percentual;
        prodNota.npi_valoripi = !!ipi ? ipi.valorIPI : 0;
        prodNota.nig_valorbc = !!icms ? icms.baseICMS : 0;

        prodNota.nig_valorIcms = !!icms ? icms.ret_valorIcms : 0;

        prodNota.nig_percicms = prodNota.iod_percicms;
        prodNota.nig_percreducaobc = prodNota.iod_percicmsreducaobc;
        prodNota.nig_percmvast = prodNota.mva_percmva;
        prodNota.nig_percreducaobcst = prodNota.mva_percaliquotaicmsreducao;
        prodNota.nig_valorbcst = !!icmst ? icmst.baseICMSST : 0;
        prodNota.nig_percicmsst = prodNota.mva_percaliquotaicms;
        prodNota.nig_valoricmsst = !!icmst ? icmst.ICMSST : 0;
        prodNota.nig_valoricmsoperacao = !!icms ? icms.ICMS : 0;
        prodNota.nig_percicmsdiferimento = prodNota.iod_percdiferimentoicms;
        prodNota.nig_valoricmsdiferimento = !!diferimento ? diferimento.valorICMSDiferido : 0;
        if (editar) {
            prodNota.nfi_informacaoadicional = prodNota.informacaoAdicional;
        } else {
            prodNota.nfi_informacaoadicional = !!prodNota.grade ? prodNota.grade : "" + " " + (!!prodNota.nfi_informacaoadicional ? prodNota.nfi_informacaoadicional : "");
        }
        if (!!callback) {
            callback();
        }
    };

    function error(mensagem, title = 'Atenção!') {
        Modal.error({
            title: title,
            content: (
                <div>
                    <p>{mensagem}</p>
                </div>
            )
        });
    }

    async function buscarProdutoCodBarra(campo) {
        let codigoEan = campo.target.value.trim();
        let dados = await api.get(`Produto/ListaProdutoFiltro?filtro=${codigoEan}`);
        if (!!dados.data && dados.data.items.length > 0) {
            form.setFieldsValue({ pro_id: dados.data.items[0].pro_id });
            adicionarItemLeitorCodBarras(dados.data.items[0]);
        }
        if (!!codBarras && !!codBarras.current) {
            codBarras.current.focus();
        }
    };

    async function adicionarItemLeitorCodBarras(dadosProduto) {
        let formulario = form.getFieldValue();
        let dados = {};
        let dadosGrade = {};
        let dadosTemp = [...dadosTabela.dadosItens];
        let custo = !!dadosNfeTransferencia.dadosNfeTransferencia && !!dadosNfeTransferencia.dadosNfeTransferencia.custo ? dadosNfeTransferencia.dadosNfeTransferencia.custo.key : 1;
        let destinoOperacao = (!!dadosEndereco.est_sigla && !!dadosContribuinte.est_sigla && (dadosEndereco.est_sigla === dadosContribuinte.est_sigla) ? '1' : '2');
        let parametros = `IdProduto=${dadosProduto.pro_id}&destinoOperacao=${destinoOperacao}&empresaEnquadramento=${dadosContribuinte.emp_regimetributario}`;
        parametros += `&tipoContribuinte=${dadosContribuinte.loc_tipocontribuinte}&tipoCusto=${custo}&ufDestino=${dadosEndereco.est_sigla}&ufOrigem=${dadosContribuinte.est_sigla}`;
        parametros += !!dadosProduto.prg_id ? `&codigoProdutoGrade=${dadosProduto.prg_id}` : '';
        await api.get(`Produto/BuscarDadosProdutoTransferencia?${parametros}`).then(
            res => {
                if (res.status === 200) {
                    let dadostributacao = res.data;
                    if ((!!dadostributacao.icm_id === false) || (!!dadostributacao.ncm_id === false) || (!!dadostributacao.ump_id === false) || (!!dadosProduto.pro_descricao === false)) {
                        setAbrirModalManutencaoNota(true);
                    } else if ((!!dadostributacao.iod_ufdestino) === false) {
                        setAbrirModalManutencaoNota(true);
                    } else {
                        setarCfopCorrespondenteTributacaoICMS(dadostributacao);
                    }
                    if (!!dadosProduto.prg_id) {
                        api.get(`ProdutoGrade/ListarGradesProdutos?apenasAtivas=true&codigoProduto=${dadosProduto.pro_id}&produtoGradeId=${dadosProduto.prg_id}&tipoCusto=${custo}`).then(
                            resGrade => {
                                if (resGrade.status === 200) {
                                    dadosGrade = resGrade.data;
                                }
                            }
                        ).catch(error => console.log(error));
                    }

                    dados = dadosProduto;
                    dados.orm_id = dadostributacao.orm_id;
                    dados.iod_uforigem = dadosContribuinte.est_sigla;
                    dados.iod_ufdestino = dadosEndereco.est_sigla;
                    dados.icm_id = dadostributacao.icm_id;
                    dados.icm_descricao = dadostributacao.icm_descricao;
                    dados.cst_id = dadostributacao.cst_id;
                    dados.ncm_id = dadostributacao.ncm_id;
                    dados.ncm_codigo = dadostributacao.ncm_codigo;
                    dados.cso_id = dadostributacao.cso_id;
                    dados.nfi_cfop = dadostributacao.cfo_cfop;
                    dados.cfo_cfop = dadostributacao.cfo_cfop;
                    dados.cfo_descricao = dadostributacao.cfo_descricao;
                    dados.cfo_id = dadostributacao.cfo_id;
                    dados.nfi_ean = dadosProduto.pro_ean == null ? dadosProduto.prg_ean : dadosProduto.pro_ean;
                    dados.pro_ean = dadosProduto.pro_ean == null ? "" : dadosProduto.pro_ean;
                    dados.nfi_unidademedida = dadosProduto.ump_id;
                    dados.nfi_qtde = 1;
                    dados.nfi_valorunitario = dadostributacao.pre_custoultimacompra;

                    dados.total = parseFloat(dados.nfi_qtde * dados.nfi_valorunitario).toFixed(2);
                    dados.percdesc = parseFloat(!!formulario.percdesc ? formulario.percdesc : 0);
                    dados.nfi_valordesconto = parseFloat(!!formulario.desconto ? formulario.desconto : 0);
                    dados.percacresc = parseFloat(!!formulario.percacresc ? formulario.percacresc : 0);
                    dados.nfi_valoroutros = parseFloat(!!formulario.acrescimo ? formulario.acrescimo : 0);
                    dados.nfi_numeroitem = !!dadosTemp.nfi_numeroitem ? dadosTemp.nfi_numeroitem : dadosTemp.length + 1;
                    dados.nfi_valorfrete = 0;
                    dados.nfi_valorseguro = 0;
                    dados.nfi_informacaoadicional = dadosProduto.descricaograde == null ? "" : dadosProduto.descricaograde;
                    dados.grade = dadosProduto.descricaograde;
                    dadosTemp.push(dados);

                    setExibirDetalhes(false);
                    dadosTabela.setDadosItens(dadosTemp);
                    setDesabilitarProd_id(false);
                    limparCampos();
                }
            }
        ).catch(error => console.log(error));
    }

    function aoSalvarModalManutencaoNota() {
        buscarDadosProdutoNotaTransf(false);
    };

    return (
        <div>
            <div>
                <Row gutter={[8, 16]}>
                    {!!consultaProduto === false &&
                        <Col xs={21} sm={21} md={21} lg={22} xl={22} xxl={23}>
                            <Form.Item label="Selecione o Produto" name="pro_id">
                                <SelectProduto disabled={desabilitarProd_id} valueKey={true} detalhes={true}
                                    placeholder="Selecione o Produto"
                                    name="pro_id" form={form}
                                    onChange={(dados) => { buscarProduto(dados) }}
                                    ordem={"pro_descricao"} setListaDados={setListaItens} />
                            </Form.Item>
                        </Col>
                    }
                    {!!consultaProduto &&
                        <Col xs={21} sm={21} md={21} lg={22} xl={22} xxl={23}>
                            <Form.Item label="Localize o Produto" name="pro_codigo">
                                <Input ref={codBarras} onPressEnter={(value) => {
                                    buscarProdutoCodBarra(value)
                                }} placeholder="Informe o Produto/Mercadoria" />
                            </Form.Item>
                        </Col>
                    }
                    <Col xs={3} sm={3} md={3} lg={2} xl={2} xxl={1} className="i-button">
                        <Tooltip title={!!consultaProduto ? 'Localize produto por EAN' : 'Selecione o produto'} placement="left">
                            <Button onClick={togglePesquisa} block>
                                {consultaProduto ? <SearchOutlined /> : <BarcodeOutlined />}
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </div>
            {exibirDetalhes &&
                <div className="m-t-16">
                    <Card title={
                        <Row justify="start" gutter={[8, 0]}>
                            <Col>
                                Produto: <b>{dadosDetalhes.pro_descricao} {!!dadosDetalhes.descricaograde ? ' - ' + dadosDetalhes.descricaograde : ''}</b>
                            </Col>
                            <Col>
                                Código Interno: <b>{dadosDetalhes.pro_codigo}</b>
                            </Col>
                            <Col>
                                Código de Barras: <b>{!!dadosDetalhes.prg_ean ? dadosDetalhes.prg_ean : dadosDetalhes.pro_ean}</b>
                            </Col>
                            <Col>
                                NCM: <b>{dadosDetalhes.ncm_codigo}</b>
                            </Col>
                        </Row>
                    }>
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={10} md={10} lg={10} xl={10} xxl={10}>
                                <Form.Item label="Tributação ICMS" name="icm_id" rules={[{ required: true, message: 'Selecione uma Tributação ICMS' }]}>
                                    <Select placeholder="Selecione uma Tributação ICMS">
                                        {tributacaoIcms.map((icms) => (
                                            <Select.Option value={icms.icm_id} key={icms.icm_id}>{icms.icm_descricao}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={14} md={14} lg={14} xl={14} xxl={14}>
                                <Form.Item label="CFOP - Código Fiscal de Operação" name="nfi_cfop" rules={[{ required: true, message: 'Informe o CFOP' }]}>
                                    <Select placeholder="Selecione um CFOP">
                                        {dadosCfop.map((cfop) => (
                                            <Select.Option value={cfop.cfo_cfop} key={cfop.cfo_id}>{cfop.cfo_cfop} - {cfop.cfo_descricao}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item hidden label="Id da Grade do Produto" name="prg_id">
                            <Input></Input>
                        </Form.Item>
                        <Row justify="end" gutter={[8, 0]}>
                            <Col xs={24} sm={4} md={4} lg={4} xl={4} xxl={3}>
                                <Form.Item label="Quantidade" name="quantidade">
                                    <InputNumber placeholder="Informe a Quantidade do Produto" onChange={() => { alterarValorTotal() }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={5} md={5} lg={5} xl={5} xxl={4}>
                                <InputPreco label="Valor Unitário (R$)" name="valorUnitario" onChange={() => { alterarValorTotal() }} />
                            </Col>
                            <Col xs={24} sm={5} md={5} lg={5} xl={5} xxl={4}>
                                <InputPreco label="Valor Total do Item (R$)" name="valorTotalItem" disabled={true} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Informações Adicionais do Produto" name="informacaoAdicional">
                                    <Input.TextArea placeholder="Informe as Observações Referente o Produto/Mercadoria" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align="middle" justify="end" gutter={[8, 0]}>
                            <Col>
                                <Button icon={<CloseOutlined />} onClick={() => (cancelar())}>
                                    Cancelar
                                </Button>
                            </Col>
                            <Col>
                                <Button icon={<PlusOutlined />} type="primary" onClick={() => (adicionarItem())}>
                                    Adicionar
                                </Button>
                            </Col>
                        </Row>
                    </Card>
                </div>}
            <ModalGradeProduto exibirModal={abrirModalGrade} fecharModal={() => { setAbrirModalGrade(false); salvarDadosGradeModal() }} dados={dadosGrade} dadosValidacao={dadosNfeTransferencia} />
            <ModalManutencaoNota formulario={form} exibirModal={abrirModalManutencaoNota} fecharModal={() => setAbrirModalManutencaoNota(false)} dadosContribuinte={dadosContribuinte} dadosOperacao={dadosOperacao} dadosEndereco={dadosEndereco} dadosProduto={dadosProduto} aoSalvar={aoSalvarModalManutencaoNota} />
        </div >
    );

}