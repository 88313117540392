import React, { useEffect, useState } from "react";
import { Row, Col, Tag, Popover, Modal, notification } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import DrawerSubGrupo from './drawer';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, Editar, Excluir } from "../../components";

export default function SubGrupo() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [editando, setEditando] = useState(false);

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+sgp_nome' } }), []);

    function excluirRegistro(id) {
        api.delete(`SubGrupo/Excluir/${id}`).then((res) => {
            if (res.status === 200) notification.success({ message: 'Operação concluída', description: "Registro excluído com sucesso!" });
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        }, erro => {
            notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
        }).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+sgp_nome' } });
            });
    }

    function editar(record) {
        setEditando(true);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };
    
    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+sgp_nome" },
                { label: "Nome Z - A", value: "-sgp_nome" },
                { label: "Código Crescente", value: "+sgp_id" },
                { label: "Código Decrescente", value: "-sgp_id" }
            ]} />

            <div className="tabela">
                <TabelaDados url="subGrupo/listar" colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ sgp_id, sgp_ativo }) => (
                                <Popover content={sgp_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                    <Tag color={sgp_ativo ? 'processing' : 'default'} className="w-100">
                                        <b>{sgp_id}</b>
                                    </Tag>
                                </Popover>
                            ),
                        },
                        {
                            title: 'Subgrupos de Produtos',
                            render: ({ sgp_nome }) => (
                                <div>
                                    <b>{sgp_nome}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Grupo',
                            width: '50%',
                            render: ({ grupo }) => (
                                <div>
                                    <b>{grupo?.gru_nome}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 65,
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Editar onClick={() => {
                                                editar(record)
                                            }} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Excluir icon={<DeleteOutlined />} onClick={() => { modalExcluir(record.sgp_id) }} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } />
            </div>

            <DrawerSubGrupo editando={{ editando, setEditando }} cadSubGp={true} />
        </div>
    );

}