import React, { useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import { DrawerNav } from "../../components";
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';
import NFEntrada from '../notaFiscalEntrada/manutencao';

export default function DrawerEntrada({ showDrawer }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [formEntrada] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [tagPages, setTagPages] = useState(null);
    const [adicionarNotas, setAdicionarNotas] = useState(false);
    const [resume, setResume] = useState(false);
    const [proximaPag, setProximaPag] = useState(0);

    function fecharDrawer() {
        formEntrada.resetFields();
        setTagPages(0);
        setProximaPag(null);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ntf_numero' } });
        showDrawer.setShowNFEntrada(false);

    };

    const ActionsController = () => {
        setProximaPag(tagPages - 1)
        setResume(false);
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                setResume(false);
                fecharDrawer();
            }
        });
    };

    return (

        <DrawerNav title="Nota Fiscal de Entrada"
            width="70%"
            visible={showDrawer.showNFEntrada}
            closeIcon={<MinusOutlined />}
            limparAoEditar={true}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={tagPages === 0} hidden={adicionarNotas} icon={<LeftOutlined />} size="large" onClick={() => ActionsController()} type="primary">
                                Voltar
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" hidden={resume || adicionarNotas} size="large" onClick={() => { setProximaPag(tagPages + 1) }}>
                                Avançar <RightOutlined />
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formEntrada.submit()} hidden={!resume || adicionarNotas} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Emitir Nota
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <NFEntrada form={formEntrada} showDrawer={showDrawer?.showNFEntrada} carregando={setCarregando} aoSalvar={fecharDrawer} pages={{ tagPages, setTagPages }}
            proximaPag={{ proximaPag, setProximaPag }} resume={setResume} />
        </DrawerNav>
    )
}