export default function formatFone(valor) {
    let retorno = '';
    if (!!valor) {
        valor = valor.toString();
        if (valor.length >= 8) {
            switch (valor.length) {
                case 8:
                    retorno = valor.slice(0, 4) + '-' + valor.slice(4, 8);
                    break;
                case 9:
                    retorno = valor.slice(0, 1) + ' ' + valor.slice(1, 5) + '-' + valor.slice(5, 9);
                    break;
                case 10:
                    retorno = '(' + valor.slice(0, 2) + ') ' + valor.slice(2, 6) + '-' + valor.slice(6, 10);
                    break;
                case 11:
                    retorno = '(' + valor.slice(0, 2) + ') ' + valor.slice(2, 3) + ' ' + valor.slice(3, 7) + '-' + valor.slice(7, 11);
                    break;
            }
        }
    }
    return retorno;
}