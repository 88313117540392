import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, notification, Modal, Input } from "antd";
import { EditOutlined, DeleteOutlined, EnvironmentOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import DrawerHotel from "./drawer";
import api from '../../services/api';
import { useStateValue } from '../../state';
import { ModalMapa } from "../../components/modals";
import { FormatFone, FormatNumber } from "../../ValueObjects";
import { drawerActions, manutencaoActions, listagemActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados } from "../../components";

export default function Hotel() {

    const [formHotel] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();
    const [openModalMapa, setOpenModalMapa] = useState(false);
    
    function excluirRegistro(id) {
        api.delete(`/Hotel/${id}`).then((res) => {
            notification.success({ message: 'Operação concluída', description: "Registro excluído com sucesso!" });
        }).catch(erro => {
            notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
        }).finally(() => {
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+Descricao' } })
        });
    }

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+hot_id' } });
    }, [])

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+pes_nome" },
                { label: "Nome Z - A", value: "-pes_nome" },
                { label: "Código Crescente", value: "+hot_id" },
                { label: "Código Decrescente", value: "-hot_id" }
            ]} />
            <div className="tabela">
                <TabelaDados url="Hotel/Listar" paginacao={false} colunas={[
                    {
                        title: 'Nome',
                        render: ({ pes_nome }) => (
                            <div>
                                <b>{pes_nome}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Tipo',
                        width: 115,
                        render: ({ hot_tipo }) => (
                            <div>
                                <b>{hot_tipo === 1 ? 'Hotel' : (hot_tipo === 2 ? 'Airbnb' : 'Não Informado')}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Telefone',
                        width: 110,
                        render: ({ psc_numero }) => (
                            <div>
                                <b>{FormatFone(psc_numero) || 'Não Informado'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Endereço',
                        render: ({ log_logradouro, bai_nome, cid_descricao, pee_numero, est_sigla }) => (
                            <div>
                                <b>{log_logradouro + `, ${pee_numero}` + `, ${bai_nome}` + `, ${cid_descricao}` + `, ${est_sigla}`}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Valor Standard (R$)',
                        align: 'right',
                        width: 130,
                        render: ({ hot_preco_standard }) => (
                            <div>
                                <b>{FormatNumber(!!hot_preco_standard ? hot_preco_standard : 0, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Valor Duplo (R$)',
                        align: 'right',
                        width: 130,
                        render: ({ hot_preco_duplo }) => (
                            <div>
                                <b>{FormatNumber(!!hot_preco_duplo ? hot_preco_duplo : 0, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Valor Triplo (R$)',
                        align: 'right',
                        width: 130,
                        render: ({ hot_preco_triplo }) => (
                            <div>
                                <b>{FormatNumber(!!hot_preco_triplo ? hot_preco_triplo : 0, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        width: 95,
                        align: 'center',
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<EnvironmentOutlined />} onClick={() => setOpenModalMapa(true)} />
                                    </Col>
                                    <Col>
                                        <Button icon={<EditOutlined />} onClick={() => {
                                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                            dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                        }} />
                                    </Col>
                                    <Col>
                                        <Button icon={<DeleteOutlined />} onClick={() => { modalExcluir(record.id) }} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <ModalMapa exibirModalMapa={openModalMapa} fecharModalMapa={() => setOpenModalMapa(false)} />
            <DrawerHotel />
        </div>
    );

}