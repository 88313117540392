import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tag, Popover, notification, Modal } from "antd";
import { EditOutlined, DeleteOutlined, CopyOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import DrawerTributacaoIcms from './drawer';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, Editar, Excluir } from "../../components";

export default function TributacaoIcms() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+icm_descricao' } }), []);

    function excluirRegistro(item) {
        setCarregando(true);
        api.delete(`tributacaoIcms/Excluir/${item.icm_id}`).then((res) => {
            if (res.status === 200) notification.success({ message: 'Operação concluída', description: "Os dados do ICMS foram excluídos com sucesso!" });
            dispatch({ type: listagemActions.CHANGE, data: { label: "Nome Z - A", ordem: '+icm_descricao' } })
        }).catch(err => {
            modalInativarConta(item);
        }).finally(() => {
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+icm_descricao' } });
        });
    }

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    const modalInativarConta = (dados) => {
        Modal.confirm({
            title: `Não foi possível excluir a Tributação ICMS ${dados.icm_descricao}!`,
            icon: <CloseCircleOutlined />,
            content: `Deve conter referências com outros registros!`,
            okText: 'Inativar Registro',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                console.log(dados);
                dados.inativar = 1;
                dados.icm_ativo = false;
                api.put(`tributacaoIcms/editar`, dados).then((res) => {
                    if (res.status === 200) {
                        notification.success({ message: 'Operação concluída', description: "Tributação inativada com sucesso!" });
                    }
                    dispatch({ type: listagemActions.CHANGE, data: { label: "Nome Z - A", ordem: '+icm_descricao' } })
                }, err => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
                }).finally(() => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+icm_descricao' } });
                });
            }
        });
    };

    const modalDuplicarDados = (dados) => {
        Modal.confirm({
            title: 'Duplicar?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja duplicar este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                dados.duplicar = true;
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
            }
        });
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+icm_descricao" },
                { label: "Nome Z - A", value: "-icm_descricao" },
                { label: "Código Crescente", value: "+icm_id" },
                { label: "Código Decrescente", value: "-icm_id" }
            ]} />
            <div className="tabela">
                <TabelaDados url="tributacaoIcms/listar" colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ icm_id, icm_ativo }) => (
                                <Popover content={icm_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                    <Tag color={icm_ativo ? 'processing' : 'default'} className="w-100">
                                        <b>{icm_id}</b>
                                    </Tag>
                                </Popover>
                            ),
                        },
                        {
                            title: 'Tributações do ICMS',
                            render: ({ icm_descricao }) => (
                                <div>
                                    <b>{icm_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 95,
                            fixed: 'right',
                            render: (record, dados) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>                                        
                                        <Col>
                                            <Button icon={<CopyOutlined />} onClick={() => { modalDuplicarDados(dados) }}/>
                                        </Col>
                                        <Col>
                                            <Editar onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Excluir icon={<DeleteOutlined />} onClick={() => modalExcluir(record)} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } />
            </div>
            <DrawerTributacaoIcms />
        </div>
    );

}