import React, { useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";
import moment from "moment";

import { useStateValue } from '../../state';
import { DrawerNav } from '../../components';
import ManutencaoOrdemCompra from './manutencao';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';

export default function DrawerOrdemCompra({ carregarDados, alteraData, fornecedor, transportador }) {

    const [formOrdemCompra] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    function fecharDrawer() {
        formOrdemCompra.resetFields();
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } }); 
        alteraData();
        carregarDados();
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (

        <DrawerNav title="Ordem de Compra"
            width="70%"
            limparAoEditar={true}
            fecharDrawer={() => {fecharDrawer()}}
            visible={ui.showDrawer}
            closeIcon={<MinusOutlined />}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formOrdemCompra.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoOrdemCompra formulario={formOrdemCompra} carregando={setCarregando} aoSalvar={fecharDrawer} fornecedor={fornecedor} transportador={transportador}/>
        </DrawerNav>

    )
}