import React, { useEffect, useState } from "react";
import { Row, Col, Tooltip, Form, Input, Select, Button, Collapse, Typography, Tag, Dropdown, Menu, Alert, notification, Modal, InputNumber } from "antd";
import { DownloadOutlined, CloseOutlined, QuestionCircleOutlined, ArrowDownOutlined, ArrowUpOutlined, SearchOutlined, FileProtectOutlined, ExclamationCircleOutlined, MoreOutlined, CheckOutlined, ReloadOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from 'moment';

import api from "../../services/api";
import { useStateValue } from "../../state";
import { getLocal } from "../../services/auth";
import { listagemActions } from "../../actions";
import { FormatNumber, MaskFormat } from "../../ValueObjects";
import { ModalManifestoDestinatario } from "../../components/modals/";
import { BreadcrumbAbrirChamado, TabelaDados, Data } from "../../components";
import { retornaSoNumeros } from "../../services/funcoes";

export default function MonitoramentoNfe() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [ambiente, setAmbiente] = useState(null);
    const [cnpj, setCnpj] = useState(null);
    const [tipoNfe, setTipoNfe] = useState([]);
    const [situacaoNfe, setSituacaoNfe] = useState([]);
    const [dadosNota, setDadosNota] = useState([]);
    const [formulario] = Form.useForm();
    const dataAtual = new Date();

    const togglePesquisa = () => {
        setOpen(!open);
    };

    const menuTable = (record) => (
        <Menu className="text-right">
            <Menu.Item key="1">
                <Button type="text" icon={<FileProtectOutlined />} onClick={() => {
                    let dados = { ...record };
                    dados.tipoNf = tipoNfe[dados.nfd_tipodocumento].value;
                    dados.tipoManifesto = (!!dados.nfd_manifestodestinatario || dados.nfd_manifestodestinatario === 0) ? 1 : 0;
                    setDadosNota(dados);
                    setOpenModal(true);
                }}> Manifestar </Button>
            </Menu.Item>
            <Menu.Item key="2">
                <Button type="text" icon={<DownloadOutlined />} onClick={() => importarNfe(record)}> Importar Nota Fiscal </Button>
            </Menu.Item>
        </Menu>
    );

    const menuOpcoes = () => (
        <Menu className="text-right">
            <Menu.Item key="1">
                <Button type="text" icon={<ReloadOutlined />} onClick={() => verificarNotas()}> Verificar Notas </Button>
            </Menu.Item>
            <Menu.Item key="2">
                <Link to="/importarNotaFiscalEntrada"> <CloudUploadOutlined /> Importar XML </Link>
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        limparFiltros();
    }, []);

    function verificarLocal() {
        api.get(`Local/BuscarLocal`).then(function (local) {
            if (local.status === 200) {
                setCnpj(local.data.loc_cnpj.numero);
            }
        });
    };
    function limparFiltros() {
        formulario.setFieldsValue({ dataEmissao: moment(new Date(dataAtual.getFullYear(), dataAtual.getMonth() - 1, 1)), dataMaxEmissao: moment(new Date()), nsuEspecifico: "" });

        listarTipoNfe();
        listarSituacaoNfe();
        verificarAmbiente();
        verificarLocal();
        listarNotas();
    };
    function verificarAmbiente() {
        api.get(`Nfe/VerificarAmbiente?codigoLocal=${getLocal()}`).then(function (retornoAmbiente) {
            if (retornoAmbiente.status === 200) {
                setAmbiente(retornoAmbiente.data.lcn_ambienteemissao);
                formulario.setFieldsValue({ nsuUltimo: retornoAmbiente.data.lcn_monitoramentonsuproducao });
            }
        });
    };

    const verificarNotas = () => {
        api.get(`Nfe/Monitoramento`).then(res => {
            if (res.status === 200) {
                if (res.data.retorno.cStat === 138) {
                    notification.success({ description: res.data.retorno.xMotivo, message: `Operação Realizada com Sucesso!` });
                } else {
                    notification.error({ description: res.data.retorno.xMotivo, message: `SEFAZ Informa !` });
                }
                listarNotas();
            }
        }).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function listarTipoNfe() {
        api.get(`Enum/Listar?nome=TipoNFe`).then(function (res) {
            if (res.status === 200) {
                setTipoNfe(res.data);
                let tipoNf = [];
                res.data.forEach((item) => {
                    tipoNf.push(item.key.toString());
                });
                formulario.setFieldsValue({ tipoNf: tipoNf });
            }
        });
    };

    function listarSituacaoNfe() {
        api.get(`Enum/Listar?nome=SituacaoNfe`).then(function (res) {
            if (res.status === 200) {
                setSituacaoNfe(res.data);
                let situacaoNf = [];
                res.data.forEach((item) => {
                    situacaoNf.push(item.key.toString());
                });
                formulario.setFieldsValue({ situacaoNf: situacaoNf });
            }
        });
    };

    function listarNotas() {
        if (!!formulario.getFieldValue().pesquisa) {
            let modelo = "";
            let chaveAcesso = retornaSoNumeros(formulario.getFieldValue().pesquisa);
            if (chaveAcesso.length === 44)
                modelo = chaveAcesso.substring(20, 22);
            if (modelo !== "55")
                notification.error({ description: "Chave de acesso não é de um documento fiscal modelo 55 NF-e!", message: "Atenção!" })
        }
        let filtros = `&SituacaoNf=${(!!formulario.getFieldValue().situacaoNf ? formulario.getFieldValue().situacaoNf.join(',') : '')}
        &Tipodocumento=${(!!formulario.getFieldValue().tipoNf ? formulario.getFieldValue().tipoNf.join(',') : '')}`;
        filtros += (!!formulario.getFieldValue().pesquisa ? `&Filtro=${formulario.getFieldValue().pesquisa}` : '');
        filtros += (!!formulario.getFieldValue().nsuEspecifico ? `&NsuEspecifico=${formulario.getFieldValue().nsuEspecifico}` : '');
        filtros += (!!formulario.getFieldValue().dataEmissao ? `&DataEmissao=${formulario.getFieldValue().dataEmissao.format('YYYY-MM-DD')}` : '');
        filtros += (!!formulario.getFieldValue().dataMaxEmissao ? `&DataMaxEmissao=${formulario.getFieldValue().dataMaxEmissao.format('YYYY-MM-DD')}` : '');

        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-nfd_datahoraemissao', outrosFiltros: filtros } });
    };


    function importarNfe(notaFiscal) {
        if (notaFiscal.nfd_manifestodestinatario === 0 || notaFiscal.nfd_manifestodestinatario === 1) {
            importarNotaFiscalEletronica(notaFiscal.nfd_chavenfe);
        } else {
            Modal.confirm({
                title: `Atenção!`,
                icon: <ExclamationCircleOutlined />,
                content: `Esta nota fiscal não possui nenhuma manifestação de destinatário realizada. Para realizar o download da NF-e a manifestação é obrigatória. Deseja realizar a CIÊNCIA DA EMISSÃO automaticamente e continuar com o processo de importar a nota selecionada?`,
                okText: 'Sim, realizar manifestação e importar!',
                cancelText: 'Não, cancelar operação.',
                width: 550,
                onOk() {
                    api.post(`NotaFiscal/ManifestarNfe?TipoManifesto=0&ChaveNfe=${notaFiscal.nfd_chavenfe}&justificativa=`).then(
                        (res) => {
                            if (res.data.retorno.cStat === 128) {
                                if (res.data.retorno.retEvento[0].infEvento.cStat === 128 || res.data.retorno.retEvento[0].infEvento.cStat === 135) {
                                    importarNotaFiscalEletronica(notaFiscal.nfd_chavenfe);
                                }
                                else {
                                    notification.error({ description: res.data.retorno.retEvento[0].infEvento.xMotivo, message: `Erro na Manifestação!` });
                                }
                            } else {
                                notification.error({ description: res.data.retorno.xMotivo, message: `Erro na Manifestação!` });
                            }
                        }
                    ).catch(() => {
                        notification.error({ description: 'A manifestação de CIÊNCIA DA EMISSÃO não foi realizada. Desta forma a NF-e não pode ser importada para o sistema.', message: `Erro na Manifestação!` });
                    });
                },
                onCancel() {
                    notification.error({ description: 'O download da nota fiscal não foi realizado, a NF-e não foi importada para o sistema.', message: `Operação Cancelada!` });
                }
            });
        }
    };
    function fecharModal() {
        setOpenModal(false);
        listarNotas();
    }
    function importarNotaFiscalEletronica(chaveNfe) {
        api.post(`Nfe/DownloadNFe?ChaveNfe=${chaveNfe}`).then(
            (res) => {
                if (!!res.data) {
                    localStorage.setItem('@GestorWeb-ChaveImportar', chaveNfe);
                    window.location = "/Home#/importarNotaFiscalEntrada";
                }
            }
        ).catch((erro) => {
            notification.error({ description: `SEFAZ Informa:  Serviço indisponível no momento (${erro.response.data.Message}), Tente novamente mais tarde!`, message: `Download do arquivo XML da NF- e` });
        });
    };

    return (
        <div className="p-10">
            <BreadcrumbAbrirChamado />
            {ambiente === 2 &&
                <Row align="middle" justify="center" gutter={[8, 0]} className="m-b-8">
                    <Col>
                        <Alert message="ATENÇÃO: Emissão de NF-e em Ambiente de Homologação. Notas emitidas em produção não serão listadas" type="error" showIcon />
                    </Col>
                    <Col>
                        <Tooltip title="Notas Fiscais emitidas em ambiente de homologação não tem validade fiscal ou jurídica">
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </Col>
                </Row>
            }
            <div>
                <Form layout="vertical" form={formulario}>
                    <Row gutter={[8, 0]}>
                        <Col xs={20} sm={22} md={22} lg={17} xl={18} xxl={19}>
                            <Form.Item name="pesquisa" label={
                                <div>
                                    Pesquisar por
                                    <Tooltip title="Emissor, CNPJ Emissor, Nº da Nota Fiscal, Chave de Acesso da NF-e">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </div>
                            }>
                                <Input.Search onSearch={valor => listarNotas()} placeholder="Nº da Nota Fiscal / Chave de Acesso da NF-e / Emissor" allowClear />
                            </Form.Item>
                        </Col>
                        <Col xs={4} sm={2} md={2} lg={1} xl={1} xxl={1} className="m-t-19">
                            <Dropdown overlay={() => menuOpcoes()} trigger={['click']}>
                                <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} block className="w-100" />
                            </Dropdown>
                        </Col>
                    </Row>
                    <div className="page">
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Collapse className="p-relative topGrade" accordion ghost destroyInactivePanel={true}>
                                    <div className="colDet"></div>
                                    <Collapse.Panel key="1" showArrow={false} extra={
                                        <Button type="primary" onClick={togglePesquisa} block>
                                            {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                        </Button>
                                    }>
                                        <Row gutter={[8, 0]}>
                                            <Col xs={24} sm={24} md={24} lg={10} xl={6} xxl={6}>
                                                <Form.Item label="Tipo de Nota Fiscal" name="tipoNf">
                                                    <Select
                                                        mode="multiple"
                                                        placeholder="Tipo de Nota Fiscal"
                                                    >
                                                        {tipoNfe.map((item) => (
                                                            <Select.Option key={item.key}>{item.value}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={24} md={24} lg={10} xl={6} xxl={6}>
                                                <Form.Item label="Situação da Nota Fiscal" name="situacaoNf">
                                                    <Select
                                                        mode="multiple"
                                                        placeholder="Situação da Nota Fiscal"
                                                    >
                                                        {situacaoNfe.map((item) => (
                                                            <Select.Option key={item.key}>{item.value}</Select.Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3}>
                                                <Form.Item label="Lote(NSU) Específico" name="nsuEspecifico">
                                                    <InputNumber placeholder="Informe nº nsu específico para pesquisa" />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3}>
                                                <Form.Item label="Último Lote(NSU)" name="nsuUltimo">
                                                    <Input disabled />
                                                </Form.Item>
                                            </Col>
                                            <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3}>
                                                <Data label="Data Inicial" name="dataEmissao" />
                                            </Col>
                                            <Col xs={24} sm={12} md={6} lg={6} xl={3} xxl={3}>
                                                <Data label="Data Final" name="dataMaxEmissao" />
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={24} xxl={24} className="mt-fild">
                                                <Row align="middle" justify="end" gutter={[8, 8]}>
                                                    <Col xs={24} sm={12} md={12} lg={8} xl={3} xxl={3}>
                                                        <Button icon={<CloseOutlined />} block onClick={() => { limparFiltros() }}>
                                                            Remover Filtros
                                                        </Button>
                                                    </Col>
                                                    <Col xs={24} sm={12} md={12} lg={8} xl={3} xxl={3}>
                                                        <Button type="primary" icon={<SearchOutlined />} onClick={listarNotas} block>
                                                            Aplicar Filtros
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
            <div className="m-t-8">
                <Row>
                    <Col span={24}>
                        <Typography.Title level={5}>
                            NOTAS FISCAIS EMITIDAS CONTRA MEU CNPJ - <b>{MaskFormat(cnpj, 'Não Informado', true)}</b>.
                        </Typography.Title>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Typography.Text>
                            Ao configurar sua empresa como emissora de Notas Fiscais Eletrônicas, o <b>GestorWeb realiza um monitoramento diário automaticamente </b>
                            de todas as notas emitidas contra sua empresa. Este monitoramento é realizado de forma automatica todos os dias às 05:45h. Para realizar uma verificação agora,
                            <Button type="link" onClick={() => verificarNotas()}>
                                <u>clique aqui.</u>
                            </Button>
                        </Typography.Text>
                    </Col>
                </Row>
                <div className="tabela mt-dif">
                    <TabelaDados url="NfeDestinada/Listar" paginacao={true} colunas={
                        [
                            {
                                title: 'Nº',
                                align: 'center',
                                width: 100,
                                render: ({ nfd_numeronf }) => (
                                    <div>
                                        <b>
                                            {nfd_numeronf}
                                        </b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Status',
                                width: 100,
                                align: 'center',
                                render: ({ nfd_situacaonfe }) => (
                                    <div>
                                        <Tag color={((nfd_situacaonfe === 0) ? 'default' : (nfd_situacaonfe === 1 ? 'green' : (nfd_situacaonfe === 2 ? 'orange' : (nfd_situacaonfe === 3 ? 'red' : ''))))} className="w-100">
                                            <b>
                                                {!!situacaoNfe[nfd_situacaonfe] ? situacaoNfe[nfd_situacaonfe].value : ''}
                                            </b>
                                        </Tag>
                                    </div>
                                ),
                            },
                            {
                                title: 'Emitente',
                                render: ({ nfd_nomeemissor }) => (
                                    <div>
                                        <b>{nfd_nomeemissor}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'CPF/CNPJ',
                                width: 140,
                                render: ({ nfd_cpfcnpjemissor }) => (
                                    <div>
                                        <b>{MaskFormat(nfd_cpfcnpjemissor, '', true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Data Emissão',
                                width: 140,
                                render: ({ nfd_datahoraemissao }) => (
                                    <div>
                                        <b>{moment(nfd_datahoraemissao).format('DD/MM/YYYY HH:mm:ss')}</b>
                                    </div>
                                ),
                            },
                            {
                                render: ({ nfd_manifestodestinatario }) => (
                                    <Row align="middle" gutter={[16, 0]}>
                                        <Col>
                                            {nfd_manifestodestinatario === 0 &&
                                                <Tag color="processing">
                                                    Ciência da Emissão
                                                </Tag>
                                            }
                                            {nfd_manifestodestinatario === 1 &&
                                                <Tag color="processing">
                                                    Confirmação da Operação
                                                </Tag>
                                            }
                                            {nfd_manifestodestinatario === 2 &&
                                                <Tag color="processing">
                                                    Operação não Realizada
                                                </Tag>
                                            }
                                            {nfd_manifestodestinatario === 3 &&
                                                <Tag color="processing">
                                                    Desconhecimento da Operação
                                                </Tag>
                                            }
                                        </Col>
                                    </Row>
                                ),
                            },
                            {
                                title: 'Total (R$)',
                                fixed: 'right',
                                align: 'right',
                                render: ({ nfd_valortotal }) => (
                                    <div>
                                        <b className="f-18">{FormatNumber(nfd_valortotal, true, false)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                key: 'x',
                                align: 'center',
                                width: 65,
                                fixed: 'right',
                                render: (record) => (
                                    <div>
                                        {record.nfd_situacaonfe === 1 &&
                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                <Col>
                                                    <Dropdown overlay={() => menuTable(record)} trigger={['click']}>
                                                        <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        }
                                    </div>
                                ),
                            }
                        ]} expandir={({ nfd_serienf, nfd_modelodocumento, nfd_tipodocumento, nfd_chavenfe }) => {
                            return (
                                <div>
                                    <Row align="middle" gutter={[16, 0]}>
                                        <Col>
                                            Série: <b>{nfd_serienf}</b>
                                        </Col>
                                        <Col>
                                            Modelo: <b>{nfd_modelodocumento}</b>
                                        </Col>
                                        <Col>
                                            Tipo de Nota: <b>{tipoNfe[nfd_tipodocumento].value}</b>
                                        </Col>
                                        <Col>
                                            Chave da NF-e: <b>{nfd_chavenfe}</b>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }} />
                </div>
            </div>
            <ModalManifestoDestinatario exibirModal={openModal} fecharModal={() => fecharModal()} dadosNota={dadosNota} />
        </div>
    );
}

