import React, { useEffect, useState } from "react";
import { Row, Col, Descriptions, Modal, Tabs } from "antd";
import moment from "moment";

import api from "../../../services/api";
import { FormatNumber } from "../../../ValueObjects";
import { formatarCnpj, formatarCpf } from "../../../services/funcoes";
import { TabItens, TabFormaPagamento } from './tabPage';

export default function ModalDetalheOrcamento({ exibirModal, fecharModal, dadosOrcamento }) {

    const [orcamento, setOrcamento] = useState([]);
    const [abaSelecionada, setAbaSelecionada] = useState("1");

    useEffect(() => {
        if (!!dadosOrcamento?.orc_id) {
            api.get(`Orcamento/Buscar?orc_id=${dadosOrcamento.orc_id}`).then(
                (res) => {
                    setOrcamento(res.data);
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            );
        }
    }, [dadosOrcamento]);

    const funcaoFecharModal = () => {
        setAbaSelecionada("1");
        fecharModal();
    }

    return (
        <Modal centered
            title="Detalhes do Orçamento"
            visible={exibirModal}
            onCancel={funcaoFecharModal}
            footer={null}
            width="50%"
            maskClosable={false}
        >
            <div>
                <Row align="middle">
                    <Col>
                        <Descriptions column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }} size="small">
                            <Descriptions.Item label="Nº">
                                <b>{orcamento.orc_numero}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Cliente">
                                <b>{orcamento.pes_nome ? orcamento.pes_nome : orcamento.orc_nomecliente}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="CPF/CNPJ">
                                <b>{!!orcamento.pef_cpf ? formatarCpf(orcamento.pef_cpf) : orcamento.pej_cnpj ? formatarCnpj(orcamento.pej_cnpj) : "Não Informado"} </b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Data de Inclusão">
                                <b>{!!orcamento.orc_datainclusao ? moment(orcamento.orc_datainclusao).format('DD/MM/YYYY') : "Não Informado"}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Data de Validade">
                                <b>{!!orcamento.orc_datavalidade ? moment(orcamento.orc_datavalidade).format('DD/MM/YYYY') : "Não Informado"}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Vendedor">
                                <b>{orcamento.ven_nome}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Frete (R$)">
                                <b>{FormatNumber(orcamento.orc_valorfrete, true)}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Outras despesas (R$)">
                                <b>{FormatNumber(orcamento.orc_valoroutros, true)}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Total (R$)">
                                <b>{FormatNumber(orcamento.orc_valortotal, true)}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <div className="m-t-8">
                    <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                        <Tabs.TabPane tab="Itens" key="1">
                            <TabItens itens={orcamento.itens} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Formas de Pagamento" key="2">
                            <TabFormaPagamento formaPagamento={orcamento.formasPagamento} />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </Modal>
    );
}