import React, { useEffect } from "react";
import { Col, Form, Row, Modal } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import moment from 'moment';

import Data from "../../data";
import { InputPreco } from "../..";
import { useStateValue } from '../../../state';

export default function ModalEditarVencimentoParcela({ exibirModal, fecharModal, dadosLinha, dadosContasReceberPagar, formulario }) {

    const [formDados] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => {
        if (!!dadosLinha && !!dadosLinha.prc_datavencimento) {
            formDados.setFieldsValue({ prc_datavencimento: moment(dadosLinha.prc_datavencimento) });
            formDados.setFieldsValue({ prc_valor: parseFloat(dadosLinha.prc_valor) });
        }
    }, [dadosLinha, []]);

    const onCloseModal = () => {
        formDados.resetFields();
        fecharModal();
    };

    const salvarDados = values => {
        let parcelaEditar = dadosContasReceberPagar.parcelas.find((item) => { return item.prc_numeroparcela === dadosLinha.prc_numeroparcela });
        parcelaEditar.prc_datavencimento = values.prc_datavencimento;
        parcelaEditar.prc_valor = parseFloat(!!values.prc_valor ? values.prc_valor : 0);
        fecharModal();
    };

    function validarValor(){
        let valorTotal = parseFloat(formulario.getFieldsValue().valortotal);
        let valorParcelas = 0;
        dadosContasReceberPagar.parcelas.forEach((item) => {
            valorParcelas += item.prc_numeroparcela === dadosLinha.prc_numeroparcela ? parseFloat(formDados.getFieldValue().prc_valor) : item.prc_valor;
        });
        if (valorParcelas > valorTotal){
            Modal.warning({
                title:'Aviso!',
                content: 'Valor das parcelas não pode ser superior ao valor total!',
            });
            formDados.setFieldsValue({prc_valor: dadosLinha.prc_valor});
            return;
        }
        
    };

    return (
        <Modal centered
            title="Editar Data de Vencimento da Parcela"
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={() => { formDados.submit() }}
            okText={
                <>
                    <CheckOutlined /> Ok
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }   
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="formParcela" form={formDados} onFinish={salvarDados}>
                    <Row align="middle" gutter={[8, 0]}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Data label="Data Vencimento" name="prc_datavencimento" />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <InputPreco label="Valor (R$)" name="prc_valor" onBlur={() => {validarValor()}}/>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}