import React, { useState } from "react";
import { Row, Col, Button, Tag, Dropdown, Menu, Table, Divider } from "antd";
import { EditOutlined, DeleteOutlined, CloseOutlined, PrinterOutlined, MoreOutlined, EyeOutlined, CopyOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import { useStateValue } from '../../state';
import { drawerActions, manutencaoActions } from "../../actions";
import { FormatFone, FormatNumber, MaskFormat } from "../../ValueObjects";
import { ModalDetalheOrcamento } from "../../components/modals/";
import DrawerOrcamento from '../../pages/orcamento/drawer';

export default function PesquisaOrcamento({ dados }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [openModalDetalheOrcamento, setOpenModalDetalheOrcamento] = useState(false);

    function menuTable(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="link" icon={<EditOutlined />} onClick={() => { editar(record) }}> Editar </Button>
                </Menu.Item>
                <Menu.Item key="2">
                    <Button type="link" icon={<CloseOutlined />} onClick={() => { cancelar(record) }}> Cancelar </Button>
                </Menu.Item>
                <Menu.Item key="3">
                    <Button type="link" icon={<CopyOutlined />} onClick={() => { duplicar(record) }}> Duplicar </Button>
                </Menu.Item>
                <Menu.Item key="4">
                    <Button type="link" icon={<DeleteOutlined />} onClick={() => { emitirNf(record) }}> Emitir NF-e </Button>
                </Menu.Item>
                <Menu.Item key="5">
                    <Button type="link" icon={<EyeOutlined />} onClick={() => { visualizar(record) }}> Visualizar </Button>
                </Menu.Item>
                <Menu.Item key="6">
                    <Button type="link" icon={<PrinterOutlined />}> Imprimir </Button>
                </Menu.Item>
            </Menu>
        )
    }

    function editar(record) {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function cancelar(record) {

    };

    function duplicar(record) {

    };

    function emitirNf(record) {

    };

    function visualizar(record) {
        setOpenModalDetalheOrcamento(true);
    };

    return (
        <div className="tabela">
            <Table dataSource={dados} columns={[
                {
                    title: "Orçamento",
                    render: ({ orc_numero, pes_nome, pef_cpf, pej_cnpj, pes_telefone, orc_datainclusao, orc_datavalidade, oit_qtditem }) => (
                        <div>
                            <Row align="middle" gutter={[16, 0]}>
                                <Col>
                                    <Tag color="processing">
                                        <b>{orc_numero}</b>
                                    </Tag>
                                </Col>
                                <Col>
                                    <b>{pes_nome}</b>
                                </Col>
                                <Col>
                                    CPF/CNPJ: <b>{!!pef_cpf ? MaskFormat(pef_cpf, 'Não Informado', true) : MaskFormat(pej_cnpj, 'Não Informado', true)}</b>
                                </Col>
                                <Col>
                                    Telefone: <b>{FormatFone(pes_telefone) || 'Não Informado'}</b>
                                </Col>
                            </Row>
                            <Row align="middle" gutter={[16, 0]}>
                                <Col>
                                    Emissão: <b>{moment(orc_datainclusao).format('DD/MM/YYYY')}</b>
                                </Col>
                                <Col>
                                    Entrega: <b>{moment(orc_datavalidade).format('DD/MM/YYYY')}</b>
                                </Col>
                                <Col>
                                    Quant.: <b>{oit_qtditem}</b>
                                </Col>
                            </Row>
                        </div>
                    )
                },
                {
                    title: "Total do Orçamento (R$)",
                    align: 'right',
                    render: ({ orc_valortotal }) => (
                        <div>
                            <b className="f-18">{FormatNumber(orc_valortotal, true)}</b>
                        </div>
                    )
                },/*
                {
                    title: 'Ações',
                    dataIndex: '',
                    key: 'x',
                    align: 'center',
                    width: 65,
                    fixed: 'right',
                    render: (record) => (
                        <div>
                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                <Col>
                                    <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                        <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>
                    ),
                },*/
            ]} locale={{
                emptyText: (
                    <Row>
                        <Col span={24}>
                            <Divider orientation="center">
                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                            </Divider>
                        </Col>
                    </Row>
                )
            }} scroll={{ x: 900 }}/>
            <DrawerOrcamento />
            <ModalDetalheOrcamento exibirModalDetalhesOrcamento={openModalDetalheOrcamento} fecharModalDetalhesOrcamento={() => setOpenModalDetalheOrcamento(false)} />
        </div>
    );

}