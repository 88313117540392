import React, { useEffect, useState } from "react";
import { Form, Row, Col, Typography, Select, Modal } from "antd";
import { ExclamationCircleOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";

import api from "../../../services/api";

export default function ModalInstrucaoRemessa({ exibirModalInstrucaoRemessa, fecharModalInstrucaoRemessa, form, parcela, onOk }) {

    const [listaInstrucao, setListaInstrucao] = useState([]);
    const [formInstrucao] = Form.useForm();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalInstrucaoRemessa();
            }
        });
    };

    function onFinish(values){        
        let isp_codigo = listaInstrucao.find((instrucao) => {return instrucao.isp_id === values.isp_id}).isp_codigo;
        onOk(isp_codigo);
    };

    useEffect(() => {
        if (!!form.getFieldValue().ban_id || !!parcela && exibirModalInstrucaoRemessa) {
            let banco = !!form.getFieldValue().ban_id ? form.getFieldValue().ban_id : parcela.ban_id;
            api.get(`InstrucaoBoleto/Listar?Descricao=${banco}`).then((res) => {
                if (res.status === 200) {
                    setListaInstrucao(res.data);
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }, [exibirModalInstrucaoRemessa]);

    return (
        <Modal centered
            title="Instrução"
            visible={exibirModalInstrucaoRemessa}
            onCancel={onCloseModal}
            onOk={() => formInstrucao.submit()}
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            okText={
                <>
                    <CheckOutlined /> Ok
                </>
            }
            maskClosable={false}
        >
            <Form layout="vertical" form={formInstrucao} onFinish={(values) => {onFinish(values)}}>
                <Row align="middle" gutter={[0, 8]}>
                    <Col>
                        <Typography.Text>
                            Abaixo informe a Instrução que deseja realizar.
                        </Typography.Text>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Form.Item label="Instrução" name="isp_id" rules={[{ required: true}]}>
                            <Select showSearch optionFilterProp="children" allowClear={true} placeholder="Selecione uma Instrução" >
                                {listaInstrucao.map((instrucao) => (
                                    <Select.Option value={instrucao.isp_id} key={instrucao.isp_id}>{instrucao.isp_descricao}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    {/*<Col span={12}>
                        <Form.Item label="Valor R$">
                            <InputNumber
                                step={1.0}
                                min={0}
                                formatter={value => `R$ ${value}`}
                                parser={value => value.replace('R$ ', '')}
                            />
                        </Form.Item>
                    </Col>*/}
                </Row>
            </Form>
        </Modal>
    );
}