import React, { useState, useEffect } from 'react';
import { Typography, Row, Col, Form, Divider, Input, Select, Steps, Button, Modal, notification, Spin } from "antd";
import { CalendarOutlined, ExclamationCircleOutlined, ExclamationOutlined } from "@ant-design/icons";
import moment from 'moment';
import api from '../../services/api';
import { useStateValue } from '../../state';
import { TabFaturamento, TabResumo } from './tabPage';
import { Data, DrawerCliente, FormGW, InputCep, InputCpfCnpj, SelectIncluir, SelectPaginacao } from '../../components';
import { TabItens, TabTransporte } from '../tabNotaFiscal/index';
import { drawerActions, manutencaoActions, selectPaginadoActions } from '../../actions';
import { ModalAliquotaSimplesNacional, ModalEndereco, ModalTelefone } from "../../components/modals";
import { validaForm, validarNumeros, isObjetoDiffVazio, novaAbaNavegador, calculaValorProporcional, openPdfNewTab, HasValue, imprimirRelatorioJasper } from '../../services/funcoes';
import { CalcularImpostoItem } from "../../services/calculaImposto";
import SelectPaginado from '../../components/selectPaginado';
import { MaskFormat } from '../../ValueObjects/index';
import { getCnpjEmpresa, getDadosIntegra, setDadosIntegra } from '../../services/auth';
import apiPayer from '../../services/apiPayer';

export default function ManutencaoNotaFiscal({ form, carregando, aoSalvar, pages, proximaPag, salvarRascunho, showDrawerExtra, fecharDrawerNotaFiscal }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [vendedores, setVendedores] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [serie, setSerie] = useState([]);
    const [dadosEndereco, setDadosEndereco] = useState([]);
    const [telefone, setTelefone] = useState([]);
    const [email, setEmail] = useState([]);
    const [pessoaFisica, setPessoaFisica] = useState(1);
    const [dadosContribuinte, setDadosContribuinte] = useState({});
    const [formularioTelefone] = Form.useForm();
    const [dadosTelefone, setDadosTelefone] = useState([]);
    const [abreModalTelefone, setAbreModalTelefone] = useState(false);
    const [dadosItens, setDadosItens] = useState([]);
    const [listaFormaPagamento, setListaFormaPagamento] = useState([]);
    const [listaVeiculos, setListaVeiculos] = useState([]);
    const [abreModalSimplesNac, setAbreModalSimplesNac] = useState(false)
    const [dadosTransp, setDadosTransp] = useState({});
    const [dadosVendedor, setDadosVendedor] = useState({});
    const [openModalEndereco, setOpenModalEndereco] = useState(false);
    const [formEndereco] = Form.useForm();
    const [permiteValidar, setPermiteValidar] = useState(true);
    const [openDrawerCliente, setOpenDrawerCliente] = useState(false);
    const [openValidaIE, setOpenValidaIE] = useState(true);
    const [codigoCliente, setCodigoCliente] = useState(null);
    const [editando, setEditando] = useState(false);
    const [operacaoFiscal, setOperacaoFiscal] = useState({});
    const [carregarPagina, setCarregarPagina] = useState(false);
    const [listaIndicadorPresenca, setListaIndicadorPresenca] = useState([]);
    const [consumidorFinal, setconsumidorFinal] = useState([]);
    const [listaSubjacente, setListaSubjacente] = useState([]);
    const listaValidacoesDest = [
        { nome: 'ope_id', obrigatorio: true, label: 'Operação' },
        { nome: 'ntf_indicadorpresenca', obrigatorio: true, label: 'Indicador de Presença' },
        { nome: 'pes_id', obrigatorio: true, label: 'Destinatário' },
        { nome: 'cep_cep', obrigatorio: true, label: 'Cep' },
        { nome: 'ntf_endereco', obrigatorio: true, label: 'Endereço' },
        { nome: 'ntf_consumidorfinal', obrigatorio: true, label: 'Destinatario é consumidor final?' }
    ];

    const statusPagamentoIntegraPagto = {
        Aprovada: 1,
        Rejeitada: 2,
        Pendente: 3,
        Cancelada: 4,
        Abortada: 5,
        Finalizada: 6,
        NaoAutorizada: 7,
    }

    var modalInstance = null;

    const listaValidacoesTransp = [
        { nome: 'ntf_modalidadefrete', obrigatorio: true, label: 'Modalidade do Frete' }
    ];

    const pagamentosCodigos = {
        dinheiro: 1,
        cheque: 2,
        cartaoCredito: 3,
        cartaoDebito: 4,
        crediarioLoja: 5,
        valeAlimentacao: 6,
        valeRefeicao: 7,
        valeCombustivel: 9,
        antecipacao: 12,
        valePresente: 12,
        boleto: 15,
        depositoBancario: 16,
        pix: 17,
        transfBancaria: 18,
        semPagamento: 90,
        outro: 99
    };

    useEffect(() => {
        if (ui.showDrawer) {
            carregarDados();
            if (!!manutencao.dados && !!manutencao.dados.ntf_id) {
                form.setFieldsValue(manutencao.dados);
                setEditando(true);
            } else {
                setEditando(false);
            }
            if (!!!form.getFieldValue().ntf_dataemissao) {
                form.setFieldsValue({ ntf_dataemissao: moment(new Date()) });
            }
        }
    }, [ui.showDrawer]);

    useEffect(() => {
        if (!!showDrawerExtra) {
            carregarDados();
            form.setFieldsValue(manutencao.dados);
            if (!!!form.getFieldValue().ntf_dataemissao) {
                form.setFieldsValue({ ntf_dataemissao: moment(new Date()) });
            }
        }
        if (!!showDrawerExtra && !!form.getFieldValue().pes_id) {
            let lista = [];
            if (!!form.getFieldValue().pes_id) {
                lista.push({ name: "pes_id", campo: "Filtro", value: form.getFieldValue().pes_id });
                buscarDadosCliente(form.getFieldValue().pes_id);
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            form.setFieldsValue(manutencao.dados);
        }
    }, [showDrawerExtra]);

    useEffect(() => {
        if (!!manutencao.dados) {
            if (!!manutencao.dados.faturamento) {
                setListaFormaPagamento(manutencao.dados.faturamento);
            }
            if (!!manutencao.dados.formaPgto && !!!manutencao.dados.antecipacao) {
                manutencao.dados.formaPgto.forEach((forma) => {
                    if (forma.fpg_tipopagamento === pagamentosCodigos.crediarioLoja && forma.fpg_quitacaoantecipacao) {
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ antecipacao: [{ valorAntecipacao: forma.nfp_valor }] } } } });
                    }
                });
            }
            if (!!manutencao.dados.formaPgto && !!!manutencao.dados.faturamento) {
                manutencao.dados.formaPgto.forEach((formas) => {
                    formas.pfp_valor = parseFloat(formas.nfp_valor);
                });
                let dadosFormaPgto = [...manutencao.dados.formaPgto];
                dadosFormaPgto = manutencao.dados.formaPgto.filter((dados) => dados.fpg_tipopagamento !== pagamentosCodigos.crediarioLoja && !dados.fpg_quitacaoantecipacao);
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ faturamento: dadosFormaPgto } } } });
            }
            if (!!manutencao.dados.pes_id && (!!!manutencao.dados.destinatario || (manutencao.dados.destinatario.pes_id !== manutencao.dados.pes_id))) {
                let lista = [];
                lista.push({ name: "pes_id", campo: "Filtro", value: manutencao.dados.pes_id });
                lista.push({ name: "ope_id", campo: "CodigoOperacao", value: manutencao.dados.ope_id });
                if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                buscarDadosCliente(manutencao.dados.pes_id);
                popularOperacaoFiscal(manutencao.dados.ope_id);
            }
            if (!!manutencao.dados && !!manutencao.dados.itensNf) {
                if (!!manutencao.dados.itensNf[0].ped_id || !!manutencao.dados.itensNf[0].ntf_id) {
                    calcularFrete(manutencao.dados.itensNf);
                }
                if (dadosItens.length === 0) {
                    setDadosItens(manutencao.dados.itensNf);
                }
            }
        }
    }, [manutencao.dados]);

    useEffect(() => {
        if (!!clientes && clientes.length > 0 && (!!manutencao.dados || !!form.getFieldValue().pes_id)) {
            let cliente = !!manutencao.dados ? manutencao.dados.pes_id : form.getFieldValue().pes_id;
            buscarDadosCliente(cliente);
        }
    }, [clientes]);

    useEffect(() => {
        if ((!!proximaPag.proximaPag || proximaPag.proximaPag === 0) && permiteValidar) {
            validarTrocaPg(proximaPag.proximaPag);
        } else {
            setPermiteValidar(true);
        }
    }, [proximaPag.proximaPag]);

    function calcularFrete() {
        let valorFrete = 0;
        manutencao.dados.itensNf.forEach((item) => {
            if (!!item.ped_id) {
                valorFrete += parseFloat(!!item.pdi_valorfrete ? item.pdi_valorfrete : 0);
            } else {
                valorFrete += parseFloat(!!item.nfi_valorfrete ? item.nfi_valorfrete : 0);
            }
        });
        form.setFieldsValue({ valorFrete: valorFrete });
    };

    async function carregarDados() {
        setCarregarPagina(true);
        let listaIndicadorPresenca = await api.get('Enum/Listar?nome=IndicadorPresenca');
        if (listaIndicadorPresenca.status === 200) {
            setListaIndicadorPresenca(listaIndicadorPresenca.data);
        }
        let consumidorFinal = await api.get('Enum/Listar?nome=ConsumidorFinal');
        if (consumidorFinal.status === 200) {
            setconsumidorFinal(consumidorFinal.data);
        }
        let listaVendedor = await api.get('Vendedor/ListarVendedor');
        if (listaVendedor.status === 200) {
            setVendedores(listaVendedor.data.items);
        }
        let listaSerie = await api.get('SerieFiscal/Listar?EmitenteDocumento=0&CodigoModeloDocFiscal=55&SomenteAtivos=true');
        let serie = [];
        if (listaSerie.status === 200) {
            serie = listaSerie.data.items;//.filter(item => (item.mdf_id === '55' && item.ser_emitentedocumento === 0));
            setSerie(serie);
            if (serie.length > 0) {
                form.setFieldsValue({ ser_id: serie[0].ser_id });
            }
        }

        let listaDadosContribuinte = await api.get('dadosContribuinte/Buscar');
        setCarregarPagina(false);
        if (listaDadosContribuinte.status === 200) {
            if (!!listaDadosContribuinte.data) {
                setDadosContribuinte(listaDadosContribuinte.data);
                if (listaDadosContribuinte.data.emp_regimetributario === 1) {
                    popularDadosSimples();
                }
            } else {
                Modal.warning({
                    title: 'Dados Contribuinte não parâmetrizados!',
                    content: 'Favor parametrizar os dados do contribuinte'
                });
                aoSalvar();
            }
        }
    };

    function setarAdjacentes(adjacentes) {
        let key = 1;
        adjacentes.forEach((item) => {
            item.key = key++;
        });
        setListaSubjacente(adjacentes);
    }

    function tratarDadosSubjacente(id) {
        let value = listaSubjacente.find(x => x.pop_id === id);
        if (HasValue(value.pop_cep)) {
            fetch(`https://viacep.com.br/ws/${value.pop_cep.replace(`-`, ``)}/json/`).then(
                res => res.json()
            ).then(
                result => {
                    let dadosEndereco = {
                        pee_id: id,
                        ntf_endereco: id,
                        bai_nome: value.pop_bairro,
                        bairro: value.pop_bairro,
                        cep_cep: value.pop_cep,
                        cid_nome: result.localidade,
                        complemento: value.pop_complemento,
                        pee_complemento: value.pop_complemento,
                        log_logradouro: value.pop_endereco,
                        pee_numero: value.pop_numero,
                        numero: value.pop_numero,
                        est_sigla: result.uf
                    };
                    setDadosEndereco([dadosEndereco]);
                    form.setFieldsValue(
                        dadosEndereco);
                },
                erro => {
                    notification.warning({ message: 'Aviso', description: 'Cep não encontrado!' })
                }
            );
        }
    }

    function setarEnderecos(enderecos) {
        if (enderecos != null && enderecos.length > 0) {
            setDadosEndereco(enderecos);
            let end = enderecos[0];
            buscarCep(end.cep_cep);
            form.setFieldsValue({
                ntf_endereco: end.pee_id,
                cep_cep: end.cep_cep,
                pse_id: end.pse_id,
                bai_nome: end.bai_nome,
                pee_numero: end.pee_numero,
                pee_complemento: end.pee_complemento
            });
            let lista = [];
            lista.push({ name: "pse_id", campo: "pse_id", value: end.pse_id });
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        } else {
            setDadosEndereco([]);
            if (!!form.getFieldValue().pes_id) {
                Modal.confirm({
                    title: 'Endereço não encontrado',
                    icon: <ExclamationOutlined />,
                    content: 'Deseja incluir um endereço para este destinatário?',
                    okText: 'Sim',
                    cancelText: 'Não',
                    centered: true,
                    onOk() {
                        setOpenModalEndereco(true);
                    }
                });
            }
        }
    };

    function setarTelefones(telefones) {
        setTelefone(telefones);
        if (telefones != null && telefones.length > 0) {
            let telefone;
            if (telefones.length > 1) {
                let telefonePrincipal = telefones.filter(f => f.psc_principal == true)
                if (telefonePrincipal.length > 0) {
                    telefone = telefonePrincipal[0].psc_id;
                }
                else {
                    telefone = telefones[0].psc_id;
                }
            } else {
                telefone = telefones[0].psc_id;
            }
            form.setFieldsValue({ telefoneDestinatario: telefone });
        }
    };

    function setarEmails(emails) {
        setEmail(emails);
        if (emails != null && emails.length > 0) {
            let email;
            if (emails.length > 1) {
                let emailPricipal = emails.filter(f => f.pem_emailprincipal == true);
                if (emailPricipal.length > 0) {
                    email = emailPricipal[0].pem_id;
                }
                else {
                    email = emails[0].pem_id;
                }
            } else {
                email = emails[0].pem_id;
            }
            form.setFieldsValue({ ntf_emaildestinatario: email });
        }
    };

    function popularDadosSimples() {
        let data = new Date();
        let dados = {};
        dados.asn_ano = data.getFullYear();
        dados.asn_mes = data.getMonth() + 1;
        api.get(`AliquotaSimplesNacional/Listar?Ano=${dados.asn_ano}&Mes=${dados.asn_mes}`).then(
            res => {
                if (!isObjetoDiffVazio(res.data?.items[0])) {
                    setAbreModalSimplesNac(true);
                }
            }
        ).catch(
            erro => {
                if (!!!erro) {
                    setAbreModalSimplesNac(true);
                } else {
                    console.log(erro);
                }
            }
        )
    };
    async function modalVerificaIEdestinatario(pagina) {
        if (modalInstance !== null) {
            modalInstance.destroy();
        }
        modalInstance = Modal.confirm({
            title: 'Atenção',
            content: 'Destinatário sem Inscrição Estadual, deseja seguir com emissão da Nota Fiscal?',
            okText: 'Sim',
            cancelText: 'Não',
            onOk: () => {
                setOpenValidaIE(false);
                //validarTrocaPg(pagina)
            },
            onCancel: () => {
                setOpenValidaIE(true);
            }
        });
    };

    async function validarOperacao() {
        let retorno;
        let end = dadosEndereco.filter((end) => (end.pee_id === form.getFieldValue().ntf_endereco))[0];
        let interestadual = false;
        if (end?.est_sigla !== dadosContribuinte.est_sigla) {
            interestadual = true;
        }
        form.setFieldsValue({ interestadual: interestadual });

        if (!!end && form.getFieldValue().pej_inscricaoestadual === null
            && form.getFieldValue().ntf_indicadorpresenca === 0 && end.est_sigla !== dadosContribuinte.est_sigla) {
            Modal.warning({
                title: "Verifique a Operação Fiscal Informada",
                content: "Nota Fiscal emitida para destinatário interestadual não contribuinte, deve ter uma operação que configure o tipo de indicador de presença do destinatário. Esta operação esta cadastrada com o indicador de presença 0-Não se Aplica - Verifique a Situação!",
            });
            retorno = false;
        } else {
            retorno = true;
        }
        return retorno;
    };

    async function buscarDadosCliente(registro) {
        if (!!registro) {
            let cliente = (await api.get(`Cliente/BuscarByIdPessoa/${registro}`)).data;
            if (!!cliente) {
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ destinatario: cliente } } } });
                form.setFieldsValue({ 'pej_inscricaoestadual': cliente.pej_inscricaoestadual });
                form.setFieldsValue({ 'pes_nome': cliente.pes_nome });
                form.setFieldsValue({ 'cli_id': cliente.cli_id });
                setarCpfCnpj(cliente);
                setarEnderecos(cliente.enderecos);
                setarTelefones(cliente.telefones);
                setarEmails(cliente.emails);
                if (cliente.pes_fisicajuridica === 1) // Pessoa Fisica
                {
                    if (cliente.pef_produtorrural) {
                        form.setFieldsValue({ 'pej_inscricaoestadual': cliente.enderecos[0].pie_inscricaoestadual });
                    }
                    if (form.getFieldValue().pej_inscricaoestadual != null && form.getFieldValue().pej_inscricaoestadual != undefined) {
                        form.setFieldsValue({ 'ntf_consumidorfinal': 1 });
                    }
                    else {
                        form.setFieldsValue({ 'ntf_consumidorfinal': 0 });
                    }
                } else {
                    form.setFieldsValue({ 'ntf_consumidorfinal': null }); // Usuario deve definir, pois pode ou não ser consumidor final
                }
                if (cliente?.pessoaorgaospublicos?.length > 0) {
                    setarAdjacentes(cliente.pessoaorgaospublicos);
                } else {
                    setListaSubjacente([]);
                }
            } else {
                limparCliente();
            }
        } else {
            limparCliente();
        }
    };

    function limparCliente() {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ destinatario: null } } } });
        form.setFieldsValue({ 'pej_inscricaoestadual': null });
        form.setFieldsValue({ 'pes_nome': null });
        form.setFieldsValue({ 'ntf_cpfcnpj': null });
        setarEnderecos([]);
        setarTelefones([]);
        setarEmails([]);
    }

    function setarCpfCnpj(cliente) {
        let cpfCnpj = '';
        if (cliente.pes_fisicajuridica > 0) {
            if (cliente.pes_fisicajuridica === 1) {
                cpfCnpj = cliente.pef_cpf;
                setPessoaFisica(1);
            } else if (cliente.pes_fisicajuridica === 2) {
                cpfCnpj = cliente.pej_cnpj;
                setPessoaFisica(2);
            } else if (cliente.pes_fisicajuridica === 3) {
                cpfCnpj = cliente.pes_idestrangeiro;
                setPessoaFisica(3);
            }
            form.setFieldsValue({ ntf_cpfcnpj: cpfCnpj });
        } else {
            notification.warn("Cliente informado com cadastro de pessoa física/jurídica/estrangeira incorreto!");
            form.setFieldsValue({ pes_id: null });
            return false;
        }
    };
    function buscarDadosEndereco(pee_id) {
        let end = dadosEndereco.filter((end) => (end.pee_id === pee_id))[0];
        if (!!end) {
            form.setFieldsValue({ pse_id: end.pse_id });
            if (end.pse_id) {
                let lista = [];
                lista.push({ name: "pse_id", campo: "pse_id", value: end.pse_id });
                if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            }
            if (end.pie_inscricaoestadual) {
                form.setFieldsValue({ 'pej_inscricaoestadual': end.pie_inscricaoestadual });
            }
            form.setFieldsValue({ cep_cep: end.cep_cep });
            buscarCep(end.cep_cep);
            form.setFieldsValue({ bai_nome: end.bai_nome });
            form.setFieldsValue({ pee_numero: end.pee_numero });
            form.setFieldsValue({ pee_complemento: end.pee_complemento });
        }
    };
    async function validarTrocaPg(pagina) {
        let paginaAtual = pages.tagPages;
        let listaValidacoes = [];
        switch (paginaAtual) {
            case 0:
                listaValidacoes = listaValidacoesDest;
                if (openValidaIE === true && pessoaFisica == 2) {
                    let ie = form.getFieldValue().pej_inscricaoestadual;
                    if (ie == null || ie == "") {
                        await modalVerificaIEdestinatario(pagina)
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                }
                if (!validarOperacao()) {
                    setPermiteValidar(false);
                    proximaPag.setProximaPag(paginaAtual);
                    return false;
                }
                break;
            case 1:
                if (paginaAtual < pagina) {
                    if (!!dadosItens && dadosItens.length === 0) {
                        notification.warning({ message: 'Aviso', description: 'Nota Fiscal deve ter ao menos 1 item!' });
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                }
                break;
            case 2:
                listaValidacoes = listaValidacoesTransp;
                await validarTransporte();
                break;
            case 3:
                if (paginaAtual < pagina) {
                    listaValidacoes = [];
                    let validaFaturamento = await validarFaturamento();
                    if (validaFaturamento === false || listaFormaPagamento.length === 0) {
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                }
                break;
            case 4:
                listaValidacoes = [];
                break;
        }
        if (validaForm(form, listaValidacoes)) {
            pages.setTagPages(pagina);
        } else {
            setPermiteValidar(false);
            proximaPag.setProximaPag(paginaAtual);
        }
    };
    async function validarTransporte() {
        await reteioFrete();
        await recalcularImpostos();
    }
    async function recalcularImpostos() {
        if (dadosItens.length > 0) {
            let total = 0;
            let totalSt = 0;
            let possuiIe = !!form.getFieldValue().pej_inscricaoestadual ? true : false;
            let fisicaJuridica = form.getFieldValue().ntf_cpfcnpj.length === 14 ? 2 : form.getFieldValue().ntf_cpfcnpj.length == 11 ? 1 : 3;
            for (const p of dadosItens) {
                try {
                    let impostos = await CalcularImpostoItem(p.orm_id, p.cst_id, p.cso_id, p.iod_uforigem, p.iod_ufdestino, p.nfi_qtde, p.nfi_valorunitario, p.nfi_valorfrete, p.nfi_valoroutros,
                        p.nfi_valordesconto, p.impostos.retornoICMS.ret_aliquotaIcms, p.impostos.retornoICMS.ret_aliquotaIcms, p.impostos.retornoICMS.ret_percReducaoIcms,
                        p.impostos.retornoICMSST.ret_aliquotaIcmsSt, p.impostos.retornoICMSST.ret_percMva, p.impostos.retornoICMSST.ret_percReducaoIcmsST, null,
                        p.impostos.retornoICMSST.ncm_percentual, fisicaJuridica, form.getFieldValue().ntf_consumidorfinal, possuiIe, form.getFieldValue().ntf_indicadorpresenca);

                    if (isObjetoDiffVazio(impostos)) {
                        p.nfi_valoricmsst = impostos.retornoICMSST.ret_valorIcmsSt;
                        p.nfi_valoripi = impostos.retornoIPI.ret_valorIpi;
                        p.total = (p.nfi_qtde * p.nfi_valorunitario) - p.nfi_valordesconto + p.nfi_valoroutros + p.nfi_valoripi + p.nfi_valoricmsst;
                        p.impostos = impostos;
                    }
                    totalSt += parseFloat(p.nfi_valoricmsst);
                    total += parseFloat(p.total);
                } catch (error) {
                    // Handle any potential errors here
                    console.error("Error item:", error);
                }
            }
            form.setFieldsValue({ valorTotalSt: totalSt });
            // form.setFieldsValue({ valorTotal: total });
        }
    }



    // async function recalcularImpostos() {
    //     if (dadosItens.length > 0) {
    //          dadosItens.forEach(p => {
    //             let impostos = await CalcularImpostoItem(p.orm_id, p.cst_id, p.cso_id, p.iod_uforigem, p.iod_ufdestino, p.nfi_qtde, p.nfi_valorunitario, p.nfi_valorfrete, p.nfi_valoroutros,
    //                 p.nfi_valordesconto, p.impostos.retornoICMS.ret_aliquotaIcms, p.impostos.retornoICMS.ret_aliquotaIcms, p.impostos.retornoICMS.ret_percReducaoIcms,
    //                 p.impostos.retornoICMSST.ret_aliquotaIcmsSt, p.impostos.retornoICMSST.ret_percMva, p.impostos.retornoICMSST.ret_percReducaoIcmsST, null,
    //                  p.impostos.retornoICMSST.ncm_percentual, 2, 0, true);

    //             if (isObjetoDiffVazio(impostos)) {
    //                 p.nfi_valoricmsst = impostos.retornoICMSST.ret_valorIcmsSt;
    //                 p.nfi_valoripi = impostos.retornoIPI.ret_valorIpi;
    //                 p.impostos = impostos;
    //             }
    //         });
    //     }
    //     console.log("Recalculo de Imposto", dadosItens)
    // }

    function reteioFrete() {
        let formulario = form.getFieldsValue();
        let _numeroItem = 1;
        let _totalFrete = 0;
        if (dadosItens.length > 0) {
            let _valorFrete = parseFloat(formulario.valorFrete);
            if (_valorFrete > 0) {
                // Ordena os itens em ordem de maior valor crescente
                var itens = dadosItens.sort((a, b) => a.total - b.total);
                // Calcular a soma da multiplicação dos campos
                let somaTotal = dadosItens.reduce((total, item) => {
                    let resultadoMultiplicacao = item.nfi_valorunitario * item.nfi_qtde;
                    return total + resultadoMultiplicacao;
                }, 0);

                itens.forEach(item => {
                    let valorTotalItem = parseFloat(item.nfi_valorunitario * item.nfi_qtde);
                    item.nfi_valorfrete = calculaValorProporcional(valorTotalItem, _valorFrete, somaTotal);
                    if (_numeroItem == dadosItens.length) {
                        item.nfi_valorfrete = parseFloat((_valorFrete - _totalFrete).toFixed(2))
                    }
                    _totalFrete += item.nfi_valorfrete;
                    _numeroItem++;
                });
            }
        }
    }
    async function validarFaturamento() {
        let formulario = form.getFieldsValue();
        let retorno = true;
        let valorTotal = 0;
        let operacao = operacaoFiscal;
        if (listaFormaPagamento.length > 0) {
            listaFormaPagamento.forEach(forma => {
                valorTotal += parseFloat(forma.pfp_valor);
                if (forma.editando == true) {
                    retorno = false;
                    notification.warning({ message: 'Aviso', description: 'Favor salvar as alterações em andamento nas parcelas(forma pagamento) para continuar!' });
                }
            });
            if (parseFloat(formulario.valorTotal) === 0) {
                retorno = false;
                notification.warning({ message: 'Aviso!', description: 'Valor da Nota Inválido!' });
            } else {
                if ((validarNumeros(formulario.valorTotal) !== parseFloat(valorTotal.toFixed(2))) || operacao.tip_id == !5) {
                    retorno = false;
                    notification.warning({ message: 'Aviso', description: 'Valor informado nas formas de pagamento é menor que o valor total da nota!' });
                }
            }
        } else {
            if (!!formulario.fpg_id) {
                let formaPagamento = (await api.get(`FormaPagamento/Get?id=${formulario.fpg_id}`)).data;
                if (!listaFormaPagamento.cpg_id) {
                    retorno = false;
                    notification.warning({ message: 'Aviso', description: 'Favor preencher as formas de pagamento!' });
                }
                else if (formaPagamento.fpg_tipoavistaaprazo === 1) {
                    retorno = false;
                    notification.warning({ message: 'Aviso', description: 'Favor preencher as formas de pagamento!' });
                }
            } else {
                retorno = false;
                notification.warning({ message: 'Aviso', description: 'Favor preencher as formas de pagamento!' });
            }
        }
        return retorno;
    };
    function adicionarTelefone() {
        if (!!form.getFieldValue().pes_id) {
            setAbreModalTelefone(true);
        } else {
            notification.warning({ message: 'Aviso', description: "Favor informar um cliente!" })
        }
    };
    function onCloseModalTelefone() {
        setAbreModalTelefone(false);
    };
    function onCloseModalAliquotaSimplesNacional() {
        setAbreModalSimplesNac(false);
        form.resetFields();
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
    };
    async function validarDadosNota(dados) {
        dados = await popularDados();
        dados.gerarBoleto = false;
        dados.ntf_ufdest = dados.notaFiscalDestinatario?.ntf_ufdest;
        dados.notaFiscalFaturamento.forEach((formasPgto) => {
            if (formasPgto.fpg_tipopagamento === pagamentosCodigos.boleto) {
                dados.gerarBoleto = true;
            }
        });
        salvarNota(dados);
    };

    async function modalAutorizarSefaz(dados) {
        Modal.confirm({
            title: 'Atenção!',
            content: (
                <div>
                    <p>
                        A operação fiscal está configurada para gerar um rascunho antes de autorizar a nota no SEFAZ.
                        <br />
                        <br />
                        O que você deseja fazer com a Nota Nº {dados.ntf_numero} - Modelo: {dados.ntf_modelo} Data: {moment(dados.ntf_dataemissao).format('DD/MM/YYYY HH:mm')}?
                    </p>
                </div>
            ),
            okText: 'Autorizar Agora',
            cancelText: 'Autorizar Depois',
            onOk() {
                realizarReenvioSefaz(dados);
            },
            onCancel() {

            }
        })
    }

    async function realizarReenvioSefaz(dadosNota) {
        let _link = `NotaFiscal/ReenviarNotaSefaz/${dadosNota.ntf_id}`;
        if (dadosNota.ntf_finalidade === 3)// Nota ajuste
            _link = `NotaFiscalDebitoCredito/ReenviarNotaSefaz/${dadosNota.ntf_id}`;

        api.post(_link).then(
            (retornoNF) => {
                if (retornoNF.status === 200) {
                    if (retornoNF.data.ntf_status === 1 && !!retornoNF.data.ntf_chavenfe) {
                        novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${retornoNF.data.ntf_chavenfe}`);
                        let formaPagItegrado = retornoNF.data.notafiscalformapgtos.filter((item) => (HasValue(item.imp_id)))
                        if (formaPagItegrado.length > 0) {
                            novaAbaNavegador(`Danfe/GerarComprovantePagamentoViaLoja?IdNotaFiscal=${retornoNF.data.ntf_id}`);
                        }
                    }
                }
                notification.success({ message: 'Sucesso', description: `NF-e Autorizada com sucesso!` });
            }

        ).catch(
            (erro) => {
                if (!!erro.response && !!erro.response.data) {
                    notification.error({ description: erro.response.data, message: `Aviso` });
                } else {
                    notification.error({ description: 'Não foi possível Autorizar a Nota Fiscal!', message: `Aviso` });
                }
            }
        ).finally(() => {
            aoSalvar();
        });
        console.log(dadosNota)
    }

    async function salvarNota(dados) {
        if (dados.notaFiscalDestinatario.pes_fisicajuridica === 1) {
            dados.ntf_consumidorfinal = 1;
        } else {
            if (dados.ntf_consumidorfinal !== 0 && dados.ntf_consumidorfinal !== 1) {
                notification.warning({ message: 'Aviso', description: 'Não foi selecionado o destino da mercadoria!' });
                return;
            }
        }
        dados.ntf_modalidadefrete = !!dados.notaFiscalTransportadora ? dados.notaFiscalTransportadora.ntf_modalidadefrete : 9;
        await analisaFormaPagamento(dados);
    };

    async function analisaFormaPagamento(dados) {
        let pagamentoMaquininha = false;
        let dadosIntegracaoPos = {};
        let cnpjEmpresa = getCnpjEmpresa();
        let listaIdsIntegracao = [];
        for (const item of dados.notaFiscalFaturamento) {
            if (item.fpg_tipopagamento === pagamentosCodigos.cartaoCredito || item.fpg_tipopagamento === pagamentosCodigos.cartaoDebito || item.fpg_tipopagamento === pagamentosCodigos.pix) {
                if (item.fpg_tipointegracao != null) {
                    if (item.fpg_tipointegracao == 1) {// vero
                        let tipopgto = 0;
                        let metodopgto = 0;
                        let submetodopgto = 0;

                        if (item.fpg_tipopagamento === pagamentosCodigos.cartaoCredito) {
                            tipopgto = 2;
                            metodopgto = 1;
                            submetodopgto = 4;
                            if (item.parcelas?.length == 1) {
                                submetodopgto = 1
                            }
                        }
                        if (item.fpg_tipopagamento === pagamentosCodigos.cartaoDebito) {
                            tipopgto = 3;
                            metodopgto = 1;
                            submetodopgto = 3;
                            if (item.parcelas?.length == 1) {
                                submetodopgto = 1
                            }
                        }
                        if (item.fpg_tipopagamento === pagamentosCodigos.pix) {
                            tipopgto = 1;
                            metodopgto = 3;
                            submetodopgto = 1
                        }
                        dadosIntegracaoPos = {
                            imp_cnpjlocal: cnpjEmpresa,
                            imp_valor: item.pfp_valor,
                            imp_tipopgto: tipopgto,
                            imp_metodopgto: metodopgto,
                            imp_submetodopgto: submetodopgto,
                            imp_qtdeparcelas: item.parcelas?.length,
                        }
                        if (!isObjetoDiffVazio(item.integrapgto)) {
                            item.integrapgtook = false;
                            item.integrapgto = await criaRegistroIntegraPagto(dadosIntegracaoPos);
                            listaIdsIntegracao.push(item.integrapgto);
                        }
                        item.imp_id = item.integrapgto.imp_id;
                    } else if (item.fpg_tipointegracao == 2) {//payer
                        await pagamentoPayer(item);
                        //if (dados.notaFiscalFaturamento.length >= 2) { ??
                        pagamentoMaquininha = true;
                        break;
                        //}
                    }
                    pagamentoMaquininha = true;
                } else {
                    pagamentoMaquininha = false;
                }
            }
        }
        if (pagamentoMaquininha) {
            setDadosIntegra(dados);
            modalAguardandoPagamento(dados);
        } else {
            concluirVenda(dados);
        }
    }

    async function pagamentoPayer(item) {
        let dadosIntegracaoPos = {};
        let _paymentType = "";
        let _paymentMethodSubType = "";
        let _installments = "";
        let _paymentMethod = "";
        let _command = "payment"

        if (item.fpg_tipopagamento === pagamentosCodigos.cartaoCredito) {
            _paymentMethod = "Card";
            _paymentType = "Credit";
            _paymentMethodSubType = "Financed_no_Fees";

            if (item.parcelas?.length == 1) {
                _paymentMethodSubType = "Full_Payment";
            } else {
                _installments = (item.parcelas?.length).toString();
            }
        }
        if (item.fpg_tipopagamento === pagamentosCodigos.cartaoDebito) {
            if (item.parcelas?.length > 1) {
                _paymentMethodSubType = "Financed_Debit";
                _installments = (item.parcelas?.length).toString();
            } else {
                _paymentMethodSubType = "Full_Payment";
            }
            _paymentMethod = "Card";
            _paymentType = "Debit"
        }
        if (item.fpg_tipopagamento === pagamentosCodigos.pix) {
            _paymentType = "Cash";
            _paymentMethod = "Pix";
        }

        dadosIntegracaoPos = {
            command: _command,
            value: parseFloat(item.pfp_valor),
            paymentMethod: _paymentMethod,
            paymentType: _paymentType,
            paymentMethodSubType: _paymentMethodSubType,
            installments: _installments
        };
        if (!isObjetoDiffVazio(item.integrapgto)) {
            item.integrapgtook = false;
            try {
                let retorno = await apiPayer.post('Client/Request', dadosIntegracaoPos);
            } catch (error) {
                notification.warning({ description: 'Verifique se o checkout Payer esteja iniciado, caso contrário inicie o mesmo para continuar !', message: 'AVISO!' });
            }
        }
    }

    async function criaRegistroIntegraPagto(dadosIntegracaoPos) {
        let retorno;
        try {
            retorno = (await api.post(`IntegraPagto/Incluir`, dadosIntegracaoPos)).data;
        } catch (error) {
            notification.warning({ description: 'Erro ao mandar o pagamento para a máquina!', message: 'AVISO!' });
            console.log(error);
            return false;
        }
        return retorno;
    }


    function modalAguardandoPagamento(dados) {
        if (modalInstance !== null) {
            modalInstance.destroy();
        }
        modalInstance = Modal.confirm({
            title: 'Aviso',
            content: 'Aguardando Pagamento!',
            okText: 'Pagamento Efetuado',
            cancelText: 'Cancelar Pagamento',
            onOk: () => {
                verificarPagamentos(dados);
            },
            onCancel: () => {
                cancelarPagamentoMaquina(dados);
            }
        });
    }

    async function cancelarPagamentoMaquina(dados) {
        for (var item of dados.notaFiscalFaturamento) {
            if (item.fpg_tipopagamento === pagamentosCodigos.cartaoCredito || item.fpg_tipopagamento === pagamentosCodigos.cartaoDebito || item.fpg_tipopagamento === pagamentosCodigos.pix) {
                let retorno = (await api.put(`IntegraPagto/CancelarPagamento?Id=${item.integrapgto.imp_id}`)).data;
                if (retorno) {
                    item.integrapgtook = false;
                    item.integrapgto = null;
                } else {
                    notification.warning({ description: 'Erro ao efetuar o cancelamento!', message: 'Aviso!' });
                    modalAguardandoPagamento(dados)
                    break;
                }
            }
        }
    }

    async function verificarPagamentos(dados) {
        for (var item of dados.notaFiscalFaturamento) {
            if (item.fpg_tipopagamento === pagamentosCodigos.cartaoCredito || item.fpg_tipopagamento === pagamentosCodigos.cartaoDebito || item.fpg_tipopagamento === pagamentosCodigos.pix) {
                if (item.fpg_tipointegracao != null && (item.integrapgtook == false || item.integrapgtook == null || item.integrapgtook == undefined) && item.fpg_tipointegracao == 1) {// Vero
                    let retorno = (await api.get(`IntegraPagto/Buscar/${item.integrapgto.imp_id}`)).data;
                    if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Pendente) {
                        if (modalInstance !== null) {
                            modalInstance.destroy();
                        }
                        modalAguardandoPagamento(dados);
                    } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Aprovada) {
                        item.integrapgtook = true;
                        item.integrapgto = retorno;
                        item.rcc_nsu = retorno.imp_autorizacao;
                    } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.NaoAutorizada) {
                        notification.warning({ description: 'Pagamento não Autorizado!', message: 'Aviso!' });
                    } else if (retorno.imp_statuspgto == statusPagamentoIntegraPagto.Cancelada) {
                        notification.warning({ description: 'Pagamento Cancelado!', message: 'Aviso!' });
                    }
                }
                if (item.fpg_tipointegracao != null && (item.integrapgtook == false) && item.fpg_tipointegracao == 2) {// Payer
                    let retorno;
                    try {
                        retorno = (await apiPayer.get('Client/Response')).data;

                    } catch (error) {
                        notification.warning({ description: 'Verifique se o checkout Payer esteja iniciado, caso contrário inicie o mesmo para continuar !', message: 'AVISO!' });
                        await pagamentoPayer(item);
                        break
                    }

                    if (retorno.statusTransaction == "PENDING") {
                        break
                    } else if (retorno.statusTransaction == "APPROVED") {
                        let ret = (await api.post(`IntegraPagto/IncluirPayer`, retorno)).data;
                        item.integrapgtook = true;
                        item.integrapgto = ret;
                        item.rcc_nsu = ret.authorizerId;
                        item.imp_id = ret.imp_id;
                    } else if (retorno.statusTransaction == "UNAUTHORIZED") {
                        notification.warning({ description: 'Pagamento não Autorizado, verifique no Checkout Payer e tente novamente!', message: 'Aviso!' });
                        return;
                        //analisaFormaPagamento(docfiscal);
                    } else if (retorno.statusTransaction == "ABORTED") {
                        notification.warning({ description: 'Pagamento Cancelado!', message: 'Aviso!' });
                    }
                } else if (item.fpg_tipointegracao != null && (item.integrapgtook == null || item.integrapgtook == undefined) && item.fpg_tipointegracao == 2) {
                    await pagamentoPayer(item);
                }
            }
        }
        await verificaTodosPagamentos(dados);
    }

    async function verificaTodosPagamentos(dados) {
        let finalizarVenda = true;
        let listaFaturamento = dados.notaFiscalFaturamento;
        listaFaturamento.forEach(item => {
            if (item.fpg_tipopagamento === pagamentosCodigos.cartaoCredito || item.fpg_tipopagamento === pagamentosCodigos.cartaoDebito || item.fpg_tipopagamento === pagamentosCodigos.pix) {
                if (item.fpg_tipointegracao != null && (item.integrapgtook == false || item.integrapgtook == null || item.integrapgtook == undefined)) {
                    finalizarVenda = false;
                }
            }
        });
        if (finalizarVenda == true) {
            concluirVenda(dados);
        } else {
            modalAguardandoPagamento(dados);
        }
    }

    function concluirVenda(dados) {
        if (!!form.getFieldValue().ntf_id) {
            dados.ntf_id = form.getFieldValue().ntf_id;
            editarNota(dados);
        } else {
            dados.ntf_dhsaient = moment(new Date());
            incluirNota(dados);
        }
    }

    function incluirNota(dados) {
        carregando(true);
        api.post(`NotaFiscal/Incluir`, dados).then(
            res => {
                notification.success({ message: 'Sucesso', description: `Nota incluída com sucesso!` });
                if (HasValue(dados.ser_id)) {
                    if (dados.ope_gerarespelho === true) {
                        modalAutorizarSefaz(res.data);
                        openPdfNewTab(`NotaFiscal/GerarEspelhoDanfeNFe?IdNotaFiscal=${res.data.ntf_id}`)
                    } else {
                        if (res.data.ntf_status === 1 && !!res.data.ntf_chavenfe && res.data.ntf_docfiscal == true) {
                            novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${res.data.ntf_chavenfe}`);
                        }
                    }
                } else {
                    imprimirDAVLS(res.data)
                }
                if (dados.gerarBoleto && !!res.data.ntf_id) {
                    novaAbaNavegador(`Boleto/ImprimirBoletos/${res.data.ntf_id}`);
                }
                if (res.status === 200 && !!dados.ped_id) {
                    tratarPedidoFaturado(dados.ped_id);
                }
                if (res.status === 200 && !!dados.vnc_id) {
                    AlterarSituacaoCondicional(dados.vnc_id);
                }
            }
        ).catch(
            erro => {
                if (!!erro.response && !!erro.response.data) {
                    Modal.warning({
                        content: erro.response.data,
                        title: 'Aviso',
                        onOk: () => {
                            aoSalvar();
                        }
                    })
                }
                console.log(erro);
            }
        ).finally(
            () => {
                carregando(false);
                aoSalvar();
            }
        );
    }
    function imprimirDAVLS(registro) {
        Modal.confirm({
            title: 'Qual modelo de impressão deseja?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Não Fiscal',
            cancelText: 'Modelo A4',
            centered: true,
            onOk() {
                novaAbaNavegador(`NotaFiscal/Imprimir?id=${registro.ntf_id}`);
            },
            onCancel() {
                imprimirRelatorioJasper(20, { idNotaFiscal: registro.ntf_id });
            }
        });
    };

    function editarNota(dados) {
        carregando(true);
        api.put(`NotaFiscal/Editar`, dados).then(
            res => {
                notification.success({ message: 'Sucesso', description: `Nota editada com sucesso!` });
                if (HasValue(dados.ser_id)) {
                    if (dados.ope_gerarespelho === true) {
                        modalAutorizarSefaz(res.data);
                        openPdfNewTab(`NotaFiscal/GerarEspelhoDanfeNFe?IdNotaFiscal=${res.data.ntf_id}`)
                    } else {
                        if (res.data.ntf_status === 1 && !!res.data.ntf_chavenfe && res.data.ntf_docfiscal == true) {
                            novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${res.data.ntf_chavenfe}`);
                        }
                    }
                } else {
                    imprimirDAVLS(res.data)
                }
                if (dados.gerarBoleto && !!res.data.ntf_id) {
                    novaAbaNavegador(`Boleto/ImprimirBoletos/${res.data.ntf_id}`);
                }
            }
        ).catch(
            erro => {
                if (!!erro.response && !!erro.response.data && !!erro.response.data.Message) {
                    Modal.warning({
                        content: erro.response.data.Message,
                        title: 'Aviso',
                        onOk: () => {
                            aoSalvar();
                        }
                    })
                }
                console.log(erro);
            }
        ).finally(
            () => {
                carregando(false);
                aoSalvar();
            }
        );
    }

    async function tratarPedidoFaturado(id) {
        api.put(`Pedido/TratarHistoricoPedidoFaturado/${id}`).then(
            ok => {
                console.log(ok);
            }).catch(
                error => {
                    console.log(error)
                }).finally(() => {
                    aoSalvar();
                });
    }

    async function AlterarSituacaoCondicional(id) {
        api.put(`VendaCondicional/AlterarSituacaoCondicional?IdCondicional=${id}&SituacaoCondicional=2`).then(
            ok => {
                console.log(ok);
            }).catch(
                error => {
                    console.log(error)
                }).finally(() => {
                    aoSalvar();
                });
    }

    async function popularDados() {
        let valores = form.getFieldsValue();
        let dados = form.getFieldValue();
        valores.ntf_docfiscal = !!dados.ser_id;
        valores.notafiscalitens = popularDadosItens(dados);
        valores.notaFiscalFaturamento = preencherDadosFaturamento(dados);
        valores.notaFiscalDestinatario = await preencherDadosDestinatario(dados);
        valores.notaFiscalTransportadora = preencherDadosTransportadores(dados);
        valores.tipooperacao = operacaoFiscal;
        valores.ope_gerarespelho = operacaoFiscal.ope_gerarespelho;
        valores.serieFiscal = preencherDadosSerieFiscal(dados);
        return valores;
    };

    function popularDadosItens(dados) {
        let valorFrete = dados.valorFrete;
        let valorSeguro = dados.valorSeguro;
        let qtdTotalItens = 0;
        let freteUn = 0;
        let seguroUn = 0;
        dadosItens.forEach((itens) => {
            qtdTotalItens += itens.nfi_qtde;
            itens.nfi_numeropedidocompra = !!itens.nfi_numeropedidocompra ? itens.nfi_numeropedidocompra.toString() : '';
            //itens.nfi_cfop = itens.nfi_cfop.toString();
        });
        freteUn = valorFrete / qtdTotalItens;
        seguroUn = valorSeguro / qtdTotalItens;
        dadosItens.forEach((itens) => {
            itens.nfi_valorfrete = freteUn * dados.nfi_qtde;
            itens.nfi_valorseguro = seguroUn * dados.nfi_qtde;
        });
        return dadosItens;
    };

    function preencherDadosFaturamento() {
        let dadosForma = [];
        let recebimentoCartao = !!manutencao.dados.recebimentoCartao ? manutencao.dados.recebimentoCartao[0] : null;
        listaFormaPagamento.forEach(forma => {
            let aux = { ...forma };
            aux.fpg_id = forma.fpg_id;
            aux.cpg_id = forma.cpg_id;
            if (forma.fpg_tipopagamento === pagamentosCodigos.cheque && !!manutencao.dados.dadosModalCheque) {
                aux.ctc_id = manutencao.dados.dadosModalCheque.contaCorrente;
                aux.cheques = manutencao.dados.dadosModalCheque.cheques;
            }
            aux.nfp_valor = parseFloat(forma.pfp_valor);
            aux.bce_id = !!recebimentoCartao ? recebimentoCartao.bce_id : null;
            aux.rcc_nsu = !!recebimentoCartao ? recebimentoCartao.rcc_nsu : null;
            if (forma.fpg_tipopagamento === pagamentosCodigos.antecipacao) {
                aux.antecipacoes = forma.formaPagamento.antecipacoes;
            } else {
                aux.antecipacoes = [];
            }
            //aux.cheques = [{chq_valor: 0, pes_id: null, chq_dataemissao: null}];
            dadosForma.push(aux);
        });
        return dadosForma;
    };

    async function preencherDadosDestinatario(form) {
        let dadosDestinatario = {};
        let subjacente = listaSubjacente?.filter(sub => sub.pop_id === form.ntf_subjacente)[0];
        let cliente = (await api.get(`Cliente/BuscarByIdPessoa/${form.pes_id}`)).data;
        let enderecoDest = dadosEndereco?.filter(pe => pe.pee_id === form.ntf_endereco)[0];
        let telefoneDest = cliente.telefones?.filter(pt => pt.psc_id === form.telefoneDestinatario)[0];
        let emailDest = cliente.emails?.filter(pem => pem.pem_id === form.ntf_emaildestinatario)[0];
        dadosDestinatario.pes_id = form.pes_id;
        dadosDestinatario.pes_fisicajuridica = cliente.pes_fisicajuridica;
        dadosDestinatario.ntf_nomedest = subjacente?.pop_nome ?? cliente.pes_nome;
        dadosDestinatario.ntf_cnpjcpfdest = (!!cliente.pej_cnpj ? cliente.pej_cnpj : cliente.pef_cpf);
        dadosDestinatario.ntf_iedest = form.pej_inscricaoestadual;
        dadosDestinatario.ntf_idestrangeirodest = cliente.pes_idestrangeiro;
        dadosDestinatario.ntf_logradourodest = enderecoDest?.log_logradouro;
        dadosDestinatario.ntf_numeroenderecodest = enderecoDest?.pee_numero;
        dadosDestinatario.ntf_complementodest = enderecoDest?.complemento;
        dadosDestinatario.ntf_bairrodest = enderecoDest?.bai_nome;
        dadosDestinatario.ntf_cepdest = enderecoDest?.cep_cep;
        dadosDestinatario.ntf_ufdest = enderecoDest?.est_sigla;
        dadosDestinatario.ntf_telefonedest = telefoneDest?.psc_numero ?? '';
        dadosDestinatario.ntf_emaildest = emailDest?.pem_email ?? null;
        return dadosDestinatario;
    };

    function preencherDadosTransportadores(form) {
        let dadosTransportador = {};
        let veiculoTransp = listaVeiculos.filter((veiculo) => (veiculo.trv_id = form.trv_id))[0];
        dadosTransportador.ntf_modalidadefrete = form.ntf_modalidadefrete;
        dadosTransportador.trn_id = !!dadosTransp && !!dadosTransp.trn_id ? dadosTransp.trn_id : null;
        dadosTransportador.trv_id = !!veiculoTransp ? veiculoTransp.trv_id : null;
        dadosTransportador.trv_observacao = !!veiculoTransp ? veiculoTransp.trv_observacao : null;
        dadosTransportador.trv_placa = !!veiculoTransp ? veiculoTransp.trv_placa : null;
        dadosTransportador.trv_uf = !!veiculoTransp ? veiculoTransp.trv_uf : null;
        dadosTransportador.trv_marca = !!veiculoTransp ? veiculoTransp.trv_marca : null;
        dadosTransportador.trv_modelo = !!veiculoTransp ? veiculoTransp.trv_modelo : null;
        dadosTransportador.trv_rntc = !!veiculoTransp ? veiculoTransp.trv_rntc : null;
        dadosTransportador.nvl_pesoliquido = form.nvl_pesoliquido;
        dadosTransportador.nvl_qtdevolumes = form.nvl_qtdevolumes;
        dadosTransportador.nvl_pesobruto = form.nvl_pesobruto;
        dadosTransportador.nvl_especievolume = form.nvl_especievolume;
        dadosTransportador.nvl_marcavolume = form.nvl_marcavolume;
        return dadosTransportador;
    };
    function preencherDadosSerieFiscal(dados) {
        let dadosSerieFiscal = serie.filter((item) => (item.ser_id === dados.ser_id))[0];
        return dadosSerieFiscal;
    }
    function alterarVendedor() {
        let dadosVen = vendedores.filter((ven) => (ven.ven_id === parseInt(form.getFieldValue().ven_id)))[0];
        setDadosVendedor(dadosVen);
    };
    function onSuccessAliquotaSimplesNacional() {
        setAbreModalSimplesNac(false);
    };

    function buscarCep(cep) {
        if (HasValue(cep)) {
            fetch(`https://viacep.com.br/ws/${cep.replace(`-`, ``)}/json/`)
                .then(
                    res => res.json()
                ).then(
                    result => {
                        form.setFieldsValue({ cidade: `${result.localidade} / ${result.uf}` });
                    },
                    erro => {
                        notification.warning({ message: 'Aviso', description: 'Cep não encontrado!' })
                    }
                );
        }
    };

    function oncloseModalEndereco() {
        setOpenModalEndereco(false)
    };

    useEffect(() => {
        if (!!codigoCliente) {
            api.get(`Cliente/ListarClientesAtivos?filtro=&ordem=%2Bpes_nome`).then(listaCliente => {
                setClientes(listaCliente.data);
            }).finally(() => {
                form.setFieldsValue({ pes_id: codigoCliente });
                let lista = [];
                lista.push({ name: "pes_id", campo: "Filtro", value: codigoCliente });
                if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            });
        }
    }, [codigoCliente]);

    useEffect(() => {
        if (!!codigoCliente) {
            buscarDadosCliente(codigoCliente);
        }
    }, [clientes]);

    async function popularOperacaoFiscal(id) {
        if (!!id) {
            api.get(`OperacaoFiscal/Buscar/${id}`).then(
                res => {
                    if (res.status === 200) {
                        setOperacaoFiscal(res.data);
                        form.setFieldsValue({ ntf_indicadorpresenca: res.data.ope_indicadorpresenca });
                    }
                }
            ).catch(
                error => { console.log(error); }
            )
        }
    };
    function verificarConsumidorFinal(ie) {
        if (ie != null && ie != undefined)
            form.setFieldsValue({ 'ntf_consumidorfinal': 1 });
        else
            form.setFieldsValue({ 'ntf_consumidorfinal': null });
    }

    return (
        <div className="pages-col">
            <Spin spinning={carregarPagina} tip="Carregando...">
                <FormGW layout="vertical" name="formNotaFiscal" form={form} onFinish={validarDadosNota}>
                    <Row gutter={[8, 0]}>
                        {!!editando && <Col xs={24} sm={8} md={3} lg={3} xl={3}>
                            <Form.Item label="Nº da Nota" name="ntf_numero">
                                <Input disabled />
                            </Form.Item>
                        </Col>}
                        <Form.Item hidden name="interestadual"></Form.Item>
                        <Form.Item hidden name="vnc_id"></Form.Item>
                        <Form.Item hidden name="ped_id"></Form.Item>
                        <Col xs={24} sm={8} md={3} lg={4} xl={4}>
                            <Form.Item label="Série" name="ser_id">
                                <Select disabled={editando} allowClear placeholder="Selecione uma Série">
                                    {serie.map((ser) => (
                                        <Select.Option key={ser.ser_id} value={ser.ser_id}>{ser.ser_serie}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={editando ? 8 : 6} md={4} lg={4} xl={4}>
                            <Data label="Data de Emissão" disabled={editando} name="ntf_dataemissao" />
                        </Col>
                        <Col xs={24} sm={editando ? 12 : 10} md={editando ? 6 : 8} lg={editando ? 6 : 8} xl={editando ? 6 : 8}>
                            <Form.Item label="Operação" name="ope_id" rules={[{ required: true, message: 'Informe a Operação' }]}>
                                <SelectPaginado url="OperacaoFiscal/ListarOperacaoFiscal?SomenteAtivo=true" placeholder="Selecione a Operação Fiscal" form={form} name="ope_id" onChangeFunction={(idOperacao) => popularOperacaoFiscal(idOperacao)} conteudo={
                                    op => (<Select.Option value={op.ope_id} key={op.key}>{op.ope_descricao}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={editando ? 8 : 9} lg={editando ? 7 : 8} xl={editando ? 7 : 8}>
                            <Form.Item label="Indicador de Presença do Comprador" name="ntf_indicadorpresenca" rules={[{ required: true, message: 'Informe o Indicador de Presença' }]}>
                                <Select showSearch optionFilterProp="children" placeholder="Selecione Indicador de Presença do Comprador">
                                    {listaIndicadorPresenca?.map(item => (
                                        <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={8} xl={10} hidden={pages.tagPages !== 0}>
                            <Form.Item label="Vendedor" name="ven_id">
                                <Select placeholder="Selecione o Vendedor" onChange={() => { alterarVendedor() }}>
                                    {vendedores.map((vendedor) => (<Select.Option value={vendedor.ven_id} key={vendedor.ven_id}>{vendedor.pes_nome}</Select.Option>))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {editando && <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item label="Destinatário é consumidor Final?" name="ntf_consumidorfinal" rules={[{ required: true, message: 'Informe se a mercadoria é para consumidor final?' }]}>
                                <Select placeholder="Mercadoria é para consumidor final?">
                                    {consumidorFinal.map((c) => (<Select.Option value={c.key} key={c.key}>{c.value}</Select.Option>))}
                                </Select>
                            </Form.Item>
                        </Col>}
                        {!editando && pessoaFisica !== 1 && <Col xs={24} sm={24} md={12} lg={6} xl={6}>
                            <Form.Item label="Destinatário é consumidor Final?" name="ntf_consumidorfinal" rules={[{ required: true, message: 'Informe se a mercadoria é para consumidor final?' }]}>
                                <Select placeholder="Mercadoria é para consumidor final?">
                                    {consumidorFinal.map((c) => (<Select.Option value={c.key} key={c.key}>{c.value}</Select.Option>))}
                                </Select>
                            </Form.Item>
                        </Col>}
                    </Row>
                    <Row gutter={[0, 24]}>
                        <Col span={24}>
                            <Steps size="small" type="navigation" onChange={(e) => {
                                proximaPag.setProximaPag(e);
                            }} current={pages.tagPages}>
                                <Steps.Step title="Destinatário" />
                                <Steps.Step title="Itens" />
                                <Steps.Step title="Transporte" />
                                <Steps.Step title="Faturamento" />
                                <Steps.Step title="Resumo" />
                            </Steps>
                        </Col>
                    </Row>
                    <Row gutter={[8, 24]}>
                        <Col span={24}>
                            {pages.tagPages === 0 &&
                                <div className="m-t-16">
                                    <Row gutter={[8, 0]}>
                                        <Col xs={24} sm={12} md={12} lg={7} xl={7}>
                                            <Form.Item name="pes_id" label="Destinatário">
                                                <SelectPaginacao url="Cliente/Listar" placeholder="Selecione um Cliente" form={form} nameLabel="pes_nome"
                                                    funcaoIncluir={() => setOpenDrawerCliente(true)}
                                                    nameValue="pes_id" allowClear={true} onChangeFunction={(dados) => {
                                                        buscarDadosCliente(dados)
                                                    }} conteudo={
                                                        pc => (<Select.Option value={pc.pes_id} key={pc.key}>{pc.cli_codigo} - {pc.pes_nome} <br />
                                                            {(!!pc.pej_cnpj ? `CNPJ.: ${MaskFormat(pc.pej_cnpj, '', true)}` : !!pc.pef_cpf ? `CPF.: ${MaskFormat(pc.pef_cpf, '', true)}` : '')}
                                                        </Select.Option>)
                                                    } />
                                            </Form.Item>
                                            <Form.Item hidden name="pes_nome"></Form.Item>
                                        </Col>
                                        <Col xs={24} sm={6} md={6} lg={4} xl={4}>
                                            {pessoaFisica === 1 &&
                                                <InputCpfCnpj cpf label="CPF/CNPJ" name="ntf_cpfcnpj" rules={[{ required: true, message: 'Informe o Cpf/Cnpj' }]} disabled />
                                            }
                                            {pessoaFisica === 2 &&
                                                <InputCpfCnpj label="CPF/CNPJ" name="ntf_cpfcnpj" rules={[{ required: true, message: 'Informe o Cpf/Cnpj' }]} disabled />
                                            }
                                            {pessoaFisica === 3 &&
                                                <InputCpfCnpj mask='00.000.000-0' label="CPF/CNPJ" name="ntf_cpfcnpj" rules={[{ required: true, message: 'Informe o Cpf/Cnpj' }]} disabled />
                                            }
                                        </Col>
                                        <Col xs={24} sm={6} md={6} lg={4} xl={4}>
                                            <Form.Item label="Inscrição Estadual" name="pej_inscricaoestadual">
                                                <Input onBlur={event => { verificarConsumidorFinal(event.currentTarget.value) }}></Input>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8} md={4} lg={3} xl={3}>
                                            <SelectIncluir dadosSelect={telefone}
                                                funcaoBotao={() => { adicionarTelefone() }}
                                                campoDescricao={'psc_numero'}
                                                campoValue={'psc_id'}
                                                campokey={'psc_id'}
                                                placeHolder={'Selecione o Telefone'}
                                                labelFormItem={'Telefone'}
                                                nameFormItem={'telefoneDestinatario'} />
                                        </Col>
                                        <Col xs={24} sm={16} md={8} lg={6} xl={6}>
                                            <Form.Item label="E-mail" name="ntf_emaildestinatario">
                                                <Select disabled={email.length === 0} placeholder="Selecione o E-mail" >
                                                    {email.map((mail) => (
                                                        <Select.Option value={mail.pem_id} key={mail.pem_id}>{mail.pem_email}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={[8, 8]} className="m-t-16">
                                        <Col span={24}>
                                            <Typography.Title level={3}>
                                                <img src={require("../../assets/i-endereco.png").default} alt="Endereço" /> Endereço
                                            </Typography.Title>
                                            <Divider />
                                        </Col>
                                    </Row>
                                    <Row gutter={[8, 0]} className="m-t-8">
                                        {
                                            listaSubjacente.length > 0 &&
                                            <Col xs={18} sm={8} md={6} lg={6} xl={6}>
                                                <Form.Item label="Orgão Subjacente" name="ntf_subjacente" rules={[{ required: true, message: 'Selecione o Orgão Subjacente' }]}>
                                                    <Select placeholder="Selecione o Orgão Subjacente" onChange={(value) => tratarDadosSubjacente(value)} >
                                                        {listaSubjacente.map(
                                                            (sub) => (
                                                                <Select.Option value={sub.pop_id} key={sub.key}>{sub.pop_nome}</Select.Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        }

                                        <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                            <Form.Item label="Endereço" name="ntf_endereco" rules={[{ required: true, message: 'Informe o Endereço' }]}>
                                                <Select placeholder="Informe o Endereço" onChange={(value) => buscarDadosEndereco(value)} >
                                                    {dadosEndereco.map(
                                                        (endereco) => (
                                                            <Select.Option value={endereco.pee_id} key={endereco.pee_id}>{endereco.log_logradouro}{', '}{endereco.cid_descricao}{' - '}{endereco.est_sigla}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={6} md={4} lg={4} xl={4}>
                                            <Form.Item label="País" name="pse_id" rules={[{ required: true, message: 'Informe o País' }]}>
                                                <SelectPaginacao url="Pais/Listar" placeholder="Informe o País" form={form} nameValue="pse_id" nameLabel={"pse_nome"} conteudo={
                                                    pse => (<Select.Option value={pse.pse_id} key={pse.key}>{pse.pse_nome}</Select.Option>)
                                                } />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={6} md={3} lg={3} xl={3}>
                                            <InputCep label="CEP" name="cep_cep" rules={[{ required: true, message: 'Informe o CEP' }]} onBlur={event => { buscarCep(event.currentTarget.value) }} />
                                        </Col>
                                        <Col xs={24} sm={5} md={3} lg={3} xl={3}>
                                            <Form.Item className="text-center t-mob-573">
                                                <Typography.Link href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="_black" underline>
                                                    Não sei o CEP
                                                </Typography.Link>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={9} md={6} lg={6} xl={6}>
                                            {<Form.Item label="Cidade" name="cidade">
                                                <Input disabled={true}></Input>
                                            </Form.Item>}
                                        </Col>
                                        <Col xs={24} sm={6} md={6} lg={5} xl={5}>
                                            <Form.Item label="Bairro" name="bai_nome">
                                                <Input placeholder="Informe o Bairro" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={4} md={4} lg={3} xl={3}>
                                            <Form.Item label="Número" name="pee_numero">
                                                <Input placeholder="Informe o Número" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={8} md={5} lg={4} xl={4}>
                                            <Form.Item label="Complemento" name="pee_complemento">
                                                <Input placeholder="Informe o Complemento" maxLength={60} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            {pages.tagPages === 1 &&
                                <TabItens
                                    form={form}
                                    dadosItens={{ dadosItens, setDadosItens }}
                                    dadosEndereco={dadosEndereco.filter((end) => (end.pee_id === form.getFieldValue().ntf_endereco))[0]}
                                    dadosOperacao={operacaoFiscal}
                                    dadosContribuinte={dadosContribuinte}
                                    dadosVendedor={dadosVendedor}
                                    carregarPagina={setCarregarPagina} />
                            }
                            {pages.tagPages === 2 &&
                                <TabTransporte form={form} veiculos={{ listaVeiculos, setListaVeiculos }} dadosTransp={{ dadosTransp, setDadosTransp }} />
                            }
                            {pages.tagPages === 3 &&
                                <TabFaturamento form={form} dadosItens={dadosItens} dadosVendedor={dadosVendedor} dadosOperacao={operacaoFiscal} />
                            }
                            {pages.tagPages === 4 &&
                                <TabResumo form={form} itensNota={dadosItens} />
                            }
                        </Col>
                    </Row>
                    <DrawerCliente form={formEndereco} openDrawer={openDrawerCliente} setOpenDrawer={setOpenDrawerCliente} setCodigoCliente={setCodigoCliente} />
                    <ModalEndereco form={formEndereco} listaEndereco={{ dadosEndereco, setDadosEndereco }} exibirModalEndereco={openModalEndereco} fecharModalEndereco={oncloseModalEndereco} />
                    <ModalTelefone form={formularioTelefone} listaTelefones={{ dadosTelefone, setDadosTelefone }} exibirModalTelefone={abreModalTelefone} fecharModalTelefone={() => { onCloseModalTelefone() }} />
                    <ModalAliquotaSimplesNacional exibirModal={abreModalSimplesNac} fecharModal={onCloseModalAliquotaSimplesNacional} aposSalvar={onSuccessAliquotaSimplesNacional} />
                </FormGW>
            </Spin>
        </div>
    );
}