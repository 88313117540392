import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import { getClienteCat } from "../../../services/auth";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { Button, Modal } from "antd";
import formatNumber from "../../../ValueObjects/formatNumber";
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined, ShoppingOutlined } from "@ant-design/icons";
import { initDatabase, saveShoppingCart } from "../../../services/CarrinhoDB";
import { useHistory } from 'react-router-dom';
import { exibirMensagemException } from "../../../services/funcoes";


export default function ModalDadosPecas({ exibirModal, fecharModal, codigoPeca }) {
    const [dadosPeca, setDadosPeca] = useState({});
    const [dadosTabela, setDadosTabela] = useState([]);
    const [valorFinal, setValorFinal] = useState("");
    const history = useHistory();
    const listaShoppingCart = [];

    useEffect(() => {
        initDatabase();
    }, []);

    useEffect(() => {
        if (exibirModal && !!codigoPeca) {
            buscarDados();
        }
    }, [exibirModal]);

    const onCloseModal = () => {
        fecharModal()
    };

    function buscarDados() {
        api.get(`Cat007/BuscarInfoPeca?empresa=1&codCliente=${getClienteCat()}&codPeca=${codigoPeca}`).then(
            res => {
                let dados = { ...res.data };
                let listaDadosTab = [
                    createData("Preço Lista:", "R$ " + formatNumber(!!dados.precoLista ? dados.precoLista : 0, true), ""),
                    createData("(%) Desconto:", formatNumber(!!dados.percDescCli ? dados.percDescCli : 0, true), ""),
                    createData("Preço Líquido: ", "R$ " + formatNumber(!!dados.valorLiquido ? dados.valorLiquido : 0, true), ""),
                    createData("(%) IPI: ", formatNumber(!!dados.percIpi ? dados.percIpi : 0, true) + " (R$ " + formatNumber(!!dados.valIpi ? dados.valIpi : 0, true) + ")", ""),
                    createData("% MVA: ", formatNumber(!!dados.perMva ? dados.perMva : 0, false), ""),
                    createData("% ICMS: ", formatNumber(!!dados.percIcms ? dados.percIcms : 0, false) + " (R$ " + formatNumber(!!dados.valIcms ? dados.valIcms : 0, true) + ")", ""),
                    createData("% Red Base de Calculo ICMS: ", formatNumber(!!dados.percRedBaseCalcST ? dados.percRedBaseCalcST : 0, true), ""),
                    createData("Base Cálculo ICMS ST: ", formatNumber(!!dados.baseCalcST ? dados.baseCalcST : 0, false), ""),
                    createData("Valor do ICMS ST: ", "R$ " + formatNumber(!!dados.valST ? dados.valST : 0, true), ""),
                    createData("Preço Final: ", "R$ " + formatNumber(!!dados.precoFinal ? dados.precoFinal : 0, true), ""),
                    createData("Estoque: ", dados.estoque > 0 ? "ITEM DISPONÍVEL" : "ITEM EM PRODUÇÃO", dados.estoque > 0 ? "txtEstoqueAzul" : "txtEstoqueVermelho")
                ];
                setDadosTabela(listaDadosTab);
                setDadosPeca(dados);

                listaDadosTab.forEach(function (dado) {
                    if (dado.name === "Preço Final: ") {
                        setValorFinal(dado.value);
                        return;
                    }
                });
            }
        ).catch(err => {
            exibirMensagemException(err);
            fecharModal();
        })
    };

    function createData(name, value, style) {
        return { name, value, style }
    };

    function addShoppingCart(codigoPeca, descricaoPeca, qtd, valorPeca) {
        valorPeca = valorPeca.replace(/[^\d.,]/g, "");
        valorPeca = valorPeca.replace('.', '').replace(',', '.');
        const valor = parseFloat(valorPeca);

        saveShoppingCart(codigoPeca, descricaoPeca, qtd, valor)
            .then(() => {
                onCloseModal();
                Modal.confirm({
                    title: 'Sucesso',
                    icon: <CheckCircleOutlined />,
                    content: 'Item adicionado ao orçamento com sucesso.',
                    okText: 'Orçamento',
                    cancelText: 'Voltar',
                    centered: true,
                    onOk() {
                        history.replace('/carrinhoCatalogo')
                    }
                });
            })
            .catch((error) => {
                onCloseModal();
                Modal.confirm({
                    title: 'Aviso',
                    icon: <CloseCircleOutlined />,
                    content: 'Erro ao adicionar item ao carrinho',
                    okText: 'OK',
                    centered: true
                })
            })
    }

    return (<Modal
        title="Informações da Peça"
        visible={exibirModal}
        width={600}
        footer={null} >
        <div className="dvBordaFim">
            <p>Código da peça: <b>{dadosPeca.codPeca}</b></p>
            <p>Código Alternativo: <b>{dadosPeca.codigoAlternativo}</b></p>
            <p>Descrição: <b>{dadosPeca.descricao}</b></p>
            <p>Modelos/Inf Adicionais: <b>{dadosPeca.infAdicional}</b></p>
        </div>
        <div style={{ lineHeight: "5px", marginBottom: "10px" }}>
            <p>Unidade de Medida: <b>{dadosPeca.unidadeMedida}</b></p>
            <p>Qtde da Embalagem: <b>{dadosPeca.quantidade}</b></p>
            <p>Peso: <b>{formatNumber(!!dadosPeca.peso ? dadosPeca.peso : 0, true, 2)}</b></p>
            <p>NCM: <b>{dadosPeca.ncm}</b></p>
        </div>
        <Table size="small">
            <TableBody>
                {dadosTabela.map((row) => (
                    <TableRow
                        key={row.name}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        style={{ margin: 0 }}>
                        <TableCell>{row.name}</TableCell>
                        <TableCell className={row.style}>{row.value}</TableCell>
                    </TableRow>
                ))}
            </TableBody>

        </Table>
        <div className="dvFimModal">
            <p>Informações Cliente: <b>{dadosPeca.infoCliente}</b></p>
            <p>Cidade: <b>{dadosPeca.clienteCidade}</b></p>
            <p>CEP: <b>{dadosPeca.clienteCep}</b>  UF: <b>{dadosPeca.clienteUF}</b></p>
        </div>
        <div className="buttonsMocalPeca">
            <button className="btnFecharModal" onClick={() => onCloseModal()}>
                Fechar
            </button>
            <Button disabled={dadosPeca.estoque > 0 ? false : true } className="btnAddCart" onClick={() => addShoppingCart(dadosPeca.codPeca, dadosPeca.descricao, 1, valorFinal)}>
                <ShoppingOutlined /> Adicionar ao Orçamento
            </Button>
        </div>
    </Modal>)
}