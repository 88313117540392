import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Checkbox, Tabs, Alert, Button, Modal, notification } from "antd";
import { CloseOutlined, SaveOutlined, CheckCircleOutlined } from "@ant-design/icons";

import api from '../../services/api';
import { Data, FormGW } from "../../components";
import { useStateValue } from "../../state";
import { compararDatas, isObjetoDiffVazio } from "../../services/funcoes";
import { TabItens, TabCliente, TabVendedor } from "./tabPage";

export default function ManutencaoPreco({ formulario, carregando, setExibeButtons, setCarregando, aoSalvar, onCancel }) {

    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [somenteCabecalho, setSomenteCabecalho] = useState(false);
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [exibeItens, setExibeItens] = useState(false);
    const [dadosSalvos, setDadosSalvos] = useState({});
    const [listaItensExcluir, setListaItensExcluir] = useState([]);
    const [listaEditarItens, setListaEditarItens] = useState([]);
    const [editandoItens, setEditandoItens] = useState(false)
    useEffect(() => {
        if (ui.showDrawer) {
            if (!!manutencao.dados) {
                setExibeButtons(true);
                setExibeItens(true);
                manutencao.dados.listaEmpresa = (manutencao.dados.loc_id === null);
                if (manutencao.dados.loc_id === null)
                    formulario.setFieldsValue({ ltp_somenteestelocal: false });
                else
                    formulario.setFieldsValue({ ltp_somenteestelocal: true });
                formulario.setFieldsValue(manutencao.dados);
                setListaEditarItens([]);
                setListaItensExcluir([]);
            } else {
                setExibeButtons(false);
                setExibeItens(false);
                formulario.setFieldsValue({
                    ltp_ativa: true,
                    listaEmpresa: true,
                    ltp_somenteestelocal: false,
                    ltp_consideradescontopgto: false,
                    ltp_principal: false
                });
            }
        }
    }, [ui.showDrawer]);


    function modalInserirItem() {
        Modal.confirm({
            title: 'Lista de Preço Adicionada com sucesso!',
            icon: <CheckCircleOutlined />,
            content: 'Deseja adicionar os itens na lista de preço?',
            okText: 'Sim',
            cancelText: 'Não',
            onOk() {
                setExibeItens(true);
                setExibeButtons(true);
                return false;
            },
            onCancel() {
                aoSalvar();
            }
        })
    }

    const salvar = () => {
        if (editandoItens) {
            notification.warning({ description: "Favor finalizar as alterações dos itens antes de salvar", message: "Aviso" });
            return;
        }
        let values = isObjetoDiffVazio(dadosSalvos) ? dadosSalvos : formulario.getFieldsValue();
        values.listaItemsExcluir = listaItensExcluir;
        values.listaEditarItens = listaEditarItens;
        if ((!!values.listaItemsExcluir && values.listaItemsExcluir.length > 0) || (!!values.listaEditarItens && values.listaEditarItens.length > 0)) {
            values.listaprecoitens = [];
        } else {
            values.listaprecoitens = formulario.getFieldsValue().itens;
        }
        values.listaprecoclientes = values.clientes;
        values.listaprecovendedores = values.vendedores;
        if (!!values.ltp_datainicial && values.ltp_datafinal) {
            if (compararDatas(values.ltp_datainicial, values.ltp_datafinal, '>')) {
                notification.warning({ message: `Aviso!`, description: `Data Final não pode ser menor que a data Inicial!` })
                return false;
            }
        }

        setCarregando(true);
        if (!!values.ltp_id) {
            editarLista(values);
        } else {
            incluirLista(values);
        }
    };

    function editarLista(values) {
        api.put(`ListaPreco/EditarAsync`, values).then(
            res => {
                setDadosSalvos(res.data);
                aoSalvar();
            }).catch(
                err => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
                }).finally(() => {
                    notification.success({ message: `Os dados da Lista de Preço foram editados com sucesso!` });
                    setCarregando(false);
                });
    }

    function incluirLista(values) {
        api.post(`ListaPreco/IncluirAsync`, values).then(
            res => {
                setDadosSalvos(res.data);
                modalInserirItem();
                aoSalvar();
            }).catch(
                err => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
                }).finally(() => {
                    notification.success({ message: `Os dados da Lista de Preço foram incluídos com sucesso!` });
                    setCarregando(false);
                });
    }

    return (
        <div>
            <FormGW layout="vertical" form={formulario} name="manutencaoPreco" onFinish={salvar}>
                <Row gutter={[8, 0]}>
                    <Form.Item name="ltp_id" hidden />
                    <Form.Item name="itens" hidden />
                    <Form.Item name="clientes" hidden />
                    <Form.Item name="vendedores" hidden />
                    <Col xs={24} sm={12} md={14} lg={16} xl={16}>
                        <Form.Item label="Nome" name="ltp_nome" rules={[{ required: true, message: 'Informe o nome' }]}>
                            <Input placeholder="Informe o nome" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={5} lg={4} xl={4}>
                        <Data label="Data Inicial" name="ltp_datainicial" />
                    </Col>
                    <Col xs={24} sm={6} md={5} lg={4} xl={4}>
                        <Data label="Data Final" name="ltp_datafinal" />
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col>
                        <Form.Item
                            valuePropName="checked"
                            name="ltp_somenteestelocal">
                            <Checkbox> Lista de preço disponível apenas para esta filial </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name="ltp_consideradescontopgto"
                            valuePropName="checked">
                            <Checkbox> Considerar os descontos na condição de pagamento </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            valuePropName="checked"
                            name="ltp_principal">
                            <Checkbox> Lista Principal </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            valuePropName="checked"
                            name="ltp_ativa">
                            <Checkbox> Lista a Ativa </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Form.Item noStyle shouldUpdate={(prev, current) => prev.listaEmpresa !== current.listaEmpresa}>
                        {({ getFieldValue }) => {
                            if (getFieldValue("listaEmpresa"))
                                return (
                                    <Col span={24}>
                                        <Alert message="Esta lista de preço estará disponível para todas as filiais de sua empresa" type="warning" showIcon />
                                    </Col>
                                );
                            else 
                            return (
                                <Col span={24}>
                                    <Alert message="Esta lista de preço estará disponível apenas para esta filial" type="warning" showIcon />
                                </Col>
                            );
                        }}
                    </Form.Item>
                </Row>
            </FormGW>
            {!exibeItens &&
                <Row align="middle" justify="end" gutter={[8, 0]} className="m-t-16">
                    <Col>
                        <Button onClick={onCancel} icon={<CloseOutlined />} size="large" htmlType="button">
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={() => {
                            setSomenteCabecalho(true);
                            formulario.submit();
                        }}
                            loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                            Salvar
                        </Button>
                    </Col>
                </Row>
            }
            {exibeItens &&
                <Row gutter={[8, 8]} className="m-t-16">
                    <Col span={24}>
                        <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                            <Tabs.TabPane tab="Itens" key="1">
                                <TabItens formulario={formulario} listaItensExcluir={listaItensExcluir} setListaItensExcluir={setListaItensExcluir} listaEditarItens={listaEditarItens} setListaEditarItens={setListaEditarItens} setEditandoItens={setEditandoItens} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Cliente" key="2">
                                <TabCliente formulario={formulario} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Vendedor" key="3">
                                <TabVendedor formulario={formulario} />
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                </Row>
            }
        </div>
    );
}