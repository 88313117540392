import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Checkbox, Input, Form, Tabs, notification, Select } from 'antd';
import { ArrowRightOutlined, UploadOutlined } from '@ant-design/icons';

import api from '../../services/api';
import { useStateValue } from '../../state';
import { listagemActions } from '../../actions';
import { EditorFormularioRelatorio, FormGW } from '../../components';

export default function Formularios({ formulario, carregando, aoSalvar, grupos }) {
    const [{ listagem, manutencao }, dispatch] = useStateValue();
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [elementosFormulario, setElementosFormulario] = useState([]);
    const [aoUpload, setAoUpload] = useState(false);
    const [sistema, setSistema] = useState([]);

    function atribuiNomeRelatorio(nomeRel) {
        //formulario.setFieldsValue({ rel_nomearquivo: nomeRel.replace(' ','_')})
    }

    useEffect(() => {
        let sis = sistema.filter((s) => { return s.key === formulario.getFieldValue().rel_sistema });
        if (sis.length > 0) {
            if (!(!!formulario.getFieldValue().rel_sistema)) {
                formulario.setFieldsValue({ rel_sistema: sis[0].key });
            }
        }
    }, [sistema]);

    function carregarEnumSistema() {
        api.get(`Enum/Listar?nome=Sistema`).then(
            res => {
                if (res.status === 200) {
                    res.data = res.data.filter((item) => { return item.key > 0 });
                    setSistema(res.data);
                }
            }
        ).catch(
            (erro) => console.error(erro)
        );
    };

    function carregarDados() {
        carregando(false)
        if (!!formulario.getFieldValue().rel_id) {
            console.log(formulario.getFieldValue().rel_id);
            api.get(`RelatorioJasper/BuscarRelatorio/${formulario.getFieldValue().rel_id}`).then(
                res => {
                    console.log("BuscarRelatorio", res.data)
                    setElementosFormulario(!!res.data.relatorioParametro ? res.data.relatorioParametro : []);
                }).catch(
                    erro =>
                        console.error(erro)
                );

        } else {
            setElementosFormulario([]);
        }

        carregarEnumSistema();
    }

    const salvarManutencao = values => {
        carregando(true);
        aoSalvar();
        values.rel_nomearquivo = values.rel_nomearquivo.replace(/[\\"]/g, "/");
        let elementosOrdem = []
        elementosFormulario.map((elemento, indice) => {
            if (elemento.rpj_ordem != indice) {
                elemento.rpj_ordem = indice
                !!elemento.status == false ? elemento.status = 2 : elemento.status = elemento.status
            }
            elementosOrdem.push(elemento)
        })
        values.RelatorioParametro = elementosOrdem;
        console.log({ values });
        let url = 'Incluir'
        if (!!values.rel_id) {
            url = 'Editar';
            api.put(`RelatorioJasper/${url}`, values).then(
                res => {
                    notification.success({ message: 'Aviso', description: `Relatório Jasper ${url === 'Incluir' ? 'incluído' : 'editado'} com sucesso!` });
                }
            ).catch(
                erro => {
                    console.log(erro);
                    notification.error({ message: 'Erro ao Gravar Relatório Jasper.', description: JSON.stringify(erro.response) });
                }
            ).finally(
                () => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: listagem.ordem } });
                    carregando(false);
                    aoSalvar();
                });
        } else {
            api.post(`RelatorioJasper/${url}`, values).then(
                res => {
                    notification.success({ message: 'Aviso', description: `Relatório Jasper ${url === 'Incluir' ? 'incluído' : 'editado'} com sucesso!` });
                }
            ).catch(
                erro => {
                    console.log(erro);
                    notification.error({ message: 'Erro ao Gravar Relatório Jasper.', description: JSON.stringify(erro.response) });
                }
            ).finally(
                () => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: listagem.ordem } });
                    carregando(false);
                    aoSalvar();
                });
        }

    };

    function uploadRelatorio() {
        setAoUpload(true)
        let arquivoRel = formulario.getFieldValue().rel_nomearquivo;
        let sistema = formulario.getFieldValue().rel_sistema;
        api.post(`RelatorioJasper/UploadRelatorios?SalvarEm=${arquivoRel}&sistema=${sistema}`).then(
            res => {
                notification.success({ message: 'Aviso', description: `Upload do Relatório para o servidor realizado com sucesso!` });
            }
        ).catch(
            erro => {
                console.log(erro);
                notification.error({ message: 'Aviso', description: 'Erro ao Atualizar Relatório.\n' + erro })
            }
        ).finally(
            () => {
                setAoUpload(false)

            });
    }

    useEffect(() => carregarDados(), [!!formulario.getFieldValue().rel_id]);
    useEffect(() => console.log({ grupos }), []);

    return (
        <div className="pages-col personalize">
            <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                <Tabs.TabPane tab="Informações gerais" key="1">
                    <FormGW layout="vertical" form={formulario} name="manutencaoFormulario" onFinish={salvarManutencao}>
                        <Form.Item name="rel_id" hidden />
                        <Row gutter={[8, 0]}>
                            <Col span={24}>
                                <Form.Item label="Nome do Relatório" name="rel_nome" rules={[{ required: true, message: 'Informe o nome do Relatorio!' }]}>
                                    <Input onBlur={atribuiNomeRelatorio} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                <Form.Item label="Nome do Arquivo do Relatório" name="rel_nomearquivo" rules={[{ required: true, message: 'Informe o Nome do Arquivo Jasper do Relatório!' }]}>
                                    <Input placeholder="Informe o nome do Arquivo Jasper" />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={12} lg={12} xl={12} className="tt-5">
                                <Form.Item label="Grupo de Relatórios" name="grj_id" rules={[{ required: true, message: 'Selecione um Grupo para o Relatório!' }]}>
                                    <Select
                                        placeholder="Selecionar Grupo de Relatórios"
                                        showSearch
                                        optionFilterProp="children"
                                    >
                                        {grupos.map((grupo) => (
                                            <Select.Option value={grupo.grj_id} key={grupo.grj_id}> {grupo.grj_descricao}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8} className="tt-5">
                                <Form.Item label="Relatório do Sistema" name="rel_sistema" rules={[{ required: true, message: 'Selecione um sistema ao Relatório!' }]}>
                                    <Select
                                        placeholder="Selecione um sistema ao Relatório"
                                        showSearch
                                        optionFilterProp="children"
                                    >
                                        {sistema.map((sis) => (
                                            <Select.Option value={sis.key} key={sis.key}> {sis.value}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={5} lg={5} xl={5} className="tt-5">
                                <Button onClick={() => uploadRelatorio()} loading={aoUpload} block className="t-mob-573">
                                    Atualizar Relatório<UploadOutlined />
                                </Button>
                            </Col>
                            <Col xs={10} sm={8} md={8} lg={8} xl={8}>
                                <Form.Item name="rel_mostrarnolist" valuePropName="checked" className="t-mob-573">
                                    <Checkbox>Mostrar na Lista de Impressão?</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[0, 8]}>
                            <Col span={24}>
                                <Form.Item label="Descrição do Relatório" name="rel_descricao" rules={[{ required: true, message: 'Informe a descrição do Relatório!' }]}>
                                    <Input.TextArea rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center" className="m-t-8">
                            <Col xs={24} sm={12} md={10} lg={6} xl={6}>
                                <Button onClick={() => setAbaSelecionada("2")} size="middle" block>
                                    Ir para definição do formulário <ArrowRightOutlined />
                                </Button>
                            </Col>
                        </Row>
                    </FormGW>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Definição do formulário" key="2">
                    <EditorFormularioRelatorio elementosFormulario={elementosFormulario} setElementosFormulario={setElementosFormulario} />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
}
