import React, { useEffect, useState } from "react";
import { Row, Col, Descriptions, Form, Input, Modal, notification } from "antd";
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../../services/api";
import { getLocal } from "../../../services/auth";
import { MaskFormat, FormatNumber } from "../../../ValueObjects";

export default function ModalCancelamentoNfe({ dadosNota, exibirModalCancelarEstornar, fecharModalCancelarEstornar, titleModal, textoBotao }) {
    const [form] = Form.useForm();
    const [carregando, setCarregando] = useState(false);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                form.resetFields();
                fecharModalCancelarEstornar();
            }
        });
    };

    const realizarCancelamento = () => {
        let motivo = '';
        let dados = { ...dadosNota };
        dados.loc_id = parseInt(getLocal());
        dados.justificativa = form.getFieldValue().justificativa;
        if (!dados.justificativa || dados.justificativa.length < 15) {
            notification.warning({ message: `Aviso!`, description: `A Justificativa deve ter no mínimo 15 caracteres!` })
            return false;
        }
        setCarregando(true);
        api.post(`NotaFiscal/Cancelar`, dados).then(
            res => {
                if (dados.ntf_docfiscal == true) {
                    let _cStat = res.data.retorno.retEvento[0].infEvento.cStat;
                    motivo = _cStat + '-' + res.data.retorno.retEvento[0].infEvento.xMotivo;
                    if (_cStat == 135 || _cStat == 155 || _cStat == 101 || _cStat == 573 || _cStat == 218) {
                        notification.success({ message: `NF-e Nº: ${dadosNota.ntf_numero} cancelada com sucesso !`, description: motivo });
                    } else {
                        notification.warning({ message: `Não foi possível efetuar o cancelamento da NF-e Nº: ${dadosNota.ntf_numero} !`, description: motivo });
                    }
                } else {
                    if (res.data.retorno.cStat == 135)
                        notification.success({ message: `NF-e Nº: ${dadosNota.ntf_numero} cancelada com sucesso !` });
                    else
                        notification.warning({ message: `Não foi possível efetuar o cancelamento da NF-e Nº: ${dadosNota.ntf_numero} !`});
                }
            }
        ).catch(
            erro => {
                notification.warning({ description: `Não foi possível efetuar o cancelamento da NF-e Nº: ${dadosNota.ntf_numero} !`, message: 'Aviso' });
                setCarregando(false);
            }
        ).finally(
            () => {
                form.resetFields();
                setCarregando(false);
                fecharModalCancelarEstornar();
            }
        );
    };

    return (
        <Modal centered
            title={titleModal}
            visible={exibirModalCancelarEstornar}
            onCancel={onCloseModal}
            onOk={realizarCancelamento}
            confirmLoading={carregando}
            okText={
                <>
                    <CheckOutlined /> {textoBotao}
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            destroyOnClose
        >
            <Form layout="vertical" form={form}>
                <Descriptions size="small">
                    <Descriptions.Item label="Chave da NF-e">
                        <b> {dadosNota.ntf_chavenfe} </b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small">
                    <Descriptions.Item label="Destinatário">
                        <b> {dadosNota.ntf_nomedest}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="CPF/CNPJ">
                        <b>{MaskFormat(dadosNota.ntf_cnpjcpfdest, '', true)}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Nº da Nota">
                        <b>{dadosNota.ntf_numero}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="Série">
                        <b>{dadosNota.ntf_serie}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Modelo">
                        <b>{dadosNota.ntf_modelo}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                    <Descriptions.Item label="Emitida em">
                        <b>{moment(dadosNota.ntf_dataemissao).format('DD/MM/YYYY')}</b>
                    </Descriptions.Item>
                    <Descriptions.Item label="Tipo de Nota">
                        <b>{dadosNota.tip_descricao}</b>
                    </Descriptions.Item>
                </Descriptions>
                <Descriptions size="small">
                    <Descriptions.Item label="Valor Total da Nota (R$)">
                        <b> {FormatNumber(dadosNota.valor_total, true)} </b>
                    </Descriptions.Item>
                </Descriptions>
                <Row className="m-t-8">
                    <Col span={24}>
                        <Form.Item label="Motivo do Cancelamento" name="justificativa" rules={[{ required: true, message: `Informe a Justificativa do Cancelamento` }]} >
                            <Input.TextArea placeholder="Informe aqui o motivo do cancelamento da Nf-e" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}