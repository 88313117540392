import React, { useState } from "react";
import { Row, Col, Table, Button, Tag, Menu, Dropdown, Typography, Popover, Divider } from "antd";
import { MoreOutlined, PrinterOutlined, CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";

import { FormatNumber } from "../../ValueObjects";
import { ModalConfirmacaoTransferencia } from "../../components/modals";
import { novaAbaNavegador } from "../../services/funcoes";
import { TabelaDados } from "../../components";
import { getEmpresa, getIdUsuario, getLocal } from "../../services/auth";
import api from '../../services/api';

export default function TabNotaFiscalRecebida({ carregarDados }) {

    const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);
    const [dadosNota, setDadosNota] = useState({});

    function imprimir(dados) {
        if (!!dados.ntf_chavenfe) {
            novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${dados.ntf_chavenfe}`);
        }else{
            imprimirRelatorioNaoFiscal(dados);
        }
    }

    function imprimirRelatorioNaoFiscal(notaFiscal) {
        api.get(`RelatorioJasper/BuscarRelatorio?relatorioId=20`).then(
            retorno => {
                let parametros = [{ nome: "empresaLogado", valor: getEmpresa() }, { nome: "localLogado", valor: getLocal() }, { nome: "usuarioLogado", valor: getIdUsuario() }, { nome: 'idNotaFiscal', valor: notaFiscal.ntf_id }];
                api.post(`RelatorioJasper/RetornaDadosRelatorio?pathRelatorio=${retorno.data.rel_nomearquivo}&parametros=${JSON.stringify(parametros)}&tipo=PDF&nomeRelatorio=${retorno.data.rel_nome}`).then(
                    res => {
                        var byteCharacters = atob(res.data.fileContents);
                        var byteNumbers = new Array(byteCharacters.length);
                        for (var i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                        }
                        var byteArray = new Uint8Array(byteNumbers);
                        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
                        var fileURL = URL.createObjectURL(file);
                        window.open(fileURL);

                    }).catch(
                        erro => {
                            console.error(erro);
                            alert('Erro ao gerar Relatório: ' + erro);
                        }
                    );
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };
    function menuTable(record) {
        return (
            <Menu className="text-right">
                {record.statusTransferencia === 0 && record.ntf_status === 1 &&
                    <Menu.Item key="1">
                        <Button type="text" icon={<CheckOutlined />} onClick={() => { abrirModalConfirmacao(record) }}> Confirmar Transferência </Button>
                    </Menu.Item>
                }
                <Menu.Item key="2">
                    <Button type="text" icon={<PrinterOutlined />} onClick={() => { imprimir(record) }}> Imprimir </Button>
                </Menu.Item>
            </Menu>
        )
    }

    function abrirModalConfirmacao(record) {
        setDadosNota(record);
        setOpenModalConfirmacao(true);
    };

    return (
        <div className="tabela">
            <TabelaDados url="NotaFiscalTransferencia/Listar" colunas={[
                {
                    title: 'Nº',
                    width: 100,
                    align: 'center',
                    render: ({ ntf_numero }) => (
                        <div>
                            <b>
                                {ntf_numero}
                            </b>
                        </div>
                    ),
                },
                {
                    title: 'Status',
                    width: 100,
                    align: 'center',
                    render: ({ statusTransferencia }) => (
                        <div>
                            <Tag color={(statusTransferencia === 0 ? 'default' : 'green')} className="w-100">
                                <b> {statusTransferencia === 0 ? 'Pendente' : 'Transferido'} </b>
                            </Tag>
                        </div>
                    ),
                },
                {
                    title: 'Transferências Emitidas',
                    render: (record ,{ ntf_nomedest, ser_serie, ntf_dataemissao, ntf_chavenfe }) => (
                        <div>
                            <Row gutter={[16, 0]}>
                                <Col>
                                    <b>
                                        {ntf_nomedest}
                                    </b>
                                </Col>
                                <Col>
                                    Série: <b>{ser_serie}</b>
                                </Col>
                                <Col>
                                    Emissão: <b>{moment(ntf_dataemissao).format('DD/MM/YYYY')}</b>
                                </Col>
                            </Row>
                            <Row gutter={[16, 0]}>
                                {!(!!ntf_chavenfe) ?
                                    <Col>
                                        NF-e não gerada
                                    </Col> :
                                    <Col>
                                        NF-e:
                                        <Popover content={<a onClick={() => { imprimir(record) }}>Clique para visualizar o Danfe</a>}>
                                            <b>{ntf_chavenfe}</b>
                                        </Popover>
                                    </Col>
                                }
                            </Row>
                        </div>
                    ),
                },
                {
                    title: 'Total da Nota (R$)',
                    align: 'right',
                    render: ({ valor_total }) => (
                        <div>
                            <b className="f-18">{FormatNumber(valor_total, true)}</b>
                        </div>
                    ),
                },
                {
                    title: 'Ações',
                    dataIndex: '',
                    key: 'x',
                    align: 'center',
                    fixed: 'right',
                    width: 65,
                    render: (record) => (
                        <div>
                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                <Col>
                                    <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                        <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>
                    ),
                },
            ]} />
            <ModalConfirmacaoTransferencia dadosNota={dadosNota} exibirModal={openModalConfirmacao} fecharModal={() => { setOpenModalConfirmacao(false); carregarDados() }} />
        </div>
    );
}