import React, { useEffect, useState } from "react";
import { Modal, Form, notification } from "antd";
import { ExclamationCircleOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";

import { LancamentoCheque } from "../..";
import { useStateValue } from "../../../state";
import { manutencaoActions } from "../../../actions";

import api from "../../../services/api";


export default function ModalCheque({ form = null, exibirModal, fecharModal, dadosCheque, funcaoSalvar }) {
    const [formCheque] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [{ manutencao }, dispatch] = useStateValue();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
            }
        });
    };

    const salvarModalContaCorrente = (dados) => {
        //funcaoSalvar(dados);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ dadosModalCheque: { cheques: dados.cheques, contaCorrente: dados.ctc_id } } } } });
        //formCheque.resetFields();
        fecharModal();
    };

    useEffect(() => {
        if (exibirModal) {
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ dadosModalCheque: { vemDaNota: true, quantidadeCheque: dadosCheque.numeroCheques, valorTotalCheque: dadosCheque.pfp_valor, cliente: dadosCheque.cli_id } } } } });
            if (form != null) formCheque.setFieldsValue({ pes_id: form.getFieldValue().pes_id });
        }
    }, [exibirModal]);
    
    return (
        <Modal centered
            title="Lançamento de cheque"
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={() => { formCheque.submit() }}
            width={1000}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }  >
            <LancamentoCheque form={formCheque} carregando={setCarregando} funcaoSalvar={salvarModalContaCorrente} />
        </Modal>
    );
}