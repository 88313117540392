import React from 'react';
import { Modal } from "antd";

import { useStateValue } from '../../state';
import { manutencaoActions } from '../../actions';
import { DetalhePedido } from "../../components";

export default function ModalDetalhePedido({ exibirModal, fecharModal, dadosModal }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();

    const onClose = () => {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        fecharModal();
    };

    return (

        <Modal destroyOnClose title="Detalhes do Pedido"
            width="70%"
            onCancel={onClose} 
            footer={null}
            maskClosable={false}
            visible={exibirModal}>
            <DetalhePedido dadosModal={dadosModal} />
        </Modal>

    )
}