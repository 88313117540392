import React, { useState } from 'react';
import { Row, Col, Button, Drawer, Modal, Form } from "antd";
import ManutencaoTransportador from '../../pages/transportadores/manutencao';
import { CloseOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';

export default function DrawerTransportadores({ form, exibirDrawerTransportadores, fecharDrawerTransportadores }) {

    const [formulario] = Form.useForm();
    const [carregando, setCarregando] = useState(false);


    function fecharDrawer() {
        form.setFieldsValue(formulario.getFieldsValue());
        fecharDrawerTransportadores();
        formulario.resetFields();
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <Drawer width="60%"
            title="Cadastro de Transportadores"
            visible={exibirDrawerTransportadores}
            fecharDrawer={() => { fecharDrawer() }}
            onOk={() => formulario.submit()}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={() => onClose()} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formulario.submit()} icon={<SaveOutlined />} loading={carregando} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoTransportador formulario={formulario} editando={false} aoSalvar={fecharDrawer} carregando={setCarregando} />
        </Drawer>

    )
};