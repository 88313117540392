import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Select, Form, Input, InputNumber, notification } from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { isNotNullOrEmpty, isObjetoDiffVazio } from "../../../services/funcoes";
import { useStateValue } from "../../../state";
import SelectPaginacao from "../../selects/selectPaginacao";
import { selectPaginadoActions } from "../../../actions";

export default function ModalManutencaoNota({ exibirModal, fecharModal, dadosContribuinte, dadosOperacao, dadosEndereco, dadosProduto, aoSalvar }) {
    const [form] = Form.useForm();
    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [mostrarCstIcms, setMostrarCstIcms] = useState(false);
    const [mostarCsoSn, setMostrarCsoSn] = useState(false);
    const [mostrarAlicotaIcms, setMostrarAlicotaIcms] = useState(false);
    const [mostrarReducaoBC, setMostrarReducaoBC] = useState(false);
    const [mostrarDiferimentoIcms, setMostrarDiferimentoIcms] = useState(false);
    const [dadosNota, setDadosNota] = useState({});
    const [notaTransferencia, setNotaTransferencia] = useState(false);
    const [exibePerc, setExibePerc] = useState(false);
    const [estadoOrig, setEstadoOrig] = useState({});
    const [estadoDest, setEstadoDest] = useState({});

    const onFinish = values => {
        values.loc_id = dadosContribuinte.loc_id;
        values.pro_id = dadosProduto.pro_id;
        if (exibePerc) {
            values.mva_uforigem = values.iod_uforigem
            values.mva_ufdestino = values.iod_ufdestino;
        } else {
            values.mva_percmva = 0;
            values.mva_percaliquotaicms = 0;
            values.mva_percaliquotaicmsreducao = 0;
        }
        values.iod_percicms = !!form.getFieldValue().iod_percicms ? form.getFieldValue().iod_percicms : 0;
        values.iod_percicmsreducaobc = !!form.getFieldValue().iod_percicmsreducaobc ? form.getFieldValue().iod_percicmsreducaobc : 0;
        values.iod_percdiferimentoicms = !!form.getFieldValue().iod_percdiferimentoicms ? form.getFieldValue().iod_percdiferimentoicms : 0;

        api.post('TributacaoIcms/AtualizarTributacaoProduto', values).then(
            (res) => {
                notification.success({ message: 'Sucesso', description: 'Produto alterado com sucesso!' });
                form.resetFields();
                aoSalvar(dadosProduto.pro_id);
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function validarCst(cst_id) {
        let codigoCst;
        if (!!cst_id) {
            codigoCst = cst_id;
        } else {
            codigoCst = form.getFieldValue('cst_id');
        }
        if (!!codigoCst) {
            if (codigoCst <= '20' || codigoCst === '90' || codigoCst === '51' || codigoCst === '70') {
                setMostrarAlicotaIcms(true);
            } else {
                setMostrarAlicotaIcms(false);
                form.setFieldsValue({ iod_percicms: 0 });
            }

            if (codigoCst === '90' || codigoCst === '20' || codigoCst === '70') {
                setMostrarReducaoBC(true);
            } else {
                setMostrarReducaoBC(false);
                form.setFieldsValue({ iod_percicmsreducaobc: 0 });
            }

            if (codigoCst === '90' || codigoCst === '51') {
                setMostrarDiferimentoIcms(true);
            } else {
                setMostrarDiferimentoIcms(false);
                form.setFieldsValue({ iod_percdiferimentoicms: 0 });
            }

            if (codigoCst === '10' || codigoCst === '30' || codigoCst === '70') {
                setExibePerc(true);
            }
        } else {
            setMostrarAlicotaIcms(true);
            setMostrarReducaoBC(true);
            setMostrarDiferimentoIcms(true);
        }
    }

    useEffect(() => {
        carregarDados()
    }, [exibirModal]);

    function carregarDados() {
        if (isObjetoDiffVazio(dadosContribuinte) && !!dadosOperacao && !!dadosEndereco) {
            if (dadosContribuinte.emp_regimetributario === 3) {
                if (!!!dadosProduto.cst_id){
                    setMostrarCstIcms(true);
                }
                setMostrarCsoSn(false);
            } else {
                if (!!dadosProduto.cst_id)
                setMostrarCstIcms(false);
                setMostrarCsoSn(true);
            }
            dadosCampos();
            buscarEstados();
        }
    };

    function dadosCampos() {
        if (mostrarCstIcms) {
            if (!!dadosProduto.cst_id) {
                if (dadosProduto.cst_id <= 20 || dadosProduto.cst_id === 90 || dadosProduto.cst_id === 51 || dadosProduto.cst_id === 70) {
                    setMostrarAlicotaIcms(true);
                } else {
                    setMostrarAlicotaIcms(false);
                }

                if (dadosProduto.cst_id === 90 || dadosProduto.cst_id === 20 || dadosProduto.cst_id === 70) {
                    setMostrarReducaoBC(true);
                } else {
                    setMostrarReducaoBC(false);
                }

                if (dadosProduto.cst_id === 90 || dadosProduto.cst_id === 51) {
                    setMostrarDiferimentoIcms(true);
                } else {
                    setMostrarDiferimentoIcms(false);
                }
            } else {
                setMostrarAlicotaIcms(true);
                setMostrarReducaoBC(true);
                setMostrarDiferimentoIcms(true);
            }
        }
        if (!!dadosProduto && !!dadosProduto.cst_id && (dadosProduto.cst_id === '10' || dadosProduto.cst_id === '30' || dadosProduto.cst_id === '70')) {
            setExibePerc(true);
        }
        setDadosNota(dadosProduto);
        let lista = [];
        if (!!dadosProduto.icm_id) {
            lista.push({ name: "icm_id", campo: "Codigo", value: dadosProduto.icm_id });
            dispatch({ type: selectPaginadoActions.CHANGE, data: lista });
            form.setFieldsValue({ icm_id: dadosProduto.icm_id });
        }

    };

    function buscarEstados() {
        api.get(`Estado/Listar?PageSize=200`).then(
            (res) => {
                if (res.status === 200) {
                    let listaEst = [...res.data.items];
                    if (listaEst.length > 0) {
                        let estadoOrigem = listaEst.filter((estado) => (estado.est_sigla === dadosContribuinte.est_sigla))[0];
                        setEstadoOrig(estadoOrigem);
                        form.setFieldsValue({ iod_uforigem: estadoOrigem.est_sigla });
                        if (isObjetoDiffVazio(dadosEndereco)) {
                            let estadoDestino = listaEst.filter((estado) => (estado.est_sigla === dadosEndereco.est_sigla))[0];
                            setEstadoDest(estadoDestino);
                            form.setFieldsValue({ iod_ufdestino: estadoDestino.est_sigla });
                        } else {
                            setEstadoDest(estadoOrigem);
                        }
                    }
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };

    return (
        <Modal centered
            visible={exibirModal}
            onCancel={() => {
                form.resetFields();
                fecharModal();
            }}
            onOk={() => {
                form.submit();
                fecharModal();
            }}
            okText={
                <>
                    <PlusOutlined /> Adicionar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="manutencaoEndereco" form={form} onFinish={onFinish} >
                    <Row gutter={[0, 16]}>
                        <Col>
                            O Item selecionado não possui as informações necessárias para gerar uma NF-e! <br />
                            Favor complementar abaixo as informações do cadastro deste item:
                        </Col>
                    </Row>
                    {!!dadosNota && dadosNota.pro_descricao === '' && <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <Form.Item name="pro_descricao" label="Descrição">
                                <Input placeholder="Informe a Descrição do Produto/Mercadoria" />
                            </Form.Item>
                        </Col>
                    </Row>}
                    <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <Form.Item name="icm_id" label="Tributação ICMS (Imposto Sobre Circulação de Mercadorias e Serviços)" >
                                <SelectPaginacao url="TributacaoIcms/Listar" disabled={!!dadosProduto?.icm_id} form={form} showSearch placeholder="Tributação Icms" optionFilterProp="children" nameLabel="icm_descricao" nameValue="icm_id"
                                    conteudo={(icm) => (<Select.Option value={icm?.icm_id} key={icm?.icm_id}>{icm?.icm_id} - {icm?.icm_descricao}</Select.Option>)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    {!!dadosNota && dadosNota.orm_id === null && <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <Form.Item name="orm_id" label="Código de Situação Tributária Origem">
                                <SelectPaginacao url="CstOrigemMercadoria/Listar" form={form} showSearch placeholder="Selecione um CST origem" optionFilterProp="children" nameLabel="orm_descricao" nameValue="orm_id"
                                    conteudo={(cst) => (<Select.Option value={cst?.orm_id} key={cst?.orm_id}>{cst?.orm_id} - {cst?.orm_descricao}</Select.Option>)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>}
                    <Row gutter={[8, 8]}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item name="iod_uforigem" label="UF de Origem">
                                <Select showSearch disabled={!!estadoOrig?.est_sigla} placeholder="Selecione o Estado de Origem" optionFilterProp="children" >
                                    <Select.Option value={estadoOrig?.est_sigla} key={estadoOrig?.est_id}>{estadoOrig?.est_sigla} - {estadoOrig?.est_descricao}</Select.Option>))
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item name="iod_ufdestino" label="UF de Destino">
                                <Select showSearch disabled={!!estadoDest?.est_sigla} placeholder="Selecione o Estado de Destino" optionFilterProp="children">
                                    <Select.Option value={estadoDest?.est_sigla} key={estadoDest?.est_id}>{estadoDest?.est_sigla} - {estadoDest?.est_descricao}</Select.Option>))
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {!!mostrarCstIcms && <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <Form.Item name="cst_id" label="CST (Código de Situação Tributária)">
                                <SelectPaginacao url="Csticms/Listar" form={form} showSearch placeholder="Selecione o CST" optionFilterProp="children" nameLabel="cst_id" nameValue="cst_id"
                                    onChangeFunction={(valor) => { validarCst(valor) }}
                                    conteudo={(cst) => (<Select.Option value={cst.cst_id} key={cst.cst_id}>{cst.cst_id}</Select.Option>)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>}
                    {!!mostarCsoSn && <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <Form.Item name="cso_id" label="CSOSN (Código de Situação da Operação no Simples Nacional)">
                                <SelectPaginacao url="Csosn/Listar" showSearch placeholder="Selecione o CSOSN" optionFilterProp="children" form={form} nameValue="cso_id" nameLabel="cso_descricao"
                                    conteudo={(csosn) => (<Select.Option value={csosn.cso_id} key={csosn.cso_id}>{csosn.cso_id} - {csosn.cso_descricao}</Select.Option>)} />
                            </Form.Item>
                        </Col>
                    </Row>}
                    {!!dadosNota && dadosNota.ncm_id === null && <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <Form.Item name="ncm_id" label="NCM (Nomenclatura Comum do Mercosul)">
                                <SelectPaginacao url="Ncm/Listar" showSearch placeholder="Selecione um NCM" optionFilterProp="children" form={form} nameValue="ncm_id" nameLabel="ncm_descricao"
                                    conteudo={(ncm) => (<Select.Option value={ncm.ncm_id} key={ncm.ncm_id}>{ncm.ncm_codigo?.codigoNcm} - {ncm.ncm_descricao}</Select.Option>)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>}
                    {!!dadosNota && dadosNota.ump_id === null && <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <Form.Item name="ump_id" label="Unidade de Medida">
                                <SelectPaginacao url="UnidadeMedida/Listar" showSearch placeholder="Selecione uma Unidade de Medida" optionFilterProp="children" form={form} nameValue="ump_id" nameLabel="ump_Descricao"
                                    conteudo={(unidadeMedida) => (<Select.Option value={unidadeMedida.ump_id} key={unidadeMedida.ump_id}>{unidadeMedida.ump_id} - {unidadeMedida.ump_descricao}</Select.Option>)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>}
                    {!!dadosNota && dadosNota.pis_id === null && !!dadosContribuinte && (dadosContribuinte.emp_regimetributario === 3 || !!notaTransferencia) && <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <Form.Item name="pis_id" label="Classificação PIS/COFINS'">
                                <SelectPaginacao url="TributacaoPisCofins/ListarAtivosPg" showSearch placeholder="Selecione um Código de Tributação" optionFilterProp="children" form={form} nameValue="pis_id" nameLabel="pis_descricao"
                                    conteudo={(tribPisCofins) => (<Select.Option value={tribPisCofins.pis_id} key={tribPisCofins.pis_id}>{tribPisCofins.pis_id} - {tribPisCofins.pis_descricao}</Select.Option>)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>}
                    {mostrarAlicotaIcms && <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <Form.Item name="iod_percicms" label="% do ICMS">
                                <InputNumber
                                    placeholder="Informe o Percentual do ICMS"
                                    defaultValue={0}
                                    precision={2}
                                    min={0}
                                    max={100}
                                    formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>}
                    {mostrarReducaoBC && <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <Form.Item name="iod_percicmsreducaobc" label="% Redução Base de Cálculo">
                                <InputNumber
                                    placeholder="Informe o Percentual de Redução Base de Cálculo"
                                    defaultValue={0}
                                    precision={2}
                                    min={0}
                                    max={100}
                                    formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>}
                    {mostrarDiferimentoIcms && <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <Form.Item name="iod_percdiferimentoicms" label="% Diferimento do ICMS">
                                <InputNumber
                                    placeholder="Informe o Diferimento do ICMS"
                                    defaultValue={0}
                                    precision={2}
                                    min={0}
                                    max={100}
                                    formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>}
                    {exibePerc && <div>
                        <Row gutter={[0, 8]}>
                            <Col span={24}>
                                <Form.Item name="mva_percmva" label="% do MVA">
                                    <InputNumber
                                        placeholder="Informe o Percentual do MVA"
                                        defaultValue={0}
                                        precision={2}
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[0, 8]}>
                            <Col span={24}>
                                <Form.Item name="mva_percaliquotaicms" label="% do ICMS da Substituição Tributária">
                                    <InputNumber
                                        placeholder="Informe o Percentual do ICMS da Substituição Tributária"
                                        defaultValue={0}
                                        precision={2}
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[0, 8]}>
                            <Col span={24}>
                                <Form.Item name="mva_percaliquotaicmsreducao" label="% Base de Cálculo Reduzida">
                                    <InputNumber
                                        placeholder="Informe o Percentual de Base de cálculo Reduzida"
                                        defaultValue={0}
                                        precision={2}
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>}
                </Form>
            </div>
        </Modal >
    );

};