import React, { useEffect, useState } from "react";
import { Row, Col, Form, Table, Input, Select, Descriptions, notification, Button, Typography, Tag } from "antd";
import moment from 'moment';

import api from "../../services/api";
import { FormatNumber } from "../../ValueObjects";
import { ModalContaCorrente, ModalEditarNotaFiscalEntrada } from "../../components/modals";
import { Data, FormularioDinamico, InputPreco, SelectPaginado, SelectPaginacao } from "../../components";
import { EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { diferencaDatas } from "../../services/funcoes";
import { DeleteOutlineOutlined } from "@material-ui/icons";

export default function TabFaturamento({ dadosFaturamento = [], faturamentoDados }) {
    const [openModalEditarNotaFiscalEntrada, setOpenModalEditarNotaFiscalEntrada] = useState(false);
    const [objFaturamento, setObjFaturamento] = useState([]);
    const [form] = Form.useForm();
    const [abreModalContaCorrente, setAbreModalContaCorrente] = useState(false);
    const [listaContaCorrente, setListaContaCorrente] = useState([]);
    const [semCondicaoPagamento, setSemCondicaoPagamento] = useState(false);
    const [listaCondicoesPagamento, setListaCondicoesPagamento] = useState([]);
    const [listaFormaPagamento, setListaFormaPagamento] = useState([]);
    const [contaCorrente, setContaCorrente] = useState({});
    const [editandoParcela, setEditandoParcela] = useState(false);
    const [parcelasGeradas, setParcelasGeradas] = useState([]);
    const [valorTotal, setValorTotal] = useState(0)
    const [renderizar, setRenderizar] = useState(false);

    function nomeFormaPagamento(formaPag) {
        switch (formaPag) {
            case 1:
                return "Dinheiro";
            case 2:
                return "Cheque";
            case 3:
                return "Cartão Crédito";
            case 4:
                return "Cartão Débito"
            case 5:
                return "Crediário Loja"
            case 10:
                return "Vale Alimentação";
            case 11:
                return "Vale Refeição";
            case 12:
                return "Vale Presente";
            case 13:
                return "Vale Combustível";
            case 14:
                return "Duplicata Mercantil";
            case 15:
                return "Boleto";
            case 16:
                return "Depósito Bancário";
            case 17:
                return "Pagamento Instantâneo (PIX)";
            case 18:
                return "Transferência Bancária, Carteira Digital";
            case 19:
                return "Programa de fidelidade, Cashback, Crédito Virtual";
            case 90:
                return "Sem Pagamento";
            default:
                return "Outros";
        }
    };

    function nomeTipoPagamento(tipoPag) {
        switch (tipoPag) {
            case 1:
                return "A Prazo";
            default:
                return "A Vista";
        }
    };

    useEffect(() => {
        if (!!dadosFaturamento.dadosFaturamento && objFaturamento.length < 1) {
            const objetoConsolidado = dadosFaturamento.dadosFaturamento[0].reduce((acumulador, array) => {
                array.forEach(objeto => {
                    acumulador[objeto.nome] = objeto.valor;
                });
                return acumulador;
            }, {});

            objFaturamento.push(objetoConsolidado);
            //VER
            objFaturamento[0].tipopagamento = nomeFormaPagamento(objFaturamento[0].fpg_tipopagamento);
            objFaturamento[0].tipoavistaaprazo = nomeTipoPagamento(objFaturamento[0].fpg_tipoavistaaprazo);
            if (objFaturamento[0].nfp_valor == null) {
                form.setFieldsValue({ nfp_valor: 0 });
            } else {
                form.setFieldsValue({ nfp_valor: objFaturamento[0].nfp_valor });
                setValorTotal(objFaturamento[0].nfp_valor);
            }
        }
    }, [dadosFaturamento]);

    function salvarParcela() {
        setEditandoParcela(false);
        notification.success({ message: 'Aviso!', description: 'Parcelas editadas com sucesso.'})
    }

    function onCloseModalContaCorrente() {
        let ultimaForma = dadosFaturamento.dadosFaturamento[dadosFaturamento.dadosFaturamento.length - 1];
        ultimaForma.ctc_id = contaCorrente.ctc_id;
        ultimaForma.ctc_descricao = contaCorrente.ctc_descricao;
        ultimaForma.valorFechou = true;
        setObjFaturamento(dadosFaturamento.dadosFaturamento[0]);
        setAbreModalContaCorrente(false);
    }
    function DeveAbrirModalContaCorrente(fpg_tipopagamento) {
        switch (fpg_tipopagamento) {
            case 1:
            case 2:
            case 17:
            case 18:
                setAbreModalContaCorrente(true);
                break;
            default:
                setAbreModalContaCorrente(false);
                break;
        }
    }

    function buscarCondicaoPagamento(idFormaPgto, id) {
        if (listaFormaPagamento.length > 0) {
            let _lista = [...listaFormaPagamento]
            if (!!form.getFieldValue().fpg_id) {
                let formaPgto = _lista.find(c => c.fpg_id == idFormaPgto)
                DeveAbrirModalContaCorrente(formaPgto.fpg_tipopagamento);
            }
        }
        api.get(`CondicaoPagamento/Listar?filtro=&codigoFormaPagamento=${idFormaPgto}&Order=%2Bcpg_descricao&PageSize=500`).then(
            res => {
                if (res.data?.items.length === 0) {
                    setSemCondicaoPagamento(true);
                    setParcelasGeradas([]);
                    form.setFieldsValue({ cpg_id: null });
                } else {
                    setSemCondicaoPagamento(false);
                }
                setListaCondicoesPagamento(res.data.items);
            }

        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function verificarParcelas(cpg_id) {
        api.get(`CondicaoPagamento/ListarParcelamento?codigoCondicaoPagamento=${cpg_id}`).then(
            res => {
                if (res.data.length > 0) {
                    montarParcelas(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    async function montarParcelas(qtdeParcelas) {
        let listaParcelas = [];
        let nfp_valor = form.getFieldValue().nfp_valor;
        let valorParcela = nfp_valor / qtdeParcelas.length;
        let resto = valorParcela - parseFloat(valorParcela.toFixed(2));
        valorParcela = parseFloat((valorParcela - resto).toFixed(2));
        resto = resto * qtdeParcelas.length;
        qtdeParcelas.forEach((parc, idx, array) => {
            let parcela = {};
            let dataAtual = new Date();
            parcela.prc_numeroparcela = parc.pcl_numeroparcela;

            parcela.prc_datavencimento = new Date(dataAtual.setDate(dataAtual.getDate() + parc.pcl_numerodias));
            parcela.pcl_numerodias = parc.pcl_numerodias;
            parcela.prc_numeroDuplicata = `${parcela.prc_numeroparcela}/${qtdeParcelas.length}`;
            if (idx === array.length - 1) {
                parcela.prc_valor = parseFloat((valorParcela + (resto)).toFixed(2));
            } else {
                parcela.prc_valor = valorParcela;
            }
            parcela.prc_valor = parseFloat(parcela.prc_valor.toFixed(2));
            listaParcelas.push(parcela);
        });
        setParcelasGeradas(listaParcelas);
        if (listaParcelas.length > 0) {
            alteraForm(listaParcelas);
        }
    };

    function alteraForm(listaParcelas) {
        listaParcelas.forEach((parc) => {
            form.setFieldsValue({ [`numeroDuplicata${parc.prc_numeroparcela}`]: parc.prc_numeroDuplicata });
            form.setFieldsValue({ [`prc_datavencimento${parc.prc_numeroparcela}`]: parc.prc_datavencimento });
            form.setFieldsValue({ [`prc_valor${parc.prc_numeroparcela}`]: parc.prc_valor });
        })
    };

    function ajustarDatasParcelas(record, idx) {
        let listaTemp = [...parcelasGeradas];
        listaTemp[idx].prc_datavencimento = moment(new Date(form.getFieldValue(`prc_datavencimento${idx + 1}`)));
        for (let i = idx; i < listaTemp.length; i++) {
            if (listaTemp[i + 1] != null) {
                let dataVencimento = moment(new Date(new Date(form.getFieldValue(`prc_datavencimento${i + 1}`)).setDate(new Date(form.getFieldValue(`prc_datavencimento${i + 1}`)).getDate() + 30)));
                form.setFieldsValue({ [`prc_datavencimento${i + 2}`]: dataVencimento });
                listaTemp[i + 1].prc_datavencimento = moment(new Date(new Date(listaTemp[i].prc_datavencimento).setDate(new Date(listaTemp[i].prc_datavencimento).getDate() + 30)));
            }
        }
        setParcelasGeradas(listaTemp);
    }

    function onChangevalorParcela(record) {
        let valorJaUtilizado = 0;
        let valorRestante = 0;
        let valorRestanteTot = 0;
        let valorParcelas = 0;
        let qtdeTotParcelas = parcelasGeradas.length;
        let dados = form.getFieldsValue();
        for (let i = 1; i <= record; i++) {
            valorJaUtilizado += parseFloat(dados[`prc_valor${i}`]);
        }
        valorRestante = valorTotal - valorJaUtilizado;
        valorParcelas = parseFloat((valorRestante / (qtdeTotParcelas - record)).toFixed(2));
        for (let i = qtdeTotParcelas; i > record; i--) {
            valorRestanteTot += valorParcelas;
            form.setFieldsValue({ [`prc_valor${i}`]: valorParcelas });
        }
        let listaTemp = [...parcelasGeradas];
        for (let a = 0; a < qtdeTotParcelas; a++) {
            listaTemp[a].prc_valor = form.getFieldValue(`prc_valor${a + 1}`);
        }
        setParcelasGeradas(listaTemp);
        let valorSobra = valorTotal - (valorJaUtilizado + valorRestanteTot);
        form.setFieldsValue({ [`prc_valor${qtdeTotParcelas}`]: parseFloat((valorParcelas + valorSobra).toFixed(2)) });
    }

    async function adicionarFormaDePagamento() {
        let listaTemp = [...faturamentoDados.faturamentoDados];
        let valorFormasPagamento;
        listaTemp.forEach((item) => {
            valorFormasPagamento += item.nfp_valor;
        });

        if (valorFormasPagamento < valorTotal || valorFormasPagamento > valorTotal) {
            notification.warning({ message: 'Aviso!', description: 'Valor Total inválido!' });
            return false
        }

        let pagamento = {};
        let dadosFormaPagamento = (await api.get(`FormaPagamento/Get?Id=${form.getFieldValue().fpg_id}`)).data;
        pagamento.fpg_descricao = dadosFormaPagamento.fpg_descricao;
        pagamento.cpg_id = form.getFieldValue().cpg_id;
        pagamento.cpg_descricao = !!pagamento.cpg_id ? listaCondicoesPagamento.filter(x => x.cpg_id === pagamento.cpg_id)[0].cpg_descricao : '';
        pagamento.fpg_id = form.getFieldValue().fpg_id;
        pagamento.ctc_id = contaCorrente?.ctc_id;
        pagamento.parcelas = parcelasGeradas;
        pagamento.nfp_valor = form.getFieldValue().nfp_valor;
        pagamento.key = listaTemp.length;
        listaTemp.push(pagamento);
        faturamentoDados.setFaturamentoDados(listaTemp);
        form.setFieldsValue({ nfp_valor: valorTotal - (!!valorFormasPagamento ? valorFormasPagamento : pagamento.nfp_valor), fpg_id: null, cpg_id: null });
        setParcelasGeradas([]);
        setSemCondicaoPagamento(false);
        setContaCorrente({});
    }

    function excluirFormaPagaento(record) {
        let listaTemp = [...faturamentoDados.faturamentoDados];
        listaTemp.splice(record, 1);

        if (listaTemp.length === 0) {
            faturamentoDados.setFaturamentoDados([])
            form.setFieldsValue({ nfp_valor: valorTotal });
        } else {
            let key = 0;
            let valorPago = 0;
            listaTemp.forEach((item) => {
                item.key = key++;
                valorPago += item.nfp_valor;
            });
            form.getFieldValue({ nfp_valor: valorTotal - valorPago });
            faturamentoDados.setFaturamentoDados(listaTemp);
        }

    }

    return (
        <div>
            <div>
                <Row gutter={[8, 0]}>
                    <Col span={24}>
                        <Row align="middle" gutter={[16, 0]}>
                            <Col>
                                Número Fatura: <b>{objFaturamento[0]?.numeroFatura}</b>
                            </Col>
                            <Col>
                                Valor Original (R$): <b>{FormatNumber(objFaturamento[0]?.nfp_valor, true)}</b>
                            </Col>
                            <Col>
                                Desconto (R$): <b>{FormatNumber(objFaturamento[0]?.vDesconto, true)}</b>
                            </Col>
                            <Col>
                                Valor Líquido (R$): <b>{FormatNumber(objFaturamento[0]?.nfp_valor, true)}</b>
                            </Col>
                            <Col>
                                Forma de pagamento: <b>{objFaturamento[0]?.tipoavistaaprazo}</b>
                            </Col>
                            <Col>
                                Tipo de pagamento: <b>{objFaturamento[0]?.tipopagamento}</b>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="m-t-16">
                <Form name="formFat" form={form} layout="vertical" onFinish={() => adicionarFormaDePagamento()}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={6} md={5} lg={4} xl={8} xxl={5}>
                            <Form.Item name="fpg_id" label="Selecione a Forma de Pagamento:" rules={[{ required: true, message: 'Favor informar forma de pagamento!' }]}>
                                <SelectPaginacao url="FormaPagamento/Listar"
                                    placeholder="Selecione a Forma de Pagamento"
                                    form={form}
                                    name="fpg_id"
                                    nameLabel="fpg_descricao"
                                    nameValue="fpg_id"
                                    setListaDados={setListaFormaPagamento}
                                    onChangeFunction={(record) => { buscarCondicaoPagamento(record) }}
                                    conteudo={
                                        formas => (<Select.Option value={formas.fpg_id} key={formas.key} >{formas.fpg_descricao}</Select.Option>)
                                    } />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={6} md={5} lg={4} xl={7} xxl={5}>
                            <div>
                                {!semCondicaoPagamento &&
                                    <Form.Item name="cpg_id" label="Seleciona a Condição de Pagamento" rules={[{ required: true, message: 'Informe a condição de pagamento.' }]}>
                                        <Select placeholder="Selecione a Condição de Pagamento" onChange={(record) => verificarParcelas(record)}>
                                            {listaCondicoesPagamento.map(condicao => (
                                                <Select.Option value={condicao.cpg_id} key={condicao.cpg_id}>{condicao.cpg_descricao}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                }
                            </div>
                        </Col>
                        <Col xs={24} sm={4} md={4} lg={4} xl={4} xxl={5}>
                            <InputPreco name="nfp_valor" label="Valor Nota" />
                        </Col>
                        <Col xs={24} sm={7} md={5} lg={5} xl={5} xxl={3} className="tt-5">
                            <Button icon={<PlusOutlined />} type="primary" block className="t-mob-573" onClick={() => form.submit()}>
                                Adicionar
                            </Button>
                        </Col>
                    </Row>
                    {parcelasGeradas.length > 0 &&
                        <div className="m-t-16">
                            <Typography.Title level={5}>
                                Parcelas Geradas
                            </Typography.Title>
                            <dic className="tabela">
                                <Table dataSource={parcelasGeradas} columns={[
                                    {
                                        title: 'Parcela',
                                        width: 75,
                                        align: 'center',
                                        render: ({ prc_numeroparcela }) => (
                                            <Tag color="processing" className="w-100">
                                                <b>{prc_numeroparcela} - {parcelasGeradas.length}</b>
                                            </Tag>
                                        )
                                    },
                                    {
                                        title: 'Vencimento',
                                        align: 'center',
                                        width: 110,
                                        render: ({ prc_datavencimento, prc_numeroparcela }, record, idx) => (
                                            <div>
                                                {editandoParcela ? <Data initialValue={prc_datavencimento} name={`prc_datavencimento${prc_numeroparcela}`} onBlur={() => ajustarDatasParcelas(record, idx)} /> : <b>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>}
                                            </div>
                                        )
                                    },
                                    {
                                        title: 'Valor Parcela(R$)',
                                        align: 'right',
                                        render: ({ prc_valor, prc_numeroparcela }) => (
                                            <div>
                                                {editandoParcela ? <InputPreco name={`prc_valor${prc_numeroparcela}`} initialValue={prc_valor} onBlur={() => onChangevalorParcela(prc_numeroparcela)} disabled={prc_numeroparcela === parcelasGeradas.length} /> : <b>{FormatNumber(prc_valor, true)}</b>}
                                            </div>
                                        )
                                    },
                                    {
                                        title: 'Ações',
                                        align: 'center',
                                        width: 65,
                                        fixed: 'right',
                                        render: () => (
                                            <Row align="center" gutter={[5, 0]}>
                                                <Col>
                                                    {!editandoParcela ? <Button icon={<EditOutlined />} onClick={() => setEditandoParcela(true)} /> : <Button icon={<SaveOutlined />} onClick={() => salvarParcela()} />}
                                                </Col>
                                            </Row>
                                        )
                                    }
                                ]
                                } scroll={{ x: 900 }} />
                            </dic>
                        </div>
                    }
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <div className="tabela">
                                <Table dataSource={faturamentoDados.faturamentoDados} columns={[
                                    {
                                        title: 'Forma de Pagamento',
                                        render: ({ fpg_descricao }) => (
                                            <div>
                                                {fpg_descricao}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Condição de Pagamento',
                                        render: ({ cpg_descricao }) => (
                                            <div>
                                                {cpg_descricao}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Valor (R$)',
                                        align: 'right',
                                        render: ({ nfp_valor }) => (
                                            <div>
                                                <b className="f-18">{FormatNumber(nfp_valor, true)}</b>
                                            </div>
                                        ),
                                    },

                                    {
                                        title: 'Operações',
                                        align: 'right',
                                        render: ({ }, record, idx) => (
                                            <div>
                                                <Button icon={<DeleteOutlineOutlined />} onClick={() => excluirFormaPagaento(idx)} />
                                            </div>
                                        )
                                    }
                                ]}
                                />
                            </div>
                        </Col>
                    </Row>
                </Form>
                <ModalContaCorrente exibirModalContaCorrente={abreModalContaCorrente} fecharModalContaCorrenteOk={() => { onCloseModalContaCorrente() }} fecharModalContaCorrente={() => { setAbreModalContaCorrente(false); }} listaContaCorrente={{ listaContaCorrente, setListaContaCorrente }} contaCorrente={{ contaCorrente, setContaCorrente }} />
                <ModalEditarNotaFiscalEntrada exibirModalEditarNotaFiscalEntrada={openModalEditarNotaFiscalEntrada} fecharModalEditarNotaFiscalEntrada={() => { setOpenModalEditarNotaFiscalEntrada(false) }} />
            </div>
        </div >
    );
}