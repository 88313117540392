import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Table, Tag, InputNumber, Input, Modal } from "antd";
import { PlusOutlined, MinusOutlined, ExclamationCircleOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";

import InputPreco from "../../inputPreco";
import { useStateValue } from '../../../state';
import { info, validarNumeros } from "../../../services/funcoes";
import { manutencaoActions } from "../../../actions";
import { FormatNumber } from "../../../ValueObjects";

export default function ModalGradeProdutoImportacao({ exibirModal, fecharModal, dados }) {
    const [form] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();
    const [dadosRetorno, setDadosRetorno] = useState([]);
    const [dadosFiltrados, setDadosFiltrados] = useState([]);

    useEffect(() => {
        if (!!dados) {
            dados.forEach((dado) => {
                if (!(!!dado.nfi_qtde)) {
                    dado.nfi_qtde = 0;
                }
            });
            setDadosRetorno(dados);
            setDadosFiltrados(dados);
        } else {
            form.resetFields();
        }
    }, [dados]);

    useEffect(() => {
        pesquisaGrade(form.getFieldValue().pesquisa);
    }, [dadosRetorno]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }
        });
    };

    const salvarDados = values => {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, dadosGrade: [...dados] } } })
        fecharModal();
        form.resetFields();
    };

    function alterarQuantidade(item, operacao, qtde = null) {
        let dadosTemp = [...dadosRetorno];
        let itemTemp = dadosTemp.find((dado) => (dado === item));
        if (qtde !== null) {
            itemTemp.nfi_qtde = qtde;
        } else {
            let qtd = !!itemTemp.nfi_qtde ? itemTemp.nfi_qtde : 0;
            if (operacao === "mais") {
                qtd++;
            } else if (operacao === "menos" && qtd > 0) {
                qtd--;
            }
            itemTemp.nfi_qtde = qtd;
        }
        itemTemp.valorTotal = (parseFloat(itemTemp.nfi_qtde) * parseFloat(itemTemp.nfi_custoinformado));
        setDadosRetorno(dadosTemp);
    };

    function pesquisaGrade(campo) {
        let filtrados = [];
        if (!!campo) {
            for (let item of dadosRetorno) {
                if (item.ean.indexOf(campo) >= 0) {
                    filtrados.push(item);
                } else {
                    for (let grade of item.atributos) {
                        if (grade.mgp_descricao.toLowerCase().indexOf(campo.toLowerCase()) >= 0) {
                            filtrados.push(item);
                        }
                    }
                }
            }
            setDadosFiltrados(filtrados);
        } else {
            setDadosFiltrados([...dadosRetorno]);
        }
    };


    return (
        <Modal centered
            title="Grade Produtos"
            visible={exibirModal}
            onCancel={onCloseModal}
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            onOk={() => { form.submit() }}
            okText={
                <>
                    <CheckOutlined /> Ok
                </>
            }
            width={780}
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="formGradeCondicional" form={form} onFinish={salvarDados} >
                    <Row gutter={[0, 16]}>
                        <Col span={24}>
                            <Form.Item name="pesquisa">
                                <Input.Search placeholder="Localizar um item da grade"
                                    onSearch={(campo) => { pesquisaGrade(campo) }} allowClear
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="tabela">
                        <Table columns={[
                            {
                                title: 'Código',
                                width: 112,
                                render: ({ ean }) => (
                                    <div>
                                        <Tag color="processing">
                                            <b>{ean}</b>
                                        </Tag>
                                    </div>
                                ),
                            }, {
                                title: 'Grade',
                                render: ({ atributos }) => (
                                    <div>
                                        <Row align="middle" gutter={[5, 5]}>
                                            {atributos.map(atrib => (
                                                <Col>
                                                    <b>{atrib.mgp_descricao}</b>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                ),
                            }, 
                            {
                                title: 'Quantidade',
                                width: 112,
                                align: 'center',
                                render: (record, { nfi_qtde }) => (
                                    <Row className="m-5">
                                        <Col span={6}>
                                            <Button type="primary" onClick={() => { alterarQuantidade(record, "menos") }} icon={<MinusOutlined />} block className="h32" />
                                        </Col>
                                        <Col span={12}>
                                            <InputNumber
                                                name="nfi_qtde" value={nfi_qtde} min={0} precision={0} onChange={(e) => { alterarQuantidade(record, "", e) }} />
                                        </Col>
                                        <Col span={6}>
                                            <Button type="primary" onClick={() => { alterarQuantidade(record, "mais") }} icon={<PlusOutlined />} block className="h32" />
                                        </Col>
                                    </Row>
                                ),
                            },
                            {
                                title: 'Vlr Custo. (R$)',
                                align: 'right',
                                width: 90,
                                render: ({  nfi_custoinformado }) => (
                                    <div>
                                        <b>{FormatNumber(nfi_custoinformado, true, false)}</b>
                                    </div>
                                ),
                            },
                             {
                                title: 'Vlr Total (R$)',
                                align: 'right',
                                width: 90,
                                render: ({ valorTotal, nfi_custoinformado}) => (
                                    <div>
                                        <b>{FormatNumber(valorTotal ? valorTotal : 0, true, false)}</b>
                                    </div>
                                ),
                            }
                        ]} dataSource={dadosFiltrados} pagination={false} scroll={{ y: 285, x: 580 }} />
                    </div>
                </Form>
            </div>
        </Modal>

    );

};