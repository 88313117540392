import React, { useState } from "react";
import { Row, Col, Typography, Divider, Button, Table, Form, Modal } from "antd";
import { PlusOutlined, EditOutlined, ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";

import { ModalContaGerencial } from "../../components/modals/";

export default function TabConfigConta({formContaGerencial,listaConfigContaGerencial, setListaConfigContaGerencial}) {

    const [openModalContaGerencial, setOpenModalContaGerencial] = useState(false);
    const [formulario] = Form.useForm();

    function excluirRegistro(id) {  
        Modal.confirm({
            title: 'Deseja deletar este registro?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                let lista = [...listaConfigContaGerencial];
                let index = lista.findIndex(i => i.key === id);
                lista.splice(index, 1);
                setListaConfigContaGerencial(lista);
            }
        });
    }

    return (
        <div className="pages-col">
            <div>
                <Row align="middle">
                    <Col span={24}>
                        <Typography.Title level={3}>
                            <img src={require("../../assets/i-settings.png").default} alt="Configura Conta Gerencial" /> Configuração da Conta Gerencial - (OPCIONAL)
                        </Typography.Title>
                        <Divider orientation="left" plain>
                            Adicione a(s) configuração(ões) da Conta Gerencial aqui.
                        </Divider>
                    </Col>
                </Row>
                <Row align="middle" justify="end">
                    <Col>
                        <div className="i-Position p-relative">
                            <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => {
                                formulario.resetFields();
                                setOpenModalContaGerencial(true);
                            }} />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="tabela mt-dif">
                <Table columns={[
                    {
                        title: 'Configurações das Contas Gerenciais',
                        render: ({cgc_descricaoTipo, cgc_descricaocontadestino}) => (
                            <div>
                               <b>{cgc_descricaoTipo} - {cgc_descricaocontadestino}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        align: 'center',
                        key: 'x',
                        width: 65,
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<EditOutlined />} onClick={() => {
                                            formulario.setFieldsValue(record);
                                            setOpenModalContaGerencial(true);
                                        }}/>
                                    </Col>
                                    <Col>
                                        <Button icon={<DeleteOutlined />} onClick={() => excluirRegistro(record.key)} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                </Divider>
                            </Col>
                        </Row>)
                }} pagination={false} dataSource={listaConfigContaGerencial} scroll={{x: 900}}/>
            </div>
            <ModalContaGerencial formContaGerencial={formContaGerencial} setListaConfigContaGerencial={setListaConfigContaGerencial} formulario={formulario} exibirModalContaGerencial={openModalContaGerencial} fecharModalContaGerencial={() => setOpenModalContaGerencial(false)} listaConfigContaGerencial={listaConfigContaGerencial}/>
        </div>
    );
}