import React, { useEffect, useState } from 'react';
import { CloseOutlined, FileImageOutlined, FileSearchOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Image, Radio, Row, Space, Table, notification } from 'antd';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import api from '../../services/api';
import { linkApiGestor } from '../../services/auth';
import DrawerConsulta from '../catPecasExterno/drawerConsulta';
import { manutencaoActions } from "../../actions";
import { useStateValue } from '../../state';



export default function DrawerGrupo( { codCatalogo, codLinha, exibirDrawer, fecharDrawer, nomeCatalogo, nomeLinha } ) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [listaGrupo, setListaGrupo] = useState([]);
    const [valueGrupo, setValueGrupo] = useState(0);
    const [valueSubGrupo, setValueSubGrupo] = useState('');
    const [foto, setFoto] = useState(false);
    const [imageGrupo, setImageGrupo] = useState({});
    const [listaSubGrupo, setListaSubGrupo] = useState([]);
    const [rowSelectionn, setRowSelectionn] = useState([]);
    const [rowSelectionnGrup, setRowSelectionnGrup] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);

    useEffect(() => 
    {
        if(codCatalogo != "")
        {
            carregaSubGrupos();
        }
    }, [codCatalogo]);

    useEffect(() => 
    {
        if(codCatalogo != "" && valueGrupo > 0)
        {
            setListaGrupo([]);
            carregaGrupos();
        }
    }, [valueGrupo]);

    function carregaSubGrupos() {
        api.get(`Cat006/getSubGrupos?empresa=1&codCatalogo=${codCatalogo}`).then(res => {
            if(res.status === 200) {
                let dados = res.data.map((x) => ({ key: x.codSubGrupo, ...x}))
                setListaSubGrupo(dados);
                if(dados.length > 0){
                    setValueGrupo(dados[0].numSubGrupo);
                    setRowSelectionn(dados[0].codSubGrupo);
                }
            }
        }, err => {
            console.log(err.response);

        });
    }
    
    function carregaGrupos() {
        api.get(`Cat004/getGrupos?empresa=1&numGrupo=${valueGrupo}`).then(res => {
            if(res.status === 200) {
                let dados = res.data.map((x) => ({ key: x.codGrupo, ...x}))
                setListaGrupo(dados);
                if(dados.length > 0){
                    setRowSelectionnGrup(dados[0].codGrupo);
                }
                buscarImagemGrupo(dados[0].codGrupo);
            }
        }, err => {
            console.log(err.response);

        });
    }
    
    function buscarImagemGrupo(rowSelectionnGrup)
    {
        api.get(`${linkApiGestor}Cat004/BuscarImg?Empresa=1&CodGrupo=${rowSelectionnGrup}&CodCatalogo=${codCatalogo}`).then(res => {
            if(res.status === 200) {
                let dados = res.data.map((x) => ({ ...x}))
                if(dados.length > 0){
                    const objeto = {...dados[0]};
                    setImageGrupo(objeto);
                    setFoto(true);
                }
                else {
                    setImageGrupo('');
                }
            }
        }, err => {
            notification.warning({ message: 'Aviso', description: 'Ausência de Imagem para a Peça Selecionada!' });
            console.log(err.response);

        });
    }

    const rowSelection = {
        type: "radio",
        selectedRowKeys: [rowSelectionn],
        onSelect: (record) => {
            funcoesAoSelecionar(record);           
        }
    }

    const rowSelectionGrup= {
        type: "radio",
        selectedRowKeys: [rowSelectionnGrup],
        onSelect: (record) => {
            funcoesAoSelecionarGrup(record);            
        }
    }

    function funcoesAoSelecionar (record) {
        setValueGrupo(record.numSubGrupo);
        setRowSelectionn(record.codSubGrupo)
    }
    
    function funcoesAoSelecionarGrup (record) {
        setValueSubGrupo(record.numGrupo);
        setRowSelectionnGrup(record.codGrupo);
    }

    const onCloseDrawer = () => {
        setOpenDrawer(false);
    };

    const onClose = () => {
        setFoto('');
        fecharDrawer();
        setListaGrupo([]);
        setListaSubGrupo([]);
        setValueGrupo(0);
    };
    
    return (
        <Drawer 
        title={<b>Linha: {codLinha} - {nomeLinha}    Catálogo: {codCatalogo} - {nomeCatalogo} </b>} 
        width="100%" visible={exibirDrawer} onClose={onClose} extra={
            <Space>
                <Button onClick={onClose} icon={<CloseOutlined />} size='large' htmlType='button' className='m-r-8' >
                    Voltar
                </Button>
            </Space>
        }>
            <Row align="top">
                <Col sm={12}>
                    <div className='tabela hgtGrupoCat' id='TabelaGrupo'>
                        <Table id="TabelaCatalogo" small responsive columns={
                            [
                                {
                                    title: 'Código',
                                    width: 80,
                                    fixed: 'left',
                                    render: ({codSubGrupo}) => (
                                        <div>
                                            <b>{codSubGrupo}</b>
                                        </div>  
                                    ),
                                },
                                {
                                    title: 'Descrição do SubGrupo',
                                    fixed: 'left',
                                    render: ({descricao}) => (
                                        <div>
                                            <b>{descricao}</b>
                                        </div>  
                                    ),
                                },
                            ]
                        }
                        rowSelection={rowSelection}
                        pagination={false}
                        scroll={{ y: window.innerHeight }}
                        dataSource={listaSubGrupo}
                        />
                    </div>
                </Col>
                {!!foto &&
                    <Col sm={12} className='divimgGrupoSubCat'>
                        <TransformWrapper >
                            <TransformComponent contentClass="imageZoomCat">
                                <img src={`data:image/jpg;base64, ${imageGrupo.base64Data}`} 
                                alt="catalogo" /> 
                            </TransformComponent>
                        </TransformWrapper>
                        
                    </Col>
                }
            </Row>
                <Row>
                    <div className='tabela hgtGrupos'>
                        <Col sm={12}>
                                <Table small responsive columns={
                                    [
                                        {
                                            title: 'Código',
                                            fixed: 'left',
                                            render: ({codGrupo}) => (
                                                <div>
                                                    <b>{codGrupo}</b>
                                                </div>  
                                            ),
                                        },
                                        {
                                            title: 'Descrição do Grupo',
                                            fixed: 'left',
                                            render: ({descGrupo}) => (
                                                <div>
                                                    <b>{descGrupo}</b>
                                                </div>  
                                            ),
                                        },
                                        {
                                            title: '',
                                            align: 'left',
                                            width: 130,
                                            render: ({ codGrupo, descGrupo }) => (
                                                <div>
                                                    <b><a onClick={() => {
                                                        let catalogo = {
                                                            codcatalogo: codCatalogo,
                                                            codgrupo: codGrupo,
                                                            nomegrupo: descGrupo,
                                                            nomecatalogo: codCatalogo + " - " + nomeCatalogo,
                                                        };
                                                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: catalogo } });
                                                        setOpenDrawer(true);
                                                    }}>Consultar Peças <FileSearchOutlined /></a></b>
                
                                                </div>
                                            ),
                                        },
                                    ]
                                }
                                pagination={false}
                                rowSelection={rowSelectionGrup}
                                scroll={{ y: window.innerHeight }}
                                dataSource={listaGrupo}
                            />
                        </Col>
                    </div>
                </Row>
                    <DrawerConsulta onCloseDrawer={onCloseDrawer} exibirDrawer={openDrawer} catalogoExterno={true}/>
        </Drawer>
    );
}