import React from 'react';
import { Row, Col, Table, Button, Dropdown, Menu } from "antd";
import moment from 'moment';
import { FormatNumber } from '../../ValueObjects';
import { Print, MoreOutlined } from '@material-ui/icons';
import { imprimirRelatorioJasper, novaAbaNavegador } from "../../services/funcoes";

export default function TabDetalheIFormaPagamento({ formaPagamento }) {

    const expandedRowRenderParcelas = record => {
        let dataSource, renderFunction;

        if (record.fpg_tipopagamento === 5) {
            dataSource = record.parcelas;
            renderFunction = ({ prc_numeroparcela, prc_datavencimento, prc_valor }) => (
                <div>
                    <Row align="middle" justify="space-between" gutter={[8, 0]}>
                        <Col>
                            Nº: <b>{prc_numeroparcela}</b>
                        </Col>
                        <Col>
                            Vencimento: <b>{!!prc_datavencimento ? moment(prc_datavencimento).format('DD/MM/YYYY') : null}</b>
                        </Col>
                        <Col>
                            Valor (R$): <b>{FormatNumber(!!prc_valor ? prc_valor : 0, true)}</b>
                        </Col>
                    </Row>
                </div>
            );
        } else if (record.fpg_tipopagamento === 3 || record.fpg_tipopagamento === 4) {
            dataSource = record.parcelasCartao;
            renderFunction = ({ bce_nomecredenciadora,rcc_conciliado, rcc_parcelarecebimento, rcc_previsaorecebimento, rcc_valor }) => (
                <div>
                    <Row align="middle" justify="space-between" gutter={[8, 0]}>
                        <Col>
                            Bandeira: <b>{bce_nomecredenciadora}</b>
                        </Col>
                        <Col>
                            Conciliado: <b>{rcc_conciliado == true ? 'Sim' : 'Não'}</b>
                        </Col>
                        <Col>
                            Nº: <b>{rcc_parcelarecebimento}</b>
                        </Col>
                        <Col>
                            Previsão recebimento: <b>{!!rcc_previsaorecebimento ? moment(rcc_previsaorecebimento).format('DD/MM/YYYY') : null}</b>
                        </Col>
                        <Col>
                            Valor (R$): <b>{FormatNumber(!!rcc_valor ? rcc_valor : 0, true)}</b>
                        </Col>
                    </Row>
                </div>
            );
        }

        return (
            <Table
                dataSource={dataSource}
                columns={[
                    {
                        title: '',
                        render: renderFunction,
                    },
                ]}
                pagination={false}
            />
        );
    };

    const rowExpandable = record => {
        return (record.fpg_tipopagamento === 3 || record.fpg_tipopagamento === 4 || record.fpg_tipopagamento === 5);
    };

    function menuTable(record) {
        return (
            <Menu className="text-right">
                {record.fpg_emiteboleto === true &&
                    <Menu.Item key="10">
                        <Button type="text" icon={<Print />} onClick={() => {
                            imprimirBoleto(record)
                        }}> Imprimir Boleto
                        </Button>
                    </Menu.Item>}
            </Menu>
        );
    }

    function imprimirBoleto(record) {
        if (!!record.ntf_id) {
            novaAbaNavegador(`Boleto/ImprimirBoletos/${record.ntf_id}`);
        }
    }

    return (
        <div className="tabela modalTable">
            <Table
                dataSource={formaPagamento}
                showHeader={true}
                columns={[
                    {
                        title: 'Forma/Condição Pagto',
                        render: ({ fpg_descricao, cpg_descricao }) => (
                            <div>
                                <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                    <Col span={16}>
                                        <b>{fpg_descricao + (!!cpg_descricao ? '/ ' + cpg_descricao : '')}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'NSU / Id Integração',
                        render: ({ imp_autorizacao, imp_idintegracao }) => (
                            <div>
                                <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                    {!!imp_autorizacao && <Col span={16}>
                                        <b>{imp_autorizacao + ' / ' + imp_idintegracao}</b>
                                    </Col>}
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Cód. Vale Presente',
                        render: ({ nfp_codigovalepresente }) => (
                            <div>
                                <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                    {!!nfp_codigovalepresente && <Col span={16}>
                                        <b>{nfp_codigovalepresente}</b>
                                    </Col>}
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Valor Troco',
                        align: 'right',
                        render: ({ nfp_valortroco }) => (
                            <div>
                                <Row align="right" justify='end' gutter={[8, 0]}>
                                    <Col span={16} align="right">
                                        <b>R$ {FormatNumber(!!nfp_valortroco ? nfp_valortroco : 0, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Valor Líquido',
                        align: 'right',
                        render: ({ nfp_valor, nfp_valortroco }) => (
                            <div>
                                <Row align="right" justify='end' gutter={[8, 0]}>
                                    <Col span={16} align="right">
                                        <b>R$ {FormatNumber(!!nfp_valor ? (nfp_valor - nfp_valortroco) : 0, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        fixed: 'right',
                        width: 65,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]}
                pagination={false}
                expandable={{ expandedRowRender: expandedRowRenderParcelas, rowExpandable }}
            />
        </div>
    );
}