import React, { useEffect, useState } from "react";
import { Tag, InputNumber, Typography, Row, Col, Table, Form, Button, Modal, notification, Tooltip } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, FormOutlined, EditOutlined, CheckOutlined, DeleteOutlined, ReloadOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import { FormatNumber } from "../../ValueObjects";
import { Data, InputPreco } from "../../components";
import { diferencaDatas, info } from "../../services/funcoes";

export default function ReparcelamentoParcelas({ aoSalvar, carregando, listaParcelas, totalizadores, formulario, dadosCliente, parcelasGeradas }) {

    const [dadosReparcelamento, setDadosReparcelamento] = useState({});
    const [listaParcelasReparcelar, setListaParcelasReparcelar] = useState(listaParcelas);
    const [descontoItem, setDescontoItem] = useState(false);
    const [paginaParcAntigas, setPaginaParcAntigas] = useState(1);
    const [paginaParcGeradas, setPaginaParcGeradas] = useState(1);
    const [nroRegistrosParcAntigas, setNroRegistrosParcAntigas] = useState(10);
    const [nroRegistrosParcGeradas, setNroRegistrosParcGeradas] = useState(100);

    function reparcelar(values) {
        carregando(true);
        values = { ...dadosReparcelamento };
        values.parcelas = parcelasGeradas.parcelasGeradas;
        values.parcelasQuitadas = listaParcelasReparcelar;
        values.pes_id = dadosCliente.pes_id;

        api.post('/Reparcelamento/Incluir', values).then(res => {
            if (res.data) {
                Modal.success({
                    content: 'Reparcelamento realizado com sucesso!',
                });
            }
        }, err => {
            info('Não foi possivel realizar o reparcelamento!');
        }).finally(() => {
            aoSalvar();
            carregando(false);
        });
    };

    function zerarJuros() {
        let listaTemp = [...listaParcelasReparcelar];
        let listaTotalizadores = { ...totalizadores.listaTotalizadoresReparcelar };
        listaTotalizadores.valorAReceber = 0;
        listaTemp.forEach(item => {
            let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
            item.valorJuros = 0;
            if (parseFloat(item.valorDesconto) > valorTotal) {
                item.valorDesconto = valorTotal;
            }
            item.valorAReceber = parseFloat((valorTotal - parseFloat(item.valorDesconto) + parseFloat(item.valorJuros) + parseFloat(item.valorAcrescimo)).toFixed(2));
            item.valorPago = item.valorAReceber;
            item.prc_valor = item.valorAReceber;
            listaTotalizadores.valorTotalJuros = 0;
            listaTotalizadores.valorAReceber += item.valorAReceber;
        });
        setListaParcelasReparcelar(listaTemp);
        totalizadores.setListaTotalizadoresReparcelar(listaTotalizadores);

    };
    function gerarParcelas() {
        if (!!formulario.getFieldValue().numeroParcelas) {
            let valorParcela = parseFloat((parseFloat(totalizadores.listaTotalizadoresReparcelar.valorAReceber) / formulario.getFieldValue().numeroParcelas).toFixed(2));
            let novaData = new Date();
            let dataInclusao = new Date();
            let somaDasParcelas = 0;
            let parcelarGeradas = [];
            let dados = {};
            dados.cpr_descricao = 'Reparcelamento de Parcelas';
            dados.ntf_id = null;
            dados.cpr_numerodocumento = '';
            dados.cpr_numeroparcelas = formulario.getFieldValue().numeroParcelas;
            dados.cpr_pagarreceber = 1;
            dados.cpr_observacao = '';
            dados.prc_periodicidade = null;
            dados.cpr_intervalodias = null;
            dados.cpr_datainclusao = dataInclusao;
            dados.cpr_contaestornadarelacionada = null;
            dados.pessoaAutorizadaId = null;
            dados.contaGerencial = { ctg_id: null };
            dados.contaCorrente = { ctc_id: null };
            setDadosReparcelamento(dados);

            for (let i = 0; i < formulario.getFieldValue().numeroParcelas; i++) {
                novaData.setDate(novaData.getDate() + 30);
                if (i !== 0) {
                    somaDasParcelas += valorParcela;
                }
                let parcela = {
                    cpr_datainclusao: dataInclusao,
                    cpr_id: null,//não pode ser nulo...
                    cpr_numerodocumento: null,
                    cpr_numeroparcelas: formulario.getFieldValue().numeroParcelas,
                    loc_id: null,
                    mcc_datapagamento: null,
                    ntf_id: null,
                    prc_codigobarras: null,//criar no momento de incluir o registro no banco
                    prc_datavencimento: novaData,
                    prc_estornado: false,
                    prc_id: null,
                    prc_numeroparcela: i + 1,
                    statusParcela: null,
                    prc_valor: valorParcela,
                    valorParcela: valorParcela,
                    valorDesconto: 0,
                    valorTotalJuros: 0,
                    ctg_id: null,
                    ctc_id: null,
                    bol_id: null,
                    fpg_id: null,
                    cpg_id: null
                }
                novaData = new Date(parcela.prc_datavencimento);
                parcelarGeradas.push(parcela);
            }
            if (somaDasParcelas > 0) {
                parcelarGeradas[0].prc_valor = parseFloat((parseFloat(totalizadores.listaTotalizadoresReparcelar.valorAReceber) - somaDasParcelas).toFixed(2));
                parcelarGeradas[0].valorParcela = parcelarGeradas[0].prc_valor;
            }
            parcelasGeradas.setParcelasGeradas(parcelarGeradas);
        }
    };

    async function ajustaDatasParcelas(record, idx) {
        let listaTemp = [...parcelasGeradas.parcelasGeradas];
        if (listaTemp[idx - 1] != null) {
            if (diferencaDatas(listaTemp[idx - 1].prc_datavencimento, new Date(formulario.getFieldValue(`prc_datavencimento${idx + 1}`))) > 30) {
                notification.warning({ message: 'Aviso!', description: 'Data das parcelas maior do que um mês!' });
                formulario.setFieldsValue({ [`prc_datavencimento${idx + 1}`]: listaTemp[idx].prc_datavencimento });
                return false;
            }
        }
        listaTemp[idx].prc_datavencimento = moment(new Date(formulario.getFieldValue(`prc_datavencimento${idx + 1}`)));
        for (let i = idx; i < listaTemp.length; i++) {
            if (listaTemp[i + 1] != null) {
                let dataVencimento = moment(new Date(new Date(formulario.getFieldValue(`prc_datavencimento${i + 1}`)).setDate(new Date(formulario.getFieldValue(`prc_datavencimento${i + 1}`)).getDate() + 30)));
                formulario.setFieldsValue({ [`prc_datavencimento${i + 2}`]: dataVencimento });
                listaTemp[i + 1].prc_datavencimento = moment(new Date(new Date(listaTemp[i].prc_datavencimento).setDate(new Date(listaTemp[i].prc_datavencimento).getDate() + 30)));
            }
        }
        parcelasGeradas.setParcelasGeradas(listaTemp);
    }

    function onBlurValorParc(record) {
        let valorJaUtilizado = 0;
        let valorRestante = 0;
        let valorRestanteTot = 0;
        let valorParcelas = 0;
        let qtdTotParcelas = parcelasGeradas.parcelasGeradas.length;
        let valorTotal = parseFloat(totalizadores.listaTotalizadoresReparcelar.valorAReceber);
        let dados = formulario.getFieldsValue();
        for (let i = 1; i <= record; i++) {
            valorJaUtilizado += parseFloat(dados[`valorParcela${i}`]);
        }
        valorRestante = valorTotal - valorJaUtilizado;
        valorParcelas = parseFloat((valorRestante / (qtdTotParcelas - record)).toFixed(2));
        for (let i = qtdTotParcelas; i > record; i--) {
            valorRestanteTot += valorParcelas;
            formulario.setFieldsValue({ [`valorParcela${i}`]: valorParcelas });
        }
        let listaTemp = [...parcelasGeradas.parcelasGeradas];
        for (let a = 0; a < qtdTotParcelas; a++) {
            listaTemp[a].valorParcela = formulario.getFieldValue(`valorParcela${a + 1}`);
        }
        let valorSobra = valorTotal - (valorJaUtilizado + valorRestanteTot);
        formulario.setFieldsValue({ [`valorParcela${qtdTotParcelas}`]: parseFloat((valorParcelas + valorSobra).toFixed(2)) });
        recalcularTotal(listaTemp);
    };

    function recalcularTotal(listaTemp) {
        listaTemp.forEach((parc) => {
            parc.prc_valor = parseFloat(parc.valorParcela) + parseFloat(parc.valorTotalJuros);
        });
        parcelasGeradas.setParcelasGeradas(listaTemp);
    };

    function onBlurValorJuros(parcela) {
        let listaTemp = [...parcelasGeradas.parcelasGeradas];
        let linha = listaTemp.filter((parc) => { return parc.prc_numeroparcela === parcela })[0];
        linha.valorTotalJuros = parseFloat(formulario.getFieldValue(`valorJuros${parcela}`));
        recalcularTotal(listaTemp);
    };

    useEffect(() => {
        let listaTotalizadores = { ...totalizadores.listaTotalizadoresReparcelar };
        if (!!parcelasGeradas && !!parcelasGeradas.parcelasGeradas && parcelasGeradas.parcelasGeradas.length > 0) {
            let totalJuros = 0;
            let valorAReceber = 0;
            for (const parc of parcelasGeradas.parcelasGeradas) {
                totalJuros += parc.valorTotalJuros;
                valorAReceber += parseFloat(parc.valorParcela);
                formulario.setFieldsValue({
                    [`valorParcela${parc.prc_numeroparcela}`]: parc.valorParcela,
                    [`valorJuros${parc.prc_numeroparcela}`]: parc.valorTotalJuros,
                    [`prc_datavencimento${parc.prc_numeroparcela}`]: parc.prc_datavencimento
                });
            }
            listaTotalizadores.valorTotalJuros = totalJuros;
            listaTotalizadores.valorAReceber = valorAReceber;
            totalizadores.setListaTotalizadoresReparcelar(listaTotalizadores);
            formulario.setFieldsValue({ valorJuros: parseFloat(totalJuros.toFixed(2)) });
        } else {
            listaTotalizadores.valorTotalJuros = 0;
            totalizadores.setListaTotalizadoresReparcelar(listaTotalizadores);
        }
    }, [parcelasGeradas.parcelasGeradas]);

    function rateioJuros() {
        let valorJuros = parseFloat(formulario.getFieldValue("valorJuros")) > 0 ? parseFloat(formulario.getFieldValue("valorJuros")) : 0;
        let valorTotal = parseFloat(totalizadores.listaTotalizadoresReparcelar.valorAReceber);

        let listaTemp = [...parcelasGeradas.parcelasGeradas];
        let sobraJuros = 0;

        for (const [idx, item] of listaTemp.entries()) {
            let percentualJuros = (item.valorParcela * 100) / valorTotal;
            let juros = (valorJuros * percentualJuros) / 100;
            sobraJuros += juros - parseFloat(juros.toFixed(2));
            item.valorTotalJuros = parseFloat((parseFloat(juros.toFixed(2)) + (idx === listaTemp.length - 1 ? sobraJuros : 0)).toFixed(2));
            formulario.setFieldsValue({ [`valorJuros${item.prc_numeroparcela}`]: item.valorTotalJuros });
            item.prc_valor = item.valorParcela + item.valorTotalJuros;
        }
        parcelasGeradas.setParcelasGeradas(listaTemp);
    };

    function calcularJuros() {
        let percentual = parseFloat(formulario.getFieldValue("percentualJuros"));
        let valorTotal = parseFloat(totalizadores.listaTotalizadoresReparcelar.valorAReceber);
        let valorJuros = (valorTotal * percentual) / 100;
        formulario.setFieldsValue({ valorJuros: valorJuros });
        rateioJuros();
    };

    return (
        <div>
            <Typography.Title level={5}>
                Parcelas Vencidas
            </Typography.Title>
            <div className="tabela">
                <Table dataSource={listaParcelasReparcelar} columns={
                    [
                        {
                            title: 'Parcela',
                            width: 75,
                            render: ({ prc_numeroparcela, cpr_numeroparcelas }) => (
                                <Tag color="processing" className="w-100">
                                    <b>{prc_numeroparcela} - {cpr_numeroparcelas}</b>
                                </Tag>
                            ),
                        },
                        {
                            title: 'Vencimento',
                            width: 130,
                            align: 'center',
                            render: ({ prc_datavencimento, vencida }) => (
                                <div>
                                    <b className={vencida ? "c-red" : ""}>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Valor Parcela (R$)',
                            align: 'right',
                            render: ({ valorOriginal }) => (
                                <div>
                                    <b>
                                        {FormatNumber(!!valorOriginal ? valorOriginal : 0, true)}
                                    </b>
                                </div>
                            ),
                        }, {
                            title: 'Valor Recebido Parcela (R$)',
                            align: 'right',
                            render: ({ valorQuitado }) => (
                                <div>
                                    <b>
                                        {FormatNumber(!!valorQuitado ? valorQuitado : 0, true)}
                                    </b>
                                </div>
                            ),
                        }, {
                            title: () => {
                                return (
                                    <div>
                                        <Row gutter={[5, 0]} justify="end">
                                            <Col>Juros</Col>
                                            <Col>
                                                <Tooltip title="Zerar Juros">
                                                    <Button size="small" icon={<ReloadOutlined />} onClick={zerarJuros} />
                                                </Tooltip>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            },
                            align: 'right',
                            render: ({ valorJuros }) => (
                                <div>
                                    <b className={!!valorJuros && parseFloat(valorJuros) > 0 ? 'c-red' : ''}>
                                        {FormatNumber(!!valorJuros ? valorJuros : 0, true)}
                                    </b>
                                </div>
                            ),
                        }, {
                            title: 'Valor Total (R$)',
                            align: 'right',
                            render: ({ valorOriginal, valorQuitado }) => (
                                <div>
                                    <b>
                                        {FormatNumber((!!valorOriginal ? valorOriginal : 0) - (!!valorQuitado ? valorQuitado : 0), true)}
                                    </b>
                                </div>
                            ),
                        },
                    ]
                } expandable={{
                    expandedRowRender: ({ prc_codigobarras, cpr_numerodocumento }) =>
                        <div>
                            <Row gutter={[8, 0]}>
                                <Col>
                                    Cód. Barras: <b> {prc_codigobarras || 'Não Informado'}</b>
                                </Col>
                                <Col>
                                    Nº Documento: <b> {cpr_numerodocumento || 'Não Informado'}</b>
                                </Col>
                            </Row>
                        </div>,
                    expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                            <ArrowUpOutlined onClick={e => onExpand(record, e)} />
                        ) : (
                            <ArrowDownOutlined onClick={e => onExpand(record, e)} />
                        )
                }} columnWidth={30}
                    pagination={{
                        current: paginaParcAntigas,
                        pageSize: nroRegistrosParcAntigas,
                        total: listaParcelasReparcelar.length,
                        onChange: (pg) => setPaginaParcAntigas(pg),
                        onShowSizeChange: (current, page) => setNroRegistrosParcAntigas(page)
                    }} />
            </div>
            <Form layout="vertical" form={formulario} name="formRecebimentoParcelas" onFinish={reparcelar}>
                {parcelasGeradas.parcelasGeradas.length === 0 &&
                    <Row gutter={[8, 0]} className="m-t-16">
                        <Col xs={24} sm={12} md={7} lg={7} xl={5} xxl={4}>
                            <Form.Item label="Número de Parcelas" name="numeroParcelas">
                                <InputNumber
                                    step={1}
                                    min={1}
                                    placeholder="Número de Parcelas"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={7} lg={7} xl={5} xxl={4} className="tt-5">
                            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.numeroParcelas !== currentValues.numeroParcelas}>
                                {({ getFieldValue }) => {
                                    if (getFieldValue('numeroParcelas') > 0) return (
                                        <Button onClick={() => gerarParcelas()} type="primary" icon={<FormOutlined />} block className="t-mob-573">Gerar Parcelas Novas</Button>
                                    );
                                    else return (
                                        <Button disabled onClick={() => gerarParcelas()} type="primary" icon={<FormOutlined />} block className="t-mob-573">Gerar Parcelas Novas</Button>
                                    );
                                }}
                            </Form.Item>
                        </Col>
                    </Row>
                }
                {parcelasGeradas.parcelasGeradas.length > 0 &&
                    <div className="m-t-16">
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={7} lg={7} xl={5} xxl={4}>
                                <Typography.Title level={5}>
                                    Parcelas Geradas
                                </Typography.Title>
                            </Col>
                            <Col xs={24} sm={12} md={7} lg={7} xl={5} xxl={20} align="right">
                                <Button icon={<DeleteOutlined />} onClick={() => parcelasGeradas.setParcelasGeradas([])} >Gerar Novamente</Button>
                            </Col>
                        </Row>
                        <div className="tabela">
                            <Table dataSource={parcelasGeradas.parcelasGeradas} columns={
                                [
                                    {
                                        title: 'Parcela',
                                        width: 75,
                                        align: 'center',
                                        render: ({ prc_numeroparcela, cpr_numeroparcelas }) => (
                                            <Tag color="processing" className="w-100">
                                                <b>{prc_numeroparcela} - {cpr_numeroparcelas}</b>
                                            </Tag>
                                        ),
                                    },
                                    {
                                        title: 'Vencimento',
                                        width: 130,
                                        render: ({ prc_numeroparcela }, record, idx) => (
                                            <div>
                                                <Data name={`prc_datavencimento${prc_numeroparcela}`} onBlur={() => ajustaDatasParcelas(record, idx)} onPressEnter={() => ajustaDatasParcelas(record, idx)} />
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Juros (R$)',
                                        width: 130,
                                        align: 'right',
                                        render: ({ prc_numeroparcela }) => (
                                            <div>
                                                <InputPreco name={`valorJuros${prc_numeroparcela}`} onBlur={() => onBlurValorJuros(prc_numeroparcela)} />
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Valor Parcela (R$)',
                                        align: 'right',
                                        width: 130,
                                        render: ({ prc_numeroparcela }) => (
                                            <div>
                                                <InputPreco name={`valorParcela${prc_numeroparcela}`} onBlur={(e) => onBlurValorParc(prc_numeroparcela)} disabled={prc_numeroparcela === parcelasGeradas.parcelasGeradas.length} />
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Valor Total (R$)',
                                        align: 'right',
                                        width: 100,
                                        render: ({ prc_valor }) => (
                                            <div>
                                                {<b>{FormatNumber(prc_valor, true)}</b>}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: '',
                                        width: 10,
                                        render: ({ }) => (
                                            <div>
                                            </div>
                                        ),
                                    }
                                ]
                            } columnWidth={30}
                                scroll={{ x: 900, y: 200 }}
                                pagination={{
                                    current: paginaParcGeradas,
                                    pageSize: nroRegistrosParcGeradas,
                                    total: parcelasGeradas.parcelasGeradas.length,
                                    onChange: (pg) => setPaginaParcGeradas(pg),
                                    onShowSizeChange: (current, page) => setNroRegistrosParcGeradas(page)
                                }} />
                        </div>
                    </div>
                }
                <div className="ant-drawer-footer footer-parcelas-recebimento">
                    <Row align="middle" justify="space-between" gutter={[8, 0]}>
                        <Col span={24} className="labelInput m-t-8">
                            <Row align="middle" justify="end" gutter={[8, 0]}>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} align="right">
                                    <Form.Item>
                                        <Row justify="end" gutter={[8, 0]}>
                                            <Col>
                                                Juros
                                            </Col>
                                            <Col>
                                                <Button className={!descontoItem ? 'ant-btn-primary' : ''} onClick={() => setDescontoItem(false)}>
                                                    R$
                                                </Button>
                                                <Button className={descontoItem ? 'ant-btn-primary' : ''} onClick={() => setDescontoItem(true)}>
                                                    %
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form.Item>
                                </Col>
                                {!descontoItem &&
                                    <Col xs={10} sm={8} md={5} lg={4} xl={4} xxl={3}>
                                        <InputPreco name="valorJuros" onBlur={() => { rateioJuros() }} />
                                    </Col>
                                }
                                {descontoItem &&
                                    <Col xs={10} sm={8} md={5} lg={4} xl={4} xxl={3}>
                                        <InputPreco name="percentualJuros" onBlur={() => { calcularJuros() }} />
                                    </Col>
                                }
                            </Row>
                        </Col>
                        {(!!totalizadores.listaTotalizadoresReparcelar.valorDesconto && totalizadores.listaTotalizadoresReparcelar.valorDesconto !== 0) &&
                            <Col span={24}>
                                <Row align="middle" justify="end" gutter={[8, 0]}>
                                    <Col className="f-14">
                                        Valor Descontos (R$):
                                    </Col>
                                    <Col>
                                        <b className="f-18">
                                            {FormatNumber(totalizadores.listaTotalizadoresReparcelar.valorDesconto, true)}
                                        </b>
                                    </Col>
                                </Row>
                            </Col>
                        }
                        {(!!totalizadores.listaTotalizadoresReparcelar.valorAReceber && totalizadores.listaTotalizadoresReparcelar.valorAReceber !== 0) &&
                            <Col span={24}>
                                <Row align="middle" justify="end" gutter={[8, 0]}>
                                    <Col className="f-14">
                                        Valor à Receber (R$):
                                    </Col>
                                    <Col className="f-18 c-primary">
                                        <b> {FormatNumber(parseFloat(totalizadores.listaTotalizadoresReparcelar.valorTotalJuros) + parseFloat(totalizadores.listaTotalizadoresReparcelar.valorAReceber), true)}</b>
                                    </Col>
                                </Row>
                            </Col>
                        }
                    </Row>
                </div>
            </Form>
        </div>
    )
}