import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Divider, Form, Input, Select, InputNumber, Checkbox, Tooltip } from "antd";

import api from "../../services/api";
import { Data, InputPhone, InputCpfCnpj } from "../../components";
import { QuestionCircleOutlined } from "@ant-design/icons";

export default function TabOutrasInformacoes() {

    const [exigibilidadeIssqn, setExigibilidadeIssqn] = useState([]);

    useEffect(() => {
        api.get(`Enum/Listar?nome=ExigibilidadeIssqn`).then(res => {
            if (res.status === 200) {
                setExigibilidadeIssqn(res.data);
            }
        }).catch((erro) => console.error(erro));
    }, []);

    return (
        <div>
            <Row align="middle" gutter={[0, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-info.png").default} alt="Outras Informações" /> Outras Informações
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Adicione abaixo as informações para realizar o cadastro com sucesso.
                    </Divider>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={12} md={7} lg={5} xl={5} xxl={6}>
                            <Form.Item label="E-mail" name="loc_email">
                                <Input placeholder="Informe o E-mail" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={6} md={5} lg={4} xl={4} xxl={3}>
                            <InputPhone label="Telefone" name="loc_telefone" rules={[{ required: true }]} />
                        </Col>
                        <Col xs={24} sm={6} md={5} lg={4} xl={4} xxl={3}>
                            <InputPhone label="Celular" name="loc_celular" />
                        </Col>
                        <Col xs={24} sm={12} md={7} lg={5} xl={5} xxl={6}>
                            <Form.Item label="Identificação do Registro" name="loc_numeroidentregistroempresa">
                                <Input placeholder="Informe o Número de Identificação do Registro" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Inscrição na Junta Comercial" name="loc_numeroinscrjuntacomercial">
                                <Input placeholder="Informe o Número Inscrição na Junta Comercial" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                            <Data label="Data da Inscrição na Junta Comercial" name="loc_datainscricaojuntacomercial" />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Código Atividade Econômica (CNAE)" name="loc_codigoatividadeeconomica">
                                <Input placeholder="Informe o Código da Atividade Econômica" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={7} md={8} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Inscrição Municipal" name="loc_inscricaomunicipal">
                                <Input placeholder="Informe a Inscrição Municipal" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={7} md={8} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Exigibilidade do ISSQN" name="loc_exigibilidadeissqn">
                                <Select showSearch optionFilterProp="children" placeholder="Selecione o Exigibilidade do ISSQN">
                                    {exigibilidadeIssqn.map((dados) => (<Select.Option value={dados.key}>{dados.value}</Select.Option>))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={7} md={5} lg={4} xl={4} xxl={4}>
                            <Form.Item label="Percentual da Alíquota do ISSQN" name="loc_percaliquotaissqn">
                                <InputNumber
                                    defaultValue={0}
                                    min={0}
                                    max={100}
                                    formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={3} md={3} lg={3} xl={3}>
                        <Form.Item name="loc_codigoantt" label={
                            <div>
                                Código ANTT
                                <Tooltip title="Informe o código ANTT (Agência Nacional de Transportes Terrestres)">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                        }>
                            <Input maxLength={8} placeholder="Informe o Código ANTT"/>
                        </Form.Item>
                    </Col>
                        <Col xs={24} sm={8} md={8} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Inscrição Substituto Tributário" name="loc_numeroinscrsubsttributario">
                                <Input placeholder="Informe a Inscrição do Substituto Tributário" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={6} xl={6} xxl={6}>
                            <Form.Item label="Nome do Responsável" name="loc_responsavelestabelecimento">
                                <Input placeholder="Informe o Nome do Responsável" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={5} xl={5} xxl={5}>
                            <InputCpfCnpj cpf label="CPF do Responsável" name="loc_cpfresponsavel" placeholder="Informe o CPF do Responsável" />
                        </Col>
                        <Col>
                            <Form.Item name="loc_incentivofiscalissqn" valuePropName="checked">
                                <Checkbox>Incentivo Fiscal ISSQN?</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="loc_substitutotributario" valuePropName="checked">
                                <Checkbox>Substituto Tributário?</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );

}