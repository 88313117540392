import React, { useEffect, useState } from 'react';
import { Form, Input, Tabs, Row, Col, Badge, Card } from 'antd';
import { Icon } from '@ant-design/compatible';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.less';
import 'slick-carousel/slick/slick-theme.less';

import './index.less';
import {
    TabTodas, TabCliente, TabFuncionario, TabFornecedor, TabVendedor, PesquisaProduto, PesquisaNotaFiscal, PesquisaPedidos,
    PesquisaOrcamento, PesquisaCondicional, PesquisaContaReceber, PesquisaContaPagar, TabTributacaoIcms, TabTributacaoPisCofins
}
    from './pages';
import api from '../../services/api';
import { isObjetoDiffVazio } from '../../services/funcoes';

export default function PesquisaGlobal({ dadosBusca, openPesquisaGlobal }) {
    const [form] = Form.useForm();
    const pesquisaTipo = {
        pessoas: 1,
        produtos: 2,
        notasFiscais: 3,
        pedidos: 4,
        orcamentos: 5,
        condicional: 6,
        tributacao: 7,
        contasPagar: 8,
        contasReceber: 9,
    }
    const [conteudoExibir, setConteudoExibir] = useState(pesquisaTipo.pessoas);
    const settings = {
        speed: 500,
        dots: false,
        slidesToShow: 5,
        slidesToScroll: 1
    };
    const [listaPessoas, setListaPessoas] = useState([]);
    const [listaNota, setListaNota] = useState([]);
    const [listaProdutos, setListaProdutos] = useState([]);
    const [listaPedido, setListaPedido] = useState([]);
    const [listaOrcamento, setListaOrcamento] = useState([]);
    const [listaCondicional, setListaCondicional] = useState([]);
    const [listaTributacaoIcms, setListaTributacaoIcms] = useState([]);
    const [listaContasPagar, setListaContasPagar] = useState([]);
    const [listaContasReceber, setListaContasReceber] = useState([]);
    const [listaTributacaoPisCofins, setListaTributacaoPisCofins] = useState([]);
    const [totalRegistros, setTotalRegistros] = useState(0);

    useEffect(() => {
        if (!!dadosBusca && isObjetoDiffVazio(dadosBusca)) {
            montarTelas(dadosBusca);
        }
    }, [dadosBusca]);

    useEffect(() => {
        if (openPesquisaGlobal) {
            form.setFieldsValue({ busca: localStorage.getItem('@GestorWeb-PesquisaGlobal') })
        }
    }, [openPesquisaGlobal]);

    function efetuarBusca(value) {
        api.get(`BuscaGlobal/buscar?pesquisa=${value}&rota=`).then(
            res => {
                montarTelas(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function montarTelas(dados) {
        telaPessoas(dados.pessoas);
        telaNota(dados.notafiscal);
        telaPedidos(dados.pedidos);
        telaCondicional(dados.vendacondicional);
        telaContasReceber(dados.contasareceber);
        telaContasPagar(dados.contasapagar);
        telaOrcamentos(dados.orcamentos);
        telaProdutos(dados.produtos);
        telaTributacaoIcms(dados.tributacaoicms);
        telaTributacaoPisCofins(dados.tributacaopiscofins)
        setTotalRegistros(dados.pessoas.length + dados.notafiscal.length + dados.pedidos.length + dados.vendacondicional.length + dados.contasareceber.length +
            dados.contasapagar.length + dados.orcamentos.length + dados.produtos.length + dados.tributacaoicms.length + dados.tributacaopiscofins.length)
    };

    function telaPessoas(dados) {
        setListaPessoas(!!dados ? dados : []);
    };

    function telaNota(dados) {
        setListaNota(!!dados ? dados : []);
    };

    function telaPedidos(dados) {
        setListaPedido(!!dados ? dados : []);
    };

    function telaCondicional(dados) {
        setListaCondicional(!!dados ? dados : []);
    };

    function telaContasReceber(dados) {
        setListaContasReceber(!!dados ? dados : []);
    };

    function telaContasPagar(dados) {
        setListaContasPagar(!!dados ? dados : []);
    };

    function telaOrcamentos(dados) {
        setListaOrcamento(!!dados ? dados : []);
    };

    function telaProdutos(dados) {
        setListaProdutos(!!dados ? dados : []);
    };

    function telaTributacaoIcms(dados) {
        setListaTributacaoIcms(!!dados ? dados : []);
    };

    function telaTributacaoPisCofins(dados) {
        setListaTributacaoPisCofins(!!dados ? dados : []);
    };

    return (
        <div>
            <Form size="middle" layout="vertical" form={form}>
                <Row className="fixed-pesquisa">
                    <Col span={24}>
                        <Row justify="center">
                            <Col xl={14} sm={23}>
                                Resultado da Busca: <b className="f-21"> {totalRegistros} Itens </b>
                            </Col>
                            <Col xl={14} sm={23}>
                                <Form.Item label="Buscar por" name="busca">
                                    <Input.Search placeholder="Informe sua Busca" allowClear onSearch={(value) => { efetuarBusca(value) }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            <div className="col-busca">
                <Row align="middle" gutter={[0, 16]} justify="center" className="hg-100"> 
                    <Col xl={14} sm={20}>
                        <Slider {...settings}>
                            <div className="p-5" onClick={() => setConteudoExibir(pesquisaTipo.pessoas)}>
                                <Card hoverable>
                                    <Row align="middle" justify="center">
                                        <Col>
                                            <Badge count={listaPessoas.length}>
                                                <Icon type="User" className="f-16" />
                                            </Badge>
                                        </Col>
                                        <Col span={24} className="text-center text-uppercase">
                                            <b>Pessoas</b>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                            <div className="p-5" onClick={() => setConteudoExibir(pesquisaTipo.produtos)}>
                                <Card hoverable>
                                    <Row align="middle" justify="center">
                                        <Col>
                                            <Badge count={listaProdutos.length}>
                                                <Icon type="Shop" className="f-16" />
                                            </Badge>
                                        </Col>
                                        <Col span={24} className="text-center text-uppercase">
                                            <b>Produtos</b>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                            <div className="p-5" onClick={() => setConteudoExibir(pesquisaTipo.notasFiscais)}>
                                <Card hoverable>
                                    <Row align="middle" justify="center">
                                        <Col>
                                            <Badge count={listaNota.length}>
                                                <Icon type="FileText" className="f-16" />
                                            </Badge>
                                        </Col>
                                        <Col span={24} className="text-center text-uppercase">
                                            <b>Notas Fiscais</b>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                            <div className="p-5" onClick={() => setConteudoExibir(pesquisaTipo.pedidos)}>
                                <Card hoverable>
                                    <Row align="middle" justify="center">
                                        <Col>
                                            <Badge count={listaPedido.length}>
                                                <Icon type="Shop" className="f-16" />
                                            </Badge>
                                        </Col>
                                        <Col span={24} className="text-center text-uppercase">
                                            <b>Pedidos</b>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                            <div className="p-5" onClick={() => setConteudoExibir(pesquisaTipo.orcamentos)}>
                                <Card hoverable>
                                    <Row align="middle" justify="center">
                                        <Col>
                                            <Badge count={listaOrcamento.length}>
                                                <Icon type="FileText" className="f-16" />
                                            </Badge>
                                        </Col>
                                        <Col span={24} className="text-center text-uppercase">
                                            <b>Orçamentos</b>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                            <div className="p-5" onClick={() => setConteudoExibir(pesquisaTipo.condicional)}>
                                <Card hoverable>
                                    <Row align="middle" justify="center">
                                        <Col>
                                            <Badge count={listaCondicional.length}>
                                                <Icon type="Shopping" className="f-16" />
                                            </Badge>
                                        </Col>
                                        <Col span={24} className="text-center text-uppercase">
                                            <b>Condicional</b>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                            <div className="p-5" onClick={() => setConteudoExibir(pesquisaTipo.tributacao)}>
                                <Card hoverable>
                                    <Row align="middle" justify="center">
                                        <Col>
                                            <Badge count={listaTributacaoIcms.length + listaTributacaoPisCofins.length}>
                                                <Icon type="Dollar" className="f-16" />
                                            </Badge>
                                        </Col>
                                        <Col span={24} className="text-center text-uppercase">
                                            <b>Tributação</b>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                            <div className="p-5" onClick={() => setConteudoExibir(pesquisaTipo.contasPagar)}>
                                <Card hoverable>
                                    <Row align="middle" justify="center">
                                        <Col>
                                            <Badge count={listaContasPagar.length}>
                                                <Icon type="Dollar" className="f-16" />
                                            </Badge>
                                        </Col>
                                        <Col span={24} className="text-center text-uppercase">
                                            <b>Contas a Pagar</b>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                            <div className="p-5" onClick={() => setConteudoExibir(pesquisaTipo.contasReceber)}>
                                <Card hoverable>
                                    <Row align="middle" justify="center">
                                        <Col>
                                            <Badge count={listaContasReceber.length}>
                                                <Icon type="Dollar" className="f-16" />
                                            </Badge>
                                        </Col>
                                        <Col span={24} className="text-center text-uppercase">
                                            <b>Contas a Receber</b>
                                        </Col>
                                    </Row>
                                </Card>
                            </div>
                        </Slider>
                    </Col>
                    {conteudoExibir === pesquisaTipo.pessoas &&
                        <Col span={23} className="hg-100">
                            <Tabs defaultActiveKey="1" size="large" type="card" centered>
                                <Tabs.TabPane tab={
                                    <Badge count={listaPessoas.length} className="text-uppercase">
                                        Todas
                                    </Badge>
                                } key="1">
                                    <TabTodas dados={listaPessoas} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={
                                    <Badge count={listaPessoas.filter((cli) => !!cli.cli_id).length} className="text-uppercase">
                                        Cliente
                                    </Badge>
                                } key="2">
                                    <TabCliente dados={listaPessoas.filter((cli) => !!cli.cli_id)} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={
                                    <Badge count={listaPessoas.filter((cli) => !!cli.ven_id).length} className="text-uppercase">
                                        Vendedor
                                    </Badge>
                                } key="3">
                                    <TabVendedor dados={listaPessoas.filter((cli) => !!cli.ven_id)} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={
                                    <Badge count={listaPessoas.filter((cli) => !!cli.for_id).length} className="text-uppercase">
                                        Fornecedor
                                    </Badge>
                                } key="4">
                                    <TabFornecedor dados={listaPessoas.filter((cli) => !!cli.for_id)} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={
                                    <Badge count={listaPessoas.filter((cli) => !!cli.fun_id).length} className="text-uppercase">
                                        Funcionário
                                    </Badge>
                                } key="5">
                                    <TabFuncionario dados={listaPessoas.filter((cli) => !!cli.fun_id)} />
                                </Tabs.TabPane>
                            </Tabs>
                        </Col>
                    }
                    {conteudoExibir === pesquisaTipo.tributacao &&
                        <Col span={23} className="hg-100">
                            <Tabs defaultActiveKey="1" size="large" type="card" centered>
                                <Tabs.TabPane tab={
                                    <Badge count={listaTributacaoIcms.length} className="text-uppercase">
                                        Tributação ICMS
                                    </Badge>
                                } key="1">
                                    <TabTributacaoIcms dados={listaTributacaoIcms} />
                                </Tabs.TabPane>
                                <Tabs.TabPane tab={
                                    <Badge count={listaTributacaoPisCofins.length} className="text-uppercase">
                                        Tributação Pis/Cofins
                                    </Badge>
                                } key="2">
                                    <TabTributacaoPisCofins dados={listaTributacaoPisCofins} />
                                </Tabs.TabPane>
                            </Tabs>
                        </Col>
                    }
                    <Col span={23} className="hg-100 p-b-15">
                        <div className="rowPanel text-center">
                            <Row align="middle" justify="center">
                                {conteudoExibir === pesquisaTipo.produtos &&
                                    <Col span={24}>
                                        <PesquisaProduto dados={listaProdutos} />
                                    </Col>
                                }
                                {conteudoExibir === pesquisaTipo.notasFiscais &&
                                    <Col span={24}>
                                        <PesquisaNotaFiscal dados={listaNota} />
                                    </Col>
                                }
                                {conteudoExibir === pesquisaTipo.pedidos &&
                                    <Col span={24}>
                                        <PesquisaPedidos dados={listaPedido} />
                                    </Col>
                                }
                                {conteudoExibir === pesquisaTipo.orcamentos &&
                                    <Col span={24}>
                                        <PesquisaOrcamento dados={listaOrcamento} />
                                    </Col>
                                }
                                {conteudoExibir === pesquisaTipo.condicional &&
                                    <Col span={24}>
                                        <PesquisaCondicional dados={listaCondicional} />
                                    </Col>
                                }
                                {conteudoExibir === pesquisaTipo.contasReceber &&
                                    <Col span={24}>
                                        <PesquisaContaReceber dados={listaContasReceber} />
                                    </Col>
                                }
                                {conteudoExibir === pesquisaTipo.contasPagar &&
                                    <Col span={24}>
                                        <PesquisaContaPagar dados={listaContasPagar} />
                                    </Col>
                                }
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}