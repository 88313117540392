import React, { useState } from 'react';
import { Row, Col, Button, Form, Modal, Drawer } from "antd";
import { CloseOutlined, ExclamationCircleOutlined, MinusOutlined, SaveOutlined } from '@ant-design/icons';

import ManutencaoClientes from '../../pages/clientes/manutencao';

export default function DrawerCliente({ openDrawer, setOpenDrawer, setCodigoCliente, dadosOrcamento }) {

    const [formulario] = Form.useForm();
    const nameForm = "manutencaoCliente"
    const [carregando, setCarregando] = useState(false);
    const [editando, setEditando] = useState(false);

    formulario.setFieldsValue({ cli_ativo: true });

    function fecharDrawer(idRetorno) {
        if (!!idRetorno) {
            setCodigoCliente(idRetorno);
        }
        formulario.resetFields();
        setOpenDrawer(false);
    };

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <Drawer width="70%"
            title="Cadastro de Cliente"
            visible={openDrawer}
            closable={false}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => {formulario.submit()}} loading={carregando} icon={<SaveOutlined />} size="large" type="primary">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoClientes formulario={formulario} carregando={setCarregando} nameForm={nameForm} aoSalvar={fecharDrawer} editando={{ editando, setEditando }} dadosOrcamento={dadosOrcamento}/>
        </Drawer>
    )
};