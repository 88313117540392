import { Col, Divider, Form, Input, Row, Select, Typography, notification } from 'antd';
import React, { useState, useEffect } from 'react';
import { InputCep, InputCpfCnpj, SelectIncluir, SelectPaginacao } from '../../components';
import { MaskFormat } from '../../ValueObjects';
import { useStateValue } from '../../state';
import api from '../../services/api';
import { selectPaginadoActions } from '../../actions';
import { ModalTelefone } from '../../components/modals';

export default function FornecedorNFE({ form, dadosEndereco, setDadosFornecedor }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [openDrawerFornecedor, setOpenDrawerFornecedor] = useState(false);
    const [listaEndereco, setListaEndereco] = useState([]);
    const [listaEmail, setListaEmail] = useState([]);
    const [dadosTelefone, setDadosTelefone] = useState([]);
    const [abreModalTelefone, setAbreModalTelefone] = useState(false);
    const [pessoaFisica, setPessoaFisica] = useState(1);
    const [formularioTelefone] = Form.useForm();

    useEffect(() => {
        if (listaEndereco?.length > 0 && !!form.getFieldValue().ntf_endereco) {
            buscarDadosEndereco(form.getFieldValue().ntf_endereco);
        }
    }, [listaEndereco]);


    async function buscarDadosFornecedor(registro) {
        if (registro) {
            let fornecedor = (await api.get(`Fornecedor/BuscarFornecedor/${registro}`)).data;
            if (fornecedor) {

                if (fornecedor.pef_produtorrural) {
                    form.setFieldsValue({ 'pej_inscricaoestadual': fornecedor?.enderecos[0].pie_inscricaoestadual });
                } else {
                    form.setFieldsValue({ 'pej_inscricaoestadual': fornecedor.pej_inscricaoestadual });
                }
                let enderecoP = fornecedor.enderecos?.find(e => e.pee_enderecoprincipal);
                if (!!enderecoP) {
                    form.setFieldsValue({ 'ntf_endereco': enderecoP.pee_id });
                }
                let telefoneP = fornecedor.telefones?.find(t => t.psc_principal);
                if (!!telefoneP) {
                    form.setFieldsValue({ 'telefoneDestinatario': telefoneP.psc_id });
                }
                let emailP = fornecedor.emails?.find(e => e.pem_emailprincipal);
                if (!!emailP) {
                    form.setFieldsValue({ 'ntf_emaildestinatario': emailP.pem_id })
                }
                setDadosTelefone(fornecedor.telefones);
                setListaEndereco(fornecedor.enderecos);
                setListaEmail(fornecedor.emails);
                setarCpfCnpj(fornecedor);
                setDadosFornecedor(fornecedor);
            }
        } else {
            form.resetFields();
        }
    }

    function buscarDadosEndereco(pee_id) {
        let end = listaEndereco.filter((end) => (end.pee_id === pee_id))[0];
        if (!!end) {
            form.setFieldsValue({ pse_id: end.pse_id });
            if (end.pse_id) {
                let lista = [];
                lista.push({ name: "pse_id", campo: "pse_id", value: end.pse_id });
                if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            }
            if (end.pie_inscricaoestadual) {
                form.setFieldsValue({ 'pej_inscricaoestadual': end.pie_inscricaoestadual });
            }
            form.setFieldsValue({ cep_cep: end.cep_cep });
            buscarCep(end.cep_cep);
            dadosEndereco(end);
            form.setFieldsValue({ bai_nome: end.bai_nome });
            form.setFieldsValue({ pee_numero: end.pee_numero });
            form.setFieldsValue({ pee_complemento: end.pee_complemento });
        }
    }

    function buscarCep(cep) {
        fetch(`https://viacep.com.br/ws/${cep.replace(`-`, ``)}/json/`)
            .then(
                res => res.json()
            ).then(
                result => {
                    form.setFieldsValue({ cidade: `${result.localidade} / ${result.uf}` });
                },
                erro => {
                    notification.warning({ message: 'Aviso', description: 'Cep não encontrado!' })
                }
            );
    };

    function adicionarTelefone() {
        if (!!form.getFieldValue().pes_id) {
            setAbreModalTelefone(true);
        } else {
            notification.warning({ message: 'Aviso', description: "Favor informar um cliente!" });
        }
    };

    function onCloseModalTelefone() {
        setAbreModalTelefone(false);
    };

    function setarCpfCnpj(registro) {
        let cpfCnpj = '';
        if (registro.pes_fisicajuridica > 0) {
            setPessoaFisica(registro.pes_fisicajuridica)
            if (registro.pes_fisicajuridica === 1) {
                cpfCnpj = registro.pef_cpf;
            } else if (registro.pes_fisicajuridica === 2) {
                cpfCnpj = registro.pej_cnpj;
            } else if (registro.pes_fisicajuridica === 3) {
                cpfCnpj = registro.pes_idestrangeiro;
            }
            form.setFieldsValue({ ntf_cpfcnpj: cpfCnpj });
        } else {
            notification.warn("Fornecedor informado com cadastro de pessoa física/jurídica/estrangeira incorreto!");
            form.setFieldsValue({ for_id: null });
            return false;
        }
    };

    return (
        <div className="m-t-16">
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={12} md={12} lg={7} xl={7}>
                    <Form.Item name="for_id" label="Fornecedor">
                        <SelectPaginacao url="Fornecedor/Listar?Ativo=1" placeholder="Selecione um Fornecedor" form={form} nameLabel="pes_nome"
                            funcaoIncluir={() => setOpenDrawerFornecedor(true)}
                            nameValue="for_id" allowClear={true} onChangeFunction={(dados) => {
                                buscarDadosFornecedor(dados)
                            }} conteudo={
                                pc => (<Select.Option value={pc.for_id} key={pc.key}>{pc.for_codigo} - {pc.pes_nome} <br />
                                    {(!!pc.pej_cnpj ? `CNPJ.: ${MaskFormat(pc.pej_cnpj, '', true)}` : !!pc.pef_cpf ? `CPF.: ${MaskFormat(pc.pef_cpf, '', true)}` : '')}
                                </Select.Option>)
                            } />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={6} lg={4} xl={4}>
                    {pessoaFisica === 1 &&
                        <InputCpfCnpj cpf label="CPF/CNPJ" name="ntf_cpfcnpj" rules={[{ required: true, message: 'Informe o Cpf/Cnpj' }]} disabled />
                    }
                    {pessoaFisica === 2 &&
                        <InputCpfCnpj label="CPF/CNPJ" name="ntf_cpfcnpj" rules={[{ required: true, message: 'Informe o Cpf/Cnpj' }]} disabled />
                    }
                    {pessoaFisica === 3 &&
                        <InputCpfCnpj mask='00.000.000-0' label="CPF/CNPJ" name="ntf_cpfcnpj" rules={[{ required: true, message: 'Informe o Cpf/Cnpj' }]} disabled />
                    }                
                </Col>
                <Col xs={24} sm={6} md={6} lg={4} xl={4}>
                        <Form.Item label="Inscrição Estadual" name="pej_inscricaoestadual">
                            <Input/>
                        </Form.Item>
                    </Col>
                <Col xs={24} sm={8} md={4} lg={3} xl={3}>
                    <SelectIncluir dadosSelect={dadosTelefone}
                        funcaoBotao={() => { adicionarTelefone() }}
                        campoDescricao={'psc_numero'}
                        campoValue={'psc_id'}
                        campokey={'psc_id'}
                        placeHolder={'Selecione o Telefone'}
                        labelFormItem={'Telefone'}
                        nameFormItem={'telefoneDestinatario'} />
                </Col>
                <Col xs={24} sm={16} md={8} lg={6} xl={6}>
                    <Form.Item label="E-mail" name="ntf_emaildestinatario">
                        <Select disabled={listaEmail.length === 0} placeholder="Selecione o E-mail" >
                            {listaEmail.map((mail) => (
                                <Select.Option value={mail.pem_id} key={mail.pem_id}>{mail.pem_email}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[8, 8]} className="m-t-16">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-endereco.png").default} alt="Endereço" /> Endereço
                    </Typography.Title>
                    <Divider />
                </Col>
            </Row>
            <Row gutter={[8, 0]} className="m-t-8">
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Form.Item label="Endereço" name="ntf_endereco" rules={[{ required: true, message: 'Informe o Endereço' }]}>
                        <Select placeholder="Informe o Endereço" onChange={(value) => buscarDadosEndereco(value)} >
                            {listaEndereco.map(
                                (endereco) => (
                                    <Select.Option value={endereco.pee_id} key={endereco.pee_id}>{endereco.log_logradouro}{', '}{endereco.cid_descricao}{' - '}{endereco.est_sigla}
                                    </Select.Option>
                                )
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={4} lg={4} xl={4}>
                    <Form.Item label="País" name="pse_id" rules={[{ required: true, message: 'Informe o País' }]}>
                        <SelectPaginacao url="Pais/Listar" placeholder="Informe o País" form={form} nameValue="pse_id" nameLabel={"pse_nome"} conteudo={
                            pse => (<Select.Option value={pse.pse_id} key={pse.key}>{pse.pse_nome}</Select.Option>)
                        } />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={3} lg={3} xl={3}>
                    <InputCep label="CEP" name="cep_cep" rules={[{ required: true, message: 'Informe o CEP' }]} onBlur={event => { buscarCep(event.currentTarget.value) }} />
                </Col>
                <Col xs={24} sm={5} md={3} lg={3} xl={3}>
                    <Form.Item className="text-center t-mob-573">
                        <Typography.Link href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="_black" underline>
                            Não sei o CEP
                        </Typography.Link>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={9} md={6} lg={6} xl={6}>
                    {<Form.Item label="Cidade" name="cidade">
                        <Input disabled={true}></Input>
                    </Form.Item>}
                </Col>
                <Col xs={24} sm={6} md={6} lg={5} xl={5}>
                    <Form.Item label="Bairro" name="bai_nome">
                        <Input placeholder="Informe o Bairro" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={4} md={4} lg={3} xl={3}>
                    <Form.Item label="Número" name="pee_numero">
                        <Input placeholder="Informe o Número" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={5} lg={4} xl={4}>
                    <Form.Item label="Complemento" name="pee_complemento">
                        <Input placeholder="Informe o Complemento" maxLength={60} />
                    </Form.Item>
                </Col>
            </Row>
            <ModalTelefone form={formularioTelefone} listaTelefones={{ dadosTelefone, setDadosTelefone }} exibirModalTelefone={abreModalTelefone} fecharModalTelefone={() => { onCloseModalTelefone() }} />

        </div>
    );
}