import React, { useEffect } from "react";
import { Row, Col, Tag, Popover, Modal, notification } from "antd";
import { EditOutlined, DeleteOutlined, CopyOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import DrawerMarca from './drawer';
import api from '../../services/api';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, Editar, Excluir, Duplicar } from "../../components";

export default function Marca() {

    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '+mar_nome' } }), []);

    function excluirRegistro(dados) {
        api.delete(`Marca/Excluir/${dados.mar_id}`).then(
            (res) => {
                notification.success({ message: `Sucesso!`, description: `Marca excluida com sucesso!` });
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }).catch(
                (erro) => {
                    modalInativarRegistro(dados);
                }
            ).finally(
                () => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+mar_nome' } });
                });
    }

    function inativarRegistro(dados) {
        dados.mar_ativa = false;
        api.put(`Marca/Editar`, dados).then(
            res => {
                notification.success({ message: `Sucesso!`, description: `Marca inativada com sucesso!` })
            }
        ).catch(
            error => {
                console.log(error);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+mar_nome' } });
            }
        )
    }

    const modalInativarRegistro = (dados) => {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: `Não foi possível excluir a marca ${dados.mar_nome}. Deseja inativar este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                inativarRegistro(dados);
            }
        });
    };

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir a Marca: ${dados.mar_nome}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    const modalDuplicarDados = (dados) => {
        Modal.confirm({
            title: 'Duplicar?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja duplicar a Marca: ${dados.mar_nome}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                duplicarDados(dados);
            }
        });
    };

    function duplicarDados(dados) {
        let valores = { ...dados };
        valores.mar_id = null;
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: valores } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    }

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+mar_nome" },
                { label: "Nome Z - A", value: "-mar_nome" },
                { label: "Código Crescente", value: "+mar_id" },
                { label: "Código Decrescente", value: "-mar_id" }
            ]} />
            <div className="tabela">
                <TabelaDados url="marca/listar" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ mar_id, mar_ativa }) => (
                            <Popover content={mar_ativa ? 'Ativa' : 'Inativa'} placement="right" className="w-100">
                                <Tag color={mar_ativa ? 'processing' : 'default'}>
                                    <b>{mar_id}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Marca',
                        render: ({ mar_nome }) => (
                            <div>
                                <b>{mar_nome}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 95,
                        fixed: 'right',
                        render: (record, dados) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Duplicar icon={<CopyOutlined />} onClick={() => { modalDuplicarDados(dados) }} />
                                    </Col>
                                    <Col>
                                        <Editar
                                            onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} icon={<EditOutlined />}
                                        />
                                    </Col>
                                    <Col>
                                        <Excluir icon={<DeleteOutlined />} onClick={() => { modalExcluir(record) }} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerMarca cadMarca={ true }/>
        </div>
    );
}