
import React, { useEffect, useState } from "react";
import { Row, Col, Tag, Popover, Modal, notification } from "antd";
import { EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from '../../services/api';
import { useStateValue } from '../../state';
import DrawerCondicaoPagamento from './drawer';
import roundValue from '../../ValueObjects/roundValue';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, Editar, Excluir } from "../../components";

export default function CondicaoPagamento() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+cpg_descricao' } });
    }, []);

    function excluirCondicao(condicao) {
        api.post(`CondicaoPagamento/Excluir/${condicao.cpg_id}`).then(
            res => {
                notification.success({ message: 'Aviso', description: 'Condição de pagamento inativada com sucesso!' });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+cpg_descricao' } });
            }
        );

    };

    const modalExcluirCondicao = condicao => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Excluir a Condição de Pagamento ${condicao.cpg_descricao} ?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirCondicao(condicao);
            }
        });
    };


    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+cpg_descricao" },
                { label: "Nome Z - A", value: "-cpg_descricao" },
                { label: "Código Crescente", value: "+cpg_id" },
                { label: "Código Decrescente", value: "-cpg_id" }
            ]} />
            <div className="tabela">
                <TabelaDados url="CondicaoPagamento/listar" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ cpg_id, cpg_ativo }) => (
                            <Popover content={cpg_ativo ? 'Ativa' : 'Inativa'} placement="right">
                                <Tag color={cpg_ativo ? 'processing' : 'default'} className="w-100">
                                    <b>{cpg_id}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Condição de Pagamento',
                        render: ({ cpg_descricao }) => (
                            <div>
                                <b>{cpg_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Desconto Padrão',
                        align: 'right',
                        render: ({ cpg_descontopadrao }) => (
                            <div>
                                <b>{roundValue(cpg_descontopadrao * 100)}%</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Percentual Juros',
                        align: 'right',
                        render: ({ cpg_percentualjuros }) => (
                            <div>
                                <b>{roundValue(cpg_percentualjuros * 100)}%</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 65,
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Editar onClick={() => {
                                            dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                            dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                        }} icon={<EditOutlined />} />
                                    </Col>
                                    {/* <Col>
                                        <Excluir onClick={() => {
                                            modalExcluirCondicao(record);
                                        }} icon={<DeleteOutlined />} />
                                    </Col> */}
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerCondicaoPagamento />
        </div>
    );

}