import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Card, Input, Select, notification } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";

import api from '../../services/api';
import { useStateValue } from "../../state";
import { FormGW, SelectProduto } from "../../components";
import { isObjetoDiffVazio } from "../../services/funcoes";
import SelectPaginado from "../../components/selectPaginado";
import { manutencaoActions, selectPaginadoActions } from "../../actions";

export default function ManutencaoFornecedorProduto({ form, carregando, aoSalvar, editando }) {
    const [{ ui, manutencao, listaSelectPaginacao }, dispatch] = useStateValue();
    const [editandoRegistro, setEditandoRegistro] = useState(false);
    const [filtroProd, setFiltroProd] = useState('')

    const salvarManutencao = values => {
        let retornoDrawer = []
        let enderecoUrl = 'FornecedorProduto/';
        let listaProdutos = [...listaSelectPaginacao?.itens];
        let produto = listaProdutos.filter((pro) => pro.key === form.getFieldValue().pro_id)[0];
        if (!!!produto && !isObjetoDiffVazio(produto)) {
            return;
        }
        values.pro_id = produto.pro_id;
        values.prg_id = produto.prg_id;
        if (!!values.fpr_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, values).then(
                res => {
                    retornoDrawer = res.data;
                    notification.success({ message: 'Operação concluída', description: "Os dados foram salvos com sucesso!" });
                }).catch(
                    error => {
                        console.log(error);
                    }).finally(
                        () => {
                            aoSalvar(retornoDrawer);
                        });
        } else {
            enderecoUrl += `Incluir`;

            api.post(enderecoUrl, values).then(
                res => {
                    retornoDrawer = res.data;
                    notification.success({ message: 'Operação concluída', description: "Os dados foram salvos com sucesso!" });
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            ).finally(
                () => {
                    aoSalvar(retornoDrawer);
                }
            );
        }
    };

    useEffect(() => {
        if (ui.showDrawer && !!manutencao.dados && isObjetoDiffVazio(manutencao.dados)) {
            let lista = [];
            if (!!manutencao.dados.for_id) {
                lista.push({ name: "for_id", campo: "FornecedorId", value: manutencao.dados.for_id });
            }
            if (!!manutencao.dados.pro_id) {
                if (!!manutencao.dados.prg_id) {
                    lista.push({ name: "pro_id", campo: "IdGrade", value: manutencao.dados.prg_id });
                } else {
                    lista.push({ name: "pro_id", campo: "IdProduto", value: manutencao.dados.pro_id });
                }
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
        }
    }, [ui.showDrawer]);

    function editarRegistro() {
        setEditandoRegistro(true);
    };

    function buscarProdutoFornecedor() {
        let listaProdutos = [...listaSelectPaginacao?.itens];
        let produto = listaProdutos.filter((pro) => pro.key === form.getFieldValue().pro_id)[0];
        if (!!!produto && !isObjetoDiffVazio(produto)) {
            return;
        }
        let forId = form.getFieldValue().for_id;
        let parametro = "";
        if (!!produto && isObjetoDiffVazio(produto) && !!produto.prg_id) parametro = "&GradeId=" + produto.prg_id;
        api.get(`FornecedorProduto/Listar?FornecedorId=${forId}&ProdutoId=${produto.pro_id}` + parametro).then(
            res => {
                if (res.data.items.length > 0) {
                    let dados = res.data.items[0];
                    form.setFieldsValue({ fpr_id: dados.fpr_id, fpr_codigo: dados.fpr_codigo, fpr_descricao: dados.fpr_descricao });
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    return (
        <FormGW layout="vertical" form={form} name="manutencaoFornecedorProduto" onFinish={salvarManutencao}>
            {!editandoRegistro &&
                <Row gutter={[8, 0]}>
                    <Form.Item hidden name="fpr_id" />
                    <Form.Item hidden name="prg_id" />
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                        <Form.Item label="Fornecedor" name="for_id" rules={[{ required: true, message: 'O campo Fornecedor é obrigatório!' }]}>
                            <SelectPaginado url="Fornecedor/Listar?Order=pes_nome" placeholder="Selecione o Fornecedor" form={form} name="for_id" onChangeFunction={() => { buscarProdutoFornecedor() }} conteudo={
                                forn => (<Select.Option value={forn.for_id} key={forn.key}>{forn.for_codigo} - {forn.pes_nome}</Select.Option>)
                            }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                        <Form.Item label="Produto" name="pro_id" rules={[{ required: true, message: 'O campo Produto é obrigatório!' }]}>
                            <SelectProduto allowClear={true} valueKey={true} detalhes={true} form={form} placeholder="Selecione o Produto" name="pro_id" ordem={"pro_descricao"} filtroPesq={filtroProd} onChange={() => { buscarProdutoFornecedor() }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
                        <Form.Item label="Cód. do Produto Conforme Fornecedor" name="fpr_codigo" rules={[{ required: true, message: 'O campo Código do Produto Conforme Fornecedor é obrigatório!' }]}>
                            <Input placeholder="Informe o Código Produto Fornecedor" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={24} xl={24} xxl={24}>
                        <Form.Item label="Descrição do Produto Conforme Fornecedor" name="fpr_descricao" rules={[{ required: true, message: 'O campo Descrição do Produto Conforme Fornecedor é obrigatório!' }]}>
                            <Input placeholder="Informe o Descrição Produto Fornecedor" />
                        </Form.Item>
                    </Col>
                </Row>
            }
            {editandoRegistro &&
                <Row>
                    <Col span={24}>
                        <Card title="Editando Relacionamento Fornecedor x Produto...">
                            <Row gutter={[8, 0]}>
                                <Col xs={24} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                    <Form.Item label="Código">
                                        <Input placeholder="Informe o Código" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={18} md={18} lg={18} xl={18} xxl={18}>
                                    <Form.Item label="Fornecedor" name="pes_nome">
                                        <Input placeholder="Informe o Fornecedor" />
                                    </Form.Item>
                                </Col>
                                <Col span={24} className="m-t-8">
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col>
                                            <Button icon={<CheckOutlined />} type="primary" >
                                                Salvar
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button icon={<CloseOutlined />} onClick={() => { setEditandoRegistro(false) }}>
                                                Cancelar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            }
        </FormGW>
    );
}