import React,{ useEffect, useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import { drawerActions, manutencaoActions } from '../../actions';

import Manutencao from './manutencao';
import { DrawerNav } from '../../components';

export default function DrawerPersonalizacao({tipoEntidade, carregaCamposPersonalizados}) {

    const [ formFormulario ] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [ carregando, setCarregando ] = useState(false);
    const [ entidade, setEntidade ] = useState("");
    const idRel = 0
    
    
    function fecharDrawer() {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null}});
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false}});
        formFormulario.resetFields();
        carregaCamposPersonalizados();
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    useEffect(() =>{
        console.log({tipoEntidade});
        switch (tipoEntidade) {
            case "1": 
                setEntidade("Cliente");
                break;
            case "2": 
                setEntidade("Fornecedor");
                break;
            default: 
                setEntidade("Outros");
        };
    },[]);

    useEffect(() =>{
        if (manutencao.dados !== null) {
            formFormulario.setFieldsValue(manutencao.dados);
            console.log("manutenção",manutencao.dados);
        } else {
            formFormulario.resetFields();
        }
    },[!!manutencao.dados])

    return (

        <DrawerNav title={`Personalização de cadastro de ${entidade}`}
            width="70%"
            visible={ui.showDrawer}
            closeIcon={<MinusOutlined />}
            destroyOnClose
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formFormulario.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>                
            <Manutencao formulario={formFormulario} carregando={setCarregando} aoSalvar={fecharDrawer} tipoEntidade={tipoEntidade} />
        </DrawerNav>      

    )
}