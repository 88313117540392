import React, { useEffect, useState } from "react";
import { Row, Col, Tag, Popover, Modal, notification } from "antd";

import DrawerVeiculo from "./drawer";
import api from '../../services/api';
import { useStateValue } from "../../state";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, Editar, Excluir, Duplicar } from "../../components";
import { CopyOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";

export default function Veiculo() {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [editando, setEditando] = useState(false);
    const [primeiroRender, setPrimeiroRender] = useState(false);

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '+vei_descricao' } })
    }, []);

    function editarVeiculo(record) {
        setEditando(true);
        record.vei_dataaquisicao = moment(record.vei_dataaquisicao);
        record.vei_datavenda = moment(record.vei_datavenda);
        let listaTemp = { ...record.vei_inscricaoestadual }

        record.vei_inscricaoestadual_ufestado = listaTemp.ufEstado;
        record.vei_inscricaoestadual_numeroInscricaoEstadual = listaTemp.numeroInscricaoEstadual;

        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
        setPrimeiroRender(false);
    }

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o Veículo ${dados.vei_descricao}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirVeiculo(dados);
            }
        });
    };

    function excluirVeiculo(dados) {
        api.delete(`Veiculo/Excluir/${dados.vei_id}`).then(
            (res) => {
                notification.success({ message: 'Sucesso!', description: 'Veículo excluído com sucesso!' });
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }).catch(
                (erro) => {
                    modalInativarVeiculo(dados);
                    console.log(erro);
                }
            ).finally(
                () => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+vei_descricao' } });
                });
    }

    const modalInativarVeiculo = (dados) => {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: `Não foi possível excluir o veículo ${dados.vei_descricao}. Deseja inativar este veículo?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                inativarVeiculo(dados);
            }
        });
    };

    function inativarVeiculo(dados) {
        dados.vei_ativo = false;
        api.put(`Veiculo/Editar`, dados).then(
            res => {
                notification.success({ message: 'Sucesso!', description: 'Veículo inativado com sucesso!' })
            }
        ).catch(
            error => {
                console.log(error);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+vei_descricao' } });
            }
        )
    }

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+vei_descricao" },
                { label: "Nome Z - A", value: "-vei_descricao" },
                { label: "Código Crescente", value: "+vei_id" },
                { label: "Código Decrescente", value: "-vei_id" },
            ]} />
            <div className="tabela">
                <TabelaDados url="veiculo/listar" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ vei_id, vei_ativo }) => (
                            <Popover content={vei_ativo ? 'Ativo' : 'Inativo'} placement="right" className="w-100">
                                <Tag color={vei_ativo ? 'processing' : 'default'}>
                                    <b>{vei_id}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Nome',
                        render: ({ vei_descricao }) => (
                            <div>
                                <b>{vei_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Modelo',
                        render: ({ vei_modelo }) => (
                            <div>
                                <b>{vei_modelo}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Placa',
                        render: ({ vei_placa }) => (
                            <div>
                                <b>{vei_placa}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 95,
                        fixed: 'right',
                        render: (record, dados) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Editar onClick={() => {
                                            editarVeiculo(record);
                                        }} icon={<EditOutlined />}
                                        />
                                    </Col>
                                    <Col>
                                        <Excluir icon={<DeleteOutlined />} onClick={() => { modalExcluir(record) }} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerVeiculo cadVeiculo={true} editando={{ editando, setEditando }} />
        </div>
    )
}
