import { getPermissao, getStatusFilial } from "./auth";

const tipoDeValidacao = {
    menu: 0,
    tela: 1
};

const botoesValidacao = {
    Incluir: 0,
    Editar: 1,
    Excluir: 2,
    Imprimir: 3,
    Exportar: 4,
    Duplicar: 5,
    ImportarXML: 6,
    CancelarEstorno: 7,
    Cancelar: 8,
    NotaFiscal: 9,
    EmitirNotaFiscalDebito: 10,
    EmitirNotaFiscalTranferencia: 11,
    Inutilizar: 12,
    LancamentoEntrada: 13,
    LancamentoSaida: 14,
    LancamentoTransferencia: 15,
    Consultar: 16,
    GerarEfdIcmsIpi: 17,
    Bordero: 18,
    Download: 19,
    Reprocessar: 20,
    Adicionar: 21,
    Emitir: 22,
};

const valuesBotoes = {
    0: "Incluir",
    1: "Editar",
    2: "Excluir",
    3: "Imprimir",
    4: "Exportar",
    5: "Duplicar",
    6: "ImportarXML",
    7: "CancelarEstorno",
    8: "Cancelar",
    9: "NotaFiscal",
    10: "EmitirNotaFiscalDebito",
    11: "EmitirNotaFiscalTranferencia",
    12: "Inutilizar",
    13: "LancamentoEntrada",
    14: "LancamentoSaida",
    15: "LancamentoTransferencia",
    16: "Consultar",
    17: "GerarEfdIcmsIpi",
    18: "Bordero",
    19: "Download",
    20: "Reprocessar",
    21: "Adicionar",
    22: "Emitir",
};

export function validarPermissaoMenu(itemMenu) {
    let url = itemMenu.pagina.replace('/', '');
    if (!!!JSON.parse(getPermissao()) || JSON.parse(getPermissao()).length === 0) {
        return false;
    }
    let objPermissao = JSON.parse(getPermissao()).filter(perm => perm.pru_nome.toLowerCase() === url.trim().toLowerCase())[0];
    if (!!!objPermissao) {
        return false;
    }
    objPermissao.pru_permissoes = JSON.parse(objPermissao.pru_permissoes);
    let permissoes = objPermissao.pru_permissoes[0].sistema[0];
    if (!!!permissoes) {
        return false;
    } else {
        return !permissoes.acesso;
    }
};

export function validarPermmissao(tipo = 1) {
    let url = window.location.href.split('/');
    if (JSON.parse(getPermissao())?.length > 0) {
        let objPermissao = JSON.parse(getPermissao()).filter(perm => { if (!!perm.pru_nome) { return perm.pru_nome.toLowerCase() === url[url.length - 1].trim().toLowerCase() } else { return false } })[0];
        if (!!!objPermissao) {
            return false;
        }
        objPermissao.pru_permissoes = JSON.parse(objPermissao.pru_permissoes);
        let permissoes = objPermissao.pru_permissoes[0].sistema[0];
        let retorno = false;
        if (tipo === tipoDeValidacao.menu) {
            retorno = permissoes.acesso;
        } else {
            retorno = permissoes.elementos;
        }
        return retorno;
    }
};

export function validarPermissaoTela(metodo) {
    let retorno = true;
    if (!getStatusFilial()) {
        retorno = false;
    } else {
        let permissoes = validarPermmissao(1);
        if (!!permissoes) {
            let acao = retornaAcao(metodo);
            let permissao = permissoes.filter(elem => elem.metodo === acao);
            retorno = permissao.length > 0 ? permissao[0].acesso : false;
        }
    }

    return !retorno;
};

function retornaAcao(metodo) {
    let acao = "";
    switch (metodo) {
        case botoesValidacao.Incluir:
            acao = valuesBotoes[botoesValidacao.Incluir];
            break;
        case botoesValidacao.Editar:
            acao = valuesBotoes[botoesValidacao.Editar];
            break;
        case botoesValidacao.Excluir:
            acao = valuesBotoes[botoesValidacao.Excluir];
            break;
        case botoesValidacao.Imprimir:
            acao = valuesBotoes[botoesValidacao.Imprimir];
            break;
        case botoesValidacao.Exportar:
            acao = valuesBotoes[botoesValidacao.Exportar];
            break;
        case botoesValidacao.Duplicar:
            acao = valuesBotoes[botoesValidacao.Duplicar];
            break;
        case botoesValidacao.ImportarXML:
            acao = valuesBotoes[botoesValidacao.ImportarXML];
            break;
        case botoesValidacao.CancelarEstorno:
            acao = valuesBotoes[botoesValidacao.CancelarEstorno];
            break;
        case botoesValidacao.Cancelar:
            acao = valuesBotoes[botoesValidacao.Cancelar];
            break;
        case botoesValidacao.NotaFiscal:
            acao = valuesBotoes[botoesValidacao.NotaFiscal];
            break;
        case botoesValidacao.EmitirNotaFiscalDebito:
            acao = valuesBotoes[botoesValidacao.EmitirNotaFiscalDebito];
            break;
        case botoesValidacao.EmitirNotaFiscalTranferencia:
            acao = valuesBotoes[botoesValidacao.EmitirNotaFiscalTranferencia];
            break;
        case botoesValidacao.Inutilizar:
            acao = valuesBotoes[botoesValidacao.Inutilizar];
            break;
        case botoesValidacao.LancamentoEntrada:
            acao = valuesBotoes[botoesValidacao.LancamentoEntrada];
            break;
        case botoesValidacao.LancamentoSaida:
            acao = valuesBotoes[botoesValidacao.LancamentoSaida];
            break;
        case botoesValidacao.LancamentoTransferencia:
            acao = valuesBotoes[botoesValidacao.LancamentoTransferencia];
            break;
        case botoesValidacao.Consultar:
            acao = valuesBotoes[botoesValidacao.Consultar];
            break;
        case botoesValidacao.GerarEfdIcmsIpi:
            acao = valuesBotoes[botoesValidacao.GerarEfdIcmsIpi];
            break;
        case botoesValidacao.Bordero:
            acao = valuesBotoes[botoesValidacao.Bordero];
            break;
        case botoesValidacao.Download:
            acao = valuesBotoes[botoesValidacao.Download];
            break;
        case botoesValidacao.Reprocessar:
            acao = valuesBotoes[botoesValidacao.Reprocessar];
            break;
        case botoesValidacao.Adicionar:
            acao = valuesBotoes[botoesValidacao.Adicionar];
            break;
        case botoesValidacao.Emitir:
            acao = valuesBotoes[botoesValidacao.Emitir];
            break;
    }
    return acao;
}