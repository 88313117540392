import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Form, Divider, InputNumber, Card } from "antd";
import { useStateValue } from "../../state";
import { validarNumeros } from "../../services/funcoes";
import { FormasPagamentoRecebimento, InputPreco } from "../../components";

export default function TabFaturamento({ form, dadosItens, dadosOperacao }) {

    const [valorNota, setValorNota] = useState(0);
    const [{ manutencao }, dispatch] = useStateValue();
    useEffect(() => {
        calcularFaturamento();
    }, [manutencao.dados]);

    useEffect(() => {
        recalcularTotal();
    }, [valorNota]);

    function calcularFaturamento() {
        let formulario = form.getFieldValue();
        let subTotal = 0;
        dadosItens.forEach((item) => {
            subTotal += item.valorTotalDesconto;
        });
        form.setFieldsValue({ subTotalNotaFiscal: parseFloat(subTotal).toFixed(2)});

        setValorNota(parseFloat(subTotal) + validarNumeros(formulario.orc_valorfrete) + validarNumeros(formulario.orc_valorseguro) + validarNumeros(formulario.valorOutrasDespesas));

        recalcularTotal();
    };

    function calcularDesconto(campo) {
        let formulario = form.getFieldValue();
        let valor = validarNumeros(formulario.valorDesconto);
        let perc = validarNumeros(formulario.percentualDesconto);
        let tot = parseFloat(valorNota);
        let result = 0;
        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ valorDesconto: result.toFixed(2) });
            } else {
                result = (valor * 100) / tot;
                form.setFieldsValue({ percentualDesconto: result.toFixed(2) });
            }

        }
        recalcularTotal();
    };

    function calcularDesconto(campo) {
        let formulario = form.getFieldValue();
        let valor = validarNumeros(formulario.valorDesconto);
        let perc = validarNumeros(formulario.percentualDesconto);
        let tot = parseFloat(valorNota);
        let result = 0;
        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ valorDesconto: result.toFixed(2) });
            } else {
                result = (valor * 100) / tot;
                form.setFieldsValue({ percentualDesconto: result.toFixed(2) });
            }

        }
        recalcularTotal();
    };

    function calcularAcrescimo(campo) {
        let formulario = form.getFieldValue();
        let valor = validarNumeros(formulario.valorAcrescimo);
        let perc = validarNumeros(formulario.percentualAcrescimo);
        let tot = (!!valorNota ? parseFloat(valorNota) : 0);
        let result = 0;
        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ valorAcrescimo: result.toFixed(2) });
            } else {
                result = (valor * 100) / tot;
                form.setFieldsValue({ percentualAcrescimo: result.toFixed(2) });
            }
        }
        recalcularTotal();
    };

    function recalcularTotal() {
        let formulario = form.getFieldValue();
        let valor = 0;
        if (!!formulario) {
            valor = valorNota - validarNumeros(formulario.valorDesconto) + validarNumeros(formulario.valorAcrescimo);
            form.setFieldsValue({ valorTotal: valor.toFixed(2) });
            let valorPago = 0; 
            if (!!manutencao.dados && !!manutencao.dados.faturamento) {
                manutencao.dados.faturamento.forEach((dadosFat) => {
                    valorPago += parseFloat(dadosFat.pfp_valor);
                });
            }
            form.setFieldsValue({ pfp_valor: (valor - valorPago).toFixed(2) });
        }
    };

    return (
        <div className="m-t-16 faturar">
            <Row align="middle" justify="center" gutter={[8, 24]}>
                <Col xs={24} sm={24} md={24} lg={16} xl={14} xxl={12}>
                    <Card extra={
                        <Row justify="center">
                            <Col>
                                <Typography.Title level={3}>
                                    Totais
                                </Typography.Title>
                            </Col>
                        </Row>
                    }>
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <InputPreco disabled label="Subtotal (R$)" name="subTotalNotaFiscal" className="text-right" />
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <InputPreco
                                    label="Valor do Frete (R$)" name="orc_valorfrete"
                                    onChange={() => calcularFaturamento()}
                                    className="text-right" />
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <InputPreco label="Valor do Seguro (R$)" name="orc_valorseguro"
                                    onChange={() => calcularFaturamento()}
                                    className="text-right" />
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <InputPreco label="Outras Despesas (R$)" name="valorOutrasDespesas"
                                    onChange={() => calcularFaturamento()}
                                    className="text-right" />
                            </Col>
                        </Row>
                        <Row align="bottom" justify="space-between" gutter={[8, 0]}>
                            <Col span={10}>
                                <Form.Item label="Percentual de Desconto" name="percentualDesconto" initialValues={0} defaultValue={0}>
                                    <InputNumber
                                        
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        onChange={() => { calcularDesconto('PERC'); }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} className="text-center">
                                <b> ou </b>
                            </Col>
                            <Col span={10}>
                                <InputPreco label="Valor do Desconto (R$)"
                                    name="valorDesconto"
                                    onChange={() => { calcularDesconto('VALOR'); }} />
                            </Col>
                        </Row>
                        <Row align="bottom" justify="space-between" gutter={[8, 0]}>
                            <Col span={10}>
                                <Form.Item label="Percentual de Acréscimo" name="percentualAcrescimo" initialValues={0} defaultValue={0}>
                                    <InputNumber
                                        //defaultValue={0}
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        onChange={() => { calcularAcrescimo('PERC') }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} className="text-center">
                                <b> ou </b>
                            </Col>
                            <Col span={10}>
                                <InputPreco label="Valor do Acréscimo (R$)"
                                    name="valorAcrescimo"
                                    onChange={() => { calcularAcrescimo('VALOR') }} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Divider />
                            </Col>
                        </Row>
                        <Row justify="end">
                            <Col>
                                <Typography.Title level={4} className="m-0">
                                    Total da Nota (R$)
                                </Typography.Title>
                            </Col>
                        </Row>
                        <Row justify="end" className="inpDis">
                            <InputPreco className="f-18" name="valorTotal" disabled />
                        </Row>
                    </Card>
                </Col>
            </Row>
            <FormasPagamentoRecebimento form={form} dadosOperacao={dadosOperacao} dadosItens={dadosItens} 
             />
        </div>
    );
}