import { Col, Form, Input, InputNumber, Modal, Row, Select, notification } from "antd";
import React, { useState, useEffect } from "react";
import SelectPaginacao from "../../selects/selectPaginacao";
import { CloseOutlined, ExclamationCircleOutlined, PlusOutlined, TrophyFilled } from "@ant-design/icons";
import UploadFoto from "./UploadFoto";
import api from "../../../services/api";

export default function ModalManutencaoPecasCat({ formGrupo, aoSalvar, listaPecas, exibirModalItens, editando, sequencia }) {
    const [formulario] = Form.useForm();
    const [dadosPecasSelect, setDadosPecasSelect] = useState([]);
    const [listaImagens, setListaImagens] = useState([]);
    const [flagCodPeca ,setFlagCodPeca] = useState(false);
    const [jaTemFoto, setJaTemFoto] = useState(false);

    useEffect(() => {
        let codPeca = formulario.getFieldValue().codpeca;
        if(codPeca && !!flagCodPeca)
        {
            api.get(`UploadImagens/GetImgMongo?filename=${codPeca}`).then(
                res => {
                    let dados = res.data.map((x) => ({ ...x}))
                    if(dados.length > 0){
                        setListaImagens(dados);
                    }
                }
            ).catch(error => {
                console.log(error);
            });
        }
        setFlagCodPeca(false);
    }, [flagCodPeca])

    useEffect(() => {
        if (editando.editando) {
            let listaTemp = listaPecas.listaPecas.find(x => x.Sequencia == sequencia.sequencial);
            formulario.setFieldsValue({
                codCatalogo: formGrupo.getFieldValue().codCatalogo,
                sequencia: listaTemp.Sequencia,
                numeroItem: listaTemp.NumeroItem,
                codpeca: listaTemp.descpeca,
                quantidade: listaTemp.Quantidade,
                observacoes: listaTemp.Observacoes,
            });
            //verificar se ja tem imagem

        } else {
            formulario.resetFields();
        }
        //VERIFICAR LOGICA NUMEROITEM
        if (!!exibirModalItens && !editando.editando) {
            const listaPecasLength = listaPecas.listaPecas ? listaPecas.listaPecas.length : 0;
            formulario.setFieldsValue({ codCatalogo: formGrupo.getFieldValue().codCatalogo, sequencia: listaPecasLength + 1 })
            formulario.setFieldsValue({ codCatalogo: formGrupo.getFieldValue().codCatalogo, numeroItem: listaPecasLength + 1 })
        }
    }, [exibirModalItens, editando.editando]);

    const salvarManutencao = () => {
        let dados = [];
        dados.SeqGrupo = formulario.getFieldValue().sequencia;
        if(editando.editando) {
            const pecaEditando = listaPecas.listaPecas.find(x => x.descpeca === formulario.getFieldValue().codpeca);
            dados.CodPeca = pecaEditando ? pecaEditando.CodPeca : formulario.getFieldValue().codpeca;
        }
        else {
            dados.CodPeca = formulario.getFieldValue().codpeca;
        }
        dados.NumeroItem = formulario.getFieldValue().numeroItem;
        dados.Quantidade = formulario.getFieldValue().quantidade;
        dados.Observacoes = formulario.getFieldValue().observacoes;

        var dadosPeca = dadosPecasSelect.find(x => x.codpeca === dados.CodPeca);
        dados.descpeca = dadosPeca.descpeca;
        let lista = [...listaPecas.listaPecas];

        if (editando.editando) {
            const index = lista.findIndex(item => item.Sequencia === sequencia.sequencial);
            if (index !== -1) {
                dados.Sequencia = index + 1;
                lista[index] = dados;
                listaPecas.setListaPecas(lista);
                editando.setEditando(false);
            }
        } else {
            dados.Sequencia = (listaPecas.listaPecas.length + 1);
            lista.push(dados);
            listaPecas.setListaPecas(lista);
        }
        onCloseModal();
        formulario.resetFields();
        setListaImagens([]);
        setJaTemFoto(false);
    }
    const validarCodPeca = (rules, value, callback) => {
        if (value && listaPecas?.listaPecas?.some(peca => peca.CodPeca === value && peca.Sequencia !== sequencia)) {
            notification.warning({ description: 'Esta peça já foi adicionada!', message: 'Aviso!' });
        } else {
            callback();
        }
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                setListaImagens([]);
                setJaTemFoto(false);
                onCloseModal();
            }
        })
    }

    const onCloseModal = () => {
        aoSalvar();
        formulario.resetFields();
    }

    const onOk = () => {
        formulario.submit();
        let codPeca = formulario.getFieldValue().codpeca;
        if(codPeca != null && !jaTemFoto && listaImagens.length > 0)
        {
            let image = {
                FileName: formulario.getFieldValue().codpeca,
                Base64Data: listaImagens[0].thumbUrl,
            };
            salvarFoto(image, codPeca);
        }
    }
    
    const salvarFoto = (image, codPeca) => {
        api.post(`UploadImagens/UploadImgMongo?codPeca=${codPeca}`, image).then(
            res => {
                if (res.status === 200) {
                    notification.success("Imagem adicionada com sucesso!");
                }
            }
        ).catch(
            erro => {
                console.error(erro);
                notification.error("Erro ao adicionar imagem!");
            }
        );
    }

    return (
        <Modal centered
            title="Peças Catálogo"
            visible={exibirModalItens}
            onCancel={onClose}
            onOk={() => onOk()}
            okText={
                <>
                    <PlusOutlined /> Adicionar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <Form form={formulario} onFinish={salvarManutencao} layout="vertical">
                <Row gutter={[8, 0]}>
                    <Col span={12}>
                        <Form.Item label="Cód. Catálogo" name="codCatalogo">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Sequência" name="sequencia" >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col span={4}>
                        <Form.Item label="Número item" name="numeroItem">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item label="Peça" name="codpeca" rules={[{ required: true, message: 'Selecione a peça.' }, { validator: validarCodPeca }]} >
                            <SelectPaginacao url="Cat007/ListarFiltros?empresa=1" form={formulario} placeholder="Favor selecionar a peça" nameValue="codpeca"
                                nameLabel="descpeca"
                                setListaDados={setDadosPecasSelect}
                                onChangeFunction={()=>setFlagCodPeca(true)}
                                conteudo={res => (
                                    <Select.Option key={res.key} value={res.codpeca}>{res.descpeca}</Select.Option>
                                )}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Quantidade"
                            name="quantidade"
                            rules={[
                                { required: true, message: 'Informe a quantidade.' },
                                { type: 'number', min: 0, message: 'A quantidade não pode ser negativa.' }
                            ]}>
                            <InputNumber step={1} placeholder="Favor informar a quantidade" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]}>
                    <Col span={24}>
                        <Form.Item label="Observação" name="observacoes">
                            <Input placeholder="Favor informar a observação..." />
                        </Form.Item>
                    </Col>
                    <Col>
                        <UploadFoto 
                            listaImagens={listaImagens} 
                            setListaImagens={setListaImagens} 
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}