import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Tag, Popconfirm, notification, Select } from 'antd';
import { EditOutlined, DeleteOutlined, PrinterOutlined } from '@ant-design/icons';

import api from '../../services/api';
import DrawerPersonalizacao from './drawer';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados } from "../../components";
import { Link } from 'react-router-dom';

export default function PersonalizacaoRelatorios({ }) {

    const [{ listagem, manutencao }, dispatch] = useStateValue();
    const [grupos, setGrupos] = useState([]);
    const [listaSistema, setSistema] = useState([]);
    const [formPesquisaAvancada] = Form.useForm();


    function editarFormRelatorio(valores) {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: valores } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function excluirRegistro(record) {
        api.delete(`RelatorioJasper/Excluir/${record.rel_id}`).then(
            (res) => {
                notification.success({ message: `Sucesso!`, description: `Relatório Jasper excluído com sucesso!` })
            }).catch(
                (erro) => {
                    console.log(erro);
                    alert('Erro ao excluir Telatório!');
                }
            ).finally(
                () => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+rel_nome' } });
                }
            );
    };

    useEffect(() => {
        api.get('Enum/Listar?nome=Sistema').then(
            (res) => {
                if (res.status === 200) {
                    setSistema(res.data);
                }
            }).catch((erro) => console.error(erro));

        api.get(`RelatorioJasper/ListarGruposRelatorio`).then(res => {
            if (res.status === 200) {
                setGrupos(res.data);
            }
        }).catch((erro) => console.error(erro));
    }, []);

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '+rel_nome' } }), []);


    function retornoPesquisaAvancada() {
        var filtrosTela = ``;
        if (formPesquisaAvancada.getFieldsValue().grupo) filtrosTela += `&CodigoGrupo=${formPesquisaAvancada.getFieldsValue().grupo}`;
        if (formPesquisaAvancada.getFieldsValue().sistema) filtrosTela += `&Sistema=${formPesquisaAvancada.getFieldsValue().sistema}`;
        if (filtrosTela) dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: filtrosTela, filtro: '', ordem: '+rel_nome' } })
        else dispatch({ type: listagemActions.CHANGE, data: { filtro: '', outrosFiltros: '', ordem: '+rel_nome' } })
    }

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Form layout="vertical" name="formularioPesqAvancada" form={formPesquisaAvancada} onFinish={retornoPesquisaAvancada}>
                <Row gutter={[8, 0]} justify="bottom">
                    <Col xs={24} sm={24} md={18} lg={16} xl={16}>
                        <PesquisaOrdenacao opcoesOrdenacao={[
                            { label: "Nome A - Z", value: "+rel_nome" },
                            { label: "Nome Z - A", value: "-rel_nome" },
                            { label: "Código Crescente", value: "+rel_id" },
                            { label: "Código Decrescente", value: "-rel_id" }
                        ]} />
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={4} xl={4}>
                        <Form.Item label="Filtrar Grupo de Relatório" name="grupo">
                            <Select
                                onChange={() => retornoPesquisaAvancada()}
                                placeholder="Selecionar Relatórios por Grupo"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                            >
                                {grupos.map((grupo) => (
                                    <Select.Option value={grupo.grj_id} key={grupo.grj_id}> {grupo.grj_descricao}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={4} xl={4}>
                        <Form.Item label="Filtrar Sistema" name="sistema">
                            <Select
                                onChange={() => retornoPesquisaAvancada()}
                                placeholder="Sistema"
                                allowClear
                            >
                                {listaSistema.map((s) => (
                                    <Select.Option key={s.key} value={s.key}>{s.value}</Select.Option>
                                ))}

                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="/RelatorioJasper/Listar" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ rel_id }) => (
                            <Tag color="processing" className="w-100">
                                <b>{rel_id}</b>
                            </Tag>
                        )
                    },
                    {
                        title: 'Nome Relatório',
                        width: '20%',
                        render: ({ rel_nome }) => (
                            <div>
                                <b>{rel_nome}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Descrição',
                        render: ({ rel_descricao }) => (
                            <div>
                                <b>{rel_descricao}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Disponível',
                        render: ({ rel_nomearquivo }) => (
                            <div>
                                <b>{rel_nomearquivo}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 100,
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Link to={{
                                            pathname: "/filtrosRelatorio",
                                            //search: `?id=${record.rel_id}`,
                                            state: { id: record.rel_id, nomeArquivo: record.rel_nomearquivo, nomeRelatorio: record.rel_nome, retorno: "/personalizacaoRelatorios" }
                                        }} onClick={() => {
                                            //dispatch({ type: manutencaoActions.CHANGE, data: { dados: produto } });
                                            localStorage.setItem("@GestorWeb-FiltrosRelatorio", JSON.stringify({ id: record.rel_id, nomeArquivo: record.rel_nomearquivo, nomeRelatorio: record.rel_nome, retorno: "/personalizacaoRelatorios" }))
                                        }}>
                                            <Button icon={<PrinterOutlined />} />
                                        </Link>
                                    </Col>
                                    <Col>
                                        <Button onClick={() => editarFormRelatorio(record)} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>
                                        <Popconfirm placement="left" title={`Deseja realmente excluir o registro?`} onConfirm={() => excluirRegistro(record)} okText="Sim" cancelText="Não">
                                            <Button icon={<DeleteOutlined />} />
                                        </Popconfirm>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                ]} />
            </div>
            <DrawerPersonalizacao grupos={grupos} />
        </div>
    );

}