
import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Descriptions, Form, Table } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";

import { InputPreco } from "../../"
import { useStateValue } from "../../../state";
import { FormatNumber } from "../../../ValueObjects";
import { manutencaoActions } from "../../../actions";

export default function ModalAntecipacaoClienteFornecedor({ formAnterior, exibirModalAntecipacaoClienteFornecedor, fecharModalAntecipacaoClienteFornecedor, dadosAntecipacao }) {

    const [listaAntecipacao, setListaAntecipacao] = useState([]);
    const [valorAntecipacao, setValorAtecipacao] = useState(0);
    const [{ manutencao }, dispatch] = useStateValue();
    const [form] = Form.useForm();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalAntecipacaoClienteFornecedor();
            }
        });
    };

    useEffect(() => {
        if (exibirModalAntecipacaoClienteFornecedor && !!formAnterior.getFieldValue() && formAnterior.getFieldValue().pfp_valor) {
            form.setFieldsValue({ 'valorPagar': formAnterior.getFieldValue().pfp_valor });
        }
    }, [exibirModalAntecipacaoClienteFornecedor]);

    useEffect(() => {
        let totalAntecipacao = 0;
        if (listaAntecipacao.length > 0) {
            listaAntecipacao.forEach((antecipacao) => {
                totalAntecipacao += parseFloat(antecipacao.acf_valor) - parseFloat(antecipacao.anm_valor);
                antecipacao.valorUtilizado = 0;
            });
        }
        setValorAtecipacao(totalAntecipacao);
    }, [listaAntecipacao]);

    function selecionarAntecipacoes(selectedRowKeys, selectedRows) {
        setListaAntecipacao(selectedRows);
    };

    function salvarAntecipacao() {
        let valorNota = 0;
        let valorRestante = 0;
        if (!!formAnterior.getFieldValue().pfp_valor) {
            valorNota = parseFloat(formAnterior.getFieldValue().pfp_valor);
            valorRestante = valorNota;
        }
        let valorAntecipacao = 0;
        listaAntecipacao.forEach((antecipacao, index, array) => {
            let saldoAUtilizar = (parseFloat(antecipacao.acf_valor) - parseFloat(antecipacao.anm_valor))
            if (valorRestante - saldoAUtilizar >= 0) {
                antecipacao.valorUtilizado = saldoAUtilizar;
            } else {    
                antecipacao.valorUtilizado = saldoAUtilizar + (valorRestante - saldoAUtilizar);
            }
            valorRestante -= antecipacao.valorUtilizado;
            valorAntecipacao += antecipacao.valorUtilizado;
            if (valorAntecipacao >= valorNota) {
                array.length = index + 1;
            }
        });
        listaAntecipacao.forEach(antecipacao => { antecipacao.valorAntecipacao = valorAntecipacao });
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ antecipacao: listaAntecipacao } } } });
        fecharModalAntecipacaoClienteFornecedor();
    };

    return (
        <Modal centered
            title="Atenção"
            width='35%'
            visible={exibirModalAntecipacaoClienteFornecedor}
            onCancel={onCloseModal}
            onOk={salvarAntecipacao}
            okText={
                <>
                    <PlusOutlined /> Confirmar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }   
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="formAntecipacaoClienteFornecedor" form={form}>
                    <Row gutter={[0, 8]}>
                        <Col>
                            <Descriptions size="small">
                                <Descriptions.Item>
                                    Abaixo valor referente ANTECIPAÇÃO gerada por: <b>{formAnterior.getFieldValue().pes_nome}</b>
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item>
                                    Localize a antecipação
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <div className="tabela"> 
                        <Table pagination={false} columns={
                            [
                                {
                                    title: 'Nota',
                                    width: 75,
                                    align: 'center',
                                    render: ({ ntf_id }) => (
                                        <div>
                                            <b>{ntf_id}</b>
                                        </div>
                                    ),
                                }, {
                                    title: 'Data',
                                    render: ({ acf_datalancamento }) => (
                                        <div><b>{moment(acf_datalancamento).format("DD/MM/YYYY")}</b></div>
                                    ),
                                }, {
                                    title: 'Vlr. Antecipação(R$)',
                                    align: 'right',
                                    render: ({ acf_valor }) => (
                                        <div><b>{FormatNumber(acf_valor, true)}</b></div>
                                    ),
                                }, {
                                    title: 'Vlr. Utilizado(R$)',
                                    align: 'right',
                                    render: ({ anm_valor }) => (
                                        <div><b>{FormatNumber(anm_valor, true)}</b></div>
                                    ),
                                }, {
                                    title: 'Saldo a Utilizar(R$)',
                                    align: 'right',
                                    render: ({ acf_valor, anm_valor }) => (
                                        <div><b>{FormatNumber((acf_valor) - (anm_valor), true)}</b></div>
                                    ),
                                },
                            ]
                        }
                            dataSource={dadosAntecipacao}
                            rowSelection={{
                                onChange: (selectedRowKeys, selectedRows) => {
                                    selecionarAntecipacoes(selectedRowKeys, selectedRows);
                                }
                            }}
                            footer={
                                () => {
                                    return (
                                        <div>
                                            Antecipação Selecionada (R$): {FormatNumber(valorAntecipacao, true)}
                                        </div>
                                    );
                                }
                            }
                            scroll={{ y: 240}}
                        />
                    </div>
                    <Row gutter={[8, 0]}>
                        <Col>
                            <InputPreco disabled name="valorPagar" label="Saldo a pagar (R$)" />
                        </Col>
                    </Row>
                </Form>
                <Descriptions>
                    <Descriptions.Item>
                        Total da Nota (R$) {FormatNumber(formAnterior.getFieldValue().valorTotal, true)}
                    </Descriptions.Item>
                </Descriptions>
            </div>
        </Modal >
    );
}