import React, { useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";

import { DrawerNav } from '../../components';
import { useStateValue } from '../../state';
import ManutencaoInventario from './manutencao';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';

export default function DrawerInventario() {

    const [formInventario] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);
    const [permiteSalvar, setPermiteSalvar] = useState(false);

    if (!!manutencao.dados) {
        formInventario.setFieldsValue(manutencao.dados);
    }

    function fecharDrawer() {
        formInventario.resetFields();
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-inv_numero' } });
        setPermiteSalvar(false);
        setCarregando(false);
    };

    const onClose = () => {
        fecharDrawer();

        // removido pq o inventario vai salvando a cada item lançado 
        // Modal.confirm({
        //     title: 'Cancelar?',
        //     icon: <ExclamationCircleOutlined />,
        //     content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
        //     okText: 'Sim',
        //     cancelText: 'Não',
        //     centered: true,
        //     onOk() {
        //         fecharDrawer();
        //     }
        // });
    };

    return (
        <DrawerNav title="Cadastro Inventário"
            width="70%"
            visible={ui.showDrawer}
            closeIcon={<MinusOutlined />}
            limparAoEditar={true}
            fecharDrawer={fecharDrawer}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Fechar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formInventario.submit()} disabled={!permiteSalvar} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoInventario formulario={formInventario} carregando={setCarregando} permiteSalvar={setPermiteSalvar} aoSalvar={fecharDrawer} />
        </DrawerNav>
    )
}