import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tag, Table } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { useStateValue } from "../../state";
import { FormatNumber } from "../../ValueObjects";
import { manutencaoActions } from "../../actions";
import { FiltroProdutoNtf } from "../../components";

export default function TabItemTransferencia({ form, dadosItens, dadosEndereco, dadosNfeTransferencia, dadosContribuinte, validarTransporte }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [valorTotal, setValorTotal] = useState(0);
    const [quantidade, setQuantidade] = useState(0);

    useEffect(() => {
        if (!!dadosItens.dadosItens && dadosItens.dadosItens.length > 0) {
            let total = 0;
            let qtd = 0;
            dadosItens.dadosItens.forEach((item) => {
                total += parseFloat(item.total);
                qtd += item.nfi_qtde;
            });
            setValorTotal(total);
            setQuantidade(qtd);
        } else {
            setValorTotal(0);
            setQuantidade(0);
        }
    }, [dadosItens.dadosItens]);

    function editarItemNota(registro) {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: registro } });
    };

    function removerItemNota(item) {
        let dadosTemp = [...dadosItens.dadosItens];
        dadosTemp = dadosTemp.filter((dados) => dados !== item);
        validarTransporte(dadosTemp);
        dadosItens.setDadosItens(dadosTemp);
    };

    return (
        <div>
            <FiltroProdutoNtf form={form} dadosTabela={dadosItens} dadosEndereco={dadosEndereco} dadosNfeTransferencia={dadosNfeTransferencia} validarTransporte={validarTransporte}/>
            <div className="tabela mt-dif">
                {!!dadosItens.dadosItens && dadosItens.dadosItens.length > 0 &&
                    <Table dataSource={dadosItens.dadosItens}
                        columns={[
                            {
                                title: 'ITEM',
                                width: '20%',
                                fixed: 'left',
                                render: ({ pro_codigo, pro_descricao, nfi_ean, grade }) => (
                                    <div>
                                        <Row align="middle" gutter={[5, 0]}>
                                            <Col>
                                                <Tag color="processing" className="w-75">
                                                    <b>{pro_codigo}</b>
                                                </Tag>
                                                <b> {pro_descricao}</b>
                                            </Col>
                                        </Row>
                                        <Row align="middle" gutter={[5, 0]}>
                                            <Col>
                                                EAN: <b>{nfi_ean}</b>
                                            </Col>
                                            <Col hidden={!!!grade}>
                                                Grade: <b>{grade}</b>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            },
                            {
                                title: 'CFOP',
                                align: 'center',
                                width: '4%',
                                fixed: 'left',
                                render: ({ nfi_cfop }) => (
                                    <div>
                                        <b>{nfi_cfop}</b>
                                    </div>
                                ),
                            },
                            {
                                title: `${dadosContribuinte.emp_regimetributario === 1 ? 'CSOSN' : 'CST'}`,
                                align: 'center',
                                width: '5%',
                                fixed: 'left',
                                render: ({ cst_id, cso_id }) => (
                                    <div>
                                        <Col >
                                            <b>{dadosContribuinte.emp_regimetributario === 1 ? cso_id : cst_id}</b>
                                        </Col>
                                    </div>
                                ),
                            },
                            {
                                title: 'Qtde.',
                                width: '5%',
                                align: 'right',
                                fixed: 'left',
                                render: ({ nfi_qtde }) => (
                                    <div>
                                        <b>{FormatNumber(nfi_qtde, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr Unt.(R$)',
                                align: 'right',
                                width: '8%',
                                fixed: 'left',
                                render: ({ nfi_valorunitario }) => (
                                    <div>
                                        <b>{FormatNumber(nfi_valorunitario, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Desc.',
                                align: 'right',
                                width: '5%',
                                render: ({ percdesc }) => (
                                    <div>
                                        <b>{percdesc}%</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Acres.',
                                align: 'right',
                                width: '6%',
                                render: ({ percacresc }) => (
                                    <div>
                                        <b>{FormatNumber(percacresc, true)}%</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr. Frete',
                                align: 'right',
                                width: '6%',
                                render: ({ nfi_valorfrete }) => (
                                    <div>
                                        <b>{FormatNumber(nfi_valorfrete, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Bc. Icms',
                                align: 'right',
                                width: '6%',
                                render: ({ baseICMS }) => (
                                    <div>
                                        <b>{FormatNumber(baseICMS, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: '% Icms',
                                align: 'right',
                                width: '6%',
                                render: ({ nig_percicms }) => (
                                    <div>
                                        <b>{FormatNumber(nig_percicms, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr Icms',
                                align: 'right',
                                width: '6%',
                                render: ({ nig_valorIcms }) => (
                                    <div>
                                        <b>{FormatNumber(nig_valorIcms, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Bc. ST',
                                align: 'right',
                                width: '6%',
                                render: ({ BaseST }) => (
                                    <div>
                                        <b>{FormatNumber(BaseST, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: '% ST',
                                align: 'right',
                                width: '6%',
                                render: ({ nig_percicmsst }) => (
                                    <div>
                                        <b>{FormatNumber(nig_percicmsst, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr ST',
                                align: 'right',
                                width: '6%',
                                render: ({ nig_valoricmsst }) => (
                                    <div>
                                        <b>{FormatNumber(nig_valoricmsst, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Vlr Total (R$)',
                                align: 'right',
                                width: '8%',
                                fixed: 'right',
                                render: ({ total }) => (
                                    <div>
                                        <b>{FormatNumber(total, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                key: 'x',
                                align: 'center',
                                width: '5%',
                                fixed: 'right',
                                render: (record, e, indice) => (
                                    <div>
                                        <Row align="center" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button icon={<EditOutlined />} onClick={() => editarItemNota(record)} />
                                            </Col>
                                            <Col>
                                                <Button icon={<DeleteOutlined />} onClick={() => removerItemNota(record, indice)} />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]}
                        scroll={{
                            x: 1500,
                            y: 300,
                        }}
                    />
                }
            </div>
            <div className="m-t-16">
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col className="f-16">
                        Quantidade:
                    </Col>
                    <Col>
                        <b className="f-21">{quantidade}</b>
                    </Col>
                </Row>
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col className="f-16">
                        Valor Total dos Itens (R$):
                    </Col>
                    <Col>
                        <b className="f-21">{FormatNumber(valorTotal, true)}</b>
                    </Col>
                </Row>
            </div>
        </div>
    );
}