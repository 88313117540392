import React, { useState, useEffect } from "react";
import { Divider, Row, Col, Typography, Table, Button, notification } from "antd";
import { CheckOutlined, ReloadOutlined } from "@ant-design/icons";

export default function TabPercursoMDF({ estadoInicio, estadoFinal, listaPercurso, setListaPercurso, setPercursoOk }) {
    const [percurso, setPercurso] = useState([estadoInicio]);
    const [ufInicial] = useState(estadoInicio);
    const [ufFinal] = useState(estadoFinal);

    const vizinhosUF = {
        AC: ['AM', 'RO'],
        AL: ['SE', 'PE', 'BA'],
        AP: ['PA'],
        AM: ['RR', 'PA', 'MT', 'RO', 'AC'],
        BA: ['SE', 'AL', 'PE', 'PI', 'TO', 'GO', 'MG', 'ES'],
        CE: ['PI', 'PE', 'PB', 'RN'],
        ES: ['BA', 'MG', 'RJ'],
        GO: ['MS', 'MG', 'BA', 'TO', 'MT', 'DF'],
        MA: ['PA', 'TO', 'PI'],
        MT: ['RO', 'AM', 'PA', 'TO', 'GO', 'MS'],
        MS: ['MT', 'GO', 'SP', 'MG', 'PR'],
        MG: ['SP', 'RJ', 'ES', 'BA', 'GO', 'MS', 'DF'],
        PA: ['AP', 'RR', 'AM', 'MT', 'TO', 'MA'],
        PB: ['PE', 'CE', 'RN'],
        PR: ['SC', 'SP', 'MS'],
        PE: ['BA', 'AL', 'PB', 'CE', 'PI'],
        PI: ['BA', 'PE', 'CE', 'MA', 'TO'],
        RJ: ['SP', 'MG', 'ES'],
        RN: ['PB', 'CE'],
        RS: ['SC'],
        RO: ['MT', 'AM', 'AC'],
        RR: ['AM', 'PA'],
        SC: ['RS', 'PR'],
        SP: ['PR', 'MS', 'MG', 'RJ'],
        SE: ['BA', 'AL'],
        TO: ['GO', 'BA', 'PI', 'MA', 'PA', 'MT'],
        DF: ['GO', 'MG']
    }

    const estadoId = { AC: 1, AL: 2, AP: 4, AM: 3, BA: 5, CE: 6, DF: 7, ES: 8, GO: 9, MA: 10, MT: 13, MS: 12, MG: 11, PA: 14, PB: 15, PR: 18, PE: 16, PI: 17, RJ: 19, RN: 20, RS: 23, RO: 21, RR: 22, SC: 24, SP: 26, SE: 25, TO: 27 }

    const iconeUf = (uf) => {
        if (uf === ufInicial) {
            return <img src={require("../../assets/i-alfinete.png").default} alt="UF Inicial" />
        }
        if (uf === ufFinal) {
            return <img src={require("../../assets/i-bandeira.png").default} alt="UF Final" />
        }
    }

    useEffect(() => {
        let listaTemp = [...listaPercurso]
        if (listaTemp.length > 0) {
            redefinirPercurso();
        }
        if (ufInicial === ufFinal || vizinhosUF[ufInicial].includes(ufFinal)) {
            notification.success({ message: 'Atenção!', description: 'Não é necessário definir um percurso.' })
            setPercursoOk(true)
        }
    }, [])

    function estadoClick(uf) {
        if (ufInicial === ufFinal || vizinhosUF[ufInicial].includes(ufFinal)) {
            notification.warning({ message: 'Atenção!', description: 'Não é necessário definir um percurso.' })
            setPercursoOk(true)
            return
        }
        if (percurso.includes(uf) || uf === ufInicial || uf === ufFinal) return;

        let ultimaUF = percurso[percurso.length - 1];
        let ufVizinha = vizinhosUF[ultimaUF].includes(uf);

        if (ufVizinha) {
            setPercurso([...percurso, uf]);
        } else {
            notification.warning({ message: 'Alerta!', description: `Não é possível adicionar ${uf} sem passar por uma UF vizinha.` })
        }
    }

    function redefinirPercurso() {
        setPercurso([ufInicial]);
        setListaPercurso([]);
        notification.info({ message: 'Aviso!', description: 'Percurso redefinido!' })
    }

    function salvarPercurso() {
        if (ufInicial === ufFinal) {
            notification.warning({ message: 'Alerta!', description: 'Não é possível definir um percurso' })
            return
        }
        let percursoFinal = [...percurso];
        if (!percursoFinal.includes(ufFinal)) {
            percursoFinal.push(ufFinal);
        }

        let percursoValido = true;
        for (let i = 0; i < percursoFinal.length - 1; i++) {
            let ufAtual = percursoFinal[i];
            let ufProximo = percursoFinal[i + 1];

            if (!vizinhosUF[ufAtual]?.includes(ufProximo)) {
                percursoValido = false;
                notification.warning({ message: 'Alerta!', description: `O percurso é inválido: ${ufAtual} não é vizinho de ${ufProximo}` });
                break
            }
        }

        if (percursoValido) {
            percursoFinal = percursoFinal.slice(1, -1);

            const listaPercursoAtt = percursoFinal.map((uf, index) => ({
                est_id: estadoId[uf],
                per_sequencia: index + 1
            }));

            setListaPercurso(listaPercursoAtt);
            notification.success({ message: 'Aviso!', description: `O percurso de ${ufInicial} para ${ufFinal} foi salvo com sucesso.` });
        }
    }

    return (
        <div>
            <Row gutter={[16, 16]} className="m-t-16">
                <Col span={24}>
                    <Typography.Title level={3} style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={require("../../assets/i-endereco.png").default} alt="Percurso" style={{ marginRight: '8px' }} /> Percurso
                    </Typography.Title>
                    <Divider orientation="left" plain> Selecione no mapa o percurso percorrido (Clique em todas as UFs necessárias) </Divider>
                </Col>
            </Row>
            <Row gutter={[16, 16]} align="middle">
                <Col xs={24} sm={16} md={18} lg={18} xl={12}>
                    <div className="mapa-brasil">
                        {['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO', 'DF'].map((uf) => (
                            <div
                                key={uf}
                                id={uf.toLowerCase()}
                                className={`estado ${percurso.includes(uf) || uf === ufInicial || uf === ufFinal ? 'estado-selecionado' : ''}`}
                                onClick={() => estadoClick(uf)}
                            >
                                {uf} {iconeUf(uf)}
                            </div>
                        ))}
                    </div>
                </Col>
                <Col xs={24} sm={8} md={6} lg={5} xl={8} style={{ marginLeft: '150px' }}>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <img src={require("../../assets/i-alfinete.png").default} style={{ width: '30px', height: '30px' }} alt="UF Inicial" /><b style={{ fontWeight: 900 }} >UF Inicial:</b> <b>{ufInicial}</b>
                        </Col>
                        <Col span={6}>
                            <b style={{ fontWeight: 900 }} >Percurso</b>
                            <Table
                                columns={[
                                    {
                                        title: 'UF',
                                        align: 'center',
                                        dataIndex: 'uf',
                                        title: () => <span style={{ fontWeight: 'bold', color: '#333' }}>UF</span>
                                    }
                                ]}
                                dataSource={percurso.filter(uf => uf !== ufInicial).map(uf => ({ uf }))}
                                pagination={false}
                                scroll={{ y: 300 }}
                            />
                        </Col>
                        <Col span={24}>
                            <img src={require("../../assets/i-bandeira.png").default} style={{ width: '30px', height: '30px' }} alt="UF Final" /><b style={{ fontWeight: 900 }} >UF Final:</b> <b>{ufFinal ? ufFinal : 'Não Informado'}</b>
                        </Col>
                        <Col span={24} style={{ textAlign: 'left', marginTop: '16px' }}>
                            <Button onClick={redefinirPercurso} icon={<ReloadOutlined />} size="small" htmlType="button">
                                Redefinir
                            </Button>
                            <Button style={{ marginLeft: '10px' }} onClick={salvarPercurso} icon={<CheckOutlined />} size="small" type="primary">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}