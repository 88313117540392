import { React, useEffect, useState } from "react";
import { Row, Col, Tag, Checkbox, Modal, Form, Descriptions, Table, Divider } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../../services/api";
import { FormatNumber, FormatFone } from "../../../ValueObjects";

export default function ModalProdutoCondicional({ exibirModalProdutoCondicional, fecharModalProdutoCondicional, dadosItens, situacaoVenda }) {
    const [itens, setItens] = useState();
    const [dadosCondicional, setDadosCondicional] = useState({});

    useEffect(() => {
        if (!!dadosItens && !!dadosItens.vnc_id) {
            carregarDados();
            setDadosCondicional(dadosItens);
        }
    }, [dadosItens])

    function carregarDados() {
        if (!!dadosItens && !!dadosItens.vnc_id) {
            console.log('dadosItens', itens);
            api.get(`VendaCondicionalItem/Listar?IdVendaCondicional=${dadosItens.vnc_id}`).then(
                res => {
                    setItens(res.data);
                }
            ).catch(
                error => {
                    console.log(error);
                }
            );
        }
    }

    function descricaoSituacao(codigo) {
        if (codigo >= 0 && !!situacaoVenda[codigo]) {
            return situacaoVenda[codigo].value;
        }
        return '';
    };

    return (
        <Modal centered
            title="Produtos da Condicional"
            visible={exibirModalProdutoCondicional}
            onCancel={() => fecharModalProdutoCondicional(true)}
            footer={null}
            maskClosable={false}
            width= '80%'>
            <div>
                <Row align="middle">
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item label="Condicional N°">
                                <b>{dadosCondicional.vnc_numero}</b>
                            </Descriptions.Item>
                            <Descriptions.Item label="Cliente" style={{ marginRight: '-5px'}}>
                                <b>{dadosCondicional.cli_nome}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row align="middle">
                    <Descriptions column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 2, xs: 1}} size="small">
                        <Descriptions.Item label="Telefone">
                            <b>{FormatFone(dadosCondicional.vnc_telefonecliente)}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Vendedor">
                            <b>{dadosCondicional.ven_nome}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Situação">
                            <b>{(descricaoSituacao(dadosCondicional.vnc_situacao))}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Emitida em">
                            <b>{moment(dadosCondicional.vnc_datalancamento).format('DD/MM/YYYY')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Retorno da Mercadoria">
                            <b>{moment(dadosCondicional.vnc_prazoretorno).format('DD/MM/YYYY')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Valor Total (R$)">
                            <b className="f-14"> {FormatNumber(dadosCondicional.vnc_valortotal, true)}</b>
                        </Descriptions.Item>
                        {!!dadosCondicional.vnc_entregaagendada && (
                            <Descriptions.Item label="Data e Hora Entrega">
                                <b>{moment(dadosCondicional.vnc_entregaagendada).format('DD/MM/YYYY')}</b><span style={{ margin: '0 10px'}}> | </span><b>{moment(dadosCondicional.vnc_entregaagendada).format('HH:mm')}</b>
                            </Descriptions.Item>
                        )}
                        {!!dadosCondicional.vnc_enderecoentrega && (
                            <Descriptions.Item label="Endereço Entrega">
                                <b>{dadosCondicional.vnc_enderecoentrega}</b>
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                </Row>
                <Row align="middle">
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item label="Observação">
                                <b>{!!dadosCondicional.vnc_observacao ? dadosCondicional.vnc_observacao : 'Não Informado'}</b>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            </div>
            <div className="tabela">
                <Table dataSource={itens} columns={
                    [
                        {
                            title: 'Itens',
                            render: ({ pro_codigo, pro_descricao, descricaograde }) => (
                                <div>
                                    <Row align="middle" gutter={[20, 5]}>
                                        <Col>
                                            <Tag color="processing" className="w-75">
                                                <b>{pro_codigo}</b>
                                            </Tag>
                                        </Col>
                                        <Col>
                                            <b>{pro_descricao}</b>
                                        </Col>
                                        {!!descricaograde &&
                                            <Col>
                                                <Tag color="default">
                                                    <b>{descricaograde}</b>
                                                </Tag>
                                            </Col>
                                        }
                                    </Row>
                                </div>
                            ),
                        },
                        {
                            title: 'EAN',
                            width: 10,
                            align: 'center',
                            render: ({ pro_ean, prg_ean }) => (
                                <div>
                                    <Row align="middle" justify="center">
                                        <Col>
                                            <b>{!!pro_ean ? pro_ean : prg_ean}</b>
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                        {
                            title: 'Qtde.',
                            width: 70,
                            align: 'right',
                            render: ({ vci_quantidade }) => (
                                <div>
                                    <Row align="middle" justify="end">
                                        <Col>
                                            <b>{vci_quantidade}</b>
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                        {
                            title: 'Qtde Dev.',
                            width: 70,
                            align: 'right',
                            render: ({ vci_devolvido }) => (
                                <div>
                                    <Row align="middle" justify="end">
                                        <Col>
                                            <b>{vci_devolvido}</b>
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                        {
                            title: 'Vlr Unt.(R$)',
                            align: 'right',
                            width: 90,
                            render: ({ vci_valorun }) => (
                                <div>
                                    <b>{FormatNumber(vci_valorun, true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Vlr Total (R$)',
                            align: 'right',
                            width: 100,
                            render: ({ vci_valorun, vci_quantidade, vci_devolvido }) => (
                                <div>
                                    <b>{FormatNumber((vci_quantidade - vci_devolvido) * vci_valorun, true)}</b>
                                </div>
                            ),
                        }
                    ]
                } />
            </div>
        </Modal>
    );
}