import { Button, Col, Form, Row, Modal, notification } from 'antd';
import React, { useState } from 'react';
import ManutencaoCatalogo from './manutencao';
import { useStateValue } from '../../state';
import { DrawerNav } from '../../components';
import { CloseOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { drawerActions } from '../../actions';
import api from '../../services/api';

export default function DrawerCadastroCatalogo() {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [listaGrupos, setListaGrupos] = useState([]);
    const [form] = Form.useForm();
    const [carregando, setCarregando] = useState(false);

    const aoSalvar = () => {
        let catalogoTemp = form.getFieldsValue();
        catalogoTemp.Empresa = 1;
        catalogoTemp.CaminhoImg = 'null';
        catalogoTemp.Grupos = listaGrupos;
        setCarregando(true);
        api.post(`Cat001/Incluir`, catalogoTemp)
            .then(() => {

                notification.success({ message: 'Sucesso!', message: 'Catálogo incluído com sucesso!' });
            }
            ).catch(
                error => {
                    console.error('Erro ao salvar catálogo:', error);
                }
            ).finally(() => {
                setCarregando(false);
                fecharDrawer();
                setListaGrupos([]); 
                form.resetFields();
            });
    }

    function fecharDrawer() {
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
                setListaGrupos([]);
                form.resetFields();
            }
        })
    }

    return (
        <DrawerNav title="Cadastro Catálogo"
            width="70%"
            visible={ui.showDrawer}
            form={form}
            fecharDrawer={() => { fecharDrawer() }}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => form.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }
        >
            <ManutencaoCatalogo formulario={form} aoSalvar={aoSalvar} carregando={setCarregando} listaGrupos={{ listaGrupos, setListaGrupos }} />
        </DrawerNav>
    );
}