import React, { useState } from "react";
import { Row, Col, Button, Divider } from "antd";
import { StopOutlined, SaveOutlined, CheckOutlined, DeleteFilled } from "@ant-design/icons";
import moment from 'moment';

import { ModalDefinirHorario } from "../../components/modals/";

export default function TabHorariosAcessoGrupo({ listHorarios, transformList }) {

    const [openModal, setOpenModal] = useState(false);
    const [idDay, setIdDay] = useState(null);

    const setDataModal = (id) => {
        setIdDay(id);
        setOpenModal(true);
    }

    const onChangeHorario = (id, disabled = false, horaInicial = null, horaFinal = null) => {
        let array = [...listHorarios.listHorarios];
        array[id].disabled = disabled;
        if (horaInicial != null && horaFinal != null)
            array[id].day.push(
                {
                    horaInicial: horaInicial != null ? horaInicial : null,
                    horaFinal: horaFinal != null ? horaFinal : null,
                    bloqueado: disabled,
                });
        listHorarios.setListHorarios(array);
        transformList(array);
    }

    const deleteHorario = (id, key) => {
        let array = [...listHorarios.listHorarios];
        array[id].day.splice(key, 1);
        listHorarios.setListHorarios(array);
        transformList(array);
    }

    return (
        <div className="listUsers">
            <Row align="top" justify="center" gutter={[8, 8]}>
                {listHorarios.listHorarios.map(({ id, description, disabled, day }, idx) => (
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} key={idx}>
                        <Row gutter={[0, 8]} align="middle" justify="center">
                            <Col span={24}>
                                {description}
                                <Divider />
                            </Col>
                        </Row>
                        {!disabled && <Row gutter={[0, 8]}>
                            {day.map((x, idx) => (

                                <Col span={24} key={idx} >
                                    <Row gutter={[8, 0]} align="middle" justify="center">
                                        <Col>
                                            <span>{moment(x.horaInicial).format("HH:mm")} até {moment(x.horaFinal).format("HH:mm")}</span>
                                        </Col>
                                        <Col>
                                            <Button
                                                icon={<DeleteFilled />}
                                                type="link"
                                                onClick={() => deleteHorario(id, idx)} />
                                        </Col>
                                    </Row>
                                </Col>
                            ))}
                            <Col span={24}>
                                <Button icon={<SaveOutlined />} type="primary" block onClick={() => setDataModal(id)}>
                                    Adicionar
                                </Button>
                            </Col>
                            <Col span={24}>
                                <Button icon={<StopOutlined />} type="primary" danger block onClick={() => onChangeHorario(id, true)}>
                                    Bloquear
                                </Button>
                            </Col>
                        </Row>}
                        {disabled && <Row gutter={[0, 8]}>
                            <Col span={24} align="center">
                                <h3>Bloqueado</h3>
                            </Col>
                            <Col span={24}>
                                <Button icon={<CheckOutlined />} block onClick={() => onChangeHorario(id, false)}>
                                    Desbloquear
                                </Button>
                            </Col>
                        </Row>}
                    </Col>
                ))}
            </Row>
            <ModalDefinirHorario id={idDay} onSave={onChangeHorario} exibirModal={openModal} fecharModal={() => setOpenModal(false)} />
        </div>
    );

}