import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Input, Table, Button, Typography, Divider, InputNumber, Modal } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined, ExclamationCircleOutlined, CloseOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { FormGW } from "../../components";
import { useStateValue } from "../../state";

export default function TabRespostas({ formulario, respostas }) {

    const [formResposta] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();
    const [listaSomaSubtrai, setListaSomaSubtrai] = useState([]);

    useEffect(() => {
        api.get(`Enum/Listar?nome=SomaSubtrai`).then(res => {
            if (!!res) {
                setListaSomaSubtrai(res.data);
            }
        });
    }, []);

    function editarRegistro(item) {
        let itemTemp = { ...item };
        if (typeof itemTemp.rlc_somasubtrai === 'object') {
            itemTemp.rlc_somasubtrai = itemTemp.rlc_somasubtrai.key;
        }
        formResposta.setFieldsValue(itemTemp);
    }

    function removerRegistro(registro) {
        let lista = [...respostas.respostasLimiteCredito];
        let index = lista.findIndex(item => item === registro);

        if (index !== -1) {
            lista.splice(index, 1);
            if (lista.length > 0) {
                lista.map((item, indice) => {
                    if (item.rlc_ordem > registro.rlc_ordem) {
                        item.rlc_ordem = (item.rlc_ordem !== 1 ? (item.rlc_ordem - 1) : item.rlc_ordem);
                    }

                    if ((indice + 1) === lista.length) {
                        respostas.setRespostasLimiteCredito(lista);
                    }
                });
            } else {
                respostas.setRespostasLimiteCredito(lista);
            }
        }
    }

    const adicionarResposta = (values) => {
        let listaTemp = [...respostas.respostasLimiteCredito];
        if (!!values.rlc_id)
        {
            let itemEditar = listaTemp.find((item) => (item.rlc_id === values.rlc_id));
            itemEditar.rlc_percentual = values.rlc_percentual;
            itemEditar.rlc_resposta = values.rlc_resposta;
            itemEditar.rlc_somasubtrai = values.rlc_somasubtrai;
        }else{
            values.rlc_somasubtrai = values.rlc_somasubtrai;
            values.rlc_ordem = listaTemp.length + 1;
            listaTemp.push(values);
        }
        respostas.setRespostasLimiteCredito(listaTemp);
        formResposta.resetFields();
    };

    const modalExcluir = (item) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                removerRegistro(item);
            }
        });
    };

    function alterarOrdem(ordemAtual, ordemNova){
        let listaTemp = [...respostas.respostasLimiteCredito];
        let itemAtual = listaTemp.find(item => item.rlc_ordem === ordemAtual);
        let itemEditar = listaTemp.find(item => item.rlc_ordem === ordemNova);
        itemAtual.rlc_ordem = ordemNova;
        itemEditar.rlc_ordem = ordemAtual;

        listaTemp.sort(function (x, y){
            return x.rlc_ordem - y.rlc_ordem;
        });
        
        respostas.setRespostasLimiteCredito(listaTemp);
    };

    return (
        <div>
            <FormGW layout="vertical" form={formResposta} name="manutencaoRespostas" onFinish={adicionarResposta}>
                <Row align="middle" gutter={[8, 8]}>
                    <Col span={24}>
                        <Typography.Title level={3}>
                            <img src={require("../../assets/i-chat.png").default} alt="Informações Financeiras" /> Respostas
                        </Typography.Title>
                        <Divider orientation="left" plain>
                            Adicione a resposta aqui.
                        </Divider>
                    </Col>
                </Row>
                <Row gutter={[8, 0]} className="m-t-16">
                    <Col xs={24} sm={8} md={7} lg={7} xl={7}>
                        <Form.Item name="rlc_id" hidden />
                        <Form.Item label="Resposta" name="rlc_resposta" rules={[{ required: true, message: 'Informe a Resposta' }]}>
                            <Input placeholder="Informe a Resposta" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={5} lg={5} xl={5}>
                        <Form.Item label="Soma(+)/Subtrai(-)" name="rlc_somasubtrai" rules={[{ required: true, message: 'Selecione Soma/Subtrai' }]}>
                            <Select placeholder="Selecione Soma/Subtrai" onChange={(valor) => {
                                if (valor === 3) {
                                    formResposta.setFieldsValue({ rlc_percentual: 0 });
                                }
                            }} options={listaSomaSubtrai.map((item) => {
                                return { label: `${item.value}`, value: item.key }
                            })} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={6} lg={6} xl={6}>
                        <Form.Item label="Percentual de Soma/Subtração" name="rlc_percentual" rules={[{ required: true, message: 'Informe o Percentual' }]}>
                            <InputNumber
                                defaultValue={0}
                                min={0}
                                max={100}
                                formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6} className="t-mob-573">  
                        <Row justify="end" gutter={[8, 8]}>
                            <Col xs={24} sm={8} md={12} lg={12} xl={12}>
                                <Button icon={<CloseOutlined />} onClick={() => { formResposta.resetFields() }} block>
                                    Cancelar
                                </Button>
                            </Col>
                            <Col xs={24} sm={8} md={12} lg={12} xl={12}>
                                <Button icon={<PlusOutlined />} onClick={() => {
                                    formResposta.submit();
                                }} type="primary" block> 
                                    Adicionar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="tabela mt-dif">
                    <Table dataSource={respostas.respostasLimiteCredito}
                        columns={[
                            {
                                width: 75,
                                align: 'center',
                                render: ({ rlc_ordem }) => (
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        {rlc_ordem !== 1 &&
                                            <Col>
                                                <Button icon={<ArrowUpOutlined />} onClick={() => {alterarOrdem(rlc_ordem, rlc_ordem - 1)}}></Button>
                                            </Col>
                                        }
                                        {rlc_ordem !== respostas.respostasLimiteCredito.length &&
                                            <Col>
                                                <Button icon={<ArrowDownOutlined />} onClick={() => {alterarOrdem(rlc_ordem, rlc_ordem + 1)}}></Button>
                                            </Col>
                                        }
                                    </Row>
                                ),
                            },
                            {
                                title: 'Ordem',
                                width: 75,
                                align: 'center',
                                render: ({ rlc_ordem }) => (
                                    <div>
                                        <b>{rlc_ordem}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Resposta',
                                render: ({ rlc_resposta }) => (
                                    <div>
                                        <b>{rlc_resposta}</b>
                                    </div>
                                )
                            },
                            {
                                title: 'Soma / Subtrai ',
                                align: 'right',
                                render: ({ rlc_somasubtrai, rlc_percentual }) => (
                                    <div>
                                        { (typeof rlc_somasubtrai === 'number')
                                            ?
                                            <b>{listaSomaSubtrai.filter((item) => (item.key === rlc_somasubtrai)).length > 0 ? listaSomaSubtrai.filter((item) => (item.key === rlc_somasubtrai))[0].value : ''} - {rlc_percentual}%</b>
                                            :
                                            <b>{rlc_somasubtrai.value} - {rlc_percentual}%</b>
                                        }
                                    </div>
                                )
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                align: 'center',
                                key: 'x',
                                width: 65,
                                fixed: 'right',
                                render: (item) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button icon={<EditOutlined />} onClick={() => editarRegistro(item)} />
                                            </Col>
                                            <Col>
                                                <Button icon={<DeleteOutlined />} onClick={() => modalExcluir(item)} />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]} locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>
                                )
                        }} />
                </div>
            </FormGW>
        </div>
    );

}
