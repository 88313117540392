import React, { useEffect } from "react";
import { Form, Row, Col, Input, Checkbox, notification } from "antd";

import api from '../../services/api';
import { Data, FormGW } from "../../components";
import { useStateValue } from '../../state';
import { listagemActions } from "../../actions";
import moment from "moment";

export default function ManutencaoVigencia({ formulario, carregando, aoSalvar }) {

    const [{ manutencao }, dispatch] = useStateValue();

    const salvarManutencao = (values) => {
        carregando(true);
        let enderecoUrl = 'VigenciaFiscal/';

        if (values.vig_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "Vigência Fiscal salvos com sucesso!" });
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    atualizarPagina();
                    carregando(false);
                    aoSalvar();
                }
            );
        } else {
            enderecoUrl += "Incluir"
            api.post(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "Vigência Fiscal salvos com sucesso!" });
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    atualizarPagina();
                    carregando(false);
                    aoSalvar();
                }
            );
        };
    };

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+vig_id' } });
    };

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoVigencia" onFinish={salvarManutencao}>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={17} lg={18} xl={18}>
                    <Form.Item name="vig_id" hidden />
                    <Form.Item label="Descrição" name="vig_descricao" rules={[{ required: true, message: 'Informe a Descrição da Vigência' }]}>
                        <Input placeholder="Informe a Descrição da Vigência" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={7} lg={6} xl={6}>
                    <Data label="Data Inicial" name="vig_datainicial" rules={[{ required: true }]} />
                </Col>
            </Row>
        </FormGW>
    );

}