import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Button, Input, InputNumber, Descriptions, Card, Tooltip, message, notification } from "antd";
import { SearchOutlined, BarcodeOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { InputPreco, SelectProduto } from "../../components";
import { useStateValue } from "../../state";
import { isObjetoDiffVazio } from "../../services/funcoes";

export default function FiltroProdutoOrc({ dadosTabela, form, btnEditando, itemEditando, }) {
    const [desabilitarProd_id, setDesabilitarProd_id] = useState(false);
    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [dadosDetalhes, setDadosDetalhes] = useState({});
    const [consultaProduto, setConsultaProduto] = useState(false);
    const [dadosProduto, setDadosProduto] = useState({});
    const [{ manutencao, listaSelectPaginacao }, dispatch] = useStateValue();
    const [listaProdutos, setListaProdutos] = useState([]);
    const refProd = useRef();

    const togglePesquisa = () => {
        setConsultaProduto(!consultaProduto);
        setExibirDetalhes(false);
        limparCampos();
    };

    useEffect(() => {
        if (consultaProduto && !!refProd && !!refProd.current) {
            refProd.current.focus();
        }
    }, [consultaProduto]);


    useEffect(() => {
        if (btnEditando.editando && isObjetoDiffVazio(itemEditando)) {
            editandoItem(itemEditando);
            setExibirDetalhes(true);
        }
    }, [btnEditando])


    function editandoItem(dadosEditando) {
        let dadosTemp = {};
        let dados = dadosEditando;
        setDesabilitarProd_id(true);
        form.setFieldsValue({
            pro_id: dados.pro_id,
            prg_id: dados.prg_id,
            pro_ean: dados.pro_ean,
            pro_descricao: dados.pro_descricao,
            ocp_quantidade: dados.ocp_quantidade,
            ocp_valorun: dados.ocp_valorun,
        });
        alterarValorTotal();
        dadosTemp = {
            pro_id: dadosEditando.pro_id,
            prg_id: dadosEditando.prg_id,
            pro_descricao: dadosEditando.pro_descricao,
            pro_codigo: dadosEditando.pro_codigo,
            pro_ean: dadosEditando.pro_ean,
        };
        setDadosDetalhes(dadosTemp);
    }

    function alterarValorTotal() {
        let total = 0;
        total = form.getFieldValue().ocp_quantidade * form.getFieldValue().ocp_valorun;
        form.setFieldsValue({ valorTotalItem: total.toFixed(2) });
    };

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.pro_id) {
            form.setFieldsValue({ pro_id: manutencao.dados.pro_id, ocp_quantidade: 1, });
            onChangeProID(manutencao.dados.pro_id);

        }
    }, [manutencao.dados]);

    function popularLista(listaDados) {
        listaDados.sort((a, b) => b.key - a.key);
        dadosTabela.setItensOrdemCompra(listaDados);
        limparCampos();
    };


    function validarItem(dados) {
        if (dados.ocp_quantidade === 0 || !!!dados.ocp_quantidade) {
            notification.warning({ message: 'Aviso!', description: 'Quantidade do Item Inválida!' });
            return false;
        }
        if (parseFloat(dados.ocp_valorun) === 0 || parseFloat(dados.ocp_valorun) === 0) {
            notification.warning({ message: 'Aviso!', description: 'Valor do Item Inválido!' });
            return false
        }
        return true;
    }


    function adicionarItem(item = {}) {
        let dadosTemp = [...dadosTabela.itensOrdemCompra];
        let dadosFormulario = form.getFieldValue();
        let dados = {};
        if (consultaProduto) {
            dados = { ...item };
            dados.key = retornaSequencial(dadosTemp);
            adicionarItemOrdemCompra(dados, () => { dadosTemp.push(dados); });
            popularLista(dadosTemp);
            limparCampos()
        }
        else {
            if (!!validarItem(dadosFormulario)) {
                if (dadosTemp.find(campo => campo.pro_id === form.getFieldValue().pro_id
                    && ((!!form.getFieldValue().prg_id && form.getFieldValue().prg_id === campo.prg_id) || !form.getFieldValue().prg_id))) {
                    if (!!form.getFieldValue().prg_id) {
                        dados = dadosTemp.find(campo => campo.prg_id === form.getFieldValue().prg_id);
                    } else {
                        dados = dadosTemp.find(campo => campo.pro_id === form.getFieldValue().pro_id);
                    }
                    adicionarItemOrdemCompra(dados);
                } else {
                    dados = { ...dadosProduto };
                    dados.key = retornaSequencial(dadosTemp);
                    adicionarItemOrdemCompra(dados, () => { dadosTemp.push(dados); });
                }
                popularLista(dadosTemp);
                limparCampos()
            }
        }
        if (!!btnEditando && btnEditando.editando) {
            btnEditando.setEditando(false);
        }
    };

    function retornaSequencial(lista) {
        return lista.length > 0 ? lista[0].key + 1 : 0;
    };

    function adicionarItemOrdemCompra(dadosItem, callback) {

        let formulario = form.getFieldValue();
        dadosItem.ocp_quantidade = !!formulario.ocp_quantidade ? formulario.ocp_quantidade : 1;
        dadosItem.ocp_valorun = parseFloat(!!formulario.ocp_valorun ? formulario.ocp_valorun : dadosItem.ocp_valorun);
        dadosItem.grade = dadosItem.descricaograde;
        dadosItem.ocp_valortotal = parseFloat(dadosItem.ocp_valorun * dadosItem.ocp_quantidade);
        if (!!callback) {
            callback();
        }
    };

    function cancelar() {
        limparCampos();
    };

    function limparCampos() {
        form.setFieldsValue({ pro_id: null, pro_codigo: null });
        setDadosProduto({})
        setExibirDetalhes(false);
        setDadosDetalhes({});
        if (btnEditando.editando) {
            btnEditando.setEditando(false);
        }
    };

    async function buscarProdutoCodBarra(campo) {
        let dadosProduto = {};
        let codigoEan = campo.target.value.trim();
        let dados = await api.get(`Produto/ListaProdutoCodigo?filtro=${codigoEan}`);
        if (!!dados.data && dados.data.length > 0) {
            dadosProduto = dados.data[0];
            if (!!refProd && !!refProd.current) {
                refProd.current.focus();
            }
        }
        let unidade = await api.get(`Produto/BuscaUnidadeMedidaProduto?IdProduto=${dadosProduto.pro_id}`);
        let valor = 0;
        if (dadosProduto.prg_id != null) {
            valor = (await api.get(`Produto/BuscarCustoProduto?IdProduto=${dadosProduto.pro_id}&tipoCusto=2&codigoProdutoGrade=${dadosProduto.prg_id}`)).data;
        } else {
            valor = (await api.get(`Produto/BuscarCustoProduto?IdProduto=${dadosProduto.pro_id}&tipoCusto=2`)).data;
        }
        let custoProduto = valor;
        dadosProduto.ump_casasdecimais = 0;
        dadosProduto.ocp_quantidade = (dadosProduto.ocp_quantidade) ? dadosProduto.ocp_quantidade : 1;
        dadosProduto.ocp_valorun = (!!custoProduto) ? custoProduto : 0;
        dadosProduto.ocp_valortotal = parseFloat(dadosProduto.ocp_valorun * dadosProduto.ocp_quantidade);
        if (!!unidade.data) {
            dadosProduto.ump_casasdecimais = unidade.data[0].ump_casasdecimais;
        }
        setDadosProduto(dadosProduto);
        if (!!dados.data && dados.data.length > 0) {
            adicionarItem(dadosProduto);
        }
    };

    async function onChangeProID(pro_id) {
        let dadosProduto = listaProdutos.filter(pro => pro.key === pro_id)[0];
        let unidade = await api.get(`Produto/BuscaUnidadeMedidaProduto?IdProduto=${dadosProduto.pro_id}`);
        let valor = 0;
        if (dadosProduto.prg_id != null) {
            valor = (await api.get(`Produto/BuscarCustoProduto?IdProduto=${dadosProduto.pro_id}&tipoCusto=2&codigoProdutoGrade=${dadosProduto.prg_id}`)).data;
        } else {
            valor = (await api.get(`Produto/BuscarCustoProduto?IdProduto=${dadosProduto.pro_id}&tipoCusto=2`)).data;
        }
        let custoProduto = valor;
        dadosProduto.ump_casasdecimais = 0;
        dadosProduto.ocp_quantidade = (dadosProduto.ocp_quantidade) ? dadosProduto.ocp_quantidade : 1;
        dadosProduto.ocp_valorun = (!!custoProduto) ? custoProduto : 0;
        dadosProduto.ocp_valortotal = parseFloat(dadosProduto.ocp_valorun * dadosProduto.ocp_quantidade)

        if (!!unidade.data) {
            dadosProduto.ump_casasdecimais = unidade.data[0].ump_casasdecimais;
        }
        setDadosProduto(dadosProduto);
        form.setFieldsValue({ ocp_quantidade: dadosProduto.ocp_quantidade ?? 1, ocp_valorun: dadosProduto.ocp_valorun, valorTotalItem: dadosProduto.ocp_valortotal });

        setExibirDetalhes(true);
        let dadosTemp = {};
        dadosTemp.prg_id = dadosProduto.prg_id;
        dadosTemp.pro_descricao = `${dadosProduto.pro_descricao} ${!!dadosProduto.descricaograde ? "-" : ""} ${dadosProduto.descricaograde}`;
        dadosTemp.pro_codigo = dadosProduto.pro_codigo;
        dadosTemp.pro_ean = !!dadosProduto.prg_ean ? dadosProduto.prg_ean : dadosProduto.pro_ean;
        dadosTemp.ump_id = dadosProduto.ump_id;
        setDadosDetalhes(dadosTemp);
    };

    return (
        <div>
            <Row gutter={[8, 16]}>
                {!!consultaProduto === false &&
                    <Col xs={21} sm={21} md={22} lg={22} xl={22} xxl={23}>
                        <Form.Item label="Selecione o Produto" name="pro_id" >
                            <SelectProduto disabled={desabilitarProd_id} valueKey={true} detalhes={true} placeholder="Seleciona o Produto"
                                name="pro_id" form={form} onChange={(dados) => { onChangeProID(dados) }} ordem={"pro_descricao"} setListaDados={setListaProdutos} />
                        </Form.Item>
                    </Col>
                }
                {!!consultaProduto &&
                    <Col xs={21} sm={21} md={22} lg={22} xl={22} xxl={23}>
                        <Form.Item label="Localize o Produto" name="pro_codigo">
                            <Input ref={refProd} onPressEnter={(value) => {
                                buscarProdutoCodBarra(value)
                            }} placeholder="Informe o Produto/Mercadoria" />
                        </Form.Item>
                    </Col>
                }
                <Col xs={3} sm={3} md={2} lg={2} xl={2} xxl={1} className="i-button">
                    <Tooltip title={!!consultaProduto ? 'Selecione o produto' : 'Localize produto por EAN'} placement="left">
                        <Button onClick={togglePesquisa} block>
                            {!!consultaProduto ? <SearchOutlined /> : <BarcodeOutlined />}
                        </Button>
                    </Tooltip>
                </Col>
            </Row>

            {exibirDetalhes &&
                <div className="m-t-16">
                    <Card className="w-100" extra={
                        <Row align="middle" justify="space-between" gutter={[16, 0]}>
                            <Col>
                                Produto: <b>{dadosDetalhes.pro_descricao}</b>
                            </Col>
                            <Col>
                                Código Interno: <b>{dadosDetalhes.pro_codigo}</b>
                            </Col>
                            <Col>
                                Código de Barras: <b>{dadosDetalhes.pro_ean}</b>
                            </Col>
                        </Row>
                    }>
                        <Row gutter={[8, 0]}>
                            <Form.Item hidden label="ID da Grade do Produto" name="prg_id">
                                <Input></Input>
                            </Form.Item>
                            <Col xs={24} sm={8} md={5} lg={6} xl={6}>
                                <Form.Item label="Quantidade" name="ocp_quantidade">
                                    <InputNumber placeholder="Informe a Quantidade do Produto" value='20' onChange={() => { alterarValorTotal() }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={5} lg={6} xl={6}>
                                <InputPreco label="Valor Unitário (R$)" name="ocp_valorun" onChange={() => { alterarValorTotal() }} />
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={6} xl={6}>
                                <InputPreco label="Valor Total do Item (R$)" name="valorTotalItem" disabled={true} />
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={6} xl={6} className="t-mob">
                                <Row justify="end" gutter={[8, 0]}>
                                    <Col>
                                        <Button icon={<CloseOutlined />} onClick={() => (cancelar())}>
                                            Cancelar
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button icon={<PlusOutlined />} type="primary" onClick={() => (adicionarItem())}>
                                            Adicionar
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </Card>
                </div>}
        </div >
    );
}