import React, { useState } from "react";
import { Row, Col, Typography, Divider, Button, Table, Form, Tag, Modal } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { useStateValue } from "../../state";
import { FormatNumber } from '../../ValueObjects';
import { ModalListaPreco } from "../../components/modals/";

export default function TabListaPreco({ listaDePrecos, setListaDePrecos, listaPrecoExclusao }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [openModal, setOpenModal] = useState(false);

    const modalConfirmaExclusao = (record) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja excluir a lista de preço ${record.ltp_id}-${record.ltp_nome} ?`,
            okText: 'Excluir',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                removeLista(record);
            }
        });
    };

    const removeLista = (record) => {
        let ltp_id = record.ltp_id;
        const newData = [...listaDePrecos];
        const index = newData.findIndex((item) => record.key === item.key);
        let listaExclusao = [...listaPrecoExclusao.listaPrecoExclusao];
        if (!!ltp_id){
            listaExclusao.push(newData.find(i => i.ltp_id === ltp_id));
            listaPrecoExclusao.setListaPrecoExclusao(listaExclusao);
        }        
        newData.splice(index, 1);
        setListaDePrecos(newData.map((x, idx) => ({ key: idx, ...x })));
    };

    const editarLista = (record, indice) => {
        form.setFieldsValue(record);
        setOpenModal(true);
    };

    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-produto.png").default} alt="Demais Listas de Preços Deste Produto" /> Demais Listas de Preços Deste Produto
                    </Typography.Title>
                    <Divider orientation="left" plain> Demais Listas de Preços deste Produto. </Divider>
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => { form.resetFields(); setOpenModal(true); }} />
                    </div>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Form form={form} component={false}>
                    <Table dataSource={listaDePrecos.filter((lista) => {return !lista.ltp_principal})} columns={[
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ ltp_id }) => (
                                <Tag color="processing" className="w-100">
                                    <b>{ltp_id}</b>
                                </Tag>
                            ),
                        },
                        {
                            title: 'Descrição da Lista de Preço',
                            render: ({ ltp_nome }) => (
                                <b>{ltp_nome}</b>
                            ),
                        },

                        {
                            title: 'Valor (R$)',
                            editable: true,
                            align: 'right',
                            render: ({ lpi_valorvenda }) => (
                                <div>
                                    <b className="f-18" >{FormatNumber(lpi_valorvenda, true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            align: 'center',
                            key: 'x',
                            width: 65,
                            fixed: 'right',
                            render: (record) => {
                                return (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button onClick={() => editarLista(record)} icon={<EditOutlined />} />
                                            </Col>
                                            <Col>
                                                <Button onClick={() => modalConfirmaExclusao(record)} icon={<DeleteOutlined />} />
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            },
                        },
                    ]} locale={{
                        emptyText: (
                            <Row>
                                <Col span={24}>
                                    <Divider orientation="center">
                                        <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                    </Divider>
                                </Col>
                            </Row>
                        )
                    }} scroll={{x: 900}}/>
                </Form>
            </div>
            <ModalListaPreco form={form} listaPreco={listaDePrecos} setListaPreco={setListaDePrecos} exibirModal={openModal} fecharModal={() => setOpenModal(false)} />
        </div>
    );
}