import React from "react";
import { Row, Col, Modal, Card } from "antd";
import { ExclamationCircleOutlined, DownloadOutlined, CloseOutlined } from "@ant-design/icons";

import { linkApi } from "../../../services/auth";
import { FormatNumber } from "../../../ValueObjects";

export default function ModalGerarRemessa({ exibirModalGerarRemessa, fecharModalGerarRemessa, dadosSelecionados, download }) {

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalGerarRemessa();
            }
        });
    };

    return (
        <Modal centered
            title="Gerar Arquivo de Remessa"
            visible={exibirModalGerarRemessa}
            onCancel={onCloseModal}
            onOk={download}
            okText={
                <>
                    <DownloadOutlined /> Download
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }   
            maskClosable={false}
        >
            <div>
                <Card>
                    <div>
                        <Row align="middle" gutter={[8, 0]} justify="space-between">
                            <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                                <b>Informações</b>
                            </Col>
                            <Col xs={24} sm={6} md={6} lg={6} xl={6} align="right">
                                <b>Valor Total (R$)</b>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[8, 0]} justify="space-between">
                            <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                                <Row align="middle" gutter={[8, 0]}>
                                    <Col>
                                        <img className="imgLogoListaBanco" src={`${linkApi}Banco/RetornaLogo/${dadosSelecionados.ban_id}`} alt={dadosSelecionados.ban_descricao} />
                                    </Col>
                                    <Col>
                                        Quant. Boletos: <b>{dadosSelecionados.rem_qtdeboletos}</b>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} sm={6} md={6} lg={6} xl={6} align="right">
                                <b>{FormatNumber(dadosSelecionados.rem_valortotal, true)}</b>
                            </Col>
                        </Row>
                    </div>
                </Card>
            </div>
        </Modal>
    );
}