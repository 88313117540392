
import React from "react";
import { Form, Row, Col } from "antd";

import { FiltroSearch, FiltroOrdenacao } from "../../components";
import ComboData from "../comboData";

export default function PesquisaOrdenacaoData({ opcoesOrdenacao = [], setDataPesquisa, dataPesquisa, form, eventoAoAlterar}) {

    return (
        <Form layout="vertical">
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={10} md={12} lg={12} xl={12}>
                    <FiltroSearch form={form} nameInput="filtroPesquisa" />
                </Col>
                <Col xs={24} sm={7} md={6} lg={6} xl={6}>
                    <FiltroOrdenacao opcoesOrdenacao={opcoesOrdenacao} />
                </Col>
                <Col xs={24} sm={7} md={6} lg={6} xl={6}>
                    <ComboData form={form} eventoAoAlterar={eventoAoAlterar}/>
                </Col>
            </Row>
        </Form>
    );

}