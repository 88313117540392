import React from "react";
import { Row, Col, Button, Tag, Dropdown, Menu, Table, Divider } from "antd";
import { DeleteOutlined, MoreOutlined, DollarOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import { FormatNumber } from '../../ValueObjects';

export default function PesquisaContaReceber({ dados }) {

    function menuTable(dados) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="link" icon={<DollarOutlined />}> Receber Pagamento </Button>
                </Menu.Item>
                <Menu.Item key="2">
                    <Button type="link" icon={<DeleteOutlined />}> Excluir Recebimento </Button>
                </Menu.Item>
            </Menu>
        )
    };

    return (
        <div className="tabela">
            <Table dataSource={dados} columns={[
                {
                    title: "",
                    width: 95,
                    align: 'center',
                    render: ({ saldo, prc_numeroparcela, cpr_numeroparcelas }) => (
                        <div>
                            <Row align="middle" justify="center">
                                <Col span={24}>
                                    <Tag color={saldo === 0 || saldo === 1 || saldo === 3 ? 'processing' : 'green'} className="w-100">
                                        <b>{!!saldo ? 'Recebido' : 'Em Aberto'}</b>
                                    </Tag>
                                </Col>
                            </Row>
                            <Row align="middle" justify="center">
                                <Col span={24}>
                                    <b>{prc_numeroparcela}/{cpr_numeroparcelas}</b>
                                </Col>
                            </Row>
                        </div>
                    )
                },
                {
                    title: "Contas a Receber",
                    render: ({ pes_nome, ctg_descricao, mcc_datahora, prc_datavencimento }) => (
                        <div>
                            <Row>
                                <Col>
                                    <b>{pes_nome}</b>
                                </Col>
                            </Row>
                            <Row align="middle" gutter={[16, 5]}>
                                <Col>
                                    Conta Gerencial: <b>{ctg_descricao}</b>
                                </Col>
                                <Col>
                                    <Row align="middle" gutter={[5, 0]}>
                                        <Col>
                                            <Tag color="processing">
                                                $
                                            </Tag>
                                        </Col>
                                        <Col>
                                            Recebido em:<b>{moment(mcc_datahora).format('DD/MM/YYYY')}</b>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row align="middle" gutter={[5, 0]}>
                                        <Col>
                                            <Tag color="error">
                                                $
                                            </Tag>
                                        </Col>
                                        <Col>
                                            Atrasado:<b>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row align="middle" gutter={[5, 0]}>
                                        <Col>
                                            <Tag color="green">
                                                $
                                            </Tag>
                                        </Col>
                                        <Col>
                                            A Receber:<b>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col>
                                    <Row align="middle" gutter={[5, 0]}>
                                        <Col>
                                            <Tag color="yellow">
                                                $
                                            </Tag>
                                        </Col>
                                        <Col>
                                            Receber Hoje:<b>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    )
                },
                {
                    title: "Número",
                    width: 100,
                    render: ({ cpr_numerodocumento }) => (
                        <div>
                            <b>{cpr_numerodocumento || 'Não Informado'}</b>
                        </div>
                    )
                },
                {
                    title: 'Valor da Parcela (R$)',
                    align: 'right',
                    width: 180,
                    render: ({ prc_valor }) => (
                        <div>
                            <b>{FormatNumber(prc_valor, true)}</b>
                        </div>
                    ),
                },
                {
                    title: 'Saldo Devedor (R$)',
                    align: 'right',
                    width: 180,
                    render: ({ prc_valor }) => (
                        <div>
                            <b>{FormatNumber(prc_valor, true)}</b>
                        </div>
                    ),
                },/*
                {
                    title: 'Ações',
                    dataIndex: '',
                    key: 'x',
                    align: 'center',
                    width: 65,
                    fixed: 'right',
                    render: (record) => (
                        <div>
                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                <Col>
                                    <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                        <Button icon={<MoreOutlined />} />
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>
                    ),
                },*/
            ]} locale={{
                emptyText: (
                    <Row>
                        <Col span={24}>
                            <Divider orientation="center">
                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                            </Divider>
                        </Col>
                    </Row>
                )
            }} scroll={{ x: 900 }}/>
        </div>
    );
}