import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tag, Popover, Form, Table, Select, Input, notification } from "antd";
import { EditOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from '../../state';
import DrawerCentroDistribuicao from './drawer';
import { MaskFormat, FormatFone } from "../../ValueObjects";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, Editar, TabelaDados, PesquisaOrdenacao } from "../../components";
import { getEmpresa, setEmpresa, setLocal, setNomeLocal } from "../../services/auth";

export default function CentroDistribuicao() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [formPesquisa] = Form.useForm();

    function editarCentro(record) {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true, editItem: true } });
    };

    function listar(filtro = null, ordem = null) {
        if (!!formPesquisa.getFieldValue().filtro) {
            filtro = formPesquisa.getFieldValue().filtro;
        }
        if (!!formPesquisa.getFieldValue().ordem) {
            ordem = formPesquisa.getFieldValue().ordem;
        }
        dispatch({ type: listagemActions.CHANGE, data: { campoFiltro: 'Filtro', filtro: filtro, ordem: ordem } });
    };

    async function acessarCentroDistribuicao(centro) {
        let codigoEmpresa = getEmpresa();
        if (codigoEmpresa) {
            let listaLocaisUsuario = await api.get(`/Local/ListarLocalUsuario?codigoEmpresa=${codigoEmpresa}`);
            if (!!listaLocaisUsuario.data.items && listaLocaisUsuario.data.items.length > 0) {
                let local = listaLocaisUsuario.data.items.find((item) => { return item.loc_id === centro.loc_id });
                if (!!local) {
                    setEmpresa(codigoEmpresa);
                    setLocal(local.loc_id);
                    setNomeLocal(local.loc_descricao);
                    window.location = "/Home";
                } else {
                    notification.warning({ message: 'Aviso!', description: 'Você não possui acesso a esta filial!' });
                }
            }
        }
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Form layout="vertical" form={formPesquisa} name="formPesquisa" initialValues={{ remember: true }}>
                <Row align="middle" gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <PesquisaOrdenacao opcoesOrdenacao={[
                            { label: "Nome A - Z", value: "+loc_descricao" },
                            { label: "Nome Z - A", value: "-loc_descricao" },
                            { label: "Código Crescente", value: "+loc_codigo" },
                            { label: "Código Decrescente", value: "-loc_codigo" }
                        ]} />
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="CentroDistribuicao/Listar" colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ loc_codigo, loc_ativo }) => (
                                <Popover content={loc_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                    <Tag color={loc_ativo ? 'processing' : 'default'} className="w-100">
                                        <b>{loc_codigo}</b>
                                    </Tag>
                                </Popover>
                            ),
                        },
                        {
                            title: 'Centro de Distribuição',
                            render: ({ loc_descricao }) => (
                                <div>
                                    <b>{loc_descricao || 'Não Informado'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'CNPJ',
                            render: ({ loc_cnpj }) => (
                                <div>
                                    <b>{MaskFormat(loc_cnpj, '', true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Telefone',
                            render: ({ loc_telefone }) => (
                                <div>
                                    <b>{(!!loc_telefone) ? FormatFone(loc_telefone) : 'Não Informado'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            fixed: 'right',
                            width: 65,
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Editar onClick={() => editarCentro(record)} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Button onClick={() => { acessarCentroDistribuicao(record) }} icon={<ArrowUpOutlined />} ></Button>
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} expandir={
                        (record) => {
                            return (
                                <div>
                                    <Row justify="space-between" gutter={[16, 0]}>
                                        <Col>
                                            Endereço: <b>{record.log_logradouro || 'Não Informado'}{(!!record.loc_numeroestabelecimento) ? (', ' + record.loc_numeroestabelecimento) : ''} {!!(record.cid_descricao) ? ('- ' + record.cid_descricao) : ''}
                                                {!!(record.est_sigla) ? ('/ ' + record.est_sigla) : ''}</b>
                                        </Col>
                                        <Col>
                                            E-mail: <b>{(!!record.loc_email) ? (record.loc_email) : 'Não Informado'}</b>
                                        </Col>
                                        <Col>
                                            Responsável: <b>{(!!record.loc_responsavelestabelecimento) ? (record.loc_responsavelestabelecimento) : 'Não Informado'}</b>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        }
              } scroll={{ x: 900 }} />
            </div>
            <DrawerCentroDistribuicao listar={listar} />
        </div>
    );

}