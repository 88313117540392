import React, { useState, useEffect } from "react";
import { Form, Row, Col, Tabs, Input, Checkbox, notification } from "antd";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

import api from "../../services/api";
import { FormGW } from "../../components";
import { useStateValue } from "../../state";
import { listagemActions } from "../../actions";
import { TabEndereco, TabResponsavel } from "./tabPage";
import { validaForm, validarCpf } from "../../services/funcoes";

export default function ManutencaoCentroDistribuicao({ formulario, carregando, aoSalvar, listar }) {

    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [filialInexistente, setFilialInexistente] = useState(false);
    const [centroCadastrado, setCentroCadastrado] = useState(false);
    const [{ manutencao }, dispatch] = useStateValue();

    const listaValidacoes = [
        { nome: 'loc_cnpj', obrigatorio: true, label: 'CNPJ' },
        { nome: 'loc_descricao', obrigatorio: true, label: 'Nome do Centro de Distribuição' },
        { nome: 'cep_cep', obrigatorio: true, label: 'CEP' },
        { nome: 'log_logradouro', obrigatorio: true, label: 'Endereço' },
        { nome: 'loc_numeroestabelecimento', obrigatorio: true, label: 'Número' },
        { nome: 'bai_nome', obrigatorio: true, label: 'Bairro' },
        { nome: 'loc_email', obrigatorio: true, label: 'E-mail' },
        { nome: 'loc_cpfresponsavel', obrigatorio: true, label: 'CPF do Responsável' }
    ];

    const salvarManutencao = () => {
        if (filialInexistente) {
            notification.warn({ message: 'Atenção!', description: "Nenhuma Filial com esse CNPJ, O centro de distribuição deve pertencer a uma filial!" });
            return false;
        }
        if (centroCadastrado) {
            notification.warn({ message: 'Atenção!', description: "Já existe um Centro de Distribuição com esse CNPJ!" });
            return false;
        }

        let values = formulario.getFieldValue();

        if (!!values.loc_cpfresponsavel && !validarCpf(values.loc_cpfresponsavel)) {
            notification.warn({ message: 'Atenção!', description: "CPF do responsável informado não é valido!" });
            return false;
        }

        if (validaForm(formulario, listaValidacoes)) {
            carregando(true);
            let enderecoUrl = 'CentroDistribuicao/';
            if (values.loc_id) {
                enderecoUrl += `Editar`;
                api.put(enderecoUrl, values).then(res => {
                                notification.success({ message: 'Operação concluída', description: "Os dados da Filial foram salvos com sucesso!" });
                            }).catch(
                                erro => {
                                    notification.error({ message: 'Atenção!', description: "Não foi possivel incluir os dados desta filial!" });
                                }).finally(() => {
                                    aoSalvar();
                                    carregando(false);
                                    atualizarPagina();
                                });
            } else {
                enderecoUrl += "Incluir"
                api.post(enderecoUrl, values).then(res => {
                                notification.success({ message: 'Operação concluída', description: "Os dados da Filial foram salvos com sucesso!" });
                            }).catch(
                                erro => {
                                    notification.error({ message: 'Atenção!', description: "Não foi possivel incluir os dados desta filial!" });
                                }).finally(() => {
                                    aoSalvar();
                                    carregando(false);
                                    atualizarPagina();
                                });
            };
        };
    };

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+loc_descricao' } });
        listar();
    };

    function buscarDadosFilial(e) {
        let cnpj = e.target.value.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
        if (cnpj.length === 14) {
            api.get(`CentroDistribuicao/VerificarCentroExistente?cnpjLocal=${cnpj}`).then(
                (centroExiste) => {
                    if (!centroExiste.data) {
                        api.get(`Local/BuscarLocalCnpj/${cnpj}`).then(
                            (retorno) => {
                                let dados = retorno.data;
                                if (!!dados) {
                                    let dadosCentroDistribuicao = {
                                        loc_cnpj: dados.loc_cnpj.numero,
                                        loc_codigo: dados.loc_codigo,
                                        loc_descricao: dados.loc_descricao,
                                        loc_numeroestabelecimento: dados.loc_numeroestabelecimento,
                                        loc_complemento: dados.loc_complemento,
                                        loc_pontoreferencia: dados.loc_pontoreferencia,
                                        loc_email: dados.loc_email.endereco,
                                        loc_telefone: dados.loc_telefone,
                                        loc_responsavelestabelecimento: dados.loc_responsavelestabelecimento,
                                        loc_cpfresponsavel: dados.loc_cpfresponsavel.numero,
                                        loc_tipocontribuinte: dados.loc_tipocontribuinte
                                    };
                                    formulario.setFieldsValue(dadosCentroDistribuicao);
                                    setFilialInexistente(false);
                                    setCentroCadastrado(false);
                                } else {
                                    if (!!!formulario.getFieldValue().loc_id) {
                                        setFilialInexistente(true);
                                        setCentroCadastrado(false);
                                    }
                                }
                            }
                        ).catch(
                            (erro) => {
                                console.log(erro);
                            }
                        );
                    } else {
                        if (!!!formulario.getFieldValue().loc_id) {
                            setFilialInexistente(false);
                            setCentroCadastrado(true);
                        }
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            );
        }
    };

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoCentroDistribuicao" initialValues={{ remember: true }} onFinish={salvarManutencao}>
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Matriz/Filial" key="1">
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={6} md={5} lg={4} xl={4}>
                                <Form.Item name="loc_id" hidden><Input /></Form.Item>
                                <Form.Item name="loc_tipocontribuinte" hidden><Input /></Form.Item>
                                <Form.Item label="CNPJ" name="loc_cnpj" rules={[{ required: true, message: 'Informe o CNPJ' }]}>
                                    <CpfCnpj
                                        className='ant-input ant-input-sm'
                                        placeholder="Informe o CNPJ"
                                        onBlur={(e) => buscarDadosFilial(e)}
                                    />
                                </Form.Item>
                            </Col>{!!filialInexistente && <span class="c-red">* Sem filiais com esse CNPJ</span>}
                            {!!centroCadastrado && <span class="c-red">* Já Existe um Centro de Distribuição com esse CNPJ</span>}
                            <Col xs={24} sm={6} md={5} lg={5} xl={5}>
                                <Form.Item label="Código de Identificação" name="loc_codigo">
                                    <Input placeholder="Informe um Código para Identificação" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={9} xl={9}>
                                <Form.Item label="Nome do Centro de Distribuição" name="loc_descricao" rules={[{ required: true, message: 'Informe o Nome Centro de Distribuição' }]}>
                                    <Input placeholder="Informe o Nome Centro de Distribuição" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={6} lg={6} xl={6} className="t-mob">
                                <Form.Item name="loc_ativo" valuePropName="checked">
                                    <Checkbox>Centro de Distribuição Ativo?</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Endereço" key="2">
                        <TabEndereco formulario={formulario} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Responsável" key="3">
                        <TabResponsavel formulario={formulario} />
                    </Tabs.TabPane>
                </Tabs>
            </FormGW>
        </div>
    );

}