import React, { useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined, CheckOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import { DrawerNav } from '../../components';
import ManutencaoProdutos from './manutencao';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';

export default function DrawerProduto({ incluirMaisUm, editando }) {

    const nameForm = "manutencaoProduto"
    const [formProduto] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    //console.log("valores: ", formProduto.getFieldValue().anp_id);

    function verificarSalvar(produto) {
        Modal.confirm({
            title: 'Atenção',
            icon: <CheckOutlined />,
            content: 'Produto incluído com sucesso!',
            okText: 'Incluir mais um',
            cancelText: 'Ok',
            centered: true,
            onOk() {
                localStorage.removeItem(nameForm);
                formProduto.resetFields();
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pro_descricao' } });
                let dados = { pro_id: produto.pro_id };
                incluirMaisUm(dados);
            },
            onCancel() {
                fecharDrawer();
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pro_descricao' } });
            }
        });
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    function fecharDrawer() {
        localStorage.removeItem(nameForm);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        editando.setEditando(false);
        formProduto.resetFields();
    };

    return (

        <DrawerNav title="Cadastro Produto"
            width="70%"
            visible={ui.showDrawer}
            closeIcon={<MinusOutlined />}
            limparAoEditar={true}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formProduto.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoProdutos formulario={formProduto} nameForm={nameForm} salvandoDados={setCarregando} aoSalvar={verificarSalvar} editando={editando} />
        </DrawerNav>
    )
}