import React,{ useEffect, useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import { drawerActions, manutencaoActions } from '../../actions';

import Formularios from './manutencao';
import { DrawerNav } from '../../components';

export default function DrawerPersonalizacao({grupos}) {

    const [ formFormulario ] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [ carregando, setCarregando ] = useState(false);
    const idRel = 0
    

    function fecharDrawer() {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null}});
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false}});
        formFormulario.resetFields();
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    useEffect(() =>{
        if (manutencao.dados !== null) {
            formFormulario.setFieldsValue(manutencao.dados);
            console.log("manutenção",manutencao.dados);
        } else {
            formFormulario.resetFields();
        }
    },[!!manutencao.dados])

    return (

        <DrawerNav title="Personalização de cadastro de Relatório"
            width="70%"
            visible={ui.showDrawer}
            closeIcon={<MinusOutlined />}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formFormulario.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>                
            <Formularios formulario={formFormulario} carregando={setCarregando} aoSalvar={fecharDrawer} grupos={grupos} />
        </DrawerNav>      

    )
}