import React, { useState, useEffect } from "react";
import { Row, Col, Form, Modal, Tag, Button, Table, Divider, Select } from "antd";
import { DeleteOutlined, PlusOutlined, ExclamationCircleOutlined, } from "@ant-design/icons";

import { SelectPagination } from "../../components";
import SelectPaginado from "../../components/selectPaginado";
import api from "../../services/api";

export default function TabVendedor({ formulario }) {

    const [form] = Form.useForm();
    const [vendedores, setVendedores] = useState(() => {
        if (formulario.getFieldValue('ltp_id')) {
            let list = formulario.getFieldValue('vendedores');
            return list ? list : [];
        }
        return []
    });

    useEffect(() => {
        formulario.setFieldsValue({ vendedores: vendedores });
    }, [vendedores])

    async function onAddVendedorList() {
        let array = [...vendedores];
        let dadosVendedor = (await api.get(`Vendedor/RetornaVendedor?idVendedor=${formulario.getFieldValue().ven_id}`)).data;
        array.push(dadosVendedor);
        let key = 0;
        array.forEach(item => {
            item.key = key++;
        });
        setVendedores(array);
        form.resetFields();
    }

    function onDelete(value) {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja excluir o vendedor selecionado?`,
            okText: 'Excluir',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                deleteVendedor(value);
            }
        })
    }

    const deleteVendedor = (value) => {
        let array = [...vendedores];
        let index = array.findIndex(x => x.ven_id == value.ven_id);
        if (index !== -1) {
            array.splice(index, 1);
            setVendedores(array);
        }
    }

    return (
        <div>
            <Form layout="vertical" form={form} onFinish={onAddVendedorList}>
                <Row gutter={[8, 8]}>
                    <Col xs={24} sm={18} md={19} lg={20} xl={20}>
                        <Form.Item name="ven_id" label="Selecione o Vendedor">
                            <SelectPaginado url="Vendedor/Listar" placeholder="Filtrar por Cliente..." form={formulario} name="ven_id"
                                conteudo={
                                    v => (<Select.Option value={v.ven_id} key={v.key}>{v.ven_id} - {v.pes_nome}</Select.Option>)
                                } />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={5} lg={4} xl={4}>
                        <Button type="primary" htmlType="submit" block icon={<PlusOutlined />} className="t-mob-573">
                            Adicionar
                        </Button>
                    </Col>
                </Row>
            </Form>
            <div className="tabela mt-dif">
                <Table
                    dataSource={vendedores}
                    columns={[
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ ven_id }) => (
                                <Tag color="processing" className="w-100">
                                    <b>{ven_id}</b>
                                </Tag>
                            ),
                        },
                        {
                            title: 'Nome',
                            render: ({ pes_nome }) => (
                                <div>
                                    <b>{pes_nome}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            align: 'center',
                            key: 'x',
                            width: 65,
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button onClick={() => onDelete(record)} icon={<DeleteOutlined />} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} locale={{
                        emptyText: (
                            <Row>
                                <Col span={24}>
                                    <Divider orientation="center">
                                        <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                    </Divider>
                                </Col>
                            </Row>)
                    }} />
            </div>
        </div>
    );

}