import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tag, Input, Modal, notification, Form, Table } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { ModalItemInventario } from "../../components/modals";
import { TabelaDados } from "../../components";
import { listagemActions } from "../../actions";
import { useStateValue } from '../../state';


export default function Itens({ form, dadosItens, TipoCodigoProduto, onCloseModalItem }) {

    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [abrirModalItem, setAbrirModalItem] = useState();
    const [dadosRegistro, setDadosRegistro] = useState({});
    const [listaItem, setListaItem] = useState([]);

    const onCloseModal = () => {
        setAbrirModalItem(false);
        onCloseModalItem();
    };
    const [situacao] = useState({
        0: 'Falta',
        1: 'Ok',
        2: 'Sobra'
    });

    const verificaSituacao = record => {
        let retorno = 0;
        if (record.ivi_estoqueanterior > record.ivi_estoqueatual) {
            retorno = 0;
        } else if (record.ivi_estoqueanterior === record.ivi_estoqueatual) {
            retorno = 1;
        } else if (record.ivi_estoqueanterior < record.ivi_estoqueatual) {
            retorno = 2;
        }
        return retorno;
    };
    const filtroItens = valor => {
        api.get(`Inventario/ListarItens?InventarioId=${form.getFieldValue().inv_id}&Filtro=${valor}&PageSize=100&Order=-ivi_id&PageNumber=1`).then(

            res => {
                res.data.items.forEach(item => {
                    item.situacao_id = verificaSituacao(item);
                    item.situacao = situacao[verificaSituacao(item)];
                });
                setListaItem(res.data.items);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
        // let itens = [...dadosItens.listaDadosItens];
        // itens = itens.filter(dados => {
        //     return (dados.grade.toLowerCase().indexOf(valor) > -1 || dados.pro_descricao.toLowerCase().indexOf(valor) > -1 || dados.pro_codigo.toString().indexOf(valor) > -1);
        // });
        // setListaItem(itens);
    };

    // useEffect(() => {
    //     let sql = "";
    //     sql += !!form.getFieldValue().inv_id ? `&InventarioId=${form.getFieldValue().inv_id}` : "";
    //     dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: sql, ordem: '-ivi_id' } });
    // }, []);

    useEffect(() => {
        if (!!dadosItens.listaDadosItens) {
            setListaItem(dadosItens.listaDadosItens);
            if (!!form.getFieldValue().pesquisa) {
                filtroItens(form.getFieldValue().pesquisa);
            }
        }
    }, [dadosItens.listaDadosItens]);

    function excluirItem(dados) {
        Modal.confirm({
            title: `Remover produto ${dados.pro_descricao}?`,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                api.delete(`Inventario/RemoverItem/${dados.ivi_id}`).then(
                    res => {
                        notification.success({ description: 'Item do inventário excluído com sucesso!', message: 'Aviso!' });
                    }
                ).catch(
                    erro => {
                        console.log(erro);
                    }
                ).finally(
                    () => {
                        onCloseModalItem();
                    }
                )
            }
        });
    };
    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Form.Item name="pesquisa">
                        <Input.Search placeholder={"Código, descrição"} onSearch={valor => { filtroItens(valor) }} allowClear />
                    </Form.Item>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Table dataSource={listaItem} columns={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ pro_codigo }) => (
                            <Tag color="processing" className="w-100">
                                <b>{pro_codigo}</b>
                            </Tag>
                        ),
                    },
                    {
                        title: 'Produto',
                        render: ({ pro_descricao }) => (
                            <div>
                                <b>{pro_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Grade',
                        render: ({ grade }) => (
                            <div>
                                <b>{grade || 'Não Informado'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Qtde. Anterior',
                        align: 'center',
                        width: 110,
                        render: ({ ivi_estoqueanterior }) => (
                            <div>
                                <b>{ivi_estoqueanterior}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Qtde. Atual',
                        align: 'center',
                        width: 110,
                        render: ({ ivi_estoqueatual }) => (
                            <div>
                                <b>{ivi_estoqueatual}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Situação',
                        align: 'center',
                        width: 100,
                        render: ({ situacao_id, situacao }) => (
                            <Tag color={situacao_id === 0 ? "volcano" : (situacao_id === 1 ? "green" : "gold")} >
                                {situacao}
                            </Tag>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        fixed: 'right',
                        width: 65,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button onClick={() => {
                                            setAbrirModalItem(true);
                                            setDadosRegistro(record);
                                        }} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>
                                        <Button onClick={() => {
                                            excluirItem(record)
                                        }} icon={<DeleteOutlined />} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} scroll={{ x: 900 }} />
            </div>
            <ModalItemInventario form={form} dadosItem={dadosRegistro} exibirModal={abrirModalItem} onCloseModal={onCloseModal} TipoCodigoProduto={TipoCodigoProduto} />
        </div>
    );

}