import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Typography, Input, Button, Tag, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { TabelaDados } from "../../components";
import { useStateValue } from "../../state";
import { listagemActions } from "../../actions";
import api from "../../services/api";

export default function Armazem({ idProduto }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const searchInput = useRef();
    const [listaArmazens, setListaArmazens] = useState([]);
    const [filtros, setFiltros] = useState([]);

    useEffect(() => {
        if (!!idProduto)
            carregarDados(idProduto);
    }, [idProduto])

    useEffect(() => {
        console.log('Busca do Servidor com os Filtros: ', filtros);
    }, [filtros])

    function carregarDados(id) {
        api.get(`ArmazemEstoque/ListarArmazensProduto?idProduto=${id}`).then(
            res => {
                if (res.status === 200) {
                    setListaArmazens(res.data);
                }
            }).catch(
                error => {
                    console.log(error);
                })
    }

    return (
        <div>
            <Row align="middle" gutter={[8, 0]}>
                <Col>
                    <Typography.Text strong>
                        O Produto está nos seguintes armazéns:
                    </Typography.Text>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Table pagination={false} columns={[
                    {
                        title: 'Armazéns',
                        render: ({ aes_descricao }) => (
                            <div>
                                {aes_descricao}
                            </div>
                        )
                    },
                    {
                        title: 'Quantidade',
                        align: 'center',
                        width: 150,
                        render: ({ pre_qtde }) => (
                            <div>
                                <b>{pre_qtde}</b>
                            </div>
                        ),
                    }
                ]}
                    dataSource={listaArmazens}
                />
            </div>
        </div>
    );

}