import React from "react";
import { Row, Col, Descriptions } from "antd";
import moment from 'moment';

import { FormatNumber } from "../../ValueObjects/";

export default function InfoNotaNFe({ dadosNota }) {

    return (
        <div>
            <Row>
                <Col span={24}>
                    <Descriptions size="small">
                        <Descriptions.Item label="Chave da NF-e">
                            <b> {dadosNota.ntf_chavenfe} </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Descriptions size="small">
                        <Descriptions.Item label="Destinatário">
                            <b> {dadosNota.pes_nome} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="CPF/CNPJ">
                            <b> {dadosNota.ntf_cnpjcpfdest} </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Descriptions size="small">
                        <Descriptions.Item label="Nº da Nota">
                            <b> {dadosNota.ntf_numero} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Série">
                            <b> {dadosNota.ser_serie} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Modelo">
                            <b> {dadosNota.nfd_modelodocumento} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Emitida em">
                            <b> {moment(dadosNota.ntf_dataemissao).format('DD/MM/YYYY HH:mm:ss')} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Tipo de Nota">
                            <b> {dadosNota.tip_descricao} </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Descriptions size="small">
                        <Descriptions.Item label="Valor Total da Nota (R$)">
                            <b> {FormatNumber(dadosNota.valor_total, true)} </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
        </div>
    );
}