import { useState } from "react";
import { useStateValue } from "../../state";
import { Button, Col, Drawer, Form, Modal, Row } from "antd";
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined, MinusOutlined, SaveOutlined } from "@ant-design/icons";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import ManutencaoKitComposicao from "./manutencao";

export default function DrawerKitComposicao({ editando, setFechouCadastro, fechouCadastro }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [formKitComp] = Form.useForm();
    const [carregando, setCarregando] = useState(false);


    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    function fecharDrawer() {
        formKitComp.resetFields();
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pro_descricao' } });
        setFechouCadastro(!fechouCadastro);
    }

    return (
        <Drawer title="Cadastro Kit Composicao"
            width='70%'
            visible={ui.showDrawer}
            closeIcon={<MinusOutlined />}
            onClose={() => onClose()}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formKitComp.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoKitComposicao formulario={formKitComp} salvandoDados={setCarregando} aoSalvar={fecharDrawer} editando={editando} />
        </Drawer>
    );
}