import React, { useEffect, useState } from "react";
import { Row, Col, Descriptions, Form, Select, Modal, notification } from "antd";
import { ExclamationCircleOutlined, PrinterOutlined, CloseOutlined } from "@ant-design/icons";

import { isObjetoDiffVazio } from "../../../services/funcoes";
import { useStateValue } from "../../../state";
import api from "../../../services/api";
import { carregarConfiguracoes, ServidorImpressoraOnline } from "../../../services/servidorImpressora";
import { imprimirEtiquetaImp, imprimirImpressoraPadrao } from "../../../services/funcoesEtiqueta";
import SelectPaginado from "../../../components/selectPaginado";

export default function ModalImprimirEtiquetas({ exibirModalImprimirEtiquetas, fecharModalImprimirEtiquetas, dadosNota }) {

    const [listaPreco, setListaPreco] = useState([]);
    const [form] = Form.useForm();
    const [listaModelo, setListaModelo] = useState([]);
    const [listaModeloPadrao, setListaModeloPadrao] = useState([]);
    const [listaModeloEtiqueta, setListaModeloEtiqueta] = useState([]);
    const [listaItens, setListaItens] = useState([]);
    const [listaProduto, setListaProduto] = useState([]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalImprimirEtiquetas();
            }
        });
    };

    useEffect(() => {
        if (!!exibirModalImprimirEtiquetas) {
            verificarServidor();
        }
    }, [exibirModalImprimirEtiquetas]);

    useEffect(() => {
        buscarListaPreco();
        buscarModelo();
        buscarModeloEtiqueta();
        //buscarProdutos();
    }, []);

    useEffect(() => {
        if (!!dadosNota && isObjetoDiffVazio(dadosNota)) {
            listarItensNota();
        }
    }, [dadosNota])

    function buscarProdutos() {
        api.get(`Produto/ListaProdutoFiltro`).then(
            res => {
                let dados = res.data;
                let id = 0;
                dados.forEach((produto) => {
                    produto.key = id;
                    id++;
                });
                setListaProduto(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function buscarListaPreco() {
        api.get(`ListaPreco/ListarTodasListaPreco`).then(
            res => {
                if (!!res.data) {
                    setListaPreco(res.data.items);
                    form.setFieldsValue({ ltp_id: res.data.items.filter(lista => lista.ltp_principal)[0].ltp_id })
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    async function verificarServidor() {
        let servidorOn = await ServidorImpressoraOnline();
        if (servidorOn.online) {
            let conf = await carregarConfiguracoes();
            if (!!conf) {
                if (!!!conf.impressoraEtiqueta || !isObjetoDiffVazio(conf.impressoraEtiqueta)) {
                    notification.warn({ description: 'Você deve configurar a impressora de etiquetas a ser utilizada nesse computador!', message: 'Aviso' });
                } else if (!!!conf.modeloEtiqueta || !isObjetoDiffVazio(conf.modeloEtiqueta)) {
                    notification.warn({ description: 'Você deve configurar o modelo de etiquetas padrão a ser utilizado nesse computador!', message: 'Aviso' });
                }
            }
        } else {
            notification.warn({ description: 'O Servidor de impressão não esta em execução nesse computador, para utilizar impressoras de etiquetas verifique as configurações!', message: 'Aviso' });
            //setServidorOn(false);

        }
    };

    function buscarModelo() {
        api.get(`ModeloEtiqueta/Listar?filtro=&ordem=%2Bmet_descricao`).then(
            res => {
                let dados = res.data.items;
                let seq = 0;
                dados.forEach((modelo) => {
                    modelo.key = seq;
                    modelo.descricaoModelo = modelo.met_descricao;
                    modelo.id = modelo.met_id;
                    seq++;
                })
                setListaModeloEtiqueta(dados);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function buscarModeloEtiqueta() {
        api.get(`RelatorioModeloEtiqueta/ListarGeral?TipoEtiqueta=0`).then(
            res => {
                let dados = res.data.items;
                let seq = 0;
                dados.forEach((modelo) => {
                    modelo.key = seq;
                    modelo.descricaoModelo = modelo.nome;
                    seq++;
                });
                setListaModeloPadrao(dados);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    async function listarItensNota() {
        let ret = await api.get(`NotaFiscal/RetornaItensEtiquetaNota?IdListaPreco=${form.getFieldValue().ltp_id}&IdNotaFiscal=${dadosNota.ntf_id}`);
        let retorno = ret.data;
        //let dados = listaProduto.filter((prodLista) => { return retorno.find((item) => (item.pro_id === prodLista.pro_id && item.prg_id === prodLista.prg_id)) });
        for (let [i, item] of retorno.entries()) {
            item.quantidade = parseInt(item.nfi_qtde);
        };
        setListaItens(retorno);
    };

    function onChangeTipo() {
        let tipo = parseInt(form.getFieldValue().tpEtiqueta);
        let lista = [];
        form.setFieldsValue({ modeloEtiqueta: null });
        if (tipo === 0) {//Impressora Padrao
            lista = [...listaModeloPadrao];
        } else {
            lista = [...listaModeloEtiqueta];
        }
        setListaModelo(lista);
    };

    function imprimirEtiqueta() {
        listarItensNota();
        let tipo = form.getFieldValue().tpEtiqueta;
        let itens = [...listaItens];
        let modelo = listaModelo.filter((mod) => { return mod.id === form.getFieldValue().modeloEtiqueta })[0];
        if (!!modelo && itens.length > 0) {
            if (tipo === 0) {
                imprimirImpressoraPadrao(modelo.id, itens)
            } else {
                imprimirEtiquetaImp(modelo, itens);
            }
        }
    };

    return (
        <Modal centered
            title="Etiquetas"
            visible={exibirModalImprimirEtiquetas}
            onCancel={onCloseModal}
            onOk={() => { imprimirEtiqueta() }}
            okText={
                <>
                    <PrinterOutlined /> Imprimir Etiqueta
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }   
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" form={form}>
                    <Descriptions column={1} size="small">
                        <Descriptions.Item label="Nota Fiscal Nº">
                            <b> {dadosNota.ntf_numero} </b>
                        </Descriptions.Item>
                    </Descriptions>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item label="Tipo Etiqueta" name="tpEtiqueta">
                                <Select placeholder="Selecione uma lista" onChange={() => { onChangeTipo() }}>
                                    <Select.Option value={0} key={0}>Impressora Padrao</Select.Option>
                                    <Select.Option value={1} key={1}>Impressora de Etiqueta</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item label="Modelo Etiqueta" name="modeloEtiqueta">
                                <Select placeholder="Selecione um modelo">
                                    {listaModelo.map(modelo => (
                                        <Select.Option value={modelo.id} key={modelo.key}>{modelo.descricaoModelo}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item label="Lista de Preço" name="ltp_id">
                                <Select placeholder="Selecione uma Lista">
                                    {listaPreco.map((lista) => (
                                        <Select.Option value={lista.ltp_id} key={lista.ltp_id}>{lista.ltp_nome}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col> */}
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Form.Item name="ltp_id" label="Lista de Preço">
                                <SelectPaginado
                                    url="ListaPreco/RetornaListaPrecoEmpresa"
                                    form={form}
                                    name="ltp_id"
                                    placeholder="Selecione uma Lista"
                                    onChangeFunction={(value) => buscarListaPreco(value)}
                                    showSearch={true}
                                    allowClear={true}
                                    conteudo={
                                        lp => (<Select.Option value={lp.ltp_id} key={lp.key}>{lp.ltp_nome}</Select.Option>)
                                    } />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}