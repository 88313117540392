import React, { createRef } from 'react';
import moment from 'moment';
import { Form, Input } from 'antd';

export default function Data(props) {
    function subtrairSomarDias(data, dias, soma) {
        if (soma) {
            return new Date(data.getTime() + (dias * 24 * 60 * 60 * 1000));
        } else {
            return new Date(data.getTime() - (dias * 24 * 60 * 60 * 1000));
        }
    }

    class NovaData extends React.Component {
        constructor(props) {
            super(props);
            this.inputRef = createRef();
        }

        onChange = e => {
            const { value } = e.target;
            let data = moment(value, "YYYY-MM-DD", true);

            if (data.isValid()) {
                this.props.onChange(data);
            } else {
                this.props.onChange(null);
            }
        };

        onKeyUp = e => {
            let key = e.which || e.keyCode;
            let data = null;

            if (key === 68) { // Tecla 'D' - data atual
                data = new Date();
            }
            if (key === 79) { // Tecla 'O' - data anterior
                data = subtrairSomarDias(new Date(), 1, false);
            }
            if (key === 65) { // Tecla 'A' - data posterior
                data = subtrairSomarDias(new Date(), 1, true);
            }
            if (key === 73) { // Tecla 'I' - data inicial do mês
                let date = new Date();
                data = new Date(date.getFullYear(), date.getMonth(), 1);
            }
            if (key === 70) { // Tecla 'F' - data final do mês
                let date = new Date();
                data = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            }

            if (!!data) {
                this.props.onChange(moment(data));
            }

            if (!!this.props.onKeyUp) {
                this.props.onKeyUp();
            }
        };

        onPressEnter = () => {
            const inputElement = this.inputRef.current.input;
            const form = inputElement.closest('form');
            if (form) {
                const inputs = Array.from(form.querySelectorAll('input'));
                const currentIndex = inputs.indexOf(inputElement);
                const nextInput = inputs[currentIndex + 1];
                if (nextInput) {
                    nextInput.focus();
                }
            }
        };

        onBlur = () => {
            if (this.props.onBlur) {
                this.props.onBlur();
            }
        };

        render() {
            const { value, month, disabled } = this.props;
            let data = value ? moment(value) : null;

            return (
                <Input
                    ref={this.inputRef}
                    className="text-uppercase"
                    disabled={disabled}
                    type={month ? "month" : "date"}
                    value={data ? data.format(month ? 'YYYY-MM' : 'YYYY-MM-DD') : ''}
                    onChange={this.onChange}
                    onKeyUp={this.onKeyUp}
                    onPressEnter={this.onPressEnter}
                    onBlur={this.onBlur}
                />
            );
        }
    };

    class DataInput extends React.Component {
        onChange = () => {
            if (this.props.onChange) {
                this.props.onChange();
            }
        };

        render() {
            const { name, label, initialValue, style, className, hidden, rules } = this.props;

            return (
                <div className="form-data">
                    <Form.Item
                        name={name}
                        label={label}
                        initialValue={initialValue}
                        style={style || {}}
                        className={className || ''}
                        hidden={hidden}
                        rules={rules || []}
                    >
                        <NovaData {...this.props} onChange={this.onChange} />
                    </Form.Item>
                </div>
            );
        }
    };

    return (
        <DataInput {...props} />
    );
};
