import React, { useEffect, useState } from "react";
import { Row, Col, Tag, Table, Modal } from "antd";

import api from "../../../services/api";
import { FormatNumber } from "../../../ValueObjects";

export default function ModalGradeEstoque({ dadosGrade, exibirModal, fecharModal }) {

    const [itensGrade, setItensGrade] = useState([]);
    const [itensProduto, setItensProduto] = useState({});

    useEffect(() => {
        if (!!dadosGrade?.pro_id) {
            setItensProduto(dadosGrade);
            api.get(`Estoque/ListaEstoqueGradesProdutos?CodigoProduto=${dadosGrade.pro_id}`).then(
                res => {
                    let key = 0;
                    res.data.forEach((produtoGrade) => {
                        produtoGrade.key = key;
                        key++;
                    });
                    setItensGrade(res.data);
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            );
        } else {
            // Limpar itens se dadosGrade não estiver disponível
            setItensGrade([]);
            setItensProduto({});
        }
    }, [dadosGrade])

    function onClose() {
        fecharModal();
    }

    return (
        <Modal centered
            title="Grade Produtos"
            visible={exibirModal}
            onCancel={onClose}
            width={780}
            footer={null}
            maskClosable={false}
        >
            <div>
                Produto/Mercadoria: <b> {itensProduto.pro_codigo} - {itensProduto.pro_descricao}</b>
                <div className="tabela mt-dif">
                    <Table columns={[
                        {
                            align: 'left',
                            title: 'Informações das Grades',
                            render: ({ ean, atributos }) => (
                                <Row gutter={[8, 0]}>
                                    <Col>
                                        <Tag color="processing">
                                            <b>{ean}</b>
                                        </Tag>
                                    </Col>
                                    {atributos.map(atrib => (
                                        <Col>
                                            <b>{atrib.mgp_descricao}</b>
                                        </Col>
                                    ))}
                                </Row>
                            ),
                        }, {
                            title: 'Preço (R$)',
                            align: 'right',
                            render: ({ precoVenda }) => (
                                <div>
                                    <b>{FormatNumber(precoVenda, true)}</b>
                                </div>
                            ),
                        }, {
                            title: 'Estoque',
                            align: 'center',
                            render: ({ qtdEstoque }) => (
                                <div>
                                    <b>{qtdEstoque}</b>
                                </div>
                            )
                        }, {
                            title: 'Pedido',
                            align: 'center',
                            render: ({ qtdPedido }) => (
                                <div>
                                    <b>{qtdPedido}</b>
                                </div>
                            )
                        }, {
                            title: 'Condicional',
                            align: 'center',
                            render: ({ qtdCondicional }) => (
                                <div>
                                    <b>{qtdCondicional}</b>
                                </div>
                            )
                        }, {
                            title: 'Previsão',
                            align: 'center',
                            render: ({ qtdPrevisao }) => (
                                <div>
                                    <b>
                                        {qtdPrevisao}
                                    </b>
                                </div>
                            )
                        }
                    ]} dataSource={itensGrade} />
                </div>
            </div>
        </Modal>
    );
}