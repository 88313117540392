import React from "react";
import { Modal } from "antd";
import DetalheMdfe from "../../detalheMdfe";

export default function ModalDetalhesMdfe({ exibirModal, fecharModal, dadosModal }) {
    return (
        <div>
            <Modal centered
                title="Detalhes MDF-e"
                visible={exibirModal}
                onCancel={fecharModal}
                width='70%'
                footer={null}
                maskClosable={false}
            >
                <DetalheMdfe dadosModal={dadosModal} />
            </Modal>
        </div>
    )
}