import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Divider, Button, notification, Table, Form, Modal } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { useStateValue } from "../../state";
import { listagemActions } from "../../actions";
import { ModalNcm } from "../../components/modals/";

export default function TabDadosOrigemDestino({ formulario }) {

    const [formModal] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();
    const [openModal, setOpenModal] = useState(false);
    const [listaNcm, setListaNcm] = useState([]);
    const [dadosNcm, setDadosNcm] = useState(null);

    const modalExcluir = (item, index) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                removerRegistro(item, index);
            }
        });
    };

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.mvaorigemdestinos) {
            setListaNcm(manutencao.dados.mvaorigemdestinos);
        }
    }, [manutencao.dados])

    function fecharModal() {
        setOpenModal(false);
        if (dadosNcm.mvaorigemdestinos) {
            let listTemp = [...dadosNcm.mvaorigemdestinos];
            setListaNcm(listTemp);
        }
    }

    function removerRegistro(item, index) {
        let listTemp = [...manutencao.dados.mvaorigemdestinos];
        if (index !== -1) {
            if (index > -1) {
                listTemp.splice(index, 1)
            }
            setListaNcm(listTemp);
            manutencao.dados.mvaorigemdestinos = listTemp
        }
    }
    function editarRegistro(item, index) {
        setOpenModal(true);
        setDadosNcm(manutencao.dados);
        formModal.setFieldsValue(item);
    }

    return (
        <div className="pages-col">
            <div>
                <Row align="middle">
                    <Col span={24}>
                        <Typography.Title level={3}>
                            <img src={require("../../assets/i-dados.png").default} alt="Dados de Origem e Destino (OPCIONAL)" /> Dados de Origem e Destino (OPCIONAL)
                        </Typography.Title>
                        <Divider orientation="left" plain>
                            Defina as Informações de Origem e Destino referente a Substituição Tributário.
                        </Divider>
                    </Col>
                </Row>
                <Row align="middle" justify="end">
                    <Col>
                        <div className="i-Position p-relative">
                            <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => {
                                setOpenModal(true)
                                setDadosNcm(manutencao.dados);
                            }
                            } />
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="tabela mt-dif">
                <Table dataSource={listaNcm} columns={
                    [
                        {
                            title: 'UF',
                            align: 'center',
                            children: [
                                {
                                    title: 'Origem',
                                    align: 'center',
                                    width: 80,
                                    render: ({ mva_uforigem }) => (
                                        <div>
                                            <b>{mva_uforigem}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Destino',
                                    align: 'center',
                                    width: 80,
                                    render: ({ mva_ufdestino }) => (
                                        <div>
                                            <b>{mva_ufdestino}</b>
                                        </div>
                                    ),
                                },
                            ],
                        },
                        {
                            title: 'Redução da Base de Cálculo',
                            align: 'center',
                            render: ({ mva_percaliquotaicmsreducao }) => (
                                <div>
                                    <b>{mva_percaliquotaicmsreducao}%</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Alíquota ICMS ST',
                            align: 'center',
                            render: ({ mva_percaliquotaicms }) => (
                                <div>
                                    <b>{mva_percaliquotaicms}%</b>
                                </div>
                            ),
                        },
                        {
                            title: 'MVA',
                            align: 'center',
                            width: 100,
                            render: ({ mva_percmva }) => (
                                <div>
                                    <b>{mva_percmva}%</b>
                                </div>
                            ),
                        },
                        {
                            title: 'MVA Ajustado',
                            align: 'center',
                            render: ({ mva_ajustado }) => (
                                <div>
                                    <b>{mva_ajustado}%</b>
                                </div>
                            ),
                        },
                        {
                            title: ' MVA Interestadual',
                            align: 'center',
                            render: ({ mva_simplesnacional }) => (
                                <div>
                                    <b>{mva_simplesnacional}%</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            align: 'center',
                            fixed: 'right',
                            key: 'x',
                            width: 75,
                            render: (item, obj, indice) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => editarRegistro(item, indice)} />
                                        </Col>
                                        <Col>
                                            <Button icon={<DeleteOutlined />} onClick={() => modalExcluir(item, indice)} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } />
            </div>
            <ModalNcm exibirModal={openModal} fecharModal={fecharModal} dados={dadosNcm} setDados={setDadosNcm} formularioDados={formModal} />
        </div>
    );
}