const TiposElementosFormulario = [
    { tipo: 'input', descricao: 'Input de Texto' },
    { tipo: 'textarea', descricao: 'Input de Texto Multi-linhas' },
    { tipo: 'number', descricao: 'Input Numérico' },
    { tipo: 'password', descricao: 'Input de Senha' },
    { tipo: 'radio-group', descricao: 'Botão de Opção' },
    { tipo: 'checkbox', descricao: 'Caixa de Seleção' },
    { tipo: 'checkbox-group', descricao: 'Caixas de Multipla Seleção' },
    { tipo: 'switch', descricao: 'Interuptor' },
    { tipo: 'select', descricao: 'Lista de Seleção' },
    { tipo: 'slider', descricao: 'Slider' },
    { tipo: 'rate', descricao: 'Avaliação' },
    { tipo: 'date-picker', descricao: 'Seleção de Data' },
    { tipo: 'time-picker', descricao: 'Seleção de Hora' },
    { tipo: 'upload', descricao: 'Envio de arquivo(s)' }
];
    
export default TiposElementosFormulario;