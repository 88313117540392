import React, { useState } from 'react';
import { Row, Col, Button, Form, Modal, Drawer } from "antd";
import { CloseOutlined, ExclamationCircleOutlined, MinusOutlined, SaveOutlined } from '@ant-design/icons';

import FecharPedidoCatalogo from '../../pages/fecharPedidoCatalogo';


export default function DrawerPedidoCat({ openDrawer, setOpenDrawer }) {
    const [proximaPag, setProximaPag] = useState(0);
    const [formulario] = Form.useForm();
    const [carregando, setCarregando] = useState(false);
    const [pages, setPages] = useState(0);

    function onClose() {
        setOpenDrawer(false);
    }

    return (
        <Drawer width="70%"
            title="Finalização Pedido"
            visible={openDrawer}
            closable={false}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => { formulario.submit() }} loading={carregando} icon={<SaveOutlined />} size="large" type="primary">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <FecharPedidoCatalogo formulario={formulario} proximaPag={{ proximaPag, setProximaPag }} carregando={setCarregando} pages={{ pages, setPages }} />
        </Drawer>
    );
}
