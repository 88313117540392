import React from "react";
import { Row, Col, Form, Input, Checkbox, notification, Modal } from "antd";
import { ExclamationCircleOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";

export default function ModalEmail({ form, listaEmail, exibirModalEmail, fecharModalEmail }) {

    const onFinish = values => {
        if (!validarEmail(values.pem_email)) {
            notification.info({ message: 'Favor informar um e-mail válido!' })
        } else {
            let indice = values.indice;
            delete values.indice;
            let lista = [...listaEmail.dadosEmail];
            if (indice >= 0) {
                lista[indice] = values;
            } else {
                let verificaEmailAdd = lista.filter(e => e.pem_email === form.getFieldValue().pem_email);
                if (verificaEmailAdd.length > 0) {
                    fecharModalEmail();
                    notification.info({ message: 'E-mail já adicionado!' });
                } else {
                    if (lista.length > 0) {
                        values.pem_emailprincipal = false;
                    } else {
                        values.pem_emailprincipal = true;
                    }
                    lista.push(values);
                }
            }
            listaEmail.setDadosEmail(lista);
            fecharModalEmail();
        }
    };

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalEmail();
            }
        });
    };

    function validarEmail(email) {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return reg.test(email);
    };

    return (
        <Modal centered
            title="Cadastro de E-mails"
            visible={exibirModalEmail}
            onCancel={() => {
                form.resetFields();
                onCloseModal();
            }}
            onOk={() => form.submit()}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }   
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="manutencaoEmail" form={form} onFinish={onFinish}>
                    <Form.Item name="indice" hidden />
                    <Form.Item name="pem_id" hidden />
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={17} lg={17} xl={17}>
                            <Form.Item label="Informe o E-mail" name="pem_email" rules={[{ required: true, message: 'Informe o e-mail.' }]}>
                                <Input placeholder="Informe o e-mail" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={10} md={7} lg={7} xl={7}>
                            <Form.Item name="pem_ativo" valuePropName="checked" className="t-mob">
                                <Checkbox>E-mail Ativo?</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                            <Form.Item initialValue={false} name="pem_emailprincipal" valuePropName="checked">
                                <Checkbox>E-mail Principal?</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                            <Form.Item initialValue={false} name="pem_enviarnfe" valuePropName="checked">
                                <Checkbox>Enviar NF-e?</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                            <Form.Item initialValue={false} name="pem_enviarcartacobranca" valuePropName="checked">
                                <Checkbox>Enviar Carta de Cobrança?</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                            <Form.Item initialValue={false} name="pem_enviarpromocao" valuePropName="checked">
                                <Checkbox>Enviar Promoção?</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={7} md={7} lg={7} xl={7}>
                            <Form.Item initialValue={false} name="pem_enviarboleto" valuePropName="checked">
                                <Checkbox>Enviar Boleto?</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                            <Form.Item initialValue={false} name="pem_enviarvencparcela" valuePropName="checked">
                                <Checkbox>Enviar Vencimento Parcela?</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}