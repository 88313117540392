import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Select, Modal, notification } from "antd";
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';

import Data from '../../data';
import api from '../../../services/api';
import { useStateValue } from '../../../state';
import SelectPaginado from "../../../components/selectPaginado";

export default function ModalGerarComissao({ exibirModal, fecharModal, dadosModal }) {
    
    const [formGerarComissao] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();
    

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
            }
        });
    };

    useEffect(() => {
        if (!!dadosModal.dataInicial) {
            formGerarComissao.setFieldsValue({ dataInicial: dadosModal.dataInicial });
        }
        if (!!dadosModal.dataFinal) {
            formGerarComissao.setFieldsValue({ dataFinal: dadosModal.dataFinal });
        }
    }, [dadosModal]);


    function salvarDados() {
        if (!(!!formGerarComissao.getFieldValue().dataInicial)) {
            notification.warning({ message: 'Data inicial inválida', description: "Favor informar uma data inicial válida!" });
        } else if (!(!!formGerarComissao.getFieldValue().dataFinal)) {
            notification.warning({ message: 'Data final inválida', description: "Favor informar uma data final válida!" });
        } else if (!(!!formGerarComissao.getFieldValue().ven_id)) {
            notification.warning({ message: 'Vendedor selecionado inválido', description: "Favor informar uma vendedor válido!" });
        } else {
            api.get(`Comissao/RecalcularComissao?dataFinal=${formGerarComissao.getFieldValue().dataFinal.format('YYYY-MM-DD')}&dataInicial=${formGerarComissao.getFieldValue().dataInicial.format('YYYY-MM-DD')}&idVendedor=${formGerarComissao.getFieldValue().ven_id}`).then(
                (res) => {
                    if (res.status === 200) {
                        if (res.data) {
                            Modal.success({
                                content: 'Comissão gerada com sucesso!',
                            });
                        }else{
                            Modal.warning({
                                content: 'Não foi possível gerar a comissão!',
                            });
                        }
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            ).finally(
                () => {
                    fecharModal();
                }
            );
        }
    };

    return (
        <Modal centered
            title="Gerar Comissão"
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={salvarDados}
            okText={
                <>
                    <CheckOutlined /> Gerar Comissão
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }   
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" form={formGerarComissao} name="formGerarComissao" initialValues={{ remember: true }}>
                    <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <Form.Item label="Vendedores" name="ven_id">
                                <SelectPaginado url="Vendedor/ListarVendedor" placeholder="Selecione os vendedores" multiple={false} form={formGerarComissao} name="ven_id" conteudo={
                                    ven => (<Select.Option value={ven.ven_id} key={ven.ven_id}>{ven.ven_id} - {ven.pes_nome}</Select.Option>)
                                }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                        <Col span={12}>
                            <Data label="Data Inicial" name="dataInicial"/>
                        </Col>
                        <Col span={12}>
                            <Data label="Data Final" name="dataFinal"/>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}