import React from "react";
import { Row, Col, Descriptions, Form, InputNumber, Tooltip, Checkbox, Tag, Modal } from "antd";
import { QuestionCircleOutlined, ExclamationCircleOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";

import { TabelaDados } from "../../../components";

export default function ModalGradeOrdemCompra({exibirModalGradeOrdemCompra, fecharModalGradeOrdemCompra}) {

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalGradeOrdemCompra();
            }
        });
    };

    return (
        <Modal centered
            title="Grade Produtos"
            visible={exibirModalGradeOrdemCompra}
            onCancel={onCloseModal}
            onOk={onCloseModal}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }   
            width={720}
            maskClosable={false}
        >
            <div>
                <div>
                    <Descriptions column={1} size="small">
                        <Descriptions.Item label="Produto/Mercadoria"> 
                            <b> 000 </b> - <b> descricao </b>
                        </Descriptions.Item>
                    </Descriptions>
                </div>
                <Form layout="vertical">
                    <Row align="middle" gutter={[8,8]}>
                        <Col span={24}>
                            <Form.Item>
                                <Checkbox>
                                    Definir Valor Para todos os Itens da Grade 
                                </Checkbox>
                                <Tooltip title="Se ativado será possível definir um valor padrão para todos os itens da grade, caso desativado irá utilizar como padrão o custo da última compra do produto!">
                                    <QuestionCircleOutlined />
                                </Tooltip> 
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Valor Padrão" name="orc_nropedido">
                                <InputNumber
                                    step={1.0}
                                    min={0}
                                    formatter={value => `R$ ${value}`}
                                    parser={value => value.replace('R$ ', '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className="tabela">
                    <TabelaDados url="" colunas={
                        [
                            { 
                                title: '', 
                                render: ({ean, mgp_descricao}) => (
                                    <div>
                                        <Row align="middle" justify="space-between" gutter={[8,0]}>
                                            <Col>
                                                <Tag color="processing">
                                                    <b> {ean} </b>
                                                </Tag>
                                            </Col>
                                            <Col>
                                                <b>{mgp_descricao}</b> 
                                            </Col>
                                            <Col>
                                                <Form.Item>
                                                    <InputNumber
                                                        step={1.0}
                                                        min={0}
                                                        formatter={value => `R$ ${value}`}
                                                        parser={value => value.replace('R$ ', '')}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col>
                                                <Form.Item>
                                                    <InputNumber />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]
                    } />   
                </div>
            </div>
        </Modal>
    );
}