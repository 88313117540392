import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col, Button, Drawer, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";

import DevolucaoCondicional from './devolucao';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';
import { useStateValue } from '../../state';
import api from '../../services/api';


export default function DrawerDevolucao({ exibirDrawerDevolucao, dataPesquisa,setExibirDrawerNota }) {

    const [formManutencao] = Form.useForm()
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);
    const [dadosItens, setDadosItens] = useState([]);

    if (!!manutencao.dados) {
        manutencao.dados.vnc_datalancamento = moment(manutencao.dados.vnc_datalancamento);
        manutencao.dados.vnc_prazoretorno = moment(manutencao.dados.vnc_prazoretorno);
        formManutencao.setFieldsValue(manutencao.dados);
        dadosItens.sort((a, b) => b.vci_sequencia - a.vci_sequencia);
    } else {
        // formManutencao.resetFields();
        formManutencao.setFieldsValue({ icm_ativo: true });
    }

    function fecharDrawer() {
        formManutencao.resetFields();
        setDadosItens([]);
        exibirDrawerDevolucao.setExibirDrawerDevolucao(false);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+vnc_datalancamento' } });
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer()
            }
        });
    };

    return (

        <Drawer title="Retorno Condicional"
            width="70%"
            visible={exibirDrawerDevolucao.exibirDrawerDevolucao}
            fecharDrawer={() => { fecharDrawer() }}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formManutencao.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <DevolucaoCondicional formulario={formManutencao} aoSalvar={fecharDrawer} dataPesquisa={dataPesquisa} dadosItens={{ dadosItens, setDadosItens }} exibirDrawerDevolucao={exibirDrawerDevolucao} setCarregando={setCarregando} setExibirDrawerNota={setExibirDrawerNota}/>

        </Drawer>

    )
}