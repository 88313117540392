import React from "react";
import { Row, Col, Form, Select, Checkbox, InputNumber } from "antd";

export default function TabPdv({ impressaoComprovantes, listaContaGerencial, listaContaGerencialAgrupador }) {

    return (
        <div>
            <Row gutter={[8, 0]}>
                <Col xxl={7} xl={6} lg={8} md={8} sm={12} xs={24}>
                    <Form.Item label="Conta Gerencial Sangria PDV" name="par_contagerencialsangriapdv">
                        <Select showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial">
                            {listaContaGerencialAgrupador.map(item => (
                                <Select.OptGroup label={item.ctg_descricao}>
                                    {listaContaGerencial.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                        <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                    ))}
                                </Select.OptGroup>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={7} xl={7} lg={8} md={8} sm={12} xs={24}>
                    <Form.Item label="Conta Gerencial Suprimento PDV" name="par_contagerencialsuprimentopdv">
                        <Select showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial">
                            {listaContaGerencialAgrupador.map(item => (
                                <Select.OptGroup label={item.ctg_descricao}>
                                    {listaContaGerencial.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                        <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                    ))}
                                </Select.OptGroup>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={10} xl={10} lg={10} md={10} sm={12} xs={24}></Col>
                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={24}>
                    <Form.Item label="Impressão de documentos NFC-e" name="par_impressaonfce">
                        <Select placeholder="Selecione uma Opção de Impressão" showSearch optionFilterProp="children" allowClear>
                            {impressaoComprovantes.map((item) => (
                                <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={2} xl={2} lg={2} md={8} sm={12} xs={24}>
                    <Form.Item label="Nº vias imprimir (NFC-e)" name="par_viasimpressaonfce">
                        <InputNumber min={0} max={100} placeholder="Informe o Nº vias imprimir (NFC-e)" />
                    </Form.Item>
                </Col>
                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={24}>
                    <Form.Item label="Impressão de documentos D.A.V" name="par_impressaodavls">
                        <Select placeholder="Selecione uma Opção de Impressão" showSearch optionFilterProp="children" allowClear>
                            {impressaoComprovantes.map((item) => (
                                <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={2} xl={2} lg={2} md={8} sm={12} xs={24}>
                    <Form.Item label="Nº vias imprimir (D.A.V)" name="par_viasimpressaodavls">
                        <InputNumber min={0} max={100} placeholder="Informe o Nº vias imprimir (DAV/LS)" />
                    </Form.Item>
                </Col>
                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={24}>
                    <Form.Item label="Impressão de Comprovantes de Condicional" name="par_impressaocondicional">
                        <Select placeholder="Selecione uma Opção de Impressão" showSearch optionFilterProp="children" allowClear>
                            {impressaoComprovantes.map((item) => (
                                <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={2} xl={2} lg={2} md={8} sm={12} xs={24}>
                    <Form.Item label="Nº vias imprimir" name="par_viasimpressaocondicional">
                        <InputNumber min={0} max={100} placeholder="Informe o Nº vias imprimir" />
                    </Form.Item>
                </Col>
                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={24}>
                    <Form.Item label="Impressão de Comprovantes de Rec. de Parcelas" name="par_impressaorecbtoparcela">
                        <Select placeholder="Selecione uma Opção de Impressão" showSearch optionFilterProp="children" allowClear>
                            {impressaoComprovantes.map((item) => (
                                <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={2} xl={2} lg={2} md={8} sm={12} xs={24}>
                    <Form.Item label="Nº vias Imprimir" name="par_viasimpressaorecbtoparcela">
                        <InputNumber min={0} max={100} placeholder="Informe o Nº vias imprimir" />
                    </Form.Item>
                </Col>
                <Col xxl={4} xl={4} lg={4} md={4} sm={12} xs={24}>
                    <Form.Item label="Impressão de Carnê" name="par_impressaocarne">
                        <Select placeholder="Selecione uma Opção de Impressão" showSearch optionFilterProp="children" allowClear>
                            {impressaoComprovantes.map((item) => (
                                <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xxl={2} xl={2} lg={2} md={8} sm={12} xs={24}>
                    <Form.Item label="Nº vias Imprimir (Carnê)" name="par_viasimpressaocarne">
                        <InputNumber min={0} max={100} placeholder="Informe o Nº vias imprimir" />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item name="par_69" valuePropName="checked" className="t-mob">
                        <Checkbox>Comandar emissão de NFC-e Manualmente?</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );

}