import React, { useState, useEffect } from "react";
import { Row, Col, Button, Divider } from "antd";
import { StopOutlined, SaveOutlined, CheckOutlined, DeleteFilled } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import { ModalDefinirHorario } from "../../components/modals";
import { DataUsage } from "@material-ui/icons";

export default function TabHorariosAcessoUsuario({ formulario }) {

    const [openModal, setOpenModal] = useState(false);
    const [idDay, setIdDay] = useState(null);
    const [listHorarios, setListHorarios] = useState(
        [{
            id: 0,
            name: 'Domingo',
            disabled: false,
            description: 'Domingo',
            day: []
        }, {
            id: 1,
            name: 'Segunda',
            disabled: false,
            description: 'Segunda-feira',
            day: []
        }, {
            id: 2,
            name: 'Terca',
            disabled: false,
            description: 'Terça-feira',
            day: []
        }, {
            id: 3,
            name: 'Quarta',
            disabled: false,
            description: 'Quarta-feira',
            day: []
        }, {
            id: 4,
            name: 'Quinta',
            disabled: false,
            description: 'Quinta-feira',
            day: []
        }, {
            id: 5,
            name: 'Sexta',
            disabled: false,
            description: 'Sexta-feira',
            day: []
        }, {
            id: 6,
            name: 'Sabado',
            disabled: false,
            description: 'Sábado',
            day: []
        },]);

    useEffect(() => {
        carregarListaHorariosAcesso();
    }, [])

    const carregarListaHorariosAcesso = async () => {
        if (!!formulario.getFieldValue('usu_id')) {
            var res = await api.get(`Usuario/ListaHorariosAcesso?codigoUsuario=${formulario.getFieldValue('usu_id')}`);
            if (res.status === 200) {
                let array = [...listHorarios];
                res.data.forEach(element => {
                    if (element.uac_dia > -1) {
                        array[element.uac_dia].disabled = element.uac_bloqueado;
                        array[element.uac_dia].day.push({
                            uac_id: element.uac_id,
                            bloqueado: element.uac_bloqueado,
                            horaInicial: element.uac_horainicial != null ? moment(element.uac_horainicial) : null,
                            horaFinal: element.uac_horafinal != null ? moment(element.uac_horafinal) : null
                        });
                    }
                });
                setListHorarios(array);
                transformList(array, false);
            }
        }
    }

    const setDataModal = (id) => {
        setIdDay(id);
        setOpenModal(true);
    }

    const onChangeHorario = (id, disabled = false, desbloquear = false, horaInicial = null, horaFinal = null,) => {

        let array = [...listHorarios];

        array[id].disabled = disabled;

        if (desbloquear) {
            array[id].day = []
        } else {
            array[id].day.push(
                {
                    horaInicial: horaInicial != null ? horaInicial : "",
                    horaFinal: horaFinal != null ? horaFinal : "",
                    bloqueado: disabled
                });
        }

        setListHorarios(array);
        transformList(array, true);
    }

    const deleteHorario = (id, key) => {
        let array = [...listHorarios];
        array[id].day.splice(key, 1);
        setListHorarios(array);
        transformList(array, true);
    }

    const transformList = (array, change) => {
        let usuarioacessos = [];

        array.forEach(e => {
            e.day.forEach(d => {
                var obj = {
                    uac_dia: e.id,
                    usu_id: formulario.getFieldValue('usu_id'),
                    uac_horainicial: new Date(d.horaInicial),
                    uac_horafinal: new Date(d.horaFinal),
                    uac_bloqueado: d.bloqueado
                };

                if (d.uac_id != null || typeof d.uac_id != 'undefined' || d.uac_id != "") obj.uac_id = d.uac_id;

                usuarioacessos.push(obj);
            })
        })
        formulario.setFieldsValue({ usuarioacessos: usuarioacessos, mudarAcesso: change });
    }

    return (
        <div className="listUsers">
            <Row justify="center" gutter={[8, 8]}>
                {listHorarios.map(({ id, description, disabled, day }, idx) => (
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} key={idx}>
                        <Row gutter={[0, 8]} align="middle" justify="center">
                            <Col span={24}>
                                <b>{description}</b>
                                <Divider />
                            </Col>
                        </Row>
                        {!disabled && <Row gutter={[0, 8]}>
                            {day.map((x, idx) => (
                                <Col span={24} key={idx}>
                                    <Row gutter={[8, 0]} align="middle" justify="center">
                                        <Col>
                                            <span>{moment(x.horaInicial).format("HH:mm")} até {moment(x.horaFinal).format("HH:mm")}</span>
                                        </Col>
                                        <Col>
                                            <Button
                                                icon={<DeleteFilled />}
                                                type="link"
                                                onClick={() => deleteHorario(id, idx)} />
                                        </Col>
                                    </Row>
                                </Col>
                            ))}
                            <Col span={24}>
                                <Button icon={<SaveOutlined />} type="primary" block onClick={() => setDataModal(id)}>
                                    Adicionar
                                </Button>
                            </Col>
                            <Col span={24}>
                                <Button icon={<StopOutlined />} type="primary" danger block onClick={() => onChangeHorario(id, true)}>
                                    Bloquear
                                </Button>
                            </Col>
                        </Row>}
                        {disabled && <Row gutter={[0, 8]}>
                            <Col span={24} align="center">
                                <h3>Bloqueado</h3>
                            </Col>
                            <Col span={24}>
                                <Button icon={<CheckOutlined />} block onClick={() => onChangeHorario(id, false, true)}>
                                    Desbloquear
                                </Button>
                            </Col>
                        </Row>}
                    </Col>
                ))}
            </Row>
            <ModalDefinirHorario id={idDay} onSave={onChangeHorario} exibirModal={openModal} fecharModal={() => setOpenModal(false)} />
        </div>
    );

}