import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, Button, Collapse, Tabs, Modal } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, SearchOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import moment from 'moment';
import api from "../../services/api";
import DrawerCondicional from './drawer';
import { useStateValue } from '../../state';
import { listagemActions } from "../../actions";
import { FormatDate } from "../../ValueObjects";
import { TabCondicionalEntrega, TabCondicionalRetirada } from "./tabPage";
import { BreadcrumbIncluirDados, Data, FormGW, PesquisaAvancada, PesquisaOrdenacaoData, SelectProduto } from "../../components";
import { getLocal, getNomeUsuario } from "../../services/auth";
import { imprimirRelatorioJasper, novaAbaNavegador } from "../../services/funcoes";

export default function Condicional() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [dataAtual] = useState(new Date());
    const [open, setOpen] = useState(false);
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [situacaoVenda, setSituacaoVenda] = useState([]);
    const [produtoFiltro, setProdutoFiltro] = useState([]);
    const [formParametrosAvancados] = Form.useForm();

    const togglePesquisa = () => {
        setOpen(!open);
    };

    useEffect(() => {
        carregaDados();
        formParametrosAvancados.setFieldsValue({ situacao: [0, 1] });
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+vnc_datalancamento' } });
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&dataFinal=${FormatDate(dataAtual.toLocaleDateString())}&dataInicial=${moment(formParametrosAvancados.getFieldValue().dataPesquisa).format('YYYY-MM-DD')}` } });
        formParametrosAvancados.setFieldsValue({ dataFinal: moment(dataAtual) });
        formParametrosAvancados.setFieldsValue({ dataInicial: moment(formParametrosAvancados.getFieldValue().dataPesquisa) });
    }, []);

    function carregaDados() {
        api.get(`Enum/Listar?nome=SituacaoVendaCondicional`).then(
            (res) => {
                if (res.status === 200) {
                    setSituacaoVenda(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );

        api.get(`Produto/ListaProdutoFiltro?ordem=%2Bpro_descricao`).then(
            (res) => {
                if (res.status === 200) {
                    let sequencial = 0;
                    res.data.items.forEach((dados) => {
                        dados.indice = sequencial;
                        sequencial++;
                    });
                    setProdutoFiltro(res.data.items);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );

    };

    function aplicarFiltrosAvancados() {
        let filtros = '';
        if (!!formParametrosAvancados.getFieldValue().filtroPesquisa) {
            filtros += `&filtro=${formParametrosAvancados.getFieldValue().filtroPesquisa}`;
        }
        if (!!formParametrosAvancados.getFieldValue().dataFinal) {
            filtros += `&dataFinal=${moment(formParametrosAvancados.getFieldValue().dataFinal).format('YYYY-MM-DD')}`;
        }
        if (!!formParametrosAvancados.getFieldValue().dataInicial) {

            filtros += `&dataInicial=${moment(formParametrosAvancados.getFieldValue().dataInicial).format('YYYY-MM-DD')}`;
        }
        if (!!formParametrosAvancados.getFieldValue().dataRetorno) {

            filtros += `&dataRetorno=${moment(formParametrosAvancados.getFieldValue().dataRetorno).format('YYYY-MM-DD')}`;
        }
        if (!!formParametrosAvancados.getFieldValue().produto) {

            filtros += `&produtoId=${formParametrosAvancados.getFieldValue().produto}`;
        }
        if (!!formParametrosAvancados.getFieldValue().situacao) {

            filtros += `&situacao=${formParametrosAvancados.getFieldValue().situacao}`;
        }
        if (!!formParametrosAvancados.getFieldValue().vendedor) {

            filtros += `&vendedorId=${formParametrosAvancados.getFieldValue().vendedor}`;
        }
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: filtros } });
    };

    function removerFiltros() {
        formParametrosAvancados.resetFields();
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&dataFinal=${moment(dataAtual).format('YYYY-MM-DD')}&dataInicial=${moment(formParametrosAvancados.getFieldValue().dataPesquisa).format('YYYY-MM-DD')}` } });
        formParametrosAvancados.setFieldsValue({ situacao: [0, 1] });
    };

    function imprimirCondicional(registro) {
        Modal.confirm({
            title: 'Deseja imprimir em impressora não fiscal?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                novaAbaNavegador(`VendaCondicional/Imprimir/${registro.vnc_id}`);
            },
            onCancel() {
                imprimirRelatorioJasper(34, { idVendaCondicional: registro.vnc_id });
            }
        });
    };

    function retornoPesquisaAvancada(expressao) {
        let dados = formParametrosAvancados.getFieldValue();
        let dataInicial = dados.dataInicial;
        let dataFinal = dados.dataFinal;
        let situacao = dados.situacao;
        let filtros = "";

        if (!!dataFinal) {
            filtros += `&dataFinal=${moment(dataFinal).format('YYYY-MM-DD')}`;
        }
        if (!!dataInicial) {
            filtros += `&dataInicial=${moment(dataInicial).format('YYYY-MM-DD')}`;
        }
        if (!!situacao) {
            filtros += `&SituacaoCondicional=${situacao}`;
        }
        if (!!expressao) {
            filtros += `&filtroAdicional=${btoa(expressao)}`
        }

        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `${filtros}`, ordem: '+vnc_datalancamento' } })
    };

    function limparFiltroPesquisa() {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: ``, ordem: '+vnc_datalancamento' } })
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={18} xl={19} xxl={20}>
                    <PesquisaOrdenacaoData opcoesOrdenacao={[
                        { label: "Data Inclusão Crescente", value: "+vnc_datalancamento" },
                        { label: "Data Inclusão Decrescente", value: "-vnc_datalancamento" },
                        { label: "Nome do Cliente A - Z", value: "+cli_nome" },
                        { label: "Nome do Cliente Z - A", value: "-cli_nome" },
                        { label: "Nome do Vendedor A - Z", value: "+ven_nome" },
                        { label: "Nome do Vendedor Z - A", value: "-ven_nome" }
                    ]} form={formParametrosAvancados} eventoAoAlterar={() => {
                        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&dataFinal=${FormatDate(dataAtual.toLocaleDateString())}&dataInicial=${moment(formParametrosAvancados.getFieldValue().dataPesquisa).format('YYYY-MM-DD')}` } });
                        formParametrosAvancados.setFieldsValue({ dataFinal: moment(dataAtual) });
                        formParametrosAvancados.setFieldsValue({ dataInicial: moment(formParametrosAvancados.getFieldValue().dataPesquisa) });
                    }} />
                </Col>
            </Row>
            <div className="page">
                <FormGW layout="vertical" name="formParametrosAvancados" form={formParametrosAvancados}>
                    <Row align="middle" gutter={[0, 24]}>
                        <Col span={24}>
                            <Collapse className="p-relative topGrade" accordion ghost destroyInactivePanel={true}>
                                <div className="colDet"></div>
                                <Collapse.Panel key="1" showArrow={false} extra={
                                    <Button type="primary" onClick={togglePesquisa} block>
                                        {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                    </Button>}>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col xs={24} sm={12} md={5} lg={4} xl={3}>
                                            <Data label="Data Inicial" name="dataInicial" />
                                        </Col>
                                        <Col xs={24} sm={12} md={5} lg={4} xl={3}>
                                            <Data label="Data Final" name="dataFinal" />
                                        </Col>
                                        <Col xs={24} sm={24} md={14} lg={16} xl={16}>
                                            <Form.Item label="Situação da Condicional" name="situacao">
                                                <Select
                                                    mode="multiple"
                                                    allowClear
                                                    showSearch
                                                    placeholder="Situação">
                                                    {situacaoVenda.map(situacao => (
                                                        <Select.Option key={situacao.key} value={situacao.key}>{situacao.value}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <PesquisaAvancada funcaoRetorno={(dados) => retornoPesquisaAvancada(dados)} funcaoLimpar={() => { limparFiltroPesquisa() }} form={formParametrosAvancados} telaFiltro={8} retornarSempre={true} />
                                </Collapse.Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </FormGW>
            </div>
            <Row align="middle" gutter={[8, 8]} className="m-t-16">
                <Col span={24}>
                    <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                        <Tabs.TabPane tab="Condicional Retirada na Loja" key="1">
                            <TabCondicionalRetirada situacaoVenda={situacaoVenda} dataPesquisa={formParametrosAvancados.getFieldValue().dataPesquisa} imprimirCondicional={imprimirCondicional} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Condicional para Entrega" key="2">
                            <TabCondicionalEntrega situacaoVenda={situacaoVenda} dataPesquisa={formParametrosAvancados.getFieldValue().dataPesquisa} imprimirCondicional={imprimirCondicional} />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
            <DrawerCondicional imprimirCondicional={imprimirCondicional} />
        </div>
    );

}