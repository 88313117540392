import React, { useState, useEffect } from "react";
import moment from 'moment';
import { Row, Col, Form, Select, Button, Dropdown, Menu } from "antd";
import { MoreOutlined, CheckOutlined, FileDoneOutlined, SyncOutlined, BankOutlined, SendOutlined, UserSwitchOutlined, TeamOutlined } from "@ant-design/icons";

import DrawerCheque from './drawer';
import api from "../../services/api";
import { useStateValue } from '../../state';
import { linkApi } from "../../services/auth";
import { listagemActions } from "../../actions";
import { FormatNumber } from "../../ValueObjects";
import { ModalOcorrenciaCheque } from "../../components/modals/";
import { BreadcrumbIncluirDados, FiltroSearch, FiltroOrdenacao, TabelaDados } from "../../components";

export default function Cheque() {

    const [{ manutencao }, dispatch] = useStateValue();

    const [openModalOcorrencia, setOpenModalOcorrencia] = useState(false);
    const [statusCheque, setStatusCheque] = useState([]);
    const [dadosCheque, setDadosCheque] = useState({});
    const [ocorrencia, setOcorrencia] = useState(null);

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-chq_dataemissao' } });

        api.get(`Enum/Listar?nome=StatusCheque`).then(
            (res) => {
                if (res.status === 200) {
                    setStatusCheque(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    }, []);

    function menuTable(dados) {
        return (
            <Menu className="text-right">
                {dados.chq_status !== 0 &&
                    < Menu.Item key="1">
                        <Button type="text" icon={<SyncOutlined />} onClick={() => {
                            setOpenModalOcorrencia(true);
                            setOcorrencia(0);
                            setDadosCheque(dados);;
                        }}>
                            Ver Movimentação
                        </Button>
                    </Menu.Item>
                }
                {dados.chq_status === 0 &&
                    <Menu.Item key="2">
                        <Button type="text" icon={<SendOutlined />} onClick={() => {
                            setOpenModalOcorrencia(true);
                            setOcorrencia(1);
                            setDadosCheque(dados);
                        }}>
                            Enviar para Depósito
                        </Button>
                    </Menu.Item>
                }
                {(dados.chq_status === 1 || dados.chq_status === 7) &&
                    <Menu.Item key="3">
                        <Button type="text" icon={<FileDoneOutlined />} onClick={() => {
                            setOpenModalOcorrencia(true);
                            setOcorrencia(6);
                            setDadosCheque(dados);
                        }}>
                            Registrar Devolução
                        </Button>
                    </Menu.Item>
                }
                {(dados.chq_status === 1 || dados.chq_status === 7) &&
                    <Menu.Item key="4">
                        <Button type="text" icon={<CheckOutlined />} onClick={() => {
                            setOpenModalOcorrencia(true);
                            setOcorrencia(3);
                            setDadosCheque(dados);
                        }}>
                            Marcar como Compensado
                        </Button>
                    </Menu.Item>
                }
                {(dados.chq_status === 6) &&
                    <Menu.Item key="5">
                        <Button type="text" icon={<BankOutlined />} onClick={() => {
                            setOpenModalOcorrencia(true);
                            setOcorrencia(2);
                            setDadosCheque(dados);
                        }}>
                            Reapresentar ao Banco
                        </Button>
                    </Menu.Item>
                }
                {(dados.chq_status === 0 || dados.chq_status === 6) &&
                    <Menu.Item key="6">
                        <Button type="text" icon={<SendOutlined />} onClick={() => {
                            setOpenModalOcorrencia(true);
                            setOcorrencia(7);
                            setDadosCheque(dados);
                        }}>
                            Enviar para Custódia
                        </Button>
                    </Menu.Item>
                }
                {(dados.chq_status === 0 || dados.chq_status === 6) &&
                    <Menu.Item key="7">
                        <Button type="text" icon={<UserSwitchOutlined />} onClick={() => {
                            setOpenModalOcorrencia(true);
                            setOcorrencia(5);
                            setDadosCheque(dados);
                        }}>
                            Trocar com o Cliente
                        </Button>
                    </Menu.Item>
                }
                {(dados.chq_status === 0) &&
                    <Menu.Item key="8">
                        <Button type="text" icon={<TeamOutlined />} onClick={() => {
                            setOpenModalOcorrencia(true);
                            setOcorrencia(4);
                            setDadosCheque(dados);
                        }}>
                            Repassar à Terceiros
                        </Button>
                    </Menu.Item>
                }
            </Menu >
        )
    };

    function filtrarStatusCheque(status) {
        if (typeof status != 'undefined') dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&status=${status}` } });
        else dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '' } });
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Form layout="vertical">
                <Row align="middle" gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={13} lg={13} xl={13}>
                        <FiltroSearch />
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                        <Form.Item label="Status do Cheque">
                            <Select onChange={(value) => { filtrarStatusCheque(value) }} allowClear={true} showSearch placeholder="Selecione um Status de Cheque" optionFilterProp="children">
                                {statusCheque?.map(cheque => (
                                    <Select.Option key={cheque.key} value={cheque.key}>{cheque.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={5} lg={5} xl={5}>
                        <FiltroOrdenacao opcoesOrdenacao={[
                            { label: "Código Crescente", value: "%2Bchq_id" },
                            { label: "Código Decrescente", value: "-chq_id" },
                            { label: "Banco A - Z", value: "%2Bban_descricao" },
                            { label: "Banco Z - A", value: "-ban_descricao" },
                            { label: "Data de Emissão Crescente", value: "%2Bchq_dataemissao" },
                            { label: "Data de Emissão Decrescente", value: "-chq_dataemissao" }
                        ]} />
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="cheque/listar" colunas={[
                    {
                        title: 'Nº Cheque',
                        align: 'center',
                        width: 105,
                        render: ({ chq_numero }) => (
                            <div>
                                <b>{chq_numero}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Banco',
                        render: ({ ban_id, ban_descricao, chq_agencia, chq_contacorrente, banco }) => (
                            <div>
                                <Row align="middle">
                                    <Col>
                                        <img className="imgLogoListaBanco" src={`${linkApi}Banco/RetornaLogo/${ban_id}`} alt={banco.ban_descricao} />  <b>{ban_id} - {banco.ban_descricao}</b>
                                    </Col>
                                </Row>
                                <Row align="middle" gutter={[8, 0]}>
                                    <Col>
                                        Agência: <b>{chq_agencia}</b>
                                    </Col>
                                    <Col>
                                        Conta: <b>{chq_contacorrente}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    }, {
                        render: ({ chq_status, pessoa }) => (
                            <div>
                                <Row justify="start">
                                    <Col>
                                        Cheque Recebido de: <b>{pessoa.pes_nome}</b>
                                    </Col>
                                </Row>
                                {statusCheque.length > 0 &&
                                    <Row justify="start" gutter={[5, 0]}>
                                        <Col>
                                            Situação: <b>{statusCheque[chq_status].value}</b>
                                        </Col>
                                    </Row>
                                }
                            </div>
                        ),
                    }, {
                        title: 'Data',
                        width: 150,
                        render: ({ chq_dataemissao, chq_bompara }) => (
                            <div>
                                <Row align="middle">
                                    <Col span={24}>
                                        Emitido em: <b>{moment(chq_dataemissao).format('DD/MM/YYYY')}</b>
                                    </Col>
                                </Row>
                                {!!chq_bompara &&
                                    <Row align="middle">
                                        <Col span={24}>
                                            Bom para: <b>{moment(chq_bompara).format('DD/MM/YYYY')}</b>
                                        </Col>
                                    </Row>
                                }
                            </div>
                        ),
                    },
                    {
                        title: 'Valor (R$)',
                        align: 'right',
                        width: 180,
                        render: ({ chq_valor }) => (
                            <div>
                               <b className="f-18">{FormatNumber(chq_valor, true, false)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 65,
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerCheque />
            <ModalOcorrenciaCheque exibirModal={openModalOcorrencia} fecharModal={() => {setOpenModalOcorrencia(false); dispatch({ type: listagemActions.CHANGE, data: { ordem: '-chq_dataemissao' } });}} dadosCheque={dadosCheque} statusCheque={statusCheque} ocorrencia={ocorrencia} />
        </div>
    );

}