import React, { useEffect, useState } from "react";
import { Row, Col, Form, Card, Typography, Divider, Tabs, Select, Button, Radio } from "antd";
import { EditOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { FormatFone } from "../../ValueObjects";
import { TabEstatistica, TabUltimasCompras } from "./tabPage";
import { BreadcrumbAbrirChamado, Data, SelectPaginacao } from "../../components";
import { formatarCnpj, formatarCpf } from "../../services/funcoes";

export default function ResumoCliente() {

    const [openCliente, setOpenCliente] = useState(false);
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [{ manutencao }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [dadosCliente, setDadosCliente] = useState({});
    const [telefone, setTelefone] = useState({});
    const [email, setEmail] = useState({});
    const [endereco, setEndereco] = useState({});
    const [dataInicial, setDataIncial] = useState(new Date());
    const [dataFinal, setDataFinal] = useState(new Date());
    const [clienteId, setClienteId] = useState(null);

    const exibirOutrosClientes = () => {
        setOpenCliente(!openCliente);
    };

    useEffect(() => {
        if (!!manutencao.dados) {
            setClienteId(manutencao.dados.cli_id);
        }
    }, [manutencao.dados]);

    useEffect(() => {
        if (!!!manutencao.dados && !!localStorage.getItem('@GestorWeb-clienteResumo')) {
            setClienteId(localStorage.getItem('@GestorWeb-clienteResumo'));
        }
    }, []);

    useEffect(() => {
        carregarDatas();
        if (!!clienteId) {
            localStorage.setItem('@GestorWeb-clienteResumo', clienteId);
            listarCliente();
        }
    }, [clienteId]);

    function carregarDatas() {
        let data = new Date();
        let periodo = form.getFieldValue().periodoData;

        form.setFieldsValue({ dataFinal: moment(data) });
        setDataFinal(moment(data));
        switch (periodo) {
            case 1:
                data.setMonth(data.getMonth() - 12);
                break;
            case 2:
                data.setMonth(data.getMonth() - 6);
                break;
            case 3:
                data.setDate(data.getDate() - 30);
                break;
            default:
                data.setDate(data.getDate() - 30);
                break;
        }
        form.setFieldsValue({ dataInicial: moment(data) });
        setDataIncial(moment(data));
    };

    function listarCliente() {
        api.get(`Cliente/BuscarCliente/${clienteId}`).then(
            res => {
                setDadosCliente(res.data);
                setTelefone(res.data.telefones.filter(telefone => telefone.psc_principal)[0]);
                setEmail(res.data.emails.filter(email => email.pem_emailprincipal)[0]);
                setEndereco(res.data.enderecos.length > 0 ? res.data.enderecos.filter(endereco => endereco.pee_enderecoprincipal)[0] : res.data.enderecos[0]);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function alterarCliente() {
        let cli_id = form.getFieldValue().cli_id;
        setClienteId(cli_id);
        exibirOutrosClientes();
        form.setFieldsValue({ cli_id: null });
    };

    return (
        <div className="p-10 pages-col">
            <BreadcrumbAbrirChamado />
            <Form layout="vertical" form={form} name="formResumoCliente">
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={15} md={16} lg={16} xl={18} xxl={20}>
                        <Form.Item label="Período" name="periodoData" initialValue={3}>
                            <Radio.Group onChange={() => { carregarDatas() }}>
                                <Radio value={1}>1 Ano</Radio>
                                <Radio value={2}>6 Meses</Radio>
                                <Radio value={3}>Últimos 30 dias</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={9} md={8} lg={8} xl={6} xxl={4}>
                        <Row justify="end" gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Data label="Data Inicial" name="dataInicial" onBlur={() => { setDataIncial(form.getFieldValue().dataInicial); }} />
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Data label="Data Final" name="dataFinal" onBlur={() => { setDataFinal(form.getFieldValue().dataFinal); }} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[0, 24]} className="m-t-5">
                    <Col span={24}>
                        <Card className="card-crm" extra={<Button onClick={() => exibirOutrosClientes()} icon={openCliente ? <CloseOutlined /> : <EditOutlined />} />}>
                            {!!openCliente &&
                                <Row className="select">
                                    <Col span={24}>
                                        <Form.Item name="cli_id">
                                            <SelectPaginacao url="Cliente/Listar" placeholder="Selecione um Cliente" allowClear={true} form={form} nameLabel="pes_nome" nameValue="cli_id" onChangeFunction={() => alterarCliente()}
                                                conteudo={
                                                    cli => (
                                                        <Select.Option value={cli.cli_id} key={cli.cli_id}>{cli.cli_codigo} - {cli.pes_nome}</Select.Option>)
                                                } />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            }
                            <Row>
                                <Col>
                                    <Typography.Title level={3}>
                                        {dadosCliente.pes_nome}
                                    </Typography.Title>
                                </Col>
                            </Row>
                            <Row align="middle" justify="space-between" gutter={[16, 0]} className="m-t-8">
                                <Col>
                                    Cliente desde: <b>{!!dadosCliente.cli_datainclusao ? moment(dadosCliente.cli_datainclusao).format('DD/MM/YYYY') : 'Não informado'}</b>
                                </Col>
                                <Col>
                                    Última atualização: <b>{!!dadosCliente.cli_dataalteracao ? moment(dadosCliente.cli_dataalteracao).format('DD/MM/YYYY') : 'Não informado'}</b>
                                </Col>
                            </Row>
                            <Row gutter={[0, 8]}>
                                <Col span={24}>
                                    <Divider />
                                </Col>
                            </Row>
                            <Row align="middle" gutter={[16, 0]}>
                                {!!dadosCliente.pef_cpf &&
                                    <Col>
                                        CPF: <b>{!!dadosCliente.pef_cpf ? formatarCpf(dadosCliente.pef_cpf) : 'Não informado'}</b>
                                    </Col>
                                }
                                {!!dadosCliente.pej_cnpj &&
                                    <Col>
                                        CNPJ: <b>{!!dadosCliente.pej_cnpj ? formatarCnpj(dadosCliente.pej_cnpj) : 'Não informado'}</b>
                                    </Col>
                                }
                                <Col>
                                    Telefone: <b>{!!telefone && FormatFone(telefone.psc_numero) ? FormatFone(telefone.psc_numero) : 'Não informado'}</b>
                                </Col>
                                <Col>
                                    E-mail: <b>{!!email && email.pem_email ? email.pem_email : 'Não informado'}</b>
                                </Col>
                                <Col>
                                    Data de Nascimento: <b>{!!dadosCliente.pef_datanascimento ? moment(dadosCliente.pef_datanascimento).format("DD/MM/YYYY") : 'Não informado'}</b>
                                </Col>
                                <Col>
                                    Endereço: <b>{!!endereco ? `${endereco.log_logradouro}, ${endereco.pee_numero}, ${endereco.bai_nome}, ${endereco.cep_cep} - ${endereco.est_sigla}` : 'Não informado'}</b>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row gutter={[0, 8]} className="m-t-16">
                    <Col span={24}>
                        <Tabs type="card" className="crm-card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                            <Tabs.TabPane tab="Estatísticas" key="1">
                                <TabEstatistica dadosCliente={dadosCliente} dataInicial={dataInicial} dataFinal={dataFinal} />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Últimas Compras" key="2">
                                <TabUltimasCompras form={form} dadosCliente={dadosCliente} dataInicial={dataInicial} dataFinal={dataFinal} />
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}