export const TOKEN_KEY = "@GestorWeb-Token";
export const EMPRESA_KEY = "@GestorWeb-Empresa";
export const LOCAL_KEY = "@GestorWeb-Local";
export const NOMEEMPRESA_KEY = "@GestorWeb-NomeEmpresa";
export const NOMELOCAL_KEY = "@GestorWeb-NomeLocal";
export const REGIMETRIBUTARIO_KEY = "@GestorWeb-RegimeTributario";
export const NOMEUSUARIO_KEY = "@GestorWeb-NomeUsuario";
export const IDUSUARIO_KEY = "@GestorWeb-IdUsuario";
export const EMAILUSUARIO_KEY = "@GestorWeb-EmailUsuario";
export const TELA_KEY = "@GestorWeb-Tela";
export const NAVEGACAO_KEY = "@GestorWeb-Navegacao";
export const PERMISSOES_KEY = "@GestorWeb-Permissoes";
export const BREADCRUMB_KEY = "@GestorWeb-Breadcrumb";
export const FILTROSRELATORIO_KEY = "@GestorWeb-FiltrosRelatorio";
export const STATUSPLANO_KEY = "@GestorWeb-StatusPlano";
export const TELAANTERIRO_KEY = "@GestorWeb-TelaAnterior";
export const ENTIDADE_KEY = "@GestorWeb-Entidade";
export const STATUSFILIAL_KEY = "@GestorWeb-StatusFilial";
export const DADOS_INTEGRA_KEY = "@GestorWeb-DadosIntegra";
export const CNPJ_EMPRESA_KEY = "@GestorWeb-CnpjEmpresa";
export const CLIENTECAT_KEY = "@CatalogoWeb-Cliente";
export const NOMECLIENTECAT_KEY = "@CatalogoWeb-NomeCliente";
export const linkApi = process.env.REACT_APP_PUBLIC_URL || (window.location.href.indexOf('localhost') > -1 ? 'https://localhost:7205/' : window.location.href.indexOf('hom') > -1 ? 'http://apihom.gestorweb.com.br/' : 'https://api.gestorweb.com.br/');
export const linkApiGestor = window.location.href.indexOf('localhost') > -1 ? "https://localhost:7298/" : window.location.href.indexOf('hom') > -1 ? "https://apihomgestor.gestorweb.com.br/" : "https://apicatalogo.gestorweb.com.br/";
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getEmpresa = () => localStorage.getItem(EMPRESA_KEY);
export const getCnpjEmpresa = () => localStorage.getItem(CNPJ_EMPRESA_KEY);
export const getLocal = () => localStorage.getItem(LOCAL_KEY);
export const getNomeEmpresa = () => localStorage.getItem(NOMEEMPRESA_KEY);
export const getNomeLocal = () => localStorage.getItem(NOMELOCAL_KEY);
export const getRegimeTributario = () => localStorage.getItem(REGIMETRIBUTARIO_KEY);
export const getNomeUsuario = () => localStorage.getItem(NOMEUSUARIO_KEY);
export const getIdUsuario = () => localStorage.getItem(IDUSUARIO_KEY);
export const getEmailUsuario = () => localStorage.getItem(EMAILUSUARIO_KEY);
export const getTela = () => localStorage.getItem(TELA_KEY);
export const getBreadcrumb = () => localStorage.getItem(BREADCRUMB_KEY);
export const getNavegacao = () => decodeURIComponent(localStorage.getItem(NAVEGACAO_KEY));
export const getPermissao = () => decodeURIComponent(localStorage.getItem(PERMISSOES_KEY));
export const getStatusPlano = () => localStorage.getItem(STATUSPLANO_KEY);
export const getStatusFilial = () => atob(localStorage.getItem(STATUSFILIAL_KEY)) === 'true';
export const getTelaAnterior = () => localStorage.getItem(TELAANTERIRO_KEY);
export const getEntidadeImp = () => localStorage.getItem(ENTIDADE_KEY);
export const getDadosIntegra = () => localStorage.getItem(DADOS_INTEGRA_KEY);
export const getClienteCat = () => localStorage.getItem(CLIENTECAT_KEY);
export const getNomeClienteCat = () => localStorage.getItem(NOMECLIENTECAT_KEY) ?? "";
export const login = token => {
  localStorage.setItem(TOKEN_KEY, token);
};
export const setEmpresa = empresa => {
  localStorage.setItem(EMPRESA_KEY, empresa);
};
export const setLocal = local => {
  localStorage.setItem(LOCAL_KEY, local);
};
export const removerLocal = () => {
  localStorage.removeItem(LOCAL_KEY);
};
export const setNomeEmpresa = nomeEmpresa => {
  localStorage.setItem(NOMEEMPRESA_KEY, nomeEmpresa);
};
export const setNomeLocal = nomeLocal => {
  localStorage.setItem(NOMELOCAL_KEY, nomeLocal);
};
export const setRegimeTributario = regimeTributario => {
  localStorage.setItem(REGIMETRIBUTARIO_KEY, regimeTributario);
};
export const setNomeUsuario = nomeUsuario => {
  localStorage.setItem(NOMEUSUARIO_KEY, nomeUsuario);
};
export const setIdUsuario = idUsuario => {
  localStorage.setItem(IDUSUARIO_KEY, idUsuario);
};
export const setEmailUsuario = emailUsuario => {
  localStorage.setItem(EMAILUSUARIO_KEY, emailUsuario);
};
export const setTela = tela => {
  localStorage.setItem(TELA_KEY, tela);
};
export const setNavegacao = nav => {
  localStorage.setItem(NAVEGACAO_KEY, encodeURIComponent(JSON.stringify(nav)));
};
export const setPermissoes = perm => {
  localStorage.setItem(PERMISSOES_KEY, encodeURIComponent(JSON.stringify(perm)));
};
export const setStatusPlano = stp => {
  localStorage.setItem(STATUSPLANO_KEY, stp);
};
export const setTelaAnterior = tela => {
  localStorage.setItem(TELAANTERIRO_KEY, tela);
};
export const setEntidadeImp = entidade => {
  localStorage.setItem(ENTIDADE_KEY, entidade);
};
export const removerTelaAnt = () => {
  localStorage.removeItem(TELAANTERIRO_KEY);
}
export const setStatusFilial = ativo => {
  localStorage.setItem(STATUSFILIAL_KEY, btoa(ativo));
};
export const setDadosIntegra = dados => {
  localStorage.setItem(DADOS_INTEGRA_KEY, dados);
}
export const setCnpjEmpresa = cnpj => {
  localStorage.setItem(CNPJ_EMPRESA_KEY, cnpj);
}
export const setClienteCatalogo = cli => {
  localStorage.setItem(CLIENTECAT_KEY, cli);
};
export const setNomeClienteCatalogo = cli => {
  localStorage.setItem(NOMECLIENTECAT_KEY, cli);
};
export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(EMPRESA_KEY);
  localStorage.removeItem(CNPJ_EMPRESA_KEY);
  localStorage.removeItem(LOCAL_KEY);
  localStorage.removeItem(NOMEEMPRESA_KEY);
  localStorage.removeItem(NOMELOCAL_KEY);
  localStorage.removeItem(REGIMETRIBUTARIO_KEY);
  localStorage.removeItem(NOMEUSUARIO_KEY);
  localStorage.removeItem(IDUSUARIO_KEY);
  localStorage.removeItem(EMAILUSUARIO_KEY);
  localStorage.removeItem(TELA_KEY);
  localStorage.removeItem(NAVEGACAO_KEY);
  localStorage.removeItem(PERMISSOES_KEY);
  localStorage.removeItem(BREADCRUMB_KEY);
  localStorage.removeItem(FILTROSRELATORIO_KEY);
  localStorage.removeItem(STATUSPLANO_KEY);
  localStorage.removeItem(TELAANTERIRO_KEY);
  localStorage.removeItem(ENTIDADE_KEY);
  localStorage.removeItem(STATUSFILIAL_KEY);
  localStorage.removeItem(DADOS_INTEGRA_KEY);
  if (window.location.pathname !== '/') window.location = '/';
  localStorage.removeItem(CLIENTECAT_KEY);
  localStorage.removeItem(NOMECLIENTECAT_KEY);
  if (window.location.pathname !== '/') {
    if (window.location.href.indexOf('catalogo') > -1 || window.location.href.indexOf("login-cliente") > -1) {
      window.location = '/login-cliente';
    } else {
      window.location = '/';
    }
  }
};

export const getUserInfo = () => {
  let token = localStorage.getItem(TOKEN_KEY);
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

export const removeDadosNota = () => {
  localStorage.removeItem(DADOS_INTEGRA_KEY);
}