import React from "react";
import { Form, Row, Col, Typography, Divider, Input, InputNumber } from "antd";

export default function TabAgenciaBancaria() {

    return (
        <div>
            <Row align="middle" gutter={[8, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-banco.png").default} alt="Dados da Agência Bancária" /> Dados da Agência Bancária
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Informe os dados da Agência Bancária
                    </Divider>
                </Col>
                <Col span={24}>
                    <Row align="middle" gutter={[8, 0]}>
                        <Col xs={24} sm={12} md={4} lg={4} xl={5} xxl={4}>
                            <Form.Item label="Número da Agência" name="ctc_numeroagencia" rules={[{ required: false, message: 'Informe o número da agência' }]}>
                                <Input placeholder="Informe Número da Agência" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={4} lg={4} xl={5} xxl={4}>
                            <Form.Item label="Dígito Verificador" name="ctc_digitoverificadoragencia" rules={[{ required: false, message: 'Informe o dígito verificador da agência' }]}>
                                <Input placeholder="Informe o DV da Agência" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={5} xl={7} xxl={6}>
                            <Form.Item label="Operação Conta Corrente" name="ctc_operacaoconta" rules={[{ required: false, message: 'Informe a operação da conta corrente' }]}>
                                <Input maxLength={3}
                                    placeholder="Informe a Operação da Conta Corrente" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={5} xl={7} xxl={4}>
                            <Form.Item label="Conta Corrente" name="ctc_numeroconta" rules={[{ required: false, message: 'Informe o número da conta corrente' }]}>
                                <Input placeholder="Informe o número da Conta Corrente" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={10} md={8} lg={6} xl={8} xxl={6}>
                            <Form.Item label="Dígito Verificador da Conta Corrente" name="ctc_digitoverificadorconta" rules={[{ required: false, message: 'Informe o dígito verificador da conta corrente' }]}>
                                <Input placeholder="Informe o dígito verificador da Conta Corrente" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={6} xl={6} xxl={5}>
                            <Form.Item label="Unidade de Atendimento" name="ctc_unidadeatendimento" rules={[{ required: false, message: 'Informe a Unidade de Atendimento' }]}>
                                <Input type="number"
                                    placeholder="Informe a Unidade de Atendimento" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={6} md={8} lg={5} xl={5} xxl={4}>
                            <Form.Item label="Limite de Crédito (R$)" name="ctc_limitecredito" rules={[{ required: false, message: 'Informe o Limite de Crédito da Conta' }]}>
                                <InputNumber
                                    step={1.0}
                                    min={0}
                                    formatter={value => `${value}`}
                                    parser={value => value.replace('', '')}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );

}