import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Table, Select, Input, Form, Button } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, ExclamationCircleOutlined, CloseOutlined, SaveOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../../services/api";
import { getLocal, linkApi } from "../../../services/auth";
import InfoCheque from "../../../pages/cheque/infoCheque";
import SelectPaginado from "../../selectPaginado";


export default function ModalOcorrencia({ exibirModal, fecharModal, dadosCheque, statusCheque, ocorrencia }) {

    const [movimentacaoCheque, setMovimentacaoCheque] = useState([]);
    const [contaCorrente, setContaCorrente] = useState([]);
    const [motivoDevolucao, setMotivoDevolucao] = useState([]);
    const [formulario] = Form.useForm();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                formulario.resetFields();
                fecharModal();
            }
        });
    };

    useEffect(() => {
        api.get(`ContaCorrente/AtivosTipoConta?tipoConta=2&ordem=+ctc_descricao`).then(
            (res) => {
                if (res.status === 200) {
                    setContaCorrente(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );

        api.get(`Cheque/ListarCodigosDevolucao?filtro=&ordem=+cmc_descricao`).then(
            (res) => {
                if (res.status === 200 && res.data.length > 0) {
                    setMotivoDevolucao(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    }, []);

    useEffect(() => {
        if (!!dadosCheque.chq_id && ocorrencia === 0) {
            api.get(`OcorrenciaCheque/Listar?IdCheque=${dadosCheque.chq_id}`).then(
                (res) => {
                    if (res.status === 200) {
                        res.data.map((item) => {
                            item.key = item.och_id;
                        });
                        setMovimentacaoCheque(res.data);
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            );
        }
    }, [dadosCheque]);

    const tituloModal = {
        0: 'Movimentação do Cheque',
        1: 'Enviar para depósito',
        2: 'Reapresentar ao Banco',
        3: 'Marcar Cheque como Compensado',
        4: 'Repassar o Cheque para terceiros',
        5: 'Trocar com o Cliente',
        6: 'Registrar Devolução do Cheque',
        7: 'Enviar Cheque para Custódia'
    };

    const operacao = {
        enviarParaDeposito: 1,
        representadoAoBanco: 2,
        compensado: 3,
        repassado: 4,
        trocado: 5,
        devolvido: 6,
        emCustodia: 7
    };

    function salvar(values) {
        let dados = {};
        dados.ctc_id = (ocorrencia !== operacao.compensado && ocorrencia !== operacao.trocado && ocorrencia !== operacao.repassado) ? values.ctc_id : 0;
        dados.chq_id = dadosCheque.chq_id;
        dados.och_data = moment(new Date());
        dados.och_status = ocorrencia;
        dados.mcc_id = null;
        dados.loc_id = getLocal();
        if (ocorrencia === operacao.devolvido) {
            dados.cmc_id = values.cmc_id;
        }
        if (!!values.och_observacao) {
            dados.och_observacao = values.och_observacao;
        }
        api.post(`OcorrenciaCheque/Incluir`, dados).then(
            (retorno) => {
                if (retorno.status === 200) {
                    Modal.success({
                        content: 'Ocorrência registrada e vinculada ao cheque com sucesso!',
                    });
                } else {
                    error("Não foi possível registrar esta ocorrência no cheque.");
                }
            }
        ).catch(
            (erro) => {
                error("Erro ao registrar esta ocorrência no cheque.");
            }
        ).finally(() => {
            formulario.resetFields();
            fecharModal();
        });
    };

    function error(mensagem, title = 'Atenção!') {
        Modal.error({
            title: title,
            content: (
                <div>
                    <p>{mensagem}</p>
                </div>
            )
        });
    }

    return (
        <Modal centered
            title={tituloModal[ocorrencia]}
            visible={exibirModal}
            onCancel={onCloseModal}
            footer={ocorrencia !== 0 ?
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onCloseModal} icon={<CloseOutlined />} size="middle" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formulario.submit()} icon={<SaveOutlined />} size="middle" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
                : null
            }
            width={720}
            maskClosable={false}
        >
            <div>
                <InfoCheque dadosCheque={dadosCheque} />
                <div className="m-t-8">
                    <Form layout="vertical" form={formulario} onFinish={salvar}>
                        {ocorrencia === 0 &&
                            <Table dataSource={movimentacaoCheque} columns={
                                [
                                    {
                                        title: 'Usuário',
                                        render: ({ usu_nome }) => (
                                            <div>
                                                <b>{usu_nome}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Data',
                                        render: ({ och_data }) => (
                                            <div>
                                                <b>{moment(och_data).format('DD/MM/YYYY HH:mm:ss')}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Banco',
                                        render: ({ och_status, ban_id, ban_descricao }) => (
                                            <div>
                                                {((och_status === 1 || och_status === 2 || och_status === 3 || och_status === 7) && (!!ban_id)) &&
                                                    <Row align="middle">
                                                        <Col>
                                                            <img className="imgLogoListaBanco" src={`${linkApi}Banco/RetornaLogo/${ban_id}`} alt={ban_descricao} /><b> {ban_id} - {ban_descricao}</b>
                                                        </Col>
                                                    </Row>
                                                }
                                                <Row align="middle">
                                                    <Col>
                                                        Situação: <b>{statusCheque[och_status].value}</b>
                                                    </Col>
                                                </Row>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Conta Destino',
                                        render: ({ och_status, ctc_descricao }) => (
                                            <div>
                                                {(och_status === 1 || och_status === 2 || och_status === 3 || och_status === 7) &&
                                                    <b>{ctc_descricao}</b>
                                                }
                                            </div>
                                        ),
                                    },
                                ]
                            } expandable={{
                                expandedRowRender: ({ och_observacao }) =>
                                    <div>
                                        <Row align="middle">
                                            <Col>
                                                Observações: <b> {och_observacao}</b>
                                            </Col>
                                        </Row>
                                    </div>,
                                expandIcon: ({ expanded, onExpand, record }) =>
                                    expanded ? (
                                        <ArrowUpOutlined onClick={e => onExpand(record, e)} />
                                    ) : (
                                        <ArrowDownOutlined onClick={e => onExpand(record, e)} />
                                    )
                            }} columnWidth={30} />
                        }
                        {(ocorrencia === 1 || ocorrencia === 2 || ocorrencia === 7) &&
                            <Row align="middle" gutter={[0, 8]}>
                                <Col span={24}>
                                    <Form.Item label="Conta Corrente" name="ctc_id" rules={[{ required: true, message: 'Informe Conta Corrente Destino' }]}>
                                        <SelectPaginado url="ContaCorrente/AtivosTipoConta" form={formulario} name="ctc_id" conteudo={
                                            cc => (
                                                <Select.Option value={cc.ctc_id} key={cc.key}>{cc.ctc_descricao}</Select.Option>
                                            )} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                        {(ocorrencia === 3) &&
                            <div className="m-t-8">
                                <Row justify="center" gutter={[0, 8]}>
                                    <b className="f-16">Este cheque será definido como compensado.</b>
                                </Row>
                                <Row justify="center" gutter={[0, 8]}>
                                    <span>Para prosseguir clique em Salvar...</span>
                                </Row>
                            </div>
                        }
                        {(ocorrencia === 4) &&
                            <div className="m-t-8">
                                <Row justify="center" gutter={[0, 8]}>
                                    <b className="f-16">Este cheque será definido como: Repassado à Terceiros.</b>
                                </Row>
                                <Row justify="center" gutter={[0, 8]}>
                                    <span>Para prosseguir clique em Salvar...</span>
                                </Row>
                            </div>
                        }
                        {(ocorrencia === 5) &&
                            <div className="m-t-8">
                                <Row justify="center" gutter={[0, 8]}>
                                    <b className="f-16">Este cheque será definido como: Trocado com o Cliente.</b>
                                </Row>
                                <Row justify="center" gutter={[0, 8]}>
                                    <span>Para prosseguir clique em Salvar...</span>
                                </Row>
                            </div>
                        }
                        {(ocorrencia === 6) &&
                            <div>
                                <Row align="middle" gutter={[8, 0]}>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item label="Selecione a conta corrente para retorno do cheque" name="ctc_id" rules={[{ required: true, message: 'Selecione uma Conta Corrente' }]}>
                                            <SelectPaginado url="ContaCorrente/AtivosTipoConta" form={formulario} name="ctc_id" conteudo={
                                                cc => (
                                                    <Select.Option value={cc.ctc_id} key={cc.key}>{cc.ctc_descricao}</Select.Option>
                                                )} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                        <Form.Item label="Selecione o motivo da devolução" name="cmc_id" rules={[{ required: true, message: 'Selecione o motivo' }]}>
                                            <Select allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione o motivo....">
                                                {motivoDevolucao.map(motivo => (
                                                    <Select.Option key={motivo.cmc_id}>{motivo.cmc_descricao}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        }
                        {ocorrencia !== 0 &&
                            <Row align="middle">
                                <Col span={24}>
                                    <Form.Item label="Observações" name="och_observacao">
                                        <Input.TextArea placeholder="" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        }
                    </Form>
                </div>
            </div>
        </Modal>
    );
}