import { Button, Col, Form, Input, InputNumber, notification, Row, Select } from "antd";
import InputCpfCnpj from "../inputCpfCnpj";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";

export default function FiltroSeguroMDF({ formularioSeg, listaSeguro, setListaSeguro, editando, setEditando, responsavelSeguro }) {

    function adicionarSeguro() {
        let values = formularioSeg.getFieldValue();
        if (values.seg_responsavel && values.seg_cnpjresponsavel?.length === 14 && values.seg_nomeseguradora && values.seg_cnpjseguradora?.length === 14 && values.seg_numeroapolice
            && values.seg_numeroaverbacao) {
            let listaTemp = [...listaSeguro];
            if (editando) {
                let index = listaTemp.findIndex(doc => doc.seg_sequencia === values.seg_sequencia)
                if (index !== -1) {
                    listaTemp[index] = values;
                    setListaSeguro(listaTemp);
                    notification.success({ message: 'Atenção!', description: 'Seguradora editada com sucesso.' });
                }
            } else {
                values.seg_sequencia = 1 + listaSeguro.length
                listaTemp.push(values);
                setListaSeguro(listaTemp);
                notification.success({ message: 'Atenção!', description: 'Seguradora adicionada com sucesso.' });
            }
            setEditando(false);
            formularioSeg.resetFields();
        } else {
            notification.warning({ message: 'Atenção!', description: 'Preencha todos os campos.' })
        }
    }

    function cancelarEdit() {
        setEditando(false);
        formularioSeg.resetFields();
    }

    return (
        <div>
            <Form form={formularioSeg}>
                <Row>
                    {editando &&
                        <Col xs={24} sm={12} md={8} lg={6} xl={2}>
                            <Form.Item label="Sequência" name="seg_sequencia">
                                <InputNumber disabled />
                            </Form.Item>
                        </Col>
                    }
                </Row>
                <Row gutter={[8, 0]}>

                    <Col xs={24} sm={13} md={13} lg={13} xl={8}>
                        <Form.Item label="Responsável" name="seg_responsavel">
                            <Select placeholder="Selecione o Responsável" showSearch allowClear optionFilterProp="children">
                                {responsavelSeguro.map((item) => (
                                    <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={9} xl={4}>
                        <InputCpfCnpj label="CNPJ do Responsável" name="seg_cpfcnpjresponsavel" />
                    </Col>
                    <Col xs={24} sm={13} md={13} lg={13} xl={8}>
                        <Form.Item label="Seguradora" name="seg_nomeseguradora">
                            <Input placeholder="Informe o nome da Seguradora" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={9} xl={4}>
                        <InputCpfCnpj label="CNPJ da Seguradora" name="seg_cnpjseguradora" />
                    </Col>
                    <Col xs={24} sm={13} md={13} lg={13} xl={5}>
                        <Form.Item label="Número Apólice" name="seg_numeroapolice">
                            <Input placeholder="Informe o número do Apólice" maxLength={12} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={13} md={13} lg={13} xl={5}>
                        <Form.Item label="Número Averbação" name="seg_numeroaverbacao">
                            <Input placeholder="Informe o número de Averbação" maxLength={12} />
                        </Form.Item>
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={2} className="add-button">
                        {editando ?
                            <Button icon={<CheckOutlined />} type="primary" onClick={() => adicionarSeguro()} >
                                Salvar
                            </Button> :
                            <Button icon={<PlusOutlined />} type="primary" onClick={() => adicionarSeguro()} >
                                Adicionar
                            </Button>
                        }
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={2} className="add-button">
                        {editando &&
                            <Button icon={<CloseOutlined />} onClick={() => cancelarEdit()} >
                                Cancelar
                            </Button>
                        }
                    </Col>
                </Row>
            </Form>
        </div>
    );
}