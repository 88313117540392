import React from "react";
import { Row, Col, Typography, Divider, Form, Input } from "antd";
import { InputCpfCnpj, InputPhone } from "../../components";

export default function TabResponsavel({ formulario }) {

    return (
        <div>
            <Row align="middle" gutter={[8, 8]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-info.png").default} alt="Outras Informações" /> Outras Informações
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Adicione abaixo as informações para realizar o cadastro com sucesso.
                    </Divider>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                            <InputCpfCnpj cpf label="CPF do Responsável" name="loc_cpfresponsavel" placeholder="Informe o CPF do Responsável" />
                        </Col>
                        <Col xs={24} sm={16} md={8} lg={8} xl={8}>
                            <Form.Item label="Nome do Responsável" name="loc_responsavelestabelecimento">
                                <Input placeholder="Informe o Nome do Responsável" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={8} lg={8} xl={8}>
                            <Form.Item label="Informe o E-mail" name="loc_email">
                                <Input placeholder="Informe o E-mail" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                            <InputPhone label="Telefone" name="loc_telefone" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );

}