import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tag, Popover, Modal, Menu, Dropdown, notification, Table, Divider } from "antd";
import { EditOutlined, DeleteOutlined, PrinterOutlined, FileTextOutlined, UndoOutlined, MoreOutlined, EyeOutlined, CloudUploadOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import moment from 'moment';
import api from '../../services/api';
import { useStateValue } from '../../state';
import { FormatNumber } from "../../ValueObjects";
import { novaAbaNavegador } from "../../services/funcoes";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { ModalImprimirEtiquetas, ModalDevolucao, ModalAjudaRejeicaoNfe, ModalCancelamentoNfe, ModalCartaCorrecaoNotaFiscal, ModalEstornoNfe } from "../../components/modals/";

export default function NotaFiscal({ dados }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [listaStatusNota, setListaStatusNota] = useState([]);
    const [openModalCartaCorrecao, setOpenModalCartaCorrecao] = useState(false);
    const [openModalExcluir, setOpenModalExcluir] = useState(false);
    const [openModalDevolucao, setOpenModalDevolucao] = useState(false);
    const [openModalAjudaRejeicaoNfe, setOpenModalAjudaRejeicaoNfe] = useState(false);
    const [openModalCancelarEstornar, setOpenModalCancelarEstornar] = useState(false);
    const [openModalImprimirEtiquetas, setOpenModalImprimirEtiquetas] = useState(false);
    const [dadosNota, setDadosNota] = useState({});
    const [openModalEstorno, setOpenModalEstorno] = useState(false);

    function retornaDescricaoStatus(ntf_status) {
        let status = listaStatusNota.filter((statusNota) => (statusNota.key === ntf_status))[0];
        return !!status ? status.value : "";
    };

    useEffect(() => {
        retornaStatusNF();
    }, []);

    function retornaStatusNF() {
        api.get('Enum/Listar?nome=StatusNFe').then(
            (res) => {
                if (res.status === 200) {
                    setListaStatusNota(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };

    function abrirCartaCorrecao(record) {
        setDadosNota(record);
        setOpenModalCartaCorrecao(true);

    };

    function abrirCancelarNota(record) {

        api.get(`NotaFiscal/VerificarTempoEmissao?codigoNotaFiscal=${record.ntf_id}`).then(
            res => {
                if (record.ntf_docfiscal) {
                    if (!!res.data.terminouPrazoCancelamento) {
                        record.horas = res.data.horas;
                        record.dias = res.data.dias;
                        setDadosNota(record);
                        setOpenModalEstorno(true);
                    } else {
                        setDadosNota(record);
                        setOpenModalCancelarEstornar(true);
                    }
                } else {
                    setDadosNota(record);
                    setOpenModalCancelarEstornar(true);
                }
            }
        )
    };

    function abrirExcluirNota(record) {
        setDadosNota(record);
        setOpenModalExcluir(true);
    };

    function recarregarLista() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ntf_numero' } });
    }

    function imprimirDanfe(dados) {
        if (dados.ntf_status == 1 && !!dados.ntf_chavenfe && !!dados.ntf_docfiscal) {
            if (dados.mdf_id == "55") {
                novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${dados.ntf_chavenfe}`)
            } else if (dados.mdf_id == "65") {
                notification.warning({ description: `Para reimprimir um cupom eletrônico acesso o módulo de PDV, va até a listagem de vendas e solicite a reimpressão do mesmo`, message: `"Não é possível gerar um DANFe a partir de uma NFC-e (Cupom Fiscal Eletrônico)` })
            } else if (dados.ntf_status == 1 && !!dados.ntf_chavenfe && !(!!dados.ntf_docfiscal)) {
                //n implementado
                /*
                RelatorioService.exibirRelatorioPDF(idRelatorioNf69, [
                        {
                            'nome': 'ntf_numero',
                            'valor': notaFiscal.ntf_numero
                        },
                        {
                            'nome': 'ntf_dataemissao',
                            'valor': new Date(notaFiscal.ntf_dataemissao)
                        }
                    ], [
                            {
                                'nome': 'dataemissaoFinal',
                                'valor': new Date(notaFiscal.ntf_dataemissao)
                            }
                        ]);
                */
            }
        }
    };

    function abrirExcluirNota(record) {
        setDadosNota(record);
        setOpenModalExcluir(true);
    };

    function menuTable(record) {
        return (
            <Menu className="text-right">
                {((record.ntf_status == 0 || record.ntf_status == 4) && record.ntf_finalidade != 4 && record.ntf_nroinutilizadosefaz == false) && <Menu.Item key="1">
                    <Button type="link" icon={<EditOutlined />} onClick={() => {
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                    }}>
                        Editar
                    </Button>
                </Menu.Item>}
                {record.ntf_status == 0 && <Menu.Item key="2">
                    <Button type="link" icon={<DeleteOutlined />} onClick={() => removerNotaDigitacao(record)}> Excluir </Button>
                </Menu.Item>}
                {(record.ntf_status == 1 && (record.ser_emitentedocumento == 0 || !!!record.ntf_docfiscal)) && <Menu.Item key="3">
                    <Button type="link" icon={<CloseOutlined />} onClick={() => abrirCancelarNota(record)}> Cancelar/Estornar </Button>
                </Menu.Item>}
                {record.ntf_status == 1 && record.ntf_modelo == '55' && record.ntf_tiponota == 1 && <Menu.Item key="4">
                    <Button type="link" icon={<DeleteOutlined />} onClick={() => abrirExcluirNota(record)}> Excluir </Button>
                </Menu.Item>}
                {(record.ntf_status == 1 && (record.ser_emitentedocumento == 0 || record.ser_serie != null)) && <Menu.Item key="5">
                    <Button type="link" icon={<FileTextOutlined />} onClick={() => abrirCartaCorrecao(record)}> Carta Correção </Button>
                </Menu.Item>}
                {(record.ntf_status == 1 && record.ntf_finalidade != 4 && record.ntf_modelo == '55' && record.dfr_id == null) && <Menu.Item key="6">
                    <Button type="link" icon={<UndoOutlined />} onClick={() => setOpenModalDevolucao(true)}> Devolver </Button>
                </Menu.Item>}
            </Menu>
        )
    };

    function excluirNota(record) {
        api.post(`NotaFiscal/Excluir?notaFiscalId=${record.ntf_id}`).then(
            res => {
                notification.success({ message: 'Nota Fiscal Excluida Com Sucesso!' });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ntf_numero' } });
            }
        )
    };

    function menuTableOutros(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="link" icon={<CloudUploadOutlined />}> Exportar </Button>
                </Menu.Item>
                <Menu.Item key="2">
                    <Button type="link" icon={<PrinterOutlined />} onClick={() => { imprimirDanfe(record) }}> Imprimir DANFE </Button>
                </Menu.Item>
            </Menu>
        )
    };

    function removerNotaDigitacao(record) {
        Modal.confirm({
            title: 'Aviso',
            icon: <ExclamationCircleOutlined />,
            content: `Excluir a Nota Fiscal ${record.ntf_numero}?`,
            okText: 'Sim',
            cancelText: 'Não',
            onOk() {
                excluirNota(record);
            },
            onCancel() {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '-ntf_numero' } });
            },
        })
    };

    return (
        <div className="tabela">
            <Table dataSource={dados} columns={[
                {
                    title: 'Nº Nota',
                    width: 100,
                    align: 'center',
                    render: ({ ntf_status, ntf_numero }) => (
                        <div>
                            <Row justify="center">
                                <Col>
                                    <Tag color={((ntf_status === 0) ? 'default' : (ntf_status === 1 ? 'green' : (ntf_status === 2 ? 'gold' : (ntf_status === 3 ? 'orange' : (ntf_status === 4 ? 'red' : (ntf_status === 5 ? 'lime' : ''))))))} className="w-100">
                                        <b>
                                            {retornaDescricaoStatus(ntf_status)}
                                        </b>
                                    </Tag>
                                </Col>
                            </Row>
                            <Row justify="center">
                                <Col>
                                    <b>{ntf_numero}</b>
                                </Col>
                            </Row>
                        </div>
                    ),
                },
                {
                    title: '',
                    render: ({ tip_descricao, ntf_nomedest, ntf_serie, ntf_docfiscal, ntf_dataemissao, mdf_id, ntf_finalidade, vendedor, ntf_chavenfe, ntf_cstat, ntf_xmotivo, formasPgto }) => (
                        <div>
                            <Row align="middle" gutter={[8, 0]}>
                                {!!tip_descricao && <Col>
                                    <Tag color='processing'>
                                        <b>{tip_descricao}</b>
                                    </Tag>
                                </Col>}
                                <Col>
                                    <b>{ntf_nomedest}</b>
                                </Col>
                                {!!ntf_nomedest === false && <Col>
                                    <small>Consumidor Final</small>
                                </Col>}
                            </Row>
                            <Row align="middle" gutter={[8, 0]}>
                                <Col>
                                    Série: <b>{(!!ntf_serie > 0 ? ntf_serie : '')}</b>
                                </Col>
                                <Col>
                                    {!ntf_docfiscal && <b>DAV-LS</b>}
                                    {!mdf_id == '65' && <b>CUPOM ELETRÔNICO</b>}
                                </Col>
                                {!!ntf_chavenfe === false && <Col>
                                    <b>NF-e não gerada</b>
                                </Col>}
                                {!!ntf_chavenfe && <Col>
                                    <Popover content={'Clique para visualizar o Danfe'}>
                                        NF-e: <b>{ntf_chavenfe}</b>
                                    </Popover>
                                </Col>}
                            </Row>
                        </div>
                    ),
                },
                {
                    title: 'Emissão',
                    width: 85,
                    render: ({ ntf_dataemissao }) => (
                        <div>
                            <b>{moment(ntf_dataemissao).format('DD/MM/YYYY')}</b>
                        </div>
                    ),
                },
                {
                    title: 'Finalidade',
                    width: 75,
                    render: ({ ntf_finalidade }) => (
                        <div>
                            <b>{ntf_finalidade}</b>
                        </div>
                    ),
                },
                {
                    title: 'Total (R$)',
                    align: 'right',
                    fixed: 'right',
                    width: 200,
                    render: ({ valor_total }) => (
                        <div>
                            <b className="f-18">{FormatNumber(valor_total, true)}</b>
                        </div>
                    ),
                },/*
                {
                    title: 'Ações',
                    dataIndex: '',
                    key: 'x',
                    align: 'center',
                    fixed: 'right',
                    width: 65,
                    render: (record) => (
                        <div>
                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                <Col>
                                    <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                        <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                    </Dropdown>
                                </Col>
                                <Col>
                                    <Dropdown overlay={menuTableOutros(record)} trigger={['click']}>
                                        <Button onClick={e => e.preventDefault()} icon={<EyeOutlined />}/>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>
                    ),
                },*/
            ]} locale={{
                emptyText: (
                    <Row>
                        <Col span={24}>
                            <Divider orientation="center">
                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                            </Divider>
                        </Col>
                    </Row>
                )
            }} scroll={{ x: 900 }}/>
            {/*<ModalImprimirEtiquetas exibirModalImprimirEtiquetas={openModalImprimirEtiquetas} fecharModalImprimirEtiquetas={() => { setOpenModalImprimirEtiquetas(false); recarregarLista(); }} />
            <ModalAjudaRejeicaoNfe exibirModalAjudaRejeicaoNfe={openModalAjudaRejeicaoNfe} fecharModalAjudaRejeicaoNfe={() => { setOpenModalAjudaRejeicaoNfe(false); recarregarLista(); }} />
            <ModalCartaCorrecaoNotaFiscal dadosNota={dadosNota} exibirModal={openModalCartaCorrecao} fecharModal={() => { setOpenModalCartaCorrecao(false); recarregarLista() }} />

            <ModalDevolucao exibirModalDevolucao={openModalDevolucao} fecharModalDevolucao={() => { setOpenModalDevolucao(false); recarregarLista(); }} />
            <ModalEstornoNfe dadosNota={dadosNota} exibirModal={openModalEstorno} fecharModal={() => { setOpenModalEstorno(false); recarregarLista(); }} />
            <ModalCancelamentoNfe dadosNota={dadosNota} titleModal="Exclusão de Entrada de NF-e" textoBotao="Realizar Exclusão" exibirModalCancelarEstornar={openModalExcluir} fecharModalCancelarEstornar={() => { setOpenModalExcluir(false); recarregarLista(); }} />
            <ModalCancelamentoNfe dadosNota={dadosNota} titleModal="Cancelamento de NF-e" textoBotao="Realizar Cancelamento" exibirModalCancelarEstornar={openModalCancelarEstornar} fecharModalCancelarEstornar={() => { setOpenModalCancelarEstornar(false); recarregarLista() }} />
        */}</div>
    );

}