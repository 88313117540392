import React from 'react';
import { Modal, Spin, notification } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const ModalCarregando = ({
    title,
    titleModalLoading = 'Atualizando...',
    confirmContent,
    apiCall,
    successMessage,
    errorMessage,
    successCallback,
    errorCallback,
    okText = 'Sim',
    cancelText = 'Não'
}) => {
    const handleOk = () => {
        // Exibe uma modal de "Atualizando..."
        const loadingModal = Modal.info({
            title: titleModalLoading,
            content: (
                <div style={{ textAlign: 'center' }}>
                    <Spin size="large" />
                    <p>Por favor, aguarde enquanto a operação está sendo realizada.</p>
                </div>
            ),
            okButtonProps: { disabled: true },
            closable: false,
            centered: true,
        });

        // Chama a função de API
        apiCall()
            .then(() => {
                loadingModal.destroy();
                notification.success({
                    message: 'Operação concluída',
                    description: successMessage,
                });
                if (successCallback) successCallback();
            })
            .catch((error) => {
                loadingModal.destroy();
                notification.error({
                    message: 'Erro na Atualização',
                    description: `${errorMessage}: ${error.message}`,
                });
                if (errorCallback) errorCallback();
            });
    };

    // Chama Modal.confirm diretamente
    Modal.confirm({
        title: title,
        icon: <ExclamationCircleOutlined />,
        content: confirmContent,
        okText: okText,
        cancelText: cancelText,
        centered: true,
        onOk: handleOk
    });

    return null; // Não renderiza nada, pois o Modal é mostrado via API
};

export default ModalCarregando;
