import React, { useEffect } from "react";
import { Col, notification, Row, Tag, Modal } from "antd";
import { DeleteOutlined, EditFilled, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from "../../state";
import DrawerEtiquetaServidor from "./drawer";
import { listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, EditarTabela, Excluir, PesquisaOrdenacao, TabelaDados } from "../../components";


export default function EtiquetaServidor() {

    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-met_descricao' } })
    }, []);

    function editarModelo(registro) {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: registro } });
    };

    function modalExcluirModelo(registro) {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o modelo selecionado?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                removerModelo(registro);
            }
        });
    };

    function removerModelo(registro) {
        api.delete(`ModeloEtiqueta/ExcluirAsync?Id=${registro.met_id}`).then(
            res => {
                if (res.data) {
                    notification.success({ description: 'Modelo removido com sucesso!' });
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '-met_descricao' } });
                } else {
                    notification.warning({ description: 'Modelo não foi removido!' });
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Código Crescente", value: "%2Bmet_id" },
                { label: "Código Decrescente", value: "-met_id" },
                { label: "Nome A - Z", value: "%2Bmet_descricao" },
                { label: "Nome Z - A", value: "-met_descricao" }
            ]} />
            <div className="tabela">
                <TabelaDados url={`ModeloEtiqueta/Listar`} colunas={[
                    {
                        title: 'Informações dos modelos de etiquetas',
                        render: ({ met_id, met_descricao, met_colunas }) => (
                            <Row align="middle" gutter={[8, 0]}>
                                <Col align="center">
                                    <Tag color={'processing'} className="w-75">
                                        <b>{met_id}</b>
                                    </Tag>
                                </Col>
                                <Col span={14}>
                                    <b>{met_descricao}</b>
                                </Col>
                                <Col span={4}>
                                    <b>Colunas {met_colunas}</b>
                                </Col>
                            </Row>
                        ),
                    }, {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 65,
                        fixed: 'right',
                        render: (registro) => (
                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                {/* <Col>
                                    <Button icon={<EyeOutlined />} />
                                </Col> */}
                                <Col>
                                    <EditarTabela registro={registro} icon={<EditFilled />} onClick={() => { editarModelo(registro) }} />
                                </Col>
                                <Col>
                                    <Excluir icon={<DeleteOutlined />} onClick={() => { modalExcluirModelo(registro) }} />
                                </Col>
                            </Row>
                        ),
                    },
                ]} />
            </div>
            <DrawerEtiquetaServidor />
        </div>
    );
};
