
import React from "react";
import { Row, Col } from "antd";

import { BreadcrumbPage, BotaoAbrirChamado } from "../../components";

export default function BreadcrumbAbrirChamado() {

    return (
        <Row align="middle" justify="space-between" gutter={[8, 0]} className="col-pagina-breadcrumb"> 
            <Col className="col-w-100">
                <BreadcrumbPage />
            </Col>
            <Col className="col-mob-100">
                <BotaoAbrirChamado />
            </Col>
        </Row>
    );
    
}