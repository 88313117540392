import React, { useEffect, useState } from 'react';
import { Col, Form, Input, InputNumber, Row, Select, Modal, Divider, Typography, notification } from 'antd';
import { CheckCircleOutlined } from '@material-ui/icons';
import moment from 'moment';

import api from '../../services/api';
import { useStateValue } from '../../state';
import { info } from '../../services/funcoes';
import { Data, FormGW, InputPreco } from "../../components";
import { getEmpresa } from '../../services/auth';

export default function LancamentoTransferencia({ formulario, carregando, aoSalvar }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [listaFiliais, setListaFiliais] = useState([]);
    const [listaContaCorrente, setListaContaCorrente] = useState([]);
    const [listaContaCorrenteDestino, setListaContaCorrenteDestino] = useState([]);
    const [listaContaGerencialOrigem, setListaContaGerencialOrigem] = useState([]);
    const [listaContaGerencialAgrupadorOrigem, setListaContaGerencialAgrupadorOrigem] = useState([]);
    const [listaContaGerencialDestino, setListaContaGerencialDestino] = useState([]);
    const [listaContaGerencialAgrupadorDestino, setListaContaGerencialAgrupadorDestino] = useState([]);


    useEffect(() => {
        api.get(`Local/Listar?Ativo=true&CodigoEmpresa=${getEmpresa()}&PageSize=500&PageNumber=1`).then(
            res => {
                setListaFiliais(res.data.items);
            });
        api.get(`contaCorrente/ListarContasParaTransferencia?filtro=&codigoContaCorrente=&ordem=+ctc_descricao`).then(res => {
            setListaContaCorrente(res.data);
        });
        api.get(`ContaGerencial/ListarAtivosEstruturadoPelaCategoria?filtro=&CodReceitaDespesa=1`).then(res => {
            setListaContaGerencialAgrupadorOrigem(res.data.filter((item) => { return item.podeSelecionar === false }));
            setListaContaGerencialOrigem(res.data.filter((item) => { return item.podeSelecionar === true }));
        });
        api.get(`ContaGerencial/ListarAtivosEstruturadoPelaCategoria?filtro=&CodReceitaDespesa=2`).then(res => {
            setListaContaGerencialAgrupadorDestino(res.data.filter((item) => { return item.podeSelecionar === false }));
            setListaContaGerencialDestino(res.data.filter((item) => { return item.podeSelecionar === true }));
        });
        listarContaDestino(null);
        formulario.setFieldsValue({ mcc_datahora: moment(new Date()) });
    }, []);

    function listarContaDestino(filial) {
        filial = !!filial ? `&IdLocalTransferencia=${filial}` : '';
        api.get(`contaCorrente/ListarContasParaTransferencia?filtro=&codigoContaCorrente=&ordem=+ctc_descricao${filial}`).then(res => {
            setListaContaCorrenteDestino(res.data);
        });
    };

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.ctc_id) {
            manutencao.dados.ctc_id_origem = manutencao.dados.ctc_id;
            formulario.setFieldsValue(manutencao.dados);
        } else {
            formulario.setFieldsValue({ ctc_id: null, mcc_datahora: moment(new Date()) });
        }
    }, [ui.showDrawerTransferencia]);

    const salvarManutencao = (values) => {
        values.pes_id = null;
        values.mcc_id = null;
        values.mcc_entradasaida = 3;
        values.ctgorigem = { ctg_id: values.ctg_id_origem };
        values.ctgdestino = { ctg_id: values.ctg_id_destino };
        values.ctcorigem = { ctc_id: values.ctc_id_origem };
        values.ctcdestino = { ctc_id: values.ctc_id_destino };
        values.localdestino = { loc_id: formulario.getFieldsValue().loc_id };
        values.mcc_numerodocumento = !!values.mcc_numerodocumento ? values.mcc_numerodocumento.toString() : '';
        values.mcc_valor = parseFloat(values.mcc_valor);
        if(values.ctcorigem.ctc_id == values.ctcdestino.ctc_id){
            notification.warning({ message: `Aviso!`, description: `Conta corrente ORIGEM não pode ser a mesma que a Conta corrente DESTINO, Verifique!` });
            return;
        }
        carregando(true);
        api.post('/MovimentoContaCorrente/IncluirLancamentoCaixa', values).then(res => {
            if (res.status === 200) {
                Modal.confirm({
                    title: 'Lançamento de Transferência incluído com sucesso!',
                    icon: <CheckCircleOutlined />,
                    content: '',
                    okText: 'Incluir mais um',
                    cancelText: 'OK',
                    centered: true,
                    onOk() {
                        carregando(false);
                        formulario.resetFields();
                        formulario.setFieldsValue({ mcc_datahora: moment(new Date()) });
                    },
                    onCancel() {
                        aoSalvar();
                        carregando(false);
                    }
                });
            }
        }).catch(err => {
            info("Não foi possível realizar o lançamento!");
        }).finally(() => {
            carregando(false);
        });
    };

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="formLancamentoTransferencia" initialValues={{ remember: true }} onFinish={salvarManutencao}>
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Typography.Title level={3}>
                            <img src={require("../../assets/i-tranferencia.png").default} alt="Transferência" /> De
                        </Typography.Title>
                        <Divider orientation="left" />
                    </Col>
                </Row>
                <Row gutter={[8, 0]} className="m-t-16">
                    <Col xs={24} sm={6} md={4} lg={4} xl={4} xxl={4}>
                        <Data label="Data" name="mcc_datahora" rules={[{ required: true, message: 'Informe uma data' }]} />
                    </Col>
                    <Col xs={24} sm={18} md={10} lg={10} xl={7} xxl={6}>
                        <Form.Item label="Conta Corrente Origem" name="ctc_id_origem" rules={[{ required: true, message: 'Selecione uma Conta Corrente Origem' }]}>
                            <Select showSearch options={listaContaCorrente.map((item) => {
                                return { label: item.ctc_descricao, value: item.ctc_id, key: item.ctc_id }
                            })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione uma Conta Corrente Origem" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={16} md={10} lg={10} xl={7} xxl={6}>
                        <Form.Item label="Conta Gerencial Origem" name="ctg_id_origem" rules={[{ required: true, message: 'Selecione uma Conta Gerencial Origem' }]}>
                            <Select showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial Origem">
                                {listaContaGerencialAgrupadorOrigem.map(item => (
                                    <Select.OptGroup label={item.ctg_descricao}>
                                        {listaContaGerencialOrigem.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                            <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} xl={6} xxl={4}>
                        <Form.Item label="Nº do Documento" name="mcc_numerodocumento">
                            <InputNumber
                                step={1}
                                min={1}
                                placeholder="Informe o Número do documento"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={9} md={11} lg={11} xl={6} xxl={4}>
                        <Form.Item label="Complemento" name="mcc_complemento">
                            <Input placeholder="Informe o Complemento do Lançamento" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={7} md={5} lg={5} xl={6} xxl={4}>
                        <InputPreco name={'mcc_valor'} label={'Valor (R$)'} />
                    </Col>
                </Row>
                <Row gutter={[8, 8]} className="m-t-8">
                    <Col span={24}>
                        <Typography.Title level={3}>
                            <img src={require("../../assets/i-tranferencia.png").default} alt="Para" /> Para
                        </Typography.Title>
                        <Divider orientation="left" />
                    </Col>
                </Row>
                <Row gutter={[8, 0]} className="m-t-16">
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Matriz/Filial de Destino" name="loc_id" rules={[{ required: true, message: 'Selecione a Matriz/Filial de destino' }]} >
                            <Select showSearch onChange={(value) => listarContaDestino(value)} options={listaFiliais.map((item) => {
                                return { label: item.loc_descricao, value: item.loc_id, key: item.loc_id }
                            })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione a Matriz/Filial de destino" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Conta Corrente Destino" name="ctc_id_destino" rules={[{ required: true, message: 'Selecione uma Conta Corrente Destino' }]}>
                            <Select showSearch options={listaContaCorrenteDestino.map((item) => {
                                return { label: item.ctc_descricao, value: item.ctc_id, key: item.ctc_id }
                            })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione uma Conta Corrente Destino" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Conta Gerencial Destino" name="ctg_id_destino" rules={[{ required: true, message: 'Selecione uma Conta Gerencial Destino' }]}>
                            <Select showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial Destino">
                                {listaContaGerencialAgrupadorDestino.map(item => (
                                    <Select.OptGroup label={item.ctg_descricao}>
                                        {listaContaGerencialDestino.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                            <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                        ))}
                                    </Select.OptGroup>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </FormGW>
        </div>
    )
}