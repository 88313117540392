import React, { useEffect, useState } from "react";
import { Row, Col, Form, Tag, Button, Table, Modal, Divider, Select } from "antd";
import { DeleteOutlined, PlusOutlined, ExclamationCircleOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

import { SelectPagination } from "../../components";
import { FormatFone, MaskFormat } from "../../ValueObjects";
import SelectPaginado from "../../components/selectPaginado";
import api from "../../services/api";

export default function TabCliente({ formulario }) {

    const [form] = Form.useForm();
    const [clientes, setClientes] = useState(() => {
        if (formulario.getFieldValue('ltp_id')) {
            let list = formulario.getFieldValue('clientes');
            return list ? list : [];
        }
        return []
    });

    useEffect(() => {
        formulario.setFieldsValue({ clientes: clientes });
    }, [clientes])

    async function onAddClienteList() {
        let cliente = (await api.get(`Cliente/BuscarCliente/${formulario.getFieldValue().cli_id}`)).data;
        cliente.ltp_id = formulario.getFieldValue('ltp_id');
        let array = [...clientes];
        let index = array.findIndex(x => x.cli_id == cliente.cli_id);
        if (index === -1)
            setClientes((prev) => ([...prev, { ...cliente }]));

        form.resetFields();
    }

    function onDelete(value) {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja excluir o cliente selecionado?`,
            okText: 'Excluir',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                deleteCliente(value);
            }
        })
    }

    const deleteCliente = (value) => {
        let array = [...clientes];
        let index = array.findIndex(x => x.cli_id == value.cli_id);
        if (index !== -1) {
            array.splice(index, 1);
            setClientes(array);
        }
    }

    return (
        <div>
            <Form layout="vertical" form={form} onFinish={onAddClienteList}>
                <Row gutter={[8, 8]}>
                    <Col xs={24} sm={18} md={19} lg={20} xl={20}>
                        <Form.Item name="cli_id" label="Selecione o CLiente">
                            <SelectPaginado url="Cliente/Listar" placeholder="Filtrar por Cliente..." form={formulario} name="cli_id"
                                conteudo={
                                    c => (<Select.Option value={c.cli_id} key={c.key}>{c.cli_id} - {c.pes_nome}</Select.Option>)
                                } />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={5} lg={4} xl={4}>
                        <Button type="primary" htmlType="submit" block icon={<PlusOutlined />} className="t-mob-573">
                            Adicionar
                        </Button>
                    </Col>
                </Row>
            </Form>
            <div className="tabela mt-dif">
                <Table columns={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ cli_codigo }) => (
                            <Tag color="processing" className="w-100">
                                <b>{cli_codigo}</b>
                            </Tag>
                        ),
                    },
                    {
                        title: 'Nome',
                        render: ({ pes_nome }) => (
                            <div>
                                <b>{pes_nome}</b>
                            </div>
                        )
                    },
                    {
                        title: 'CPF/CNPJ',
                        width: 138,
                        render: ({ pef_cpf, pej_cnpj }) => (
                            <div>
                                <b>{!!pef_cpf ? MaskFormat(pef_cpf, 'Não Informado', true) : MaskFormat(pej_cnpj, 'Não Informado', true)}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Telefone',
                        width: 120,
                        render: ({ psc_numero }) => (
                            <div>
                                <b>{FormatFone(psc_numero) || 'Não Informado'}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        align: 'center',
                        fixed: 'right',
                        key: 'x',
                        width: 65,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button onClick={() => onDelete(record)} icon={<DeleteOutlined />} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} expandedRowRender={({ log_logradouro, pee_numero, bai_nome, cep_cep, cid_descricao, est_sigla, pem_email }) => {
                    return (
                        <div>
                            <Row align="middle" gutter={[8, 0]}>
                                <Col>
                                    Endereço: <b>{log_logradouro || 'Não Informado'}{(!!pee_numero) ? (', ' + pee_numero) : ''}{!!(bai_nome) ? (', ' + bai_nome) : ''} {!!(cep_cep) ? ('- ' + cep_cep) : ''} {!!(cid_descricao) ? ('- ' + cid_descricao) : ''} {!!(est_sigla) ? ('- ' + est_sigla) : ''}</b>
                                </Col>
                                <Col>
                                    E-mail: <b>{pem_email || 'Não Informado'}</b>
                                </Col>
                            </Row>
                        </div>
                    )
                }} expandIcon={({ record, expanded, onExpand }) => {
                    return expanded ? (
                        <ArrowUpOutlined onClick={(e) => onExpand(record, e)} />
                    ) : (
                        <ArrowDownOutlined onClick={(e) => onExpand(record, e)} />
                    );
                }} columnWidth={30} dataSource={clientes} locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                </Divider>
                            </Col>
                        </Row>)
                }} />
            </div>
        </div >
    );
}