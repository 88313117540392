import React, { useState, useEffect } from "react";
import { Tabs, Row, Col, Form, Input, Select, Checkbox, notification } from "antd";

import api from '../../services/api';
import { FormGW, SelectPaginacao } from "../../components";
import { useStateValue } from '../../state';
import { TabConfigConta } from './tabPage.js';
import { listagemActions } from "../../actions";

export default function ManutencaoContaGerencial({ formulario, carregando, aoSalvar }) {

    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [listarContaDRE, setListarContaDRE] = useState([]);
    const [{ manutencao }, dispatch] = useStateValue();
    const [listaConfigContaGerencial, setListaConfigContaGerencial] = useState([]);

    useEffect(() => {
        api.get(`Dre/ListarTodas`).then(res => {
            setListarContaDRE(res.data);
        })
    }, []);

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.conta) {
            let index = 1;
            let dados = manutencao.dados.conta;
            dados.map((item) => {
                item.key = index;
                index++;
            });
            setListaConfigContaGerencial(dados);
        }
    }, [manutencao.dados]);

    function salvarContaGerencial(values) {
        values.conta = listaConfigContaGerencial;
        carregando(true);
        if (!!values.ctg_id) {
            editarManutencao(values);
        } else {
            incluirManutencao(values);
        }
    };

    function editarManutencao(values) {
        api.put(`ContaGerencial/Editar`, values).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Os dados da Conta Gerencial foram salvos com sucesso!" });

            }).catch(
                err => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
                }).finally(
                    () => {
                        dispatch({ type: listagemActions.CHANGE, data: { ordem: '' } });
                        carregando(false);
                        aoSalvar();
                    });
    }

    function incluirManutencao(values) {
        api.post(`ContaGerencial/Incluir`, values).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Os dados da Conta Gerencial foram salvos com sucesso!" });
            }).catch(
                err => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
                }).finally(
                    () => {
                        dispatch({ type: listagemActions.CHANGE, data: { ordem: '' } });
                        carregando(false);
                        aoSalvar();
                    });
    }

    return (
        <div>
            <FormGW layout="vertical" form={formulario} name="manutencaoContaGerencial" onFinish={salvarContaGerencial} >
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Dados Gerais" key="1">
                        <Row align="middle" gutter={[8, 0]}>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
                                <Form.Item name="ctc_id" hidden />
                                <Form.Item name="ctg_id" hidden />
                                <Form.Item label="Código da Categoria da Conta Gerencial" name="ctc_id" rules={[{ required: true, message: 'Selecione a Categoria da Conta Gerencial' }]}>
                                    <SelectPaginacao url="CategoriaContaGerencial/Listar"
                                        allowClear={true}
                                        selecionarRegUnico={"ctc_id"}
                                        nameLabel="ctc_descricao"
                                        mostrarCodigoLabel={true}
                                        nameValue="ctc_id"
                                        placeholder="Selecione a Categoria da Conta Gerencial"
                                        form={formulario}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <Form.Item label="Descrição Reduzida da Conta" name="ctg_descricaoResumida" rules={[{ required: true, message: 'Informe a Descrição Reduzida da Conta Gerencial' }]}>
                                    <Input maxLength={14} placeholder="Informe a Descrição Reduzida da Conta Gerencial" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <Form.Item label="Descrição Detalhada da Conta Gerencial" name="ctg_descricao" rules={[{ required: true, message: 'Informe a Descrição da Conta Gerencial' }]}>
                                    <Input placeholder="Informe a Descrição Detalhada da Conta Gerencial" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Código da Conta na DRE - Demonstração do Resultado do Exercício" name="dre_id" >
                                    <Select allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione a Conta na DRE">
                                        {listarContaDRE?.map(item => (
                                            <Select.Option key={item.dre_id} value={item.dre_id}>{item.dre_id} - {item.dre_descricao}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item label="Observações Complementares da Conta Gerencial" name="ctg_observacoes">
                                    <Input.TextArea placeholder="Informe as Observações Referente a Conta Gerencial" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                <Form.Item name="ctg_ativo" valuePropName="checked" initialValue={true}
                                    hidden={(!(formulario.getFieldValue(['ctg_id']) > 0))}>
                                    <Checkbox> Conta Gerencial Ativa? </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Configuração das Contas" key="2">
                        <TabConfigConta formContaGerencial={formulario} listaConfigContaGerencial={listaConfigContaGerencial} setListaConfigContaGerencial={setListaConfigContaGerencial} />
                    </Tabs.TabPane>
                </Tabs>
            </FormGW>
        </div>
    );
}
