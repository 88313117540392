import { FormatNumber } from "../ValueObjects";
import api from "./api";
import { getEmpresa } from "./auth";
import jsreports from "jsreports/jsreports-all";
import { carregarConfiguracoes, imprimirRawBase64 } from "./servidorImpressora";
import { substituirCaracterEspecial } from "./funcoes";

const squemaEtiquetaProduto = [
    {
        nome: 'Codigo_Empresa',
        tipo: 'number'
    },
    {
        nome: 'Empresa',
        tipo: 'string'
    },
    {
        nome: 'Codigo_Produto',
        tipo: 'number'
    },
    {
        nome: 'Produto',
        tipo: 'string'
    },
    {
        nome: 'Grade',
        tipo: 'string'
    },
    {
        nome: 'Marca',
        tipo: 'string'
    },
    {
        nome: 'Preco',
        tipo: 'string'
    },
    {
        nome: 'Ean',
        tipo: 'string'
    },
    {
        nome: 'Codigo_Etiqueta',
        tipo: 'string'
    },
    {
        nome: 'Codigo_Referencia',
        tipo: 'string'
    },
    {
        nome: 'Observacao',
        tipo: 'string'
    },
    {
        nome: 'Quantidade_Parcela',
        tipo: 'string'
    },
    {
        nome: 'Valor_Parcelado',
        tipo: 'string'
    }
];


export async function imprimirEtiquetaImp(modelo, listaItens) {
    let listaEtiqueta = [];
    let comandosEnviarImp = [];
    let config = await carregarConfiguracoes();
    listaItens.forEach((item) => {
        let indice = 0;
        while (indice < item.quantidade) {
            let etiqueta = {
                pro_codigo: item.pro_codigo,
                pro_codigoetiqueta: item.pro_codigoetiqueta,
                prg_codigoetiqueta: item.prg_codigoetiqueta,
                pro_referencia: item.pro_referencia,
                grade: item.grade,
                pro_ean: !!item.prg_ean ? item.prg_ean : item.pro_ean,
                lpi_valorvenda: item.lpi_valorvenda,
                pro_descricao: item.pro_descricao
            }
            listaEtiqueta.push(etiqueta);
            indice++;
        }
    });

    let qtdComandos = parseInt(listaEtiqueta.length / modelo.met_colunas);
    for (let x = 0; x <= qtdComandos; x++) {
        let comando = modelo.met_comando;
        for (let i = 1; i <= modelo.met_colunas; i++) {
            let indiceEtiqueta = i + (x * modelo.met_colunas);
            if (indiceEtiqueta <= listaEtiqueta.length) {
                let indice = indiceEtiqueta - 1;
                /* let configCodBarra = formulario.getFieldValue().persCodBarra;
                 if (configCodBarra === 1) {
                     comando = comando.replace('@codigoBarras' + i, listaEtiqueta[indice].pro_codigo);
                 } else if (configCodBarra === 2) {
                     comando = comando.replace('@codigoBarras' + i, !!listaEtiqueta[indice].pro_codigoetiqueta ? listaEtiqueta[indice].pro_codigoetiqueta : listaEtiqueta[indice].prg_codigoetiqueta);
                 } else {*/
                comando = comando.replace('@codigoBarras' + i, listaEtiqueta[indice].pro_ean);
                //}
                comando = comando.replace('@precoProduto' + i, 'R$ ' + parseFloat(listaEtiqueta[indice].lpi_valorvenda).toFixed(2).replace('.', ','));
                /*if (dadosAdicionais) {
                    let entrada = 1;
                    let qtdParc = formulario.getFieldValue().numeroParcelas
                    if (formulario.getFieldsValue().possuiEntrada) {
                        comando = comando.replace('@formaPagamento' + i, `${qtdParc}x(${entrada}+${qtdParc - entrada})`);
                    } else {
                        comando = comando.replace('@formaPagamento' + i, `${qtdParc}x`);
                    }
                    comando = comando.replace('@valorPagamento' + i, FormatNumber(listaEtiqueta[indice].lpi_valorvenda / qtdParc, true));
                } else {*/
                comando = comando.replace('@formaPagamento' + i, ' ');
                comando = comando.replace('@valorPagamento' + i, ' ');
                //}

                comando = comando.replace('@codigoProduto' + i, !!listaEtiqueta[indice].pro_codigo ? listaEtiqueta[indice].pro_codigo : ' ');
                comando = comando.replace('@codigoEtiqueta' + i, !!listaEtiqueta[indice].pro_codigoetiqueta ? listaEtiqueta[indice].pro_codigoetiqueta : ' ');
                comando = comando.replace('@referenciaProduto' + i, !!listaEtiqueta[indice].pro_referencia ? listaEtiqueta[indice].pro_referencia : ' ');

                if (!!listaEtiqueta[indice].descricaograde) {
                    comando = comando.replace('@gradeProduto' + i + 'Linha1', listaEtiqueta[indice].descricaograde.substr(0, parseInt(modelo.met_quantidadecaracteresdescricaoproduto)));
                    if (listaEtiqueta[indice].descricaograde.length > parseInt(modelo.met_quantidadecaracteresdescricaoproduto)) {
                        comando = comando.replace('@gradeProduto' + i + 'Linha2', listaEtiqueta[indice].descricaograde.substr(parseInt(modelo.met_quantidadecaracteresdescricaoproduto), parseInt(modelo.met_quantidadecaracteresdescricaoproduto)));
                    } else {
                        comando = comando.replace('@gradeProduto' + i + 'Linha2', ' ');
                    }
                } else {
                    comando = comando.replace('@gradeProduto' + i + 'Linha1', ' ');
                    comando = comando.replace('@gradeProduto' + i + 'Linha2', ' ');
                }

                comando = comando.replace('@descricaoProduto' + i + 'Linha1', listaEtiqueta[indice].pro_descricao.substr(0, parseInt(modelo.met_quantidadecaracteresdescricaoproduto)));

                if (listaEtiqueta[indice].pro_descricao.length > parseInt(modelo.met_quantidadecaracteresdescricaoproduto)) {
                    comando = comando.replace('@descricaoProduto' + i + 'Linha2', listaEtiqueta[indice].pro_descricao.substr(parseInt(modelo.met_quantidadecaracteresdescricaoproduto), parseInt(modelo.met_quantidadecaracteresdescricaoproduto)));
                } else {
                    comando = comando.replace('@descricaoProduto' + i + 'Linha2', ' ');
                }

                comando = substituirCaracterEspecial(comando);

            } else {
                comando = comando.replace('@codigoProduto' + i, ' ');
                comando = comando.replace('@codigoEtiqueta' + i, ' ');
                comando = comando.replace('@referenciaProduto' + i, ' ');
                comando = comando.replace('@codigoBarras' + i, ' ');
                comando = comando.replace('@precoProduto' + i, ' ');
                comando = comando.replace('@formaPagamento' + i, ' ');
                comando = comando.replace('@valorPagamento' + i, ' ');
                comando = comando.replace('@gradeProduto' + i + 'Linha1', ' ');
                comando = comando.replace('@gradeProduto' + i + 'Linha2', ' ');
                comando = comando.replace('@descricaoProduto' + i + 'Linha1', ' ');
                comando = comando.replace('@descricaoProduto' + i + 'Linha2', ' ');
            }
        }
        comandosEnviarImp.push(comando);
    }

    comandosEnviarImp.reduce(function (p, val) {
        return p.then(function () {

            return imprimirRawBase64(config.impressoraEtiqueta.name, val);
        });
    }, Promise.resolve());
};

export function imprimirImpressoraPadrao(idModelo, listaItens) {

    buscarDadosEmpresa((dadosEmpresa) => {
        api.get(`RelatorioModeloEtiqueta/Listar?Id=${idModelo}`).then(
            res => {
                montarEtiquetaImpressora(res.data, listaItens, dadosEmpresa);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    });
};

function buscarDadosEmpresa(callback) {
    api.get(`Empresa/Buscar/${getEmpresa()}`).then(
        res => {
            if (callback) {
                callback(res.data);
            }
        }
    ).catch(
        erro => {
            console.log(erro);
        }
    );
};

function montarEtiquetaImpressora(dadosRel, listaItens, dadosEmpresa) {
    let qtd = dadosRel.quantidadeColuna;
    let qtdTot = 0;
    let campos = [];
    let dadosCampos = [];
    let listaDadosImpressora = [];
    let numeroColuna = 1;
    let dadosAux = [];
    let dadosImpressora = {
        id: "etiqueta",
        name: "etiqueta",
    };
    listaItens.forEach((itens) => {
        qtdTot += itens.quantidade;
    });
    squemaEtiquetaProduto.forEach((squema) => {
        for (let i = 1; i <= qtd; i++) {
            campos.push({ name: `${squema.nome}_Coluna_${i}`, type: squema.tipo })
        }
    });

    listaItens.forEach((produto) => {
        for (let idx = 1; idx <= produto.quantidade; idx++) {
            let valorParcelado = 0;
            let quantidadeParcela = '';
            /*if (dadosAdicionais) {
                let qtdParcelas = !!formulario.getFieldValue().numeroParcelas ? formulario.getFieldValue().numeroParcelas : 1;
                if (!!formulario.getFieldValue().possuiEntrada) {
                    quantidadeParcela = `${qtdParcelas}x(1+${qtdParcelas - 1})`;
                } else {
                    quantidadeParcela = qtdParcelas + 'x';
                }

                valorParcelado = FormatNumber(produto.lpi_valorvenda / qtdParcelas, true);
            }*/
            dadosAux.push({
                ['Codigo_Empresa_Coluna_' + numeroColuna]: getEmpresa(),
                ['Empresa_Coluna_' + numeroColuna]: dadosEmpresa.emp_nomefantasia,
                ['Codigo_Produto_Coluna_' + numeroColuna]: produto.pro_codigo,
                ['Produto_Coluna_' + numeroColuna]: produto.pro_descricao,
                ['Grade_Coluna_' + numeroColuna]: produto.descricaograde,
                ['Marca_Coluna_' + numeroColuna]: produto.mar_nome,
                ['Preco_Coluna_' + numeroColuna]: FormatNumber(produto.lpi_valorvenda, true),
                ['Ean_Coluna_' + numeroColuna]: !!produto.prg_ean ? produto.prg_ean : produto.pro_ean,
                ['Codigo_Etiqueta_Coluna_' + numeroColuna]: produto.pro_codigoetiqueta,
                ['Codigo_Referencia_Coluna_' + numeroColuna]: produto.pro_referencia,
                ['Observacao_Coluna_' + numeroColuna]: produto.observacao,
                ['Quantidade_Parcela_Coluna_' + numeroColuna]: quantidadeParcela,
                ['Valor_Parcelado_Coluna_' + numeroColuna]: valorParcelado
            });

            if (numeroColuna === qtd || (qtdTot < qtd && qtdTot === numeroColuna)) {
                if (qtdTot < qtd) {
                    numeroColuna++;
                    for (let idx = numeroColuna; idx <= qtd; idx++) {
                        dadosAux.push(retornaEtiquetaEmBranco(idx));
                    }
                }
                dadosCampos.push(retornaObjeto(dadosAux));
                qtdTot = qtdTot - qtd;
                dadosAux = [];
                numeroColuna = 1;
            } else {
                numeroColuna++;
            }
        }
    });
    dadosImpressora.data = dadosCampos;
    dadosImpressora.schema = {
        fields: campos
    };

    listaDadosImpressora.push(dadosImpressora);
    jsreports.export({
        report_def: JSON.parse(dadosRel.jsonRelatorio),
        datasets: listaDadosImpressora,
        format: 'pdf',
        outputHandler: function (dados) {
            window.open(URL.createObjectURL(dados), '_blank');
        }
    })
};

function retornaEtiquetaEmBranco(numeroColuna) {
    return {
        ['Codigo_Empresa_Coluna_' + numeroColuna]: null,
        ['Empresa_Coluna_' + numeroColuna]: '',
        ['Codigo_Produto_Coluna_' + numeroColuna]: null,
        ['Produto_Coluna_' + numeroColuna]: '',
        ['Grade_Coluna_' + numeroColuna]: '',
        ['Marca_Coluna_' + numeroColuna]: '',
        ['Preco_Coluna_' + numeroColuna]: null,
        ['Ean_Coluna_' + numeroColuna]: '',
        ['Codigo_Etiqueta_Coluna_' + numeroColuna]: null,
        ['Codigo_Referencia_Coluna_' + numeroColuna]: '',
        ['Observacao_Coluna_' + numeroColuna]: '',
        ['Quantidade_Parcela_Coluna_' + numeroColuna]: null,
        ['Valor_Parcelado_Coluna_' + numeroColuna]: null
    }
};

function retornaObjeto(listaObj) {
    var objRetorno = {};
    for (var i = 0; i < listaObj.length; i++) {
        for (var attrname in listaObj[i]) {
            objRetorno[attrname] = listaObj[i][attrname];
        }
    }
    return objRetorno;
}