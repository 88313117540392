import React, { useEffect, useState } from "react";
import { Row, Col, Descriptions, Form, Input, Modal, notification } from "antd";
import { ExclamationCircleOutlined, ExceptionOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../../services/api";
import { MaskFormat, FormatNumber } from "../../../ValueObjects";

export default function ModalInutilizarNota({ dadosNota, exibirModal, fecharModal }) {
    const [form] = Form.useForm();
    const [carregando, setCarregando] = useState(false);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                form.resetFields();
                fecharModal();
            }
        });
    };

    const inutilizarNota = () => {
        let dados = { ...dadosNota };
        let obj = {};
        obj.ntf_id = dados.ntf_id;
        obj.justificativa = form.getFieldValue().justificativa;
        if (!obj.justificativa || obj.justificativa.length < 15) {
            notification.warning({ message: `Aviso!`, description: `A Justificativa deve ter no mínimo 15 caracteres!` })
            return false;
        }
        setCarregando(true);
        api.post(`NotaFiscal/Inutilizar`, obj).then(
            res => {
                let _cStat = res.data.retorno.infInut.cStat;
                let motivo = _cStat + '-' + res.data.retorno.infInut.xMotivo;
                if (_cStat == 102 || _cStat == 135 || _cStat == 206) {
                    notification.success({ message: `NF-e Nº: ${dadosNota.ntf_numero} inutiliazada com sucesso !`, description: motivo });
                } else {
                    notification.warning({ message: `Não foi possível efetuar a inutilização da NF-e Nº: ${dadosNota.ntf_numero}, ` + motivo, description: motivo });
                }
            }
        ).catch(
            erro => {
                notification.warning({ description: `Não foi possível inutilizar a NF-e Nº: ${dadosNota.ntf_numero} !`, message: 'Aviso' });
                setCarregando(false);
            }
        ).finally(
            () => {
                setCarregando(false);
                form.resetFields();
                fecharModal();
            }
        );
    };

    return (
        <Modal centered
            title='Inutilizar Nota'
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={inutilizarNota}
            confirmLoading={carregando}
            okText={
                <>
                    <ExceptionOutlined /> Inutilizar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            destroyOnClose
        >
            <div>
                <div>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Descriptions>
                                <Descriptions.Item label="Chave da NF-e">
                                    <b> {dadosNota.ntf_chavenfe} </b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Descriptions>
                                <Descriptions.Item label="Destinatário">
                                    <b> {dadosNota.ntf_nomedest}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Descriptions>
                                <Descriptions.Item label="CPF/CNPJ">
                                    <b>{MaskFormat(dadosNota.ntf_cnpjcpfdest, '', true)}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Descriptions>
                                <Descriptions.Item label="Nº da Nota">
                                    <b>{dadosNota.ntf_numero}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Descriptions>
                                <Descriptions.Item label="Série">
                                    <b>{dadosNota.ntf_serie}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Descriptions>
                                <Descriptions.Item label="Modelo">
                                    <b>{dadosNota.ntf_modelo}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Descriptions>
                                <Descriptions.Item label="Emitida em">
                                    <b>{moment(dadosNota.ntf_dataemissao).format('DD/MM/YYYY')}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Descriptions>
                                <Descriptions.Item label="Tipo de Nota">
                                    <b>{dadosNota.tip_descricao}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Descriptions>
                                <Descriptions.Item label="Valor Total da Nota (R$)">
                                    <b> {FormatNumber(dadosNota.valor_total, true)} </b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Form layout="vertical" form={form}>
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={24}>
                                <Form.Item label="Motivo da Inutilização" name="justificativa" rules={[{ required: true, message: `Informe a Justificativa da Inutilização` }]} >
                                    <Input.TextArea placeholder="Informe aqui o motivo da Inutilização da Nf-e" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </Modal >
    );
}