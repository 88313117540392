import React from "react";
import { Row, Col, Form, Modal, Checkbox, Input } from "antd";
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

export default function ModalContrato({ exibirModalContrato, fecharModalContrato, urlContrato, testeGratuito, cadastrarEmpresa, assinarContrato, codigoSeguranca }) {
    const [formulario] = Form.useForm();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalContrato();
            }
        });
    };

    function onFinish(values) {
        if (codigoSeguranca !== values.codigoSeguranca) {
            Modal.error({
                title: 'Aviso!',
                content: 'Código de segurança informado diferente do código presente no contrato!'
            });
            return false;
        }
        if (!values.confirmacaoLeitura) {
            Modal.error({
                title: 'Aviso!',
                content: 'É necessário confirmar a leitura!'
            });
            return false;
        }

        if (testeGratuito) {
            cadastrarEmpresa();
        } else {
            assinarContrato();
        }
    };

    return (
        <Modal centered
            title={testeGratuito ? "Gestor - Termo de Uso - Teste Gratuito (15 DIAS)" : "Contrato de Licença de Usuário Final (EULA) e de Prestação de Serviços"}
            visible={exibirModalContrato}
            onCancel={onCloseModal}
            onOk={() => formulario.submit()}
            okText={
                <>
                    <CheckOutlined /> Aceito os Termos
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            className="modalPDF"
        >
            <Form layout="vertical" form={formulario} onFinish={onFinish}>
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <iframe width="100%" height="530px" src={!!urlContrato ? urlContrato : require("../../../assets/Contrato_NewERP.pdf").default} ></iframe>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="confirmacaoLeitura" valuePropName="checked" rules={[{ required: true, message: 'É necessário confirmar a leitura!' }]}>
                            <Checkbox>{testeGratuito ? 'Declaro que li e estou de acordo com os termos de uso do teste gratuito.' : 'Declaro para os devidos fins que li e concordo com os termos dispostos no CONTRATO DE LICENÇA DE USUÁRIO FINAL (EULA) E DE PRESTAÇÃO DE SERVIÇOS do GestorWeb.'} </Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.confirmacaoLeitura !== currentValues.confirmacaoLeitura}>
                    {({ getFieldValue }) => {
                        if (getFieldValue('confirmacaoLeitura')) return (
                            <Row gutter={[0, 8]}>
                                <Col span={14}>
                                    <Form.Item label="Insira o código de confirmação de leitura que se encontra ao final do contrato exibido acima." name="codigoSeguranca" rules={[{ required: true, message: 'Favor preencher o código de segurança!' }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        );
                        else return null;
                    }}
                </Form.Item>
            </Form>
        </Modal>
    );
}