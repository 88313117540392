import React, { useEffect, useState } from 'react';
import moment from 'moment';

import api from '../../../services/api';
import { Col,  Row } from 'antd';
import { FormatNumber } from '../../../ValueObjects';

export default function FluxoCaixaAnualLocalSintetico({valoresProvisionados, tabelaFluxoCaixa, saldos, form, filtros }) {

    const [saldoInicial, setSaldoInicial] = useState();
    const [saldoFinal, setSaldoFinal] = useState();
    const [listaInicial, setListaInicial] = useState([]);
    const [listaFinal, setListaFinal] = useState([]);

    useEffect(() => {
        if (!!filtros) {
            tratarDados(filtros);
        }
    }, []);

    async function tratarDados(filtros) {
        api.get(`FluxoCaixa/SaldoInicial?caixaPeriodo=${filtros.fluxoPeriodo}&caixaApresentacao=${filtros.fluxoApresentacao}
            &caixaOrganizacao=${filtros.fluxoOrganizacao}&dataInicial=${filtros.dataInicial}&dataFinal=${filtros.dataFinal}`).then(
            res => {
                if (!!res.data) {
                    let dadosInicial = res.data;
                    let saldoInicial = dadosInicial.map(item =>
                        createInicial(
                            item.codigoLocal,
                            item.CodigoLocal,
                            item.contaCorrente,
                            item.nomeContaCorrente,
                            item.saldo,
                            item.saldoConta,
                            item.saldoInicial,
                            item.totalSaldo,
                            item.totalSaldoInicial,
                            item.totalSaldosConta
                        )
                    );
                    setListaInicial(dadosInicial);
                }
            }
        ).catch(err => {
            console.log(err);
        }
        );
        api.get(`FluxoCaixa/SaldoFinal?caixaPeriodo=${filtros.fluxoPeriodo}&caixaApresentacao=${filtros.fluxoApresentacao}&caixaOrganizacao=${filtros.fluxoOrganizacao}
                &dataInicial=${filtros.dataInicial}&dataFinal=${filtros.dataFinal}`).then(
            res => {
                if (!!res.data) {
                    let dadosFinal = res.data;
                    setSaldoFinal(res.data[0].totalSaldosConta);
                    setSaldoInicial(res.data[0].totalSaldoInicial);
                    let saldoFinal = dadosFinal.map(item =>
                        createFinal(
                            item.codigoLocal,
                            item.CodigoLocal,
                            item.contaCorrente,
                            item.nomeContaCorrente,
                            item.saldo,
                            item.saldosConta,
                            item.saldoInicial,
                            item.totalSaldo,
                            item.totalSaldoInicial,
                            item.totalSaldosConta
                        )
                    );
                    setListaFinal(saldoFinal);
                }
            }
        ).catch(err => {
        }
        );
    }

    function createFinal(codigoLocal, CodigoLocal, contaCorrente, nomeContaCorrente, saldo, saldosConta, saldoInicial, totalSaldo, totalSaldoInicial, totalSaldosConta) {
        return { codigoLocal, CodigoLocal, contaCorrente, nomeContaCorrente, saldo, saldosConta, saldoInicial, totalSaldo, totalSaldoInicial, totalSaldosConta }
    };
    function createInicial(codigoLocal, CodigoLocal, contaCorrente, nomeContaCorrente, saldo, saldosConta, saldoInicial, totalSaldo, totalSaldoInicial, totalSaldosConta) {
        return { codigoLocal, CodigoLocal, contaCorrente, nomeContaCorrente, saldo, saldosConta, saldoInicial, totalSaldo, totalSaldoInicial, totalSaldosConta }
    };

    return (
        <div>
            <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', align: 'center' }}>
                <Col align="center"><b>Fluxo de Caixa de {moment(filtros.dataInicial).format('DD/MM/YYYY')} a {moment(filtros.dataFinal).format('DD/MM/YYYY')}</b></Col>
            </div>
            {/* <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '14px', align: 'center' }}>
                <div>
                    <Col align="center">
                        <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>
                            Contas: <span style={{ fontWeight: 'normal', align: 'rigth' }}>Loja Matriz</span>
                        </div>
                        {listaFinal.map((row, index) => (
                            <div key={index} style={{ marginBottom: '5px', align: 'rigth' }}>
                                <span>{row.nomeContaCorrente}: </span>
                                <span style={{ fontWeight: 'bold', color: '#046ab4', align: 'rigth' }}>
                                    {FormatNumber(row.saldoInicial, true)}
                                </span>
                                <span style={{ marginLeft: '20px', align: 'rigth' }}>{row.nomeContaCorrente}: </span>
                                <span style={{ fontWeight: 'bold', color: '#046ab4', align: 'rigth' }}>
                                    {FormatNumber(row.saldosConta, true)}
                                </span>
                            </div>
                        ))}
                        <div style={{ marginTop: '10px', fontWeight: 'bold' }}>
                            SALDO INICIAL: <span style={{ color: '#046ab4' }}>{FormatNumber(saldoInicial, true)}</span>
                            <span style={{ marginLeft: '30px' }}>
                                SALDO FINAL: <span style={{ color: '#046ab4' }}>{FormatNumber(saldoFinal, true)}</span>
                            </span>
                        </div>
                    </Col>
                </div>
            </div> */}
            <div>
                <div>
                    {!valoresProvisionados &&
                        <div className="tabela-fluxo m-t-19">
                            <Row className="b-color-secondary col-layout c-black t-head">
                                {/* Cabeçalho da Tabela */}
                                <Col align="start" style={{ width: '20%' }}><b>DATA</b></Col>
                                <Col align="right" style={{ width: '40%' }}><b>ENTRADA</b></Col>
                                <Col align="right" style={{ width: '40%' }}><b>SAÍDA</b></Col>
                            </Row>

                            <div className="t-body p-0">
                                {/* Mapeando os dados do fluxo de caixa */}
                                {tabelaFluxoCaixa.map((fluxoLocal, index) => (
                                    <Row key={index} className="row">
                                        <Col align="start" style={{ width: '20%' }}>
                                            {fluxoLocal.ano}
                                        </Col>
                                        <Col align="right" style={{ width: '40%' }}>
                                            {FormatNumber(fluxoLocal.realizadoEntradaAno, true, false)}
                                        </Col>
                                        <Col align="right" style={{ width: '40%' }}>
                                            {FormatNumber(fluxoLocal.realizadoSaidaAno, true, false)}
                                        </Col>
                                    </Row>
                                ))}

                                {/* Linha de Totais */}
                                <Row className="c-primary row">
                                    <Col style={{ width: '20%' }}><b>TOTAL DO PERÍODO</b></Col>
                                    <Col align="right" style={{ width: '40%' }}>
                                        <b>{FormatNumber(saldos.realizadoEntrada, true)}</b>
                                    </Col>
                                    <Col align="right" className="c-red" style={{ width: '40%' }}>
                                        <b>{FormatNumber(saldos.realizadoSaida, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    }
                    {valoresProvisionados &&
                        <div className="tabela-fluxo m-t-19">
                            <Row className="b-color-secondary col-layout c-black t-head">
                                {/* <Col span={6}><b>FLUXO DE CAIXA DE <span className="f-14">{moment(form.getFildValue().flx_mesInicial).format('DD/MM/YYYY')}</span> A <span className="f-14">{moment(form.getFildValue().flx_mesFinal).format('DD/MM/YYYY')}</span></b></Col> */}
                                <Col align="start" style={{ width: '20%' }}><b>DATA</b></Col>
                                <Col style={{ width: '40%' }}>
                                    <Row>
                                        <Col style={{ width: '80%' }} align="right"><b>ENTRADA</b></Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ width: '20%' }} align="right" ><b></b></Col>
                                        <Col style={{ width: '30%' }} align="right" ><b>PREVISTO</b></Col>
                                        <Col style={{ width: '10%' }} align="right" ><b></b></Col>
                                        <Col style={{ width: '40%' }} align="right" ><b>REALIZADO</b></Col>
                                    </Row>
                                </Col>
                                <Col style={{ width: '40%' }}>
                                    <Row>
                                        <Col align="right" style={{ width: '80%' }} ><b>SAÍDA</b></Col>
                                    </Row>
                                    <Row>
                                        <Col style={{ width: '20%' }} align="right" ><b></b></Col>
                                        <Col style={{ width: '30%' }} align="right" ><b>PREVISTO</b></Col>
                                        <Col style={{ width: '10%' }} align="right" ><b></b></Col>
                                        <Col style={{ width: '40%' }} align="right" ><b>REALIZADO</b></Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className="t-body p-0">
                                {/* Mapeando os dados do fluxo de caixa */}
                                {tabelaFluxoCaixa.map((fluxoLocal, index) => (
                                    <Row key={index} className="row">
                                        <Col align="start" style={{ width: '20%' }}>
                                            {fluxoLocal.ano}
                                        </Col>
                                        <Col align="right" style={{ width: '20%' }}>
                                            {FormatNumber(fluxoLocal.prevReceberAno, true, false)}
                                        </Col>
                                        <Col align="right" style={{ width: '20%' }}>
                                            {FormatNumber(fluxoLocal.realizadoEntradaAno, true, false)}
                                        </Col>
                                        <Col align="right" style={{ width: '20%' }}>
                                            {FormatNumber(fluxoLocal.prevPagarAno, true, false)}
                                        </Col>
                                        <Col align="right" style={{ width: '20%' }}>
                                            {FormatNumber(fluxoLocal.realizadoSaidaAno, true, false)}
                                        </Col>
                                    </Row>
                                ))}
                                <Row className="c-primary row">
                                    <Col style={{ width: '20%' }}><b>TOTAL DO PERÍODO</b></Col>
                                    <Col align="right" style={{ width: '20%' }}>
                                        <b>{FormatNumber(saldos.prevReceber, true)}</b>
                                    </Col>
                                    <Col align="right" style={{ width: '20%' }}>
                                        <b>{FormatNumber(saldos.realizadoEntrada, true)}</b>
                                    </Col>
                                    <Col align="right" className="c-red" style={{ width: '20%' }}>
                                        <b>{FormatNumber(saldos.prevPagar, true)}</b>
                                    </Col>
                                    <Col align="right" className="c-red" style={{ width: '20%' }}>
                                        <b>{FormatNumber(saldos.realizadoSaida, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}