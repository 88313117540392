import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Form, Input, Button, Typography, Modal } from 'antd';
import { UnlockFilled, SendOutlined } from '@ant-design/icons';

import { SlideLogin } from "../../components";
import api from "../../services/api";

export default function AtivarUsuarioPlano() {
    const [dadosUsuario, setDadosUsuario] = useState({});
    const [senhaValida, setSenhaValida] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        carregarUsuario();
    }, []);

    function carregarUsuario() {
        let url = window.location.href;
        let dadosUsuario = atob(url.substr(url.lastIndexOf('/') + 1)).split('|');
        api.get(`Usuario/RetornaUsuarioEmail?email=${dadosUsuario[0]}`).then(
            res => {
                if (!!res.data) {
                    if (!!res.data.usu_ativo) {
                        ativarEmpresaLocal();
                        Modal.warning({
                            title: 'Atenção!',
                            content: 'Usuário já está ativo! caso não lembre sua senha clique em "Esqueci minha senha"',
                            onOk() {
                                voltarLogin();
                            }
                        });
                    } else {
                        setDadosUsuario(res.data)
                    }
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };
    function ativarEmpresaLocal() {
        let url = window.location.href;
        let dadosUsuario = atob(url.substr(url.lastIndexOf('/') + 1)).split('|');
        let dados = {
            email: dadosUsuario[0],
            senha: null
        };
        api.post(`Empresa/AtivarNovaEmpresa`, dados).then(
            res => {
                console.log(res);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };
    function ativarUsuario() {
        let url = window.location.href;
        let dadosUsuario = atob(url.substr(url.lastIndexOf('/') + 1)).split('|');
        
        let dados = {
            email: dadosUsuario[0],
            senha: btoa(form.getFieldValue().usu_senha)
        };
        api.post(`Empresa/AtivarNovaEmpresa`, dados).then(
            res => {
                Modal.success({
                    title: 'Aviso!',
                    content: 'Sua conta foi ativada com sucesso!',
                    onOk() {
                        voltarLogin();
                    }
                });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function validarSenha() {
        let usu_senha = '';
        let usu_confirmaSenha = '';
        if (!!form.getFieldValue().usu_senha) {
            usu_senha = form.getFieldValue().usu_senha.trim();
        }
        if (!!form.getFieldValue().usu_confirmaSenha) {
            usu_confirmaSenha = form.getFieldValue().usu_confirmaSenha.trim();
        }

        form.setFieldsValue({ usu_senha: usu_senha, usu_confirmaSenha: usu_confirmaSenha });
        if (usu_senha.length >= 6 && usu_senha === usu_confirmaSenha) {
            let letrasMinusculas = /[a-z]+/.test(usu_senha);
            let letrasMaiusculas = /[A-Z]+/.test(usu_senha);
            let numeros = /[0-9]+/.test(usu_senha);
            if (letrasMinusculas && letrasMaiusculas && numeros) {
                setSenhaValida(true);
            } else {
                setSenhaValida(false);
            }
        } else {
            setSenhaValida(false);
        }
    };

    function voltarLogin() {
        window.location = '/';
    };

    return (
        <Layout className="vh100">
            <Layout.Content>
                <Row justify="center" className="vh100">
                    <Col span={13} className="vh100 fixed-left">
                        <SlideLogin />
                    </Col>
                    <Col span={11} className="vh100 bg-log fixed-right">
                        <Form layout="vertical" name="recuperar-senha" form={form} initialValues={{ remember: true }} size="middle" onFinish={ativarUsuario}>
                            <Row align="middle" justify="center" className="vh100" gutter={[16, 16]}>
                                <Col span={18}>
                                    <Row align="middle" justify="center" gutter={[0, 16]}>
                                        <Col>
                                            <img src={require("../../assets/logoGestor.png").default} width="250" alt="Gestor Web" />
                                        </Col>
                                        <Col span={24}>
                                            <Typography.Title level={3}>
                                                Olá <b>{dadosUsuario.usu_nome}</b>
                                            </Typography.Title>
                                        </Col>
                                    </Row>
                                    <Row align="middle" justify="center">
                                        <Col span={24}>
                                            <Typography.Text>
                                                Antes de ativar sua conta, precisamos definir sua senha de acesso. <br />
                                                A senha deve atender os seguintes requisitos:<br /><br />
                                            </Typography.Text>
                                        </Col>
                                        <Col span={24}>
                                            1. Mínimo de 6 caracteres;
                                        </Col>
                                        <Col span={24}>
                                            2. Deve conter números e letras (maiúsculas e minúsculas);
                                        </Col>
                                    </Row>
                                    <Row align="middle" className="m-t-16">
                                        <Col span={24}>
                                            <Form.Item label="Senha de Acesso" name="usu_senha" rules={[{ required: true, message: 'Informe a Senha de Acesso.' }]}>
                                                <Input.Password placeholder="Informe Senha de Acesso" prefix={<UnlockFilled />} onChange={() => { validarSenha() }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label="Confirmar Nova Senha" name="usu_confirmaSenha" rules={[{ required: true, message: 'Confirme a Senha de Acesso.' }]}>
                                                <Input.Password placeholder="Confirme a Senha de Acesso" prefix={<UnlockFilled />} onChange={() => { validarSenha() }} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row align="middle" justify="end" className="m-t-16">
                                        <Col>
                                            <Button disabled={!senhaValida} type="primary" htmlType="submit">
                                                Ativar sua Conta <SendOutlined />
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
}