import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Select, Layout, Card, notification } from "antd";
import { CheckOutlined, PrinterFilled } from "@ant-design/icons";
import moment from "moment";

import api from "../../services/api";
import { FormatNumber } from "../../ValueObjects";
import { formatarCnpj } from "../../services/funcoes";
import { getEmpresa, getNomeEmpresa } from "../../services/auth";
import { Data } from "../../components";

export default function AnaliseInadimplenciaCliente() {
    const [form] = Form.useForm();
    const [listaIndice, setListaIndice] = useState([]);
    const [listaLocais, setListaLocais] = useState([]);
    const [logoEmpresa, setLogoEmpresa] = useState("");
    const [listaDadosInadimplencia, setListaDadosInadimplencia] = useState([]);
    const [tiposPagamento, setTiposPagamento] = useState([])
    const [indiceSelecionado, setIndiceSelecionado] = useState(0);
    const [totalizadores, setTotalizadores] = useState({});
    const [imprimir, setImprimir] = useState(false);
    const [mostrarScroll, setMostrarScroll] = useState(false)
    const [carregando, setCarregando] = useState(false);

    useEffect(() => {
        carregarDadosIndice();
        carregarDadosLocal();
        BuscarParametrosEmpresa();
        popularTipoCobranca();
        setarFuncaoTamanho();
    }, []);

    useEffect(() => {
        if (!!listaLocais && listaLocais.length > 0) {
            carregarLocaisPadroes();
        }
    }, [listaLocais]);

    useEffect(() => {
        if (!!tiposPagamento && tiposPagamento.length > 0) {
            popularLabelTpPag();
        }
    }, [tiposPagamento]);

    useEffect(() => {
        if (!!window.outerHeight) {
            if (window.outerHeight < 800) {
                setMostrarScroll(true);
            } else {
                setMostrarScroll(false);
            }
        }
    }, [window]);

    function carregarDadosIndice() {
        let indices = [];
        let numeroIndice = 30;
        for (let i = 0; i < 6; i++) {
            indices.push({ key: i, label: `Indice +${numeroIndice} dias.`, value: numeroIndice });
            numeroIndice += 30;
        }
        setListaIndice(indices);
    };

    function carregarDadosLocal() {
        api.get(`Local/ListarLocalUsuario?CodigoEmpresa=${getEmpresa()}`).then(
            res => {
                setListaLocais(res.data.items);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function carregarLocaisPadroes() {
        let locais = []
        listaLocais.forEach((local) => {
            locais.push(local.loc_id);
        });
        form.setFieldsValue({ loc_id: locais });
    };

    function BuscarParametrosEmpresa() {
        api.get(`Empresa/Buscar/${getEmpresa()}`).then(
            res => {
                setLogoEmpresa(res.data.emp_logo);
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function popularTipoCobranca() {
        let listaTpCobranca = [];
        listaTpCobranca.push({ id: 1, value: 'Cheques' });
        listaTpCobranca.push({ id: 2, value: 'Crediario' });
        setTiposPagamento(listaTpCobranca);
    };

    function popularLabelTpPag() {
        let formTiposCobranca = form.getFieldValue().tiposCobranca;
        let retorno = []
        tiposPagamento.forEach(
            (tpCobranca) => {
                if (formTiposCobranca.indexOf(tpCobranca.id) > -1) {
                    retorno.push(tpCobranca.value);
                }
            }
        );
        form.setFieldsValue({ tipoCobrancaLabel: retorno.join(', ') });
    };

    function setarFuncaoTamanho() {
        window.addEventListener("resize", () => {
            if (!!window.outerHeight) {
                if (window.outerHeight < 800 && listaDadosInadimplencia.length > 1) {
                    setMostrarScroll(true);
                } else {
                    setMostrarScroll(false);
                }
            }
        });
    };

    async function buscarDadosInadimplencia() {
        if (!!!form.getFieldValue().dataAnalise) {
            notification.warning({ description: 'Favor informe uma data para análise!', message: 'Aviso' });
            return false;
        }
        let formTiposCobranca = form.getFieldValue().tiposCobranca;
        let dadosCrediario = [];
        let dadosCheques = [];
        let listaDados = [];
        let dadosTabela = [];
        let obj = {};
        let localAtual = 0;

        setCarregando(true);

        if (formTiposCobranca.indexOf(1) > -1) {
            let reqCheques = await api.get(`AnaliseInadimplenciaClientes/ListarCheques?filiais=${form.getFieldValue().loc_id.join(',')}&dataBase=${moment(form.getFieldValue().dataAnalise).format("YYYY-MM-DD")}`);
            if (reqCheques.status === 200) {
                dadosCheques = reqCheques.data;
            }
        }

        if (formTiposCobranca.indexOf(2) > -1) {
            let reqCrediario = await api.get(`AnaliseInadimplenciaClientes/ListarCrediario?filiais=${form.getFieldValue().loc_id.join(',')}&dataBase=${moment(form.getFieldValue().dataAnalise).format("YYYY-MM-DD")}`);
            if (reqCrediario.status === 200) {
                dadosCrediario = reqCrediario.data;
            }
        }

        if (dadosCheques.length > 0) {
            listaDados.push(dadosCheques);
        }

        if (dadosCrediario.length > 0) {
            listaDados.push(dadosCrediario);
        }

        listaDados = dadosCheques.concat(dadosCrediario);
        //localAtual = parseInt(listaDados[0].codigoLocal);
        limparVariaveis(obj);

        listaDados.forEach((dados, idx, array) => {
            obj.codigoLocal = dados.codigoLocal;
            obj.nomeLocal = dados.nomeLocal;
            obj.cnpjLocal = dados.cnpjLocal;
            obj.vlr_AVencer += parseFloat(dados.vlr_AVencer);
            obj.vlr_Ate30dias += parseFloat(dados.vlr_Ate30dias);
            obj.vlr_Ate60dias += parseFloat(dados.vlr_Ate60dias);
            obj.vlr_Ate90dias += parseFloat(dados.vlr_Ate90dias);
            obj.vlr_Ate120dias += parseFloat(dados.vlr_Ate120dias);
            obj.vlr_Ate150dias += parseFloat(dados.vlr_Ate150dias);
            obj.vlr_Ate180dias += parseFloat(dados.vlr_Ate180dias);
            obj.vlr_Acima180dias += parseFloat(dados.vlr_Acima180dias);
            obj.vlr_Vencidos += parseFloat(dados.vlr_Vencidos);
            obj.vlr_Total += parseFloat(dados.vlr_Total);
            obj.vlr_Indicador_Acima30dias += parseFloat(dados.vlr_Indicador_Acima30dias);
            obj.vlr_Indicador_Acima60dias += parseFloat(dados.vlr_Indicador_Acima60dias);
            obj.vlr_Indicador_Acima90dias += parseFloat(dados.vlr_Indicador_Acima90dias);
            obj.vlr_Indicador_Acima120dias += parseFloat(dados.vlr_Indicador_Acima120dias);
            obj.vlr_Indicador_Acima150dias += parseFloat(dados.vlr_Indicador_Acima150dias);
            obj.vlr_Indicador_Acima180dias += parseFloat(dados.vlr_Indicador_Acima180dias);

            if (localAtual !== parseInt(dados.codigoLocal)) {
                localAtual = parseInt(dados.codigoLocal);
                dadosTabela.push({ ...obj });
                limparVariaveis(obj);
            } else if (idx === array.length - 1) {
                dadosTabela.push(obj);
            }
        });
        setCarregando(false);
        setListaDadosInadimplencia(dadosTabela);
        calcularTotal(dadosTabela);
    };

    function limparVariaveis(obj) {
        obj.vlr_AVencer = 0;
        obj.vlr_Ate30dias = 0;
        obj.vlr_Ate60dias = 0;
        obj.vlr_Ate90dias = 0;
        obj.vlr_Ate120dias = 0;
        obj.vlr_Ate150dias = 0;
        obj.vlr_Ate180dias = 0;
        obj.vlr_Acima180dias = 0;
        obj.vlr_Vencidos = 0;
        obj.vlr_Total = 0;
        obj.vlr_Indicador_Acima30dias = 0;
        obj.vlr_Indicador_Acima60dias = 0;
        obj.vlr_Indicador_Acima90dias = 0;
        obj.vlr_Indicador_Acima120dias = 0;
        obj.vlr_Indicador_Acima150dias = 0;
        obj.vlr_Indicador_Acima180dias = 0;
    };

    function calcularTotal(dadosTabela) {
        let objTotalizador = {};
        objTotalizador.vlr_AVencer = 0;
        objTotalizador.vlr_Ate30dias = 0;
        objTotalizador.vlr_Ate60dias = 0;
        objTotalizador.vlr_Ate90dias = 0;
        objTotalizador.vlr_Ate120dias = 0;
        objTotalizador.vlr_Ate150dias = 0;
        objTotalizador.vlr_Ate180dias = 0;
        objTotalizador.vlr_Acima180dias = 0;
        objTotalizador.vlr_Vencidos = 0;
        objTotalizador.vlr_Total = 0;
        objTotalizador.vlr_Indicador_Acima30dias = 0;
        objTotalizador.vlr_Indicador_Acima60dias = 0;
        objTotalizador.vlr_Indicador_Acima90dias = 0;
        objTotalizador.vlr_Indicador_Acima120dias = 0;
        objTotalizador.vlr_Indicador_Acima150dias = 0;
        objTotalizador.vlr_Indicador_Acima180dias = 0;

        dadosTabela.forEach((dados => {
            objTotalizador.vlr_AVencer += dados.vlr_AVencer;
            objTotalizador.vlr_Ate30dias += dados.vlr_Ate30dias;
            objTotalizador.vlr_Ate60dias += dados.vlr_Ate60dias;
            objTotalizador.vlr_Ate90dias += dados.vlr_Ate90dias;
            objTotalizador.vlr_Ate120dias += dados.vlr_Ate120dias;
            objTotalizador.vlr_Ate150dias += dados.vlr_Ate150dias;
            objTotalizador.vlr_Ate180dias += dados.vlr_Ate180dias;
            objTotalizador.vlr_Acima180dias += dados.vlr_Acima180dias;
            objTotalizador.vlr_Vencidos += dados.vlr_Vencidos;
            objTotalizador.vlr_Total += dados.vlr_Total;
            objTotalizador.vlr_Indicador_Acima30dias += dados.vlr_Indicador_Acima30dias;
            objTotalizador.vlr_Indicador_Acima60dias += dados.vlr_Indicador_Acima60dias;
            objTotalizador.vlr_Indicador_Acima90dias += dados.vlr_Indicador_Acima90dias;
            objTotalizador.vlr_Indicador_Acima120dias += dados.vlr_Indicador_Acima120dias;
            objTotalizador.vlr_Indicador_Acima150dias += dados.vlr_Indicador_Acima150dias;
            objTotalizador.vlr_Indicador_Acima180dias += dados.vlr_Indicador_Acima180dias;
        }));

        setTotalizadores(objTotalizador);
    };

    function impressao() {
        setImprimir(true);
        window.print();
    };

    return (
        <div className="App">
            <Layout className="vh100">
                {!imprimir &&
                    <Layout.Header style={{ position: 'relative' }}>
                        <Row align="middle">
                            <Col>
                                <img src={require("../../assets/logoGestorWhite.png").default} width="145" alt="Gestor Web" />
                            </Col>
                            <Col xxl={20} xl={19} lg={14} className="text-center f-18">
                                Análise de Inadimplência de Clientes
                            </Col>
                        </Row>
                    </Layout.Header>
                }
                <Layout className={`vh100 ${imprimir ? 'm-t-20' : 'm-t-60'} p-10`}>
                    <Layout.Content>
                        {!imprimir && <Form layout="vertical" form={form}>
                            <Row gutter={[8, 0]}>
                                <Col span={24}>
                                    <Form.Item label="Local" name="loc_id">
                                        <Select
                                            mode="multiple">
                                            {listaLocais.map(local => (
                                                <Select.Option value={local.loc_id} key={local.loc_id}>{local.loc_descricao}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={4} xl={4}>
                                    <Data label="Data da Análise" name="dataAnalise" rules={[{ required: true, message: 'Informe a data da análise' }]} />
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={4} xl={4}>
                                    <Form.Item label="Selecione o Indice Desejado" name="indiceSelecionado" initialValue={0}>
                                        <Select onChange={(valor) => { setIndiceSelecionado(valor) }}>
                                            {listaIndice.map(indice => (
                                                <Select.Option value={indice.key} key={indice.key}>{indice.label}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={16} xl={16}>
                                    <Form.Item label="Tipo de Cobrança" name="tiposCobranca" initialValue={[1, 2]}>
                                        <Select
                                            onChange={() => { popularLabelTpPag() }}
                                            mode="multiple">
                                            {tiposPagamento.map((tpPag) => (
                                                <Select.Option value={tpPag.id} key={tpPag.id}>{tpPag.value}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>                               
                            </Row>
                            <Row justify="end" className="m-t-8"> 
                                <Col>
                                    <Button loading={carregando} type="primary" icon={<CheckOutlined />} onClick={() => { buscarDadosInadimplencia() }}>
                                        Aplicar Filtros
                                    </Button>
                                </Col>
                            </Row>
                        </Form>}
                        {listaDadosInadimplencia.length > 0 &&
                            <div className="m-t-8">
                                <Card>
                                    <Row gutter={[8, 0]} align="middle" justify="space-between">
                                        <Col xs={4} sm={4} md={2} lg={2} xl={2}>
                                            <img src={`data:image/png;base64,${logoEmpresa}`} className="w-100" alt="Logo" />
                                        </Col>
                                        <Col xs={12} sm={15} md={18} lg={18} xl={18}>
                                            <Row justify="center">
                                                <Col>
                                                    <b>{getNomeEmpresa()}</b>
                                                </Col>
                                            </Row>
                                            <Row justify="center">
                                                <Col>
                                                    Data base da análise: <b>{moment(form.getFieldValue().dataAnalise).format("DD/MM/YYYY")}</b>
                                                </Col>
                                            </Row>
                                            <Row justify="center">
                                                <Col>
                                                    Tipos de cobrança: <b>{form.getFieldValue().tipoCobrancaLabel}</b>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={6} sm={5} md={4} lg={4} xl={4}>
                                            <Row justify="end">
                                                <Col>
                                                    Data: <b>{moment(new Date()).format("DD/MM/YYYY")}</b>
                                                </Col>
                                            </Row>
                                            <Row justify="end">
                                                <Col>
                                                    Hora: <b>{moment(new Date()).format("HH:mm:ss")}</b>
                                                </Col>
                                            </Row>
                                            <Row justify="end">
                                                <Col>
                                                    <Button type="link" size="middle" icon={<PrinterFilled className="f-30" />} onClick={() => { impressao() }} />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                                <div className="m-t-16 col-analise text-right" style={mostrarScroll ? { overflowY: 'scroll', height: '310px' } : {}}>
                                    <Card>
                                        <Row justify="end" className="p-8">
                                            <Col span={2}> </Col>
                                            <Col span={2}>
                                                <b>A Vencer</b>
                                            </Col>
                                            <Col span={2}>
                                                <b>Até 30 dias</b>
                                            </Col>
                                            <Col span={2}>
                                                <b>Até 60 dias</b>
                                            </Col>
                                            <Col span={2}>
                                                <b>Até 90 dias</b>
                                            </Col>
                                            <Col span={2}>
                                                <b>Até 120 dias</b>
                                            </Col>
                                            <Col span={2}>
                                                <b>Até 150 dias</b>
                                            </Col>
                                            <Col span={2}>
                                                <b>Até 180 dias</b>
                                            </Col>
                                            <Col span={2}>
                                                <b>Mais de 180 dias</b>
                                            </Col>
                                            <Col span={2}>
                                                <b>Vencidos</b>
                                            </Col>
                                            <Col span={2}>
                                                <b>Total</b>
                                            </Col>
                                            {indiceSelecionado === 0 &&
                                                <Col span={2}>
                                                    <b>Indice (+30)</b>
                                                </Col>
                                            }
                                            {indiceSelecionado === 1 &&
                                                <Col span={2}>
                                                    <b>Indice (+60)</b>
                                                </Col>
                                            }
                                            {indiceSelecionado === 2 &&
                                                <Col span={2}>
                                                    <b>Indice (+90)</b>
                                                </Col>
                                            }
                                            {indiceSelecionado === 3 &&
                                                <Col span={2}>
                                                    <b>Indice (+120)</b>
                                                </Col>
                                            }
                                            {indiceSelecionado === 4 &&
                                                <Col span={2}>
                                                    <b>Indice (+150)</b>
                                                </Col>
                                            }
                                            {indiceSelecionado === 5 &&
                                                <Col span={2}>
                                                    <b>Indice (+180)</b>
                                                </Col>
                                            }
                                        </Row>
                                        {listaDadosInadimplencia.map((dados) => (
                                            <div>
                                                <Row className="p-8 b-t" gutter={[8, 0]}>
                                                    <Col align="left">
                                                        <b>{getNomeEmpresa()}</b> / <b>{dados.nomeLocal} </b>
                                                    </Col>
                                                    <Col align="left">
                                                        CNPJ: <b>{formatarCnpj(dados.cnpjLocal)}</b>
                                                    </Col>
                                                </Row>
                                                <div className="p-t-b-8 b-t">
                                                    <Row className="p-r-l-8" justify="end">
                                                        <Col span={2}> </Col>
                                                        <Col span={2}>
                                                            {FormatNumber(dados.vlr_AVencer, 2)}
                                                        </Col>
                                                        <Col span={2}>
                                                            {FormatNumber(dados.vlr_Ate30dias, 2)}
                                                        </Col>
                                                        <Col span={2}>
                                                            {FormatNumber(dados.vlr_Ate60dias, 2)}
                                                        </Col>
                                                        <Col span={2}>
                                                            {FormatNumber(dados.vlr_Ate90dias, 2)}
                                                        </Col>
                                                        <Col span={2}>
                                                            {FormatNumber(dados.vlr_Ate120dias, 2)}
                                                        </Col>
                                                        <Col span={2}>
                                                            {FormatNumber(dados.vlr_Ate150dias, 2)}
                                                        </Col>
                                                        <Col span={2}>
                                                            {FormatNumber(dados.vlr_Ate180dias, 2)}
                                                        </Col>
                                                        <Col span={2}>
                                                            {FormatNumber(dados.vlr_Acima180dias, 2)}
                                                        </Col>
                                                        <Col span={2}>
                                                            {FormatNumber(dados.vlr_Vencidos, 2)}
                                                        </Col>
                                                        <Col span={2}>
                                                            {FormatNumber(dados.vlr_Total, 2)}
                                                        </Col>
                                                        {indiceSelecionado === 0 &&
                                                            <Col span={2}>
                                                                {FormatNumber(dados.vlr_Indicador_Acima30dias, 2)}
                                                            </Col>
                                                        }
                                                        {indiceSelecionado === 1 &&
                                                            <Col span={2}>
                                                                {FormatNumber(dados.vlr_Indicador_Acima60dias, 2)}
                                                            </Col>
                                                        }
                                                        {indiceSelecionado === 2 &&
                                                            <Col span={2}>
                                                                {FormatNumber(dados.vlr_Indicador_Acima90dias, 2)}
                                                            </Col>
                                                        }
                                                        {indiceSelecionado === 3 &&
                                                            <Col span={2}>
                                                                {FormatNumber(dados.vlr_Indicador_Acima120dias, 2)}
                                                            </Col>
                                                        }
                                                        {indiceSelecionado === 4 &&
                                                            <Col span={2}>
                                                                {FormatNumber(dados.vlr_Indicador_Acima150dias, 2)}
                                                            </Col>
                                                        }
                                                        {indiceSelecionado === 5 &&
                                                            <Col span={2}>
                                                                {FormatNumber(dados.vlr_Indicador_Acima180dias, 2)}
                                                            </Col>
                                                        }
                                                    </Row>
                                                    <Row justify="end" className="p-r-l-8">
                                                        <Col span={2}> </Col>
                                                        <Col span={2}>
                                                            % {(((dados.vlr_AVencer * 100) / (dados.vlr_Total > 0 ? dados.vlr_Total : 1)).toFixed(2)).replaceAll(".", ",")}
                                                        </Col>
                                                        <Col span={2}>
                                                            % {(((dados.vlr_Ate30dias * 100) / (dados.vlr_Total > 0 ? dados.vlr_Total : 1)).toFixed(2)).replaceAll(".", ",")}
                                                        </Col>
                                                        <Col span={2}>
                                                            % {(((dados.vlr_Ate60dias * 100) / (dados.vlr_Total > 0 ? dados.vlr_Total : 1)).toFixed(2)).replaceAll(".", ",")}
                                                        </Col>
                                                        <Col span={2}>
                                                            % {(((dados.vlr_Ate90dias * 100) / (dados.vlr_Total > 0 ? dados.vlr_Total : 1)).toFixed(2)).replaceAll(".", ",")}
                                                        </Col>
                                                        <Col span={2}>
                                                            % {(((dados.vlr_Ate120dias * 100) / (dados.vlr_Total > 0 ? dados.vlr_Total : 1)).toFixed(2)).replaceAll(".", ",")}
                                                        </Col>
                                                        <Col span={2}>
                                                            % {(((dados.vlr_Ate150dias * 100) / (dados.vlr_Total > 0 ? dados.vlr_Total : 1)).toFixed(2)).replaceAll(".", ",")}
                                                        </Col>
                                                        <Col span={2}>
                                                            % {(((dados.vlr_Ate180dias * 100) / (dados.vlr_Total > 0 ? dados.vlr_Total : 1)).toFixed(2)).replaceAll(".", ",")}
                                                        </Col>
                                                        <Col span={2}>
                                                            % {(((dados.vlr_Acima180dias * 100) / (dados.vlr_Total > 0 ? dados.vlr_Total : 1)).toFixed(2)).replaceAll(".", ",")}
                                                        </Col>
                                                        <Col span={2}>
                                                            % {(((dados.vlr_Vencidos * 100) / (dados.vlr_Total > 0 ? dados.vlr_Total : 1)).toFixed(2)).replaceAll(".", ",")}
                                                        </Col>
                                                        <Col span={2}></Col>
                                                        {indiceSelecionado === 0 &&
                                                            <Col span={2}>
                                                                % {(((dados.vlr_Indicador_Acima30dias * 100) / (dados.vlr_Total > 0 ? dados.vlr_Total : 1)).toFixed(2)).replaceAll(".", ",")}
                                                            </Col>}
                                                        {indiceSelecionado === 1 &&
                                                            <Col span={2}>
                                                                % {(((dados.vlr_Indicador_Acima60dias * 100) / (dados.vlr_Total > 0 ? dados.vlr_Total : 1)).toFixed(2)).replaceAll(".", ",")}
                                                            </Col>}
                                                        {indiceSelecionado === 2 &&
                                                            <Col span={2}>
                                                                % {(((dados.vlr_Indicador_Acima90dias * 100) / (dados.vlr_Total > 0 ? dados.vlr_Total : 1)).toFixed(2)).replaceAll(".", ",")}
                                                            </Col>}
                                                        {indiceSelecionado === 3 &&
                                                            <Col span={2}>
                                                                % {(((dados.vlr_Indicador_Acima120dias * 100) / (dados.vlr_Total > 0 ? dados.vlr_Total : 1)).toFixed(2)).replaceAll(".", ",")}
                                                            </Col>}
                                                        {indiceSelecionado === 4 &&
                                                            <Col span={2}>
                                                                % {(((dados.vlr_Indicador_Acima150dias * 100) / (dados.vlr_Total > 0 ? dados.vlr_Total : 1)).toFixed(2)).replaceAll(".", ",")}
                                                            </Col>}
                                                        {indiceSelecionado === 5 &&
                                                            <Col span={2}>
                                                                % {(((dados.vlr_Indicador_Acima180dias * 100) / (dados.vlr_Total > 0 ? dados.vlr_Total : 1)).toFixed(2)).replaceAll(".", ",")}
                                                            </Col>}
                                                    </Row>
                                                </div>

                                            </div>
                                        ))}
                                        <Row className="p-8 b-t" justify="end">
                                            <Col span={2}>
                                                <b>Totais (R$):</b>
                                            </Col>
                                            <Col span={2}>
                                                {FormatNumber(totalizadores.vlr_AVencer, 2)}
                                            </Col>
                                            <Col span={2}>
                                                {FormatNumber(totalizadores.vlr_Ate30dias, 2)}
                                            </Col>
                                            <Col span={2}>
                                                {FormatNumber(totalizadores.vlr_Ate60dias, 2)}
                                            </Col>
                                            <Col span={2}>
                                                {FormatNumber(totalizadores.vlr_Ate90dias, 2)}
                                            </Col>
                                            <Col span={2}>
                                                {FormatNumber(totalizadores.vlr_Ate120dias, 2)}
                                            </Col>
                                            <Col span={2}>
                                                {FormatNumber(totalizadores.vlr_Ate150dias, 2)}
                                            </Col>
                                            <Col span={2}>
                                                {FormatNumber(totalizadores.vlr_Ate180dias, 2)}
                                            </Col>
                                            <Col span={2}>
                                                {FormatNumber(totalizadores.vlr_Acima180dias, 2)}
                                            </Col>
                                            <Col span={2}>
                                                {FormatNumber(totalizadores.vlr_Vencidos, 2)}
                                            </Col>
                                            <Col span={2}>
                                                {FormatNumber(totalizadores.vlr_Total, 2)}
                                            </Col>
                                            {indiceSelecionado === 0 &&
                                                <Col span={2}>
                                                    {FormatNumber(totalizadores.vlr_Indicador_Acima30dias, 2)}
                                                </Col>
                                            }
                                            {indiceSelecionado === 1 &&
                                                <Col span={2}>
                                                    {FormatNumber(totalizadores.vlr_Indicador_Acima60dias, 2)}
                                                </Col>
                                            }
                                            {indiceSelecionado === 2 &&
                                                <Col span={2}>
                                                    {FormatNumber(totalizadores.vlr_Indicador_Acima90dias, 2)}
                                                </Col>
                                            }
                                            {indiceSelecionado === 3 &&
                                                <Col span={2}>
                                                    {FormatNumber(totalizadores.vlr_Indicador_Acima120dias, 2)}
                                                </Col>
                                            }
                                            {indiceSelecionado === 4 &&
                                                <Col span={2}>
                                                    {FormatNumber(totalizadores.vlr_Indicador_Acima150dias, 2)}
                                                </Col>
                                            }
                                            {indiceSelecionado === 5 &&
                                                <Col span={2}>
                                                    {FormatNumber(totalizadores.vlr_Indicador_Acima180dias, 2)}
                                                </Col>
                                            }
                                        </Row>
                                    </Card>
                                </div>
                            </div>
                        }
                    </Layout.Content>
                </Layout>
            </Layout>
        </div>
    )
};