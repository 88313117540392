import React from "react";
import { Form, Row, Col, Input, Checkbox, notification, Modal } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';

import api from '../../services/api';
import { FormGW } from "../../components";
import { useStateValue } from '../../state';
import { listagemActions } from "../../actions";
import { TabEntrada, TabSaida } from "./tabPage";

export default function ManutencaoTributacaoPisCofins({ formulario, carregando, aoSalvar }) {

    const [{ manutencao }, dispatch] = useStateValue();

    const salvarTributacaoPisCofins = (values) => {


        if (!!values.pis_id && !!values.nat_id) {
            if (values.cpc_saidacstpis.cpc_id > 3) {
                values.nat_id = values.nat_id;//.map((x) => ({ nat_id: x }));
            }
        }

        if (!!!values.cpc_saidacstpis.cpc_id && !!!values.cpc_saidacstcofins.cpc_id && // não é permitido informar somente a descrição da tributação 
            !!!values.cpc_entradacstcofinspf.cpc_id && !!!values.cpc_entradacstpispf.cpc_id
            && !!!values.cpc_entradacstpispj.cpc_id && !!!values.cpc_entradacstcofinspj.cpc_id) {
            carregando(false);
            Modal.warning({
                title: 'Atenção!',
                icon: <ExclamationCircleOutlined />,
                content: 'Você deve informar ao menos uma configuração do PIS e da COFINS!',
                okText: 'Ok',
            });
        } else {
            values.cpc_entradacstcofinspf = !!values.cpc_entradacstcofinspf.cpc_id ? values.cpc_entradacstcofinspf.cpc_id : null;
            values.cpc_entradacstcofinspj = !!values.cpc_entradacstcofinspj.cpc_id ? values.cpc_entradacstcofinspj.cpc_id : null;
            values.cpc_entradacstpispf = !!values.cpc_entradacstpispf.cpc_id ? values.cpc_entradacstpispf.cpc_id : null;
            values.cpc_entradacstpispj = !!values.cpc_entradacstpispj.cpc_id ? values.cpc_entradacstpispj.cpc_id : null;
            values.cpc_saidacstcofins = !!values.cpc_saidacstcofins.cpc_id ? values.cpc_saidacstcofins.cpc_id : null;
            values.cpc_saidacstpis = !!values.cpc_saidacstpis.cpc_id ? values.cpc_saidacstpis.cpc_id : null;
            carregando(true);
            if (!!values.pis_id) {
                api.put(`TributacaoPisCofins/Editar`, values).then(
                    res => {
                        notification.success({ message: 'Operação concluída', description: `Os dados da Tributação foram editados com sucesso!` });
                    }).catch(
                        error => {
                            console.log(error);
                        }).finally(() => {
                            carregando(false);
                            aoSalvar();
                            formulario.resetFields();
                        });
            } else {
                api.post(`TributacaoPisCofins/Incluir`, values).then(
                    res => {
                        notification.success({ message: 'Operação concluída', description: `Os dados da Tributação foram adicionados com sucesso!` });
                    }).catch(
                        error => {
                            console.log(error);
                        }).finally(() => {
                            carregando(false);
                            aoSalvar();
                            formulario.resetFields();
                        });
            }

        }
    };

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoTributacaoPisCofins" onFinish={salvarTributacaoPisCofins}>
                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                                <Form.Item name="pis_id" hidden />
                                <Form.Item label="Descrição da Tributação" name="pis_descricao" rules={[{ required: true, message: 'Informe a Descrição da Tributação' }]}>
                                    <Input placeholder="Informe a Descrição da Tributação" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                                <Form.Item
                                    name="pis_ativo" valuePropName="checked" initialValue={true}
                                    hidden={(!(formulario.getFieldValue(['pis_id']) > 0))} className="m-t-19">
                                    <Checkbox> Tributação Ativa</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <TabSaida formulario={formulario} />
                    </Col>
                    <Col span={24}>
                        <TabEntrada formulario={formulario} />
                    </Col>
                </Row>
            </FormGW>
        </div>
    );

}