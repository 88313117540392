import React, { useState, useEffect } from 'react';
import { Row, Col, Form, message, Empty, Select, Input, Button, InputNumber, Checkbox, Switch } from 'antd';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { EyeOutlined, CloudUploadOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';

import { ItemFormulario, TiposElementosFormulario } from './pages';
import { ModalPersonalizacaoFormularioFiltros, ModalImportarElementosFormulario } from "../../components/modals/";

export default function EditorFormulario({ elementosFormulario, setElementosFormulario }) {

    const objElementoFormularioEdicao = { indice: null, elemento: null };
    const [formEditor] = Form.useForm();
    const [openModalPersonalizacao, setOpenModalPersonalizacao] = useState(false);
    const [openModalImportarElemento, setOpenModalImportarElemento] = useState(false);
    const [elementoEdicao, setElementoEdicao] = useState(objElementoFormularioEdicao);
    const [optionsSelect, setOptionsSelect] = useState([]);


    useEffect(() => { formEditor.resetFields(); console.log("lista alterada Elementos", elementosFormulario) }, [elementoEdicao]);

    const salvarElemento = values => {
        console.log("values", values)
        if (values.rpj_tipoelemento === 'selectPaginacao' || values.rpj_tipoelemento === 'selectAll') {
            values.rpj_options = JSON.stringify({ url: values.url, nameLabel: values.nameLabel, nameValue: values.nameValue, mostrarCodigoLabel: values.mostrarCodigoLabel, multiple: values.multiple })
            delete (values.url)
            delete (values.nameLabel)
            delete (values.nameValue)
            delete (values.mostrarCodigoLabel)
            delete (values.multiple)
        }
        if (values.rpj_tipoelemento === 'dataPicker') {
            values.rpj_options = JSON.stringify({ format: values.format })
            delete (values.format)
        }
        if (values.rpj_tipoelemento === 'checkbox') {
            values.rpj_options = JSON.stringify({ defaultChecked: values.defaultChecked })
            delete (values.defaultChecked)
        }
        if (values.rpj_tipoelemento === 'radio-group' || values.rpj_tipoelemento === 'checkbox-group') {
            values.rpj_options = JSON.stringify(values.rpj_options)
        }
        if (values.rpj_tipoelemento === 'select') {
            values.rpj_options = JSON.stringify({ options: values.rpj_options, multiple: values.multiple })
            delete (values.multiple)
        }
        console.log("Valores antes de gravar:", values);
        if (elementoEdicao.indice === null) {
            //values.rpj_id = null;
            values.rpj_obrigatorio = false;
            //values.rpj_options = null; comentado pois quando ia adicionar um componente novo setava o options com null não salvando corretamente em banco
            values.rpj_mensagemobrigatorio = null;
            setElementosFormulario([...elementosFormulario, values]);
        } else {
            values.status = 2
            let listaElementos = [...elementosFormulario];
            listaElementos[elementoEdicao.indice] = values;
            setElementosFormulario(listaElementos);
        }
        setElementoEdicao(objElementoFormularioEdicao);
    }

    const tratarErro = errorInfo => {
        errorInfo.errorFields.map((field) => field.errors.map((erro) => message.warning(erro, 2 * errorInfo.errorFields.length)));
    };

    const editarElementoTela = indiceElemento => {
        let elemento = { ...elementosFormulario[indiceElemento] }
        if (elemento.rpj_tipoelemento === 'selectPaginacao' || elemento.rpj_tipoelemento === 'selectAll') {
            elemento.url = JSON.parse(elemento.rpj_options).url
            elemento.nameLabel = JSON.parse(elemento.rpj_options).nameLabel
            elemento.nameValue = JSON.parse(elemento.rpj_options).nameValue
            elemento.mostrarCodigoLabel = JSON.parse(elemento.rpj_options).mostrarCodigoLabel
            elemento.multiple = JSON.parse(elemento.rpj_options).multiple
            delete (elemento.rpj_options);
        }
        if (elemento.rpj_tipoelemento === 'dataPicker') {
            elemento.format = JSON.parse(elemento.rpj_options).format
            delete (elemento.rpj_options)
        }
        if (elemento.rpj_tipoelemento === 'checkbox') {
            elemento.defaultChecked = JSON.parse(elemento.rpj_options).defaultChecked
            delete (elemento.rpj_options)
        }
        if (elemento.rpj_tipoelemento === 'radio-group' || elemento.rpj_tipoelemento === 'checkbox-group') {
            elemento.rpj_options = JSON.parse(elemento.rpj_options)
        }
        if (elemento.rpj_tipoelemento === 'select') {
            elemento.multiple = JSON.parse(elemento.rpj_options).multiple
            elemento.rpj_options = JSON.parse(elemento.rpj_options).options
        }
        setElementoEdicao({ indice: indiceElemento, elemento: elemento });
    }

    const excluirElemento = indiceElemento => {
        const newData = [...elementosFormulario];
        console.log("elementos todos", newData)

        //record.indice = indice;
        //const index = newData.findIndex((item) => indice === item.indice);
        newData.splice(indiceElemento, 1)
        console.log("elementos excluido", newData)
        setElementosFormulario(newData);

        /* let elemento = {...elementosFormulario[indiceElemento]}
        elemento.status = 3
        let listaElementos = [...elementosFormulario];
        listaElementos[indiceElemento] = elemento;
        console.log("elemento exclusao", elemento)
        setElementosFormulario(listaElementos); */

        setElementoEdicao(objElementoFormularioEdicao);
        formEditor.resetFields();
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let listaElementos = [...elementosFormulario];
        listaElementos = arrayMove(listaElementos, oldIndex, newIndex);
        setElementosFormulario(listaElementos);
        console.log("ordenando", listaElementos);
        setElementoEdicao(objElementoFormularioEdicao);
        formEditor.resetFields();
    };


    useEffect(() => console.log({ optionsSelect }), [optionsSelect]);

    const SortableItem = SortableElement(({ value, sortIndex }) => <ItemFormulario elemento={value} fnEditar={() => editarElementoTela(sortIndex)} fnExcluir={() => excluirElemento(sortIndex)} status={!!value.status ? value.status : 0} />);

    const SortContainer = SortableContainer(({ children }) => {
        return <div>{children}</div>;
    });

    return (
        <div className="hg-100">
            <div className="col-formulario-60">
                <Row justify="space-between" gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                        <b>Estrutura do Formulário</b>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                        <Row justify="end" gutter={[8, 8]}>
                            <Col xs={24} sm={12} md={12} lg={12} xl={10}>
                                <Button icon={<CloudUploadOutlined />} onClick={() => setOpenModalImportarElemento(true)} block>
                                    Importar Elemento
                                </Button>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={10}>
                                <Button type="primary" icon={<EyeOutlined />} onClick={() => setOpenModalPersonalizacao(true)} block>
                                    Pré-Visualizar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="col-elementor">
                    <SortContainer onSortEnd={onSortEnd} shouldCancelStart={(e) => (e.target.tagName.toLowerCase() === "path" || e.target.tagName.toLowerCase() === "svg" || e.target.tagName.toLowerCase() === "button")}>
                        {elementosFormulario.map((elemento, indice) =>
                            <Row className="m-b-5">
                                <Col span={24}>
                                    <SortableItem index={indice} sortIndex={indice} value={elemento} key={`${elemento.key}-${indice}`} />
                                </Col>
                            </Row>
                        )}
                    </SortContainer>
                </div>
                {elementosFormulario.length === 0 && <Empty />}
            </div>
            <div className="col-formulario-40">
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Row gutter={[8, 8]}>
                            <Col>
                                <b>Adicionar Elementos ao Formulário</b>
                            </Col>
                        </Row>
                        <Form layout="vertical" form={formEditor} initialValues={elementoEdicao.elemento} onFinish={salvarElemento} onFinishFailed={tratarErro}>
                            <Row gutter={[8, 0]}>
                                <Form.Item name="rpj_id" hidden />
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item label="Número da Linha" name="rpj_linha" rules={[{ required: true, message: 'Informe o número da Linha para o elemento!' }]}>
                                        <InputNumber placeholder="Linha do Elemento" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item label="Tamanho da Coluna" name="rpj_coluna" rules={[{ required: true, message: 'Informe o tamanho da coluna para o elemento!' }]} >
                                        <InputNumber placeholder="Tamanho do Elemento" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item label="Nome do Atributo" name="rpj_nomeelemento" rules={[{ required: true, message: 'Informe o nome do atributo para o elemento!' }]} normalize={(value) => {
                                        return !!value.match(/[a-zA-Z0-9_.]/gm) ? value.match(/[a-zA-Z0-9_.]/gm).join('') : '';
                                    }}>
                                        <Input placeholder="Informe o Nome do Atributo..." />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item label="Label" name="rpj_label" rules={[{ required: true, message: 'Informe o label/descrição do elemento no formulário!' }]}>
                                        <Input placeholder="Informe a Label do Elemento" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Texto de Ajuda" name="rpj_placeholder" initialValue="">
                                        <Input placeholder="Informe um Texto de ajuda..." />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Tipo de Elemento" name="rpj_tipoelemento" rules={[{ required: true, message: 'Selecione o Tipo do Elemento!' }]}>
                                        <Select placeholder="Selecione o tipo de elemento..." showSearch optionFilterProp="children" >
                                            {TiposElementosFormulario.map((tipoEl, key, array) => (
                                                <Select.Option key={key} value={tipoEl.value}> {tipoEl.label}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.rpj_tipoelemento !== currentValues.rpj_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('rpj_tipoelemento') === 'select') ? (
                                                <Form.Item name="rpj_options" label="Opções" rules={[{ required: true }]} extra={
                                                    <Row>
                                                        <Col span={24}>
                                                            Informe o texto da opção e pressione <b>[ENTER]</b>
                                                        </Col>
                                                        <Col span={24}>
                                                            Para retornar valor informe <b>Valor "-" Descrição</b>
                                                        </Col>
                                                    </Row>
                                                }>
                                                    <Select mode="tags" placeholder="Informe as opções do elemento" />
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.rpj_tipoelemento !== currentValues.rpj_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('rpj_tipoelemento') === 'select') ? (
                                                <Form.Item name="multiple" valuePropName="checked" initialValue={false} >
                                                    <Checkbox>Permitir Multi-seleção?</Checkbox>
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.rpj_tipoelemento !== currentValues.rpj_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('rpj_tipoelemento') === 'radio-group' || getFieldValue('rpj_tipoelemento') === 'checkbox-group') ? (
                                                <Form.Item name="rpj_options" label="Opções" rules={[{ required: true }]} extra="Informe o texto da opção e pressione <ENTER>">
                                                    <Select mode="tags" placeholder="Informe as opções do elemento" />
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.rpj_tipoelemento !== currentValues.rpj_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('rpj_tipoelemento') === 'selectPaginacao' || getFieldValue('rpj_tipoelemento') === 'selectAll') ? (
                                                <Form.Item name="url" label="URL p/ carregar a lista:" rules={[{ required: true }]} extra="Informe a URL para carregar a Lista.">
                                                    <Input placeholder="Informe a url para carregar a Lista..." />
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.rpj_tipoelemento !== currentValues.rpj_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('rpj_tipoelemento') === 'selectPaginacao' || getFieldValue('rpj_tipoelemento') === 'selectAll') ? (
                                                <Form.Item name="nameLabel" label="Campo Label p/ Lista" rules={[{ required: true, message: 'Informe o campo Label para Lista!' }]} >
                                                    <Input placeholder="Informe o campo label..." />
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.rpj_tipoelemento !== currentValues.rpj_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('rpj_tipoelemento') === 'selectPaginacao' || getFieldValue('rpj_tipoelemento') === 'selectAll') ? (
                                                <Form.Item name="nameValue" label="Campo Value p/ Lista" rules={[{ required: true, message: 'Informe o campo Value para Lista!' }]}>
                                                    <Input placeholder="Informe o campo Value..." />
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.rpj_tipoelemento !== currentValues.rpj_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('rpj_tipoelemento') === 'selectPaginacao' || getFieldValue('rpj_tipoelemento') === 'selectAll') ? (
                                                <Row>
                                                    <Col span={24}>
                                                        Para retornar 2 campos na Label informe campos <b>Codigo"+"Descrição</b>
                                                    </Col>
                                                </Row>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.rpj_tipoelemento !== currentValues.rpj_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('rpj_tipoelemento') === 'selectPaginacao' || getFieldValue('rpj_tipoelemento') === 'selectAll') ? (
                                                <Form.Item name="mostrarCodigoLabel" valuePropName="checked" initialValue={true} >
                                                    <Checkbox>Mostrar Codigo na Label?</Checkbox>
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.rpj_tipoelemento !== currentValues.rpj_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('rpj_tipoelemento') === 'selectPaginacao' || getFieldValue('rpj_tipoelemento') === 'selectAll') ? (
                                                <Form.Item name="multiple" valuePropName="checked" initialValue={false} >
                                                    <Checkbox>Permitir Multi-seleção?</Checkbox>
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.rpj_tipoelemento !== currentValues.rpj_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('rpj_tipoelemento') === 'dataPicker') ? (
                                                <Form.Item name="format" label="Formato da Data:" rules={[{ required: true }]} message="Informe o formato da Data!" initialValue="DD/MM/YYYY">
                                                    <Input placeholder="Informe o formato da Data..." />
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.rpj_tipoelemento !== currentValues.rpj_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            let elemento = getFieldValue('rpj_tipoelemento');
                                            //if (elemento === 'input') return <Form.Item name="initialValue" label="Valor Inicial"><Input /></Form.Item>;
                                            if (elemento === 'textarea') return <Form.Item name="initialValue" label="Valor Inicial"><Input.TextArea /></Form.Item>;
                                            if (elemento === 'inputNumber') return <Form.Item name="initialValue" label="Valor Inicial"><InputNumber /></Form.Item>;
                                            if (elemento === 'checkbox') return <Form.Item name="defaultChecked" label="Valor Inicial" valuePropName="checked"><Checkbox /></Form.Item>;
                                            if (elemento === 'switch') return <Form.Item name="initialValue" label="Valor Inicial" valuePropName="checked"><Switch /></Form.Item>;
                                            else return null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.rpj_obrigatorio !== currentValues.rpj_obrigatorio} >
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('rpj_obrigatorio') === true) ? (
                                                <Form.Item label="Mensagem Obrigatório:" name="rpj_mensagemobrigatorio" rules={[{ required: true, message: 'Informe uma mensagem referente a obrigação da informação!' }]}>
                                                    <Input placeholder="Informe uma Mensagem Obrigatório..." />
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Obrigatório" name="rpj_obrigatorio" valuePropName="checked" initialValue={false}>
                                        <Switch>Obrigatorio</Switch>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="center" gutter={[8, 0]} className="m-t-8">
                                <Col>
                                    {elementoEdicao.indice !== null &&
                                        <Button icon={<CloseOutlined />} size="middle" onClick={() => setElementoEdicao(objElementoFormularioEdicao)} htmlType="button">
                                            Cancelar
                                        </Button>
                                    }
                                </Col>
                                <Col>
                                    <Button htmlType="submit" size="middle" type="primary" icon={<PlusOutlined />}>
                                        {elementoEdicao.indice === null ? "Adicionar Elemento" : "Alterar Elemento"}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
            <ModalPersonalizacaoFormularioFiltros exibirModalPersonalizacao={openModalPersonalizacao} fecharModalPersonalizacao={() => setOpenModalPersonalizacao(false)} elementosFormulario={elementosFormulario} setElementosFormulario={setElementosFormulario} />
            <ModalImportarElementosFormulario exibirModalImportarElementos={openModalImportarElemento} fecharModalImportarElementos={() => setOpenModalImportarElemento(false)} elementosFormulario={elementosFormulario} setElementosFormulario={setElementosFormulario} />
        </div>
    );

}