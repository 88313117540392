import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Modal, notification, Form, Select } from 'antd';
import { CloseOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { getNomeEmpresa, getNomeLocal, getStatusFilial } from '../../../services/auth';
import { FormGW, SelectPaginacao } from "../../../components";
import api from "../../../services/api";


import { selectPaginadoActions } from "../../../actions";
import { useStateValue } from "../../../state";

export default function ModalEmpresaFilialBusca({ exibirModalEmpresaFilial, fecharModalEmpresaFilial, relatorio, rels, form }) {

    const [codigoEmpresa, setCodigoEmpresa] = useState(null);
    const [codigoLocal, setCodigoLocal] = useState(null);
    const [relNovo, setRelNovo] = useState();
    const [{ manutencao, listaSelectPaginacao }, dispatch] = useStateValue();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                form.resetFields();
                fecharModalEmpresaFilial();
            }
        });
    };

    useEffect(() => {
        if (!!form.getFieldValue()?.emp_id) {
            setCodigoEmpresa(form.getFieldValue()?.emp_id);
            carregarLocais(form.getFieldValue()?.emp_id);
        }

    }, [form.getFieldValue().emp_id]);

    useEffect(() => {
        if (!!codigoLocal) {
            carregarDadosEmpresa();
        }
    }, [codigoLocal]);

    function carregarDadosEmpresa() {
        var rel = {};
        rel.Nome = relatorio.nome;
        rel.emp_id = codigoEmpresa;
        rel.loc_id = !codigoLocal ? 0 : codigoLocal;
        rel.QuantidadeColuna = relatorio.quantidadeColuna;
        rel.JsonRelatorio = relatorio.jsonRelatorio;
        setRelNovo(rel);
    }

    function carregarLocais(codEmpresa) {
        let lista = [];
        lista.push({ name: "filial", campo: "codigoEmpresa", value: codEmpresa });
        dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
    }

    function onFinish () {
        api.post(`RelatorioModeloEtiqueta/Incluir`, relNovo).then(
            res => {
                notification.warning({ description: 'Modelo de etiqueta enviado com sucesso!', message: 'Aviso' });
                form.resetFields();
                fecharModalEmpresaFilial(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    return (

        <Modal centered
            title="Enviar Para"
            visible={exibirModalEmpresaFilial}
            onCancel={() => {
                form.resetFields();
                onCloseModal();
            }}
            onOk={() => //form.submit()
            onFinish()
            }
            okText={
                <>
                    <PlusOutlined /> Adicionar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <div>
                <FormGW layout="vertical" name="manutencaoEndereco" form={form} onFinish={onFinish} >
                    <Row align="middle" gutter={[0, 8]}>
                        <Col span={24}>
                            <Typography.Text>
                                Você está acessando: <b>{`${getNomeEmpresa()}/ ${getNomeLocal()}${!getStatusFilial() ? ' (INATIVO)' : ''}`}</b>
                            </Typography.Text>
                        </Col>
                    </Row>
                </FormGW>
            </div>
            <div>
                <FormGW layout="vertical" name="manutencaoEndereco" form={form} relatorio={relatorio}>
                    <Row align="middle" gutter={[0, 8]}>
                        <Col span={24}>
                            <Typography.Text>
                                Relatório:  <b> {`${rels}`}</b> 
                            </Typography.Text>
                        </Col>
                    </Row>
                </FormGW>
            </div>
            <div>
                <Form layout="vertical" name="login" size="middle" //onFinish={acessar} 
                form={form} >
                    <Row align="middle" gutter={[0, 16]}>
                        <Col span={24}>
                            <Form.Item name="empresa" label="Selecione a Empresa de Destino">
                                <SelectPaginacao url="Empresa/ListarEmpresaUsuario" allowClear={true} placeholder="Selecione uma Empresa" nameLabel="emp_nomefantasia" nameValue="emp_id"
                                    form={form} selecionarRegUnico={"emp_id"} name="emp_id" onChangeFunction={(value) => {
                                        setCodigoEmpresa(value);
                                    }} order="emp_razaosocial"
                                    conteudo={
                                        e => (<Select.Option value={e.emp_id} key={e.emp_id}>{e.emp_nomefantasia} <br /> Razao Social: {e.emp_razaosocial} </Select.Option>)
                                    } />
                            </Form.Item>
                        </Col>
                    </Row>
                    {form.getFieldValue()?.emp_id && <Row align="middle" gutter={[0, 16]}>
                        <Col span={24}>
                            <Form.Item name="filial" label="Selecione a Filial">
                                <SelectPaginacao url="Local/ListarLocalUsuario" allowClear={true} nameLabel="loc_descricao" placeholder="Selecione uma Filial" nameValue="filial"
                                    form={form} onChangeFunction={(valor) => setCodigoLocal(valor)} conteudo={Local => (
                                        <Select.Option key={Local.loc_id} value={Local.loc_id}>{Local.loc_descricao}{!Local.loc_ativo ? ' (INATIVO)' : ''}</Select.Option>
                                    )} />
                            </Form.Item>
                        </Col>
                    </Row>}
                </Form>
            </div>
        </Modal>

    );
}