import React, { useEffect, useState } from "react";
import { Row, Col, Tag, Popover, notification, Modal, Button, Dropdown, Menu, Form, Collapse, Radio } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, MoreOutlined, CloudUploadOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import DrawerFuncionario from './drawer';
import { useStateValue } from '../../state';
import { FormatFone, MaskFormat } from "../../ValueObjects";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { PesquisaOrdenacao, PesquisaAvancadaPessoas, TabelaDados, Editar, Excluir, BotaoIncluirDados, BotaoAbrirChamado, BreadcrumbPage, PesquisaAvancada } from "../../components";
import { Link } from "react-router-dom";

export default function Funcionario() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [editando, setEditando] = useState(false);
    const [open, setOpen] = useState(false);

    const menuOutros = () => {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="text" icon={<CloudUploadOutlined />} onClick={() => {
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { entidade: 3 } } });
                    }}>
                        <Link to="/importarPessoa"> Importar Funcionário </Link>
                    </Button>
                </Menu.Item>
            </Menu >
        )
    };

    useEffect(() => {
        let _ativo = !!!form.getFieldsValue().funcionarioAtivo ? 1 : form.getFieldsValue().funcionarioAtivo;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&ativo=${_ativo}`, ordem: '+pes_nome' } })
        form.setFieldsValue({ funcionarioAtivo: 1 });
    }, []);

    function excluirRegistro(funcionario) {
        api.delete(`Funcionario/Excluir/${funcionario.fun_id}`).then(
            (res) => {
                notification.success({ message: 'Aviso', description: 'funcionario excluído com sucesso!' });
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&ativo=${form.getFieldValue().funcionarioAtivo}`, filtro: '', ordem: '+pes_nome' } });
            }).catch(
                (erro) => {
                    console.error(erro);
                    modalInativarRegistro(funcionario);
                })
    };

    function inativarRegistro(funcionario) {
        api.put(`Funcionario/InativarFuncionario/${funcionario.fun_id}`).then((res) => {
            if (res.status === 200)
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros:  `&ativo=${form.getFieldValue().funcionarioAtivo}`, filtro: '', ordem: '+pes_nome' } });
        })
    };

    const modalExcluirRegistro = (funcionario) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o funcionario ${funcionario.fun_id}-${funcionario.pes_nome} ?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(funcionario);
            }
        });
    };

    const modalInativarRegistro = (funcionario) => {
        Modal.confirm({
            title: 'Não foi possível excluir o funcionario!',
            icon: <ExclamationCircleOutlined />,
            content: `Deve conter referências com outros registros,
            Deseja inativar o funcionario ${funcionario.fun_id}-${funcionario.pes_nome} ?`,
            okText: 'Inativar',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                inativarRegistro(funcionario);
            }
        });
    };

    const togglePesquisa = () => {
        setOpen(!open);
    };

    function editarFuncionario(record) {
        setEditando(true);
        record.fun_dataadmissao = moment(record.fun_dataadmissao);
        record.fun_datademissao = moment(record.fun_datademissao);
        record.pef_rgdataexpedicao = moment(record.pef_rgdataexpedicao);
        record.pef_datanascimento = moment(record.pef_datanascimento);
        record.pef_dataalteracao = moment(record.pef_dataalteracao);
        record.pes_datainclusao = moment(record.pes_datainclusao);
        record.pes_dataalteracao = moment(record.pes_dataalteracao);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function retornoPesquisaAvancada(expressao) {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&ativo=${form.getFieldValue().funcionarioAtivo}&filtroAdicional=${btoa(expressao)}`, ordem: '+pes_nome' } })
    };

    function limparFiltroPesquisa() {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&ativo=${form.getFieldValue().funcionarioAtivo}`, ordem: '+pes_nome' } })
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb" gutter={[8, 8]}>
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoAbrirChamado />
                        </Col>
                        <Col>
                            <BotaoIncluirDados />
                        </Col>
                        <Col>
                            <Dropdown overlay={menuOutros()} trigger={['click']}>
                                <Button size="middle" onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form layout="vertical" name="formularioPesqAvancadaFuncionarios" form={form} onFinish={retornoPesquisaAvancada}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={16}>
                        <PesquisaOrdenacao opcoesOrdenacao={[
                            { label: "Nome A - Z", value: "+pes_nome" },
                            { label: "Nome Z - A", value: "-pes_nome" },
                            { label: "Código Crescente", value: "+fun_id" },
                            { label: "Código Decrescente", value: "-fun_id" }
                        ]} />
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9} xl={6}>
                        <Form.Item label="Selecione Status do Funcionario" name="funcionarioAtivo">
                            <Radio.Group onChange={() => retornoPesquisaAvancada()}>
                                <Radio value={2}>Todos</Radio>
                                <Radio value={1}>Ativo</Radio>
                                <Radio value={0}>Inativo</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Collapse className="p-relative mt-coll" accordion ghost destroyInactivePanel={true}>
                            <div className="colDet"></div>
                            <Collapse.Panel key="1" showArrow={false} extra={
                                <Button type="primary" onClick={togglePesquisa} block>
                                    {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                </Button>
                            }>
                                <PesquisaAvancada funcaoRetorno={(dados) => retornoPesquisaAvancada(dados)} funcaoLimpar={() => { limparFiltroPesquisa() }} form={form} telaFiltro={4} />
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="Funcionario/Listar"
                    colunas={[
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ fun_id, fun_ativo }) => (
                                <Popover content={fun_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                    <Tag color={fun_ativo ? 'processing' : 'default'} className="w-100">
                                        <b>{fun_id}</b>
                                    </Tag>
                                </Popover>
                            ),
                        },
                        {
                            title: 'Funcionário',
                            render: ({ pes_nome }) => (
                                <div>
                                    <b>{pes_nome}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'CPF',
                            width: 138,
                            render: ({ pef_cpf }) => (
                                <div>
                                    <b> {MaskFormat(pef_cpf, 'Não Informado', true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Telefone',
                            width: 120,
                            render: ({ psc_numero }) => (
                                <div>
                                    <b>{FormatFone(psc_numero) || 'Não Informado'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Endereço',
                            render: ({ log_logradouro, pee_numero, bai_nome, cep_cep, cid_descricao, est_sigla }) => (
                                <div>
                                    <b>
                                        {!!log_logradouro ? log_logradouro : 'Não Informado'}
                                        {!!pee_numero ? (', ' + pee_numero) : ''}
                                        {!!bai_nome ? (', ' + bai_nome) : ''}
                                        {!!cep_cep ? ('- ' + cep_cep) : ''}
                                        {!!cid_descricao ? ('- ' + cid_descricao) : ''}
                                        {!!est_sigla ? ('- ' + est_sigla) : ''}
                                    </b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            align: 'center',
                            fixed: 'right',
                            key: 'x',
                            width: 65,
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Editar onClick={() => {
                                                editarFuncionario(record);
                                            }} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Excluir onClick={() => { modalExcluirRegistro(record) }} icon={<DeleteOutlined />} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]}
                    expandir={({ pem_email, pef_rg }) => {
                        return (
                            <Row gutter={[16, 0]}>
                                {!!pef_rg ? <Col>
                                    RG: <b>{pef_rg}</b>
                                </Col> : null}
                                <Col>
                                    E-mail: <b>{pem_email || 'Não Informado'}</b>
                                </Col>
                            </Row>
                        )
                    }} />
            </div>
            <DrawerFuncionario editando={{ editando, setEditando }} />
        </div>
    );
}