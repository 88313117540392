import React, { useEffect } from "react";
import { Row, Col, Card, Form, Typography, Input, Divider } from "antd";

import { FormatNumber } from "../../ValueObjects";
import { validarNumeros } from "../../services/funcoes";

export default function TabResumoTransferencia({ form, dadosItens, resumoNota }) {

    useEffect(() => {
        let formulario = form.getFieldValue();
        let resumo = {
            valorProduto: 0,
            valorFrete: 0,
            valorOutros: 0,
            valorSeguro: 0,
            valorBaseICMS: 0,
            valorICMS: 0,
            valorBaseST: 0,
            valorICMSST: 0,
            valorBaseIPI: 0,
            valorIPI: 0,
            valorBaseICMSST: 0
        };
        dadosItens.dadosItens.forEach((item) => {
            resumo.valorProduto += (item.nfi_valorunitario * item.nfi_qtde) || 0;
            resumo.valorFrete += (item.nfi_valorfrete) || 0;
            resumo.valorOutros += (item.nfi_valoroutros) || 0;
            resumo.valorSeguro += (item.nfi_valorseguro) || 0;
            resumo.valorBaseICMS += (item.baseICMS) || 0;
            resumo.valorICMS += (item.nig_valorIcms) || 0;
            resumo.valorBaseST += (item.nig_valorbcst) || 0;
            resumo.valorICMSST += (item.nig_valoricmsst) || 0;
            resumo.valorBaseIPI += (item.npi_valorbcipi) || 0;
            resumo.valorIPI += (item.npi_valoripi) || 0;
        });
        resumo.valorFrete = validarNumeros(formulario.valorFrete);
        resumo.valorSeguro = validarNumeros(formulario.valorSeguro);
        resumo.valorTotalNotaFiscal = resumo.valorProduto + resumo.valorFrete + resumo.valorOutros + resumo.valorSeguro + resumo.valorIPI + resumo.valorICMSST + resumo.valorICMS; 
        let dadosItensTemp = [...dadosItens.dadosItens];
        dadosItens.setDadosItens(dadosItensTemp);
        resumoNota.setResumoNota(resumo);
    }, []);

    return (
        <div className="m-t-16">
            <Row justify="center" gutter={[8, 16]}>
                <Col xs={24} sm={24} md={18} lg={15} xl={16} xxl={14}>
                    <Card extra={
                        <Row justify="center">
                            <Col>
                                <Typography.Title level={3}>
                                    Resumo da Nota Fiscal de Transferência
                                </Typography.Title>
                            </Col>
                        </Row>
                    }>
                        <Row gutter={[8, 0]} justify="end">
                            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                                <Form.Item label="Valor Total dos Produtos (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorProduto, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                                <Form.Item label="Valor do Frete (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorFrete, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                                <Form.Item label="Valor do Seguro (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorSeguro, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                                <Form.Item label="Outras Despesas (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorOutros, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                                <Form.Item label="Base de Cálculo do IPI (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorBaseIPI, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                                <Form.Item label="Valor do IPI (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorIPI, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={8}>
                                <Form.Item label="Base de Cálculo do ICMS (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorBaseICMS, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={8}>
                                <Form.Item label="Valor do ICMS (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorICMS, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={8}>
                                <Form.Item label="Base de Cálculo ICMS ST (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorBaseST, true)}</b>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12} xxl={8}>
                                <Form.Item label="Valor do ICMS ST (R$)" align="right">
                                    <b>{FormatNumber(resumoNota.resumoNota.valorICMSST, true)}</b>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Divider />
                            </Col>
                        </Row>
                        <Row justify="end">
                            <Col>
                                <Typography.Title level={3}>
                                    Total da Nota (R$)
                                </Typography.Title>
                            </Col>
                        </Row>
                        <Row justify="end">
                            <Col>
                                <b className="f-18">{FormatNumber(resumoNota.resumoNota.valorTotalNotaFiscal, true)}</b>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className="m-t-16">
                <Col span={24}>
                    <Form.Item label="Informações Adicionais da Nota" name="ntf_infcomplementar">
                        <Input.TextArea placeholder="Informações Adicionais da Nota" />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );
}