import React, { useEffect, useState } from "react";
import { Row, Col, Descriptions, Form, Input, Modal, notification } from "antd";
import { ExclamationCircleOutlined, CloudUploadOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../../services/api";
import { novaAbaNavegador } from "../../../services/funcoes";

export default function ModalExibirAcaoUsuario({ dadosModal, exibirModal, fecharModal }) {

    const onCloseModal = () => {
        fecharModal();
    };

    return (
        <Modal centered
            width="80%"
            title="Informação do Registro"
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={onCloseModal}
            cancelText={
                <>
                    <CloseOutlined /> Fechar
                </>
            }
            maskClosable={false}
            destroyOnClose
        >
            <Form layout="vertical">
                <Descriptions size="small">
                    <Descriptions.Item label="Json">
                        <b>{JSON.stringify(dadosModal.data)}</b>
                    </Descriptions.Item>
                </Descriptions>
            </Form>
        </Modal>
    );
}