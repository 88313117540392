import React, { useState, useEffect } from "react";
import { Row, Col, Tag, Divider, Button, Table, notification } from "antd";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";

import api from '../../services/api';

export default function TabPermissoesUsuario({ formulario }) {

    const [load, setLoad] = useState(false);
    const [listPermissoes, setListPermissoes] = useState([]);
    const [pagina, setPagina] = useState(1);
    const [nroRegistros, setNroRegistros] = useState(10);
    const [ordem, setOrdem] = useState('loc_id');

    useEffect(() => { loadPermissoes() }, [pagina, nroRegistros]);

    async function loadPermissoes() {
        setLoad(true);

        var _listaPermissoesPagina = await api.get(`PermissaoUsuario/ListarPermissoesPaginas`);
        if (_listaPermissoesPagina.status === 200) {
            const list = _listaPermissoesPagina.data;
            let userId = formulario.getFieldValue('usu_id');
            if (userId) {
                try {
                    var _listarPersmissoesMongo = await api.get(`PermissaoUsuario/Listar?idUser=${userId}`);
                    if (_listarPersmissoesMongo.status === 200) {
                        let dataAcess = _listarPersmissoesMongo.data;
                        for (let i in list) {
                            let idx = dataAcess.findIndex(x => x.pru_nome === list[i].pagina);
                            if (idx > -1) {
                                let obj = JSON.parse(dataAcess[idx].pru_permissoes);
                                let { api, sistema } = obj[0];
                                var permissoes = list[i].permissoes;
                                let acessoModulo = false;
                                if (typeof api != "undefined" && sistema != "undefined") {
                                    var permissoesApi = api[0].metodos;
                                    permissoes.forEach(e => {
                                        var indexMetodo = permissoesApi.findIndex(pm => pm.nome == e.metodo);
                                        e.acesso = permissoesApi[indexMetodo].acesso;
                                        if (!acessoModulo) acessoModulo = permissoesApi[indexMetodo].acesso ? true : false;
                                    });
                                } else {
                                    var acessoPermissoesMongo = obj;
                                    permissoes.forEach(e => {
                                        var indexMetodo = acessoPermissoesMongo.findIndex(pm => pm.metodo == e.metodo);
                                        e.acesso = acessoPermissoesMongo[indexMetodo].acesso;
                                        if (!acessoModulo) acessoModulo = acessoPermissoesMongo[indexMetodo].acesso ? true : false;
                                    });
                                }
                                if (acessoModulo) {
                                    list[i].acesso = true
                                }
                            }
                        }
                        setListPermissoes(list.map((x, idx) => ({ key: idx, ...x })));
                    }
                } catch (error) {
                    const array = list.map((x, idx) => ({ key: idx, ...x }));
                    setListPermissoes(array);
                    setLoad(false)
                }
            } else {
                const array = list.map((x, idx) => ({ key: idx, ...x }));
                setListPermissoes(array);
            }
        }
        setLoad(false)
    }

    const handleChangeBloqueioPagina = (key, metodo = null) => {
        let array = [...listPermissoes];
        let index = array.findIndex(x => x.key == key);
        if (index > -1) {
            if (metodo != null) {
                let idx = array[index].permissoes.findIndex(x => x.metodo == metodo);
                if (idx > -1)
                    array[index].permissoes[idx].acesso = !array[index].permissoes[idx].acesso;
            }
            else {
                array[index].acesso = !array[index].acesso;
                for (let y in array[index].permissoes)
                    array[index].permissoes[y].acesso = array[index].acesso;
            }
            setListPermissoes(array);
            formulario.setFieldsValue({ listaPermissoesPaginas: array });
        }
    }

    const handleChangeBloqueioAll = (option, list = null) => {

        let array = (list != null) ? list : [...listPermissoes];
        for (let i in array) {
            array[i].acesso = option;
            for (let y in array[i].permissoes) {
                array[i].permissoes[y].acesso = option;
            }
        }
        setListPermissoes(array);
        formulario.setFieldsValue({ listaPermissoesPaginas: array });
    }


    return (
        <div>
            <div>
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button type="primary" icon={<CheckOutlined />} onClick={() => handleChangeBloqueioAll(true)}>
                            Ativar Todas as Permissões
                        </Button>
                    </Col>
                    <Col>
                        <Button icon={<DeleteOutlined />} onClick={() => handleChangeBloqueioAll(false)}>
                            Remover Todas as Permissão
                        </Button>
                    </Col>
                </Row>
            </div>
            <div className="tabela mt-dif">
                <Table className="hide-header"
                    loading={load}
                    columns={[
                        {
                            title: '',
                            render: (record, { pagina, acesso, permissoes }) => (
                                <div>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col>
                                            <Tag
                                                className="cursor-pointer"
                                                color={acesso ? 'processing' : 'default'}
                                                onClick={() => handleChangeBloqueioPagina(record.key)}
                                            >
                                                <b>{(!!acesso) ? 'Liberado' : 'Bloqueado'}</b>
                                            </Tag>
                                        </Col>
                                        <Col>
                                            <b className="text-uppercase">{pagina}</b>
                                        </Col>
                                    </Row>
                                    {acesso &&
                                        <div>
                                            <Divider className="m-t-b-5" />
                                            <Row align="middle" gutter={[8, 0]}>
                                                {permissoes.map((item, idx) =>
                                                    <Col key={idx}>
                                                        <Row align="middle" gutter={[8, 0]}>
                                                            <Col>
                                                                {item.metodo}
                                                            </Col>
                                                            <Col>
                                                                <Tag className="cursor-pointer" color={item.acesso ? 'cyan' : 'default'} onClick={() => handleChangeBloqueioPagina(record.key, item.metodo)}>
                                                                    <b>{(item.acesso) ? 'Sim' : 'Não'}</b>
                                                                </Tag>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                )}
                                            </Row>
                                        </div>
                                    }
                                </div>
                            ),
                        },
                    ]}
                    dataSource={listPermissoes} scroll={{ x: 900 }}
                />
            </div>
        </div>
    );

}