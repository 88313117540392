import React, { useState } from "react";
import { Tag, Popover,  notification } from 'antd';

import api from '../../services/api';
import { FormGW, TabelaDados } from "../../components";

export default function ImportarOperacao({ formulario, carregando, aoSalvar }) {

    const[ operacao, setOperacao ] =  useState(null);

    function salvarOperacoes() {
        api.post(`OperacaoFiscal/ImportarOperacao`, operacao).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Operações Fiscais importadas com Sucesso!" });
            }
        ).catch(
            erro => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
            }
        ).finally(
            () => {
                carregando(false);
                aoSalvar();
            }
        );
    };

    function populaOperacaoSel(keys, linhas) {
        let operacao = [];
        // Extrai os ope_id das linhas selecionadas e cria um array de objetos
        operacao = linhas.map(linha => ({ope_id: linha.ope_id}));
        // Armazena o array de objetos diretamente
        setOperacao(operacao);
    };

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoMetas" onFinish={salvarOperacoes}>
            <div className="p-10">
                <div className="tabela">
                    <TabelaDados url="OperacaoFiscal/OperacaoFiscalOutrosLocais"
                        paginacao={true}
                        selecionarItem={true}
                        colunas={[
                            {
                                title: 'Código',
                                width: 75,
                                align: 'center',
                                render: ({ ope_id, ope_ativo }) => (
                                    <Popover content={ope_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                        <Tag color={ope_ativo ? 'processing' : 'default'} className="w-100">
                                            <b>{ope_id}</b>
                                        </Tag>
                                    </Popover>
                                ),
                            },
                            {
                                title: 'Operação Fiscal',
                                render: ({ ope_descricao }) => (
                                    <div>
                                        <b>{ope_descricao}</b>
                                    </div>
                                ),
                            }
                        ]
                        } parametrosSelecionarItens={
                            {
                                tipo: "change",
                                funcao: populaOperacaoSel
                            }
                        }
                    />
                </div>
            </div>
        </FormGW >
    );
}