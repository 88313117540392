import React, { useState } from "react";
import { Row, Col, Form, Input, Checkbox, Modal } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";

import { useStateValue } from "../../../state";

export default function ModalVeiculo({ form, listaVeiculos, exibirModalVeiculo, fecharModalVeiculo }) {

    const [veiculos, setVeiculos] = useState([]);
    const [{ manutencao }, dispatch] = useStateValue();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalVeiculo();
                form.resetFields();
            }
        });
    };

    const salvarVeiculo = record => {
        let indice = form.getFieldValue().indice;
        delete record.indice;
        let lista = [...listaVeiculos.dadosVeiculos];
        if(!!manutencao.dados){
            record.trn_id = manutencao.dados.trn_id;
        }
        if (indice >= 0) {
            lista[indice] = record;
        } else {
            lista.push(record);
        }
        listaVeiculos.setDadosVeiculos(lista);
        fecharModalVeiculo();
        form.resetFields();
    };

    return (
        <Modal centered
            title="Cadastro de Veículos"
            visible={exibirModalVeiculo}
            onCancel={onCloseModal}
            onOk={() => form.submit()}
            okText={
                <>
                    <PlusOutlined /> Adicionar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <Form layout="vertical" name="manutencaoVeiculo" form={form} onFinish={salvarVeiculo}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={8} md={6} lg={5} xl={5}>
                        <Form.Item name="indice" hidden />
                        <Form.Item name="trn_id" hidden />
                        <Form.Item name="trv_id" hidden />
                        <Form.Item label="Placa" name="trv_placa" rules={[{ required: true, message: 'Informe a placa' }]}>
                            <Input placeholder="Informe a Placa" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={6} lg={5} xl={5}>
                        <Form.Item label="UF" name="trv_uf" rules={[{ required: true, message: 'Informe a UF' }]}>
                            <Input placeholder="Informe a UF" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={6} lg={6} xl={6}>
                        <Form.Item label="RNTRC" name="trv_rntc">
                            <Input placeholder="Informe o RNTRC" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={6} lg={8} xl={8}>
                        <Form.Item label="Marca" name="trv_marca">
                            <Input placeholder="Informe a Marca" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={6} lg={8} xl={8}>
                        <Form.Item label="Modelo" name="trv_modelo" rules={[{ required: true, message: 'Informe o Modelo.' }]}>
                            <Input placeholder="Informe o Modelo" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item name="trv_ativo" valuePropName="checked" initialValue={true} className="t-mob-573">
                            <Checkbox> Veículo Ativo? </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Observação" name="trv_observacao">
                            <Input.TextArea placeholder="Informe a Observação" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}