import React from "react";
import { Tag, Table } from "antd";
import { FormatNumber } from "../../ValueObjects";

export default function TabDetalheDocs({ docs }) {
    return (
        <div className="tabela">
            <Table columns={
                [
                    {
                        title: 'Sequência',
                        align: 'center',
                        width: 80,
                        render: ({ doc_sequencia }) => (
                            <Tag color="processing" className="w-75">
                                <b>{doc_sequencia}</b>
                            </Tag>
                        )
                    },
                    {
                        title: 'Chave',
                        render: ({ doc_chaveacesso }) => (
                            <b>{doc_chaveacesso}</b>
                        )
                    },
                    {
                        title: 'Modelo',
                        align: 'center',
                        render: ({ doc_modelodoc }) => (
                            <b>{doc_modelodoc}</b>
                        )
                    },
                    {
                        title: 'Cidade',
                        align: 'center',
                        render: ({ cid_descricao }) => (
                            <b>{cid_descricao}</b>
                        )
                    },
                    {
                        title: 'Estado (UF)',
                        align: 'center',
                        render: ({ est_sigla }) => (
                            <b>{est_sigla}</b>
                        )
                    },
                    {
                        title: 'Peso',
                        align: 'center',
                        render: ({ doc_pesobruto }) => (
                            <b>{doc_pesobruto}</b>
                        )
                    },
                    {
                        title: 'Valor (R$)',
                        align: 'right',
                        fixed: 'right',
                        render: ({ doc_valortotal }) => (
                            <b>R$ {FormatNumber(doc_valortotal, true)}</b>
                        )
                    }
                ]
            } dataSource={docs}
            />
        </div>
    )
}