import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Tabs, Button, notification } from "antd";
import { EyeOutlined } from "@ant-design/icons";

import api from "../../services/api";
import TabItensGrade from './tabItens';
import { FormGW } from "../../components";
import { useStateValue } from "../../state";
import { listagemActions } from "../../actions";
import { validaForm } from "../../services/funcoes";

export default function ManutencaoGradeProduto({ formulario, carregando, aoSalvar }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [itensGrade, setItensGrade] = useState([]);

    const salvarManutencao = (values) => {
        if (validaForm(formulario, listaValidacoes)) {
            carregando(true);

            let dadosEnviar = {};
            dadosEnviar.mgp_id = (!!values.mgp_id ? values.mgp_id : null);
            dadosEnviar.grade = itensGrade;
            dadosEnviar.mgp_modalidade = (!!values.mgp_modalidade ? values.mgp_modalidade : null) ;
            dadosEnviar.mgp_descricao = values.mgp_descricao;
            dadosEnviar.mgp_imprimenaetiqueta = (!!values.mgp_imprimenaetiqueta ? values.mgp_imprimenaetiqueta : null);
            let enderecoUrl = 'ModalidadeGrade/';
            
            if (values.mgp_id ){
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, dadosEnviar).then(res => {
                notification.success({ message: 'Operação concluída', description: "Modalidade da Grade salva com sucesso!" });
            }).catch(
                erro => {
                    notification.error({ message: 'Atenção!', description: "Não foi possivel incluir os dados desta Modalidade da Grade!" });
                }).finally(() => {
                    aoSalvar();
                    carregando(false);
                    atualizarPagina();
                });
            } else {
            enderecoUrl += "Incluir"
            api.post(enderecoUrl, dadosEnviar).then(res => {
                            notification.success({ message: 'Operação concluída', description: "Modalidade da Grade salva com sucesso!" });
                        }).catch(
                            erro => {
                                notification.error({ message: 'Atenção!', description: "Não foi possivel incluir os dados desta Modalidade da Grade!" });
                            }).finally(() => {
                                aoSalvar();
                                carregando(false);
                                atualizarPagina();
                            });
                };
            }
        };

        function atualizarPagina() {
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+mgp_descricao' } });
            //listar();
        };

    const listaValidacoes = [
        { nome: 'mgp_descricao', obrigatorio: true, label: 'Descrição da Modalidade da Grade' }
    ];

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.mgp_id) {
            atualizarDadosGrade(manutencao.dados.mgp_id);
            setAbaSelecionada("1");
        } else {
            setItensGrade([]);
        }
    }, [manutencao.dados]);

    function atualizarDadosGrade(mgp_id) {
        if (!!mgp_id) {
            api.get(`ModalidadeGrade/BuscarModalidade/${mgp_id}`).then(res => {
                if (res.status === 200) {
                    let lista = res.data;
                    lista.map((item, indice) => {
                        item.key = indice + 1;
                    });
                    setItensGrade(res.data);
                }
            }).catch((erro) => console.error(erro));
        }
    };

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoGradeProduto" onFinish={salvarManutencao}>
            <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                <Tabs.TabPane tab="Dados da Modalidade de Grade" key="1">
                    <Row gutter={[8, 0]}>
                        <Form.Item name="mgp_id" hidden><Input /></Form.Item>
                        <Col xs={24} sm={24} md={16} lg={16} xl={17} xxl={18}>
                            <Form.Item label="Descrição da Modalidade da Grade" name="mgp_descricao" rules={[{ required: true, message: 'Informe a Descrição do Grupo' }]}>
                                <Input placeholder="Informe aqui a descrição do item de grade" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={7} xxl={6}>
                            <Button type="primary" icon={<EyeOutlined />} onClick={() => setAbaSelecionada("2")} block className="t-mob">Ver Itens desta modalidade de grade</Button>
                        </Col>
                    </Row>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Itens da Modalidade de Grade" key="2">
                    <TabItensGrade formulario={formulario} itensGrade={itensGrade} setItensGrade={setItensGrade} atualizarDadosGrade={atualizarDadosGrade} />
                </Tabs.TabPane>
            </Tabs>
        </FormGW>
    );

}