import React, { useEffect, useState } from "react";
import { Row, Col, Form, Modal, Select, Typography } from "antd";
import { ExclamationCircleOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";

export default function ModalDevolucao({ exibirModalDevolucao = false, fecharModalDevolucao, dadosNotaDevolucao, abrirManutencao }) {

    const [regimeTributario, setRegimeTributario] = useState([]);
    const [formulario] = Form.useForm();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalDevolucao();
            }
        });
    };

    useEffect(() => {
        api.get(`Enum/Listar?nome=RegimeTributarioFiscal`).then(
            res => {
                setRegimeTributario(res.data);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    }, []);

    function salvarRegimeTributario() {
        api.post(`Pessoa/EditarRegimeTributario?codigoPessoa=${dadosNotaDevolucao.pes_id}&codigoRegimeTributario=${formulario.for_regimetributariofiscal}`).then(res => {
            if (res.data) {
                fecharModalDevolucao();
            }
        }, erro => {
            Modal.error({
                title: `Atenção!`,
                content: `Não foi possivel alterar o regime tributário para o fornecedor: ${dadosNotaDevolucao.pes_nome}!`
            });
            fecharModalDevolucao(false);
        });
    };

    return (
        <Modal centered
            title="Devolução"
            visible={exibirModalDevolucao}
            onCancel={onCloseModal}
            onOk={salvarRegimeTributario}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }   
            maskClosable={false}
        >
            <Form layout="vertical" form={formulario}>
                <Row align="middle">
                    <Col span={24}>
                        <Typography.Title level={5}>
                            Para efetuar devolução de notas é necessário informar um regime tributário para o fornecedor: {dadosNotaDevolucao.pes_nome}
                        </Typography.Title>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Regime Tributário Fiscal" name="for_regimetributariofiscal">
                            <Select allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione um Regime Tributário Fiscal">
                                {regimeTributario.map(reg => (
                                    <Select.Option value={reg.key} key={reg.key}>{reg.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}