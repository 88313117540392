
// const dataBase = openDatabase("NotaEntradaXML", "1.0", "Teste Web SQL Database", 200000);


export function criarBanco(nomeTabela, campos = [], version) {
    const request = indexedDB.open("NotaEntradaXML", version);

    request.onupgradeneeded = function(event) {
        const db = event.target.result;
        const objectStore = db.createObjectStore(nomeTabela, { keyPath: 'id', autoIncrement: true });
        let i = 0;
        campos.forEach(campo => {
            
            if(version !== 1){
                if(i > 0) return;
                campo.forEach(campo => {
                    
                    objectStore.createIndex(campo.nome, campo.nome, { unique: !!campo.unico });
                })
            }
            else {
                
                objectStore.createIndex(campo.nome, campo.nome, { unique: !!campo.unico });
            }
            i++;
        });
    };

    request.onsuccess = function(event) {
        const db = event.target.result;
        db.close();
    };

    request.onerror = function(event) {
        console.log('Erro ao abrir o banco de dados:', event.target.error);
    };
}

export async function excluirBancoDados(callback) {    
    const tempDBName = "NotaEntradaXML";
    const request = indexedDB.deleteDatabase(tempDBName);

    request.onsuccess = function() {
        if (!!callback) {
            callback();
        }
    };

    request.onerror = function(event) {
        console.log("Erro ao excluir o banco de dados:", event.target.error);
    };
}

export async function incluirBanco(nomeTabela, dados = [], version) {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open("NotaEntradaXML", version);

        request.onsuccess = function(event) {
            
            const db = event.target.result;
            const transaction = db.transaction([nomeTabela], 'readwrite');
            const objectStore = transaction.objectStore(nomeTabela);

            dados.forEach(dado => {
                if (!dado.unico) {
                    const addObjectRequest = objectStore.add(dado);
                    addObjectRequest.onerror = function(event) {
                       console.log("Erro ao inserir dados: " + event.target.error);
                    };
                }
            });

            transaction.oncomplete = function() {
                db.close();
                resolve(); // Resolva a Promise após a inclusão dos dados
            };

            transaction.onerror = function(event) {
                reject(new Error("Erro ao iniciar transação: " + event.target.error));
            };
        };

        request.onerror = function(event) {
            reject(new Error("Erro ao abrir o banco de dados: " + event.target.error));
        };
    });
}


export function buscarDadosBanco(nomeTabela, campos = [], condicao, retorno = () => { }, callback) {
    if (!validarNomeTabela(nomeTabela)) return;

    const request = indexedDB.open("BancoTeste1", 1.0);
    
    request.onsuccess = function(event) {
        const db = event.target.value;
        const transaction = db.transaction([nomeTabela], "readonly");
        const objectStore = transaction.objectStore(nomeTabela);

        let index;

        if(!!condicao && condicao.campo) {
            index = objectStore.index(condicao.campo);
        }

        const getRequest = index ? index.get(condicao.valor) : objectStore.getAll();

        getRequest.onsuccess = function(event) {
            const result = event.target.value;
            console.log("Busca concluída com sucesso!");
            if (retorno) {
                retorno(result);
            }
            if(callback) {
                callback();
            }
            db.close();
        };

        getRequest.onerror = function(event) {
            console.log("Erro ao buscar dados: ", event.target.error);
        };
    };
}

export function buscarDadosTabela(retorno = [], tabela, callback, version) {
    const request = indexedDB.open("NotaEntradaXML", version);
    
    request.onsuccess = function(event) {
        
        const db = event.target.result;
        const transaction = db.transaction([tabela], 'readonly');
        const objectStore = transaction.objectStore(tabela);
        const getRequest = objectStore.getAll();

        getRequest.onsuccess = function(event) {
            const result = event.target.result;
            if(retorno) {
            
            retorno.push(result);
        }
        if (callback) {
            callback();
        }
            db.close();
        }
            getRequest.onerror = function(event) {
            console.log("Erro ao buscar dados: ", event.target.error);
        }
    }
    request.onerror = function(event) {
        console.log("Erro: ", event.target.error);
    }
}

export function editarDadosBanco(nomeTabela, alteracao = [], condicao, callback) {
    const request = indexedDB.open("NotaEntradaXML", 1.0);
    
    request.onsuccess = function(event) {
        const db = event.target.result;
        const transaction = db.transaction([nomeTabela], "readwrite");
        const objectStore = transaction.objectStore(nomeTabela);

        const getRequest = objectStore.get(condicao.valor);

        getRequest.onsuccess = function(event) {
            const data = event.target.result;
            alteracao.forEach(campo => {
                data[campo.campo] = campo.valor;
            });

            const updateRequest = objectStore.put(data);

            updateRequest.onsuccess = function(event) {
                if(!!callback) {
                    callback();
                }
            };
            updateRequest.onerror = function(event) {
                console.log("Erro ao editar dados: ", event.target.error);
            }
        }
        getRequest.onerror = function(event) {
            console.log("Erro ao buscar dados: ", event.target.error);
        }
    }
    request.onerror = function(event) {
        console.log("Erro ao abrir banco: ", event.target.error);
    }
}

export function excluirDadosBanco(nomeTabela, condicao, callback) {
    const request = indexedDB.open("NotaEntradaXML", 1);
    
    request.onsuccess = function(event) {
        const db = event.target.result;
        const transaction = db.transaction([nomeTabela], "readwrite");
        const objectStore = transaction.objectStore(nomeTabela);
        const deleteRequest = objectStore.delete(condicao.valor);

        deleteRequest.onsuccess = function(event) {
            if(callback) {
                callback();
            }
        }
        deleteRequest.onerror = function(event) {
            console.log("Erro ao excluir dados: ", event.target.error);
        }
    }
    request.onerror = function(event) {
        console.log("Erro ao abrir banco: ", event.target.error);
    }
}

// 

function validarNomeTabela(nomeTabela) {
    let retorno = false;
    if (!!nomeTabela) {
        retorno = true;
    } else {
        console.log(`Nome da tabela não informada!`);
    }
    return retorno;
};

function validarCondicao(condicao) {
    let retorno = false;
    if (!!condicao && !!condicao.campo && !!condicao.valor) {
        retorno = true;
    } else {
        console.log(`Condições não informadas!`);
    }
    return retorno;
};