import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Modal, notification } from "antd";
import { ExclamationCircleOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { isObjetoDiffVazio } from "../../../services/funcoes";

export default function ModalEditarPDV({ exibirModalPDV, fecharModalPDV, dadosPdv, setDadosPdv }) {

    const [serieFiscal, setSerieFiscal] = useState([]);
    const [formulario] = Form.useForm();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalPDV();
            }
        });
    };

    useEffect(() => {
        api.get(`SerieFiscal/Listar?CodigoModeloDocFiscal=65&EmitenteDocumento=0&pageSize=500`).then(
            (res) => {
                if (res.status === 200) {
                    setSerieFiscal(res.data.items);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    }, []);

    useEffect(() => {
        if (isObjetoDiffVazio(dadosPdv)) {
            formulario.setFieldsValue({ ser_id: dadosPdv.ser_id });
        }
    }, [dadosPdv])

    function editarPdv(valores) {
        let dadosTemp = serieFiscal.filter(ser => (ser.ser_id === valores.ser_id))[0]
        let dadosPdvTemp = dadosPdv;
        dadosPdvTemp.ser_id = dadosTemp.ser_id;
        dadosPdvTemp.ser_serie = dadosTemp.ser_serie;
        dadosPdvTemp.serieFiscal = dadosTemp;
        api.put(`Pdv/Editar`, dadosPdvTemp).then(
            (res) => {
                notification.success({ message: 'Sucesso!', description: 'Caixa Pdv editado com sucesso!' });
            }
        ).catch(
            error => {
                console.log(error);
                notification.warning({ message: 'Aviso!', description: 'Problema ao editar Caixa Pdv' });
            }
        ).finally(() => { fecharModalPDV(); })
    }

    return (
        <Modal centered
            title="PDV"
            visible={exibirModalPDV}
            onOk={() => formulario.submit()}
            onCancel={onCloseModal}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="manutencaoEditarPDV" form={formulario} onFinish={editarPdv}>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Série Fiscal" name="ser_id" rules={[{ required: true, message: 'Informe a Série Fiscal' }]}>
                                <Select allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione a Série Fiscal">
                                    {serieFiscal.map((serie) => (
                                        <Select.Option value={serie.ser_id} key={serie.ser_id}>{serie.ser_serie}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}