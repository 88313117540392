import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, Button, Collapse, Tag, Popover, Modal, notification } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, SearchOutlined, EyeOutlined, DeleteOutlined, ExclamationCircleOutlined, CheckCircleOutlined, CloseOutlined, PrinterOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import DrawerContaReceber from './drawer';
import { useStateValue } from '../../state';
import { FormatNumber, FormatDate } from '../../ValueObjects';
import { ModalListarParcelas } from "../../components/modals/";
import { imprimirRelatorioJasper } from "../../services/funcoes";
import { listagemActions, manutencaoActions, drawerActions } from "../../actions";
import { BreadcrumbIncluirDados, TabelaDados, ComboData, Data, FiltroSearch } from "../../components";

export default function ContaReceber() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [openPesquisaAvancada, setOpenPesquisaAvancada] = useState(false);
    const [dataPesquisa, setDataPesquisa] = useState(new Date().toLocaleDateString());
    const [dataAtual] = useState(new Date());
    const [parametrosContasReceber] = Form.useForm();
    const [listaStatusParcela, setListaStatusParcela] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [parcelas, setParcelas] = useState([]);

    const togglePesquisa = () => {
        setOpenPesquisaAvancada(!openPesquisaAvancada);
    };

    const modalNovaConta = (item) => {
        Modal.confirm({
            title: 'Conta estornada com sucesso!',
            icon: <CheckCircleOutlined />,
            content: `Deseja criar uma nova conta com base nesta conta estornada?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                api.get(`ContasPagarReceber/Buscar/${item.cpr_id}`).then(
                    res => {
                        let dadosTemp = res.data;
                        dadosTemp.valortotal = 0;
                        dadosTemp.cpr_datainclusao = moment(res.data.cpr_datainclusao);
                        dadosTemp.cpr_dataprimeiropagamento = moment(res.data.cpr_dataprimeiropagamento);
                        res.data.parcelas.forEach(parcela => {
                            dadosTemp.valortotal += parcela.prc_valor;
                        });

                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dadosTemp } });
                        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                    });
            }
        });
    };

    async function modalEstornar(item) {
        if (!!item.ntf_id) {
            Modal.warning({
                title: 'Aviso!',
                icon: <ExclamationCircleOutlined />,
                content: `Parcela ligada a Nota Fiscal: ${item.ntf_id}, realizar a devolução ou o cancelamento!`,
                centered: true
            });
            return false;
        } else {
            Modal.confirm({
                title: 'Estornar Parcela',
                icon: <ExclamationCircleOutlined />,
                content: `Deseja estornar a conta de ${item.pes_nome} no valor de ${FormatNumber(item.prc_valor, true)}?`,
                okText: 'Sim',
                cancelText: 'Não',
                centered: true,
                onOk() {
                    api.post(`ContasPagarReceber/CancelarContaPagarReceber?ContaPagarReceberId=${item.cpr_id}`).then(
                        () => {
                            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pes_nome' } })
                            modalNovaConta(item);
                        }).catch(
                            err => {
                                Modal.warning({
                                    title: 'Aviso!',
                                    icon: <ExclamationCircleOutlined />,
                                    content: !!err && !!err.response && !!err.response.data && !!err.response.data.Message ? err.response.data.Message : 'Erro ao efetuar o estorno da parcela!',
                                    centered: true
                                });
                            });
                }
            });
        }
    };

    useEffect(() => {
        api.get(`Enum/Listar?nome=StatusParcela`).then(res => {
            setListaStatusParcela(res.data);
        });
        parametrosContasReceber.setFieldsValue({ dataInicial: moment(new Date()), dataFinal: moment(new Date()) });
    }, []);

    useEffect(() => {
        carregarLista();
    }, [dataPesquisa]);

    function carregarLista() {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&tipoMovimentacao=1&dataInicial=${FormatDate(dataPesquisa)}&dataFinal=${FormatDate(dataAtual.toLocaleDateString())}`, ordem: '+pes_nome' } });
    }

    function aplicarFiltrosAvancados() {
        let filtros = '&tipoMovimentacao=1';
        filtros += `&dataInicial=${(!!parametrosContasReceber.getFieldValue().dataInicial ? moment(parametrosContasReceber.getFieldValue().dataInicial).format('YYYY-MM-DD') : FormatDate(dataAtual))}`;
        filtros += `&dataFinal=${(parametrosContasReceber.getFieldValue().dataFinal === undefined ? FormatDate(dataAtual) : FormatDate(parametrosContasReceber.getFieldValue().dataFinal._d.toLocaleDateString()))}`;
        filtros += `&statusParcela=${(!!parametrosContasReceber.getFieldValue().statusParcela ? parametrosContasReceber.getFieldValue().statusParcela.join(',') : '0,1,2,3')}`;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: filtros } });
    };

    function retornarStatus(prc_status) {
        if (prc_status === 0) {
            return 'Pendente'
        } else if (prc_status === 1) {
            return 'Rcto. Parcial'
        } else if (prc_status === 2) {
            return 'Liquidada'
        } else if (prc_status === 3) {
            return 'Reparcelada'
        }
    };

    function buscarParcelas(dados) {
        if (!!dados.cpr_id) {
            api.get(`Parcela/ListarParcelas?IdContaPagarReceber=${dados.cpr_id}&Estornado=false&PagarOuReceber=1&PageSize=100&Order=+prc_numeroparcela`).then(res => {
                setParcelas(res.data.items);
                setOpenModal(true);
            }).catch(
                error => {
                    notification.warning({ message: 'Aviso!', description: 'Erro ao pesquisar as parcelas!' });
                }
            );
        }
    };

    function imprimirContaReceber(record) {
        imprimirRelatorioJasper(37, { IdContaPagarReceber: record.cpr_id });
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Form layout="vertical">
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={18} xl={19} xxl={20}>
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={17} md={17} lg={18} xl={18}>
                                <FiltroSearch />
                            </Col>
                            <Col xs={24} sm={7} md={7} lg={6} xl={6}>
                                <ComboData form={parametrosContasReceber} eventoAoAlterar={() => {
                                    parametrosContasReceber.setFieldsValue({ dataFinal: moment(new Date()) });
                                    parametrosContasReceber.setFieldsValue({ dataInicial: moment(parametrosContasReceber.getFieldValue().dataPesquisa) });
                                    aplicarFiltrosAvancados();
                                }} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            <Form layout="vertical" name="formParametrosContasReceber" form={parametrosContasReceber}>
                <Row align="middle" gutter={[0, 24]}>
                    <Col span={24}>
                        <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                            <div className="colDet"></div>
                            <Collapse.Panel key="1" showArrow={false} extra={
                                <Button type="primary" onClick={togglePesquisa} block>
                                    {openPesquisaAvancada ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                </Button>
                            }>
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={24} md={24} lg={16} xl={11} xxl={13}>
                                        <Form.Item label="Situação da Parcela" name="statusParcela">
                                            <Select mode="multiple" showSearch optionFilterProp="children" placeholder="Situação">
                                                {listaStatusParcela.map(item => (
                                                    <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={6} md={6} lg={4} xl={3} xxl={3}>
                                        <Data label="Data Incial" name="dataInicial" />
                                    </Col>
                                    <Col xs={24} sm={6} md={6} lg={4} xl={3} xxl={3}>
                                        <Data label="Data Final" name="dataFinal" />
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={24} xl={7} xxl={5} className="btn-mob">
                                        <Row gutter={[8, 8]} justify="end">
                                            <Col xs={24} sm={12} md={12} lg={4} xl={12} xxl={12}>
                                                <Button icon={<CloseOutlined />} block>
                                                    Remover Filtros
                                                </Button>
                                            </Col>
                                            <Col xs={24} sm={12} md={12} lg={4} xl={12} xxl={12}>
                                                <Button type="primary" onClick={() => aplicarFiltrosAvancados()} icon={<SearchOutlined />} block>
                                                    Aplicar Filtros
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="contasPagarReceber/ListarPagamentoRecebimento" colunas={[
                    {
                        width: 105,
                        render: ({ prc_status }) => (
                            <Popover content={() => {
                                if (prc_status === 0 || prc_status === 1 || prc_status === 3) {
                                    return 'Parcela Pendente'
                                } else if (prc_status === 2) {
                                    return 'Parcela Liquidada'
                                }
                            }} placement="right">
                                <Tag color={prc_status === 0 || prc_status === 1 || prc_status === 3 ? 'red' : 'green'} className="w-100">
                                    <b>{retornarStatus(prc_status)}</b>
                                </Tag>
                            </Popover>
                        ),
                    }, {
                        title: 'Nº Doc.',
                        width: 80,
                        align: 'center',
                        render: ({ cpr_numerodocumento }) => (
                            <Tag color="processing" className="w-100">
                                <b>{cpr_numerodocumento}</b>
                            </Tag>
                        ),
                    }, {
                        title: 'Cliente',
                        width: 330,
                        render: ({ pes_nome }) => (
                            <div>
                                <b>{pes_nome}</b>
                            </div>
                        ),
                    }, {
                        title: 'Vencimento',
                        width: 80,
                        align: 'center',
                        render: ({ prc_datavencimento }) => (
                            <div>
                                <b>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                            </div>
                        ),
                    }, {
                        title: 'Observação',
                        width: 300,
                        render: ({ cpr_observacao }) => (
                            <div>
                                <b>{cpr_observacao}</b>
                            </div>
                        ),
                    }, {
                        title: 'Valor (R$)',
                        align: 'right',
                        width: 200,
                        render: ({ prc_valor }) => (
                            <div>
                                <b>{FormatNumber(prc_valor, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 95,
                        fixed: 'right',
                        render: ({ prc_status }, record, dados) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button onClick={() => buscarParcelas(record)} icon={<EyeOutlined />} />
                                    </Col>
                                    <Col>
                                        <Button onClick={() => imprimirContaReceber(record)} icon={<PrinterOutlined />} />
                                    </Col>
                                    <Col>
                                        {(prc_status === 0 || prc_status === 1 || prc_status === 3) && <Button icon={<DeleteOutlined />} onClick={() => modalEstornar(record)} />}
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} expandir={({ cpr_descricao, ntf_numero, prc_numeroparcela, saldo }) => {
                    return (
                        <div>
                            <Row justify="space-between">
                                <Col>
                                    Obs.: <b>{cpr_descricao || ``}</b>
                                </Col>
                                <Col>
                                    Nota N°: <b> {ntf_numero || 'Não Informado'}</b>
                                </Col>
                                <Col>
                                    Parcela N°: <b>{prc_numeroparcela || 'Não Informado'}</b>
                                </Col>
                                <Col>
                                    Saldo Devedor (R$): <b>{FormatNumber(saldo, true)}</b>
                                </Col>
                            </Row>
                        </div>
                    )
                }} />
            </div>
            <DrawerContaReceber />
            <ModalListarParcelas exibirModal={openModal} fecharModal={() => setOpenModal(false)} parcelas={parcelas} setParcelas={setParcelas} />
        </div>


    )
}