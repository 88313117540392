import React from "react";
import { Button } from "antd";
import { EditOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import { drawerActions } from "../../actions";
import { setTela } from "../../services/auth";
import { gravarTela } from "../../services/navigation";

export default function EditarTabela(props) {

    const [{ ui, manutencao }, dispatch] = useStateValue();

    function editarRegistroTab() {
        props.onClick();
        setTimeout(() => {
            let dados = {};
            let url = window.document.location.href;
            let nomeForm = url.substring(url.lastIndexOf('/'), url.length);
            dados = props.registro;
            dados.formulario = nomeForm;
            dados.editar = true;
            setTela(JSON.stringify(dados));
            setTimeout(() => {
                gravarTela(nomeForm, manutencao);
            }, 300);
            dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
        }, 200);
    };

    return (
        <Button icon={<EditOutlined />} onClick={() => editarRegistroTab()}/>
    );

}