import React, { useEffect, useState } from "react";
import { Tabs, Typography, Row, Col, Form, Divider, Input, Select, Button, Radio, Checkbox, Switch, Tooltip, Modal, notification, Spin, Descriptions, Steps } from "antd";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { FormGW, InputPreco, FormularioDinamico, Data, SelectPaginacao } from "../../components";
import { getClienteCat, getEmailUsuario } from "../../services/auth";
import ItensPedidoCat from "./itensPedidoCat";
import FaturamentoPedidoCat from "./faturamentoPedido";
import { loadShoppingCartItems } from "../../services/CarrinhoDB";
import { GerarChavePedido } from "../../services/funcoesGestor";

export default function FecharPedidoCatalogo({ carregando, pages, aoSalvar, editando, formulario }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [dadosPedidos, setDadosPedidos] = useState({});
    const [carregarPagina, setCarregarPagina] = useState(false);
    const [itensCarrinho, setItensCarrinho] = useState([]);

    useEffect(() => {
        buscarDados();
    }, []);

    async function buscarDados() {
        let dataAtual = new Date();
        let codCliente = getClienteCat();
        let dadosCliente = await buscarDadosCliente(codCliente);
        let obj = {
            codigoLocal: 1,
            chave: "",
            emailUsuario: getEmailUsuario(),
            nome: dadosCliente.nome,
            cliCpfCnpj: dadosCliente.cpfCnpj,
            endereco: dadosCliente.endereco,
            cidade: dadosCliente.nomeCidade,
            cep: dadosCliente.cep,
            estado: dadosCliente.codUF,
            observacao: "",
            itens: [],
        };
        loadShoppingCartItems(setItensCarrinho, (itens) => {
            itens.forEach(item => {
                let dado = {
                    codProduto: item.codigo,
                    descricao: item.descricao,
                    quantidade: item.Qtd,
                    valorUnitario: item.valor,
                }
                obj.itens.push(dado);
            });
            setDadosPedidos(obj);
        });
    }

    async function buscarDadosCliente(codigo) {
        let retorno =
            await api.get(`Ges040/BuscarClienteCodigo?codigo=${codigo}`);
        if (!!retorno.data) {
            return retorno.data;
        } else {
            return null;
        }
    }

    function salvarDados() {
        let dadosForm = formulario.getFieldValue();
        /*if (dadosForm.cpg_id == null) {
            notification.warn({ description: "Favor preencher os dados de faturamento!", message: "Aviso" });
            if (pages.pages != 1) {
                pages.setPages(1);
            }
            return;
        }*/
        let pedido = { ...dadosPedidos };
        let codCliente = getClienteCat();
        /* pedido.tipoCobranca = dadosForm.Codigo;
         pedido.condPagamento = dadosForm.cpg_id;
         */
        pedido.chave = GerarChavePedido(codCliente);
        carregando(true);
        api.post("Pedido/SalvarOrcamentoCat", pedido).then(
            res => {
                notification.success({ message: "Sucesso", description: "Pedido Incluído com Sucesso!" });
                let dados = res.data;
                aoSalvar();
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                carregando(false);
            }
        )
    }

    return (
        <div className="pages-col">
            <Spin spinning={carregarPagina} tip="Carregando...">
                <FormGW layout="vertical" form={formulario} name="manutencaoPedido" onFinish={salvarDados}>
                    <Row gutter={[8, 0]}>
                        <Descriptions>
                            <Descriptions.Item label="Cliente">
                                {dadosPedidos?.nome}
                            </Descriptions.Item>
                            <Descriptions.Item label="CNPJ">
                                {dadosPedidos?.cliCpfCnpj}
                            </Descriptions.Item>
                            <Descriptions.Item label="Endereco">
                                {dadosPedidos?.endereco}
                            </Descriptions.Item>
                            <Descriptions.Item label="Cidade">
                                {dadosPedidos?.cidade}
                            </Descriptions.Item>
                            <Descriptions.Item label="Cep">
                                {dadosPedidos?.cep}
                            </Descriptions.Item>
                            <Descriptions.Item label="Estado">
                                {dadosPedidos?.estado}
                            </Descriptions.Item>
                        </Descriptions>
                    </Row>
                    <Row gutter={[0, 24]}>
                        <Col span={24}>
                            <Steps size="small" type="navigation" current={0}>
                                <Steps.Step title="Itens" />
                            </Steps>
                        </Col>
                    </Row>
                    <Row gutter={[8, 24]}>
                        <Col span={24}>
                            <ItensPedidoCat formulario={formulario} dados={{ dadosPedidos, setDadosPedidos }} />
                        </Col>
                    </Row>
                    {/*<Row gutter={[0, 24]}>
                        <Col span={24}>
                            <Steps size="small" type="navigation" onChange={(e) => {
                                pages.setPages(e);
                            }} current={pages.pages}>
                                <Steps.Step title="Itens" />
                                <Steps.Step title="Faturamento" />
                            </Steps>
                        </Col>
                    </Row>
                    <Row gutter={[8, 24]}>
                        <Col span={24}>
                            {pages.pages === 0 &&
                                <ItensPedidoCat formulario={formulario} dados={{ dadosPedidos, setDadosPedidos }} />}
                            {pages.pages === 1 &&
                                <FaturamentoPedidoCat formulario={formulario} />}
                        </Col>
                            </Row>*/}
                </FormGW>
            </Spin>
        </div>
    );
}