import React from "react";
import { Card, Descriptions, Tooltip, Button, Row, Col, Tag, Popconfirm } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import { TiposElementosFormulario } from './pages';
import { render } from "react-dom";

export default function ItemFormulario({ elemento, fnEditar = () => { }, fnExcluir = () => { }, status }) {
    function RetornaDescricaoElementoFormulario(tipo) {
        let retorno = TiposElementosFormulario.filter(el => el.value === tipo);
        return (retorno.length > 0 ? retorno[0].label : "");
    }
    return (
        <Card hidden={status === 3}
            className="zIndex1">
            <Row gutter={[8, 0]}>
                <Col span={24}>
                    <Descriptions size="small" title={elemento.cap_label} column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 1, xs: 1 }} className="m-b-0">
                        <Descriptions.Item label="Linha">
                            <b>{elemento.cap_linha}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Col">
                            <b>{elemento.cap_coluna}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Tipo">
                            <b>{RetornaDescricaoElementoFormulario(elemento.cap_tipoelemento)}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Atributo">
                            <b>{elemento.cap_nomeelemento}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Obrigatório">
                            <Tag color={elemento.cap_obrigatorio ? "blue" : ""}>
                                {elemento.cap_obrigatorio ? "SIM" : "NÃO"}
                            </Tag>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <div className="col-linha-direita">
                <Row justify="end" gutter={[5, 0]}>
                    <Col>
                        <Tooltip title="Editar elemento do formulário">
                            <Button shape="circle" icon={<EditOutlined />} onClick={() => fnEditar()} />
                        </Tooltip>
                    </Col>
                    <Col>
                        <Tooltip title="Excluir elemento do formulário">
                            <Popconfirm placement="left" title={`Deseja realmente excluir o elemento: ${elemento.cap_label}?`} onConfirm={() => fnExcluir()} okText="Sim" cancelText="Não">
                                <Button shape="circle" icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Tooltip>
                    </Col>
                </Row>
            </div>
        </Card>

    );
}