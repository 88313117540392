import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Input, Select, Radio } from "antd";
import moment from 'moment';

import api from '../../services/api';
import { Data, InputPreco, SelectIncluir } from '../../components';
import DrawerTransportadores from '../../components/drawerTransportadores';
import SelectPaginado from "../../components/selectPaginado";

export default function TabTransporte({ form, dadosVeiculos, valorTotalPedido }) {

    const [dadosTransportador, setDadosTransportador] = useState([]);
    const [formTransp] = Form.useForm();
    const [mostrarModalTransp, setMostrarModalTransp] = useState(false);
    const [mostrarTransportador, setMostrarTransportador] = useState(true);

    useEffect(() => {
        carregarDados();
    }, []);

    function carregarDados() {
        if (!!form.getFieldValue().enderecoCliente) {
            form.setFieldsValue({ pee_id: form.getFieldValue().enderecoCliente });
        }
        if (!!form.getFieldValue().trn_id) {
            buscarVeiculos();
        }
        if (form.getFieldValue().ped_modalidadefrete === 9) {
            setMostrarTransportador(false);
        }
        form.setFieldsValue({ ped_dataprevisaoentrega: moment(new Date()) });
        //carregarListaTransp();
    };

    function buscarVeiculos() {
        if (!!form.getFieldValue().trn_id) {
            api.get(`VeiculoTransportador/ListarVeiculoTransportador?codigoTransportador=${form.getFieldValue().trn_id}`).then(
                (res) => {
                    if (res.status === 200) {
                        dadosVeiculos.setDadosVeiculos(res.data.items);
                        form.setFieldsValue({ trv_id: res.data.items[0].trv_id });
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            );
        }
    };

    function retornaValorFrete() {
        let pdi_valorfrete = 0;
        let dados = [...dadosTransportador];
        let transportador = dados.filter((transp) => (transp.trn_id === form.getFieldValue().trn_id))[0];
        if (!!transportador) {
            if (!!transportador.trn_valorfretefixo && transportador.trn_valorfretefixo > 0) {
                pdi_valorfrete = transportador.trn_valorfretefixo;
            } else if (!!transportador.trn_percentualfrete && transportador.trn_percentualfrete > 0) {
                pdi_valorfrete = (valorTotalPedido * transportador.trn_percentualfrete) / 100;
            }
        }
        form.setFieldsValue({ pdi_valorfrete: pdi_valorfrete });
    };

    function abrirDrawerTransportador() {
        setMostrarModalTransp(true);
    };

    function fecharModalTransp() {
        //carregarListaTransp();
        setMostrarModalTransp(false);
    };

    function alterarFrete(event) {
        if (event.target.value === 9) {
            form.setFieldsValue({ trn_id: null });
            form.setFieldsValue({ trv_id: null });
            form.setFieldsValue({ pdi_valoroutrasdespesas: 0 });
            form.setFieldsValue({ pdi_valorfrete: 0 });
            setMostrarTransportador(false);
        } else {
            setMostrarTransportador(true);
        }
    };

    function carregarListaTransp(callback) {
        api.get(`Transportador/ListarTransportador`).then(
            (res) => {
                let seq = 0;
                res.data.forEach((transp) => {
                    transp.key = seq++;
                });
                setDadosTransportador(res.data);
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
        if (!!callback) {
            callback();
        }
    }

    return (
        <div>
            <Row gutter={[8, 0]}>
                <Col span={24}>
                    <Form.Item label="Modalidade do Frete" name="ped_modalidadefrete" initialValue={9}>
                        <Radio.Group onChange={(dados) => { alterarFrete(dados) }}>
                            <Radio value={0}>0 - Contratação do Frete por conta do Remetente (CIF)</Radio>
                            <Radio value={1}>1 - Contratação do Frete por conta do Destinatário (FOB)</Radio>
                            <Radio value={2}>2 - Contratação do Frete por conta de Terceiros</Radio>
                            <Radio value={3}>3 - Transporte Próprio por conta do Remetente</Radio>
                            <Radio value={4}>4 - Transporte Próprio por conta do Destinatário</Radio>
                            <Radio value={9}>9 - Sem Frete</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            {mostrarTransportador &&
                <Row gutter={[8, 0]} className="m-t-16">
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Transportador" name="trn_id" rules={[{ required: true, message: 'Selecione o Transportador' }]}>
                            <SelectPaginado url="Transportador/ListarTransportador" placeholder="Selecione o Transportador" form={form} onBlurFunction={(campo) => { buscarVeiculos(campo); retornaValorFrete(campo); }} name="trn_id" conteudo={
                                trn => (<Select.Option value={trn.trn_id} key={trn.trn_id}>{trn.trn_id} - {trn.pes_nome}</Select.Option>)
                            }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Form.Item label="Veículos da Transportadora" name="trv_id">
                            <Select placeholder="Selecione um Veículos da Transportadora" allowClear={true}>
                                {dadosVeiculos.dadosVeiculos.map((veiculo) => (<Select.Option value={veiculo.trv_id} key={veiculo.trv_id}>{veiculo.trv_placa} - {veiculo.trv_marca}-{veiculo.trv_modelo}</Select.Option>))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <Data label="Previsão de Entrega" name="ped_dataprevisaoentrega" />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <InputPreco name="pdi_valorfrete" label="Valor do Frete (R$)" />
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                        <InputPreco label="Valor de Outras Despesas (R$)" name="pdi_valoroutrasdespesas" />
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Observações" name="ped_observacao">
                            <Input.TextArea placeholder="Adicione aqui as Informações Adicionais do Pedido" />
                        </Form.Item>
                    </Col>
                </Row>
            }
            <DrawerTransportadores form={formTransp} exibirDrawerTransportadores={mostrarModalTransp} fecharDrawerTransportadores={fecharModalTransp} />
        </div>
    );
}