import React, { useEffect } from "react";
import { Row, Col, Tag, Popover, notification, Modal } from "antd";
import { EditOutlined, DeleteOutlined, CopyOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from '../../services/api';
import DrawerGrupoProduto from './drawer';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, Editar, Excluir, Duplicar } from "../../components";

export default function GrupoProduto() {

    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+gru_nome'}}), []);

    function excluirRegistro(id) {
        api.delete(`GrupoProduto/Excluir/${id}`).then((res) => {
            if (res.status === 200) notification.success({ message: 'Operação concluída', description: "Registro excluído com sucesso!" });
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null}});
        }, erro => {
            notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
        }).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+gru_nome' } });
        });
    }

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };  

    const modalDuplicarDados = (dados) => {
        Modal.confirm({
            title: 'Duplicar?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja duplicar este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                dados.duplicar = true;
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados }});
                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true }});
            }
        });
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+gru_nome" },
                { label: "Nome Z - A", value: "-gru_nome" }, 
                { label: "Código Crescente", value: "+gru_id" },
                { label: "Código Decrescente", value: "-gru_id" }
            ]} /> 
            <div className="tabela">
                <TabelaDados url="grupoProduto/listar"  colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align:'center',
                            render: ({gru_id, gru_ativo}) => (
                                <Popover content={gru_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                    <Tag color={gru_ativo ? 'processing' : 'default'} className="w-100">
                                        <b>{gru_id}</b>
                                    </Tag>
                                </Popover>
                            ),
                        },
                        { 
                            title: 'Grupos de Produtos', 
                            render: ({gru_nome}) => (
                                <div>
                                    <b>{gru_nome}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',                            
                            fixed: 'right',
                            width: 95,
                            render: (record, dados) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Duplicar icon={<CopyOutlined />} onClick={() => {modalDuplicarDados(dados)}}/>
                                        </Col>
                                        <Col>
                                            <Editar onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record }});
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true }});
                                            }} icon={<EditOutlined />}/>
                                        </Col>
                                        <Col>
                                            <Excluir icon={<DeleteOutlined />} onClick={() => {modalExcluir(dados.gru_id)}} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } />
            </div>   
            <DrawerGrupoProduto cadGrupo={ true }/>
        </div>
    );
    
}