import React, { useState, useEffect } from "react";
import { Form, Layout, Menu, Row, Col, Input } from "antd";
import { Icon } from '@ant-design/compatible';

import "./index.less";
import LinkNav from "../linkNav";
import api from "../../services/api";
import { validarPermissaoMenu } from "../../services/permissoes";
import { listagemActions } from "../../actions";
import { useStateValue } from "../../state";
import { getTelaAnterior } from "../../services/auth";
import apiWeb from "../../services/apiWeb";

export default function MenuPage({ incollapsed, catalogo = false }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [listaMenu, setListaMenu] = useState([]);
    const [openKeys, setOpenKeys] = useState([]);
    const rootKeys = ["sub0", "sub1", "sub2", "sub3", "sub4", "sub5", "sub6", "sub7", "sub8", "sub9", "sub10", "sub11", "sub12", "sub13", "sub14", "sub15", "sub16", "sub17", "sub18","sub19"];

    const onOpenChange = (items) => {
        const latestOpenKey = items.find((key) => openKeys.indexOf(key) === -1);
        if (rootKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(items);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : openKeys);
        }
    };

    function CarregaMenu() {
        apiWeb.get(`GrupoMenu/RetornaMenu?filtro&IdModulo=${catalogo ? '3' : '2'}`).then((res) => {
            if (res.status === 200) {
                setListaMenu(res.data);
            }
        }).catch((erro) => console.error(erro));
    }

    function AtzBreadcrumb(item) {
        let elemento = item.item.props.elementRef.current;
        let elementoPai = elemento.parentNode.parentNode.firstChild;
        if (elemento.innerText !== "Home") {
            localStorage.setItem('@GestorWeb-Breadcrumb', '|' + elementoPai.innerText + '|' + elemento.innerText);
        } else {
            localStorage.setItem('@GestorWeb-Breadcrumb', '|' + elemento.innerText);
        }
    }

    useEffect(() => {
        CarregaMenu()
        /*if (catalogo) {
            let keys = openKeys;
            keys.push("sub18");
            setOpenKeys(keys);
        }*/
    }, []);
    useEffect(() => {
        if (!incollapsed) {
            setOpenKeys(openKeys);
        }
    }, [openKeys, incollapsed]);

    function pesquisarMenu(e) {
        if (!!!e.target.value) {
            CarregaMenu();
        } else {
            api.get(`GrupoMenu/RetornaMenu?filtro=${e.target.value}&IdModulo=${catalogo ? '3' : '2'}`).then(
                res => {
                    let dados = [...res.data];
                    dados.forEach((menu) => {
                        menu.subMenu = menu.subMenu.filter((subMenu) => (subMenu.titulo.toLowerCase().indexOf(e.target.value.toLowerCase().trim()) > -1));
                    });
                    dados = dados.filter((menu) => (menu.subMenu.length > 0));
                    setListaMenu(dados);
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            );
        }
    };

    return (
        <Layout.Sider breakpoint="lg" collapsedWidth="0" className="menu-lateral">
            <>
                <Row justify="center" className="col-busca-menu">
                    <Col span={21}>
                        <Form.Item>
                            <Input.Search placeholder="Pesquisar..." size="middle" onPressEnter={(valor) => { pesquisarMenu(valor) }} onBlur={(valor) => { pesquisarMenu(valor) }} allowClear />
                        </Form.Item>
                    </Col>
                </Row>
                <Menu theme="light" mode="inline" defaultSelectedKeys={["0"]} onOpenChange={onOpenChange} openKeys={openKeys} onClick={(item) => AtzBreadcrumb(item)}>
                    <Menu.SubMenu key={`sub0`} id={`sub0`} onTitleClick={() => {
                        if (catalogo) {
                            window.location = "/catalogo#/"
                        } else {
                            window.location = "/Home#/"
                        }
                    }}
                        title={
                            <span>
                                <Icon type={"Home"} />
                                <span> Home </span>
                            </span>
                        } className="home">
                    </Menu.SubMenu>
                    {listaMenu.map(menu => (
                        <Menu.SubMenu key={`sub${menu.ordemMenu}`}
                            id={`sub${menu.ordemMenu}`}
                            title={
                                <span>
                                    <Icon type={menu.icone} />
                                    <span> {menu.titulo} </span>
                                </span>
                            }>
                            {menu.subMenu.map(itemMenu => {
                                return itemMenu.pagina.substring(0, 4) !== 'http' ?
                                    (
                                        <Menu.Item key={itemMenu.itm_id} disabled={validarPermissaoMenu(itemMenu)}>
                                            <LinkNav patch={itemMenu.pagina} label={itemMenu.titulo} icone={itemMenu.icone} onClick={() => {
                                                if (itemMenu.pagina !== getTelaAnterior()) {
                                                    dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: ``, filtro: '', ordem: '' } });
                                                }
                                            }} />
                                        </Menu.Item>
                                    ) :
                                    (
                                        <div className="ant-menu-item ant-menu-item-only-child">
                                            <a href={itemMenu.pagina} target="_blank">
                                                <Icon type={(!!itemMenu.icone ? itemMenu.icone : "DoubleRight")} /> {itemMenu.titulo}
                                            </a>
                                        </div>
                                    )
                            })
                            }
                        </Menu.SubMenu>
                    ))}

                </Menu>
            </>
        </Layout.Sider>

    );

}