import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Typography, Input, Button, Tag, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from "../../state";


export default function OutrasFiliais() {

    const searchInput = useRef();
    const [{ manutencao }, dispatch] = useStateValue();
    const [filtros, setFiltros] = useState([]);
    const [dadosEstoque, setDadosEstoque] = useState([]);
    const [dadosEstoqueGeral, setDadosEstoqueGeral] = useState([]);
    const [pagina, setPagina] = useState(1);
    const [registrosPorPagina, setRegistrosPorPagina] = useState(10);
    useEffect(() => {
        let items = [];
        dadosEstoqueGeral.filter(
            (estoque, index) => {

                let retorno = true;
                filtros.forEach((filtro, index, array) => {
                    if (!(estoque[filtro.campo].toLowerCase().indexOf(filtro.valor.toLowerCase()) > -1) && filtro.valor !== '') {
                        retorno = false;
                    }
                    if (index === array.length - 1 && retorno) {
                        items.push(estoque)
                    }

                })
                if (index === dadosEstoque.length - 1) {
                    if (items.length > 0 || filtros.length > 0) {
                        setDadosEstoque(items);
                    } else {
                        setDadosEstoque(dadosEstoqueGeral);
                    }
                } else {
                    setDadosEstoque(dadosEstoqueGeral);
                }
            }
        );
    }, [filtros]);

    useEffect(() => {
        if (!!manutencao.dados) {
            buscarDadosEstoque();
        }
    }, [manutencao.dados]);

    function buscarDadosEstoque() {
        api.get(`Estoque/Listar?CodProduto=${manutencao.dados.pro_id}&TipoLista=2`).then(
            res => {
                if (res.status === 200) {
                    let sequencia = 1;
                    res.data.items.forEach(item => {
                        item.indice = sequencia;
                        sequencia++;
                    });
                    setDadosEstoqueGeral(res.data.items);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    useEffect(() => {
        if (!!dadosEstoqueGeral && dadosEstoqueGeral.length > 0) {
            setDadosEstoque(dadosEstoqueGeral);
        }
    }, [dadosEstoqueGeral]);

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }) => (
            <div className="p-10">
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Input
                            ref={searchInput}
                            placeholder={`Buscar por...`}
                            value={selectedKeys[0]}
                            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={() => handleSearch(selectedKeys, dataIndex, clearFilters)}
                        />
                    </Col>
                    <Col span={24}>
                        <Button type="primary" onClick={() => handleSearch(selectedKeys, dataIndex, clearFilters)} icon={<SearchOutlined />} size="small" block>
                            Filtrar
                        </Button>
                    </Col>
                </Row>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.focus());
            }
        },
        render: text => text
    });

    const handleSearch = (selectedKeys, dataIndex, clearFilters) => {
        let listaFiltros = [...filtros];
        let indice = listaFiltros.findIndex(f => f.campo === dataIndex);
        if (indice < 0) {
            listaFiltros.push({ campo: dataIndex, valor: selectedKeys[0], clearFilters });
        } else {
            listaFiltros[indice].valor = selectedKeys[0];
        }
        setFiltros(listaFiltros);
    };

    return (
        <div>
            <Row align="middle" gutter={[8,0]}>
                <Col>
                    <Typography.Text strong>
                        A lista abaixo está sendo filtrada por:
                    </Typography.Text>
                </Col>
                <Col>
                    {filtros.map((_filtro, indice) => (
                        <Tag closable onClose={() => {
                            filtros[indice].clearFilters();
                            let _filtros = [...filtros];
                            _filtros.splice(indice, 1);
                            setFiltros(_filtros);
                        }}>
                            {`${_filtro.valor}`}
                        </Tag>
                    ))}
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Table columns={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ loc_codigo }) => (
                           <div>
                                <Tag color="processing" className="w-100">
                                    <b className="w-100">{loc_codigo}</b>
                                </Tag>
                            </div>
                        ),
                    },
                    {
                        title: 'Produto',
                        ...getColumnSearchProps('loc_descricao'),
                        render: ({ loc_descricao }) => (
                            <div>
                                <b>{loc_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Quantidade',
                        align: 'center',
                        width: 120,
                        render: ({ pre_qtde }) => (
                            <div>
                                <b>{pre_qtde}</b>
                            </div>
                        ),
                    },
                ]} dataSource={dadosEstoque} />
            </div>
        </div>
    );

}