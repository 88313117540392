import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table, Row, Col, Divider } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { acrescDados } from '../../services/navigation';
import { useStateValue } from '../../state';

function Tabela({ colunas, dados = [], paginacao = true, expandir, footerTabela, parametrosSelecionarItens, selecionarItem = false, nomeId = 'itens' }) {

    const [pagina, setPagina] = useState(1);
    const [registrosPorPagina, setRegistrosPorPagina] = useState(20);
    const [{ manutencao }, dispatch] = useStateValue();

    const configPaginacao = paginacao ? {
        current: pagina,
        pageSize: registrosPorPagina,
        showSizeChanger: true,
        onChange: (pg) => setPagina(pg),
        onShowSizeChange: (atual, porPagina) => setRegistrosPorPagina(porPagina)
    } : null;

    const configSelecionarItem = selecionarItem ?
        setCondicaoSelecionarItem()
        : null;

    useEffect(() => {
        if (!!dados && dados.length > 0) {
            let url = window.document.location.href;
            acrescDados(url.substring(url.lastIndexOf('/'), url.length), nomeId, dados, dispatch);
        }
    }, [dados]);

    function setCondicaoSelecionarItem() {
        if (!!parametrosSelecionarItens && !!parametrosSelecionarItens.tipo) {
            if (parametrosSelecionarItens.tipo === "change") {
                return {
                    onChange: (selectedRowKeys, selectedRows) => {
                        parametrosSelecionarItens.funcao(selectedRowKeys, selectedRows);
                    }
                }
            } else if (parametrosSelecionarItens.tipo === "select") {
                return {
                    onSelect: (record, selected, selectedRows) => {
                        parametrosSelecionarItens.funcao(record, selected, selectedRows);
                    }
                }
            } else if (parametrosSelecionarItens.tipo === "selectall") {
                return {
                    onSelectAll: (selected, selectedRows, changeRows) => {
                        parametrosSelecionarItens.funcao(selected, selectedRows, changeRows);
                    }
                }
            }
        }
        return "";

    };

    return (
        <Table columns={colunas} dataSource={dados} pagination={configPaginacao} expandedRowRender={expandir}
            expandIcon={({ record, expanded, onExpand }) => {
                return expanded ? (
                    <ArrowUpOutlined onClick={(e) => onExpand(record, e)} />
                ) : (
                    <ArrowDownOutlined onClick={(e) => onExpand(record, e)} />
                );
            }}
            locale={{
                emptyText: (
                    <Row>
                        <Col span={24}>
                            <Divider orientation="center">
                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                            </Divider>
                        </Col>
                    </Row>)
            }}
            rowSelection={configSelecionarItem} scroll={{ x: 900 }} footer={footerTabela} columnWidth={15}
        />
    )
}

Tabela.propTypes = {
    colunas: PropTypes.array.isRequired,
    paginacao: PropTypes.bool
}

export default Tabela;