import React, { useEffect } from 'react';
import { Row, Col, Button } from "antd";
import { EditOutlined, PrinterOutlined } from "@ant-design/icons";

import api from '../../services/api';
import { useStateValue } from '../../state';
import { TabelaDados } from "../../components";
import DrawerRelatorioEtiqueta from './drawer';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";

export default function TabModeloFixo({ exibeModelo }) {

    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: ' ', filtro: ' ', ordem: 'Nome' } })
    }, []);

    function imprimirEtiqueta(modelo) {
        if (!!!modelo.jsonRelatorio) {
            api.get(`RelatorioModeloEtiqueta/Listar?id=${modelo.id}`).then(
                res => {
                    exibeModelo(res.data);
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            )
        } else {
            exibeModelo(modelo);
        }
    };

    return (
        <div>
            <div className="tabela mt-dif">
                <TabelaDados url="RelatorioModeloEtiqueta/ListarGeral" condicao={(campo) => { return campo.emp_id === null && campo.loc_id === null }} colunas={
                    [
                        {
                            title: 'Modelo',
                            render: ({ nome }) => (
                                <div>
                                    <b>{nome}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 65,
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button icon={<PrinterOutlined />} onClick={() => { imprimirEtiqueta(record) }} />
                                        </Col>
                                        <Col>
                                            <Button icon={<EditOutlined />} onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } />
            </div>
            <DrawerRelatorioEtiqueta />
        </div>

    )
}