import React, { useEffect } from "react";
import { Row, Col, Form, Modal, InputNumber, Select, Alert, notification } from "antd";
import { ExclamationCircleOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { getEmpresa } from "../../../services/auth";

export default function ModalAliquotaSimplesNacional({ exibirModal, fecharModal, aposSalvar }) {
    const [formulario] = Form.useForm();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
            }
        });
    };

    const salvarAliquota = () => {
        let valores = formulario.getFieldValue();
        valores.emp_id = getEmpresa();
        api.post(`AliquotaSimplesNacional/Incluir`, valores).then(
            res => {
                notification.success({ message: 'Aviso', description: 'Aliquota adicionada com sucesso!' })
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            ()=>{
                aposSalvar();
            }
        )
    };

    useEffect(() => {
        let data = new Date();
        carregarDados(data);

    }, []);

    function carregarDados(data) {
        formulario.setFieldsValue({ asn_mes: data.getMonth() + 1 });
        formulario.setFieldsValue({ asn_ano: data.getFullYear() });
    };

    return (
        <Modal centered
            title="Manutenção da Alíquota Simples Nacional"
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={salvarAliquota}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }   
        >
            <Form layout="vertical" form={formulario}>
                <Row gutter={[8,0]}>
                    <Form.Item hidden={true} name="asn_id"/>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item label="Mês" name="asn_mes">
                            <Select placeholder="Selecione um Mês">
                                <Select.Option value={1} key={1}>Janeiro</Select.Option>
                                <Select.Option value={2} key={2}>Fevereiro</Select.Option>
                                <Select.Option value={3} key={3}>Março</Select.Option>
                                <Select.Option value={4} key={4}>Abril</Select.Option>
                                <Select.Option value={5} key={5}>Maio</Select.Option>
                                <Select.Option value={6} key={6}>Junho</Select.Option>
                                <Select.Option value={7} key={7}>Julho</Select.Option>
                                <Select.Option value={8} key={8}>Agosto</Select.Option>
                                <Select.Option value={9} key={9}>Setembro</Select.Option>
                                <Select.Option value={10} key={10}>Outubro</Select.Option>
                                <Select.Option value={11} key={11}>Novembro</Select.Option>
                                <Select.Option value={12} key={12}>Dezembro</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <Form.Item label="Ano" name="asn_ano">
                            <InputNumber
                                step={1}
                                min={1}
                                max={9999}
                                placeholder="Informe o ano de referência"
                                enterButton />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                        <Form.Item label="Alíquota" name="asn_percaliquota">
                            <InputNumber
                                defaultValue={0}
                                min={0}
                                max={100}
                                formatter={value => `${value}%`}
                                parser={value => value.replace('%', '')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 8]}>
                    <Alert message="Para saber o valor da alíquota contate seu escritório de contabilidade" type="warning" showIcon />
                </Row>
            </Form>
        </Modal>
    );
};