import React, { useEffect } from "react";
import { Row, Col, Tag, Popover, Modal, notification } from "antd";
import { EditOutlined, DeleteOutlined, PrinterOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import DrawerUsuario from './drawer';
import api from '../../services/api';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, Editar, Excluir, Imprimir } from "../../components";

export default function Usuario() {

    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: "&Ativos=1", ordem: '+usu_nome' } }), []);

    const loadData = (record) => {
        record.usuEmail = record.usu_email;
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    }

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o usuário: ${dados.usu_nome}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };
    const modalInativarRegistro = (dados) => {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: `Não foi possível excluir usuário ${dados.usu_nome}. Deseja inativar este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                inativarRegistro(dados);
            }
        });
    };
    function inativarRegistro(dados) {
        api.put(`Usuario/Inativar/${dados.usu_id}`).then(
            res => {
                notification.success({ message: `Sucesso!`, description: `Usuario(a) inativado(a) com sucesso!` })
            }
        ).catch(
            error => {
                console.log(error);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: "&Ativos=1", ordem: '+usu_nome' } });
            }
        )
    }
    function excluirRegistro(dados) {
        api.delete(`Usuario/Excluir/${dados.usu_id}`).then((res) => {
            if (res.data == false) {
                modalInativarRegistro(dados);
            }
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        }, (err) => {
            console.error(err);
            modalInativarRegistro(dados);
        }).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: "&Ativos=1", ordem: '+usu_nome' } });
            });
    }

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao
                opcoesOrdenacao={[
                    { label: "Nome A - Z", value: "+usu_nome" },
                    { label: "Nome Z - A", value: "-usu_nome" },
                    { label: "Código Crescente", value: "+usu_id" },
                    { label: "Código Decrescente", value: "-usu_id" }
                ]} mostrarFiltroAtivo={true} nameInputFiltroAtivo="Ativos" />
            <div className="tabela">
                <TabelaDados url="Usuario/ListarUsuarioAcessos" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ usu_ativo, usu_id }) => (
                            <Popover content={usu_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                <Tag color={usu_ativo ? 'processing' : 'default'} className="w-100">
                                    <b>{usu_id}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Usuário',
                        render: ({ usu_nome }) => (
                            <div>
                                <b>{usu_nome}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'E-mail',
                        render: ({ usu_email }) => (
                            <div>
                                <b>{usu_email}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Data Inclusão',
                        render: ({ usu_datainclusao }) => (
                            <div>
                                <b>{(usu_datainclusao ? moment(usu_datainclusao).format("DD/MM/YYYY") : '')}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 95,
                        fixed: 'right',
                        render: (record, dados) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    {/* <Col>
                                        <Imprimir icon={<PrinterOutlined />}/>
                                    </Col> */}
                                    <Col>
                                        <Editar onClick={() => loadData(record)} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>
                                        <Excluir icon={<DeleteOutlined />} onClick={() => modalExcluir(dados)} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerUsuario />
        </div>
    );

}