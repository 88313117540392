import React, { useEffect } from "react";
import { Row, Col, Tag, Popover, Modal, notification } from "antd";
import { EditOutlined, DeleteOutlined, CopyOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from '../../state';
import DrawerArmazemEstoque from './drawer';
import { getEmpresa, getLocal } from "../../services/auth";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, Editar, Excluir, Duplicar } from "../../components";

export default function ArmazemEstoque() {

    const [{ manutencao }, dispatch] = useStateValue();

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    const modalDuplicarDados = (dados) => {
        Modal.confirm({
            title: 'Duplicar?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja duplicar este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                dados.duplicar = true;
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
            }
        });
    };

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+aes_descricao' } })
    }, []);

    function excluirRegistro(dados) {
        api.delete(`ArmazemEstoque/Excluir/${dados.aes_id}`).then(
            res => {
                notification.success({ description: `Armazém de Estoque excluido com sucesso!`, message: 'Sucesso' });
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+aes_descricao' } });
            }
        ).catch(
            erro => {
                Modal.confirm({
                    title: `Não foi possível excluir o Armazém de Estoque ${dados.aes_descricao}`,
                    icon: <ExclamationCircleOutlined />,
                    content: `Deseja inativar este registro?`,
                    okText: 'Sim',
                    cancelText: 'Não',
                    centered: true,
                    onOk() {
                        api.put(`ArmazemEstoque/Inativar/${dados.aes_id}`).then(
                            res => {
                                notification.success({ message: 'Operação concluída', description: 'Armazém de Estoque inativado com sucesso!' });
                            }
                        ).catch(
                            error => {
                                console.log(error);
                                notification.warning({ message: 'Aviso!', description: 'Não foi possivel inativar o Armazém de Estoque' });
                            }
                        ).finally(
                            () => {
                                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+aes_descricao' } });
                            }
                        );
                    }
                });
            }
        )
    };


    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+aes_descricao" },
                { label: "Nome Z - A", value: "-aes_descricao" },
                { label: "Código Crescente", value: "+aes_id" },
                { label: "Código Decrescente", value: "-aes_id" }
            ]} />
            <div className="tabela">
                <TabelaDados url="armazemEstoque/listar" colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ aes_id, aes_ativo }) => (
                                <Popover content={aes_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                    <Tag color={aes_ativo ? 'processing' : 'default'} className="w-100">
                                        <b>{aes_id}</b>
                                    </Tag>
                                </Popover>
                            ),
                        },
                        {
                            title: 'Armazém de Estoque',
                            render: ({ aes_descricao }) => (
                                <div>
                                    <b>{aes_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 95,
                            fixed: 'right',
                            render: (record, dados) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Duplicar icon={<CopyOutlined />} onClick={() => { modalDuplicarDados(dados) }} />
                                        </Col>
                                        <Col>
                                            <Editar onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Excluir icon={<DeleteOutlined />} onClick={() => { modalExcluir(dados) }} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} expandir={({ aes_endereco, aes_numero, aes_bairro }) => {
                        return (
                            <div>
                                <Row>
                                    <Col>
                                        Endereço: <b>{aes_endereco || 'Não Informado'}{(!!aes_numero) ? (', ' + aes_numero) : ''}{!!(aes_bairro) ? (', ' + aes_bairro) : ''}</b>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }} />
            </div>
            <DrawerArmazemEstoque />
        </div>
    );

}