import React, { useState, useEffect } from 'react';
import { Form, Row, Col, InputNumber, Tag, Alert, Radio, notification } from "antd";

import api from '../../services/api';
import { useStateValue } from '../../state';
import { listagemActions } from "../../actions";
import { FormGW, TabelaDados } from "../../components";

export default function ManutencaoComissao({ formulario, carregando, aoSalvar, editar }) {

    const [formComissao] = Form.useForm();
    const [dados, setDados] = useState([]);
    const [{ manutencao }, dispatch] = useStateValue();
    const [vendedorSelec, setVendedorSelec] = useState([]);

    function possuiComissao(registro) {
        let retorno = false;
        if ((!!registro.ven_perccomissaoaprazo && registro.ven_perccomissaoaprazo > 0) ||
            (!!registro.ven_perccomissaoavista && registro.ven_perccomissaoavista > 0)) {
            retorno = true;
        }
        return retorno;
    };

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+loc_id' } });
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `` } });
        formulario.setFieldsValue({ ven_formacalculocomissao: 1 });
    }, []);

    function popularVendedoresSelec(keys, linhas) {
        setVendedorSelec(linhas);
    };

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+loc_id' } });
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `` } });
    };

    function salvarDados(dados) {
        if ((!!vendedorSelec && vendedorSelec.length > 0) || editar) {
            let dadosTemp = {};
            dadosTemp.ven_formacalculocomissao = !!dados.ven_formacalculocomissao ? dados.ven_formacalculocomissao : "";
            dadosTemp.ven_formulacomissao = !!dados.ven_formulacomissao ? dados.ven_formulacomissao : "";
            dadosTemp.ven_perccomissaoaprazo = dados.ven_perccomissaoaprazo;
            dadosTemp.ven_perccomissaoavista = dados.ven_perccomissaoavista;
            dadosTemp.vendedores = vendedorSelec;
            if (editar) {
                dadosTemp.vendedores.push({ ven_id: dados.ven_id });
            }
            carregando(true);
            api.put('Comissao/EditarComissao', dadosTemp).then(
                res => {
                    if (res.status === 200) {
                        notification.success({ message: 'Operação concluída', description: "Os dados de comissão foram salvos com sucesso!" });
                    }
                }
            ).catch(
                erro => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
                }
            ).finally(
                () => {
                    atualizarPagina();
                    carregando(false);
                    aoSalvar();
                }
            );
        } else {
            notification.warning({ message: 'Vendedores não selecionados', description: "Favor selecionar ao menos um vendedor!" });
        }
    };

    function retornaFormula(valor) {
        let retorno = "";

        if (valor === 1) {
            retorno = "Recebimento";
        } else if (valor === 2) {
            retorno = "Faturamento";
        } else {
            retorno = "";
        }
        return retorno;
    };

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoComissao" onFinish={salvarDados}>
            <Row gutter={[8, 0]}>
                <Form.Item hidden name="ven_id">
                    <InputNumber />
                </Form.Item>
                <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                    <Form.Item name="ven_perccomissaoavista" label="Percentual de Comissão de Venda à Vista">
                        <InputNumber
                            min={0}
                            max={100}
                            formatter={value => `${value}%`}
                            parser={value => value.replace('%', '')} />
                    </Form.Item>
                </Col>
                <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                    <Form.Item name="ven_perccomissaoaprazo" label="Percentual de Comissão de Venda a Prazo">
                        <InputNumber
                            min={0}
                            max={100}
                            formatter={value => `${value}%`}
                            parser={value => value.replace('%', '')} />
                    </Form.Item>
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                    <Form.Item name="ven_formacalculocomissao" initialValue={1} label="Definição para o Cálculo da Comissão">
                        <Radio.Group options={[{ label: '1 - Recebimento', value: 1 }, { label: '2 - Faturamento', value: 2 }]} />
                    </Form.Item>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                {!editar && <TabelaDados url={"Comissao/ListarComissao"} selecionarItem={true}
                    colunas={
                        [
                            {
                                title: 'Código',
                                width: 75,
                                align: 'center',
                                render: ({ ven_id }) => (
                                    <Tag color="processing" className="w-100">
                                        <b>{ven_id}</b>
                                    </Tag>
                                ),
                            },
                            {
                                title: 'Vendedores',
                                render: ({ pes_nome }) => (
                                    <div>
                                        <b>{pes_nome}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Percentual %',
                                align: 'center',
                                colSpan: 3,
                                children: [
                                    {
                                        title: 'à Vista',
                                        align: 'center',
                                        render: ({ ven_perccomissaoavista }) => (
                                            <div>
                                                {((ven_perccomissaoavista > 0) ? <b>{ven_perccomissaoavista}%</b> : '')}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'à Prazo',
                                        align: 'center',
                                        render: ({ ven_perccomissaoaprazo }) => (
                                            <div>
                                                {((ven_perccomissaoaprazo > 0) ? <b>{ven_perccomissaoaprazo} % </b> : '')}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Atenção',
                                        dataIndex: '',
                                        key: 'x',
                                        align: 'center',
                                        render: (record) => (
                                            <div>
                                                {!possuiComissao(record) && <Alert message="Este vendedor não possui comissão!" type="warning" showIcon />}
                                            </div>
                                        ),
                                    },
                                ],
                            },
                            {
                                title: '',
                                align: 'center',
                                render: ({ ven_formacalculocomissao }) => (
                                    <div>
                                        <b>{retornaFormula(ven_formacalculocomissao)}</b>
                                    </div>
                                ),
                            },
                        ]
                    } parametrosSelecionarItens={
                        {
                            tipo: "change",
                            funcao: popularVendedoresSelec
                        }
                    } />}
            </div>
        </FormGW>
    );

}