import React from "react";
import { Form, Row, Col, Typography, Divider, Input } from "antd";

import { InputPhone } from "../../components";

export default function TabGerente() {

    return (
        <div>
            <Row align="middle" gutter={[8, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-gerente.png").default} alt="Dados do Gerente" /> Dados do Gerente
                    </Typography.Title>
                    <Divider orientation="left" plain> Informe os Dados do Gerente  </Divider>
                </Col>
            </Row>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={8} md={12} lg={12} xl={12}>
                    <Form.Item label="Gerente" name="ctc_gerenteconta" rules={[{ required: false, message: 'Informe o Nome do Gerente da Conta Corrente' }]}>
                        <Input placeholder="Informe o Nome do Gerente da Conta Corrente" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={6} lg={6} xl={6}>
                    <InputPhone label="Telefone" name="ctc_fonegerente" placeholder="Informe o Telefone do Gerente" />
                </Col>
                <Col xs={24} sm={8} md={6} lg={6} xl={6}>
                    <Form.Item label="E-mail" name="ctc_emailgerente" rules={[{ required: false, message: 'Informe o E-Mail do Gerente' }]}>
                        <Input type="email" placeholder="Informe o E-mail do Gerente" />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );

}