import React, { useEffect } from "react";
import { Row, Col, Modal, Table, Divider } from "antd";
import {  ExclamationCircleOutlined } from "@ant-design/icons";

import api from '../../../services/api';
import { useStateValue } from '../../../state';
import { FormatFone, MaskFormat } from "../../../ValueObjects";
import { listagemActions, manutencaoActions } from "../../../actions";

export default function TabFornecedor({ dados }) {

    const [{ manutencao }, dispatch] = useStateValue();
    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '+pes_nome' } }), []);

    function excluirRegistro(fornecedor) {
        api.post(`fornecedor/excluir?codigoFornecedor=${fornecedor.for_id}`).then((res) => {
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        }, (erro) => {
            console.error(erro);
            modalInativarRegistro(fornecedor);
        })
    };

    function inativarRegistro(fornecedor) {
        api.post(`fornecedor/InativarFornecedor?fornecedorId=${fornecedor.for_id}`).then((res) => {
            if (res.status === 200)
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '+pes_nome' } });
        })
    };

    const modalExcluirRegistro = (fornecedor) => {
        console.log('fornecedor', fornecedor);
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o fornecedor ${fornecedor.pes_nome} ?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(fornecedor);
            }
        });
    };

    const modalInativarRegistro = (fornecedor) => {
        Modal.confirm({
            title: 'Não foi possível excluir o fornecedor!',
            icon: <ExclamationCircleOutlined />,
            content: `Deve conter referências com outros registros,
            Deseja inativar o fornecedor ${fornecedor.pes_nome} ?`,
            okText: 'Inativar',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                inativarRegistro(fornecedor);
            }
        });
    };

    return (
        <div className="rowPanel text-center">
            <Row align="middle" justify="center" className="hg-100">
                <Col span={24} className="hg-100">
                    <div className="tabela">
                        <Table dataSource={dados} columns={[
                            {
                                title: 'Informações dos Fornecedores',
                                render: ({ pes_nome, pef_rg, pej_inscricaoestadual, log_logradouro, pee_numero, bai_nome, cep_cep, cid_descricao, est_sigla, pem_email }) => (
                                    <div>
                                        <Row gutter={[16, 0]}>
                                            <Col>
                                                <b>{pes_nome}</b>
                                            </Col>
                                            {!!pej_inscricaoestadual && <Col>
                                                IE: <b>{pej_inscricaoestadual || 'Não Informado'}</b>
                                            </Col>}
                                            {!!pef_rg && <Col>
                                                RG: <b>{pef_rg || 'Não Informado'}</b>
                                            </Col>}
                                        </Row>
                                        <Row gutter={[16, 0]}>
                                            <Col>
                                                E-mail: <b>{pem_email || 'Não Informado'}</b>
                                            </Col>
                                            <Col>
                                                Endereço: <b>{log_logradouro || 'Não Informado'}{(!!pee_numero) ? (', ' + pee_numero) : ''}{!!(bai_nome) ? (', ' + bai_nome) : ''} {!!(cep_cep) ? ('- ' + cep_cep) : ''} {!!(cid_descricao) ? ('- ' + cid_descricao) : ''} {!!(est_sigla) ? ('- ' + est_sigla) : ''}</b>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                            {
                                title: 'CPF/CNPJ',
                                width: 130,
                                render: ({ pef_cpf, pej_cnpj }) => (
                                    <div>
                                        <b>{!!pef_cpf ? MaskFormat(pef_cpf, 'Não Informado', true) : MaskFormat(pej_cnpj, 'Não Informado', true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Telefone',
                                width: 120,
                                render: ({ psc_numero }) => (
                                    <div>
                                        <b>{FormatFone(psc_numero) || 'Não Informado'}</b>
                                    </div>
                                ),
                            },/*
                            {
                                title: 'Ações',
                                dataIndex: '',
                                align: 'center',
                                fixed: 'right',
                                key: 'x',
                                width: 65,
                                render: (record) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button icon={<EditOutlined />} />
                                            </Col>
                                            <Col>
                                                <Button icon={<DeleteOutlined />} onClick={() => modalExcluirRegistro(record)} />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },*/
                        ]} locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }} scroll={{ x: 900 }}/>
                    </div>
                </Col>
            </Row>
        </div>
    );

}