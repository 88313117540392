import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, InputNumber, Select, Button, Tag, Radio, Card, Modal, notification, Input } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined, ExclamationCircleOutlined, CalculatorOutlined, CloseOutlined, HighlightOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { FormatNumber } from "../../../ValueObjects";
import TextArea from "rc-textarea";

export default function ModalCalcularLimite({ formulario, exibirModal, setOpenModalCalcular }) {

    const [step, setStep] = useState(0);
    const [estadoCivil, setEstadoCivil] = useState([]);
    const [mostraRendaConj, setmostraRendaConj] = useState(false);
    const [valorsimulado, setvalorsimulado] = useState();
    const [listaQuestoes, setListaQuestoes] = useState([]);
    const [parametrosEmpresa, setParametrosEmpresa] = useState({});
    const [form] = Form.useForm();
    const [dadosResposta, setDadosResposta] = useState([]);
    const [valores, setValores] = useState({});

    useEffect(() => {
        listarEstadoCivil();
        buscarQuestoes();
        buscarParametrosEmpresa()
    }, []);

    /*
   const onNext = () => onChange(step + 1);
   const onPrevious = () => onChange(step - 1);
   const onChange = (nextStep) => {
       setStep(nextStep < 0 ? 0 : nextStep > 2 ? 2 : nextStep);
   };*/

    function showRendaConjunta(ecv_id) {
        if (ecv_id === 2) {
            setmostraRendaConj(true);
        } else {
            setmostraRendaConj(false);
        }
    }

    function listarEstadoCivil() {
        api.get(`EstadoCivil/Listar`).then(
            res => {
                if (res.status === 200) {
                    setEstadoCivil(res.data.items);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    }
    function buscarQuestoes() {
        api.get(`QuestaoLimiteCredito/Listar`).then(
            res => {
                setListaQuestoes(res.data.items);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    }

    function buscarParametrosEmpresa() {
        api.get(`ParametroEmpresa/BuscarParametros`).then(
            res => {
                setParametrosEmpresa(res.data);
            }
        ).catch(
            erro => { console.log(erro); }
        )
    }

    function adicionarResposta(registro, pergunta) {
        let respostas = [...dadosResposta];
        let id = registro.target.value;
        let dadoPergunta = { ...pergunta };
        if (dadoPergunta.qlc_tiporesposta === 2) {
            dadoPergunta.respostalimitecreditos = pergunta.respostalimitecreditos.filter(respostas => (respostas.rlc_id === id))[0];
        } else {
            dadoPergunta.respostalimitecreditos = id;
        }
        let respEditar = respostas.find((resp) => resp.qlc_id === pergunta.qlc_id);
        if (respEditar) {
            if (respEditar.qlc_tiporesposta === 2) {
                respEditar.respostalimitecreditos = pergunta.respostalimitecreditos.filter(respostas => (respostas.rlc_id === id))[0];
            } else {
                respEditar.respostalimitecreditos = id;
            }
        } else {
            respostas.push(dadoPergunta);
        }
        setDadosResposta(respostas);


    };

    useEffect(() => {
        if (!!dadosResposta && dadosResposta.length > 0) {
            calculoSimulado()
        }
    }, [dadosResposta]);

    function calculoSimulado() {
        let dados = {
            pef_renda: (!!form.getFieldValue().pef_renda ? parseFloat(form.getFieldValue().pef_renda) : 0.0),
            pef_rendaconjuge: (!!form.getFieldValue().pef_rendaconjuge ? parseFloat(form.getFieldValue().pef_rendaconjuge) : 0.0),
            questionario: dadosResposta
        }
        let porcentagemBase = ((!!parametrosEmpresa.par_percentuallimitecredito) ? parametrosEmpresa.par_percentuallimitecredito : 30) / 100;
        let vlrbase = 0;
        let vlrsim = 0.0;
        if (!!dadosResposta && dadosResposta.length > 0) {
            let porcentagem = 0.0;
            dadosResposta.forEach((questao) => {
                if (questao.qlc_tiporesposta == 2) {
                    if (questao.respostalimitecreditos.rlc_somasubtrai == 1) {
                        porcentagem += (questao.respostalimitecreditos.rlc_percentual / 100);
                    } else {
                        porcentagem -= (questao.respostalimitecreditos.rlc_percentual / 100);
                    }
                }
            });

            if (!!dados.pef_rendaconjuge) {
                vlrbase = (dados.pef_renda + dados.pef_rendaconjuge) * porcentagemBase;
                vlrsim = vlrbase * porcentagem;
                vlrsim = (porcentagem !== 0) ? vlrbase + (vlrbase * porcentagem) : vlrbase;
            } else {
                vlrbase = dados.pef_renda * porcentagemBase;
                vlrsim = (porcentagem !== 0) ? vlrbase + (vlrbase * porcentagem) : vlrbase;
            }
        }

        setvalorsimulado(vlrsim);
        setValores(dados);
    };

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Realmente deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                setOpenModalCalcular(false);
                formulario.setFieldsValue({ pef_renda: null, ecv_id: null, pef_rendaconjuge: null });
                setDadosResposta({});
                setStep(0);
            }
        });
    };

    function salvarLimite() {
        let dados = { ...valores };
        let pergunta = {};
        let resposta = {};
        dados.questionario.forEach((q) => {
            pergunta.qlc_tiporesposta = q.qlc_tiporesposta;
            resposta.rlc_somasubtrai = q.respostalimitecreditos.rlc_somasubtrai;
            resposta.rlc_percentual = q.respostalimitecreditos.rlc_percentual;
            q.pergunta = pergunta;
            q.resposta = resposta;
        });
        setCarregando(true);
        api.post(`AnaliseCredito/GerarLimite`, dados).then(
            res => {
                formulario.setFieldsValue({ cli_valorlimitecredito: res.data });
                //  notification.success({ message: 'Operação concluída', description: "Limite de Crédito foi gerado!" });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {

                formulario.setFieldsValue({ pef_renda: null, ecv_id: null, pef_rendaconjuge: null });
                setDadosResposta({});
                setStep(0);
                setOpenModalCalcular(false);
                setCarregando(false);
            }
        );
    };

    const [carregando, setCarregando] = useState(false);

    return (
        <Modal centered title="Análise de Crédito"
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={salvarLimite}
            maskClosable={false}
            width={700}
            footer={
                <Row align="middle" justify="end" gutter={[8, 0]}>
                    <Col>
                        <Button onClick={onCloseModal} icon={<CloseOutlined />}>
                            Cancelar
                        </Button>
                    </Col>
                    <Col>
                        <Button loading={carregando} icon={<CalculatorOutlined />} type="primary" onClick={salvarLimite}>
                            Calcular Limite
                        </Button>
                    </Col>
                </Row>
            }
        >
            <Form layout="vertical" form={form} >
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item label="Renda" name="pef_renda" onChange={() => calculoSimulado()} rules={[{ required: true, message: 'Informe a Renda.' }]}>
                            <InputNumber
                                step={1.0}
                                min={0}
                                formatter={value => `R$ ${value}`}
                                parser={value => value.replace('R$ ', '')}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        <Form.Item label="Estado Civil" name="ecv_id" rules={[{ required: true, message: 'Informe o Estado Civil.' }]}>
                            <Select onChange={(campo) => showRendaConjunta(campo)} placeholder="Selecione um Estado Civil">
                                {estadoCivil.map((evc) => (
                                    <Select.Option value={evc.ecv_id} key={evc.ecv_id}>{evc.ecv_descricao}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                        {mostraRendaConj &&
                            <Form.Item label="Renda Cônjuge" name="pef_rendaconjuge" rules={[{ required: mostraRendaConj, message: 'Informe a Renda do Conjuge.' }]}>
                                <InputNumber
                                    step={1.0}
                                    min={0}
                                    formatter={value => `R$ ${value}`}
                                    parser={value => value.replace('R$ ', '')}
                                />
                            </Form.Item>
                        }
                    </Col>
                </Row>
                <Row align="middle" gutter={[0, 8]} className="m-t-8">
                    <Col span={24} align="right">
                        <Button>
                            <Link to="/limiteCreditoCliente">
                                <HighlightOutlined /> Deseja cadastrar outras perguntas?
                            </Link>
                        </Button>
                    </Col>
                    <Col span={24}>
                        <Card size="small" type="inner" title="Responda as perguntas abaixo"
                            extra={
                                <Tag color="geekblue" className="m-0">
                                    {`${step + 1} / ${listaQuestoes.length}`}
                                </Tag>
                            }
                        >
                            <div>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        {listaQuestoes.map((questoes, idx) => {
                                            {
                                                return step === idx ?
                                                    <Form.Item label={questoes.qlc_questao} name={`questoes${questoes.qlc_id}`}>
                                                        {questoes.qlc_tiporesposta === 2 &&
                                                            <Radio.Group onChange={(registro) => { adicionarResposta(registro, questoes) }}>
                                                                {questoes.respostalimitecreditos.map((resposta => (
                                                                    <Radio value={resposta.rlc_id}>{resposta.rlc_resposta}</Radio>
                                                                )))}
                                                            </Radio.Group>
                                                        }
                                                        {questoes.qlc_tiporesposta === 1 &&
                                                            <Input onBlur={(registro) => { adicionarResposta(registro, questoes) }} rows={4} />
                                                        }
                                                    </Form.Item> : null
                                            }
                                        })}
                                    </Col>
                                </Row>
                                <Row align="middle" justify="space-between" gutter={[8, 8]}>
                                    <Col>
                                        {step !== 0 &&
                                            <Button icon={<ArrowLeftOutlined />} onClick={() => setStep(step - 1)}>
                                                Voltar
                                            </Button>
                                        }
                                    </Col>
                                    <Col>
                                        {step !== listaQuestoes.length - 1 &&
                                            <Button type="primary" onClick={() => setStep(step + 1)}>
                                                Avançar <ArrowRightOutlined />
                                            </Button>
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Col>
                                Simulação (R$): <b>{FormatNumber(valorsimulado, true)}</b>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}