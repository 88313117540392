import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Select, Checkbox, Modal } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import Data from "../../data";

export default function ModalNacionalidade({ form, dadosNacionalidade, exibirModalNacionalidade, fecharModalNacionalidade }) {

    const [listaPais, setListaPais] = useState([]);
    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalNacionalidade();
            }
        });
    };

    useEffect(() => {
        carregarPais();
    }, []);

    function carregarPais() {
        api.get(`Pais/listar?PageSize=1000&PageNumber=1`).then(
            res => {
                if (res.status === 200) {
                    setListaPais(res.data.items);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    const salvar = record => {
        let nacionalidade = [...dadosNacionalidade.listaNacionalidade];
        let indice = form.getFieldValue().indice;
        delete record.indice;
        let pais = listaPais.filter((pais) => (pais.pse_id === record.pse_id))[0];
        record.pse_id = pais.pse_id;
        record.pse_nome = pais.pse_nome;
        if (indice >= 0) {
            nacionalidade[indice] = record;
        } else {
            nacionalidade.push(record);
        }
        dadosNacionalidade.setListaNacionalidade(nacionalidade);
        fecharModalNacionalidade();
    };

    return (
        <Modal centered
            title="Cadastro de Nacionalidade"
            visible={exibirModalNacionalidade}
            onCancel={onCloseModal}
            onOk={() => form.submit()}
            okText={
                <>
                    <PlusOutlined /> Adicionar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="formNacionalidade" form={form} onFinish={salvar}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item label="País" name="pse_id" rules={[{ required: true, message: 'Selecione o País' }]}>
                                <Select placeholder="Selecione o País" showSearch optionFilterProp="children">
                                    {listaPais.map((pais) => (
                                        <Select.Option value={pais.pse_id} key={pais.pse_id}>{pais.pse_nome}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Data label="Data de Entrada no País" name="pen_dataentradapais" />
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item name="pen_naturalizadobrasil" valuePropName="checked" initialValue={false} className="t-mob-573">
                                <Checkbox>Naturalizado</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Observação" name="pen_observacao">
                                <Input.TextArea placeholder="Informe uma observação" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}