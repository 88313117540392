import React,{ useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined, LeftOutlined, RightOutlined  } from "@ant-design/icons";

import { useStateValue } from '../../state';
import { DrawerNav } from '../../components';
import { drawerActions, manutencaoActions } from '../../actions';
import ManutencaoOrcamento from './manutencaoOrc';
import { useEffect } from 'react';

export default function Drawer({ carregarDados, vendedor }) {

    const [ formOrcamento ] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [ carregando, setCarregando ] = useState(false);
    const [proximaPag, setProximaPag] = useState(0);
    const [tagPages, setTagPages] = useState(null);
    
    useEffect(() => {
        if (manutencao.dados !== null) {
            formOrcamento.setFieldsValue(manutencao.dados);
        } else {
            formOrcamento.resetFields();
        }
    }, [manutencao.dados])
    

    function fecharDrawer() {
        formOrcamento.resetFields();
        setTagPages(0);
        setProximaPag(null);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null}});
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false}});
        setCarregando(false);
        carregarDados();
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                formOrcamento.resetFields();
                fecharDrawer();
            }
        });
    };

    return (
        <DrawerNav title="Cadastro Orçamento"
            width="70%"
            visible={ui.showDrawer}
            closeIcon={<MinusOutlined />}
            destroyOnClose={true}
            fecharDrawer={() => {fecharDrawer()}}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={tagPages === 0} icon={<LeftOutlined />} size="large" onClick={() => setProximaPag(tagPages - 1)} type="primary">
                                Voltar
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" hidden={tagPages === 1} size="large" onClick={() => setProximaPag(tagPages + 1)}>
                                Avançar <RightOutlined />
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formOrcamento.submit()}  hidden={tagPages !== 1}  loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar Orçamento
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoOrcamento formulario={formOrcamento} aoSalvar={fecharDrawer} vendedor={vendedor} pages={{ tagPages, setTagPages}}
            proximaPag={{ proximaPag, setProximaPag }} />
        </DrawerNav>      
    )
}