import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Form, Button, Collapse, Modal, notification, Tag, Radio } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, SearchOutlined, PrinterOutlined, DeleteOutlined, ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';

import api from '../../services/api';
import { useStateValue } from '../../state';
import { FormatNumber, FormatDate } from "../../ValueObjects";
import { listagemActions, manutencaoActions } from '../../actions';
import { TabelaDados, Excluir, Imprimir, Data, BreadcrumbIncluirDados, FiltroSearch } from '../../components';
import { linkApi } from '../../services/auth';
import DrawerAntecipacao from './drawer';
import ComboData from '../../components/comboData';

export default function AntecipacaoClienteForncedor() {

    const [open, setOpen] = useState(false);
    const [{ manutencao }, dispatch] = useStateValue();
    const [parametrosValePresente] = Form.useForm();
    const [dataPesquisa, setDataPesquisa] = useState(new Date().toLocaleDateString());
    const [dataAtual] = useState(new Date());

    useEffect(() => {
        let _tipoPessoa = !!!parametrosValePresente.getFieldsValue().tipoPessoa ? 2 : parametrosValePresente.getFieldsValue().tipoPessoa;
        parametrosValePresente.setFieldsValue({ DataFinal: moment(new Date()) });
        parametrosValePresente.setFieldsValue({ DataInicial: moment(parametrosValePresente.getFieldValue().dataPesquisa) });
        parametrosValePresente.setFieldsValue({ tipoPessoa: 2 });
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '%2Bfpg_descricao' } });
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&dataFinal=${moment(parametrosValePresente.getFieldValue().DataFinal).format('YYYY-MM-DD')}&dataInicial=${moment(parametrosValePresente.getFieldValue().DataInicial).format('YYYY-MM-DD')}&EntradaSaida=${_tipoPessoa}&filtro=` } })
    }, []);

    function aplicarFiltrosAvancados() {
        let filtros = '';
        filtros += `&SomenteAtivos=true`;
        filtros += `&EntradaSaida=${parametrosValePresente.getFieldValue().tipoPessoa}`
        filtros += `&dataInicial=${(!!!parametrosValePresente.getFieldValue().DataInicial ? moment(dataAtual).format("YYYY-MM-DD") : moment(parametrosValePresente.getFieldValue().DataInicial).format('YYYY-MM-DD'))}`;
        filtros += `&dataFinal=${(!!!parametrosValePresente.getFieldValue().DataFinal ? moment(dataAtual).format("YYYY-MM-DD") : moment(parametrosValePresente.getFieldValue().DataFinal).format('YYYY-MM-DD'))}`;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: filtros } });
    };

    const modalImprimir = (record) => {
        Modal.confirm({
            title: 'Imprimir',
            icon: <ExclamationCircleOutlined />,
            content: `Confirmar impressao do vale ${record.acf_nomepresenteado} no valor de ${FormatNumber(record.acf_valor, true)}?`,
            okText: 'Imprimir',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                imprimir(record);
            }
        });
    };

    function excluirVale(valePresente) {
        api.post(`AntecipacaoClienteFornecedor/ExcluirValePresente/${valePresente.acf_id}`).then(
            (res) => {
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }
        ).catch(
            (erro) => {
                notification.warning({ message: `Aviso!`, description: erro.response.data });
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '%2Bfpg_descricao' } });
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&SomenteAtivos=true&dataFinal=${moment(parametrosValePresente.getFieldValue().DataFinal).format('YYYY-MM-DD')}&dataInicial=${moment(parametrosValePresente.getFieldValue().DataInicial).format('YYYY-MM-DD')}&filtro=` } })
            }
        );
    };

    const modalExcluirVale = (ValePresente) => {
        let mensagem = (ValePresente.acf_valepresente ? `Deseja realmente excluir o Vale de R$ ${FormatNumber(ValePresente.acf_valor, true)} presenteado à ${ValePresente.acf_nomepresenteado} ?` : `Deseja realmente excluir a antecipação do cliente ${ValePresente.pes_nome} no valor de R$ ${FormatNumber(ValePresente.acf_valor, true)}?`)
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: mensagem,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirVale(ValePresente);
            }
        });
    };

    const togglePesquisa = () => {
        setOpen(!open);
    };

    const imprimir = ValePresente => {
        window.open(linkApi + 'AntecipacaoClienteFornecedor/GerarComprovante?idValePresente=' + ValePresente.acf_id, "_blank");
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Form layout="vertical" name="formParametrosValePresente" form={parametrosValePresente}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={18} xl={19} xxl={20}>
                        <Row gutter={[8, 0]}>
                            <Col xs={16} sm={12} md={12} lg={12} xl={12}>
                                <FiltroSearch />
                            </Col>
                            <Col xs={24} sm={7} md={7} lg={6} xl={6}>
                                <ComboData form={parametrosValePresente} eventoAoAlterar={() => {
                                    let filtros = '';
                                    filtros += `&SomenteAtivos=true`;
                                    filtros += `&EntradaSaida=${parametrosValePresente.getFieldValue().tipoPessoa}`
                                    filtros += `&dataInicial=${moment(parametrosValePresente.getFieldValue().dataPesquisa).format('YYYY-MM-DD')}`;
                                    filtros += `&dataFinal=${FormatDate(new Date().toLocaleDateString())}`;
                                    dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: filtros } });
                                    parametrosValePresente.setFieldsValue({ DataFinal: moment(new Date()) });
                                    parametrosValePresente.setFieldsValue({ DataInicial: moment(parametrosValePresente.getFieldValue().dataPesquisa) });
                                }} />
                            </Col>
                            <Col xs={24} sm={24} md={9} lg={9} xl={6}>
                                <Form.Item label="Selecione Tipo de Pessoa" name="tipoPessoa">
                                    <Radio.Group onChange={() => aplicarFiltrosAvancados()}>
                                        <Radio value={2}>Cliente</Radio>
                                        <Radio value={1}>Fornecedor</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            <Form layout="vertical" name="formParametrosValePresente" form={parametrosValePresente}>
                <Row align="middle" gutter={[0, 24]}>
                    <Col span={24}>
                        <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                            <div className="colDet"></div>
                            <Collapse.Panel key="1" showArrow={false} extra={
                                <Button type="primary" onClick={togglePesquisa} block>
                                    {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                </Button>}>
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={12} md={5} lg={4} xl={4} xxl={3}>
                                        <Data label="Data Inicial" name="DataInicial" />
                                    </Col>
                                    <Col xs={24} sm={12} md={5} lg={4} xl={4} xxl={3}>
                                        <Data label="Data Final" name="DataFinal" />
                                    </Col>
                                    <Col xs={24} sm={24} md={14} lg={16} xl={16} xxl={18} className="t-mob">
                                        <Row gutter={[8, 8]} className="tt-5">
                                            <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={3}>
                                                <Button icon={<CloseOutlined />} block>
                                                    Remover Filtros
                                                </Button>
                                            </Col>
                                            <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={3}>
                                                <Button type="primary" icon={<SearchOutlined />} onClick={aplicarFiltrosAvancados} block>
                                                    Aplicar Filtros
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="AntecipacaoClienteFornecedor/ListarAntecipacao" colunas={[
                    {
                        title: 'Cliente/Fornecedor',
                        align: 'left',
                        render: ({ pes_nome }) => (
                            <div>
                                <Row align="middle" gutter={[5, 0]}>
                                    <Col>
                                        <b>{pes_nome}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Data',
                        width: 100,
                        render: ({ acf_datalancamento }) => (
                            <div>
                                <b>{moment(acf_datalancamento).format('DD/MM/YYYY')}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Total (R$)',
                        align: 'right',
                        render: ({ acf_valor }) => (
                            <div>
                                <b>{FormatNumber(acf_valor, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Utilizado (R$)',
                        align: 'right',
                        render: ({ valorUtilizado }) => (
                            <div>
                                <b>{FormatNumber(valorUtilizado, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Saldo (R$)',
                        align: 'right',
                        render: ({ saldoAntecipacao }) => (
                            <div>
                                <b>{FormatNumber(saldoAntecipacao, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        fixed: 'right',
                        width: 65,
                        render: (record, dados) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col hidden={!dados.acf_valepresente}>
                                        <Imprimir icon={<PrinterOutlined />} onClick={() => { modalImprimir(dados) }} />
                                    </Col>
                                    <Col>
                                        <Excluir onClick={() => modalExcluirVale(dados)} icon={<DeleteOutlined />} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    }
                ]} />
                <DrawerAntecipacao dataPesquisa={dataPesquisa} />
            </div>
        </div>
    );

}