import React, { useState, useEffect } from "react";
import { Tag, Table, notification } from "antd";

import api from '../../services/api';
import { MaskFormat } from '../../ValueObjects';

export default function TabFiliaisUsuario({ formulario }) {

    const [load, setLoad] = useState(false);
    const [locais, setLocais] = useState({});
    const [pagina, setPagina] = useState(1);
    const [nroRegistros, setNroRegistros] = useState(10);
    const [pageRows, setPageRows] = useState([]);
    const [ordem, setOrdem] = useState('loc_id');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [validaLocId, setValidaLocId] = useState(true);
    const [outrosLocId, setOutrosLocId] = useState([]);

    useEffect(() => {
        //if (formulario.getFieldValue('usu_id') != undefined)
        loadLocais()
    }, [pagina, nroRegistros, formulario.getFieldValue('usu_id')]);

    async function loadLocais() {
        var keys = []
        setSelectedRowKeys([]);
        setLoad(true);
        var itens = [];

        try {
            var resListar = await api.get(`Local/Listar?PageNumber=${pagina}&PageSize=${nroRegistros}&Order=${ordem}`)

            if (resListar.status === 200) {

                resListar.data.items.map(i => {
                    itens.push(i.loc_id)
                });
                setPageRows(itens);

                resListar.data.items = await resListar.data.items.map((x) => ({ key: x.loc_id, ...x }));
                formulario.setFieldsValue({usuarioslocais: resListar.data.items});
                setLocais({dados: resListar.data.items, totalItens: resListar.data.totalItems})
                
            }
            var usu_id = formulario.getFieldValue('usu_id');
            if (typeof usu_id != 'undefined') {
                var resLocais = await api.get(`Local/LocaisSelecionadosUsuario?idUser=${usu_id}`)

                if (resLocais.status === 200) {
                    resLocais.data.items = await resLocais.data.map((x) => ({ key: x.loc_id, ...x }));
                    let locaisSelecionados = resLocais.data.items;
                    locaisSelecionados.forEach(e => {
                        keys.push(e.key);
                    });
                    setSelectedRowKeys(keys);
                    formulario.setFieldsValue({locaisselecionados: locaisSelecionados});
                }
            }
        } catch (error) {
            console.log(error.response)
        } finally {
            setLoad(false)
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (keys, selectedRows) => {
            var others = [];

            if (keys.length == 0) {
                return notification.info({description: "Ao menos uma filial deve permanecer selecionada. Para desativar o usuário desmarque a opção \"Usuário Ativo?\"."})
            }

            if (validaLocId) {
                var is = false;
                var justOutCheck = [];
                pageRows.forEach(p => {
                    for (var i = 0; i < keys.length; i++) {
                        is = p == keys[i] ? true : false;
                        if (is) break;
                    }
                    if (!is) justOutCheck.push(p);
                    is = false;
                });

                var selected = selectedRowKeys;
                var sel_page = keys;

                is = false;
                selected.forEach(s => {
                    for (var i = 0; i < sel_page.length; i++) {
                        is = s == sel_page[i] ? true : false;
                        if (is) break;
                    }
                    if (!is) others.push(s);
                    var is = false;
                })

                justOutCheck.forEach(o => {
                    for (var i = 0; i < others.length; i++) {
                        if (others[i] == o) others.pop(o);
                    }
                })
                setValidaLocId(false);
                setOutrosLocId(others);
            }

            var include = others.concat(keys);
            let list = include.map(x => ({ loc_id: x }));

            if (!others.length > 0) others = outrosLocId;
            
            setSelectedRowKeys(keys);

            formulario.setFieldsValue({ locaisselecionados: list });
        },
    };

    return (
        <div className="tabela mt-dif">
            <Table
                rowSelection={{
                    ...rowSelection,
                }}
                loading={load}
                dataSource={locais.dados}
                columns={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ loc_id }) => (
                            <Tag color="processing">
                                <b>{loc_id}</b>
                            </Tag>
                        ),
                    },
                    {
                        title: 'Filial',
                        render: ({ loc_descricao }) => (
                            <b>{loc_descricao}</b>
                        ),
                    },
                    {
                        title: 'CNPJ',
                        render: ({ loc_cnpj: { numero } }) => (
                            <b>{MaskFormat(numero, "00.000.000/0000-00")}</b>
                        ),

                    },
                ]}
                pagination={{
                    current: pagina,
                    pageSize: nroRegistros,
                    total: locais.totalItens,
                    onChange: (pg) => setPagina(pg),
                    onShowSizeChange: (current, page) => setNroRegistros(page)
                }} scroll={{ x: 900 }} />
        </div>
    );

}