import React, { useState, useEffect, useCallback } from "react";
import { clearShoppingCart, deleteCartItem, deleteShoppingCart, editCartItemQuantity, getDateSincro, 
initDatabase, loadShoppingCartItems, clearDateSincro } from "../../services/CarrinhoDB";
import { DownOutlined, ShopOutlined, ShoppingOutlined, ToolOutlined, UpOutlined } from "@ant-design/icons";
import { DeleteOutlineOutlined } from "@material-ui/icons";
import api from "../../services/api";
import { getClienteCat } from "../../services/auth";
import { DrawerPedidoCat } from "../../components";
import { Button } from "antd";
import { useHistory } from 'react-router-dom';

export default function CarrinhoCatalogo() {
    const [carrinhoItens, setCarrinhoItens] = useState([]);
    const [atualizarCarrinho, setAtualizarCarrinho] = useState(false);
    const [total, setTotal] = useState(0);
    const [precoItem, setPrecoItem] = useState(0);
    const [dateSincro, setDateSincro] = useState(0);
    const [openDrawerPedido, setOpenDrawerPedido] = useState(false);
    const history = useHistory();

    useEffect(() => {
        initDatabase();
        loadShoppingCartItems(setCarrinhoItens);

    }, []);

    useEffect(() => {
        loadShoppingCartItems(setCarrinhoItens);
        updateTotal();
    }, [atualizarCarrinho]);

    useEffect(() => {
        getDateSincro(setDateSincro);
        if (dateSincro) {
            var date = new Date();
            date = date.getTime();

            var eq = date - dateSincro;
            if (eq >= 86400000) {

                buscarPreco();
            }
        }
        updateTotal();
    }, [carrinhoItens])

    const deleteItem = useCallback((codigoPeca) => {
        deleteCartItem(codigoPeca);
        // setValorFinalPorItem(prevState => {
        //     const novoEstado = { ...prevState };
        //     delete novoEstado[codigoPeca];
        //     return novoEstado;
        // });
        setAtualizarCarrinho(prevState => !prevState);
    }, []);

    const deleteAllItems = useCallback(() => {
        clearShoppingCart();
        clearDateSincro();
        setAtualizarCarrinho(prevState => !prevState)
    })

    const editItemQuantity = useCallback((codigoPeca, qtd, more) => {
        editCartItemQuantity(codigoPeca, more ? qtd + 1 : qtd - 1);
        // buscarDados(codigoPeca, more ? qtd + 1 : qtd - 1);
        setAtualizarCarrinho(prevState => !prevState);
    }, []);

    const buscarPreco = () => {
        var enviarPedido = ConcatPedido(carrinhoItens);
        api.post('Cat007/AtualizarCarrinho', enviarPedido)
            .then(
                res => {
                    var precoFinal = res.data.map(x => x.precoFinal);
                    setPrecoItem(precoFinal);
                }
            )
            .catch(
                console.error("Erro na busca pelo preco dos itens.")
            )
    }

    const updateTotal = () => {
        const newTotal = carrinhoItens.reduce((acc, item) => {
            return acc + item.valor * item.Qtd;
        }, 0);
        setTotal(newTotal.toFixed(2));
    };

    const ConcatPedido = (Itens) => {
        var client = getClienteCat();
        var itemsCart = Itens.map(x => x.codigo)
        var object = {
            CodigoCliente: client,
            Itens: itemsCart
        }
        return object;
    }

    const concluirPedido = () => {
        try{
            buscarPreco(carrinhoItens);
        }
        catch{
            console.error()
        }
        finally {
            setOpenDrawerPedido(true);
        }
    }

    return (
        <div className="mainShopingCart">
            <div className="shopingCart">
                <h1 className="titleCart"><ShoppingOutlined /> Orçamento</h1>
                <div>
                    {carrinhoItens.map(item => (
                        <div key={item.codigo} className="FastShoppingCart">
                            <div className="codPeca">{item.codigo}</div>
                            <div className="descPeca">
                                <span>{item.descricao}</span>
                            </div>
                            <div className="qtdItem">
                                <p>
                                    <a onClick={() => editItemQuantity(item.codigo, item.Qtd, false)} onChange={item.qtd + 1}>
                                        <DownOutlined />
                                    </a>
                                    {item.Qtd}
                                    <a onClick={() => editItemQuantity(item.codigo, item.Qtd, true)} onChange={item.qtd - 1}>
                                        <UpOutlined />
                                    </a>
                                </p>
                            </div>
                            <div className="valuePeca">
                                <p>{(item.valor * item.Qtd).toFixed(2)}</p>
                            </div>
                            <div className="deletePeca">
                                <a onClick={() => deleteItem(item.codigo)}>
                                    <DeleteOutlineOutlined />
                                </a>
                            </div>
                        </div>
                    ))}
                    {carrinhoItens.length < 1 &&
                        <span style={{ textAlign: 'center' }} >
                            Vazio :/
                        </span>
                    }
                </div>
            </div>
            <div className="totalRevenue">
                <div className="containerRevenue">
                    <h1 className="titleRevenue">Faturamento Total</h1>
                    <div className="valueRevenue">
                        <p>R$</p>
                        <span>{total}</span>
                    </div>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <Button type="primary" id="contComprando" className="buttonContinue" onClick = {() => history.replace('/consultaPecas')}
                        size="small">
                            <ToolOutlined /> Lista de Peças
                    </Button>
                    <Button size="large" disabled={carrinhoItens.length < 1 ? true : false} className="buttonFinish" 
                        onClick={() => concluirPedido()} >
                            <ShoppingOutlined />Fechar Orçamento
                    </Button>
                </div>
                <div style={{ textAlign: 'center', textDecoration: 'underline', fontSize: '14px' }}>
                    <a onClick={() => deleteAllItems()} style={{ color: '#0053a6' }} >Limpar</a>
                </div>
            </div>
            <DrawerPedidoCat openDrawer={openDrawerPedido} setOpenDrawer={setOpenDrawerPedido} />
        </div>
    );
}
