import React from "react";
import { Row, Col, Form, Modal } from "antd";
import { ExclamationCircleOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";

import { InputPreco } from "../../../components";
import Data from "../../data";

export default function ModalEditarNotaFiscalEntrada({ form, exibirModalEditarNotaFiscalEntrada, fecharModalEditarNotaFiscalEntrada }) {

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalEditarNotaFiscalEntrada();
            }
        });
    };

    return (
        <Modal centered
            title="Editar Item"
            visible={exibirModalEditarNotaFiscalEntrada}
            onCancel={() => {
                onCloseModal();
            }}
            onOk={() => form.submit()}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }   
            maskClosable={false}
        >
            <Form layout="vertical" name="manutencaoEditarNotaFiscalEntrada" form={form}>
                <Row gutter={[8, 0]}>
                    <Col span={24}>
                        <InputPreco label="Valor  (R$)" name="vDup" />
                    </Col>
                    <Col span={24}>
                        <Data label="Data Vencimento" name="dVenc" />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}