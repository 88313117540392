import React, { useState } from 'react';
import { Row, Col, Button, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';
import ManutencaoNotaFiscal from '../notaFiscal/manutencao';
import { DrawerNav } from '../../components';

export default function DrawerNFExtra({ drawerExtra }) {

    const [formNotaFiscal] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);
    const [tagPages, setTagPages] = useState(null);
    const [proximaPag, setProximaPag] = useState(0);
    const [salvarRascunho, setSalvarRascunho] = useState(false);

    if (!!manutencao.dados) {
        formNotaFiscal.setFieldsValue(manutencao.dados);
    }
    function fecharDrawer() {
        formNotaFiscal.resetFields();
        setTagPages(0);
        setProximaPag(null);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+vnc_datalancamento' } });
        if (!!drawerExtra.exibirDrawerNota) {
            drawerExtra.setExibirDrawerNota(false)
        }
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <DrawerNav title="Emissão de Nota Fiscal Eletrônica"
            width="70%"
            visible={drawerExtra.exibirDrawerNota}
            closeIcon={<MinusOutlined />}
            limparAoEditar={true}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={tagPages === 0} icon={<LeftOutlined />} size="large" onClick={() => setProximaPag(tagPages - 1)} type="primary">
                                Voltar
                            </Button>
                        </Col>
                        <Col>
                            <Button type="primary" hidden={tagPages === 4} size="large" onClick={() => setProximaPag(tagPages + 1)}>
                                Avançar <RightOutlined />
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formNotaFiscal.submit()} hidden={tagPages !== 4} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Emitir Nota
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoNotaFiscal form={formNotaFiscal} carregando={setCarregando} aoSalvar={fecharDrawer} pages={{ tagPages, setTagPages }} proximaPag={{ proximaPag, setProximaPag }} salvarRascunho={{salvarRascunho, setSalvarRascunho}} showDrawerExtra={drawerExtra.exibirDrawerNota} />
        </DrawerNav>

    )
}