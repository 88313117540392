import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tag, Popover, notification, Modal } from "antd";
import { EditOutlined, DeleteOutlined, CopyOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, Editar, Excluir } from "../../components";
import DrawerRetencao from "./drawer";
import { FormatNumber } from "../../ValueObjects";

export default function TabelaRetencao() {

    const [dadosTipoRetencao, setDadosTipoRetencao] = useState([]);
    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => {
        buscarTipoRetencao();
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+tar_descricao' } });
    }, []);

    function buscarTipoRetencao() {
        api.get(`Enum/Listar?nome=TipoRetencao`).then(
            res => {
                let objeto = {};
                for (let item of res.data) {
                    objeto[item.key] = item.value;
                }
                setDadosTipoRetencao(objeto);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    function excluirRegistro(tabRetencao) {
        api.delete(`TabelaRetencao/Excluir?id=${tabRetencao.tar_id}`).then((res) => {
            if (res.status === 200) notification.success({ message: 'Operação concluída', description: "Os dados foram excluídos com sucesso!" });
            dispatch({ type: listagemActions.CHANGE, data: { label: "Nome A - Z", ordem: '+tar_descricao' } })
        }).catch(err => {
            console.error(err);
        }).finally(() => {
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+tar_descricao' } });
        });
    }

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+tar_descricao" },
                { label: "Nome Z - A", value: "-tar_descricao" },
                { label: "Código Crescente", value: "+tar_id" },
                { label: "Código Decrescente", value: "-tar_id" }
            ]} />
            <div className="tabela">
                <TabelaDados url="TabelaRetencao/Listar" colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ tar_id }) => (
                                <Popover placement="right">
                                    <Tag color={'processing'} className="w-100">
                                        <b>{tar_id}</b>
                                    </Tag>
                                </Popover>
                            ),
                        },
                        {
                            title: 'Descrição',
                            render: ({ tar_descricao }) => (
                                <div>
                                    <b>{tar_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Percentual',
                            render: ({ tar_percentuaretencao }) => (
                                <div>
                                    <b>{FormatNumber(tar_percentuaretencao, true)} %</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Tipo Imposto',
                            render: ({ tar_tiporetencao }) => (
                                <div>
                                    <b>{dadosTipoRetencao[tar_tiporetencao] || '-'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Desconta total da nota?',
                            render: ({ tar_descontatotalnota }) => (
                                <div>
                                    <b>{tar_descontatotalnota ? 'Sim' : 'Não'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Desconta total da movimentação financeira?',
                            render: ({ tar_descontamovimentacaofinanceira }) => (
                                <div>
                                    <b>{tar_descontamovimentacaofinanceira ? 'Sim' : 'Não'}</b>
                                </div>
                            )
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 95,
                            fixed: 'right',
                            render: (record, dados) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        {/* <Col>
                                            <Button icon={<CopyOutlined />} onClick={() => { }} />
                                        </Col> */}
                                        <Col>
                                            <Editar onClick={() => {
                                                 dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                 dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } }); 
                                            }} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Excluir icon={<DeleteOutlined />} onClick={() => { modalExcluir(record)}} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } />
            </div>
            <DrawerRetencao />
        </div>
    );

}