import React, { useEffect } from "react";
import { Form, Row, Col, Input, Button, notification } from "antd";
import { EditOutlined, PrinterOutlined } from "@ant-design/icons";
import jsreports from "jsreports/jsreports-all";

import api from "../../services/api";
import { FormGW } from "../../components";
import { useStateValue } from "../../state";
import { delay } from "../../services/funcoes";
import { listagemActions } from "../../actions";

export default function ManutencaoRelatorioEtiqueta({ formulario, carregando, aoSalvar, exibeModelo }) {
    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => {
        definirDadosPadrao();
    }, []);

    const squemaEtiquetaProduto = [
        {
            nome: 'Codigo_Empresa',
            tipo: 'number'
        },
        {
            nome: 'Empresa',
            tipo: 'string'
        },
        {
            nome: 'Codigo_Produto',
            tipo: 'number'
        },
        {
            nome: 'Produto',
            tipo: 'string'
        },
        {
            nome: 'Grade',
            tipo: 'string'
        },
        {
            nome: 'Marca',
            tipo: 'string'
        },
        {
            nome: 'Preco',
            tipo: 'string'
        },
        {
            nome: 'Ean',
            tipo: 'string'
        },
        {
            nome: 'Codigo_Etiqueta',
            tipo: 'string'
        },
        {
            nome: 'Codigo_Referencia',
            tipo: 'string'
        },
        {
            nome: 'Observacao',
            tipo: 'string'
        },
        {
            nome: 'Quantidade_Parcela',
            tipo: 'string'
        },
        {
            nome: 'Valor_Parcelado',
            tipo: 'string'
        }
    ];

    const salvarManutencao = (record) => {
        record.jsonRelatorio = JSON.stringify(record.jsonRelatorio)
        carregando(true);
        if (!!record.id) {
            api.put(`RelatorioModeloEtiqueta/Editar`, record).then(
                res => {
                    notification.success({ message: 'Operação concluída', description: "Os dados da Etiqueta foram salvos com sucesso!" });
                    aoSalvar(res.data);
                }
            ).catch(
                error => {
                    console.log(error);
                }
            ).finally(
                () => {
                    carregando(false);
                    dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: ' ', filtro: ' ', ordem: 'Nome' } })
                }
            );
        } else {
            api.post(`RelatorioModeloEtiqueta/Incluir`, record).then(
                res => {
                    notification.success({ message: 'Operação concluída', description: "Os dados da Etiqueta foram salvos com sucesso!" });
                    aoSalvar(res.data);
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            ).finally(
                () => {
                    carregando(false);
                    dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: ' ', filtro: ' ', ordem: 'Nome' } })
                }
            );
        }
    };



    useEffect(() => {
        if (!!manutencao.dados) {
            api.get(`RelatorioModeloEtiqueta/Listar?id=${manutencao.dados.id}`).then(
                res => {
                    formulario.setFieldsValue(res.data);
                }
            ).catch(
                erro => {
                    console.log(erro);
                }
            )
        }
    }, [manutencao.dados]);

    function definirDadosPadrao() {
        let json = { "title": "Relatório", "id": "rtpRelatorioEtiqueta", "default_format": "html", "version": "1.4.122", "page": { "units": "mm", "paper_size": { "name": "A4", "inches": ["8.5", "11.69"], "mm": ["210", "297"], "id": "a4" }, "margins": { "top": 0, "left": 0, "right": 0, "bottom": 0 } }, "filters": [], "inputs": [], "header": { "visible": false, "height": 27, "elements": [] }, "body": { "data_source": "etiqueta", "show_detail": true, "height": 16.897356568410437, "elements": [], "sublevels": [], "column_count": 1, "pivot_enabled": false, "pivot_column_left": 84.5, "pivot_column_right": 105.50000000000001, "pivot_area_right": 137, "visible": "", "shrinkToFit": false, "order_detail_by": "" }, "footer": { "visible": false, "height": 6, "elements": [] }, "page_header": { "visible": false, "elements": [], "height": 54.39344262295082 }, "page_footer": { "visible": false, "elements": [], "height": 5.0000034679066045 }, "type": "hierarchical", "defaultFont": { "name": "Arial", "css": "Arial, \"Helvetica Neue\", Helvetica, sans-serif" }, "defaultFontSize": 8 };
        formulario.setFieldsValue({ jsonRelatorio: json });
    }

    function imprimirEtiqueta(modelo) {
        if (!!!formulario.getFieldValue().jsonRelatorio) {
            api.get(`RelatorioModeloEtiqueta/Listar?id=${formulario.getFieldValue().id}`).then(
                res => {
                    exibeModelo(res.data);
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            )
        } else {
            exibeModelo(formulario.getFieldValue());
        }
    };

    function abrirMontarRelatorio() {
        let data_sources = [];
        let dataSource = {
            id: 'etiqueta',
            name: 'Etiqueta',
            data: []
        }
        let atributosSquema = [];
        squemaEtiquetaProduto.forEach((squema) => {
            for (let i = 1; i <= formulario.getFieldValue().quantidadeColuna; i++) {
                atributosSquema.push({ name: squema.nome + "_Coluna_" + i, type: squema.tipo });
            }
        });

        dataSource.schema = {
            fields: atributosSquema
        }

        data_sources.push(dataSource);

        let designer = new jsreports.Designer({
            data_sources: data_sources,
            report_def: formulario.getFieldValue().jsonRelatorio

        });
        let outPutReport = document.getElementsByClassName('report-output');

        delay(function () {
            designer.on("save", function (reportdef) {
                let reportDef = JSON.parse(reportdef);
                if (!!reportDef.body.data_source) {
                    formulario.setFieldsValue({ jsonRelatorio: reportDef });
                    jsreports.render({
                        report_def: (formulario.getFieldValue().jsonRelatorio),
                        target: outPutReport,
                        datasets: data_sources,
                        showToolbar: false
                    });
                } else {
                    notification.warn({ description: 'Data Source não foi selecionado!', message: 'Aviso' });
                }
            }, 100);
        })
    };

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoRelatorioEtiqueta" onFinish={salvarManutencao}>
            <Row gutter={[8, 0]}>
                <Form.Item name="id" hidden></Form.Item>
                <Form.Item name="emp_id" hidden></Form.Item>
                <Form.Item name="loc_id" hidden></Form.Item>
                <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                    <Form.Item label="Nome Modelo" name="nome" rules={[{ required: true, message: 'Informe um Nome para o Modelo de Etiqueta' }]}>
                        <Input placeholder="Informe um Nome para o Modelo de Etiqueta" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                    <Form.Item label="Colunas" name="quantidadeColuna" rules={[{ required: true, message: 'Informe a Quantidade de Colunas do Modelo' }]} initialValue={1}>
                        <Input placeholder="Informe a Quantidade de Colunas do Modelo" />
                    </Form.Item>
                </Col>
                <Form.Item name="jsonRelatorio" hidden />
                <Col span={24}>
                    <Row justify="center" gutter={[8, 0]} className="m-t-16">
                        <Col>
                            <Button type="primary" icon={<EditOutlined />} onClick={() => { abrirMontarRelatorio() }}>
                                Montar Relatório
                            </Button>
                        </Col>
                        <Col>
                            <Button icon={<PrinterOutlined />} onClick={() => { imprimirEtiqueta() }}>
                                Imprimir Modelo
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div class="card min clearfix">
                <div class="report-output clearfix min"></div>
            </div>
        </FormGW>
    );

}