import axios from 'axios';
import { getToken, logout, getEmpresa, getLocal, linkApi, linkApiGestor } from './auth';
const apiWeb = axios.create({ baseURL: linkApi });

apiWeb.interceptors.request.use(async config => {
  const token = getToken();
  const empresa = getEmpresa();
  const local = getLocal();
  //config.headers.ApiKey ="as123";
  if (token) config.headers.Authorization = `Bearer ${token}`;
  if (empresa) config.headers.codigoempresa = empresa;
  if (local) config.headers.codigolocal = local;

  return config;
});

apiWeb.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    logout();
  }
  return Promise.reject(error);
});

export default apiWeb;

