import { Button, Col, Form, Input, Modal, Row, Select, Table, Tag, Tooltip, Typography, notification, Checkbox, InputNumber, Popover } from "antd";
import { FiltroProdutoKit, FormGW, InputPreco, SelectProduto } from "../../components";
import { BarcodeOutlined, CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { calculaDigitoVerificadorEAN, validarEan } from "../../services/funcoes";
import api from "../../services/api";
import { useEffect, useState } from "react";
import { manutencaoActions } from "../../actions";
import { useStateValue } from "../../state";
import { FormatNumber } from '../../ValueObjects';

export default function ManutencaoKitComposicao({ formulario, salvandoDados, aoSalvar, editando }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();

    const [listaUnidadeMedida, setListaUnidadeMedida] = useState([]);
    const [listaItems, setListaItems] = useState([]);
    const [controlButton, setControlButton] = useState(true);

    const [formGrade] = Form.useForm();

    useEffect(() => {
        if (!!manutencao.dados?.pro_id) {
            carregarItensProdutoKit(manutencao.dados?.pro_id);
        }
        carregarListas();
    }, [])

    function carregarItensProdutoKit(pro_id, soAtivos = true) {
        api.get(`ProdutoKitComposicao/ListarItens?idProduto=${pro_id}&soAtivos=${soAtivos}`).then(
            res => {
                res.data?.items?.forEach(item => {
                    item.editando = false;
                })
                setListaItems(res.data?.items)
                let dadosTemp = { ...manutencao.dados }
                dadosTemp.preco_total_kit = parseFloat(res.data?.items?.reduce((total, item) => total + parseFloat(item.qtde_item * item.pkc_valorunitario), 0));
                formulario.setFieldsValue(dadosTemp);

            }
        ).catch(
            error => console.log(error)
        )
    }

    async function salvarDados(values) {
        let dadosPost = { ...values };
        dadosPost.lpi_valorvenda = parseFloat(values.preco_total_kit).toFixed(2);
        dadosPost.listaProdutosKit = listaItems

        if (!dadosPost.pro_id) {
            salvarManutencao(dadosPost);
        } else {
            editarManutencao(dadosPost);
        }
    }

    function salvarManutencao(dadosPost) {
        salvandoDados(true);
        api.post(`ProdutoKitComposicao/Incluir`, dadosPost).then(
            res => {

                notification.success({ message: 'Sucesso!', description: 'Kit Composição inserido com sucesso!' });
            }
        ).catch(
            error => {
                notification.warn({ message: 'Aviso!', description: 'Não foi possivel inserir o Kit!' });
            }
        ).finally(() => {
            salvandoDados(false);
            setListaItems([]);
            aoSalvar();
        })
    }

    function editarManutencao(dadosPost) {
        salvandoDados(true);
        api.put(`ProdutoKitComposicao/Editar`, dadosPost).then(
            res => {
                notification.success({ message: 'Sucesso!', description: 'Kit Composição inserido com sucesso!' });
            }
        ).catch(
            error => {
                notification.warn({ message: 'Aviso!', description: 'Não foi possivel inserir o Kit!' });
            }
        ).finally(() => {
            salvandoDados(false);
            setListaItems([]);
            aoSalvar();
        })
    }

    async function carregarListas() {
        let unidade = await api.get(`unidademedida/listar?PageSize=100&PageNumber=1`);
        if (!!formulario.getFieldValue().ump_id && unidade.data?.items.length > 0 && !unidade.data?.items?.find((un) => { return un.ump_id === formulario.getFieldValue().ump_id })) {
            formulario.setFieldsValue({ ump_id: null });

        }
        setListaUnidadeMedida(unidade.data?.items);
    }

    useEffect(() => {
        if (listaUnidadeMedida.length > 0 && !formulario.getFieldValue().ump_id) {
            formulario.setFieldsValue({ ump_id: listaUnidadeMedida.find(un => un.ump_id === 'UN').ump_id });
        }
    }, [listaUnidadeMedida]);

    useEffect(() => {
        if (listaItems.length > 0) {
            formulario.setFieldsValue({ preco_total_kit: parseFloat(listaItems?.reduce((total, item) => total + parseFloat(item.qtde_item * item.pkc_valorunitario), 0)).toFixed(2) });
        }
    }, [listaItems])


    function onBlurProEan(event) {
        let codEan = "";
        if (!!event.currentTarget) {
            codEan = event.currentTarget.value;
        } else {
            codEan = event;
        }
        if (codEan !== "") {
            if (validarEan(codEan)) {
                buscarEanExistente(codEan);
                if (codEan.startsWith("7") && codEan.length === 13)
                    formulario.setFieldsValue({ pro_eaninvalido: false });
                else
                    formulario.setFieldsValue({ pro_eaninvalido: true });
            } else {
                formulario.setFieldsValue({ pro_eaninvalido: true });
                notification.warning({ description: 'EAN informado fora dos padrões GTIN, verifique o EAN antes de continuar!', message: 'Aviso' });
            }
        }
    }

    function buscarEanExistente(event) {
        let codEan;
        if (!!event.currentTarget) {
            codEan = event.currentTarget.value;
        } else {
            codEan = event;
        }
        if (isNaN(codEan)) {
            return;
        }
        api.get(`Produto/VerificaEanExistente?CodigoEan=${codEan}`).then(
            res => {
                if (!!res.data && res.data.existe == true) {
                    Modal.confirm({
                        title: 'Aviso',
                        icon: <ExclamationCircleOutlined />,
                        content: 'Produto já cadastrado - deseja alterá-lo?',
                        okText: 'Sim',
                        cancelText: 'Não',
                        centered: true,
                        onOk() {
                            buscarProdutosEan(res.data.pro_id);
                        }, onCancel() {
                            formulario.resetFields();
                        }
                    });
                }
            }
        ).catch(
            erro => {
                console.log(erro)
            }
        );
    }

    function buscarProdutosEan(IdProduto) {
        api.get(`produto/RetornaDadosProduto?ProdutoId=${IdProduto}`).then(
            res => {
                formulario.setFieldsValue(res.data);
                editando.setEditando(true);
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: res.data } });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function gerarEANProduto(campo) {
        let eanAnterior = null;

        let primeiroNumero = "6";
        let EAN;
        let digitoVerificador;
        eanAnterior = primeiroNumero;
        for (let i = 0; i <= 10; i++) {
            eanAnterior += Math.floor((Math.random() * 9) + 1).toString();
        }
        EAN = eanAnterior;
        EAN = EAN.substring(0, 12);
        EAN = (parseInt(EAN) + 1).toString();
        eanAnterior = EAN;
        digitoVerificador = calculaDigitoVerificadorEAN(EAN);
        EAN = EAN + "" + digitoVerificador;

        if (!!campo) {
            formGrade.setFieldsValue({ [campo]: EAN });
        } else {
            formulario.setFieldsValue({ pro_ean: EAN });
        }
        formulario.setFieldsValue({ pro_eaninvalido: true });
    }

    function calcularValorVendaPorMarkup() {
        let valorItens = listaItems?.reduce((total, item) => total + parseFloat(item.qtde_item * item.pkc_valorunitario), 0);
        let valorProdutoKit = formulario.getFieldsValue().preco_total_kit;
        let porc_desconto = ((valorProdutoKit * 100) / valorItens).toFixed(6);
        let listaItemsTemp = [...listaItems];
        // Ordenar a lista primeiro por qtde_item (em ordem decrescente) e depois por pkc_valorunitario (em ordem crescente)
        listaItemsTemp.sort((a, b) => {
            if (a.qtde_item !== b.qtde_item) {
                return b.qtde_item - a.qtde_item;
            } else {
                return a.pkc_valorunitario - b.pkc_valorunitario;
            }
        });
        let count = 0;
        let somaValor = 0;
        listaItemsTemp.forEach((item) => {
            count++;
            if (listaItemsTemp.length !== count) {
                item.pkc_valorunitario = parseFloat(((item.pkc_valorunitario * porc_desconto)) / 100).toFixed(2);
                somaValor += parseFloat((item.pkc_valorunitario * item.qtde_item).toFixed(2));
            }
            else {
                if (item.qtde_item === 1) {
                    let diferenca = parseFloat(valorProdutoKit - somaValor).toFixed(2);
                    item.pkc_valorunitario = diferenca;
                } else {
                    item.pkc_valorunitario = parseFloat(((item.pkc_valorunitario * porc_desconto)) / 100).toFixed(2);
                    somaValor += parseFloat((item.pkc_valorunitario * item.qtde_item).toFixed(2));
                }
            }
        })
        setListaItems(listaItemsTemp);
    };

    const editarItem = (index) => {
        let listaTemp = [...listaItems];
        let item = listaTemp[index];
        listaTemp[index].editando = true;
        setListaItems(listaTemp);
        formulario.setFieldsValue({ [`pkc_valorunitario`]: item.pkc_valorunitario });
        formulario.setFieldsValue({ ["pkc_qtde_item"]: item.qtde_item });
        setControlButton(true);
    }

    const saveEdition = (index) => {
        let listaTemp = [...listaItems];
        let item = listaTemp[index];
        let qtdeItem = formulario.getFieldValue().pkc_qtde_item;
        let pkcValorUnitario = formulario.getFieldsValue().pkc_valorunitario;

        let novoValorTotal = qtdeItem * pkcValorUnitario;

        listaTemp[index] = {
            ...item,
            qtde_item: qtdeItem,
            pkc_valorunitario: pkcValorUnitario
        };

        listaTemp[index].editando = false;

        setListaItems(listaTemp);

    };

    const cancelEdition = (index) => {
        let listaTemp = [...listaItems];
        listaTemp[index].editando = false;
        setListaItems(listaTemp);
    }

    function excluirItem(record, index) {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja remover o item do kit?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                inativarItem(record, index)
            }
        });
    }

    async function inativarItem(record, index) {
        if (!!record.pkc_id) {
            let retorno = await api.put(`ProdutoKitComposicao/AtivarInativarItem`, record);
            if (!retorno) {
                notification.warning({ description: 'Aviso!', message: 'Não foi possivel inativar o item' });
                return;
            }
            notification.success({ description: 'Sucesso!', description: 'Item inativado com sucesso!' });
        }
        let listaTemp = [...listaItems];
        listaTemp.splice(index, 1);
        setListaItems(listaTemp)
    }

    function ativarItem(record) {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja reativar o item do kit?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                ativarItem(record)
            }
        });
    }

    function ativarItem(record) {
        api.put(`ProdutoKitComposicao/AtivarInativarItem`, record).then(
            res => {
                if (res.data) {
                    notification.success({ message: 'Sucesso!', description: 'Item ativo novamente!' });
                    carregarItensProdutoKit(manutencao.dados?.pro_id, formulario.getFieldsValue()?.listar_inativos)
                }
            }
        ).catch(
            error => {
                console.log(error);
                notification.warning({ message: 'Aviso!', description: 'Não foi possivel ativar o Item!' });
            }
        )
    }

    function myFunction(index) {
        let listaTemp = [...listaItems];
        let item = listaTemp[index];
        let qtdeItem = formulario.getFieldValue().pkc_qtde_item;
        let pkcValorUnitario = formulario.getFieldValue().pkc_valorunitario;

        if (qtdeItem !== item.qtde_item || pkcValorUnitario !== item.pkc_valorunitario) {
            setControlButton(false);
        }
    }

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="formularioKitComposicao" onFinish={(values) => salvarDados(values)}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={13} lg={15} xl={15}>
                        <Form.Item hidden name="pro_id" > <Input /> </Form.Item>
                        <Form.Item hidden name="pro_codigo" > <Input /> </Form.Item>
                        <Form.Item hidden name="orm_id" > <Input /> </Form.Item>
                        <Form.Item hidden name="pro_kitcomposicao" valuePropName="checked"><Checkbox /></Form.Item>
                        <Form.Item hidden name="pro_ativo" valuePropName="checked" initialValue={true}><Checkbox /></Form.Item>
                        <Form.Item label="Descrição do Kit" name="pro_descricao" rules={[{ required: true, message: 'Informe a Descrição do Kit' }]}>
                            <Input placeholder="Informe a Descrição do Produto/Mercadoria" maxLength={120} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={16} md={7} lg={6} xl={6}>
                        <Form.Item name="pro_ean" label={
                            <div>
                                Código de Barras - EAN
                                <Tooltip title="Campo destinado a informação do EAN. Verifique a necessidade dos produtos ou mercadorias da empresa, se houver dúvidas em relação a escolha/informação do código, consulte o responsável fiscal de sua empresa. Esta informação é OPCIONAL.">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </div>
                        }>
                            <Input placeholder="Informe o Código de Barras (OPCIONAL)" onBlur={(event) => onBlurProEan(event)} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={4} lg={3} xl={3} className="tt-5">
                        <Button block icon={<BarcodeOutlined />} onClick={() => gerarEANProduto()} className="t-mob-573">Gerar EAN</Button>
                    </Col>
                    <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                        <Form.Item label="Unidade de Medida" name="ump_id" rules={[{ required: true, message: 'Informe a Unidade de Medida' }]}>
                            <Select
                                placeholder="Selecione a Unidade de Medida"
                                showSearch
                                allowClear
                                optionFilterProp="children">
                                {listaUnidadeMedida.map((um) => (<Select.Option key={um.ump_id} id={um.ump_id}>{um.ump_id} - {um.ump_descricao}</Select.Option>))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <FiltroProdutoKit formulario={formulario} setListaItems={setListaItems} listaItems={listaItems} />
                <Row align="middle" gutter={[0, 16]}>
                    <Col span={24}>
                        <div className="tabela mt-diff">
                            <Table dataSource={listaItems} columns={[
                                {
                                    title: 'Código',
                                    width: 75,
                                    align: 'center',
                                    render: ({ pro_codigo, pkc_ativo }) => (
                                        <Popover content={pkc_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                            <Tag color={pkc_ativo ? 'processing' : 'default'} className="w-100">
                                                <b>{pro_codigo}</b>
                                            </Tag>
                                        </Popover>
                                    ),
                                },
                                {
                                    title: 'Descrição',
                                    align: 'left',
                                    render: ({ pro_descricao }) => (
                                        <b>{pro_descricao}</b>
                                    )
                                },
                                {
                                    title: 'Qtde',
                                    width: 75,
                                    align: 'center',
                                    render: ({ qtde_item, editando }, record, index) => (
                                        <div>
                                            <Form.Item name={`pkc_qtde_item`}>
                                                {editando ?
                                                    <InputNumber
                                                        defaultValue={qtde_item}
                                                        onChange={() => myFunction(index)}
                                                    /> : <b>{FormatNumber(qtde_item, true)}</b>
                                                }
                                            </Form.Item>
                                        </div>
                                    )
                                },
                                {
                                    title: 'Vlr Tabela',
                                    width: 100,
                                    align: 'right',
                                    render: ({ lpi_valorvenda }) => (
                                        <b>R$ {parseFloat(lpi_valorvenda).toFixed(2)}</b>
                                    )
                                },
                                {
                                    title: 'Vlr Unitário',
                                    width: 100,
                                    align: 'right',
                                    render: ({ pkc_valorunitario, editando }, record, index) => (
                                        <div>
                                            {editando ?
                                                <InputPreco
                                                    name={"pkc_valorunitario"}
                                                    initialValue={pkc_valorunitario}
                                                    onBlur={() => myFunction(index)}
                                                /> : <b>R$ {parseFloat(pkc_valorunitario).toFixed(2)}</b>
                                            }
                                        </div>
                                    )
                                },
                                {
                                    title: 'Vlr Total',
                                    width: 100,
                                    align: 'right',
                                    render: ({ pkc_valorunitario, qtde_item }) => (
                                        <b>R$ {FormatNumber(parseFloat(qtde_item * pkc_valorunitario).toFixed(2))}</b>
                                    )
                                },
                                {
                                    title: 'Ações',
                                    dataIndex: '',
                                    align: 'center',
                                    key: 'x',
                                    width: 65,
                                    fixed: 'right',
                                    render: ({ editando }, record, index) => {
                                        return <div>
                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                <Col>
                                                    {editando ?
                                                        <Button
                                                            onClick={() => saveEdition(index)}
                                                            icon={<CheckOutlined />}
                                                        /> :
                                                        <Button
                                                            onClick={() => editarItem(index)}
                                                            icon={<EditOutlined />}
                                                        />
                                                    }
                                                </Col>
                                                <Col>
                                                    {editando ? <Button 
                                                            onClick={() => cancelEdition(index)} 
                                                            icon={<CloseOutlined />} /> :
                                                        <Button
                                                            onClick={() => excluirItem(record, index)}
                                                            icon={<DeleteOutlined />}
                                                        />
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    },
                                },
                            ]} pagination={false} scroll={{
                                y: 180
                            }} />
                        </div>
                    </Col>
                </Row>
                <Row justify="space-between" className="m-t-10">
                    <Col>
                        <Form.Item name="listar_inativos" valuePropName="checked" initialValue={false} onChange={() => carregarItensProdutoKit(manutencao.dados?.pro_id, !formulario.getFieldsValue().listar_inativos)}>
                            <Checkbox> Listar Itens Inativos </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                        <InputPreco label="Valor do KIT (R$)" name="preco_total_kit" onBlur={() => calcularValorVendaPorMarkup()} />
                    </Col>
                </Row>
                <Row justify="end">
                    <Col>
                        <Typography.Title level={4} className="m-0">
                            Valor Total dos Itens: R$ {parseFloat(listaItems?.reduce((total, item) => total + parseFloat(item.qtde_item * item.pkc_valorunitario), 0)).toFixed(2)}
                        </Typography.Title>
                    </Col>
                </Row>
            </FormGW>
        </div >
    );
}