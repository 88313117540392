import React, { useState } from "react";
import { Col, Descriptions, Form, Modal, notification, Row, Table } from "antd";
import { CloseOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import moment from "moment";

export default function ModalSelectLote({ exibirModal, fecharModal, dadosLote, setDadosLote, dadosItem }) {

    const [listaLote, setListaLote] = useState([]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
            }
        })
    }
    function salvarLotes() {
        let itemCLote = { ...dadosItem };
        let quantidadeRestante = itemCLote.nfi_qtde;
    
        // Ordena listaLote pela data mais antiga
        listaLote.sort((a, b) => new Date(a.prl_datavencimento) - new Date(b.prl_datavencimento));
    
        let listaTemp = [];
        
        listaLote.forEach(item => {
            let quantidadeParaAtribuir = Math.min(item.saldo, quantidadeRestante);
    
            listaTemp.push({
                prl_id: item.prl_id,
                nfl_qtde: quantidadeParaAtribuir,
            });
    
            quantidadeRestante -= quantidadeParaAtribuir;
    
            if (quantidadeRestante <= 0) {
                return;
            }
        });
    
        let qtdeLote = listaTemp.reduce((total, item) => total + item.nfl_qtde, 0);
    
        if (qtdeLote < itemCLote.nfi_qtde) {
            notification.warning({ message: 'Aviso!', description: 'Quantidade de Lotes menor que quantidade do item.' });
        } else {
            fecharModal(itemCLote, listaTemp);
        }
    };

    function selecionarLotes(selectedRowKeys, selectedRows) {
        setListaLote(selectedRows);
    }

    return (
        <Modal centered
            title="Lotes de Produto"
            width= '35%'
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={salvarLotes}
            okText={
                <>
                    <PlusOutlined /> Confirmar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="formSelecionarLote" /*form={formulario}*/>
                    <Row gutter={[0, 8]}>
                        <Col>
                            <Descriptions size="small">
                                <Descriptions.Item>
                                    <b>{dadosItem.pro_descricao}</b>
                                </Descriptions.Item>
                            </Descriptions>
                            <Descriptions>
                                <Descriptions.Item>
                                    Selecione o Lote
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <div className="tabela">
                        <Table pagination={false} columns={
                            [
                                {
                                    title: 'Número',
                                    width: 180,
                                    align: 'center',
                                    render: ({ prl_numerolote }) => (
                                        <b>{prl_numerolote}</b>
                                    )
                                },
                                {
                                    title: 'Qtde',
                                    width: 75,
                                    align: 'center',
                                    render: ({ saldo }) => (
                                        <b>{saldo}</b>
                                    ),
                                },
                                {
                                    title: 'Data Entrada',
                                    render: ({ prl_dataentrada }) => (
                                        <b>{moment(prl_dataentrada).format('DD/MM/YYYY')}</b>
                                    )
                                },
                                {
                                    title: 'Data Fabricação',
                                    render: ({ prl_datafabricacao }) => (
                                        <b>{moment(prl_datafabricacao).format('DD/MM/YYYY')}</b>
                                    )
                                },
                                {
                                    title: 'Data Validade',
                                    render: ({ prl_datavencimento }) => (
                                        <b>{moment(prl_datavencimento).format('DD/MM/YYYY')}</b>
                                    ),
                                }
                            ]
                        }
                            dataSource={dadosLote}
                            rowSelection={{
                                onChange: (selectedRowKeys, selectedRows) => {
                                    selecionarLotes(selectedRowKeys, selectedRows);
                                }
                            }}
                            scroll={{ y: 240}}
                        />
                    </div>
                </Form>
            </div>
        </Modal>
    )
}