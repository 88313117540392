import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Input } from "antd";
import { FormularioDinamico } from "../../components";
import api from "../../services/api";

export default function PesquisaCamposPersonalizados({formPesquisaAvancada, campoPerSelecionado, setCampoPerSelecionado, tipoFiltroPersonalizado, setTipoFiltroPersonalizado, tipoEntidade}) {
    const [condicao, setCondicao] = useState([]);
    const [camposPer, setCamposPer] = useState([]);

    function carregaCamposPersonalizados() {
        api.get(`CamposPersonalizados/ListarTodos?entidade=${tipoEntidade}&pagina=1&nroRegistros=1000&ordem=+cap_ordem`).then(res => {
            if (res.status === 200) {
                let key=0;
                res.data.dados.forEach((item) => (
                    item.key = key++
                ))
                setCamposPer(res.data.dados);
            }
        }).catch(
            (erro) => {
                console.log(erro);
                alert('Erro ao carregar Campos personalizados!');
            }
        );
    };

    useEffect(() => {
        api.get(`Enum/Listar?filtro=&nome=CondicaoFiltroCampoAdicional`).then(
            res => {
                setCondicao(res.data);
            }
        );
        carregaCamposPersonalizados();
    }, []);
    return (
        <Row gutter={[8, 0]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                <Form.Item label="Campo Personalizado" name="campoPersonalizado" >
                    <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        placeholder="Selecione um campo personalizado"
                        options={
                            camposPer.map((item) => {
                                return { label: `${item.cap_id} - ${item.cap_label}`, value: item.cap_id }
                            })
                        }
                        onChange = {(valor) => {
                            let campoPer = camposPer.filter(campo => campo.cap_id === valor)[0]
                            if (!!campoPer){
                                if (campoPer.cap_tipoelemento === 'select' || campoPer.cap_tipoelemento === 'dataPicker' || campoPer.cap_tipoelemento === 'checkbox'){
                                    setTipoFiltroPersonalizado(2);
                                } else {
                                    setTipoFiltroPersonalizado(1);    
                                };
                                setCampoPerSelecionado([campoPer]); 
                            } else
                                setTipoFiltroPersonalizado(0);
                        }}
                        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} />
                </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                <Form.Item label="Condição" name="condicao" rules={tipoFiltroPersonalizado!=0? [{ required: true, message: 'Informe uma Condição para o Filtro.' }]:[]}>
                    <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        placeholder="Selecione uma condição..."
                        options={
                            condicao.map((item) => {
                                return { label: `${item.value}`, value: item.key }
                            })
                        }
                        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} />
                </Form.Item>
            </Col>            
            { tipoFiltroPersonalizado==1 && 
                <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                    <Form.Item label="Filtro" name="filtroCampoAdicional" rules={[{ required: true, message: 'Informe um Filtro para a consulta.' }]}>
                        <Input placeholder="Informe o filtro para essa condição" />
                    </Form.Item>
                </Col>
            }
            { tipoFiltroPersonalizado==2 && 
                <FormularioDinamico formulario={formPesquisaAvancada}  parametrosFiltros={campoPerSelecionado} retornarLinhas={false}/> 
            }
        </Row>
    )
}