import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Tag, Dropdown, Menu, notification, Table, Divider } from "antd";
import { EditOutlined, DeleteOutlined, CloseOutlined, PrinterOutlined, MoreOutlined, EyeOutlined, CopyOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import { useStateValue } from '../../state';
import DrawerPedidos from '../../pages/pedidos/drawer';
import { ModalDetalhePedido } from "../../components/modals/";
import { getIdUsuario, setIdUsuario } from "../../services/auth";
import { FormatNumber, MaskFormat, FormatDate } from "../../ValueObjects";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";

export default function PesquisaPedidos({ dados }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [clientes, setClientes] = useState([]);
    const [openModalDetalhePedido, setOpenModalDetalhePedido] = useState(false);
    const [dataPesquisa, setDataPesquisa] = useState(new Date().toLocaleDateString());
    const [formPedidos] = Form.useForm();
    const [idPedido, setIdPedido] = useState(0);

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ped_datainclusao' } })
        dispatch({
            type: listagemActions.CHANGE, data: {
                outrosFiltros:
                    `&ativoPedido=1&dataFinal=${moment(new Date).format('YYYY-MM-DD')}&dataInicial=${FormatDate(dataPesquisa)}&filtro=&status=60,61,62`
            }
        })
        formPedidos.setFieldsValue({ status: ["60", "61", "62"] });
        formPedidos.setFieldsValue({ ativoPedido: ["1"] });
        carregarClientes()
    }, []);

    useEffect(() => {
        let filtro = '';
        filtro += (!!formPedidos.getFieldValue().status ? `&status=${formPedidos.getFieldValue().status}` : '');
        filtro += (!!formPedidos.getFieldValue().ativoPedido ? `&ativoPedido=${formPedidos.getFieldValue().ativoPedido}` : '');

        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&dataFinal=${moment(new Date).format('YYYY-MM-DD')}&dataInicial=${FormatDate(dataPesquisa)}&filtro=&status=${formPedidos.getFieldValue().status}` + filtro } })
    }, [dataPesquisa]);

    function carregarClientes() {
        api.get(`Cliente/Listar?filtro=`).then(
            (res) => {
                if (res.status === 200) {
                    setClientes(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function editarPedido(record) {
        record.ped_datainclusao = moment(record.ped_datainclusao);
        record.ped_dataprevisaoentrega = moment(record.ped_dataprevisaoentrega);
        record.ped_datavalidade = moment(record.ped_datavalidade);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function cancelarPedido(record) {
        record.usu_id = parseInt(getIdUsuario());
        record.ped_ativo = false;
        api.post(`Pedido/CancelarPedido?`, record).then(
            res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "Pedido cancelado com sucesso!" });
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ped_datainclusao' } });
            }
        );
    };

    function emitirNf(record) {
    };

    function visualizarPedido(record) {
        setIdPedido(record.ped_id);
        setOpenModalDetalhePedido(true)
    };

    function menuTable(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="link" icon={<EditOutlined />} onClick={() => editarPedido(record)}> Editar</Button>
                </Menu.Item>
                {record.ped_ativo !== false && <Menu.Item key="2">
                    <Button type="link" icon={<CloseOutlined />} onClick={() => cancelarPedido(record)}> Cancelar </Button>
                </Menu.Item>}
                <Menu.Item key="3">
                    <Button type="link" icon={<CopyOutlined />} onClick={() => duplicarPedido(record)}> Duplicar </Button>
                </Menu.Item>
                <Menu.Item key="4">
                    <Button type="link" icon={<DeleteOutlined />} onClick={() => emitirNf(record)}> Emitir NF-e </Button>
                </Menu.Item>
                <Menu.Item key="5">
                    <Button type="link" icon={<EyeOutlined />} onClick={() => visualizarPedido(record)}> Visualizar </Button>
                </Menu.Item>
                <Menu.Item key="6">
                    <Button type="link" icon={<PrinterOutlined />}> Imprimir </Button>
                </Menu.Item>
            </Menu>
        )
    };

    function duplicarPedido(dados) {
        dados.ped_datainclusao = moment(dados.ped_datainclusao);
        dados.ped_dataprevisaoentrega = moment(dados.ped_dataprevisaoentrega);
        dados.ped_datavalidade = moment(dados.ped_datavalidade);
        dados.ped_id_duplicar = dados.ped_id;
        dados.ped_id = null;
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    return (
        <div className="tabela">
            <Table dataSource={dados} columns={[

                {
                    title: "",
                    width: 100,
                    align: 'center',
                    render: ({ stp_descricao, ped_numero }) => (
                        <div>
                            <Row align="middle" justify="center">
                                <Col span={24}> 
                                    <Tag color="processing" className="w-100">
                                        <b>{stp_descricao}</b>
                                    </Tag>
                                </Col>
                                <Col span={24}> 
                                    <b>{ped_numero}</b>
                                </Col>
                            </Row>
                        </div>
                    )
                },
                {
                    title: "Pedidos",
                    render: ({ pes_nome, pef_cpf, pej_cnpj, pes_telefone, pes_endereco, pes_nroestabelecimento, pes_bairro, pes_cep, pes_cidade, pes_uf, ped_datainclusao, ped_dataprevisaoentrega, pdi_qtditem, ped_ativo }) => (
                        <div>
                            <Row align="middle" gutter={[16, 0]}>
                                <Col>
                                    <b>{pes_nome}</b>
                                </Col>
                                <Col>
                                    CPF/CNPJ: <b>{!!pef_cpf ? MaskFormat(pef_cpf, 'Não Informado', true) : MaskFormat(pej_cnpj, 'Não Informado', true)}</b>
                                </Col>
                                <Col>
                                    Telefone: <b>{pes_telefone || 'Não Informado'}</b>
                                </Col>
                            </Row>
                            <Row align="middle">
                                <Col>
                                    Endereço: <b>{pes_endereco}, {pes_nroestabelecimento}, {pes_bairro}, {pes_cep}, {pes_cidade}-{pes_uf}</b>
                                </Col>
                            </Row>
                            <Row align="middle" gutter={[16, 0]}>
                                {!ped_ativo && <Col className="c-red">
                                    <strong>Cancelado</strong>
                                </Col>}
                                <Col>
                                    Emissão: <b>{moment(ped_datainclusao).format('DD/MM/YYYY')}</b>
                                </Col>
                                <Col>
                                    Entrega: <b>{moment(ped_dataprevisaoentrega).format('DD/MM/YYYY')}</b>
                                </Col>
                                <Col>
                                    Quantidade de Itens: <b>{pdi_qtditem}</b>
                                </Col>
                            </Row>
                        </div>
                    )
                },
                {
                    title: "Total do Pedido (R$)",
                    align: 'right',
                    render: ({ ped_valortotal }) => (
                        <div>
                            <b className="f-18">{FormatNumber(ped_valortotal, true)}</b>
                        </div>
                    )
                },/*
                {
                    title: 'Ações',
                    dataIndex: '',
                    key: 'x',
                    align: 'center',
                    width: 65,
                    fixed: 'right',
                    render: (record) => (
                        <div>
                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                <Col>
                                    <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                        <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                    </Dropdown>
                                </Col>
                            </Row>
                        </div>
                    ),
                },*/
            ]} locale={{
                emptyText: (
                    <Row>
                        <Col span={24}>
                            <Divider orientation="center">
                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                            </Divider>
                        </Col>
                    </Row>
                )
            }} scroll={{ x: 900 }}/>
            <DrawerPedidos clientes={clientes} />
            <ModalDetalhePedido exibirModalDetalhesPedido={openModalDetalhePedido} fecharModalDetalhesPedido={() => setOpenModalDetalhePedido(false)} ped_id={idPedido} />
        </div>
    );

}