import React, { useEffect, useState } from "react";
import { Form, Row, Col, Tabs, Input, Select, notification, Button } from "antd";

import api from "../../services/api";
import TabRespostas from './tabRespostas';
import { FormGW } from "../../components";
import { useStateValue } from "../../state";
import { listagemActions } from "../../actions";
import { getEmpresa } from "../../services/auth";
import { validaForm } from "../../services/funcoes";
import { ArrowRightOutlined } from "@ant-design/icons";

export default function ManutencaoLimiteCreditoCliente({ formulario, carregando, aoSalvar }) {

    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [listaTipoResposta, setListaTipoResposta] = useState([]);
    const [showRespostas, setShowRespostas] = useState(false);
    const [respostasLimiteCredito, setRespostasLimiteCredito] = useState([]);

    useEffect(() => {
        setAbaSelecionada("1");
        carregarListaTipoResposta();
    }, [manutencao.dados])

    function carregarListaTipoResposta() {
        api.get(`Enum/Listar?nome=TipoResposta`).then(res => {
            if (!!res) {
                setListaTipoResposta(res.data);
            }
        });

        if (!!manutencao.dados) {
            tipoResposta(manutencao.dados.qlc_tiporesposta);
            setRespostasLimiteCredito(manutencao.dados.respostalimitecreditos.sort((a, b) => a.rlc_ordem - b.rlc_ordem));
        } else {
            tipoResposta(1);
        }
    };

    const salvarManutencao = (values) => {
        if (respostasLimiteCredito.length === 0 && values.qlc_tiporesposta === 2) {
            notification.warning({ message: 'Aviso', description: 'É preciso adicionar pelo menos uma resposta!' });
            return;
        }
        if (validaForm(formulario, listaValidacoes)) {
            values.qlc_tiporesposta = listaTipoResposta.find((item) => (item.key === values.qlc_tiporesposta)).key;
            values.respostalimitecreditos = respostasLimiteCredito;

            if (!!values.qlc_id) {
                editarLimiteCredito(values);
            } else {
                incluirLimiteCredito(values);
            }
        }
    };

    function incluirLimiteCredito(values) {
        carregando(true);
        api.post(`QuestaoLimiteCredito/Incluir`, values).then(res => {
            if (res.status === 200) {
                notification.success({ message: 'Operação concluída', description: "Os dados foram salvos com sucesso!" });
            }
        }, err => {
            notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
        }).finally(() => {
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+qlc_ordem', outrosFiltros: `&codigoEmpresa=${getEmpresa()}` } });
            carregando(false);
            aoSalvar();
        });
    }

    function editarLimiteCredito(values) {
        carregando(true);
        api.put(`QuestaoLimiteCredito/Editar`, values).then(res => {
            if (res.status === 200) {
                notification.success({ message: 'Operação concluída', description: "Os dados foram salvos com sucesso!" });
            }
        }, err => {
            notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
        }).finally(() => {
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+qlc_ordem', outrosFiltros: `&codigoEmpresa=${getEmpresa()}` } });
            carregando(false);
            aoSalvar();
        });
    }

    const listaValidacoes = [
        { nome: 'qlc_questao', obrigatorio: true, label: 'Pergunta' },
        { nome: 'qlc_tiporesposta', obrigatorio: true, label: 'Tipo de Resposta' }
    ];

    const tipoResposta = (value) => {
        if (value === 2) {
            setShowRespostas(true);
        } else {
            setShowRespostas(false);
        }
    };

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoLimiteCreditoCliente" onFinish={salvarManutencao}>
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Questões" key="1">
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={8} lg={9} xl={9}>
                                <Form.Item hidden name="qlc_id" />
                                <Form.Item hidden name="qlc_ordem" />
                                <Form.Item label="Pergunta" name="qlc_questao">
                                    <Input placeholder="Informe uma Pergunta" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={8} lg={9} xl={9}>
                                <Form.Item label="Tipo de Resposta" name="qlc_tiporesposta">
                                    <Select options={listaTipoResposta.map((item) => {
                                        return { label: `${item.value}`, value: item.key }
                                    })} placeholder="Selecione o Tipo de Resposta" onChange={(value) => tipoResposta(value)} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                                <Row justify="center" className="t-mob">
                                    <Col xs={24} sm={12} md={24} lg={24} xl={24}>
                                        <Button onClick={() => setAbaSelecionada("2")} hidden={!showRespostas} block>
                                            Ir para definição das Respostas <ArrowRightOutlined />
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    {showRespostas &&
                        <Tabs.TabPane tab="Respostas" key="2">
                            <TabRespostas formulario={formulario} respostas={{ respostasLimiteCredito, setRespostasLimiteCredito }} />
                        </Tabs.TabPane>
                    }
                </Tabs>
            </FormGW>
        </div>
    );
}
