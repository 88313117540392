import React, { useEffect, useState } from "react";
import { Form, Row, Col, InputNumber, Tag, Table, Switch } from "antd";

import { FormatNumber } from '../../ValueObjects';
import { useStateValue } from "../../state";

export default function TabItens({ form, dadosItens }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [totalizador, setTotalizador] = useState({});

    useEffect(() => {
        if (ui.showDrawerExtra) {
            ratearDescontoFrete();
        }
    }, [ui.showDrawerExtra]);

    function ratearDescontoFrete(item = [...dadosItens.dadosItens]) {

        var itensSemQuantidade = 0;
        var somaTotalOrdem = 0;
        let vlrTotalRecebendo = 0;
        let vlrTotalDesconto = 0;
        let somaRateio = 0;
        let resto = 0;
        let QtdeTotalRecebendo = 0;
        let frete = dadosItens.frete ?? 0;
        let totalSemDesc = 0;
        let valorTotal = 0;
        let percentualDesconto = (parseFloat(form.getFieldValue().percentualDesconto) / 100);

        item.forEach((itens) => {
            var valorTotalItem = itens.ocp_quantidade * itens.ocp_valorun;
            somaTotalOrdem += valorTotalItem;
        });

        item.forEach(itens => {
            var valorFrete = 0;
            var qtdrecebendo = form.getFieldValue(`ocp_qtdrecebendo${itens.key}`) ?? 0;
            var maximoItens = itens.ocp_quantidade - itens.ocp_quantidaderecebida;

            if (qtdrecebendo <= maximoItens) {
                itens.ocp_qtdrecebendo = qtdrecebendo;
            } else {
                itens.ocp_qtdrecebendo = maximoItens;
            }
            if (itens.max == true) {
                itens.ocp_qtdrecebendo = maximoItens;
                itens.qtdrecebendo = maximoItens;
                form.setFieldsValue({ [`ocp_qtdrecebendo${itens.key}`]: itens.qtdrecebendo })
            }

            itens.valorTotalDesconto = 0;
            itens.ocp_frete = 0;
            itens.ocp_desconto = 0;
            itens.desconto = 0;

            var totalItemRecebendo = qtdrecebendo * itens.ocp_valorun;
            var desconto = totalItemRecebendo * (!!percentualDesconto ? percentualDesconto : 0);

            itens.valorTotalDesconto = totalItemRecebendo;
            itens.desconto = desconto;
            itens.frete = valorFrete;
            vlrTotalDesconto = desconto;
            vlrTotalRecebendo += totalItemRecebendo;
            totalSemDesc += totalItemRecebendo;

            itensSemQuantidade++;
        });

        vlrTotalRecebendo = parseFloat(vlrTotalRecebendo.toFixed(2));
        totalSemDesc = parseFloat(totalSemDesc.toFixed(2));
        QtdeTotalRecebendo = item.length;

        var itensSemQuantidade = (item.length - itensSemQuantidade) < 0 ? true : false;

        if (somaRateio > 0 && itensSemQuantidade) {
            resto = parseFloat(vlrTotalRecebendo) - somaRateio;
            if (resto !== 0) {
                item[item.length - 1].valorTotalDesconto += resto;
            }
        }

        form.setFieldsValue({ orc_valortotal: vlrTotalRecebendo, totalSemDesc: totalSemDesc, frete: frete, QtdeTotalRecebendo: QtdeTotalRecebendo });
        setTotalizador({ valorTotal: vlrTotalRecebendo, totalSemDesc: totalSemDesc, totalDesconto: vlrTotalDesconto, QtdeTotalRecebendo: QtdeTotalRecebendo });
        dadosItens.setDadosItens(totalSemDesc);
        dadosItens.setDadosItens(valorTotal);
        dadosItens.setDadosItens(item);
        return item;
    };


    function switchReceber() {
        let valorTotal = 0;
        let qtdeTotalRecebendo = 0;
        let dados = [...dadosItens.dadosItens];
        dados.forEach(item => {
            item.max = true;
            item.ocp_qtdrecebendo = item.ocp_quantidade - item.ocp_quantidaderecebida;
            item.valorTotalDesconto = item.ocp_qtdrecebendo * item.ocp_valorun;
            form.setFieldsValue({ [`ocp_qtdrecebendo${item.key}`]: item.ocp_qtdrecebendo })
            if (item.ocp_qtdrecebendo > 0)
                qtdeTotalRecebendo++;

            valorTotal += item.valorTotalDesconto;
        })
        dadosItens.setDadosItens(dados);
        setTotalizador({ valorTotal: valorTotal, QtdeTotalRecebendo: qtdeTotalRecebendo });
    };


    function alteraQuantidadeItemRecebido(item, key) {

        let valorTotal = 0;
        let qtdrecebendo = form.getFieldValue(`ocp_qtdrecebendo${key}`)
        let dados = [...dadosItens.dadosItens];
        let i = dados.find(i => i.key == item.key)
        i.ocp_qtdrecebendo = qtdrecebendo
        i.valorTotalDesconto = i.ocp_qtdrecebendo * i.ocp_valorun;
        dadosItens.setDadosItens(dados);
        let itens = dadosItens.dadosItens.filter(r => r.ocp_qtdrecebendo > 0)
        itens.forEach(i =>{
            valorTotal += i.valorTotalDesconto;
        })   
        setTotalizador({ valorTotal: valorTotal, QtdeTotalRecebendo: itens.length });
    }

    return (
        <div className="m-t-14">
            <div className="teste mf-dif">
                {<Form.Item className="t-mob">
                    <Switch
                        onClick={(item) => switchReceber(item, item.key)}
                    /> Receber Todos os Itens?
                </Form.Item>}

            </div>
            <div className="tabela mt-dif">
                {
                    !!dadosItens.dadosItens && dadosItens.dadosItens.length > 0 &&
                    <Table columns={[
                        {
                            title: 'ITEM',
                            width: 200,
                            render: ({ key, pro_id, pro_descricao, ean, grade }) => (
                                <div>
                                    <Row align="middle" gutter={[5, 0]}>
                                        <Col>
                                            <Tag color="processing" className="w-80">
                                                <b> {key} </b>
                                            </Tag>
                                            <b>  {pro_id} - {pro_descricao}</b>
                                        </Col>
                                    </Row>
                                    <Row align="middle" gutter={[5, 0]}>
                                        <Col>
                                            EAN: <b>{ean}</b>
                                        </Col>
                                        <Col >
                                            Grade: <b>{grade}</b>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        },
                        {
                            title: 'Qtde. Solic.',
                            width: 20,
                            align: 'center',
                            render: ({ ocp_quantidade }) => (
                                <div>
                                    <b>{ocp_quantidade?.toFixed(0)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Recebido',
                            align: 'center',
                            width: 20,
                            render: ({ ocp_quantidaderecebida }) => (
                                <div>
                                    <b>{ocp_quantidaderecebida}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Qtde. Receber',
                            align: 'center',
                            width: 20,
                            render: (item) => (
                                <div>
                                    <Form.Item name={`ocp_qtdrecebendo${item.key}`}>
                                        <InputNumber disabled={item.ocp_quantidade === item.ocp_quantidaderecebida}
                                            defaultValue={0}
                                            min={0}
                                            max={item.ocp_quantidade - item.ocp_quantidaderecebida}
                                            onChange={() => alteraQuantidadeItemRecebido(item, item.key)}
                                            className="w-60" />
                                    </Form.Item>
                                </div>
                            ),
                        },
                        {
                            title: 'Vlr Unt.(R$)',
                            align: 'right',
                            width: 20,
                            render: ({ nfi_valorunitario }) => (
                                <div>
                                    <b>{FormatNumber(nfi_valorunitario, true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Vlr Total (R$)',
                            align: 'right',
                            width: 40,
                            render: ({ valorTotalDesconto }) => (
                                <div>
                                    <b>{FormatNumber(valorTotalDesconto, true)}</b>
                                </div>
                            ),
                        }
                    ]} dataSource={dadosItens.dadosItens}

                        scroll={{ x: 900 }} />
                }
            </div>
            <div>
                <Row justify="end" gutter={[8, 0]}>
                    <Col className="f-16 m-t-3">
                        <b>Quantidade Itens:</b>
                    </Col>
                    <Col className="f-21">
                        <b>{totalizador.QtdeTotalRecebendo}</b>
                    </Col>
                </Row>
                <Row justify="end" gutter={[8, 0]}>
                    <Col className="f-16 m-t-3">
                        <b>Valor Total dos Itens (R$):</b>
                    </Col>
                    <Col className="f-21">
                        <b>{FormatNumber(totalizador.valorTotal, true)}</b>
                    </Col>
                </Row>
            </div >
        </div>
    );
}