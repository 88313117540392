import React, { useState } from "react";
import { Row, Col, Typography, Divider, Form, Input, notification } from "antd";

import api from "../../services/api";
import { InputCep } from "../../components";

export default function TabEndereco({ formulario }) {

    const [cidade, setCidade] = useState(null);
    const verificaCep = (cep) => {
        if (!!cep || !!formulario.getFieldValue().cep_cep) {
            cep = !!cep ? cep : formulario.getFieldValue().cep_cep;
            api.get(`Cidade/BuscaCidadeCep?cep=${cep}`).then(
                res => {
                    if (!!res.data) {
                        setCidade(res.data[0]);
                        formulario.setFieldsValue({ cid_descricao: `${res.data[0].cid_descricao} / ${res.data[0].est_sigla}`, est_sigla: res.data[0].est_sigla, cep_id: res.data.cep_id });
                    } else {
                        fetch(`https://viacep.com.br/ws/${cep.replace(`-`, ``)}/json/`)
                            .then(
                                res => res.json()
                            ).then(
                                result => {
                                    setCidade(result);
                                    formulario.setFieldsValue({ cid_descricao: `${result.localidade} / ${result.uf}`, est_sigla: result.uf });
                                }).catch(
                                    error => {
                                        notification.warning({ message: 'Aviso', description: 'Cep não encontrado!' })
                                    }
                                );
                    }
                }
            ).catch(
                error => {
                    notification.warning({ message: 'Aviso', description: 'Cep não encontrado!' })
                }
            )
        }
    };

    return (
        <div>
            <Row align="middle" gutter={[8, 8]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-endereco.png").default} alt="Endereço" /> Endereço
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Adicione abaixo as informações referente ao endereço.
                    </Divider>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={6} md={3} lg={3} xl={3}>
                            <Form.Item hidden name="cid_id" />
                            <Form.Item hidden name="cep_id" />
                            <Form.Item hidden name="est_sigla" />
                            <Form.Item hidden name="log_id" />
                            <Form.Item hidden name="bai_id" />
                            <Form.Item hidden name="est_id" />
                            <Form.Item hidden name="lcd_id" />
                            <Form.Item hidden name="pse_id" />
                            <InputCep label="CEP" name="cep_cep" rules={[{ required: true, message: 'Informe o CEP' }]} onBlur={(event) => { verificaCep(event.currentTarget.value); }} />
                        </Col>
                        <Col xs={24} sm={6} md={3} lg={3} xl={3}>
                            <Form.Item className="text-center t-mob-573">
                                <Typography.Link href="http://www.buscacep.correios.com.br/sistemas/buscacep/" target="_black" underline>
                                    Não sei o CEP
                                </Typography.Link>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={4} xl={4}>
                            <Form.Item label="Cidade" name="cid_descricao">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={20} md={8} lg={8} xl={8}>
                            <Form.Item label="Endereço" name="log_logradouro" rules={[{ required: true, message: 'Informe o Endereço' }]}>
                                <Input placeholder="Informe o Endereço" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={4} md={4} lg={3} xl={3}>
                            <Form.Item label="Número" name="loc_numeroestabelecimento" rules={[{ required: true, message: 'Informe o Número' }]}>
                                <Input placeholder="Informe o Número" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={9} md={6} lg={3} xl={3}>
                            <Form.Item label="Bairro" name="bai_nome" rules={[{ required: true, message: 'Informe o Bairro' }]}>
                                <Input placeholder="Informe o Bairro" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={6} md={6} lg={3} xl={3}>
                            <Form.Item label="Complemento" name="loc_complemento">
                                <Input placeholder="Informe o Complemento" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={9} md={8} lg={5} xl={5}>
                            <Form.Item label="Ponto de Referência" name="loc_pontoreferencia">
                                <Input placeholder="Informe o Ponto de Referência" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );

}