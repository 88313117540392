import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Select, Divider, Button, Typography, Table, notification } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import moment from "moment";

import api from '../../services/api';
import { useStateValue } from "../../state";
import { manutencaoActions, selectPaginadoActions } from "../../actions";
import { FormatNumber } from "../../ValueObjects";
import { Data, FormGW, InputPreco, SelectPaginacao } from "../../components";
import { ModalGradeOrdemCompra } from "../../components/modals/";
import FiltroProdutoOrc from "../../components/filtroProdutoOrc";
import { getIdUsuario } from "../../services/auth";
import SelectPaginado from '../../components/selectPaginado';

export default function ManutencaoOrdemCompra({ formulario, carregando, aoSalvar, transportador }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [openModalGradeOrdemCompra, setOpenModalGradeOrdemCompra] = useState(false);
    const [itensOrdemCompra, setItensOrdemCompra] = useState([]);
    const [totalizadores, setTotalizadores] = useState({});
    const [pagina, setPagina] = useState(1);
    const [regPagina, setRegPagina] = useState(10);
    const [editando, setEditando] = useState(false);
    const [dadosEditando, setDadosEditando] = useState({});
    

    useEffect(() => {
        if (ui.showDrawer) {
            carregarDados();
            if (!(!!formulario.getFieldValue().orc_datainclusao)) {
                formulario.setFieldsValue({ orc_datainclusao: moment(new Date()) });
            }
            if (!!manutencao.dados) {
                let lista = [];
                if (!!manutencao.dados.for_id) {
                    lista.push({ name: "for_id", campo: "FornecedorId", value: manutencao.dados.for_id });
                }
                if (!!manutencao.dados.trn_id) {
                    lista.push({ name: "trn_id", campo: "IdTrasnportador", value: manutencao.dados.trn_id });
                }
                if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                formulario.setFieldsValue(manutencao.dados);
            }
        }
    }, [ui.showDrawer]);

    useEffect(() => {
        if (!!manutencao.dados) {
            manutencao.dados.orc_dataconclusao = moment(manutencao.dados.orc_dataconclusao);
            manutencao.dados.orc_prazomaximo = moment(manutencao.dados.orc_prazomaximo);
            manutencao.dados.orc_datainclusao = moment(manutencao.dados.orc_datainclusao);
            if (!!manutencao.dados.orc_id && itensOrdemCompra.length === 0) {
                buscarItensOrdemCompra(manutencao.dados.orc_id);
            }
            formulario.setFieldsValue(manutencao.dados);
        }
    }, [manutencao.dados])

    useEffect(() => {
        let dados = {};
        dados.total = 0;
        dados.quantidade = 0;
        if (itensOrdemCompra.length > 0) {
            itensOrdemCompra.forEach((item) => {
                dados.total += parseFloat(item.ocp_valorun * item.ocp_quantidade);
                dados.quantidade += item.ocp_quantidade;
            });
        }
        formulario.setFieldsValue({ orc_valortotal: parseFloat(dados.total).toFixed(2) });
        dados.total += parseFloat(!!formulario.getFieldValue().orc_valorfrete ? formulario.getFieldValue().orc_valorfrete : 0);
        setTotalizadores(dados);
    }, [itensOrdemCompra]);

    async function salvarManutencao(values) {

        if (itensOrdemCompra.length > 0) {
            carregando(true);
            values.ordemcompraitens = itensOrdemCompra;
            values.orc_valorfrete = parseFloat(values.orc_valorfrete);
            if (!(!!values.orc_id)) {
                values.orc_status = 0;
            }

            if (typeof values.orc_id == 'undefined') delete values.orc_id;
            values.usu_id = getIdUsuario();

            try {
                var res = values.orc_id ? await api.put('OrdemCompra/Editar', values) : await api.post('OrdemCompra/Incluir', values);
                if (res.status == 200) {
                    notification.success({ message: 'Operação concluída', description: "Os dados da Ordem de Compra foram salvos com sucesso!" });
                }
            } catch (error) {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(error.response) });
            } finally {
                carregando(false);
                aoSalvar();
            }

        } else {
            notification.warning({ message: 'Aviso!', description: 'É necessário informar pelo menos um item!' });
        }
    };

    function carregarDados() {

        if (!!formulario.getFieldValue().orc_id) {
            api.get(`OrdemCompraItem/ListarItensOrdemCompra?codigoOrdemCompra=${formulario.getFieldValue().orc_id}`).then(
                (res) => {
                    let itens = res.data;
                    itens.forEach((item, indice) => {
                        if (!!item.prg_id) {
                            item.grade = {
                                prg_id: item.prg_id,
                                grade: item.mgp_descricao,
                                mgp_descricao: item.mgp_descricao,
                                prg_ean: item.prg_ean,
                                prg_codigoetiqueta: item.prg_codigoetiqueta,
                                prg_valorvenda: item.lpi_valorvenda,
                            }
                        }
                        if (indice + 1 === itens.length) {
                            setItensOrdemCompra(itens);
                        }
                    });
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            )
        }
    };

    function buscarItensOrdemCompra(orc_id) {
        api.get(`OrdemCompraItem/ListarItensOrdemCompra?codigoOrdemCompra=${orc_id}`).then(
            res => {
                setItensOrdemCompra(res.data);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    function atualizarValorTotalOC() {
        let dados = { ...totalizadores };
        dados.total = parseFloat(!!formulario.getFieldValue().orc_valortotal ? formulario.getFieldValue().orc_valortotal : 0) + parseFloat(!!formulario.getFieldValue().orc_valorfrete ? formulario.getFieldValue().orc_valorfrete : 0);
        setTotalizadores(dados);
    };

    function editarItem(registro) {
        setDadosEditando(registro);
        setEditando(true);
        //dispatch({ type: manutencaoActions.CHANGE, data: { dados: registro } });
    };

    function removerItem(item) {
        let dadosTemp = [...itensOrdemCompra];
        dadosTemp = dadosTemp.filter((dados) => dados !== item);
        setItensOrdemCompra(dadosTemp);
    };


    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoOrdemCompra" onFinish={salvarManutencao}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={16} md={14} lg={12} xl={12} xxl={12}>
                        <Form.Item name="orc_id" hidden />
                        <Form.Item name="orc_status" hidden />
                        <Form.Item label="Selecione o Fornecedor" name="for_id" rules={[{ required: true, message: 'Selecione um Fornecedor' }]}>
                            <SelectPaginacao url="Fornecedor/Listar?Order=%2Bpes_nome"
                                placeholder="Selecione o Fornecedor"
                                form={formulario} nameValue="for_id" nameLabel="for_id"
                                conteudo={
                                    forn => (<Select.Option value={forn.for_id} key={forn.for_id}>
                                        {forn.for_id} - {forn.pes_nome}
                                        {(!!forn.pej_cnpj ? <div>CNPJ: <b>{forn.pej_cnpj}</b></div> : "")}
                                    </Select.Option>)
                                }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={5} lg={5} xl={4}>
                        <Data disabled label="Data de Lançamento" name="orc_datainclusao" />
                    </Col>
                    <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                        <Form.Item label="Nº do Pedido" name="orc_nropedido">
                            <Input placeholder="Informe o Número do Pedido do seu Fornecedor" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={5} lg={4} xl={4}>
                        <Data label="Prazo Máximo" name="orc_prazomaximo" rules={[{ required: true, message: 'Selecione uma Data' }]} />
                    </Col>
                    <Col xs={24} sm={16} md={9} lg={8} xl={9}>
                        <Form.Item label="Nome do Atendente" name="orc_atendente">
                            <Input placeholder="Informe o Nome do Atendente" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={16} md={10} lg={6} xl={6}>
                        <Form.Item label="Forma de Pagamento" name="orc_formapagamento">
                            <Input placeholder="Informe a Forma de Pagamento" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={10} xl={9}>
                        <Form.Item label="Transpordador" name="trn_id" rules={[{ required: false, message: 'Informe o Transportador' }]}>
                            <SelectPaginado url="Transportador/ListarTodos" placeholder="Selecione o Transportador" form={formulario} name="trn_id" conteudo={
                                trn => (<Select.Option value={trn.trn_id} key={trn.trn_id}>{trn.pes_nome}</Select.Option>)
                            } />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item label="Observação" name="orc_observacao">
                            <Input.TextArea placeholder="Informe uma observação" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[8, 0]} className="m-t-16">
                    <Col span={24}>
                        <Typography.Title level={3}>
                            <img src={require("../../assets/i-cartao.png").default} alt="Produtos" /> Produtos
                        </Typography.Title>
                        <Divider orientation="left" plain>
                            Adicione o(s) produto(s) para este ordem de compra aqui.
                        </Divider>
                    </Col>
                </Row>
                <div className="m-t-8">
                    <FiltroProdutoOrc dadosTabela={{ itensOrdemCompra, setItensOrdemCompra }} form={formulario} btnEditando={{ editando, setEditando }} itemEditando={dadosEditando} />
                </div>
                {itensOrdemCompra.length > 0 &&
                    <div className="tabela mt-dif">
                        <Table dataSource={itensOrdemCompra} columns={[
                            {
                                title: '',
                                render: ({ pro_descricao, mgp_descricao, grade }) => (
                                    <div>
                                        <Row align="middle">
                                            <Col>
                                                <b>{pro_descricao}</b>
                                            </Col>
                                        </Row>
                                        <Row align="middle">
                                            <Col>
                                                Grade: <b>{!!mgp_descricao ? mgp_descricao : (!!grade ? grade : 'Não Possui')} </b>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                            {
                                title: 'Quantidade',
                                align: 'center',
                                width: 85,
                                render: ({ ocp_quantidade }) => (
                                    <div>
                                        <b>{ocp_quantidade}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Valor Unitário (R$)',
                                align: 'right',
                                width: 150,
                                render: ({ ocp_valorun }) => (
                                    <div>
                                        <b className="f-18">{FormatNumber(ocp_valorun || 'Não Informado', true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                align: 'center',
                                fixed: 'right',
                                key: 'x',
                                width: 65,
                                render: (record) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button icon={<EditOutlined />} onClick={() => editarItem(record)} />
                                            </Col>
                                            <Col>
                                                <Button icon={<DeleteOutlined />} onClick={() => removerItem(record)} />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]} pagination={{
                            current: pagina,
                            pageSize: regPagina,
                            total: itensOrdemCompra.length,
                            showSizeChanger: true,
                            onChange: (pg) => setPagina(pg),
                            onShowSizeChange: (atual, porPagina) => setRegPagina(porPagina)
                        }} />
                    </div>
                }
                <Row className="m-t-16" justify="end">
                    <Col span={24} align="left">
                        Quantidade de Itens: <b>{totalizadores.quantidade}</b>
                    </Col>
                </Row>
                {itensOrdemCompra.length > 0 &&
                    <Row gutter={[8, 0]} className="m-t-8">
                        <Col xs={24} sm={8} md={6} lg={6} xl={4}>
                            <InputPreco label="Valor do Frete (R$)" name="orc_valorfrete" onBlur={() => { atualizarValorTotalOC() }} />
                        </Col>
                        <Col xs={24} sm={8} md={6} lg={6} xl={4}>
                            <InputPreco label="Total dos Itens (R$)" name="orc_valortotal" disabled={true} />
                        </Col>
                        <Col xs={24} sm={8} md={12} lg={12} xl={16}>
                            <Form.Item label="Total da Ordem de Compra (R$)" className="f-right text-right">
                                <strong className="f-21">{FormatNumber(totalizadores.total, true)}</strong>
                            </Form.Item>
                        </Col>
                    </Row>
                }
                <ModalGradeOrdemCompra exibirModalGradeOrdemCompra={openModalGradeOrdemCompra} fecharModalGradeOrdemCompra={() => setOpenModalGradeOrdemCompra(false)} />
            </FormGW>
        </div>
    );
}