import React, { useEffect } from "react";
import { Row, Col, Tag, Popover, Modal, notification } from "antd";

import DrawerMarcaVeiculo from "./drawer";
import api from '../../services/api';
import { useStateValue } from "../../state";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, Editar, Excluir, Duplicar } from "../../components";
import { CopyOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { DeleteOutline } from "@material-ui/icons";

export default function MarcaVeiculo() {

    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '+mav_descricao' } }), []);

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir a Marca ${dados.mav_descricao}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirMarca(dados);
            }
        });
    };

    function excluirMarca(dados) {
        api.delete(`MarcaVeiculo/Excluir/${dados.mav_id}`).then(
            (res) => {
                notification.success({ message: 'Sucesso!', description: 'Marca excluída com sucesso!' });
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
            }).catch(
                (erro) => {
                    modalInativarMarca(dados);
                }
            ).finally(
                () => {
                    dispatch({ type: listagemActions.CHANGE, data: { ordem: '+mav_descricao' } });
                });
    }

    const modalInativarMarca = (dados) => {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: `Não foi possível excluir a marca ${dados.mav_descricao}. Deseja inativar este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                inativarMarca(dados);
            }
        });
    };

    function inativarMarca(dados) {
        dados.mav_ativa = false;
        api.put(`MarcaVeiculo/Editar`, dados).then(
            res => {
                notification.success({ message: 'Sucesso!', description: 'Marca inativada com sucesso!' })
            }
        ).catch(
            error => {
                console.log(error);
            }
        ).finally(
            () => {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+mav_descricao' } });
            }
        )
    }

    const modalDuplicarDados = (dados) => {
        Modal.confirm({
            title: 'Duplicar?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja duplicar a Marca ${dados.mav_descricao}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                duplicarDados(dados);
            }
        })
    }

    function duplicarDados(dados) {
        let valores = { ...dados };
        valores.mav_id = null;
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: valores } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    }

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+mav_descricao" },
                { label: "Nome Z - A", value: "-mav_descricao" },
                { label: "Código Crescente", value: "+mav_id" },
                { label: "Código Decrescente", value: "-mav_id" }
            ]} />
            <div className="tabela">
                <TabelaDados url="marcaVeiculo/listar" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ mav_id, mav_ativa }) => (
                            <Popover content={mav_ativa ? 'Ativa' : 'Inativa'} placement="right" className="w-100">
                                <Tag color={mav_ativa ? 'processing' : 'default'}>
                                    <b>{mav_id}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Marca de Veículo',
                        render: ({ mav_descricao }) => (
                            <div>
                                <b>{mav_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 95,
                        fixed: 'right',
                        render: (record, dados) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Duplicar icon={<CopyOutlined />} onClick={() => { modalDuplicarDados(dados) }} />
                                    </Col>
                                    <Col>
                                        <Editar
                                            onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} icon={<EditOutlined />}
                                        />
                                    </Col>
                                    <Col>
                                        <Excluir icon={<DeleteOutlined />} onClick={() => { modalExcluir(record) }} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerMarcaVeiculo cadMarcaVeiculo={true} />
        </div>
    )
}