import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Row, Col, Select, Button, Input } from "antd";
import { PrinterOutlined } from "@ant-design/icons";

import api from '../../services/api';
import { useStateValue } from '../../state';
import { listagemActions } from "../../actions";
import { BreadcrumbAbrirChamado, TabelaDados } from "../../components";

export default function Relatorios() {

    const [{ listagem, manutencao }, dispatch] = useStateValue();
    const [grupos, setGrupos] = useState([]);
    const [formulario] = Form.useForm();

    useEffect(() => {
        api.get(`RelatorioJasper/ListarGruposRelatorio`).then(res => {
            if (res.status === 200) {
                setGrupos(res.data);
            }
        }).catch((erro) => console.error(erro));
    }, []);

    function filtroGrupo(val) {
        if (val) {
            dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&MostrarList=true&Sistema=1&CodigoGrupo=${val}` } })
        }
        else {
            dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&MostrarList=true&Sistema=1` } })
        }
    }

    function visualizarRelatorio(dados) {

    };

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '&MostrarList=true&Sistema=1', filtro: '', ordem: '+rel_nome' } }), []);

    return (
        <div className="p-10">
            <BreadcrumbAbrirChamado />
            <Form layout="vertical" form={formulario}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={12} lg={14} xl={14}>
                        <Form.Item label="Pesquisar por" name="pesquisa">
                            <Input.Search placeholder="Pesquisar pelo nome do relatório" onSearch={valor => dispatch({ type: listagemActions.CHANGE, data: { filtro: valor } })} allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                        <Form.Item label="Ordenar por" name="ordem" initialValue={"+rel_nome"}>
                            <Select showSearch options={[
                                { label: "Nome A - Z", value: "+rel_nome" },
                                { label: "Nome Z - A", value: "-rel_nome" },
                                { label: "Código Crescente", value: "+rel_id" },
                                { label: "Código Decrescente", value: "-rel_id" }
                            ]} placeholder="Selecione a Ordenação" value={listagem.ordem} onChange={(val) => { console.log(val); dispatch({ type: listagemActions.CHANGE, data: { ordem: val } }) }} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                        <Form.Item label="Grupo" name="grupo">
                            <Select
                                onChange={(val) => filtroGrupo(val)}
                                placeholder="Selecionar Relatórios por Grupo"
                                allowClear
                                showSearch
                                optionFilterProp="children"
                            >
                                {grupos.map((grupo) => (
                                    <Select.Option value={grupo.grj_id} key={grupo.grj_id}> {grupo.grj_descricao}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="/RelatorioJasper/Listar" colunas={[
                    {
                        title: "Nome Relatório",
                        render: ({ rel_nome }) => (
                            <div>
                                <b>{rel_nome}</b>
                            </div>
                        )
                    },
                    {
                        title: "Descrição do Relatório",
                        render: ({ rel_descricao }) => (
                            <div>
                                <b>{rel_descricao}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 65,
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Link to={{
                                            pathname: "/filtrosRelatorio",
                                            state: { id: record.rel_id, nomeArquivo: record.rel_nomearquivo, nomeRelatorio: record.rel_nome, retorno: "/relatorios" }
                                        }} onClick={() => {
                                            localStorage.setItem("@GestorWeb-FiltrosRelatorio", JSON.stringify({ id: record.rel_id, nomeArquivo: record.rel_nomearquivo, nomeRelatorio: record.rel_nome, retorno: "/relatorios" }))
                                        }}>
                                            <Button onClick={() => { visualizarRelatorio(record) }} icon={<PrinterOutlined />} />
                                        </Link>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                ]} />
            </div>
        </div>
    );

}