import React, { useState, useEffect } from 'react';
import { useStateValue } from '../../../state';
import { Col, Form, Modal, Row, Select } from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';
import { getEmailUsuario, setClienteCatalogo, setNomeClienteCatalogo } from '../../../services/auth';
import SelectPaginacao from '../../selects/selectPaginacao';
import { manutencaoActions, selectPaginadoActions } from '../../../actions';
import api from '../../../services/api';
import { isObjetoDiffVazio } from '../../../services/funcoes';


export default function ModalSelecaoClientesCat({ exibirModal, onCloseModal }) {
    const [formClientes] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [dadosLista, setDadosLista] = useState([]);
    const [abrirModal, setAbrirModal] = useState(false);

    useEffect(() => {
        if (exibirModal) {

            let emailusuario = getEmailUsuario();
            verificarCliente(emailusuario);
        }
    }, [exibirModal]);

    function selecionarCliente() {
        let lista = [...dadosLista];
        let codCliente = formClientes.getFieldValue().codigo;
        let cliente = lista.find(c => c.codigo == codCliente);
        if (!!cliente) {
            setNomeClienteCatalogo(cliente.nome);
        }
        setClienteCatalogo(codCliente);
        fecharModal(cliente.nome);
    }

    function verificarCliente(email) {
        api.get(`Ges040/BuscarClienteCatalogo?email=${email}`).then(
            res => {
                let cli = { ...res.data };
                if (isObjetoDiffVazio(cli)) {
                    setAbrirModal(false);
                    setNomeClienteCatalogo(cli.nome);
                    setClienteCatalogo(cli.codigo);
                    fecharModal(cli.nome);
                } else {
                    setAbrirModal(true);
                }
            }
        ).catch(
            erro => {
                setAbrirModal(true);
                console.log(erro);
            }
        )
    }

    function fecharModal(nome) {
        setAbrirModal(false);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { nomeCliente: nome } } });
        onCloseModal();
    }

    return (

        <Modal centered
            title="Selecionar Cliente"
            visible={abrirModal}
            onOk={() => { formClientes.submit() }}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            closable={false}
            cancelButtonProps={{ hidden: true }}
            destroyOnClose
            maskClosable={false}
            width={700}
        >
            <div>
                <Form layout="vertical" name="selecaoclientes" size="middle" onFinish={selecionarCliente} form={formClientes}>
                    <Row align="middle" gutter={[0, 16]}>
                        <Col span={24}>
                            <Form.Item name="cliente" label="Selecione seu cliente">
                                <SelectPaginacao url="Ges040/ListarCatalogo" allowClear={true} placeholder="Selecione um cliente"
                                    nameLabel="codigo" nameValue="codigo" form={formClientes} name="codigo" setListaDados={setDadosLista}
                                    conteudo={
                                        e => (<Select.Option value={e.codigo} key={e.codigo}>{e.codigo} - {e.nome}</Select.Option>)
                                    } />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>

    );
}
