import React, { useState, useEffect } from 'react';
import { Row, Col, Form, message, Empty, Select, Input, Button, InputNumber, Checkbox, Switch, Slider, notification } from 'antd';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { PlusOutlined, CloseOutlined, EyeOutlined, CloudUploadOutlined } from '@ant-design/icons';
import arrayMove from 'array-move';

import FormGW from '../formulario';
import { ItemFormulario, TiposElementosFormulario } from './pages';
import { ModalPersonalizacaoFormularioFiltros, ModalImportarElementosCamposAdicionais } from "../../components/modals/";

export default function EditorFormularioCampos({ elementosFormulario, setElementosFormulario, tipoEntidade }) {

    const objElementoFormularioEdicao = { indice: null, elemento: null };
    const [formEditor] = Form.useForm();
    const [openModalPersonalizacao, setOpenModalPersonalizacao] = useState(false);
    const [openModalImportarElemento, setOpenModalImportarElemento] = useState(false);
    const [elementoEdicao, setElementoEdicao] = useState(objElementoFormularioEdicao);
    const [optionsSelect, setOptionsSelect] = useState([]);

    useEffect(() => { formEditor.resetFields(); console.log("lista alterada Elementos", elementosFormulario) }, [elementoEdicao]);

    const salvarElemento = values => {
        let somaColunaLinha = values.cap_coluna;
        let verificaNome = false;
        elementosFormulario.map((elemento) => {
            if (elemento.cap_nomeelemento !== values.cap_nomeelemento && elemento.cap_linha === values.cap_linha && elemento.status != 3 &&
                (elemento.key === null || elemento.key !== values.key)) {
                somaColunaLinha += elemento.cap_coluna;
            }
            if (elemento.cap_nomeelemento === values.cap_nomeelemento && elementoEdicao.indice === null && verificaNome === false) {
                notification.warning({ duration: 4, message: 'Aviso', description: 'Nome do Campo já está em uso!' });
                verificaNome = true;
            }
        });
        if (somaColunaLinha <= 24 && verificaNome === false) {
            if (values.cap_tipoelemento === 'selectPaginacao') {
                values.cap_options = JSON.stringify({ url: values.url, nameLabel: values.nameLabel, nameValue: values.nameValue, mostrarCodigoLabel: values.mostrarCodigoLabel, multiple: values.multiple })
                delete (values.url)
                delete (values.nameLabel)
                delete (values.nameValue)
                delete (values.mostrarCodigoLabel)
                delete (values.multiple)
            }
            if (values.cap_tipoelemento === 'dataPicker') {
                values.cap_options = JSON.stringify({ format: values.format })
                delete (values.format)
            }
            if (values.cap_tipoelemento === 'checkbox') {
                values.cap_options = JSON.stringify({ defaultChecked: values.defaultChecked })
                delete (values.defaultChecked)
            }
            if (values.cap_tipoelemento === 'radio-group' || values.cap_tipoelemento === 'checkbox-group') {
                values.cap_options = JSON.stringify(values.cap_options)
            }
            if (values.cap_tipoelemento === 'select') {
                values.cap_options = JSON.stringify({ options: values.cap_options, multiple: false })
            }
            values.cap_tipoentidade = tipoEntidade;
            if (elementoEdicao.indice === null) {
                values.key = elementosFormulario.length;
                values.cap_id = null;
                values.cap_obrigatorio = false;
                values.status = 1;
                setElementosFormulario([...elementosFormulario, values]);
            } else {
                values.status = 2;
                let listaElementos = [...elementosFormulario];
                listaElementos[elementoEdicao.indice] = values;
                setElementosFormulario(listaElementos);
            }
            setElementoEdicao(objElementoFormularioEdicao);
        } else if (verificaNome === false) {
            notification.warning({ duration: 5, message: 'Aviso', description: 'Soma dos Tamanhos das Colunas ultrapassa o valor máximo para linha!' });
            return;
        }
    }

    const tratarErro = errorInfo => {
        errorInfo.errorFields.map((field) => field.errors.map((erro) => message.warning(erro, 2 * errorInfo.errorFields.length)));
    };

    const editarElementoTela = indiceElemento => {
        let elemento = { ...elementosFormulario[indiceElemento] }
        if (elemento.cap_tipoelemento === 'selectPaginacao') {
            elemento.url = JSON.parse(elemento.cap_options).url
            elemento.nameLabel = JSON.parse(elemento.cap_options).nameLabel
            elemento.nameValue = JSON.parse(elemento.cap_options).nameValue
            elemento.mostrarCodigoLabel = JSON.parse(elemento.cap_options).mostrarCodigoLabel
            elemento.multiple = JSON.parse(elemento.cap_options).multiple
            delete (elemento.cap_options);
        }
        if (elemento.cap_tipoelemento === 'dataPicker') {
            elemento.format = JSON.parse(elemento.cap_options).format
            delete (elemento.cap_options)
        }
        if (elemento.cap_tipoelemento === 'checkbox') {
            elemento.defaultChecked = JSON.parse(elemento.cap_options).defaultChecked
            delete (elemento.cap_options)
        }
        if (elemento.cap_tipoelemento === 'radio-group' || elemento.cap_tipoelemento === 'checkbox-group') {
            elemento.cap_options = JSON.parse(elemento.cap_options)
        }
        if (elemento.cap_tipoelemento === 'select') {
            elemento.cap_options = JSON.parse(elemento.cap_options).options
        }
        elemento.key = indiceElemento;
        setElementoEdicao({ indice: indiceElemento, elemento: elemento });
    }

    const excluirElemento = indiceElemento => {
        let elemento = { ...elementosFormulario[indiceElemento] }
        elemento.status = 3
        let listaElementos = [...elementosFormulario];
        listaElementos[indiceElemento] = elemento;
        //listaElementos.splice(indiceElemento, 1);
        setElementosFormulario(listaElementos);
        setElementoEdicao(objElementoFormularioEdicao);
        formEditor.resetFields();
    }

    useEffect(() => {
        
    }, [elementosFormulario]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        let listaElementos = [...elementosFormulario];
        listaElementos = arrayMove(listaElementos, oldIndex, newIndex);
        setElementosFormulario(listaElementos);
        setElementoEdicao(objElementoFormularioEdicao);
        formEditor.resetFields();
    };

    const marks = {
        1: {
            style: {
                color: '#0053a6',
            },
            label: <strong>1</strong>,
        },
        6: '6',
        12: '12',
        18: '18',
        24: {
            style: {
                color: '#0053a6',
            },
            label: <strong>24</strong>,
        },
    };


    useEffect(() => console.log({ optionsSelect }), [optionsSelect]);
    useEffect(() => console.log({ elementoEdicao }), [elementoEdicao]);

    const SortableItem = SortableElement(({ value, sortIndex }) => <ItemFormulario elemento={value} fnEditar={() => editarElementoTela(sortIndex)} fnExcluir={() => excluirElemento(sortIndex)} status={!!value.status ? value.status : 0} />);
    const SortContainer = SortableContainer(({ children }) => {
        return <div>{children}</div>;
    });

    return (
        <div className="hg-100">
            <div className="col-formulario-60">
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Row justify="space-between" gutter={[8, 8]}>
                            <Col xs={24} sm={24} md={24} lg={10} xl={10}>
                                <b>Estrutura do Formulário</b>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                                <Row justify="end" gutter={[8, 8]}>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={10}>
                                        <Button icon={<CloudUploadOutlined />} onClick={() => setOpenModalImportarElemento(true)} block>
                                            Importar Elemento
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={12} xl={10}>
                                        <Button type="primary" icon={<EyeOutlined />} onClick={() => setOpenModalPersonalizacao(true)} block>
                                            Pré-Visualizar
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <div className="col-elementor">
                            <SortContainer onSortEnd={onSortEnd} shouldCancelStart={(e) => (e.target.tagName.toLowerCase() === "path" || e.target.tagName.toLowerCase() === "svg" || e.target.tagName.toLowerCase() === "button")}>
                                {!!elementosFormulario && elementosFormulario.map((elemento, indice) =>
                                    <Row className="m-b-5">
                                        <Col span={24}>
                                            <SortableItem index={indice} sortIndex={indice} value={elemento} key={`${elemento.key}-${indice}`} />
                                        </Col>
                                    </Row>
                                )}
                            </SortContainer>
                        </div>
                        {!!elementosFormulario && elementosFormulario.length === 0 && <Empty />}
                    </Col>
                </Row>
            </div>
            <div className="col-formulario-40">
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Row gutter={[8, 8]}>
                            <Col>
                                <b>Adicionar Elementos ao Formulário</b>
                            </Col>
                        </Row>
                        <FormGW layout="vertical" form={formEditor} initialValues={elementoEdicao.elemento} onFinish={salvarElemento} onFinishFailed={tratarErro}>
                            <Row gutter={[8, 0]}>
                                <Form.Item name="key" initialValue={null} hidden />
                                <Form.Item name="cap_id" initialValue={null} hidden />
                                <Col xs={24} sm={24} md={24} lg={8} xl={8}>
                                    <Form.Item label="Número da Linha" name="cap_linha" rules={[{ required: true, message: 'Informe o número da Linha para o elemento!' }]}>
                                        <InputNumber placeholder="Linha do Elemento" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={14} xl={14}>
                                    <Form.Item label="Tamanho da Coluna" name="cap_coluna" initialValue={6} rules={[{ required: true, message: 'Informe o tamanho da coluna para o elemento!' }]} >
                                        <Slider marks={marks} min={1} max={24} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item label="Nome do Atributo" name="cap_nomeelemento" rules={[{ required: true, message: 'Informe o nome do atributo para o elemento!' }]} normalize={(value) => {
                                        return !!value.match(/[a-zA-Z0-9_.]/gm) ? value.match(/[a-zA-Z0-9_.]/gm).join('') : '';
                                    }}>
                                        <Input placeholder="Informe o Nome do Atributo..." />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                    <Form.Item label="Label" name="cap_label" rules={[{ required: true, message: 'Informe o label/descrição do elemento no formulário!' }]}>
                                        <Input placeholder="Informe a Label do Elemento" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Texto de ajuda" name="cap_placeholder" initialValue="">
                                        <Input placeholder="Informe um Texto de ajuda..." />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Tipo de Elemento" name="cap_tipoelemento" rules={[{ required: true, message: 'Selecione o Tipo do Elemento!' }]}>
                                        <Select placeholder="Selecione o tipo de elemento..." showSearch optionFilterProp="children" >
                                            {TiposElementosFormulario.map((tipoEl, key, array) => (
                                                <Select.Option key={key} value={tipoEl.value}> {tipoEl.label}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.cap_tipoelemento !== currentValues.cap_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('cap_tipoelemento') === 'select') ? (
                                                <Form.Item name="cap_options" label="Opções" rules={[{ required: true }]} extra={
                                                    <Row>
                                                        <Col span={24}>
                                                            Informe o texto da opção e pressione <b>[ENTER]</b>
                                                        </Col>
                                                        <Col span={24}>
                                                            Para retornar valor informe <b>Valor "-" Descrição</b>
                                                        </Col>
                                                    </Row>
                                                }>
                                                    <Select mode="tags" placeholder="Informe as opções do elemento" />
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.cap_tipoelemento !== currentValues.cap_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('cap_tipoelemento') === 'radio-group' || getFieldValue('cap_tipoelemento') === 'checkbox-group') ? (
                                                <Form.Item name="cap_options" label="Opções" rules={[{ required: true }]} extra="Informe o texto da opção e pressione <ENTER>">
                                                    <Select mode="tags" placeholder="Informe as opções do elemento" />
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.cap_tipoelemento !== currentValues.cap_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('cap_tipoelemento') === 'selectPaginacao') ? (
                                                <Form.Item name="url" label="URL p/ carregar a lista:" rules={[{ required: true }]} extra="Informe a URL para carregar a Lista.">
                                                    <Input placeholder="Informe a url para carregar a Lista..." />
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.cap_tipoelemento !== currentValues.cap_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('cap_tipoelemento') === 'selectPaginacao') ? (
                                                <Form.Item name="nameLabel" label="Campo Label p/ Lista" rules={[{ required: true, message: 'Informe o campo Label para Lista!' }]} >
                                                    <Input placeholder="Informe o campo label..." />
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.cap_tipoelemento !== currentValues.cap_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('cap_tipoelemento') === 'selectPaginacao') ? (
                                                <Form.Item name="nameValue" label="Campo Value p/ Lista" rules={[{ required: true, message: 'Informe o campo Value para Lista!' }]}>
                                                    <Input placeholder="Informe o campo Value..." />
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.cap_tipoelemento !== currentValues.cap_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('cap_tipoelemento') === 'selectPaginacao') ? (
                                                <Form.Item name="mostrarCodigoLabel" valuePropName="checked" initialValue={true} >
                                                    <Checkbox>Mostrar Codigo na Label?</Checkbox>
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.cap_tipoelemento !== currentValues.cap_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('cap_tipoelemento') === 'selectPaginacao') ? (
                                                <Form.Item name="multiple" valuePropName="checked" initialValue={false} >
                                                    <Checkbox>Permitir Multi-seleção?</Checkbox>
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.cap_tipoelemento !== currentValues.cap_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('cap_tipoelemento') === 'dataPicker') ? (
                                                <Form.Item name="format" label="Formato da Data:" rules={[{ required: true }]} message="Informe o formato da Data!" initialValue="DD/MM/YYYY">
                                                    <Input placeholder="Informe o formato da Data..." />
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.cap_tipoelemento !== currentValues.cap_tipoelemento}>
                                        {({ getFieldValue }) => {
                                            let elemento = getFieldValue('cap_tipoelemento');
                                            //if (elemento === 'input') return <Form.Item name="initialValue" label="Valor Inicial"><Input /></Form.Item>;
                                            if (elemento === 'textarea') return <Form.Item name="initialValue" label="Valor Inicial"><Input.TextArea /></Form.Item>;
                                            if (elemento === 'inputNumber') return <Form.Item name="initialValue" label="Valor Inicial"><InputNumber /></Form.Item>;
                                            if (elemento === 'checkbox') return <Form.Item name="defaultChecked" label="Valor Inicial" valuePropName="checked"><Checkbox /></Form.Item>;
                                            if (elemento === 'switch') return <Form.Item name="initialValue" label="Valor Inicial" valuePropName="checked"><Switch /></Form.Item>;
                                            else return null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.cap_obrigatorio !== currentValues.cap_obrigatorio} >
                                        {({ getFieldValue }) => {
                                            return (getFieldValue('cap_obrigatorio') === true) ? (
                                                <Form.Item label="Mensagem Obrigatório:" name="cap_mensagemobrigatorio" rules={[{ required: true, message: 'Informe uma mensagem referente a obrigação da informação!' }]}>
                                                    <Input placeholder="Informe uma Mensagem Obrigatório..." />
                                                </Form.Item>
                                            ) : null;
                                        }}
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item label="Obrigatório" name="cap_obrigatorio" valuePropName="checked" initialValue={false}>
                                        <Switch>Obrigatório</Switch>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row justify="center" gutter={[8, 0]} className="m-t-8">
                                <Col>
                                    <Button onClick={() => { formEditor.submit() }} size="middle" type="primary" icon={<PlusOutlined />}>
                                        {elementoEdicao.indice === null ? "Adicionar Elemento" : "Alterar Elemento"}
                                    </Button>
                                </Col>
                                <Col>
                                    {elementoEdicao.indice !== null &&
                                        <Button size="middle" icon={<CloseOutlined />} onClick={() => setElementoEdicao(objElementoFormularioEdicao)} htmlType="button">
                                            Cancelar
                                        </Button>
                                    }
                                </Col>
                            </Row>
                        </FormGW>
                    </Col>
                </Row>
            </div>
            <ModalPersonalizacaoFormularioFiltros exibirModalPersonalizacao={openModalPersonalizacao} fecharModalPersonalizacao={() => setOpenModalPersonalizacao(false)} elementosFormulario={elementosFormulario} setElementosFormulario={setElementosFormulario} />
            <ModalImportarElementosCamposAdicionais exibirModalImportarElementos={openModalImportarElemento} fecharModalImportarElementos={() => setOpenModalImportarElemento(false)} elementosFormulario={elementosFormulario} setElementosFormulario={setElementosFormulario} />
        </div>
    );

}