import React, { useEffect, useState } from "react";
import { Input, Row, Col, Form, Button, Modal, Table, Tag, Select } from 'antd';
import { ExclamationCircleOutlined, ImportOutlined } from "@ant-design/icons";

import api from "../../../services/api";

export default function ModalImportarElementosCamposAdicionais({ exibirModalImportarElementos, fecharModalImportarElementos, elementosFormulario, setElementosFormulario }) {
    const [listaElementosSelecionados, setListaElementosSelecionados] = useState([]);
    const [listaParametros, setListaParametros] = useState([]);
    const [filtros, setFiltros] = useState("");
    const [entidade, setEntidade] = useState();
    const [form] = Form.useForm();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a Importação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalImportarElementos();
            }
        });
    };

    const entidades = [
        { label: "Cliente", value: 1 },
        { label: "Fornecedor", value: 2 },
        { label: "Funcionario", value: 3 },
        { label: "Produto", value: 4 },
        { label: "Trasportador", value: 5 },
        { label: "Vendedor", value: 6 }
    ]

    function listarParametros() {
        // let parametros = `${!!filtros ? '&filtro=' + filtros : ""}${!!entidade?'&TipoEntidade='+entidade:'&ordem=+cap_ordem'}`;
        // api.get(`CamposPersonalizados/ListarTodos?PageNumber=1&PageSize=1000&Order=+cap_ordem${parametros}`).then(res => {
        let parametros = `${!!filtros?'&filtro='+filtros:""}${!!entidade?'&TipoEntidade='+entidade:'&ordem=+cap_ordem'}`;
        api.get(`CamposPersonalizados/ListarTodos?pagina=1&PageSize=1000${parametros}`).then(res => {
            if (res.status === 200) {
                let listaParametros = res.data.items
                let listaParametrosAtz = [];
                listaParametros.map((par) => {
                    par.checked = false;
                    listaParametrosAtz = [...listaParametrosAtz, par];
                })
                setListaParametros({ dados: listaParametrosAtz, total: res.data.total });
            }
        }).catch(
            (erro) => {
                console.log(erro);
                alert('Erro ao carregar Campos personalizados!');
            }
        )
    }

    function selecionaElementos(indice, record, checked) {
        console.log(indice, record, checked)
        if (checked) {
            setListaElementosSelecionados([...listaElementosSelecionados, record])
            let listaParametrosTemp = [...listaParametros.dados]
            listaParametrosTemp[indice].checked = true
            setListaParametros({ dados: listaParametrosTemp, total: listaParametros.total })
        } else {
            let listaElementos = [...listaElementosSelecionados];
            let idx = listaElementos.indexOf(record);
            listaElementos.splice(idx, 1);
            setListaElementosSelecionados(listaElementos);
            let listaParametrosTemp = [...listaParametros.dados]
            listaParametrosTemp[indice].checked = false
            setListaParametros({ dados: listaParametrosTemp, total: listaParametros.total })
        }

    }

    function importarElementos() {
        let listaParametrosAtz = [];
        listaElementosSelecionados.map((par) => {
            delete (par.checked);
            delete (par.rel_id);
            delete (par.cap_id);
            listaParametrosAtz = [...listaParametrosAtz, par];
        })
        setElementosFormulario([...elementosFormulario, ...listaParametrosAtz]);
        fecharModalImportarElementos();
    }

    useEffect(() => listarParametros(), [entidade, filtros]);
    useEffect(() => setListaElementosSelecionados([]), []);

    useEffect(() => {
        if (!!exibirModalImportarElementos) {
            listarParametros()
        }
    }, [exibirModalImportarElementos]);

    return (
        <Modal centered
            title="Importar Elementos de Outros Formulários"
            visible={exibirModalImportarElementos}
            maskClosable={false}
            width={720}
            onCancel={() => {
                onCloseModal();
            }} footer={null}>
            <div className="col-importarElementos">
                <Form layout="vertical" form={form}>
                    <Row>
                        <Col span={24}>
                            <Form.Item label={"Pesquisar por"}>
                                <Input.Search placeholder={"Elemento, label e tipo de Elemento"} onSearch={valor => setFiltros(valor)} allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Filtro de Entidade">
                                <Select
                                    allowClear={true}
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Filtrar Entidades"
                                    onChange={(valor) => { setEntidade(valor); }}
                                    options={entidades}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div className="tabela mt-dif">
                    <Table
                        dataSource={listaParametros?.dados}
                        columns={[
                            {
                                title: 'Nome Elemento',
                                Key: "cap_nomeelemento",
                                editable: true,
                                render: ({ cap_nomeelemento }) => (
                                    <div>
                                        <b>{cap_nomeelemento}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Label',
                                Key: "cap_label",
                                editable: true,
                                render: ({ cap_label }) => (
                                    <div>
                                        <b>{cap_label}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'TipoElemento',
                                Key: "cap_tipoelemento",
                                editable: true,
                                render: ({ cap_tipoelemento }) => (
                                    <div>
                                        <b>{cap_tipoelemento}</b>
                                    </div>
                                ),
                            },
                            {
                                title: '',
                                align: 'center',
                                fixed: 'right',
                                width: 80,
                                render: (value, record, indice) => (
                                    <Tag.CheckableTag
                                        key="checked"
                                        checked={record.checked}
                                        onChange={(checked) => selecionaElementos(indice, record, checked)}
                                    >
                                        Selecionar
                                    </Tag.CheckableTag>
                                ),
                            }
                        ]} scroll={{ y: 350 }}
                    />
                </div>
                <Row justify="center" className="m-t-16">
                    <Col>
                        <Button size="middle" onClick={() => importarElementos()} htmlType="submit" type="primary" icon={<ImportOutlined />}>Importar Elementos </Button>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}