import React, { useState } from "react";
import { Row, Col, Typography, Divider, Button, Tag, Table, Modal } from "antd";
import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import { FormatNumber } from "../../ValueObjects";

export default function TabNotasFiscais({ listaNotaDevolucao, retornaDescricaoStatus, adicionarNotas }) {

    function removeNotaFiscal(ntf_id){
        Modal.confirm({
            title: 'Atenção!',
            icon: <ExclamationCircleOutlined />,
            content: 'Remover a Nota Fiscal Selecionada?',
            okText: 'Sim, quero remover',
            cancelText: 'Não',
            centered: true,
            onOk() {
                let lista = [...listaNotaDevolucao.listaNotaDevolucao];
                let indice = lista.findIndex(nota => nota.ntf_id === ntf_id);
                lista.splice(indice, 1);
                listaNotaDevolucao.setListaNotaDevolucao(lista);
            }
        });
    };

    return (
        <div>
            <Row gutter={[8, 16]}>
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-boleto.png").default} alt="Notas Fiscais Referenciadas" /> Notas Fiscais Referenciadas
                    </Typography.Title>
                    <Divider orientation="left" plain>Referencie todas as notas que deseja devolver para o mesmo destinatário aqui! </Divider>
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => { adicionarNotas.setAdicionarNotas(true) }} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div className="tabela">
                        <Table dataSource={listaNotaDevolucao.listaNotaDevolucao} columns={[
                            {
                                title: 'Nº Nota',
                                align: 'center',
                                render: ({ ntf_status, ntf_numero }) => (
                                    <div>
                                        <Row justify="center">
                                            <Col>
                                                <Tag color={((ntf_status === 0) ? 'default' : (ntf_status === 1 ? 'green' : (ntf_status === 2 ? 'gold' : (ntf_status === 3 ? 'orange' : (ntf_status === 4 ? 'red' : (ntf_status === 5 ? 'lime' : ''))))))}>
                                                    <b>
                                                        {retornaDescricaoStatus(ntf_status)}
                                                    </b>
                                                </Tag>
                                            </Col>
                                        </Row>
                                        <Row justify="center">
                                            <Col>
                                                <b>{ntf_numero}</b>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            }, {
                                title: 'Outras Informações',
                                render: ({ tip_descricao, ser_serie, ntf_dataemissao, ntf_finalidadeNfe, ntf_chavenfe, pes_nome }) => (
                                    <div>
                                        <Row align="middle" gutter={[8, 0]}>
                                            <Col>
                                                <b>{pes_nome}</b>
                                            </Col>
                                        </Row>
                                        <Row align="middle" gutter={[8, 0]}>
                                            <Col>
                                                Série: <b>{(!!ser_serie > 0 ? ser_serie : '')}</b>
                                            </Col>
                                            <Col>
                                                Emissão: <b>{moment(ntf_dataemissao).format('DD/MM/YYYY')}</b>
                                            </Col>
                                            <Col>
                                                Nota de: <b>{tip_descricao}</b>
                                            </Col>
                                            <Col>
                                                Finalidade: <b>{ntf_finalidadeNfe}</b>
                                            </Col>
                                            {!(!!ntf_chavenfe) && <Col>
                                                <b>NF-e não gerada</b>
                                            </Col>}
                                            {!!ntf_chavenfe && <Col>
                                                NF-e: <b>{ntf_chavenfe}</b>
                                            </Col>}
                                        </Row>
                                    </div>
                                ),
                            },
                            {
                                title: 'Total (R$)',
                                align: 'right',
                                width: 220,
                                render: ({ totalNfe }) => (
                                    <div>
                                        <b className="f-18">{FormatNumber(totalNfe, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                key: 'x',
                                align: 'center',
                                fixed: 'right',
                                width: 65,
                                render: ({ntf_id}) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button icon={<DeleteOutlined />} onClick={() => {removeNotaFiscal(ntf_id)}}/>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]} />
                    </div>
                </Col>
            </Row>
        </div>
    );
}