import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Divider, Button, Tag, Form, Table } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { useStateValue } from "../../state";
import { manutencaoActions } from "../../actions";
import { ModalVeiculo } from "../../components/modals/";

export default function Veiculos() {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [openModalVeiculo, setOpenModalVeiculo] = useState(false);
    const [dadosVeiculos, setDadosVeiculos] = useState(!!manutencao.dados && !!manutencao.dados.veiculos ? manutencao.dados.veiculos : []);
    const [form] = Form.useForm();
    const tagsAtivo = ['Ativo'];

    const editaTelefone = (record, indice) => {
        record.indice = indice;
        form.setFieldsValue(record);
        setOpenModalVeiculo(true);
    };

    const excluir = (record) => {
        let dadosTemp = [...dadosVeiculos];
        dadosTemp = dadosTemp.filter((dados) => dados !== record);
        setDadosVeiculos(dadosTemp);
    };

    const alterarPropriedadeVeiculo = (key, coluna, checked) => {
        const newData = [...dadosVeiculos];
        newData[key][coluna] = checked;
        setDadosVeiculos(newData);
    };

    useEffect(() => {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ veiculos: dadosVeiculos } } } });
    }, [dadosVeiculos]);

    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-car.png").default} alt="Veículo(s)" /> Veículo(s) - (OPCIONAL)
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Adicione o(s) veículo(s) do transportador aqui.
                    </Divider>
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => setOpenModalVeiculo(true)} />
                    </div>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Table columns={
                    [
                        {
                            title: 'Veículo',
                            render: ({ trv_placa, trv_uf, trv_marca, trv_modelo }) => (
                                <div>
                                    <b>{trv_placa}-{trv_uf} {trv_marca} {trv_modelo}</b>
                                </div>
                            ),
                        },
                        {
                            title: '',
                            width: 75,
                            align: 'center',
                            render: (record, obj, indice) => (
                                tagsAtivo.map(tag => (
                                    <Tag.CheckableTag
                                        key="trv_ativo"
                                        checked={record.trv_ativo}
                                        onChange={(checked) => alterarPropriedadeVeiculo(indice, "trv_ativo", checked)}
                                    >
                                        {tag}
                                    </Tag.CheckableTag>
                                ))
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            align: 'center',
                            key: 'x',
                            width: 65,
                            render: (record, obj, indice) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button onClick={() => { editaTelefone(record, indice) }} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Button onClick={() => { excluir(record) }} icon={<DeleteOutlined />} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Ainda não possui Outro Contato Cadastrado
                                </Divider>
                            </Col>
                        </Row>
                    )
                }} dataSource={dadosVeiculos} />
            </div>
            <ModalVeiculo form={form} exibirModalVeiculo={openModalVeiculo} fecharModalVeiculo={() => setOpenModalVeiculo(false)} listaVeiculos={{ dadosVeiculos, setDadosVeiculos }} />
        </div>

    );

}