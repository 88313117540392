import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, Radio, Modal } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined} from "@ant-design/icons";

import api from "../../../services/api";
import { info } from "../../../services/funcoes";

export default function ModalContaGerencial({ formContaGerencial, formulario, exibirModalContaGerencial, fecharModalContaGerencial, listaConfigContaGerencial, setListaConfigContaGerencial }) {

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalContaGerencial();
            }
        });
    };

    const [listaContaGerencial, setListaContaGerencial] = useState([]);
    const [listaContaGerencialAgrupador, setListaContaGerencialAgrupador] = useState([]);

    const listaTipo = [
        { 'value': 1, 'label': 'Juros Recebidos' },
        { 'value': 2, 'label': 'Juros Pagos' },
        { 'value': 3, 'label': 'Descontos Obtidos' },
        { 'value': 4, 'label': 'Descontos Concedidos' },
        { 'value': 5, 'label': 'Multas Pagas' },
        { 'value': 6, 'label': 'Multas Recebidas' }
    ];

    useEffect(() => {
        api.get(`ContaGerencial/ListarAtivosEstruturadoPelaCategoria?filtro=&PageNumber=1&PageSize=1000`).then(res => {
            setListaContaGerencialAgrupador(res.data.filter((item) => { return item.podeSelecionar === false && item.nivel === 0}));
            setListaContaGerencial(res.data.filter((item) => { return item.podeSelecionar === true }));
        })
    }, []);

    function adicionarConfig(values) {
        let listaTemp = [...listaConfigContaGerencial];

        if (listaTemp.filter(item => { return item.cgc_tipo === values.cgc_tipo }).length > 0) {
            info('Operação não permitida! Registro já existente para o tipo de valor selecionado!');
            return;
        }



        if (!!values.key) {
            let index = listaTemp.findIndex(i => i.key === values.key);
            listaTemp.splice(index, 1);
        } else {
            values.key = listaTemp.length + 1;
        }
        values.cgc_descricaoTipo = listaTipo.filter(item => { return item.value === values.cgc_tipo })[0].label;
        values.cgc_descricaocontadestino = listaContaGerencial.filter(item => { return item.ctg_id === values.cgc_contagerencialdestino })[0].ctg_descricao;
        values.ctg_id = formContaGerencial.getFieldValue()?.ctg_id;

        listaTemp.push(values);
        setListaConfigContaGerencial(listaTemp);
        fecharModalContaGerencial();
    };

    return (
        <Modal centered
            title="Cadastro das Configurações das Contas Gerenciais"
            visible={exibirModalContaGerencial}
            onCancel={onCloseModal}
            onOk={() => { formulario.submit() }}
            okText={
                <>
                    <PlusOutlined /> Adicionar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            destroyOnClose        
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="configuracaoContaGerencial" form={formulario} onFinish={adicionarConfig} initialValues={{ remember: true }}>
                    <Row align="middle" gutter={[8, 0]}>
                        <Col span={24}>
                            <Form.Item name="key" hidden />
                            <Form.Item label="Selecione a Conta Gerencial" name="cgc_contagerencialdestino" rules={[{ required: true, message: 'Selecione a Conta Gerencial' }]}>
                                <Select showSearch allowClear optionFilterProp="children" placeholder="Selecione uma Conta Gerencial...">
                                    {listaContaGerencialAgrupador.map(item => (
                                        <Select.OptGroup label={item.ctg_descricao} >
                                            {listaContaGerencial.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                                <Select.Option key={conta.ctg_id} value={conta.ctg_id}>{conta.ctg_descricao}</Select.Option>
                                            ))}
                                        </Select.OptGroup>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Tipo de Valor" name="cgc_tipo" rules={[{ required: true, message: 'Informe o Tipo de Valor' }]}>
                                <Radio.Group>
                                    <Row gutter={[8,0]}>
                                        {listaTipo.map(item => (
                                            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
                                                <Radio value={item.value}>
                                                    {item.label}
                                                </Radio>
                                            </Col>
                                        ))}
                                    </Row>                                    
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}