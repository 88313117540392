import React, { useEffect } from "react";
import { Row, Col, Tag, Modal, notification } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import DrawerGradeProduto from './drawer';
import { useStateValue } from '../../state';
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, Editar, Excluir } from "../../components";

export default function GradeProduto() {

    const [{ manutencao }, dispatch] = useStateValue();

    useEffect(() => dispatch({ type: listagemActions.CHANGE, data: { ordem: '+mgp_descricao' } }), []);

    function excluirRegistro(dados) {
        Modal.confirm({
            title: 'Atenção!',
            icon: <ExclamationCircleOutlined />,
            content: `Remover a modalidade de grade ${dados.mgp_descricao}?`,
            okText: 'Sim, quero remover',
            cancelText: 'Não',
            centered: true,
            onOk() {
                api.delete(`ModalidadeGrade/Excluir?IdModalidade=${dados.mgp_id}`).then(res => {
                    if (res.status === 200) {
                        notification.success({ message: `Sucesso!`, description: `Modalidade Grade excluida com sucesso!` });
                        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+mgp_descricao' } });
                    } else {
                        error('É provável que esta modalidade de grade possua um ou mais itens relacionados a algum produto cadastrado no sistema.', 'Não foi possível excluir esta modalidade de grade!');
                    }
                }, erro => {
                    error('É provável que esta modalidade de grade possua um ou mais itens relacionados a algum produto cadastrado no sistema.', 'Não foi possível excluir esta modalidade de grade!');
                });
            }
        });
    };

    function error(mensagem, title = 'Atenção!') {
        Modal.error({
            title: title,
            content: (
                <div>
                    <p>{mensagem}</p>
                </div>
            )
        });
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <PesquisaOrdenacao opcoesOrdenacao={[
                { label: "Nome A - Z", value: "+mgp_descricao" },
                { label: "Nome Z - A", value: "-mgp_descricao" },
                { label: "Código Crescente", value: "+mgp_id" },
                { label: "Código Decrescente", value: "-mgp_id" }
            ]} />
            <div className="tabela">
                <TabelaDados url="modalidadeGrade/listarModalidade" colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ mgp_id }) => (
                                <div>
                                    <Tag color="processing" className="w-100">
                                        <b>{mgp_id}</b>
                                    </Tag>
                                </div>
                            ),
                        },
                        {
                            title: 'Modalidade de Grades',
                            render: ({ mgp_descricao }) => (
                                <div>
                                    <b>{mgp_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            fixed: 'right',
                            align: 'center',
                            width: 65,
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Editar onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Excluir icon={<DeleteOutlined />} onClick={() => { excluirRegistro(record) }} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } />
            </div>
            <DrawerGradeProduto />
        </div>
    );

}