import React, { useEffect, useState } from 'react';
import { Button, Col, Drawer, Row, Table, Tooltip } from 'antd';
import { CloseOutlined, InfoCircleOutlined, LeftCircleOutlined, PictureOutlined, RightCircleOutlined } from '@ant-design/icons';


import ConsultaPecas from './consultaPecas';
import { useStateValue } from '../../state';
import api from '../../services/api';
import { isObjetoDiffVazio } from '../../services/funcoes';


export default function DrawerConsulta({ exibirDrawer, onCloseDrawer, catalogoExterno}) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [grupoAtual, setGrupoAtual] = useState({});
    const [disabledAnt, setDisabledAnt] = useState(false);
    const [disabledPro, setDisabledPro] = useState(false);
    const [exibir, setExibir] = useState(false);

    useEffect(() => {
        if (!!manutencao.dados && exibirDrawer) {
            let dados = { ...manutencao.dados };
            //setGrupoAtual(dados);
            buscarGrupo(dados.codgrupo, dados.codcatalogo, dados.codpeca);
        }
    }, [manutencao.dados]);

    useEffect(() => {
        if (isObjetoDiffVazio(grupoAtual)) {
            validarGrupo();
        }
    }, [grupoAtual]);

    const onClose = () => {
        onCloseDrawer();
        setExibir(false);
    };

    function onProximo() {
        let codProximo = grupoAtual.proximo;
        let codCatalogo = grupoAtual.codcatalogo;
        buscarGrupo(codProximo, codCatalogo);
    }

    function onAnterior() {
        let codAnterior = grupoAtual.anterior;
        let codCatalogo = grupoAtual.codcatalogo;
        buscarGrupo(codAnterior, codCatalogo);
    }

    function validarGrupo() {
        if (!!!grupoAtual.proximo) {
            setDisabledPro(true);
        } else {
            setDisabledAnt(false);
        }
        if (!!!grupoAtual.anterior) {
            setDisabledAnt(true);
        } else {
            setDisabledAnt(false);
        }
    }

    function buscarGrupo(codGrupo, codCatalogo, codPeca) {
        api.get(`Cat004/BuscarGrupoAntProx?codGrupo=${codGrupo}&codCatalogo=${codCatalogo}`).then(
            res => {
                let grupos = { ...res.data };
                if (!!codPeca) {
                    grupos.codpeca = codPeca;
                }
                setGrupoAtual(grupos);
                setExibir(true)
            }
        ).catch(ex => console.log(ex));
    }

    return (
        <Drawer title={<b>Catálogo: {grupoAtual?.desccatalogo} </b>} id="GruposCatalogo"
            width="90%"
            visible={exibir}
            onClose={onClose}
            extra={<Button onClick={onClose} icon={<CloseOutlined />} size="small" htmlType="button" className="m-r-8">
                Voltar
            </Button>
            }
            footer={
                <Row gutter={[8, 0]}>
                    <Col>
                        <Button type="primary" disabled={disabledAnt} onClick={onAnterior}>
                            <LeftCircleOutlined /> Grupo Anterior
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" disabled={disabledPro} onClick={onProximo}>
                            Próximo Grupo <RightCircleOutlined />
                        </Button>
                    </Col>
                </Row>
            }
        >
            <ConsultaPecas grupoAtual={grupoAtual} catalogoExterno={!!catalogoExterno ? true : false}/>
        </Drawer>
    );
};
