import React, { useEffect, useState } from "react";
import { Form, Row, Col, Tabs, Input, Select, Checkbox, Modal, notification } from "antd";
import { ConsoleSqlOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { Data, FormGW, InputCpfCnpj } from "../../components";
import { useStateValue } from "../../state";
import { consultaCNPJ, HasValue, isObjetoDiffVazio, validaForm, retornaSoNumeros } from "../../services/funcoes";
import { validarIE } from "../../services/funcoesIE";
import { TabFilial, TabEndereco, TabOutrasInformacoes, TabSenhaGerencial } from "./tabPage";
import { drawerActions, manutencaoActions, listagemActions } from "../../actions";

export default function ManutencaoMatrizFilial({ formulario, carregando, aoSalvar, fechar }) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [tipoContribuinte, setTipoContribuinte] = useState([]);
    const [regimeTributarioFiscal, setRegimeTributarioFiscal] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);
    const [desabilitaCNPJ, setDesabilitaCNPJ] = useState(false);

    const salvarManutencao = () => {
        if (validaForm(formulario, listaValidacoes)) {
            carregando(true);
            verificaMatriz();
        } else {
            return false;
        }
    };

    useEffect(() => {
        if (!!manutencao.dados) {
            formulario.setFieldsValue(manutencao.dados);
            if (!!manutencao.dados?.loc_cnpj?.numero && !!manutencao.dados.loc_id) {
                if (!!manutencao.dados.loc_inscricaoestadual.numeroInscricaoEstadual) {
                    formulario.setFieldsValue({ loc_inscricaoestadual: manutencao.dados.loc_inscricaoestadual.numeroInscricaoEstadual })
                } else {
                    formulario.setFieldsValue({ loc_inscricaoestadual: '' })
                }
                if (!!manutencao.dados.loc_cnpj.numero) {
                    formulario.setFieldsValue({ loc_cnpj: manutencao.dados.loc_cnpj.numero })
                }
                if (!!manutencao.dados.loc_cpfresponsavel && manutencao.dados.loc_cpfresponsavel.numero) {
                    formulario.setFieldsValue({ loc_cpfresponsavel: manutencao.dados.loc_cpfresponsavel.numero })
                } else {
                    formulario.setFieldsValue({ loc_cpfresponsavel: '' })
                }
                formulario.setFieldsValue({ loc_email: manutencao.dados.loc_email.endereco })
                formulario.setFieldsValue({ log_logradouro: manutencao.dados.logradouroCidade.logradouro.log_logradouro })
                formulario.setFieldsValue({ log_id: manutencao.dados.logradouroCidade.log_id })
                formulario.setFieldsValue({ cid_id: manutencao.dados.logradouroCidade.cidade.cid_id })
                formulario.setFieldsValue({ est_id: manutencao.dados.logradouroCidade.cidade.estado.est_id })
                formulario.setFieldsValue({ est_sigla: manutencao.dados.logradouroCidade.cidade.estado.est_sigla })
                formulario.setFieldsValue({ cid_descricao: manutencao.dados.logradouroCidade.cidade.cid_descricao })
                formulario.setFieldsValue({ bai_nome: manutencao.dados.logradouroCidade.bairro.bai_nome })
                formulario.setFieldsValue({ bai_id: manutencao.dados.logradouroCidade.bairro.bai_id })
                formulario.setFieldsValue({ cep_cep: manutencao.dados.logradouroCidade.cep.cep_cep })
                formulario.setFieldsValue({ cep_id: manutencao.dados.logradouroCidade.cep.cep_id })
                setDesabilitaCNPJ(true);
                if (!!manutencao.dados.loc_logo) setImageUrl("data:image/jpeg;base64," + manutencao.dados.loc_logo);
                else setImageUrl(null);
            }
            verificaCep(manutencao.dados.logradouroCidade.cep.cep_cep);
        } else {
            formulario.setFieldsValue({ loc_ativo: true })
            setDesabilitaCNPJ(false);
        }

        api.get(`dadosContribuinte/Buscar`).then(
            (res) => {
                if (res.status === 200) {
                    formulario.setFieldsValue({ emp_regimetributario: res.data.emp_regimetributario })
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    }, [manutencao.dados, ui.showDrawer])

    function verificaMatriz() {
        if (formulario.getFieldValue().loc_matriz) {
            api.get(`Local/BuscarLocalCnpj/${formulario.getFieldValue().loc_cnpj}`).then(retornoFilial => {
                if (retornoFilial.data.length > 0) {
                    for (var i = 0; i < retornoFilial.data.length; i++) {
                        if (retornoFilial.data[i].loc_cnpj.numero !== formulario.getFieldValue().loc_cnpj) {
                            modalAlterarMatriz();
                        } else {
                            salvarFilial();
                        }
                    }
                } else {
                    salvarFilial(true);
                }
            }).catch((erro) => console.error(erro));
        } else {
            salvarFilial();
        }
    };

    const modalAlterarMatriz = () => {
        Modal.confirm({
            title: 'Alterar Matriz',
            icon: <ExclamationCircleOutlined />,
            content: 'Esta empresa já possui um local definido como Matriz, deseja alterar para este local?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                salvarFilial(true);
            },
            onCancel() {
                salvarFilial();
            }
        });
    };

    function salvarFilial(matriz = false) {
        let values = { ...formulario.getFieldValue() };
        values.loc_inscricaoestadual = !!values.loc_inscricaoestadual ? values.loc_inscricaoestadual : '';
        values.loc_inscricaoestadual_ufestado = !!values.est_sigla ? values.est_sigla : '';
        if (!!values.loc_inscricaoestadual && !validarIE(values.loc_inscricaoestadual, values.loc_inscricaoestadual_ufestado)) {
            notification.warning({ message: 'Aviso', description: 'Inscrição Estadual informada é inválida' });
            return false;
        }
        values.alteraMatriz = matriz;
        var logo = (imageUrl == null ? "" : imageUrl);
        if (!!logo) {
            values.loc_logo = (logo.split(",", 2)[1]);
        } else {
            values.loc_logo = "";
        }
        if (!(!!values.loc_centrodistribuicao)) {
            values.loc_centrodistribuicao = false;
        }

        if (!!values.loc_senhagerencial) {
            if (values.novaSenhaGerencial === '') {
                delete values.novaSenhaGerencial;
            }
            if (values.senhaGerencialAtual === '') {
                delete values.senhaGerencialAtual;
            }
        }

        let enderecoUrl = 'local/';
        if (values.loc_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "Os dados do Local foram salvos com sucesso!" });
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    atualizarPagina();
                    carregando(false);
                    aoSalvar();
                }
            );
        } else {
            enderecoUrl += "Incluir"
            api.post(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "Os dados do Local foram salvos com sucesso!" });
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    atualizarPagina();
                    carregando(false);
                    aoSalvar();
                }
            );
        };
    };
    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: 'loc_descricao' } });
    };

    function error(mensagem, title = 'Atenção!') {
        Modal.error({
            title: title,
            content: (
                <div>
                    <p>{mensagem}</p>
                </div>
            )
        });
    }

    const listaValidacoes = [
        { nome: 'loc_cnpj', obrigatorio: true, label: 'CNPJ' },
        { nome: 'loc_tipocontribuinte', obrigatorio: true, label: 'Tipo de Contribuinte' },
        { nome: 'emp_regimetributario', obrigatorio: true, label: 'Regime Tributário Fiscal' },
        { nome: 'loc_descricao', obrigatorio: true, label: 'Nome da Filial' },
        { nome: 'cep_cep', obrigatorio: true, label: 'CEP' },
        { nome: 'log_logradouro', obrigatorio: true, label: 'Endereço' },
        //{ nome: 'pee_numero', obrigatorio: true, label: 'Número' },
        { nome: 'bai_nome', obrigatorio: true, label: 'Bairro' },
        { nome: 'loc_telefone', obrigatorio: true, label: 'Telefone' }
    ];

    useEffect(() => {
        api.get(`Enum/Listar?nome=TipoContribuinte`).then(res => {
            if (res.status === 200) {
                setTipoContribuinte(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get(`Enum/Listar?nome=RegimeTributarioFiscal`).then(res => {
            if (res.status === 200) {
                setRegimeTributarioFiscal(res.data);
            }
        }).catch((erro) => console.error(erro));

    }, []);

    function pesquisaInformacoesCep() {
        if (!!formulario.getFieldValue().loc_cep) {
            let cep = formulario.getFieldValue().loc_cep.replace('-', '');
            api.get(`Cidade/ListarCidadeCep?cep=${cep}`).then(retornoCep => {
                if (!!retornoCep.data[0]) {
                    formulario.setFieldsValue({
                        cid_id: retornoCep.data[0].cid_id,
                        cep: retornoCep.data[0],
                        loc_cep: retornoCep.data[0].cep_cep,
                        cid_descricao: retornoCep.data[0].cid_descricao + " / " + retornoCep.data[0].est_sigla,
                        siglaEstado: retornoCep.data[0].est_sigla
                    });
                } else {
                    pesquisaCepPostmon(cep, false);
                }
            }).catch(erro => {
                console.log(erro);
            });
        }
    };

    function pesquisaCepPostmon(cep, possuiCep) {
        fetch(`https://api.postmon.com.br/v1/cep/${cep}`).then(
            res => res.json()
        ).then(
            retornoCep => {
                if (!possuiCep) {
                    formulario.setFieldsValue({
                        cep: {},
                        loc_cep: retornoCep.cep,
                        cid_descricao: retornoCep.cidade,
                        siglaEstado: retornoCep.estado
                    });
                }
            }, erro => {
                Modal.warning({
                    content: `Cep (${cep}) não encontrado!`,
                });
                formulario.setFieldsValue({
                    loc_cep: '',
                    cid_descricao: ''
                });
            }
        );
    };

    function verificarLocal() {
        if (!!formulario.getFieldValue().loc_cnpj) {
            let cnpj = formulario.getFieldValue().loc_cnpj.replace(/\D/g, '');
            api.get(`Local/BuscarLocalCnpj/${cnpj}`).then(retornoCnpjLocal => {
                if (!!retornoCnpjLocal.data) {
                    Modal.warning({
                        title: 'Aviso!',
                        content: "CNPJ já cadastrado!",
                        onOk() {
                            formulario.setFieldsValue({ loc_cnpj: null });
                        }
                    });
                } else {
                    consultaCNPJ(cnpj).then((retorno) => {
                        if (retorno.status === "ERROR") {
                            api.get(`Empresa/BuscarEmpresa?cnpj=${cnpj}`).then(retornoCnpj => {
                                if (retornoCnpj.data.length > 0) {
                                    formulario.setFieldsValue({
                                        loc_inscricaoestadual: retornoCnpj.data[0].pej_inscricaoestadual,
                                        loc_telefone: retornoCnpj.data[0].psc_numero,
                                        loc_descricao: retornoCnpj.data[0].pes_nome,
                                        loc_nomefantasia: retornoCnpj.data[0].pej_nomefantasia,
                                        loc_cep: retornoCnpj.data[0].cep_cep,
                                        cidade: retornoCnpj.data[0].cid_descricao + " / " + retornoCnpj.data[0].est_sigla,
                                        loc_numeroestabelecimento: retornoCnpj.data[0].pee_numero,
                                        bai_nome: retornoCnpj.data[0].bai_nome,
                                        loc_complemento: retornoCnpj.data[0].pee_complemento
                                    });
                                    pesquisaInformacoesCep();
                                }
                            }).catch(erro => {
                                console.log(erro);
                            });
                        } else {
                            formulario.setFieldsValue({
                                loc_inscricaoestadual: "",
                                loc_telefone: retorno.telefone.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', ''),
                                loc_descricao: retorno.nome,
                                loc_nomefantasia: retorno.fantasia,
                                loc_cep: retorno.cep.replace('.', '').replace('-', ''),
                                cidade: retorno.municipio + " / " + retorno.uf,
                                loc_numeroestabelecimento: retorno.numero,
                                bai_nome: retorno.bairro,
                                loc_complemento: retorno.complemento,
                                endereco: retorno.logradouro
                            });
                            pesquisaInformacoesCep();
                        }
                    }, (erro) => {
                        console.log('ERRO:', erro);
                    });
                }
            }).catch(erro => {
                console.log(erro);
            });
        }
    };

    const verificaCep = (cep) => {
        if (HasValue(cep)) {
            cep = retornaSoNumeros(cep);
            api.get(`Cidade/BuscaCidadeCep?cep=${cep}`).then(
                res => {
                    if (!!res.data) {
                        formulario.setFieldsValue({ cid_descricao: `${res.data[0].cid_descricao} / ${res.data[0].est_sigla}`, est_sigla: res.data[0].est_sigla, cep_id: res.data.cep_id });
                    } else {
                        fetch(`https://viacep.com.br/ws/${cep.replace(`-`, ``)}/json/`)
                            .then(
                                res => res.json()
                            ).then(
                                result => {
                                    formulario.setFieldsValue({ cid_descricao: `${result.localidade} / ${result.uf}`, est_sigla: result.uf });
                                }).catch(
                                    error => {
                                        notification.warning({ message: 'Aviso', description: 'Cep não encontrado!' })
                                    }
                                );
                    }
                }
            ).catch(
                error => {
                    notification.warning({ message: 'Aviso', description: 'Cep não encontrado!' })
                }
            )
        }
    };

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoMatrizFilial" onFinish={salvarManutencao}>
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Matriz" key="1">
                        <Row gutter={[8, 0]}>
                            <Data hidden name="loc_datainclusao" />
                            <Form.Item name="loc_centrodistribuicao" hidden valuePropName="checked"><Checkbox></Checkbox></Form.Item>
                            <Form.Item name="loc_id" hidden><Input /></Form.Item>
                            <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                                <InputCpfCnpj disabled={desabilitaCNPJ} label="CNPJ" name="loc_cnpj" rules={[{ required: true }]} onBlur={verificarLocal} />
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <Form.Item label="Inscrição Estadual" name="loc_inscricaoestadual">
                                    <Input placeholder="Informe a Inscrição Estadual (Opcional)" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={7} lg={7} xl={7}>
                                <Form.Item label="Tipo de Contribuinte" name="loc_tipocontribuinte" rules={[{ required: true }]}>
                                    <Select showSearch options={tipoContribuinte.map((item) => {
                                        return { label: item.value, value: item.key, key: item.key }
                                    })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione o Tipo de Contribuinte" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={7} lg={7} xl={7}>
                                <Form.Item label="Regime Tributário Fiscal (Empresa)" name="emp_regimetributario" rules={[{ required: true }]}>
                                    <Select showSearch options={regimeTributarioFiscal.map((item) => {
                                        return { label: item.value, value: item.key, key: item.key }
                                    })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione o Regime Tributário Fiscal" disabled={true} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Item name="loc_matriz" valuePropName="checked">
                                    <Checkbox>Matriz?</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Filial" key="2">
                        <TabFilial imageUrl={imageUrl} setImageUrl={setImageUrl} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Endereço" key="3">
                        <TabEndereco verificaCep={verificaCep} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Senha Gerencial" key="4">
                        <TabSenhaGerencial form={formulario}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Outras Informações" key="5">
                        <TabOutrasInformacoes />
                    </Tabs.TabPane>
                </Tabs>
            </FormGW>
        </div>
    );
}