import React, { useEffect, useState } from 'react';
import { Row, Col } from 'antd';

import { useStateValue } from '../../state';
import { listagemActions } from '../../actions';
import { TabelaDados, BreadcrumbPage, BotaoIncluirDados, BotaoAbrirChamado } from '../../components';
import DrawerAliquotaSimples from './drawer';
import { FormatNumber } from '../../ValueObjects';

export default function AliquotaSimples() {
    const [{ ui, listaTabelaDados }, dispatch] = useStateValue();
    const [possuiAliquotaMes, setPossuiAliquotaMes] = useState(true);

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-asn_id' } });
    }, []);

    function retornaMesExtenso(mes) {
        switch (mes) {
            case 1:
                return 'Janeiro';
            case 2:
                return 'Fevereiro';
            case 3:
                return 'Março';
            case 4:
                return 'Abril';
            case 5:
                return 'Março';
            case 6:
                return 'Junho';
            case 7:
                return 'Julho';
            case 8:
                return 'Agosto';
            case 9:
                return 'Setembro';
            case 10:
                return 'Outubro';
            case 11:
                return 'Novembro';
            case 12:
                return 'Dezembro';
        }
    };

    useEffect(() => {
        if (!!listaTabelaDados.itens && listaTabelaDados.itens.length > 0) {
            if (listaTabelaDados.itens[0].asn_mes !== (new Date().getMonth() + 1)) {
                setPossuiAliquotaMes(false);
            } else {
                setPossuiAliquotaMes(true);
            }
        } else {
            setPossuiAliquotaMes(false);
        }
    }, [listaTabelaDados.itens])

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoAbrirChamado />
                        </Col>
                        <Col>
                            <BotaoIncluirDados >
                                {possuiAliquotaMes ? 'ALTERAR' : 'INCLUIR'}  {retornaMesExtenso(new Date().getMonth() + 1).toUpperCase()}
                            </BotaoIncluirDados>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <div className="tabela m-t-16">
                <TabelaDados
                    url="AliquotaSimplesNacional/Listar"
                    colunas={[
                        {
                            title: 'Mês',
                            render: ({ asn_mes }) => (
                                <div>
                                    {retornaMesExtenso(asn_mes)}
                                </div>
                            )
                        }, {
                            title: 'Ano',
                            render: ({ asn_ano }) => (
                                <div>
                                    {asn_ano}
                                </div>
                            )
                        }, {
                            title: 'Alíquota (%)',
                            align: 'right',
                            render: ({ asn_percaliquota }) => (
                                <div>
                                    {FormatNumber(asn_percaliquota, true, false, 2)}
                                </div>
                            )
                        }
                    ]}
                />
            </div>
            <DrawerAliquotaSimples />
        </div>
    )
};