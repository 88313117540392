import React, { useEffect, useState } from "react";
import { Form, Row, Col, Input, Checkbox, Select, Typography, Divider, InputNumber, Radio, Table, Modal, notification } from "antd";
import { DeleteOutlined, PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { FormatNumber } from "../../ValueObjects";
import { getLocal, linkApi } from "../../services/auth";
import { FormGW, Adicionar, Excluir, InputCpfCnpj, InputPercentual } from "../../components";
import { consultaCNPJ, info, validaForm } from "../../services/funcoes";

export default function ManutencaoBandeiraCartaoEmpresa({ formulario, carregando, aoSalvar }) {

    const [value, setValue] = useState(1);
    const [{ manutencao }, dispatch] = useStateValue();
    const [contaCorrente, setContaCorrente] = useState([]);
    const [dadosCartao, setDadosCartao] = useState([]);
    const [listaTaxasCartao, setListaTaxasCartao] = useState([]);
    const [formularioTaxas] = Form.useForm();

    const listaValidacoes = [
        { nome: 'bce_cnpjcredenciadora', obrigatorio: true, label: 'CNPJ da Credenciadora' },
        { nome: 'ctc_id', obrigatorio: true, label: 'Conta Bancária para Recebimento' }
    ];

    useEffect(() => {
        api.get(`ContaCorrente/AtivosTipoConta?tipoConta=2&ordem=+ctc_descricao`).then(
            (res) => {
                if (res.status === 200) {
                    setContaCorrente(res.data.items);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    }, []);

    useEffect(() => {
        if (manutencao.dados !== null) {
            if (manutencao.dados.taxasCartaoCreditoParcelados.length > 0) {
                setValue(2);
                let indice = 1;
                manutencao.dados.taxasCartaoCreditoParcelados.map((taxa) => {
                    taxa.key = indice++;
                });
                setListaTaxasCartao(manutencao.dados.taxasCartaoCreditoParcelados);
            } else {
                setValue(1);
                setListaTaxasCartao([]);
            }
            if (manutencao.dados.bce_id === null) {
                formulario.setFieldsValue({ bce_ativo: true });
            }
            if (manutencao.dados.ctc_id !== null && contaCorrente.filter((conta) => { return conta.ctc_id === manutencao.dados.ctc_id }).length === 0) {
                formulario.setFieldsValue({ ctc_id: manutencao.dados.ctc_descricao });
            }
            setDadosCartao(manutencao.dados);
        }
    }, [manutencao.dados]);

    function buscarDadosCNPJ(event) {
        let cnpj = event.target.value;
        consultaCNPJ(cnpj).then(
            res => {
                formulario.setFieldsValue({ bce_nomecredenciadora: res.nome });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function adicionarTaxa(values) {
        let listaTemp = [...listaTaxasCartao];
        if (values.tcp_taxa > 0) {
            values.tcp_taxa = parseFloat(values.tcp_taxa) / 100;
            values.key = listaTemp.length + 1;
            listaTemp.push(values);
            setListaTaxasCartao(listaTemp);
        } else {
            info('Informe a Taxa!');
        }
    };

    function excluirTaxa(id) {
        let lista = [...listaTaxasCartao];
        let index = lista.findIndex(i => i.key === id);
        lista.splice(index, 1);
        setListaTaxasCartao(lista);
    };

    function salvarManutencao(values) {
        if (validaForm(formulario, listaValidacoes)) {

            if (isNaN(values.ctc_id) && !!dadosCartao.ctc_id) {
                values.ctc_id = dadosCartao.ctc_id;
                values.ctc_descricao = dadosCartao.ctc_descricao;
            } else {
                values.ctc_id = parseInt(values.ctc_id);
                values.ctc_descricao = contaCorrente.filter((conta) => { return conta.ctc_id === values.ctc_id })[0].ctc_descricao;
            }

            if (!!values.bce_taxapadraocredito) {
                values.bce_taxapadraocredito = parseFloat(parseFloat(values.bce_taxapadraocredito) / 100);
            }
            if (!!values.bce_taxapadraodebito) {
                values.bce_taxapadraodebito = parseFloat(parseFloat(values.bce_taxapadraodebito) / 100);
            }

            values.ban_id = dadosCartao.ban_id;
            values.ban_descricao = dadosCartao.ban_descricao;
            values.bnd_id = dadosCartao.bnd_id;
            values.bnd_nome = dadosCartao.bnd_nome;
            values.ctc_digitoverificadoragencia = dadosCartao.ctc_digitoverificadoragencia;
            values.ctc_digitoverificadorconta = dadosCartao.ctc_digitoverificadorconta;
            values.ctc_numeroagencia = dadosCartao.ctc_numeroagencia;
            values.ctc_numeroconta = dadosCartao.ctc_numeroconta;
            values.ctc_numeroconta = dadosCartao.ctc_numeroconta;
            values.loc_id = getLocal();
            values.taxasCartaoCreditoParcelados = (value === 2 ? listaTaxasCartao : []);

            carregando(true);
            if (!!values.bce_id) {
                editar(values);
            } else {
                incluir(values);
            }
        }
    };

    function editar(values) {
        api.put(`BandeiraCartaoEmpresa/Editar`, values).then(res => {
            notification.success({ message: 'Operação concluída!', description: 'Bandeira de Cartão de Crédito salva com sucesso!' });
        }).catch(error => {
            console.log(error);
            notification.warning({ message: 'Aviso!', description: error.response.data.Message });
        }).finally(() => {
            carregando(false);
            setDadosCartao([]);
            setListaTaxasCartao([]);
            aoSalvar();
        })
    }

    function incluir(values) {
        api.post(`BandeiraCartaoEmpresa/Incluir`, values).then(res => {
            notification.success({ message: 'Operação concluída!', description: 'Bandeira de Cartão de Crédito salva com sucesso!' });
        }).catch(error => {
            console.log(error);
            notification.warning({ message: 'Aviso!', description: error.response.data.Message });
        }).finally(() => {
            carregando(false);
            setDadosCartao([]);
            setListaTaxasCartao([]);
            aoSalvar();
        })
    }

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoBandeiraCartaoEmpresa" onFinish={salvarManutencao}>
                <Row gutter={[8, 8]}>
                    <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                        <Row justify="center">
                            <Col xs={8} sm={8} md={24} lg={24} xl={24}>
                                <img className="w-100 b-1 p-5" src={dadosCartao.imagemCartao} alt={dadosCartao.bnd_nome} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={24} sm={24} md={21} lg={21} xl={21}>
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <Form.Item name="bce_id" hidden><Input /></Form.Item>
                                <InputCpfCnpj label="CNPJ da Credenciadora" name="bce_cnpjcredenciadora" onBlur={(value) => { buscarDadosCNPJ(value) }} />
                            </Col>
                            <Col xs={24} sm={12} md={11} lg={11} xl={11}>
                                <Form.Item label="Razão Social da Credenciadora" name="bce_nomecredenciadora">
                                    <Input placeholder="Informe a Razão Social da Credenciadora" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={7} lg={7} xl={7}>
                                <Form.Item label="Conta Bancária para Recebimento" name="ctc_id">
                                    <Select defaultValue={dadosCartao.ctc_descricao} allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione a Conta Corrente onde serão recebidos os depósitos">
                                        {contaCorrente.map(conta => (
                                            <Select.Option key={conta.ctc_id}>{conta.ctc_descricao}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6} className="t19 tt0">
                                <Form.Item name="bce_ativo" valuePropName="checked">
                                    <Checkbox>Ativo?</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row align="middle" gutter={[8, 8]} className="m-t-16">
                    <Col span={24}>
                        <Typography.Title level={3}>
                            <img src={require("../../assets/i-cartao.png").default} alt="Cartão de Débito" /> Cartão de Débito
                        </Typography.Title>
                        <Divider orientation="left" plain>
                            Informações referente a Cartão de Débito.
                        </Divider>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <Form.Item label="Dias para Recebimento" name="bce_nrodiasrecebimentodebito">
                                    <Input defaultValue="2"  placeholder="Informe os Dias para Recebimento" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <InputPercentual
                                    label="Taxa % para vendas"
                                    name="bce_taxapadraodebito"
                                    defaultValue={0}
                                    min={0}
                                    max={100}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row align="middle" gutter={[8, 8]} className="m-t-16">
                    <Col span={24}>
                        <Typography.Title level={3}>
                            <img src={require("../../assets/i-cartao.png").default} alt="Cartão de Crédito" /> Cartão de Crédito
                        </Typography.Title>
                        <Divider orientation="left" plain>
                            Informações referente a Cartão de Crédito.
                        </Divider>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                                <Form.Item label="Dias para Recebimento" name="bce_nrodiasrecebimentocredito">
                                    <Input defaultValue="30" placeholder="Informe os Dias para Recebimento" />
                                </Form.Item>
                            </Col>
                            {value === 1 &&
                                <Col xs={24} sm={6} md={6} lg={6} xl={6}>
                                    <InputPercentual
                                        label="Taxa % para vendas"
                                        name="bce_taxapadraocredito"
                                        defaultValue={0}
                                        min={0}
                                        max={100}
                                    />
                                </Col>
                            }
                            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                <Form.Item className="t-mob-573">
                                    <Radio.Group onChange={(e) => setValue(e.target.value)} value={value}>
                                        <Radio value={1}>
                                            Taxa Única
                                        </Radio>
                                        <Radio value={2}>
                                            Taxa Variável por Parcelas
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </FormGW>
            {value === 2 &&
                <div>
                    <Form form={formularioTaxas} layout="vertical" onFinish={adicionarTaxa}>
                        <Row justify="end" gutter={[8, 0]}>
                            <Col xs={24} sm={8} md={7} lg={7} xl={7}>
                                <Form.Item label="Parcela Mínima" name="tcp_parcelaminima" rules={[{ required: true, message: 'Informe Parcela Mínima' }]}>
                                    <Input placeholder="Informe Parcela Mínima" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={7} lg={7} xl={7}>
                                <Form.Item label="Parcela Máxima" name="tcp_parcelamaxima" rules={[{ required: true, message: 'Informe Parcela Máxima' }]}>
                                    <Input placeholder="Informe Parcela Máxima" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={6} xl={6}>
                                <InputPercentual
                                    label="Taxa % para vendas"
                                    name="tcp_taxa"
                                    defaultValue={0}
                                    min={0}
                                    max={100}
                                />
                            </Col>
                            <Col xs={24} sm={8} md={4} lg={4} xl={4} className="t-mob">
                                <Adicionar type="primary" icon={<PlusOutlined />} block onClick={() => { formularioTaxas.submit() }}> Adicionar </Adicionar>
                            </Col>
                        </Row>
                    </Form>
                    <div className="tabela m-t-16">
                        <Table dataSource={listaTaxasCartao} columns={
                            [
                                {
                                    title: 'Parcela Mínima',
                                    render: ({ tcp_parcelaminima }) => (
                                        <div>
                                            <b>De {tcp_parcelaminima} parcela(s)</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Parcela Máxima',
                                    render: ({ tcp_parcelamaxima }) => (
                                        <div>
                                            <b>Até {tcp_parcelamaxima} parcela(s)</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Taxa %',
                                    align: 'right',
                                    render: ({ tcp_taxa }) => (
                                        <div>
                                            <b>{FormatNumber(parseFloat(tcp_taxa) * 100, true, false)}%</b>
                                        </div>
                                    ),
                                }, {
                                    title: 'Ações',
                                    key: 'x',
                                    width: 65,
                                    fixed: 'right',
                                    align: 'center',
                                    render: (record) => (
                                        <div>
                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                <Col>
                                                    <Excluir onClick={() => excluirTaxa(record.key)} icon={<DeleteOutlined />} />
                                                </Col>
                                            </Row>
                                        </div>
                                    ),
                                }
                            ]
                        } scroll={{ x: 900 }} locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }} />
                    </div>
                </div>
            }
        </div>
    );
}