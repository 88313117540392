import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography, Form, Divider, Select, Button } from 'antd';
import { ExclamationCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import api from '../../services/api';
import { getEmpresa, getLocal } from '../../services/auth';
import { BreadcrumbPage, BotaoAbrirChamado, SelectPaginacao } from "../../components";
import { ComponentSpin, RenderChartPie, TableRenderer } from './funcoes';

const numberFormatter = (item, casasDecimais = 2) => item.toLocaleString({ style: 'currency', currency: 'BRL' }, { minimumFractionDigits: casasDecimais, maximumFractionDigits: casasDecimais });

export default function PosicaoEstoque({ graficoSelecionado }) {
    const [formFiltros] = Form.useForm();
    const [listaLocaisUsuario, setListaLocaisUsuario] = useState([]);
    const [totalizadores, setTotalizadores] = useState({});
    const [posicaoEstoqueMarca, setPosicaoEstoqueMarca] = useState([]);
    const [posicaoEstoqueGrupo, setPosicaoEstoqueGrupo] = useState([]);
    const [topGrupo, setTopGrupo] = useState([]);
    const [topMarca, setTopMarca] = useState([]);
    const [topProduto, setTopProduto] = useState([]);
    const [carregandoTotalizadores, setCarregandoTotalizadores] = useState(false);
    const [carregandoGrupo, setCarregandoGrupo] = useState(false);
    const [carregandoMarca, setCarregandoMarca] = useState(false);
    const [carregandoProduto, setCarregandoProduto] = useState(false);

    useEffect(() => {

        api.get(`/Local/ListarLocalUsuario?codigoEmpresa=${getEmpresa()}`).then(res => {
            if (res.status === 200) {
                setListaLocaisUsuario(res.data.items);
            }
        }).catch((erro) => console.error(erro));

        carregarDados();
    }, []);

    function carregarDados() {
        let locais = !!formFiltros.getFieldValue().local ? formFiltros.getFieldValue().local.join(',') : '';
        let marcas = !!formFiltros.getFieldValue().mar_id ? formFiltros.getFieldValue().mar_id.join(',') : '';
        let grupos = !!formFiltros.getFieldValue().gru_id ? formFiltros.getFieldValue().gru_id.join(',') : '';
        let subgrupos = !!formFiltros.getFieldValue().sgp_id ? formFiltros.getFieldValue().sgp_id.join(',') : '';

        setCarregandoTotalizadores(true);
        setCarregandoGrupo(true);
        setCarregandoMarca(true);
        setCarregandoProduto(true);
        api.get(`Dashboard/ListarTotalizadoresEstoque?locais=${locais}&filtroMarca=${marcas}&filtroGrupo=${grupos}&filtroSubgrupo=${subgrupos}`).then(
            (res) => {
                if (!!res.data && res.data.length > 0) {
                    setTotalizadores(res.data[0]);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoTotalizadores(false);
        });

        api.get(`Dashboard/ListarEstoqueGrupo?locais=${locais}&filtroMarca=${marcas}&filtroGrupo=${grupos}&filtroSubgrupo=${subgrupos}`).then(
            (res) => {
                if (!!res.data) {
                    setTopGrupo(res.data.filter((x) => { return x.qtde_saldo_final >= 0 }).slice(0, 10));
                    setPosicaoEstoqueGrupo(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoGrupo(false);
        });

        api.get(`Dashboard/ListarEstoqueMarca?locais=${locais}&filtroMarca=${marcas}&filtroGrupo=${grupos}&filtroSubgrupo=${subgrupos}`).then(
            (res) => {
                if (!!res.data) {
                    setTopMarca(res.data.filter((x) => { return x.qtde_saldo_final >= 0 }).slice(0, 10));
                    setPosicaoEstoqueMarca(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoMarca(false);
        });

        api.get(`Dashboard/ListarEstoqueProduto?locais=${locais}&filtroMarca=${marcas}&filtroGrupo=${grupos}&filtroSubgrupo=${subgrupos}`).then(
            (res) => {
                if (!!res.data) {
                    setTopProduto(res.data.filter((x) => { return x.qtde_saldo_final >= 0 }).slice(0, 10));
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        ).finally(() => {
            setCarregandoProduto(false);
        });
    };

    return (
        <div>
            <Row align="middle" justify="space-between" gutter={[8, 0]} className="col-pagina-breadcrumb">
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoAbrirChamado />
                        </Col>
                        <Col>
                            <Button icon={<ArrowLeftOutlined />} size="middle" type="primary" onClick={() => { graficoSelecionado.setGraficoSelecionado(null) }}>
                                Voltar
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <Row justify="center" gutter={[8, 0]}>
                        <Col>
                            <Typography.Title level={3} className="m-b-0">
                                <img src={require("../../assets/i-produto.png").default} alt="Posição de Estoque" /> Posição de Estoque
                            </Typography.Title>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Form form={formFiltros} layout="vertical">
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
                                <Form.Item name="local" label="Local" initialValue={[parseInt(getLocal())]}>
                                    <Select onChange={carregarDados} mode="multiple" allowClear={true} showSearch optionFilterProp="children" placeholder="Selecione o local...">
                                        {listaLocaisUsuario.filter(ativa => (ativa.loc_ativo)).map(local => (
                                            <Select.Option key={local.loc_id} value={local.loc_id}> {local.loc_descricao} </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
                                <Form.Item label="Marca" name="mar_id">
                                    <SelectPaginacao url="marca/Listar"
                                        multiple={true}
                                        nameLabel="mar_nome"
                                        nameValue="mar_id"
                                        placeholder="Selecione a Marca"
                                        form={formFiltros}
                                        onChangeFunction={carregarDados}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
                                <Form.Item label="Grupo" name="gru_id">
                                    <SelectPaginacao url="grupoProduto/listar"
                                        multiple={true}
                                        nameLabel="gru_nome"
                                        nameValue="gru_id"
                                        placeholder="Selecione a Grupo"
                                        form={formFiltros}
                                        onChangeFunction={carregarDados}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={6} xxl={6}>
                                <Form.Item label="SubGrupo" name="sgp_id">
                                    <SelectPaginacao url="SubGrupo/listar"
                                        multiple={true}
                                        nameLabel="sgp_nome"
                                        nameValue="sgp_id"
                                        placeholder="Selecione a Subgrupo"
                                        form={formFiltros}
                                        onChangeFunction={carregarDados}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="m-t-8 card-dash">
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                    <Card>
                        <ComponentSpin carregando={carregandoTotalizadores}>
                            <Typography.Title level={4} className="m-b-0">
                                Valor Total Custo Última Compra (R$)
                            </Typography.Title>
                            <Typography.Title level={2} className="m-t-0 m-b-0 text-right">
                                {numberFormatter(!!totalizadores.vlr_custo_ultima_compra ? totalizadores.vlr_custo_ultima_compra : 0)}
                            </Typography.Title>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8} xxl={8}>
                    <Card>
                        <ComponentSpin carregando={carregandoTotalizadores}>
                            <Typography.Title level={4} className="m-b-0">
                                Valor Total Custo Informado (R$)
                            </Typography.Title>
                            <Typography.Title level={2} className="m-t-0 m-b-0 text-right">
                                {numberFormatter(!!totalizadores.vlr_custo_informado ? totalizadores.vlr_custo_informado : 0)}
                            </Typography.Title>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                    <Card>
                        <ComponentSpin carregando={carregandoTotalizadores}>
                            <Typography.Title level={4} className="m-b-0">
                                Valor Total Custo Médio (R$)
                            </Typography.Title>
                            <Typography.Title level={2} className="m-t-0 m-b-0 text-right">
                                {numberFormatter(!!totalizadores.vlr_custo_medio ? totalizadores.vlr_custo_medio : 0)}
                            </Typography.Title>
                        </ComponentSpin>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="m-t-16">
                <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                    <Card>
                        <ComponentSpin carregando={carregandoGrupo}>
                            <Typography.Title level={4}>
                                % Top 10 por Grupo e Quantidade de Estoque
                            </Typography.Title>
                            <div>
                                {topGrupo.length > 0 ?
                                    <RenderChartPie resultSet={topGrupo} dataKey="qtde_saldo_final" nameKey="grupo" /> : <Row className="col-charts">
                                        <Col span={24}>
                                            <Divider orientation="center">
                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                            </Divider>
                                        </Col>
                                    </Row>
                                }
                            </div>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={8} xxl={8}>
                    <Card>
                        <ComponentSpin carregando={carregandoMarca}>
                            <Typography.Title level={4}>
                                % Top 10 por Marca e Quantidade de Estoque
                            </Typography.Title>
                            <div>
                                {topMarca.length > 0 ?
                                    <RenderChartPie resultSet={topMarca} dataKey="qtde_saldo_final" nameKey="marca" /> : <Row className="col-charts">
                                        <Col span={24}>
                                            <Divider orientation="center">
                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                            </Divider>
                                        </Col>
                                    </Row>
                                }
                            </div>
                        </ComponentSpin>
                    </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8}>
                    <Card>
                        <ComponentSpin carregando={carregandoProduto}>
                            <Typography.Title level={4}>
                                % Top 10 por Produto e Quantidade de Estoque
                            </Typography.Title>
                            <div>
                                {topProduto.length > 0 ?
                                    <RenderChartPie resultSet={topProduto} dataKey="qtde_saldo_final" nameKey="produto" /> : <Row className="col-charts">
                                        <Col span={24}>
                                            <Divider orientation="center">
                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                            </Divider>
                                        </Col>
                                    </Row>
                                }
                            </div>
                        </ComponentSpin>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="m-t-16">
                <Col span={24}>
                    <Card>
                        <Typography.Title level={4}>
                            Posição de Estoque por Grupo
                        </Typography.Title>
                        <div className="">
                            <TableRenderer dataSource={posicaoEstoqueGrupo} carregando={carregandoGrupo} columns={[{
                                title: 'Grupo',
                                render: ({ grupo }) => (
                                    <div>
                                        <b>{grupo}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Quant. Saldo Final (R$)',
                                align: 'right',
                                render: ({ qtde_saldo_final }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(qtde_saldo_final), 3)}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Valor Custo Últ. Compra (R$)',
                                align: 'right',
                                render: ({ vlr_custo_ultima_compra }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(vlr_custo_ultima_compra))}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Valor Custo Informado (R$)',
                                align: 'right',
                                render: ({ vlr_custo_informado }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(vlr_custo_informado))}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Valor Custo Médio (R$)',
                                align: 'right',
                                render: ({ vlr_custo_medio }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(vlr_custo_medio))}</b>
                                    </div>
                                ),
                            }]} />
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="m-t-16">
                <Col span={24}>
                    <Card>
                        <Typography.Title level={4}>
                            Posição de Estoque por Marca
                        </Typography.Title>
                        <div className="">
                            <TableRenderer dataSource={posicaoEstoqueMarca} carregando={carregandoMarca} columns={[{
                                title: 'Marca',
                                render: ({ marca }) => (
                                    <div>
                                        <b>{marca}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Quant. Saldo Final (R$)',
                                align: 'right',
                                render: ({ qtde_saldo_final }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(qtde_saldo_final), 3)}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Valor Custo Últ. Compra (R$)',
                                align: 'right',
                                render: ({ vlr_custo_ultima_compra }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(vlr_custo_ultima_compra))}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Valor Custo Informado (R$)',
                                align: 'right',
                                render: ({ vlr_custo_informado }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(vlr_custo_informado))}</b>
                                    </div>
                                ),
                            }, {
                                title: 'Valor Custo Médio (R$)',
                                align: 'right',
                                render: ({ vlr_custo_medio }) => (
                                    <div>
                                        <b>{numberFormatter(parseFloat(vlr_custo_medio))}</b>
                                    </div>
                                ),
                            }]} />
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};