import { Button, Col, Collapse, Dropdown, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { BotaoAbrirChamado, BreadcrumbPage, PesquisaAvancada, PesquisaOrdenacao } from "../../components";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
export default function CatAcessosExterno() {
    const [formPesquisaAvancada] = Form.useForm();
    const [open, setOpen] = useState(false);

    const togglePesquisa = () => {

    }

    function aplicarFiltrosAvancados() {

    }

    function pesquisaAvancada() {

    }

    function limparFiltroPesquisa() {

    }
    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb" gutter={[8, 8]}>
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoAbrirChamado />
                        </Col>

                    </Row>
                </Col>
            </Row>
            <Form layout="vertical" name="formularioPesqAvancadaAcessos" form={formPesquisaAvancada} onFinish={aplicarFiltrosAvancados}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={16}>
                        <PesquisaOrdenacao opcoesOrdenacao={[
                            { label: "Nome A - Z", value: "+pes_nome" },
                            { label: "Nome Z - A", value: "-pes_nome" },
                            { label: "Código Crescente", value: "+cli_codigo" },
                            { label: "Código Decrescente", value: "-cli_codigo" }
                        ]} />
                    </Col>
                </Row>
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Collapse className="p-relative mt-coll" accordion ghost destroyInactivePanel={true}>
                            <div className="colDet"></div>
                            <Collapse.Panel key="1" showArrow={false} extra={
                                <Button type="primary" onClick={togglePesquisa} block>
                                    {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                </Button>
                            }>
                                <PesquisaAvancada form={formPesquisaAvancada} funcaoRetorno={(ret) => { pesquisaAvancada(ret) }} funcaoLimpar={() => { limparFiltroPesquisa() }} telaFiltro={1} />
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">

            </div>
        </div>
    );
}