import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Collapse, Tag, Dropdown, Menu, Modal } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, SearchOutlined, EditOutlined, CloseOutlined, PrinterOutlined, MoreOutlined, EyeOutlined, FileTextOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import DrawerOrcamento from './drawer';
import { useStateValue } from '../../state';
import { ModalDetalheOrcamento, ModalSelecionarOrcamento } from "../../components/modals/";
import { FormatFone, FormatNumber, MaskFormat } from "../../ValueObjects";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacaoData, TabelaDados, Imprimir, Data } from "../../components";
import { imprimirRelatorioJasper, novaAbaNavegador } from "../../services/funcoes";

export default function Orcamento() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [open, setOpen] = useState(false);
    const [openModalDetalheOrcamento, setOpenModalDetalheOrcamento] = useState(false);
    const [openModalSelecionarOrcamento, setOpenModalSelecionarOrcamento] = useState(false);
    const [dataPesquisa, setDataPesquisa] = useState(new Date().toLocaleDateString());
    const [listaVendedor, setListaVendedor] = useState([]);
    const [dadosOrcamento, setDadosOrcamento] = useState({});
    const [formPesquisa] = Form.useForm();

    const [form] = Form.useForm();

    const togglePesquisa = () => {
        setOpen(!open);
    };

    useEffect(() => {
        carregarDados();
    }, [dataPesquisa]);

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-orc_datainclusao' } });
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&dataFinal=${moment(new Date).format('YYYY-MM-DD')}&dataInicial=${moment(form.getFieldValue().dataPesquisa).format("YYYY-MM-DD")}` } });
        formPesquisa.setFieldsValue({ dataFinal: moment(new Date()) });
        formPesquisa.setFieldsValue({ dataInicial: moment(formPesquisa.getFieldValue().dataPesquisa) });
    }, []);

    function carregarDados(dataInicial, dataFinal, aba) {

        let sql = "";
        sql += !!form.getFieldValue().orc_datainclusao ? `&orc_datainclusao=${form.getFieldValue().orc_datainclusao.format('YYYY-MM-DD')}` : "";
        sql += !!form.getFieldValue().orc_datavalidade ? `&orc_datavalidade=${form.getFieldValue().orc_datavalidade.format('YYYY-MM-DD')}` : "";
        sql += !!form.getFieldValue().ven_id ? `&ven_id=${form.getFieldValue().ven_id}` : '';
        sql += !!form.getFieldValue().cli_id ? `&cli_id=${form.getFieldValue().cli_id}` : '';
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '-orc_datainclusao', outrosFiltros: sql } });

        dataFinal = !!dataFinal ? dataFinal : moment(new Date()).format('YYYY-MM-DD');
        dataInicial = !!dataInicial ? dataInicial : !!formPesquisa.getFieldValue().dataPesquisa ? moment(formPesquisa.getFieldValue().dataPesquisa).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
        //não apagar busca cliente e vendedor pq senão não aparece os nomes na tela de editar
        api.get('Vendedor/Listar?Order=+pes_nome').then(
            (res) => {
                setListaVendedor(res.data.items);
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
        let filtros = `&situacao=0,1`;
        filtros += `&dataFinal=${dataFinal}&dataInicial=${dataInicial}`;
        form.setFieldsValue({ dataFinal: moment(new Date()), dataInicial: moment(form.getFieldValue().dataPesquisa) });
    };

    function aplicarFiltrosAvancados() {
        let dataInicial = !!formPesquisa.getFieldsValue().dataInicial ? moment(formPesquisa.getFieldValue().dataInicial).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
        let dataFinal = !!formPesquisa.getFieldValue().dataFinal ? moment(formPesquisa.getFieldValue().dataFinal).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
        carregarDados(dataInicial, dataFinal);
    };

    function editar(record) {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function visualizar(record) {
        setOpenModalDetalheOrcamento(true);
        setDadosOrcamento(record);
    };

    function gerarPedido(record) {
        setOpenModalSelecionarOrcamento(true)
        setDadosOrcamento(record)
    }

    function imprimir(registro) {
        Modal.confirm({
            title: 'Deseja imprimir em impressora não fiscal?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                novaAbaNavegador(`Orcamento/Imprimir/${registro.orc_id}`);
            },
            onCancel() {
             imprimirRelatorioJasper(59, { IdOrcamento: registro.orc_id });
            }
        });
    };

    function menuTable(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="text" icon={<EyeOutlined />} onClick={() => { visualizar(record) }}> Visualizar </Button>
                </Menu.Item>
                <Menu.Item key="2">
                    <Imprimir type="text" icon={<PrinterOutlined />} onClick={() => { imprimir(record) }}> Imprimir </Imprimir>
                </Menu.Item>
                <Menu.Item key="3">
                    <Button type="text" icon={<FileTextOutlined />} onClick={() => { gerarPedido(record) }}> Gerar Pedido </Button>
                </Menu.Item>
            </Menu>
        )
    }

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={24} lg={18} xl={19} xxl={20}>
                    <PesquisaOrdenacaoData opcoesOrdenacao={[
                        { label: "Data Inclusão Crescente", value: "+orc_datainclusao" },
                        { label: "Data Inclusão Decrescente", value: "-orc_datainclusao" },
                        { label: "Nome do Cliente A - Z", value: "+pes_nome" },
                        { label: "Nome do Cliente Z - A", value: "-pes_nome" }
                    ]} form={form} 
                        eventoAoAlterar={() => {
                        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&dataFinal=${moment(new Date).format('YYYY-MM-DD')}&dataInicial=${moment(form.getFieldValue().dataPesquisa).format("YYYY-MM-DD")}`} })
                        form.setFieldsValue({ dataFinal: moment(new Date) });
                        form.setFieldsValue({ dataInicial: moment(form.getFieldValue().dataPesquisa) });
                    }}
                    />
                </Col>
            </Row>
            <div className="page">
                <Form layout="vertical" form={form}>
                    <Row align="middle" gutter={[0, 24]}>
                        <Col span={24}>
                            <Collapse className="p-relative topGrade" accordion ghost destroyInactivePanel={true}>
                                <div className="colDet"></div>
                                <Collapse.Panel key="1" showArrow={false} extra={
                                    <Button type="primary" onClick={togglePesquisa} block>
                                        {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                    </Button>
                                }>
                                    <Row gutter={[8, 0]}>
                                        <Col xs={24} sm={12} md={5} lg={4} xl={4} xxl={3}>
                                            <Data label="Data Inicial" name="dataInicial" />
                                        </Col>
                                        <Col xs={24} sm={12} md={5} lg={4} xl={4} xxl={3}>
                                            <Data label="Data Final" name="dataInicial" />
                                        </Col>
                                        <Col xs={24} sm={24} md={14} lg={16} xl={16} xxl={18} className="t-mob">
                                            <Row gutter={[8, 8]} className="tt-5">
                                                <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={3}>
                                                    <Button icon={<CloseOutlined />} block>
                                                        Remover Filtros
                                                    </Button>
                                                </Col>
                                                <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={3}>
                                                    <Button type="primary" icon={<SearchOutlined />} onClick={aplicarFiltrosAvancados} block>
                                                        Aplicar Filtros
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Collapse.Panel>
                            </Collapse>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div className="tabela">
                <TabelaDados url="Orcamento/ListarOrcamento" colunas={[
                    {
                        title: "",
                        width: 65,
                        align: 'center',
                        render: ({ orc_numero }) => (
                            <div>
                                <Tag color="processing" className="w-100">
                                    <b>{orc_numero}</b>
                                </Tag>
                            </div>
                        )
                    },
                    {
                        title: "",
                        render: ({ pes_nome, pef_cpf, pej_cnpj, pes_telefone, orc_datainclusao, orc_datavalidade, qtditem }) => (
                            <div>
                                <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                    <Col>
                                        <b>{pes_nome}</b>
                                    </Col>
                                    <Col>
                                        CPF/CNPJ: <b>{!!pef_cpf ? MaskFormat(pef_cpf, 'Não Informado', true) : MaskFormat(pej_cnpj, 'Não Informado', true)}</b>
                                    </Col>
                                    <Col>
                                        Telefone: <b>{!!pes_telefone ? (FormatFone(pes_telefone)) : 'Não Informado'}</b>
                                    </Col>
                                </Row>
                                <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                    <Col>
                                        Emissão: <b>{moment(orc_datainclusao).format('DD/MM/YYYY')}</b>
                                    </Col>
                                    <Col>
                                        Entrega: <b>{moment(orc_datavalidade).format('DD/MM/YYYY')}</b>
                                    </Col>
                                    <Col>
                                        Quant.: <b>{!!qtditem ? qtditem : 0}</b>
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: "Total do Orçamento (R$)",
                        align: 'right',
                        width: 180,
                        render: ({ orc_valortotal }) => (
                            <div>
                                <b>{FormatNumber(orc_valortotal, true)}</b>
                            </div>
                        )
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 95,
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<EditOutlined />} onClick={() => { editar(record) }} />
                                    </Col>
                                    <Col>
                                        <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                            <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerOrcamento carregarDados={carregarDados} vendedor={listaVendedor} />
            <ModalDetalheOrcamento exibirModal={openModalDetalheOrcamento} fecharModal={() => setOpenModalDetalheOrcamento(false)} dadosOrcamento={dadosOrcamento} form={form} />
            <ModalSelecionarOrcamento exibirModal={openModalSelecionarOrcamento} fecharModal={() => setOpenModalSelecionarOrcamento(false)} dadosOrcamento={dadosOrcamento} />
        </div>
    );
}