import React, { useEffect, useState } from "react";
import jsreports from "jsreports/jsreports-all";
import { Row, Col, Tabs } from "antd";

import api from '../../services/api';
import { useStateValue } from '../../state';
import DrawerRelatorioEtiqueta from './drawer';
import { getEmpresa } from "../../services/auth";
import { FormatNumber } from "../../ValueObjects";
import { retornaObjeto } from "../../services/funcoes";
import { TabModeloFixo, TabModeloPersonalizado } from './tabPage';
import { BreadcrumbIncluirDados, PesquisaOrdenacao } from "../../components";
import { listagemActions } from "../../actions";

export default function RelatorioEtiqueta() {

    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [{ manutencao }, dispatch] = useStateValue();
    const [dadosEmpresa, setDadosEmpresa] = useState([]);

    const squemaEtiquetaProduto = [
        {
            nome: 'Codigo_Empresa',
            tipo: 'number'
        },
        {
            nome: 'Empresa',
            tipo: 'string'
        },
        {
            nome: 'Codigo_Produto',
            tipo: 'number'
        },
        {
            nome: 'Produto',
            tipo: 'string'
        },
        {
            nome: 'Grade',
            tipo: 'string'
        },
        {
            nome: 'Marca',
            tipo: 'string'
        },
        {
            nome: 'Preco',
            tipo: 'string'
        },
        {
            nome: 'Ean',
            tipo: 'string'
        },
        {
            nome: 'Codigo_Etiqueta',
            tipo: 'string'
        },
        {
            nome: 'Codigo_Referencia',
            tipo: 'string'
        },
        {
            nome: 'Observacao',
            tipo: 'string'
        },
        {
            nome: 'Quantidade_Parcela',
            tipo: 'string'
        },
        {
            nome: 'Valor_Parcelado',
            tipo: 'string'
        }
    ];
    
    useEffect(() => {
        buscarDadosEmpresa();
    }, []);

    function buscarDadosEmpresa() {
        api.get(`Empresa/Buscar/${getEmpresa()}`).then(
            res => {
                setDadosEmpresa(res.data)
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    };

    function exibeModelo(modelo) {
        let quantidadeColuna = parseInt(modelo.quantidadeColuna);
        let listaProdutosEtiquetas = [
            {
                codigoEmpresa: getEmpresa(),
                empresa: dadosEmpresa.emp_nomefantasia,
                codigoProduto: 1,
                produto: 'PRODUTO MODELO 1',
                marca: 'MARCA MODELO 1',
                grade: 'GRADE MODELO 1',
                codigoEtiqueta: 'CODIGO ETIQUETA MODELO 1',
                codigoReferencia: 'CODIGO REFERENCIA MODELO 1',
                observacao: 'OBSERVACAO MODELO 1',
                preco: 59.90,
                ean: '2514362547366',
                quantidadeParcela: '5x(1+4)',
                valorParcelado: 'R$ 11,98',
                numero: 2
            },
            {
                codigoEmpresa: getEmpresa(),
                empresa: dadosEmpresa.emp_nomefantasia,
                codigoProduto: 2,
                produto: 'PRODUTO MODELO 2',
                marca: 'MARCA MODELO 2',
                grade: 'GRADE MODELO 2',
                codigoEtiqueta: 'CODIGO ETIQUETA MODELO 2',
                codigoReferencia: 'CODIGO REFERENCIA MODELO 2',
                observacao: 'OBSERVACAO MODELO 2',
                preco: 75.50,
                ean: '1203456789010',
                quantidadeParcela: '5x(1+4)',
                valorParcelado: 'R$ 15,10',
                numero: 1
            }
        ];

        let atributosSquema = [];
        squemaEtiquetaProduto.forEach((squema) => {
            for (let i = 0; i <= quantidadeColuna; i++) {
                atributosSquema.push({ name: squema.nome + "_Coluna_" + i, type: squema.tipo });
            }
        });

        let listaEtiqueta = [];
        let dadosEtiquetaColuna = [];
        let numeroColuna = 1;

        listaProdutosEtiquetas.forEach(produto => {
            for (var i = 1; i <= quantidadeColuna; i++) {
                dadosEtiquetaColuna.push({
                    ['Codigo_Empresa_Coluna_' + numeroColuna]: produto.codigoEmpresa,
                    ['Empresa_Coluna_' + numeroColuna]: produto.empresa,
                    ['Codigo_Produto_Coluna_' + numeroColuna]: produto.codigoProduto,
                    ['Produto_Coluna_' + numeroColuna]: produto.produto,
                    ['Grade_Coluna_' + numeroColuna]: produto.grade,
                    ['Marca_Coluna_' + numeroColuna]: produto.marca,
                    ['Preco_Coluna_' + numeroColuna]: FormatNumber(produto.preco, true),
                    ['Ean_Coluna_' + numeroColuna]: produto.ean,
                    ['Codigo_Etiqueta_Coluna_' + numeroColuna]: produto.codigoEtiqueta,
                    ['Codigo_Referencia_Coluna_' + numeroColuna]: produto.codigoReferencia,
                    ['Observacao_Coluna_' + numeroColuna]: produto.observacao,
                    ['Quantidade_Parcela_Coluna_' + numeroColuna]: produto.quantidadeParcela,
                    ['Valor_Parcelado_Coluna_' + numeroColuna]: produto.valorParcelado
                });

                if (numeroColuna === quantidadeColuna) {
                    listaEtiqueta.push(retornaObjeto(dadosEtiquetaColuna));
                    dadosEtiquetaColuna = [];
                    numeroColuna = 1;
                } else {
                    numeroColuna++;
                }
            }
        })

        if (dadosEtiquetaColuna.length > 0) {
            listaEtiqueta.push(retornaObjeto(dadosEtiquetaColuna));
        }

        let data_sources = [];
        let dataSource = {
            id: 'etiqueta',
            name: 'etiqueta',
            data: listaEtiqueta
        }

        dataSource.schema = {
            fields: atributosSquema
        }

        data_sources.push(dataSource);

        jsreports.export({
            report_def: modelo.jsonRelatorio,
            datasets: data_sources,
            format: 'pdf',
            outputHandler: function (dados) {
                window.open(URL.createObjectURL(dados), '_blank');
            }
        });
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Row gutter={[0, 24]}>
                <Col span={24}>
                    <PesquisaOrdenacao opcoesOrdenacao={[
                        { label: "Nome A - Z", value: "Nome" },
                        { label: "Nome Z - A", value: "-Nome" },
                    ]} />
                </Col>
                <Col span={24}>
                    <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                        <Tabs.TabPane tab="Modelos Fixos do Sistema" key="1">
                            <TabModeloFixo exibeModelo={exibeModelo} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Modelos Personalizados" key="2">
                            <TabModeloPersonalizado exibeModelo={exibeModelo} />
                        </Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
            <DrawerRelatorioEtiqueta exibeModelo={exibeModelo} />
        </div>
    );

}