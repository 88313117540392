import React, { useEffect } from "react";
import { Row, Col, Form, Typography, Divider, Radio, Descriptions } from "antd";

import { formatarCnpj, formatarCpf } from "../../services/funcoes";

export default function TabTransporte({ form, dadosTransporte }) {

    useEffect(() => {
        if (!!dadosTransporte.dadosTransporte) {
            form.setFieldsValue(dadosTransporte.dadosTransporte);
        }
    }, [dadosTransporte]);

    return (
        <div>
            <Row>
                <Col span={24}>
                    <Form.Item name="ntf_modalidadefrete">
                        <Radio.Group disabled={true}>
                            <Radio value={0}>0 - Contratação do Frete por conta do Remetente (CIF)</Radio>
                            <Radio value={1}>1 - Contratação do Frete por conta do Destinatário (FOB)</Radio>
                            <Radio value={2}>2 - Contratação do Frete por conta de Terceiros</Radio>
                            <Radio value={3}>3 - Transporte Próprio por conta do Remetente</Radio>
                            <Radio value={4}>4 - Transporte Próprio por conta do Destinatário</Radio>
                            <Radio value={9}>9 - Sem Frete</Radio>
                        </Radio.Group>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[8, 16]} className="m-t-16">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-car.png").default} alt="Dados Transportador" /> Dados Transportador
                    </Typography.Title>
                    <Divider orientation="left" plain />
                </Col>
                <Col span={24}>
                    <Row gutter={[16, 0]} align="middle">
                        <Col>
                            Nome: <b>{!!dadosTransporte ? dadosTransporte.dadosTransporte?.[0]?.find(obj => obj.nome === 'pes_nome')?.valor : ''}</b>
                        </Col>
                        <Col>
                            CPF/CNPJ: <b>{!!dadosTransporte ? !!dadosTransporte.dadosTransporte?.[0]?.find(obj => obj.nome === 'cnpjcpf') && 
                            dadosTransporte?.[0]?.find(obj => obj.nome === 'cnpjcpf')?.valor.length > 11 ? 
                            formatarCnpj(dadosTransporte.dadosTransporte?.[0]?.find(obj => obj.nome === 'cnpjcpf')?.valor) : 
                            formatarCpf(dadosTransporte.dadosTransporte?.[0]?.find(obj => obj.nome === 'cnpjcpf')?.valor) : ""}</b>
                        </Col>
                        <Col>
                            Inscrição Estadual: <b>{!!dadosTransporte ? dadosTransporte.dadosTransporte?.[0]?.find(obj => obj.nome === 'pej_inscricaoestadual')?.valor : ''}</b>
                        </Col>
                        <Col>
                            Endereço: <b>{!!dadosTransporte ? dadosTransporte.dadosTransporte?.[0]?.find(obj => obj.nome === 'log_logradouro')?.valor : ""}</b>
                        </Col>
                        <Col>
                            Município: <b>{!!dadosTransporte ? dadosTransporte.dadosTransporte?.[0]?.find(obj => obj.nome === 'cid_descricao')?.valor : ""}</b>
                        </Col>
                        <Col>
                            UF: <b>{!!dadosTransporte ? dadosTransporte.dadosTransporte?.[0]?.find(obj => obj.nome === 'est_sigla')?.valor : ""}</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/*<Row gutter={[8, 16]} className="m-t-16">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={iICMS} alt="Retenção ICMS" /> Retenção ICMS
                    </Typography.Title>
                    <Divider orientation="left" plain />
                </Col>
                <Col span={24}>
                    <Row align="middle" gutter={[8, 8]}>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            Valor do Serviço: <b>valorServico</b>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            Base Cálculo Retenção: <b>valorBCRetencao</b>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            Alíquota: <b>picmsRetido</b>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6} align="right">
                            ICMS Retido: <b>valorIcmsRetido</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[8, 16]} className="m-t-16">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={iInfo} alt="Veículo" /> Veículo
                    </Typography.Title>
                    <Divider orientation="left" plain />
                </Col>
                <Col span={24}>
                    <Row align="middle" gutter={[8, 8]}>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            Placa Veículo: <b>placaVeiculo</b>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            UF Veículo: <b>ufVeiculo</b>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            Placa Reboque: <b>placaReboque</b>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6} align="right">
                            UF Reboque: <b>ufReboque</b>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row gutter={[8, 16]} className="m-t-16">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={iVolumes} alt="Volumes" /> Volumes
                    </Typography.Title>
                    <Divider orientation="left" plain />
                </Col>
                <Col span={24}>
                    <Table url="" header={false} columns={[
                        {
                            title: '',
                            render: ({ nVol, qVol, esp, marca, pesoL, qVpesoBol, nLacre }) => (
                                <div>
                                    <Row gutter={[5, 0]} justify="space-between">
                                        <Col>
                                            Numeração: <b>{nVol}</b>
                                        </Col>
                                        <Col>
                                            Quantidade: <b>{qVol}</b>
                                        </Col>
                                        <Col>
                                            Espécie: <b>{esp}</b>
                                        </Col>
                                        <Col>
                                            Marca: <b>{marca}</b>
                                        </Col>
                                    </Row>
                                    <Row gutter={[5, 0]} justify="space-between">
                                        <Col>
                                            Peso Líquido: <b>{pesoL}</b>
                                        </Col>
                                        <Col>
                                            Peso Bruto: <b>{qVpesoBol}</b>
                                        </Col>
                                        <Col>
                                            Visualizar Lacres: <b>{nLacre} </b>
                                            <Button type="link" icon={<EyeOutlined />} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />
                </Col>
                </Row>*/}
        </div>
    );
}