import React, { useState, useEffect } from "react";
import { Form, Row, Col, Typography, Divider, Checkbox, Select, Button, Tag, Popover, Table, Upload, Input, InputNumber, Modal, Collapse } from "antd";
import { Link } from "react-router-dom";
import { PlusOutlined, AppstoreOutlined, FileImageOutlined, DeleteOutlined, DownloadOutlined, CheckOutlined, ExclamationCircleOutlined, SyncOutlined, SearchOutlined, EyeOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { InputPreco } from "../../components";
import { arredonda, info, validarEan } from "../../services/funcoes";

export default function TabGrade({ gerarEANProduto, formulario, formGrade, listaDeGrades, setListaDeGrades }) {

    const [{ ui, manutencao, parametrosEmpresa }, dispatch] = useStateValue();
    const [listaModalidadesGrades, setModalidadesGrades] = useState([]);
    const [listaModalidadeItens, setListaModalidadeItens] = useState([]);
    const [criarGrade, setCriarGrade] = useState(false);
    const [openModalImgGrade, setOpenModalImgGrade] = useState(false);
    const [dadosImgGrade, setDadosImgGrade] = useState([]);
    const [detalhesModalImg, setDetalhesModalImg] = useState({});
    const [itensGradeSelecionados, setItensGradeSelecionados] = useState([]);
    const [modalidadesAdicionadas, setModalidadesAdicionadas] = useState([]);
    const [previewImage, setPreviewImage] = useState("");
    const [previewVisible, setPreviewVisible] = useState(false);
    const inputPesquisa = React.createRef();
    const [open, setOpen] = useState(false);
    useEffect(() => carregarDados(), []);

    const togglePesquisa = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                if (!!inputPesquisa && !!inputPesquisa.current) {
                    inputPesquisa.current.focus();
                }
            }, 200);
        }
    }, [open]);

    function carregarDados() {
        api.get(`ModalidadeGrade/ListarModalidade?PageSize=500`).then(res => {
            if (res.status === 200) setModalidadesGrades(res.data.items);
        }).catch((erro) => console.error(erro));
    };

    useEffect(() => {
        setItensGradeSelecionados([]);
        setModalidadesAdicionadas([]);
    }, [manutencao.dados]);

    function atualizarFormGrade(listaGrades = [...listaDeGrades]) {
        formGrade.resetFields();
        listaGrades.forEach((item, indice) => {
            item.key = indice + 1;
            formGrade.setFieldsValue({
                ['prg_ean' + item.key]: item.prg_ean,
                ['pre_custoultimacompra' + item.key]: (!!item.pre_custoultimacompra ? arredonda(item.pre_custoultimacompra, 2) : null),
                ['prg_markup' + item.key]: (!!item.prg_markup ? arredonda(item.prg_markup, 2) : null),
                ['lpi_valorvenda' + item.key]: (!!item.lpi_valorvenda ? arredonda(item.lpi_valorvenda, 2) : null),
                ['prg_codigoetiqueta' + item.key]: item.prg_codigoetiqueta
            });
        });
        setListaDeGrades(listaGrades);
    };

    function buscarModalidadeItem(modalidade) {
        if (modalidade) {
            api.get(`ModalidadeGrade/BuscarModalidade/${modalidade}`).then(res => {
                if (res.status === 200) {
                    setListaModalidadeItens(res.data.map((x, idx) => ({ filtrado: true, checked: false, ...x })));
                }
            }).catch((error) => console.error(error));
        } else {
            setListaModalidadeItens([]);
        }
        setItensGradeSelecionados([]);
    };

    function abrirModalImgGrade(record) {
        let detalhesModal = {};
        let dadosImg = [];
        detalhesModal.descricao = '';
        detalhesModal.key = record.key;
        record.modalidadesgrade.forEach((atributo, indice) => {
            detalhesModal.descricao += atributo.mgp_descricao + (indice + 1 === record.modalidadesgrade.length ? '' : ' / ');
        });
        setDetalhesModalImg(detalhesModal);
        if (record.imagens.length === 0) {
            setDadosImgGrade(dadosImg);
        } else {
            record.imagens.forEach((img, indice) => {
                dadosImg.push({ uid: indice + 1, url: (!!img.url ? img.url : img.conteudoImagem), principal: img.principal });
                if (indice + 1 === record.imagens.length) {
                    setDadosImgGrade(dadosImg);
                }
            });
        }
        setOpenModalImgGrade(true);
    };

    const onChange = ({ fileList: newFileList }) => {
        setDadosImgGrade(newFileList);
    };

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    const handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    function salvarDadosImgGrade() {
        let dadosGrade = [...listaDeGrades];
        let grade = dadosGrade.filter((item) => { return item.key === detalhesModalImg.key })[0];
        grade.imagens = [];
        if (dadosImgGrade.length === 0) {
            setListaDeGrades(dadosGrade);
        } else {
            dadosImgGrade.forEach((img, indice) => {
                let imgTemp = img;
                if (!(!!imgTemp.url)) {
                    imgTemp.url = imgTemp.thumbUrl;
                }
                if (!(!!imgTemp.principal)) {
                    imgTemp.principal = false;
                }
                grade.imagens.push(imgTemp);
                if (indice + 1 === dadosImgGrade.length) {
                    setListaDeGrades(dadosGrade);
                }
            });
        }
        setOpenModalImgGrade(false);
    };

    function calcularValorVendaPorMarkup(key) {
        let valorVenda = retornaPrecoPorMarkup(formGrade.getFieldValue(['pre_custoultimacompra' + key]), formGrade.getFieldValue(['prg_markup' + key]));
        formGrade.setFieldsValue({ ['lpi_valorvenda' + key]: valorVenda });
    };

    function retornaPrecoPorMarkup(custo, markup) {
        if (parseFloat(custo) > 0 && parseFloat(markup) > 0) return (parseFloat(custo) * parseFloat(markup)).toFixed(2);
        else return null;
    };

    function recalcularMarkupDoProduto(key) {
        if (parseFloat(formGrade.getFieldValue(['pre_custoultimacompra' + key])) > 0 && parseFloat(formGrade.getFieldValue(['lpi_valorvenda' + key])) > 0) {
            let markupCalculado = (parseFloat(formGrade.getFieldValue(['lpi_valorvenda' + key])) / parseFloat(formGrade.getFieldValue(['pre_custoultimacompra' + key]))).toFixed(2);
            formGrade.setFieldsValue({ ['prg_markup' + key]: markupCalculado })
        } else {
            formGrade.setFieldsValue({ ['prg_markup' + key]: parametrosEmpresa.markupProduto });
        }
    };

    function verificaEanExiste(key) {
        let ean = formGrade.getFieldValue(['prg_ean' + key]);
        if (!!ean) {
            let produto = (!!formulario.getFieldValue('pro_id') ? `&IdProduto=${formulario.getFieldValue('pro_id')}` : '');
            api.get(`Produto/verificaEanExistente?CodigoEan=${ean}${produto}`).then(res => {
                if (res.status === 200) {
                    if (!!res.data && res.data.existe == true) {
                        info("Este EAN já existe em outro produto!", "EAN já informado!");
                        formGrade.setFieldsValue({ ['prg_ean' + key]: null });
                    } else {
                        for (let campo in formGrade.getFieldsValue()) {
                            if (formGrade.getFieldsValue()[campo] === ean && campo !== ('prg_ean' + key)) {
                                info("Este EAN já existe em outro produto!", "EAN já informado!");
                                formGrade.setFieldsValue({ ['prg_ean' + key]: null });
                                return;
                            }
                        }
                    }
                }
                validaEanGrade();
            }).catch((erro) => console.error(erro));
        }
    };
    function validaEanGrade() {
        let key = 0;
        for (let campo in listaDeGrades) {
            key++;
            let codEan = formGrade.getFieldValue(['prg_ean' + key]);
            if (codEan !== "") {
                if (codEan.startsWith("7") && codEan.length === 13)
                    formulario.setFieldsValue({ pro_eaninvalido: false });
                else {
                    formulario.setFieldsValue({ pro_eaninvalido: true });
                    break
                }
            }
        }
    }

    const modalExcluirGrade = (grade) => {
        Modal.confirm({
            title: 'Remover Grade do Produto?',
            icon: <ExclamationCircleOutlined />,
            content: `Você deseja realmente remover esta grade do produto?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirGrade(grade);
            }
        });
    };

    function excluirGrade(grade) {
        if (!!grade.prg_id) {
            api.delete(`ProdutoGrade/ExcluirGrade/${grade.prg_id}`).then((res) => {
                if (res.data) {
                    Modal.success({
                        content: 'A Grade do produto foi removida com sucesso!',
                    });
                    atualizarGrade();
                } else {
                    modalInativarGrade(grade.prg_id);
                }
            }, (erro) => {
                modalInativarGrade(grade.prg_id);
            });
        } else {
            let listaGrades = [...listaDeGrades];
            let index = listaGrades.findIndex(i => i.key === grade.key);
            listaGrades.splice(index, 1);
            listaGrades.map((item, indice) => {
                item.key = indice + 1;
            });
            setListaDeGrades(listaGrades);

            Modal.success({
                content: 'A Grade do produto foi removida com sucesso!',
            });
            atualizarFormGrade(listaGrades);
        }
    };

    const modalInativarGrade = (codigoGrade) => {
        Modal.confirm({
            title: 'Não foi possível excluir a grade!',
            icon: <ExclamationCircleOutlined />,
            content: `A Grade deve conter referências com outros registros!`,
            okText: 'Inativar registro',
            cancelText: 'Não fazer nada',
            centered: true,
            onOk() {
                ativarInativarItemGrade(codigoGrade, false);
            }
        });

    };

    function ativarInativarItemGrade(codigoGrade, status) {
        api.put(`ProdutoGrade/ativarInativar?codigoGrade=${codigoGrade}&status=${status}`).then(
            (res) => {
                Modal.success({
                    content: `A Grade do produto foi ${status ? 'ativada' : 'inativada'} com sucesso!`,
                });
                validarGrade();
                atualizarGrade();
            }
        ).catch(
            (erro) => {
                error(`Não foi possivel ${status ? 'ativar' : 'inativar'} item da grade!`);
            }
        );
    };

    function validarGrade() {
        api.put(`ProdutoGrade/VerificarProdutoPossuiGradeAtiva?IdProduto=${formulario.getFieldsValue().pro_id}`).then(
            res => {
                formulario.setFieldsValue({ pro_ativo: res.data });
                if (res.data == false) {
                    api.put(`produto/AtivarInativar?IdProduto=${formulario.getFieldsValue().pro_id}&Ativo=false`).then((res) => {
                    })
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        );
    }

    function atualizarGrade() {
        api.get(`produto/ListarGrades?IdProduto=${formulario.getFieldsValue().pro_id}`).then(res => {
            if (res.status === 200) {
                let lista = res.data;
                if (lista.length > 0) {
                    lista.forEach((grade, index) => {
                        grade.key = index + 1;
                    });
                }
                setListaDeGrades(lista);
            }
        }).catch((erro) => console.error(erro));
    };

    function error(mensagem, title = 'Atenção!') {
        Modal.error({
            title: title,
            content: (
                <div>
                    <p>{mensagem}</p>
                </div>
            )
        });
    };

    function adicionaGradeProduto() {
        if (itensGradeSelecionados.length > 0) {
            let listaTemp = [...modalidadesAdicionadas];
            let modalidade = listaModalidadesGrades.filter((item) => { return item.mgp_id === formGrade.getFieldValue('mgp_id') })[0];
            if (modalidadesAdicionadas.filter((modalidade) => { return modalidade.mgp_id === formGrade.getFieldValue('mgp_id') }).length > 0) {
                info("A Grade '" + modalidade.mgp_descricao + "' já está adicionada ao produto.", "Grade já adicionada!");
            } else {
                let itens = [];
                listaModalidadeItens.map((item) => {
                    if (itensGradeSelecionados.indexOf(item.mgp_id.toString()) >= 0) {
                        itens.push(item);
                    }
                });
                listaTemp.push({ mgp_id: modalidade.mgp_id, mgp_descricao: modalidade.mgp_descricao, itensGrade: itens });
                listaTemp.map((item, indice) => {
                    item.key = indice + 1;
                });

                setModalidadesAdicionadas(listaTemp);
                formGrade.setFieldsValue({ mgp_id: undefined });
            }
        } else {
            info("Para adicionar a grade ao produto você deve selecionar ao menos um item de grade.", "Selecione ao menos um Item da Grade!");
        }
    };

    function excluirModalidade(itemGrade) {
        Modal.confirm({
            title: 'Remover Grade do Produto?',
            icon: <ExclamationCircleOutlined />,
            content: `Você deseja realmente remover a grade ${itemGrade.mgp_descricao} do produto?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                let lista = [...modalidadesAdicionadas];
                let index = lista.findIndex(i => i.key === itemGrade.key);
                lista.splice(index, 1);
                lista.map((item, indice) => {
                    item.key = indice + 1;
                });
                setModalidadesAdicionadas(lista);
                Modal.success({
                    content: 'A Grade do produto foi removida com sucesso.',
                });
            }
        });
    };

    function gerarGrade() {
        let listaGrade = [];
        let modalidades = [...modalidadesAdicionadas];
        let totalModalidadesSelecionadas = modalidades.length;
        let posicaoGradeProduto;
        if (totalModalidadesSelecionadas > 1) {
            let primeiraModalidade = modalidades.shift();
            let totalOcorrencias = 0;
            modalidades.forEach((value, key) => {
                if (totalOcorrencias === 0) {
                    totalOcorrencias = value.itensGrade.length;
                } else {
                    totalOcorrencias = totalOcorrencias * value.itensGrade.length;
                }
            });
            totalOcorrencias = totalOcorrencias * primeiraModalidade.itensGrade.length;
            for (let i = 1; i <= totalOcorrencias; i++) {
                listaGrade.push({
                    prg_ean: '',
                    prg_codigoetiqueta: '',
                    pre_custoultimacompra: formulario.getFieldValue().pre_custoultimacompra,
                    prg_markup: parseFloat(formulario.getFieldValue().pro_markup),
                    lpi_valorvenda: formulario.getFieldValue().lpi_valorvenda,
                    pre_qtde: null,
                    prg_ativa: true,
                    modalidadesgrade: [],
                    imagens: []
                });
            }
            posicaoGradeProduto = 0;
            for (let i = 0; i < primeiraModalidade.itensGrade.length; i++) {
                let itemModalidade = primeiraModalidade.itensGrade[i];
                for (let x = 0; x < (totalOcorrencias / primeiraModalidade.itensGrade.length); x++) {
                    listaGrade[posicaoGradeProduto].modalidadesgrade.push(itemModalidade);
                    posicaoGradeProduto++;
                }
            }
            let totalItensGradeAnterior = primeiraModalidade.itensGrade.length;
            let totalOcorrenciasGrade = 0;
            while (modalidades.length > 1) {
                let modalidadeDistribuicao = modalidades.shift();
                modalidades.forEach((value, key) => {
                    if (totalOcorrenciasGrade == 0) {
                        totalOcorrenciasGrade = value.itensGrade.length;
                    } else {
                        totalOcorrenciasGrade = totalOcorrenciasGrade * value.itensGrade.length;
                    }
                });
                posicaoGradeProduto = 0;
                for (let z = 0; z < totalItensGradeAnterior; z++) {
                    for (let i = 0; i < modalidadeDistribuicao.itensGrade.length; i++) {
                        let itemModalidade = modalidadeDistribuicao.itensGrade[i];
                        for (let x = 0; x < totalOcorrenciasGrade; x++) {
                            listaGrade[posicaoGradeProduto].modalidadesgrade.push(itemModalidade);
                            posicaoGradeProduto++;
                        }
                    }
                }
                totalItensGradeAnterior = modalidadeDistribuicao.itensGrade.length;
            }
            posicaoGradeProduto = 0;
            for (let i = 0; i < (totalOcorrencias / modalidades[0].itensGrade.length); i++) {
                modalidades[0].itensGrade.forEach((value, key) => {
                    listaGrade[posicaoGradeProduto].modalidadesgrade.push(value);
                    posicaoGradeProduto++;
                });
            }
        } else {
            modalidades[0].itensGrade.forEach((value, key) => {
                listaGrade.push({
                    prg_ean: '',
                    prg_codigoetiqueta: '',
                    pre_custoultimacompra: formulario.getFieldValue().pre_custoultimacompra,
                    prg_markup: parseFloat(formulario.getFieldValue().pro_markup),
                    lpi_valorvenda: formulario.getFieldValue().lpi_valorvenda,
                    pre_qtde: null,
                    prg_ativa: true,
                    modalidadesgrade: [value],
                    imagens: []
                });
            });
        }

        listaDeGrades.forEach((item, indice) => {
            listaGrade.push(item);
        });
        listaGrade.map((item, indice) => {
            item.key = indice + 1;
        });
        setListaDeGrades(listaGrade);
        setCriarGrade(false);
        setModalidadesAdicionadas([]);
        setItensGradeSelecionados([]);
        atualizarFormGrade(listaGrade);
    };

    function adicionarItensSelecionados(selecionados) {
        let listaSel = [];
        let listaTemp = [...listaModalidadeItens];
        let filtrados = listaTemp.filter((item) => { return item.filtrado });
        filtrados.map((item) => {
            item.checked = selecionados.indexOf(item.mgp_id.toString()) >= 0;
        });
        listaTemp.map((item) => {
            if (item.checked) {
                listaSel.push(item.mgp_id.toString());
            }
        });
        setItensGradeSelecionados(listaSel);
        setListaModalidadeItens(listaTemp);
    };

    const autoTabProd = (event) => {
        if ((event.keyCode === 13 || event.keyCode === 9) || (!!event.which && (event.which === 13 || event.which === 9))) {
            if (!!event.target.form) {
                let elementos = event.target.form.querySelectorAll('[id^="prg_ean"]');
                for (let key in elementos) {
                    if (!!elementos[key].setAttribute && parseInt(key) >= 0) {
                        elementos[key].setAttribute('index2', key);
                    }
                }
                setTimeout(() => {
                    let index = parseInt(event.target.getAttribute('index2'));
                    if (!!elementos[index + 1]) {
                        elementos[index + 1].focus();
                    }
                }, 100);
            }
        }

    };

    return (
        <div>
            <Form form={formGrade} onKeyPress={autoTabProd} >
                <Row align="middle" justify="center" gutter={[0, 16]}>
                    <Col span={24}>
                        <Typography.Title level={3}>
                            <img src={require("../../assets/i-grade.png").default} alt="Dados da Grade" /> Dados da Grade
                        </Typography.Title>
                        <Divider plain />
                    </Col>
                    {!criarGrade && listaDeGrades.length === 0 &&
                        <Col span={24}>
                            <Typography.Text>
                                O recurso de Grades permite o controle dos produtos que podem ser comercializados contendo atributos semelhantes e/ou variáveis. Através deste recurso no sistema, é possível realizar o controle das vendas e estoque levando em consideração características específicas dos produtos, possibilitando a distinção de um mesmo produto através de atributos como cor, tamanho, tipo, voltagem, etc.
                                Para realizar a configuração dos possíveis atributos vá para a tela de&nbsp;
                                <Link to="/gradeProduto">
                                    <u>Modalidades de Grade de Produtos.</u>
                                </Link>
                            </Typography.Text>
                        </Col>
                    }
                    {!criarGrade && listaDeGrades.length === 0 &&
                        <Col>
                            <Button onClick={() => { setCriarGrade(true) }} type="primary" icon={<AppstoreOutlined />}>
                                Desejo Criar Grade para este Produto
                            </Button>
                        </Col>
                    }
                    {listaDeGrades.length > 0 &&
                        <Col className="btn-p">
                            <Row justify="end">
                                {!criarGrade &&
                                    <Col>
                                        <Button icon={<PlusOutlined />} onClick={() => setCriarGrade(true)} type="primary">Adicionar Modalidade</Button>
                                    </Col>
                                }
                                {criarGrade &&
                                    <Col>
                                        <Button icon={<EyeOutlined />} onClick={() => setCriarGrade(false)} type="primary">Ver Grades</Button>
                                    </Col>
                                }
                            </Row>
                        </Col>
                    }
                </Row>
                {criarGrade &&
                    <div className="m-t-8">
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={24} md={16} lg={18} xl={18}>
                                <Form.Item label="Modalidade da Grade" name="mgp_id" rules={[{ required: true, message: 'Selecione a Modalidade da Grade' }]}>
                                    <Select
                                        placeholder="Selecione a Modalidade da Grade"
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        onChange={(mod) => {
                                            buscarModalidadeItem(mod);
                                        }}
                                        options={listaModalidadesGrades.map((mod) => { return { label: `${mod.mgp_id} - ${mod.mgp_descricao}`, value: mod.mgp_id } })}
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={4} xl={4}>
                                <Form.Item name="selecionarTodos" className="t-mob">
                                    <Checkbox onChange={e => {
                                        if (e.target.checked) {
                                            let lista = [];
                                            listaModalidadeItens.map(item => { lista.push(item.mgp_id.toString()) });
                                            setItensGradeSelecionados(lista);
                                        } else {
                                            setItensGradeSelecionados([]);
                                        }
                                    }} valuePropName="checked"> Selecionar Todos </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.mgp_id !== currentValues.mgp_id}>
                            {({ getFieldValue }) => {
                                if (!!getFieldValue('mgp_id') && listaModalidadeItens.length > 0) return (
                                    <Row align="middle" gutter={[8, 8]}>
                                        <Col span={24}>
                                            <Collapse className="p-relative topGrade" accordion ghost destroyInactivePanel={true}>
                                                <Collapse.Panel key="1" showArrow={false} extra={
                                                    <Button type="primary" icon={<SearchOutlined />} onClick={togglePesquisa}>Buscar</Button>
                                                }>
                                                    <Form.Item name="pesquisaGrade">
                                                        <Input ref={inputPesquisa} placeholder="Localizar um item da grade..." type="text" onChange={(e) => {
                                                            let value = e.target.value;
                                                            let listaTemp = [...listaModalidadeItens];
                                                            listaTemp.map((item) => {
                                                                if (!!value) {
                                                                    item.filtrado = item.mgp_descricao.toLowerCase().indexOf(value.toLowerCase()) >= 0;
                                                                } else {
                                                                    item.filtrado = true;
                                                                }
                                                            });
                                                            setListaModalidadeItens(listaTemp);
                                                        }} />
                                                    </Form.Item>
                                                </Collapse.Panel>
                                            </Collapse>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label="Modalidades da Grade">
                                                <Checkbox.Group onChange={(lista) => { adicionarItensSelecionados(lista) }} value={itensGradeSelecionados} options={listaModalidadeItens.filter((item) => { return item.filtrado || !!!formGrade.getFieldValue().pesquisaGrade }).map((mod) => {
                                                    return { label: `${mod.mgp_descricao}`, value: `${mod.mgp_id}` }
                                                })} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                );
                                if (!!getFieldValue('mgp_id') && listaModalidadeItens.length === 0) return (
                                    <div>
                                        <Row justify="center" align="middle">
                                            <Col>
                                                <Typography.Text>
                                                    Não existem itens cadastrados para essa modalidade de grade!
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                        <Row justify="center" align="middle">
                                            <Col>
                                                <Typography.Text>
                                                    <Link to="/gradeProduto">
                                                        <Button type="link" htmlType="button">Clique aqui </Button>
                                                    </Link>
                                                    para cadastrar itens para essa modalidade.
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                    </div>
                                );
                            }}
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.mgp_id !== currentValues.mgp_id}>
                            {({ getFieldValue }) => {
                                if (!!getFieldValue('mgp_id') && listaModalidadeItens.length > 0) return (
                                    <Row align="middle" justify="center">
                                        <Col>
                                            <Button onClick={adicionaGradeProduto} type="primary" icon={<PlusOutlined />} htmlType="button">
                                                Adicionar
                                            </Button>
                                        </Col>
                                    </Row>
                                );
                            }}
                        </Form.Item>
                    </div>
                }
                {modalidadesAdicionadas.length > 0 &&
                    <div>
                        <Row gutter={[0, 16]}>
                            <Col span={24}>
                                <div className="tabela mt-dif">
                                    <Table showHeader={false} pagination={false} dataSource={modalidadesAdicionadas} columns={
                                        [
                                            {
                                                width: 75,
                                                align: 'center',
                                                render: ({ key }) => (
                                                    <Tag color="processing" className="w-100">
                                                        <b>{key}</b>
                                                    </Tag>
                                                ),
                                            }, {
                                                render: ({ mgp_descricao }) => (
                                                    <b>{mgp_descricao}</b>
                                                ),
                                            }, {
                                                render: ({ itensGrade }) => (
                                                    itensGrade.map((item) => (
                                                        <Tag color='default' className="m-r-5">
                                                            <b>{item.mgp_descricao}</b>
                                                        </Tag>
                                                    ))
                                                ),
                                            }, {
                                                title: '',
                                                dataIndex: '',
                                                key: 'x',
                                                align: 'center',
                                                width: 40,
                                                fixed: 'right',
                                                render: (itemGrade) => (
                                                    <div>
                                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                                            <Col>
                                                                <Button onClick={() => { excluirModalidade(itemGrade) }} icon={<DeleteOutlined />} />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ),
                                            }
                                        ]
                                    } />
                                </div>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="adicionarEtiquetas">
                                    <Checkbox valuePropName="checked">
                                        Adicionar etiquetas automaticamente de acordo com as quantidades de estoque da grade ao salvar o produto?
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Row justify="center">
                                    <Col>
                                        <Button onClick={() => gerarGrade()} type="primary" icon={<CheckOutlined />} htmlType="button">
                                            Gerar Grade
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                }
                {listaDeGrades.length > 0 && !criarGrade &&
                    <div className="tabela mt-dif">
                        <Table pagination={false} dataSource={listaDeGrades} columns={
                            [
                                {
                                    align: 'center',
                                    width: 75,
                                    render: ({ prg_ativa, key }) => (
                                        <Popover content={prg_ativa ? 'Ativo' : 'Inativo'} placement="right">
                                            <Tag color={prg_ativa ? 'processing' : 'default'} className="w-100">
                                                <b className="w-100">{key}</b>
                                            </Tag>
                                        </Popover>
                                    ),
                                }, {
                                    title: 'Itens da Grade',
                                    width: 130,
                                    render: ({ modalidadesgrade }) => (
                                        modalidadesgrade.map((item) => (
                                            <Tag color='default'>
                                                <b>{item.mgp_descricao}</b>
                                            </Tag>
                                        ))
                                    ),
                                }, {
                                    title: '',
                                    width: 100,
                                    render: (record) => (
                                        <div>
                                            <Button onClick={() => { abrirModalImgGrade(record) }} icon={<FileImageOutlined />}>
                                                Imagens
                                            </Button>
                                        </div>
                                    ),
                                }, {
                                    title: 'EAN/GTIN',
                                    render: ({ prg_ean, key }) => (
                                        <Row justify="space-between" gutter={[8, 0]}>
                                            <Col span={21}>
                                                <Form.Item name={'prg_ean' + key}>
                                                    <Input placeholder="Informe o EAN/GTIN" onBlur={() => verificaEanExiste(key)} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={3}>
                                                <Button block icon={<SyncOutlined />} onClick={() => gerarEANProduto('prg_ean' + key)} />
                                            </Col>
                                        </Row>
                                    ),
                                }, {
                                    title: 'Valor',
                                    align: 'right',
                                    render: ({ prg_codigoetiqueta, lpi_valorvenda, pre_custoultimacompra, prg_markup, key }) => (
                                        <div>
                                            <Row justify="space-between" gutter={[8, 0]}>
                                                <Col span={10}>
                                                    Custo:
                                                </Col>
                                                <Col span={14}>
                                                    <InputPreco onChange={() => calcularValorVendaPorMarkup(key)} placeholder="Informe o custo R$" name={'pre_custoultimacompra' + key} />
                                                </Col>
                                            </Row>
                                            <Row justify="space-between" gutter={[8, 0]}>
                                                <Col span={10}>
                                                    Markup:
                                                </Col>
                                                <Col span={14}>
                                                    <InputPreco onChange={() => calcularValorVendaPorMarkup(key)} cifrao={false} placeholder="Informe o Markup R$" name={'prg_markup' + key} />
                                                </Col>
                                            </Row>
                                            <Row justify="space-between" gutter={[8, 0]}>
                                                <Col span={10}>
                                                    Valor Venda:
                                                </Col>
                                                <Col span={14}>
                                                    <InputPreco onChange={() => recalcularMarkupDoProduto(key)} placeholder="Informe o valor R$" name={'lpi_valorvenda' + key} />
                                                </Col>
                                            </Row>
                                            <Row justify="space-between" gutter={[8, 0]}>
                                                <Col span={10}>
                                                    Cód. Etiqueta:
                                                </Col>
                                                <Col span={14}>
                                                    <Form.Item name={'prg_codigoetiqueta' + key}>
                                                        <Input maxLength={20} placeholder="Informe o Cód. Etiqueta" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            {!formulario.getFieldValue('pro_id') &&
                                                <Row justify="space-between" gutter={[8, 0]}>
                                                    <Col span={10}>
                                                        Estoque:
                                                    </Col>
                                                    <Col span={14}>
                                                        <Form.Item name={'pre_qtde' + key}>
                                                            <InputNumber placeholder="Qtde no estoque" />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    ),
                                }, {
                                    title: 'Ações',
                                    dataIndex: '',
                                    key: 'x',
                                    align: 'center',
                                    width: 65,
                                    render: (grade) => (
                                        <div>
                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                <Col>
                                                    {grade.prg_ativa &&
                                                        <Button onClick={() => modalExcluirGrade(grade)} icon={<DeleteOutlined />} />
                                                    }
                                                    {!grade.prg_ativa &&
                                                        <Popover content="Ativar?">
                                                            <Button onClick={() => ativarInativarItemGrade(grade.prg_id, true)} icon={<CheckOutlined />} />
                                                        </Popover>
                                                    }
                                                </Col>
                                            </Row>
                                        </div>
                                    ),
                                }
                            ]
                        } scroll={{ x: 900 }} />
                    </div>
                }
            </Form>
            <Modal title='Imagens da Grade do Produto' visible={openModalImgGrade} onOk={() => salvarDadosImgGrade()} onCancel={() => setOpenModalImgGrade(false)}>
                {detalhesModalImg.descricao}
                <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    onChange={onChange}
                    onPreview={handlePreview}
                    fileList={dadosImgGrade}
                >
                    <Row align="middle" justify="center">
                        <Col span={22}>
                            <Button type="link" block icon={<DownloadOutlined />}>
                                Clique Aqui
                            </Button>
                        </Col>
                    </Row>
                </Upload>
            </Modal>
            <Modal
                visible={previewVisible}
                footer={null}
                onCancel={() => setPreviewVisible(false)}
            >
                <img src={previewImage} alt="Imagem Produto" className="w-100" />
            </Modal>
        </div>
    );

}