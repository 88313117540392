import React from "react";
import { Form, Row, Col } from "antd";

import { FiltroSearch, FiltroOrdenacao, FiltroAtivo } from "../../components";

export default function PesquisaOrdenacao({ opcoesOrdenacao = [], ordem = opcoesOrdenacao[0].value, mostrarFiltroAtivo = false, labelInputFiltroAtivo = "", nameInputFiltroAtivo = "" }) {

    return (
        <div>
            <Form layout="vertical">
                <Row align="middle" gutter={[8, 0]}>
                    <Col xs={24}
                        sm={mostrarFiltroAtivo ? 10 : 17}
                        md={mostrarFiltroAtivo ? 7 : 17}
                        lg={mostrarFiltroAtivo ? 9 : 19}
                        xl={mostrarFiltroAtivo ? 10 : 19}
                        xxl={mostrarFiltroAtivo ? 10 : 19}
                    >
                        <FiltroSearch ordem={ordem} />
                    </Col>
                    <Col
                        xs={24}
                        sm={mostrarFiltroAtivo ? 7 : 7}
                        md={mostrarFiltroAtivo ? 7 : 7}
                        lg={mostrarFiltroAtivo ? 5 : 5}
                        xl={mostrarFiltroAtivo ? 8 : 5}
                        xxl={mostrarFiltroAtivo ? 8 : 5}
                    >
                        <FiltroOrdenacao opcoesOrdenacao={opcoesOrdenacao} />
                    </Col>
                    {mostrarFiltroAtivo && (
                        <Col xs={24} sm={7} md={9} lg={9} xl={6} xxl={6}>
                            <FiltroAtivo labelInput={labelInputFiltroAtivo} nameInput={nameInputFiltroAtivo} />
                        </Col>
                    )}
                </Row>
            </Form>
        </div>
    );

}