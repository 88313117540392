import React from "react";

import Planos from "./planos";
import { BreadcrumbAbrirChamado } from "../../components";

export default function Plano() {

    return (

        <div className="p-10">
            <BreadcrumbAbrirChamado />
            <Planos />
        </div>

    );
}