
import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Descriptions, Form, Select } from "antd";
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { SelectPaginado } from "../../../components";

import api from "../../../services/api";
import InputPreco from "../../inputPreco";
import { validaForm } from "../../../services/funcoes";

export default function ModalDevolucaoSubstituicaoTributaria({ exibirModal, fecharModal, dadosNfe, aoSalvar, serieFiscal, dadosNotaDevSt }) {

    const [formulario] = Form.useForm();
    const [operacaoDebCred, setOperacaoDebCred] = useState([]);

    useEffect(() => {
        if (exibirModal) {
            // api.get('OperacaoDebitoCredito/Listar?filtro=').then(
            //     (res) => {
            //         if (res.status === 200) {
            //             setOperacaoDebCred(res.data);
            //         }
            //     }
            // ).catch(
            //     (erro) => {
            //         console.log(erro);
            //     }
            // );
            dadosNotaDevSt.valorBaseIcmsDev = parseFloat(dadosNotaDevSt.valorBaseIcmsDev.toFixed(2));
            dadosNotaDevSt.valorIcmsDev = parseFloat(dadosNotaDevSt.valorIcmsDev.toFixed(2));
            dadosNotaDevSt.valorBaseIcmsStDev = parseFloat(dadosNotaDevSt.valorBaseIcmsStDev.toFixed(2));
            dadosNotaDevSt.valorIcmsStDev = parseFloat(dadosNotaDevSt.valorIcmsStDev.toFixed(2));
            formulario.setFieldsValue(dadosNotaDevSt);
        }
    }, [exibirModal]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
            }
        });
    };

    const listaValidacoes = [
        { nome: 'ser_id', obrigatorio: true, label: 'Série Fiscal' },
        { nome: 'operacaodebito', obrigatorio: true, label: 'Operação de Débito' },
        { nome: 'valorBaseIcmsStDev', obrigatorio: true, label: 'Valor Base de Cálculo da Substituição Tributária' },
        { nome: 'valorIcmsStDev', obrigatorio: true, label: 'Valor da Substituição Tributária Cálculada' },
        { nome: 'operacaocredito', obrigatorio: true, label: 'Operação de Crédito' },
        { nome: 'valorBaseIcmsDev', obrigatorio: true, label: 'Valor Base de Cálculo da Adjudicação de ICMS' },
        { nome: 'valorIcmsDev', obrigatorio: true, label: 'Valor da Adjudicação de ICMS' }
    ];

    async function validar(values) {
        
        if (validaForm(formulario, listaValidacoes)) {
            let dadosNotaDevSt = { ...values };
            dadosNotaDevSt.ntf_dataemissao = new Date();
            dadosNotaDevSt.operacaodebito = (await api.get(`OperacaoDebitoCredito/Buscar/${values.operacaodebito}`)).data;
            dadosNotaDevSt.operacaocredito = (await api.get(`OperacaoDebitoCredito/Buscar/${values.operacaocredito}`)).data;
            dadosNotaDevSt.emitirNfe = true;
            aoSalvar(dadosNotaDevSt);
        }
    };

    return (
        <Modal centered
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={() => formulario.submit()}
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            okText={
                <>
                    <CheckOutlined /> Emitir
                </>
            }
            width={850}
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="formModalDevolucaoSubstituicaoTributaria" form={formulario} onFinish={(values) => { validar(values) }}>
                    <Row>
                        <Col>
                            <b>
                                A nota fiscal devolvida, possui itens com substituição tributária!<br />
                                Neste caso devem ser emitidas 3(três) notas fiscais.<br />
                                Para concluir a emissão preencha os dados solicitados abaixo:
                            </b>
                        </Col>
                    </Row>
                    <Row className="m-t-16">
                        <Col>
                            <Descriptions size="small">
                                <Descriptions.Item label="Operação para NF-e de Débito de Substituição Tributária. A NF-e será emitida para a empresa">
                                    <b>{dadosNfe.dadosNfeDevolucao.pes_nome}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <Form.Item label="Série Fiscal" name="ser_id" rules={[{ required: true, message: 'Selecione a Série Fiscal' }]}>
                                <Select placeholder="Selecione a Série Fiscal" allowClear={true} showSearch optionFilterProp="children">
                                    {serieFiscal.map(serie => (
                                        <Select.Option value={serie.ser_id} key={serie.ser_id}>{serie.ser_serie}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[0, 8]}>
                        <Col span={24}>

                            <Form.Item label="Operação de Débito" name="operacaodebito" rules={[{ required: true, message: 'Selecione a Operação de Débito' }]}>
                                <SelectPaginado url="OperacaoDebitoCredito/Listar" placeholder="Selecione a Operação de Débito" form={formulario} name="operacaodebito" conteudo={
                                    odc => (<Select.Option value={odc.odc_id} key={odc.odc_id}>{odc.odc_descricao}</Select.Option>)
                                } />
                            </Form.Item>
                            {/* <Form.Item label="Operação de Débito" name="operacaodebito">
                                <Select placeholder="Selecione a Operação de Débito" allowClear={true} showSearch optionFilterProp="children">
                                    {operacaoDebCred.map(odc => (
                                        <Select.Option value={odc.odc_id} key={odc.odc_id}>{odc.odc_descricao}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item> */}
                        </Col>
                    </Row>
                    <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <InputPreco name="valorBaseIcmsStDev" label="Valor Base de Cálculo da Substituição Tributária (R$)" placeholder="Informe Valor Base de Cálculo da Substituição Tributária" />
                        </Col>
                    </Row>
                    <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <InputPreco name="valorIcmsStDev" label="Valor da Substituição Tributária Cálculada (R$)" placeholder="Informe Valor da Substituição Tributária Cálculada" />
                        </Col>
                    </Row>
                    <Row align="middle" justify="center">
                        <Col>
                            <Descriptions size="small">
                                <Descriptions.Item>
                                    Operação para NF-e de Adjudicação de Crédito de ICMS. A NF-e será emitida para o emitente da NF-e!
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <Form.Item label="Operação de Crédito" name="operacaocredito" rules={[{ required: true, message: 'Selecione a Operação de Crédito' }]}>
                                <SelectPaginado url="OperacaoDebitoCredito/Listar" placeholder="Selecione a Operação de Crédito" form={formulario} name="operacaocredito" conteudo={
                                    odc => (<Select.Option value={odc.odc_id} key={odc.odc_id}>{odc.odc_descricao}</Select.Option>)
                                } />
                            </Form.Item>
                            {/* <Form.Item label="Operação de Crédito" name="operacaocredito">
                                <Select placeholder="Selecione a Operação de Crédito" allowClear={true} showSearch optionFilterProp="children">
                                    {operacaoDebCred.map(odc => (
                                        <Select.Option value={odc.odc_id} key={odc.odc_id}>{odc.odc_descricao}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item> */}
                        </Col>
                    </Row>
                    <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <InputPreco name="valorBaseIcmsDev" label="Valor Base de Cálculo da Adjudicação de ICMS (R$)" placeholder="Informe Valor Base de Cálculo da Adjudicação de ICMS" />
                        </Col>
                    </Row>
                    <Row gutter={[0, 8]}>
                        <Col span={24}>
                            <InputPreco name="valorIcmsDev" label="Valor da Adjudicação de ICMS (R$)" placeholder="Informe Valor da Adjudicação de ICMS" />
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal >
    );
}