import React, { useEffect } from "react";
import { Form, Row, Col, Input, Checkbox, notification } from "antd";

import api from '../../services/api';
import { FormGW } from "../../components";
import { useStateValue } from '../../state';
import { listagemActions } from "../../actions";

export default function ManutencaoGrupoProduto({ formulario, carregando, aoSalvar }) {

    const [{ manutencao }, dispatch] = useStateValue();

    const salvarManutencao = (values) => {

        carregando(true);
        let retorno;
        let enderecoUrl = 'GrupoProduto/';

        if (values.gru_id) {
            enderecoUrl += `Editar`;
            api.put(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "Os dados do Grupo foram salvos com sucesso!" });
                    retorno = res.data.gru_id;
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    atualizarPagina();
                    carregando(false);
                    aoSalvar(retorno);
                }
            );

        } else {
            enderecoUrl += "Incluir"
            api.post(enderecoUrl, values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "Os dados do Grupo foram salvos com sucesso!" });
                    retorno = res.data.gru_id;
                }
            }, err => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
            }).finally(
                () => {
                    atualizarPagina();
                    carregando(false);
                    aoSalvar(retorno);
                }
            );
        };
    }

    useEffect(() => {
        if (!!manutencao.dados && manutencao.dados.duplicar) {
            formulario.setFieldsValue({ gru_id: null });
        }
    }, [manutencao.dados]);

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+gru_nome' } });
    };

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoGrupoProduto" onFinish={salvarManutencao}>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={19} lg={20} xl={20}>
                    <Form.Item name="gru_id" hidden />
                    <Form.Item label="Descrição do Grupo" name="gru_nome" rules={[{ required: true, message: 'Informe a Descrição do Grupo' }]}>
                        <Input placeholder="Informe a Descrição do Grupo" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={6} md={5} lg={4} xl={4}>
                    <Form.Item name="gru_ativo" initialValue={true} valuePropName="checked"
                        hidden={(!(formulario.getFieldValue(['gru_id']) > 0))} className="t-mob-573">
                        <Checkbox>Grupo Ativo?</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </FormGW>
    );

}