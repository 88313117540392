import React, { useEffect } from "react";
import { Button, Result } from "antd";
import { BlockOutlined } from "@ant-design/icons";

import { AnaliseInadimplenciaCliente } from "..";
import { BreadcrumbAbrirChamado } from "../../components";

export default function AnaliseInadimplencia() {

    useEffect(() => {
        abrirNovaJanela();
    }, [])

    function abrirNovaJanela() {
        window.open(`AnaliseInadimplenciaCliente`, '_blank', 'scrollbars=yes,top=0,left=0,resizable=yes');
    };

    return (

        <div className="p-10">
            <BreadcrumbAbrirChamado />
            <div>
                <Result
                    title="Para melhor visualização dos dados, a tela de Análise de Inadimplência de Clientes foi aberta em uma nova janela em seu navegador.
                    Caso a tela não abriu automáticamente, verifique se o bloqueador de popups do navegador não bloqueou a mesma."
                    extra={
                        <Button onClick={() => { abrirNovaJanela() }} type="primary" icon={<BlockOutlined />} size="middle">
                            Abrir Tela Novamente
                        </Button>
                    }
                />
            </div>
        </div>

    );

}