import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, Button, Collapse, Tag, Popover, Modal, notification } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined, SearchOutlined, DeleteOutlined, EyeOutlined, CheckCircleOutlined, ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import DrawerContaPagar from './drawer';
import { useStateValue } from '../../state';
import { FormatDate, FormatNumber } from "../../ValueObjects";
import { ModalListarParcelas } from "../../components/modals/";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, TabelaDados, Data, FiltroSearch, ComboData } from "../../components";

export default function ContaPagar() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [openPesquisaAvancada, setOpenPesquisaAvancada] = useState(false);
    const [dataPesquisa, setDataPesquisa] = useState(new Date().toLocaleDateString());
    const [dataAtual] = useState(new Date());
    const [parametrosContasPagar] = Form.useForm();
    const [listaStatusParcela, setListaStatusParcela] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [parcelas, setParcelas] = useState([]);

    const togglePesquisa = () => {
        setOpenPesquisaAvancada(!openPesquisaAvancada);
    };

    const modalNovaConta = (item) => {
        Modal.confirm({
            title: 'Conta estornada com sucesso!',
            icon: <CheckCircleOutlined />,
            content: `Deseja criar uma nova conta com base nesta conta estornada?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                api.get(`ContasPagarReceber/Buscar/${item.cpr_id}`).then(
                    res => {
                        let dadosTemp = res.data;
                        dadosTemp.valortotal = 0;
                        dadosTemp.cpr_datainclusao = moment(res.data.cpr_datainclusao);
                        dadosTemp.cpr_dataprimeiropagamento = moment(res.data.cpr_dataprimeiropagamento);
                        res.data.parcelas.forEach(parcela => {
                            dadosTemp.valortotal += parcela.prc_valor;
                        });
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: dadosTemp } });
                        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });

                    }
                );
            },
            onCancel() {
                dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pes_nome' } });
            }
        });
    };

    const modalEstornar = (item) => {
        Modal.confirm({
            title: 'Estornar Parcela',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja estornar a conta de ${item.pes_nome} no valor de ${FormatNumber(item.prc_valor, true)}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                api.post(`ContasPagarReceber/CancelarContaPagarReceber?ContaPagarReceberId=${item.cpr_id}`).then(
                    res => {
                        if (res.data.cpr_estornado === true) {
                            modalNovaConta(item);
                        } else {
                            Modal.success({
                                title: 'Não foi possivel estornar a Conta!',
                                icon: <ExclamationCircleOutlined />,
                                content: `Existe pagamentos referentes a esta conta`,
                                okText: 'OK'
                            })
                        }
                    }
                ).catch(
                    error => {
                        notification.warn({ message: 'Aviso!', description: !!error.response.data  ? error.response.data : 'Não foi possivel estonar a conta!' })
                    }
                )
            }
        });
    };

    useEffect(() => {
        api.get(`Enum/Listar?nome=StatusParcela`).then(res => {
            setListaStatusParcela(res.data);
        });
        parametrosContasPagar.setFieldsValue({ dataInicial: moment(new Date()), dataFinal: moment(new Date()) });
    }, []);

    useEffect(() => {
        carregarLista();
    }, [dataPesquisa]);

    function carregarLista() {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&tipoMovimentacao=0&dataInicial=${FormatDate(dataPesquisa)}&dataFinal=${FormatDate(dataAtual.toLocaleDateString())}&statusParcela=0,1,2,3`, ordem: '+pes_nome' } });
    }

    function aplicarFiltrosAvancados() {
        let filtros = '&tipoMovimentacao=0';
        filtros += `&dataInicial=${(!!parametrosContasPagar.getFieldValue().dataInicial ? moment(parametrosContasPagar.getFieldValue().dataInicial).format('YYYY-MM-DD') : FormatDate(dataAtual))}`;
        filtros += `&dataFinal=${(!!parametrosContasPagar.getFieldValue().dataFinal ? moment(parametrosContasPagar.getFieldValue().dataFinal).format('YYYY-MM-DD') : FormatDate(dataAtual))}`;
        filtros += `&statusParcela=${(!!parametrosContasPagar.getFieldValue().statusParcela ? parametrosContasPagar.getFieldValue().statusParcela.join(',') : '0,1,2,3')}`;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: filtros } });
    };

    function retornarStatus(prc_status) {
        if (prc_status === 0) {
            return 'Pendente'
        } else if (prc_status === 1) {
            return 'Rcto. Parcial'
        } else if (prc_status === 2) {
            return 'Liquidada'
        } else if (prc_status === 3) {
            return 'Reparcelada'
        }
    };

    function buscarParcelas(dados) {
        if (!!dados.cpr_id) {
            setOpenModal(true);
            api.get(`Parcela/ListarParcelas?IdContaPagarReceber=${dados.cpr_id}&Estornado=false&PagarOuReceber=0&PageSize=100&Order=+prc_numeroparcela`).then(res => {
                res.data.items.forEach(parc => { parc.editando = false });
                setParcelas(res.data.items);
            });
        }
    };

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Form layout="vertical">
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={18} xl={19} xxl={20}>
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={17} md={17} lg={18} xl={18}>
                                <FiltroSearch />
                            </Col>
                            <Col xs={24} sm={7} md={7} lg={6} xl={6}>
                                <ComboData form={parametrosContasPagar} eventoAoAlterar={() => {
                                    parametrosContasPagar.setFieldsValue({ dataFinal: moment(new Date()) });
                                    parametrosContasPagar.setFieldsValue({ dataInicial: moment(parametrosContasPagar.getFieldValue().dataPesquisa) });
                                    aplicarFiltrosAvancados();
                                }} />
                                {/* <PesquisaData setDataPesquisa={setDataPesquisa} dataPesquisa={dataPesquisa} /> */}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            <Form layout="vertical" name="formParametrosContasPagar" form={parametrosContasPagar}>
                <Row gutter={[0, 24]}>
                    <Col span={24}>
                        <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                            <div className="colDet"></div>
                            <Collapse.Panel key="1" showArrow={false} extra={
                                <Button type="primary" onClick={togglePesquisa} block>
                                    {openPesquisaAvancada ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                </Button>
                            }>
                                <Row gutter={[8, 0]}>
                                    <Col xs={24} sm={8} md={8} lg={6} xl={6} xxl={6}>
                                        <Form.Item label="Situação da Parcela" name="statusParcela">
                                            <Select mode="multiple" showSearch optionFilterProp="children" placeholder="Situação">
                                                {listaStatusParcela.map(item => (
                                                    <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={6} md={6} lg={4} xl={3} xxl={3}>
                                        <Data label="Data Incial" name="dataInicial" />
                                    </Col>
                                    <Col xs={24} sm={6} md={6} lg={4} xl={3} xxl={3}>
                                        <Data label="Data Final" name="dataFinal" />
                                    </Col>
                                    <Col xs={24} sm={12} md={12} lg={24} xl={7} xxl={5} className="btn-mob">
                                        <Row gutter={[8, 8]} justify="end">
                                            <Col xs={24} sm={12} md={12} lg={4} xl={12} xxl={12}>
                                                <Button icon={<CloseOutlined />} block>
                                                    Remover Filtros
                                                </Button>
                                            </Col>
                                            <Col xs={24} sm={12} md={12} lg={4} xl={12} xxl={12}>
                                                <Button type="primary" onClick={() => aplicarFiltrosAvancados()} icon={<SearchOutlined />} block>
                                                    Aplicar Filtros
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="ContasPagarReceber/ListarPagamentoRecebimento" colunas={[
                    {
                        width: 105,
                        title: "Status",
                        render: ({ prc_status }) => (
                            <Popover content={() => {
                                if (prc_status === 0 || prc_status === 1 || prc_status === 3) {
                                    return 'Parcela Pendente'
                                } else if (prc_status === 2) {
                                    return 'Parcela Liquidada'
                                }
                            }} placement="right">
                                <Tag color={prc_status === 0 || prc_status === 1 || prc_status === 3 ? 'red' : 'green'} className="w-100">
                                    <b>{retornarStatus(prc_status)}</b>
                                </Tag>
                            </Popover>
                        ),
                    }, {
                        title: 'Nº Doc.',
                        width: 80,
                        align: 'center',
                        render: ({ cpr_numerodocumento }) => (
                            <Tag color="processing" className="w-100">
                                <b>{cpr_numerodocumento || `N/D`}</b>
                            </Tag>
                        ),
                    }, {
                        title: 'Fornecedor',
                        width: 330,
                        render: ({ pes_nome }) => (
                            <div>
                                <b>{pes_nome}</b>
                            </div>
                        ),
                    }, {
                        title: 'Vencimento',
                        width: 80,
                        render: ({ prc_datavencimento }) => (
                            <div>
                                <b>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                            </div>
                        ),
                    }, {
                        title: 'Observação',
                        width: 300,
                        render: ({ cpr_observacao }) => (
                            <div>
                                <b>{cpr_observacao}</b>
                            </div>
                        ),
                    }, {
                        title: 'Valor (R$)',
                        align: 'right',
                        render: ({ prc_valor }) => (
                            <div>
                                <b>{FormatNumber(prc_valor, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        fixed: 'right',
                        width: 65,
                        render: (record, dados) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button onClick={() => buscarParcelas(record)} icon={<EyeOutlined />} />
                                    </Col>
                                    <Col>
                                        <Button icon={<DeleteOutlined />} onClick={() => modalEstornar(dados)} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} expandir={({ cpr_descricao, ntf_numero, prc_numeroparcela, saldo }) => {
                    return (
                        <div>
                            <Row justify="space-between">
                                <Col>
                                    Obs.: <b>{cpr_descricao || ``}</b>
                                </Col>
                                <Col>
                                    Nota Nº: <b>{ntf_numero || `Não Informado`}</b>
                                </Col>
                                <Col>
                                    Parcela Nº: <b>{prc_numeroparcela || `Não Informado`}</b>
                                </Col>
                                <Col>
                                    Saldo Devedor (R$): <b>{FormatNumber(saldo, true)}</b>
                                </Col>
                            </Row>
                        </div>
                    )
                }}
                />
            </div>
            <DrawerContaPagar />
            <ModalListarParcelas exibirModal={openModal} fecharModal={() => setOpenModal(false)} parcelas={parcelas} setParcelas={setParcelas} />
        </div>
    );
}

