import React, { useEffect, useState } from 'react';
import { Row, Col, Tag, Popover, notification, Modal, Dropdown, Button, Menu, Form, Collapse, Radio } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, MoreOutlined, CloudUploadOutlined, ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import DrawerVendedor from './drawer';
import { useStateValue } from '../../state';
import { FormatFone, FormatarCPF } from "../../ValueObjects";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { PesquisaOrdenacao, TabelaDados, Editar, Excluir, BotaoIncluirDados, BotaoAbrirChamado, BreadcrumbPage, PesquisaAvancada } from "../../components";
import { Link } from 'react-router-dom';

export default function Vendedor() {

    const [{ listagem, manutencao }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [editando, setEditando] = useState(false);
    const [open, setOpen] = useState(false);

    const menuOutros = () => {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Button type="text" icon={<CloudUploadOutlined />} onClick={() => {
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { entidade: 4 } } });
                    }}>
                        <Link to="/importarPessoa"> Importar Vendedor </Link>
                    </Button>
                </Menu.Item>
            </Menu >
        )
    };

    const togglePesquisa = () => {
        setOpen(!open);
    };

    useEffect(() => {
        let _ativo = !!!form.getFieldsValue().vendedorAtivo ? 1 : form.getFieldsValue().vendedorAtivo;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&ativo=${_ativo}`, filtro: '', ordem: '+pes_nome' } })
        form.setFieldsValue({ vendedorAtivo: 1 });
    }, []);

    function editarVendedor(valores) {
        setEditando(true);
        valores.pef_datanascimento = !!valores.pef_datanascimento ? moment(valores.pef_datanascimento) : null;
        valores.pes_datainclusao = !!valores.pes_datainclusao ? moment(valores.pes_datainclusao) : null;
        valores.pes_dataalteracao = !!valores.pes_dataalteracao ? moment(valores.pes_dataalteracao) : null;
        valores.pef_dataalteracao = !!valores.pef_dataalteracao ? moment(valores.pef_dataalteracao) : null;
        valores.pef_rgdataexpedicao = !!valores.pef_rgdataexpedicao ? moment(valores.pef_rgdataexpedicao) : null;
        valores.id_rguf = valores.pef_rguf
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: valores } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function excluirRegistro(vendedor) {
        api.delete(`Vendedor/Excluir/${vendedor.ven_id}`).then(
            (res) => {
                notification.success({ message: 'Aviso', description: 'vendedor excluído com sucesso!' });
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&ativo=${form.getFieldValue().vendedorAtivo}`, filtro: '', ordem: '+pes_nome' } });
            }).catch(
                (erro) => {
                    console.error(erro);
                    modalInativarRegistro(vendedor);
                })
    };

    function inativarRegistro(vendedor) {
        api.put(`Vendedor/InativarVendedor/${vendedor.ven_id}`).then((res) => {
            if (res.status === 200)
                dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros:  `&ativo=${form.getFieldValue().vendedorAtivo}`, filtro: '', ordem: '+pes_nome' } });
        })
    };

    const modalExcluirRegistro = (vendedor) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir o vendedor ${vendedor.ven_id}-${vendedor.pes_nome} ?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(vendedor);
            }
        });
    };

    const modalInativarRegistro = (vendedor) => {
        Modal.confirm({
            title: 'Não foi possível excluir o vendedor!',
            icon: <ExclamationCircleOutlined />,
            content: `Deve conter referências com outros registros,
            Deseja inativar o vendedor ${vendedor.ven_id}-${vendedor.pes_nome} ?`,
            okText: 'Inativar',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                inativarRegistro(vendedor);
            }
        });
    };

    function retornoPesquisaAvancada(expressao) {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&ativo=${form.getFieldValue().vendedorAtivo}&filtroAdicional=${btoa(expressao)}`, ordem: '+pes_nome' } })
    };

    function limparFiltroPesquisa() {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&ativo=${form.getFieldValue().vendedorAtivo}`, ordem: '+pes_nome' } })
    };

    return (
        <div className="p-10">
            <Row align="middle" justify="space-between" className="col-pagina-breadcrumb" gutter={[8, 8]}>
                <Col className="col-w-100">
                    <BreadcrumbPage />
                </Col>
                <Col className="col-mob-100">
                    <Row align="middle" justify="end">
                        <Col>
                            <BotaoAbrirChamado />
                        </Col>
                        <Col>
                            <BotaoIncluirDados />
                        </Col>
                        <Col>
                            <Dropdown overlay={menuOutros()} trigger={['click']}>
                                <Button size="middle" onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Form layout="vertical" name="formularioPesqAvancadaVendedor" form={form} onFinish={retornoPesquisaAvancada}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={16}>
                        <PesquisaOrdenacao opcoesOrdenacao={[
                            { label: "Nome A - Z", value: "+pes_nome" },
                            { label: "Nome Z - A", value: "-pes_nome" },
                            { label: "Código crescente", value: "+ven_id" },
                            { label: "Código decrescente", value: "-ven_id" }
                        ]} />
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9} xl={6}>
                        <Form.Item label="Selecione Status do Vendedor" name="vendedorAtivo">
                            <Radio.Group onChange={() => retornoPesquisaAvancada()}>
                                <Radio value={2}>Todos</Radio>
                                <Radio value={1}>Ativo</Radio>
                                <Radio value={0}>Inativo</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                            <div className="colDet"></div>
                            <Collapse.Panel key="1" showArrow={false} extra={
                                <Button type="primary" onClick={togglePesquisa} block>
                                    {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                </Button>
                            }>
                                <PesquisaAvancada funcaoRetorno={(dados) => retornoPesquisaAvancada(dados)} funcaoLimpar={() => { limparFiltroPesquisa() }} form={form} telaFiltro={6} />
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>

            </Form>
            <div className="tabela">
                <TabelaDados url="/Vendedor/Listar" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ ven_id, ven_ativo }) => (
                            <Popover content={ven_ativo ? "Ativo" : "Inativo"} placement="right" className="w-100">
                                <Tag color={ven_ativo ? 'processing' : 'default'}>
                                    <b>{ven_id}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Vendedor', key: 'pes_nome',
                        render: ({ pes_nome }) => (
                            <div>
                                <b>{pes_nome}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'CPF',
                        width: 138,
                        render: ({ pef_cpf }) => (
                            <div>
                                <b>{FormatarCPF(pef_cpf) || 'Não Informado'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'RG',
                        width: 120,
                        render: ({ pef_rg }) => (
                            <div>
                                <b>{pef_rg || 'Não Informado'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Telefone',
                        width: 120,
                        render: ({ psc_numero }) => (
                            <div>
                                <b>{FormatFone(psc_numero) || 'Não Informado'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Endereço',
                        render: ({ log_logradouro, pee_numero, bai_nome, cep_cep, cid_descricao, est_sigla }) => (
                            <div>
                                <b>{log_logradouro || 'Não Informado'}{(!!pee_numero) ? (', ' + pee_numero) : ''}{!!(bai_nome) ? (', ' + bai_nome) : ''} {!!(cep_cep) ? ('- ' + cep_cep) : ''} {!!(cid_descricao) ? ('- ' + cid_descricao) : ''} {!!(est_sigla) ? ('- ' + est_sigla) : ''}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        align: 'center',
                        fixed: 'right',
                        key: 'x',
                        width: 65,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Editar onClick={() => editarVendedor(record)} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>
                                        <Excluir icon={<DeleteOutlined />} onClick={() => modalExcluirRegistro(record)} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]}
                    expandir={({ pem_email }) => {
                        return (
                            <div>
                                <Row gutter={[16, 0]}>
                                    <Col>
                                        E-mail: <b>{pem_email || 'Não Informado'}</b>
                                    </Col>
                                </Row>
                            </div>
                        );
                    }}
                />
            </div>
            <DrawerVendedor editando={{ editando, setEditando }} />
        </div>
    )
}