import React, { useState, useEffect } from "react";
import { Form, Row, Col, Checkbox, Input, Select, notification, Radio } from "antd";


import api from '../../services/api';
import { FormGW } from "../../components";
import { useStateValue } from '../../state';
import { listagemActions } from "../../actions";
import { isObjetoDiffVazio } from "../../services/funcoes";
//import { listagemActions, manutencaoActions } from "../../actions";

export default function ManutencaoSerieFiscal({ formulario, carregando, aoSalvar }) {

    const [listaModelo, setListaModelo] = useState([]);
    const [{ manutencao }, dispatch] = useStateValue();
    const [bloquearCampos, setBloquearCampos] = useState(false);

    useEffect(() => {
        api.get(`ModeloDocumentoFiscal/ListarTodos`).then(res => {
            setListaModelo(res.data);
        })
    }, [])

    useEffect(() => {
        if (!!manutencao && isObjetoDiffVazio(manutencao.dados)) {
            setBloquearCampos(true);
        } else {
            setBloquearCampos(false);
        }
    }, [manutencao.dados]);

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ser_serie' } });
    };

    function salvarManutencao(values) {
        carregando(true);
        if (values.ser_id) {
            editarSerie(values);
        } else {
            incluirSerie(values);
        };
    }

    function incluirSerie(values) {
        api.post(`SerieFiscal/Incluir`, values).then(res => {
            notification.success({ message: 'Operação concluída', description: "Os dados da Série Fiscal foram salvos com sucesso!" });
        }).catch(
            erro => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
            }).finally(() => {
                atualizarPagina();
                carregando(false);
                aoSalvar();
            });
    }

    function editarSerie(values) {
        api.put(`SerieFiscal/Editar`, values).then(res => {
            notification.success({ message: 'Operação concluída', description: "Os dados da Série Fiscal foram salvos com sucesso!" });
        }).catch(
            erro => {
                notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
            }).finally(() => {
                atualizarPagina();
                carregando(false);
                aoSalvar();
            });
    }

    return (
        <FormGW layout="vertical" form={formulario} name="manutencaoSerieFiscal" onFinish={salvarManutencao}>
            <Row gutter={[8, 0]}>
                <Col xs={24} sm={24} md={8} lg={9} xl={9}>
                    <Form.Item name="ser_id" hidden />
                    <Form.Item label="Descrição da Série" name="ser_serie" rules={[{ required: true, message: 'Informe a Descrição da Série' }]}>
                        <Input maxlength={10} placeholder="Informe a Descrição da Série" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={9} xl={9}>
                    <Form.Item label="Série Fiscal do Documento" name="ser_numero">
                        <Input maxlength={3} disabled={bloquearCampos} placeholder="Informe a Série Fiscal do Documento" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                    <Form.Item label="Emitente do Documento" name="ser_emitentedocumento" rules={[{ required: true, message: 'Informe o Emitente do Documento' }]} initialValue={0}>
                        <Radio.Group buttonStyle="solid" optionType="button" disabled={bloquearCampos} className="col-radio-button">
                            <Radio.Button value={0}>Emissão Própria</Radio.Button>
                            <Radio.Button value={1}>Emissão Terceiros</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                    <Form.Item label="Último Número Emitido" name="ser_ultimonumemitido">
                        <Input placeholder="Informe o Número do Último Documento Emitido" />
                    </Form.Item>
                </Col>
                <Col xs={16} sm={16} md={12} lg={12} xl={12}>
                    <Form.Item label="Modelo do Documento" name="mdf_id" rules={[{ required: true, message: 'Selecione um Modelo de Documentos' }]}>
                        <Select disabled={bloquearCampos}
                            showSearch={true}
                            options={listaModelo.map(item => {
                                return { label: `${item.mdf_id} - ${item.mdf_descricao}`, value: item.mdf_id }
                            })}
                            placeholder="Selecione um Modelo de Documentos" />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                    <Form.Item name="ser_ativo" valuePropName="checked" initialValue={true} className="t-mob">
                        <Checkbox>Série Ativa?</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
        </FormGW>
    );
}