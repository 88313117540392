import React, { useState, useEffect } from "react";
import { Row, Col, Button, Tag, Popover, Menu, Dropdown, notification, Modal, Radio, Form } from "antd";
import { EditOutlined, DeleteOutlined, MoreOutlined, ExclamationCircleOutlined, CloseCircleOutlined, FileSearchOutlined, ArrowLeftOutlined, ArrowRightOutlined, RetweetOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import { Link } from "react-router-dom";
import DrawerContaCorrente from './drawer';
import { useStateValue } from '../../state';
import { FormatNumber } from "../../ValueObjects";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";
import { BreadcrumbIncluirDados, PesquisaOrdenacao, TabelaDados, DrawerLancamentoTransferencia, DrawerManutencaoCaixa, Excluir, Editar, LancamentoEntrada, LancamentoSaida, LancamentoTransferencia } from "../../components";

export default function ContaCorrente() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);
    const [formPesquisa] = Form.useForm();

    useEffect(() => {
        let _ativo = !!!formPesquisa.getFieldsValue().statusAtivo ? 1 : formPesquisa.getFieldsValue().statusAtivo;
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&Ativo=${_ativo}`, filtro: '', ordem: '+ctc_descricao' } });
        formPesquisa.setFieldsValue({ statusAtivo: 1 });
    }, [])


    const modalInativarConta = (dados) => {
        Modal.confirm({
            title: `Não foi possível excluir a Conta Corrente ${dados.ctc_descricao}!`,
            icon: <CloseCircleOutlined />,
            content: `Deve conter referências com outros registros!`,
            okText: 'Inativar Registro',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                api.put(`ContaCorrente/InativarContaCorrente?idContaCorrente=${dados.ctc_id}`).then(
                    res => {
                        if (res.status === 200) {
                            dispatch({ type: listagemActions.CHANGE, data: {  outrosFiltros: `&Ativo=${formPesquisa.getFieldsValue().statusAtivo}`, ordem: '+ctc_descricao' } })
                            notification.success({ message: 'Operação concluída', description: "Conta inativada com sucesso!" });
                        }
                    });
            }
        });
    };

    const modalExcluir = (dados) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir a Conta Corrente: ${dados.ctc_id}?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                excluirRegistro(dados);
            }
        });
    };

    function aplicarFiltros() {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: `&Ativo=${formPesquisa.getFieldsValue().statusAtivo}`, ordem: '+ctc_descricao' } });
    }

    function abrirLancamentoEntradaSaida(operacao, record) {
        record.operacao = operacao;
        dispatch({ type: drawerActions.CHANGE, data: { showDrawerManutencaoCaixa: true } });
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
    };

    function abrirLancamentoTransferencia(record) {
        dispatch({ type: drawerActions.CHANGE, data: { showDrawerTransferencia: true } });
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
    };

    function abrirLancamentoCaixa(record) {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record.ctc_id } });
    }

    function menuTable(record) {
        return (
            <Menu className="text-right">
                <Menu.Item key="1">
                    <Link to="/lancamentoCaixa" onClick={() => abrirLancamentoCaixa(record)}> <FileSearchOutlined />Consultar Movimentação</Link>
                </Menu.Item>
                <Menu.Item key="2">
                    <LancamentoEntrada type="text" icon={<ArrowLeftOutlined />} onClick={() => abrirLancamentoEntradaSaida("2", record)}> Lançamento de Entrada</LancamentoEntrada>
                </Menu.Item>
                <Menu.Item key="3">
                    <LancamentoSaida type="text" icon={<ArrowRightOutlined />} onClick={() => abrirLancamentoEntradaSaida("1", record)}> Lançamento de Saída</LancamentoSaida>
                </Menu.Item>
                <Menu.Item key="4">
                    <LancamentoTransferencia type="text" icon={<RetweetOutlined />} onClick={() => {
                        abrirLancamentoTransferencia(record);
                    }}> Lançamento de Transferência</LancamentoTransferencia>
                </Menu.Item>
            </Menu>
        )
    };

    function excluirRegistro(dados) {
        Modal.confirm({
            title: 'Deseja deletar esta conta corrente?',
            icon: <ExclamationCircleOutlined />,
            onOk() {
                setCarregando(true);
                api.post(`ContaCorrente/Excluir?codigoContaCorrente=${dados.ctc_id}`).then((res) => {
                    if (res.status === 200) notification.success({ message: 'Operação concluída', description: "Os dados da Conta Corrente foram excluídos com sucesso!" });
                }, err => {
                    modalInativarConta(dados);
                }).finally(() => {
                    dispatch({ type: listagemActions.CHANGE, data: {  outrosFiltros: `&Ativo=${formPesquisa.getFieldsValue().statusAtivo}`,ordem: '+ctc_descricao' } });
                });
            }
        });
    }

    return (
        <div className="p-10">
            <BreadcrumbIncluirDados />
            <Form layout="vertical" name="formPesquisa" form={formPesquisa} onFinish={aplicarFiltros}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={18} xxl={18}>
                        <PesquisaOrdenacao opcoesOrdenacao={[
                            { label: "Nome A - Z", value: "+ctc_descricao" },
                            { label: "Nome Z - A", value: "-ctc_descricao" },
                            { label: "Código Crescente", value: "+ctc_id" },
                            { label: "Código Decrescente", value: "-ctc_id" }
                        ]} />
                    </Col>
                    <Col xs={24} sm={24} md={9} lg={9} xl={6}>
                        <Form.Item label="Selecione Status" name="statusAtivo">
                            <Radio.Group onChange={() => aplicarFiltros()}>
                                <Radio value={2}>Todos</Radio>
                                <Radio value={1}>Ativo</Radio>
                                <Radio value={0}>Inativo</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className="tabela">
                <TabelaDados url="ContaCorrente/Listar" colunas={
                    [
                        {
                            title: 'Código',
                            width: 75,
                            align: 'center',
                            render: ({ ctc_id, ctc_ativo }) => (
                                <Popover content={ctc_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                    <Tag color={ctc_ativo ? 'processing' : 'default'} className="w-100">
                                        <b>{ctc_id}</b>
                                    </Tag>
                                </Popover>
                            ),
                        },
                        {
                            title: 'Descrição',
                            render: ({ ctc_descricao }) => (
                                <div>
                                    <b>{ctc_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Tipo de Conta',
                            render: ({ tcc_descricao }) => (
                                <div>
                                    <b>{tcc_descricao}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Agência',
                            width: 120,
                            render: ({ ctc_numeroagencia }) => (
                                <div>
                                    <b>{(!!ctc_numeroagencia) ? (ctc_numeroagencia) : 'Não Informada'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Conta Bancária',
                            width: 150,
                            render: ({ ctc_numeroconta }) => (
                                <div>
                                    <b>{(!!ctc_numeroconta) ? (ctc_numeroconta) : 'Não Informada'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Data Abertura',
                            align: 'center',
                            width: 120,
                            render: ({ ctc_datasaldoinicial }) => (
                                <div>
                                    <b>{(!!ctc_datasaldoinicial) ? moment(ctc_datasaldoinicial).format('DD/MM/YYYY') : 'Não Informada'}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Saldo Atual (R$)',
                            align: 'right',
                            render: ({ saldo }) => (
                                <div>
                                    <b>{(!!saldo) ? FormatNumber(saldo, true) : ''}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            key: 'x',
                            align: 'center',
                            width: 95,
                            fixed: 'right',
                            render: (record) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Editar onClick={() => {
                                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
                                                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                                            }} icon={<EditOutlined />} />
                                        </Col>
                                        {/* <Col>
                                            <Excluir onClick={() => excluirRegistro(record)} icon={<DeleteOutlined />}/>
                                        </Col> */}
                                        <Col>
                                            <Excluir icon={<DeleteOutlined />} onClick={() => modalExcluir(record)} />
                                        </Col>

                                        <Col>
                                            <Dropdown overlay={(menuTable(record))} trigger={['click']}>
                                                <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                            </Dropdown>
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} />
            </div>
            <DrawerContaCorrente />
            <DrawerLancamentoTransferencia />
            <DrawerManutencaoCaixa />
        </div>
    );

}