import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Descriptions, Form, Table, Divider } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment"

import api from "../../../services/api";
import { FormatNumber } from '../../../ValueObjects';

export default function ModalDetalhesOrdemCompra({ exibirModal, fecharModal, dadosOrdemCompra }) {

    const [itensOrdemCompra, setItensOrdemCompra] = useState([]);

    useEffect(() => {
        if (!!dadosOrdemCompra.orc_id) {
            api.get(`OrdemCompraItem/ListarItensOrdemCompra?codigoOrdemCompra=${dadosOrdemCompra.orc_id}`).then(
                (res) => {
                    if (!!res.data) {
                        let itens = res.data;
                        itens.forEach((item, indice) => {
                            item.ocp_faltareceber = item.ocp_quantidaderecebida;

                            api.get(`ListaPrecoItem/BuscarPrecoPadraoItem?codigoProduto=${item.pro_id}`).then(
                                (res) => {
                                    if (!!res.data) {
                                        item.lpi_valorvenda = res.data;
                                    }
                                }
                            );
                            if (!!item.prg_id) {
                                api.get(`Produto/ListarGrades?codigoProduto=${item.pro_id}`).then(
                                    (res) => {
                                        if (!!res.data) {
                                            res.data.forEach((grade) => {
                                                if (grade.prg_id === item.prg_id) {
                                                    item.grade = {
                                                        prg_id: grade.prg_id,
                                                        mgp_descricao: "",
                                                        prg_ean: grade.ean,
                                                        prg_codigoetiqueta: grade.etiqueta,
                                                        prg_valorvenda: grade.valorVenda,
                                                        atributos: []
                                                    }

                                                    for (let i = 0; i < grade.atributos.length; i++) {
                                                        item.grade.mgp_descricao += grade.atributos[i].mgp_descricao + " ";
                                                        let atributoTemp = {
                                                            mgp_id: grade.atributos[i].mgp_id,
                                                            mgp_descricao: grade.atributos[i].mgp_descricao
                                                        }
                                                        item.grade.atributos.push(atributoTemp);
                                                    }
                                                }
                                            })
                                        }
                                    }
                                );
                            }

                            if (itens.length - 1 === indice) {
                                setItensOrdemCompra(itens);
                            }
                        });
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            );
        }
    }, [dadosOrdemCompra]);

    return (
        <Modal centered
            width={800}
            title="Detalhes da Ordem de Compra"
            visible={exibirModal}
            onCancel={fecharModal}
            footer={null}
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="">
                    <Row>
                        <Col span={24}>
                            <Descriptions size="small">
                                <Descriptions.Item label="Nº">
                                    <b>{dadosOrdemCompra.orc_id}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
                                <Descriptions.Item label="Fornecedor">
                                    <b>{dadosOrdemCompra.nomeFornecedor}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Transportador">
                                    <b>{dadosOrdemCompra.nomeTransportador || 'Não Informado'}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}>
                                <Descriptions.Item label="Atendente">
                                    <b>{dadosOrdemCompra.orc_atendente || 'Não Informado'}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Nº do pedido">
                                    <b>{dadosOrdemCompra.orc_nropedido || 'Não Informado'}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Descriptions size="small">
                                <Descriptions.Item label="Observação">
                                    <b>{dadosOrdemCompra.orc_observacao || 'Não Informado'}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Descriptions size="small" column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 2, xs: 1 }}>
                                <Descriptions.Item label="Forma de Pagamento">
                                    <b>{dadosOrdemCompra.orc_formapagamento || 'Não Informado'}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Data de Inclusão">
                                    <b>{moment(dadosOrdemCompra.orc_datainclusao).format('DD/MM/YYYY')}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Data de Conclusão">
                                    <b>{moment(dadosOrdemCompra.orc_dataconclusao).format('DD/MM/YYYY')}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Descriptions size="small" column={{ xxl: 3, xl: 3, lg: 2, md: 2, sm: 2, xs: 1 }}>
                                <Descriptions.Item label="Total dos Itens (R$)">
                                    <b>{FormatNumber(dadosOrdemCompra.orc_valortotal - dadosOrdemCompra.orc_valorfrete + dadosOrdemCompra.orc_valordesconto, true)}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Frete (R$)">
                                    <b>{FormatNumber(dadosOrdemCompra.orc_valorfrete, true)}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Desconto (R$)">
                                    <b>{FormatNumber(dadosOrdemCompra.orc_valordesconto, true)}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Divider className="m-2" />
                        </Col>
                    </Row>
                    <Row gutter={[8, 8]} justify="end">
                        <Col span={24} align="right">
                            Total (R$): <b className="f-16"> {FormatNumber(dadosOrdemCompra.orc_valortotal, true)}</b>
                        </Col>
                    </Row>
                    <div className="tabela mt-dif">
                        <Table dataSource={itensOrdemCompra} columns={
                            [
                                {
                                    title: 'Código',
                                    width: 50,
                                    align: 'left',
                                    render: ({ pro_codigo }) => (
                                        <div>
                                            <b>{pro_codigo}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Itens',
                                    width: 180,
                                    render: ({ pro_descricao, mgp_descricao }) => (
                                        <div>
                                            <Row gutter={[8, 0]}>
                                                <Col>
                                                    <b>{pro_descricao}</b>
                                                </Col>
                                            </Row>
                                            {mgp_descricao != "" &&
                                                <Row gutter={[8, 0]}>
                                                    <Col>
                                                        Grade: <b>{mgp_descricao}</b>
                                                    </Col>
                                                </Row>
                                            }
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Qtde.',
                                    width: 50,
                                    align: 'right',
                                    render: ({ ocp_quantidade }) => (
                                        <div>
                                            <b>{FormatNumber(ocp_quantidade, true)}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Qtde. Rec.',
                                    width: 50,
                                    align: 'right',
                                    render: ({ ocp_quantidaderecebida }) => (
                                        <div>
                                            <b>{FormatNumber(ocp_quantidaderecebida, true)}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Vlr. Un.(R$)',
                                    width: 50,
                                    align: 'right',
                                    render: ({ ocp_valorun }) => (
                                        <div>
                                            <b>{FormatNumber(ocp_valorun, true)}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Vlr. Total(R$)',
                                    width: 60,
                                    align: 'right',
                                    render: ({ ocp_valorun, ocp_frete, ocp_desconto, ocp_quantidade }) => (
                                        <div>
                                            <b>{FormatNumber((ocp_valorun + ocp_frete - ocp_desconto) * ocp_quantidade, true)}</b>
                                        </div>
                                    ),
                                },
                            ]
                        } locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }} scroll={{ y: 196, x: 580 }} />
                    </div>
                </Form>
            </div>
        </Modal>
    );
}