import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Input, Alert, Tooltip, notification, Modal } from "antd";
import { QuestionCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import { useStateValue } from "../../state";
import { Data, InputPreco, SelectPaginacao } from "../../components";
import { novaAbaNavegador } from "../../services/funcoes";
import { getEmpresa, getLocal } from "../../services/auth";
import { MaskFormat } from '../../ValueObjects/index';

export default function NotaFiscalDebitoCredito({ formulario, carregando, aoSalvar }) {

    const [ambiente, setAmbiente] = useState([]);
    const [operacao, setOperacao] = useState([]);
    const [serieFiscal, setSerieFiscal] = useState([]);
    const [listaPessoas, setListaPessoas] = useState([]);
    const [exibeDestinatario, setExibeDestinatario] = useState(false);
    const [dadosContribuinte, setDadosContribuinte] = useState({});
    const [editando, setEditando] = useState(false);
    const [{ ui, manutencao }, dispatch] = useStateValue();

    const salvarManutencao = (values) => {
        values.ntf_numero = !!values.ntf_numero ? values.ntf_numero : dadosContribuinte.ntf_numero;
        values.ntf_status = 1;
        values.baseCalculo = !!values.baseCalculo ? values.baseCalculo : 0;
        values.valorDebitoCredito = !!values.valorDebitoCredito ? values.valorDebitoCredito : 0;
        values.emitirNfe = true;
        values.dadosContribuinte = dadosContribuinte;
        values.dadosDestinatario = listaPessoas.find((pes) => { return pes.pes_id === values.pes_id });
        values.operacao = operacao.filter((odc) => odc.odc_id === values.odc_id)[0];

        carregando(true);
        if (values.ntf_id) {
            api.put('/NotaFiscalDebitoCredito/Alterar', values).then(res => {
                if (res.status === 200) {
                    notification.success({ message: 'Operação concluída', description: "Os dados foram salvos com sucesso!" });
                    if (res.data.ntf_status === 1 && !!res.data.ntf_chavenfe) {
                        novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${res.data.ntf_chavenfe}`);
                    }
                }
            }, erro => {
                if (!!erro.response && !!erro.response.data && !!erro.response.data.Message) {
                    Modal.warning({
                        content: erro.response.data.Message,
                        title: 'Aviso',
                        onOk: () => {
                            aoSalvar();
                        }
                    })
                }
                console.log(erro);
                // notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
            }).finally(
                () => {
                    carregando(false);
                    aoSalvar();
                }
            );
        } else {
            api.post('/NotaFiscalDebitoCredito/Incluir', values).then(res => {
                notification.success({ message: 'Operação concluída', description: "Os dados foram salvos com sucesso!" });
                if (res.data.ntf_status === 1 && !!res.data.ntf_chavenfe) {
                    novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${res.data.ntf_chavenfe}`);
                }
            }).catch(
                erro => {
                    if (!!erro.response && !!erro.response.data && !!erro.response.data.Message) {
                        Modal.warning({
                            content: erro.response.data.Message,
                            title: 'Aviso',
                            onOk: () => {
                                aoSalvar();
                            }
                        })
                    }
                    console.log(erro);
                    // notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
                }).finally(
                    () => {
                        carregando(false);
                        aoSalvar();
                    });
        }
    };

    useEffect(() => {
        api.get(`Nfe/VerificarAmbiente?codigoLocal=${getLocal()}`).then(retornoAmbiente => {
            if (!!retornoAmbiente.data) {
                setAmbiente(retornoAmbiente.data);
            }
        }).catch((erro) => console.error(erro));


        api.get(`OperacaoDebitoCredito/Listar`).then(res => {
            if (res.status === 200) {
                setOperacao(res.data.items);
            }
        }).catch((erro) => console.error(erro));

        api.get(`SerieFiscal/Listar?EmitenteDocumento=0&CodigoModeloDocFiscal=55&SomenteAtivos=true`).then(res => {
            if (res.status === 200) {
                setSerieFiscal(res.data.items);
                if (res.data.items.length === 1) {
                    formulario.setFieldsValue({ ser_id: res.data.items[0].ser_id });
                }
            }
        }).catch((erro) => console.error(erro));

        // api.get(`Pessoa/ListarPessoasAtivas`).then(
        //     res => {
        //         if (res.status === 200) {
        //             setListaPessoas(res.data.items);
        //         }
        //     }).catch((erro) => console.error(erro));

        api.get(`dadosContribuinte/Buscar`).then(
            (res) => {
                if (res.status === 200) {
                    setDadosContribuinte(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    }, []);

    useEffect(() => {
        if (ui.showDrawerExtra && operacao.length > 0) {
            verificarOperacao();
            if (!!formulario.getFieldValue().pes_id) {
                verificarPessoaContribuinte(formulario.getFieldValue().pes_id);
            }
        }
        if (!!manutencao.dados) {
            setEditando(true);
            formulario.setFieldsValue(manutencao.dados);
        } else {
            formulario.setFieldsValue({ ntf_dataemissao: moment(new Date()) });
        }
    }, [ui.showDrawerExtra, operacao]);

    function verificarOperacao() {
        if (!!formulario.getFieldValue().odc_id) {
            let odc = operacao.find((odc) => { return odc.odc_id === formulario.getFieldValue().odc_id });
            if (odc.cfo_cfop > 5000) {
                setExibeDestinatario(true);
            }
        } else {
            setExibeDestinatario(false);
        }
    };

    function verificarPessoaContribuinte(id) {
        if (!!id && !listaPessoas.find((pes) => { return pes.pes_id === id }).pej_inscricaoestadual) {
            formulario.setFieldsValue({ pes_id: null });
            notification.error({ message: 'Destinatário inválido para este tipo de operação', description: "Não é possível emitir NF-e de Débito ou Crédito de ICMS para não Contribuinte de ICMS - Verifique no cadastro do destinatário a informação da Inscrição Estadual!" });
        }
    };

    return (
        <Form layout="vertical" form={formulario} name="manutencaoDebitoCredito" onFinish={salvarManutencao}>
            {ambiente === 2 &&
                <Row align="middle" justify="center" gutter={[8, 8]} className="m-b-16">
                    <Col>
                        <Alert message="ATENÇÃO: Sistema de Emissão de Nota Fiscal em Ambiente de Homologação" type="warning" showIcon />
                    </Col>
                    <Col>
                        <Tooltip title="Notas Fiscais emitidas em ambiente de homologação não tem validade fiscal ou jurídica">
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </Col>
                </Row>
            }
            <Row gutter={[8, 0]} className="m-t-16">
                {!!editando && <Col xs={24} sm={8} md={3} lg={3} xl={3}>
                    <Form.Item label="Nº da Nota" name="ntf_numero">
                        <Input disabled />
                    </Form.Item>
                </Col>}
                <Col xs={24} sm={12} md={6} lg={5} xl={5}>
                    <Form.Item hidden name="ntf_id">
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name="cli_id">
                        <Input />
                    </Form.Item>
                    <Form.Item hidden name="ntf_numero">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Série" name="ser_id" rules={[{ required: true }]}>
                        <Select disabled={editando} placeholder="Selecione uma Série" allowClear showSearch optionFilterProp="children">
                            {serieFiscal.map(serie => (
                                <Select.Option value={serie.ser_id} key={serie.ser_id}>{serie.ser_serie}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={5} lg={4} xl={4}>
                    <Data label="Data de Emissão" name="ntf_dataemissao" rules={[{ required: true }]} />
                </Col>
                <Col xs={24} sm={editando ? 12 : 14} md={editando ? 12 : 14} lg={editando ? 12 : 14} xl={editando ? 12 : 14}>
                    <Form.Item label="Operação" name="odc_id" rules={[{ required: true }]}>
                        <Select disabled={editando} placeholder="Selecione a Operação" allowClear showSearch optionFilterProp="children" onChange={() => verificarOperacao()}>
                            {operacao.map(odc => (
                                <Select.Option value={odc.odc_id} key={odc.odc_id}>{odc.odc_descricao}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={8} md={6} lg={4} xl={4}>
                    <InputPreco disabled={editando} label="Base de Cálculo (R$)" name="baseCalculo" className="text-right" />
                </Col>
                <Col xs={24} sm={8} md={6} lg={5} xl={5}>
                    <InputPreco disabled={editando} label="Valor do Débito/Crédito (R$)" name="valorDebitoCredito" className="text-right" rules={[{ required: true }]} />
                </Col>
                {exibeDestinatario &&
                    <Col span={24}>
                        <Form.Item name="pes_id" label="Destinatário">
                            <SelectPaginacao url="Pessoa/ListarPessoasAtivas" placeholder="Selecione uma pessoa" form={formulario} nameLabel="pes_nome"
                                nameValue="pes_id" allowClear={true}
                                setListaDados={setListaPessoas}
                                onChangeFunction={(dados) => {
                                    verificarPessoaContribuinte(dados)
                                }} conteudo={
                                    pc => (<Select.Option value={pc.pes_id} key={pc.key}>{pc.pes_id} - {pc.pes_nome} <br />
                                        {(!!pc.pej_cnpj ? `CNPJ.: ${MaskFormat(pc.pej_cnpj, '', true)}` : !!pc.pef_cpf ? `CPF.: ${MaskFormat(pc.pef_cpf, '', true)}` : '')} <br />
                                        {(!!pc.pej_inscricaoestadual ? ` IE.: ${pc.pej_inscricaoestadual}` : '')}
                                    </Select.Option>)
                                } />
                        </Form.Item>
                    </Col>
                }
                <Col span={24}>
                    <Form.Item label="Informações Complementares" name="ntf_infcomplementar">
                        <Input.TextArea placeholder="Informe as Observações Complementares" />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
}