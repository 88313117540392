
import React, { useState } from "react";
import { Row, Col, Modal, Table, Tag, Divider, Descriptions } from "antd";
import { ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons";

export default function ModalListaProduto({ exibirModalListaProduto, fecharModalListaProduto, dadosItens }) {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const rowSelection = {
        selectedRowKeys,
    };

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalListaProduto();
            }
        });
    };

    return (
        <Modal centered
            title="Produtos"
            width={700}
            visible={exibirModalListaProduto}
            onCancel={onCloseModal}
            onOk={onCloseModal}
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }  
            maskClosable={false}
        >
            <div>
                <Row>
                    <Col>
                        <Descriptions size="small">
                            <Descriptions.Item>
                                Abaixo selecione o produto desejado.
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table columns={[
                            {
                                title: 'Informações dos Produtos/Mercadorias',
                                render: ({ pro_id, pro_descricao }) => (
                                    <div>
                                        <Row gutter={[8, 0]}>
                                            <Col>
                                                <Tag color="processing">
                                                    <b>{pro_id}</b>
                                                </Tag>
                                            </Col>
                                            <Col>
                                                <b>{pro_descricao}</b>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]} rowSelection={rowSelection} dataSource={dadosItens} locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }} />
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}