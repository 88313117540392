import React, { useState } from "react";
import { Button } from 'antd';

import { validarPermissaoTela } from "../../services/permissoes";

export default function LancamentoSaida(props) {

    const [desabilita, setDesabilita] = useState(false);

    useState(() => {
        setDesabilita(validarPermissaoTela(15));
    }, []);

    return (
        <Button type={props.type} disabled={desabilita} onClick={() => props.onClick()} icon={props.icon} size={props.size} htmlType={props.htmlType}>
            {props.children}
        </Button>
    );
};