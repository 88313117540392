import React, { useEffect, useState } from "react";
import { Col, Descriptions, Divider, Modal, notification, Row, Tabs } from "antd";

import api from "../../../services/api";
import { CloseOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { formatarCnpj, formatarCpf } from "../../../services/funcoes";
import moment from "moment";
import { FormatNumber } from "../../../ValueObjects";
import TabItensSelecionar from "./tabItensSelecionar";
import TabFormaPagamentoSelecionar from "./tabFormaPagamentoSelec";
import DrawerPedidos from "../../../pages/pedidos/drawer";

export default function ModalSelecionarOrcamento ({ exibirModal, fecharModal, dadosOrcamento }) {
    const [orcamento, setOrcamento] = useState([]);
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [itensConfirmados, setItensConfirmados] = useState([]);
    const [fpgsConfirmados, setFpgsConfirmados] = useState([]);
    const [dadosCliente, setDadosCliente] = useState({});
    const [modalFechada, setModalFechada] = useState(false);
    const [exibirDrawerPedidos, setExibirDrawerPedidos] = useState(false);

    useEffect(() => {
        if (!!dadosOrcamento?.orc_id) {
            api.get(`Orcamento/Buscar?orc_id=${dadosOrcamento.orc_id}`).then(
                (res) => {
                    setOrcamento(res.data);
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            );
        }
    }, [dadosOrcamento]);

    const funcaoConfirmarItens = (itensConfirmados) => {
        setItensConfirmados(itensConfirmados);
    }

    const funcaoConfirmarFpgs = (fpgsConfirmados) => {
        setFpgsConfirmados(fpgsConfirmados);
    }

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModal();
                setAbaSelecionada("1")
                setModalFechada(true);
            }
        });
    }

    async function gerarPedido() {
        if (itensConfirmados.length === 0 || fpgsConfirmados.length === 0) {
            notification.warning({ message: 'Aviso!', description: 'Selecione pelo menos um Item e uma Forma de Pagamento'})
        } else {
            try {
                if (dadosOrcamento.cli_id !== null) {
                    var response = await api.get(`Cliente/BuscarCliente/${dadosOrcamento.cli_id}`);
                    var cliente = response.data
                } else {
                    var cliente = dadosOrcamento;
                }
                cliente.itens = [...itensConfirmados];
                cliente.formasPagamento = [...fpgsConfirmados];
                setDadosCliente(cliente)
                fecharModal();
                setExibirDrawerPedidos(true);
                setAbaSelecionada("1")
                setModalFechada(true);
            } catch (error) {
                console.error("Erro", error);
            }
        }
    }

    return (
        <div>
            <Modal centered
                title="Selecionar Itens e Forma de Pagamento"
                visible={exibirModal}
                onCancel={onCloseModal}
                width="70%"
                maskClosable={false}
                onOk={gerarPedido}
                okText={
                    <><PlusOutlined /> Gerar Pedido </>
                }
                cancelText={
                    <><CloseOutlined /> Cancelar </>
                }
            >
                <div>
                    <Row align="middle">
                        <Col>
                        <Descriptions column={{ xxl: 3, xl: 3, lg: 3, md: 2, sm: 2, xs: 1 }} size="small" >
                                <Descriptions.Item label="Nº">
                                    <b>{orcamento.orc_numero}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Cliente">
                                    <b>{orcamento.pes_nome ? orcamento.pes_nome : orcamento.orc_nomecliente}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="CPF/CNPJ">
                                    <b>{!!orcamento.pef_cpf ? formatarCpf(orcamento.pef_cpf) : orcamento.pej_cnpj ? formatarCnpj(orcamento.pej_cnpj) : "Não Informado"} </b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Data de Inclusão">
                                    <b>{!!orcamento.orc_datainclusao ? moment(orcamento.orc_datainclusao).format('DD/MM/YYYY') : "Não Informado"}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Data de Validade">
                                    <b>{!!orcamento.orc_datavalidade ? moment(orcamento.orc_datavalidade).format('DD/MM/YYYY') : "Não Informado"}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Vendedor">
                                    <b>{orcamento.ven_nome}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Frete (R$)">
                                    <b>{FormatNumber(orcamento.orc_valorfrete, true)}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Outras despesas (R$)">
                                    <b>{FormatNumber(orcamento.orc_valoroutros, true)}</b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Total (R$)">
                                    <b>{FormatNumber(orcamento.orc_valortotal, true)}</b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Divider orientation="center">
                        Selecione para Gerar Pedido
                    </Divider>
                    <div className="m-t-8">
                        <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                            <Tabs.TabPane tab="Itens" key="1">
                                <TabItensSelecionar itens={orcamento.itens} confirmarItens={funcaoConfirmarItens} modalFechada={modalFechada}/>
                            </Tabs.TabPane>
                            <Tabs.TabPane tab="Formas de Pagamento" key="2">
                                <TabFormaPagamentoSelecionar formaPagamento={orcamento.formasPagamento} confirmarFpgs={funcaoConfirmarFpgs} modalFechada={modalFechada}/>
                            </Tabs.TabPane>
                        </Tabs>
                    </div>
                </div>
            </Modal>
            <DrawerPedidos dadosOrcamento={orcamento} clientes={dadosCliente} exibirDrawer={exibirDrawerPedidos} fecharDrawerPedidos={() => setExibirDrawerPedidos(false)}/>
        </div>
    );
}