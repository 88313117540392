import { CloseOutlined, ExclamationCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Form, Row, Modal } from 'antd';
import React, { useState } from 'react';
import GrupoCatalogo from '../../pages/grupoCatalogo';


export default function DrawerGrupoCat({ openDrawer, setOpenDrawer, formCatalogo, listaGrupos, editandoGrp, sequenciaGrp, aoSalvarGrupos }) {
    const [formulario] = Form.useForm();
    const [carregando, setCarregando] = useState(false);

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                onCloseDrawer();
            }
        })
    }

    const onCloseDrawer = () => {
        aoSalvarGrupos();
        formulario.resetFields();
    }

    return (
        <Drawer width="60%"
            title="Cadastro de Grupos"
            visible={openDrawer}
            closable={false}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => { formulario.submit() }} loading={carregando} icon={<SaveOutlined />} size="large" type="primary">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <GrupoCatalogo formulario={formulario} formCatalog={formCatalogo} listaGrupos={listaGrupos} setOpenDrawer={setOpenDrawer}
                editandoGrp={editandoGrp} sequenciaGrp={sequenciaGrp} openDrawer={openDrawer} aoSalvarGrupos={aoSalvarGrupos} />
        </Drawer>);
}