import React from "react";
import { Col, Row, Typography } from 'antd';

import { Planos } from "../";
import { BreadcrumbAbrirChamado } from "../../components";

export default function SemAcesso() {
    return (
        <div className="p-10">
            <BreadcrumbAbrirChamado />
            <Row align="middle">
                <Col span={24}>
                    <Row justify="center">
                        <Col>
                            <img src={require("../../assets/i-sem-acesso.png").default} width="150" />
                        </Col>
                        <Col span={24}>
                            <Typography.Title level={2} className="text-center m-b-0">
                                Seu plano atual não possui esta opção.
                            </Typography.Title>
                        </Col>
                        <Col span={24}>
                            <Typography.Title level={5} className="text-center"> 
                                Consulte nossos planos e faça um upgrade!
                            </Typography.Title>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                   <Planos />
                </Col>
            </Row>
        </div>

    )
}