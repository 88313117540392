import React, { useState, useEffect } from 'react';
import { Row, Col, Descriptions, Tabs, Divider } from "antd";
import moment from "moment";
import { TabDetalheDocs, TabDetalheMotorista, TabDetalheVeiculo } from './tabPage'
import formatNumber from '../../ValueObjects/formatNumber';

export default function DetalheMdfe({ dadosModal }) {
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [totalNfe, setTotalNfe] = useState(null);
    const [totalCte, setTotalCte] = useState(null);
    const [valorTotal, setValorTotal] = useState(null);
    const [pesoTotal, setPesoTotal] = useState(null);

    useEffect(() => {
        if (dadosModal?.docs?.length > 0) {
            let docs = [...dadosModal.docs]
            let totalValor = docs.reduce((vlr, item) => { let valor = parseFloat(item.doc_valortotal) || 0; return vlr + valor; }, 0);
            let totalPeso = docs.reduce((vlr, item) => { let peso = parseFloat(item.doc_pesobruto) || 0; return vlr + peso; }, 0);
            setValorTotal(totalValor);
            setPesoTotal(totalPeso);

            if (docs[0].doc_modelodoc === "55") {
                setTotalNfe(docs.length);
            } else if (docs[0].doc_modelodoc === "57") {
                setTotalCte(docs.length);
            }
        }

    }, [dadosModal])

    return (
        <div>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="Chave MDF-e">
                            <b> {dadosModal.mdf_chavemdfe} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Nº">
                            <b> {dadosModal.mdf_numero} </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="Data de Emissão">
                            <b> {moment(dadosModal.mdf_dataemissao).format('DD-MM-YYYY')} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Status">
                            <b> {dadosModal.mdf_status} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Ambiente">
                            <b> {dadosModal.mdf_ambiente} </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="Tipo Emitente">
                            <b> {dadosModal.mdf_tipoemitente} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Tipo Modal">
                            <b> {dadosModal.mdf_tipomodal} </b>
                        </Descriptions.Item>

                        <Descriptions.Item label="Produto Predominante">
                            <b> {dadosModal.pme_descricao} </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="Unidade de Peso">
                            <b> {dadosModal.mdf_unidadepeso} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Peso total da carga">
                            <b> {pesoTotal} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Valor total da carga">
                            <b> R$ {formatNumber(valorTotal, true)} </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align='middle'>
                <Col>
                    <Descriptions size='small'>
                        <Descriptions.Item label="Total NF-e">
                            <b> {totalNfe ?? 0} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Total CT-e">
                            <b> {totalCte ?? 0} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Informação Complementar">
                            <b> {(dadosModal.mdf_infocomplementar ?? "NÃO INFORMADO")} </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Divider orientation="left" plain style={{ margin: '0px', marginTop: '15px' }}>Carregamento</Divider>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="CEP">
                            <b> {dadosModal.pme_cepcarrega} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Cidade">
                            <b> {dadosModal.cid_descricaocarrega} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Estado (UF)">
                            <b> {dadosModal.mdf_ufinicio} </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Divider orientation="left" plain style={{ margin: '0px', marginTop: '15px' }}>Descarregamento</Divider>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="CEP">
                            <b> {dadosModal.pme_cepdescarrega} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Cidade">
                            <b> {dadosModal.cid_descricaodescarrega} </b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Estado (UF)">
                            <b> {dadosModal.mdf_uffim} </b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <div className='m-t-8'>
                <Tabs type='card' centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Docs. Referenciados" key="1">
                        <TabDetalheDocs docs={dadosModal.docs} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Veículo" key="2">
                        <TabDetalheVeiculo veiculo={dadosModal.veiculos} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Motorista" key="3">
                        <TabDetalheMotorista motorista={dadosModal.motoristas} />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    )
}