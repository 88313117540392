import React, { useState } from 'react';
import { Form } from "antd";
import { MinusOutlined } from "@ant-design/icons";

import { useStateValue } from '../../state';
import { drawerActions, manutencaoActions } from '../../actions';
import ImprimirEtiqueta from './manutencao';
import { DrawerNav } from '../../components';

export default function DrawerImprimirEtiqueta() {

    const [formImprimirEtiqueta] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [carregando, setCarregando] = useState(false);

    function fecharDrawer() {
        formImprimirEtiqueta.resetFields();
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
    }

    return (

        <DrawerNav title="Imprimir Etiquetas"
            width="70%"
            visible={ui.showDrawer}
            closeIcon={<MinusOutlined />}
            fecharDrawer={() => {
                formImprimirEtiqueta.resetFields();
                dispatch({ type: drawerActions.CHANGE, data: { showDrawer: false } });
            }}
        >
            <ImprimirEtiqueta formulario={formImprimirEtiqueta} carregando={setCarregando} aoSalvar={fecharDrawer} />
        </DrawerNav >

    )
}