import React, { useState, useEffect } from "react";
import { Form, Row, Col, Input, Checkbox, Typography, Divider, Button, Table, Modal, notification } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from '../../services/api';
import { FormGW } from "../../components";
import { useStateValue } from "../../state";
import { listagemActions } from "../../actions";
import { ModalTributacaoIcms } from "../../components/modals/";

export default function ManutencaoTributacaoIcms({ formulario, carregando, aoSalvar }) {

    const [formModal] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();
    const [openModal, setOpenModal] = useState(false);
    const [listaOrigemDestino, setListaOrigemDestino] = useState([]);
    const [dadosOrigemDestino, setDadosOrigemDestino] = useState([]);

    const modalExcluir = (item) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente excluir este registro?`,
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                removerRegistro(item);
            }
        });
    };

    useEffect(() => carregaDados(), [])

    useEffect(() => {
        if (!!dadosOrigemDestino && dadosOrigemDestino.length > 0) {
            let list = [...listaOrigemDestino];
            let index = null;
            dadosOrigemDestino.forEach((item) => {
                if (!!item.iod_id) {
                    index = list.findIndex(x => x.iod_id === item.iod_id);
                    list[index] = item;
                } else {
                    if (!!item.iod_novo) {
                        index = list.findIndex(x => x.iod_novo === item.iod_novo);
                        list[index] = item;
                    } else {
                        let possuiRegistro = list.find(x => x.iod_ufdestino === item.iod_ufdestino);
                        if (possuiRegistro) {
                            notification.error({ message: 'Já possui registro com a UF destino ' + item.iod_ufdestino });
                        } else {
                            item.iod_novo = item.key;
                            list.push(item);
                        }
                    }
                }
            });
            setListaOrigemDestino(list);
        }
    }, [dadosOrigemDestino])

    useEffect(() => {
        if (!!manutencao.dados && manutencao.dados.duplicar) {
            formulario.setFieldsValue({ icm_id: null });
        }
    }, [manutencao.dados]);

    function atualizarPagina() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+icm_descricao' } });
    };


    function carregaDados() {
        if (!!formulario.getFieldValue().icm_id) {
            api.get(`TributacaoIcms/Buscar/${formulario.getFieldValue().icm_id}`).then(
                res => {
                    setListaOrigemDestino(res.data.icmsorigemdestinos);
                }).catch(
                    erro => {
                        notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
                    }).finally(
                        () => {
                            carregando(false)
                        }
                    );
        }
    }

    function removerRegistro(registro) {
        if (!!registro.iod_id) {
            api.delete(`icmsOrigemDestino/Excluir/${registro.iod_id}`).then(
                res => {
                    notification.success({ message: 'Operação concluída', description: "Os dados da Origem Destino foram excluídos com sucesso!" });
                    let lista = [...listaOrigemDestino];
                    let index = lista.findIndex(i => i.iod_id === registro.iod_id);
                    lista.splice(index, 1);
                    setListaOrigemDestino(lista);
                }).catch(
                    erro => {
                        notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(erro.response) });
                    }).finally(
                        () => carregando(false)
                    );
        } else if (registro.iod_novo !== undefined) {
            let list = [...listaOrigemDestino];
            let listaTemp = list.filter((item) => (item.iod_novo !== registro.iod_novo));
            let iod = 0;
            listaTemp.forEach((item) => {
                item.iod_novo = iod++;
                item.key = iod++;
            });
            setListaOrigemDestino(listaTemp);
        }
    }

    function salvarManutencao(values) {
        values.icmsorigemdestinos = listaOrigemDestino;
        carregando(true);
        if (values.icm_id) {
            editarManutencao(values);
        } else {
            incluirManutencao(values)
        }
    };

    function editarManutencao(values) {
        api.put('TributacaoICMS/Editar', values).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Os dados do ICMS foram salvos com sucesso!" });
                aoSalvar();
                atualizarPagina();
            }).catch(
                err => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
                }).finally(
                    () => {
                        atualizarPagina();
                        carregando(false);
                        aoSalvar();
                    });
    }

    function incluirManutencao(values) {
        api.post('TributacaoIcms/Incluir', values).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Os dados do ICMS foram salvos com sucesso!" });
                aoSalvar();
                atualizarPagina();
            }).catch(
                err => {
                    notification.error({ message: 'Erro ao processar a requisição.', description: JSON.stringify(err.response) });
                }).finally(
                    () => {
                        atualizarPagina();
                        carregando(false);
                        aoSalvar();
                    });
    }

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoTributacaoIcms" onFinish={salvarManutencao}>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={24} md={20} lg={20} xl={20}>
                        <Form.Item name="icm_id" hidden />
                        <Form.Item label="Descrição da Tributação" name="icm_descricao" rules={[{ required: true, message: 'Informe a descrição' }]}>
                            <Input placeholder="Informe a descrição" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4}>
                        <Form.Item name="icm_ativo" valuePropName="checked" className="t-mob">
                            <Checkbox>Tributação Ativa</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <div className="m-t-16">
                    <Row align="middle">
                        <Col span={24}>
                            <Typography.Title level={3}>
                                <img src={require("../../assets/i-dados.png").default} alt="Dados de Origem e Destino" /> Dados de Origem e Destino
                            </Typography.Title>
                            <Divider orientation="left" plain>
                                Defina as Informações de Origem e Destino aqui.
                            </Divider>
                        </Col>
                    </Row>
                    <Row align="middle" justify="end">
                        <Col>
                            <div className="i-Position p-relative">
                                <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => {
                                    setOpenModal(true);
                                    setDadosOrigemDestino(null);
                                }} />
                            </div>
                        </Col>
                    </Row>
                    <div className="tabela mt-dif">
                        <Table dataSource={listaOrigemDestino} columns={
                            [
                                {
                                    title: 'UF Origem',
                                    align: 'center',
                                    render: ({ iod_uforigem }) => (
                                        <div>
                                            <b>{iod_uforigem}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'UF Destino',
                                    align: 'center',
                                    render: ({ iod_ufdestino }) => (
                                        <div>
                                            <b>{iod_ufdestino}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'CSOSN/CST',
                                    align: 'center',
                                    render: ({ cso_id, cst_id }) => (
                                        <div>
                                            <b>{!!cso_id ? cso_id : cst_id}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: '% ICMS',
                                    align: 'center',
                                    render: ({ iod_percicms }) => (
                                        <div>
                                            <Row>
                                                <Col span={24}>
                                                    <b>{iod_percicms}%</b>
                                                </Col>
                                            </Row>
                                        </div>
                                    ),
                                },
                                {
                                    title: '% Diferimento',
                                    align: 'center',
                                    render: ({ iod_percdiferimentoicms }) => (
                                        <div>
                                            <b>{iod_percdiferimentoicms}%</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: '% Redução BC',
                                    align: 'center',
                                    render: ({ iod_percicmsreducaobc }) => (
                                        <div>
                                            <b>{iod_percicmsreducaobc}%</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Ações',
                                    dataIndex: '',
                                    align: 'center',
                                    key: 'x',
                                    width: 65,
                                    render: (item) => (
                                        <div>
                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                <Col>
                                                    <Button icon={<EditOutlined />} onClick={() => {
                                                        setOpenModal(true);
                                                        console.log(`ITEM`, item);
                                                        setDadosOrigemDestino(item);
                                                        formModal.setFieldsValue(item);
                                                    }} />
                                                </Col>
                                                <Col>
                                                    <Button icon={<DeleteOutlined />} onClick={() => modalExcluir(item)} />
                                                </Col>
                                            </Row>
                                        </div>
                                    ),
                                },
                            ]
                        } locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }} scroll={{ x: 900 }} />
                    </div>
                    <ModalTributacaoIcms exibirModal={openModal} fecharModal={() => setOpenModal(false)} dados={dadosOrigemDestino} setDados={setDadosOrigemDestino} formulario={formModal} listaOrigemDestino={listaOrigemDestino} />                </div>
            </FormGW>
        </div>
    );

}